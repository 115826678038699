import { Coordinate } from 'ol/coordinate';
import proj4 from 'proj4';
import { CoordinateSystem } from '^/types';
import * as THREE from 'three';

export const extractIFCProperties = (
  model: THREE.Object3D,
  projectionSystemLabel: string,
  coordinateSystem: CoordinateSystem | undefined
) => {
  const coordinateMatrix = (model as any).coordinationMatrix as THREE.Matrix4;

  const x = -coordinateMatrix.elements[12];
  const y = coordinateMatrix.elements[14];
  const z = -coordinateMatrix.elements[13];

  let projection = projectionSystemLabel;

  if (coordinateSystem) {
    const splitString = coordinateSystem.split('_');
    projection = `EPSG:${splitString[splitString.length - 2]}`;
  }

  const [reprojectedX, reprojectedY, reprojectHeight]: Coordinate = proj4(
    'EPSG:4326',
    projection
  ).inverse([x, y, z]);

  const rotation = new THREE.Euler();
  rotation.setFromRotationMatrix(coordinateMatrix);

  const heading = THREE.MathUtils.radToDeg(rotation.y);
  const pitch = THREE.MathUtils.radToDeg(rotation.x);
  const roll = THREE.MathUtils.radToDeg(rotation.z);

  const adjustedHeading = heading + 90;

  model.userData.latitude = reprojectedY;
  model.userData.longitude = reprojectedX;
  model.userData.elevation = reprojectHeight;
  model.userData.heading = adjustedHeading;
  model.userData.pitch = pitch;
  model.userData.roll = roll;
};
