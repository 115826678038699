import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import {
  useFlag,
  useUnleashClient,
  useUnleashContext,
  useVariant,
  useFlagsStatus,
  IVariant,
  UnleashClient,
  IMutableContext,
} from '@unleash/proxy-client-react';

interface IProps {
  isEnabled: boolean;
  getVariant: Partial<IVariant>;
  getClient: UnleashClient;
  getUnleashContextSetter(): (context: IMutableContext) => Promise<void>;
  getFlagsStatus(): {
    flagsReady: boolean;
    flagsError: any;
  };
}

interface IUnleashFlagProviderProps {
  render(props: IProps): ReactNode;
  flagName: string;
}

export const UnleashFlagProvider: FC<IUnleashFlagProviderProps> = ({ render, flagName }) => {
  const enabled = useFlag(flagName);
  const variant = useVariant(flagName);
  const client = useUnleashClient();

  const updateContext = useUnleashContext();
  const { flagsReady, flagsError } = useFlagsStatus();

  const isEnabled = useMemo(() => enabled, [enabled]);

  const getVariant = useMemo(() => variant, [variant]);

  const getClient = useMemo(() => client, [client]);

  const getUnleashContextSetter = useCallback(() => updateContext, [updateContext]);

  const getFlagsStatus = useCallback(() => ({ flagsReady, flagsError }), [flagsReady, flagsError]);

  return (
    <>
      {render({
        isEnabled,
        getVariant,
        getClient,
        getUnleashContextSetter,
        getFlagsStatus,
      })}
    </>
  );
};
