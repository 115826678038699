import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';
import Tippy from '@tippyjs/react';
import palette from '^/constants/palette';
import dsPalette from '^/constants/ds-palette';

import ContentAttachments from '../ContentAttachments';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import { ContentsListItem, HorizontalDivider } from '^/components/atoms/ContentsListItem';
import { GetESSAttachments } from '^/store/duck/ESSAttachments';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import SingleSlider from '^/components/atoms/SingleSlider';
import CustomModelInput from '../CustomModelInput';
import ContentColor from '^/components/atoms/ContentsListItemColor';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import Text from './text';
import { UseL10n, useL10n } from '^/hooks';
import { determineUnitType } from '^/utilities/imperial-unit';

import { OpenContentPagePopup } from '^/store/duck/Pages';
import { useESSContents } from '^/hooks/useESSContents';
import { useESSContentsStore } from '^/store/essContentsStore';

const balloonColorCustomStyle: CSSObject = {
  margin: 0,
  zIndex: 1,
  width: '5px',
};

const ToolContainer = styled.div({
  margin: '-14px 0 0 0px',
  width: '100%',
});

const SliderContainer = styled.div({
  marginTop: '25px',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '20px',
  alignItems: 'baseline',
});

const OpacityContainer = styled.div({
  height: '30px',
  width: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  border: `1px solid ${palette.ContentsList.inputBorder.toString()}`,
  color: dsPalette.themePrimary.toString(),
  borderRadius: '4px',
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: '700',
  paddingRight: '12px',
  marginLeft: '14px',
});

const OpacityTitle = styled.div({
  color: palette.darkBlack.toString(),
});

const CMInputStyle: CSSObject = {
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
};

const CustomModelInputContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gridColumnGap: '20px',
  gridRowGap: '2px',
  marginBottom: '20px',
});

const CMLabelStyle: CSSObject = {
  fontSize: '13px',
  lineHeight: '20px',
  paddingRight: '4px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 0,
};

const CustomModelInputExtraContainer = styled.div({
  paddingTop: '5px',
});

const ColorContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'end',
  margin: '-14px 58px 0 0',
});

const ToggleContainer = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ToggleLabel = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: dsPalette.title.toString(),
});

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

const Button = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  width: '72px',
  borderRadius: '4px',
  border: '1px solid #3669B5',
  fontSize: '12px',
  fontWeight: '500',
  cursor: 'pointer',
});

const rootStyle: CSSObject = {
  whiteSpace: 'nowrap',
};

const SaveAsButton = styled(Button)`
  color: ${dsPalette.themePrimary.toString()};
`;

const UpdateButton = styled(Button)`
  margin-left: 2px;
  background-color: ${dsPalette.themePrimary.toString()};
  color: ${palette.white.toString()};
`;

export interface Props {
  content: T.ESSCustomModelContent;
  isPinned?: boolean;
}

const MAX_OPACITY: number = 100;

const RawContentsListESSCustomModelItem: FC<Props> = ({ content, isPinned = false }) => {
  const { updateESSContent } = useESSContents();
  const { editingESSContentId, updateSingleESSContent } = useESSContentsStore(s => ({
    editingESSContentId: s.editingESSContentId,
    updateSingleESSContent: s.updateSingleESSContent,
  }));

  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const isEditing: boolean = content.id === editingESSContentId;

  const contentId = content.id;

  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);

  const project: T.Project | undefined = projectId ? projectById[projectId] : undefined;
  const unitType: T.ValidUnitType = project
    ? determineUnitType(project.unit)
    : determineUnitType(projectUnit);

  const getDimensionInUnits: (dimension: T.Dimension) => T.Dimension = dimension =>
    dimension.map(value =>
      unitType === T.UnitType.IMPERIAL ? Number((value * 0.3048).toFixed(2)) : value
    ) as T.Dimension;

  const getDimensionInImperial: (dimension: T.Dimension) => T.Dimension = dimension =>
    dimension.map(value =>
      unitType === T.UnitType.IMPERIAL ? Number((value * 3.28084).toFixed(2)) : value
    ) as T.Dimension;

  const [contentState, setContentState] = useState<T.ESSCustomModelContent>({
    ...content,
    info: {
      ...content.info,
      miscMeta: {
        ...content.info.miscMeta,
        dimensions: getDimensionInImperial(content.info.miscMeta.dimensions),
        radius:
          unitType === T.UnitType.IMPERIAL
            ? Number((content.info.miscMeta.radius * 3.28084).toFixed(2))
            : content.info.miscMeta.radius,
      },
    },
  });

  useEffect(() => {
    setContentState({
      ...content,
      info: {
        ...content.info,
        miscMeta: {
          ...content.info.miscMeta,
          dimensions: getDimensionInImperial(content.info.miscMeta.dimensions),
          radius:
            unitType === T.UnitType.IMPERIAL
              ? Number((content.info.miscMeta.radius * 3.28084).toFixed(2))
              : content.info.miscMeta.radius,
        },
      },
    });
  }, [content]);

  const opacity: number =
    content.info.miscMeta.opacity !== undefined ? content.info.miscMeta.opacity : MAX_OPACITY;

  const handleOpacityChange: (opacity: number) => void = opacityValue => {
    updateSingleESSContent(contentId, {
      ...content.info,
      miscMeta: {
        ...content.info.miscMeta,
        opacity: opacityValue,
      },
    } as T.ESSContent['info']);
  };

  const handleUpdate: () => void = () => {
    const newContentState = {
      ...contentState,
      info: {
        ...contentState.info,
        miscMeta: {
          ...contentState.info.miscMeta,
          dimensions: getDimensionInUnits(contentState.info.miscMeta.dimensions),
          radius:
            unitType === T.UnitType.IMPERIAL
              ? Number((contentState.info.miscMeta.radius * 0.3048).toFixed(2))
              : contentState.info.miscMeta.radius,
        },
      },
    };

    void updateESSContent({ content: newContentState });
  };

  function handleContent() {
    void updateESSContent({
      content: {
        ...content,
        info: {
          ...content.info,
          miscMeta: {
            ...content.info.miscMeta,
            dimensions: getDimensionInUnits(content.info.miscMeta.dimensions),
            radius:
              unitType === T.UnitType.IMPERIAL
                ? Number((content.info.miscMeta.radius * 0.3048).toFixed(2))
                : content.info.miscMeta.radius,
            opacity,
          },
        },
      },
    });
  }

  type InputFieldKinds =
    | 'name'
    | 'specification'
    | 'length'
    | 'width'
    | 'height'
    | 'radius'
    | 'description';

  const getValue = (value: string) => Number(parseFloat(value));

  const openModal = () => {
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.CUSTOM_MODEL_SAVE }));
  };

  const handleInputChange: (kind: InputFieldKinds, value: string) => void = (kind, value) => {
    const { dimensions: inputDimensions } = contentState.info.miscMeta;

    setContentState({
      ...contentState,
      info: {
        ...contentState.info,
        miscMeta: {
          ...contentState.info.miscMeta,
          dimensions: [
            kind === 'width' ? getValue(value) : inputDimensions[0],
            kind === 'length' ? getValue(value) : inputDimensions[1],
            kind === 'height' ? getValue(value) : inputDimensions[2],
          ],
          radius: kind === 'radius' ? getValue(value) : contentState.info.miscMeta.radius,
          specification:
            kind === 'specification' ? value : contentState.info.miscMeta.specification,
        },
        description: kind === 'description' ? value : contentState.info.description,
      },
    });
  };

  useEffect(() => {
    if (isEditing) {
      dispatch(GetESSAttachments({ contentId: content.id }));
    }
  }, [isEditing]);

  const toggleWorkRadiusVis: () => void = useCallback(() => {
    // TODO: update this later for cesium
    // dispatch(
    //   ChangeESSCustomModelContentWorkRadiusViz({
    //     id: content.id,
    //     isWorkRadiusVisEnabled: !content?.info.isWorkRadiusVisEnabled,
    //   })
    // );
  }, [content?.info.isWorkRadiusVisEnabled]);

  const workRadiusToggle: ReactNode = useMemo(
    () => (
      <>
        <HorizontalDivider />
        <ToggleContainer>
          <ToggleLabel>{l10n(Text.workRadiusToggleLabel)}</ToggleLabel>
          <ToggleSlider
            enabled={Boolean(content?.info.isWorkRadiusVisEnabled)}
            onClick={toggleWorkRadiusVis}
          />
        </ToggleContainer>
      </>
    ),
    [content?.info.isWorkRadiusVisEnabled]
  );

  return (
    <ContentsListItem isPinned={isPinned} className={CANCELLABLE_CLASS_NAME} content={content}>
      <ToolContainer>
        <OpacityTitle>{l10n(Text.opacityTitle)}</OpacityTitle>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={l10n(Text.color)}
        >
          <ColorContainer>
            <ContentColor content={content} balloonColorCustomStyle={balloonColorCustomStyle} />
          </ColorContainer>
        </Tippy>
        <SliderContainer>
          <SingleSlider
            minValue={0}
            maxValue={100}
            value={opacity}
            onChange={handleOpacityChange}
            onMouseUp={handleContent}
          />
          <OpacityContainer>{opacity.toFixed(0)}%</OpacityContainer>
        </SliderContainer>
        <CustomModelInputContainer>
          <CustomModelInput
            wrapperStyle={CMInputStyle}
            labelStyle={CMLabelStyle}
            rootStyle={rootStyle}
            kind="width"
            value={contentState.info.miscMeta.dimensions[0]}
            onChange={handleInputChange}
          />
          <CustomModelInput
            wrapperStyle={CMInputStyle}
            labelStyle={CMLabelStyle}
            kind="length"
            value={contentState.info.miscMeta.dimensions[1]}
            onChange={handleInputChange}
          />
          <CustomModelInput
            wrapperStyle={CMInputStyle}
            labelStyle={CMLabelStyle}
            kind="height"
            value={contentState.info.miscMeta.dimensions[2]}
            onChange={handleInputChange}
          />
          <CustomModelInput
            wrapperStyle={CMInputStyle}
            labelStyle={CMLabelStyle}
            kind="radius"
            value={contentState.info.miscMeta.radius}
            onChange={handleInputChange}
          />
        </CustomModelInputContainer>
        <CustomModelInputExtraContainer>
          <ButtonContainer>
            <SaveAsButton onClick={openModal}>{l10n(Text.saveAs)}</SaveAsButton>
            <UpdateButton onClick={handleUpdate}>{l10n(Text.update)}</UpdateButton>
          </ButtonContainer>
          <ContentAttachments content={content} />
        </CustomModelInputExtraContainer>
      </ToolContainer>
      {workRadiusToggle}
    </ContentsListItem>
  );
};

export const ContentsListESSCustomModelItem: FC<Props> = withErrorBoundary(
  RawContentsListESSCustomModelItem
)(Fallback);
