import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import Text from './text';
import TranslateSvg from '^/assets/icons/gizmo/transform.svg';
import ScaleSvg from '^/assets/icons/gizmo/scale.svg';
import RotateSvg from '^/assets/icons/gizmo/rotate.svg';

import MaximizeSvg from '^/assets/icons/map-controller/maximize.svg';
import MinimizeSvg from '^/assets/icons/map-controller/minimize.svg';
import { useL10n } from '^/hooks';
import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import palette from '^/constants/palette';
import { isMobile } from '^/utilities/device';
import { TransformControls as ThreeTransformControls } from '@angelsw/custom-gizmo';

import * as T from '^/types';

export const TooltipCustomStyle: WrapperHoverableProps['customStyle'] = {
  tooltipTargetStyle: {
    width: '100%',
    height: '100%',
  },
  tooltipBalloonStyle: {
    position: 'absolute',
    left: 'auto',
    right: '33px',
    bottom: '3px',
  },
};

export const TooltipToggleStyle: WrapperHoverableProps['customStyle'] = {
  tooltipTargetStyle: {
    width: '100%',
    height: '100%',
  },
  tooltipBalloonStyle: {
    position: 'absolute',
    left: '51px',
    bottom: '12px',
  },
};

export const ZoomWrapper = styled.div<{ isPreview: boolean }>(({ isPreview }) => ({
  height: 'auto',

  cursor: 'pointer',

  marginTop: '16px',

  boxShadow: palette.insideMap.shadow,
  backdropFilter: 'blur(10px)',

  '> div': {
    position: 'relative',
    width: '100%',
    height: isPreview ? '30px' : '45px',
    backgroundColor: palette.insideMap.gray.toString(),

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: palette.insideMap.hoverGray.toString(),
    },
  },
  '> div + div': {
    borderTopWidth: '0.5px',
    borderTopStyle: 'solid',
    borderTopColor: palette.divider.toString(),
  },
  '> div:first-of-type': {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  '> div:last-of-type': {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  '> div:first-of-type > div > div': {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  '> div:last-of-type > div > div': {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));

export const SVGWrapper = styled.div<{ isSelected?: boolean }>(({ isSelected }) => ({
  width: '100%',
  height: '100%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isSelected ? 'white' : '',
}));

interface Props {
  selectedModelType?: boolean;
  transformControls: ThreeTransformControls;
  isPreviewScreen?: boolean;
}

export const RawTransformControls: FC<Props> = ({
  selectedModelType = false,
  transformControls,
  isPreviewScreen = true,
}) => {
  const [l10n] = useL10n();
  const [selectedControlType, setSelectedControlType] = useState<ThreeTransformControls['mode']>(
    transformControls.mode
  );
  const [isActive, setIsActive] = useState(isPreviewScreen);

  const _selectedModelType =
    selectedModelType ||
    transformControls.object?.name.includes('IFC') ||
    transformControls.object?.name.includes('gltf');

  const handleTranslateClick = () => {
    transformControls.mode = 'translate';
  };
  const handleRotateClick = () => {
    transformControls.mode = 'rotate';
  };
  const handleScaleClick = () => {
    transformControls.mode = 'scale';
  };

  useEffect(() => {
    const handleModeChange = () => {
      if (transformControls.object) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      setSelectedControlType(transformControls.mode);
    };
    transformControls.addEventListener('change', handleModeChange);
    return () => {
      transformControls.removeEventListener('change', handleModeChange);
    };
  }, []);

  if (!isActive) {
    return null;
  }

  return isMobile() ? null : (
    <ZoomWrapper isPreview={isPreviewScreen}>
      <WrapperHoverable
        title={l10n(Text.translate)}
        customStyle={isPreviewScreen ? TooltipCustomStyle : TooltipToggleStyle}
      >
        <SVGWrapper
          data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
          data-ddm-track-label={T.TrackLabels.BTN_TRANSFORM_ROTATE}
          data-testid="btn-transform-rotate"
          onClick={handleTranslateClick}
          isSelected={selectedControlType === 'translate'}
        >
          <TranslateSvg />
        </SVGWrapper>
      </WrapperHoverable>
      <WrapperHoverable
        title={l10n(Text.rotate)}
        customStyle={isPreviewScreen ? TooltipCustomStyle : TooltipToggleStyle}
      >
        <SVGWrapper
          data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
          data-ddm-track-label={T.TrackLabels.BTN_TRANSFORM_ROTATE}
          data-testid="btn-transform-rotate"
          onClick={handleRotateClick}
          isSelected={selectedControlType === 'rotate'}
        >
          <RotateSvg />
        </SVGWrapper>
      </WrapperHoverable>
      {!_selectedModelType && (
        <WrapperHoverable
          title={l10n(Text.scale)}
          customStyle={isPreviewScreen ? TooltipCustomStyle : TooltipToggleStyle}
        >
          <SVGWrapper
            data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
            data-ddm-track-label={T.TrackLabels.BTN_TRANSFORM_SCALE}
            data-testid="btn-transform-scale"
            onClick={handleScaleClick}
            isSelected={selectedControlType === 'scale'}
          >
            <ScaleSvg />
          </SVGWrapper>
        </WrapperHoverable>
      )}
    </ZoomWrapper>
  );
};

interface RawMapControlsProps {
  zoomIn(): void;
  zoomOut(): void;
}
export const RawMapControls: FC<RawMapControlsProps> = ({ zoomIn, zoomOut }) => {
  const [l10n] = useL10n();

  return isMobile() ? null : (
    <ZoomWrapper isPreview={true}>
      <WrapperHoverable title={l10n(Text.zoomIn)} customStyle={TooltipCustomStyle}>
        <SVGWrapper
          data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
          data-ddm-track-label={T.TrackLabels.BTN_TRANSFORM_ROTATE}
          data-testid="btn-transform-rotate"
          onClick={zoomIn}
        >
          <MaximizeSvg />
        </SVGWrapper>
      </WrapperHoverable>
      <WrapperHoverable title={l10n(Text.zoomOut)} customStyle={TooltipCustomStyle}>
        <SVGWrapper
          data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
          data-ddm-track-label={T.TrackLabels.BTN_TRANSFORM_ROTATE}
          data-testid="btn-transform-rotate"
          onClick={zoomOut}
        >
          <MinimizeSvg />
        </SVGWrapper>
      </WrapperHoverable>
    </ZoomWrapper>
  );
};
