import { droneStationAuthStore } from '^/store/droneStationAuthStore';
import { makeDJIAPIURL, makeSearchURL, makeV2APIURL } from '^/store/duck/API';

const djiVersion: string = 'v1';
const deviceVersion: string = 'manage/api';
const waylineVersion: string = 'wayline/api';

export function getLoginUrl() {
  return makeDJIAPIURL(deviceVersion, djiVersion, 'login');
}
export function getDJITokenUrl() {
  return makeV2APIURL('dji_token');
}
export function getLiveSteamStartUrl() {
  return makeDJIAPIURL(deviceVersion, djiVersion, 'live', 'streams', 'start');
}
export function getLiveSteamUpdateUrl() {
  return makeDJIAPIURL(deviceVersion, djiVersion, 'live', 'streams', 'update');
}
export const getDroneStationFetchUrl = () => {
  const { workspaceId } = droneStationAuthStore.getState().authDetails;
  return makeDJIAPIURL(deviceVersion, djiVersion, 'devices', workspaceId, 'devices');
};

interface TGetFlightPlanFetchUrl {
  readonly page: number;
  readonly page_size: number;
  readonly order_by: string;
  readonly favorited: boolean;
}
export const getFlightPlanFetchUrl = ({
  page,
  page_size,
  order_by,
  favorited,
}: TGetFlightPlanFetchUrl) => {
  const { workspaceId } = droneStationAuthStore.getState().authDetails;
  return makeSearchURL(
    makeDJIAPIURL(waylineVersion, djiVersion, 'workspaces', workspaceId, 'waylines'),
    {
      page,
      page_size,
      order_by,
      favorited: String(favorited),
    }
  );
};
export const getFlightPlanUploadUrl = () => {
  const { workspaceId } = droneStationAuthStore.getState().authDetails;
  return makeDJIAPIURL(
    waylineVersion,
    djiVersion,
    'workspaces',
    workspaceId,
    'waylines',
    'file',
    'upload'
  );
};
export const getFlightPlanDeleteUrl = (id: string | number) => {
  const { workspaceId } = droneStationAuthStore.getState().authDetails;
  return makeDJIAPIURL(waylineVersion, djiVersion, 'workspaces', workspaceId, 'waylines', id);
};

interface TGetFlightScheduleFetchUrl {
  readonly page: number;
  readonly page_size: number;
}
export const getFlightScheduleFetchUrl = ({ page, page_size }: TGetFlightScheduleFetchUrl) => {
  const { workspaceId } = droneStationAuthStore.getState().authDetails;
  return makeSearchURL(
    makeDJIAPIURL(waylineVersion, djiVersion, 'workspaces', workspaceId, 'jobs'),
    { page, page_size }
  );
};
export const getFlightSchedulePostUrl = () => {
  const { workspaceId } = droneStationAuthStore.getState().authDetails;
  return makeDJIAPIURL(waylineVersion, djiVersion, 'workspaces', workspaceId, 'flight-tasks');
};
