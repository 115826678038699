import * as THREE from 'three';
import { PointCloudOctree } from '@pnext/three-loader';

import { pointPlusIcon, pointTickIcon, sphereIcon, svgToBase64 } from '^/assets/icons/imageBase64';
import { Vector3 } from 'three';
import proj4 from 'proj4';
import { getEPSGfromProjectionLabel } from '^/utilities/coordinate-util';
import * as T from '^/types';
import { VALUES_PER_METER } from '^/utilities/imperial-unit';
import { useThreeStore } from './ThreeStore';
import CameraControls from 'camera-controls';
import { IFCObject } from './ThreeObjects/Model';

// Calculate total distance between points
export const calculateTotalDistance = (locations: number[][]): number => {
  let total = 0;
  const threePositions: THREE.Vector3[] = locations.map(location => new THREE.Vector3(...location));

  threePositions.forEach((position, index) => {
    const nextIdx = index + 1;
    if (nextIdx < threePositions.length) {
      total += position.distanceTo(threePositions[nextIdx]);
    }
  });

  return total;
};

// Find point between two points at certain distance from first end
export function findPointBetween(A: THREE.Vector3, B: THREE.Vector3, d: number): THREE.Vector3 {
  // Calculate the distance between the two input points.
  const AB = A.distanceTo(B);

  // Calculate the x and y coordinates of the point between A and B at a distance
  // of d from A.
  const x = A.x + (d * (B.x - A.x)) / AB;
  const y = A.y + (d * (B.y - A.y)) / AB;
  const z = A.z + (d * (B.z - A.z)) / AB;

  // Return a new Vector3 object with the calculated coordinates.
  return new THREE.Vector3(x, y, z);
}

export const calculatePCMArea = (threePositions: Vector3[]): number => {
  let area = 0;
  // const threePositions: THREE.Vector3[] = locations.map(location => new THREE.Vector3(...location));
  let j = threePositions.length - 1;

  for (let i = 0; i < threePositions.length; i++) {
    const p1: THREE.Vector3 = threePositions[i];
    const p2: THREE.Vector3 = threePositions[j];
    area += (p2.x + p1.x) * (p1.y - p2.y);
    j = i;
  }

  return Math.abs(area / 2);
};

// Find average vector among vectors
export function findAverage(points: THREE.Vector3[]): THREE.Vector3 {
  // The number of input points.
  const n = points.length;

  // Create a new Vector3 object to store the sum of the input points.
  // Initialize it to the zero vector.
  const sum = new THREE.Vector3();

  // Use a loop to add each point in the array to the sum vector.
  for (let i = 0; i < n; i++) {
    sum.add(points[i]);
  }

  // Divide the sum vector by the number of input points to find the average.
  const average = sum.divideScalar(n);

  // Return the average vector.
  return average;
}

// Get a point at mid distance among various points or edges.
export function getMidPointFromEdges(
  points: THREE.Vector3[],
  totalDistance: number
): THREE.Vector3 {
  const halfPoint = new THREE.Vector3();
  let halfDistance = totalDistance / 2;

  points.every((point, index) => {
    if (index >= points.length - 1) {
      return false;
    }
    const distance = point.distanceTo(points[index + 1]);
    if (distance > halfDistance) {
      halfPoint.copy(findPointBetween(point, points[index + 1], halfDistance));
      return false;
    } else {
      halfDistance -= distance;
      return true;
    }
  });
  return halfPoint;
}

// Translate given points based on mouse drag
export function getTranslatedPoints(
  _scene: THREE.Scene,
  mouseStart: THREE.Vector3,
  mouseEnd: THREE.Vector3,
  points: THREE.Vector3[]
): THREE.Vector3[] | null {
  const translatedVector = new THREE.Vector3().subVectors(mouseEnd, mouseStart);
  const translatedPoints = points.map(point =>
    new THREE.Vector3().addVectors(point, translatedVector)
  );

  return translatedPoints;
}

export function getMousePointCloudIntersection(
  point: any,
  camera: THREE.Camera,
  pointcloud: PointCloudOctree
) {
  const newPoint = new THREE.Vector3(point.position.x, point.position.y, point.position.z);
  let selectedPointcloud = null;
  let closestDistance = Infinity;
  let closestIntersection = null;
  let closestPoint = null;

  const distance = camera.position.distanceTo(newPoint);

  if (distance < closestDistance) {
    closestDistance = distance;
    selectedPointcloud = pointcloud;
    closestIntersection = newPoint;
    closestPoint = point;
  }
  if (selectedPointcloud) {
    return {
      location: closestIntersection,
      distance: closestDistance,
      pointcloud: selectedPointcloud,
      point: closestPoint,
    };
  } else {
    return null;
  }
}

export function getMouseMeshIntersection(camera: THREE.Camera, scene: THREE.Scene) {
  const raycaster: THREE.Raycaster = new THREE.Raycaster();

  const intersectPoint = raycaster.intersectObjects([
    scene.getObjectByName('ground')!,
    // scene.getObjectByName('map')!,
  ]);

  if (intersectPoint[0].object.parent instanceof THREE.Group) {
    if (intersectPoint.length > 2) {
      return intersectPoint[2].point;
    } else {
      return intersectPoint[intersectPoint.length - 1].point;
    }
  }
  return intersectPoint[0].point;
}

export function addCommas(nStr: string) {
  const nString = String(nStr);
  const x = nString.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

export function projectedRadius(
  radius: number,
  camera: THREE.Camera,
  distance: number,
  screenWidth: number,
  screenHeight: number
) {
  if (camera instanceof THREE.OrthographicCamera) {
    return projectedRadiusOrtho(radius, camera.projectionMatrix, screenWidth, screenHeight);
  } else if (camera instanceof THREE.PerspectiveCamera) {
    return projectedRadiusPerspective(radius, (camera.fov * Math.PI) / 180, distance, screenHeight);
  } else {
    throw new Error('invalid parameters');
  }
}

export function projectedRadiusOrtho(
  radius: number | undefined,
  proj: THREE.Matrix4,
  screenWidth: number,
  screenHeight: number
) {
  let p1: THREE.Vector4 | THREE.Vector3 = new THREE.Vector4(0);
  let p2: THREE.Vector4 | THREE.Vector3 = new THREE.Vector4(radius);

  p1 = p1.applyMatrix4(proj);
  p2 = p2.applyMatrix4(proj);
  p1 = new THREE.Vector3(p1.x, p1.y, p1.z);
  p2 = new THREE.Vector3(p2.x, p2.y, p2.z);
  p1.x = (p1.x + 1.0) * 0.5 * screenWidth;
  p1.y = (p1.y + 1.0) * 0.5 * screenHeight;
  p2.x = (p2.x + 1.0) * 0.5 * screenWidth;
  p2.y = (p2.y + 1.0) * 0.5 * screenHeight;
  return p1.distanceTo(p2);
}

export function projectedRadiusPerspective(
  radius: number,
  fov: number,
  distance: number,
  screenHeight: number
) {
  let projFactor = 1 / Math.tan(fov / 2) / distance;
  projFactor = (projFactor * screenHeight) / 2;

  return radius * projFactor;
}

export function loadAllTexture(color: THREE.Color) {
  const textureLoader = new THREE.TextureLoader();
  const defaultTexture = textureLoader.load(sphereIcon);
  const markerTexture = textureLoader.load(svgToBase64(color));
  markerTexture.name = T.ContentType.MARKER;
  markerTexture.userData = { type: T.ContentType.MARKER };

  const plusNodeTexture = textureLoader.load(pointPlusIcon);
  const tickNodeTexture = textureLoader.load(pointTickIcon);
  return { defaultTexture, plusNodeTexture, tickNodeTexture, markerTexture };
}

export function getHeight(positions: Vector3[], unitType: T.ValidUnitType) {
  const sorted = positions.slice().sort((a, b) => a.z - b.z);
  const lowPoint = sorted[0].clone();
  const highPoint = sorted[sorted.length - 1].clone();
  const min = lowPoint.z;
  const max = highPoint.z;
  let height = max - min;
  height *= VALUES_PER_METER[unitType];
  return String(height.toFixed(2));
}

export function getBaseDistance(positions: Vector3[], unitType: T.ValidUnitType) {
  const position0 = positions[0];
  const position1 = positions[1];
  position0.z = position1.z;
  let baseDistance = position0.distanceTo(position1);
  baseDistance *= VALUES_PER_METER[unitType];
  return { baseDistance: baseDistance.toFixed(2) };
}

export function geoPointToPointCloud(
  geoPoint: T.GeoPoint,
  proj: T.ProjectionEnum,
  pc: PointCloudOctree
): { point: THREE.Vector3; insideBoundingBox: boolean } {
  const [lon, lat, alt] = geoPoint;
  let insideBoundingBox = false;

  const offset = pc.pcoGeometry.offset;
  const boundingBox = pc.pcoGeometry.boundingBox;
  const project = getEPSGfromProjectionLabel(proj);
  const [x, y] = proj4(project).forward([lon, lat]);
  const point = new THREE.Vector3(x, y, alt).sub(offset);
  const altitude = point.z < 0 ? offset.z : point.z;
  point.setZ(altitude);
  // don't check z value i.e altitude for insideBoundingBox
  if (boundingBox.containsPoint(point.clone().setZ(offset.z))) {
    insideBoundingBox = true;
  }
  return { point, insideBoundingBox };
}

export function geoPointToPotreeCloud(
  geoPoint: T.GeoPoint,
  proj: T.ProjectionEnum,
  pc: PointCloudOctree
): { point: THREE.Vector3; insideBoundingBox: boolean } {
  const [lon, lat, alt] = geoPoint;
  let insideBoundingBox = false;

  const boundingBox = pc.pcoGeometry.boundingBox;
  const project = getEPSGfromProjectionLabel(proj);
  const [x, y] = proj4(project).forward([lon, lat]);

  const point = new THREE.Vector3(x, y, alt);
  const altitude = point.z;
  point.setZ(altitude);
  // don't check z value i.e altitude for insideBoundingBox
  if (boundingBox.containsPoint(point.clone())) {
    insideBoundingBox = true;
  }
  return { point, insideBoundingBox };
}

export function pointCloudToGeoPoint(
  point: THREE.Vector3,
  proj: T.ProjectionEnum,
  pc: PointCloudOctree
): T.GeoPoint {
  const offset = pc.pcoGeometry.offset;
  const EPSGPoint = point.clone().add(offset);
  const project = getEPSGfromProjectionLabel(proj);
  const [x, y] = proj4(project).inverse([EPSGPoint.x, EPSGPoint.y]);
  return [x, y, EPSGPoint.z];
}

export function potreeToGeoPoint(point: THREE.Vector3, proj: T.ProjectionEnum): T.GeoPoint {
  const EPSGPoint = point.clone();
  const project = getEPSGfromProjectionLabel(proj);
  const [x, y] = proj4(project).inverse([EPSGPoint.x, EPSGPoint.y]);
  return [x, y, EPSGPoint.z];
}

export function geoPointToMesh(
  geoPoint: T.GeoPoint,
  proj: T.ProjectionEnum
): { point: THREE.Vector3 } {
  const [lon, lat, alt] = geoPoint;
  const project = getEPSGfromProjectionLabel(proj);
  const [x, y] = proj4(project).forward([lon, lat]);
  const point = new THREE.Vector3(x, y, alt);
  return { point };
}

export function worldToLatLon(
  point: THREE.Vector3,
  proj: T.ProjectionEnum,
  pc: PointCloudOctree
): [number, number, number] {
  const offset = pc.pcoGeometry.offset;
  const project = getEPSGfromProjectionLabel(proj);

  // Subtract the offset from the point before converting
  const offsetPoint = point.clone().sub(offset);

  const [lon, lat] = proj4(project).inverse([offsetPoint.x, offsetPoint.y]);
  return [lon, lat, offsetPoint.z];
}

export function latLonAltToWorld(
  lat: number,
  lon: number,
  alt: number,
  proj: T.ProjectionEnum
): THREE.Vector3 {
  const project = getEPSGfromProjectionLabel(proj);
  const [x, y] = proj4(project).forward([lon, lat]);
  return new THREE.Vector3(x, y, alt);
}

export function getCenterInteraction(
  camera: THREE.Camera,
  scene: THREE.Scene
): THREE.Intersection | undefined {
  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(new THREE.Vector2(), camera);
  const direction = new THREE.Vector3();
  camera.getWorldDirection(direction);
  raycaster.ray.direction.copy(direction);
  const intersectObjects = scene.getObjectByName('ground');
  return raycaster.intersectObjects(intersectObjects ? [intersectObjects] : [], true)[0];
}
const terrainCollisionRayCaster = new THREE.Raycaster();
export function getGroundPosition(
  position: THREE.Vector3,
  tile: THREE.Object3D
): THREE.Vector3 | null {
  const { x, y, z } = position;
  terrainCollisionRayCaster.set(new THREE.Vector3(x, y, z + 10000000), new THREE.Vector3(0, 0, -1));

  const belowIntersections = terrainCollisionRayCaster.intersectObject(tile);

  if (belowIntersections.length > 0) {
    return belowIntersections[0].point;
  }
  return null;
}

export function moveCameraToViewObject(contentId: string | number) {
  const scene = useThreeStore.getState().scene;
  const cameraControls = useThreeStore.getState().cameraControls;
  const object = scene?.getObjectByProperty('contentId', contentId);
  if (!object) {
    return;
  }

  if (object instanceof IFCObject) {
    if (!object.isLoaded) {
      object.viewer.entities.ifcModelEntity!.contentIdToCenter = object.contentId;
      return;
    }
  }
  void (async function name() {
    await cameraControls?.rotateTo(0, 0, false);
    await cameraControls?.fitToBox(object, true, {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
    });
    await cameraControls?.rotate(45 * THREE.MathUtils.DEG2RAD, 45 * THREE.MathUtils.DEG2RAD, true);
  })();
}

export function removeContentFromScene(contentId: string | number) {
  const { scene } = useThreeStore.getState().viewer!;
  const object = scene.getObjectByProperty('contentId', contentId.toString());
  if (!object) {
    return;
  }
  scene.remove(object);
}

export function centerObjectForView(object: THREE.Object3D, cameraControls: CameraControls | null) {
  // removing transition because camera transition was slow
  void cameraControls?.rotateTo(0, 0, false);
  void cameraControls?.fitToBox(object, false, {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  });
  void cameraControls?.rotate(45 * THREE.MathUtils.DEG2RAD, 45 * THREE.MathUtils.DEG2RAD, false);
}

export function getContentFromScene(
  contentId: string | number
): THREE.Object3D<THREE.Event> | undefined {
  const scene = useThreeStore.getState().viewer?.scene;
  return scene?.getObjectByProperty('contentId', contentId);
}

export function createTextureFromSVG(
  svgContent: string,
  width: number,
  height: number
): THREE.Texture {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  canvas.width = width;
  canvas.height = height;
  const img = new Image();
  img.src = `data:image/svg+xml,${encodeURIComponent(svgContent)}`;
  // img.onload = () => {
  context.drawImage(img, 0, 0, width, height);
  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  return texture;
  // };
  // const emptyTexture = new THREE.Texture();
  // return emptyTexture;
}
