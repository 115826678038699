import { create, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import * as T from '^/types';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Feature } from 'ol';

interface SnappingStore {
  autoOffOldest: boolean;
  snapEnabledLimit: number;
  snapSource: VectorSource;
  showSnapActionBar: boolean;
  snapCoordinates?: Coordinate;
  snapEnabledDxfIds: Array<T.Content['id']>;
  loadedGeoJsonDxfIds: Array<T.Content['id']>;
  setLoadedGeoJsonDxfIds(id: T.Content['id'], deactivate?: boolean): void;
  setSnapEnabledLimit(snapEnabledLimit: number): void;
  setAutoOffOldest(autoOffOldest: boolean): void;
  toggleSnapEnabledDxfIds(id: T.Content['id'], deactivate?: boolean): void;
  setShowSnapActionBar(showSnapActionBar: boolean): void;
}

const initialState = {
  snapEnabledLimit: 2,
  autoOffOldest: true,
  snapEnabledDxfIds: [],
  loadedGeoJsonDxfIds: [],
  showSnapActionBar: false,
  snapCoordinates: undefined,
  snapSource: new VectorSource({ format: new GeoJSON() }),
};

export const snappingStore = create<SnappingStore>()(
  devtools(set => ({
    ...initialState,
    toggleSnapEnabledDxfIds: (id: T.Content['id'], deactivate?: boolean) => {
      set(state => {
        const { snapEnabledDxfIds, snapSource: activeSource, setShowSnapActionBar } = state;
        if (deactivate) {
          activeSource.clear();
          activeSource.changed();
          return { snapEnabledDxfIds: [] };
        }
        if (snapEnabledDxfIds.includes(id)) {
          requestIdleCallback(async () => {
            const allFeatures = activeSource.getFeatures();
            const featuresToRemove = allFeatures.filter(feature => feature.get('dxfId') === id);
            if (featuresToRemove.length > 0) {
              removeFeaturesInBatches(featuresToRemove, 1, activeSource);
            }
          });
          return { snapEnabledDxfIds: snapEnabledDxfIds.filter(existingId => existingId !== id) };
        } else {
          setShowSnapActionBar(true);
          return { snapEnabledDxfIds: [...snapEnabledDxfIds, id] };
        }
      });
    },
    setLoadedGeoJsonDxfIds: (id: T.Content['id'], deactivate?: boolean) => {
      set(state => {
        if (deactivate) {
          return { loadedGeoJsonDxfIds: [] };
        }
        const { loadedGeoJsonDxfIds } = state;
        if (loadedGeoJsonDxfIds.includes(id)) {
          return {
            loadedGeoJsonDxfIds: loadedGeoJsonDxfIds.filter(existingId => existingId !== id),
          };
        } else {
          return { loadedGeoJsonDxfIds: [...loadedGeoJsonDxfIds, id] };
        }
      });
    },
    setSnapEnabledLimit: (snapEnabledLimit: number) => {
      set({ snapEnabledLimit });
    },
    setAutoOffOldest: (autoOffOldest: boolean) => {
      set({ autoOffOldest });
    },
    setShowSnapActionBar: (showSnapActionBar: boolean) => {
      set({ showSnapActionBar });
    },
  }))
);

export function useSnappingStore(): SnappingStore;
export function useSnappingStore<T>(selector: (state: SnappingStore) => T): T;
export function useSnappingStore<T>(selector?: (state: SnappingStore) => T) {
  return useStore(snappingStore, selector!);
}

const removeFeaturesInBatches = (
  featuresToRemove: Feature[],
  batchSize = 1,
  activeSource: VectorSource
) => {
  if (!activeSource) {
    return;
  }
  let index = 0;
  const processBatch = () => {
    const batch = featuresToRemove.slice(index, index + batchSize);
    if (batch.length > 0) {
      activeSource.removeFeature(batch[0]);
      index += batchSize;
      requestIdleCallback(processBatch);
    }
  };
  processBatch();
};
