import { useState, useEffect, useRef, MutableRefObject } from 'react';

export const usePartialVisibility: (
  options?: IntersectionObserverInit
) => [MutableRefObject<null>, boolean] = (options = {}) => {
  const [isPartiallyVisible, setIsPartiallyVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isPartially = entry.intersectionRatio > 0 && entry.intersectionRatio < 1;

        // We only set to true and never back to false to avoid infinite loops
        // when using this state to reposition partially visible elements
        if (isPartially) {
          setIsPartiallyVisible(true);
        }
      },
      { threshold: [0, 1], ...options }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return [ref, isPartiallyVisible];
};
