import React, { useMemo, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import PhotoViewer from '^/components/molecules/PhotoViewer';
import { useL10n } from '^/hooks';
import palette from '^/constants/palette';

import IssueAddPhotosSvg from '^/assets/icons/issue-add-photos.svg';
import RawIssueViewPhotoSvg from '^/assets/icons/issue-view-photo.svg';
import RawIssueDeletePhotoSvg from '^/assets/icons/issue-delete-photo.svg';

import {
  useDeleteIssueAttachment,
  usePostIssueAttachment,
} from '^/store/react-query/issue/attachment';
import * as T from '^/types';

import Text from './text';

function convertAttachmentsToFinalPhotos(attachments: T.IssueAttachment[]): T.FinalPhoto[] {
  return attachments.map(attachment => {
    if (attachment.attachmentType === 'image') {
      return {
        id: attachment.id,
        originalFilename: '',
        imageUrl: attachment.imageUrl ?? '',
        thumbUrl: attachment.imageUrl ?? '',
        fullUrl: attachment.imageUrl ?? '',
        boxThumbUrl: attachment.imageUrl ?? '',
        geotags: null,
        latitude: null,
        longitude: null,
        altitude: null,
        direction: null,
        takenBy: null,
        uploadType: null,
        uploadSource: 'application',
        videoUrl: '',
        videoThumbUrl: '',
        takenAt: null,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
    }

    return {
      id: attachment.id,
      originalFilename: '',
      imageUrl: '',
      thumbUrl: '',
      fullUrl: attachment.videoUrl ?? '',
      boxThumbUrl: attachment.videoThumbnails ?? '',
      geotags: null,
      latitude: null,
      longitude: null,
      altitude: null,
      direction: null,
      takenBy: null,
      uploadType: null,
      uploadSource: 'application',
      videoUrl: attachment.videoUrl ?? '',
      videoThumbUrl: attachment.videoThumbnails ?? '',
      takenAt: null,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  });
}

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  color: '#4D4F53',
  fontSize: '12px',
  fontWeight: 400,
});

const Title = styled.div({
  marginBottom: '6px',
});

const PhotoList = styled.div({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '9px',
});

const photoIconStyle: CSSObject = {
  boxSizing: 'border-box',
  width: '50px',
  height: '50px',
  borderRadius: '5px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const AddPhotoButton = styled.label({
  all: 'unset',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  color: palette.ContentsList.inputBorder.toString(),
  border: `1px solid ${palette.ContentsList.inputBorder.toString()}`,
  cursor: 'pointer',

  ...photoIconStyle,
});

const ViewPhotoButtonRoot = styled.button({
  all: 'unset',
  ...photoIconStyle,
  cursor: 'pointer',
});

const ViewPhotoButtonHoverWrapper = styled.div({
  ...photoIconStyle,
  position: 'absolute',
  zIndex: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',

  opacity: 0,
  '&:hover': {
    opacity: 1,
  },
});

const IssueViewPhotoSvg = styled(RawIssueViewPhotoSvg)({
  position: 'absolute',
  left: '16px',
  top: '19px',
});

const IssueDeletePhotoWrapper = styled.button({
  all: 'unset',
  position: 'absolute',
  right: '6px',
  top: '6px',
  cursor: 'pointer',
});

const IssueDeletePhotoSvg = styled(RawIssueDeletePhotoSvg)({
  position: 'absolute',
  right: '0px',
  top: '0px',
});

const Thumbnail = styled.div({
  ...photoIconStyle,
});

interface ViewPhotoButtonProps {
  imageUrl: T.IssueAttachment['imageUrl'];
  imageId: number;
  onClickView(): void;
  onClickDelete(): void;
}

function ViewPhotoButton({ imageUrl, onClickView, onClickDelete, imageId }: ViewPhotoButtonProps) {
  const styles = useMemo(
    () => ({
      backgroundImage: `url(${imageUrl})`,
    }),
    [Boolean(imageUrl), imageId]
  );

  return (
    <ViewPhotoButtonRoot>
      <ViewPhotoButtonHoverWrapper onClick={onClickView}>
        <IssueViewPhotoSvg />
        <IssueDeletePhotoWrapper
          onClick={event => {
            event.stopPropagation();
            event.nativeEvent.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            onClickDelete();
          }}
        >
          <IssueDeletePhotoSvg />
        </IssueDeletePhotoWrapper>
      </ViewPhotoButtonHoverWrapper>
      <Thumbnail style={styles} />
    </ViewPhotoButtonRoot>
  );
}

const ViewMoreButtonRoot = styled.button({
  all: 'unset',
  ...photoIconStyle,
  cursor: 'pointer',
});

const ViewMoreButtonWrapper = styled.div({
  ...photoIconStyle,
  position: 'absolute',
  zIndex: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: palette.white.toString(),

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  fontSize: '12px',
  fontWeight: 400,
});

interface ViewMoreButtonProps {
  imageUrl: T.IssueAttachment['imageUrl'];
  howMore: number;
  onClick(): void;
}

function ViewMoreButton({ howMore, onClick, imageUrl }: ViewMoreButtonProps) {
  const styles = useMemo(
    () => ({
      backgroundImage: `url(${imageUrl})`,
    }),
    [Boolean(imageUrl)]
  );
  return (
    <ViewMoreButtonRoot onClick={onClick}>
      <ViewMoreButtonWrapper>+{howMore}</ViewMoreButtonWrapper>
      <Thumbnail style={styles} />
    </ViewMoreButtonRoot>
  );
}

interface Props {
  content: T.IssueContent;
}

function IssuePhotoAttachments({ content }: Props) {
  const [l10n] = useL10n();
  const postIssueAttachment = usePostIssueAttachment();
  const deleteIssueAttachment = useDeleteIssueAttachment();

  const [currentAttachmentId, setCurrentAttachmentId] = useState<
    T.IssueAttachment['id'] | undefined
  >(undefined);

  const finalPhotos: T.FinalPhoto[] = convertAttachmentsToFinalPhotos(content.issueAttachments);

  const handleOnSetCurrentPhotoId = (attachmentId?: number) => {
    setCurrentAttachmentId(attachmentId);
  };

  const handleOnDeletePhoto = (attachmentId: T.IssueAttachment['id']) => {
    deleteIssueAttachment({
      issueId: content.id,
      attachmentId,
    });
  };

  const handleOnChangeDate = () => {};

  const handleOnPhotoInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files === null || event.currentTarget.files[0] === undefined) {
      return;
    }

    postIssueAttachment({
      issueId: content.id,
      content_mimetype: event.currentTarget.files[0].type,
      original_filename: event.currentTarget.files[0].name,
      file: event.currentTarget.files[0],
    });
  };

  const firstThumbnail = content.issueAttachments[0] ? (
    <ViewPhotoButton
      imageId={content.issueAttachments[0].id}
      imageUrl={
        content.issueAttachments[0].attachmentType === 'image'
          ? content.issueAttachments[0].imageUrl
          : content.issueAttachments[0].videoThumbnails
      }
      onClickView={() => {
        handleOnSetCurrentPhotoId(content.issueAttachments[0].id);
      }}
      onClickDelete={() => {
        handleOnDeletePhoto(content.issueAttachments[0].id);
      }}
    />
  ) : null;

  const secondThumbnail = content.issueAttachments[1] ? (
    <ViewPhotoButton
      imageId={content.issueAttachments[1].id}
      imageUrl={
        content.issueAttachments[1].attachmentType === 'image'
          ? content.issueAttachments[1].imageUrl
          : content.issueAttachments[1].videoThumbnails
      }
      onClickView={() => {
        handleOnSetCurrentPhotoId(content.issueAttachments[1].id);
      }}
      onClickDelete={() => {
        handleOnDeletePhoto(content.issueAttachments[1].id);
      }}
    />
  ) : null;

  const moreThumbnail = content.issueAttachments[2] ? (
    content.issueAttachments.length > 3 ? (
      <ViewMoreButton
        imageUrl={
          content.issueAttachments[2].attachmentType === 'image'
            ? content.issueAttachments[2].imageUrl
            : content.issueAttachments[2].videoThumbnails
        }
        howMore={content.issueAttachments.length - 2}
        onClick={() => {
          handleOnSetCurrentPhotoId(content.issueAttachments[2].id);
        }}
      />
    ) : (
      <ViewPhotoButton
        imageId={content.issueAttachments[2].id}
        imageUrl={content.issueAttachments[2].imageUrl}
        onClickView={() => {
          handleOnSetCurrentPhotoId(content.issueAttachments[2].id);
        }}
        onClickDelete={() => {
          handleOnDeletePhoto(content.issueAttachments[2].id);
        }}
      />
    )
  ) : null;

  return (
    <Root>
      <Title>{l10n(Text.title)}</Title>
      <PhotoList>
        <AddPhotoButton htmlFor="files">
          <IssueAddPhotosSvg />
          <input
            type="file"
            id="files"
            accept="image/jpeg,image/png,video/mp4"
            multiple={false}
            style={{ display: 'none' }}
            onChange={handleOnPhotoInputChange}
          />
        </AddPhotoButton>
        {firstThumbnail}
        {secondThumbnail}
        {moreThumbnail}
      </PhotoList>
      <PhotoViewer
        isIssueMode={true}
        photos={finalPhotos}
        currentPhotoId={currentAttachmentId}
        onSetCurrentPhotoId={handleOnSetCurrentPhotoId}
        onDelete={() => {
          if (currentAttachmentId !== undefined) {
            handleOnDeletePhoto(currentAttachmentId);
          }
        }}
        onChangeDate={handleOnChangeDate}
      />
    </Root>
  );
}

export default IssuePhotoAttachments;
