import React, { memo } from 'react';
import styled from 'styled-components';

const TextWrapper = styled.div({
  padding: '16px',
  width: '100%',
  background: '#FFF3BA',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
});

const WarningText = styled.span({
  fontSize: '13px',
  color: '#4D4C4C',
});

interface Props {
  readonly warningText: string;
}
function InaccurateMeasurementWarning({ warningText }: Props) {
  return (
    <TextWrapper>
      <WarningText>{warningText}</WarningText>
    </TextWrapper>
  );
}

export default memo(InaccurateMeasurementWarning);
