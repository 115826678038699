import { create } from 'zustand';
import { TimelapseCollectionTypes } from '^/types';
import * as T from '^/types';

export type id = string | number;

export type TimelapseImageType = T.FinalPhoto;

export interface TimelapseCollection {
  id: id;
  name: string;
  longitude: number;
  latitude: number;
  altitude: number;
  yaw: number;
  pitch: number;
  roll: number;
  type: TimelapseCollectionTypes;
  photoCount: number;
  thumbnailUrl?: string;
}

export interface GetTimelapseCollectionsResponse {
  readonly data: TimelapseCollection[];
  readonly status?: number;
  readonly info: TimelapseInfo | null;
}

export interface DownloadInfo {
  url: string;
  photoIds: Array<TimelapseImageType['id']>;
}

export interface TimelapseInfo {
  download: DownloadInfo[];
}

export interface GetTimelapseImagesResponse {
  readonly data: {
    readonly photos: TimelapseImageType[];
    readonly info: TimelapseInfo | null;
  };
  readonly status?: number;
}

export interface PostBulkPhotosEditResponse {
  readonly data: TimelapseImageType[];
  readonly status?: number;
}

export interface TimelapseDownloadResponse {
  readonly video_url: string;
}

export enum TIMELAPSE_IMAGE_TYPE {
  TIMELAPSE = 'timelapse',
  EXCLUDED = 'excluded',
}

export interface TimelapseStore {
  selectedCollectionId?: id;

  selectedState?: TIMELAPSE_IMAGE_TYPE;

  timelapseCollections: TimelapseCollection[];
  timelapseInspectionImages: TimelapseImageType[];
  timelapseInspectionImagesInfo: TimelapseInfo | null;
  timelapseImages: TimelapseImageType[];

  collectionFilter: TimelapseCollectionTypes;
  photoFilter: T.PhotoAlbumFilterTypes;
  photoCollectionFilter: T.PhotoCollectionFilterTypes;

  lastSelectedPhotoId?: TimelapseImageType['id'];
  setlastSelectedPhotoId(value?: TimelapseImageType['id']): void;

  setSelectedState(value?: TIMELAPSE_IMAGE_TYPE): void;

  setTimelapseCollections(value: TimelapseCollection[]): void;
  setTimelapseImages(value: TimelapseImageType[]): void;
  setTimelapseInspectionImages(value: TimelapseImageType[]): void;
  setTimelapseInspectionImagesInfo(value: TimelapseInfo | null): void;
  setSelectedCollectionId(value?: TimelapseCollection['id']): void;

  setCollectionFilter(value: TimelapseCollectionTypes): void;
  setPhotoFilter(value: T.PhotoAlbumFilterTypes): void;
  setPhotoCollectionFilter(value: T.PhotoCollectionFilterTypes): void;

  // loading states
  getTimelapseCollectionsStatus: T.APIStatus;
  setTimelapseCollectionsStatus(value: T.APIStatus): void;

  getTimelapseInspectionImagesStatus: T.APIStatus;
  setTimelapseInspectionImagesStatus(value: T.APIStatus): void;

  getTimelapseDownloadStatus: T.APIStatus;
  setTimelapseDownloadStatus(value: T.APIStatus): void;
}

const initialState = {
  timelapseImages: [],
  timelapseCollections: [],
  timelapseInspectionImages: [],
  collectionFilter: TimelapseCollectionTypes.BIRD_EYE_VIEW,
  photoFilter: T.PhotoAlbumFilterTypes.ALL,
  photoCollectionFilter: T.PhotoCollectionFilterTypes.ALL,
  timelapseInspectionImagesInfo: null,

  // loading states
  getTimelapseDownloadStatus: T.APIStatus.IDLE,
  getTimelapseCollectionsStatus: T.APIStatus.PROGRESS,
  getTimelapseInspectionImagesStatus: T.APIStatus.PROGRESS,
};

export const useTimelapseStore = create<TimelapseStore>()(set => ({
  ...initialState,

  setSelectedState: (value: TIMELAPSE_IMAGE_TYPE) => set(() => ({ selectedState: value })),

  setTimelapseCollections: value => set(() => ({ timelapseCollections: value })),
  setTimelapseInspectionImages: value => set(() => ({ timelapseInspectionImages: value })),
  setTimelapseInspectionImagesInfo: value => set(() => ({ timelapseInspectionImagesInfo: value })),

  setSelectedCollectionId: value => set(() => ({ selectedCollectionId: value })),

  setCollectionFilter: value => set(() => ({ collectionFilter: value })),
  setPhotoFilter: value => set(() => ({ photoFilter: value })),
  setPhotoCollectionFilter(photoCollectionFilter) {
    set({ photoCollectionFilter });
  },
  setTimelapseImages: value => set(() => ({ timelapseImages: value })),

  setlastSelectedPhotoId: value => set(() => ({ lastSelectedPhotoId: value })),

  // loading states
  setTimelapseCollectionsStatus: value => set(() => ({ getTimelapseCollectionsStatus: value })),
  setTimelapseInspectionImagesStatus: value =>
    set(() => ({ getTimelapseInspectionImagesStatus: value })),

  setTimelapseDownloadStatus: value => set(() => ({ getTimelapseDownloadStatus: value })),
}));
