import React, { ChangeEvent, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { DDMInput } from '^/components/atoms/DDMInput';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';

const TextLabel = styled.p({
  fontSize: '14px',
  fontWeight: 'bold',

  color: dsPalette.title.toString(),
});

const InputWrapper = styled.div({
  width: '100%',
  height: '37px',

  color: dsPalette.title.toString(),
});

const ErrorMessage = styled.p({
  marginTop: '4px',

  fontSize: '14px',
  lineHeight: 1.3,
  color: palette.error.toString(),
});

export interface Props {
  label: string;
  placeHolder: string;
  hasError?: boolean;
  showError?: boolean;
  customInputStyles?: CSSObject;
  title: string;
  setTitle(title: string): void;
}

const TextField = (props: Props) => {
  const { label, placeHolder, title, hasError, showError, setTitle, customInputStyles } = props;

  const onInputChange: (e: ChangeEvent<HTMLInputElement>) => void = e => {
    setTitle(e.currentTarget.value);
  };

  const rawErrorComponent: ReactNode =
    hasError && showError ? <ErrorMessage>Name is too short.</ErrorMessage> : null;

  return (
    <>
      <TextLabel>{label}</TextLabel>
      <InputWrapper>
        <DDMInput
          customInputStyles={customInputStyles}
          value={title}
          placeholder={placeHolder}
          hasError={hasError}
          onChange={onInputChange}
        />
      </InputWrapper>
      {rawErrorComponent}
    </>
  );
};

export default TextField;
