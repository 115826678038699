import { isESSContent } from '^/hooks';
import { AuthHeader, makeV2APIURL } from '^/store/duck/API';
import * as T from '^/types';
import axios, { AxiosResponse } from 'axios';

export async function updateContentSelectedAtInServer(
  contents: T.Content[],
  authHeader: AuthHeader | undefined,
  projectId: number | undefined,
  selectedAt?: any
): Promise<void[]> {
  if (authHeader === undefined || projectId === undefined) {
    return [];
  }

  let mainContents: Record<string, { config: string }> = {};
  const request: Array<Promise<void>> = [];

  contents.forEach(async content => {
    // ESS contents will only update the selectedAt locally,
    // since the requirement is to always hide the ESS contents
    // when loading the map. Therefore, skipping it.
    if (isESSContent(content)) {
      return;
    }

    mainContents = {
      ...mainContents,
      [content.id]: { config: JSON.stringify({ ...content.config, selectedAt }) },
    };
  });

  if (Object.keys(mainContents).length) {
    const URL = makeV2APIURL('projects', projectId, 'contents');
    const res: AxiosResponse = await axios.patch(
      URL,
      { contents: mainContents },
      {
        headers: { Authorization: authHeader.Authorization },
      }
    );

    request.push(res.data.data);
  }
  return Promise.all(request);
}
