import OlMap from 'ol/Map';
import BaseEvent from 'ol/events/Event';
import { defaults as defaultInteractions } from 'ol/interaction';
import { fromLonLat } from 'ol/proj';
import React, {
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StyledComponent } from 'styled-components';

import { RightDragRotate } from '^/components/ol/OlCustomInteractions/rightDragRotate';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import View from 'ol/View';
import { defaultMapZoom } from '^/constants/defaultContent';

const preventOpenContextMenuFromClicking: (e: BaseEvent) => boolean = e => {
  e.preventDefault();

  return false;
};

export type MapTargetComponent = StyledComponent<'div', {}>;

export interface Props {
  readonly MapTarget: MapTargetComponent;
  readonly redraw?: number;
}

export interface State {
  readonly map: OlMap;
}

export function OlMapProviderMinimap({
  MapTarget,
  children,
}: PropsWithChildren<Props>): ReactElement {
  const { photoLongLat } = useThreePhotoAlbumStore(state => state.threeSixtyMiniMapDetails);
  const setThreeSixtyMiniMapDetails = useThreePhotoAlbumStore(
    state => state.setThreeSixtyMiniMapDetails
  );

  const mapTarget: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [olMap] = useState(() => {
    const interactions = defaultInteractions({
      altShiftDragRotate: false,
      doubleClickZoom: false,
    }).extend([new RightDragRotate()]);

    return new OlMap({
      view: new View({
        zoom: 16,
      }),
      controls: [],
      interactions,
    });
  });

  useEffect(() => {
    if (mapTarget.current && photoLongLat) {
      const viewDetails = olMap.getView();
      const newCenter = fromLonLat(photoLongLat);
      const zoom = viewDetails.getZoom() ?? defaultMapZoom;

      viewDetails.animate(
        { zoom: zoom - 1, duration: 500 },
        { center: newCenter, duration: 500 },
        { zoom: zoom, duration: 500 }
      );
    }
  }, [photoLongLat]);

  /**
   * @ebraj-angelswing
   * This is only for the setting up the initial location...
   */
  useEffect(() => {
    if (mapTarget.current && photoLongLat) {
      const viewDetails = olMap.getView();
      const hasCenter: boolean = Boolean(viewDetails.getCenter());
      const initialCenter = fromLonLat(photoLongLat);
      if (!hasCenter) {
        viewDetails.setCenter(initialCenter);
      }
    }
  }, [mapTarget.current, photoLongLat]);

  useEffect(() => {
    if (mapTarget.current) {
      olMap?.setTarget(mapTarget.current);
    }

    olMap.addEventListener('contextmenu', preventOpenContextMenuFromClicking);

    return () => {
      olMap?.removeEventListener('contextmenu', preventOpenContextMenuFromClicking);
    };
  }, []);

  useEffect(() => {
    if (olMap) {
      setThreeSixtyMiniMapDetails({
        miniMap2D: olMap,
      });
    }
  }, []);

  return <MapTarget ref={mapTarget}>{children}</MapTarget>;
}
