// import Color from 'color';
import React, { FC } from 'react';

export const FastForwardIcon: FC = () => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 8V12L15 6L6 0V4L0 0V12L6 8Z" fill="white" />
  </svg>
);

export const RewindIcon: FC = () => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12L0 6L9 0V4L15 0L15 12L9 8V12Z"
      fill="white"
    />
  </svg>
);

export const PlayIcon: FC<{ width?: number; height?: number }> = ({ width, height }) => (
  <svg
    width={width || 9}
    height={height || 12}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 6L0 0V12L9 6Z" fill="white" />
  </svg>
);

export const PauseIcon: FC<{ width?: number; height?: number }> = ({ width, height }) => (
  <svg
    width={width || 9}
    height={height || 12}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M3 0H0V12H3V0ZM9 0H6V12H9V0Z" fill="white" />
  </svg>
);

export const VolumeIcon: FC = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0L2.64286 5H0V9H2.64286L8 14V0ZM15.214 7.00016C15.214 10.3868 12.809 13.2117 9.61377 
      13.8602V12.4238C12.0292 11.8022 13.814 9.60961 13.814 7.00016C13.814 4.3907 12.0292 2.1981 
      9.61377 1.57652V0.140137C12.809 0.788627 15.214 3.61354 15.214 7.00016ZM12.4146 7.00006C12.4146 
      8.82907 11.2455 10.385 9.61377 10.9614V9.42594C10.4511 8.94192 11.0146 8.03679 11.0146 7.00006C11.0146 
      5.96334 10.4511 5.05821 9.61377 4.57419V3.03877C11.2455 3.61511 12.4146 5.17106 12.4146 7.00006Z"
      fill="white"
    />
  </svg>
);

export const PlayBackRate: FC = () => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9998 6.66667C19.9998 10.3486 17.0151 13.3333 13.3332 13.3333C9.65127 13.3333 6.6665 
      10.3486 6.6665 6.66667C6.6665 2.98477 9.65127 0 13.3332 0C17.0151 0 19.9998 2.98477 19.9998 
      6.66667ZM13.9397 6.06068V3.63623H12.7275V7.27259H13.3335V7.2728H17.5759V6.06068H13.9397ZM2.42383 
      2.42432H6.06019V3.63644H2.42383V2.42432ZM2.42383 9.69678H6.06019V10.9089H2.42383V9.69678ZM0 
      4.84863H4.84848V6.06075H0V4.84863ZM4.84848 7.27246H0V8.48458H4.84848V7.27246Z"
      fill="white"
    />
  </svg>
);

export const FullScreenMode: FC = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0H0V5H2V2H5V0ZM8 13H13V8H11V11H8V13ZM0 13V8H2L2 11H5V13H0ZM13 5L13 0H8V2L11 2L11 5L13 5Z"
      fill="white"
    />
  </svg>
);

export const ExitFullScreenMode: FC = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5H5V4L5 0H3L3 3L0 3V5L4 5ZM9 8H8V9V13H10V10H13V8H9ZM5 8V9V13H3V10H0V8H4H5ZM8 4V5H9H13V3L10 3V0H8V4Z"
      fill="white"
    />
  </svg>
);

export const MuteIcon: FC = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9116 13.1898L15 14.9997L15.9824 13.8662L0.982422 0.866211L2.6077e-05 
      1.99974L3.59246 5.11319L2.64258 6H0V10H2.64258L8 15V8.93305L9.67796 10.3873C9.65659 
      10.4004 9.63503 10.4132 9.61328 10.4258V11.9609C10.0331 11.8129 10.4229 11.5997 10.7699 
      11.3336L11.8441 12.2646C11.2074 12.8068 10.4478 13.2091 9.61328 13.4238V14.8604C10.8672 
      14.606 11.9988 14.0165 12.9116 13.1898ZM15.2148 8C15.2148 9.04102 14.9883 10.0293 14.5801 
      10.917L13.4648 9.95117C13.6914 9.34375 13.8145 8.68652 13.8145 8C13.8145 5.39062 12.0293 
      3.19824 9.61328 2.57617V1.14062C12.8086 1.78906 15.2148 4.61328 15.2148 8ZM12.4141 8C12.4141 
      8.32617 12.377 8.64258 12.3066 8.94727L11.0078 7.82227C10.9473 6.86035 10.4023 6.03027 9.61328 
      5.57422V4.03906C11.2461 4.61523 12.4141 6.1709 12.4141 8ZM8 1V5.21484L5.6582 3.18555L8 1Z"
      fill="white"
    />
  </svg>
);
