import React, { FC } from 'react';
import styled, { css, CSSObject, Keyframes, keyframes } from 'styled-components';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';

interface LoadingDivProps {
  isDownloading?: boolean;
}

const rotationAnimation: Keyframes = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `;

const ToastWrapper = styled.div({
  // display: 'flex',
  // position: 'absolute',
  background: palette.white.alpha(0.9).toString(),
  borderRadius: '5px',
  height: '110px',
  width: '110px',
  paddingTop: '44px',
  paddingBottom: '29px',
  // alignItems: 'center',
  // gap: '20px',
});
const LoadingDiv = styled.div<LoadingDivProps>`
  border: ${props =>
    props.isDownloading ? '3px solid #8aade2' : `3px solid ${dsPalette.grey40.toString()}`};
  border-top: 3px solid var(--color-theme-primary);
  border-radius: 50%;
  width: 110px;
  height: 110px;
  transform-origin: center;
  animation: ${css`
      ${rotationAnimation}`} 1s infinite;
`;

export interface Props {
  readonly loadingDivCustomStyle?: CSSObject;
  readonly isDownloading?: boolean;
}

const DownloadProgressDisplay: FC<Props> = ({ loadingDivCustomStyle, isDownloading }) => (
  <ToastWrapper>
    <LoadingDiv
      isDownloading={isDownloading}
      data-testid="loading-button"
      style={loadingDivCustomStyle}
    />
  </ToastWrapper>
);
/**
 * Never update loading icon again because there is no point of doing that
 * But there would be a problem if you are trying to change the custom style
 * dynamically
 */
export default DownloadProgressDisplay;
