import * as T from '^/types';

export type IsUserRoleFunction = (role: T.UserRole) => boolean;
export type isUserRoleFunctor = (
  roleToCheckAgainst: T.UserRole | T.UserRole[]
) => IsUserRoleFunction;

/**
 * Check if the user role is one of 'roleToCheckAgainst'(s)
 */
export const isUserRoleFunctor: isUserRoleFunctor = roleToCheckAgainst => role =>
  Array.isArray(roleToCheckAgainst)
    ? roleToCheckAgainst.includes(role)
    : roleToCheckAgainst === role;

export type isOneOfUserRoleFunctor = (roleFunctors: IsUserRoleFunction[]) => IsUserRoleFunction;
export const isOneOfUserRoleFunctor: isOneOfUserRoleFunctor = roleFunctors => role =>
  roleFunctors.some(fn => fn(role));

export const isUserRoleAdmin: IsUserRoleFunction = isUserRoleFunctor(T.UserRole.ADMIN);
export const isUserRolePro: IsUserRoleFunction = isUserRoleFunctor(T.UserRole.PRO);
export const isUserRoleBasic: IsUserRoleFunction = isUserRoleFunctor(T.UserRole.BASIC);
export const isUserRoleDummy: IsUserRoleFunction = isUserRoleFunctor(T.UserRole.DUMMY);
export const isUserRolePremium: IsUserRoleFunction = isUserRoleFunctor(T.UserRole.PREMIUM);
export const isUserRoleMaster: IsUserRoleFunction = isUserRoleFunctor(T.UserRole.MASTER);

const isUserRoleAdminOrProFunctor: IsUserRoleFunction = isOneOfUserRoleFunctor([
  isUserRoleAdmin,
  isUserRolePro,
]);

export {
  //   isUserRoleAdmin as isAllowUserCreate,
  isUserRoleAdminOrProFunctor as isUserAllowToCreateAndDeleteProject,
};
