import React, {
  FC,
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import styled from 'styled-components';

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import {
  UseInput,
  UseL10n,
  UseState,
  useClickOutside,
  useInput,
  useL10n,
  usePrevProps,
} from '^/hooks';
import * as T from '^/types';

import Text from './text';
import useIssueContents from '^/hooks/useIssueContents';

const Root = styled.div({
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const Description = styled.textarea({
  boxSizing: 'border-box',

  width: '100%',
  height: '24px',

  marginTop: '10px',
  paddingTop: '3px',
  paddingBottom: '3px',
  paddingLeft: '10px',
  paddingRight: '10px',

  whiteSpace: 'pre-wrap',

  borderRadius: '5px',
  border: `1px solid ${palette.ContentsList.inputBorder.toString()}`,

  fontSize: '8px',
  color: dsPalette.title.toString(),

  '::placeholder': {
    color: palette.dividerLight.toString(),
  },

  ':hover': {
    border: `solid 1px ${palette.ContentsList.hoverInputBorder.toString()}`,
  },

  ':focus': {
    border: `solid 1px ${dsPalette.title.toString()}`,
  },
});

interface Props {
  readonly content: T.IssueContent;
}

const ContentAttachments: FC<Props> = ({ content }) => {
  const descRef: MutableRefObject<HTMLTextAreaElement | null> = useRef(null);
  const [l10n]: UseL10n = useL10n();

  const [description, onDescriptionChange, manuallySetDescription]: UseInput<string> = useInput(
    content.description
  );
  const [isDescriptionEditing, setIsDescriptionEditing]: UseState<boolean> =
    useState<boolean>(false);
  const prevDescriptionEditing: boolean | undefined = usePrevProps(isDescriptionEditing);

  const onDescriptionFocus: () => void = () => {
    setIsDescriptionEditing((prevState: boolean) => !prevState);
  };

  const onDescriptionKeyPress: (e: KeyboardEvent<HTMLTextAreaElement>) => void = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      setIsDescriptionEditing(false);
      descRef.current?.blur();
    }
  };

  const { patchIssueContent } = useIssueContents();

  const handleDescriptionSave: () => void = useCallback(() => {
    if (description !== content.description) {
      patchIssueContent({
        content: {
          id: content.id,
          description,
        },
      });
    }
  }, [content.type, description, content.description]);

  useClickOutside<HTMLTextAreaElement>({
    ref: descRef,
    callback: () => {
      if (isDescriptionEditing) {
        setIsDescriptionEditing(false);
      }
    },
  });

  useEffect(() => {
    if (prevDescriptionEditing && !isDescriptionEditing) {
      handleDescriptionSave();
    }
  }, [prevDescriptionEditing, isDescriptionEditing]);

  useEffect(
    () => () => {
      if (descRef.current && descRef.current.value !== content.description) {
        patchIssueContent({
          content: {
            id: content.id,
            description: descRef.current.value,
          },
        });
      }
    },
    [descRef.current]
  );

  useEffect(() => {
    if (description !== content.description) {
      manuallySetDescription(content.description);
    }
  }, [content.description]);

  return (
    <Root>
      <Description
        ref={descRef}
        value={description}
        placeholder={l10n(Text.placeholder)}
        onChange={onDescriptionChange}
        onFocus={onDescriptionFocus}
        onKeyPress={onDescriptionKeyPress}
      />
    </Root>
  );
};

export default memo(ContentAttachments);
