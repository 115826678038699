import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { config } from '^/config';
import { attachmentsStore } from '^/store/attachmentsStore';
import { AuthHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { FinishPatchUserInfo } from '^/store/duck/Users';
import { ERROR } from '^/store/react-query/types';
import { getRequestErrorType } from '^/store/react-query/utilities/API';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { uploadFileToGCS } from '^/utilities/gcs-upload';
import { useDispatch, useStore } from 'react-redux';
import { useIssueStore } from '^/store/issue';

export interface PostIssueAttachmentBody {
  content_mimetype: string;
  original_filename: string;
}

export interface PostIssueAttachmentParameter extends PostIssueAttachmentBody {
  issueId: T.IssueContent['id'];
  file: File;
}

export interface PostIssueAttachmentResponse {
  issue_attachment_id: number;
  url: string;
  fields: {
    key: string;
    success_action_status: string;
    acl: string;
    policy: string;
    'x-amz-credential': string;
    'x-amz-algorithm': string;
    'x-amz-date': string;
    'x-amz-signature': string;
  };
  'signer-url': string;
  region: string;
  bucket: string;
  'aws-id': string;
  key: string;
}

export interface PostUploadCompleteResponse {
  data: T.IssueAttachment;
}

const postIssueAttachmentFn =
  (state: T.State) =>
  async ({ issueId, content_mimetype, original_filename, file }: PostIssueAttachmentParameter) => {
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );

    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    const metaUrl = makeV2APIURL('issues', issueId, 'issue_attachments');
    const metaBody: PostIssueAttachmentBody = {
      content_mimetype,
      original_filename,
    };

    try {
      const metaResponse = await http.post<PostIssueAttachmentResponse>(metaUrl, metaBody, {
        headers,
      });

      switch (config.region) {
        case T.Region.KSA: {
          await uploadFileToGCS(metaResponse.data.url, file);
          attachmentsStore.getState().resetAttachmentUploadStatus();
          break;
        }
        case T.Region.DEFAULT:
        default: {
          const uploadFormData = new FormData();
          uploadFormData.append('key', metaResponse.data.key);
          uploadFormData.append(
            'success_action_status',
            metaResponse.data.fields.success_action_status
          );
          uploadFormData.append('acl', metaResponse.data.fields.acl);
          uploadFormData.append('policy', metaResponse.data.fields.policy);
          uploadFormData.append('x-amz-credential', metaResponse.data.fields['x-amz-credential']);
          uploadFormData.append('x-amz-algorithm', metaResponse.data.fields['x-amz-algorithm']);
          uploadFormData.append('x-amz-date', metaResponse.data.fields['x-amz-date']);
          uploadFormData.append('x-amz-signature', metaResponse.data.fields['x-amz-signature']);
          uploadFormData.append('file', file);

          await axios.post(metaResponse.data.url, uploadFormData);
        }
      }

      const finishUrl = makeV2APIURL(
        'issue_attachments',
        metaResponse.data.issue_attachment_id,
        'upload_complete'
      );

      const finalResponse = await http.put<PostUploadCompleteResponse>(finishUrl, null, {
        headers,
      });
      const finalAttachment = finalResponse.data.data;

      return {
        issueId,
        attachment: finalAttachment,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(ERROR.OtherError);
    }
  };

export const usePostIssueAttachment = () => {
  const dispatch = useDispatch();
  const { setIssuesContentsById, issuesContentsById } = useIssueStore();
  const state = useStore().getState();
  const { mutate } = useMutation(postIssueAttachmentFn(state), {
    onSuccess(result) {
      const oldIssueContent = issuesContentsById[result.issueId];
      const updatedIssue = {
        ...oldIssueContent,
        issueAttachments: [...oldIssueContent.issueAttachments, result.attachment],
      };
      setIssuesContentsById({ ...issuesContentsById, [oldIssueContent.id]: updatedIssue });
    },
    onError(error: AxiosError) {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      } else {
        dispatch(
          FinishPatchUserInfo({
            error: getRequestErrorType({ status: error.response?.status } as any),
          })
        );
      }
    },
  });

  return mutate;
};

/**
 * TODO
 * Add useDeleteIssueAttachment
 */
export interface DeleteIssueAttachmentParameter {
  issueId: T.IssueContent['id'];
  attachmentId: T.IssueAttachment['id'];
}

export const deleteIssueAttachmentFn =
  (state: T.State) =>
  async ({ issueId, attachmentId }: DeleteIssueAttachmentParameter) => {
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );

    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    const url = makeV2APIURL('issue_attachments', attachmentId);

    try {
      await http.delete(url, { headers });
      return { issueId, attachmentId };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(ERROR.OtherError);
    }
  };

export const useDeleteIssueAttachment = () => {
  const dispatch = useDispatch();
  const state = useStore().getState();
  const { setIssuesContentsById, issuesContentsById } = useIssueStore();
  const { mutate } = useMutation(deleteIssueAttachmentFn(state), {
    onSuccess(result) {
      const oldIssueContent = issuesContentsById[result.issueId];
      const updatedIssue = {
        ...oldIssueContent,
        issueAttachments: oldIssueContent.issueAttachments.filter(
          attachment => attachment.id !== result.attachmentId
        ),
      };
      setIssuesContentsById({
        ...issuesContentsById,
        [oldIssueContent.id]: updatedIssue,
      });
    },
    onError(error: AxiosError) {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      } else {
        dispatch(
          FinishPatchUserInfo({
            error: getRequestErrorType({ status: error.response?.status } as any),
          })
        );
      }
    },
  });

  return mutate;
};
