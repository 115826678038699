import React, { FC, memo, MouseEvent, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { CSSObject } from 'styled-components';
import { useDispatch } from 'react-redux';

import SearchSVG from '^/assets/icons/search.svg';
import FilterSVG from '^/assets/icons/filter.svg';
import CloseSVG from '^/assets/icons/close-gray.svg';
import CheckSvg from '^/assets/icons/contents-list/check.svg';
import UncheckSvg from '^/assets/icons/contents-list/uncheck.svg';
import * as T from '^/types';
import { ApplyFilter } from '^/store/duck/Contents';
import text, { filterContentTexts, placeholderText } from './text';
import { useClickOutside, useL10n } from '^/hooks';
import { ContentFilterIcon } from './ContentFilterIcon';
import { useContentsFilter } from './context';
import { useESSContentsFilter } from './essContext';
import { useESSContentsStore } from '^/store/essContentsStore';

const FILTER_Z_INDEX = 900;
const Root = styled.div({
  padding: '0rem 0.75rem 0.5rem 1.25rem',
});

const SearchBox = styled.div({
  border: '1px solid #D9D9D9',
  height: '1.75rem',
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.5rem',
  gap: '5px',
});
const Input = styled.input({
  flexGrow: 1,
  fontWeight: 400,
  fontSize: 13,
  color: '#4D4C4C',
  backgroundColor: 'transparent',
  '::-webkit-input-placeholder': {
    color: '#B5B5B5',
  },
});

const VerticalDivider = styled.div({
  width: 1,
  height: '80%',
  backgroundColor: '#D9D9D9',
});

const iconStyles: CSSObject = {
  height: 20,
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: 'transparent',
};
const SearchIconWrapper = styled.div(iconStyles);
const IconWrapper = styled.button<{ isActive?: boolean }>(({ isActive }) => ({
  ...iconStyles,
  cursor: 'pointer',
  backgroundColor: isActive ? '#CED7E5' : 'transparent',
  ':hover': {
    backgroundColor: '#CED7E5',
  },
}));
const FilterWrapper = styled.div({
  position: 'relative',
});
const FilterPortalWrapper = styled.div({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: FILTER_Z_INDEX,
  top: 0,
});

const FilterRoot = styled.div({
  position: 'fixed',
  zIndex: FILTER_Z_INDEX,
  backgroundColor: '#fff',
  minWidth: '164px',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  padding: '5px 0',
  borderRadius: '4px',
});
const FilterItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '9px 13px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#E5E5E5',
    color: 'red',
  },
  ':hover svg': {
    color: '#8d8d8d',
  },
  [`:hover ${IconWrapper}`]: {
    backgroundColor: '#CED7E5',
  },
});
const FilterName = styled.div({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '15px',
  color: '#4D4C4C',
});
const Result = styled.p({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  color: '#8C8C8C',
  paddingTop: '1rem',
});

interface Props {
  isESS?: boolean;
}

const ContentsSearchBox: FC<Props> = props => {
  const inputRef = useRef<HTMLInputElement>(null);
  const filterPopDiv = useRef<HTMLDivElement>(document.createElement('div'));
  const [filterInputValue, setFilterInputValue] = useState<string>('');
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [l10n] = useL10n();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const { selectedFilterContents, filterText, filterType, contentTypes, count } = props.isESS
    ? useESSContentsFilter()
    : useContentsFilter();
  const filterPopRef = useRef<HTMLDivElement>(null);
  const setContentFilters = useESSContentsStore(s => s.setContentFilters);
  const isESS =
    filterType === T.ContentsListType.OPEN_ESS || filterType === T.ContentsListType.PERSONAL_ESS;

  // reset filter on sidebar tab change
  useEffect(
    () => () => {
      if (filterType && contentTypes) {
        if (isESS) {
          // ess
          const contentFilters = {
            [filterType]: {
              filterText: '',
              filterContents: contentTypes,
            },
          };
          setContentFilters(contentFilters);
        } else {
          dispatch(
            ApplyFilter({
              filterType,
              filterText: '',
              filterContents: contentTypes,
            })
          );
        }
      }
    },
    []
  );

  useClickOutside({
    ref: filterPopRef,
    callback() {
      if (filterPopRef.current) {
        setIsFilterOpen(false);
        inputRef.current?.focus();
      }
    },
  });

  // filterType and contentTypes are mandatory
  // But, these are undefined on default useContext values
  if (!filterType || !contentTypes) {
    return null;
  }

  useEffect(() => {
    if (isESS) {
      // save in ess store
      const contentFilters = {
        [filterType]: {
          filterText: filterInputValue.trimStart(),
          filterContents: selectedFilterContents,
        },
      };

      setContentFilters(contentFilters);
    } else {
      dispatch(
        ApplyFilter({
          filterType,
          filterText: filterInputValue.trimStart(),
          filterContents: selectedFilterContents,
        })
      );
    }
  }, [filterInputValue]);

  const handleFilterIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    setIsFilterOpen(_isFilterOpen => !_isFilterOpen);
    const { x, y, height } = event.currentTarget.getBoundingClientRect();
    setPosition({ x, y: y + height + 10 });
  };

  const handleCloseIconClick = () => {
    if (isESS) {
      // ess
      const contentFilters = {
        [filterType]: {
          filterText: '',
          filterContents: contentTypes,
        },
      };
      setContentFilters(contentFilters);
    } else {
      dispatch(ApplyFilter({ filterType, filterText: '', filterContents: contentTypes }));
    }
    setIsFilterOpen(false);
    setFilterInputValue('');
    inputRef.current?.focus();
  };

  const handleFilterItemClick = (name?: T.ContentType) => () => {
    if (name) {
      const newFilterContents = selectedFilterContents.includes(name)
        ? selectedFilterContents.filter(type => type !== name)
        : [...selectedFilterContents, name];
      if (isESS) {
        // ess
        const contentFilters = {
          [filterType]: {
            filterText: filterInputValue.trimStart(),
            filterContents: newFilterContents,
          },
        };
        setContentFilters(contentFilters);
      } else {
        dispatch(
          ApplyFilter({
            filterType,
            filterText: filterInputValue.trimStart(),
            filterContents: newFilterContents,
          })
        );
      }
    } else {
      if (selectedFilterContents.length === contentTypes.length) {
        if (isESS) {
          // ess
          const contentFilters = {
            [filterType]: {
              filterText: filterInputValue.trimStart(),
              filterContents: [],
            },
          };
          setContentFilters(contentFilters);
        } else {
          dispatch(
            ApplyFilter({
              filterType,
              filterText: filterInputValue.trimStart(),
              filterContents: [],
            })
          );
        }
      } else {
        if (isESS) {
          // esss
          const contentFilters = {
            [filterType]: {
              filterText: filterInputValue.trimStart(),
              filterContents: contentTypes,
            },
          };
          setContentFilters(contentFilters);
        } else {
          dispatch(
            ApplyFilter({
              filterType,
              filterText: filterInputValue.trimStart(),
              filterContents: contentTypes,
            })
          );
        }
      }
    }
  };

  const filterPopup = isFilterOpen ? (
    <React.Fragment>
      {ReactDOM.createPortal(
        <FilterPortalWrapper>
          <FilterRoot ref={filterPopRef} style={{ left: position.x, top: position.y }}>
            <FilterItem onClick={handleFilterItemClick()}>
              <IconWrapper style={{ marginRight: 13 }}>
                {contentTypes.length === selectedFilterContents.length ? (
                  <CheckSvg />
                ) : (
                  <UncheckSvg />
                )}
              </IconWrapper>
              <ContentFilterIcon />
              <FilterName style={{ marginLeft: 13 }}>{l10n(filterContentTexts.all)}</FilterName>
            </FilterItem>
            {contentTypes.map(name => (
              <FilterItem key={name} onClick={handleFilterItemClick(name)}>
                <IconWrapper style={{ marginRight: 13 }}>
                  {selectedFilterContents.includes(name) ? <CheckSvg /> : <UncheckSvg />}
                </IconWrapper>
                <ContentFilterIcon contentType={name} />
                <FilterName style={{ marginLeft: 13 }}>{l10n(filterContentTexts[name])}</FilterName>
              </FilterItem>
            ))}
          </FilterRoot>
        </FilterPortalWrapper>,
        filterPopDiv.current
      )}
    </React.Fragment>
  ) : null;

  useEffect(() => {
    document.body.appendChild(filterPopDiv.current);
  }, []);

  // remove the editableDiv element when the component is unmounted
  useEffect(
    () => () => {
      document.body.removeChild(filterPopDiv.current);
    },
    [filterPopDiv]
  );

  return (
    <Root>
      <SearchBox>
        <SearchIconWrapper>
          <SearchSVG />
        </SearchIconWrapper>
        <Input
          ref={inputRef}
          value={filterInputValue}
          onChange={e => setFilterInputValue(e.target.value)}
          placeholder={l10n(placeholderText[filterType])}
        />
        {filterText && (
          <IconWrapper onClick={handleCloseIconClick}>
            <CloseSVG />
          </IconWrapper>
        )}
        <VerticalDivider />
        <FilterWrapper>
          <IconWrapper isActive={isFilterOpen} onClick={handleFilterIconClick}>
            <FilterSVG />
          </IconWrapper>
          {filterPopup}
        </FilterWrapper>
      </SearchBox>
      {filterText && <Result> {l10n(text.resultText(filterText, count))} </Result>}
    </Root>
  );
};

export default memo(ContentsSearchBox);
