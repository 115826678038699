import React, {
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  SyntheticEvent,
  MutableRefObject,
} from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { Global } from '^/constants/zindex';
import { UseState, useClickOutside } from '^/hooks';
import LoadingIcon from '../LoadingIcon';

const ATTACH_FILE_INPUT_ID: string = 'ATTACH_FILE_INPUT';

const Button = styled.button<{ isDxf?: boolean; hasError?: boolean }>(({ isDxf, hasError }) => ({
  position: 'relative',

  width: '100%',
  // height: '100%',
  height: '40px', //Temporary

  cursor: 'pointer',

  fontSize: '13px',
  color: dsPalette.title.toString(),
  backgroundColor: isDxf ? palette.white.toString() : palette.itemBackground.toString(),
  borderRadius: isDxf ? '2px' : '6px',

  border: isDxf ? `1px solid  ${palette.border.toString()}` : 'none',
  ...(hasError
    ? {
        backgroundColor: palette.UploadPopup.error.alpha(0.05).toString(),
        border: `1px solid ${palette.error.toString()}`,
      }
    : {}),
}));

const ItemsWrapper = styled.ul<{ isDxf?: boolean }>(({ isDxf }) => ({
  listStyle: 'none',

  width: '100%',

  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: Global.ATTACH_DROPDOWN,

  backgroundColor: palette.white.toString(),
  border: isDxf ? `1px solid ${palette.border.toString()}` : 'none',
}));

const OptionsWrapper = styled.ul({
  listStyle: 'none',
});

const InputLabel = styled.label.attrs({
  htmlFor: ATTACH_FILE_INPUT_ID,
})({
  display: 'flex',
  alignItems: 'center',

  width: '100%',
  height: '100%',

  cursor: 'pointer',
});

const Item = styled.li<{ isDxf?: boolean; isProcessing?: boolean }>(({ isDxf, isProcessing }) => ({
  boxSizing: 'border-box',

  width: '100%',
  height: '31px',

  paddingLeft: '12px',
  paddingRight: '12px',
  opacity: isProcessing ? 0.5 : 1,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  borderBottom: isDxf ? `1px solid #D9D9D9` : 'none',
  backgroundColor: isDxf ? palette.white.toString() : palette.itemBackground.toString(),
  cursor: 'pointer',

  ':hover': {
    backgroundColor: isProcessing ? palette.white.toString() : '#E1E1E1',
  },
}));

const ItemText = styled.span({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const InputItem = styled(Item)({
  borderBottom: `1px solid ${palette.Photo.photoTabButtonBackground.toString()}`,
});

const NoItem = styled(Item)({
  color: palette.Photo.photoTabButtonBackground.toString(),
  cursor: 'auto',
});

export interface Option {
  text: string;
  value: string;
  isAuto?: boolean;
  isProcessing?: boolean;
}

export interface Props {
  readonly isStatic?: boolean;
  readonly text: string;
  readonly options: Option[];
  readonly noOptionText: string;
  readonly fileExtension: string;
  readonly attachFileText: string;
  readonly isDxf?: boolean;
  readonly hasError?: boolean;
  onClick(value: Option['value'], isAutoGcp?: boolean): void;
  onAttach(file: File): void;
  callback?(): void;
}

function AttachDropdown({
  isStatic,
  text,
  options,
  noOptionText,
  fileExtension,
  attachFileText,
  onClick,
  onAttach,
  callback,
  isDxf,
  hasError,
}: Props): ReactElement {
  const [isOpened, setIsOpened]: UseState<boolean> = useState<boolean>(false);
  const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  const handleButtonClickOutside: () => void = useCallback(() => {
    if (isOpened) {
      setIsOpened(false);
    }
  }, [isOpened]);
  useClickOutside({
    ref: buttonRef,
    callback: handleButtonClickOutside,
  });

  const handleButtonClick = useCallback(() => {
    setIsOpened(true);
  }, []);

  const handleAttach: (e: SyntheticEvent<HTMLInputElement>) => void = useCallback(
    e => {
      const file: File | undefined | null = e.currentTarget.files?.item(0);

      if (file) {
        onAttach(file);
        setIsOpened(false);
      }
    },
    [onAttach]
  );

  const handleOptionClick: (value: string, isAutoGcp?: boolean) => void = useCallback(
    (value, isAutoGcp) => {
      onClick(value, isAutoGcp);
      setIsOpened(false);
    },
    [onClick]
  );

  const input: ReactNode = useMemo(
    () => (
      <InputItem
        onClick={() => {
          if (isStatic) {
            callback?.();
            setIsOpened(false);
          }
        }}
      >
        <InputLabel>{attachFileText}</InputLabel>
        <input
          style={{ display: 'none' }}
          id={ATTACH_FILE_INPUT_ID}
          type="file"
          accept={fileExtension}
          onChange={handleAttach}
          disabled={isStatic}
        />
      </InputItem>
    ),
    [attachFileText, fileExtension, handleAttach]
  );

  const optionsElement: ReactNode = useMemo(
    () =>
      options.length > 0 ? (
        options.map((option, index) => {
          const handleClick: (e: MouseEvent<HTMLLIElement>) => void = e => {
            if (option.isProcessing) {
              return;
            }
            e.stopPropagation();
            handleOptionClick(option.value, option.isAuto);
          };

          return (
            <Item
              key={index}
              aria-disabled={option?.isProcessing}
              onClick={handleClick}
              isDxf={isDxf}
            >
              <ItemText>{option.text}</ItemText>
              {option.isProcessing && <LoadingIcon />}
            </Item>
          );
        })
      ) : (
        <NoItem>
          <ItemText>{noOptionText}</ItemText>
        </NoItem>
      ),
    [options, noOptionText, handleOptionClick]
  );

  const itemsElement: ReactNode = useMemo(
    () =>
      isOpened ? (
        <ItemsWrapper isDxf={isDxf}>
          {input}
          <OptionsWrapper>
            <ScrollBars autoHeight={true}>{optionsElement}</ScrollBars>
          </OptionsWrapper>
        </ItemsWrapper>
      ) : null,
    [isOpened, input, optionsElement]
  );

  return (
    <Button
      hasError={hasError}
      type="button"
      ref={buttonRef}
      onClick={handleButtonClick}
      isDxf={isDxf}
    >
      <>{text}</>
      {itemsElement}
    </Button>
  );
}

export default memo(AttachDropdown);
