/* eslint-disable max-lines */
import { Cartesian3 } from 'cesium';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';
import React, {
  Dispatch as LocalDispatch,
  ReactNode,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
  FC,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import CameraButton from '../CameraButton';
import { Fallback } from './fallback';
import Text from './text';
import MapCenterSvg from '^/assets/icons/map-controller/map-center.svg';
import CoordinatesSvg from '^/assets/icons/map-controller/coordinates.svg';
import ShowHiddenTerrainActiveSvg from '^/assets/icons/map-controller/show-hidden-terrain-active.svg';
import ShowHiddenTerrainSvg from '^/assets/icons/map-controller/show-hidden-terrain.svg';
import ShowWorkRadiusActiveSvg from '^/assets/icons/map-controller/show-work-radius-active.svg';
import ShowWorkRadiusSvg from '^/assets/icons/map-controller/show-work-radius.svg';
import ShowEssDefaultSvg from '^/assets/icons/map-controller/show-ess-default.svg';
import ShowEssActiveSvg from '^/assets/icons/map-controller/show-ess-active.svg';

import GeolocationButton from '^/components/atoms/GeolocationButton';
import { OlViewProps, withOlView } from '^/components/atoms/OlViewProvider';
import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import { CesiumContext, CesiumContextProps } from '^/components/cesium/CesiumContext';
import {
  convertOlZoomToCesiumAlt,
  getRadiusForCesium,
  requestElevationInfoOnCoordinate,
  setThreeDTilesetCenter,
} from '^/components/cesium/cesium-util';
import MapZoom from '^/components/molecules/MapZoom';
import ProjectLogo from '^/components/molecules/ProjectLogo';
import { Actions } from '^/components/ol/OlMapEventListeners/store/Actions';
import { defaultMapZoom } from '^/constants/defaultContent';
import { cesiumConstants as CC } from '^/constants/map-display';
import palette from '^/constants/palette';
import route from '^/constants/routes';
import { MediaQuery } from '^/constants/styles';
import { MapController as MapControllerZIndex } from '^/constants/zindex';
import { UseL10n, UseState, useL10n, useRouteIsMatching } from '^/hooks';
import { RootAction } from '^/store/duck';
import { AuthHeader, makeAuthHeader } from '^/store/duck/API';
import {
  ChangeIsOnWorkRadius,
  // ChangeIsSketchMode,
  ChangeTwoDDisplayZoom,
} from '^/store/duck/Pages';
import * as T from '^/types';
import { getCenterBoundary } from '^/utilities/map-util';
import { getSingleContentId } from '^/utilities/state-util';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { useThreeStore } from '^/components/three/ThreeStore';
import { usePotreeStore } from '^/components/potree/PotreeStore';
import IFCRepositionComponent from '../IFCRepositionComponent';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { usePresentationStore } from '^/store/presentationStore';
import { useAnnotationStore } from '^/store/annotationStore';
import { useUtilsStore } from '^/store/utilsStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';

interface StatusProps {
  isInCesium: boolean;
  isInPotree?: boolean;
  isOnSharePage?: boolean;
  isOnPrintPage?: boolean;
}

export const Root = styled.div<StatusProps>(
  ({ isInCesium, isOnSharePage, isOnPrintPage, isInPotree }) => ({
    position: 'absolute',
    top: (() => {
      if (isOnSharePage && isInCesium) {
        return '156px';
      }
      if (isInCesium || isInPotree) {
        return '120px';
      }
      if (isOnSharePage) {
        return '135px';
      }
      if (isOnPrintPage) {
        return '275px';
      }

      return '260px';
    })(),
    right: '34px',
    width: '32px',
    zIndex: MapControllerZIndex.DEFAULT,

    [MediaQuery.MOBILE_L]: {
      top: isOnSharePage ? '85px' : '35px',
    },
  })
);

const ProjectLogoWrapper = styled.div({
  position: 'fixed',
  bottom: '24px',
  right: '94px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '56px',
  height: '56px',
});

export const MapCenterWrapper = styled.div<StatusProps>(({ isInCesium }) => ({
  height: '30px',
  cursor: 'pointer',
  boxShadow: palette.insideMap.shadow,
  backdropFilter: 'blur(10px)',
  marginBottom: isInCesium ? '72px' : '0',
  '> div': {
    borderRadius: '3px',
  },

  backgroundColor: palette.insideMap.gray.toString(),
  borderRadius: '3px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: palette.insideMap.hoverGray.toString(),
  },
}));

export const MapCenterSvgWrapper = styled.div({
  width: '32px',
  height: '32px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const GeolocationWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'relative',
  marginTop: '6px',
});

const TooltipBalloonStyle: CSSObject = {
  position: 'absolute',
  left: 'auto',
  right: '33px',
  bottom: '3px',
};

export const TooltipCustomStyle: WrapperHoverableProps['customStyle'] = {
  tooltipTargetStyle: {
    width: '100%',
    height: '100%',
  },
  tooltipBalloonStyle: TooltipBalloonStyle,
};

export const TooltipContentStyle: WrapperHoverableProps['customStyle'] = {
  ...TooltipCustomStyle,
  tooltipTextContentStyle: {
    position: 'absolute',
    transform: 'translate(-50%, 10%)',
  },
  tooltipBackgroundStyle: {
    display: 'none',
  },
};

const WorkRadiusTooltipCustomStyle: WrapperHoverableProps['customStyle'] = {
  ...TooltipCustomStyle,
  tooltipWrapperStyle: {
    height: '28px',
  },
};

const IndividualButtonWrapper = styled.div<{ isClicked: boolean }>(({ isClicked }) => ({
  height: '32px',
  cursor: 'pointer',
  boxShadow: palette.insideMap.shadow,
  backdropFilter: 'blur(10px)',

  marginTop: '6px',
  marginBottom: '6px',

  borderRadius: '3px',
  backgroundColor: (isClicked ? palette.white : palette.insideMap.gray).toString(),

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: palette.insideMap.hoverGray.toString(),
  },
}));

const CoordinateButtonRoot = styled.button<{ isActive: boolean }>(({ isActive }) => ({
  cursor: 'pointer',
  borderRadius: '4px',
  width: '32px',
  height: '32px',
  boxShadow: palette.insideMap.shadow,
  backgroundColor: isActive ? palette.white.toString() : palette.insideMap.gray.toString(),

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '5px',

  '&:hover': {
    backgroundColor: palette.insideMap.hoverGray.toString(),
  },
}));

interface Props {
  readonly localDispatch?: LocalDispatch<Actions>;
}
interface SelectorState {
  isInESS: boolean;
  twoDDisplayZoom: number;
  isInCesium: boolean;
  isInPotree: boolean;
  tms: T.MapContent['info']['tms'] | undefined;
  isDisabled: boolean;
  lastThreeDOrthoDSMId: number | undefined;
  isOnPrintPage: boolean;
  isInSourcePhotoUpload: boolean;
  isInFlightScheduleCreation: boolean;
  twoDDisplayMode: T.TwoDDisplayMode;
  in3D: boolean;
  sidebarTab: T.ContentPageTabType;
  isOnWorkRadius: boolean;
  isSketchMode: boolean;
}
const MapController: FC<CesiumContextProps & OlViewProps & Props> = ({
  view: olView,
  localDispatch,
}) => {
  const dispatch: Dispatch<RootAction> = useDispatch();
  const { viewer: cesiumViewer }: CesiumContextProps = useContext(CesiumContext);
  const centerPotreeMap = usePotreeStore(s => s.centerPotreeMap);
  const mapToCenter3DMesh = useThreeStore(s => s.mapToCenter3DMesh);
  const { setIsLatLongTooltipEnabled, isLatLongTooltipEnabled, setHoveredCoordinates } =
    useUtilsStore();

  const setDepthTestAgainstTerrainThree = useThreeStore(s => s.setDepthTestAgainstTerrain);
  const [l10n]: UseL10n = useL10n();
  const [additionalAltAboveGround, setAdditionalAltAboveGround]: UseState<number> =
    useState<number>(0);
  const [depthTestAgainstTerrain, setDepthTestAgainstTerrain]: UseState<boolean> = useState(false);
  const isOnSharePage: boolean = useRouteIsMatching(route.share.main);
  const byId = useContentsStore(s => s.contents.byId);
  //TODO: this testing for on demad
  const isinThreeMesh = useThreeStore(s => Boolean(s.viewer));
  const { isEssModelIconEnabled, setIsEssModelIconEnabled } = useUtilsStore(s => ({
    isEssModelIconEnabled: s.isEssModelIconEnabled,
    setIsEssModelIconEnabled: s.setIsEssModelIconEnabled,
  }));
  const {
    isInESS,
    twoDDisplayZoom,
    isInCesium,
    isInPotree,
    tms,
    isDisabled,
    lastThreeDOrthoDSMId,
    isOnPrintPage,
    isInSourcePhotoUpload,
    isInFlightScheduleCreation,
    twoDDisplayMode,
    in3D,
    sidebarTab,
    isOnWorkRadius,
  }: // isSketchMode,
  SelectorState = useSelector((state: T.State) => {
    const { Pages, ProjectConfigPerUser } = state;
    const { in3DPointCloud, printingContentId, currentPointCloudEngine, currentMeshEngine } =
      Pages.Contents;
    const lastMapId: T.Content['id'] | undefined = getSingleContentId(
      Pages,
      ProjectConfigPerUser,
      T.ContentType.MAP
    );
    const lastThreeDOrthoId: T.ThreeDOrthoContent['id'] | undefined = getSingleContentId(
      Pages,
      ProjectConfigPerUser,
      T.ContentType.THREE_D_ORTHO
    );

    const _lastThreeDOrthoDSMId: T.DSMContent['id'] | undefined =
      lastThreeDOrthoId === undefined
        ? undefined
        : (byId?.[lastThreeDOrthoId ?? NaN] as T.ThreeDOrthoContent)?.info?.dsm;

    // const isInPointCloudCesium =
    //   in3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.CESIUM;
    const _tms: T.MapContent['info']['tms'] =
      lastMapId !== undefined && Pages.Contents.twoDDisplayZoom
        ? (byId[lastMapId] as T.MapContent).info.tms
        : undefined;
    const _isOnPrintPage: boolean = printingContentId !== undefined;

    return {
      isInCesium: currentMeshEngine === T.MeshEngine.CESIUM && Pages.Contents.in3D,
      isInPotree: in3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.POTREE,
      tms: _tms,
      isDisabled: _isOnPrintPage || Pages.Contents.sidebarTab === T.ContentPageTabType.PHOTO,
      lastThreeDOrthoDSMId: _lastThreeDOrthoDSMId,
      isOnPrintPage: _isOnPrintPage,
      isInESS: Pages.Contents.sidebarTab === T.ContentPageTabType.ESS,
      twoDDisplayZoom: Pages.Contents.twoDDisplayZoom,
      isInSourcePhotoUpload: Pages.Contents.isInSourcePhotoUpload,
      isInFlightScheduleCreation: Pages.Contents.isInFlightScheduleCreation,
      twoDDisplayMode: Pages.Contents.twoDDisplayMode,
      in3D: Pages.Contents.in3D,
      sidebarTab: Pages.Contents.sidebarTab,
      isOnWorkRadius: Pages.Contents.isOnWorkRadius,
      isSketchMode: Pages.Contents.isSketchMode,
    };
  }, shallowEqual);

  const authHeader: AuthHeader | undefined = useSelector(
    ({ Auth, PlanConfig }: T.State) => makeAuthHeader(Auth, PlanConfig.config?.slug),
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
  );
  const threeDTilesetBounds = useSelector((s: T.State) => s.Pages.Contents.threeDTilesetBounds);
  const isInPresentationMode = usePresentationStore(s => s.isInPresentationMode);
  const isAnnotationModeOpen = useAnnotationStore(s => s.isAnnotationModeOpen);
  const is360MiniMapView = useThreePhotoAlbumStore(s => s.is360MiniMapView);

  const changeZoom: (zoom: number) => void = zoom =>
    dispatch(ChangeTwoDDisplayZoom({ twoDDisplayZoom: zoom }));

  const initialCameraPosition: Coordinate = useSelector(
    ({ SharedContents }: T.State) => SharedContents.initialCameraPosition
  );

  const isVideoOverlay = useThreePhotoAlbumStore(s => s.isVideoOverlay);
  let twoDDisplayCenter: T.GeoPoint | undefined;

  if (isOnSharePage) {
    twoDDisplayCenter = initialCameraPosition;
  } else if (tms) {
    twoDDisplayCenter = getCenterBoundary(tms.boundaries[defaultMapZoom]);
  }
  useEffect(() => {
    if (!isInCesium || lastThreeDOrthoDSMId === undefined || twoDDisplayCenter === undefined) {
      return;
    }
    requestElevationInfoOnCoordinate({
      authHeader,
      lastDSMId: lastThreeDOrthoDSMId,
      lon: twoDDisplayCenter[0],
      lat: twoDDisplayCenter[1],
    }).subscribe((alt: number) => {
      setAdditionalAltAboveGround(alt);
    });
  }, []);

  useEffect(() => {
    if (cesiumViewer === undefined || cesiumViewer.isDestroyed()) {
      return;
    }
    cesiumViewer.scene.globe.depthTestAgainstTerrain = depthTestAgainstTerrain;
    cesiumViewer.scene.requestRender();
  }, [Boolean(cesiumViewer), depthTestAgainstTerrain]);

  useEffect(() => {
    if (!isinThreeMesh) {
      return;
    }

    setDepthTestAgainstTerrainThree(depthTestAgainstTerrain);
  }, [isinThreeMesh, depthTestAgainstTerrain]);

  const handleMapCenterInCesium: () => void = () => {
    if (!cesiumViewer || cesiumViewer.isDestroyed()) {
      return;
    }

    if (threeDTilesetBounds !== undefined) {
      setThreeDTilesetCenter({
        viewer: cesiumViewer,
        minBounds: threeDTilesetBounds.min,
        maxBounds: threeDTilesetBounds.max,
      });
    } else if (twoDDisplayCenter !== undefined) {
      const [lon, lat]: T.GeoPoint = twoDDisplayCenter;
      const radiusIn4326: number = getRadiusForCesium(CC.zoom.defaultLevel)(
        -CC.defaultCameraOrientation.pitch
      );

      cesiumViewer.camera.setView({
        destination: Cartesian3.fromDegrees(
          lon,
          lat - radiusIn4326,
          convertOlZoomToCesiumAlt(CC.zoom.defaultLevel) + additionalAltAboveGround
        ),
        orientation: CC.defaultCameraOrientation,
      });
    }
  };

  const handleMapCenterClick: () => void = () => {
    if (isInPotree) {
      centerPotreeMap();
      return;
    }

    if (isinThreeMesh) {
      mapToCenter3DMesh();
    }

    if (isInCesium) {
      handleMapCenterInCesium();
      return;
    }

    if (!twoDDisplayCenter) {
      return;
    }
    olView?.setRotation(0);
    olView?.setCenter(fromLonLat(twoDDisplayCenter));

    olView?.setZoom(defaultMapZoom);
  };

  const shouldShowCameraButton: boolean = useMemo(
    () =>
      !isOnSharePage &&
      !isInPresentationMode &&
      sidebarTab !== T.ContentPageTabType.VIEWPOINT_CAPTURE,
    [isOnSharePage, isInPresentationMode, sidebarTab]
  );

  const cameraButton: ReactNode = useMemo(
    () =>
      shouldShowCameraButton ? (
        <CameraButton isCesium={isInCesium} isDisabled={isDisabled} />
      ) : undefined,
    [shouldShowCameraButton]
  );

  const handleDepthTestClick: () => void = () => {
    setDepthTestAgainstTerrain(prev => !prev);
  };

  const handleWorkRadiusClick: () => void = () => {
    dispatch(ChangeIsOnWorkRadius({ isOnWorkRadius: !isOnWorkRadius }));
  };

  const handleToggleEssIconClick: () => void = () => {
    setIsEssModelIconEnabled(!isEssModelIconEnabled);
  };

  const workRadiusButton: ReactNode = (
    <IndividualButtonWrapper
      data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
      data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_WORK_RADIUS}-${
        isOnWorkRadius ? 'off' : 'on'
      }`}
      isClicked={isOnWorkRadius}
      onClick={handleWorkRadiusClick}
    >
      <WrapperHoverable
        allowForceCheckMouseout={true}
        allowForceCheckTouchend={true}
        title={l10n(Text.workRadius)}
        customStyle={WorkRadiusTooltipCustomStyle}
      >
        {isOnWorkRadius ? <ShowWorkRadiusActiveSvg /> : <ShowWorkRadiusSvg />}
      </WrapperHoverable>
    </IndividualButtonWrapper>
  );

  const essToggleButton: ReactNode = (
    <IndividualButtonWrapper
      data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
      data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_ESS_MODEL}-${
        isEssModelIconEnabled ? 'off' : 'on'
      }`}
      isClicked={isEssModelIconEnabled}
      onClick={handleToggleEssIconClick}
    >
      <WrapperHoverable
        allowForceCheckMouseout={true}
        allowForceCheckTouchend={true}
        title={!isEssModelIconEnabled ? l10n(Text.showEssModelIcon) : l10n(Text.hideEssModelIcon)}
        customStyle={WorkRadiusTooltipCustomStyle}
      >
        {!isEssModelIconEnabled ? <ShowEssActiveSvg /> : <ShowEssDefaultSvg />}
      </WrapperHoverable>
    </IndividualButtonWrapper>
  );
  const depthTestButton: ReactNode =
    !isInPotree && (isinThreeMesh || isInCesium) ? (
      <IndividualButtonWrapper
        data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
        data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_3D_TERRAIN}-${
          depthTestAgainstTerrain ? 'off' : 'on'
        }`}
        isClicked={depthTestAgainstTerrain}
        onClick={handleDepthTestClick}
      >
        <WrapperHoverable
          allowForceCheckMouseout={true}
          allowForceCheckTouchend={true}
          title={l10n(Text.depthTestAgainstTerrain)}
          customStyle={TooltipCustomStyle}
        >
          {depthTestAgainstTerrain ? <ShowHiddenTerrainActiveSvg /> : <ShowHiddenTerrainSvg />}
        </WrapperHoverable>
      </IndividualButtonWrapper>
    ) : null;

  const shouldHideGeoLocation = useMemo(
    () =>
      in3D ||
      twoDDisplayMode !== T.TwoDDisplayMode.NORMAL ||
      isInPresentationMode ||
      sidebarTab === T.ContentPageTabType.VIEWPOINT_CAPTURE,
    [in3D, twoDDisplayMode, isInPresentationMode, sidebarTab]
  );

  const geolocation: ReactNode = useMemo(
    () =>
      shouldHideGeoLocation ? null : (
        <GeolocationWrapper>
          <WrapperHoverable title={l10n(Text.geolocation)} customStyle={TooltipCustomStyle}>
            <GeolocationButton dispatch={localDispatch} />
          </WrapperHoverable>
        </GeolocationWrapper>
      ),
    [shouldHideGeoLocation]
  );

  const shouldHideGeoCoordinates = useMemo(
    () => sidebarTab === T.ContentPageTabType.VIEWPOINT_CAPTURE || isInPresentationMode,
    [isInPresentationMode, sidebarTab]
  );

  const geoCoordinates: ReactNode = shouldHideGeoCoordinates ? null : (
    <CoordinateButtonRoot
      isActive={isLatLongTooltipEnabled}
      onClick={() => {
        setHoveredCoordinates(null);
        setIsLatLongTooltipEnabled(!isLatLongTooltipEnabled);
      }}
    >
      <WrapperHoverable title={l10n(Text.geolocationVisibility)} customStyle={TooltipCustomStyle}>
        <CoordinatesSvg />
      </WrapperHoverable>
    </CoordinateButtonRoot>
  );

  return (() => {
    if (isAnnotationModeOpen || is360MiniMapView) {
      return null;
    }

    if (sidebarTab === T.ContentPageTabType.PHOTO) {
      if (isinThreeMesh && !isVideoOverlay) {
        return (
          <Root
            isInCesium={isInCesium}
            isInPotree={isInPotree || isinThreeMesh}
            isOnSharePage={isOnSharePage}
            isOnPrintPage={isOnPrintPage}
            data-html2canvas-ignore="true"
          >
            <MapCenterWrapper
              data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
              data-ddm-track-label={`${T.TrackLabels.BTN_CENTER}-${isInCesium ? '3d' : '2d'}`}
              isInCesium={isInCesium}
              onClick={handleMapCenterClick}
              data-testid="map-center-btn"
            >
              <WrapperHoverable title={l10n(Text.mapCenter)} customStyle={TooltipCustomStyle}>
                <MapCenterSvgWrapper>
                  <MapCenterSvg />
                </MapCenterSvgWrapper>
              </WrapperHoverable>
            </MapCenterWrapper>
          </Root>
        );
      }
      return null;
    }

    if (isInSourcePhotoUpload || isInFlightScheduleCreation) {
      return null;
    }

    return (
      <>
        <Root
          isInCesium={isInCesium}
          isInPotree={isInPotree || isinThreeMesh}
          isOnSharePage={isOnSharePage}
          isOnPrintPage={isOnPrintPage}
          data-html2canvas-ignore="true"
        >
          <ProjectLogoWrapper>
            <ProjectLogo />
          </ProjectLogoWrapper>
          {cameraButton}
          <MapCenterWrapper
            data-ddm-track-action={T.TrackActions.MAP_CONTROLS}
            data-ddm-track-label={`${T.TrackLabels.BTN_CENTER}-${isInCesium ? '3d' : '2d'}`}
            isInCesium={isInCesium}
            onClick={handleMapCenterClick}
            data-testid="map-center-btn"
          >
            <WrapperHoverable title={l10n(Text.mapCenter)} customStyle={TooltipCustomStyle}>
              <MapCenterSvgWrapper>
                <MapCenterSvg />
              </MapCenterSvgWrapper>
            </WrapperHoverable>
          </MapCenterWrapper>
          <MapZoom
            isInCesium={isInCesium}
            isinThreeMesh={isinThreeMesh}
            isInPotree={isInPotree}
            tooltipCustomStyle={TooltipCustomStyle}
            twoDDisplayZoom={twoDDisplayZoom}
            changeZoom={changeZoom}
          />

          <IFCRepositionComponent />

          {geolocation}
          {depthTestButton}
          {isInESS ? workRadiusButton : null}
          {isInESS ? essToggleButton : null}
          {!isInPotree && geoCoordinates}
        </Root>
      </>
    );
  })();
};

export default withErrorBoundary(memo(withOlView(MapController)))(Fallback);
