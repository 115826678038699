import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import proj4 from 'proj4';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  radarSvgIconToBase64,
  photoPointIcon,
  threeSixtyPointIcon,
} from '^/assets/icons/imageBase64';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store/ThreePhotoAlbumStore';
import { UseState } from '^/hooks';
import * as T from '^/types';
import { Map } from 'ol';
import { Icon, Style } from 'ol/style';
import { OlCustomPropertyNames } from '../constants';

function isPoint(g: Geometry): g is Point {
  return g instanceof Point;
}

interface Props {
  map: Map;
  photo: T.Photo;
  photoLayer?: VectorLayer<Feature>;
}

export const OlPhotoMarkerLayerMinimap = ({ photo, photoLayer, map }: Props) => {
  const [geometry, setGeometry]: UseState<Geometry | undefined> = useState();
  const selectedMedia = useSelector((s: T.State) => s.Photos.selectedMedia);
  const hoveredId = useSelector((s: T.State) => s.Photos.hoveredId);
  const isPhotoSelected = selectedMedia.some(media => media.contentId === photo.id);
  const { longitude: lon, latitude: lat }: T.Photo = photo;
  const { selectedPhotoId } = useThreePhotoAlbumStore(state => state.threeSixtyMiniMapDetails);
  const isSelected = isPhotoSelected && hoveredId !== photo.id;
  const source: VectorSource | null = useMemo(() => {
    if (photoLayer) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return photoLayer.values_.source;
    }
  }, [map]);

  useEffect(() => {
    if (lon === null || lat === null) {
      return;
    }
    setGeometry(new Point([lat, lon]));
  }, [lon, lat]);

  useEffect(() => {
    if (
      !geometry ||
      lon === null ||
      lat === null ||
      !selectedMedia.some(media => media.contentId === photo.id) ||
      !source
    ) {
      return;
    }
    const threeSixtyIconStyle = new Style({
      image: new Icon({
        src: threeSixtyPointIcon,
      }),
    });
    const radarStyle = new Style({
      image: new Icon({
        src: radarSvgIconToBase64(50),
        rotateWithView: true,
      }),
    });
    const photoPointStyle = new Style({
      image: new Icon({
        src: photoPointIcon,
        rotateWithView: true,
      }),
    });
    const feature: Feature = new Feature(geometry);
    feature.setId(`${OlCustomPropertyNames.PHOTO_FEATURE}${photo.id}`);

    // if (photo.photoType === 'three_sixty' || photo.photoType === 'three_sixty_stitched') {
    if (String(photo.id) === selectedPhotoId) {
      feature.setStyle([radarStyle, photoPointStyle]);
    } else {
      feature.setStyle(threeSixtyIconStyle);
    }

    source.addFeature(feature);

    const photoCoordinate: Coordinate = proj4('EPSG:4326', 'EPSG:3857').forward([lon, lat]);

    if (isPoint(geometry)) {
      geometry.setCoordinates(photoCoordinate);
    }
  }, [geometry, isSelected, selectedMedia, source, lon, lat]);

  return null;
};
