import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectPermissionTable, { Props } from '^/components/organisms/ProjectPermissionTable';
import { useReduxState } from '^/hooks/useReduxState';
import { OpenContentPagePopup, OpenProjectPagePopup } from '^/store/duck/Pages';
import { UpdateConfirmDeletePermission } from '^/store/duck/Permissions';
import { usepatchPermissionMutation, usePermissionQuery } from '^/store/react-query/permission';
import {
  ContentPagePopupType,
  Permission,
  PermissionRole,
  Project,
  ProjectPagePopupType,
  State,
} from '^/types';

type StatePropKeys = 'authedId' | 'authedRole' | 'permissions' | 'featurePermission' | 'isLoading';
type DispatchPropKeys = 'onDelete' | 'onPermissionChange' | 'onFeaturePermissionChange';
export type OwnProps = Partial<Omit<Props, StatePropKeys | DispatchPropKeys>>;
export type StateProps = Pick<Props, StatePropKeys>;
export type DispatchProps = Pick<Props, DispatchPropKeys>;

export default function ({ isCompactSize = false }: OwnProps) {
  const { Auth, Projects, Pages } = useReduxState();
  const currentProjectId: Project['id'] | undefined =
    Pages.Project.editingProjectId || Pages.Contents.projectId;
  const isInContent: boolean = useSelector((state: State) => !state.Pages.Project.editingProjectId);

  const dispatch = useDispatch();
  const { data: permissions, isLoading } = usePermissionQuery(currentProjectId);
  const { mutate: patchPermission } = usepatchPermissionMutation(currentProjectId);
  let stateProps: StateProps;
  if (currentProjectId !== undefined) {
    stateProps = {
      authedId: Auth.authedUser !== undefined ? Auth.authedUser.id : undefined,
      authedRole: Projects.projects.byId[currentProjectId].permissionRole,
      permissions: permissions ?? [],
      featurePermission: Projects.projects.byId[currentProjectId].features,
      isLoading,
    };
  } else {
    stateProps = {
      authedRole: PermissionRole.VIEWER,
      permissions: permissions ?? [],
      isLoading,
      featurePermission: {
        ess: false,
        ddm: false,
        oneD: false,
        droneStation: false,
      },
    };
  }

  const dispatchProps = {
    onDelete(permission: Permission, hasPermission: boolean): void {
      if (!hasPermission) {
        if (isInContent) {
          dispatch(OpenContentPagePopup({ popup: ContentPagePopupType.NO_PERMISSION }));
        } else {
          dispatch(OpenProjectPagePopup({ popup: ProjectPagePopupType.NO_PERMISSION }));
        }
        return;
      }

      dispatch(UpdateConfirmDeletePermission({ permission }));
      if (isInContent) {
        dispatch(OpenContentPagePopup({ popup: ContentPagePopupType.CONFIRM_DELETE_PERMISSION }));
      } else {
        dispatch(OpenProjectPagePopup({ popup: ProjectPagePopupType.CONFIRM_DELETE_PERMISSION }));
      }
    },
    onPermissionChange(id: Permission['id'], role: PermissionRole, hasPermission: boolean): void {
      if (!hasPermission) {
        if (isInContent) {
          dispatch(OpenContentPagePopup({ popup: ContentPagePopupType.NO_PERMISSION }));
        } else {
          dispatch(OpenProjectPagePopup({ popup: ProjectPagePopupType.NO_PERMISSION }));
        }
        return;
      }
      patchPermission({ role, id });
    },
    onFeaturePermissionChange(
      id: Permission['id'],
      role: PermissionRole,
      isESS: boolean,
      hasPermission: boolean
    ): void {
      if (!hasPermission) {
        if (isInContent) {
          dispatch(OpenContentPagePopup({ popup: ContentPagePopupType.NO_PERMISSION }));
        } else {
          dispatch(OpenProjectPagePopup({ popup: ProjectPagePopupType.NO_PERMISSION }));
        }
        return;
      }
      patchPermission({ id, role, feature_ess: isESS });
    },
  };

  return (
    <ProjectPermissionTable {...stateProps} {...dispatchProps} isCompactSize={isCompactSize} />
  );
}
