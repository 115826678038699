import { Language } from '^/types';

export default {
  firstBalloonTitle: {
    [Language.KO_KR]: '불투명도',
    [Language.EN_US]: 'Opacity',
  },
  title: {
    [Language.KO_KR]: '핀 셋팅 바로 가기',
    [Language.EN_US]: 'Go to Pin Settings',
  },
  description: {
    [Language.KO_KR]: 'PDF 형식의 도면은 핀 세팅을 이용해 직접 위치를 지정해야 합니다.',
    [Language.EN_US]: 'The CAD Overlay(PDF) must be positioned directly using pin setting.',
  },
  designDXF: {
    [Language.KO_KR]: '계획고',
    [Language.EN_US]: 'Design',
  },
  error: {
    [Language.KO_KR]: '도면도 처리 실패',
    [Language.EN_US]: 'Overlay Processing Failed',
  },
  errorDetail: {
    [Language.KO_KR]: '유의사항 자세히 보기',
    [Language.EN_US]: 'Learn More',
  },
  warningDescription: {
    [Language.KO_KR]: 'BIM 오버레이를 사용하려면,',
    [Language.EN_US]: 'If you wish to use BIM overlays,',
  },
  switchText: {
    [Language.KO_KR]: '베타 3D 뷰어로 전환하십시오',
    [Language.EN_US]: 'please switch to Beta 3D Viewer',
  },
  originalPosition: {
    [Language.KO_KR]: '위치 재설정',
    [Language.EN_US]: 'Reset Location',
  },
  originalPositionTooltip: {
    [Language.KO_KR]: 'BIM 모델을 원래 위치와 방향으로 재설정하려면 여기를 클릭하세요',
    [Language.EN_US]: 'Click here to reset the BIM model to its original position and orientation',
  },
  snapToggleButton: {
    [Language.KO_KR]: '스냅핑',
    [Language.EN_US]: 'Snapping',
  },
};
