/* eslint-disable curly */
import { forEach } from 'lodash-es';
import { Mesh, Object3D, Group, Scene, WebGLRenderer, Cache } from 'three';

/**
 * @public
 * @description dispose scene
 * @param {WebGLRenderer} renderer
 * @param {Scene} scene
 * @returns { void}
 */

export const disposeAll = (renderer: WebGLRenderer, scene: Scene): void => {
  try {
    if (scene) {
      const temp = scene.children.splice(0);
      forEach(temp, v => {
        if (v.children) {
          disposeHierarchy(v, disposeNode);
        }
        scene.remove(v);
      });
      renderer.renderLists.dispose();
      renderer.dispose();
      Cache.clear();
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error('unmount', e.message);
  }
};

/**
 * @public
 * @description dispose object and children
 * @param {Object3D | Mesh | Group | Scene} node
 * @param {CallableFunction} disposeNode
 * @returns { void}
 */

export const disposeHierarchy = (
  node: Object3D | Mesh | Group | Scene,
  callback = disposeNode
): void => {
  for (let i = node.children.length - 1; i >= 0; i--) {
    try {
      const child = node.children[i];
      node.remove(child); //TODO: need to check
      disposeHierarchy(child, callback);
      callback(child);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('dispose children', e);
    }
  }
};
/**
 * @callback
 * @description dispose geometry and material
 * @param {Object3D} node
 * @returns { void}
 */
const disposeNode = (node: Object3D): void => {
  try {
    if (node instanceof Mesh) {
      if (node.geometry) {
        if (node.geometry.boundsTree) {
          node.geometry.disposeBoundsTree();
        }
        node.geometry.dispose();
      }
      if (node.material) {
        if (node.material instanceof Array) {
          forEach(node.material, mtrl => {
            if (mtrl.map) mtrl.map.dispose();
            if (mtrl.lightMap) mtrl.lightMap.dispose();

            if (mtrl.bumpMap) mtrl.bumpMap.dispose();

            if (mtrl.normalMap) mtrl.normalMap.dispose();

            if (mtrl.specularMap) mtrl.specularMap.dispose();

            if (mtrl.envMap) mtrl.envMap.dispose();

            if (mtrl.alphaMap) mtrl.alphaMap.dispose();

            if (mtrl.aoMap) mtrl.aoMap.dispose();

            if (mtrl.displacementMap) mtrl.displacementMap.dispose();

            if (mtrl.emissiveMap) mtrl.emissiveMap.dispose();

            if (mtrl.gradientMap) mtrl.gradientMap.dispose();

            if (mtrl.metalnessMap) mtrl.metalnessMap.dispose();

            if (mtrl.roughnessMap) mtrl.roughnessMap.dispose();

            mtrl.dispose(); // disposes any programs associated with the material
          });
        } else {
          if (node.material.map) node.material.map.dispose();

          if (node.material.lightMap) node.material.lightMap.dispose();

          if (node.material.bumpMap) node.material.bumpMap.dispose();

          if (node.material.normalMap) node.material.normalMap.dispose();

          if (node.material.specularMap) node.material.specularMap.dispose();

          if (node.material.envMap) node.material.envMap.dispose();

          if (node.material.alphaMap) node.material.alphaMap.dispose();

          if (node.material.aoMap) node.material.aoMap.dispose();

          if (node.material.displacementMap) node.material.displacementMap.dispose();

          if (node.material.emissiveMap) node.material.emissiveMap.dispose();

          if (node.material.gradientMap) node.material.gradientMap.dispose();

          if (node.material.metalnessMap) node.material.metalnessMap.dispose();

          if (node.material.roughnessMap) node.material.roughnessMap.dispose();

          node.material.dispose(); // disposes any programs associated with the material
        }
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (node.dispose) {
        (node as any).dispose();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
