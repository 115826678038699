// Auto run queries on mount

import { QueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router';

import { useUserConfigQuery } from './userConfig';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function fetchOnPageEntry(_path: string) {
  const queries = [useUserConfigQuery];

  return queries;
}

export default function ReactQueries() {
  const location = useLocation();
  const queries = fetchOnPageEntry(location.pathname);
  queries.forEach(useQuery => useQuery());

  return null;
}

// Global queryClient
export const queryClient: QueryClient = new QueryClient();
