/* eslint-disable max-lines */
import React, { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import SingleSlider from '^/components/atoms/SingleSlider';
import palette from '^/constants/palette';
import { DISABLED_CONTENT_OPACITY, FontFamily } from '^/constants/styles';
import { UseL10n, useL10n } from '^/hooks';
import { contentsSelector, PatchContent } from '^/store/duck/Contents';
import * as T from '^/types';
import { determineUnitType } from '^/utilities/imperial-unit';
import { getSingleContentId } from '^/utilities/state-util';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import { useContentsStore } from '^/store/zustand/content/contentStore';

export const contentsListMapItemClassName: string = 'contents-list-map-item';
export const contentsListDsmItemClassName: string = 'contents-list-dsm-item';

const OpactiyControlWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  marginBottom: '15px',
  width: '100%',
});
const Opacity = styled.div({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',

  marginTop: '13px',
});

const OpacityText = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,

  color: 'var(--color-theme-primary)',
  fontFamily: FontFamily.ROBOTO,
  fontWeight: 500,
}));

export const HorizontalDivider = styled.div(() => ({
  width: '100%',
  borderTop: `1px solid ${palette.toggleButtonGray.toString()}`,

  marginTop: '13px',
  marginBottom: '13px',
}));

export const HorizontalWideDivider = styled(HorizontalDivider)({
  marginTop: '23px',
  marginBottom: '23px',
});

export interface Props {
  readonly content: T.MapContent;
}

interface SelectorState {
  unitType: T.ValidUnitType;
  editingContentId: T.Content['id'] | undefined;
  in3D: boolean;
  in3DPointCloud: boolean;
  isPreventAutoSelect: boolean | undefined;
  opacity: number;
  isPlainMap: boolean;
  shouldMapBeSelected: boolean;
  isProcessingOrFailed: boolean;
  isSelected: boolean;
}

export const RawContentsListMapItem: FC<Props> = ({ content }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  const { opacity, isProcessingOrFailed }: SelectorState = useSelector(
    (s: T.State): SelectorState => {
      const { Pages, ProjectConfigPerUser } = s;
      const projectId = s.Pages.Contents.projectId;
      const projectUnit = s.SharedContents.projectUnit;
      const project = s.Projects.projects.byId[projectId ?? NaN];
      const _editingContentId = s.Pages.Contents.editingContentId;

      if (!projectUnit && !projectId) {
        throw new Error(' No Project Id in Pages.Contents.projectId');
      }

      const _isPlainMap: boolean = content.type === T.ContentType.MAP;

      const dsmId: T.DSMContent['id'] | undefined = getSingleContentId(
        Pages,
        ProjectConfigPerUser,
        T.ContentType.DSM
      );
      const mapId: T.DSMContent['id'] | undefined = getSingleContentId(
        Pages,
        ProjectConfigPerUser,
        T.ContentType.MAP
      );
      const isMapAvailable: boolean = mapId !== undefined;
      const _isSelected: boolean = contentsSelector.isSelected(ProjectConfigPerUser)(content.id);

      const hasUserJustToggledDSMIn3D: boolean =
        dsmId !== undefined && _editingContentId === dsmId && _isSelected;
      const hasUserJustSelectedDSMIn3D: boolean = isMapAvailable;
      const _in3D = s.Pages.Contents.in3D;
      return {
        unitType: project ? determineUnitType(project?.unit) : determineUnitType(projectUnit),
        editingContentId: _editingContentId,
        in3D: _in3D,
        in3DPointCloud: s.Pages.Contents.in3DPointCloud,
        isPreventAutoSelect: s.Pages.Contents.isPreventAutoSelect,
        opacity: content.config?.opacity !== undefined ? content.config.opacity : 100,
        isPlainMap: _isPlainMap,

        shouldMapBeSelected:
          !_in3D && !_isSelected && !hasUserJustToggledDSMIn3D && !hasUserJustSelectedDSMIn3D,
        isProcessingOrFailed: contentsSelector.isProcessingOrFailedByContent(content),
        isSelected: _isSelected,
      };
    },
    shallowEqual
  );

  const updateContent: (opacity: number) => void = newOpacity => {
    updateContentConfig(content.id, {
      ...content.config,
      type: T.ContentType.MAP,
      opacity: newOpacity,
    });
  };

  const patchContent: (opacity: number) => void = newOpacity => {
    dispatch(
      PatchContent({
        content: {
          id: content.id,
          config: { ...content.config, type: T.ContentType.MAP, opacity: newOpacity },
        },
      })
    );
  };

  const handleOpacityChange: (opacityValue: number) => void = opacityValue => {
    updateContent(opacityValue);
  };

  const handleMouseUp: () => void = () => {
    patchContent(opacity);
  };

  const opacityControl: React.ReactNode = useMemo(
    () => (
      <Opacity>
        <OpactiyControlWrapper>
          <OpacityText isDisabled={isProcessingOrFailed}>{opacity.toFixed(0)}%</OpacityText>
        </OpactiyControlWrapper>
        <SingleSlider
          minValue={0}
          maxValue={100}
          value={opacity}
          onChange={handleOpacityChange}
          onMouseUp={handleMouseUp}
          isDisabled={isProcessingOrFailed}
        />
      </Opacity>
    ),
    [opacity, isProcessingOrFailed, content.config]
  );

  return (
    <ContentsListItem
      className={`${CANCELLABLE_CLASS_NAME} ${contentsListMapItemClassName}`}
      content={content}
      firstBalloonTitle={l10n(Text.firstBalloonTitle)}
    >
      {opacityControl}
    </ContentsListItem>
  );
};

export const ContentsListMapItem: FC<Props> = withErrorBoundary(RawContentsListMapItem)(Fallback);
