import palette from '^/constants/palette';
import React, { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';
import DeleteAllSvg from '^/assets/icons/delete.svg';
import dsPalette from '^/constants/ds-palette';

export const SelectButton = styled.div<{
  isPlaceHolder?: boolean;
  isError?: boolean;
  selectButtonStyles?: CSSObject;
}>(({ isPlaceHolder, isError, selectButtonStyles }) => ({
  color: isPlaceHolder ? palette.textGray.toString() : 'var(--color-theme-primary)',
  border: `1px solid ${
    isError
      ? palette.UploadPopup.error.toString()
      : isPlaceHolder
      ? 'transparent'
      : palette.border.toString()
  }`,
  backgroundColor: isPlaceHolder ? dsPalette.themePrimary.toString() : palette.white.toString(),
  minHeight: '41px',
  maxHeight: '250px',
  display: 'flex',
  justifyContent: isPlaceHolder ? 'center' : 'space-between',
  alignItems: 'center',
  padding: '0px 20px',
  borderRadius: '4px',
  overflowY: 'auto',
  cursor: isPlaceHolder ? 'pointer' : 'default',

  '&:hover': {
    backgroundColor: isPlaceHolder ? 'var(--color-theme-primary-lighter)' : 'none',
  },

  ...selectButtonStyles,
}));

export const FileText = styled.div<{ isPlaceHolder?: boolean }>(({ isPlaceHolder }) => ({
  fontSize: '12px',
  color: isPlaceHolder ? palette.white.toString() : 'var(--color-theme-primary)',
  borderRadius: '6px',
  marginRight: '13px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const TextContentWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
});

interface Props {
  onClick(): void;
  textValue?: string;
  onDeleteClick?(): void;
  placeHolderText?: string;
  selectButtonStyles?: CSSObject;
}

const SelectedPlaceholder = ({
  onClick,
  textValue,
  onDeleteClick,
  placeHolderText,
  selectButtonStyles,
}: Props) => {
  const deleteAllIcon: ReactNode = <DeleteAllSvg onClick={onDeleteClick} />;
  return (
    <SelectButton
      onClick={onClick}
      isPlaceHolder={!textValue}
      selectButtonStyles={selectButtonStyles}
    >
      {textValue ? (
        <TextContentWrapper>
          <FileText isPlaceHolder={false}>{textValue}</FileText>
          {deleteAllIcon}
        </TextContentWrapper>
      ) : (
        <FileText isPlaceHolder={true}>{placeHolderText}</FileText>
      )}
    </SelectButton>
  );
};

export default SelectedPlaceholder;
