import React, { FC, SyntheticEvent, memo, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import { Action, Dispatch } from 'redux';
import { useDispatch, useSelector, batch } from 'react-redux';

import PhotoCameraSvg from '^/assets/icons/photo/photo-camera.svg';
import { UseL10n, useL10n } from '^/hooks';
import { SetHoveredPhotoId, SetHoveredVideoId, SetSelectedMedia } from '^/store/duck/Photos';
import {
  PhotoInspectionListItem,
  PhotoSvgWrapper,
  Wrapper,
  Checkbox,
  PhotoInspectionListItemWrapper,
} from '../PhotoList/style';
import * as T from '^/types';
import Text from '../PhotoList/text';

import { useIsInView } from '^/hooks/useIsInView';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import usePhotoActions from '^/hooks/timelapse/usePhotoActions';
import Photo360PanoSvg from '^/assets/icons/photo/photo-360-icon.svg';
import styled from 'styled-components';
import { ChangeIn3D } from '^/store/duck/Pages';
import { From2DTo3D360, PatchContent } from '^/store/duck/Contents';
import { isVideo } from '../PhotoList/util';
import { getSingleContentId } from '^/utilities/state-util';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { useContentsStore } from '^/store/zustand/content/contentStore';

interface PhotoItemProps {
  isSelected: boolean;
  photoDate: T.Photo;
}
const ThreeSixtyWrapper = styled.div({
  width: '100%',
  display: 'flex',
});
const ThreeSixtySvgWrapper = styled.div({
  marginLeft: 'auto',
  padding: '15px 10px',
});
const LoadingWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
const PanoProcessingText = styled.span({
  marginTop: '10px',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 600,
  color: '#8F8F8F',
});
const RawPhotoItem: FC<PhotoItemProps> = memo(({ isSelected, photoDate }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const selectedPhotoAlbumIdNew = useThreePhotoAlbumStore(s => s.selectedPhotoAlbumIdNew);
  const { handlePhotoListItemClick, handlePhotoOnMapId } = usePhotoActions(photoDate, isSelected);
  const { selectedMedia } = useSelector((s: T.State) => s.Photos);
  const isIn3D: T.ContentsPageState['in3D'] = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const byId = useContentsStore(s => s.contents.byId);

  const threeDMeshId: T.ThreeDMeshContent['id'] | undefined = useSelector((state: T.State) =>
    getSingleContentId(state.Pages, state.ProjectConfigPerUser, T.ContentType.THREE_D_MESH)
  );
  const threeDMeshContent: T.ThreeDMeshContent | undefined = byId[threeDMeshId ?? NaN] as
    | T.ThreeDMeshContent
    | undefined;

  const isThreeDMeshAvailable: boolean = Boolean(
    threeDMeshId && threeDMeshContent?.status !== T.ContentProcessingStatus.PROCESSING
  );

  const [ref, isVisible] = useIsInView<HTMLDivElement>();

  const phototooltipContent = () =>
    photoDate.latitude && photoDate.longitude
      ? l10n(Text.photo.viewTooltip)
      : l10n(Text.photo.noLocationTooltip);

  const loadingIcon =
    photoDate.status === 'pending' ? (
      <LoadingWrapper>
        <LoadingIcon />
        {photoDate.photoType === 'three_sixty_stitched' && (
          <PanoProcessingText>{l10n(Text.processingPano)}</PanoProcessingText>
        )}
      </LoadingWrapper>
    ) : null;
  const threeSixtyComponent =
    photoDate.photoType === 'three_sixty' ||
    photoDate.photoType === 'three_sixty_stitched' ||
    photoDate.photoType === 'three_sixty_vslam' ? (
      <ThreeSixtyWrapper>
        <ThreeSixtySvgWrapper>
          <Photo360PanoSvg />
        </ThreeSixtySvgWrapper>
      </ThreeSixtyWrapper>
    ) : null;

  const handleSelectedHoveredItems: (photoId: T.Photo['id'], isHovered: boolean) => () => void =
    (photoId, isHovered) => () => {
      if (isSelected && isHovered) {
        if (isVideo(photoDate.fullUrl)) {
          dispatch(SetHoveredVideoId({ hoveredVideoId: photoId }));
        } else {
          dispatch(SetHoveredPhotoId({ hoveredId: photoId }));
        }
      } else if (isSelected && !isHovered) {
        if (isVideo(photoDate.fullUrl)) {
          dispatch(SetHoveredVideoId({ hoveredVideoId: undefined }));
        } else {
          dispatch(SetHoveredPhotoId({ hoveredId: undefined }));
        }
      }
    };

  const handleInputChange: (
    event?: SyntheticEvent<HTMLInputElement, MouseEvent>
  ) => void = event => {
    event?.stopPropagation();
    const temp = isSelected
      ? selectedMedia.filter(media => media.contentId !== photoDate.id)
      : [...selectedMedia, { type: photoDate.photoType ?? null, contentId: photoDate.id }];

    dispatch(SetSelectedMedia({ selectedMedia: temp }));
  };

  const handleInspectionPhotoClick = (
    providedPhotoId: T.Photo['id'],
    providedPhotoType: T.Photo['photoType'],
    photoStatus: T.Photo['status'],
    isThreeDMeshPresent: boolean
  ) => {
    if (
      !isIn3D &&
      (providedPhotoType === 'three_sixty' ||
        providedPhotoType === 'three_sixty_stitched' ||
        providedPhotoType === 'three_sixty_vslam') &&
      photoStatus !== 'pending' &&
      isThreeDMeshPresent
    ) {
      const actions: Action[] = [];

      actions.push(ChangeIn3D({ in3D: true }));
      actions.push(
        PatchContent({
          content: {
            id: threeDMeshId!,
            config: {
              selectedAt: new Date(),
            },
          },
        })
      );
      actions.push(
        From2DTo3D360({
          isFrom2DTo3D360: true,
          photoId: providedPhotoId,
        })
      );

      batch(() => {
        actions.forEach(dispatch);
      });
    }

    handlePhotoListItemClick(providedPhotoId, providedPhotoType, photoStatus, isThreeDMeshPresent);
  };

  useEffect(() => {
    if (
      selectedPhotoAlbumIdNew.photoId === photoDate.id &&
      selectedPhotoAlbumIdNew.photoType === photoDate.photoType &&
      ref.current
    ) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedPhotoAlbumIdNew]);

  return (
    <PhotoInspectionListItemWrapper isSelected={isSelected} ref={ref}>
      {isVisible && (
        <PhotoInspectionListItem
          isSelected={isSelected}
          onMouseEnter={handleSelectedHoveredItems(photoDate.id, true)}
          onMouseLeave={handleSelectedHoveredItems(photoDate.id, false)}
          url={photoDate.status !== 'pending' ? photoDate.thumbUrl : ''}
          onClick={() => {
            if (photoDate.status === T.PhotoProcessStatus.PENDING) {
              return;
            }
            handleInspectionPhotoClick(
              photoDate.id,
              photoDate.photoType,
              photoDate.status,
              isThreeDMeshAvailable
            );
          }}
          id={`photo_${photoDate.id}`}
        >
          {loadingIcon}
          {threeSixtyComponent}
          <Wrapper>
            <Checkbox checked={isSelected} onChange={() => handleInputChange()} />
          </Wrapper>

          {photoDate.status !== 'pending' && (
            <Tippy
              offset={T.TIPPY_OFFSET}
              theme="angelsw"
              placement="top"
              arrow={false}
              content={phototooltipContent()}
            >
              <PhotoSvgWrapper onClick={handlePhotoOnMapId} disabled={false}>
                <PhotoCameraSvg />
              </PhotoSvgWrapper>
            </Tippy>
          )}
        </PhotoInspectionListItem>
      )}
    </PhotoInspectionListItemWrapper>
  );
});

export const PhotoInspectionItem: FC<PhotoItemProps> = RawPhotoItem;
