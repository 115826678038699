import { makeV2APIURL } from '^/store/duck/API';
import * as T from '^/types';
import { http } from '^/utilities/api';

export const VIEWPORTS_LIMIT = 10;

export const requestDroneCapture = async (photoAlbumId: T.RequestPhoto['id']) => {
  const URL = makeV2APIURL('photo_albums', photoAlbumId, 'request');
  const res = await http.post(URL);
  return res.data.data as T.PhotoAlbum;
};
