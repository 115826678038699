import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { MediaQuery } from '^/constants/styles';
import * as T from '^/types';

const Logo = styled.img({
  maxWidth: '100%',
  maxHeight: '100%',
  opacity: 0.6,

  backdropFilter: 'blur(0)',

  pointerEvents: 'none',
  userSelect: 'none',

  [MediaQuery[T.Device.MOBILE_L]]: {
    display: 'none',
  },
});

const ProjectLogo: FC = () => {
  const projects = useSelector((state: T.State) => state.Projects.projects);
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);

  const project: T.Project | undefined =
    projectId !== undefined && projects.allIds.includes(projectId)
      ? projects.byId[projectId]
      : undefined;

  if (project?.logo === undefined) {
    return null;
  }

  return <Logo src={project.logo} alt="project-logo" />;
};

export default memo(ProjectLogo);
