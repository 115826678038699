import { autobind } from 'core-decorators';
import { SpriteMaterial, TextureLoader, Color } from 'three';
const TEXTURE_LOADER = new TextureLoader();
export class SpriteMaterialPool {
  private readonly pool: Map<string, SpriteMaterial> = new Map();
  private readonly baseMaterial: SpriteMaterial;
  private readonly svgFunc: Function;
  public constructor(baseMaterial: SpriteMaterial, svgFunc: Function) {
    this.baseMaterial = baseMaterial;
    this.svgFunc = svgFunc;
  }
  @autobind
  public getMaterial(colorString: string): SpriteMaterial {
    if (!this.pool.has(colorString)) {
      const cloneMaterial = this.baseMaterial.clone();
      cloneMaterial.map = TEXTURE_LOADER.load(this.svgFunc(new Color(colorString)), texture => {
        /**
         * TODO: @ebraj-angelswing
         * Only for 360, maybe will be removed later...
         */
        texture.colorSpace = 'srgb';
      });
      this.pool.set(colorString, cloneMaterial);
    }
    return this.pool.get(colorString) ?? new SpriteMaterial();
  }

  @autobind
  public reset() {
    this.pool.clear();
  }
  @autobind
  public dispose() {
    this.pool.forEach(v => v.dispose());
    this.reset();
  }
}
