import { Language } from '^/types';

export default {
  weekday: {
    [Language.KO_KR]: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    [Language.EN_US]: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  year: {
    [Language.KO_KR]: '년',
    [Language.EN_US]: '',
  },
  month: {
    [Language.KO_KR]: [
      '1월',
      '2월',
      '3월',
      '4월',
      '5월',
      '6월',
      '7월',
      '8월',
      '9월',
      '10월',
      '11월',
      '12월',
    ],
    [Language.EN_US]: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  date: {
    [Language.KO_KR]: '일',
    [Language.EN_US]: '',
  },

  scheduleDetails: {
    [Language.KO_KR]: '비행 일정 세부정보',
    [Language.EN_US]: 'Schedule Details',
  },
  taskActions: {
    [Language.KO_KR]: '동작',
    [Language.EN_US]: 'Task Actions',
  },

  fileName: {
    [Language.KO_KR]: '이름',
    [Language.EN_US]: 'Name',
  },
  scheduleType: {
    [Language.KO_KR]: '일정 유형',
    [Language.EN_US]: 'Schedule Type',
  },
  beginTime: {
    [Language.KO_KR]: '시작 시각',
    [Language.EN_US]: 'Begin Time',
  },
  endTime: {
    [Language.KO_KR]: '종료 시각',
    [Language.EN_US]: 'End Time',
  },
  rthAltitude: {
    [Language.KO_KR]: 'Rth 고도',
    [Language.EN_US]: 'Rth Altitude',
  },
  createdAt: {
    [Language.KO_KR]: '생성된 날짜',
    [Language.EN_US]: 'Created At',
  },
  buttons: {
    startResume: {
      [Language.KO_KR]: '시작/재개',
      [Language.EN_US]: 'Start/Resume',
    },
    stop: {
      [Language.KO_KR]: '중지',
      [Language.EN_US]: 'Stop',
    },
    cancel: {
      [Language.KO_KR]: '취소',
      [Language.EN_US]: 'Cancel',
    },
  },
};
