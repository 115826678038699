import React, { FC, ReactNode, useState, useEffect } from 'react';

export interface Props {
  delay: number;
  children: ReactNode;
}

/**
 * Class for self-destructing component after a delay of some time.
 * Useful when you want to unmount a component after a few seconds, etc.
 */
const Expire: FC<Props> = ({ delay, children }) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [timer, setTimer] = useState<number | null>();

  useEffect(() => {
    setTimer(
      window.setTimeout(() => {
        setVisible(false);
      }, delay)
    );

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    setTimer(
      window.setTimeout(() => {
        setVisible(false);
      }, delay)
    );
  }, [children]);

  return visible ? <div>{children}</div> : null;
};

export default Expire;
