import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import * as T from '^/types';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import { useDispatch, useSelector } from 'react-redux';
import { useDownloadImages } from '^/utilities/useDownloadImages';
import { simpleDownload } from '^/utilities/download';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import { usePhotoAlbumIdQuery } from '../api/photos/usePhoto';
import { SetSelectedMedia } from '^/store/duck/Photos';

const useSelectedActions = () => {
  const selectedMedia = useSelector((s: T.State) => s.Photos.selectedMedia);
  const photos = useSelector((s: T.State) => s.Photos.photos);
  const { pathname } = useLocation();
  const isTimelapseMode = pathname.endsWith(`/${T.PhotoPathType.LOCATION}`);
  const { albumId } = useParams();

  const dispatch = useDispatch();

  const { handleDownloadImages } = useDownloadImages(photos);
  const { photos: photosFromAlbum } = usePhotoAlbumIdQuery(Number(albumId));

  const { timelapseInspectionImages, setlastSelectedPhotoId } = useTimelapseStore(s => ({
    timelapseInspectionImages: s.timelapseInspectionImages,
    setlastSelectedPhotoId: s.setlastSelectedPhotoId,
  }));

  const handleDownload = useCallback(async () => {
    let selectedPhotos: T.FinalPhoto[] = [];
    if (photosFromAlbum.length) {
      selectedPhotos = photosFromAlbum.filter(pht =>
        selectedMedia.some(media => media.contentId === pht.id)
      );
    } else if (isTimelapseMode) {
      selectedPhotos = timelapseInspectionImages.filter(pht =>
        selectedMedia.some(media => media.contentId === pht.id)
      );
    } else {
      selectedPhotos = photos.filter(pht =>
        selectedMedia.some(media => media.contentId === pht.id)
      );
    }
    if (selectedPhotos.length === 1) {
      simpleDownload(selectedPhotos[0].fullUrl, selectedPhotos[0].originalFilename);
    } else {
      await handleDownloadImages(selectedMedia);
    }
  }, [photos, selectedMedia, photosFromAlbum]);

  const handleDeletePhotos = useCallback(() => {
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.DELETE_PHOTO }));
  }, []);

  const handleTagPhotos = () => {
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.TAG_DATE_POPUP }));
  };
  const handleResetPhotoItems = () => {
    dispatch(SetSelectedMedia({ selectedMedia: [] }));
    setlastSelectedPhotoId(undefined);
  };

  return {
    handleDownload,
    handleDeletePhotos,
    handleTagPhotos,
    handleResetPhotoItems,
  };
};

export default useSelectedActions;
