import React, { useState, useEffect, FC, useMemo } from 'react';
import { preloadImages } from './utils';
import Timelapse from './Timelapse';

import LoadingIcon from '^/components/atoms/LoadingIcon';
import styled from 'styled-components';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import * as T from '^/types';

const Root = styled.div({
  position: 'fixed',
  zIndex: 999,
  inset: '0',
  overflow: 'hidden',
  backgroundColor: 'rgba(44, 50, 60, 0.96)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const LoadingWrapper = styled.div({
  inset: '0',
  margin: 'auto',
});

export const ParentTimeLapse: FC = () => {
  const [loading, setLoading] = useState(true);
  const { timelapseImages } = useTimelapseStore(s => ({
    timelapseImages: s.timelapseImages,
  }));

  const sortByDate = (a: T.FinalPhoto, b: T.FinalPhoto) => {
    if (!a.takenAt || !b.takenAt) {
      return -1;
    }
    return new Date(a.takenAt).getTime() - new Date(b.takenAt).getTime();
  };

  const sortedImages = useMemo(() => [...timelapseImages].sort(sortByDate), [timelapseImages]);

  const timelapseImagesURL = useMemo(
    () => sortedImages.map(image => image.fullUrl),
    [sortedImages]
  );
  const photoIds = useMemo(() => sortedImages.map(image => image.id), [sortedImages]);

  const loadingContainer = (
    <LoadingWrapper>
      <LoadingIcon />
    </LoadingWrapper>
  );

  useEffect(() => {
    preloadImages(timelapseImagesURL)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [timelapseImages]);

  return (
    <Root>
      {loading ? loadingContainer : <Timelapse photos={timelapseImagesURL} photoIds={photoIds} />}
    </Root>
  );
};
