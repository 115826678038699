/**
 * TODO: @ebraj-angelswing
 * It is built with reference to the issue-category dropdown component, which is based on the class component...
 * Have to refactor more...
 */

import Color from 'color';
import _ from 'lodash-es';
import React, { Fragment, ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars-2';

import FaIcon from '^/components/atoms/FaIcon';
import palette from '^/constants/palette';

export interface Option<K> {
  text: string;
  value: string | number;
  infos: K;
}

export const createOption = <K extends { id: string | number; title: string }>(
  value: K
): Option<K> => ({
  text: value.title,
  value: value.id,
  infos: value,
});

export const createOptions = <K extends { id: string | number; title: string }>(
  values: K[]
): Array<Option<K>> => (values ?? []).map(createOption);

const borderWidth: number = 1;

interface RootProps {
  readonly rootWidth?: string;
  readonly rootHeight?: string;
}
const Root = styled.div<RootProps>(({ rootWidth, rootHeight }) => ({
  flexShrink: 0,

  width: rootWidth ?? '100%',
  height: rootHeight ?? '30px',
}));

interface WrapperProps {
  readonly zIndex: number;
  readonly centerPos?: string;
  readonly nonFaFontSize?: string;
}
const Wrapper = styled.div<WrapperProps>(({ zIndex, centerPos, nonFaFontSize }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  color: palette.textLight.toString(),

  '& *': {
    lineHeight: centerPos ?? '28px',
    color: palette.textLight.toString(),

    ':not(.fa)': {
      fontSize: nonFaFontSize ?? '12px',
    },
  },

  zIndex,
}));

interface MainButtonProps {
  readonly mainButtonStyle?: CSSObject;
  readonly error?: boolean;
}
const MainButton = styled.button<MainButtonProps>(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    boxSizing: 'border-box',
    width: '100%',
    height: '100%',

    borderWidth: `${borderWidth}px`,
    borderStyle: 'solid',
    borderRadius: '5px',

    textAlign: 'left',
    cursor: 'pointer',
  },
  ({ error, mainButtonStyle }) => {
    const buttonStyle: CSSObject =
      mainButtonStyle !== undefined
        ? {
            ...mainButtonStyle,
            '&&&': mainButtonStyle,
          }
        : {};

    return {
      ...buttonStyle,
      borderColor: (error ? palette.error : palette.border).toString(),
      backgroundColor: error
        ? palette.DDMInput.error.alpha(0.05).toString()
        : palette.white.toString(),
    };
  }
);

const SearchIconWrapper = styled.span({
  marginLeft: '10px',
});

interface ValueWrapperProps {
  readonly centerPos?: string;
}
const ValueWrapper = styled.span<ValueWrapperProps>(({ centerPos }) => ({
  marginLeft: '9px',
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  pointerEvents: 'none',

  lineHeight: centerPos ?? '28px',
}));

const ValueEditor = styled.input({
  marginLeft: '9px',
  flexGrow: 1,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  backgroundColor: 'transparent',
});

interface CaretProps {
  readonly caretStyle?: CSSObject;
}

const Caret = styled.i.attrs({
  className: 'fa fa-caret-down',
})<CaretProps>(
  {
    marginRight: '9px',

    fontSize: '10px',
    color: `${Color('black').toString()}`,
  },
  ({ caretStyle }) =>
    caretStyle !== undefined
      ? {
          ...caretStyle,
          '&&&': caretStyle,
        }
      : {}
);

const Paper = styled.div({
  position: 'absolute',

  boxSizing: 'border-box',
  top: '100%',
  width: '100%',

  borderWidth: `${borderWidth}px`,
  borderStyle: 'solid',
  borderColor: palette.border.toString(),
  borderRadius: '5px',

  backgroundColor: palette.white.toString(),

  overflow: 'hidden',
});

interface MenuProps {
  readonly menuStyle?: CSSObject;
  readonly height?: CSSObject['height'];
}
export const Menu = styled.ul.attrs<any>({
  'data-testid': 'dropdown-menu',
})<MenuProps>({}, ({ menuStyle, height }) =>
  menuStyle !== undefined
    ? {
        ...menuStyle,
        '&&&': menuStyle,
        height,
      }
    : { height }
);

interface MenuWrapperProps {
  isLimitExceed: boolean;
}

export const MenuWrapper = styled.div<MenuWrapperProps>(({ isLimitExceed }) => ({
  boxSizing: 'border-box',
  width: 'calc(100% + 2px)',
  /**
   * Due to compatibility of react scrollbars,
   * I needed to make the item-count criterium and use fixed height.
   */
  height: isLimitExceed ? '182px' : '',
  marginLeft: '-1px',
  borderRadius: '0 0 5px 5px',
  borderLeft: `1px solid ${palette.border.toString()}`,
  borderRight: `1px solid ${palette.border.toString()}`,
  borderBottom: `1px solid ${palette.border.toString()}`,
}));

const ItemCSS: CSSObject = {
  display: 'block',

  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  paddingLeft: '10px',

  fontWeight: 300,

  textAlign: 'left',
  backgroundColor: palette.white.toString(),

  cursor: 'pointer',
};

const ItemLabel = styled.li.attrs<any>({
  'data-testid': 'dropdown-item-label',
})<ItemProps>({
  ...ItemCSS,
  fontWeight: 500,
  pointerEvents: 'none',
});

interface ItemProps {
  readonly itemStyle?: CSSObject;
}

const Item = styled.li.attrs<any>({
  'data-testid': 'dropdown-item',
})<ItemProps>(
  {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    height: '26px',
    padding: '4px 10px',

    textAlign: 'left',
    color: palette.textLight.toString(),
    borderBottom: `1px solid ${palette.ContentsList.inputBorder.toString()}`,

    cursor: 'pointer',
    ':hover': {
      color: '#737373',
      backgroundColor: '#E1E1E1',
    },
    ':last-of-type': {
      border: 'none',
    },
  },
  ({ itemStyle }) =>
    itemStyle !== undefined
      ? {
          ...itemStyle,
          '&&&': itemStyle,
        }
      : {}
);

const itemLabelValue: string = '[]';

interface StyleProps
  extends RootProps,
    WrapperProps,
    MainButtonProps,
    CaretProps,
    MenuProps,
    ItemProps {}
export interface Props<K> extends StyleProps {
  readonly valueId: string | number | undefined;
  readonly options: Array<Option<K>>;
  readonly placeHolder: string;
  readonly className?: string;
  readonly error?: boolean;
  readonly isSearchEnable?: boolean;
  readonly trackAction?: string;
  readonly trackLabel?: string;
  onClick(option: Option<K>, index: number): void;
}

export interface State<K> {
  readonly isOpen: boolean;
  readonly keyword: string;
  readonly filteredOptions: Array<Option<K>>;
}

/**
 * Dropdown element
 */

const Dropdown = <K,>({
  options,
  valueId,
  placeHolder,
  isSearchEnable,
  onClick,
  itemStyle,
  menuStyle,
  height,
  mainButtonStyle,
  caretStyle,
  error,
  className,
  trackAction,
  trackLabel,
  rootWidth,
  rootHeight,
  zIndex = 0,
  centerPos,
  nonFaFontSize,
}: Props<K>) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<Array<Option<K>>>(options);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const root = rootRef.current;
      if (root && !root.contains(event.target as Node) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('touchend', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('touchend', handleDocumentClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!_.isEqual(options, filteredOptions)) {
      setFilteredOptions(options);
    }
  }, [options]);

  const optionToElement = (option: Option<K>, index: number): ReactNode => {
    const onClickHandler = (event: SyntheticEvent) => handleItemClick(event, option, index);

    const item =
      option.text === itemLabelValue ? (
        <ItemLabel>{option.text}</ItemLabel>
      ) : (
        <Item
          className={option.value === valueId ? 'active' : undefined}
          itemStyle={itemStyle}
          onClick={onClickHandler}
        >
          {option.text}
        </Item>
      );

    return <Fragment key={index}>{item}</Fragment>;
  };

  const handleButtonClick = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleSearchChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const keyword = _.toLower(event.currentTarget.value.trim());

    const matches = keyword.match(/[a-zA-Z\s]+/gi);
    if (keyword.length > 0 && matches && matches.length > 1) {
      return;
    }

    const hasKeyword = ({ text, value }: Option<K>) =>
      _.toLower(text).indexOf(keyword) > -1 || _.toLower(value.toString()).indexOf(keyword) > -1;

    const allFilteredOptions = keyword.length === 0 ? options : options.filter(hasKeyword);

    setFilteredOptions(allFilteredOptions);
  };

  const handleItemClick = (event: SyntheticEvent, option: Option<K>, index: number) => {
    setIsOpen(false);
    onClick(option, index);
    event.preventDefault();
  };

  const selectedOption = options.find(({ value }) => value === valueId);
  const selectedValue = selectedOption ? selectedOption.text : placeHolder;

  const isLimitExceed = filteredOptions.length > 7;

  const menu = isOpen ? (
    <MenuWrapper isLimitExceed={isLimitExceed}>
      {isLimitExceed ? (
        <Scrollbars>
          <Menu menuStyle={menuStyle} height={height}>
            {filteredOptions.map(optionToElement)}
          </Menu>
        </Scrollbars>
      ) : (
        <Menu menuStyle={menuStyle} height={height}>
          {filteredOptions.map(optionToElement)}
        </Menu>
      )}
    </MenuWrapper>
  ) : null;

  const dropdownPaper = isOpen ? <Paper>{menu}</Paper> : null;

  const searchIcon = isSearchEnable ? (
    <SearchIconWrapper>
      <FaIcon faNames="search" fontSize="15px" data-testid="dropdown-search-icon" />
    </SearchIconWrapper>
  ) : undefined;

  const valueViewer =
    isOpen && isSearchEnable ? (
      <ValueEditor
        autoFocus={true}
        onChange={handleSearchChange}
        data-testid="dropdown-value-input"
      />
    ) : (
      <ValueWrapper data-testid="dropdown-value" centerPos={centerPos}>
        {selectedValue}
      </ValueWrapper>
    );

  return (
    <Root
      className={className}
      ref={rootRef}
      data-ddm-track-action={trackAction}
      data-ddm-track-label={trackLabel}
      rootWidth={rootWidth}
      rootHeight={rootHeight}
      onKeyDown={event => {
        if (isOpen && event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          event.nativeEvent.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
        }
      }}
    >
      <Wrapper
        zIndex={zIndex + (isOpen ? 1 : 0)}
        centerPos={centerPos}
        nonFaFontSize={nonFaFontSize}
      >
        <MainButton
          mainButtonStyle={mainButtonStyle}
          error={error}
          onClick={handleButtonClick}
          type="button"
          data-testid="dropdown-mainbutton"
        >
          {searchIcon}
          {valueViewer}
          <Caret caretStyle={caretStyle} data-testid="dropdown-caret" />
        </MainButton>
        {dropdownPaper}
      </Wrapper>
    </Root>
  );
};

export default Dropdown;
