export async function runPromisesInBatches<T = any>(
  promises: Array<() => Promise<T>>,
  batchLimit = 5
): Promise<Array<PromiseSettledResult<T>>> {
  let index = 0;
  const results: Array<Promise<PromiseSettledResult<T>>> = new Array(promises.length);
  const executing: Array<Promise<void>> = [];

  const enqueue = async (): Promise<void> => {
    if (index >= promises.length) {
      return Promise.resolve();
    }
    const i = index++;
    const promise = promises[i]().then(
      value => ({ status: 'fulfilled', value } satisfies PromiseFulfilledResult<T>),
      reason => ({ status: 'rejected', reason } satisfies PromiseRejectedResult)
    );
    results[i] = promise;
    const e = promise.then(() => {
      executing.splice(executing.indexOf(e), 1);
    });
    executing.push(e);
    if (executing.length >= batchLimit) {
      await Promise.race(executing);
    }
    return enqueue();
  };

  await enqueue();
  return Promise.all(results);
}
