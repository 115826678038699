import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getUnixTime, parseISO } from 'date-fns';

import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useAuthHeader } from '^/hooks';
import { droneStationAuthStore } from '^/store/droneStationAuthStore';
import { AuthHeader } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { APIToContent } from '^/store/duck/Contents';
import { ChangeContentsSidebarTab, OpenContentPagePopup } from '^/store/duck/Pages';
import { useFlightScheduleStore } from '^/store/flightScheduleStore';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { ERROR } from '../types';
import { getFlightScheduleFetchUrl, getFlightSchedulePostUrl } from './common';

export interface RawPagination {
  readonly page: number;
  readonly total: number;
  readonly page_size: number;
}

export interface TGetFlightScheduleResponse {
  readonly code: number;
  readonly message: string;
  readonly data: {
    readonly list: T.RawFlightSchedule[];
    readonly pagination: RawPagination;
  };
}

interface TGetAllFlightSchedules {
  readonly pageParam: number;
  readonly pageLimit?: number;
  readonly projectId: T.Project['id'] | undefined;
  readonly selectedFlightScheduleSort: T.FlightScheduleSortByCriteria;
  readonly authHeader: AuthHeader | undefined;
}
export enum FLIGHT_SCHEDULE_QUERY {
  GetAllFlightSchedules = 'GetAllFlightSchedules',
}

async function getAllFlightSchedules({
  pageParam,
  pageLimit,
  projectId,
  authHeader: paramAuthHeader,
}: TGetAllFlightSchedules) {
  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  // const sortOrOrderBy = (() => {
  //   switch (selectedFlightScheduleSort) {
  //     case T.FlightScheduleSortByCriteria.OLDEST:
  //       return 'update_time asc';
  //     // case T.FlightScheduleSortByCriteria.NAME:
  //     case T.FlightScheduleSortByCriteria.NEWEST:
  //     default:
  //       return 'update_time desc';
  //   }
  // })();

  const flightScheduleFetchUrl = getFlightScheduleFetchUrl({
    page: pageParam || 1,
    page_size: pageLimit || 10,
  });

  const fetchFlightScheduleResponse = await http.get<TGetFlightScheduleResponse>(
    flightScheduleFetchUrl,
    {
      headers: {
        Authorization: `Bearer ${paramAuthHeader.Authorization}`,
        projectId: projectId,
        'X-Auth-Token': droneStationAuthStore.getState().authDetails.accessToken,
      },
    }
  );

  const flightSchedules = fetchFlightScheduleResponse.data.data.list.map(rawFlightSchedule => {
    const serializedFlightSchedules = {
      jobId: rawFlightSchedule.job_id,
      jobName: rawFlightSchedule.job_name,
      fileId: rawFlightSchedule.file_id,
      dockSn: rawFlightSchedule.dock_sn,
      workspaceId: rawFlightSchedule.workspace_id,
      waylineType: rawFlightSchedule.wayline_type,
      taskType: rawFlightSchedule.task_type,
      beginTime: rawFlightSchedule.begin_time,
      endTime: rawFlightSchedule.end_time,
      // status: rawFlightSchedule.status,
      username: rawFlightSchedule.username,
      rthAltitude: rawFlightSchedule.rth_altitude,
      outOfControlAction: rawFlightSchedule.out_of_control_action,
      mediaCount: rawFlightSchedule.media_count,
    } as T.FlightSchedule;
    return APIToContent({
      type: T.ContentType.FLIGHT_SCHEDULE,
      projectId,
      ...serializedFlightSchedules,
    } as T.APIFlightScheduleContent);
  });

  return {
    list: [...flightSchedules],
    pagination: fetchFlightScheduleResponse.data.data.pagination,
  };
}

export const useGetFlightSchedulesQuery = () => {
  const authHeader = useAuthHeader();
  const projectId: T.Project['id'] | undefined = useSelector(
    (state: T.State) => state.Pages.Contents.projectId
  );
  const dispatch: Dispatch = useDispatch();

  const { selectedFlightScheduleSort, setFlightSchedules } = useFlightScheduleStore(s => ({
    selectedFlightScheduleSort: s.selectedFlightScheduleSort,
    setFlightSchedules: s.setFlightSchedules,
  }));

  return useInfiniteQuery({
    queryKey: [FLIGHT_SCHEDULE_QUERY.GetAllFlightSchedules, selectedFlightScheduleSort],
    queryFn: async ({ pageParam }) =>
      getAllFlightSchedules({ pageParam, projectId, selectedFlightScheduleSort, authHeader }),
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    getNextPageParam: lastPage => {
      const { pagination } = lastPage;
      const { page, total, page_size } = pagination;
      const totalPages = Math.ceil(total / page_size);

      if (page < totalPages) {
        return page + 1;
      } else {
        return undefined;
      }
    },
    onSuccess(data) {
      const receievedFlightSchedules = data.pages.flatMap(page => page.list) || [];
      const modifiedReceievedFlightSchedules = receievedFlightSchedules.reduce(
        (acc, flightSchedule) => ({ ...acc, [flightSchedule.id]: flightSchedule }),
        {}
      );
      setFlightSchedules(modifiedReceievedFlightSchedules);
    },
    onError: (error: AxiosError) => {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      }
    },
  });
};

/**
 * TODO: @ebraj-angelswing
 * WIP...
 */
export interface TPostFlightScheduleResponse {
  readonly code: number;
  readonly message: string;
  readonly data?: Record<string, any>;
}

function convertTimeToISO(dateString?: string, timeString?: string) {
  if (!dateString || !timeString) {
    return;
  }
  const date = new Date(dateString);
  const [time, period] = timeString.split(' ');
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const adjustedHours =
    period === 'PM' && hours !== 12 ? hours + 12 : period === 'AM' && hours === 12 ? 0 : hours;

  date.setUTCHours(adjustedHours, minutes, seconds, 0);
  return date.toISOString();
}

function getPostFlightSchedulePayload(datas: T.RawFlightScheduleFields) {
  const {
    title,
    selectedFlightPlan,
    selectedDroneStation,
    selectedPlanTimer,
    selectedLostAction,
    rthAltitude,
    selectedExecuteDate,
    selectedExecuteTime,
    selectedExecuteEndTime,
    batteryLevel,
    storageLevel,
  } = datas;

  const minBatteryCapacityForTimed: number = 90;
  const defaultSelectTimeNumber: number = 1;
  const defaultWaylineType: number = 1;

  const executeDateForImmediateType = new Date();
  const selectedExecuteDateToTaskDays = {
    from: getUnixTime(selectedExecuteDate.from!),
    to: getUnixTime(selectedExecuteDate.to!),
  };

  const selectedExecuteTimeInFormat = convertTimeToISO(
    selectedExecuteDate.from?.toISOString(),
    selectedExecuteTime
  );
  const selectedExecuteTimeToTaskPeriods = getUnixTime(parseISO(selectedExecuteTimeInFormat!));

  const selectedExecuteEndTimeInFormat = convertTimeToISO(
    selectedExecuteDate.from?.toISOString(),
    selectedExecuteEndTime
  );
  const selectedExecuteEndTimeToTaskPeriods = getUnixTime(
    parseISO(selectedExecuteEndTimeInFormat!)
  );

  const basePayload = {
    name: title,
    file_id: selectedFlightPlan?.id,
    dock_sn: selectedDroneStation?.info.deviceSn,
    wayline_type: defaultWaylineType, // hard-coded value for now...
    select_time_number: defaultSelectTimeNumber, // hard-coded value for now...
    rth_altitude: rthAltitude,
    out_of_control_action: selectedLostAction,
    task_type: selectedPlanTimer,
  };

  const extraPayloads = (() => {
    switch (selectedPlanTimer) {
      case T.FlightScheduleScheduleType.TIMED:
        return {
          select_time: [[selectedExecuteTimeInFormat]],
          task_days: [selectedExecuteDateToTaskDays.from],
          task_periods: [[selectedExecuteTimeToTaskPeriods]],
          select_execute_date: [
            selectedExecuteDate.from?.toISOString(),
            selectedExecuteDate.to?.toISOString(),
          ],
          min_battery_capacity: minBatteryCapacityForTimed,
        };
      case T.FlightScheduleScheduleType.CONTINUOUS:
        return {
          select_time: [[selectedExecuteTimeInFormat, selectedExecuteEndTimeInFormat]],
          task_days: [selectedExecuteDateToTaskDays.from, selectedExecuteDateToTaskDays.to],
          task_periods: [[selectedExecuteTimeToTaskPeriods, selectedExecuteEndTimeToTaskPeriods]],
          select_execute_date: [
            selectedExecuteDate.from?.toISOString(),
            selectedExecuteDate.to?.toISOString(),
          ],
          min_battery_capacity: batteryLevel,
          min_storage: storageLevel,
        };
      case T.FlightScheduleScheduleType.IMMEDIATE:
      default:
        return {
          select_time: [[]],
          taskPeriods: [],
          task_days: [getUnixTime(executeDateForImmediateType)],
          select_execute_date: [
            executeDateForImmediateType.toISOString(),
            executeDateForImmediateType.toISOString(),
          ],
        };
    }
  })();

  return { ...basePayload, ...extraPayloads };
}

interface TPostFlightSchedule {
  datas: T.RawFlightScheduleFields;
  projectId: T.Project['id'] | undefined;
  authHeader: AuthHeader | undefined;
}
async function postFlightSchedule({
  datas,
  projectId,
  authHeader: paramAuthHeader,
}: TPostFlightSchedule) {
  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const { accessToken } = droneStationAuthStore.getState().authDetails;

  const authHeader = {
    Authorization: `Bearer ${paramAuthHeader.Authorization}`,
    projectId: projectId,
    'X-Auth-Token': accessToken,
  };

  /**
   * TODO: @ebraj-angelswing
   * WIP
   */
  const payload = getPostFlightSchedulePayload(datas);

  try {
    const response = await http.post<TPostFlightScheduleResponse>(
      getFlightSchedulePostUrl(),
      payload,
      { headers: authHeader }
    );

    if (response.data.code === -1) {
      throw new Error(response.data.message || 'Something went wrong. Please try again.');
    }
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const usePostFlightScheduleMutation = () => {
  const queryClient = useQueryClient();
  const dispatch: Dispatch = useDispatch();

  const { mutate: postFlightScheduleMutation } = useMutation({
    mutationFn: postFlightSchedule,
    onSuccess: async () => {
      await queryClient.invalidateQueries([FLIGHT_SCHEDULE_QUERY.GetAllFlightSchedules]);
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.FLIGHT_SCHEDULE_CREATION_SUCCESS,
        })
      );
    },
    onError: (error: AxiosError) => {
      void queryClient.invalidateQueries([FLIGHT_SCHEDULE_QUERY.GetAllFlightSchedules]);
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      }
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.FLIGHT_SCHEDULE_CREATION_FAIL,
          errorMessage: error.message,
        })
      );
    },
    onSettled: () => {
      dispatch(
        ChangeContentsSidebarTab({
          sidebarTab: T.ContentPageTabType.FLIGHT_SCHEDULE,
        })
      );
    },
  });

  return postFlightScheduleMutation;
};
