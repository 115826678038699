import React, { FC, MutableRefObject, useRef, useState } from 'react';
import styled from 'styled-components';
import CloseSvg from '^/assets/icons/close-new-thin.svg';
import dsPalette from '^/constants/ds-palette';
import { getDSMColorPickerPalette } from '^/constants/palette';
import Color from 'color';
import DSMColorPickerPopup from '^/components/atoms/DSMColorPickerPopup';
import { DISABLED_CONTENT_OPACITY } from '^/constants/styles';

const ColorAndHeightListItemWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
});

const ColorPickerWrapper = styled.div({
  top: '0',
  left: '0',
});

const ColorPickerButton = styled.button<{ colorValue: Color }>(({ colorValue }) => ({
  width: '20px',
  height: '20px',
  borderRadius: '5px',
  border: '1px solid #EAEFF3',
  backgroundColor: colorValue.toString(),
  cursor: 'pointer',
  ':hover': {
    border: '1px solid var(--color-theme-primary)',
  },
}));

const StyledInput = styled.input({
  width: '100%',
  height: '20px',

  border: '1px solid #EAEFF3',
  borderRadius: '5px',

  padding: '2px 5px',
  backgroundColor: '#EAEFF3',

  fontSize: '14px',
  ':focus': {
    border: '1px solid var(--color-theme-primary)',
  },
});

const DeleteButton = styled.button<{ isDisabled: boolean }>(({ isDisabled }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  height: '25px',
  width: 'fit-content',

  cursor: isDisabled ? 'default' : 'pointer',
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  borderRadius: '5px',
  ':hover': {
    backgroundColor: isDisabled ? '' : '#E5E5E5',
  },
}));

const CloseIcon = styled(CloseSvg)({
  fill: dsPalette.title.toString(),
  transform: 'scale(0.3)',
});

const ColorPickerPopupWrapper = styled.div({
  position: 'absolute',
  top: '100%',
  left: '0',
  zIndex: '50',
});

interface Props {
  id: number;
  colorValue: Color;
  height: number;
  onDelete(id: number): void;
  isDeleteDisabled: boolean;
  onHeightChange(id: number, height: number): void;
  onColorChange(id: number, color: Color): void;
}

const paletteColors: Color[][] = getDSMColorPickerPalette();

export const ColorAndHeightListItem: FC<Props> = ({
  id,
  colorValue,
  height,
  onDelete,
  isDeleteDisabled,
  onHeightChange,
  onColorChange,
}) => {
  const [isColorEditing, setIsColorEditing] = useState<boolean>(false);
  const parentRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const handleColorEdit: () => void = () => {
    setIsColorEditing(prev => !prev);
  };

  const handleClickOutside = () => {
    if (isColorEditing) {
      setIsColorEditing(false);
    }
  };

  const handleColorClick = (color: Color) => {
    onColorChange(id, color);
    setIsColorEditing(false);
  };

  return (
    <ColorAndHeightListItemWrapper>
      <ColorPickerWrapper ref={parentRef}>
        <ColorPickerButton colorValue={colorValue} onClick={handleColorEdit} />
        <ColorPickerPopupWrapper>
          {isColorEditing && (
            <DSMColorPickerPopup
              parentRef={parentRef}
              clickedColor={colorValue}
              colors={paletteColors}
              setColor={handleColorClick}
              onClickOutside={handleClickOutside}
            />
          )}
        </ColorPickerPopupWrapper>
      </ColorPickerWrapper>
      <StyledInput
        type="text"
        value={height}
        onChange={e => {
          if (Number.isNaN(Number(e.target.value))) {
            return;
          }
          onHeightChange(id, Number(e.target.value));
        }}
      />
      <DeleteButton
        isDisabled={isDeleteDisabled}
        onClick={() => onDelete(id)}
        data-testid="delete-color-height-list"
      >
        <CloseIcon />
      </DeleteButton>
    </ColorAndHeightListItemWrapper>
  );
};
