import styled from 'styled-components';

import colorTokens from '^/theme/colors/color-tokens';

export interface Props {
  readonly error?: boolean;
}
const DDMInput = styled.input<Props>(({ error }) => ({
  boxSizing: 'border-box',

  width: '100%',
  height: '48px',
  padding: '0 12px',

  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '8px',
  background: colorTokens.inputBackgroundColor,
  fontSize: '13px',
  fontWeight: 'normal',
  color: colorTokens.textGrayDark,

  '::placeholder': {
    color: colorTokens.disabledGrayDarker,
  },
  borderColor: error ? colorTokens.errorRed : colorTokens.inputBorderColor,
}));

export default DDMInput;
