import { ExifrParsedImage, useExifrPhotosStore } from '^/store/exifrParsedPhotosStore';
import { parse } from 'exifr';

interface ParsedRawImage {
  invalidFiles: File[];
  imagesWithoutLocation: File[];
  imagesWithLocation: ExifrParsedImage[];
}

export const useExifr = () => {
  const {
    setParsedPhotos,
    setProgressPercent,
    setRenderProgress,
    imagesWithLocation: existingParsedFiles,
  } = useExifrPhotosStore();

  const parseRawImage = async (images: File[]): Promise<ParsedRawImage> => {
    const invalidFiles: File[] = [];
    const imagesWithoutLocation: File[] = [];
    const imagesWithLocation: ExifrParsedImage[] = [];
    setRenderProgress(true);
    for (let imageIndex = 0; imageIndex < images.length; imageIndex++) {
      const image = images[imageIndex];
      const existingFile = existingParsedFiles.find(file => file.originalFilename === image.name);
      try {
        if (existingFile) {
          imagesWithLocation.push(existingFile);
        } else {
          const buffer = await image.arrayBuffer();
          const output = await parse(buffer);
          if (output && 'latitude' in output && 'longitude' in output) {
            if (!existingFile) {
              imagesWithLocation.push({
                id: imageIndex + 1,
                latitude: output.latitude,
                longitude: output.longitude,
                originalFilename: image.name,
                takenAt: output.DateTimeOriginal,
              });
            }
          } else {
            imagesWithoutLocation.push(image);
          }
          setParsedPhotos([...imagesWithLocation]); // Need to find alternative solutions
          const parsedImagePercent = (
            ((imagesWithLocation.length + imagesWithoutLocation.length) / images.length) *
            100
          ).toFixed(0);
          setProgressPercent(Number(parsedImagePercent));
        }
      } catch {
        invalidFiles.push(image);
      }
    }
    return { imagesWithLocation, imagesWithoutLocation, invalidFiles };
  };

  return { parseRawImage };
};
