import { attachmentsStore } from '^/store/attachmentsStore';
import { calculateHash } from './file-util';
import { http } from './api/http';

export async function uploadFileToGCS(
  signedUrl: string,
  file: File,
  xMetaHeaders?: Record<`x-goog-meta-${string}`, string>
) {
  const response = await http.put(signedUrl, file, {
    headers: { 'Content-Type': file.type, ...xMetaHeaders },
    onUploadProgress(progressEvent) {
      // console.log(
      //   `Upload progress: ${Math.round((progressEvent.loaded / (progressEvent.total ?? 0)) * 100)}%`
      // );

      attachmentsStore.getState().setAttachmentUploadStatus(calculateHash(file), {
        progress: progressEvent.loaded,
        total: progressEvent.total ?? 0,
      });
    },
  });

  if (response.status !== 200) {
    throw new Error(`Failed to upload file: ${response.statusText}`);
  }

  return response.data;
}
