import React, { FC, memo } from 'react';

import LoadingIcon from '^/components/atoms/LoadingIcon';
import { LoaderWrapper } from './style';
import DefaultPhotoView from '../DefaultPhotoView';
import { usePhotoQuery } from '^/hooks/api/photos/usePhoto';
import { PhotoListContent } from './PhotoListContent';
// import PhotoAlbumsList from '../PhotoAlbum';

const PhotoList: FC = () => {
  const { groupedAlbums, isLoading: isPhotoLoading, rawPhotos } = usePhotoQuery();

  //   const droneUploads = droneVideos.filter(
  //     photo => photo?.uploadSource === 'application' || photo.uploadSource === 'drone'
  //   );
  //   const surveyUploads = droneVideos.filter(photo => photo?.uploadSource === 'survey');

  //   /**
  //    * @desc when unSortedPhotos updated or deleted all, Redirect to Photo Gallery Page
  //    */
  //   useEffect(() => {
  //     if (unSortedPhotos?.length === 0 && !showTab) {
  //       dispatch(SetPhotoDateType({ dateType: T.PhotoDateType.SORTED_PHOTO }));
  //     }
  //   }, [photos]);

  return (
    <>
      {isPhotoLoading && (
        <LoaderWrapper>
          <LoadingIcon loadingDivCustomStyle={{ height: 32, width: 32 }} />
        </LoaderWrapper>
      )}
      {groupedAlbums.size && !isPhotoLoading ? (
        <PhotoListContent groupedAlbums={groupedAlbums} />
      ) : null}
      {rawPhotos.length === 0 && !isPhotoLoading ? <DefaultPhotoView /> : null}
    </>
  );
};

export default memo(PhotoList);
