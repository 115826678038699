import { Language } from '^/types';

export default {
  firstBalloonTitle: {
    [Language.KO_KR]: '지도 평탄화하기',
    [Language.EN_US]: 'Flatten map',
  },
  visualize: {
    [Language.KO_KR]: '기준 밑면',
    [Language.EN_US]: 'Base plane',
  },
};
