import styled, { CSSObject } from 'styled-components';

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';

export interface Props {
  readonly error?: boolean;
  readonly customStyle?: CSSObject;
}
const CMInput = styled.input<Props>(({ error, customStyle }) => ({
  boxSizing: 'border-box',
  textAlign: 'right',

  width: '100%',
  height: '30px',
  padding: '0 20px',

  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '3px',

  color: dsPalette.themePrimary.toString(),

  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: '700',

  '::placeholder': {
    color: palette.textLight.toString(),
  },

  '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  borderColor: (error ? palette.error : palette.border).toString(),

  ...customStyle,
}));

export default CMInput;
