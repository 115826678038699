import { ContentToItem } from '^/components/atoms/ContentToItem';
import * as T from '^/types';
import React, { FC, ReactNode, memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isIssue, useL10n, UseL10n } from '^/hooks';
import IssueGroupOrSortBy from '^/components/molecules/IssueGroupOrSortBy';
import { GroupedContentsHeader } from '^/components/molecules/GroupedContentsHeader/ForIssueItems';
import { issuePriorityOptions, issueSortByCriteriaOptions } from '^/constants/issue';
import Text from './text';
import { isThisIssueRelatedToMe } from '^/utilities/issue';
import { useIssueStore } from '^/store/issue';

function getGroupTitle(groupTitle: string, language: T.Language) {
  const lowerTitle: string = groupTitle.toLowerCase();
  switch (lowerTitle) {
    case 'to-do':
    case 'in progress':
    case 'done':
      return Text.status[lowerTitle][language];
    default:
      return groupTitle;
  }
}

const IssueList = styled.div({
  position: 'relative',
  minHeight: '2.5px',
  paddingBottom: '2.5px',
});
IssueList.displayName = 'IssueList';

const ContentsList = styled.div({
  position: 'relative',
  minHeight: '2.5px',
  paddingBottom: '2.5px',
});
ContentsList.displayName = 'ContentsList';

const toIssuePriorityValue: Record<T.IssuePriority, number> = {
  [T.IssuePriority.LOW]: 0,
  [T.IssuePriority.HIGH]: 1,
  [T.IssuePriority.CRITICAL]: 2,
};

interface IssueGroupListProps {
  value: string | number;
  title: string;
  contentIds: Array<T.IssueContent['id']>;
  groupByCriteria: T.IssueGroupByCriteria;
}

const IssueGroupList: FC<IssueGroupListProps> = group => {
  const [l10n, language]: UseL10n = useL10n();
  const { issuesContentsById, editingIssueContentId } = useIssueStore();

  const options = issueSortByCriteriaOptions[language].filter(
    criterion => (criterion.value as string) !== (group.groupByCriteria as string)
  );

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [sortByCriteria, setSortByCriteria] = useState<T.IssueSortByCriteria>(options[0].value);

  useEffect(() => {
    if (group.contentIds.some(id => id === editingIssueContentId)) {
      setIsOpened(true);
    }
  }, [group.contentIds, editingIssueContentId]);

  const copiedContentIds = Array.from(group.contentIds);
  copiedContentIds.sort((lhs, rhs) => {
    const lIssue = issuesContentsById[lhs];
    const rIssue = issuesContentsById[rhs];
    if (!isIssue(lIssue) || !isIssue(rIssue)) {
      return 0;
    }

    switch (sortByCriteria) {
      case T.IssueSortByCriteria.PRIORITY:
        if (toIssuePriorityValue[lIssue.priority] !== toIssuePriorityValue[rIssue.priority]) {
          return toIssuePriorityValue[rIssue.priority] - toIssuePriorityValue[lIssue.priority];
        }
        break;
      case T.IssueSortByCriteria.STATUS:
        if (lIssue.issueStatusId !== rIssue.issueStatusId) {
          return lIssue.issueStatusId - rIssue.issueStatusId;
        }
        break;
      case T.IssueSortByCriteria.CREATED_BY:
        if (lIssue.createdBy && rIssue.createdBy && lIssue.createdBy.id !== rIssue.createdBy.id) {
          return lIssue.createdBy.name.localeCompare(rIssue.createdBy.name, 'en', {
            sensitivity: 'base',
          });
        }
        break;
      case T.IssueSortByCriteria.CREATED_DATE:
        if (lIssue.createdAt!.getTime() !== rIssue.createdAt!.getTime()) {
          return lIssue.createdAt!.getTime() - rIssue.createdAt!.getTime();
        }
        break;
      default:
        break;
    }

    return lIssue.title.localeCompare(rIssue.title, 'en', { sensitivity: 'base' });
  });

  const issueSortBy: ReactNode = isOpened ? (
    <IssueGroupOrSortBy
      mainTitle={l10n(Text.sortBy)}
      value={sortByCriteria}
      options={options}
      onClick={option => {
        setSortByCriteria(option.value as T.IssueSortByCriteria);
      }}
      placeHolder={l10n(Text.sortByPlaceholder)}
      zIndex={0}
      customWidth="108px"
    />
  ) : null;

  const issueTreeList: ReactNode = isOpened
    ? copiedContentIds.map(id => <ContentToItem key={id} contentId={id} isIssueContent={true} />)
    : null;

  const issueGroupTitle: string = getGroupTitle(group.title, language);

  return (
    <div
      key={`issue-group-${group.value}`}
      data-ctxsort-parent="Group"
      data-ctxsort-key={group.value}
      style={{ paddingTop: '2.5px' }}
    >
      <GroupedContentsHeader
        groupTitle={issueGroupTitle}
        groupValue={group.value}
        childrenIds={copiedContentIds}
        isOpened={isOpened}
        onSetIsOpen={newIsOpened => {
          setIsOpened(newIsOpened);
        }}
      />
      {issueSortBy}
      <ContentsList>{issueTreeList}</ContentsList>
    </div>
  );
};

interface Props {
  groupByCriteria: T.IssueGroupByCriteria;
  statuses?: T.IssueStatus[];
  categories?: T.IssueCategory[];
}

export const IssueTreeList: FC<Props> = memo(({ groupByCriteria }) => {
  const [l10n, language]: UseL10n = useL10n();

  const { issuesContentsById, issuesContentAllIds } = useIssueStore();

  const issueCategoryOptions = useIssueStore(s => s.issueCategoryOptions);
  const issueStatusOptions = useIssueStore(s => s.issueStatusOptions);
  const isShowingIssuesRelatedToMe = useIssueStore(s => s.isShowingIssuesRelatedToMe);

  const myId = useSelector((s: T.State) => s.Auth.authedUser?.id);
  const allIssueContentIds = issuesContentAllIds;
  const issueContentIds = allIssueContentIds.filter(id =>
    isThisIssueRelatedToMe(myId, issuesContentsById[id], isShowingIssuesRelatedToMe)
  );

  const issueGroup: IssueGroupListProps[] = [];
  switch (groupByCriteria) {
    case T.IssueGroupByCriteria.PRIORITY:
      issuePriorityOptions[language].forEach(priorityOption => {
        issueGroup.push({
          value: priorityOption.value,
          title: priorityOption.text,
          contentIds: issueContentIds.filter(id => {
            const curContent = issuesContentsById[id];
            if (!isIssue(curContent)) {
              return false;
            }
            return curContent.priority === priorityOption.value;
          }),
          groupByCriteria,
        });
      });
      break;
    case T.IssueGroupByCriteria.STATUS:
      issueStatusOptions.forEach(statusOption => {
        issueGroup.push({
          value: statusOption.id,
          title: statusOption.label,
          contentIds: issueContentIds.filter(id => {
            const curContent = issuesContentsById[id];
            if (!isIssue(curContent)) {
              return false;
            }
            return curContent.issueStatusId === statusOption.id;
          }),
          groupByCriteria,
        });
      });
      break;
    case T.IssueGroupByCriteria.CATEGORY:
      issueGroup.push({
        value: -1,
        title: l10n(Text.group.notDefined),
        contentIds: issueContentIds.filter(id => {
          const curContent = issuesContentsById[id];
          if (!isIssue(curContent)) {
            return false;
          }
          return curContent.issueCategoryId === null;
        }),
        groupByCriteria,
      });
      issueCategoryOptions.forEach(categoryOption => {
        issueGroup.push({
          value: categoryOption.id,
          title: categoryOption.label,
          contentIds: issueContentIds.filter(id => {
            const curContent = issuesContentsById[id];
            if (!isIssue(curContent)) {
              return false;
            }
            return curContent.issueCategoryId === categoryOption.id;
          }),
          groupByCriteria,
        });
      });
      break;
    default:
      break;
  }

  const issueGroupListRender: ReactNode = issueGroup.map(group => (
    <IssueGroupList key={group.value} {...group} />
  ));

  return <>{issueGroupListRender}</>;
});
