import { Language } from '^/types';

export default {
  zoomIn: {
    [Language.KO_KR]: '확대',
    [Language.EN_US]: 'Zoom In',
  },
  zoomOut: {
    [Language.KO_KR]: '축소',
    [Language.EN_US]: 'Zoom Out',
  },
};
