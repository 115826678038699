import { TrustedServers } from 'cesium';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider, RouteObject, Outlet } from 'react-router-dom';
import { IConfig } from 'unleash-proxy-client';
import FlagProvider from '@unleash/proxy-client-react';

import GuideButton from './components/atoms/GuideButton';
import { Initializer } from './components/atoms/Initializer';
import { TUTORIAL_PORTAL_ID } from './components/atoms/TutorialWrapperHoverable';
import NotificationTopbar from '^/components/atoms/NotificationTopbar';
import { config } from '^/config';
import * as T from '^/types';
import { getTrackId, activateGA } from './utilities/load-ga';
import { getUserAgent } from './utilities/userAgent';
import { ErrorBoundary } from './components/atoms/ErrorBoundary';
import { ChangePathBeforeAuth } from './store/duck/Auth';
import { authorizedRoutes, unauthorizedRoutes } from './constants/routes';
import InvalidPageDisplay from './components/atoms/InvalidPageDisplay';
import { useSessionLogout } from './hooks/useSessionLogout';

// Add trusted servers
TrustedServers.add('dev-storage.angelswing.io', 443);
TrustedServers.add('storage.angelswing.io', 443);
TrustedServers.add('ksa-dev-storage.angelswing.io', 443);
TrustedServers.add('ksa-storage.angelswing.io', 443);

const App: React.FC = () => {
  const authedUser = useSelector((state: T.State) => state.Auth.authedUser);
  const pathBeforeAuth = useSelector((state: T.State) => state.Auth.pathBeforeAuth);
  const isAuthorized = Boolean(authedUser);

  const userAgent = useMemo(() => getUserAgent(), []);
  const dispatch = useDispatch();

  const isBrowserNotificationShown = useMemo(
    () => ![T.UserAgent.CHROME, T.UserAgent.EDGE, T.UserAgent.SAFARI].includes(userAgent),
    [userAgent]
  );
  const lastPath = location.pathname;

  // Store the last path user was intending to access
  // before they were asked to authorize.
  if (
    !isAuthorized &&
    lastPath?.length > 1 &&
    !lastPath.includes('login') &&
    !lastPath.includes('auth')
  ) {
    dispatch(ChangePathBeforeAuth({ path: lastPath }));
  }

  const GATrackInput = activateGA ? (
    <input type="hidden" id="ddm-ua-track-id" value={getTrackId()} />
  ) : null;

  const unleashConfig: IConfig = {
    url: config.unleashFrontendUrl,
    clientKey: config.clientKey,
    appName: 'ed1',
    environment: config.buildLevel ?? 'development',
    refreshInterval: 15 * 60,
    context: {
      properties: {
        email: authedUser?.userEmail ?? '',
        region: config.region,
      },
      userId: `${authedUser?.id}`,
    },
  };

  const appRoutes: RouteObject[] = useMemo(
    () => [
      {
        element: (
          <ErrorBoundary fallback={<InvalidPageDisplay />}>
            <Outlet />
          </ErrorBoundary>
        ),
        children: isAuthorized ? authorizedRoutes(pathBeforeAuth) : unauthorizedRoutes,
      },
    ],
    [pathBeforeAuth, isAuthorized] // Dependencies
  );
  const authorizedRouter = createBrowserRouter(appRoutes);
  useSessionLogout();

  return (
    <FlagProvider config={unleashConfig}>
      {GATrackInput}
      <Initializer />
      <GuideButton />
      <div id={TUTORIAL_PORTAL_ID} />
      {isAuthorized && isBrowserNotificationShown ? <NotificationTopbar /> : null}
      <RouterProvider router={authorizedRouter} />
    </FlagProvider>
  );
};

export default App;
