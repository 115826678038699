import React, {
  Context,
  FC,
  createContext,
  useContext,
  memo,
  useMemo,
  PropsWithChildren,
} from 'react';

import { checkIfContentIsFilteredOut } from '^/hooks/useContentsSearchFilter';
import * as T from '^/types';
import { getAllChildren } from '^/utilities/state-util';
import { useESSContentsStore } from '^/store/essContentsStore';

interface ContentsFilter {
  filterText: string;
  selectedFilterContents: T.ContentType[];
  filterType?: T.ContentsListType;
  contentTypes?: T.ContentType[];
  rootGroupIds: Array<T.Content['id']>;
  filteredContentIds: Array<T.Content['id']>;
  count: number;
}

export const ContentFilterContext: Context<ContentsFilter> = createContext<ContentsFilter>({
  filterText: '',
  selectedFilterContents: [],
  rootGroupIds: [],
  filteredContentIds: [],
  count: 0,
});

interface ContentFilterContextProviderProps {
  contentTypes: T.ContentType[];
  filterType: T.ContentsListType;
  rootGroupIds: Array<T.Content['id']>;
}

const ESSContentFilterContextProviderRoot: FC<
  PropsWithChildren<ContentFilterContextProviderProps>
> = ({ filterType, contentTypes, children, rootGroupIds }) => {
  const { essContents, essContentGroupTree, contentFilters } = useESSContentsStore(s => ({
    essContents: s.essContents,
    essContentGroupTree: s.essContentGroupTree,
    contentFilters: s.contentFilters,
  }));
  const filterText = contentFilters[filterType].filterText;
  const selectedFilterContents = contentFilters[filterType].filterContents;

  const filterAndMapContentIds = () => {
    const allChildren = getAllChildren(rootGroupIds, essContentGroupTree, essContents);
    const filteredContent = allChildren.filter(
      _content => !checkIfContentIsFilteredOut(_content, filterText, selectedFilterContents)
    );
    const filteredParents = filteredContent
      .map(_content => essContents[_content?.groupId ?? NaN])
      .filter(_content => _content);
    const filteredGrandParents = filteredParents
      .map(_content => essContents[_content?.groupId ?? NaN])
      .filter(_content => _content);

    const ids = filteredContent
      .concat(filteredParents)
      .concat(filteredGrandParents)
      .map(_content => _content.id);

    return ids;
  };

  const filteredContentIds = filterAndMapContentIds();

  const filterState = useMemo(
    () => ({
      filterText,
      selectedFilterContents,
      filterType,
      contentTypes,
      rootGroupIds,
      filteredContentIds,
      count: filteredContentIds.length,
    }),
    [filterText, selectedFilterContents, filterType, contentTypes, rootGroupIds, filteredContentIds]
  );

  return (
    <ContentFilterContext.Provider value={filterState}>{children}</ContentFilterContext.Provider>
  );
};

export const ESSContentFilterContextProvider = memo<
  FC<PropsWithChildren<ContentFilterContextProviderProps>>
>(ESSContentFilterContextProviderRoot);
export const useESSContentsFilter = () => useContext(ContentFilterContext);
