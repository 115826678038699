import Tippy from '@tippyjs/react';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import { Vector3 } from 'three';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import HorizontalLengthSVG from '^/assets/icons/contents-list/horizontal-length.svg';
import VerticalHeightSVG from '^/assets/icons/contents-list/vertical-height.svg';
import PointToPointLengthSVG from '^/assets/icons/contents-list/point-to-point-length.svg';
import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import { isAvailablePointToPoint, isContentPointToPoint } from '^/components/cesium/cesium-util';
import LengthMetricList, { MetricsContainer } from '^/components/molecules/LengthMetricList';
import { UseL10n, useL10n, useProjectCoordinateSystem } from '^/hooks';
import { PatchContent } from '^/store/duck/Contents';
import * as T from '^/types';
import { determineUnitType, VALUES_PER_METER } from '^/utilities/imperial-unit';
import { getSingleContentId } from '^/utilities/state-util';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { geoPointToPotreeCloud, getBaseDistance, getHeight } from '^/components/three/utils';
import { usePotreeStore } from '^/components/potree/PotreeStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';

const LengthMetricWrapper = styled.div({
  width: '100%',
  height: '100%',
});

type MetricType = keyof NonNullable<T.LengthContent['info']['metrics']>;

export interface Props {
  content: T.ThreeDHeightContent;
  isPinned?: boolean;
}

const ContentsListThreeHeightItem: FC<Props> = ({ content, isPinned = false }) => {
  const byId = useContentsStore(s => s.contents.byId);
  const { Pages, ProjectConfigPerUser }: T.State = useSelector((state: T.State) => state);

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);

  if (!projectUnit && !projectId) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }

  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();
  const PCO = usePotreeStore(s => s.PCO);
  const dispatch: Dispatch = useDispatch();
  const [l10n, language]: UseL10n = useL10n();

  const isEditing: boolean = content.id === Pages.Contents.editingContentId;
  const contentId: T.Content['id'] = content.id;

  // DsmMapContent
  const dsmId: T.Content['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.DSM
  );
  const dsmContent: T.DSMContent | undefined =
    dsmId !== undefined ? (byId[dsmId] as T.DSMContent) : undefined;

  const project: T.Project | undefined = projectId ? projectById[projectId] : undefined;
  const unitType: T.ValidUnitType = project
    ? determineUnitType(project.unit)
    : determineUnitType(projectUnit);

  const isDsmAvailable: boolean = Boolean(dsmContent?.status);
  const isElevationGenerated: boolean =
    content.info.elevations !== undefined && content.info.elevations.length !== 0;
  const isElevationToggled: boolean = Boolean(content.config?.isElevationToggled);
  const isIn3D: T.ContentsPageState['in3D'] = useSelector((s: T.State) => s.Pages.Contents.in3D);

  useEffect(() => {
    if (!isElevationGenerated && isElevationToggled) {
      dispatch(
        PatchContent({ content: { id: content.id, config: { isElevationToggled: false } } })
      );
    }
  }, [isDsmAvailable, isEditing]);

  const getMetricContent: (type: MetricType) => string | undefined = useCallback(
    type => {
      const metrics: T.LengthContent['info']['metrics'] = content.info.metrics;
      if (!isDsmAvailable) {
        return '-';
      }

      return metrics === undefined
        ? undefined
        : (metrics[type] * VALUES_PER_METER[unitType]).toFixed(2);
    },
    [content.info.metrics, isDsmAvailable]
  );

  // TODO: There was a bug with the tooltip wrapperhoverable,
  // where it would get into the sidebar and clipped due to overflow: hidden;
  // This positions the tooltip exactly where it needs to be.
  // It only needs this hack on english language where the text are longer.
  // Please update this if the text gets updated...
  const getTooltipCustomStyle: (type: 'horizontal' | 'pointToPoint' | MetricType) => CSSObject =
    useCallback(
      type =>
        language === T.Language.KO_KR
          ? {}
          : type === 'pointToPoint'
          ? { width: '180px', transform: 'translate(-66%, 3px)' }
          : { width: '180px' },
      [language]
    );

  const stringifiedLocations: string = content.info.locations.toString();
  const stringifiedMetrics: string | undefined = content.info.metrics?.toString();
  const pointToPoint: string | undefined = useMemo(() => {
    if (isAvailablePointToPoint(content.info.locations) && PCO) {
      let total: number = 0;
      if (content.type === T.ContentType.THREE_HEIGHT) {
        const threePositions: Vector3[] = content.info.locations.map(
          location => geoPointToPotreeCloud(location, projectProjection, PCO).point
        );

        threePositions.forEach((position, index) => {
          const nextIdx = index + 1;
          if (nextIdx < threePositions.length) {
            total += position.distanceTo(threePositions[nextIdx]);
          }
        });
      }

      total *= VALUES_PER_METER[unitType];
      return String(total.toFixed(2));
    }

    return getMetricContent('pointToPoint');
  }, [stringifiedLocations, stringifiedMetrics, PCO]);

  const heightMeasurement: string = useMemo(() => {
    if (!PCO) {
      return '';
    }
    const threePositions: Vector3[] = content.info.locations.map(location =>
      content.info.locationsType === 'geo_point'
        ? geoPointToPotreeCloud(location, projectProjection, PCO).point
        : new Vector3(...location)
    );

    return getHeight(threePositions, unitType);
  }, [stringifiedLocations, PCO]);

  const baseDistanceMeasurement: string = useMemo(() => {
    if (!PCO) {
      return '';
    }
    const threePositions: Vector3[] = content.info.locations.map(location =>
      content.info.locationsType === 'geo_point'
        ? geoPointToPotreeCloud(location, projectProjection, PCO).point
        : new Vector3(...location)
    );

    const { baseDistance } = getBaseDistance(threePositions, unitType);
    return baseDistance;
  }, [stringifiedLocations, PCO]);

  const onChangeDistanceType = (type: T.DistanceType) => () => {
    dispatch(
      PatchContent({
        content: {
          id: contentId,
          config: {
            distanceType: type,
          },
        },
      })
    );
  };

  const isSurface: boolean =
    isIn3D &&
    (content.config?.distanceType === T.DistanceType.SURFACE ||
      !isAvailablePointToPoint(content.info.locations));
  const isPointToPoint: boolean = isIn3D && !isSurface && isContentPointToPoint(content);

  const isSelectAvailablePointToPoint: boolean =
    isIn3D && isAvailablePointToPoint(content.info.locations);

  return (
    <ContentsListItem
      isPinned={isPinned}
      className={CANCELLABLE_CLASS_NAME}
      content={content}
      firstBalloonTitle={l10n(Text.firstBalloonTitle)}
    >
      <MetricsContainer>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="right"
          arrow={false}
          content={l10n(Text.twoDDisabled)}
          disabled={!isIn3D}
        >
          <LengthMetricWrapper>
            <LengthMetricList
              type={content.type}
              title={l10n(Text.horizontalLength)}
              tooltip={l10n(Text.horizontalLengthTooltip)}
              icon={<HorizontalLengthSVG />}
              value={baseDistanceMeasurement}
              isActive={!isIn3D}
              isSelectDisabled={false}
              tooltipCustomStyle={getTooltipCustomStyle('horizontal')}
              onClick={onChangeDistanceType(T.DistanceType.HORIZONTAL)}
              unitType={unitType}
            />
          </LengthMetricWrapper>
        </Tippy>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="right"
          arrow={false}
          content={l10n(Text.threeDDisabled)}
          disabled={isIn3D}
        >
          <LengthMetricWrapper>
            <LengthMetricList
              type={content.type}
              title={l10n(Text.ThreeHeightLength)}
              tooltip={l10n(Text.ThreeHeightLengthTooltip)}
              icon={<VerticalHeightSVG />}
              value={heightMeasurement}
              isActive={isSurface}
              isSelectDisabled={!isIn3D}
              tooltipCustomStyle={getTooltipCustomStyle('surface')}
              onClick={onChangeDistanceType(T.DistanceType.SURFACE)}
              unitType={unitType}
            />
          </LengthMetricWrapper>
        </Tippy>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="right"
          arrow={false}
          content={l10n(Text.potreeDisabled)}
          disabled={isIn3D}
        >
          <LengthMetricWrapper>
            <LengthMetricList
              type={content.type}
              title={l10n(Text.pointToPointLength)}
              tooltip={l10n(Text.pointToPointLengthTooltip)}
              icon={<PointToPointLengthSVG />}
              value={pointToPoint}
              isActive={isPointToPoint}
              isSelectDisabled={!isSelectAvailablePointToPoint}
              tooltipCustomStyle={getTooltipCustomStyle('pointToPoint')}
              onClick={onChangeDistanceType(T.DistanceType.POINT_TO_POINT)}
              unitType={unitType}
            />
          </LengthMetricWrapper>
        </Tippy>
      </MetricsContainer>
    </ContentsListItem>
  );
};

export default memo(withErrorBoundary(ContentsListThreeHeightItem)(Fallback));
