/* eslint-disable max-lines */
import _ from 'lodash-es';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { useRole } from './useRole';
import { LAT_LON_FIX_FORMAT, Y_X_FIX_FORMAT } from '^/constants/defaultContent';
import { DeleteContent, contentsSelector } from '^/store/duck/Contents';
import { OpenContentDeletingConfirmPopup, OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';
import { exhaustiveCheck } from '^/utilities/exhaustive-check';
import { isAllowDeleteContent } from '^/utilities/role-permission-check';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useFlightPlanStore } from '^/store/flightPlanStore';
import { useIssueStore } from '^/store/issue';
import { contentsStore, useContentsStore } from '^/store/zustand/content/contentStore';
import { groupStore } from '^/store/zustand/groups/groupStore';

/**
 * @key Having a true value means query result includes contents with that attribute
 * @example
 * pinned: true => query results includes pinned ones too
 * processing: false => query results doesn't include processing ones
 */
export interface ContentsQueryFilter {
  pinned?: boolean; //default assumption: true
  processingOrFailed?: boolean; //default assuption: false
  selectedAt?: boolean; //default assuption: false
}

/* eslint-disable max-len */
export type QueryParam =
  | T.ContentsQueryParam.TITLE
  | T.ContentsQueryParam.TYPE_AND_SCREENID
  | T.ContentsQueryParam.ID;
export type QueryContentWithId = (
  givenId: T.Content['id'],
  givenOptions?: ContentsQueryFilter
) => T.Content | undefined;
export type QueryContentWithTitle = (
  givenTitle: T.Content['title'],
  givenOptions?: ContentsQueryFilter
) => T.Content | undefined;
export type QueryContentWithTypeAndScreenId = (
  givenType: T.Content['type'],
  givenScreenId: T.Screen['id'],
  givenOptions?: Omit<ContentsQueryFilter, 'pinned'>
) => T.Content | undefined;

export type Query = QueryContentWithTitle | QueryContentWithTypeAndScreenId | QueryContentWithId;

export function queryContent(
  contents: T.State['Contents']['contents'],
  queryParam: QueryParam
): Query {
  const allContents: T.Content[] = contents.allIds.map(cid => contents.byId[cid]);

  switch (queryParam) {
    case T.ContentsQueryParam.TITLE:
      return (givenTitle: T.Content['title'], givenOptions?: ContentsQueryFilter) => {
        for (const c of allContents) {
          if (c.title !== givenTitle) {
            continue;
          }

          if (
            !Boolean(givenOptions?.processingOrFailed) &&
            contentsSelector.isProcessingOrFailedByContent(c)
          ) {
            continue;
          }
          if (givenOptions?.pinned === false && c.screenId === undefined) {
            continue;
          }
          if (Boolean(givenOptions?.selectedAt) && c.config?.selectedAt === undefined) {
            continue;
          }

          return c;
        }

        return;
      };
    case T.ContentsQueryParam.TYPE_AND_SCREENID:
      return (
        givenType: T.Content['type'],
        givenScreenId: T.Screen['id'],
        givenOptions?: Omit<ContentsQueryFilter, 'pinned'>
      ) => {
        for (const c of allContents) {
          if (c.type !== givenType) {
            continue;
          }
          if (c.screenId === undefined || c.screenId !== givenScreenId) {
            continue;
          }

          if (
            !Boolean(givenOptions?.processingOrFailed) &&
            contentsSelector.isProcessingOrFailedByContent(c)
          ) {
            continue;
          }
          if (Boolean(givenOptions?.selectedAt) && c.config?.selectedAt === undefined) {
            continue;
          }

          return c;
        }

        return;
      };
    case T.ContentsQueryParam.ID:
      return (givenId: T.Content['id'], givenOptions?: Omit<ContentsQueryFilter, 'pinned'>) => {
        for (const c of allContents) {
          if (c.id !== givenId) {
            continue;
          }

          if (
            !Boolean(givenOptions?.processingOrFailed) &&
            contentsSelector.isProcessingOrFailedByContent(c)
          ) {
            continue;
          }
          if (Boolean(givenOptions?.selectedAt) && c.config?.selectedAt === undefined) {
            continue;
          }

          return c;
        }

        return;
      };
    default:
      exhaustiveCheck(queryParam);
  }
}

export function useGetContentOf(queryParam: T.ContentsQueryParam.TITLE): QueryContentWithTitle;
export function useGetContentOf(
  queryParam: T.ContentsQueryParam.TYPE_AND_SCREENID
): QueryContentWithTypeAndScreenId;
export function useGetContentOf(queryParam: T.ContentsQueryParam.ID): QueryContentWithId;
export function useGetContentOf(queryParam: QueryParam): Query | undefined {
  const contents = useContentsStore(s => s.contents);

  return queryContent(contents, queryParam);
}

export type QueryIDParam = T.ContentsQueryParam.TITLE | T.ContentsQueryParam.TYPE_AND_SCREENID;

export type QueryIDWithTitle = (
  givenTitle: T.Content['title'],
  givenOptions?: ContentsQueryFilter
) => T.Content['id'] | undefined;
export type QueryIDWithTypeAndScreenId = (
  givenType: T.Content['type'],
  givenScreenId: T.Screen['id'],
  givenOptions?: Omit<ContentsQueryFilter, 'pinned'>
) => T.Content['id'] | undefined;

export type QueryID = QueryIDWithTitle | QueryIDWithTypeAndScreenId;

export function useGetContentIdOf(queryIDParam: T.ContentsQueryParam.TITLE): QueryIDWithTitle;
export function useGetContentIdOf(
  queryIDParam: T.ContentsQueryParam.TYPE_AND_SCREENID
): QueryIDWithTypeAndScreenId;
export function useGetContentIdOf(queryIDParam: QueryIDParam): QueryID | undefined {
  const getContentOfTitle: QueryContentWithTitle = useGetContentOf(T.ContentsQueryParam.TITLE);
  const getContentOfType: QueryContentWithTypeAndScreenId = useGetContentOf(
    T.ContentsQueryParam.TYPE_AND_SCREENID
  );
  if (queryIDParam === T.ContentsQueryParam.TITLE) {
    return (givenTitle: T.Content['title'], givenOptions?: ContentsQueryFilter) =>
      getContentOfTitle(givenTitle, givenOptions)?.id;
  } else if (queryIDParam === T.ContentsQueryParam.TYPE_AND_SCREENID) {
    return (
      givenType: T.Content['type'],
      givenScreenId: T.Screen['id'],
      givenOptions?: Omit<ContentsQueryFilter, 'pinned'>
    ) => getContentOfType(givenType, givenScreenId, givenOptions)?.id;
  }

  return;
}

export type QueryAllParam =
  | T.ContentsQueryParam.TYPE
  | T.ContentsQueryParam.TYPE_AND_SCREENID
  | T.ContentsQueryParam.SCREEN;

export type QueryContentsWithType = (
  givenType: T.Content['type'],
  givenOptions?: ContentsQueryFilter
) => T.Content[];
export type QueryContentsWithScreenId = (
  givenScreenId: T.Screen['id'],
  givenOptions?: ContentsQueryFilter
) => T.Content[];
export type QueryContentsWithTypeAndScreenId = (
  givenType: T.Content['type'],
  givenScreenId: T.Screen['id'],
  givenOptions?: ContentsQueryFilter
) => T.Content[];

export type QueryAll =
  | QueryContentsWithType
  | QueryContentsWithScreenId
  | QueryContentsWithTypeAndScreenId;

export function useGetAllContentsOf(queryParam: T.ContentsQueryParam.TYPE): QueryContentsWithType;
export function useGetAllContentsOf(
  queryParam: T.ContentsQueryParam.SCREEN
): QueryContentsWithScreenId;
export function useGetAllContentsOf(
  queryParam: T.ContentsQueryParam.TYPE_AND_SCREENID
): QueryContentsWithTypeAndScreenId;
export function useGetAllContentsOf(queryParam: QueryAllParam): QueryAll | undefined {
  const contents = useContentsStore(s => s.contents);
  const allContents: T.Content[] = contents.allIds.map(cid => contents.byId[cid]);

  if (queryParam === T.ContentsQueryParam.TYPE) {
    return (givenType: T.Content['type'], givenOptions?: ContentsQueryFilter) => {
      const filteredContents: T.Content[] = [];
      for (const c of allContents) {
        if (c.type !== givenType) {
          continue;
        }
        if (givenOptions?.pinned === false && c.screenId === undefined) {
          continue;
        }

        if (
          !Boolean(givenOptions?.processingOrFailed) &&
          contentsSelector.isProcessingOrFailedByContent(c)
        ) {
          continue;
        }
        if (Boolean(givenOptions?.selectedAt) && c.config?.selectedAt === undefined) {
          continue;
        }
        filteredContents.push(c);
      }

      return filteredContents;
    };
  } else if (queryParam === T.ContentsQueryParam.TYPE_AND_SCREENID) {
    return (
      givenType: T.Content['type'],
      givenScreenId: T.Screen['id'],
      givenOptions?: ContentsQueryFilter
    ) => {
      const filteredContents: T.Content[] = [];
      for (const c of allContents) {
        if (c.type !== givenType) {
          continue;
        }
        if (
          !Boolean(givenOptions?.processingOrFailed) &&
          contentsSelector.isProcessingOrFailedByContent(c)
        ) {
          continue;
        }
        if (Boolean(givenOptions?.selectedAt) && c.config?.selectedAt === undefined) {
          continue;
        }
        if (givenOptions?.pinned === true && c.screenId === undefined) {
          filteredContents.push(c);
          continue;
        }
        if (c.screenId !== givenScreenId) {
          continue;
        }

        filteredContents.push(c);
      }

      return filteredContents;
    };
  } else if (queryParam === T.ContentsQueryParam.SCREEN) {
    return (givenScreenId: T.Screen['id'], givenOptions?: Omit<ContentsQueryFilter, 'pinned'>) => {
      const filteredContents: T.Content[] = [];
      for (const c of allContents) {
        if (c.screenId !== givenScreenId) {
          continue;
        }

        if (
          !Boolean(givenOptions?.processingOrFailed) &&
          contentsSelector.isProcessingOrFailedByContent(c)
        ) {
          continue;
        }
        if (Boolean(givenOptions?.selectedAt) && c.config?.selectedAt === undefined) {
          continue;
        }

        filteredContents.push(c);
      }

      return filteredContents;
    };
  }

  return;
}

export type UseDeleteContent = (
  contentId: T.Content['id'],
  contentType: T.ContentType,
  isESS?: boolean,
  isFlightPlan?: boolean
) => void;
export const useDeleteContent: () => UseDeleteContent = () => {
  const setDeletingESSContentId = useESSContentsStore(s => s.setDeletingESSContentId);
  const setDeletingFlightPlanId = useFlightPlanStore(s => s.setDeletingFlightPlanId);
  const setDeletingIssueContentId = useIssueStore(s => s.setDeletingIssueContentId);
  const dispatch: Dispatch = useDispatch();
  const role: T.PermissionRole = useRole();

  return (
    contentId: T.Content['id'],
    contentType: T.ContentType,
    isESS?: boolean,
    isFlightPlan?: boolean
  ) => {
    if (!isAllowDeleteContent(role, contentType)) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
      return;
    }

    if (isESS) {
      setDeletingESSContentId(contentId);
    }

    if (contentType === T.ContentType.ISSUE_PHOTO || contentType === T.ContentType.ISSUE_POINT) {
      setDeletingIssueContentId(contentId);
    }

    if (isFlightPlan) {
      setDeletingFlightPlanId(contentId);
    }

    dispatch(
      OpenContentDeletingConfirmPopup({
        popup: T.ContentPagePopupType.DELETE_CONFIRM,
        contentId,
      })
    );
  };
};

export function useDSMAvailableDates(): Date[] {
  const dsmContents: T.DSMContent[] = (
    useGetAllContentsOf(T.ContentsQueryParam.TYPE)(T.ContentType.DSM) as T.DSMContent[]
  ).filter((c: T.DSMContent) => c.appearAt.getTime() !== 0); // remove "getTime() !== 0" after 2.0 is deployed

  const DSMAvailableDates: Date[] = dsmContents
    .map(content => content.appearAt.getTime())
    .sort((a, b) => a - b)
    .map(time => new Date(time));

  return _.sortedUniqBy(DSMAvailableDates, date => date.getTime());
}

export interface OverwriteMapTabCondition {
  hasDSM: boolean;
  hasTwoDOrtho: boolean;
  hasPointCloud: boolean;
  hasThreeDOrtho: boolean;
  hasThreeDMesh: boolean;
}

export function getShouldOverwriteMapTab(
  popupType: T.AttachmentType,
  { hasDSM, hasTwoDOrtho, hasPointCloud, hasThreeDOrtho, hasThreeDMesh }: OverwriteMapTabCondition
): boolean {
  const { SOURCE, DSM, ORTHO, POINTCLOUD }: typeof T.AttachmentType = T.AttachmentType;

  switch (popupType) {
    case SOURCE:
      return hasTwoDOrtho || hasDSM || hasPointCloud || hasThreeDOrtho || hasThreeDMesh;
    case DSM:
      return hasDSM;
    case ORTHO:
      return hasTwoDOrtho;
    case POINTCLOUD:
      return hasPointCloud;
    default:
      return false;
  }
}

export type UseShouldOverwriteMapTab = (
  popupType: T.AttachmentType,
  selectedScreenId: T.Screen['id']
) => boolean;
export function useShouldOverwriteMapTab(): UseShouldOverwriteMapTab {
  const getContent: QueryContentWithTypeAndScreenId = useGetContentOf(
    T.ContentsQueryParam.TYPE_AND_SCREENID
  );

  return (popupType, selectedScreenId) => {
    const [hasDSM, hasTwoDOrtho, hasPointCloud, hasThreeDOrtho, hasThreeDMesh]: boolean[] = [
      T.ContentType.DSM,
      T.ContentType.MAP,
      T.ContentType.POINTCLOUD,
      T.ContentType.THREE_D_ORTHO,
      T.ContentType.THREE_D_MESH,
      T.ContentType.ESS_MODEL,
      T.ContentType.ESS_ARROW,
    ].map(c => Boolean(getContent(c, selectedScreenId)));

    return getShouldOverwriteMapTab(popupType, {
      hasDSM,
      hasTwoDOrtho,
      hasPointCloud,
      hasThreeDOrtho,
      hasThreeDMesh,
    });
  };
}

export type UseDeleteMapTabContents = (
  popupType: T.AttachmentType,
  selectedScreenId: T.Screen['id']
) => Promise<void>;
export function useDeleteMapTabContents(): UseDeleteMapTabContents {
  const dispatch: Dispatch = useDispatch();
  const getAllContentsOfScreen: QueryContentsWithScreenId = useGetAllContentsOf(
    T.ContentsQueryParam.SCREEN
  );

  return async (attachmentType: T.AttachmentType, selectedScreenId: T.Screen['id']) => {
    const { SOURCE, ORTHO, DSM, POINTCLOUD }: typeof T.AttachmentType = T.AttachmentType;
    const screenContents: T.Content[] = getAllContentsOfScreen(selectedScreenId);
    const mapTabContents: T.MapTabContent[] = screenContents.filter(c =>
      T.MAP_TAB_CONTENTS.includes(c.type)
    ) as T.MapTabContent[];
    const deletingTypeMap: T.AttachmentContentTypeMap = {
      [DSM]: T.ContentType.DSM,
      [ORTHO]: T.ContentType.MAP,
      [POINTCLOUD]: T.ContentType.POINTCLOUD,
    };
    if (attachmentType === SOURCE) {
      await Promise.all(
        mapTabContents.map(async c => dispatch(DeleteContent({ contentId: c.id })))
      );

      return;
    }
    const deletingContent: T.MapTabContent | undefined = mapTabContents.find(
      c => c.type === deletingTypeMap[attachmentType]
    );
    if (deletingContent === undefined) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(DeleteContent({ contentId: deletingContent.id }));
  };
}

export interface TMapAndTerrainArray {
  [T.ContentType.MAP]: T.MapContent | undefined;
  [T.ContentType.DSM]: T.DSMContent | undefined;
  [T.ContentType.DTM]: T.DTMContent | undefined;
}
export function useMapContentsOfScreens(
  ...screens: Array<T.Screen | undefined>
): Array<TMapAndTerrainArray | undefined> {
  const getContentOf: QueryContentWithTypeAndScreenId = useGetContentOf(
    T.ContentsQueryParam.TYPE_AND_SCREENID
  );

  const getMapContentOfScreen: (
    screen: T.Screen | undefined
  ) => TMapAndTerrainArray | undefined = screen => {
    const mapDetails = screen
      ? typeGuardMap(getContentOf(T.ContentType.MAP, screen.id))
      : undefined;
    const dsmDetails = screen
      ? typeGuardDSM(getContentOf(T.ContentType.DSM, screen.id))
      : undefined;
    const dtmDetails = screen
      ? typeGuardDTM(getContentOf(T.ContentType.DTM, screen.id))
      : undefined;
    return screen
      ? {
          [T.ContentType.MAP]: mapDetails,
          [T.ContentType.DSM]: dsmDetails,
          [T.ContentType.DTM]: dtmDetails,
        }
      : undefined;
  };

  return screens.map(getMapContentOfScreen);
}

// Legacy coords that saved as lon lat (EPSG:4326) || ProjectionSystem is 4326
export function isLonLat(location: T.GeoPoint): boolean {
  const lonlatRegex: RegExp = new RegExp(
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    'g'
  );

  return [location[1], location[0]].join(', ').match(lonlatRegex) !== null;
}

export function addMarkerLocationPrecision(
  location: T.GeoPoint,
  isLonLatProject: boolean
): string[] {
  return location.map(l => l.toFixed(isLonLatProject ? LAT_LON_FIX_FORMAT : Y_X_FIX_FORMAT));
}

/**
 * Recursively get all the non-group ids in a list of ids.
 * Typically the root of the list should be from a list of root-level ids in a category.
 *
 * @param byId
 * @param idsByGroup
 * @param ids
 * @returns A list of ids, flattened.
 */
const getNonGroupIds: (
  byId: Record<T.Content['id'], T.Content>,
  idsByGroup: Record<T.GroupContent['id'], Array<T.Content['id']>>,
  ids: Array<T.Content['id']>
) => Array<T.Content['id']> = (byId, idsByGroup, ids) =>
  ids.reduce<Array<T.Content['id']>>((acc, id) => {
    const content = byId[id];
    if (content === undefined) {
      return acc;
    }

    if (isGroupable(content)) {
      return acc.concat(getNonGroupIds(byId, idsByGroup, idsByGroup[id] ?? []));
    }

    return acc.concat(id);
  }, []);

export function getCurrentScreenContentIds(params: {
  state: T.State;
  isESSDisabled?: boolean;
  screenId?: T.Content['id'];
  isMapDisabled?: boolean;
}): Array<T.Content['id']> {
  const { ProjectConfigPerUser, Projects, Screens } = params.state;
  const tree = groupStore.getState().tree;

  const projectConfig: T.ProjectConfig | undefined = ProjectConfigPerUser.config;
  if (!projectConfig || !projectConfig.lastSelectedScreenId) {
    return [];
  }

  const currentScreenId = params.screenId ? params.screenId : projectConfig.lastSelectedScreenId;

  const currentProject: T.Project = Projects.projects.byId[projectConfig.projectId];
  const currentScreen: T.Screen | undefined = Screens.screens.find(
    screen => screen.id === currentScreenId
  );
  if (currentScreen === undefined || currentProject === undefined) {
    return [];
  }

  const categories = (() => {
    const allCategories = Object.keys(tree.rootIdsByCategory) as T.ContentCategory[];

    if (params.isESSDisabled) {
      return allCategories.filter(category => category !== T.ContentCategory.ESS);
    }

    if (params.isMapDisabled) {
      return allCategories.filter(category => category !== T.ContentCategory.MAP);
    }

    return allCategories;
  })();
  const byId = contentsStore.getState().contents.byId;
  const { idsByGroup, rootIdsByCategory } = tree;

  return categories.reduce<Array<T.Content['id']>>((acc, category) => {
    const { pinned, unpinned } = rootIdsByCategory[category];

    return acc
      .concat(getNonGroupIds(byId, idsByGroup, pinned))
      .concat(getNonGroupIds(byId, idsByGroup, unpinned[currentScreen.id] ?? []));
  }, []);
}
export function useGetCurrentScreenContentIds(
  params: { isESSDisabled?: boolean } = {}
): Array<T.Content['id']> {
  const state = useStore().getState();
  // TODO: State_Management: if a function needs whole state, use it inside the function instead of passing like this.
  // Root state used like this won't have the latest state.
  return getCurrentScreenContentIds({ state, isESSDisabled: params.isESSDisabled });
}

export function typeGuardMap(c: T.Content | undefined): T.MapContent | undefined {
  return isMap(c) ? c : undefined;
}
export function typeGuardDSM(c: T.Content | undefined): T.DSMContent | undefined {
  return isDSM(c) ? c : undefined;
}
export function typeGuardDTM(c: T.Content | undefined): T.DTMContent | undefined {
  return isDTM(c) ? c : undefined;
}
export function typeGuardArea(
  c: T.Content | undefined
): T.AreaContent | T.ThreeAreaContent | undefined {
  return isArea(c) ? c : undefined;
}
export function typeGuardLength(c: T.Content | undefined): T.LengthContent | undefined {
  return isLength(c) ? c : undefined;
}
export function typeGuardMarker(c: T.Content | undefined): T.MarkerContent | undefined {
  return isMarker(c) ? c : undefined;
}
export function typeGuardVolume(c: T.Content | undefined): T.VolumeContent | undefined {
  return isVolume(c) ? c : undefined;
}
export function typeGuardDesignDXF(c: T.Content | undefined): T.DesignDXFContent | undefined {
  return isDesignDXF(c) ? c : undefined;
}
export function typeGuardPointCloud(c: T.Content | undefined): T.PointCloudContent | undefined {
  return isPointcloud(c) ? c : undefined;
}
export function typeGuardThreeDOrtho(c: T.Content | undefined): T.ThreeDOrthoContent | undefined {
  return isThreeDOrtho(c) ? c : undefined;
}
export function typeGuardThreeDMesh(c: T.Content | undefined): T.ThreeDMeshContent | undefined {
  return isThreeDMesh(c) ? c : undefined;
}

export function typeGuardBimTiles(c: T.Content | undefined): T.BimContent | undefined {
  return isBimTiles(c) ? c : undefined;
}
export function typeGuardBlueprintDXF(c: T.Content | undefined): T.BlueprintDXFContent | undefined {
  return isBlueprintDXF(c) ? c : undefined;
}
export function typeGuardBlueprintDWG(c: T.Content | undefined): T.BlueprintDWGContent | undefined {
  return isBlueprintDWG(c) ? c : undefined;
}
export function typeGuardBlueprintPDF(c: T.Content | undefined): T.BlueprintPDFContent | undefined {
  return isBlueprintPDF(c) ? c : undefined;
}
export function typeGuardCADContent(c: T.Content | undefined): T.CADContent | undefined {
  return isCADContent(c) ? c : undefined;
}
export function typeGuardGCPGroup(c: T.Content | undefined): T.GCPGroupContent | undefined {
  return isGCPGroup(c) ? c : undefined;
}
export function typeGuardGroup(c: T.Content | undefined): T.GroupContent | undefined {
  return isGroup(c) ? c : undefined;
}
export function typeGuardGroupable(c: T.Content | undefined): T.GroupableContent | undefined {
  return isGroupable(c) ? c : undefined;
}
export function typeGuardESSModel(c: T.Content | undefined): T.ESSModelContent | undefined {
  return isESSModel(c) ? c : undefined;
}
export function typeGuardESSCustomModel(
  c: T.Content | undefined
): T.ESSCustomModelContent | undefined {
  return isESSCustomModel(c) ? c : undefined;
}
export function typeGuardESSArrow(c: T.Content | undefined): T.ESSArrowContent | undefined {
  return isESSArrow(c) ? c : undefined;
}
export function typeGuardESSPolyline(c: T.Content | undefined): T.ESSPolylineContent | undefined {
  return isESSPolyline(c) ? c : undefined;
}
export function typeGuardESSPolygon(c: T.Content | undefined): T.ESSPolygonContent | undefined {
  return isESSPolygon(c) ? c : undefined;
}
export function typeGuardESSText(c: T.Content | undefined): T.ESSTextContent | undefined {
  return isESSText(c) ? c : undefined;
}
export function typeGuardESSContent(c: T.Content | undefined): T.ESSContent | undefined {
  return isESSContent(c) || isGroup(c) ? c : undefined;
}

function isMap(c: T.Content | undefined): c is T.MapContent {
  return c !== undefined && c.type === T.ContentType.MAP;
}
function isDSM(c: T.Content | undefined): c is T.DSMContent {
  return c !== undefined && c.type === T.ContentType.DSM;
}
function isDTM(c: T.Content | undefined): c is T.DTMContent {
  return c !== undefined && c.type === T.ContentType.DTM;
}
function isArea(c: T.Content | undefined): c is T.AreaContent | T.ThreeAreaContent {
  return c !== undefined && (c.type === T.ContentType.AREA || c.type === T.ContentType.THREE_AREA);
}
function isLength(c: T.Content | undefined): c is T.LengthContent {
  return c !== undefined && c.type === T.ContentType.LENGTH;
}
export function isMarker(c: T.Content | undefined): c is T.MarkerContent {
  return c !== undefined && c.type === T.ContentType.MARKER;
}
function isVolume(c: T.Content | undefined): c is T.VolumeContent {
  return c !== undefined && c.type === T.ContentType.VOLUME;
}
function isDesignDXF(c: T.Content | undefined): c is T.DesignDXFContent {
  return c !== undefined && c.type === T.ContentType.DESIGN_DXF;
}
function isPointcloud(c: T.Content | undefined): c is T.PointCloudContent {
  return c !== undefined && c.type === T.ContentType.POINTCLOUD;
}
function isThreeDOrtho(c: T.Content | undefined): c is T.ThreeDOrthoContent {
  return c !== undefined && c.type === T.ContentType.THREE_D_ORTHO;
}
function isThreeDMesh(c: T.Content | undefined): c is T.ThreeDMeshContent {
  return c !== undefined && c.type === T.ContentType.THREE_D_MESH;
}
function isBimTiles(c: T.Content | undefined): c is T.BimContent {
  return c !== undefined && c.type === T.ContentType.BIM;
}
export function isBlueprintDXF(c: T.Content | undefined): c is T.BlueprintDXFContent {
  return c !== undefined && c.type === T.ContentType.BLUEPRINT_DXF;
}
export function isBlueprintDWG(c: T.Content | undefined): c is T.BlueprintDWGContent {
  return c !== undefined && c.type === T.ContentType.BLUEPRINT_DWG;
}
export function isCADContent(c: T.Content | undefined): c is T.CADContent {
  return (
    c !== undefined &&
    (c.type === T.ContentType.BLUEPRINT_DXF || c.type === T.ContentType.BLUEPRINT_DWG)
  );
}
function isBlueprintPDF(c: T.Content | undefined): c is T.BlueprintPDFContent {
  return c !== undefined && c.type === T.ContentType.BLUEPRINT_PDF;
}

function isGCPGroup(c: T.Content | undefined): c is T.GCPGroupContent {
  return c !== undefined && c.type === T.ContentType.GCP_GROUP;
}
function isGroup(c: T.Content | undefined): c is T.GroupContent {
  return c !== undefined && c.type === T.ContentType.GROUP;
}
export function isGroupable(c: T.Content | undefined): c is T.GroupableContent {
  return c !== undefined && c.type === T.ContentType.GROUP;
}

function isESSModel(c: T.Content | undefined): c is T.ESSModelContent {
  return c !== undefined && c.type === T.ContentType.ESS_MODEL;
}
export function isESSCustomModel(c: T.Content | undefined): c is T.ESSCustomModelContent {
  return c !== undefined && c.type === T.ContentType.ESS_MODEL_CUSTOM;
}
function isESSArrow(c: T.Content | undefined): c is T.ESSArrowContent {
  return c !== undefined && c.type === T.ContentType.ESS_ARROW;
}
function isESSPolygon(c: T.Content | undefined): c is T.ESSPolygonContent {
  return c !== undefined && c.type === T.ContentType.ESS_POLYGON;
}
function isESSPolyline(c: T.Content | undefined): c is T.ESSPolylineContent {
  return c !== undefined && c.type === T.ContentType.ESS_POLYLINE;
}
function isESSText(c: T.Content | undefined): c is T.ESSTextContent {
  return c !== undefined && c.type === T.ContentType.ESS_TEXT;
}
function isESSLineText(c: T.Content | undefined): c is T.ESSLineTextContent {
  return c !== undefined && c.type === T.ContentType.ESS_LINE_TEXT;
}
export function isESSContent(c: T.Content | undefined): c is T.ESSContent {
  return (
    isESSArrow(c) ||
    isESSPolygon(c) ||
    isESSPolyline(c) ||
    isESSModel(c) ||
    isESSCustomModel(c) ||
    isESSText(c) ||
    isESSLineText(c)
    // isGroup(c) // removing group condition to ensure group is also selected and unselected from server
  );
}
export function isIssuePoint(c: T.Content | undefined): c is T.IssuePointContent {
  return c !== undefined && c.type === T.ContentType.ISSUE_POINT;
}
export function isIssuePhoto(c: T.Content | undefined): c is T.IssuePhotoContent {
  return c !== undefined && c.type === T.ContentType.ISSUE_PHOTO;
}
export function isIssue(c: T.Content | undefined): c is T.IssueContent {
  return isIssuePoint(c) || isIssuePhoto(c);
}

export function typeGuardMaps(cs: T.Content[]): T.MapContent[] {
  return isMaps(cs) ? cs : [];
}
export function typeGuardDSMs(cs: T.Content[]): T.DSMContent[] {
  return isDSMs(cs) ? cs : [];
}
export function typeGuardAreas(cs: T.Content[]): T.AreaContent[] | T.ThreeAreaContent[] {
  return isAreas(cs) ? cs : [];
}
export function typeGuardLengths(cs: T.Content[]): T.LengthContent[] {
  return isLengths(cs) ? cs : [];
}
export function typeGuardMarkers(cs: T.Content[]): T.MarkerContent[] {
  return isMarkers(cs) ? cs : [];
}
export function typeGuardVolumes(cs: T.Content[]): T.VolumeContent[] {
  return isVolumes(cs) ? cs : [];
}
export function typeGuardDesignDXFs(cs: T.Content[]): T.DesignDXFContent[] {
  return isDesignDXFs(cs) ? cs : [];
}
export function typeGuardPointClouds(cs: T.Content[]): T.PointCloudContent[] {
  return isPointclouds(cs) ? cs : [];
}
export function typeGuardThreeDOrthos(cs: T.Content[]): T.ThreeDOrthoContent[] {
  return isThreeDOrthos(cs) ? cs : [];
}
export function typeGuardBlueprintDXFs(cs: T.Content[]): T.BlueprintDXFContent[] {
  return isBlueprintDXFs(cs) ? cs : [];
}
export function typeGuardBlueprintDWGs(cs: T.Content[]): T.BlueprintDWGContent[] {
  return isBlueprintDWGs(cs) ? cs : [];
}
export function typeGuardBlueprintPDFs(cs: T.Content[]): T.BlueprintPDFContent[] {
  return isBlueprintPDFs(cs) ? cs : [];
}
export function typeGuardCADContents(cs: T.Content[]): T.CADContent[] {
  return isCADContents(cs) ? cs : [];
}
export function typeGuardThreeDMeshs(cs: T.Content[]): T.ThreeDMeshContent[] {
  return isThreeDMeshs(cs) ? cs : [];
}
export function typeGuardGCPGroups(cs: T.Content[]): T.GCPGroupContent[] {
  return isGCPGroupContents(cs) ? cs : [];
}
export function typeGuardESSModels(cs: T.Content[]): T.ESSModelContent[] | undefined {
  return isESSModelContents(cs) ? cs : undefined;
}
export function typeGuardESSCustomModels(cs: T.Content[]): T.ESSCustomModelContent[] | undefined {
  return isESSCustomModelContents(cs) ? cs : undefined;
}
export function typeGuardESSArrowContents(cs: T.Content[]): T.ESSArrowContent[] | undefined {
  return isESSArrowContents(cs) ? cs : undefined;
}

function isMaps(cs: T.Content[]): cs is T.MapContent[] {
  return cs.every(c => c.type === T.ContentType.MAP);
}
function isDSMs(cs: T.Content[]): cs is T.DSMContent[] {
  return cs.every(c => c.type === T.ContentType.DSM);
}
function isAreas(cs: T.Content[]): cs is T.AreaContent[] | T.ThreeAreaContent[] {
  return cs.every(c => c.type === T.ContentType.AREA || c.type === T.ContentType.THREE_AREA);
}
function isLengths(cs: T.Content[]): cs is T.LengthContent[] {
  return cs.every(c => c.type === T.ContentType.LENGTH);
}
function isMarkers(cs: T.Content[]): cs is T.MarkerContent[] {
  return cs.every(c => c.type === T.ContentType.MARKER);
}
function isVolumes(cs: T.Content[]): cs is T.VolumeContent[] {
  return cs.every(c => c.type === T.ContentType.VOLUME);
}
function isDesignDXFs(cs: T.Content[]): cs is T.DesignDXFContent[] {
  return cs.every(c => c.type === T.ContentType.DESIGN_DXF);
}
function isPointclouds(cs: T.Content[]): cs is T.PointCloudContent[] {
  return cs.every(c => c.type === T.ContentType.POINTCLOUD);
}
function isThreeDOrthos(cs: T.Content[]): cs is T.ThreeDOrthoContent[] {
  return cs.every(c => c.type === T.ContentType.THREE_D_ORTHO);
}
function isBlueprintDXFs(cs: T.Content[]): cs is T.BlueprintDXFContent[] {
  return cs.every(c => c.type === T.ContentType.BLUEPRINT_DXF);
}
function isBlueprintDWGs(cs: T.Content[]): cs is T.BlueprintDWGContent[] {
  return cs.every(c => c.type === T.ContentType.BLUEPRINT_DWG);
}
function isBlueprintPDFs(cs: T.Content[]): cs is T.BlueprintPDFContent[] {
  return cs.every(c => c.type === T.ContentType.BLUEPRINT_PDF);
}
function isCADContents(cs: T.Content[]): cs is T.CADContent[] {
  return cs.every(
    c => c.type === T.ContentType.BLUEPRINT_DXF || c.type === T.ContentType.BLUEPRINT_DWG
  );
}
function isThreeDMeshs(cs: T.Content[]): cs is T.ThreeDMeshContent[] {
  return cs.every(c => c.type === T.ContentType.THREE_D_MESH);
}
function isGCPGroupContents(cs: T.Content[]): cs is T.GCPGroupContent[] {
  return cs.every(c => c.type === T.ContentType.GCP_GROUP);
}
function isESSModelContents(cs: T.Content[]): cs is T.ESSModelContent[] {
  return cs.every(c => c.type === T.ContentType.ESS_MODEL);
}
function isESSCustomModelContents(cs: T.Content[]): cs is T.ESSCustomModelContent[] {
  return cs.every(c => c.type === T.ContentType.ESS_MODEL_CUSTOM);
}
function isESSArrowContents(cs: T.Content[]): cs is T.ESSArrowContent[] {
  return cs.every(c => c.type === T.ContentType.ESS_ARROW);
}

export function typeGuardMeasurementContent(
  c: T.Content | undefined
): T.MeasurementContent | undefined {
  return isMeasurementContent(c) ? c : undefined;
}
export function isMeasurementContent(c: T.Content | undefined): c is T.MeasurementContent {
  return c !== undefined && T.MeasurementContentTypes.includes(c.type);
}
export function typeGuardOverlayContent(c: T.Content | undefined): T.OverLayContent | undefined {
  return isOverlayContent(c) ? c : undefined;
}
function isOverlayContent(c: T.Content | undefined): c is T.OverLayContent {
  return c !== undefined && T.OverlayContentTypes.includes(c.type);
}
