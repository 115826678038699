import * as Sentry from '@sentry/browser';
import React, { FC, ReactNode, memo, useMemo, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import ChevronSvg from '^/assets/icons/contents-list/chevron.svg';
import CheckSvg from '^/assets/icons/contents-list/check.svg';
import GroupSvg from '^/assets/icons/contents-list/group.svg';
import PartialCheckSvg from '^/assets/icons/contents-list/partial-check.svg';
import UncheckSvg from '^/assets/icons/contents-list/uncheck.svg';
import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import palette from '^/constants/palette';
import { UseL10n, useAuthHeader, useL10n } from '^/hooks';
import { AuthHeader } from '^/store/duck/API';
import { ChangeSelectedGroupId } from '^/store/duck/Groups';
import * as T from '^/types';
import { CANCELLABLE_CLASS_NAME } from '^/components/molecules/CreatingVolumeClickEventHandler';
import Text from './text';
import { updateContentSelectedAtInServer } from '^/utilities/updateContentSelectedAtInServer';
import { useIssueStore } from '^/store/issue';

const RelativeDiv = styled.div({ position: 'relative' });

const CheckboxTooltipBalloonStyle: CSSObject = {
  top: 'calc(100% + 3px)',
  left: 'calc(50% - 2px)',
  transform: 'translateX(-50%)',
  height: '14px',
};
const CheckboxTooltipCustomStyle: WrapperHoverableProps['customStyle'] = {
  tooltipBalloonStyle: CheckboxTooltipBalloonStyle,
};

const GroupTitleWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  height: '32px',
});

const GroupIconRoot = styled.div({
  width: '18px',
  marginRight: '1.5px',
  marginTop: '2px',
});
GroupIconRoot.displayName = 'GroupIconRoot';

const GroupArrowRoot = styled.div<{ isOpened: boolean }>(({ isOpened }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  justifySelf: 'flex-end',

  width: '20px',
  height: '20px',
  borderRadius: '3px',
  transform: isOpened ? 'rotate(180deg)' : 'none',

  ':hover': {
    backgroundColor: '#CED7E5',
  },
}));
GroupArrowRoot.displayName = 'GroupArrowRoot';

const GroupCheckboxRoot = styled.div({
  marginRight: '9px',
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#CED7E5',
  },
});
GroupCheckboxRoot.displayName = 'GroupCheckboxRoot';

interface GroupedContentsHeaderRootProps {
  isHighlighted: boolean;
}

const GroupedContentsHeaderRoot = styled.div<GroupedContentsHeaderRootProps>(
  ({ isHighlighted }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    boxSizing: 'border-box',
    width: '100%',
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '12px',
    fontSize: '15px',
    color: palette.ContentsList.groupHeaderTextGray.toString(),
    backgroundColor: isHighlighted ? palette.toggleButtonGray.toString() : 'none',
    cursor: 'pointer',
  })
);
GroupedContentsHeaderRoot.displayName = 'GroupedContentsHeaderRoot';

const GroupTitleTextWrapper = styled.span({
  marginLeft: '5px',
});

const GroupIcon: FC = () => (
  <GroupIconRoot>
    <GroupSvg />
  </GroupIconRoot>
);

const GroupArrow: FC<{ isOpened: boolean; onClick(e: MouseEvent<HTMLDivElement>): void }> = ({
  isOpened,
  onClick,
}) => (
  <GroupArrowRoot isOpened={isOpened} onClick={onClick}>
    <ChevronSvg />
  </GroupArrowRoot>
);

const GroupCheckbox: FC<{
  readonly isChecked: boolean;
  readonly isPartiallyChecked: boolean;
  onClick(e: MouseEvent<HTMLDivElement>): void;
}> = ({ isChecked, isPartiallyChecked, onClick }) => {
  const [l10n]: UseL10n = useL10n();

  const checkboxSvg: ReactNode = useMemo(() => {
    if (isPartiallyChecked) {
      return <PartialCheckSvg />;
    }

    if (isChecked) {
      return <CheckSvg />;
    }

    return <UncheckSvg />;
  }, [isChecked, isPartiallyChecked]);

  const checkboxTooltip: string = l10n(
    isChecked ? Text.tooltipUnselectAllText : Text.tooltipSelectAllText
  );

  return (
    <RelativeDiv>
      <WrapperHoverable title={checkboxTooltip} customStyle={CheckboxTooltipCustomStyle}>
        <GroupCheckboxRoot onClick={onClick}>{checkboxSvg}</GroupCheckboxRoot>
      </WrapperHoverable>
    </RelativeDiv>
  );
};

interface Props {
  readonly groupValue: string | number;
  readonly groupTitle: string;
  readonly childrenIds: Array<T.IssueContent['id']>;
  readonly isOpened: boolean;
  onSetIsOpen(newIsOpen: boolean): void;
}

/*
 * Group Header
 */
export const GroupedContentsHeader: FC<Props> = memo(
  ({ groupValue, groupTitle, childrenIds, isOpened, onSetIsOpen }) => {
    const dispatch: Dispatch = useDispatch();
    const authHeader: AuthHeader | undefined = useAuthHeader();

    const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);

    const { issuesContentsById, editingIssueContentId, setIssuesContentsById } = useIssueStore();
    const children = childrenIds.map(childId => issuesContentsById[childId]);

    const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);

    if (sidebarTab !== T.ContentPageTabType.ISSUE) {
      return null;
    }

    const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

    const selectedChildren = children.filter((child: T.Content) => child.config?.selectedAt);
    const noChildrenSelected = selectedChildren.length === 0;
    const hasAllChildrenSelected =
      !noChildrenSelected && selectedChildren.length === children.length;
    const isPartiallyChecked = !noChildrenSelected && selectedChildren.length < children.length;
    const isChecked = children.length > 0 && hasAllChildrenSelected;

    const handleGroupArrowClick: (e: MouseEvent<HTMLDivElement>) => void = e => {
      e.stopPropagation();
      onSetIsOpen(!isOpened);
    };

    const handleGroupHeaderClick: (e: MouseEvent<HTMLDivElement>) => void = e => {
      e.stopPropagation();
      onSetIsOpen(!isOpened);
    };

    function alreadyUpToDateContents(content: T.Content): boolean {
      return isChecked ? true : !content?.config?.selectedAt;
    }

    const handleToggleGroupCheckbox: (e: MouseEvent<HTMLDivElement>) => void = async e => {
      try {
        e.stopPropagation();
        dispatch(ChangeSelectedGroupId({ selectedGroupId: undefined, tab: sidebarTab }));

        const selectedAt: Date | undefined = isChecked ? undefined : new Date();

        // Note: Technically an empty group doesn't need to have
        // the ability to be toggled, but it's done anyway to make it consistent
        // with the previous behavior.
        if (children.length === 0) {
          return;
        }
        const outdatedContents: T.Content[] = children.filter(alreadyUpToDateContents);

        const selectIssuesContents = outdatedContents.reduce((acc, content: T.Content) => {
          if (content.id) {
            const newContent: any = {
              ...content,
              config: {
                ...content.config,
                type: content.type,
                selectedAt: selectedAt,
              },
            };
            acc[content.id] = newContent;
          }
          return acc;
        }, {} as Record<number | string, T.IssueContent>);
        setIssuesContentsById({ ...issuesContentsById, ...selectIssuesContents });
        const requests = updateContentSelectedAtInServer(
          outdatedContents,
          authHeader,
          projectId,
          selectedAt
        );
        await requests;
      } catch (event) {
        // eslint-disable-next-line no-console
        console.error(event);
        Sentry.captureException(event);
      }
    };

    useEffect(() => {
      const isHavingItemSelected = childrenIds.some(id => id === editingIssueContentId);
      setIsHighlighted(isHavingItemSelected);
    }, [childrenIds.length, editingIssueContentId]);

    return (
      <GroupedContentsHeaderRoot
        data-ctxsort="Group"
        data-ctxsort-key={groupValue}
        isHighlighted={isHighlighted}
        onClick={handleGroupHeaderClick}
        tabIndex={0}
      >
        <GroupCheckbox
          isChecked={isChecked}
          isPartiallyChecked={isPartiallyChecked}
          onClick={handleToggleGroupCheckbox}
        />
        <GroupIcon />
        <GroupTitleWrapper className={CANCELLABLE_CLASS_NAME}>
          <GroupTitleTextWrapper>{groupTitle}</GroupTitleTextWrapper>
        </GroupTitleWrapper>
        <GroupArrow isOpened={isOpened} onClick={handleGroupArrowClick} />
      </GroupedContentsHeaderRoot>
    );
  }
);
