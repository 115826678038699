import React from 'react';
import { ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import { UseL10n, useL10n } from '^/hooks';

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';

import Text from './text';
import LoadingIcon from '../LoadingIcon';

export const ToastWrapper = styled.div({
  display: 'flex',
  background: palette.white.alpha(0.9).toString(),
  borderRadius: '5px',
  height: '68px',
  alignItems: 'center',
  gap: '20px',
});

export const Container = styled.div<{ size: number }>(({ size }) => ({
  position: 'relative',
  width: `${size}px`,
  height: `${size}px`,
}));

export const Circle = styled.svg({
  transform: 'rotate(-90deg)',
});

export const ProgressCircle = styled.circle<{
  circumference: number;
  strokeWidth: number;
  color: string;
  offset: number;
}>(({ circumference, strokeWidth, color, offset }) => ({
  fill: 'none',
  stroke: color,
  strokeWidth: `${strokeWidth}px`,
  strokeDasharray: `${circumference} ${circumference}`,
  strokeDashoffset: offset,
  transition: 'stroke-dashoffset 0.3s ease',
}));

export const PercentageText = styled.span<{ fontSize: number; color: string }>(
  ({ fontSize, color }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: `${fontSize}px`,
    color: color,
  })
);

export const ToastContent = styled.p({
  color: '#4D4C4C',
  fontSize: '13px',
  fontWeight: 700,
});

export const defaultToasterOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: false,
  draggable: false,
};

export enum ProgressType {
  DOWNLOAD = 'download',
  PARSE = 'parse',
  FETCH = 'fetch',
}

interface DownloadProgressDisplayProps {
  progress: number;
  progressType?: ProgressType.DOWNLOAD | ProgressType.PARSE | ProgressType.FETCH;
}

export const DownloadProgressDisplay: React.FC<DownloadProgressDisplayProps> = ({
  progress,
  progressType = ProgressType.DOWNLOAD,
}) => {
  const [l10n]: UseL10n = useL10n();
  const size = 36;
  const strokeWidth = 4;
  const color = dsPalette.themePrimary.toString();
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;
  return (
    <ToastWrapper>
      {progressType === ProgressType.FETCH ? (
        <LoadingIcon />
      ) : (
        <Container size={size}>
          <Circle width={size} height={size}>
            <ProgressCircle
              cx={size / 2}
              cy={size / 2}
              r={radius}
              color={color}
              strokeWidth={strokeWidth}
              circumference={circumference}
              offset={offset}
            />
          </Circle>
          <PercentageText fontSize={size / 4} color={color}>
            {progress}%
          </PercentageText>
        </Container>
      )}
      <ToastContent>{l10n(Text[progressType].progressMessage)}</ToastContent>
    </ToastWrapper>
  );
};
