import React, { useMemo, FC } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import SingleSlider from '^/components/atoms/SingleSlider';
import { DEFAULT_OPACITY } from '^/constants/defaultContent';
import { DISABLED_CONTENT_OPACITY, FontFamily } from '^/constants/styles';
import { UseL10n, useL10n } from '^/hooks';
import { PatchContent, contentsSelector } from '^/store/duck/Contents';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { useContentsStore } from '^/store/zustand/content/contentStore';

interface DivideProps {
  readonly hasMoreSection: boolean;
}

const Opacity = styled.div<DivideProps>(({ hasMoreSection }) => ({
  height: '53.7px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: hasMoreSection ? '16.9px' : undefined,
  marginTop: '5.2px',
}));

const OpacityText = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '48.5px',
  height: '19px',

  color: 'var(--color-theme-primary)',
  fontFamily: FontFamily.ROBOTO,
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: 1.67,
}));

const OpacitySlider = styled.div({
  width: '100%',
  height: '11px',

  clear: 'both',
  paddingTop: '7.2px',
});

export interface Props {
  readonly content: T.ThreeDOrthoContent;
}

export const RawContentsListThreeDOrthoItem: FC<Props> = ({ content }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  const opacity: number = useMemo(
    () => (content.config?.opacity !== undefined ? content.config.opacity : DEFAULT_OPACITY),
    [content.config?.opacity]
  );
  const isProcessingOrFailed: boolean = contentsSelector.isProcessingOrFailedByContent(content);

  const handleMouseUp: () => void = () => {
    dispatch(
      PatchContent({
        content: { id: content.id, config: { type: T.ContentType.THREE_D_ORTHO, opacity } },
      })
    );
  };

  const handleOpacityChange: (value: number) => void = value => {
    updateContentConfig(content.id, {
      ...content.config,
      type: T.ContentType.THREE_D_ORTHO,
      opacity: value,
    });
  };

  return (
    <ContentsListItem
      className={CANCELLABLE_CLASS_NAME}
      content={content}
      firstBalloonTitle={l10n(Text.firstBalloonTitle)}
    >
      <Opacity hasMoreSection={false}>
        <OpacityText isDisabled={isProcessingOrFailed}>{opacity.toFixed(0)}%</OpacityText>
        <OpacitySlider>
          <SingleSlider
            minValue={0}
            maxValue={100}
            value={opacity}
            onChange={handleOpacityChange}
            onMouseUp={handleMouseUp}
            isDisabled={isProcessingOrFailed}
          />
        </OpacitySlider>
      </Opacity>
    </ContentsListItem>
  );
};

export const ContentsListThreeDOrthoItem: FC<Props> = withErrorBoundary(
  RawContentsListThreeDOrthoItem
)(Fallback);
