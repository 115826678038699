import _ from 'lodash-es';
import { createStore, useStore } from 'zustand';

import * as T from '^/types';
import { devtools } from 'zustand/middleware';
import { initialGroupState } from './constant';
import { addIdWhenInexist, getIndexFromList, logWarning } from './utils';

type Movable = Pick<T.Content, 'id' | 'groupId' | 'screenId' | 'category' | 'type' | 'isPersonal'>;

export interface GroupStateActions {
  setState(key: keyof T.GroupsState, value: T.GroupsState[keyof T.GroupsState]): void;
  changeSelectedGroupId(tab: T.ContentPageTabType, selectedGroupId?: T.Content['groupId']): void;
  addContentToGroupTree(content: T.Movable, moveOption: T.MoveOption, target?: T.Movable): void;
  addContentToCategoryTree(content: T.Movable, moveOption: T.MoveOption, target?: T.Movable): void;
  removeContentFromGroupTree(content: T.Movable): void;
  removeContentFromCategoryTree(content: T.Movable): void;
  reset(): void;
}

type GroupStoreInterface = T.GroupsState & GroupStateActions;

export const groupStore = createStore<GroupStoreInterface>()(
  devtools(set => ({
    ...initialGroupState,

    setState: <K extends keyof T.GroupsState>(key: K, value: T.GroupsState[K]) =>
      set({ [key]: value } as Pick<T.GroupsState, K>),

    changeSelectedGroupId: (tab, selectedGroupId) => {
      set(state => ({
        selectedGroupIdByTab: {
          ...state.selectedGroupIdByTab,
          [tab]: selectedGroupId,
        },
      }));
    },

    addContentToGroupTree: (content, moveOption, target) => {
      set(state => {
        const { idsByGroup } = state.tree;

        if (content.groupId === undefined) {
          // First validate content type
          if (content.type !== T.ContentType.GROUP) {
            logWarning('Non-group content cannot be root', content);
            return idsByGroup;
          }

          if (!idsByGroup[content.id]) {
            idsByGroup[content.id] = [];
          } else {
            logWarning('Unable to add group that already exists', content);
          }
          return idsByGroup;
        }

        if (content.type === T.ContentType.GROUP && !idsByGroup[content.id]) {
          idsByGroup[content.id] = [];
        }

        const currentGroup = idsByGroup[content.groupId];
        if (currentGroup) {
          const index = getIndexFromList(currentGroup, moveOption, target?.id);
          idsByGroup[content.groupId] = addIdWhenInexist(currentGroup, content.id, index);
        } else {
          logWarning('No group id found in the groups tree for', content);
        }
        return idsByGroup;
      });
    },

    removeContentFromGroupTree: content => {
      set(state => ({
        tree: {
          ...state.tree,
          idsByGroup: (() => {
            const idsByGroup = { ...state.tree.idsByGroup };

            if (content.groupId === undefined) {
              if (idsByGroup[content.id]) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete idsByGroup[content.id];
              } else {
                console.warn('Unable to remove group that does not exist', content);
              }
              return idsByGroup;
            }

            const currentGroup = idsByGroup[content.groupId];
            if (currentGroup) {
              const removedList = currentGroup.filter(id => id !== content.id);
              if (removedList.length < currentGroup.length) {
                idsByGroup[content.groupId] = removedList;
              } else {
                console.warn('No group id found in the groups tree for', content);
              }
            }

            return idsByGroup;
          })(),
        },
      }));
    },

    addContentToCategoryTree: (content, moveOption, target) => {
      set(state => {
        // Category tree only consists of root-level items.
        if (content.groupId !== undefined) {
          return state;
        }
        // Update rootIdsByCategory
        const updatedRootIdsByCategory = {
          ...state.tree.rootIdsByCategory,
          [content.category]: (() => {
            const currentCategory = state.tree.rootIdsByCategory[content.category];
            const list =
              content.screenId === undefined
                ? currentCategory.pinned
                : currentCategory.unpinned[content.screenId] ?? [];
            const index = getIndexFromList(list, moveOption, target?.id);

            if (content.screenId === undefined) {
              return {
                ...currentCategory,
                pinned: addIdWhenInexist(currentCategory.pinned, content.id, index),
              };
            }

            return {
              ...currentCategory,
              unpinned: {
                ...currentCategory.unpinned,
                [content.screenId]: addIdWhenInexist(list, content.id, index),
              },
            };
          })(),
        };

        // Update rootIdsBySpace
        const updatedRootIdsBySpace = {
          ...state.tree.rootIdsBySpace,
          [content.category]: (() => {
            const currentSpace = state.tree.rootIdsBySpace[content.category];
            const list = content.isPersonal ? currentSpace.personal : currentSpace.open;
            const index = getIndexFromList(list, moveOption, target?.id);

            if (content.isPersonal) {
              return {
                ...currentSpace,
                personal: addIdWhenInexist(currentSpace.personal, content.id, index),
              };
            } else {
              return {
                ...currentSpace,
                open: addIdWhenInexist(currentSpace.open, content.id, index),
              };
            }
          })(),
        };

        return {
          tree: {
            ...state.tree,
            rootIdsByCategory: updatedRootIdsByCategory,
            rootIdsBySpace: updatedRootIdsBySpace,
          },
        };
      });
    },

    removeContentFromCategoryTree: content => {
      set(state => {
        if (content.groupId !== undefined) {
          // Category tree only consists of root-level items
          return state;
        }

        const updatedRootIdsByCategory = {
          ...state.tree.rootIdsByCategory,
          [content.category]: (() => {
            const currentCategory = state.tree.rootIdsByCategory[content.category];
            const { pinned, unpinned } = currentCategory;

            if (content.screenId === undefined) {
              const removedPinnedList = _.without(pinned, content.id);
              if (removedPinnedList.length < pinned.length) {
                return { pinned: removedPinnedList, unpinned };
              }
            }

            const unpinnedList = unpinned[content.screenId ?? NaN] ?? [];
            const removedUnpinnedList = _.without(unpinnedList, content.id);

            if (
              content.screenId !== undefined &&
              removedUnpinnedList.length < unpinnedList.length
            ) {
              return {
                pinned,
                unpinned: {
                  ...unpinned,
                  [content.screenId]: removedUnpinnedList,
                },
              };
            }

            const fallbackScreenId = Object.keys(unpinned).find(screenId =>
              unpinned[parseInt(screenId, 10)].includes(content.id)
            );

            return {
              pinned: _.without(pinned, content.id),
              unpinned:
                fallbackScreenId === undefined
                  ? unpinned
                  : {
                      ...unpinned,
                      [fallbackScreenId]: _.without(
                        unpinned[parseInt(fallbackScreenId, 10)],
                        content.id
                      ),
                    },
            };
          })(),
        };

        const updatedRootIdsBySpace = {
          ...state.tree.rootIdsBySpace,
          [content.category]: (() => {
            const currentSpace = state.tree.rootIdsBySpace[content.category];
            if (content.isPersonal) {
              return {
                ...currentSpace,
                personal: _.without(currentSpace.personal, content.id),
              };
            }

            return {
              ...currentSpace,
              open: _.without(currentSpace.open, content.id),
            };
          })(),
        };

        return {
          tree: {
            ...state.tree,
            rootIdsByCategory: updatedRootIdsByCategory,
            rootIdsBySpace: updatedRootIdsBySpace,
          },
        };
      });
    },

    reset: () => set(initialGroupState),
  }))
);

export function useGroupStore(): GroupStoreInterface;
export function useGroupStore<T>(selector: (state: GroupStoreInterface) => T): T;
export function useGroupStore<T>(selector?: (state: GroupStoreInterface) => T) {
  return useStore(groupStore, selector!);
}
