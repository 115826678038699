/* eslint-disable max-lines */
import React, { FC, memo, MutableRefObject, ReactNode, useMemo, useRef } from 'react';

import styled from 'styled-components';

import { ContentIcon as RawContentIcon } from '^/components/atoms/ContentIcon';

import { CANCELLABLE_CLASS_NAME } from '^/components/molecules/CreatingVolumeClickEventHandler';

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { DISABLED_CONTENT_OPACITY, MediaQuery } from '^/constants/styles';
import { useL10n } from '^/hooks';

import * as T from '^/types';

import RadioActive from '^/assets/icons/earthwork/radio-active.svg';
import RadioInactive from '^/assets/icons/earthwork/radio-inactive.svg';
import { Earthwork } from '^/store/earthwork/types';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import Tippy from '@tippyjs/react';
import Text from './text';
import DeleteEarthworkButton from './DeleteEarthworkButton';

interface ItemProps {
  isActive?: boolean;
}

export const CONTENTSLIST_CTXSORT_KEY = 'ContentsListItem';

export const Root = styled.li({
  position: 'relative',
  listStyle: 'none',

  width: '100%',
  [MediaQuery[T.Device.MOBILE_L]]: {
    width: 'auto',
  },

  [MediaQuery[T.Device.MOBILE_S]]: {
    width: 'auto',
  },

  zIndex: 'auto',
});

export const SelectionBox = styled.div<ItemProps>(({ isActive }) => ({
  position: 'relative',

  display: 'flex',
  alignItems: 'center',

  boxSizing: 'border-box',
  height: '40px',
  paddingLeft: '24px',
  paddingRight: '24px',
  backgroundColor: `${
    isActive ? palette.ContentsList.titleActiveLightBlue.toString() : palette.white
  }`,

  cursor: 'pointer',
  userSelect: 'none',

  '&:hover': {
    backgroundColor: '#f3f3f3',
  },
}));
SelectionBox.displayName = 'SelectionBox';

export const TitleWrapper = styled.div({
  position: 'relative',
  flex: 1,
  color: dsPalette.title.toString(),
  marginLeft: '1.5px',
});

export const CheckBox = styled.div<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: !isDisabled ? '#CED7E5' : undefined,
  },
}));

export const HorizontalDivider = styled.div<DisabledProps>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '100%',
  borderTop: `1px solid ${palette.toggleButtonGray.toString()}`,

  marginTop: '13px',
  marginBottom: '13px',
}));

export const HorizontalWideDivider = styled(HorizontalDivider)({
  marginTop: '23px',
  marginBottom: '23px',
});

export const ContentIcon = styled(RawContentIcon)<{ isProcessingOrFailed?: boolean }>(
  ({ isProcessingOrFailed }) => ({
    marginLeft: isProcessingOrFailed ? '12px' : '9px',
  })
);

export const FeatureWrapper = styled.div({
  marginLeft: 'auto',

  display: 'flex',
  alignItems: 'center',
});

export interface Props {
  readonly earthwork: Earthwork;
  readonly className?: string;
  displayCheckBox?: boolean;
  onClick?(): void;
}

interface DisabledProps {
  isDisabled?: boolean;
}
/**
 * If you pass children, it will be appended into first balloon
 * at the bottom of content title.
 */
export const EarthworkContent: FC<Props> = ({ earthwork, className, onClick }) => {
  const { selectedEarthworkId } = useEarthWorkStore();
  const [l10n] = useL10n();

  const rootRef: MutableRefObject<HTMLLIElement | null> = useRef(null);

  const isChecked = selectedEarthworkId === earthwork.id;

  const checkBoxSvg: ReactNode = isChecked ? <RadioActive /> : <RadioInactive />;
  const checkBox: ReactNode = <CheckBox isDisabled={false}>{checkBoxSvg}</CheckBox>;

  const title: ReactNode = (
    <TitleWrapper className={CANCELLABLE_CLASS_NAME}> {earthwork.attributes.name} </TitleWrapper>
  );

  const deleteButton: ReactNode = useMemo(
    () => (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom"
        arrow={false}
        content={l10n(Text.tooltipDelete)}
      >
        <DeleteEarthworkButton earthwork={earthwork} isDisabled={false} />
      </Tippy>
    ),
    []
  );

  return (
    <Root id={`earthwork-${earthwork.id}`} ref={rootRef} className={className} onClick={onClick}>
      <SelectionBox isActive={isChecked}>
        <CheckBox isDisabled={false} data-ddm-track-action={''} data-ddm-track-label={''}>
          {checkBox}
        </CheckBox>
        {title}
        {deleteButton}
      </SelectionBox>
    </Root>
  );
};

export const ContentsListItem = memo<FC<Props>>(EarthworkContent);
