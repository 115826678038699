import { GroupedContentsListHeader } from '^/components/molecules/GroupedContentsListHeader/ForDroneStationItems';
import palette from '^/constants/palette';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import React, { FC, memo, useCallback, useRef } from 'react';
import ScrollBars, { ScrollbarProps } from 'react-custom-scrollbars-2';
import styled, { CSSObject } from 'styled-components';
import { Fallback } from './fallback';

import { ContentToItem } from '^/components/atoms/ContentToItem';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { UseL10n, useL10n } from '^/hooks';
import { useFlightPlanStore } from '^/store/flightPlanStore';
import { useGetFlightPlansQuery } from '^/store/react-query/drone-station/flight-plan';
import * as T from '^/types';
import Text from './text';

const HorizontalDividerWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  paddingTop: '3px',
  paddingLeft: '10px',
  paddingRight: '10px',

  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});

const HorizontalDivider = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '1px',
  background: palette.toggleButtonGray.toString(),
});

export const FlightPlanContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',
  fontSize: '15px',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),

  display: 'flex',
  flexDirection: 'column',
});
FlightPlanContentsListRoot.displayName = 'GroupedContentsListRoot';

export const FlightPlansWrapper = styled.div({});

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const LoaderIconWrapper = styled.div({
  width: '100%',
  height: '60px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: palette.white.toString(),
});

const LoaderText = styled.p({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '44px',
  fontSize: '14px',
  textAlign: 'center',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
  color: palette.ContentsList.groupListHeaderTextGray.toString(),
});

const GroupListDivider = styled.div({
  position: 'sticky',
  zIndex: 10,
  top: '43px',
  height: '6px',
  marginTop: '17.5px',
  borderTop: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  borderBottom: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  backgroundColor: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  boxSizing: 'border-box',
});
GroupListDivider.displayName = 'GroupListDivider';

const ScrollBarsWrapper = styled(ScrollBars as any)<ScrollbarProps>({
  height: '100%',
});
const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

const FlightPlansContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const FlexGrowWrapper = styled.div({
  flexGrow: '1',
});

const Contents: FC = () => {
  const [l10n]: UseL10n = useL10n();
  const {
    data: flightPlans,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
  } = useGetFlightPlansQuery();

  /**
   * TODO: @ebraj-angelswing
   * Make it reusable - used in the flightschedulecontentslist also...
   */
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          void fetchNextPage();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  const rawLoadingDiv: React.ReactNode = (
    <LoaderIconWrapper>
      <LoadingIcon />
    </LoaderIconWrapper>
  );

  const rawLoadingText: React.ReactNode = <LoaderText>{l10n(Text.emptyData)}</LoaderText>;

  if (isLoading || isError) {
    return <>{rawLoadingText}</>;
  }

  const allFlightPlans = flightPlans.pages.flatMap(single => single.list) ?? [];

  return (
    <FlightPlansContainer>
      <ScrollBarsWrapper
        renderView={ScrollBarView}
        autoHide={true}
        autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
        autoHideDuration={HIDE_SCROLL_BAR_DURATION}
      >
        {allFlightPlans.map(content => (
          <FlightPlansWrapper key={content.id} ref={lastElementRef}>
            <ContentToItem contentId={content.id} isFlightPlan={true} />
          </FlightPlansWrapper>
        ))}

        {hasNextPage && isFetching ? <>{rawLoadingDiv}</> : null}
      </ScrollBarsWrapper>
    </FlightPlansContainer>
  );
};

const FlightPlanContentsList: FC = memo(() => {
  const { selectedFlightPlanSort, setSelectedFlightPlanSort } = useFlightPlanStore(s => ({
    selectedFlightPlanSort: s.selectedFlightPlanSort,
    setSelectedFlightPlanSort: s.setSelectedFlightPlanSort,
  }));

  const onChangeSeletedFlightPlanSort = (
    newSelectedFlightPlanSort: T.FlightPlanSortByCriteria
  ): void => {
    setSelectedFlightPlanSort(newSelectedFlightPlanSort);
  };

  return (
    <FlightPlanContentsListRoot>
      <GroupedContentsListHeader
        selectedSortValue={selectedFlightPlanSort}
        onChangeSortValue={onChangeSeletedFlightPlanSort}
      />
      <HorizontalDividerWrapper>
        <HorizontalDivider />
      </HorizontalDividerWrapper>
      <FlexGrowWrapper>
        <Contents />
      </FlexGrowWrapper>
    </FlightPlanContentsListRoot>
  );
});

export default memo(withErrorBoundary(FlightPlanContentsList)(Fallback));
