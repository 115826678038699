import { AxiosError } from 'axios';
import { FetchStatus, QueryStatus } from '@tanstack/react-query';
import * as T from '^/types';

const customQueryStatus: {
  [k in QueryStatus | FetchStatus]: T.APIStatus;
} = {
  idle: T.APIStatus.IDLE,
  loading: T.APIStatus.PROGRESS,
  fetching: T.APIStatus.PROGRESS,
  error: T.APIStatus.ERROR,
  success: T.APIStatus.SUCCESS,
  paused: T.APIStatus.IDLE,
};

export const mapQueryStatus = (status: QueryStatus | FetchStatus) => customQueryStatus[status];

export const getRequestErrorType: (axiosError: AxiosError) => T.HTTPError = axiosError => {
  const HTTPStatusBoundary: number = 100;
  const ClientErrorDigit: number = 4;
  const ServerErrorDigit: number = 5;

  if (!axiosError.response) {
    return T.HTTPError.UNKNOWN_ERROR;
  }

  if (axiosError.response.status === 409) {
    return T.HTTPError.CLIENT_OUTDATED_ERROR;
  }

  // One example is when a content is created with inexistent group id.
  if (axiosError.response.status === 406) {
    return T.HTTPError.CLIENT_NOT_ACCEPTED_ERROR;
  }

  // 403 means user is unauthorized to do certain action.
  // This includes logging in to a plan that doesn't belong to them,
  // or accessing a project that doesn't belong to them.
  if (axiosError.response.status === 403) {
    return T.HTTPError.CLIENT_UNAUTHORIZED_ERROR;
  }

  if (axiosError.response.status === 404) {
    return T.HTTPError.CLIENT_NOT_FOUND_ERROR;
  }

  switch (Math.round(axiosError.response.status / HTTPStatusBoundary)) {
    case ClientErrorDigit:
      return T.HTTPError.CLIENT_ERROR;
    case ServerErrorDigit:
      return T.HTTPError.SERVER_ERROR;
    default:
      return T.HTTPError.UNKNOWN_ERROR;
  }
};

export const mapQueryError: (error: any) => T.HTTPError | undefined = error => {
  if (error?.response) {
    return getRequestErrorType(error);
  }
  return error;
};
