import React, { useEffect, useState } from 'react';
import { Option } from '^/components/atoms/AttachDropdown';
import styled from 'styled-components';
import Text from './text';
import { useL10n } from '^/hooks';
import palette from '^/constants/palette';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import SelectedPlaceholder from './SelectedPlaceholder';
import useEarthWorkFile from '^/hooks/earthwork/useEarthWorkFile';
import { Option as DropdownOption } from '^/components/atoms/Dropdown/1';
import { ContentWrapper, CurrentSelectedContentWrapper, Item, ItemName } from './SelectRockLayer';
import { Label } from '^/components/molecules/SourcePhotoFileInput';
import { ContentIcon } from '^/components/atoms/ContentIcon';
import * as T from '^/types';
import DxfContentSelectDropdown from './DxfContentSelectDropdown';
import { CheckboxWrapper } from '^/components/molecules/TimelapseInspectionlist';
import { Checkbox } from '^/components/molecules/PhotoList/style';
import dsPalette from '^/constants/ds-palette';
import {
  ChangeContentsSidebarTab,
  ChangeCreatingVolume,
  ChangeCurrentContentTypeFromAnnotationPicker,
  OpenContentPageMapPopup,
} from '^/store/duck/Pages';
import { useDispatch } from 'react-redux';
import { useToggle3D } from '^/hooks/useToggle3D';
import { ContentsListItemTitle } from '^/components/atoms/ContentsListItemTitle';
import { useContentsStore } from '^/store/zustand/content/contentStore';

export const Wrapper = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
  marginBottom: '10px',
});

export const AttachDropdownWrapper = styled.div({
  height: '40px',
  width: '100%',
  textAlign: 'left',
});

export const SelectedAttachDropdownWrapper = styled.div({
  minHeight: '40px',
  width: '100%',
  textAlign: 'left',
  marginTop: '10px',
});

export const EmptyZoneContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  padding: '10px 20px',
});

export const EmptyText = styled.p({
  fontSize: '13px',
  textAlign: 'center',
  lineHeight: '17px',
  color: palette.textBlack.toString(),
});

export const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '4px',
});

export const TitleWrapper = styled.span({
  position: 'relative',
  width: '130px',
});

export const RouteText = styled.a({
  fontSize: '13px',
  textAlign: 'center',
  lineHeight: '12px',
  marginRight: '8px',
  color: dsPalette.themePrimary.toString(),
  textDecoration: 'underline',
  fontWeight: 'bold',
  '&:hover': {
    opacity: 0.8,
  },
});

const FinalEarthWorkSelectAttachment = () => {
  const [l10n] = useL10n();
  const dispatch = useDispatch();
  const { isIn3D, on3DToggle } = useToggle3D();

  const {
    setFinalEarthWork,
    finalEarthWork,
    setSelectedZones,
    selectedZones,
    setIsAddNowIsActive,
  } = useEarthWorkStore();
  const { dxfOptions, getZones } = useEarthWorkFile();
  const [zones, setZones] = useState<DropdownOption[]>([]);

  const handleDropdownClick = (value: Option['value']) => {
    setFinalEarthWork({
      dxfId: value,
    });
  };

  let dxfTitle = '';
  let designDxfId = finalEarthWork?.dxfId;

  if (finalEarthWork) {
    if (finalEarthWork.dxfId) {
      if (typeof finalEarthWork?.dxfId === 'string') {
        designDxfId = parseInt(finalEarthWork?.dxfId, 10);
      }
      dxfTitle =
        dxfOptions.find(option => option.value.toString() === finalEarthWork.dxfId?.toString())
          ?.text || '';
    } else {
      dxfTitle = finalEarthWork.uploadedDXF?.name || '';
    }
  }

  const onDeleteClick = () => {
    setFinalEarthWork(undefined);
    setSelectedZones(undefined);
  };

  const onClickAddNow = () => {
    if (!finalEarthWork?.dxfId) {
      return;
    }
    if (isIn3D) {
      on3DToggle();
    }
    setIsAddNowIsActive(true);
    dispatch(ChangeContentsSidebarTab({ sidebarTab: T.ContentPageTabType.MEASUREMENT }));
    dispatch(ChangeCreatingVolume({ info: { type: T.VolumeCalcMethod.DESIGN } }));
    dispatch(
      ChangeCurrentContentTypeFromAnnotationPicker({
        currentContentTypeFromAnnotationPicker: T.ContentType.VOLUME,
      })
    );
    dispatch(OpenContentPageMapPopup({ popupType: T.ContentPagePopupOnMapType.DESIGN_DXF_SELECT }));
    dispatch(
      ChangeCreatingVolume({
        info: { designDxfId: designDxfId },
      })
    );
  };

  const selectZones = (
    <CurrentSelectedContentWrapper>
      <Header>
        <Label hasError={false}>
          {l10n(Text.finalEarthWork.selectedZones)}: {selectedZones?.length ?? 0}
        </Label>

        <RouteText
          onClick={onClickAddNow}
          data-ddm-track-action={T.TrackActions.EARTH_WORK}
          data-ddm-track-label={T.TrackLabels.BTN_VOLUME_NOW}
        >
          {l10n(Text.finalEarthWork.route)}
        </RouteText>
      </Header>
      <ContentWrapper>
        {zones.length > 0 ? (
          zones.map((zone, index) => (
            <ZoneContent key={`selected-zone-type-${index}`} zone={zone} />
          ))
        ) : (
          <EmptyZoneContent>
            <EmptyText>{l10n(Text.finalEarthWork.emptyZone)}</EmptyText>
          </EmptyZoneContent>
        )}
      </ContentWrapper>
    </CurrentSelectedContentWrapper>
  );

  useEffect(() => {
    if (finalEarthWork?.dxfId) {
      setZones(getZones(finalEarthWork.dxfId));
    }
  }, [finalEarthWork?.dxfId]);

  return (
    <Wrapper>
      <SelectedAttachDropdownWrapper>
        {finalEarthWork ? (
          <>
            <SelectedPlaceholder
              textValue={dxfTitle}
              onDeleteClick={onDeleteClick}
              onClick={() => {}}
              placeHolderText={l10n(Text.finalEarthWork.placeHolder)}
            />
            {selectZones}
          </>
        ) : (
          <AttachDropdownWrapper>
            <DxfContentSelectDropdown onSelect={handleDropdownClick} />
          </AttachDropdownWrapper>
        )}
      </SelectedAttachDropdownWrapper>
    </Wrapper>
  );
};
export default FinalEarthWorkSelectAttachment;

const ZoneContent = ({ zone }: { zone: DropdownOption }) => {
  const { setSelectedZones, selectedZones } = useEarthWorkStore();

  const byId = useContentsStore(s => s.contents.byId);

  const { value } = zone;
  const isSelected = Boolean(selectedZones?.includes(value));

  const handleSelect = (isChecked: boolean) => {
    if (!isChecked) {
      setSelectedZones(selectedZones?.filter(zoneId => zoneId !== value));
    } else {
      setSelectedZones([...(selectedZones ?? []), value]);
    }
  };

  return (
    <Item>
      <ItemName>
        <CheckboxWrapper isSelected={true}>
          <Checkbox onChange={handleSelect} checked={isSelected} show={true} isMonth={true} />
        </CheckboxWrapper>
        <ContentIcon contentType={T.ContentType.VOLUME} color={'#FF0000'} />
      </ItemName>
      <TitleWrapper>
        <ContentsListItemTitle
          id={value as string}
          content={byId[value]}
          fromUI={T.EditableTextUI.TOPBAR}
        />
      </TitleWrapper>
    </Item>
  );
};
