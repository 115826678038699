import proj4 from 'proj4';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectManageTab, { Props } from '^/components/organisms/ProjectManageTab';
import { isMarker } from '^/hooks';
import { useReduxState } from '^/hooks/useReduxState';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { PatchContent } from '^/store/duck/Contents';
import { OpenProjectPagePopup } from '^/store/duck/Pages';
import { PatchProject } from '^/store/duck/Projects';
import * as T from '^/types';

import { getEPSGfromProjectionLabel } from '^/utilities/coordinate-util';
import { useParams } from 'react-router-dom';
import { projectPageManageEnterActions, projectPageManageExitActions } from '^/store/duck/router';
import { usePlanConfig } from '^/hooks/usePlanConfig';

type StatePropsKey = 'project' | 'patchStatus' | 'timezoneOffset' | 'auth' | 'slug';
type DispatchPropsKey =
  | 'onDeleteClick'
  | 'onShareClick'
  | 'onSubmit'
  | 'updateCoordinates'
  | 'displayNoPermissionPopup'
  | 'changeAuthedUser';
export type OwnProps = Omit<Props, StatePropsKey | DispatchPropsKey>;
export type StateProps = Pick<Props, StatePropsKey>;
export type DispatchProps = Pick<Props, DispatchPropsKey>;

export default function () {
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.id;
  const { Pages, Projects, Auth, PlanConfig } = useReduxState();
  const editingProjectId = useSelector((s: T.State) => s.Pages.Project.editingProjectId);
  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);

  usePlanConfig();

  useEffect(() => {
    if (!projectId) {
      return;
    }

    projectPageManageEnterActions(Number(projectId)).forEach(dispatch);

    return () => projectPageManageExitActions().forEach(dispatch);
  }, [projectId]);

  const stateProps: StateProps = {
    patchStatus: Projects.patchProjectStatus,
    timezoneOffset: Pages.Common.timezoneOffset,
    project: projectById[editingProjectId ?? NaN],
    auth: Auth,
    slug: PlanConfig.config?.slug,
  };

  const dispatchProps: DispatchProps = {
    onDeleteClick(): void {
      dispatch(OpenProjectPagePopup({ popup: T.ProjectPagePopupType.DELETE }));
    },
    onShareClick(): void {
      dispatch(OpenProjectPagePopup({ popup: T.ProjectPagePopupType.SHARE }));
    },
    onSubmit(
      id: number,
      title: string,
      description: string,
      coordinateSystem?: T.ProjectionEnum,
      logo?: File,
      unit?: T.ValidUnitType,
      projectConfig?: T.Project['projectConfig']
    ): void {
      dispatch(
        PatchProject({
          project: {
            id,
            title,
            description,
            coordinateSystem,
            logo,
            unit,
            projectConfig,
          },
        })
      );
    },
    changeAuthedUser(): void {
      dispatch(ChangeAuthedUser({}));
    },
    async updateCoordinates(
      targetContent: T.Content,
      from: T.ProjectionEnum,
      to: T.ProjectionEnum
    ): Promise<void> {
      if (!isMarker(targetContent)) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/await-thenable
      dispatch(
        PatchContent({
          content: {
            id: targetContent.id,
            info: {
              location: proj4(
                getEPSGfromProjectionLabel(from),
                getEPSGfromProjectionLabel(to)
              ).forward(targetContent.info.location),
            },
          },
        })
      );
    },
    displayNoPermissionPopup(): void {
      dispatch(OpenProjectPagePopup({ popup: T.ProjectPagePopupType.NO_PERMISSION }));
    },
  };
  return <ProjectManageTab {...stateProps} {...dispatchProps} />;
}
