import { Language } from '^/types';

export const LINK_CUSTOM_TAG = '<link>';

export default {
  sizeOfPoint: {
    [Language.KO_KR]: '점의 크기',
    [Language.EN_US]: 'Point Size',
  },
  numberOfPoints: {
    [Language.KO_KR]: '점의 개수',
    [Language.EN_US]: 'Number of Points',
  },
  sizeOfPointTooltip: {
    [Language.KO_KR]: '모델을 구성하는 점의 크기를 조정합니다.',
    [Language.EN_US]: 'Resize point of the model',
  },
  numberOfPointsTooltip: {
    [Language.KO_KR]: '모델을 구성하는 전체 점의 개수를 조정합니다.',
    [Language.EN_US]: 'Adjust the number of total points in the model',
  },
  unit: {
    [Language.KO_KR]: '백만 개',
    [Language.EN_US]: 'M',
  },
};
