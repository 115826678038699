import ContractIcon from '^/assets/icons/vslam-three-sixty/contract.svg';
import ExpandIcon from '^/assets/icons/vslam-three-sixty/expand.svg';
// import ResizeIcon from '^/assets/icons/vslam-three-sixty/resize.svg';
import DragResizeIcon from '^/assets/icons/vslam-three-sixty/drag-resize.svg';
import { OlFloorPlanMapLayerVslamNewUI } from '^/components/atoms/OlFloorPlanMapLayerVslam/NewUI';
import { OlVslamDWGDXF } from '^/components/atoms/OlFloorPlanMapLayerVslam/OlVslamDWGDXF';
import { OlGoogleMapLayerVslam } from '^/components/atoms/OlGoogleMapLayerVslam';
import { OlMapProviderMinimap } from '^/components/atoms/OlMapProvider/OlMapProviderMinimap';
import { OlVectorLayerPhotoMinimap } from '^/components/atoms/OlVectorLayerPhoto/OlVectorLayerPhotoMinimap';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { useThreeStore } from '^/components/three/ThreeStore';
import palette from '^/constants/palette';
import { usePhotoQuery } from '^/hooks/api/photos/usePhoto';
import { useVslamAnd360Store } from '^/store/vslamAnd360Store';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import * as T from '^/types';
import gsap from 'gsap';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import MapZoomForMini from '../MapZoom/MapZoomForMini';
import Switch2d3dMinimap from '../Switch2d3dMinimap';

type TMiniMapSize = [number, number];

const Root = styled.div({});
const MINI_MAP_HEIGHT: number = 250;
const MINI_MAP_WIDTH: number = 350;

const ThreeSixtyHolderWithController = styled.div<{
  miniMapSize: TMiniMapSize;
  isMiniMapExpanded: boolean;
}>(props => ({
  position: 'absolute',

  zIndex: '299',

  overflow: 'hidden',
  display: 'block',
  background: palette.white.toString(),
  opacity: 0,

  ...(props.isMiniMapExpanded
    ? {
        width: props.miniMapSize[0],
        height: '100%',
        bottom: '0px',
        left: '0px',
        borderRadius: '0px',
        borderRight: `1px solid ${palette.white.toString()}`,
      }
    : {
        width: props.miniMapSize[0],
        height: props.miniMapSize[1],
        bottom: '20px',
        left: '20px',
        borderRadius: '10px',
        border: `0px solid ${palette.white.toString()}`,
      }),
}));

const ThreeSixtyOverlayHolder3D = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  background: palette.white.toString();
`;

const ThreeSixtyOverlayHolder2D = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  background: palette.white.toString();
`;

const ResizeButton = styled.div({
  position: 'absolute',
  right: '10px',
  top: '10px',

  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  userSelect: 'none',

  '&:hover': {
    cursor: 'all-scroll',
  },
});

const ExpandContractButton = styled.div({
  position: 'absolute',
  left: '10px',
  bottom: '10px',
  fontSize: '12px',
  padding: '5px',
  opacity: 0.7,

  cursor: 'pointer',
  borderRadius: '3px',
  backgroundColor: '#ffffff',
  ':hover': {
    opacity: 0.9,
  },
});

const OlMapViewerTarget = styled.div.attrs({
  id: 'threesixty-ol-minimap',
})({
  position: 'relative',
  touchAction: 'none',
  width: '100%',
  height: '100%',
});

const ThreeSixtyMiniMap = () => {
  const [miniMapSize, setMiniMapSize] = useState<TMiniMapSize>([MINI_MAP_WIDTH, MINI_MAP_HEIGHT]);
  const [isMiniMapExpanded, setIsMinimapExpanded] = useState<boolean>(false);
  const [is2DMinimapSelected, setIs2DMinimapSelected] = useState<boolean>(true);
  const { miniMap2D } = useThreePhotoAlbumStore(state => state.threeSixtyMiniMapDetails);
  const { isPhotosFromVSLAM, selectedVideoId } = useVslamAnd360Store(
    state => state.georeferencingMapDetails
  );
  const byId = useContentsStore(s => s.contents.byId);
  const { rawVideos } = usePhotoQuery();
  const selectedVideo = rawVideos.find(single => single.id === selectedVideoId);
  const viewer = useThreeStore(s => s.viewer);
  const threeSixtyMiniMapRef = useRef<HTMLDivElement>(null!);

  const floorPlanComponent = useMemo(() => {
    if (!selectedVideo) {
      return null;
    }
    const singleContent = byId[selectedVideo.floorPlan?.contentId ?? ''];

    switch (singleContent.type) {
      case T.ContentType.BLUEPRINT_PDF: {
        const geoPoint = singleContent.info.geoPoint;
        const imagePoint = singleContent.info.imagePoint;
        return (
          <OlFloorPlanMapLayerVslamNewUI
            zIndex={200}
            content={singleContent}
            updatedGeoPoint={geoPoint}
            updatedImagePoint={imagePoint}
            baseMap={miniMap2D}
          />
        );
      }
      case T.ContentType.BLUEPRINT_DWG:
      case T.ContentType.BLUEPRINT_DXF: {
        return <OlVslamDWGDXF content={singleContent} baseMap={miniMap2D} zIndex={200} />;
      }
      default:
        return <></>;
    }
  }, [selectedVideo?.id, miniMap2D]);

  const handleMiniMapMoveOnMap = (event: MouseEvent | TouchEvent): void | boolean => {
    let clientY: number | undefined;
    let clientX: number | undefined;
    event.preventDefault();

    event.stopPropagation();
    switch (event.type) {
      case 'touchmove':
        clientY = (event as TouchEvent).targetTouches[0].clientY;
        clientX = (event as TouchEvent).targetTouches[0].clientX;
        break;
      case 'mousemove':
        clientY = (event as MouseEvent).clientY;
        clientX = (event as MouseEvent).clientX;
        break;
      default:
        return true;
    }

    const obtainedHeight = window.innerHeight;
    const obtainedWidth = window.innerWidth;
    let newHeight = obtainedHeight - clientY;
    let newWidth = isMiniMapExpanded ? clientX + 20 : clientX;

    const maxAllowedWidth = obtainedWidth / 2;
    const maxNotExpandedWidth = obtainedWidth * (4 / 6);
    const maxNotExpandedHeight = obtainedHeight * (3 / 4);

    const withinHeightBounds =
      newHeight >= MINI_MAP_HEIGHT &&
      newHeight <= (isMiniMapExpanded ? obtainedHeight : maxNotExpandedHeight);
    const withinWidthBounds =
      newWidth >= MINI_MAP_WIDTH &&
      newWidth <= (isMiniMapExpanded ? maxAllowedWidth : maxNotExpandedWidth);

    if (!withinHeightBounds) {
      if (newHeight < MINI_MAP_HEIGHT) {
        newHeight = MINI_MAP_HEIGHT;
      } else if (isMiniMapExpanded) {
        if (newHeight > obtainedHeight) {
          newHeight = obtainedHeight;
        }
      } else {
        if (newHeight > maxNotExpandedHeight) {
          newHeight = maxNotExpandedHeight;
        }
      }
    }

    if (!withinWidthBounds) {
      if (newWidth < MINI_MAP_WIDTH) {
        newWidth = MINI_MAP_WIDTH;
      } else if (isMiniMapExpanded) {
        if (newWidth > maxAllowedWidth) {
          newWidth = maxAllowedWidth;
        }
      } else {
        if (newWidth > maxNotExpandedWidth) {
          newWidth = maxNotExpandedWidth;
        }
      }
    }

    document.documentElement.style.cursor = 'col-resize';
    window.dispatchEvent(new Event('resize'));
    handleVerticalMiniMapSize([newWidth, newHeight]);

    return;
  };

  const handleMiniMapPointerDown: () => void = () => {
    document.addEventListener('mouseup', handleMiniMapPointerUp);
    document.addEventListener('mousemove', handleMiniMapMoveOnMap);
    document.addEventListener('touchstart', handleMiniMapPointerUp);
    document.addEventListener('touchmove', handleMiniMapMoveOnMap);
  };

  const handleMiniMapPointerUp: () => void = () => {
    window.dispatchEvent(new Event('resize'));
    document.documentElement.style.cursor = 'default';
    document.removeEventListener('mouseup', handleMiniMapPointerUp);
    document.removeEventListener('mousemove', handleMiniMapMoveOnMap);
    document.removeEventListener('touchstart', handleMiniMapPointerUp);
    document.removeEventListener('touchmove', handleMiniMapMoveOnMap);
  };

  const handleVerticalMiniMapSize: (position: TMiniMapSize) => void = useCallback(
    (newSize: TMiniMapSize) => {
      setMiniMapSize(newSize);
    },
    [miniMapSize]
  );

  const handleMiniMapExpandChange: (stateChange: boolean) => void = useCallback(
    stateChange => {
      setIsMinimapExpanded(stateChange);
    },
    [isMiniMapExpanded]
  );

  const onExpandContractButtonClick = () => {
    setMiniMapSize([MINI_MAP_WIDTH, MINI_MAP_HEIGHT]);
    miniMap2D?.updateSize();
    handleMiniMapExpandChange(!isMiniMapExpanded);
  };

  const handle2DChangeTo3DMap = (newVal: boolean) => {
    setIs2DMinimapSelected(newVal);
  };

  useEffect(() => {
    miniMap2D?.updateSize();
    if (viewer) {
      viewer.controllers.photoAlbumController?.resizeSplitView();
    }
  }, [isMiniMapExpanded]);

  useEffect(() => {
    if (is2DMinimapSelected) {
      miniMap2D?.updateSize();
    }
  }, [is2DMinimapSelected]);

  useEffect(() => {
    const handleMiniMapResizeCustom = () => {
      if (!threeSixtyMiniMapRef.current) {
        return;
      }
      const obtainedWidth = window.innerWidth;
      const newHeight = threeSixtyMiniMapRef.current.clientHeight;
      let newWidth = threeSixtyMiniMapRef.current.clientWidth;

      if (!newWidth || !newHeight) {
        return;
      }
      if (obtainedWidth < newWidth) {
        return;
      }

      const maxAllowedWidth = obtainedWidth / 2;
      const maxNotExpandedWidth = obtainedWidth * (4 / 6);

      const withinWidthBounds =
        newWidth >= MINI_MAP_WIDTH &&
        newWidth <= (isMiniMapExpanded ? maxAllowedWidth : maxNotExpandedWidth);

      if (!withinWidthBounds) {
        if (newWidth <= MINI_MAP_WIDTH) {
          newWidth = MINI_MAP_WIDTH;
        } else if (isMiniMapExpanded) {
          if (newWidth >= maxAllowedWidth) {
            newWidth = maxAllowedWidth;
          }
        } else {
          if (newWidth >= maxNotExpandedWidth) {
            newWidth = maxNotExpandedWidth;
          }
        }
      }

      handleVerticalMiniMapSize([newWidth, newHeight]);
    };

    window.addEventListener('resize', handleMiniMapResizeCustom);
    return () => {
      window.removeEventListener('resize', handleMiniMapResizeCustom);
    };
  }, [threeSixtyMiniMapRef.current, isMiniMapExpanded]);

  useEffect(() => {
    if (threeSixtyMiniMapRef.current) {
      gsap.to(threeSixtyMiniMapRef.current, { opacity: 1, duration: 1, delay: 0.5 });
    }
  }, [threeSixtyMiniMapRef.current]);

  return (
    <Root>
      <ThreeSixtyHolderWithController
        miniMapSize={miniMapSize}
        isMiniMapExpanded={isMiniMapExpanded}
        ref={threeSixtyMiniMapRef}
        id="threesixty-overlay-holder-parent"
      >
        <ThreeSixtyOverlayHolder2D isVisible={is2DMinimapSelected}>
          <OlMapProviderMinimap MapTarget={OlMapViewerTarget}>
            {floorPlanComponent}
            <OlVectorLayerPhotoMinimap />
            {!isPhotosFromVSLAM && <OlGoogleMapLayerVslam zIndex={100} baseMap={miniMap2D} />}
          </OlMapProviderMinimap>
        </ThreeSixtyOverlayHolder2D>

        <ThreeSixtyOverlayHolder3D isVisible={true} id="threesixty-overlay-holder" />

        <>
          <Switch2d3dMinimap
            is2DMinimapSelected={is2DMinimapSelected}
            handle2DChangeTo3DMap={handle2DChangeTo3DMap}
          />
          <ResizeButton
            onMouseDown={handleMiniMapPointerDown}
            onTouchStart={handleMiniMapPointerDown}
          >
            <DragResizeIcon />
          </ResizeButton>
          <MapZoomForMini is2DMinimapSelected={is2DMinimapSelected} />
          <ExpandContractButton onClick={onExpandContractButtonClick}>
            {isMiniMapExpanded ? <ContractIcon /> : <ExpandIcon />}
          </ExpandContractButton>
        </>
      </ThreeSixtyHolderWithController>
    </Root>
  );
};

export default ThreeSixtyMiniMap;
