import theme from '^/theme';
import React, { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import PhotoAlbumTitleInput from '../PhotoAlbum/PhotoAlbumTitleInput';
import { TimelapseCollection } from '^/store/zustand/timelapse/timelapseStore';
import useTimelapseContents from '^/hooks/timelapse/useTimelapseContents';
import { useParams } from 'react-router-dom';

const EditableTitleContainerRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '20px',
});

EditableTitleContainerRoot.displayName = 'PhotoAlbumTitleEditable';

const AlbumTitle = styled.div({
  color: theme.colors.black[100],
  fontSize: '18px',
  fontWeight: 700,
  ':hover': {
    backgroundColor: theme.colors.coolGray[100],
    color: theme.colors.coolGray[800],
  },
  padding: '5px 8px',
  borderRadius: '4px',
  cursor: 'text',
});

export const TimelapseTitleEditable = ({
  timelapseCollection,
}: {
  timelapseCollection: TimelapseCollection;
}) => {
  const title = timelapseCollection?.name
    ? timelapseCollection.name
    : `Location ${timelapseCollection.id}`;

  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const { id: projectId } = useParams();

  const { renameTimelapseCollection } = useTimelapseContents(Number(projectId!));

  const handleRenameOptionClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setEditedTitle(title);
    setIsEditing(true);
  };

  const handleCompleteRename = async () => {
    await renameTimelapseCollection(timelapseCollection.id, editedTitle);
  };

  return (
    <EditableTitleContainerRoot>
      {isEditing ? (
        <PhotoAlbumTitleInput
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          editedTitle={editedTitle}
          setEditedTitle={setEditedTitle}
          onSave={handleCompleteRename}
          onLightBg={true}
          customStyles={{
            fontSize: '18px',
            fontWeight: 700,
          }}
        />
      ) : (
        <AlbumTitle onClick={handleRenameOptionClick}>{title}</AlbumTitle>
      )}
    </EditableTitleContainerRoot>
  );
};
