/* eslint-disable max-len */
import palette from '^/constants/palette';
import styled from 'styled-components';

const WHITE_COLOR: string = palette.white.toString();
const UNFILLED_COLOR: string = palette.white.alpha(0.4).toString();
const LOADED_COLOR: string = palette.white.alpha(0.65).toString();
const MOUSE_HOVER_COLOR_A: string = palette.white.alpha(0.8).toString();
const MOUSE_HOVER_COLOR_B: string = palette.white.alpha(0.55).toString();

export const VideoPlayerItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

export const Container = styled.div({
  width: '100%',
  display: 'block',
  boxSizing: 'border-box',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '16px',
  paddingRight: '16px',
});

export const PlayerWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
});

export const ControlContainer = styled.div({
  //   backgroundColor: 'rgba(0, 0, 0, 0.6)',
  position: 'absolute',
  top: '0',
  bottom: '0',
  right: '0',
  left: '0',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  zIndex: '1',
  display: 'flex',
  justifyContent: 'space-between',
});

export const TopContainer = styled.div({
  justifyContent: 'center',
  display: 'flex',
  marginTop: 'auto',
  alignItems: 'center',
  alignSelf: 'center',
});

export const MidContainer = styled.div({
  display: 'flex',
  marginTop: 'auto',
  justifyContent: 'flex-end',
  padding: '0 12px',
});

export const BottomContainer = styled.div({
  // marginTop: 'auto',
  display: 'flex',
  // padding: '1.3% 0',
  justifyContent: 'space-between',

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    left: 0,
    background: 'linear-gradient(360deg, #2C323B 0%, rgba(44, 50, 59, 0) 100%)',
    height: '102px',
    zIndex: -1,
  },
});

export const VolumeWrapper = styled.label({
  cursor: 'pointer',
  display: 'flex',
  width: '0px',
  opacity: 0,
  WebkitBoxOrient: 'horizontal',
  WebkitBoxDirection: 'reverse',
  MsFlexDirection: 'row-reverse',
  flexDirection: 'row-reverse',
  WebkitBoxAlign: 'center',
  MsFlexAlign: 'center',
  alignItems: 'center',
});

export const VolumeContainer = styled.div({
  display: 'flex',
  padding: '0 1%',
  [`&:hover ${VolumeWrapper}`]: {
    transition: 'width 0.3s ease-in-out',
    width: '80px',
    opacity: 1,
  },
});

export const IconButton = styled.button({
  padding: '0.75rem 10px',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

export const PlayBackButton = styled(IconButton)<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
  borderBottom: isSelected ? '2px solid #fff' : '',
}));

export const SliderInput = styled.input<{
  changedValue: number;
  loaded?: number;
  seekMouse?: number;
}>(({ changedValue, loaded, seekMouse }) => ({
  '-webkit-appearance': 'none',
  appearance: 'none',
  width: '100%',
  background: `linear-gradient(
    90deg,
    ${WHITE_COLOR} ${changedValue}%,
    ${
      seekMouse && loaded && seekMouse <= loaded
        ? `${MOUSE_HOVER_COLOR_A} ${changedValue}% ${seekMouse}%,`
        : ''
    }
    ${loaded ? `${LOADED_COLOR} ${changedValue}% ${loaded}%,` : ''} ${
    seekMouse ? `${MOUSE_HOVER_COLOR_B} ${loaded}% ${seekMouse}%,` : ''
  }  ${UNFILLED_COLOR} ${loaded ? loaded : changedValue}% 100%
  )`,

  outline: 'none',

  borderRadius: '4.5px',
  height: '5px',
  opacity: 1,

  '::-webkit-slider-thumb': {
    '-webkit-appearance': 'none',
    appearance: 'none',

    border: 'solid 4px #fff',
    background: palette.white.toString(),
    width: '13px',
    height: '13px',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  '::-webkit-slider-runnable-track': {
    cursor: 'pointer',
  },
}));

export const SliderRoot = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const DurationWrapper = styled.div({
  padding: '1.3% 10px',
  backgroundColor: 'transparent',
});

export const DurationText = styled.span({
  fontWeight: '600',
  fontSize: '90%',
  color: palette.white.toString(),
  whiteSpace: 'nowrap',
});

export const PlayBackRateText = styled.span({
  fontWeight: '600',
  fontSize: '80%',
  color: palette.white.toString(),
  paddingRight: '10px',
  paddingLeft: '5px',
});

export const Flexbox = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const PlayBackMenu = styled.div({
  position: 'absolute',
  width: '131px',
  textAlign: 'left',
  zIndex: 300,
  flexDirection: 'column',
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'center',
  bottom: '61px',
  right: '44px',
  background: '#2C323C',
  borderRadius: '6px',
});

export const PlaySpeedText = styled.span({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '13px',
  padding: '5px 12px 9px 12px',
  lineHeight: '19px',
  color: palette.white.toString(),
});

export const PlayRateText = styled.span({
  fontFamily: "'Roboto'",
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '13px',
  lineHeight: '15px',
  color: palette.white.toString(),
  cursor: 'pointer',
  padding: '6px 12px',

  ':hover': {
    backgroundColor: '#181F2B',
  },
});

export const PlayBackRateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid rgba(255,255,255,0.3)',
  padding: '3px 0',
});

export const Loader = styled.div<{ hide?: boolean; side: 'left' | 'right' | 'center' }>(
  ({ hide, side }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: side === 'left' ? 12 : '',
    right: side === 'right' ? 12 : '',
    width: '100px',
    height: '100px',
    borderRadius: '50%',

    opacity: hide ? 0 : 1,
    transition: 'opacity 300ms ease-in-out',
  })
);

export const ScreenPlayPauseWrapper = styled.div({
  position: 'absolute',
  width: '100%',
  height: '85%',
  zIndex: 299,
});

export const SeekTimerText = styled.span<{ seekMouse: number }>(({ seekMouse }) => ({
  position: 'absolute',
  bottom: '58px',
  left: `${seekMouse < 10 ? 10 : seekMouse > 90 ? 90 : seekMouse}%`,
  transform: 'translateX(-50%)',
  fontFamily: "'Roboto'",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '19px',
  textAlign: 'center',
}));
