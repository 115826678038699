import React from 'react';
import styled from 'styled-components';
import {
  ContextMenuIconContainer,
  ContextMenuOption,
  ContextMenuTextContainer,
} from '../ContextMenuList';
import palette from '^/constants/palette';
import theme from '^/theme';

const ContextMenuItemRoot = styled.button(({ disabled }) => ({
  gap: '3px',
  display: 'flex',
  fontSize: '12px',
  cursor: 'pointer',
  paddingTop: '5px',
  paddingBottom: ' 5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  background: 'transparent',
  alignItems: 'center',
  opacity: disabled ? '0.5' : '1',
  pointerEvents: disabled ? 'none' : 'auto',
  color: theme.colors.white[100],
  '&:hover': {
    backgroundColor: palette.activeBlue.toString(),
  },
}));

ContextMenuItemRoot.displayName = 'ContextMenuItemRoot';

const DropdownDivider = styled.div({
  height: '1px',
  width: '100%',
  backgroundColor: theme.colors.white[10],
  margin: '8px 0px',
});

export const ContextMenuItem = (props: ContextMenuOption) => {
  const { text, preIcon, postIcon, onClick, name, shouldShowDividerAfter, disabled, hide } = props;

  if (hide) {
    return null;
  }

  return (
    <>
      <ContextMenuItemRoot
        data-testid={`context-menu-item-${name}`}
        name={name}
        onClick={onClick}
        disabled={disabled}
      >
        <ContextMenuIconContainer>{preIcon}</ContextMenuIconContainer>
        <ContextMenuTextContainer>{text}</ContextMenuTextContainer>
        <ContextMenuIconContainer>{postIcon}</ContextMenuIconContainer>
      </ContextMenuItemRoot>
      {shouldShowDividerAfter && <DropdownDivider />}
    </>
  );
};
