import * as T from '^/types';

export enum BASE_ROCK_TYPES {
  RIPPING = 'Ripping',
  BLASTING = 'Blasting',
  MEDITTEREAN = 'Meditterean',
}

export type RockType = BASE_ROCK_TYPES | string;
export type BasePoint = 'Initial' | 'Mid' | 'End';
export type RowType = 'cut' | 'fill';

export enum SyncStatus {
  IDLE = 'IDLE',
  SYNCING = 'SYNCING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export const BaseRocks: RockType[] = [
  BASE_ROCK_TYPES.BLASTING,
  BASE_ROCK_TYPES.RIPPING,
  BASE_ROCK_TYPES.MEDITTEREAN,
];

export interface FinalEarthWork {
  uploadedDXF?: File;
  dxfId?: string | number;
}

export interface InitialSurvey {
  uploadedDXF?: File;
  dxfId?: string | number;
  sourcePhotoId?: string;
}

export interface RockLayer {
  name?: RockType;
  uploadedDXF?: File;
  dxfId?: string | number;
}

export interface EarthWorkStore {
  selectedVolumeId?: T.VolumeContent['id'];
  setSelectedVolumeId(value?: T.VolumeContent['id']): void;

  selectedEarthworkId?: Earthwork['id'];
  setSelectedEarthworkId(value?: Earthwork['id']): void;

  createNewEarthwork: Boolean;
  setCreateNewEarthwork(value: Boolean): void;

  earthworkLists: Earthwork[];
  setEarthworkLists(value: Earthwork[]): void;

  earthworkFetchStatus: T.APIStatus;
  setEarthworkFetchStatus(value: T.APIStatus): void;

  earthworkAnalysis?: EarthworkAnalysis;
  setEarthworkAnalysis(value?: EarthworkAnalysis): void;

  earthworkAnalysisFetchStatus: T.APIStatus;
  setEarthworkAnalysisFetchStatus(value: T.APIStatus): void;

  earthworkBasics?: EarthworkBasics;
  setEarthworkBasics(value?: EarthworkBasics): void;

  finalEarthWork?: FinalEarthWork;
  setFinalEarthWork(value?: FinalEarthWork): void;

  selectedZones?: Array<string | number>;
  setSelectedZones(value?: Array<string | number>): void;

  initialSurvey?: InitialSurvey;
  setInitialSurvey(value?: InitialSurvey): void;

  rockLayers?: RockLayer[];
  setRockLayers(value?: RockLayer[]): void;

  syncStatus: SyncStatus;
  setSyncStatus(value?: SyncStatus): void;

  showBurnDown: boolean;
  setShowBurnDown(value: boolean): void;

  createEarthworkStatus: T.APIStatus;
  setCreateEarthworkStatus(value: T.APIStatus): void;

  deletingEarthworkId?: string | number;
  setDeletingEarthworkId(value?: string | number): void;

  isAddNowIsActive: boolean;
  setIsAddNowIsActive(value: boolean): void;

  earthworkAnalysisById: Record<EarthworkAnalysis['id'], GetEarthworkAnalysisResponse['data']>;
  setEarthworkAnalysisById(
    earthworkAnalysisById: Record<EarthworkAnalysis['id'], GetEarthworkAnalysisResponse['data']>
  ): void;
  reset(): void;
}

export interface Earthwork {
  id: T.BaseContent['id'];
  attributes: {
    name: string;
    description: string;
  };
}

export interface EarthworkBasics {
  name: string;
  description?: string;
}

export interface GetEarthWorklistsResponse {
  data: Earthwork[];
}

export interface GetEarthworkAnalysisResponse {
  data: {
    id: T.BaseContent['id'];
    attributes: EarthworkAnalysis;
  };
}

export interface CutFillItem {
  name: string;
  initial: number;
  remaining: number;
  totalChange: number;
  changes: number[];
}

export interface Zone {
  id: string | number;
  name: string;
  cut: CutFillItem[];
  fill: CutFillItem[];
}

export interface Moment {
  id: string | number;
  data: string;
}

export interface EarthworkAnalysis {
  id: T.BaseContent['id'];
  projectId: T.Project['id'];
  name: string;
  description: string;
  moments: Moment[];
  zones: Zone[];
}

export interface CutFillInput {
  name: string;
  designDxfId: string | number;
}

export interface ZoneInput {
  name: string;
  wkt: string;
  demType: T.ContentType.DSM | T.ContentType.DTM;
}
export interface SurfaceInput {
  goal: {
    designDxfId: string | number;
  };
  initial: {
    designDxfId: string | number;
  };
  cut: CutFillInput[];
  fill: {
    name: string;
  };
}

export interface CreateEarthworkRequestParam {
  projectId: T.Project['id'];
  name: string;
  description?: string;
  surfaces: SurfaceInput;
  zones: ZoneInput[];
}

export interface CreateEarthworkResponse {
  id: Earthwork['id'];
}
