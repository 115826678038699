import React, { useRef } from 'react';
import styled, { CSSObject } from 'styled-components';
import palette from '^/constants/palette';
import { MediaQuery } from '^/constants/styles';
import ProfileSvg from '^/assets/icons/profile.svg';
import Tippy from '@tippyjs/react';
import * as T from '^/types';

const NAME_MAX_WIDTH: string = '168px';

const Root = styled.div({
  width: '100%',
  display: 'flex',
  direction: 'ltr',
  alignItems: 'center',

  fontSize: '10px',
});

const AvatarWrapper = styled.div({
  flexShrink: 0,

  overflow: 'hidden',
  width: '37px',
  height: '37px',

  borderRadius: '50%',
  backgroundColor: palette.icon.toString(),

  [MediaQuery.MOBILE_L]: {
    marginLeft: 0,
  },
});

const UserAvatarSVGWrapper = styled.svg({
  width: '100%',
  height: '100%',
  fill: palette.background.toString(),
});

const NameCell = styled.div({
  marginLeft: '13px',
  width: '100%',
});

const NameTextWrapper = styled.div({
  display: 'flex',
  color: '#4D4C4C',
});

const truncateStyle: CSSObject = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const NameText = styled.div({
  fontWeight: 500,
  color: palette.textGray.toString(),
  paddingBottom: '2px',
  lineHeight: 'initial',
  paddingRight: '4px',
  flexShrink: 1,
  maxWidth: NAME_MAX_WIDTH,
  ...truncateStyle,
});

const EmailText = styled.div({
  display: 'block',

  maxWidth: NAME_MAX_WIDTH,
  width: 'fit-content',

  color: palette.textLight.toString(),
  lineHeight: 'initial',
  ...truncateStyle,
});

const labelTextStyle: CSSObject = {
  display: 'flex',
  alignItems: 'center',

  boxSizing: 'border-box',
  padding: '1px 3px',

  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '3px',

  fontSize: '10px',
  lineHeight: 'initial',
  fontWeight: 500,
};

const PendingText = styled.span({
  ...labelTextStyle,
  borderColor: palette.subColor.toString(),
  color: palette.subColor.toString(),
});
PendingText.displayName = 'PendingText';

const DeniedText = styled.span({
  ...labelTextStyle,
  borderColor: palette.error.toString(),
  color: palette.error.toString(),
});
DeniedText.displayName = 'DeniedText';

const Tooltip = styled(Tippy)({
  wordWrap: 'break-word',
});

const ImageWrapper = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

interface NameContentProps {
  id: number;
  name: string;
  email: string;
  avatar?: string;
}

function NameContent({ name, email, avatar }: NameContentProps) {
  const nameDivRef = useRef<HTMLDivElement>(null);
  const rawNameDiv = <NameText ref={nameDivRef}>{name}</NameText>;
  const nameDiv =
    nameDivRef.current && nameDivRef.current.offsetWidth < nameDivRef.current.scrollWidth ? (
      <Tooltip
        theme="angelsw"
        offset={T.TIPPY_OFFSET}
        arrow={false}
        content={name}
        placement="bottom"
      >
        {rawNameDiv}
      </Tooltip>
    ) : (
      rawNameDiv
    );

  const emailDivRef = useRef<HTMLDivElement>(null);
  const rawEmailDiv = <EmailText ref={emailDivRef}>{email}</EmailText>;
  const emailDiv =
    emailDivRef.current && emailDivRef.current.offsetWidth < emailDivRef.current.scrollWidth ? (
      <Tooltip
        theme="angelsw"
        offset={T.TIPPY_OFFSET}
        arrow={false}
        content={email}
        placement="bottom"
      >
        {rawEmailDiv}
      </Tooltip>
    ) : (
      rawEmailDiv
    );

  const avatarArea = avatar ? (
    <ImageWrapper data-testid="profile-img" src={avatar} />
  ) : (
    <UserAvatarSVGWrapper>
      <ProfileSvg />
    </UserAvatarSVGWrapper>
  );

  return (
    <Root>
      <AvatarWrapper>{avatarArea}</AvatarWrapper>
      <NameCell>
        <NameTextWrapper>{nameDiv}</NameTextWrapper>
        {emailDiv}
      </NameCell>
    </Root>
  );
}

export default NameContent;
