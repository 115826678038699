import { isMobile } from '^/utilities/device';

export enum OlCustomPropertyNames {
  IS_MAKRER_READY_TO_TRANSLATE = '__IS_MAKRER_READY_TO_TRANSLATE',
  TITLE = '__title',
  MAIN_FEATURE = '__main',
  PHOTO_FEATURE = '__photo',
  VIDEO_FEATURE = '__video',
  BEAMO_FLOOR_PLAN_FEATURE = '__beamo-floor-plan',
  COLOR = '__color',
  GEOMETRY_TYPE = '__geometry-type',
  VECTOR_ONCHANGE = '__vector-onchange-',
  WHITE_PLAIN = '__white-plain-',
  PLUS = '__plus-',
  LAST_COORDINATE_CHECK_ICON = '__last-coordinate-check-icon',
  FIRST_COORDINATE_CHECK_ICON = '__first-coordinate-check-icon',
  LENGTH_SEGMENT = '__length-segment-',
  ELEVATION = '__elevation',
  IS_DESIGN_DXF = '__is_design_dxf',
  PRINT_POINT = '__print-point-',
  PRINT_ROTATE_POINT = '__print-rotate',
  PRINT_RECT = '__print-rect',
  SHOULD_KEEP_OL_MEASUREMENT_BOX_ON = '__should_keep_ol_measurement_box_on',

  OL_LENGTH_SEGMENT_SLOPE = '__ol-length-segment-slope-',
  OL_LENGTH_SEGMENT_MEASUREMENT = '__ol-length-segment-measurement-',
  OL_REALTIME_MEASUREMENT_TOOLTIP_CLASSNAME = '__ol-realtime-measurement-tooltip',
  OL_REALTIME_MEASUREMENT_TOOLTIP_LENGTH_CLASSNAME = '__ol-realtime-measurement-tooltip-length',
  OL_LOADING_TOOLTIP = '__ol-loading-tooltip',
  OL_LOADING_TOOLTIP_SMALL = '__ol-loading-tooltip-small',
  OL_HIDE_MEASUREMENT_BOX = '__ol-hide-measurement-box',
  OL_ROOT_DIV = '__ol-root-div',
  TO_BE_DELETED_POINT = '__to-be-deleted-point',

  GCP_LABEL = '__gcp-label',
  GCP_ID = '__gcp-id-',

  EXIT_EDIT = '__exit_edit',
  ENTER_EDIT = '__enter_edit',
}

export enum ListenerNames {
  POINTERMOVE = 'pointermove-listener',
  CLICK = 'click-listener',
  DRAW = 'draw-listener',
  LAYER_CHANGE = 'layerchange-listener',
  GEOMETRY_CHANGE = 'geometrychange-listener',
  GEOMETRY_CHANGE_POINTERMOVE = 'geometrychange-pointermove-listener',
  MODIFY_START = 'modifystart-listener',
  MODIFY_END = 'modifyend-listener',
  DRAW_START = 'drawstart-listener',
  DRAW_END = 'drawend-listener',
  TRANSLATE_START = 'translatestart-listener',
  TRANSLATING = 'translating-listener',
  TRANSLATE_END = 'translateend-listener',
  KEYDOWN = 'keydown-listener',
}

export enum InteractionEventTypes {
  CHANGE = 'change',
  CLICK = 'click',
  POINTER_MOVE = 'pointermove',
  DRAW_END = 'drawend',
  DRAW_START = 'drawstart',
  MODIFY_START = 'modifystart',
  MODIFY_END = 'modifyend',
  TRANSLATE_START = 'translatestart',
  TRANSLATING = 'translating',
  TRANSLATE_END = 'translateend',
  KEYDOWN = 'keydown',
  UPDATE_EDITING_LAYER = 'update_editing_layer',
}

export enum OlMinCoordinatesLength {
  LENGTH = 3,
  AREA = 4,
}

export const INTERVAL: number = 100;
export const MAX_THRESHOLD_TO_ASSUME_SAME_COORDINATE: number = 2e-9;
export const PIXEL_DISTANCE_THRESHOLD = 0.0001; // Adjust as needed
export const INVALID: string = 'INVALID';
export const SINGLE_LENGTH_SEGMENT_OVERLAY_OFFSET: [number, number] = [0, -40];
export const DEFAULT_ZOOM_LEVEL_FOR_CLUSTER: number = 20;
export const ZOOM_DURATION: number = 1000;
export const CLUSTER_DISTANCE: number = 15;
export const RIGHT_CLICK_THRESHOLD_MS = 200;

export const hitTolerance3px = {
  hitTolerance: isMobile() ? 10 : 3,
};
export const hitTolerance10px = {
  hitTolerance: isMobile() ? 20 : 10,
};

export const SNAP_PIXEL_TOLERANCE = 20;

export const OlMeasurementMarkerLayerId = 'OlMeasurementMarkerLayer';

export enum GeometryType {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  LINEAR_RING = 'LinearRing',
  POLYGON = 'Polygon',
  MULTI_POINT = 'MultiPoint',
  MULTI_LINE_STRING = 'MultiLineString',
  MULTI_POLYGON = 'MultiPolygon',
  GEOMETRY_COLLECTION = 'GeometryCollection',
  CIRCLE = 'Circle',
}

export enum IconAnchorUnits {
  FRACTION = 'fraction',
  PIXELS = 'pixels',
}

export enum OverlayPositioning {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right',
  CENTER_LEFT = 'center-left',
  CENTER_CENTER = 'center-center',
  CENTER_RIGHT = 'center-right',
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
}
