import SkeletonElement from '^/components/atoms/SkeletonElement';
import React from 'react';
import styled from 'styled-components';
import { PhotoInspectionListItemWrapper } from '../PhotoList/style';

interface SkeletonLoaderImagesProps {
  width?: string;
  height?: string;
  count: number;
}

const SkeletonLoaderImagesWrapper = styled.div({
  marginBottom: '10px',
  width: '100%',
  height: '100%',
});

const SkeletonLoaderImages: React.FC<SkeletonLoaderImagesProps> = ({
  height = '100%',
  width = '100%',
  count,
}) => (
  <>
    {[...Array(count)].map((_, index) => (
      <PhotoInspectionListItemWrapper key={`skeleton_${index}`} isSelected={false}>
        <SkeletonLoaderImagesWrapper>
          <SkeletonElement key={index} width={width} height={height} />
        </SkeletonLoaderImagesWrapper>
      </PhotoInspectionListItemWrapper>
    ))}
  </>
);

export default SkeletonLoaderImages;
