import { Language } from '^/types';

export default {
  exportCSV: {
    [Language.EN_US]: 'Export CSV',
    [Language.KO_KR]: 'CSV 내보내기',
  },
  tableHeaders: {
    volume: {
      [Language.KO_KR]: '면적',
      [Language.EN_US]: 'Volume',
    },
    cutFill: {
      [Language.KO_KR]: '구분',
      [Language.EN_US]: 'Cut/Fill',
    },
    soilType: {
      [Language.KO_KR]: '성사 구분',
      [Language.EN_US]: 'Soil Type',
    },
    total: {
      [Language.KO_KR]: '총량',
      [Language.EN_US]: 'Total',
    },
    expected: {
      [Language.KO_KR]: '계획 수량',
      [Language.EN_US]: 'Expected',
    },
    remaining: {
      [Language.KO_KR]: '잔여토공량',
      [Language.EN_US]: 'Remaining',
    },
    survey: {
      [Language.KO_KR]: '이전 날짜 대비 반출량',
      [Language.EN_US]: 'Cut/Fill Compare Survey',
    },
    surveyTotal: {
      [Language.KO_KR]: '일자별 합계',
      [Language.EN_US]: 'Survey Total',
    },
  },
  cut: {
    [Language.KO_KR]: '절토',
    [Language.EN_US]: 'Cut',
  },
  fill: {
    [Language.KO_KR]: '성토',
    [Language.EN_US]: 'Fill',
  },
  showBurndown: {
    [Language.KO_KR]: '번다운차트 표시',
    [Language.EN_US]: 'Show Burndown Chart',
  },
  soil: {
    [Language.KO_KR]: '토사',
    [Language.EN_US]: 'Soil',
  },
  rock: {
    [Language.KO_KR]: '풍화암',
    [Language.EN_US]: 'Rock',
  },
  volumeBurndownChart: {
    [Language.KO_KR]: '번다운 차트',
    [Language.EN_US]: 'Volume Burndown Chart',
  },
  uploadLabel: {
    [Language.EN_US]: 'Earthwork File',
    [Language.KO_KR]: '토공 파일',
  },
  refresh: {
    [Language.KO_KR]: '다시 로드',
    [Language.EN_US]: 'Refresh',
  },
  fileLabel: {
    [Language.EN_US]: 'Select / Upload an earthwork file',
    [Language.KO_KR]: '토공 파일 선택/업로드',
  },
  fileText: {
    [Language.EN_US]: 'Upload File',
    [Language.KO_KR]: '파일 업로드',
  },
  noPreviousFile: {
    [Language.KO_KR]: '다운로드 파일이 없습니다.',
    [Language.EN_US]: 'No previous file.',
  },
  completeCalculations: {
    [Language.EN_US]: 'All calculations have been completed.',
    [Language.KO_KR]: '모든 계산이 완료되었습니다.',
  },
  noAnalysisData: {
    [Language.EN_US]: 'No analysis data. Please create an earthwork to show the analysis table.',
    [Language.KO_KR]: '분석 데이터가 없습니다. 분석 표시를 위해서는 토공을 생성하세요.',
  },
};
