import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import { defaultMap } from '^/constants/defaultContent';
import { attachmentsStore, useAttachmentsStore } from '^/store/attachmentsStore';
import {
  AuthHeader,
  jsonContentHeader,
  makeAuthHeader,
  makeV2APIURL,
  makeVersionHeader,
} from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import {
  APIToContent,
  PostContent,
  PostContentArguments,
  UploadContentBody,
} from '^/store/duck/Contents';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { calculateHash } from '^/utilities/file-util';
import { runPromisesInBatches } from '^/utilities/promise';
import Color from 'color';
import { useAttachment } from './useAttachment';
import { useScreen } from './useScreen';
import { contentsStore } from '^/store/zustand/content/contentStore';

type WithScreen<U> = U & {
  readonly screen: T.Screen;
};
interface UploadSourcePhotoProps {
  readonly files: File[];
  readonly noOfStream?: number;
  readonly gcpGroupInfo?: T.GCPGroupContent['info'];
  readonly fromAlbum?: boolean;
}
interface FileObj {
  file: File;
  hash: string;
  bucketFileName: string;
}
interface UploadCompleteResponse {
  readonly data: T.APIContent[];
}

export function useUploadSourcePhotos() {
  const dispatch = useDispatch();
  const { createScreen } = useScreen();
  const { createAttachment } = useAttachment();
  const { setIsUploading, resetAttachmentUploadStatus } = useAttachmentsStore(s => ({
    setIsUploading: s.setIsUploading,
    resetAttachmentUploadStatus: s.resetAttachmentUploadStatus,
  }));

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const { selectedSourceAlbum } = useSelector((s: T.State) => s.Photos);

  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();

  const headers = {
    ...authHeader,
    ...versionHeader,
    ...jsonContentHeader,
  };

  async function createContent(screen: T.Screen) {
    if (projectId === undefined || authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    const newScreen = await createScreen({
      screen,
      attachmentType: T.AttachmentType.SOURCE,
    });

    const url = makeV2APIURL('projects', projectId, 'contents');

    const defaultMapContent: Pick<T.MapContent, PostContentArguments> = defaultMap();

    const body: UploadContentBody = {
      ...defaultMapContent,
      color: defaultMapContent.color.toString(),
      info: JSON.stringify(defaultMapContent.info),
    };

    const response = await http.post(
      url,
      { screenId: newScreen?.id ?? screen.id, ...body },
      { headers }
    );

    const content = APIToContent(response.data.data);

    return content;
  }

  function createGCPGroupInfo(content: T.Content, gcpGroupInfo?: T.GCPGroupContent['info']) {
    if (gcpGroupInfo !== undefined) {
      dispatch(
        PostContent({
          projectId: projectId!,
          content: {
            type: T.ContentType.GCP_GROUP,
            color: Color('#fff').toString(),
            title: '',
            info: gcpGroupInfo,
            screenId: content.screenId !== undefined ? content.screenId : null,
          },
        })
      );
    }
  }

  async function triggerUploadComplete(contentId: T.Content['id'], fromAlbum?: boolean) {
    const completeUrl = new URL(
      makeV2APIURL('contents', contentId, 'attachments', 'upload_complete')
    );
    completeUrl.searchParams.set('is_spa', 'true');

    if (fromAlbum && selectedSourceAlbum) {
      completeUrl.searchParams.set('from_content_id', selectedSourceAlbum?.toString());
    }

    const response = await http.post<UploadCompleteResponse>(completeUrl.href, {}, { headers });

    const contents: T.Content[] = response.data.data.map(content => APIToContent(content));
    contents.forEach(content => {
      contentsStore.getState().addContent(content);
    });
    contentsStore.getState().finishUploadContent(contentId);

    return;
  }

  async function uploadSourcePhotos({
    files,
    gcpGroupInfo,
    noOfStream,
    screen,
    fromAlbum,
  }: WithScreen<UploadSourcePhotoProps>) {
    try {
      const content = await createContent(screen);
      if (content === undefined) {
        return;
      }

      setIsUploading(true);

      const padMaxLength: number = 4;
      const padPrefix: string = '0';
      const fileObjs: FileObj[] = files.map((f, index: number) => ({
        file: f,
        hash: calculateHash(f),
        bucketFileName: `${(index + 1).toString().padStart(padMaxLength, padPrefix)}_${f.name}`,
      }));
      /**
       * @desc To keep files in the head of queue to be uploaded first,
       * we should spread files evenly into group instead of using splice method.
       */
      const groupNumber: number = noOfStream === undefined ? 1 : noOfStream;
      const fileGroups: FileObj[][] = _.zip(..._.chunk(fileObjs, groupNumber)).map(fileGroup =>
        fileGroup.filter((fileObj): fileObj is FileObj => fileObj !== undefined)
      );

      contentsStore.getState().addContent(content);
      contentsStore.getState().changeUploadContent({
        id: content.id,
        type: T.AttachmentType.SOURCE,
        file: _.map(fileObjs, ({ file, hash }) => ({ size: file.size, hash })),
        uploadedAt: new Date(),
        status: T.APIStatus.PROGRESS,
      });

      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.PROGRESS_BAR,
          contentId: content.id,
        })
      );

      const uploadPromises = fileGroups.flatMap(filesInGroup =>
        filesInGroup.map(fileObj => async () => {
          if (!attachmentsStore.getState().isUploading) {
            return;
          }
          await createAttachment({
            contentId: content.id,
            file: fileObj.file,
            attachmentType: T.AttachmentType.SOURCE,
            bucketFileName: fileObj.bucketFileName,
          });
        })
      );

      const results = await runPromisesInBatches(uploadPromises, 10);

      const isSuccess = results.every(result => result.status === 'fulfilled');

      if (!attachmentsStore.getState().isUploading) {
        return;
      }

      if (
        !isSuccess ||
        Object.keys(attachmentsStore.getState().attachmentUploadStatus).length !== files.length
      ) {
        throw new Error();
      }

      createGCPGroupInfo(content, gcpGroupInfo);

      await triggerUploadComplete(content.id, fromAlbum);

      resetAttachmentUploadStatus();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsUploading(false);
      resetAttachmentUploadStatus();
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.SOURCE_ERROR,
        })
      );
    }
  }

  return { uploadSourcePhotos };
}
