import Tippy from '@tippyjs/react';
import theme from '^/theme';
import { TIPPY_OFFSET } from '^/types';
import React, { PropsWithChildren } from 'react';
import styled, { CSSObject } from 'styled-components';
import { Placement } from 'tippy.js';

export const IconContainer = styled.section<{
  isSelected?: boolean;
  isDisabled?: boolean;
  customStyles?: CSSObject;
  showBeta?: boolean;
}>(({ isSelected, isDisabled, customStyles, showBeta }) => ({
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '44px',
  margin: '8px',
  borderRadius: '5px',
  cursor: isDisabled ? 'default' : 'pointer',
  transition: '0.15s all',
  backgroundColor: isSelected ? theme.colors.neutralBlue[500] : undefined,

  '> svg path': isSelected
    ? { fill: theme.colors.white[100] }
    : isDisabled
    ? { fill: theme.colorTokens.disabledGray }
    : undefined,

  '> svg circle': isSelected
    ? { fill: theme.colors.white[100] }
    : isDisabled
    ? { fill: theme.colorTokens.disabledGray }
    : undefined,

  '&:hover':
    !isDisabled && !isSelected ? { backgroundColor: theme.colors.coolGray[75] } : undefined,
  '&:active':
    !isDisabled && !isSelected
      ? {
          backgroundColor: theme.colors.neutralBlue[100],
          '> svg path': {
            fill: theme.colors.coolGray[600],
          },
        }
      : undefined,

  ...(showBeta && {
    '::before': {
      content: '"BETA"',
      position: 'absolute',
      top: '17px',
      left: '-9px',
      backgroundColor: 'black',
      color: 'white',
      fontSize: '8px',
      padding: '2px 9px',
      transform: 'rotate(-45deg)',
      transformOrigin: 'left top',
      whiteSpace: 'nowrap',
    },
  }),
  ...customStyles,
}));

interface Props {
  popoverContent: string;
  onClick(): void;
  dataTestId?: string;
  popoverPlacement?: Placement;
  isSelected?: boolean;
  isDisabled?: boolean;
  trackAction?: string;
  trackLabel?: string;
  customStyles?: CSSObject;
  showBeta?: boolean;
}
export function IconButtonWithPopover({
  children,
  popoverContent,
  onClick,
  dataTestId,
  isDisabled,
  isSelected,
  popoverPlacement = 'right',
  trackAction,
  trackLabel,
  customStyles,
  showBeta,
}: PropsWithChildren<Props>) {
  return (
    <Tippy
      offset={TIPPY_OFFSET}
      theme="angelsw"
      placement={popoverPlacement}
      arrow={false}
      content={popoverContent}
    >
      <IconContainer
        data-testid={dataTestId}
        isDisabled={isDisabled}
        isSelected={isSelected}
        onClick={onClick}
        data-ddm-track-action={trackAction}
        data-ddm-track-label={trackLabel}
        customStyles={customStyles}
        showBeta={showBeta}
      >
        {children}
      </IconContainer>
    </Tippy>
  );
}
