import React, { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIcon from '^/components/atoms/LoadingIcon';
import { usePhotoAlbumIdQuery } from '^/hooks/api/photos/usePhoto';
import { LoaderWrapper } from '../PhotoList/style';
import { PhotoListContent } from '../PhotoList/PhotoListContent';
import { PhotoAlbumTitleEditable } from './PhotoAlbumTitleEditable';

const PhotoTypeList: FC = () => {
  const params = useParams();
  const { albumId } = params;

  // Use the new photo album query
  const { photosFromAlbums, albumInfo, isError, isLoading } = usePhotoAlbumIdQuery(Number(albumId));

  // Show error state if query fails
  if (isError) {
    return <div>Error loading photo album</div>;
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <LoaderWrapper>
          <LoadingIcon loadingDivCustomStyle={{ height: 32, width: 32 }} />
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <PhotoAlbumTitleEditable photoAlbum={albumInfo} />
          <PhotoListContent groupedAlbums={photosFromAlbums} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default memo(PhotoTypeList);
