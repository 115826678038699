import { makeV2APIURL } from '^/store/duck/API';

enum PERMISSION {
  PERMISSION = 'PERMISSION',
}

export const PermissionKeys = {
  getPermission: (projectId?: number): [PERMISSION.PERMISSION, { projectId?: number }] => [
    PERMISSION.PERMISSION,
    { projectId },
  ],
};

export const PermissionURLs = {
  getPermissionURL: (projectId: number) => makeV2APIURL('projects', projectId, 'permissions'),
  postPermissionURL: (projectId: number) => makeV2APIURL('projects', projectId, 'permissions'),
  patchPermissionURL: (id: number) => makeV2APIURL('permissions', id),
  deletePermissionURL: (id: number) => makeV2APIURL('permissions', id),
};
