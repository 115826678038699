/* eslint-disable max-lines */
import React, { FC, ReactNode, memo, useEffect, useRef, useState } from 'react';
import isDeepEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import BoldArrowSVG from '^/assets/icons/annotation/arrow.svg';
import ArrowSVG from '^/assets/icons/content-sidebar-header/arrow.svg';
import CalendarSVG from '^/assets/icons/content-sidebar-header/calendar.svg';
import { ScreenPicker } from '^/components/organisms/ScreenPicker';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { MediaQuery, responsiveStyle } from '^/constants/styles';
import {
  UseL10n,
  UseLastSelectedScreen,
  UseState,
  typeGuardMap,
  typeGuardPointCloud,
  typeGuardThreeDMesh,
  typeGuardThreeDOrtho,
  useL10n,
  useLastSelectedScreen,
  usePrevProps,
} from '^/hooks';
import { contentsSelector, PatchContent, SetOutdatedVolumes } from '^/store/duck/Contents';
import {
  ChangeCurrentContentTypeFromAnnotationPicker,
  ChangeEditingContent,
  ChangeIn3D,
  ChangeIn3DPointCloud,
  SetPreventAutoSelect,
} from '^/store/duck/Pages/Content';
import { PatchProjectConfig } from '^/store/duck/ProjectConfig';
import * as T from '^/types';
import { isPinnable, isContentPinned } from '^/utilities/content-util';
import { ApplyOptionIfKorean, GetCommonFormat, formatWithOffset } from '^/utilities/date-format';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { useESSContentsStore } from '^/store/essContentsStore';
import { defaultFeatures } from '^/constants/defaultContent';
import { usePresentationStore } from '^/store/presentationStore';
import { getSingleContentId } from '^/utilities/state-util';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useNavigate } from 'react-router-dom';
import route from '^/constants/routes';

export interface Props {
  // readonly projectTitle: string;
  // onLogoClick(): void;
}

export const Root = styled.div<{ isVisible: boolean; isMarginTopRequired?: boolean }>(
  ({ isVisible, isMarginTopRequired }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    width: '100%',
    height: isVisible ? '' : responsiveStyle.topBar[T.Device.DESKTOP]?.height,
    borderBottom: `1px solid ${palette.MapTopBar.divider.toString()}`,
    backgroundColor: palette.white.string(),
    color: dsPalette.title.toString(),

    marginTop: isMarginTopRequired ? '50px' : '0px',
  })
);
Root.displayName = 'ContentsSidebarHeaderRoot';

const DISABLED_ARROW_ALPHA: number = 0.2;

export const ArrowWrapper = styled.div<{ isRight?: boolean; isDisabled: boolean }>(
  ({ isDisabled, isRight }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    marginLeft: '14px',
    marginRight: '17px',
    opacity: isDisabled ? DISABLED_ARROW_ALPHA : undefined,
    ':hover': isDisabled
      ? undefined
      : {
          cursor: 'pointer',
          borderRadius: '4px',
          backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),
        },
    '> svg': isRight
      ? {
          transform: 'rotate(180deg)',
        }
      : undefined,
  })
);

export const DateTitleWrapper = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '170px',
  height: '32px',
  cursor: 'pointer',
  '> span': {
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '3.5px',
    fontSize: '15px',
    [MediaQuery[T.Device.MOBILE_L]]: {
      fontSize: '15px',
    },
    [MediaQuery[T.Device.MOBILE_S]]: {
      fontSize: '14px',
    },
    whiteSpace: 'nowrap',
    fontWeight: 700,
    letterSpacing: '-0.3x/8px',
  },
  '> svg': {
    '> g': {
      fill: isVisible ? 'var(--color-theme-primary)' : '',
    },
    position: 'absolute',
    right: 0,
    paddingLeft: '3.5px',
    paddingRight: '10px',
  },
  ':hover': {
    borderRadius: '3px',
    backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),
  },
}));

export const CalendarTextSection = styled.div({
  display: 'flex',
  height: '50px',

  alignItems: 'center',
  justifyContent: 'space-between',
});
CalendarTextSection.displayName = 'CalendarTextSection';

const CalendarSection = styled.div<{ isVisible: boolean }>(
  {
    display: 'none',
  },
  ({ isVisible }) =>
    isVisible
      ? {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '450px',
        }
      : {}
);
CalendarSection.displayName = 'CalendarSection';

const CalendarWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});
CalendarWrapper.displayName = 'CalendarWrapper';

const HideCalendarButton = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '19px',
  margin: '19px 0px',
  borderRadius: '4px',
  backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),
  ':hover': {
    cursor: 'pointer',
    backgroundColor: palette.ContentsList.hoverGray.toString(),
  },
  '> svg': {
    transform: 'scale(2)',
  },
});

const ContentsSidebarHeader: FC<Props> = () => {
  const { editingESSContentId, setEditingESSContentId } = useESSContentsStore(s => ({
    editingESSContentId: s.editingESSContentId,
    setEditingESSContentId: s.setEditingESSContentId,
  }));
  const { byId, allIds } = useContentsStore(s => s.contents);

  const dispatch: Dispatch = useDispatch();
  const outdatedVolumeIds = useSelector((s: T.State) => s.Contents.outdatedVolumeIds);
  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);

  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const features = projectById[projectId ?? NaN]?.features || defaultFeatures;

  const isIn3DPointCloud = useSelector((s: T.State) => s.Pages.Contents.in3DPointCloud);
  const in3D = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const editingMainContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);

  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const Pages = useSelector((s: T.State) => s.Pages);
  const Screens = useSelector((s: T.State) => s.Screens);

  const threeDMeshId: T.ThreeDMeshContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.THREE_D_MESH
  );
  const threeOrthoId: T.ThreeDOrthoContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.THREE_D_ORTHO
  );
  const is3DMeshSelected = contentsSelector.isSelected(ProjectConfigPerUser)(threeDMeshId);
  const is3DOrthoSelected = contentsSelector.isSelected(ProjectConfigPerUser)(threeOrthoId);

  const isESS = sidebarTab === T.ContentPageTabType.ESS;
  const editingContentId = isESS ? editingESSContentId : editingMainContentId;
  const isPreventAutoSelect = Pages.Contents.isPreventAutoSelect;
  const screenIds: Array<T.Screen['id']> = Screens.screens.map(({ id }) => id);

  const [, lang]: UseL10n = useL10n();

  const lastSelectedScreen: UseLastSelectedScreen = useLastSelectedScreen();
  const prevLastSelectedScreenDate: T.Screen['appearAt'] | undefined = usePrevProps(
    lastSelectedScreen?.appearAt
  );

  const [isCalendarVisible, setIsCalendarVisible]: UseState<boolean> = useState(false);
  const calendarParentRef = useRef<HTMLDivElement | null>(null);

  const isInPresentationMode = usePresentationStore(s => s.isInPresentationMode);
  const navigate = useNavigate();

  const [currentScreenIdx, setCurrentScreenIdx]: UseState<number> = useState(
    screenIds.findIndex(id => id === lastSelectedScreen?.id)
  );

  const pinnedVolumeIdsOnCurrentDate: Array<T.VolumeContent['id']> = (() =>
    allIds
      .map(id => byId[id])
      .filter(content => content.type === T.ContentType.VOLUME && content.appearAt === undefined)
      .map(v => v.id))();

  useEffect(() => {
    if (isProjectEnterOrLeave()) {
      return;
    }
    setCurrentScreenIdx(screenIds.findIndex(id => id === lastSelectedScreen?.id));
    if (isPinnedContentsOpened()) {
      closePinnedContents();
      turnOffVolumeVisualization();
    }
    if (shouldOutdatedVolumesBeSet()) {
      dispatch(SetOutdatedVolumes({ outdatedVolumeIds: pinnedVolumeIdsOnCurrentDate }));
    }
  }, [lastSelectedScreen?.id]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const isInsideCalendarParent =
        calendarParentRef.current && calendarParentRef.current.contains(event.target as Node);

      if (!isInsideCalendarParent) {
        setIsCalendarVisible(false);
      }
    };

    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  if (projectId === undefined || !lastSelectedScreen) {
    return null;
  }

  function shouldOutdatedVolumesBeSet(): boolean {
    return !isDeepEqual(pinnedVolumeIdsOnCurrentDate, outdatedVolumeIds);
  }

  function isProjectEnterOrLeave(): boolean {
    const isProjectEnter: boolean =
      prevLastSelectedScreenDate === undefined && lastSelectedScreen?.appearAt !== undefined;
    const isProjectLeave: boolean = lastSelectedScreen?.appearAt === undefined;

    return isProjectEnter || isProjectLeave;
  }

  function isPinnedContentsOpened(): boolean {
    return (
      editingContentId !== undefined &&
      isContentPinned(byId[editingContentId]) &&
      isPinnable(byId[editingContentId]?.category)
    );
  }

  function closePinnedContents(): void {
    dispatch(ChangeEditingContent({}));
    setEditingESSContentId(undefined);
  }

  function turnOffVolumeVisualization(): void {
    for (const volume of pinnedVolumeIdsOnCurrentDate.map(id => byId[id]) as T.VolumeContent[]) {
      if (!volume.config?.dsm?.isOn) {
        return;
      }
      dispatch(
        PatchContent({
          content: {
            id: volume.id,
            config: {
              dsm: {
                ...volume.config.dsm,
                isOn: false,
              },
            },
          },
        })
      );
    }
  }

  const handleCalendarClick: () => void = () => setIsCalendarVisible(!isCalendarVisible);

  const setLastSelectedScreen: (screen: T.Screen) => void = screen => {
    const getViewableThreeDContent = (
      contentIds: Array<T.ThreeDMeshContent['id'] | T.ThreeDOrthoContent['id']>
    ) => {
      let threeDMeshContent = undefined;
      let threeDOrthoContent = undefined;
      let twoDOrthoContent = undefined;
      let threeDPointCloudContent = undefined;

      for (const contentId of contentIds) {
        threeDMeshContent ||= typeGuardThreeDMesh(byId[contentId]);
        threeDOrthoContent ||= typeGuardThreeDOrtho(byId[contentId]);
        twoDOrthoContent ||= typeGuardMap(byId[contentId]);
        threeDPointCloudContent ||= typeGuardPointCloud(byId[contentId]);
      }

      return {
        threeDContent: is3DMeshSelected
          ? threeDMeshContent ?? threeDOrthoContent
          : threeDOrthoContent ?? threeDMeshContent,
        twoDContent: twoDOrthoContent,
        threeDPointCloudContent: threeDPointCloudContent,
      };
    };

    dispatch(
      PatchProjectConfig({
        projectId,
        config: {
          lastSelectedScreenId: screen.id,
        },
      })
    );

    const viewableThreeDContent = getViewableThreeDContent(screen.contentIds);
    if (
      (sidebarTab === T.ContentPageTabType.ESS || is3DMeshSelected || is3DOrthoSelected) &&
      viewableThreeDContent.threeDContent
    ) {
      dispatch(
        PatchContent({
          content: {
            id: viewableThreeDContent.threeDContent.id,
            config: {
              selectedAt: new Date(),
            },
          },
        })
      );
    } else if (isIn3DPointCloud && viewableThreeDContent.threeDPointCloudContent) {
      dispatch(
        PatchContent({
          content: {
            id: viewableThreeDContent.threeDPointCloudContent.id,
            config: {
              selectedAt: new Date(),
            },
          },
        })
      );
    } else if (viewableThreeDContent.twoDContent) {
      if (isIn3DPointCloud) {
        dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
      }
      if (in3D) {
        dispatch(ChangeIn3D({ in3D: false }));
      }
      dispatch(
        PatchContent({
          content: {
            id: viewableThreeDContent.twoDContent.id,
            config: {
              selectedAt: new Date(),
            },
          },
        })
      );
    }
  };

  const handleCurrentScreenChange: (screen: T.Screen) => void = screen => {
    navigate(
      route.content.createMain(String(projectId), sidebarTab, String(screen.id), null, null)
    );
    setLastSelectedScreen(screen);
  };

  const commonFuncOnDateChange: () => void = () => {
    if (isPreventAutoSelect) {
      dispatch(SetPreventAutoSelect({ value: false }));
    }
    dispatch(ChangeCurrentContentTypeFromAnnotationPicker({}));
  };

  const handleNextScreenClick: () => void = () => {
    commonFuncOnDateChange();
    const nextScreen = Screens.screens[currentScreenIdx - 1];
    if (!nextScreen?.id) {
      return;
    }

    setCurrentScreenIdx(prev => Number(prev) + 1);
    setLastSelectedScreen(nextScreen);
  };

  const handlePrevScreenClick: () => void = () => {
    commonFuncOnDateChange();
    const prevScreen = Screens.screens[currentScreenIdx + 1];
    if (!prevScreen?.id) {
      return;
    }

    setCurrentScreenIdx(prev => prev - 1);
    setLastSelectedScreen(prevScreen);
  };

  const YYYYMMDD: string = formatWithOffset(
    0,
    lastSelectedScreen.appearAt,
    GetCommonFormat({ lang, hasDay: true }),
    ApplyOptionIfKorean(lang)
  );

  const isLeftDisabled: boolean = screenIds[currentScreenIdx + 1] === undefined;
  const isRightDisabled: boolean = screenIds[currentScreenIdx - 1] === undefined;

  const [leftArrow, rightArrow]: ReactNode[] = Array(2)
    .fill(undefined)
    .map((_, index) => {
      const isRight: boolean = index === 1;

      return (
        <ArrowWrapper
          key={index}
          className={CANCELLABLE_CLASS_NAME}
          isDisabled={isRight ? isRightDisabled : isLeftDisabled}
          isRight={isRight}
          onClick={isRight ? handleNextScreenClick : handlePrevScreenClick}
        >
          <ArrowSVG />
        </ArrowWrapper>
      );
    });

  return isInPresentationMode ? null : (
    <Root
      isVisible={isCalendarVisible}
      isMarginTopRequired={features.droneStation}
      ref={calendarParentRef}
    >
      <CalendarTextSection>
        {leftArrow}
        <DateTitleWrapper isVisible={isCalendarVisible} onClick={handleCalendarClick}>
          <span>{YYYYMMDD}</span>
          <CalendarSVG />
        </DateTitleWrapper>
        {rightArrow}
      </CalendarTextSection>
      <CalendarSection isVisible={isCalendarVisible}>
        <ScreenPicker
          size={T.CalendarScreenSize.L}
          defaultViewMode={T.CalendarScreenTab.LIST}
          currentScreenId={lastSelectedScreen.id}
          onChange={handleCurrentScreenChange}
        />
        <HideCalendarButton onClick={handleCalendarClick}>
          <BoldArrowSVG />
        </HideCalendarButton>
      </CalendarSection>
    </Root>
  );
};
export default memo(withErrorBoundary(ContentsSidebarHeader)(Fallback));
