import Color from 'color';

const COLOR_VARIANTS = {
  blackVariants: {
    blackDark: '#252628',
    blackLight: '#252628',
    blackLighter: '#656E82',
  },
  neutralGray: {
    30: '#F9FAFA',
    50: '#F5F6F7',
    75: '#EFF1F1',
    100: '#EBE9EA',
    200: '#D5D5D8',
    300: '#BBBBBF',
    400: '#A2A2A6',
    500: '#787C81',
    600: '#68696F',
    700: '#55565C',
    800: '#393941',
    900: '#25262E',
  },

  coolGray: {
    30: '#FAFBFC',
    50: '#F6F8FA',
    75: '#F1F4F7',
    100: '#EDF0F4',
    200: '#DEE3EB',
    300: '#CBD3DF',
    400: '#B0C2D3',
    500: '#9CA9C1',
    600: '#808BA2',
    700: '#656D82',
    800: '#383F54',
    900: '#202315',
  },

  neutralBlue: {
    30: '#F0F9FD',
    50: '#F0F5FC',
    75: '#E6EDFA',
    100: '#D8E6F9',
    200: '#BDCCF5',
    300: '#91B1F1',
    400: '#6794EB',
    500: '#2767E4',
    600: '#2356C2',
    700: '#1A54A0',
    800: '#19266C',
    900: '#151B4A',
  },

  lightBlue: {
    30: '#F5FAFE',
    50: '#EEF7FD',
    75: '#E2F2FD',
    100: '#D5EDF9',
    200: '#B0E0FE',
    300: '#76CBFE',
    400: '#48B7FE',
    500: '#0099FF',
    600: '#047E07',
    700: '#086380',
    800: '#003874',
    900: '#11204D',
  },

  vividBlue: {
    50: '#C5D9FF',
    100: '#AAC7FF',
    200: '#82ADFF',
    300: '#5690FF',
    400: '#2F76FF',
    500: '#0057FF',
    600: '#0048F0',
    700: '#0039E1',
    800: '#002AD2',
    900: '#000CB4',
    1000: '#000096',
  },

  purple: {
    30: '#F8F7FD',
    50: '#F3F3FC',
    75: '#EBE7FB',
    100: '#E4DCF9',
    200: '#CDBCFS',
    300: '#AF91F1',
    400: '#9076EB',
    500: '#6327F4',
    600: '#5323C2',
    700: '#431FA0',
    800: '#28196C',
    900: '#18154A',
  },

  pointRed: {
    50: '#FFB3F5',
    100: '#FBE1E4',
    200: '#FCC7CB',
    300: '#FCA3AA',
    400: '#FC8089',
    500: '#FD4B58',
    600: '#CE4052',
    700: '#9F354C',
    800: '#592442',
    900: '#2A193C',
  },

  pointGreen: {
    50: '#F3FAF7',
    100: '#E2F5EC',
    200: '#C9EDDB',
    300: '#A8E3C5',
    400: '#87DAAE',
    500: '#56C8BC',
    600: '#49A67B',
    700: '#3B8168',
    800: '#274A52',
    900: '#1A2541',
  },
  tag: {
    red: new Color('#ED6A5D'),
    orange: new Color('#F9A52B'),
    yellow: new Color('#F8C53A'),
    green: new Color('#3FBF83'),
    blue: new Color('#62B0F3'),
    purple: new Color('#AF7BF5'),
    gray: new Color('#A6A6A6'),
  },
};

export default COLOR_VARIANTS;
