import { useL10n } from '^/hooks';
import theme from '^/theme';
import React from 'react';
import styled, { CSSObject } from 'styled-components';
import Text from './text';

const MIN_DAYS = 2;
const MAX_WEEKS = 2;

const Card = styled.div({
  width: '196px',
  backgroundColor: theme.colors.coolGray[50],
  padding: '21px 23px',
  borderRadius: '8px',
  fontFamily: 'Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginBottom: '28px',
});

const Title = styled.p({
  fontWeight: '700',
  fontSize: '16px',
  color: theme.colorTokens.accentBlackLighter,
  marginBottom: '8px',
});

const TextInfo = styled.p<{ customStyles?: CSSObject }>(({ customStyles }) => ({
  fontSize: '12px',
  color: theme.colors.coolGray[600],
  margin: '4px 0',
  lineHeight: '160%',
  ...customStyles,
}));

export function RequestedInfo() {
  const [l10n] = useL10n();
  return (
    <Card>
      <Title>{l10n(Text.successfullyRequested)}</Title>
      <TextInfo>{l10n(Text.minMaxDays(MIN_DAYS, MAX_WEEKS))}</TextInfo>
      <TextInfo>{l10n(Text.checkInPhotoAlbum)}</TextInfo>
    </Card>
  );
}
