import CloseSvg from '^/assets/icons/photo-request/close.svg';
import DeleteSvg from '^/assets/icons/photo-request/delete.svg';
import { useL10n } from '^/hooks';
import { usePopupStore } from '^/store/popup';
import { useViewpointStore } from '^/store/viewpointStore';
import theme from '^/theme';
import * as T from '^/types';
import React from 'react';
import styled, { CSSObject } from 'styled-components';
import Text from './text';

const Root = styled.div({
  backgroundColor: '#DAE6F8',
  height: '54px',
  padding: '16px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  margin: '10px 0px',
});

const SVGWrapper = styled.button<{ customStyles?: CSSObject }>(({ customStyles }) => ({
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '4px',
  backgroundColor: 'transparent',
  ':hover': {
    backgroundColor: theme.colors.lightBlue[200],
  },
  height: '30px',
  width: '30px',
  ...customStyles,
}));

const SelectedText = styled.p({
  fontSize: '16px',
  color: '#1F4782',
});

const SelectionBar = () => {
  const [l10n] = useL10n();
  const selectedViewpointIds = useViewpointStore(state => state.selectedViewpointIds);
  const setSelectedViewpointIds = useViewpointStore(state => state.setSelectedViewpointIds);

  const setPopup = usePopupStore(s => s.setMinorPopup);

  const handleReset = () => {
    setSelectedViewpointIds([]);
  };
  const handleDelete = () => {
    if (!selectedViewpointIds?.length) {
      return;
    }
    setPopup(T.ContentPageMinorPopupType.CONFIRM_DELETE_VIEWPOINTS);
  };

  if (!selectedViewpointIds?.length) {
    return null;
  }

  return (
    <Root>
      <SVGWrapper onClick={handleReset}>
        <CloseSvg />
      </SVGWrapper>
      <SelectedText>{l10n(Text.itemSelectedTooltip(selectedViewpointIds?.length))}</SelectedText>
      <SVGWrapper onClick={handleDelete}>
        <DeleteSvg />
      </SVGWrapper>
    </Root>
  );
};

export default SelectionBar;
