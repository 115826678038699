import Tippy from '@tippyjs/react';
import React, { FC, SyntheticEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, Dispatch } from 'redux';
import styled from 'styled-components';

import Photo360PanoSvg from '^/assets/icons/photo/photo-360-icon.svg';
import PhotoCameraSvg from '^/assets/icons/photo/photo-camera.svg';
import LoadingIcon from '^/components/atoms/LoadingIcon';

import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { UseL10n, useL10n } from '^/hooks';
import usePhotoActions from '^/hooks/timelapse/usePhotoActions';
import { useIsInView } from '^/hooks/useIsInView';
import { useContentsStore } from '^/store/zustand/content/contentStore';

import PlayIcon from '^/assets/icons/photo/video.svg';
import { From2DTo3D360, PatchContent } from '^/store/duck/Contents';
import { ChangeIn3D } from '^/store/duck/Pages';
import { SetCurrentPhotoId, SetHoveredPhotoId, SetSelectedMedia } from '^/store/duck/Photos';

import { usePhotoQuery } from '^/hooks/api/photos/usePhoto';
import { usePopupStore } from '^/store/popup';
import { useVslamAnd360Store } from '^/store/vslamAnd360Store';
import * as T from '^/types';
import { getSingleContentId } from '^/utilities/state-util';
import {
  Checkbox,
  PhotoInspectionListItem,
  PhotoInspectionListItemWrapper,
  PhotoSvgWrapper,
  ThumbNailWrapper,
  VideoDurationText,
  VideoDurationWrapper,
  VideoWrapper,
  Wrapper,
} from '../PhotoList/style';
import Text from '../PhotoList/text';
import { formatFullTime, isVideo } from '../PhotoList/util';

const ThreeSixtyWrapper = styled.div({
  width: '100%',
  display: 'flex',
});

const ThreeSixtySvgWrapper = styled.div({
  marginLeft: 'auto',
  padding: '15px 10px',
});

const LoadingWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const PanoProcessingText = styled.span({
  marginTop: '10px',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 600,
  color: '#8F8F8F',
});

interface Photo360ItemProps {
  isSelected: boolean;
  photoDate: T.Photo;
}

const RawPhoto360Item: FC<Photo360ItemProps> = memo(({ isSelected, photoDate }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const selectedPhotoAlbumIdNew = useThreePhotoAlbumStore(s => s.selectedPhotoAlbumIdNew);
  const { handlePhotoListItemClick, handlePhotoOnMapId } = usePhotoActions(photoDate, isSelected);
  const isPhotoReady = photoDate.status === T.PhotoProcessStatus.COMPLETED;
  const { rawVideos, photosByType } = usePhotoQuery();
  const setMinorPopup = usePopupStore(s => s.setMinorPopup);
  const { selectedMedia } = useSelector((s: T.State) => s.Photos);
  const isIn3D: T.ContentsPageState['in3D'] = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const byId = useContentsStore(s => s.contents.byId);
  const setGeoreferencingMapDetails = useVslamAnd360Store(
    state => state.setGeoreferencingMapDetails
  );
  const { id } = photoDate;
  const threeDMeshId: T.ThreeDMeshContent['id'] | undefined = useSelector((state: T.State) =>
    getSingleContentId(state.Pages, state.ProjectConfigPerUser, T.ContentType.THREE_D_MESH)
  );
  const threeDMeshContent: T.ThreeDMeshContent | undefined = byId[threeDMeshId ?? Number.NaN] as
    | T.ThreeDMeshContent
    | undefined;

  const isThreeDMeshAvailable: boolean = Boolean(
    threeDMeshId && threeDMeshContent?.status !== T.ContentProcessingStatus.PROCESSING
  );
  const isPhotoAVideo = isVideo(photoDate.fullUrl);
  const isPhotoProcessing =
    photoDate.status === T.PhotoProcessStatus.PENDING ||
    photoDate.status === T.PhotoProcessStatus.PROCESSING;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [ref, isVisible] = useIsInView<HTMLDivElement>();
  const [duration, setDuration] = useState('');
  const allThreeSixtyVslamPhotosType = photosByType.get('three_sixty_vslam');

  const handleSelectedHoveredItems = useCallback(
    (isHovered: boolean) => {
      if (isSelected) {
        dispatch(SetHoveredPhotoId({ hoveredId: isHovered ? photoDate.id : undefined }));
      }
    },
    [isSelected, photoDate.id, dispatch]
  );

  const handleInputChange = useCallback(
    (event?: SyntheticEvent<HTMLInputElement, MouseEvent>) => {
      event?.stopPropagation();

      if (photoDate.photoType === T.PhotoAlbumType.THREE_SIXTY_VIDEO) {
        const allThreeSixtyVslamPhotos = allThreeSixtyVslamPhotosType?.filter(
          single => single.videoId === photoDate.id
        );
        const allThreeSixtyVslamPhotosMedias = allThreeSixtyVslamPhotos?.map(single => ({
          type: single.photoType,
          contentId: single.id,
          parentId: single.videoId,
        })) as T.SelectedMedia[];
        if (allThreeSixtyVslamPhotosMedias) {
          const finalizedData = isSelected
            ? selectedMedia.filter(media =>
                media.parentId
                  ? media.parentId && media.parentId !== photoDate.id
                  : media.contentId !== photoDate.id
              )
            : [
                ...selectedMedia,
                ...allThreeSixtyVslamPhotosMedias,
                { type: photoDate.photoType ?? null, contentId: photoDate.id },
              ];
          dispatch(
            SetSelectedMedia({
              selectedMedia: finalizedData,
            })
          );
        }
      } else {
        const temp = isSelected
          ? selectedMedia.filter(media => media.contentId !== photoDate.id)
          : [...selectedMedia, { type: photoDate.photoType ?? null, contentId: photoDate.id }];

        dispatch(SetSelectedMedia({ selectedMedia: temp }));
      }
    },
    [isSelected, selectedMedia, photoDate.id, photoDate.photoType, dispatch]
  );

  const handleSwitchFrom2DTo3D = (videoId: number) => {
    if (!isIn3D && isThreeDMeshAvailable && threeDMeshId) {
      const actions: Action[] = [];

      actions.push(ChangeIn3D({ in3D: true }));
      actions.push(
        PatchContent({
          content: {
            id: threeDMeshId,
            config: {
              selectedAt: new Date(),
            },
          },
        })
      );
      actions.push(
        From2DTo3D360({
          isFrom2DTo3D360: true,
          photoId: videoId,
        })
      );

      actions.forEach(dispatch);
    } else {
      dispatch(
        From2DTo3D360({
          isFrom2DTo3D360: true,
          photoId: videoId,
        })
      );
    }
  };

  const handle360PhotoClick = () => {
    if (!isPhotoReady) {
      return;
    }

    if (isPhotoAVideo && photoDate.photoType === T.PhotoAlbumType.THREE_SIXTY_VIDEO) {
      setGeoreferencingMapDetails({
        selectedVideoId: id,
        isPhotosFromVSLAM: true,
      });
      const selectedVideo = rawVideos.find(single => single.id === id);
      if (selectedVideo && selectedVideo.floorPlan?.id) {
        const videoIdAndType = { contentId: id, type: 'three_sixty_video' } as T.SelectedMedia;
        const allThreeSixtyVslamPhotos = allThreeSixtyVslamPhotosType?.filter(
          single => single.videoId === photoDate.id
        );
        const allThreeSixtyVslamPhotosMedias = allThreeSixtyVslamPhotos?.map(single => ({
          type: single.photoType,
          contentId: single.id,
          parentId: single.videoId,
        })) as T.SelectedMedia[];

        if (allThreeSixtyVslamPhotosMedias && allThreeSixtyVslamPhotosMedias.length > 0) {
          handleSwitchFrom2DTo3D(Number(allThreeSixtyVslamPhotosMedias[0].contentId));
          dispatch(
            SetSelectedMedia({
              selectedMedia: [videoIdAndType, ...allThreeSixtyVslamPhotosMedias],
            })
          );
        } else {
          dispatch(
            SetSelectedMedia({
              selectedMedia: [videoIdAndType],
            })
          );
          dispatch(SetCurrentPhotoId({ photoId: photoDate.id }));
        }
      } else {
        setMinorPopup(T.ContentPageMinorPopupType.VSLAM_SELECTING_BLUEPRINT);
      }
    } else {
      handleSwitchFrom2DTo3D(photoDate.id);
      handlePhotoListItemClick(
        photoDate.id,
        photoDate.photoType,
        photoDate.status,
        isThreeDMeshAvailable
      );
    }
  };

  useEffect(() => {
    if (
      selectedPhotoAlbumIdNew.photoId === photoDate.id &&
      selectedPhotoAlbumIdNew.photoType === photoDate.photoType &&
      ref.current
    ) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedPhotoAlbumIdNew]);

  const handleVideoLoad = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const videoDuration = formatFullTime(videoElement.duration);
      setDuration(videoDuration);
    }
  };

  return (
    <PhotoInspectionListItemWrapper isSelected={isSelected} ref={ref}>
      {isVisible && (
        <PhotoInspectionListItem
          isSelected={isSelected}
          onMouseEnter={() => handleSelectedHoveredItems(true)}
          onMouseLeave={() => handleSelectedHoveredItems(false)}
          url={isPhotoReady ? photoDate.thumbUrl : ''}
          onClick={handle360PhotoClick}
          id={`photo_${photoDate.id}`}
        >
          {!isPhotoReady ? (
            <LoadingWrapper>
              <LoadingIcon />
              {photoDate.photoType === T.PhotoAlbumType.THREE_SIXTY_STITCHED && (
                <PanoProcessingText>{l10n(Text.processingPano)}</PanoProcessingText>
              )}
            </LoadingWrapper>
          ) : (
            <>
              {isVideo(photoDate.fullUrl) ? (
                <>
                  <ThumbNailWrapper isSelected={isSelected}>
                    <VideoDurationWrapper>
                      <VideoDurationText>{duration}</VideoDurationText>
                      <PlayIcon />
                    </VideoDurationWrapper>
                    <VideoWrapper
                      isProcessing={isPhotoProcessing}
                      ref={videoRef}
                      src={photoDate.fullUrl}
                      onLoadedMetadata={handleVideoLoad}
                    />
                    <Tippy
                      offset={T.TIPPY_OFFSET}
                      theme="angelsw"
                      placement="top"
                      arrow={false}
                      content={
                        photoDate.latitude && photoDate.longitude
                          ? l10n(Text.photo.viewTooltip)
                          : l10n(Text.photo.noLocationTooltip)
                      }
                    >
                      <PhotoSvgWrapper onClick={handlePhotoOnMapId} disabled={false}>
                        <PhotoCameraSvg />
                      </PhotoSvgWrapper>
                    </Tippy>
                  </ThumbNailWrapper>
                </>
              ) : (
                <ThreeSixtyWrapper>
                  <ThreeSixtySvgWrapper>
                    <Photo360PanoSvg />
                  </ThreeSixtySvgWrapper>
                </ThreeSixtyWrapper>
              )}
            </>
          )}

          <Wrapper>
            <Checkbox checked={isSelected} onChange={() => handleInputChange()} />
          </Wrapper>
        </PhotoInspectionListItem>
      )}
    </PhotoInspectionListItemWrapper>
  );
});

export const Photo360Item: FC<Photo360ItemProps> = RawPhoto360Item;
