import { CutFillItem, EarthworkAnalysis, Moment, Zone } from '^/store/earthwork/types';
import { fromLonLat } from 'ol/proj';
import * as XLSX from 'xlsx';
import _ from 'lodash-es';
import { ChartConfiguration } from 'chart.js';
export const generateRandomColor = (opacity: number = 1): string => {
  const hue = Math.floor(Math.random() * 360); // Full range of hues
  const saturation = 40 + Math.random() * 20; // 40-60% saturation for darker colors
  const lightness = 10 + Math.random() * 20; // 10-30% lightness for dark colors
  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
};

/**
 * Function to generate burndown data based on initial values and changes.
 * @param {CutFillItem} data - The cut or fill item.
 * @returns {number[]} - Array of volume values after each change.
 */

const getLabelsInOrder = (moments: Moment[]) => {
  const labels = [];
  for (let i = moments.length - 1; i >= 0; i--) {
    labels.push(moments[i].data);
  }
  return labels;
};

const generateBurndownData = (cuts: CutFillItem[], activeSoilType: string) => {
  if (!cuts) {
    return [];
  }
  const activeCutItem = cuts.find(cut => cut.name === activeSoilType || cut.name === 'Fill');
  if (!activeCutItem) {
    return [];
  }

  const changes = activeCutItem.changes ?? [];
  const initial = activeCutItem.initial ?? 0;
  let changed = initial;

  const data = [];

  for (let index = changes.length - 1; index >= 0; index--) {
    const value = changes[index];

    if (index === changes.length - 1) {
      data.push(initial - value);
    } else {
      const updated = changed - value;
      changed = updated;
      data.push(updated);
    }
  }

  return data;
};

/**
 *
 * @param tableHeader - The header row of the table.
 * @param tableBody - The body rows of the table.
 * @param filename - The name of the file to be exported.
 */

type tableArray = Array<string | number | null>;

const exportTableToCSV = (tableHeader: string[], tableBody: tableArray[], filename: string) => {
  if (!Array.isArray(tableBody) || tableBody.some(row => !Array.isArray(row))) {
    throw new Error('Invalid table body: Expected a 2D array.');
  }
  const sanitizedBody = tableBody.map(row =>
    row.map(cell => (cell === undefined || cell === null ? '' : cell))
  );
  const sanitizedHeader = tableHeader.map(cell => cell || '');
  const rows = [sanitizedHeader, ...sanitizedBody];
  const worksheet = XLSX.utils.aoa_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${filename}.csv`);
};

const generateWKT = (locations: Array<[number, number]>) => {
  const unduplicatedLocations: Array<[number, number]> = locations.filter(
    val => !_.isEqual(val, locations[0])
  );
  unduplicatedLocations.unshift(locations[0]);
  unduplicatedLocations.push(locations[0]);
  const locationPoints: string = unduplicatedLocations
    .map(point => fromLonLat(point))
    .map(([x, y]) => `${x} ${y}`)
    .join(',');
  const wkt: string = `POLYGON ((${locationPoints}))`;
  return wkt;
};

const generateCSVData = (earthworkAnalysis: EarthworkAnalysis) => {
  const header = [
    'Zone',
    'Cut / Fill',
    'Soil Type',
    'Initial',
    'Remaining',
    'Total Change',
    ...earthworkAnalysis.moments.map(moment => moment.data),
  ];
  const body = earthworkAnalysis.zones.flatMap(zone =>
    zone.cut
      .concat(zone.fill)
      .map(row => [
        zone.name,
        row.name !== 'Fill' ? 'Cut' : 'Fill',
        row.name || '-',
        row.initial,
        row.remaining,
        row.totalChange,
        ...row.changes,
      ])
  );
  return { header, body };
};

const dataSeries = (data: Zone, activeSoilType: string) => [
  {
    fill: true,
    label: 'Cut',
    pointRadius: 2,
    lineTension: 0,
    borderWidth: 0,
    pointHitRadius: 5,
    borderColor: '#ef4444',
    backgroundColor: 'rgba(239, 68, 68, 0.6)',
    data: generateBurndownData(data.cut, activeSoilType),
  },
  {
    fill: true,
    label: 'Fill',
    pointRadius: 2,
    lineTension: 0,
    borderWidth: 0,
    pointHitRadius: 5,
    borderColor: '#3b82f6',
    backgroundColor: 'rgba(59, 130, 246, 0.6)',
    data: generateBurndownData(data.fill, activeSoilType),
  },
];

const chartOptions = (createChartData: Function): ChartConfiguration => ({
  type: 'line',
  data: createChartData(),
  options: {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
    },
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        padding: 40,
      },
    },
    scales: {
      xAxes: [
        {
          position: 'bottom',
          scaleLabel: {
            display: false,
          },
          gridLines: {
            display: true,
          },
          ticks: {
            padding: 10,
            maxRotation: 90,
            minRotation: 90,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: 'm³',
            fontStyle: 'bold',
            fontColor: '#333',
          },
          gridLines: {
            display: true,
          },
          ticks: {
            padding: 10,
            callback: function (currentValue: number) {
              return currentValue.toLocaleString();
            },
          },
        },
      ],
    },
  },
});

export {
  dataSeries,
  generateWKT,
  chartOptions,
  generateCSVData,
  getLabelsInOrder,
  exportTableToCSV,
  generateBurndownData,
};
