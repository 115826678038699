import React from 'react';
import styled from 'styled-components';
import { BackToPageButton } from '../BackToPageButton';
import colorTokens from '^/theme/colors/color-tokens';
import Button from '^/components/atoms/Button';
import { HorizontalLine } from '^/components/atoms/HorizontalLine';
import { FlexBox } from '^/components/atoms/FlexBox';
import { useL10n } from '^/hooks';
import Text from './text';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ChangePathBeforeAuth } from '^/store/duck/Auth';

interface AuthMessageBoxProps {
  title: string;
  description: string;
  email?: string;
  showBackButton?: boolean;
  showSupportEmail?: boolean;
  link?: { text: string; href: string };
  buttonDetails?: {
    text: string;
    link?: string;
    externalLink?: string;
    onClick?(): void;
    isError?: boolean;
  };
  children?: React.ReactNode;
}

const CardContainer = styled.div({
  display: 'flex',
  gap: '40px',
  width: '540px',
  background: '#fff',
  borderRadius: '12px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '60px 80px 60px 80px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
});

const Title = styled.p({
  fontSize: '36px',
  fontWeight: 'bold',
  marginBottom: '8px',
});

const Description = styled.p({
  color: colorTokens.textCool,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '150%',
  fontFamily: 'pretendard, sans-serif',
});

const EmailBox = styled.div({
  background: '#f3f4f6',
  fontSize: '16px',
  color: colorTokens.textGrayDark,
  padding: '10px',
  borderRadius: '8px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  margin: '0',
  fontFamily: 'pretendard, sans-serif',
  borderLeft: `2px solid ${colorTokens.lineCool}`,
  fontWeight: 700,
});

const SupportEmail = styled.a({
  color: colorTokens.accentBlue,
  '&:hover': {
    textDecoration: 'underline',
  },
});

const AuthMessageBox: React.FC<AuthMessageBoxProps> = ({
  title,
  email,
  children,
  description,
  buttonDetails,
  showSupportEmail,
  showBackButton = true,
}) => {
  const [l10n] = useL10n();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    buttonDetails?.onClick?.();
    const externalLink = buttonDetails?.externalLink;
    const link = buttonDetails?.link;
    dispatch(ChangePathBeforeAuth({ path: undefined }));
    if (externalLink) {
      location.replace(externalLink);
    } else if (link) {
      navigate(link);
      if (buttonDetails.isError) {
        location.reload();
      }
    }
  };
  return (
    <CardContainer>
      {showBackButton && <BackToPageButton label={l10n(Text.backToSignIn)} />}
      <FlexBox gap="20px" flexDirection="column">
        <Title>{title}</Title>
        {email && <EmailBox>{email}</EmailBox>}
        <Description>
          {description}
          {showSupportEmail && (
            <SupportEmail href="mailto:support@angelswing.io"> support@angelswing.io</SupportEmail>
          )}
        </Description>
        <HorizontalLine />
        {children}
        {buttonDetails && (
          <Button variant="primaryBlack" size="large" onClick={onClickHandler}>
            {buttonDetails.text}
          </Button>
        )}
      </FlexBox>
    </CardContainer>
  );
};

export default AuthMessageBox;
