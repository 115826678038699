import React, { FC } from 'react';

import { Root, tabIconMap } from './';
import UploadSVG from '^/assets/icons/contents-tab-bar/upload.svg';
import AngelswingLogo from '^/assets/icons/logo.svg';
import {
  ErrorText,
  NOT_ALLOWED_CLASS_NAME,
  defaultToastErrorOption,
  useInitialToast,
} from '^/hooks';
import * as T from '^/types';
import { IconContainer } from '../IconButtonWithPopover';
import styled from 'styled-components';
import palette from '^/constants/palette';

const UploadTab = styled.button<{ isSelected?: boolean; isDisabled?: boolean }>(
  ({ isSelected, isDisabled }) => ({
    width: '44px',
    height: '44px',

    margin: 'auto',

    border: '2px solid var(--color-theme-primary)',
    backgroundColor: 'white',
    borderRadius: '4px',
    cursor: 'pointer',

    '&:hover': (() => {
      if (isDisabled || isSelected) {
        return undefined;
      }

      return {
        backgroundColor: palette.SideBar.select.toString(),
        '> svg path': {
          fill: palette.white.toString(),
        },
      };
    })(),
  })
);

const LogoTab = styled.section<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  height: '60px',
  borderRadius: 0,
  margin: 0,
  cursor: isDisabled ? 'default' : 'pointer',

  '&:hover': (() => {
    if (isDisabled) {
      return undefined;
    }

    return {
      backgroundColor: palette.SideBar.select.toString(),

      '> svg path': (() => {
        if (isDisabled) {
          return { fill: palette.iconDisabled.toString() };
        }

        return { fill: palette.white.toString() };
      })(),
    };
  })(),
}));

export const Fallback: FC = () => {
  useInitialToast({
    type: T.Toast.ERROR,
    content: {
      title: ErrorText.contentsTabbar.title,
      description: ErrorText.contentsTabbar.description,
    },
    option: defaultToastErrorOption,
  });

  const allTabs: T.ContentPageTabType[] = Object.values(T.ContentPageTabType);

  return (
    <Root className={NOT_ALLOWED_CLASS_NAME}>
      <LogoTab>
        <AngelswingLogo />
      </LogoTab>
      {allTabs.map(tabType => (
        <IconContainer key={tabType}> {tabIconMap[tabType]} </IconContainer>
      ))}
      <UploadTab>
        <UploadSVG />
      </UploadTab>
    </Root>
  );
};
