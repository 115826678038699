/* eslint-disable max-lines */
import { defaultGroup } from '^/constants/defaultContent';
import {
  createPatchUndoItem,
  createPostUndoItem,
  UndoPatchItem,
  UndoPostItem,
} from '^/hooks/useUndoAction';
import { makeV2APIURL } from '^/store/duck/API';
import { APIToContent, PostContentBody } from '^/store/duck/Contents';
import {
  PartialAPIESSParams,
  PartialESSContent,
  shapeESSContentParams,
} from '^/store/duck/ESSContents';
import { getCopiedGroupTitle, getNewGroupTitle } from '^/store/duck/Groups';
import { ChangeCurrentContentTypeFromAnnotationPicker } from '^/store/duck/Pages';
import { essContentsStore, useESSContentsStore } from '^/store/essContentsStore';
import { useESSModelsStore } from '^/store/essModelsStore';
import { useUndoStore } from '^/store/undo';
import * as T from '^/types';
import { getCopiedContentTitle } from '^/utilities/annotation-content-util';
import {
  checkContentType,
  getContentTitlesByType,
  getCurrentGroupId,
} from '^/utilities/content-util';
import { exhaustiveCheck } from '^/utilities/exhaustive-check';
import Color from 'color';
import _ from 'lodash-es';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { isGroupable, typeGuardESSContent, typeGuardGroupable } from './contents';
import { useAuthHeader } from './useAuthHeader';
import { useESSModels } from './useESSModels';
import { MathUtils } from 'three';
import { http } from '^/utilities/api';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { groupStore, useGroupStore } from '^/store/zustand/groups/groupStore';

type GroupContentBody = PostContentBody & {
  readonly groupId?: T.Content['groupId'];
};

interface GetESSContentsResponse {
  readonly data: Array<T.APIESSContent | T.APIESSModelContent>;
}

interface CreateESSContentResponse {
  readonly data: T.APIESSContent;
}

interface PatchESSContentResponse {
  readonly data: T.APIESSContent;
}

export interface PatchESSContentProps {
  content: Omit<PartialESSContent, 'type'> & {
    id: T.ESSContent['id'];
  };
  skipDBUpdate?: boolean;
  isUndoable?: boolean;
  undoTimeStamp?: Date;
  isColor?: boolean;
  isTitle?: boolean;
  isDescription?: boolean;
}

interface DeleteESSContentProps {
  id: T.ESSContent['id'];
}

/**
 * Recursively get all the non-group ids in a list of ids.
 * Typically the root of the list should be from a list of root-level ids in a category.
 *
 * @param byId
 * @param idsByGroup
 * @param ids
 * @returns A list of ids, flattened.
 */
function getNonGroupESSIds(
  byId: Record<T.Content['id'], T.Content>,
  idsByGroup: Record<T.GroupContent['id'], Array<T.Content['id']>>,
  ids: Array<T.Content['id']>
): Array<T.Content['id']> {
  return ids.reduce<Array<T.Content['id']>>((acc, id) => {
    const content = byId[id];
    if (content === undefined) {
      return acc;
    }

    if (isGroupable(content)) {
      return acc.concat(getNonGroupESSIds(byId, idsByGroup, idsByGroup[id] ?? []));
    }

    return acc.concat(id);
  }, []);
}

export function useESSContents() {
  const authHeader = useAuthHeader();
  const {
    essContents,
    rootESSContentIds,
    essContentGroupTree,
    setEditingESSContentId,
    setDeletingESSContentId,
    setESSContent,
    setESSContents,
    setESSContentIds,
    buildESSContentGroupTree,
    removeESSContent,
    removeESSContentGroup,
    setFetchESSContentStatus,
    setSelectedESSGroupIdByTab,
    setESSContentsResponse,
    setESSNewContentId,
    // reset,
  } = useESSContentsStore(s => ({
    essContents: s.essContents,
    rootESSContentIds: s.rootESSContentIds,
    essContentGroupTree: s.essContentGroupTree,
    essContentsResponse: s.essContentsResponse,
    setEditingESSContentId: s.setEditingESSContentId,
    setDeletingESSContentId: s.setDeletingESSContentId,
    setESSContent: s.setESSContent,
    setESSContents: s.setESSContents,
    setESSContentIds: s.setESSContentIds,
    buildESSContentGroupTree: s.buildESSContentGroupTree,
    removeESSContent: s.removeESSContent,
    removeESSContentGroup: s.removeESSContentGroup,
    setFetchESSContentStatus: s.setFetchESSContentStatus,
    setSelectedESSGroupIdByTab: s.setSelectedESSGroupIdByTab,
    setESSContentsResponse: s.setESSContentsResponse,
    setESSNewContentId: s.setESSNewContentId,
    // reset: s.reset,
  }));
  const { getESSModelById } = useESSModels();
  const setSelectedESSModelId = useESSModelsStore(s => s.setSelectedESSModelId);
  const state$ = useStore().getState();

  const byId = useContentsStore(s => s.contents.byId);
  const projectConfig: T.ProjectConfig | undefined = useSelector(
    (s: T.State) => s.ProjectConfigPerUser.config
  );
  const currentProjectId = useSelector((s: T.State) => s.ProjectConfigPerUser.config?.projectId);
  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const screens = useSelector((s: T.State) => s.Screens.screens);

  const dispatch = useDispatch();
  const undoStore = useUndoStore();
  const { rootIdsByCategory, idsByGroup } = useGroupStore(s => ({
    rootIdsByCategory: s.tree.rootIdsByCategory,
    idsByGroup: s.tree.idsByGroup,
  }));

  async function processESSContents(contents: T.APIESSContent[]) {
    const filteredESSContentsObject: Record<T.ESSContent['id'], T.ESSContent> = {};
    const modelIdsFromContents: Set<NonNullable<T.ESSModelContent['info']>['modelId']> = new Set();
    const essContentIds: Array<T.ESSContent['id']> = [];

    contents
      .filter(({ type }) => checkContentType(type))
      .forEach(content => {
        const essContent = APIToContent({
          ...content,
          category: T.ContentCategory.ESS,
        }) as T.ESSContent;

        filteredESSContentsObject[essContent.id] = essContent;
        essContentIds.push(essContent.id);

        if (essContent.type === T.ContentType.ESS_MODEL) {
          modelIdsFromContents.add(essContent.info.modelId);
        }
      });

    setESSContents(filteredESSContentsObject);
    setESSContentIds(essContentIds);
    buildESSContentGroupTree(filteredESSContentsObject);
    return { modelIdsFromContents, essContentIds };
  }

  async function fetchESSContents(projectId: T.Content['id']) {
    setFetchESSContentStatus(T.APIStatus.PROGRESS);
    try {
      const response = await http.get<GetESSContentsResponse>(
        makeV2APIURL('projects', projectId, 'ess_contents'),
        { headers: authHeader }
      );
      setESSContentsResponse(response.data.data);
      const { modelIdsFromContents } = await processESSContents(response.data.data);

      /**
       * To reduce unnecessary request, the initial ESS contents
       * would be loading its own models first. The subsequently added models
       * will have the models loaded when users load the models by category
       * from the ESS models list
       */
      await Promise.all([...modelIdsFromContents].map(async id => getESSModelById(id)));

      setFetchESSContentStatus(T.APIStatus.SUCCESS);
    } catch {
      setFetchESSContentStatus(T.APIStatus.ERROR);
    }
  }

  function getCurrentScreenESSContentIds(isESSDisabled?: boolean): Array<T.Content['id']> {
    if (!projectConfig || !projectConfig.lastSelectedScreenId) {
      return [];
    }

    const currentProject: T.Project = projectById[projectConfig.projectId];
    const currentScreen: T.Screen | undefined = screens.find(
      (screen: T.Screen) => screen.id === projectConfig.lastSelectedScreenId
    );
    if (currentScreen === undefined || currentProject === undefined) {
      return [];
    }

    const categories = (() => {
      const allCategories = Object.keys(rootIdsByCategory) as T.ContentCategory[];

      if (isESSDisabled) {
        return allCategories.filter(category => category !== T.ContentCategory.ESS);
      }

      return allCategories;
    })();

    return categories.reduce<Array<T.Content['id']>>((acc, category) => {
      const { pinned, unpinned } = rootIdsByCategory[category];

      return acc
        .concat(getNonGroupESSIds(essContents, idsByGroup, pinned))
        .concat(getNonGroupESSIds(essContents, idsByGroup, unpinned[currentScreen.id] ?? []));
    }, []);
  }

  async function createESSContent({
    content,
    isUndoable,
    isCopy,
  }: {
    content: PartialESSContent;
    readonly isUndoable?: boolean;
    readonly isCopy?: boolean;
  }) {
    const tab = T.ContentPageTabType.ESS;
    let groupId: T.GroupContent['id'] | undefined;
    try {
      // TODO: State_Management: if a function needs whole state, use it inside the function instead of passing like this.
      // Root state used like this won't have the latest state.
      groupId = getCurrentGroupId(state$, tab);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return;
    }
    setSelectedESSGroupIdByTab(groupId);
    const groupContent = typeGuardGroupable(essContents[groupId ?? NaN]);
    if (groupContent !== undefined && !groupContent.info?.isOpened) {
      void updateESSContent({ content: { id: groupContent.id, info: { isOpened: true } } });
    }
    const temporaryContentId = MathUtils.generateUUID();

    const body: PartialAPIESSParams = shapeESSContentParams({
      ...content,
      groupId,
      config: { temporaryContentId },
    });

    const temporaryContent = {
      ...content,
      id: temporaryContentId,
      groupId,
      screenId: groupContent?.screenId,
      category: T.ContentCategory.ESS,
      config: { selectedAt: new Date(), temporaryContentId },
    };

    if (!isCopy) {
      dispatch(ChangeCurrentContentTypeFromAnnotationPicker({}));
      setESSNewContentId(temporaryContent.id);
      setESSContent(temporaryContent as T.ESSContent, null, T.MoveOption.FIRST);
      buildESSContentGroupTree();
    }

    try {
      const response = await http.post<CreateESSContentResponse>(
        makeV2APIURL('projects', currentProjectId!, 'ess_contents'),
        body,
        { headers: authHeader }
      );

      const newContent = APIToContent(response.data.data) as T.ESSContent;

      if (isUndoable) {
        const undoItem: UndoPostItem = createPostUndoItem({
          contentId: newContent.id,
          category: newContent.category,
        });
        undoStore.pushUndoItem(undoItem);
      }

      const newESSContent: T.ESSContent = {
        ...newContent,
        category: T.ContentCategory.ESS,
        config: { selectedAt: new Date(), temporaryContentId } as any,
      };

      if (isCopy) {
        delete newESSContent.config?.temporaryContentId;
      }

      dispatch(ChangeCurrentContentTypeFromAnnotationPicker({}));
      setSelectedESSModelId(undefined);
      setESSNewContentId(newESSContent.id);
      setESSContent(newESSContent, response.data.data, T.MoveOption.FIRST);
      setEditingESSContentId(newESSContent.id);
      buildESSContentGroupTree();

      if (newESSContent.config?.temporaryContentId) {
        removeESSContent(newESSContent.config.temporaryContentId);
        delete newESSContent.config.temporaryContentId;
      }
    } catch (error) {
      removeESSContent(temporaryContentId);
    }
  }

  async function createESSGroupContent({
    isPersonal,
    parentGroupId,
  }: {
    isPersonal: boolean;
    parentGroupId?: T.GroupContent['id'];
  }) {
    const projectId = state$.Pages.Contents.projectId;
    if (projectId === undefined) {
      return [];
    }

    const lang = state$.Pages.Common.language;

    const existingGroupTitles = essContentsStore
      .getState()
      .rootESSContentIds.map(id => essContentsStore.getState().essContents[id]?.title);

    const newGroupTitle = getNewGroupTitle(existingGroupTitles, lang, false);
    const newGroupSkeleton = defaultGroup({ title: newGroupTitle });

    const newGroupContent: GroupContentBody = {
      ...newGroupSkeleton,
      category: T.ContentCategory.ESS,
      screenId: null,
      color: newGroupSkeleton.color.toString(),
      groupId: parentGroupId,
      isPersonal,
    };

    const { screenId, category, ...body } = newGroupContent;

    try {
      const response = await http.post<CreateESSContentResponse>(
        makeV2APIURL('projects', projectId, 'ess_contents'),
        body,
        { headers: authHeader }
      );
      const newContent = APIToContent(response.data.data) as T.ESSContent;
      const newESSContent: T.ESSContent = { ...newContent, category: T.ContentCategory.ESS };
      groupStore.setState({ isCreatingNewGroup: true });
      setESSContent(newESSContent, response.data.data, T.MoveOption.FIRST);
      setSelectedESSGroupIdByTab(newESSContent.id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return;
  }

  async function updateESSContent({
    content,
    skipDBUpdate,
    isUndoable,
    undoTimeStamp,
    isColor,
    isTitle,
    isDescription,
  }: PatchESSContentProps) {
    // fetching esscontents again to get the latest and updated contents
    const byIds = essContentsStore.getState().essContents;
    const originalContent: T.ESSContent | undefined = typeGuardESSContent(byIds[content.id]);
    if (!originalContent) {
      return;
    }
    // Update the store first so that the interface gets updated
    // without having to wait until the response is received from BE.
    const updatedContent: T.ESSContent = (() => {
      const clonedContent = {
        ...originalContent,
        color: content.color ? new Color(content.color.toString()) : originalContent.color,
        title: content.title ?? originalContent.title,
        config: content.config
          ? {
              type: originalContent.type,
              selectedAt: content.config?.selectedAt
                ? new Date(content.config.selectedAt.toISOString())
                : undefined,
            }
          : originalContent.config,
      } as T.ESSContent;

      if (!content.info) {
        return clonedContent;
      }
      switch (clonedContent.type) {
        case T.ContentType.ESS_ARROW:
        case T.ContentType.ESS_POLYGON:
        case T.ContentType.ESS_POLYLINE:
        case T.ContentType.ESS_MODEL:
        case T.ContentType.ESS_MODEL_CUSTOM:
        case T.ContentType.GROUP: {
          return {
            ...clonedContent,
            info: {
              ...clonedContent.info,
              ...content.info,
            },
          } as T.ESSContent;
        }
        case T.ContentType.ESS_LINE_TEXT: {
          const info = content.info as T.ESSLineTextContent['info'];

          return {
            ...clonedContent,
            info: {
              ...clonedContent.info,
              ...content.info,
              // For some reason, the fontColor is not updating properly.
              // It has to be recreated in this way so that the color is updated.
              fontColor: info.fontColor
                ? new Color(info.fontColor.toString())
                : clonedContent.info.fontColor,
              borderColor: info.borderColor
                ? new Color(info.borderColor.toString())
                : clonedContent.info.borderColor,
            },
          };
        }
        case T.ContentType.ESS_TEXT: {
          const info = content.info as T.ESSTextContent['info'];

          return {
            ...clonedContent,
            info: {
              ...clonedContent.info,
              ...content.info,
              // For some reason, the fontColor is not updating properly.
              // It has to be recreated in this way so that the color is updated.
              fontColor: info.fontColor
                ? new Color(info.fontColor.toString())
                : clonedContent.info.fontColor,
            },
          };
        }
        default: {
          exhaustiveCheck(clonedContent);
        }
      }
    })();

    setESSContent(updatedContent, null);

    if (skipDBUpdate) {
      return;
    }

    let undoItem: UndoPatchItem | undefined;

    const contentInfo = content.info as T.AreaContent['info'] & T.ESSModelContent['info'];
    const isLocationAvailable = contentInfo?.locations || contentInfo?.location;
    if (isUndoable && (isLocationAvailable || isColor || isTitle || isDescription)) {
      undoItem = createPatchUndoItem({
        content: { ..._.pick(originalContent, _.keys(content)), id: originalContent.id },
        category: originalContent.category,
      });
      undoStore.pushUndoItem(undoItem);
    }

    const body: PartialAPIESSParams = shapeESSContentParams(updatedContent);

    const response = await http.patch<PatchESSContentResponse>(
      makeV2APIURL('ess_contents', content.id),
      body,
      { headers: authHeader }
    );

    const essContent = APIToContent(response.data.data) as T.ESSContent;
    setESSContent(
      {
        ...essContent,
        config: updatedContent.config as any,
        category: T.ContentCategory.ESS,
      },
      response.data.data
    );

    // This switch case is needed because
    // AddContent accepts any content that typically is saved from the content response from BE,
    // but there are properties that only exists on client-side. Therefore, when updating the response
    // from BE, use the value from the updated content instead of rewriting from DB.
    const filter = undoStore.undoUpdatesById[essContent.id] === undoTimeStamp;
    if (undoTimeStamp && filter) {
      switch (essContent.type) {
        case T.ContentType.ESS_MODEL: {
          if (updatedContent.type !== T.ContentType.ESS_MODEL) {
            throw new Error('Updated content and response from the content mismatched.');
          }
          const newContent: T.ESSContent = {
            ...essContent,
            category: T.ContentCategory.ESS,
            info: {
              ...essContent.info,
              isWorkRadiusVisEnabled: updatedContent.info.isWorkRadiusVisEnabled,
            },
            config: updatedContent.config,
          };
          setESSContent(newContent, response.data.data);
          return;
        }
        case T.ContentType.ESS_MODEL_CUSTOM: {
          if (updatedContent.type !== T.ContentType.ESS_MODEL_CUSTOM) {
            throw new Error('Updated content and response from the content mismatched.');
          }

          const newContent: T.ESSContent = {
            ...essContent,
            category: T.ContentCategory.ESS,
            info: {
              ...essContent.info,
              isWorkRadiusVisEnabled: updatedContent.info.isWorkRadiusVisEnabled,
            },
            config: updatedContent.config,
          };
          setESSContent(newContent, response.data.data);
          return;
        }
        case T.ContentType.GROUP: {
          const groupConfig = updatedContent.config as T.GroupContent['config'];

          const newContent: T.ESSContent = {
            ...essContent,
            category: T.ContentCategory.ESS,
            config: groupConfig,
          };
          setESSContent(newContent, response.data.data);
          return;
        }
        default: {
          const newContent: T.ESSContent = {
            ...essContent,
            category: T.ContentCategory.ESS,
            config: updatedContent.config,
          };
          setESSContent(newContent, response.data.data);
          return;
        }
      }
    }
  }

  async function duplicateESSContent({ content }: { content: PartialESSContent }) {
    return createESSContent({ content, isCopy: true });
  }

  async function duplicateESSGroupContent({
    isPersonal,
    isUndoable,
    selectedGroupId,
  }: {
    isUndoable?: boolean;
    isPersonal: boolean;
    selectedGroupId?: T.GroupContent['id'];
  }) {
    const lang = state$.Pages.Common.language;

    const existingGroupTitles = rootESSContentIds.map(id => essContents[id]?.title);
    const selectedGroupTitle = selectedGroupId ? essContents[selectedGroupId]?.title : '';
    const copiedGroupTitle = getCopiedGroupTitle(existingGroupTitles, selectedGroupTitle, lang);

    const group = defaultGroup({ title: copiedGroupTitle });
    const newGroup: PostContentBody = {
      ...group,
      category: T.ContentCategory.ESS,
      screenId: null,
      color: group.color.toString(),
      isPersonal,
    };

    const ESSContentfGroup: T.ESSContent[] = [];
    const childrenIds = selectedGroupId ? essContentGroupTree[selectedGroupId] : [];
    childrenIds?.forEach(contentId => {
      const content = essContents[contentId];
      ESSContentfGroup.push(typeGuardESSContent(content)!);
    });

    // User should be able to immediately rename after creating a group
    // since the title is a placeholder. However, there are cases
    // where it doesn't need to be, since it's just to create dummy group.
    groupStore.setState({ isCreatingNewGroup: true });

    const { screenId, category, ...body } = newGroup;

    const contentTitles = getContentTitlesByType(byId, T.ContentType.ESS_MODEL);

    const response = await http.post<CreateESSContentResponse>(
      makeV2APIURL('projects', currentProjectId!, 'ess_contents'),
      body,
      { headers: authHeader }
    );

    const newGroupContent = APIToContent({
      ...response.data.data,
      category: T.ContentCategory.ESS,
    }) as T.ESSContent;

    setESSContent(newGroupContent, response.data.data, T.MoveOption.FIRST);
    setEditingESSContentId(newGroupContent.id);
    setSelectedESSGroupIdByTab(newGroupContent.id);
    buildESSContentGroupTree();

    if (isUndoable) {
      const undoItem: UndoPostItem = createPostUndoItem({
        contentId: newGroupContent.id,
        category: newGroupContent.category,
      });
      undoStore.pushUndoItem(undoItem);
    }

    await Promise.all(
      ESSContentfGroup.map(async content => {
        const copiedESSContent = {
          ...content,
          title: getCopiedContentTitle(content?.title, contentTitles),
          groupId: newGroupContent.id,
        };
        return duplicateESSContent({ content: copiedESSContent });
      })
    );
  }

  async function deleteESSContent({ id }: DeleteESSContentProps) {
    const isGroupId = essContents[id].type === T.ContentType.GROUP;
    if (isGroupId) {
      setSelectedESSGroupIdByTab(undefined);
    }

    try {
      await http.delete<PatchESSContentResponse>(makeV2APIURL('ess_contents', id), {
        headers: authHeader,
      });

      setEditingESSContentId(undefined);
      setDeletingESSContentId(undefined);
      if (isGroupId) {
        removeESSContentGroup(id);
      } else {
        removeESSContent(id);
      }
      buildESSContentGroupTree();
      dispatch(ChangeCurrentContentTypeFromAnnotationPicker({}));
    } catch (error) {
      groupStore.setState({ isGroupAlreadyDeleted: true });
    }
  }

  return {
    essContents,
    fetchESSContents,
    getCurrentScreenESSContentIds,
    createESSContent,
    createESSGroupContent,
    updateESSContent,
    duplicateESSContent,
    duplicateESSGroupContent,
    deleteESSContent,
    processESSContents,
  };
}
