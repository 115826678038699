import Color from 'color';
import COLOR_VARIANTS from './color-variants';

const white = new Color('#FFFFFF');
const black = new Color('#000000');

const COLORS = {
  primary: '#2767e4',
  white: {
    10: white.alpha(0.1).toString(),
    30: white.alpha(0.3).toString(),
    50: white.alpha(0.5).toString(),
    70: white.alpha(0.7).toString(),
    90: white.alpha(0.9).toString(),
    100: white.toString(),
  },
  black: {
    10: black.alpha(0.1).toString(),
    30: black.alpha(0.3).toString(),
    50: black.alpha(0.5).toString(),
    70: black.alpha(0.7).toString(),
    90: black.alpha(0.9).toString(),
    100: black.toString(),
  },
  ...COLOR_VARIANTS,
};

export default COLORS;
