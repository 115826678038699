import React, { FC, FormEvent, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import Text from './text';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import * as T from '^/types';

import { l10n } from '^/utilities/l10n';
import CMInput from '^/components/atoms/CMInput';
import dsPalette from '^/constants/ds-palette';
import { useSelector } from 'react-redux';
import { determineUnitType } from '^/utilities/imperial-unit';
import {
  getImperialMeasurementUnitFromGeometryType,
  getMeasurementUnitFromGeometryType,
} from '^/components/ol/contentTypeSwitch';

interface CustomStyleProps {
  readonly customStyle?: CSSObject;
}
const Root = styled.div<CustomStyleProps>(({ customStyle }) => ({
  ...customStyle,
}));

const Wrapper = styled.div<CustomStyleProps>(
  {
    positive: 'relative',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const Label = styled.label<CustomStyleProps>(
  {
    display: 'block',
    marginBottom: '10px',

    fontWeight: 500,
    color: palette.darkBlack.toString(),
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const Message = styled.p({
  display: 'block',
  marginTop: '10px',

  lineHeight: 1.3,
  fontSize: '14px',
});

const ErrorMessage = styled(Message)({
  color: palette.error.toString(),
});

const CMInputContainer = styled.div({
  position: 'relative',
});

const CMInputUnit = styled.div({
  position: 'absolute',
  right: '7px',
  top: '50%',
  transform: 'translateY(-50%)',
  color: dsPalette.themePrimary.toString(),
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: '700',
});

export type CustomModelInputFieldKind =
  | 'name'
  | 'specification'
  | 'length'
  | 'width'
  | 'height'
  | 'radius';

const kindToType: Record<CustomModelInputFieldKind, string> = {
  name: 'text',
  specification: 'text',
  length: 'number',
  width: 'number',
  height: 'number',
  radius: 'number',
};

const kindToLabel: Record<CustomModelInputFieldKind, (language: T.Language) => string> = {
  name: language => l10n(Text.nameLabel, language),
  specification: language => l10n(Text.specificationLabel, language),
  length: language => l10n(Text.lengthLabel, language),
  width: language => l10n(Text.widthLabel, language),
  height: language => l10n(Text.heightLabel, language),
  radius: language => l10n(Text.radiusLabel, language),
};

type LangToStr = (language: T.Language) => string;
/**
 * @todo many duplicated functions, we could reduce these functions with lodash
 */
const kindToPlaceholder: Record<CustomModelInputFieldKind, LangToStr> = {
  name: language => l10n(Text.namePlaceholder, language),
  specification: language => l10n(Text.specificationPlaceholder, language),
  length: language => l10n(Text.lengthPlaceholder, language),
  width: language => l10n(Text.widthPlaceholder, language),
  height: language => l10n(Text.heightPlaceholder, language),
  radius: language => l10n(Text.radiusPlaceholder, language),
};

export interface Props {
  readonly kind: CustomModelInputFieldKind;
  readonly value: number | string;
  readonly error?: string;
  readonly rootStyle?: CSSObject;
  readonly labelStyle?: CSSObject;
  readonly wrapperStyle?: CSSObject;
  readonly inputStyle?: CSSObject;
  onChange(kind: CustomModelInputFieldKind, value: string): void;
  onBlur?(kind: CustomModelInputFieldKind, value: string): void;
}

const CustomModelInput: FC<Props & L10nProps> = ({
  kind,
  value,
  error,
  rootStyle,
  labelStyle,
  wrapperStyle,
  inputStyle,
  language,
  onChange,
}) => {
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);

  const project: T.Project | undefined = projectId ? projectById[projectId] : undefined;
  const unitType: T.ValidUnitType = project
    ? determineUnitType(project.unit)
    : determineUnitType(projectUnit);

  const handleInputChange: (event: FormEvent<HTMLInputElement>) => void = event => {
    onChange(kind, event.currentTarget.value);
  };

  const withError: boolean = error !== undefined;
  const errorMessage: ReactNode = withError ? (
    <ErrorMessage data-testid="sign-up-input-field-error">{`* ${error}`}</ErrorMessage>
  ) : undefined;

  return (
    <Root customStyle={rootStyle}>
      <Wrapper customStyle={wrapperStyle}>
        <Label customStyle={labelStyle}>{kindToLabel[kind](language)}</Label>
        <CMInputContainer>
          <CMInput
            customStyle={inputStyle}
            type={kindToType[kind]}
            placeholder={kindToPlaceholder[kind](language)}
            error={withError}
            value={value}
            onChange={handleInputChange}
          />
          {kindToType[kind] === 'number' && (
            <CMInputUnit>
              {unitType === T.UnitType.IMPERIAL
                ? getImperialMeasurementUnitFromGeometryType({
                    geometryType: T.ContentType.ESS_MODEL_CUSTOM,
                  })
                : getMeasurementUnitFromGeometryType({
                    geometryType: T.ContentType.ESS_MODEL_CUSTOM,
                  })}
            </CMInputUnit>
          )}
        </CMInputContainer>
        {errorMessage}
      </Wrapper>
    </Root>
  );
};
export default withL10n(CustomModelInput);
