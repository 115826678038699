import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
`;

interface SkeletonElementProps {
  width?: string;
  height?: string;
}

/**
 * @author Saroj Aryal
 * @desc Thu June 12 17:20:41 2024 UTC
 */

const SkeletonElement = styled.div<SkeletonElementProps>`
  background-image: linear-gradient(90deg, #f6f7f8 25%, #e0e0e0 50%, #f6f7f8 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite linear;
  border-radius: 4px;
  height: ${props => props.height || '30px'};
  width: ${props => props.width || '100%'};
`;

export default SkeletonElement;
