import { Reducer, combineReducers } from 'redux';
import { Epic, combineEpics } from 'redux-observable';

import AttachmentsReducer, {
  Action as AttachmentAction,
  epic as AttachmentEpic,
} from './Attachments';
import AuthReducer, { Action as AuthAction, epic as AuthEpic } from './Auth';
import ContentsReducer, { Action as ContentsAction, epic as ContentsEpic } from './Contents';
import ESSAttachmentsReducer, {
  Action as ESSAttachmentsAction,
  epic as ESSAttachmentsEpic,
} from './ESSAttachments';
import GroupReducer, { Action as GroupsAction, epic as GroupsEpic } from './Groups';
import PagesReducer, { Action as PagesAction, rootEpic as PagesEpic } from './Pages';
import PermissionsReducer, { Action as PermissionsAction } from './Permissions';
import PhotosReducer, { Action as PhotosAction, epic as PhotosEpic } from './Photos';
import PlanConfigReducer, {
  Action as PlanConfigAction,
  epic as PlanConfigEpic,
} from './PlanConfig';
import ProjectConfigReducer, {
  Action as ProjectConfigAction,
  epic as ProjectConfigEpic,
} from './ProjectConfig';
import ProjectsReducer, { Action as ProjectsAction, epic as ProjectsEpic } from './Projects';
import ScreensReducer, { Action as ScreensAction, epic as ScreensEpic } from './Screens';
import SharedContentsReducer, {
  Action as SharedContentsAction,
  epic as SharedContentsEpic,
} from './SharedContents';
import UsersReducer, { Action as UsersAction, epic as UsersEpic } from './Users';

/**
 * @todo implement screen API
 */

import { Action as PersistAction, epic as PersistEpic } from './persist';
// import { Action as RouterAction, epic as RouterEpic } from './router';
import { State } from '^/types';

export type RootAction =
  | AttachmentAction
  | AuthAction
  | ContentsAction
  | ScreensAction
  | PermissionsAction
  | ProjectsAction
  | SharedContentsAction
  | UsersAction
  | PlanConfigAction
  | ProjectConfigAction
  | PagesAction
  | PersistAction
  // | RouterAction
  | PhotosAction
  | ESSAttachmentsAction
  | GroupsAction;

export type RootEpic = Epic<RootAction, RootAction, State>;
export const rootEpic: RootEpic = combineEpics<RootEpic>(
  AttachmentEpic,
  AuthEpic,
  ContentsEpic,
  ScreensEpic,
  ProjectsEpic,
  UsersEpic,
  PlanConfigEpic,
  ProjectConfigEpic,
  PagesEpic,
  PersistEpic,
  // RouterEpic,
  SharedContentsEpic,
  PhotosEpic,
  ESSAttachmentsEpic,
  GroupsEpic
);

const createRootReducer: () => Reducer<State, RootAction> = () =>
  combineReducers<State, RootAction>({
    Auth: AuthReducer,
    Contents: ContentsReducer,
    Attachments: AttachmentsReducer,
    Permissions: PermissionsReducer,
    Screens: ScreensReducer,
    Projects: ProjectsReducer,
    Users: UsersReducer,
    PlanConfig: PlanConfigReducer,
    SharedContents: SharedContentsReducer,
    ProjectConfigPerUser: ProjectConfigReducer,
    Pages: PagesReducer,
    Photos: PhotosReducer,
    ESSAttachments: ESSAttachmentsReducer,
    Groups: GroupReducer,
    // router: connectRouter(history),
  });

export default createRootReducer;
