import React, { memo, FC, useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import Text from './text';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';
import { SetSelectedMedia } from '^/store/duck/Photos';
import * as T from '^/types';
import { GroupImageSvgIcon } from '^/assets/icons/photo/group-img.svg';
import dsPalette from '^/constants/ds-palette';
import { isVideo } from '../PhotoList/util';
import { useNavigate } from 'react-router-dom';

const Root = styled.div<{ size: 'sm' | 'md' }>(({ size }) => ({
  position: 'relative',
  zIndex: 240,
  display: 'flex',
  flexDirection: size === 'md' ? 'row' : 'column',
  padding: '24px 19px',
  marginTop: '35px',
  marginRight: '35px',
  alignItems: 'flex-start',
  backgroundColor: palette.toggleButtonGray.toString(),
  borderRadius: '10px',
  gap: size === 'md' ? '35px' : '19px',
}));
Root.displayName = 'PhotoTopBarRoot';

const PhotoTabButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: '35px',
});

const PhotoTabButton = styled.button({
  flexShrink: 0,
  backgroundColor: dsPalette.themePrimary.toString(),
  borderRadius: '6px',
  padding: '10px 32px',
  color: dsPalette.white.toString(),
  fontSize: '16px',
  fontWeight: 700,
  width: 'max-content',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: dsPalette.themePrimaryLightest.toString(),
  },
});

const PhotoTabText = styled.p({
  color: palette.textBlack.toString(),
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
});

const GroupImageIconWrapper = styled.div({
  width: 45,
  height: 34,
});

interface PhotoTopBarProps {
  selectedPhotos: T.FinalPhoto[];
  size: 'sm' | 'md';
}
export const PhotoTopBar: FC<PhotoTopBarProps> = memo(({ selectedPhotos, size }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const navigate = useNavigate();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [videoOverlay, setVideoOverlay] = useState(0);
  const [photoOverlay, setPhotoOverlay] = useState(0);

  const onButtonClick = () => {
    navigate(T.PhotoPathType.NO_DATE);
    dispatch(SetSelectedMedia({ selectedMedia: [] }));
  };
  useEffect(() => {
    const videoCount = selectedPhotos.filter(photo => isVideo(photo.fullUrl)).length;
    const photoCount = selectedPhotos.length - videoCount;
    setVideoOverlay(videoCount);
    setPhotoOverlay(photoCount);
  }, [selectedPhotos]);
  return (
    <Root ref={rootRef} size={size}>
      <PhotoTabButtonWrapper>
        {size === 'md' && (
          <GroupImageIconWrapper>
            <GroupImageSvgIcon color={palette.dropdown.caretColor} />
          </GroupImageIconWrapper>
        )}
        <PhotoTabText>
          {photoOverlay > 0 && videoOverlay > 0
            ? l10n(Text.photoAndVideoText(photoOverlay + videoOverlay))
            : photoOverlay > 0
            ? l10n(Text.photoText(photoOverlay))
            : l10n(Text.videoText(videoOverlay))}
        </PhotoTabText>
      </PhotoTabButtonWrapper>
      <PhotoTabButton onClick={onButtonClick}>
        <span>
          {photoOverlay > 0 && videoOverlay > 0
            ? l10n(Text.buttonVideoAndPhotoText(photoOverlay + videoOverlay))
            : photoOverlay > 0
            ? l10n(Text.buttonPhotoText(photoOverlay))
            : l10n(Text.buttonVideoText(videoOverlay))}
        </span>
      </PhotoTabButton>
    </Root>
  );
});
