import { useL10n } from '^/hooks';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import * as T from '^/types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Root, TableListWrapper } from '../styles';
import Text from '../text';

import { TableSkeleton } from './TableSkeleton';

import EmptySvg from '^/assets/icons/earthwork/Empty.svg';
import {
  EmptyText,
  EmptyZoneContent,
} from '../EarthworkSidebarContents/FinalEarthWorkSelectAttachment';
import { EarthworkTable } from './EarthworkTable';
import { TitleSkeleton } from './TitleSkeleton';

export const ExportCSVButton = styled.div({
  padding: '14px 20px',
  fontSize: '13px',
  backgroundColor: 'var(--color-theme-primary)',
  color: 'white',
  position: 'absolute',
  top: '10px',
  right: '40px',
  borderRadius: '8px',
  cursor: 'pointer',
});

export const EmptyZoneContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90vh',
});

type Row = Array<string | number>;
export interface Measurement {
  [T.ContentType.MARKER]: Row[];
  [T.ContentType.LENGTH]: Row[];
  [T.ContentType.AREA]: Row[];
  [T.ContentType.VOLUME]: Row[];
}
interface Props {
  sidebarWidth: number;
}
//
export const EarthworkTables: React.FC<Props> = ({ sidebarWidth }) => {
  const [l10n] = useL10n();
  const {
    earthworkAnalysis,
    earthworkFetchStatus,
    earthworkAnalysisFetchStatus,
    selectedEarthworkId,
  } = useEarthWorkStore();

  const isSidebarOpened = useSelector((s: T.State) => s.Pages.Contents.showSidebar);

  const isLoading =
    earthworkFetchStatus === T.APIStatus.PROGRESS ||
    earthworkAnalysisFetchStatus === T.APIStatus.PROGRESS;

  const emptyZone = (
    <EmptyZoneContentWrapper>
      <EmptyZoneContent>
        <EmptySvg />
        <EmptyText>{l10n(Text.noAnalysisData)}</EmptyText>
      </EmptyZoneContent>
    </EmptyZoneContentWrapper>
  );

  if (!earthworkAnalysis) {
    if (isLoading) {
      return (
        <Root sidebarWidth={isSidebarOpened ? sidebarWidth : 0}>
          <TitleSkeleton />
          <TableSkeleton />
        </Root>
      );
    }
    return <Root sidebarWidth={isSidebarOpened ? sidebarWidth : 0}>{emptyZone}</Root>;
  }

  return (
    <Root sidebarWidth={isSidebarOpened ? sidebarWidth : 0}>
      <TableListWrapper className="no-scrollbar">
        {selectedEarthworkId && <EarthworkTable earthWOrkId={selectedEarthworkId} />}
      </TableListWrapper>
    </Root>
  );
};
