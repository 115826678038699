import { Language } from '^/types';

export default {
  tooltipCopy: {
    [Language.KO_KR]: '복사',
    [Language.EN_US]: 'Copy',
  },
  tooltipDelete: {
    [Language.KO_KR]: '삭제',
    [Language.EN_US]: 'Delete',
  },
  tooltipDownload: {
    [Language.KO_KR]: '다운로드',
    [Language.EN_US]: 'Download',
  },
  tooltipCenter: {
    [Language.KO_KR]: '원점',
    [Language.EN_US]: 'Center on map',
  },
  tooltipExpandText: {
    [Language.KO_KR]: '세부 정보 표시',
    [Language.EN_US]: 'Show details',
  },
  tooltipCollapseText: {
    [Language.KO_KR]: '세부 정보 숨기기',
    [Language.EN_US]: 'Hide details',
  },
  DSM: {
    [Language.KO_KR]: 'DSM',
    [Language.EN_US]: 'DSM',
  },
  DTM: {
    [Language.KO_KR]: 'DTM',
    [Language.EN_US]: 'DTM',
  },
};
