import React, { memo, ReactNode, useCallback, useState } from 'react';

import palette from '^/constants/palette';
import {
  getLastCreatedScreenId,
  typeGuardGCPGroup,
  UseL10n,
  useL10n,
  useProjectCoordinateSystem,
  UseState,
} from '^/hooks';
import Text from './text';
import styled from 'styled-components';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import GCPInput from '^/components/molecules/SourcePhotoUpload/GCPInput';

import * as T from '^/types';
import { useSelector } from 'react-redux';
import { getCoordinateTitles } from '^/utilities/coordinate-util';
import { RowValue } from '^/components/molecules/CoordinateTable';
import { MediaQuery } from '^/constants/styles';
import { State } from '^/components/organisms/ProjectManageTab';
import { useAttachmentsStore } from '^/store/attachmentsStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';

const DEFAULT_IS_GCP_USING: boolean = true;
interface Props {
  readonly isEditMode: boolean;
  readonly withGcp: boolean;
  readonly withRtk: boolean;
  setProjectState: React.Dispatch<React.SetStateAction<State>>;
}

type ErrorInputKey = 'gcp';

type InputErrors = { [K in ErrorInputKey]?: boolean };
interface SettingProps {
  isEditMode?: boolean;
}
const SettingsWrapper = styled.div({
  marginBottom: '10px',
  fontSize: '18px',
  [MediaQuery[T.Device.MOBILE_L]]: {
    fontSize: '14px',
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    fontSize: '12px',
  },
  lineHeight: 1,
  color: palette.darkBlack.toString(),
});

const SettingsContentsWrapper = styled.div<SettingProps>(({ isEditMode }: SettingProps) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: palette.white.toString(),

  border: isEditMode ? `1px solid ${palette.border.toString()}` : 'none',
  borderRadius: '3px',
  padding: isEditMode ? ' 0px 15px 15px' : '0px',
}));

const TextWrapper = styled.div({
  padding: '16px',
  marginTop: '16px',
  background: palette.itemBackground.toString(),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
});

const InfoText = styled.span({
  fontSize: '13px',
  color: palette.textBlack.toString(),
  lineHeight: 1,
});

const SettingText = styled.p({
  marginBottom: '10px',
  fontSize: '18px',
  [MediaQuery[T.Device.MOBILE_L]]: {
    fontSize: '14px',
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    fontSize: '12px',
  },
  lineHeight: 1,
  fontWeight: 'bold',
  color: palette.darkBlack.toString(),
});

const MoreSettingsText = styled.h3({
  paddingTop: '17px',
  marginBottom: '10px',
  fontSize: '18px',
  [MediaQuery[T.Device.MOBILE_L]]: {
    fontSize: '14px',
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    fontSize: '12px',
  },
  lineHeight: 1,
  fontWeight: 'bold',
  color: palette.darkBlack.toString(),
});

const SettingWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '20px',
  marginTop: '16px',
});

const Input = styled.div({
  marginTop: '16px',
  listStyle: 'none',
  flex: 1,
});

const StatusText = styled.span({
  fontWeight: 'bold',
  fontSize: '14px',
});

const GCPSettings = ({ isEditMode, withGcp, withRtk, setProjectState }: Props) => {
  const [l10n]: UseL10n = useL10n();

  const { setProcessingAttachment } = useAttachmentsStore();

  const [rows, setRows]: UseState<RowValue[]> = useState<RowValue[]>([]);
  const [errors, setErrors]: UseState<InputErrors> = useState<InputErrors>({});
  const projectCoordinateSystem: T.CoordinateSystem = useProjectCoordinateSystem();
  const { byId, allIds } = useContentsStore(s => s.contents);

  const lastCreatedScreenGCPGroup: T.GCPGroupContent | undefined = useSelector((s: T.State) => {
    const lastCreatedScreenId: T.Screen['id'] | undefined = getLastCreatedScreenId(
      s.Screens.screens
    );
    if (lastCreatedScreenId === undefined) {
      return;
    }
    const gcpGroupId: T.GCPGroupContent['id'] | undefined = allIds.find(contentId => {
      const content: T.Content = byId[contentId];

      return content.type === T.ContentType.GCP_GROUP && content.screenId === lastCreatedScreenId;
    });
    if (gcpGroupId === undefined) {
      return;
    }
    return typeGuardGCPGroup(byId[gcpGroupId]);
  });
  const [gcps, setGCPs]: UseState<T.GCP[]> = useState<T.GCP[]>(() => {
    if (lastCreatedScreenGCPGroup !== undefined) {
      return lastCreatedScreenGCPGroup.info.gcps;
    }
    return [];
  });
  const [crs, setCRS]: UseState<T.CoordinateSystem> = useState<T.CoordinateSystem>(() => {
    if (lastCreatedScreenGCPGroup === undefined) {
      return projectCoordinateSystem;
    } else {
      return lastCreatedScreenGCPGroup.info.crs;
    }
  });
  const [crsTitles, setCRSTitles]: UseState<string[]> = useState<string[]>(
    getCoordinateTitles(crs)
  );
  const [usingGCPScreenId, setUsingGCPScreenId]: UseState<T.Screen['id'] | undefined> = useState<
    T.Screen['id'] | undefined
  >(lastCreatedScreenGCPGroup?.screenId);

  const [isGCPUsing, setIsGCPUsing]: UseState<boolean> = useState<boolean>(DEFAULT_IS_GCP_USING);

  const handleEnableGcp = () => {
    setProjectState(prev => {
      if (prev.projectConfig?.withGcp) {
        setProcessingAttachment(undefined);
      }
      return {
        ...prev,
        projectConfig: { withRtk: withRtk, withGcp: !withGcp },
      };
    });
  };

  const handleGCPError: (hasError: boolean) => void = useCallback(hasError => {
    setErrors(prevState => ({
      ...prevState,
      gcp: hasError,
    }));
  }, []);

  const getStatusIcon = (status: boolean) => {
    if (status) {
      return <StatusText>{l10n(Text.Yes)}</StatusText>;
    } else {
      return <StatusText>{l10n(Text.No)}</StatusText>;
    }
  };

  const info: ReactNode = isEditMode && (
    <TextWrapper>
      <InfoText>{l10n(Text.info)}</InfoText>
    </TextWrapper>
  );

  const gcpSetting: ReactNode = (
    <SettingWrapper>
      <SettingText>{l10n(Text.gcp)}</SettingText>
      {isEditMode ? (
        <ToggleSlider enabled={withGcp} onClick={handleEnableGcp} />
      ) : (
        <SettingText>{getStatusIcon(withGcp)}</SettingText>
      )}
    </SettingWrapper>
  );

  const handleRowChange = (rowValues: RowValue[]) => {
    setRows(rowValues);
  };

  const uploadGcp: ReactNode =
    withGcp && isEditMode ? (
      <Input>
        <GCPInput
          key="gcp"
          isGCPUsing={isGCPUsing}
          usingGCPScreenId={usingGCPScreenId}
          crsTitles={crsTitles}
          gcps={gcps}
          rows={rows}
          crs={crs}
          hasGCPError={errors.gcp}
          onUsingGCPChange={setIsGCPUsing}
          onUsingGCPScreenIdChange={setUsingGCPScreenId}
          onGCPTitlesChange={setCRSTitles}
          onGCPsChange={setGCPs}
          onRowsChange={handleRowChange}
          onCRSChange={setCRS}
          onError={handleGCPError}
          isAuto={true}
        />
      </Input>
    ) : null;

  return (
    <SettingsWrapper>
      {isEditMode && <MoreSettingsText>More Settings</MoreSettingsText>}
      <SettingsContentsWrapper isEditMode={isEditMode}>
        {info}
        {/* {rtkSetting} */}
        {gcpSetting}
        {uploadGcp}
      </SettingsContentsWrapper>
    </SettingsWrapper>
  );
};

export default memo(GCPSettings);
