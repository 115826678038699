import * as T from '^/types';

export default {
  title: {
    [T.Language.KO_KR]: '사진첩 선택',
    [T.Language.EN_US]: 'Select photo album',
  },
  emptyInfo: {
    [T.Language.KO_KR]: '포토 갤러리에 사진이 없습니다.',
    [T.Language.EN_US]: 'There are no photos in the photo gallery.',
  },
  info: {
    [T.Language.KO_KR]: '새로운 원본 사진을 업로드해주세요.',
    [T.Language.EN_US]: 'Please upload new source photos.',
  },
};
