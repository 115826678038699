import { useL10n } from '^/hooks';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Text from './text';

const OgTag = () => {
  const [l10n] = useL10n();
  // Not functioning as expected
  return (
    <Helmet>
      <meta property="og:title" content={l10n(Text.title)} />
      <meta property="og:description" content={l10n(Text.description)} />
      <meta property="og:url" content={metadata.appUrl} />
      <meta property="og:type" content={'website'} />
      <meta property="og:site_name" content="Angelswing" />
      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:site" content={metadata.twitter.site} />
      <meta name="twitter:title" content={l10n(Text.title)} />
      <meta name="twitter:description" content={l10n(Text.description)} />
      <meta name="twitter:image" content={metadata.images[0]} />
      <link rel="manifest" href={metadata.manifest} />
    </Helmet>
  );
};

export default OgTag;

const metadata = {
  appUrl: 'https://app.angelswing.io/',
  images: ['https://angelswing.io/og_image.png'],
  twitter: {
    card: 'summary_large_image',
    site: '@AngelswingKorea',
    title: 'Angelswing — Drone Data Platform',
    description:
      'Elevate your projects with our next-generation drone data platform from planning to execution. Build a Smarter, Safer Future with Angelswing',
  },
  manifest: 'https://app.angelswing.io/manifest.json',
};
