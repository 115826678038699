import * as T from '^/types';
import _ from 'lodash-es';
// Show point cloud measurements only when point cloud is selected
export const defaultMeasurementFilters = [
  T.ContentType.GROUP,
  ..._.difference(T.MeasurementContentTypes, T.PointCloudMeasurementContentTypes),
];
export const defaultThreeFilters = [T.ContentType.GROUP, ...T.PointCloudMeasurementContentTypes];
export const defaultESSFilters = [T.ContentType.GROUP, ...T.ESSContentTypes];
export const defaultOverlayFilters = [T.ContentType.GROUP, ...T.OverlayContentTypes];
export const defaultTerrainFilters = [T.ContentType.GROUP, ...T.TerrainContentTypes];

export const initialContentState: T.ContentsState = {
  contents: {
    byId: {},
    allIds: [],
  },
  contentFilters: {
    [T.ContentsListType.PERSONAL_MEASUREMENT]: {
      filterText: '',
      filterContents: defaultMeasurementFilters,
    },
    [T.ContentsListType.OPEN_MEASUREMENT]: {
      filterText: '',
      filterContents: defaultMeasurementFilters,
    },
    [T.ContentsListType.PERSONAL_OVERLAY]: {
      filterText: '',
      filterContents: defaultOverlayFilters,
    },
    [T.ContentsListType.OPEN_OVERLAY]: {
      filterText: '',
      filterContents: defaultOverlayFilters,
    },
    [T.ContentsListType.PERSONAL_ESS]: {
      filterText: '',
      filterContents: defaultESSFilters,
    },
    [T.ContentsListType.OPEN_ESS]: {
      filterText: '',
      filterContents: defaultESSFilters,
    },
    [T.ContentsListType.OPEN_TERRAIN]: {
      filterText: '',
      filterContents: defaultTerrainFilters,
    },
    [T.ContentsListType.OPEN_PRESENTATION]: {
      filterText: '',
      filterContents: [],
    },
  },
  measurement: {},
  uploadContents: {},
  outdatedVolumeIds: [],
  getContentsStatus: T.APIStatus.IDLE,
  getInitialContentsStatus: T.APIStatus.IDLE,
  getIssuesStatus: T.APIStatus.IDLE,
  postContentStatus: T.APIStatus.IDLE,
  deleteContentStatus: T.APIStatus.IDLE,
  patchContentStatus: T.APIStatus.IDLE,
  printMapStatus: T.APIStatus.IDLE,
  dxf2RasterStatus: T.APIStatus.IDLE,
  requestVolumeCalculation: {},
  requestMarkerElevationInfo: {},
  requestLengthElevationInfo: {},
  getLengthMetrics: {},
  getAreaSurface: {},

  requestLasDownSamplingStatus: T.APIStatus.IDLE,
  getContentDownloadablesStatus: T.APIStatus.IDLE,
  lasDownSamplingStatus: {},
  contentDownloadables: {},
  requestLasReprocessingStatus: T.APIStatus.IDLE,
  isDSMHillshadeMultiplyToggled: false,
  isDTMHillshadeMultiplyToggled: false,
  from2DTo3D360: {
    isFrom2DTo3D360: false,
    photoId: undefined,
  },
  dsmHeightsAndColors: [],
  dsmMinHeight: 0,
  dsmMaxHeight: 255,
  dtmHeightsAndColors: [],
  dtmMinHeight: 0,
  dtmMaxHeight: 255,
  getLonLatOn2D3DToggleStatus: T.APIStatus.IDLE,
};
