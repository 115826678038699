import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import Tippy from '@tippyjs/react';

import PhotoAlbum from '../PhotoAlbum';
import {
  PhotoWrapper,
  PhotoMonthHeader,
  PhotoContainer,
  PhotoListWrapper,
  PhotoDiv,
  PhotoRoot,
  Checkbox,
  PhotoDateSubHeader,
} from './style';
import * as T from '^/types';
import { PhotoItem } from '../PhotoAlbum/PhotoItem';
import { UseL10n, useL10n } from '^/hooks';
import Text from './text';
import { useDispatch, useSelector } from 'react-redux';
import { SetSelectedMedia } from '^/store/duck/Photos';
import { AlbumMapType } from '^/hooks/api/photos/services';
import { Photo360Item } from '../Photo/Photo360Item';
import { useParams } from 'react-router-dom';
import { usePhotoAlbumIdQuery, usePhotoQuery } from '^/hooks/api/photos/usePhoto';
import { PhotoRequestItem } from '../Photo/PhotoRequestItem';

interface PhotoListContentProps {
  groupedAlbums: AlbumMapType;
}

export const PhotoListContent: FC<PhotoListContentProps> = ({ groupedAlbums }) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch = useDispatch();
  const selectedMedia = useSelector((s: T.State) => s.Photos.selectedMedia);
  const { contentType, albumType, albumId, takenAt } = useParams();
  const paramType = contentType ?? albumType;
  const { rawPhotos, birdEyeLinkViewPointPhotos } = usePhotoQuery();
  const { photos: albumPhotos } = usePhotoAlbumIdQuery(Number(albumId));

  const isPhotoTypeThreeSixtyFamily =
    paramType === T.PhotoAlbumType.THREE_SIXTY ||
    paramType === T.PhotoAlbumType.THREE_SIXTY_STITCHED ||
    paramType === T.PhotoAlbumType.THREE_SIXTY_VSLAM;

  /** Memoized function to render a photo */
  const memoizedRenderPhoto = useMemo(
    () => (isSelected: boolean, photoDate: T.FinalPhoto) => {
      switch (paramType) {
        case T.PhotoAlbumType.THREE_SIXTY:
        case T.PhotoAlbumType.THREE_SIXTY_STITCHED:
        case T.PhotoAlbumType.THREE_SIXTY_VSLAM:
        case T.PhotoAlbumType.THREE_SIXTY_VIDEO:
          return (
            <PhotoDiv
              isSelected={selectedMedia.some(media => media.contentId === photoDate.id)}
              key={photoDate.id}
            >
              <Photo360Item isSelected={isSelected} photoDate={photoDate} />
            </PhotoDiv>
          );
        case T.PhotoAlbumType.VIEWPOINT: {
          const birdEyePhoto = birdEyeLinkViewPointPhotos.find(
            bird => bird.viewpointPhotoId === photoDate.id
          );
          return birdEyePhoto ? (
            <PhotoRequestItem
              birdEyePhoto={birdEyePhoto}
              isSelected={selectedMedia.some(media => media.contentId === birdEyePhoto.id)}
              photoDate={photoDate}
            />
          ) : null;
        }
        default:
          return (
            <PhotoDiv
              isSelected={selectedMedia.some(media => media.contentId === photoDate.id)}
              key={photoDate.id}
            >
              <PhotoItem isSelected={isSelected} photoDate={photoDate} />
            </PhotoDiv>
          );
      }
    },
    [paramType, selectedMedia, albumPhotos, rawPhotos, birdEyeLinkViewPointPhotos, groupedAlbums]
  );

  /** Select all photos for a date */
  const selectAllDatePhotos = useCallback(
    (checked: boolean, photoLists: Array<T.FinalPhoto | T.PhotoAlbum>) => {
      const temp = selectedMedia.slice();
      if (paramType === T.PhotoAlbumType.VIEWPOINT) {
        const photos = photoLists.map(photo => photo.id);
        const birdEyePhotos = birdEyeLinkViewPointPhotos.filter(bird =>
          photos.includes(bird.viewpointPhotoId!)
        );
        birdEyePhotos.forEach(bird => {
          if (checked && !temp.some(media => media.contentId === bird.id)) {
            temp.push({ type: bird.photoType ?? null, contentId: bird.id });
          } else if (!checked) {
            temp.splice(
              temp.findIndex(media => media.contentId === bird.id),
              1
            );
          }
        });
      } else {
        photoLists.forEach(photo => {
          if (checked && !temp.some(media => media.contentId === photo.id)) {
            if (!photo.count) {
              temp.push({ type: photo.photoType ?? null, contentId: photo.id });
            }
          } else if (!checked) {
            temp.splice(
              temp.findIndex(media => media.contentId === photo.id),
              1
            );
          }
        });
      }

      dispatch(SetSelectedMedia({ selectedMedia: temp }));
    },
    [selectedMedia, dispatch] // Added dispatch to dependencies
  );

  /** Auto-select all photos for 360 albums */
  useEffect(() => {
    if (isPhotoTypeThreeSixtyFamily) {
      const photosFromAlbums = albumId
        ? albumPhotos
        : rawPhotos.filter(
            photo =>
              photo.photoType === contentType &&
              photo.takenAt?.toISOString().split('T')[0] === takenAt
          );

      const selectAllPhotoIds = photosFromAlbums.map(photo => ({
        type: photo.photoType ?? null,
        contentId: photo.id,
      }));
      dispatch(SetSelectedMedia({ selectedMedia: selectAllPhotoIds }));
    }
  }, [isPhotoTypeThreeSixtyFamily, dispatch]); // Added dispatch to dependencies

  return (
    <>
      {[...groupedAlbums.entries()].map(([year, months]) => (
        <PhotoWrapper key={year}>
          {[...months.entries()].map(([month, days]) => (
            <React.Fragment key={month}>
              <PhotoMonthHeader>{month}</PhotoMonthHeader>
              {[...days.entries()].map(([day, dayAlbums]) => (
                <React.Fragment key={day}>
                  <PhotoContainer isSelected={false}>
                    <PhotoRoot>
                      <Tippy
                        offset={T.TIPPY_OFFSET}
                        theme="angelsw"
                        placement="top"
                        arrow={false}
                        content={l10n(Text.photo.selectMonthTooltip)}
                      >
                        <Checkbox
                          checked={dayAlbums.every((pht: { id: number }) =>
                            selectedMedia.some(media => media.contentId === pht.id)
                          )}
                          onChange={checked => selectAllDatePhotos(checked, dayAlbums)}
                          show={dayAlbums.some((pht: { id: number }) =>
                            selectedMedia.some(media => media.contentId === pht.id)
                          )}
                          isMonth={true}
                        />
                      </Tippy>
                      <PhotoDateSubHeader
                        isSelected={(paramType === T.PhotoAlbumType.VIEWPOINT
                          ? birdEyeLinkViewPointPhotos
                          : dayAlbums
                        ).some((pht: { id: number }) =>
                          selectedMedia.some(media => media.contentId === pht.id)
                        )}
                      >
                        {day}
                      </PhotoDateSubHeader>
                    </PhotoRoot>
                    <PhotoListWrapper>
                      {dayAlbums.map(album =>
                        album.count ? (
                          <PhotoAlbum key={album.id} photoAlbum={album as T.PhotoAlbum} />
                        ) : (
                          <Fragment key={album.id}>
                            {memoizedRenderPhoto(
                              selectedMedia.some(media => media.contentId === album.id),
                              album as T.FinalPhoto
                            )}
                          </Fragment>
                        )
                      )}
                    </PhotoListWrapper>
                  </PhotoContainer>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </PhotoWrapper>
      ))}
    </>
  );
};
