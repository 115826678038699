import React, {
  FC,
  memo,
  MouseEvent,
  ReactNode,
  SetStateAction,
  Dispatch as StateDispatch,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import ArrowSvg from '^/assets/icons/contents-list/arrow.svg';
import ContentsSidebarHeader from '^/components/molecules/ContentsSidebarHeader';
import { ContextMenuWrapper } from '^/components/molecules/ContextMenuWrapper';
import { CANCELLABLE_CLASS_NAME } from '^/components/molecules/CreatingVolumeClickEventHandler';
import { SidebarContextMenu } from '^/components/molecules/SidebarContextMenu';
import MapContentsList from '^/components/organisms/MapContentsList';
import SourcePhotoUpload from '^/components/organisms/SourcePhotoUpload';
import palette from '^/constants/palette';
import { MediaQuery, responsiveStyle } from '^/constants/styles';
import ContentsTabbar from '^/containers/molecules/ContentsTabbar';
import { defaultToastErrorOption, ErrorText, UseToast, useToast } from '^/hooks';
import { useSidebarResize } from '^/hooks/useSidebarResize';
import { useAnnotationStore } from '^/store/annotationStore';
import { ChangeSidebarStatus } from '^/store/duck/Pages';
import { usePresentationStore } from '^/store/presentationStore';
import { useServiceTabStore } from '^/store/serviceTabStore';
import { useSidebarStore } from '^/store/sidebarStore';
import { useUtilsStore } from '^/store/utilsStore';
import { groupStore, useGroupStore } from '^/store/zustand/groups/groupStore';
import * as T from '^/types';
import { getUserAgent } from '^/utilities/userAgent';
import { useShallow } from 'zustand/react/shallow';
import { EarthworkContents } from '../Dashboard/EarthworkDashboard';
import DroneStationContentsList from '../DroneStationContentsList';
import ESSContentsList from '../ESSContentsList';
import FlightPlanContentsList from '../FlightPlanContentsList';
import FlightScheduleContentsList from '../FlightScheduleContentsList';
import FlightScheduleCreation from '../FlightScheduleCreation';
import IssueContentsList from '../IssueContentsList';
import MeasurementContentsList from '../MeasurementContentsList';
import OverlayContentsList from '../OverlayContentsList';
import { ViewpointCaptureContentsList } from '../ViewpointCaptureContentsList';
import Text from './text';

export const SMALL_SIDEBAR_WIDTH: string = '60px';
export const PRESENTATION_SIDEBAR_WIDTH: string = '60px';

export const getSidebarWidth: (
  sidebarTab: T.ContentPageTabType,
  isSidebarOpened: boolean,
  sidebarWidth: number
) => CSSObject['width'] = (sidebarTab, isSidebarOpened, sidebarWidth) => {
  if (isSidebarOpened) {
    return '0px';
  }

  return sidebarTab === T.ContentPageTabType.PHOTO ? SMALL_SIDEBAR_WIDTH : `${sidebarWidth}px`;
};
interface SidebarProps {
  sidebarTab: T.ContentPageTabType;
  isSidebarOpened: boolean;
  sidebarWidth: number;
  isResizing: boolean;
}

const Root = styled.div<SidebarProps>(
  ({ sidebarTab, isSidebarOpened, sidebarWidth, isResizing }) => ({
    position: 'relative',
    top: 0,
    bottom: 0,
    left: `-${getSidebarWidth(sidebarTab, isSidebarOpened, sidebarWidth)}`,
    zIndex: 250, // Set zIndex of the sidebar > 200 (ContentPage), but <= 300 (the Popup backdrop)
    transition: !isResizing ? ' left 0.3s ease-in-out' : '',
    display: 'flex',
    flexDirection: 'column',
    width: sidebarTab === T.ContentPageTabType.PHOTO ? SMALL_SIDEBAR_WIDTH : sidebarWidth,
    height: '100%',
    [MediaQuery[T.Device.MOBILE_L]]: {
      width:
        sidebarTab === T.ContentPageTabType.PHOTO
          ? SMALL_SIDEBAR_WIDTH
          : responsiveStyle.sideBar[T.Device.MOBILE_L]?.width,
      left: isSidebarOpened ? '0' : `-${responsiveStyle.sideBar[T.Device.MOBILE_L]?.width}`,
    },
    [MediaQuery[T.Device.MOBILE_S]]: {
      width:
        sidebarTab === T.ContentPageTabType.PHOTO
          ? SMALL_SIDEBAR_WIDTH
          : responsiveStyle.sideBar[T.Device.MOBILE_S]?.width,
      left: isSidebarOpened ? '0' : `-${responsiveStyle.sideBar[T.Device.MOBILE_S]?.width}`,
    },

    backgroundColor: palette.SideBar.ContentslistBackground.toString(),
  })
);
Root.displayName = 'SidebarRoot';

const SidebarBody = styled.div<{ isSidebarOpened: boolean }>(({ isSidebarOpened }) => ({
  display: isSidebarOpened ? 'flex' : 'none',
  flexGrow: 1,
  width: '100%',
}));

const TabbarWrapper = styled.div({
  flexShrink: 0,
  zIndex: 250,
  width: responsiveStyle.tabBar[T.Device.DESKTOP]?.width,
});

const TabBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowX: 'hidden',
});

const ContentsListWrapper = styled.div<{ isCrossBrowserSolution?: boolean }>(
  ({ isCrossBrowserSolution }) => ({
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    overflowY: isCrossBrowserSolution ? undefined : 'hidden',
  })
);

const ContentListWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [MediaQuery[T.Device.MOBILE_L]]: {
    width: responsiveStyle.contentList[T.Device.MOBILE_L]?.width,
  },
  [MediaQuery[T.Device.MOBILE_S]]: {
    width: responsiveStyle.contentList[T.Device.MOBILE_S]?.width,
  },
});

interface SidebarToggleButtonProps {
  isSidebarOpened: boolean;
  isVisible: boolean;
}

export const SidebarToggleButton = styled.button<SidebarToggleButtonProps>(
  ({ isVisible, isSidebarOpened }) => ({
    display: isVisible ? 'block' : 'none',
    position: 'absolute',
    top: '50%',
    left: '100%',
    transform: 'translateY(-50%)',
    overflow: 'hidden',

    width: '25px',
    height: '38px',
    zIndex: 250,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: 'var(--color-theme-primary-lighter)',
    backdropFilter: 'blur(4px)',

    fontSize: '13px',
    lineHeight: '75px',
    textAlign: 'center',
    color: palette.textLight.toString(),

    cursor: 'pointer',

    '> svg': {
      transform: `rotate(${isSidebarOpened ? '90' : '-90'}deg) translate(${
        isSidebarOpened ? '-19px, 1px' : '19px, -1px'
      })`,
      ' > path': {
        fill: palette.white.toString(),
      },
    },
  })
);

interface ResizeHandleProps {
  isResizing: boolean;
}

const ResizeHandle = styled.div<ResizeHandleProps>`
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: ew-resize;
  z-index: 40;

  background-color: ${({ isResizing }) => (isResizing ? `${palette.borderGray}` : 'transparent')};

  &:hover {
    background-color: ${palette.borderGray.toString()};
  }
`;

const ContainerWithHeight = styled.div({
  height: '50px',
});

/**
 * Component for sidebar of contents page
 */
interface Props {
  setSidebarWidth: StateDispatch<SetStateAction<number>>;
  sidebarWidth: number;
  isADS?: boolean;
}
const ContentsSidebar: FC<Props> = ({ sidebarWidth, setSidebarWidth, isADS }) => {
  const dispatch: Dispatch = useDispatch();
  const toastify: UseToast = useToast();

  const selectedServiceTab = useServiceTabStore(s => s.selectedServiceTab);
  const isInPresentationMode = usePresentationStore(s => s.isInPresentationMode);
  const isAnnotationModeOpen = useAnnotationStore(s => s.isAnnotationModeOpen);
  const isGroupAlreadyDeleted = useGroupStore(s => s.isGroupAlreadyDeleted);
  const isCreatingContentOnDeletedGroup = useGroupStore(s => s.isCreatingContentOnDeletedGroup);
  const isResizing = useSidebarStore(s => s.isResizing);
  const setIsResizing = useSidebarStore(s => s.setIsResizing);

  const {
    isSidebarContextMenuOpen,
    contextMenuPosition,
    setIsSidebarContextMenuOpen,
    setContextMenuPosition,
    setContextMenuContentType,
    setContextMenuContentId,
    resetContextMenu,
  } = useUtilsStore(
    useShallow(s => ({
      isSidebarContextMenuOpen: s.isSidebarContextMenuOpen,
      contextMenuPosition: s.contextMenuPosition,
      setIsSidebarContextMenuOpen: s.setIsSidebarContextMenuOpen,
      setContextMenuPosition: s.setContextMenuPosition,
      setContextMenuContentType: s.setContextMenuContentType,
      setContextMenuContentId: s.setContextMenuContentId,
      resetContextMenu: s.resetContextMenu,
    }))
  );

  const userAgent: T.UserAgent = getUserAgent();
  const sidebarTab: T.ContentPageTabType = useSelector(
    (state: T.State) => state.Pages.Contents.sidebarTab
  );
  const isSidebarOpened: boolean = useSelector(
    (state: T.State) => state.Pages.Contents.showSidebar
  );
  const isInSourcePhotoUpload: boolean = useSelector(
    (state: T.State) => state.Pages.Contents.isInSourcePhotoUpload
  );
  const isInFlightScheduleCreation: boolean = useSelector(
    (state: T.State) => state.Pages.Contents.isInFlightScheduleCreation
  );
  const isToggleButtonVisible: boolean = useMemo(
    () =>
      sidebarTab !== T.ContentPageTabType.PHOTO &&
      !isInSourcePhotoUpload &&
      !isInFlightScheduleCreation &&
      !isInPresentationMode &&
      !isAnnotationModeOpen,
    [
      sidebarTab,
      isInSourcePhotoUpload,
      isInFlightScheduleCreation,
      isInPresentationMode,
      isAnnotationModeOpen,
    ]
  );

  // TODO: removing this temporarily
  const { startResizing } = useSidebarResize(setSidebarWidth);

  useEffect(() => {
    if (isCreatingContentOnDeletedGroup) {
      toastify({
        type: T.Toast.ERROR,
        content: {
          title: Text.MissingContentDetected.title,
          description: Text.MissingContentDetected.description,
        },
        option: defaultToastErrorOption,
      });
      groupStore.setState({ isCreatingContentOnDeletedGroup: false });
    }
  }, [isCreatingContentOnDeletedGroup]);

  useEffect(() => {
    if (isGroupAlreadyDeleted) {
      toastify({
        type: T.Toast.ERROR,
        content: {
          title: ErrorText.retryDeleteGroup.title,
          description: ErrorText.retryDeleteGroup.description,
        },
        option: defaultToastErrorOption,
      });
      groupStore.setState({ isGroupAlreadyDeleted: false });
    }
  }, [isGroupAlreadyDeleted]);

  const contentsList: ReactNode = useMemo(() => {
    switch (sidebarTab) {
      case T.ContentPageTabType.MEASUREMENT:
        return <MeasurementContentsList />;
      case T.ContentPageTabType.OVERLAY:
        return <OverlayContentsList />;
      case T.ContentPageTabType.MAP:
        return <MapContentsList />;
      case T.ContentPageTabType.ESS:
        return <ESSContentsList />;
      case T.ContentPageTabType.PHOTO:
        return null;
      case T.ContentPageTabType.VIEWPOINT_CAPTURE:
        return null;
      case T.ContentPageTabType.ISSUE:
        return <IssueContentsList />;
      case T.ContentPageTabType.DASHBOARD:
        return <EarthworkContents />;
      case T.ContentPageTabType.DRONE_STATION:
        return <DroneStationContentsList />;
      case T.ContentPageTabType.FLIGHT_PLAN:
        return <FlightPlanContentsList />;
      case T.ContentPageTabType.FLIGHT_SCHEDULE:
        return <FlightScheduleContentsList />;
      case T.ContentPageTabType.PRESENTATION:
        return null;
      default:
        return <MapContentsList />;
    }
  }, [sidebarTab]);

  const tabBar: ReactNode = useMemo(
    () => (
      <TabbarWrapper>
        <ContentsTabbar />
      </TabbarWrapper>
    ),
    []
  );

  const isDashboard = sidebarTab === T.ContentPageTabType.DASHBOARD;
  const hideHeader =
    (selectedServiceTab === T.ContentPageServiceTabType.FLIGHT || isDashboard) && isADS;

  const contentsListWithHeader: ReactNode = useMemo(
    () => (
      <ContentListWrapper>
        {hideHeader ? <ContainerWithHeight /> : !isDashboard ? <ContentsSidebarHeader /> : null}
        <TabBody>
          <ContentsListWrapper isCrossBrowserSolution={userAgent === T.UserAgent.SAFARI}>
            {contentsList}
          </ContentsListWrapper>
        </TabBody>
      </ContentListWrapper>
    ),
    [contentsList, userAgent, selectedServiceTab]
  );

  const sidebarWrapper: ReactNode = useMemo(() => {
    if (isInFlightScheduleCreation) {
      return (
        <ContentsListWrapper>
          <FlightScheduleCreation />
        </ContentsListWrapper>
      );
    }

    if (isInSourcePhotoUpload) {
      return (
        <ContentsListWrapper>
          <SourcePhotoUpload />
        </ContentsListWrapper>
      );
    }

    if (sidebarTab === T.ContentPageTabType.PHOTO) {
      return <>{tabBar}</>;
    }

    if (isInPresentationMode) {
      return null;
    }

    if (sidebarTab === T.ContentPageTabType.VIEWPOINT_CAPTURE) {
      return (
        <>
          {tabBar}
          {<ViewpointCaptureContentsList />}
        </>
      );
    }

    return (
      <>
        {tabBar}
        {contentsListWithHeader}
      </>
    );
  }, [
    contentsListWithHeader,
    sidebarTab,
    isInSourcePhotoUpload,
    isInFlightScheduleCreation,
    tabBar,
    selectedServiceTab,
  ]);

  const handleToggleClick: () => void = () =>
    dispatch(ChangeSidebarStatus({ open: !isSidebarOpened }));

  const handleContextMenu = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const tabsWithNoContextMenu = [T.ContentPageTabType.MAP, T.ContentPageTabType.ISSUE];
    if (tabsWithNoContextMenu.includes(sidebarTab)) {
      return;
    }
    const target = e.target as HTMLElement;
    const contentTypeAndId = target.closest<HTMLElement>(T.ContentIdentifier.SELECTOR);
    if (contentTypeAndId?.dataset?.content) {
      const [contentType, contentId] = contentTypeAndId.dataset.content.split('-');
      setContextMenuPosition({ top: e.clientY, left: e.clientX });
      setIsSidebarContextMenuOpen(true);
      setContextMenuContentType(contentType as 'content' | 'group');
      setContextMenuContentId(Number(contentId) as T.Content['id']);
    }
  };

  const handleCloseContextMenu = () => {
    resetContextMenu();
  };

  return (
    <>
      {isSidebarContextMenuOpen ? (
        <ContextMenuWrapper
          top={contextMenuPosition?.top || 0}
          left={contextMenuPosition?.left || 0}
          onClose={handleCloseContextMenu}
          partiallyVisibleStyles={{
            transform: 'translate(0%, -100%)',
          }}
        >
          <SidebarContextMenu />
        </ContextMenuWrapper>
      ) : null}
      <Root
        data-testid="sidebar-wrapper"
        sidebarTab={sidebarTab}
        isResizing={isResizing}
        isSidebarOpened={isSidebarOpened}
        sidebarWidth={sidebarWidth}
        onContextMenu={handleContextMenu}
      >
        <SidebarBody isSidebarOpened={isSidebarOpened}>{sidebarWrapper}</SidebarBody>
        {sidebarTab !== T.ContentPageTabType.PHOTO && (
          <ResizeHandle isResizing={isResizing} onMouseDown={startResizing} />
        )}
        <SidebarToggleButton
          data-testid="sidebar-toggle-button"
          className={CANCELLABLE_CLASS_NAME}
          isSidebarOpened={isSidebarOpened}
          onClick={handleToggleClick}
          isVisible={isToggleButtonVisible}
          onMouseDown={() => setIsResizing(true)}
          onMouseUpCapture={() => setIsResizing(false)}
        >
          <ArrowSvg />
        </SidebarToggleButton>
      </Root>
    </>
  );
};

export default memo(ContentsSidebar);
