import { union } from 'tsdux';

import { InitializeAttatchment } from './Attachments';
import { ChangePathBeforeAuth } from './Auth';
import { ChangeContents, ResetContentsAPIStatusInStore } from './Contents';
import { InitializeESSAttatchment } from './ESSAttachments';
import {
  ChangeContentsSidebarTab,
  ChangeCreatingVolume,
  ChangeCurrentContentTypeFromAnnotationPicker,
  ChangeEditingContent,
  ChangeIn3D,
  ChangeIsInContentsHistoryLogTable,
  ChangeIsInSourcePhotoUpload,
  ChangeIsInFlightScheduleCreation,
  ChangeIsTopBarShown,
  ChangeProjectId,
  ChangeSidebarStatus,
  ChangeTwoDDisplayMode,
  CloseContentPageMapPopup,
  CloseContentPagePopup,
} from './Pages/Content';
import { ChangeNewPasswordToken } from './Pages/Front';
import { ChangeEditingProject, ChangeProjectPageTab, CloseProjectPagePopup } from './Pages/Project';
import { GetPlanConfig } from './PlanConfig';
import { ChangeProjectConfig } from './ProjectConfig';
import { GetProject } from './Projects';
import { ResetScreensAPIStatusInStore } from './Screens';
import { ChangeShareToken, GetSharedContents } from './SharedContents';

import { config } from '^/config';
import * as T from '^/types';

// Redux actions
const TsduxAction = union([
  ChangeNewPasswordToken,

  ChangeContents,

  GetProject,

  ChangeShareToken,
  GetSharedContents,

  ChangeEditingContent,
  ChangeIn3D,
  ChangeProjectId,
  ChangeSidebarStatus,
  ChangeIsTopBarShown,
  ChangeTwoDDisplayMode,
  CloseContentPagePopup,

  ChangeCurrentContentTypeFromAnnotationPicker,
  ChangeCreatingVolume,

  ChangeIsInSourcePhotoUpload,
  ChangeIsInFlightScheduleCreation,
  ChangeIsInContentsHistoryLogTable,

  ChangeEditingProject,
  ChangeProjectPageTab,
  CloseProjectPagePopup,
  CloseContentPageMapPopup,

  InitializeAttatchment,
  InitializeESSAttatchment,

  ChangeProjectConfig,
  GetPlanConfig,

  ChangeContentsSidebarTab,

  ResetContentsAPIStatusInStore,
  ResetScreensAPIStatusInStore,

  ChangePathBeforeAuth,
]);
export type Action = typeof TsduxAction;

export const projectPageEnterActions: () => Action[] = () => [
  ChangeContents({ contents: [] }),
  ChangeProjectConfig({}),
  ChangeProjectId({}),
  ChangeProjectPageTab({ tab: T.ProjectPageTabType.LIST }),
];
export const projectPageManageEnterActions: (projectId: number) => Action[] = projectId => [
  GetProject({ projectId }),
  ChangeEditingProject({ projectId }),
  ChangeProjectPageTab({ tab: T.ProjectPageTabType.MANAGE }),
];
export const projectPageDroneStationsEnterActions: () => Action[] = () => [
  ChangeContents({ contents: [] }),
  ChangeProjectConfig({}),
  ChangeProjectId({}),
  ChangeProjectPageTab({ tab: T.ProjectPageTabType.DRONE_STATION }),
];
export const projectPageMypageEnterActions: () => Action[] = () => [
  ChangeProjectPageTab({ tab: T.ProjectPageTabType.MYPAGE }),
];
export const contentPageEnterActions: (projectId: number) => Action[] = projectId => [
  ChangeProjectConfig({}),
  ChangeProjectId({ projectId }),
  ChangeSidebarStatus({ open: true }),
  ChangeIsTopBarShown({ isOpened: true }),
  ChangeCurrentContentTypeFromAnnotationPicker({}),
  ChangeCreatingVolume({}),
  ChangeIsInSourcePhotoUpload({ isInSourcePhotoUpload: false }),
  ChangeIsInFlightScheduleCreation({ isInFlightScheduleCreation: false }),
  ChangeIsInContentsHistoryLogTable({ isInContentsEventLogTable: false }),
  CloseContentPageMapPopup(),
  // GetProject({ projectId }),
];
export const sharePageEnterActions: (shareToken: string) => Action[] = shareToken => [
  ChangeShareToken({ shareToken }),
  GetSharedContents(),
];
export const projectPageExitActions: () => Action[] = () => [
  CloseProjectPagePopup(),
  ChangePathBeforeAuth({ path: undefined }),
];
export const projectPageManageExitActions: () => Action[] = () => [
  ChangeEditingProject({ projectId: undefined }),
  ChangePathBeforeAuth({ path: undefined }),
];
export const projectPageMypageExitActions: () => Action[] = () => [];
export const contentPageExitActions: () => Action[] = () => [
  ChangeIn3D({ in3D: false }),
  ChangeTwoDDisplayMode({ twoDDisplayMode: T.TwoDDisplayMode.NORMAL }),
  ChangeEditingContent({}),
  ResetContentsAPIStatusInStore({}),
  ResetScreensAPIStatusInStore({}),
  CloseContentPagePopup(),
  InitializeAttatchment(),
  InitializeESSAttatchment(),
  ChangePathBeforeAuth({ path: undefined }),
];
export const sharePageExitActions: () => Action[] = () => [
  ChangeShareToken({}),
  ChangeContents({ contents: [] }),
  ChangePathBeforeAuth({ path: undefined }),
];

// Since the es are not completely separated, add the variable to compare with the old ESS.
// This variable must be deleted when the ESS is completely isolated.
const DDM_SUBDOMAIN_SUFFIX_DEV = '-legacy';
export const getDDMSubdomain: () => string | undefined = () => {
  // IE does not support location.hostname,
  // so use the regex as the fallback.
  const hostname: string =
    window.location.hostname || (/:\/\/([^/]+)/.exec(window.location.href)?.[1] ?? '');

  if (hostname.length === 0) {
    throw new Error(`Could not find hostname: ${hostname}`);
  }

  const hostnameSplits: string[] = hostname.split('.');

  // The URL pattern is [subdomain].angelswing.io,
  // which is why it has to be in three parts.
  if (hostnameSplits.length !== 3) {
    throw new Error(`Missing subdomain for ${hostname}`);
  }

  // In BE, the automatically-generated subdomain is appended with `-dev` for the dev environment.
  // It makes sense since technically it's sharing the same subdomain angelswing.io.
  // In order to make the code work, the subdomain had to be cleaned up here.

  if (config.buildLevel === T.BuildLevel.PRODUCTION) {
    return hostnameSplits[0];
  }

  const devSuffixIndex: number = hostnameSplits[0].lastIndexOf(DDM_SUBDOMAIN_SUFFIX_DEV);

  return devSuffixIndex === hostnameSplits[0].length - DDM_SUBDOMAIN_SUFFIX_DEV.length
    ? hostnameSplits[0].slice(0, devSuffixIndex)
    : hostnameSplits[0];
};
