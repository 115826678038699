import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import InfoBlueFilledIcon from '^/assets/icons/info-blue-filled.svg';
import CloseIcon from '^/assets/icons/close.svg';
import colorTokens from '^/theme/colors/color-tokens';

const ToastWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  backgroundColor: 'white',
  fontWeight: '700',
  width: '100%',
  fontFamily: 'pretendard, sans-serif',
});

const IconContainer = styled.div({
  fontSize: '24px',
  flexShrink: 0,
});

const ContentContainer = styled.div({
  flexGrow: 1,
});

const Title = styled.span({
  margin: 0,
  fontSize: '16px',
  fontWeight: 500,
  color: '#333',
});

const Message = styled.p({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: ' 150%',
  marginTop: '8px',
});

const CloseButton = styled.button({
  background: 'none',
  cursor: 'pointer',
  color: colorTokens.textGrayDarker,
  height: '26px',
  width: '26px',
  padding: '4px',
  borderRadius: '4px',
  alignItems: 'center',
  justifyContent: 'center',
  ':hover': {
    background: colorTokens.bgLightCoolGray,
  },
});

const TitleWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface CustomToastProps {
  title: string;
  message: string;
  toastId?: string;
  closeToast?(): void;
}

const CustomToast: React.FC<CustomToastProps> = ({ title, message, closeToast }) => (
  <ToastWrapper>
    <IconContainer>
      <InfoBlueFilledIcon />
    </IconContainer>
    <ContentContainer>
      <TitleWrapper>
        <Title>{title}</Title>
        <CloseButton onClick={closeToast}>
          <CloseIcon />
        </CloseButton>
      </TitleWrapper>
      <Message>{message}</Message>
    </ContentContainer>
  </ToastWrapper>
);

export default CustomToast;
