import _ from 'lodash-es';
import { useIssueStore } from '^/store/issue';
import * as T from '^/types';
import { store } from '..';
import { getRequestErrorType, makeV2APIURL } from '^/store/duck/API';
import { http } from '^/utilities/api';
import { APIToContent, RawPatchContent } from '^/store/duck/Contents';
import { createPatchUndoItem, UndoPatchItem } from './useUndoAction';
import { useUndoStore } from '^/store/undo';
import { ChangeContentsSidebarTab, OpenContentPagePopup } from '^/store/duck/Pages';
import { useParams } from 'react-router-dom';

interface PatchIssueContentProps {
  readonly content: PatchIssueContentBody;
  readonly isUndoable?: boolean;
  readonly undoTimeStamp?: Date;
}

export type PostContentArguments =
  | 'title'
  | 'color'
  | 'type'
  | 'info'
  | 'screenId'
  | 'isPersonal'
  | 'photoId'
  | 'info'
  | 'groupId';

export interface PostIssueContentBody {
  title: string;
  description: string;
  type: T.ContentType.ISSUE_POINT | T.ContentType.ISSUE_PHOTO;
  priority: T.IssuePriority;
  info: T.IssueContent['info'];
  issueStatusId: T.IssueContent['issueStatusId'];
  photoId?: T.IssueContent['photoId'];
}

export interface PatchIssueContentBody extends RawPatchContent {
  description?: string;
  priority?: T.IssuePriority;
  info?: Partial<T.IssueContent['info']>;
  issueCategoryId?: T.IssueContent['issueCategoryId'];
  issueStatusId?: T.IssueContent['issueStatusId'];
  dueDate?: T.IssueContent['dueDate'];
}

export const useIssueContents = () => {
  const state = store.getState();
  const dispatch = store.dispatch;

  const params = useParams();
  const projectId = params.id;
  const lastSelectedScreenId = state.ProjectConfigPerUser.config?.lastSelectedScreenId;
  const {
    issueStatusOptions,
    issuesContentsById,
    issuesContentAllIds,
    setIssuesContentsById,
    setIssuesContentAllIds,
    setEditingIssueContentId,
    setDeletingIssueContentId,
  } = useIssueStore();

  const patchIssueContentSelection = (content: T.IssueContent) => {
    setIssuesContentsById({ ...issuesContentsById, [content.id]: content });
  };

  const postAndEditIssueContent = (content: Pick<T.IssueContent, PostContentArguments>) => {
    if (!projectId || !lastSelectedScreenId) {
      return;
    }
    const URL: string = makeV2APIURL('projects', projectId, 'issues');
    const initIssueStatusId = issueStatusOptions[0].id;
    const body: PostIssueContentBody = {
      ...content,
      description: '',
      type: content.type,
      priority: T.IssuePriority.CRITICAL,
      issueStatusId: initIssueStatusId,
    };
    http
      .post(URL, body)
      .then(resp => {
        const issueContent = APIToContent(resp.data.data) as T.IssueContent;
        const updatedIssueContent = {
          ...issueContent,
          config: {
            ...issueContent.config,
            type: issueContent.type,
            selectedAt: new Date(),
          },
        } as T.IssueContent;
        setIssuesContentAllIds([...issuesContentAllIds, issueContent.id]);
        setIssuesContentsById({ ...issuesContentsById, [issueContent.id]: updatedIssueContent });
        dispatch(ChangeContentsSidebarTab({ sidebarTab: T.ContentPageTabType.ISSUE }));
        setEditingIssueContentId(issueContent.id);
      })
      // send this error to sentry
      .catch(error => console.log(error));
  };

  const getIssueContents = async () => {
    if (!projectId) {
      setIssuesContentsById({});
      setIssuesContentAllIds([]);
      return;
    }
    const URL: string = makeV2APIURL('projects', projectId, 'issues');
    const response = await http.get(URL);
    const issues = response.data.data.map(
      (content: T.APIIssueContent) =>
        APIToContent({
          ...content,
          category: T.ContentCategory.ISSUE,
          config: {
            ...content.config,
            selectedAt: undefined,
            type: content.config?.type as T.ContentType.ISSUE_POINT,
          },
        }) as T.IssueContent
    );
    const issueContents = issues?.reduce(
      (acc: Record<number, T.IssueContent>, issue: T.IssueContent) => {
        acc[issue.id as number] = issue;
        return acc;
      },
      {} as Record<number, T.IssueContent>
    );
    if (issueContents) {
      setIssuesContentsById(issueContents);
      const issueContentAllIds = Object.keys(issueContents).map(Number);
      setIssuesContentAllIds(issueContentAllIds);
    }
  };

  const patchIssueContent = ({ content, isUndoable, undoTimeStamp }: PatchIssueContentProps) => {
    const issueContentsByIds = useIssueStore.getState().issuesContentsById;
    const URL: string = makeV2APIURL('issues', content.id);
    const oldContent = issueContentsByIds[content.id];

    const newInfo: T.IssueContent['info'] = {
      ...(oldContent?.info ? oldContent.info : {}),
      ...(content.info as T.IssueContent['info']),
    };

    const newContent: T.IssueContent = {
      ...oldContent,
      ...content,
      info: newInfo,
    } as T.IssueContent;

    const appearAt: Date | undefined = content.appearAt;
    const appearAtToServer: string | undefined = appearAt
      ? `${appearAt.getFullYear()}/${appearAt.getMonth() + 1}/${appearAt.getDate()}`
      : undefined;

    const body: Overwrite<
      PatchIssueContentBody,
      {
        readonly appearAt?: string;
      }
    > = {
      ...newContent,
      pinEventAt: content.pinEventAt,
      appearAt: appearAtToServer,
      title: content.title,
      screenId: content.screenId,
    };

    Object.keys(body).forEach(key => {
      if (body[key as keyof PatchIssueContentBody] === undefined) {
        /**
         * @todo remove following `delete`
         */
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete body[key as keyof PatchIssueContentBody];
      }
    });

    let undoItem: UndoPatchItem | undefined;

    if (isUndoable) {
      undoItem = createPatchUndoItem({
        content: { ..._.pick(oldContent, _.keys(content)), id: oldContent.id },
        category: oldContent.category,
      });
      useUndoStore.getState().pushUndoItem(undoItem);
    }
    http
      .put(URL, body)
      .then(resp => {
        const issueContent = APIToContent(resp.data.data) as T.IssueContent;
        const updatedIssueContent = {
          ...issueContent,
          config: {
            ...issueContent.config,
            type: issueContent.type,
            selectedAt: new Date(),
          },
        } as T.IssueContent;
        // if (undoTimeStamp) {
        //   const filterr = useUndoStore.getState().undoUpdatesById[content.id];
        //   return undoTimeStamp === filterr ? true : false;
        // } else {
        //   return true;
        // }
        if (undoTimeStamp) {
          patchIssueContent({
            content: {
              ...issueContent,
              config: {
                ...issueContent.config,
                selectedAt: new Date(),
              },
            },
          });
        }
        setEditingIssueContentId(issueContent.id);
        dispatch(ChangeContentsSidebarTab({ sidebarTab: T.ContentPageTabType.ISSUE }));
        setIssuesContentsById({ ...issueContentsByIds, [issueContent.id]: updatedIssueContent });
      })
      // send this error to sentry
      .catch(error => console.log(error));
  };
  const deleteIssueContent = ({ contentId }: { contentId?: string | number }) => {
    if (!contentId) {
      return;
    }
    const issueContentsByIds = useIssueStore.getState().issuesContentsById;
    const URL: string = makeV2APIURL('issues', contentId);

    http
      .delete(URL)
      .then(() => {
        const { [contentId]: deletedIssue, ...remainingIssueItems } = issueContentsByIds;
        const issueContentAllIds = Object.keys(remainingIssueItems);
        setDeletingIssueContentId(undefined);
        setIssuesContentAllIds(issueContentAllIds);
        setIssuesContentsById(remainingIssueItems);
      })
      .catch(respError => {
        const error = getRequestErrorType(respError.response);
        if (error === T.HTTPError.CLIENT_UNAUTHORIZED_ERROR) {
          dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
        }
      });
  };

  const addAssignee = ({ issueId, user }: { issueId: string | number; user: T.IssueAssignee }) => {
    const oldContent = issuesContentsById[issueId];
    if (!oldContent) {
      return;
    }
    const URL: string = makeV2APIURL('issues', issueId, 'assign');
    const body = { user_id: user.id };
    http
      .post(URL, body)
      .then(() => {
        const updatedIssue = { ...oldContent, assignees: [...oldContent.assignees, user] };
        setIssuesContentsById({ ...issuesContentsById, [oldContent.id]: updatedIssue });
      })
      .catch(err => console.log(err));
  };
  const removeAssignee = ({
    issueId,
    user,
  }: {
    issueId: string | number;
    user: T.IssueAssignee;
  }) => {
    const oldContent = issuesContentsById[issueId];
    if (!oldContent) {
      return;
    }
    const URL: string = makeV2APIURL('issues', issueId, 'unassign');
    const body = { user_id: user.id };
    http
      .post(URL, body)
      .then(() => {
        const updatedIssue = {
          ...oldContent,
          assignees: oldContent.assignees.filter(assignee => assignee.id !== user.id),
        };
        setIssuesContentsById({
          ...issuesContentsById,
          [oldContent.id]: updatedIssue,
        });
      })
      .catch(err => console.log(err));
  };

  return {
    addAssignee,
    removeAssignee,
    getIssueContents,
    patchIssueContent,
    deleteIssueContent,
    postAndEditIssueContent,
    patchIssueContentSelection,
  };
};

export default useIssueContents;
