/* eslint-disable max-lines */
import Color from 'color';
import { autobind } from 'core-decorators';
import _ from 'lodash-es';
import React, {
  Component,
  Fragment,
  ReactNode,
  RefObject,
  SyntheticEvent,
  createRef,
  MouseEvent as ReactMouseEvent,
} from 'react';
import styled, { CSSObject } from 'styled-components';

import palette from '^/constants/palette';
import FaIcon from '^/components/atoms/FaIcon';

import * as T from '^/types';
import dsPalette from '^/constants/ds-palette';
import RawPlusSvg from '^/assets/icons/plus.svg';
import { l10n } from '^/utilities/l10n';

import Text from './text';
import Scrollbars from 'react-custom-scrollbars-2';

export interface CategoryOption {
  text: string;
  value: T.IssueCategory;
}

export const createOption: (value: T.IssueCategory) => CategoryOption = value => ({
  text: value.label,
  value,
});

export const createOptions: (values: T.IssueCategory[]) => CategoryOption[] = values =>
  values.map(value => createOption(value));

const borderWidth: number = 1;

interface RootProps {
  readonly rootWidth?: string;
  readonly rootHeight?: string;
}
const Root = styled.div<RootProps>(({ rootWidth, rootHeight }) => ({
  flexShrink: 0,
  width: rootWidth ?? '100%',
  height: rootHeight ?? '30px',
}));

interface WrapperProps {
  readonly zIndex: number;
  readonly centerPos?: string;
  readonly nonFaFontSize?: string;
}
const Wrapper = styled.div<WrapperProps>(({ zIndex, centerPos, nonFaFontSize }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  color: palette.textLight.toString(),

  '& *': {
    lineHeight: centerPos ?? '28px',
    color: palette.textLight.toString(),

    ':not(.fa)': {
      fontSize: nonFaFontSize ?? '12px',
    },
  },

  zIndex,
}));

interface MainButtonProps {
  readonly mainButtonStyle?: CSSObject;
  readonly error?: boolean;
}
const MainButton = styled.button<MainButtonProps>(
  {
    display: 'flex',
    justifyContent: 'space-between',

    boxSizing: 'border-box',
    width: '100%',
    height: '100%',

    borderWidth: `${borderWidth}px`,
    borderStyle: 'solid',
    borderColor: palette.border.toString(),
    borderRadius: '5px',

    backgroundColor: palette.white.toString(),

    textAlign: 'left',
    cursor: 'pointer',
  },
  ({ error, mainButtonStyle }) => {
    const buttonStyle: CSSObject =
      mainButtonStyle !== undefined
        ? {
            ...mainButtonStyle,
            '&&&': mainButtonStyle,
          }
        : {};

    return {
      ...buttonStyle,
      borderColor: (error ? palette.error : palette.border).toString(),
    };
  }
);

const SearchIconWrapper = styled.span({
  marginLeft: '10px',
});

interface ValueWrapperProps {
  readonly centerPos?: string;
}
const ValueWrapper = styled.span<ValueWrapperProps>(({ centerPos }) => ({
  marginLeft: '9px',
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  pointerEvents: 'none',

  lineHeight: centerPos ?? '28px',
}));

const ValueEditor = styled.input({
  marginLeft: '9px',
  flexGrow: 1,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  backgroundColor: 'transparent',
});

interface CaretProps {
  readonly caretStyle?: CSSObject;
}

const Caret = styled.i.attrs({
  className: 'fa fa-caret-down',
})<CaretProps>(
  {
    marginRight: '9px',

    fontSize: '10px',
    color: `${Color('black').toString()}`,
  },
  ({ caretStyle }) =>
    caretStyle !== undefined
      ? {
          ...caretStyle,
          '&&&': caretStyle,
        }
      : {}
);

const Paper = styled.div({
  position: 'absolute',

  boxSizing: 'border-box',
  top: '100%',
  width: '100%',

  borderWidth: `${borderWidth}px`,
  borderStyle: 'solid',
  borderColor: palette.border.toString(),
  borderRadius: '5px',

  backgroundColor: palette.white.toString(),

  overflow: 'auto',
});

interface MenuProps {
  readonly menuStyle?: CSSObject;
  readonly height?: CSSObject['height'];
}
export const Menu = styled.ul.attrs(() => ({
  'data-testid': 'dropdown-menu',
}))<MenuProps>({}, ({ menuStyle, height }) =>
  menuStyle !== undefined
    ? {
        ...menuStyle,
        '&&&': menuStyle,
        height,
      }
    : { height }
);

interface MenuWrapperProps {
  isLimitExceed: boolean;
}

export const MenuWrapper = styled.div<MenuWrapperProps>(({ isLimitExceed }) => ({
  boxSizing: 'border-box',
  width: 'calc(100% + 2px)',
  /**
   * Due to compatibility of react scrollbars,
   * I needed to make the item-count criterium and use fixed height.
   */
  height: isLimitExceed ? '182px' : '',
  marginLeft: '-1px',
  borderRadius: '0 0 5px 5px',
  borderLeft: `1px solid ${palette.border.toString()}`,
  borderRight: `1px solid ${palette.border.toString()}`,
  borderBottom: `1px solid ${palette.border.toString()}`,
}));

const ItemCSS: CSSObject = {
  display: 'block',

  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  paddingLeft: '10px',

  fontWeight: 300,

  textAlign: 'left',
  backgroundColor: palette.white.toString(),

  cursor: 'pointer',
};

const ItemLabel = styled.li.attrs({
  'data-testid': 'dropdown-item-label',
})<ItemProps>({
  ...ItemCSS,
  fontWeight: 500,
  pointerEvents: 'none',
});

interface ItemProps {
  readonly itemStyle?: CSSObject;
}

const Item = styled.li.attrs({
  'data-testid': 'dropdown-item',
})<ItemProps>(
  {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    height: '26px',
    padding: '4px 10px',

    textAlign: 'left',
    color: palette.textLight.toString(),
    borderBottom: `1px solid ${palette.ContentsList.inputBorder.toString()}`,

    cursor: 'pointer',
    ':hover': {
      color: '#737373',
      backgroundColor: '#E1E1E1',
    },
    ':last-of-type': {
      border: 'none',
    },
  },
  ({ itemStyle }) =>
    itemStyle !== undefined
      ? {
          ...itemStyle,
          '&&&': itemStyle,
        }
      : {}
);

interface AddCategoryProps {
  horizontalPadding: string;
  labelVerticalPadding: string;
}

const AddCategoryWrapper = styled.div<{ horizontalPadding: string }>(({ horizontalPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  paddingBottom: '16px',
  paddingLeft: horizontalPadding,
  paddingRight: horizontalPadding,
}));

const AddCategoryLabel = styled.span<{ labelVerticalPadding: string }>(
  ({ labelVerticalPadding }) => ({
    color: '#999',
    fontSize: '9.5px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: labelVerticalPadding,
    paddingBottom: labelVerticalPadding,
  })
);

const PlusSvg = styled(RawPlusSvg)({});

const AddCategoryButton = styled.button({
  all: 'unset',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '6px',

  boxSizing: 'border-box',
  width: '100%',
  height: '32px',

  border: `1px solid ${dsPalette.themePrimary.toString()}`,
  borderRadius: '7px',
  cursor: 'pointer',

  color: dsPalette.themePrimary.toString(),
  fill: dsPalette.themePrimary.toString(),
  backgroundColor: dsPalette.categorySelect.toString(),
  ':not([disabled]):hover': {
    color: palette.white.toString(),
    fill: palette.white.toString(),
    backgroundColor: dsPalette.themePrimary.toString(),
  },
  ':disabled': {
    cursor: 'not-allowed',
  },
});

const itemLabelValue: string = '[]';

interface StyleProps
  extends RootProps,
    WrapperProps,
    MainButtonProps,
    CaretProps,
    MenuProps,
    ItemProps,
    AddCategoryProps {}
export interface Props extends StyleProps {
  readonly valueId: T.IssueCategory['id'];
  readonly options: CategoryOption[];
  readonly placeHolder: string;
  readonly className?: string;
  readonly error?: boolean;
  readonly isSearchEnable?: boolean;
  readonly trackAction?: string;
  readonly trackLabel?: string;
  readonly language: T.Language;
  onClick(option: CategoryOption, index: number): void;
  onAddCategory(newCategory: string): void;
}

export interface State {
  readonly isOpen: boolean;
  readonly keyword: string;
  readonly filteredOptions: CategoryOption[];
}

/**
 * Dropdown element
 */
class Dropdown extends Component<Props, State> {
  private readonly rootRef: RefObject<HTMLDivElement>;

  public constructor(props: Props) {
    super(props);
    this.rootRef = createRef();
    this.state = {
      isOpen: false,
      keyword: '',
      filteredOptions: props.options,
    };
  }

  // Register callback for outside click for closing dropdown
  public componentDidMount(): void {
    document.addEventListener('click', this.handleDocumentClick, false);
    document.addEventListener('touchend', this.handleDocumentClick, false);
  }

  // UnRegister callback for outside click for closing dropdown
  public componentWillUnmount(): void {
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  }

  public componentDidUpdate({ options: prevOptions }: Readonly<Props>): void {
    const { options }: Props = this.props;
    if (!_.isEqual(prevOptions, options)) {
      this.setState({ filteredOptions: options });
    }
  }

  @autobind
  private optionToElement(option: CategoryOption, index: number): ReactNode {
    const onClick: (event: SyntheticEvent) => void = event =>
      this.handleItemClick(event, option, index);

    const item: ReactNode =
      option.value.label === itemLabelValue ? (
        <ItemLabel>{option.text}</ItemLabel>
      ) : (
        <Item
          className={option.value.id === this.props.valueId ? 'active' : undefined}
          itemStyle={this.props.itemStyle}
          onClick={onClick}
        >
          {option.text}
        </Item>
      );

    return <Fragment key={index}>{item}</Fragment>;
  }

  @autobind
  private handleDocumentClick({ target }: MouseEvent): void {
    const rootRef: HTMLDivElement | null = this.rootRef.current;

    if (rootRef !== null && !rootRef.contains(target as Node) && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  @autobind
  private handleButtonClick(e: ReactMouseEvent): void {
    if (this.state.isOpen && this.state.keyword.length > 0 && e.nativeEvent.detail === 0) {
      return;
    }
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  }

  @autobind
  private handleSearchChange(event: SyntheticEvent<HTMLInputElement>): void {
    const keyword: string = _.toLower(event.currentTarget.value.trim());

    /**
     * @info dirty trick to solve the AutoFill problem from Browsers
     */
    const matches: RegExpMatchArray | null = keyword.match(/[a-zA-Z\s]+/gi);
    if (keyword.length > 0 && matches && matches.length > 1) {
      return;
    }

    const hasKeyword: (option: CategoryOption) => boolean = ({ text, value }) =>
      _.toLower(text).indexOf(keyword) > -1 || _.toLower(value.toString()).indexOf(keyword) > -1;
    const filteredOptions: CategoryOption[] =
      keyword.length === 0 ? this.props.options : this.props.options.filter(hasKeyword);
    this.setState({
      keyword: event.currentTarget.value,
      filteredOptions,
    });
  }

  @autobind
  private handleItemClick(event: SyntheticEvent, option: CategoryOption, index: number): void {
    this.setState({
      isOpen: false,
    });
    this.props.onClick(option, index);

    /**
     * @desc this preventDefault help the Dropdown react correctly when put it inside of a Label
     */
    event.preventDefault();
  }
  @autobind
  private createCategory() {
    const duplicateKeyword = this.props.options.find(({ text }) => text === this.state.keyword);
    if (duplicateKeyword === undefined) {
      this.props.onAddCategory(this.state.keyword);
      this.setState({
        isOpen: false,
        keyword: '',
        filteredOptions: this.props.options,
      });
    }
  }
  public render(): ReactNode {
    const { keyword, filteredOptions, isOpen }: State = this.state;
    const { options, isSearchEnable }: Props = this.props;

    const selectedOption: CategoryOption | undefined = options.find(
      ({ value }) => value.id === this.props.valueId
    );
    const selectedValue: string =
      selectedOption === undefined ? this.props.placeHolder : selectedOption.text;

    const isLimitExceed = filteredOptions.length > 7;
    const menu: ReactNode = isOpen ? (
      <MenuWrapper isLimitExceed={isLimitExceed}>
        {isLimitExceed ? (
          <Scrollbars>
            <Menu menuStyle={this.props.menuStyle} height={this.props.height}>
              {filteredOptions.map(this.optionToElement)}
            </Menu>
          </Scrollbars>
        ) : (
          <Menu menuStyle={this.props.menuStyle} height={this.props.height}>
            {filteredOptions.map(this.optionToElement)}
          </Menu>
        )}
      </MenuWrapper>
    ) : null;
    const duplicateKeyword = options.find(({ text }) => text === this.state.keyword);
    const addCategory: ReactNode = isOpen ? (
      <AddCategoryWrapper horizontalPadding={this.props.horizontalPadding}>
        <AddCategoryLabel labelVerticalPadding={this.props.labelVerticalPadding}>
          {l10n(Text.createCategoryLabel(this.state.keyword), this.props.language)}
        </AddCategoryLabel>
        <AddCategoryButton onClick={this.createCategory} disabled={Boolean(duplicateKeyword)}>
          <PlusSvg />
          {this.state.keyword}
        </AddCategoryButton>
      </AddCategoryWrapper>
    ) : null;

    const dropdownPaper: ReactNode = isOpen ? (
      <Paper>
        {menu}
        {addCategory}
      </Paper>
    ) : null;

    const searchIcon: ReactNode = isSearchEnable ? (
      <SearchIconWrapper>
        <FaIcon faNames="search" fontSize="15px" data-testid="dropdown-search-icon" />
      </SearchIconWrapper>
    ) : undefined;

    const valueViewer: ReactNode =
      isOpen && isSearchEnable ? (
        <ValueEditor
          value={keyword}
          autoFocus={true}
          onChange={this.handleSearchChange}
          data-testid="dropdown-value-input"
        />
      ) : (
        <ValueWrapper data-testid="dropdown-value" centerPos={this.props.centerPos}>
          {selectedValue}
        </ValueWrapper>
      );

    return (
      <Root
        className={this.props.className}
        ref={this.rootRef}
        data-ddm-track-action={this.props.trackAction}
        data-ddm-track-label={this.props.trackLabel}
        rootWidth={this.props.rootWidth}
        rootHeight={this.props.rootHeight}
        onKeyDown={event => {
          if (this.state.isOpen && event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            this.createCategory();
          }
        }}
      >
        <Wrapper
          zIndex={this.props.zIndex + (isOpen ? 1 : 0)}
          centerPos={this.props.centerPos}
          nonFaFontSize={this.props.nonFaFontSize}
        >
          <MainButton
            mainButtonStyle={this.props.mainButtonStyle}
            error={this.props.error}
            onClick={this.handleButtonClick}
            type="button"
            data-testid="dropdown-mainbutton"
          >
            {searchIcon}
            {valueViewer}
            <Caret caretStyle={this.props.caretStyle} data-testid="dropdown-caret" />
          </MainButton>
          {dropdownPaper}
        </Wrapper>
      </Root>
    );
  }
}
export default Dropdown;
