import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { isErrorIgnorable } from './http-response';

export interface TryCatchOutput<T> {
  data: T | null;
  error: Error | null;
}

/**
 * Abstraction to make async actions easier.
 */
export async function tryCatch<T>(promise: Promise<T>): Promise<TryCatchOutput<T>> {
  try {
    const data: T = await promise;

    return { data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error) || !isErrorIgnorable(error.response?.status)) {
      Sentry.captureException(error);
    }

    return { error, data: null };
  }
}

export async function delayedResolve(time: number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('Resolved');
    }, time);
  });
}
