import { useThreeStore } from '^/components/three/ThreeStore';
import { useDispatch, useSelector } from 'react-redux';
import * as T from '^/types';
import { SyntheticEvent } from 'react';
import { isImageGeoLocated, isImageGeoReferenced } from '^/utilities/image';
import {
  SetCurrentPhotoId,
  SetHoveredPhotoId,
  SetHoveredVideoId,
  SetPhotoOnMapId,
  SetSelectedMedia,
  SetVideoOnMapId,
} from '^/store/duck/Photos';
import { Mesh, MeshBasicMaterial, SphereGeometry } from 'three';
import { PhotoAlbumObject } from '^/components/three/ThreeObjects';
import { PhotoThreeSixtyObject } from '^/components/three/ThreeObjects/Photo';
import { isVideo } from '^/components/molecules/PhotoList/util';
import { VideoAlbumObject } from '^/components/three/ThreeObjects/Video';
import { ChangeTwoDDisplayCenter } from '^/store/duck/Pages';

const usePhotoActions = (photoDate: T.Photo, isSelected: boolean) => {
  const viewer = useThreeStore(s => s.viewer);
  const cameraControls = useThreeStore(s => s.cameraControls);
  const runtime = useThreeStore(s => s.runtime);

  const { selectedMedia } = useSelector((s: T.State) => s.Photos);
  const dispatch = useDispatch();

  const photoAlbumAnimation = (): boolean => {
    let photoObject;
    if (isVideo(photoDate.fullUrl)) {
      photoObject = viewer?.controllers.photoAlbumController?.getObjectByName(
        `video_${photoDate.id.toString()}`
      );
    } else {
      photoObject = viewer?.controllers.photoAlbumController?.getObjectByName(
        photoDate.id.toString()
      );
    }
    if (!photoObject) {
      return false;
    }

    if (photoObject instanceof PhotoThreeSixtyObject) {
      const object = photoObject;
      viewer?.controllers.photoAlbumController?.setThreeSixtyPhoto(object);
      return true;
    } else {
      const object = photoObject as PhotoAlbumObject | VideoAlbumObject;
      viewer?.controllers.photoAlbumController?.setPhotoObject(object);
      return true;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePhotoListItemClick = (
    photoId: T.Photo['id'],
    photoType?: T.PhotoType,
    photoStatus?: T.Photo['status'],
    isThreeDMeshAvailable?: boolean
  ) => {
    const updatedSelectedPhotoIds = () => {
      const updatedSelectedPhotosList = isSelected
        ? selectedMedia.filter(selected => selected.contentId !== photoDate.id)
        : [...selectedMedia, { contentId: photoDate.id, type: photoDate.photoType ?? null }];
      dispatch(SetSelectedMedia({ selectedMedia: updatedSelectedPhotosList }));
    };

    if (isImageGeoReferenced(photoDate) && viewer) {
      if (selectedMedia.some(media => media.contentId === photoDate.id)) {
        photoAlbumAnimation();
      } else {
        const temp = isSelected
          ? selectedMedia.filter(selected => selected.contentId !== photoDate.id)
          : [...selectedMedia, { contentId: photoDate.id, type: photoDate.photoType ?? null }];
        dispatch(SetSelectedMedia({ selectedMedia: temp }));
        updatedSelectedPhotoIds();
        setTimeout(() => {
          photoAlbumAnimation();
        }, 500);
      }
    } else {
      if (
        (photoType === 'three_sixty' ||
          photoType === 'three_sixty_stitched' ||
          photoType === 'three_sixty_vslam') &&
        photoStatus !== 'pending' &&
        isImageGeoLocated(photoDate) &&
        isThreeDMeshAvailable
      ) {
        if (selectedMedia.some(media => media.contentId === photoDate.id)) {
          photoAlbumAnimation();
        } else {
          updatedSelectedPhotoIds();
          setTimeout(() => {
            photoAlbumAnimation();
          }, 500);
        }
      } else {
        dispatch(SetCurrentPhotoId({ photoId }));
      }
    }
  };

  const handlePhotoOnMapId: (
    event: SyntheticEvent<HTMLDivElement, MouseEvent>
  ) => void = async event => {
    event.stopPropagation();
    if (viewer) {
      event.stopPropagation();
      if (!photoDate.latitude || !photoDate.longitude || !photoDate.altitude) {
        return;
      }

      if (!isImageGeoReferenced(photoDate) && !photoDate.geotags?.absoluteAltitude === null) {
        return;
      }

      if (!isSelected) {
        dispatch(
          SetSelectedMedia({
            selectedMedia: [
              ...selectedMedia,
              { contentId: photoDate.id, type: photoDate.photoType ?? null },
            ],
          })
        );
      }
      void viewer.controllers.photoAlbumController?.exit();
      const center = runtime!.getPositionFromLatLongHeight({
        long: photoDate.longitude,
        lat: photoDate.latitude,
        height: photoDate.altitude,
      });
      const sphere = new Mesh(
        new SphereGeometry(15, 32, 16),
        new MeshBasicMaterial({ color: 0xffff00 })
      );
      sphere.position.set(center.x, center.y, center.z);
      await cameraControls?.rotateTo(0, 0);
      await cameraControls?.fitToSphere(sphere, true);
      sphere.geometry.dispose();
      sphere.material.dispose();
      setTimeout(() => {
        let photoObject;
        if (isVideo(photoDate.fullUrl)) {
          photoObject = viewer?.controllers.photoAlbumController?.getObjectByName(
            `video_${photoDate.id.toString()}`
          );
        } else {
          photoObject = viewer?.controllers.photoAlbumController?.getObjectByName(
            photoDate.id.toString()
          );
        }
        if (photoObject instanceof PhotoAlbumObject || photoObject instanceof VideoAlbumObject) {
          viewer.controllers.photoAlbumController?.children.forEach(node => {
            if (node instanceof PhotoAlbumObject || node instanceof VideoAlbumObject) {
              node.setUnHighlight();
            }
          });
          viewer.controllers.photoAlbumController!.hoverObject = photoObject;
          photoObject.setHighlight();
        }
      }, 200);
    } else {
      if (!isSelected) {
        dispatch(
          SetSelectedMedia({
            selectedMedia: [
              ...selectedMedia,
              { contentId: photoDate.id, type: photoDate.photoType ?? null },
            ],
          })
        );
      }

      if (isVideo(photoDate.fullUrl)) {
        dispatch(SetVideoOnMapId({ videoId: photoDate.id }));
        dispatch(SetHoveredVideoId({ hoveredVideoId: photoDate.id }));
      } else {
        dispatch(SetPhotoOnMapId({ photoId: photoDate.id }));
        dispatch(SetHoveredPhotoId({ hoveredId: photoDate.id }));
      }

      const { longitude: lon, latitude: lat }: T.Photo = photoDate;
      if (!lat || !lon) {
        return;
      }
      //   view.setCenter(fromLonLat([lon, lat]));
      dispatch(ChangeTwoDDisplayCenter({ twoDDisplayCenter: [lon, lat] }));
    }
  };

  return {
    handlePhotoListItemClick,
    handlePhotoOnMapId,
    photoAlbumAnimation,
  };
};

export default usePhotoActions;
