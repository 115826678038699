import React from 'react';
import SelectedPlaceholder from './SelectedPlaceholder';
import { AttachDropdownWrapper, Wrapper } from './FinalEarthWorkSelectAttachment';
import { useL10n } from '^/hooks';
import * as T from '^/types';
import Text from './text';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import useEarthWorkFile from '^/hooks/earthwork/useEarthWorkFile';
import styled from 'styled-components';
import { usePopupStore } from '^/store/popup';

const DropdownWrapper = styled(AttachDropdownWrapper)({
  height: '40px',
  width: '100%',
  textAlign: 'left',
  marginTop: '10px',
});

const InitialSurveySelectAttachment = () => {
  const [l10n] = useL10n();
  const { setMinorPopup } = usePopupStore();

  const { initialSurvey, setInitialSurvey } = useEarthWorkStore();
  const { dxfOptions, sourcePhotosOption } = useEarthWorkFile();

  const handleInitialSurveyPopup = () => {
    setMinorPopup(T.ContentPageMinorPopupType.SELECT_INTIAL_SURVEY);
  };

  const onDeleteClick = () => {
    setInitialSurvey(undefined);
  };

  let title: string = '';
  if (initialSurvey) {
    if (initialSurvey.dxfId) {
      title = `DXF ( ${
        dxfOptions.find(option => option.value === initialSurvey.dxfId?.toString())?.text ?? ''
      } )`;
    } else if (initialSurvey.uploadedDXF) {
      title = initialSurvey.uploadedDXF?.name ?? '';
    } else {
      title = `SOURCE_PHOTO ( ${
        sourcePhotosOption.find(option => option.value === initialSurvey.sourcePhotoId?.toString())
          ?.text ?? ''
      } )`;
    }
  }

  return (
    <Wrapper>
      <DropdownWrapper>
        {initialSurvey ? (
          <SelectedPlaceholder textValue={title} onDeleteClick={onDeleteClick} onClick={() => {}} />
        ) : (
          <SelectedPlaceholder
            onClick={handleInitialSurveyPopup}
            placeHolderText={l10n(Text.intialSurvey.placeHolder)}
          />
        )}
      </DropdownWrapper>
    </Wrapper>
  );
};

export default InitialSurveySelectAttachment;
