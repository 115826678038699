/* eslint-disable max-lines */
import Tippy from '@tippyjs/react';
import { useFlag } from '@unleash/proxy-client-react';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

// Components
import LoadingIcon from '^/components/atoms/LoadingIcon';
import olWrap from '^/components/atoms/OlWrap';
import PhotoUploadButton from '^/components/atoms/PhotoUploadButton';
import { SidebarToggleButton } from '^/components/organisms/ContentsSidebar';
import { PhotoInspectionHeader } from '../PhotoInspectionList';
import { PhotoTopBar } from '../PhotoTopBar';
import PhotoViewer from '../PhotoViewer';
import ThreeSixtyMiniMap from '../ThreeSixtyMiniMap';
import TimeLineScrollBar from '../TimelineScrollBar';
import Text from './text';

// Icons
import ArrowSvg from '^/assets/icons/contents-list/arrow.svg';
import BackArrowSvg from '^/assets/icons/photo/back-arrow-black.svg';
import CalendarEditSvg from '^/assets/icons/photo/calendar-edit.svg';
import DeleteSvg from '^/assets/icons/photo/delete-button.svg';
import DownloadSvg from '^/assets/icons/photo/download.svg';
import PlusSvg from '^/assets/icons/presentation/plus-icon-small.svg';
import CloseSVG from '^/components/molecules/Notification/close-thinner.svg';

// Hooks and utilities
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { useL10n, UseL10n, useRole } from '^/hooks';
import {
  useDeleteBulkPhotos,
  usePhotoAlbumIdQuery,
  usePhotoAlbumQuery,
  usePhotoQuery,
} from '^/hooks/api/photos/usePhoto';
import { useSidebarStore } from '^/store/sidebarStore';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import { isAllowToUpload } from '^/utilities/role-permission-check';
import { useDownloadImages } from '^/utilities/useDownloadImages';

// Actions and state
import { ChangeSidebarStatus, OpenContentPagePopup } from '^/store/duck/Pages';
import { SetCurrentPhotoId, SetSelectedMedia } from '^/store/duck/Photos';

// Constants and types
import palette from '^/constants/palette';
import * as T from '^/types';

// Styles
import {
  FlexBox,
  FlexBoxWrapper,
  HeaderWrapper,
  NoDateSubText,
  PhotoHeader,
  PhotoHeaderWrapper,
  PhotoListRoot,
  PhotoUploadButtonWrapper,
  PreviousIcon,
  PreviousIconWrapper,
  SelectedTextElement,
  SelectWrapper,
  SvgWrapper,
  TabButton,
  TabContainer,
  TabText,
  TopIconsWrapper,
} from '../PhotoList/style';
import { PhotoCollectionFilter } from './PhotoCollectionFilter';
import dsPalette from '^/constants/ds-palette';
import { usePopupStore } from '^/store/popup';

// Constants
export const MIN_WIDTH_PX = 466;
export const MAX_WIDTH_PX = 1200;
export const previousWidth: number | null = null;

// Types
interface SliderProps {
  readonly sliderPosition: number;
}

interface ResizeHandleProps {
  isResizing: boolean;
}

interface SvgButtonProps {
  text: string;
  icon: ReactNode;
  onClick(): void;
  show: boolean;
  disabled?: boolean;
}

export interface Props {
  readonly zIndex?: number;
}

// Styled components
const Root = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  transitionProperty: 'all',
  transitionDuration: '0.3s',
  transitionTimingFunction: 'linear',
});

const PhotosRoot = styled.div({
  flexGrow: 1,
  overflow: 'auto',
  paddingRight: '35px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none', // For Firefox
});

const PhotoWrapper = styled.div<{
  isSidebarOpen: boolean;
  photoAlbumSidebarWidth: number;
}>(({ photoAlbumSidebarWidth, isSidebarOpen }) => ({
  zIndex: 240,
  flexShrink: 0,
  height: '100%',
  background: 'white',
  position: 'absolute',
  minWidth: `${MIN_WIDTH_PX}px`,
  transition: 'left 0.3s ease-in-out',
  width: `${photoAlbumSidebarWidth}px`,
  left: isSidebarOpen ? `0px` : `-${photoAlbumSidebarWidth}px`,
}));

const SliderBar = styled.div.attrs<SliderProps>(({ sliderPosition }) => ({
  'data-testid': 'OlTwoDSlider-SliderBar',
  style: {
    left: `${sliderPosition}px`,
    zIndex: 299,
  },
}))<SliderProps>({
  position: 'absolute',
  top: '0',
  bottom: '0',
  transform: 'translateX(-50%)',
  width: '1px',
  pointerEvents: 'auto',
});

const ResizeHandle = styled.div<ResizeHandleProps>`
  top: 0;
  right: 0;
  width: 5px;
  z-index: 40;
  height: 100%;
  cursor: ew-resize;
  position: absolute;
  background-color: ${({ isResizing }) => (isResizing ? `${palette.borderGray}` : 'transparent')};
  &:hover {
    background-color: ${palette.borderGray.toString()};
  }
`;

const PhotoTimeLineWrapper = styled.div<{ isPhotoPage?: boolean }>(({ isPhotoPage }) => ({
  display: 'flex',
  flexGrow: 1,
  paddingTop: isPhotoPage ? '50px' : '',
}));

const TimelapsePhotoInspectionHeader = styled(PhotoInspectionHeader)({
  paddingBottom: '0px',
  width: '300px',
});

const FilterWrapper = styled.div({
  position: 'sticky',
  top: '0px',
  //   width: '100%',
  //   height: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-end',
  gap: '10px',
  flexWrap: 'wrap',
  padding: '20px',
  backgroundColor: dsPalette.white.toString(),
  zIndex: 1,
});

const IconWrapper = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  opacity: disabled ? 0.6 : 1,
}));

export const PhotoAlbumLayout: FC<Props> = olWrap(() => {
  // Hooks
  const dispatch = useDispatch();
  const role: T.PermissionRole = useRole();
  const [l10n]: UseL10n = useL10n();
  const navigate = useNavigate();
  const { contentType, albumId, screenId, albumType } = useParams();
  const { pathname } = useLocation();
  const isPhotoAlbumNewFeaturesEnabled = useFlag(T.FEATURE_FLAGS.PHOTO_ALBUM_NEW_FEATURES);

  const paramPhotoType = contentType ?? albumType;

  // Zustand stores
  const { photoAlbumSidebarWidth, setPhotoAlbumSidebarWidth } = useSidebarStore(s => ({
    photoAlbumSidebarWidth: s.photoAlbumSidebarWidth,
    setPhotoAlbumSidebarWidth: s.setPhotoAlbumSidebarWidth,
  }));
  const is360MiniMapView = useThreePhotoAlbumStore(s => s.is360MiniMapView);
  const sourcePhotos = useThreePhotoAlbumStore(s => s.sourcePhotos);
  const { timelapseInspectionImages, setTimelapseInspectionImages, setSelectedCollectionId } =
    useTimelapseStore(s => ({
      timelapseInspectionImages: s.timelapseInspectionImages,
      setSelectedCollectionId: s.setSelectedCollectionId,
      setTimelapseInspectionImages: s.setTimelapseInspectionImages,
    }));

  // Feature flags
  const isVideoOverlayFeatureAvailable = useFlag(T.FEATURE_FLAGS.VIDEO_OVERLAY);

  // Path detection
  const lastPath = pathname.split('/');
  const isNonDatePhotoPath = lastPath[lastPath.length - 1] === T.PhotoPathType.NO_DATE;
  const isLocationPath = pathname.endsWith(`/${T.PhotoPathType.LOCATION}`);
  const isAlbumPath = pathname.includes(`/${T.PhotoPathType.ALBUM}`);
  const isTimelinePath = pathname.endsWith(`/${screenId}`);
  const isTimelapse = pathname.endsWith(`/${T.PhotoPathType.TIMELAPSE}`);

  // State
  const [isPhotoAlbumSidebarResizing, setIsPhotoAlbumSidebarResizing] = useState(false);
  const [photoWrapperRef, setPhotoWrapperRef] = useState<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);
  const [photoSize, setPhotoSize] = useState<'sm' | 'md'>('sm');

  // Redux selectors
  const selectedMedia: T.SelectedMedia[] = useSelector((s: T.State) => s.Photos.selectedMedia);
  const isIn3D = useSelector((state: T.State) => state.Pages.Contents.in3D);
  const currentPhotoId = useSelector((s: T.State) => s.Photos.currentPhotoId);
  const isSidebarOpened = useSelector((state: T.State) => state.Pages.Contents.showSidebar);
  const isDownloading = useSelector((s: T.State) => s.Photos.isDownloading);

  const isVideosSelected = selectedMedia.some(media => media.type === T.ContentCategory.OVERLAY);

  // zustand store selectors
  const setPopup = usePopupStore(s => s.setMinorPopup);

  // Data fetching
  const { rawPhotos, rawVideos, nonDatePhotos } = usePhotoQuery();
  // For prefetching albums, there's a better way we will do it later on technical debt
  usePhotoAlbumQuery();
  const { photos: photosFromAlbum } = usePhotoAlbumIdQuery(Number(albumId));
  const deleteBulkPhotosMutation = useDeleteBulkPhotos(Number(albumId));

  // Derived data
  const droneVideos: T.FinalPhoto[] = isVideoOverlayFeatureAvailable
    ? rawVideos
    : rawVideos.filter(video => video.type !== T.ContentCategory.OVERLAY);
  const finalPhotos: T.FinalPhoto[] = [...rawPhotos, ...droneVideos];
  const { handleDownloadImages } = useDownloadImages(
    albumId ? photosFromAlbum : rawPhotos.concat(...droneVideos)
  );

  // Event handlers
  const handleOnSetCurrentPhotoId = useCallback(
    (photoId?: number) => {
      dispatch(SetCurrentPhotoId({ photoId }));
    },
    [dispatch]
  );

  const handleResetPhotoItems = useCallback(() => {
    dispatch(SetSelectedMedia({ selectedMedia: [] }));
  }, [dispatch]);

  const handleOnChangeDate = useCallback(() => {
    if (currentPhotoId) {
      const item = selectedMedia.find(media => media.contentId === currentPhotoId);
      if (item) {
        dispatch(
          SetSelectedMedia({
            selectedMedia: [item],
          })
        );
        dispatch(
          OpenContentPagePopup({
            popup: T.ContentPagePopupType.TAG_DATE_POPUP,
          })
        );
      }
    }
  }, [currentPhotoId, selectedMedia, dispatch]);

  const handleAddAlbum = () => {
    setPopup(T.ContentPageMinorPopupType.UPLOAD_TO_ALBUM);
  };

  const handleTagPhotos = useCallback(() => {
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.TAG_DATE_POPUP }));
  }, [dispatch]);

  const handleDeletePhotos = useCallback(() => {
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.DELETE_PHOTO }));
  }, [dispatch]);

  const handleDownload = useCallback(async () => {
    await handleDownloadImages(selectedMedia);
  }, [selectedMedia, handleDownloadImages]);

  const handleToggleClick = useCallback(() => {
    dispatch(ChangeSidebarStatus({ open: !isSidebarOpened }));
  }, [dispatch, isSidebarOpened]);

  const previousButtonClick = useCallback(() => {
    if (isAlbumPath) {
      navigate(T.PhotoPathType.ALBUM);
    } else {
      navigate(T.PhotoPathType.TIMELINE);
    }
    dispatch(SetSelectedMedia({ selectedMedia: [] }));
  }, [navigate, dispatch, isAlbumPath]);

  const handleUploadPopupOpen = useCallback(() => {
    if (!isAllowToUpload(role)) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
      return;
    }

    const photoType = paramPhotoType === T.PhotoAlbumType.DRONE ? 'drone' : undefined;

    dispatch(
      OpenContentPagePopup({
        popup: T.ContentPagePopupType.PHOTO_UPLOAD,
        photoType,
        hideBackButton: true,
      })
    );
  }, [role, isIn3D, dispatch, paramPhotoType]);

  const changeTab = useCallback(
    (path: T.PhotoPathType) => {
      dispatch(SetSelectedMedia({ selectedMedia: [] }));
      navigate(path);
    },
    [dispatch, navigate]
  );

  const exitInspectionMode = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      navigate(-1);
      handleResetPhotoItems();
      setSelectedCollectionId(undefined);
      setTimelapseInspectionImages([]);
    },
    [navigate, handleResetPhotoItems, setSelectedCollectionId, setTimelapseInspectionImages]
  );

  // Resize handlers
  const handleSliderPointerMoveOnMap = useCallback(
    (event: any): void | boolean => {
      let clientX: number | undefined;
      event.preventDefault();
      event.stopPropagation();

      switch (event.type) {
        case 'touchmove':
          clientX = (event as TouchEvent).targetTouches[0].clientX;
          break;
        case 'mousemove':
          clientX = (event as MouseEvent).clientX;
          break;
        default:
          return true;
      }

      const newWidth = clientX;
      if (clientX <= MIN_WIDTH_PX || newWidth >= MAX_WIDTH_PX) {
        return true;
      }

      if (newWidth < 650) {
        setPhotoSize('sm');
      } else {
        setPhotoSize('md');
      }

      document.documentElement.style.cursor = 'col-resize';
      setPhotoAlbumSidebarWidth(newWidth);
    },
    [setPhotoAlbumSidebarWidth]
  );

  const handleSliderPointerUp = useCallback(() => {
    setIsPhotoAlbumSidebarResizing(false);
    document.documentElement.style.cursor = 'default';

    document.removeEventListener('mouseup', handleSliderPointerUp);
    document.removeEventListener('mousemove', handleSliderPointerMoveOnMap);
    document.removeEventListener('touchstart', handleSliderPointerUp);
    document.removeEventListener('touchmove', handleSliderPointerMoveOnMap);
  }, [handleSliderPointerMoveOnMap]);

  const handleSliderPointerDown = useCallback(() => {
    setIsPhotoAlbumSidebarResizing(true);

    document.addEventListener('mouseup', handleSliderPointerUp);
    document.addEventListener('mousemove', handleSliderPointerMoveOnMap);
    document.addEventListener('touchstart', handleSliderPointerUp);
    document.addEventListener('touchmove', handleSliderPointerMoveOnMap);
  }, [handleSliderPointerUp, handleSliderPointerMoveOnMap]);

  // Photo list ref handler
  const handlePhotoListRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        const newHeight = window.innerHeight - node.getBoundingClientRect().y - 20;
        node.style.height = `${newHeight}px`;
        setHeight(newHeight);
        setPhotoWrapperRef(node);

        const savedScrollTop = sessionStorage.getItem('photoScrollPosition');
        if (savedScrollTop) {
          node.scrollTop = Number(savedScrollTop);
        }

        const handleScroll = () => {
          sessionStorage.setItem('photoScrollPosition', node.scrollTop.toString());
        };

        node.addEventListener('scroll', handleScroll);

        // Instead of returning the cleanup function directly,
        // we should handle cleanup in a useEffect
      }
    },
    [paramPhotoType, isTimelapse, isAlbumPath, selectedMedia]
  );

  // Add this useEffect to handle the cleanup
  useEffect(
    () =>
      // This effect will run when photoWrapperRef changes
      () => {
        if (photoWrapperRef) {
          // Clean up the scroll event listener when the component unmounts
          const handleScroll = () => {
            sessionStorage.setItem('photoScrollPosition', photoWrapperRef.scrollTop.toString());
          };
          photoWrapperRef.removeEventListener('scroll', handleScroll);
        }
      },
    [photoWrapperRef]
  );

  // Helper functions
  const getPhotoAlbumTitle = useCallback((): string => {
    if (isNonDatePhotoPath) {
      return l10n(Text.noDate);
    }

    switch (paramPhotoType) {
      case T.PhotoAlbumType.THREE_SIXTY:
        return l10n(Text.header.threeSixtyMode);
      case T.PhotoAlbumType.THREE_SIXTY_SOURCE:
        return l10n(Text.header.threeSixtySourceMode);
      case T.PhotoAlbumType.THREE_SIXTY_STITCHED:
        return l10n(Text.header.threeSixtyStitchedMode);
      case T.PhotoAlbumType.THREE_SIXTY_VIDEO:
        return l10n(Text.header.threeSixtyVideoMode);
      case T.PhotoAlbumType.THREE_SIXTY_VSLAM:
        return l10n(Text.header.threeSixtyVslamMode);
      case T.PhotoAlbumType.INSPECTION:
        return l10n(Text.header.droneMode);
      case T.PhotoAlbumType.SOURCE:
        return l10n(Text.header.sourcePhoto);
      case T.PhotoAlbumType.VIEWPOINT:
        return l10n(Text.header.viewpoint);
      default:
        return l10n(Text.header.title);
    }
  }, [l10n, isNonDatePhotoPath, paramPhotoType]);

  const SvgButton = useCallback(
    ({ text, icon, onClick, show, disabled }: SvgButtonProps): ReactNode => {
      if (!show) {
        return null;
      }

      function handleClick() {
        if (disabled) {
          return;
        }
        onClick();
      }

      return (
        <IconWrapper onClick={handleClick} disabled={disabled}>
          <Tippy
            offset={T.TIPPY_OFFSET}
            theme="angelsw"
            placement="top"
            arrow={false}
            content={text}
          >
            <SvgWrapper>{icon}</SvgWrapper>
          </Tippy>
        </IconWrapper>
      );
    },
    []
  );

  // Effects
  useEffect(() => {
    if (previousWidth) {
      const newWidth = previousWidth;
      if (previousWidth <= MIN_WIDTH_PX || newWidth >= MAX_WIDTH_PX) {
        return;
      }
      document.documentElement.style.cursor = 'col-resize';
      setPhotoAlbumSidebarWidth(newWidth);
    }
  }, [setPhotoAlbumSidebarWidth]);

  useEffect(() => {
    if (!photosFromAlbum.length) {
      sessionStorage.removeItem('inspectionScrollPosition');
    }
  }, [photosFromAlbum]);

  // Component rendering
  const getPhotos = () => {
    if (paramPhotoType === T.PhotoAlbumType.VIEWPOINT) {
      return finalPhotos;
    }
    if (paramPhotoType === T.PhotoAlbumType.SOURCE) {
      return sourcePhotos;
    }
    if (albumId) {
      return photosFromAlbum;
    }
    if (timelapseInspectionImages.length > 0) {
      return timelapseInspectionImages;
    }
    {
      return finalPhotos;
    }
  };

  const renderPhotoViewer = () => (
    <PhotoViewer
      photos={getPhotos()}
      currentPhotoId={currentPhotoId}
      onSetCurrentPhotoId={handleOnSetCurrentPhotoId}
      onDelete={handleDeletePhotos}
      onChangeDate={handleOnChangeDate}
    />
  );

  const renderTabs = () => {
    if (paramPhotoType || isTimelapse || isNonDatePhotoPath) {
      return null;
    }

    return (
      <TabContainer>
        <TabButton
          onClick={() => changeTab(T.PhotoPathType.TIMELINE)}
          isSelected={isTimelinePath}
          isSurvey={false}
        >
          <TabText>{l10n(Text.drone.userText)}</TabText>
        </TabButton>
        <TabButton
          onClick={() => changeTab(T.PhotoPathType.LOCATION)}
          isSelected={isLocationPath}
          isSurvey={false}
        >
          <TabText>{l10n(Text.drone.timelapse)}</TabText>
        </TabButton>
        {isPhotoAlbumNewFeaturesEnabled && (
          <TabButton
            onClick={() => changeTab(T.PhotoPathType.ALBUM)}
            isSelected={isAlbumPath}
            isSurvey={false}
          >
            <TabText>{l10n(Text.drone.album)}</TabText>
          </TabButton>
        )}
      </TabContainer>
    );
  };

  const renderPhotoTopBar = () => {
    if (nonDatePhotos.length === 0 || !isTimelinePath) {
      return null;
    }

    return <PhotoTopBar selectedPhotos={nonDatePhotos} size={photoSize} />;
  };

  const renderSelectionBar = () => {
    if (!selectedMedia.length || isTimelapse) {
      return null;
    }

    return (
      <SelectWrapper
        slidePanelWidth={photoAlbumSidebarWidth}
        isSelected={Boolean(selectedMedia.length)}
      >
        <FlexBox slidePanelWidth={photoAlbumSidebarWidth}>
          {SvgButton({
            onClick: handleResetPhotoItems,
            text: l10n(Text.photo.deselectToolTip),
            icon: <CloseSVG />,
            show: true,
          })}
          <SelectedTextElement>
            {l10n(Text.photo.itemSelectedTooltip(selectedMedia.length))}
          </SelectedTextElement>
        </FlexBox>
        <TopIconsWrapper>
          {isPhotoAlbumNewFeaturesEnabled &&
            SvgButton({
              text: isVideosSelected
                ? l10n(Text.photo.videosCannotBeAdded)
                : l10n(Text.photo.addToAlbum),
              icon: <PlusSvg />,
              onClick: handleAddAlbum,
              show: paramPhotoType !== T.PhotoAlbumType.VIEWPOINT,
              disabled: isVideosSelected,
            })}
          {SvgButton({
            text: l10n(Text.photo.editDatesTooltip),
            icon: <CalendarEditSvg />,
            onClick: handleTagPhotos,
            show:
              isTimelinePath ||
              isNonDatePhotoPath ||
              paramPhotoType === T.PhotoAlbumType.INSPECTION ||
              paramPhotoType === T.PhotoAlbumType.DRONE,
          })}
          {SvgButton({
            onClick: isDownloading ? () => {} : handleDownload,
            text: isDownloading
              ? l10n(Text.photo.DownloadProgressTooltip)
              : l10n(Text.photo.DownloadTooltip),
            icon: isDownloading ? <LoadingIcon isDownloading={true} /> : <DownloadSvg />,
            show: true,
          })}
          {SvgButton({
            text: l10n(Text.photo.deleteTooltip),
            icon: deleteBulkPhotosMutation.isLoading ? (
              <LoadingIcon isDownloading={true} />
            ) : (
              <DeleteSvg />
            ),
            onClick: deleteBulkPhotosMutation.isLoading ? () => {} : handleDeletePhotos,
            show:
              paramPhotoType !== T.PhotoAlbumType.SOURCE &&
              paramPhotoType !== T.PhotoAlbumType.VIEWPOINT,
          })}
        </TopIconsWrapper>
      </SelectWrapper>
    );
  };

  const renderHeader = () => {
    if (isTimelapse) {
      return (
        <TimelapsePhotoInspectionHeader onClick={exitInspectionMode}>
          <BackArrowSvg /> {l10n(Text.goBack)}
        </TimelapsePhotoInspectionHeader>
      );
    }

    return (
      <>
        {isNonDatePhotoPath || paramPhotoType ? (
          <PreviousIconWrapper onClick={previousButtonClick}>
            <PreviousIcon />
          </PreviousIconWrapper>
        ) : null}
        <HeaderWrapper>
          <PhotoHeaderWrapper>
            <PhotoHeader>{getPhotoAlbumTitle()}</PhotoHeader>
            {rawPhotos.length > 0 &&
            !isLocationPath &&
            (isTimelinePath || paramPhotoType === T.PhotoAlbumType.DRONE) ? (
              <PhotoUploadButtonWrapper>
                <PhotoUploadButton
                  photoSize={photoSize}
                  isIn3D={isIn3D}
                  onClick={handleUploadPopupOpen}
                />
              </PhotoUploadButtonWrapper>
            ) : null}
          </PhotoHeaderWrapper>
          {isNonDatePhotoPath && <NoDateSubText>{l10n(Text.noDateSubText)}</NoDateSubText>}
        </HeaderWrapper>
      </>
    );
  };

  return (
    <Root>
      {!is360MiniMapView && (
        <PhotoWrapper
          isSidebarOpen={isSidebarOpened}
          photoAlbumSidebarWidth={photoAlbumSidebarWidth}
        >
          {!currentPhotoId && (
            <SliderBar sliderPosition={photoAlbumSidebarWidth}>
              <SidebarToggleButton
                isVisible={true}
                onClick={handleToggleClick}
                isSidebarOpened={isSidebarOpened}
                data-testid="sidebar-toggle-button"
              >
                <ArrowSvg />
              </SidebarToggleButton>
              {paramPhotoType !== T.PhotoAlbumType.VIEWPOINT && (
                <ResizeHandle
                  isResizing={isPhotoAlbumSidebarResizing}
                  onMouseDown={handleSliderPointerDown}
                  onTouchStart={handleSliderPointerDown}
                />
              )}
            </SliderBar>
          )}
          <PhotoListRoot>
            <FlexBoxWrapper>{renderHeader()}</FlexBoxWrapper>

            {renderTabs()}
            {isTimelinePath ? (
              <FilterWrapper>
                <PhotoCollectionFilter />
              </FilterWrapper>
            ) : null}
            {renderPhotoTopBar()}
            {renderSelectionBar()}

            <PhotoTimeLineWrapper isPhotoPage={!isTimelapse}>
              <PhotosRoot ref={handlePhotoListRef}>
                <Outlet />
              </PhotosRoot>
              {!isLocationPath && !isNonDatePhotoPath && !isAlbumPath && (
                <TimeLineScrollBar
                  photoWrapperRef={photoWrapperRef}
                  photosPerRow={photoSize === 'sm' ? 1 : 3}
                  height={height}
                />
              )}
            </PhotoTimeLineWrapper>
          </PhotoListRoot>
        </PhotoWrapper>
      )}

      {renderPhotoViewer()}

      {is360MiniMapView ? <ThreeSixtyMiniMap /> : null}
    </Root>
  );
});
