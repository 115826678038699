import * as T from '^/types';

const {
  PHOTO,
  DRONE_PHOTO,
  SOURCE,
  BLUEPRINT_PDF,
  BLUEPRINT_DXF,
  BLUEPRINT_DWG,
  DESIGN_DXF,
  ORTHO,
  DSM,
  POINTCLOUD,
  BIM,
  FLIGHT_PLAN,
  GCP,
  FLIGHT_VIDEO,
  FLIGHT_LOG,
  THREE_SIXTY,
  THREE_SIXTY_SOURCE,
  INSPECTION,
  VSLAM_VIDEO,

  /**
   * TODO: @ebraj-angelswing
   * This can be removed.
   */
  THREE_SIXTY_STITCHED,
  VIEWPOINT,
  THREE_SIXTY_VSLAM,
  THREE_SIXTY_VIDEO,
}: typeof T.AttachmentType = T.AttachmentType;

export const fileSignatures: { [K in T.AttachmentType]: number[][] } = {
  [FLIGHT_VIDEO]: [
    [0x00, 0x00, 0x00, 0x18, 0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32], // MP4
    [0x00, 0x00, 0x00, 0x20, 0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6f, 0x6d], // MP4 (ISO Base Media file)
    [0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32], // MP4 (MPEG-4 v2)
  ],
  [FLIGHT_LOG]: [
    [0x43, 0x53, 0x56], // "CSV" in ASCII
  ],
  [VSLAM_VIDEO]: [
    [0x00, 0x00, 0x00, 0x18, 0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32], // MP4
    [0x00, 0x00, 0x00, 0x20, 0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6f, 0x6d], // MP4 (ISO Base Media file)
    [0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32], // MP4 (MPEG-4 v2)
  ],
  [THREE_SIXTY_VIDEO]: [
    [0x00, 0x00, 0x00, 0x18, 0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32], // MP4
    [0x00, 0x00, 0x00, 0x20, 0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6f, 0x6d], // MP4 (ISO Base Media file)
    [0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32], // MP4 (MPEG-4 v2)
  ],
  [PHOTO]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
  [DRONE_PHOTO]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
  [INSPECTION]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
  [SOURCE]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
  ],
  [THREE_SIXTY]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
  [THREE_SIXTY_SOURCE]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
  [THREE_SIXTY_STITCHED]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
  [THREE_SIXTY_VSLAM]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
  [ORTHO]: [
    [0x49, 0x49, 0x2a, 0x00], // TIF
    [0x4d, 0x4d, 0x00, 0x2a], // TIF
    [0x49, 0x49, 0x2b, 0x00, 0x08, 0x00, 0x00, 0x00], // BigTiff LittleEndian
    [0x4d, 0x4d, 0x00, 0x2b, 0x00, 0x08, 0x00, 0x00], // BigTiff BigEndian
  ],
  [BLUEPRINT_PDF]: [
    [0x25, 0x50, 0x44, 0x46, 0x2d], // PDF
    [0x20, 0x20, 0x30, 0x0d, 0x0a], // DXF
    [0x41, 0x43, 0x31, 0x30], // DWG
  ],
  [BLUEPRINT_DXF]: [
    [0x25, 0x50, 0x44, 0x46, 0x2d], // PDF
    [0x20, 0x20, 0x30, 0x0d, 0x0a], // DXF
    [0x41, 0x43, 0x31, 0x30], // DWG
  ],
  [BLUEPRINT_DWG]: [
    [0x25, 0x50, 0x44, 0x46, 0x2d], // PDF
    [0x20, 0x20, 0x30, 0x0d, 0x0a], // DXF
    [0x41, 0x43, 0x31, 0x30], // DWG
  ],
  [DESIGN_DXF]: [
    [0x20, 0x20, 0x30, 0x0d, 0x0a], // DXF
  ],
  [DSM]: [
    [0x49, 0x49, 0x2a, 0x00],
    [0x4d, 0x4d, 0x00, 0x2a],
    [0x49, 0x49, 0x2b, 0x00, 0x08, 0x00, 0x00, 0x00], // BigTiff LittleEndian
    [0x4d, 0x4d, 0x00, 0x2b, 0x00, 0x08, 0x00, 0x00], // BigTiff BigEndian
  ],
  [POINTCLOUD]: [
    [0x4c, 0x41, 0x53, 0x46], // LAS
  ],
  [BIM]: [
    [0x49, 0x53, 0x4f, 0x2d, 0x31, 0x30, 0x33, 0x30, 0x33, 0x2d, 0x32, 0x31],
    [0x49, 0x53, 0x4f, 0x2d, 0x31, 0x30, 0x33, 0x30, 0x33, 0x2d, 0x32, 0x31, 0x3b],
  ],
  /**
   * KMZ File signature
   * NOTE: It seems like, KMZ file signature and the zip have same file signature
   * Source - https://en.wikipedia.org/wiki/List_of_file_signatures
   */
  [FLIGHT_PLAN]: [[0x50, 0x4b, 0x03, 0x04]],
  [GCP]: [[0x43, 0x41, 0x4c, 0x45, 0x4e, 0x20, 0x46, 0x52, 0x4f, 0x4d, 0x20, 0x0a]],
  [VIEWPOINT]: [
    [0xff, 0xd8, 0xff], // JPG, JPEG
    [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
  ],
};

export const fileExtensions: { [K in T.AttachmentType]: string[] } = {
  [FLIGHT_VIDEO]: ['.mp4'],
  [FLIGHT_LOG]: ['.csv'],
  [VSLAM_VIDEO]: ['.mp4'],
  [THREE_SIXTY_VIDEO]: ['.mp4'],
  [PHOTO]: ['.jpg', '.png', '.jpeg'],
  [DRONE_PHOTO]: ['.jpg', '.png', '.jpeg'],
  [INSPECTION]: ['.jpg', '.png', '.jpeg'],
  [SOURCE]: ['.jpg', '.jpeg'],
  [THREE_SIXTY]: ['.jpg', '.jpeg', '.png'],
  [THREE_SIXTY_SOURCE]: ['.jpg', '.jpeg', '.png'],
  [THREE_SIXTY_STITCHED]: ['.jpg', '.jpeg', '.png'],
  [THREE_SIXTY_VSLAM]: ['.jpg', '.jpeg', '.png'],
  [ORTHO]: ['.tif', '.tiff'],
  [BLUEPRINT_PDF]: ['.dwg', '.dxf', '.pdf'],
  [BLUEPRINT_DXF]: ['.dwg', '.dxf', '.pdf'],
  [BLUEPRINT_DWG]: ['.dwg', '.dxf', '.pdf'],
  [DESIGN_DXF]: ['.dxf'],
  [DSM]: ['.tif', '.tiff'],
  [POINTCLOUD]: ['.las'],
  [BIM]: ['.ifc'],
  [FLIGHT_PLAN]: ['.kmz'],
  [GCP]: ['.csv'],
  [VIEWPOINT]: ['.jpg', '.jpeg', '.png'],
};
