/* eslint-disable max-lines */
import Tippy from '@tippyjs/react';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import MagnifierSvg from '^/assets/icons/photo/magnifier.svg';
import InfoWhiteSvg from '^/assets/icons/photo/info-circle.svg';
import ScreenChangeSVG from '^/assets/icons/photo/edit-calender.svg';
import DownloadSVG from '^/assets/icons/photo/download-white.svg';
import DeleteButtonSVG from '^/assets/icons/photo/delete-button.svg';
import BackArrowSVG from '^/assets/icons/photo/back-arrow.svg';
import BigChevronSVG from '^/assets/icons/photo/big-chevron.svg';
import SmallChevronSVG from '^/assets/icons/photo/small-chevron.svg';
import IssueSVG from '^/assets/icons/photo/issue.svg';
import FilterIconSvg from '^/assets/icons/timelapse/filter-icon.svg';

import Text from './text';
import PhotoText from '../PhotoList/text';
import { PhotoViewerItemWithIssue } from './PhotoViewerItemWithIssue';
import * as T from '^/types';
import MagnifyImage from './MagnifyImage';
import { simpleDownload } from '^/utilities/download';
import { pick } from 'lodash-es';
import { UseL10n, useL10n, useLastSelectedScreen, UseLastSelectedScreen } from '^/hooks';
import { isVideo } from '../PhotoList/util';
import VideoPlayer from '../VideoPlayer';
import {
  Root,
  PhotoViewerWrapper,
  PhotoViewerItemWrapper,
  ArrowSvgWrapper,
  PhotoViewerItemHandle,
  BackArrowWrapper,
  PhotoViewerToolsWrapper,
  PhotoIndexLabel,
  VerticalDivider,
  ZoomButton,
  InfoButton,
  InfoRoot,
  InfoWrapper,
  InfoItem,
  InfoItemKey,
  InfoItemValue,
  ChangeDateButton,
  DownloadButton,
  DeleteButton,
  PhotoThumbNailsWrapper,
  PhotoThumbNails,
  PhotoThumbNailItemHandle,
  PhotoThumbNailIconWrapper,
  PhotoViewerContainer,
  DefaultImageContainer,
  DefaultImageElement,
  DefaultImageText,
  IssueButton,
} from './style';
import { createDefaultIssuePhoto } from '^/constants/defaultContent';
import { getMeasurementContentTitlesFromDate } from '^/utilities/content-util';

import { PhotoThumbNailComponent } from './PhotoThumbNailComponent';
import { VideoPlayerItem } from '../VideoPlayer/style';
import { useIssueStore } from '^/store/issue';
import useIssueContents from '^/hooks/useIssueContents';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import { useLocation } from 'react-router-dom';

enum ViewerTools {
  'INFO' = 'INFO',
  'CALENDER' = 'CALENDER',
  'ISSUE_PHOTO' = 'ISSUE_PHOTO',
  'DOWNLOAD' = 'DOWNLOAD',
  'DELETE' = 'DELETE',
}

const photoInfoKeys = [
  'originalFilename',
  'latitude',
  'longitude',
  'altitude',
  'direction',
  'takenAt',
  'takenBy',
  'createdAt',
  'updatedAt',
];

function separateCamelCase(word: string) {
  const words = [];
  for (let i = 0; i < word.length; i++) {
    if (word[i] === word[i].toUpperCase() && i > 0) {
      words.push(' ');
      words.push(word[i].toLowerCase());
    } else {
      words.push(word[i]);
    }
  }
  return words.join('');
}

interface ModifiedPhoto extends T.Photo {
  showDate: boolean;
  highlightDate: boolean;
  isSelected: boolean;
  isUniqueDate: boolean;
}

interface Props {
  photos: T.FinalPhoto[];
  currentPhotoId?: T.Photo['id'];
  isIssueMode?: boolean;
  onSetCurrentPhotoId(photoId?: number): void;
  onDelete(): void;
  onChangeDate?(): void;
}

const PhotoViewer: FC<Props> = ({
  photos,
  currentPhotoId,
  onSetCurrentPhotoId,
  isIssueMode,
  onDelete,
  onChangeDate,
}: Props) => {
  const clickOnCloseElementsRefs = useRef<Set<HTMLDivElement | null>>(new Set());
  const [l10n, lang]: UseL10n = useL10n();
  const [filter, setFilter] = useState(false);
  const { issuesContentsById } = useIssueStore();
  const lastSelectedScreen: UseLastSelectedScreen = useLastSelectedScreen();
  const contentTitlesShownOnCurrentDate: string[] = getMeasurementContentTitlesFromDate(
    issuesContentsById,
    lastSelectedScreen?.appearAt
  );

  const photoThumbNailsRef = useRef<HTMLDivElement>(null);
  const photoThumbNailRefs = useRef<{ [id: number]: HTMLDivElement }>({});
  const photoThumbNailBarRefs = useRef<{ [id: number]: HTMLDivElement }>({});

  const [selectedTool, setSelectedTool] = useState<ViewerTools | null>(null);
  const [isZoomSelected, setIsZoomSelected] = useState(false);
  const [wheelMousePos, setWheelMousePos] = useState({ x: 0.5, y: 0.5 });

  const [scrollLeft, setScrollLeft] = useState(0);
  const [initialScrolled, setInitialScrolled] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date | undefined>();
  const { pathname } = useLocation();
  const isTimelapseMode = pathname.endsWith(`/${T.PhotoPathType.LOCATION}`);

  const { postAndEditIssueContent } = useIssueContents();

  const { timelapseInspectionImages, setTimelapseInspectionImages } = useTimelapseStore(s => ({
    timelapseInspectionImages: s.timelapseInspectionImages,
    setTimelapseInspectionImages: s.setTimelapseInspectionImages,
  }));

  const photosSorted: ModifiedPhoto[] = useMemo(() => {
    const dateOccurrences: { [date: string]: number } = {}; // Object to track date occurrences
    let prevDate: string | null = null;

    // Count occurrences of each date
    photos.forEach((photo: T.Photo) => {
      const currentPhotoDate = photo.takenAt ? new Date(photo.takenAt).toLocaleDateString() : '';

      if (dateOccurrences[currentPhotoDate]) {
        dateOccurrences[currentPhotoDate]++;
      } else {
        dateOccurrences[currentPhotoDate] = 1;
      }
    });

    return photos
      .sort((a, b) => {
        if (new Date(a.takenAt || new Date()) > new Date(b.takenAt || new Date())) {
          return -1;
        } else if (new Date(a.takenAt || new Date()) < new Date(b.takenAt || new Date())) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((photo: T.Photo) => {
        const currentPhotoDate = photo.takenAt ? new Date(photo.takenAt).toLocaleDateString() : '';
        const showDate = Boolean(photo.takenAt && (!prevDate || currentPhotoDate !== prevDate));
        const isSelected = photo.id === currentPhotoId;
        const highlightDate = Boolean(
          currentDate && currentPhotoDate === currentDate.toLocaleDateString()
        );

        const isUniqueDate = dateOccurrences[currentPhotoDate] === 1; // Check if the date has only one occurrence
        prevDate = currentPhotoDate;

        return {
          ...photo,
          showDate,
          highlightDate,
          isSelected,
          isUniqueDate,
        };
      });
  }, [photos, currentDate]);

  const currentPhotoInfo: { photo: T.Photo; index: number } | undefined = photosSorted
    .map((photo, index) => ({ photo, index }))
    .find(({ photo }) => photo.id === currentPhotoId);

  const isPhoto = currentPhotoInfo ? !isVideo(currentPhotoInfo.photo.fullUrl) : false;

  useEffect(() => {
    const bars = Object.values(photoThumbNailBarRefs.current);
    bars.forEach((ele, index) => {
      const nextBar = bars[index + 1];
      const isScrolledLeft =
        ele.getBoundingClientRect().left ===
        photoThumbNailsRef.current?.getBoundingClientRect().left;
      if (!nextBar || !isScrolledLeft || !ele.firstElementChild) {
        return;
      }
      const nextBarLeft = nextBar.getBoundingClientRect().left;
      const currentBarLeft = ele.getBoundingClientRect().left;
      const currentBarWidth = ele.firstElementChild.getBoundingClientRect().width;
      if (currentBarLeft + currentBarWidth + 16 < nextBarLeft) {
        ele.style.visibility = 'visible';
      } else {
        ele.style.visibility = 'hidden';
      }
    });
  }, [scrollLeft]);

  const handleOnBack: () => void = useCallback(() => {
    if (selectedTool) {
      setSelectedTool(null);
    } else if (isZoomSelected) {
      setIsZoomSelected(false);
    } else {
      if (!isTimelapseMode) {
        setTimelapseInspectionImages([]);
      }
      onSetCurrentPhotoId();
    }
  }, [isZoomSelected, selectedTool, isTimelapseMode]);

  const centerThumbnail = useCallback(
    (photoId: number) => {
      const currentPhotoRef = photoThumbNailRefs.current[photoId];
      if (photoThumbNailsRef.current && currentPhotoRef) {
        photoThumbNailsRef.current.scrollLeft =
          currentPhotoRef.offsetLeft -
          photoThumbNailsRef.current.offsetWidth / 2 -
          currentPhotoRef.offsetWidth;
      }
    },
    [photoThumbNailRefs, photoThumbNailsRef, filter]
  );

  const handleViewerItemHandleClick: (photoIdx: number) => () => void = useCallback(
    photoIdx => () => {
      const photo = photosSorted[photoIdx];
      if (photo.takenAt) {
        setCurrentDate(new Date(photo.takenAt));
      }
      const photoId: number | undefined = photo?.id;
      onSetCurrentPhotoId(photoId);

      if (photoThumbNailsRef.current && photoId) {
        const currentPhotoRef = photoThumbNailRefs.current[photoId];
        const isContentVisible =
          currentPhotoRef.offsetLeft - 200 >= photoThumbNailsRef.current.scrollLeft &&
          currentPhotoRef.offsetLeft - 200 + currentPhotoRef.offsetWidth <=
            photoThumbNailsRef.current.scrollLeft + photoThumbNailsRef.current.offsetWidth;
        if (!isContentVisible) {
          centerThumbnail(photoId);
        }
      }
    },
    [photos, currentPhotoId]
  );

  const handleThumbNailClick = useCallback((photo: T.Photo) => {
    onSetCurrentPhotoId(photo.id);
    if (photo.takenAt) {
      setCurrentDate(new Date(photo.takenAt));
    }
  }, []);

  const handleZoomClick = () => {
    setSelectedTool(null);
    setIsZoomSelected(prev => !prev);
  };

  const handleDownload = () => {
    setSelectedTool(null);
    if (currentPhotoInfo?.photo) {
      simpleDownload(currentPhotoInfo.photo.imageUrl, currentPhotoInfo.photo.originalFilename);
    }
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleFilter = () => {
    if (!currentPhotoId) {
      return;
    }
    if (filter) {
      setFilter(false);
      setTimelapseInspectionImages([]);
      return;
    }
    if (!currentPhotoInfo?.photo.locationsWithExcluded) {
      return;
    }
    const currentPhotoLocations = currentPhotoInfo.photo.locationsWithExcluded.map(
      location => location.timelapseLocationId
    );
    const newTimelinePhotos = photos.filter(photo => {
      const photoLocations = photo.locationsWithExcluded?.map(
        location => location.timelapseLocationId
      );
      return (
        photoLocations?.some(location => currentPhotoLocations?.includes(location)) ||
        photo.id === currentPhotoId
      );
    });
    setFilter(true);
    setTimelapseInspectionImages(newTimelinePhotos);
  };

  const handleInfoClick = () => {
    setSelectedTool(prev => {
      if (prev === ViewerTools.INFO) {
        return null;
      } else {
        return ViewerTools.INFO;
      }
    });
  };

  const handleIssueClick = () => {
    setIsZoomSelected(false);
    setSelectedTool(prev => (prev !== ViewerTools.ISSUE_PHOTO ? ViewerTools.ISSUE_PHOTO : null));
  };

  const handleIssueCreate = (imagePoint: T.Point) => {
    if (!currentPhotoInfo?.photo) {
      return;
    }

    const { info, title, color } = createDefaultIssuePhoto({
      imagePoint,
      language: lang,
      usingNames: contentTitlesShownOnCurrentDate,
    });

    postAndEditIssueContent({
      type: T.ContentType.ISSUE_PHOTO,
      info,
      title,
      color,
      photoId: currentPhotoInfo.photo.id,
    });
    setSelectedTool(null);
  };

  const handleChangeDate = () => {
    setSelectedTool(null);
    onChangeDate?.();
  };

  const handleRootClick = (e: any) => {
    e.stopPropagation();
    if (clickOnCloseElementsRefs.current.has(e.target) && !isZoomSelected) {
      handleOnBack();
    }
  };

  const handleLeftThumbNailArrow = () => {
    if (photoThumbNailsRef.current && photoThumbNailsRef.current?.scrollLeft > 0) {
      photoThumbNailsRef.current.scrollLeft =
        photoThumbNailsRef.current.scrollLeft - photoThumbNailsRef.current.clientWidth / 2;
    }
  };

  const handleRightThumbNailArrow = () => {
    if (photoThumbNailsRef.current) {
      photoThumbNailsRef.current.scrollLeft =
        photoThumbNailsRef.current.scrollLeft + photoThumbNailsRef.current.clientWidth / 2;
    }
  };

  const handleWheel = (event: any) => {
    if (event.deltaY < 0 && selectedTool === null) {
      setIsZoomSelected(true);
      const { top, left, width, height } = event.target.getBoundingClientRect();
      setWheelMousePos({ x: (event.clientX - left) / width, y: (event.clientY - top) / height });
    }
  };

  useEffect(() => {
    const keyDownEventHandler = (event: { code: string }) => {
      if (currentPhotoInfo) {
        if (event.code === 'ArrowLeft' && currentPhotoInfo.index !== 0 && photos.length !== 1) {
          handleViewerItemHandleClick(currentPhotoInfo.index - 1)();
        } else if (event.code === 'ArrowRight' && currentPhotoInfo.index + 1 !== photos.length) {
          handleViewerItemHandleClick(currentPhotoInfo.index + 1)();
        } else if (event.code === 'Escape') {
          handleOnBack();
        }
      }
    };
    document.addEventListener('keydown', keyDownEventHandler);
    return () => {
      document.removeEventListener('keydown', keyDownEventHandler);
    };
  }, [currentPhotoInfo, filter]);

  useEffect(() => {
    if (currentPhotoInfo && currentPhotoId) {
      if (photoThumbNailsRef.current && !initialScrolled) {
        centerThumbnail(currentPhotoId);
        setInitialScrolled(true);
      }
    } else {
      setInitialScrolled(false);
      setScrollLeft(0);
    }
  }, [initialScrolled, currentPhotoInfo, filter, photoThumbNailsRef]);

  useEffect(() => {
    if (currentPhotoId) {
      centerThumbnail(currentPhotoId);
    }
  }, [filter, currentPhotoId]);

  if (!currentPhotoInfo) {
    return null;
  }

  const photoViewerTools = (
    <PhotoViewerToolsWrapper showBackground={isZoomSelected}>
      <PhotoIndexLabel>
        {currentPhotoInfo.index + 1} / {photosSorted.length}
      </PhotoIndexLabel>
      <VerticalDivider />
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="top"
        arrow={false}
        content={l10n(Text.zoomTooltip)}
      >
        <ZoomButton disabled={!isPhoto} isSelected={isZoomSelected} onClick={handleZoomClick}>
          <MagnifierSvg />
        </ZoomButton>
      </Tippy>
      {isIssueMode ? null : (
        <InfoRoot>
          {selectedTool === ViewerTools.INFO && (
            <InfoWrapper>
              {Object.entries(pick(currentPhotoInfo.photo, photoInfoKeys)).map(([key, value]) => (
                <InfoItem key={key}>
                  <InfoItemKey> {separateCamelCase(key)} </InfoItemKey>
                  <InfoItemValue>{value?.toString()}</InfoItemValue>
                </InfoItem>
              ))}
            </InfoWrapper>
          )}
          <Tippy
            offset={T.TIPPY_OFFSET}
            theme="angelsw"
            placement="top"
            arrow={false}
            content={l10n(Text.detailTooltip(isPhoto))}
          >
            <InfoButton onClick={handleInfoClick} isSelected={selectedTool === ViewerTools.INFO}>
              <InfoWhiteSvg />
            </InfoButton>
          </Tippy>
        </InfoRoot>
      )}
      {isIssueMode ? null : (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={l10n(Text.editDateTooltip)}
        >
          <ChangeDateButton
            isSelected={selectedTool === ViewerTools.CALENDER}
            onClick={handleChangeDate}
            disabled={!isPhoto}
          >
            <ScreenChangeSVG />
          </ChangeDateButton>
        </Tippy>
      )}
      {isIssueMode ? null : (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={l10n(Text.issueTooltip)}
        >
          <IssueButton
            onClick={handleIssueClick}
            isSelected={selectedTool === ViewerTools.ISSUE_PHOTO}
          >
            <IssueSVG />
          </IssueButton>
        </Tippy>
      )}
      {isIssueMode ? null : (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={l10n(Text.downloadTooltip(isPhoto))}
        >
          <DownloadButton
            onClick={handleDownload}
            isSelected={selectedTool === ViewerTools.DOWNLOAD}
          >
            <DownloadSVG />
          </DownloadButton>
        </Tippy>
      )}
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="top"
        arrow={false}
        content={l10n(Text.deleteTooltip(isPhoto))}
      >
        <DeleteButton onClick={handleDelete} isSelected={selectedTool === ViewerTools.DELETE}>
          <DeleteButtonSVG />
        </DeleteButton>
      </Tippy>
      {isTimelapseMode ? null : (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={!filter ? l10n(Text.filter) : l10n(Text.unfilter)}
        >
          <DeleteButton
            onClick={handleFilter}
            isSelected={timelapseInspectionImages.length > 0 && filter}
          >
            <FilterIconSvg />
          </DeleteButton>
        </Tippy>
      )}
    </PhotoViewerToolsWrapper>
  );

  const photoThumbNailList = (
    <PhotoThumbNailsWrapper>
      <PhotoThumbNailItemHandle isLeft={true} isVisible={scrollLeft > 0}>
        <PhotoThumbNailIconWrapper onClick={handleLeftThumbNailArrow}>
          <SmallChevronSVG />
        </PhotoThumbNailIconWrapper>
      </PhotoThumbNailItemHandle>
      <PhotoThumbNails
        ref={photoThumbNailsRef}
        onScroll={e => setScrollLeft(e.currentTarget.scrollLeft)}
      >
        {photosSorted.map((photo, index) => (
          <PhotoThumbNailComponent
            lang={lang}
            index={index}
            photo={photo}
            key={photo.id}
            currentPhotoId={currentPhotoId}
            photoThumbNailRefs={photoThumbNailRefs}
            handleThumbNailClick={handleThumbNailClick}
            photoThumbNailBarRefs={photoThumbNailBarRefs}
          />
        ))}
      </PhotoThumbNails>

      <PhotoThumbNailItemHandle
        isLeft={false}
        isVisible={
          scrollLeft + Number(photoThumbNailsRef.current?.clientWidth) !==
          Number(photoThumbNailsRef.current?.scrollWidth)
        }
      >
        <PhotoThumbNailIconWrapper onClick={handleRightThumbNailArrow}>
          <SmallChevronSVG />
        </PhotoThumbNailIconWrapper>
      </PhotoThumbNailItemHandle>
    </PhotoThumbNailsWrapper>
  );

  const main = (
    <PhotoViewerWrapper ref={ele => clickOnCloseElementsRefs.current.add(ele)}>
      <PhotoViewerItemHandle
        isLeft={true}
        isVisible={currentPhotoInfo.index !== 0 && photos.length !== 1}
        onClick={handleViewerItemHandleClick(currentPhotoInfo.index - 1)}
      >
        <ArrowSvgWrapper isLeft={true}>
          <BigChevronSVG />
        </ArrowSvgWrapper>
      </PhotoViewerItemHandle>
      <PhotoViewerItemWrapper
        id={`preview_${currentPhotoInfo.photo.id}`}
        ref={ele => clickOnCloseElementsRefs.current.add(ele)}
      >
        {isPhoto ? (
          <React.Fragment>
            {currentPhotoInfo.photo.status === T.PhotoProcessStatus.PENDING ||
            currentPhotoInfo.photo.status === T.PhotoProcessStatus.PROCESSING ? (
              <DefaultImageContainer>
                <DefaultImageElement scale={9} />

                <DefaultImageText>{l10n(PhotoText.drone.sourcePhotoProcessing)}</DefaultImageText>
              </DefaultImageContainer>
            ) : (
              <PhotoViewerItemWithIssue
                key={currentPhotoInfo.photo.fullUrl}
                src={currentPhotoInfo.photo.fullUrl}
                isIssueEnabled={selectedTool === ViewerTools.ISSUE_PHOTO && !isIssueMode}
                issuePhotoContents={
                  Object.values(issuesContentsById).filter(
                    content =>
                      content.type === T.ContentType.ISSUE_PHOTO &&
                      content.photo?.id === currentPhotoInfo.photo.id
                  ) as T.IssuePhotoContent[]
                }
                onWheel={handleWheel}
                onIssueCreate={handleIssueCreate}
              />
            )}
          </React.Fragment>
        ) : (
          <VideoPlayerItem ref={ele => clickOnCloseElementsRefs.current.add(ele)}>
            <VideoPlayer url={currentPhotoInfo.photo.fullUrl} id={currentPhotoInfo.photo.id} />
          </VideoPlayerItem>
        )}
      </PhotoViewerItemWrapper>
      <PhotoViewerItemHandle
        isLeft={false}
        isVisible={currentPhotoInfo.index + 1 !== photos.length}
        onClick={handleViewerItemHandleClick(currentPhotoInfo.index + 1)}
      >
        <ArrowSvgWrapper isLeft={false}>
          <BigChevronSVG />
        </ArrowSvgWrapper>
      </PhotoViewerItemHandle>
    </PhotoViewerWrapper>
  );

  return (
    <Root onClick={handleRootClick} ref={ele => clickOnCloseElementsRefs.current.add(ele)}>
      <PhotoViewerContainer>
        {photoViewerTools}
        {main}
        {photoThumbNailList}
        {isZoomSelected && (
          <MagnifyImage
            onMinZoomLevel={() => setIsZoomSelected(false)}
            imageURI={currentPhotoInfo.photo.fullUrl}
            wheelMousePos={wheelMousePos}
          />
        )}
        <BackArrowWrapper onClick={handleOnBack} showBackground={isZoomSelected}>
          <BackArrowSVG />
        </BackArrowWrapper>
      </PhotoViewerContainer>
    </Root>
  );
};

export default memo(PhotoViewer);
