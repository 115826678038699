import React from 'react';
import styled from 'styled-components';

const TooltTipContainer = styled.div({
  position: 'relative',
});

const TooltipText = styled.span<{ placement: string }>(props => ({
  position: 'absolute',
  padding: '8px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  borderRadius: '4px',
  fontSize: '11px',
  whiteSpace: 'nowrap',
  opacity: 0,
  ...getPositionStyles(props.placement),
}));

const TooltipTrigger = styled.div({
  display: 'inline-block',
  cursor: 'pointer',

  ':hover': {
    [`${TooltipText}`]: {
      opacity: 1,
    },
  },
});

interface TooltipProps {
  content: string;
  children: React.ReactNode;
  placement: 'top' | 'top-start' | 'top-end' | 'near-item';
}

const CustomTooltip: React.FC<TooltipProps> = ({ content, children, placement }) => (
  <TooltTipContainer>
    <TooltipTrigger>
      {children}
      <TooltipText placement={placement}>{content}</TooltipText>
    </TooltipTrigger>
  </TooltTipContainer>
);

// Helper function to calculate the position styles based on placement
const getPositionStyles = (placement: string) => {
  switch (placement) {
    case 'top':
      return {
        bottom: '50px',
        left: '50%',
        transform: 'translateX(-50%)',
      };
    case 'top-start':
      return {
        bottom: '50px',
        left: '0',
      };
    case 'top-end':
      return {
        bottom: '50px',
        right: '0',
      };

    case 'near-item': {
      return {
        bottom: '30px',
        right: '-30px',
      };
    }
    default:
      return {};
  }
};

export default CustomTooltip;
