/* eslint-disable max-lines */
import * as Sentry from '@sentry/browser';
import { Reducer } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { concat, Observable, of, queueScheduler, scheduled } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, concatAll, map, mergeMap } from 'rxjs/operators';
import { action as makeAction, props, union } from 'tsdux';
import { ofType } from 'tsdux-observable';
import {
  AuthHeader,
  getRequestErrorType,
  jsonContentHeader,
  makeAuthHeader,
  makeV2APIURL,
} from '../API';
import { ChangeAuthedUser } from '../Auth';
import { CloseContentPagePopup, OpenContentPagePopup } from '../Pages';
import * as T from '^/types';
import { isErrorIgnorable } from '^/utilities/http-response';
import { generateUUID } from 'three/src/math/MathUtils';
import { rxjsHttp } from '^/utilities/api';
// import { checkIsInternalUser } from '^/hooks/useAuthedUser';

export const GetPhotos = makeAction(
  'ddm/photos/GET_PHOTOS',
  props<{ readonly projectId: T.Project['id'] }>()
);
export const GetDroneVideos = makeAction(
  'ddm/photos/GET_DRONE_VIDEOS',
  props<{ readonly projectId: T.Project['id'] }>()
);
export const FinishGetPhotos = makeAction(
  'ddm/photos/FINISH_GET_PHOTOS',
  props<{ error?: T.HTTPError }>()
);

export const FinishGetDroneVidoes = makeAction(
  'ddm/photos/FINISH_GET_DRONE_VIDEOS',
  props<{ error?: T.HTTPError }>()
);
export const UpdatePhotosInStore = makeAction(
  'ddm/photos/UPDATE_PHOTOS_IN_STORE',
  props<{ photos: T.FinalPhoto[] }>()
);
export const UpdateDroneVidoesInStore = makeAction(
  'ddm/photos/UPDATE_DRONE_VIDEOS_IN_STORE',
  props<{ droneVideos: T.FinalPhoto[] }>()
);

export const UploadPhotos = makeAction(
  'ddm/photos/UPLOAD_PHOTOS',
  props<{ readonly files: File[]; photoType?: T.PhotoType }>()
);
export const FinishUploadPhotos = makeAction('ddm/photos/FINISH_UPLOAD_PHOTOS');
export const FinishPostPhoto = makeAction('ddm/photos/FINISH_POST_PHOTO');
export const DeletePhoto = makeAction(
  'ddm/photos/DELETE_PHOTO',
  props<{ readonly photoId: T.Photo['id'] }>()
);

export const DeleteDroneVideo = makeAction(
  'ddm/photos/DELETE_DRONE_VIDEO',
  props<{ readonly videoIds: Array<T.FinalPhoto['id']> }>()
);

export const ResetDeleteBulkPhotos = makeAction('ddm/photos/RESET_DELETE_BULK_PHOTOS');
export const StartDeleteBulkPhotos = makeAction('ddm/photos/START_DELETE_BULK_PHOTOS');
export const DeleteBulkPhotos = makeAction(
  'ddm/photos/BULK_DELETE_PHOTOS',
  props<{ readonly photoIds: Array<T.Photo['id']>; readonly videoIds: Array<T.DroneVideo['id']> }>()
);
export const FinishDeleteBulkPhotos = makeAction(
  'ddm/photos/FINISH_DELETE_BULK_PHOTOS',
  props<{ error?: T.HTTPError }>()
);
export const SetCurrentPhotoId = makeAction(
  'ddm/photos/SET_CURRENT_PHOTO_ID',
  props<{ readonly photoId?: T.Photo['id'] }>()
);

export const SetCurrentVideoId = makeAction(
  'ddm/photos/SET_CURRENT_VIDEO_ID',
  props<{ readonly videoId?: T.DroneVideo['id'] }>()
);

export const SetPhotoOnMapId = makeAction(
  'ddm/photos/SET_PHOTO_ON_MAP_PHOTO_ID',
  props<{ readonly photoId?: T.Photo['id'] }>()
);

export const SetVideoOnMapId = makeAction(
  'ddm/photos/SET_VIDEO_ON_MAP_VIDEO_ID',
  props<{ readonly videoId?: T.DroneVideo['id'] }>()
);

export const SetSelectedMedia = makeAction(
  'ddm/photos/SET_SELECTED_MEDIA',
  props<{ readonly selectedMedia: T.SelectedMedia[] }>()
);

export const SetHoveredPhotoId = makeAction(
  'ddm/photos/SET_HOVERED_PHOTO_ID',
  props<{ readonly hoveredId?: T.Photo['id'] }>()
);

export const SetHoveredVideoId = makeAction(
  'ddm/photos/SET_HOVERED_VIDEO_ID',
  props<{ readonly hoveredVideoId?: T.DroneVideo['id'] }>()
);

export const SetPhotoDateType = makeAction(
  'ddm/photos/SET_PHOTO_DATE_TYPE',
  props<{ readonly dateType: T.PhotoDateType }>()
);

export const SetPhotoDate = makeAction(
  'ddm/photos/SET_PHOTO_DATE',
  props<{ readonly droneDate: Date | undefined }>()
);

export const SetUploadedPhotoCount = makeAction(
  'ddm/photos/SET_UPLOADED_PHOTO_COUNT',
  props<{ readonly count: number }>()
);

export const EditTagPhotoDate = makeAction(
  'ddm/pages/EdIT_TAG_PHOTO_DATE',
  props<{
    readonly tagMedia: T.SelectedMedia[];
    readonly takenAt: Date;
  }>()
);

export const SetDownloadPhotoState = makeAction(
  'ddm/photos/DOWNLOAD_PHOTOS_STATE',
  props<{ readonly isDownloading: boolean }>()
);

export const setSourcePhotoContentId = makeAction(
  'ddm/photos/SET_SOURCE_PHOTO_ALBUM',
  props<{ readonly contentId: T.Photo['contentId'] }>()
);

export const Action = union([
  // API Actions
  GetPhotos,
  GetDroneVideos,
  FinishGetPhotos,
  FinishGetDroneVidoes,
  UploadPhotos,
  FinishPostPhoto,
  FinishUploadPhotos,
  DeletePhoto,
  DeleteDroneVideo,
  StartDeleteBulkPhotos,
  ResetDeleteBulkPhotos,
  DeleteBulkPhotos,
  FinishDeleteBulkPhotos,
  EditTagPhotoDate,
  // In-state Actions
  UpdatePhotosInStore,
  UpdateDroneVidoesInStore,
  SetPhotoDateType,
  SetPhotoDate,
  SetCurrentPhotoId,
  SetCurrentVideoId,
  SetPhotoOnMapId,
  SetVideoOnMapId,
  SetHoveredPhotoId,
  SetHoveredVideoId,
  SetSelectedMedia,
  SetUploadedPhotoCount,
  SetDownloadPhotoState,
  setSourcePhotoContentId,
  // Out-duck Actions
  OpenContentPagePopup,
  CloseContentPagePopup,
]);

export type Action = typeof Action;

interface GetPhotosResponse {
  readonly data: T.APIPhoto[];
  readonly meta: T.APIPhotoMeta[];
}

export interface PostBulkPhotoUploadResponse {
  photo_id: number;
  url: string;
  fields: {
    key: string;
    success_action_status: string;
    acl: string;
    policy: string;
    'x-amz-credential': string;
    'x-amz-algorithm': string;
    'x-amz-date': string;
    'x-amz-signature': string;
  };
  'signer-url': string;
  region: string;
  bucket: string;
  'aws-id': string;
  key: string;
}

interface GetDroneVideosResponse {
  readonly data: T.APIDroneVideo[];
}

/**
 * TODO: @ebraj-angelswing
 * Remove the condition once other url available for the 360 images...
 */
export const APIToPhoto: (rawPhoto: T.APIPhoto | T.Photo) => T.FinalPhoto = rawPhoto => ({
  ...rawPhoto,
  thumbUrl:
    rawPhoto.photoType === 'three_sixty_stitched' ? rawPhoto.lowQualityUrl! : rawPhoto.thumbUrl,
  boxThumbUrl:
    rawPhoto.photoType === 'three_sixty_stitched' ? rawPhoto.lowQualityUrl! : rawPhoto.boxThumbUrl,
  fullUrl: rawPhoto.photoType === 'three_sixty_stitched' ? rawPhoto.imageUrl : rawPhoto.fullUrl,
  takenAt: rawPhoto.takenAt ? new Date(rawPhoto.takenAt) : null,
  createdAt: new Date(rawPhoto.createdAt),
  updatedAt: new Date(rawPhoto.updatedAt),
  uploadSource: 'photo',
  videoThumbUrl: rawPhoto.boxThumbUrl ? rawPhoto.boxThumbUrl : '',
  videoUrl: '',
});
export const APIToSourcePhoto: (rawPhoto: T.APIPhotoMeta | T.Photo) => T.FinalPhoto = rawPhoto => ({
  ...rawPhoto,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  id: generateUUID(),
  takenAt: rawPhoto.takenAt ? new Date(rawPhoto.takenAt) : null,
  createdAt: rawPhoto.takenAt ? new Date(rawPhoto.takenAt) : new Date(),
  updatedAt: rawPhoto.takenAt ? new Date(rawPhoto.takenAt) : new Date(),
  fullUrl: '',
  uploadSource: 'photo',
  videoThumbUrl: '',
  videoUrl: '',
  photoType: (rawPhoto as T.APIPhotoMeta)?.viewpointAlbumId ? 'viewpoint' : 'source',
});

export const APISourcePhotoToMeta = (
  sourcePhotos: T.FinalPhoto[],
  meta: T.APIPhoto
): T.FinalPhoto => {
  const source = sourcePhotos.filter(val => val.screenId);
  const screenPhoto = source.find(val => val.screenId === meta.screen.id);

  if (screenPhoto && screenPhoto.count) {
    return {
      ...screenPhoto,
      count: screenPhoto.count + 1,
    };
  }

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    id: generateUUID(),
    takenAt: meta.takenAt ? new Date(meta.takenAt) : null,
    createdAt: meta.takenAt ? new Date(meta.takenAt) : new Date(),
    updatedAt: meta.takenAt ? new Date(meta.takenAt) : new Date(),
    fullUrl: '',
    uploadSource: 'photo',
    videoThumbUrl: '',
    videoUrl: '',
    photoType: 'source',
    screenId: meta.screen.id,
    screenTitle: meta.screen.title,
    count: 1,
  };
};

export const APIToDroneVideo: (
  rawPhoto: T.APIDroneVideo | T.DroneVideo
) => T.FinalPhoto = rawVideo => ({
  ...rawVideo,
  takenAt: rawVideo.takenAt ? new Date(rawVideo.takenAt) : null,
  createdAt: new Date(rawVideo.createdAt),
  updatedAt: new Date(rawVideo.updatedAt),
  imageUrl: '',
  thumbUrl: rawVideo.videoThumbUrl ?? rawVideo.thumbUrl,
  fullUrl: rawVideo.videoUrl ? rawVideo.videoUrl : '',
  boxThumbUrl: rawVideo.videoThumbUrl,
  geotags: rawVideo.geotags ? rawVideo.geotags : null,
  flightLogUrl: rawVideo.flightLogUrl ?? '',
  focalLength: rawVideo.geotags?.focalLength ?? '',
  altitude: rawVideo.altitude ?? null,
  photoType: rawVideo.type,
});

const getPhotosEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(GetPhotos),
    mergeMap(({ projectId }) => {
      const URL: string = makeV2APIURL('projects', projectId, 'photos');
      // const isInternalUser = checkIsInternalUser(state$.value.Auth);
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );

      if (authHeader === undefined) {
        return [ChangeAuthedUser({})];
      }

      return rxjsHttp.get(URL, { headers: authHeader }).pipe(
        map(({ response }): GetPhotosResponse => response),
        map(({ data, meta }) => {
          const dataPhoto = data.map(APIToPhoto);
          const sourcePhoto = meta?.map(APIToSourcePhoto) ?? [];
          return [...dataPhoto, ...sourcePhoto];
        }),
        mergeMap(photos => [UpdatePhotosInStore({ photos }), FinishGetPhotos({})])
      );
    }),
    catchError((e: AjaxError) => {
      if (!isErrorIgnorable(e.status)) {
        Sentry.captureException(e);
      }

      return [FinishGetPhotos({ error: getRequestErrorType(e) })];
    })
  );

const getDroneVideosEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(GetDroneVideos),
    mergeMap(({ projectId }) => {
      const URL: string = makeV2APIURL('projects', projectId, 'videos');
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );

      if (authHeader === undefined) {
        return [ChangeAuthedUser({})];
      }

      return rxjsHttp.get(URL, { headers: authHeader }).pipe(
        map(({ response }): GetDroneVideosResponse => response),
        map(({ data }) =>
          data.map(item => {
            //FIXME: API return focalLength is null
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            item.geotags = {
              ...item.geotags,
              focalLength: '',
              absoluteAltitude: item.altitude,
              relativeAltitude: item.altitude,
            };
            return APIToDroneVideo(item);
          })
        ),
        mergeMap(droneVideos => [
          UpdateDroneVidoesInStore({ droneVideos }),
          FinishGetDroneVidoes({}),
        ])
      );
    }),
    catchError((e: AjaxError) => {
      if (!isErrorIgnorable(e.status)) {
        Sentry.captureException(e);
      }

      return [FinishGetDroneVidoes({ error: getRequestErrorType(e) })];
    })
  );

const deletePhotoEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(DeletePhoto),
    mergeMap(({ photoId }) => {
      const s: T.State = state$.value;
      const photos: T.Photo[] = [...s.Photos.photos];
      const photoIdx: number = photos.findIndex(photo => photo.id === photoId);

      const URL: string = makeV2APIURL('photos', photoId);
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );

      return scheduled(
        [
          rxjsHttp.delete(URL, { headers: authHeader }).pipe(
            mergeMap(() => {
              const newPhotos: T.Photo[] = photos.filter(photo => photo.id !== photoId);
              const actions: Action[] = [];
              if (newPhotos.length === 0) {
                actions.push(SetCurrentPhotoId({ photoId: undefined }));
              } else if (photoIdx !== 0 && photoIdx === newPhotos.length) {
                actions.push(SetCurrentPhotoId({ photoId: newPhotos[photoIdx - 1].id }));
              } else {
                actions.push(SetCurrentPhotoId({ photoId: newPhotos[photoIdx].id }));
              }

              actions.push(UpdatePhotosInStore({ photos: newPhotos.map(APIToPhoto) }));
              actions.push(CloseContentPagePopup());

              return actions;
            }),
            catchError((e: AjaxError) => {
              if (!isErrorIgnorable(e.status)) {
                Sentry.captureException(e);
              }

              return [];
            })
          ),
        ],
        queueScheduler
      ).pipe(concatAll());
    }),
    catchError((e: AjaxError) => {
      if (!isErrorIgnorable(e.status)) {
        Sentry.captureException(e);
      }

      return [];
    })
  );

const deleteVideoEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(DeleteDroneVideo),
    mergeMap(({ videoIds }) => {
      const s: T.State = state$.value;
      const videos: T.DroneVideo[] = [...s.Photos.droneVideos];
      const projectId: T.Project['id'] | undefined = s.Pages.Contents.projectId;
      if (!projectId) {
        return [];
      }
      const URL: string = makeV2APIURL('projects', projectId, 'videos');
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );
      return scheduled(
        [
          rxjsHttp
            .delete(URL, {
              data: { ids: videoIds },
              headers: { ...authHeader, ...jsonContentHeader },
            })
            .pipe(
              mergeMap(() => {
                const newVideos: T.DroneVideo[] = videos.filter(
                  photo => !videoIds.includes(photo.id)
                );
                return [
                  SetCurrentPhotoId({ photoId: undefined }),
                  UpdateDroneVidoesInStore({ droneVideos: newVideos.map(APIToDroneVideo) }),
                  SetSelectedMedia({ selectedMedia: [] }),
                  CloseContentPagePopup(),
                  FinishDeleteBulkPhotos({}),
                ];
              }),
              catchError((e: AjaxError) => {
                if (!isErrorIgnorable(e.status)) {
                  Sentry.captureException(e);
                }

                return [FinishDeleteBulkPhotos({ error: getRequestErrorType(e) })];
              })
            ),
        ],
        queueScheduler
      ).pipe(concatAll());
    }),
    catchError((e: AjaxError) => {
      if (!isErrorIgnorable(e.status)) {
        Sentry.captureException(e);
      }

      return [];
    })
  );

const deleteBulkPhotosEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(DeleteBulkPhotos),
    mergeMap(({ photoIds, videoIds }) => {
      const s: T.State = state$.value;
      const photos: T.Photo[] = [...s.Photos.photos];
      const videos: T.DroneVideo[] = [...s.Photos.droneVideos];
      const projectId: T.Project['id'] | undefined = s.Pages.Contents.projectId;
      if (!projectId) {
        return [];
      }
      const URL: string = makeV2APIURL('projects', projectId, 'medias', 'bulk');
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );
      return scheduled(
        [
          rxjsHttp
            .delete(URL, {
              data: { photoIds, videoIds },
              headers: { ...authHeader, ...jsonContentHeader },
            })
            .pipe(
              mergeMap(() => {
                const newPhotos: T.Photo[] = photos.filter(photo => !photoIds.includes(photo.id));
                const newVideos: T.DroneVideo[] = videos.filter(
                  video => !videoIds.includes(video.id)
                );
                return [
                  SetCurrentPhotoId({ photoId: undefined }),
                  UpdatePhotosInStore({ photos: newPhotos.map(APIToPhoto) }),
                  UpdateDroneVidoesInStore({ droneVideos: newVideos.map(APIToDroneVideo) }),
                  SetSelectedMedia({ selectedMedia: [] }),
                  CloseContentPagePopup(),
                  FinishDeleteBulkPhotos({}),
                ];
              }),
              catchError((e: AjaxError) => {
                if (!isErrorIgnorable(e.status)) {
                  Sentry.captureException(e);
                }

                return [FinishDeleteBulkPhotos({ error: getRequestErrorType(e) })];
              })
            ),
        ],
        queueScheduler
      ).pipe(concatAll());
    }),
    catchError((e: AjaxError) => {
      if (!isErrorIgnorable(e.status)) {
        Sentry.captureException(e);
      }

      return [];
    })
  );

const editTagPhotoDateEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(EditTagPhotoDate),
    mergeMap(({ tagMedia, takenAt }) => {
      const s: T.State = state$.value;
      const photos: T.Photo[] = [...s.Photos.photos];
      const videos: T.DroneVideo[] = [...s.Photos.droneVideos];
      const projectId: T.Project['id'] | undefined = s.Pages.Contents.projectId;
      if (!projectId) {
        return [];
      }

      // Update photos array with new takenAt value
      tagMedia.forEach(media => {
        const photoIdx: number = photos.findIndex(
          photo => photo.id === media.contentId && media.type !== 'overlay'
        );
        photos[photoIdx] = {
          ...photos[photoIdx],
          takenAt,
        };
      });
      tagMedia.forEach(media => {
        const videoIdx: number = videos.findIndex(
          video => video.id === media.contentId && media.type === 'overlay'
        );
        videos[videoIdx] = {
          ...videos[videoIdx],
          takenAt,
        };
      });
      // const URL: string = makeV2APIURL('projects', projectId, 'photos', 'bulk');
      const URL: string = makeV2APIURL('projects', projectId, 'medias', 'bulk');
      const header: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );
      const photoIds = tagMedia
        .filter(media => media.type !== 'overlay')
        .map(media => media.contentId);
      const videoIds = tagMedia
        .filter(media => media.type === 'overlay')
        .map(media => media.contentId);
      const postPhotos$: Observable<Action> = rxjsHttp
        .patch(
          URL,
          {
            photoIds,
            videoIds,
            takenAt: takenAt.toISOString(),
          },
          { headers: { ...header, ...jsonContentHeader } }
        )
        .pipe(
          map(({ response }): T.Photo => APIToPhoto(response.data)),
          mergeMap(() => [FinishPostPhoto()]),
          catchError((e: AjaxError) => {
            // eslint-disable-next-line no-console
            console.error(e);
            if (!isErrorIgnorable(e.status)) {
              Sentry.captureException(e);
            }

            return [FinishPostPhoto()];
          })
        );

      // Dispatch UpdatePhotosInStore action with updated photos array
      return concat(
        of(UpdatePhotosInStore({ photos: photos.map(APIToPhoto) })),
        of(UpdateDroneVidoesInStore({ droneVideos: videos.map(APIToDroneVideo) })),
        scheduled([postPhotos$], queueScheduler).pipe(concatAll())
      );
    }),
    catchError((e: AjaxError) => {
      if (!isErrorIgnorable(e.status)) {
        Sentry.captureException(e);
      }

      return [];
    })
  );

export const epic: Epic<Action, Action, T.State> = combineEpics(
  getPhotosEpic,
  getDroneVideosEpic,
  deletePhotoEpic,
  deleteVideoEpic,
  deleteBulkPhotosEpic,
  editTagPhotoDateEpic
);
// Redux reducer
const initialState: T.PhotosState = {
  photos: [],
  droneVideos: [],
  currentPhotoId: undefined,
  currentVideoId: undefined,
  uploadedPhotoCount: 0,
  photoOnMapId: undefined,
  selectedMedia: [],
  tagPhotoIds: [],
  isDownloading: false,
  dateType: T.PhotoDateType.SORTED_PHOTO,
  droneDate: undefined,
  hoveredId: undefined,
  videoOnMapId: undefined,
  hoveredVideoId: undefined,
  getPhotoStatus: T.APIStatus.IDLE,
  getDroneVidoeStatus: T.APIStatus.IDLE,
  bulkDeletePhotoStatus: T.APIStatus.IDLE,
  selectedSourceAlbum: undefined,
};

const reducer: Reducer<T.PhotosState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case GetPhotos.type:
      return {
        ...state,
        getPhotoStatus: T.APIStatus.PROGRESS,
      };
    case UpdatePhotosInStore.type:
      return {
        ...state,
        photos: action.photos,
      };
    case UpdateDroneVidoesInStore.type:
      return {
        ...state,
        droneVideos: action.droneVideos,
      };
    case SetCurrentPhotoId.type:
      return {
        ...state,
        currentPhotoId: action.photoId,
      };
    case SetCurrentVideoId.type:
      return {
        ...state,
        currentVideoId: action.videoId,
      };
    case SetPhotoOnMapId.type:
      return {
        ...state,
        photoOnMapId: action.photoId,
      };
    case SetVideoOnMapId.type:
      return {
        ...state,
        videoOnMapId: action.videoId,
      };
    case SetHoveredVideoId.type:
      return {
        ...state,
        hoveredVideoId: action.hoveredVideoId,
      };
    case SetSelectedMedia.type:
      return {
        ...state,
        selectedMedia: action.selectedMedia,
      };
    case SetPhotoDateType.type:
      return {
        ...state,
        dateType: action.dateType,
      };
    case SetPhotoDate.type:
      return {
        ...state,
        droneDate: action.droneDate,
      };
    case SetUploadedPhotoCount.type:
      return {
        ...state,
        uploadedPhotoCount: action.count,
      };
    case SetDownloadPhotoState.type:
      return {
        ...state,
        isDownloading: action.isDownloading,
      };

    case setSourcePhotoContentId.type:
      return {
        ...state,
        selectedSourceAlbum: action.contentId,
      };
    case SetHoveredPhotoId.type:
      return {
        ...state,
        hoveredId: action.hoveredId,
      };
    case FinishGetPhotos.type:
      return {
        ...state,
        getPhotoStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
      };

    case FinishGetDroneVidoes.type:
      return {
        ...state,
        getDroneVidoeStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
      };
    case StartDeleteBulkPhotos.type: {
      return {
        ...state,
        bulkDeletePhotoStatus: T.APIStatus.PROGRESS,
      };
    }
    case ResetDeleteBulkPhotos.type: {
      return {
        ...state,
        bulkDeletePhotoStatus: T.APIStatus.IDLE,
      };
    }
    case DeleteBulkPhotos.type:
      return {
        ...state,
        bulkDeletePhotoStatus: T.APIStatus.PROGRESS,
      };
    case FinishDeleteBulkPhotos.type:
      return {
        ...state,
        bulkDeletePhotoStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
      };
    default:
      return state;
  }
};

export default reducer;
