import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import ContentsListAreaItem from '^/components/molecules/ContentsListAreaItem';
import { ContentsListBimtItem } from '^/components/molecules/ContentsListBimItem';
import { ContentsListBlueprintItem } from '^/components/molecules/ContentsListBlueprintItem';
import { ContentsListESSArrowItem } from '^/components/molecules/ContentsListESSArrowItem';
import { ContentsListESSCustomModelItem } from '^/components/molecules/ContentsListESSCustomModelItem';
import { ContentsListESSModelItem } from '^/components/molecules/ContentsListESSModelItem';
import { ContentsListESSTextItem } from '^/components/molecules/ContentsListESSTextItem';
import { ContentsListGCPGroupItem } from '^/components/molecules/ContentsListGCPGroupItem';
import { ContentsListIssueItem } from '^/components/molecules/ContentsListIssueItem';
import ContentsListLengthItem from '^/components/molecules/ContentsListLengthItem';
import { ContentsListMapItem } from '^/components/molecules/ContentsListMapItem';
import { ContentsListMarkerItem } from '^/components/molecules/ContentsListMarkerItem';
import { ContentsListPointCloudItem } from '^/components/molecules/ContentsListPointCloudItem';
import ContentsListThreeAreaItem from '^/components/molecules/ContentsListThreeAreaItem';
import { ContentsListThreeDMeshItem } from '^/components/molecules/ContentsListThreeDMeshItem';
import { ContentsListThreeDOrthoItem } from '^/components/molecules/ContentsListThreeDOrthoItem';
import ContentsListThreeHeightItem from '^/components/molecules/ContentsListThreeHeightItem';
import ContentsListThreeLengthItem from '^/components/molecules/ContentsListThreeLengthItem';
import { ContentsListVolumeItem } from '^/components/molecules/ContentsListVolumeItem';
import { GroupList } from '^/components/organisms/ContentsTreeList';
import { useIsContentHiddenByFilter } from '^/hooks/useContentsSearchFilter';
import { useESSContentsStore } from '^/store/essContentsStore';
import * as T from '^/types';
import { isContentPinned } from '^/utilities/content-util';
import { withFeatureToggle } from '^/utilities/withFeatureToggle';
import { ContentsListESSLineTextItem } from '^/components/molecules/ContentsListESSLineTextItem';
import { useIsInView } from '^/hooks/useIsInView';
import ContentItemSkeletonLoader from '../ContentItemSkeletonLoader';
import ContentsListTerrainFlatItem from '^/components/molecules/ContentsListTerrainFlatItem';
import { ContentsListFlightPlanItem } from '^/components/molecules/ContentsListFlightPlanItem';
import { useFlightPlanStore } from '^/store/flightPlanStore';
import { ContentsListFlightScheduleItem } from '^/components/molecules/ContentsListFlightScheduleItem';
import { useFlightScheduleStore } from '^/store/flightScheduleStore';
import { useIssueStore } from '^/store/issue';
import { ContentsListDSMDTMItem } from '^/components/molecules/ContentsListDSMDTMItem';
import { useContentsStore } from '^/store/zustand/content/contentStore';

interface ListItemProps<Content> {
  readonly content: Content;
  readonly isPinned?: boolean;
}

type ExcludeContentType<T> = T extends
  | T.ContentType.DTM
  | T.ContentType.SLIDE
  | T.ContentType.ANNOTATION
  ? never
  : T;

const contentTypeListItem: {
  [K in ExcludeContentType<T.Content['type']>]: FC<ListItemProps<T.Content>>;
} = {
  [T.ContentType.AREA]: withFeatureToggle(T.Feature.DDM)(ContentsListAreaItem),
  [T.ContentType.THREE_AREA]: withFeatureToggle(T.Feature.DDM)(ContentsListThreeAreaItem),
  [T.ContentType.LENGTH]: withFeatureToggle(T.Feature.DDM)(ContentsListLengthItem),
  [T.ContentType.THREE_LENGTH]: withFeatureToggle(T.Feature.DDM)(ContentsListThreeLengthItem),
  [T.ContentType.THREE_HEIGHT]: withFeatureToggle(T.Feature.DDM)(ContentsListThreeHeightItem),
  [T.ContentType.MARKER]: withFeatureToggle(T.Feature.DDM)(ContentsListMarkerItem),
  [T.ContentType.VOLUME]: withFeatureToggle(T.Feature.DDM)(ContentsListVolumeItem),
  [T.ContentType.BLUEPRINT_PDF]: withFeatureToggle(T.Feature.DDM)(ContentsListBlueprintItem),
  [T.ContentType.BLUEPRINT_DXF]: withFeatureToggle(T.Feature.DDM)(ContentsListBlueprintItem),
  [T.ContentType.BLUEPRINT_DWG]: withFeatureToggle(T.Feature.DDM)(ContentsListBlueprintItem),
  [T.ContentType.BIM]: withFeatureToggle(T.Feature.DDM)(ContentsListBimtItem),

  [T.ContentType.DESIGN_DXF]: withFeatureToggle(T.Feature.DDM)(ContentsListBlueprintItem),
  [T.ContentType.MAP]: withFeatureToggle(T.Feature.DDM)(ContentsListMapItem),
  [T.ContentType.DSM]: withFeatureToggle(T.Feature.DDM)(ContentsListDSMDTMItem),
  // [T.ContentType.DTM]: withFeatureToggle(T.Feature.DDM)(ContentsListMapItem),
  [T.ContentType.POINTCLOUD]: withFeatureToggle(T.Feature.DDM)(ContentsListPointCloudItem),
  // These two should be available on both ESS and DDM.
  [T.ContentType.THREE_D_MESH]: ContentsListThreeDMeshItem,
  [T.ContentType.THREE_D_ORTHO]: ContentsListThreeDOrthoItem,
  // [T.ContentType.DTM]: withFeatureToggle(T.Feature.DDM)(ContentsListMapItem),
  [T.ContentType.GCP_GROUP]: withFeatureToggle(T.Feature.DDM)(ContentsListGCPGroupItem),
  [T.ContentType.ESS_MODEL]: withFeatureToggle(T.Feature.ESS)(ContentsListESSModelItem),
  [T.ContentType.ESS_MODEL_CUSTOM]: withFeatureToggle(T.Feature.ESS)(
    ContentsListESSCustomModelItem
  ),
  [T.ContentType.ESS_ARROW]: withFeatureToggle(T.Feature.ESS)(ContentsListESSArrowItem),
  [T.ContentType.ESS_POLYGON]: withFeatureToggle(T.Feature.ESS)(ContentsListESSArrowItem),
  [T.ContentType.ESS_POLYLINE]: withFeatureToggle(T.Feature.ESS)(ContentsListESSArrowItem),
  [T.ContentType.ESS_TEXT]: withFeatureToggle(T.Feature.ESS)(ContentsListESSTextItem),
  [T.ContentType.ESS_LINE_TEXT]: withFeatureToggle(T.Feature.ESS)(ContentsListESSLineTextItem),

  // Workaround fix because GroupList is imported dynamically, it is undefined at first
  // [T.ContentType.GROUP]: GroupList,
  [T.ContentType.GROUP]: (props: { content: T.GroupContent }) => <GroupList {...props} />,
  [T.ContentType.ISSUE_POINT]: withFeatureToggle(T.Feature.DDM)(ContentsListIssueItem),
  [T.ContentType.FLATTEN_MAP]: withFeatureToggle(T.Feature.DDM)(ContentsListTerrainFlatItem),
  [T.ContentType.ISSUE_PHOTO]: withFeatureToggle(T.Feature.DDM)(ContentsListIssueItem),

  [T.ContentType.FLIGHT_PLAN]: withFeatureToggle(T.Feature.DDM)(ContentsListFlightPlanItem),
  [T.ContentType.FLIGHT_SCHEDULE]: withFeatureToggle(T.Feature.DDM)(ContentsListFlightScheduleItem),
  [T.ContentType.DRONE_STATION]: withFeatureToggle(T.Feature.DDM)(ContentsListFlightPlanItem),
};

interface Props {
  contentId?: T.Content['id'];
  isESS?: boolean;
  isIssueContent?: boolean;
  isFlightPlan?: boolean;
  isFlightSchedule?: boolean;
}

export const ContentToItem: FC<Props> = ({
  contentId,
  isESS,
  isFlightPlan,
  isFlightSchedule,
  isIssueContent,
}) => {
  const { editingESSContentId, essContents } = useESSContentsStore(s => ({
    editingESSContentId: s.editingESSContentId,
    essContents: s.essContents,
  }));

  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const mainEditingContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);
  const isMap = sidebarTab === T.ContentPageTabType.MAP;
  const flightPlans = useFlightPlanStore(s => s.flightPlans);
  const flightSchedules = useFlightScheduleStore(s => s.flightSchedules);
  const { issuesContentsById, editingIssueContentId } = useIssueStore();
  const byId = useContentsStore(s => s.contents.byId);
  const updateContents = useContentsStore(s => s.updateContents);

  const content: T.Content | undefined = useMemo(() => {
    if (!contentId) {
      return undefined;
    }
    if (isESS) {
      return essContents[contentId ?? NaN];
    } else if (isIssueContent) {
      return issuesContentsById[contentId ?? NaN];
    } else if (isFlightPlan) {
      return flightPlans[contentId ?? NaN];
    } else if (isFlightSchedule) {
      return flightSchedules[contentId ?? NaN];
    } else {
      return byId[contentId ?? NaN];
    }
  }, [
    contentId,
    isESS,
    isIssueContent,
    isFlightPlan,
    isFlightSchedule,
    essContents,
    issuesContentsById,
    flightPlans,
    flightSchedules,
    byId,
  ]);

  const isHidden = useIsContentHiddenByFilter(content, isESS);
  const [ref, isInView] = useIsInView<HTMLSpanElement>(isHidden);

  const editingContentId: T.ContentsPageState['editingContentId'] = isIssueContent
    ? editingIssueContentId
    : isESS
    ? editingESSContentId
    : mainEditingContentId;

  const handleScrollIntoView = useCallback(() => {
    if (!ref?.current) {
      return;
    }
    scrollIntoViewIfNeeded(ref.current as Element, {
      scrollMode: 'if-needed',
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, [ref.current]);

  const isEditing: boolean = useMemo(
    () => content?.id === editingContentId,
    [content?.id, editingContentId]
  );

  useEffect(() => {
    if (isEditing) {
      window.setTimeout(handleScrollIntoView);
    }
  }, [isEditing]);

  //  this check insures contentType group is loaded initially.
  const isGroupContent = content?.type === T.ContentType.GROUP;
  useEffect(() => {
    if (isGroupContent && isMap) {
      updateContents([
        {
          ...content,
          info: {
            ...content.info,
            isOpened: false,
          },
        },
      ]);
    }
  }, [isGroupContent, isMap]);

  if (
    content === undefined ||
    content.type === T.ContentType.SLIDE ||
    (isHidden && content.type !== T.ContentType.GROUP) ||
    content.type === T.ContentType.DTM ||
    content.type === T.ContentType.ANNOTATION
  ) {
    return null;
  }

  const isPinned = isContentPinned(content);
  const ListItem: FC<ListItemProps<T.Content>> = contentTypeListItem[content.type];

  //  check is a temporary fix.
  const isPersonalESSContent = isESS && content?.isPersonal;

  return (
    <span ref={ref} key={`content-to-item-span-${content?.id}`}>
      {isInView || isPersonalESSContent || isGroupContent ? (
        <ListItem key={`content-to-item-${content?.id}`} content={content} isPinned={isPinned} />
      ) : (
        <ContentItemSkeletonLoader key={`skeleton-content-to-item-${content?.id}`} />
      )}
    </span>
  );
};
