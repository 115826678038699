import { useSidebarStore } from '^/store/sidebarStore';
import { Dispatch, SetStateAction, useEffect } from 'react';

export const MIN_SIDEBAR_WIDTH = 366;
export const MAX_SIDEBAR_WIDTH = 700;

export const useSidebarResize = (
  setWidth: Dispatch<SetStateAction<number>>
  //   setIsResizing: Dispatch<SetStateAction<boolean>>,
  //   isResizing: boolean
) => {
  const isResizing = useSidebarStore(s => s.isResizing);
  const setIsResizing = useSidebarStore(s => s.setIsResizing);
  const setSidebarWidth = useSidebarStore(s => s.setSlidePanelWidth);

  const startResizing = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
  };

  const stopResizing = () => {
    setIsResizing(false);
  };

  const resize = (e: MouseEvent) => {
    if (!isResizing) {
      return;
    }

    let newWidth = e.clientX;

    if (newWidth < MIN_SIDEBAR_WIDTH) {
      newWidth = MIN_SIDEBAR_WIDTH;
    } else if (newWidth > MAX_SIDEBAR_WIDTH) {
      newWidth = MAX_SIDEBAR_WIDTH;
    }
    setSidebarWidth(newWidth);
    sessionStorage.setItem('sidebar_width', JSON.stringify(newWidth));
    setWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    if (isResizing) {
      document.body.classList.add('resizing');
    } else {
      document.body.classList.remove('resizing');
    }
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing]);

  useEffect(() => {
    const sidebarWidth = sessionStorage.getItem('sidebar_width');
    if (sidebarWidth) {
      setWidth(Number(sidebarWidth));
    }
  }, []);

  return {
    isResizing,
    startResizing,
  };
};
