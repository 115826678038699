/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { MERGE_MATERIAL } from '../constant';
import {
  Euler,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PerspectiveCamera,
  PlaneGeometry,
  Quaternion,
  Scene,
  Vector3,
  BufferGeometry,
  Group,
} from 'three';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
// import { Viewer } from '../../ThreeInteraction/Viewer';
import { TerrainSketchObject } from '../../ThreeObjects/Terrain';

import { MeshBVH } from 'three-mesh-bvh';
export const mergeTileset = (tileset: Mesh | Object3D | Group): Mesh => {
  const tempGeometry: BufferGeometry[] = [];
  const objects: Mesh[] = [];
  tileset.traverse((object: any) => {
    if (object instanceof Mesh && object.visible) {
      if (object.name === 'sketchMesh') {
        const parent = object.parent as TerrainSketchObject;
        if (parent.tile.parent!.visible) {
          objects.push(parent.tile);
        }
      } else {
        if (object.parent?.visible) {
          objects.push(object);
        }
      }
    }
  });
  objects.forEach((v: Mesh) => {
    v.updateMatrixWorld();
    const cloneBufferGeometry = v.geometry.clone();
    cloneBufferGeometry.applyMatrix4(v.matrixWorld);
    tempGeometry.push(cloneBufferGeometry);
    if (!cloneBufferGeometry.attributes.normal) {
      cloneBufferGeometry.computeVertexNormals();
    }
    // for (const mesh of v.children) {

    //   // if (mesh instanceof Mesh && mesh.visible) {
    //   //   let _mesh: Mesh = mesh;
    //   //   if (mesh.name === 'sketchMesh') {
    //   //     const parent = mesh.parent as TerrainSketchObject;
    //   //     _mesh = parent.tile as Mesh;
    //   //   }

    //   // }
    // }
  });
  if (tempGeometry.length === 0) {
    const mergeMesh = new Mesh(new BufferGeometry(), MERGE_MATERIAL);
    return mergeMesh;
  } else {
    const merge = mergeGeometries(tempGeometry);
    merge.computeVertexNormals();
    // merge.computeBoundsTree();
    merge.boundsTree = new MeshBVH(merge);
    const mergeMesh = new Mesh(merge, MERGE_MATERIAL);
    return mergeMesh;
  }
};

/* eslint-disable no-param-reassign */

/**
 * This help parse aircraft convention orientation to matrix
 * @param {any} panoramic
 */
export const parseAircraftConventionOrientationToMatrix = (panoramic: any, order: any = '') => {
  const euler = new Euler(
    degToRad(panoramic.tilt),
    degToRad(panoramic.azimuth),
    degToRad(panoramic.roll),
    order //'YXZ'
  );
  return new Matrix4().makeRotationFromEuler(euler);
};
/**
 * This help create drone camera
 * @param {any} panoramic
 */

export function createDroneCamera(
  scene: Scene,
  coord: any,
  EnhToOrientationUp: Vector3,
  EnhToOrientationLookAt: Vector3,
  rotMatrix: Matrix4,
  orientationToCameraUp: Vector3,
  orientationToCameraLookAt: Vector3,
  distance: number,
  size: number[],
  focale: number,
  videoCamera?: PerspectiveCamera | undefined
) {
  const fov = radToDeg(2 * Math.atan(size[1] / 2 / focale));
  const coordView = coord.as('EPSG:4326');
  // create 'local space', with the origin placed on 'coord',
  // with Y axis to the north, X axis to the east and Z axis as the geodesic normal.
  const localSpace = new Object3D();
  localSpace.up.set(0, 1, 0);
  localSpace.updateMatrixWorld();
  scene.add(localSpace);
  // placeObjectFromCoordinate(localSpace, coordView);
  coordView.toVector3(localSpace.position);
  localSpace.lookAt(localSpace.position);

  // add second object : 'oriented image'
  const orientedImage = new Object3D();
  orientedImage.up.set(0, 1, 0);
  orientedImage.updateMatrixWorld();
  // setup initial convention orientation.
  orientedImage.up.copy(EnhToOrientationUp);
  orientedImage.lookAt(EnhToOrientationLookAt);

  // place the 'oriented image' in the 'local space'
  localSpace.add(orientedImage);

  // apply rotation
  const quaternion = new Quaternion().setFromRotationMatrix(rotMatrix);
  orientedImage.quaternion.multiply(quaternion);
  // orientedImage.updateMatrixWorld();

  // create a THREE JS Camera
  const camera = videoCamera ?? new PerspectiveCamera(fov, size[0] / size[1], 1, distance * 2);
  camera.up.copy(orientationToCameraUp);
  camera.lookAt(orientationToCameraLookAt);
  camera.updateProjectionMatrix();
  orientedImage.add(camera);

  localSpace.updateMatrixWorld(true);
  return {
    camera,
    localSpace,
  };
}

/**
 * This help create plane for drone camera
 * @param {PerspectiveCamera} camera
 * @param  {number} distance
 * @param { { main: Mesh; border: Mesh } | undefined} planeElement
 */
export function setupPictureFromCamera(
  camera: PerspectiveCamera,
  distance: number,
  planeElement?: { main: Mesh; border: Mesh } | undefined
): any {
  let plane: { main: Mesh; border: Mesh } | undefined = planeElement;
  if (!plane?.main) {
    plane = createTexturedPlane();
  }

  // plane.main.renderOrder = 999;
  // plane.main.material.depthTest = false;
  transformTexturedPlane(camera, distance, plane.main);
  transformTexturedPlane(camera, distance + 0.2, plane.border);
  return { main: plane.main, border: plane.border };
}

function createTexturedPlane() {
  const geometry = new PlaneGeometry(1, 1, 32);
  const material = new MeshBasicMaterial({
    color: 'white',
    transparent: true,
  });
  const borderGeometry = new PlaneGeometry(1.02, 1.02, 32);
  const borderMaterial = new MeshBasicMaterial({
    color: 'white',
    transparent: true,
  });
  return {
    main: new Mesh(geometry, material),
    border: new Mesh(borderGeometry, borderMaterial),
  };
}
/**
 * This help adjust the plane
 * @param {PerspectiveCamera} camera
 * @param  {number} distance
 * @param  {Mesh} plane
 */
function transformTexturedPlane(camera: PerspectiveCamera, distance: number, plane: Mesh) {
  const Yreel = 2 * Math.tan(degToRad(camera.fov / 2)) * distance;
  const Xreel = camera.aspect * Yreel;

  // set position and scale
  plane.scale.set(Xreel, Yreel, 1);
  plane.position.set(0, 0, -distance);
  plane.updateMatrixWorld();
}

/**
 * This help setup the vector up of camera
 * @param {PerspectiveCamera} camera
 * @param  {PerspectiveCamera} targetCamera
 */
export function setupViewCameraDecomposing(targetCamera: PerspectiveCamera): PerspectiveCamera {
  let upWorld;
  const object = new PerspectiveCamera();
  targetCamera.matrixWorld.decompose(object.position, object.quaternion, object.scale);
  object.up.copy(targetCamera.up);
  object.updateProjectionMatrix();
  // setup up vector
  upWorld = targetCamera.localToWorld(targetCamera.up.clone());
  upWorld = object.position.clone().sub(upWorld);
  object.up.copy(upWorld);
  return object;
}
