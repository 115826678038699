import { BufferGeometry, Float32BufferAttribute } from 'three';

export class CadNodeGeometry extends BufferGeometry {
  /**
   * Map node geometry constructor.
   *
   * @param width - Width of the node.
   * @param height - Height of the node.
   * @param widthSegments - Number of subdivisions along the width.
   * @param heightSegments - Number of subdivisions along the height.
   */
  public constructor(
    width: number = 1.0,
    height: number = 1.0,
    widthSegments: number = 1.0,
    heightSegments: number = 1.0
  ) {
    super();

    // Buffers
    const indices: number[] = [];
    const vertices: number[] = [];
    const normals: number[] = [];
    const uvs: number[] = [];

    // Build plane
    this.buildPlane(width, height, widthSegments, heightSegments, indices, vertices, normals, uvs);

    this.setIndex(indices);
    this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
    this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
    this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
  }

  public buildPlane(
    width: number = 1.0,
    height: number = 1.0,
    widthSegments: number = 1.0,
    heightSegments: number = 1.0,
    indices: number[],
    vertices: number[],
    normals: number[],
    uvs: number[]
  ): void {
    // Half width X
    const widthHalf = width / 2;

    // Half width Z
    const heightHalf = height / 2;

    // Size of the grid in X
    const gridX = widthSegments + 1;

    // Size of the grid in Z
    const gridZ = heightSegments + 1;

    // Width of each segment X
    const segmentWidth = width / widthSegments;

    // Height of each segment Z
    const segmentHeight = height / heightSegments;

    // Generate vertices, normals and uvs
    for (let iz = 0; iz < gridZ; iz++) {
      const z = iz * segmentHeight - heightHalf;

      for (let ix = 0; ix < gridX; ix++) {
        const x = ix * segmentWidth - widthHalf;

        vertices.push(x, 0, z);
        normals.push(0, 1, 0);
        uvs.push(ix / widthSegments, 1 - iz / heightSegments);
      }
    }

    // Indices
    for (let iz = 0; iz < heightSegments; iz++) {
      for (let ix = 0; ix < widthSegments; ix++) {
        const a = ix + gridX * iz;
        const b = ix + gridX * (iz + 1);
        const c = ix + 1 + gridX * (iz + 1);
        const d = ix + 1 + gridX * iz;

        // Faces
        indices.push(a, b, d, b, c, d);
      }
    }
  }
}
