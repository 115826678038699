import { Language } from '^/types';

export default {
  firstBalloonTitle: {
    [Language.KO_KR]: '면적',
    [Language.EN_US]: 'Area',
  },
  clamped: {
    [Language.KO_KR]: '지면에 고정',
    [Language.EN_US]: 'Clamped',
  },
  horizontalArea: {
    [Language.KO_KR]: '수평 면적',
    [Language.EN_US]: 'Horizontal Area',
  },
  surfaceArea: {
    [Language.KO_KR]: '표면적',
    [Language.EN_US]: 'Surface Area',
  },
  horizontalAreaTooltip: {
    [Language.KO_KR]: '동일 고도선상의 단면적',
    [Language.EN_US]: 'Area of the cross-section along the same elevation',
  },
  surfaceAreaTooltip: {
    [Language.KO_KR]: '지형의 사면 또는 굴곡을 포함한 면적',
    [Language.EN_US]: 'Area including slopes and curves of the terrain',
  },
  inaccurateMeasurementMessage: {
    [Language.KO_KR]: '부정확성을 최소화하기 위해 측정값이 숨겨집니다.',
    [Language.EN_US]: 'Measurements hidden to avoid inaccuracy.',
  },
};
