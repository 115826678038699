import * as T from '^/types';
import { Movable } from '^/types';
import { exhaustiveCheck } from '^/utilities/exhaustive-check';
/**
 * Returns the index from a list depending on the move option.
 */
export const getIndexFromList: (
  list: Array<T.Content['id']>,
  moveOption: T.MoveOption,
  id?: T.Content['id']
) => number = (list, moveOption, id) => {
  switch (moveOption) {
    case T.MoveOption.FIRST: {
      return 0;
    }
    case T.MoveOption.PREVIOUS:
    case T.MoveOption.NEXT: {
      if (id === undefined) {
        // eslint-disable-next-line no-console
        logWarning('Target id not supplied to get the index of the move target.');

        return NaN;
      }

      return list.indexOf(id) + (moveOption === T.MoveOption.NEXT ? 1 : 0);
    }
    case T.MoveOption.LAST: {
      return list.length;
    }
    default: {
      exhaustiveCheck(moveOption);
    }
  }
};

export const addIdWhenInexist: (
  array: Array<T.Content['id']>,
  id: T.Content['id'],
  index: number
) => Array<T.Content['id']> = (array, id, index) => {
  if (array.includes(id)) {
    return array;
  }

  return array.slice(0, index).concat([id]).concat(array.slice(index, array.length));
};

export const logWarning = (message: string, ...args: Movable[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(message, ...args);
  }
};
