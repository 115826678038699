/* eslint-disable max-lines */
import FlightScheduleSelectionDropdown, {
  Option,
  createOptions,
} from '^/components/atoms/Dropdown/ForFlightSchedule';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { SideBar } from '^/constants/zindex';
import { UseL10n, useL10n } from '^/hooks';
import { useGetDroneStationsQuery } from '^/store/react-query/drone-station/drone-station';
import { useGetFlightPlansQuery } from '^/store/react-query/drone-station/flight-plan';
import {
  FlightScheduleFieldsValue,
  useFlightScheduleCreationStore,
} from '^/store/flightScheduleCreationStore';
import * as T from '^/types';
import React, { FC, ReactNode, memo, useCallback, useMemo } from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars-2';
import styled, { CSSObject } from 'styled-components';
import CustomFlightScheduleInput from '../../CustomFlightScheduleInput';
import FlightScheduleDatePicker from '../../FlightScheduleDatePicker';
import FlightScheduleTimePicker from '../../FlightScheduleTimePicker';
import TextField from '../../TextField';
import Text from './text';

const FlightScheduleUploadWrapper = styled.aside({
  position: 'absolute',
  boxSizing: 'border-box',

  zIndex: SideBar.FLIGHT_SCHEDULE_CREATION,

  width: '100%',
  height: '100%',
  fontSize: '14px',

  display: 'flex',
  flexDirection: 'column',

  backgroundColor: palette.white.toString(),
});
FlightScheduleUploadWrapper.displayName = FlightScheduleUploadWrapper;

const InputFieldsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const InputItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const labelStyles = {
  fontSize: '14px',
  fontWeight: 'bold',

  marginBottom: '6px',
  color: dsPalette.title.toString(),
};
const Label = styled.p(labelStyles);

const PlanTimerWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(3,1fr)',

  border: `1px solid ${palette.DDMInput.inputBorder.toString()}`,
  borderRadius: '4px',

  overflow: 'hidden',

  fontSize: '13px',
});

const LostActionsWrapper = styled(PlanTimerWrapper)({});

const PlanTimerBaseChild = styled.div({
  fontSize: '14px',

  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const Button = styled.button<{
  isSelected: boolean;
  customStyles?: CSSObject;
  isBorderRequired?: boolean;
}>(({ isSelected, customStyles, isBorderRequired }) => ({
  padding: '8px',

  color: dsPalette.title.toString(),
  backgroundColor: isSelected ? palette.itemBackground.toString() : palette.white.toString(),

  cursor: 'pointer',
  borderLeft: isBorderRequired ? `1px solid ${palette.DDMInput.inputBorder.toString()}` : '0px',
  borderRight: isBorderRequired ? `1px solid ${palette.DDMInput.inputBorder.toString()}` : '0px',

  ':hover': {
    backgroundColor: palette.itemBackground.toString(),
  },

  ...customStyles,
}));

const SingleFlightPlanWrapper = styled.div({
  padding: '12px',

  fontSize: '13px',
  color: palette.issue.sidebarItemFont.toString(),
  background: palette.white.toString(),

  border: `1px solid ${palette.DDMInput.inputBorder.toString()}`,
  borderRadius: '4px',

  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});
const SingleDeviceWrapper = styled(SingleFlightPlanWrapper)({});

const FlightPlanInfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  pointerEvents: 'visible',
});
const DeviceInfoWrapper = styled(FlightPlanInfoWrapper)({});

const FlightPlanInfoTitle = styled.div({});
const DeviceInfoTitle = styled.div({});

const PlanTimerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

interface PlanTimersListType {
  label: string;
  value: T.FlightScheduleScheduleType;
}

interface LostActionsListType {
  label: string;
  value: T.FlightScheduleLostActionType;
}

function getCmInputStyles(hasError?: boolean) {
  const cmInputStyles = {
    width: '100%',
    textAlign: 'left',
    paddingLeft: '12px',
    height: '37px',
    // color: dsPalette.title.toString(),

    backgroundColor: hasError ? palette.DDMInput.error.alpha(0.05).toString() : undefined,
    border: `1px solid ${(hasError
      ? palette.DDMInput.error
      : palette.DDMInput.inputBorder
    ).toString()}`,

    '::placeholder': {
      // color: (hasError ? palette.DDMInput.error : palette.dividerLight).toString(),
      fontWeight: '400',
      fontSize: '12px',
    },
  };
  return cmInputStyles;
}

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const ScrollBarsWrapper = styled(Scrollbars)<ScrollbarProps>({
  height: '100%',
  width: '100%',
});
const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

const AltitudeRangeInfo = styled.span<{ error: boolean }>(({ error }) => ({
  fontStyle: 'italic',
  fontSize: '12px',
  color: error ? palette.error.toString() : dsPalette.themeSecondary.toString(),
}));

const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

const FlightScheduleDetails = () => {
  const [l10n]: UseL10n = useL10n();
  const {
    flightScheduleFields,
    FlightScheduleErrors,
    setFlightScheduleFields,
    setFlightScheduleFieldsError,
    resetFlightScheduleFields,
    resetSingleFlightScheduleField,
  } = useFlightScheduleCreationStore(s => ({
    flightScheduleFields: s.flightScheduleFields,
    FlightScheduleErrors: s.flightScheduleErrors,
    setFlightScheduleFields: s.setFlightScheduleFields,
    setFlightScheduleFieldsError: s.setFlightScheduleFieldsError,
    resetFlightScheduleFields: s.resetFlightScheduleFields,
    resetSingleFlightScheduleField: s.resetSingleFlightScheduleField,
  }));

  const {
    title,
    selectedFlightPlan,
    selectedDroneStation,
    selectedPlanTimer,
    selectedLostAction,
    rthAltitude,
    selectedExecuteDate,
    selectedExecuteTime,
    selectedExecuteEndTime,
    batteryLevel,
    storageLevel,
  } = flightScheduleFields;
  const {
    title: titleError,
    selectedFlightPlan: selectedFlightPlanError,
    selectedDroneStation: selectedDroneStationError,
    selectedPlanTimer: selectedPlanTimerError,
    selectedLostAction: selectedLostActionError,
    rthAltitude: rthAltitudeError,
    selectedExecuteDate: selectedExecuteDateError,
    selectedExecuteTime: selectedExecuteTimeError,
    selectedExecuteEndTime: selectedExecuteEndTimeError,
    batteryLevel: batteryLevelError,
    storageLevel: storageLevelError,
  } = FlightScheduleErrors;

  const { data: flightPlans } = useGetFlightPlansQuery();
  const { data: droneStations } = useGetDroneStationsQuery();

  const planTimersLists: PlanTimersListType[] = [
    {
      label: l10n(Text.inputFields.planTimerOptions.immediate.label),
      value: T.FlightScheduleScheduleType.IMMEDIATE,
    },
    {
      label: l10n(Text.inputFields.planTimerOptions.timed.label),
      value: T.FlightScheduleScheduleType.TIMED,
    },
    {
      label: l10n(Text.inputFields.planTimerOptions.continuous.label),
      value: T.FlightScheduleScheduleType.CONTINUOUS,
    },
  ];

  const lostActionLists: LostActionsListType[] = [
    {
      label: l10n(Text.inputFields.lostActionsOptions.returnToHome.label),
      value: T.FlightScheduleLostActionType.RETURN_TO_HOME,
    },
    {
      label: l10n(Text.inputFields.lostActionsOptions.hover.label),
      value: T.FlightScheduleLostActionType.HOVER,
    },
    {
      label: l10n(Text.inputFields.lostActionsOptions.land.label),
      value: T.FlightScheduleLostActionType.LAND,
    },
  ];

  const allFlightPlans = (flightPlans?.pages.flatMap(single => single.list) ??
    []) as T.FlightPlanContent[];
  const allDroneStations = droneStations as T.DroneStationContent[];

  const handleTitleChange = useCallback(
    (str: string) => {
      setFlightScheduleFields('title', str);
      setFlightScheduleFieldsError('title', false);
    },
    [title, titleError]
  );

  const onClickFlightPlanOption = useCallback(
    (option: Option<T.FlightPlanContent>) => {
      const _selectedFlightPlan = allFlightPlans.find(single => single.id === option.infos.id);
      setFlightScheduleFields('selectedFlightPlan', _selectedFlightPlan);

      setFlightScheduleFieldsError('selectedFlightPlan', false);
    },
    [allFlightPlans, selectedFlightPlan, selectedFlightPlanError]
  );

  const onClickDroneStationOption = useCallback(
    (option: Option<T.DroneStationContent>) => {
      const _selectedDroneStation = allDroneStations.find(single => single.id === option.infos.id);
      setFlightScheduleFields('selectedDroneStation', _selectedDroneStation);

      setFlightScheduleFieldsError('selectedDroneStation', false);
    },
    [allDroneStations, selectedDroneStation, selectedDroneStationError]
  );

  const handleRthAltitudeChange = useCallback(
    (_: string, value: number) => {
      setFlightScheduleFields('rthAltitude', value);

      setFlightScheduleFieldsError('rthAltitude', false);
    },
    [rthAltitude, rthAltitudeError]
  );

  const handleBatteryLevelChange = useCallback(
    (_: any, value: number) => {
      setFlightScheduleFields('batteryLevel', value);

      setFlightScheduleFieldsError('batteryLevel', false);
    },
    [batteryLevel, batteryLevelError]
  );

  const handleStorageLevelChange = useCallback(
    (_: any, value: number) => {
      setFlightScheduleFields('storageLevel', value);

      setFlightScheduleFieldsError('storageLevel', false);
    },

    [storageLevel, storageLevelError]
  );

  const handleSelectedExecuteDate = useCallback(
    (value: FlightScheduleFieldsValue) => {
      setFlightScheduleFields('selectedExecuteDate', value);

      setFlightScheduleFieldsError('selectedExecuteDate', false);
    },
    [selectedExecuteDate, selectedExecuteDateError]
  );

  const handleSelectedExecuteTime = useCallback(
    (value: FlightScheduleFieldsValue) => {
      setFlightScheduleFields('selectedExecuteTime', value);

      setFlightScheduleFieldsError('selectedExecuteTime', false);
    },
    [selectedExecuteTime, selectedExecuteTimeError]
  );

  const handleSelectedExecuteEndTime = useCallback(
    (value: FlightScheduleFieldsValue) => {
      setFlightScheduleFields('selectedExecuteEndTime', value);

      setFlightScheduleFieldsError('selectedExecuteEndTime', false);
    },
    [selectedExecuteEndTime, selectedExecuteEndTimeError]
  );

  const tabToButtonLostAction: (
    tabType: LostActionsListType | PlanTimersListType,
    isPlanTimer?: boolean
  ) => ReactNode = (tabType, isPlanTimer) => {
    let isBorderRequired = false,
      isSelected = false,
      onChangeClick;

    const handleLostActionChange = useCallback(() => {
      setFlightScheduleFields('selectedLostAction', (tabType as LostActionsListType).value);
    }, [selectedLostAction, selectedLostActionError]);

    const handlePlanTimerChange = useCallback(() => {
      setFlightScheduleFields('selectedPlanTimer', (tabType as PlanTimersListType).value);
      resetFlightScheduleFields();
    }, [selectedPlanTimer, selectedPlanTimerError]);

    if (isPlanTimer) {
      isBorderRequired = tabType.value === T.FlightScheduleScheduleType.TIMED;
      isSelected = selectedPlanTimer === tabType.value;
      onChangeClick = handlePlanTimerChange;
    } else {
      isBorderRequired = tabType.value === T.FlightScheduleLostActionType.HOVER;
      isSelected = selectedLostAction === tabType.value;
      onChangeClick = handleLostActionChange;
    }

    return (
      <Button
        key={tabType.value}
        isBorderRequired={isBorderRequired}
        onClick={onChangeClick}
        isSelected={isSelected}
      >
        {tabType.label}
      </Button>
    );
  };

  const rawPlanTimerTimedComponent: ReactNode = useMemo(
    () => (
      <PlanTimerBaseChild>
        <FlightScheduleDatePicker
          hasError={selectedExecuteDateError!}
          selectedDate={selectedExecuteDate}
          onResetDate={() => {
            resetSingleFlightScheduleField('selectedExecuteDate');
            setFlightScheduleFieldsError('selectedExecuteDate', false);
          }}
          onSelectedDateChange={handleSelectedExecuteDate}
        />
        <FlightScheduleTimePicker
          hasError={selectedExecuteTimeError!}
          selectedTime={selectedExecuteTime}
          onResetTime={() => {
            resetSingleFlightScheduleField('selectedExecuteTime');
            setFlightScheduleFieldsError('selectedExecuteTime', false);
          }}
          onSelectedTimeChange={handleSelectedExecuteTime}
        />
      </PlanTimerBaseChild>
    ),
    [selectedExecuteDate, selectedExecuteTime, selectedExecuteDateError, selectedExecuteTimeError]
  );

  const rawPlanTimerContinuousComponent: ReactNode = useMemo(
    () => (
      <PlanTimerBaseChild>
        <FlightScheduleDatePicker
          hasError={selectedExecuteDateError!}
          selectedDate={selectedExecuteDate}
          onResetDate={() => {
            resetSingleFlightScheduleField('selectedExecuteDate');
            setFlightScheduleFieldsError('selectedExecuteDate', false);
          }}
          onSelectedDateChange={handleSelectedExecuteDate}
        />

        <FlightScheduleTimePicker
          hasError={selectedExecuteTimeError!}
          selectedTime={selectedExecuteTime}
          onResetTime={() => {
            resetSingleFlightScheduleField('selectedExecuteTime');
            setFlightScheduleFieldsError('selectedExecuteTime', false);
          }}
          onSelectedTimeChange={handleSelectedExecuteTime}
        />
        <FlightScheduleTimePicker
          isStartTime={false}
          hasError={selectedExecuteEndTimeError!}
          selectedTime={selectedExecuteEndTime}
          onResetTime={() => {
            resetSingleFlightScheduleField('selectedExecuteEndTime');
            setFlightScheduleFieldsError('selectedExecuteEndTime', false);
          }}
          onSelectedTimeChange={handleSelectedExecuteEndTime}
        />

        <InputItem>
          <CustomFlightScheduleInput
            inputStyle={getCmInputStyles(batteryLevelError)}
            labelStyle={{ ...labelStyles, marginBottom: '14px' }}
            kind="batteryLevel"
            value={batteryLevel}
            onChange={handleBatteryLevelChange}
          />
        </InputItem>
        <InputItem>
          <CustomFlightScheduleInput
            inputStyle={getCmInputStyles(storageLevelError)}
            labelStyle={{ ...labelStyles, marginBottom: '14px' }}
            kind="storageLevel"
            value={storageLevel}
            onChange={handleStorageLevelChange}
          />
        </InputItem>
      </PlanTimerBaseChild>
    ),
    [
      selectedExecuteDate,
      selectedExecuteTime,
      selectedExecuteEndTime,
      batteryLevel,
      storageLevel,
      selectedExecuteDateError,
      selectedExecuteTimeError,
      selectedExecuteEndTimeError,
      batteryLevelError,
      storageLevelError,
    ]
  );

  const rawPlanTimerComponent: ReactNode = (() => {
    switch (selectedPlanTimer) {
      case T.FlightScheduleScheduleType.TIMED:
        return rawPlanTimerTimedComponent;
      case T.FlightScheduleScheduleType.CONTINUOUS:
        return rawPlanTimerContinuousComponent;
      case T.FlightScheduleScheduleType.IMMEDIATE:
      default:
        return <></>;
      // return <PlanTimerBaseChild>Immediate</PlanTimerBaseChild>;
    }
  })();

  return (
    <ScrollBarsWrapper
      renderView={ScrollBarView}
      autoHide={true}
      autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
      autoHideDuration={HIDE_SCROLL_BAR_DURATION}
    >
      <InputFieldsWrapper>
        <InputItem>
          <TextField
            customInputStyles={{
              fontSize: '12px',
              '::placeholder': {
                color: palette.dividerLight.toString(),
              },
            }}
            label={l10n(Text.inputFields.planName.label)}
            placeHolder={l10n(Text.inputFields.planName.placeHolder)}
            title={title}
            setTitle={handleTitleChange}
            hasError={titleError}
          />
        </InputItem>

        <InputItem>
          <Label>{l10n(Text.inputFields.flightPlan.label)}</Label>

          <FlightScheduleSelectionDropdown
            isSearchEnable={true}
            valueId={selectedFlightPlan?.id}
            placeHolder={l10n(Text.inputFields.flightPlanDropdown.placeHolder)}
            options={createOptions(allFlightPlans)}
            zIndex={0}
            rootHeight="37px"
            onClick={onClickFlightPlanOption}
            error={selectedFlightPlanError}
          />

          {selectedFlightPlan && (
            <SingleDeviceWrapper>
              <DeviceInfoWrapper>
                <DeviceInfoTitle>{l10n(Text.selectedFlightPlans.fileName)}</DeviceInfoTitle>
                {selectedFlightPlan.title}
              </DeviceInfoWrapper>
              <DeviceInfoWrapper>
                <DeviceInfoTitle>{l10n(Text.selectedFlightPlans.createdAt)}</DeviceInfoTitle>
                {selectedFlightPlan.createdAt?.toLocaleDateString()}
              </DeviceInfoWrapper>
            </SingleDeviceWrapper>
          )}
        </InputItem>

        <InputItem>
          <Label>{l10n(Text.inputFields.device.label)}</Label>

          <FlightScheduleSelectionDropdown
            isSearchEnable={true}
            valueId={selectedDroneStation?.id}
            placeHolder={l10n(Text.inputFields.deviceDropdown.placeHolder)}
            options={createOptions(allDroneStations)}
            zIndex={0}
            rootHeight="37px"
            onClick={onClickDroneStationOption}
            error={selectedDroneStationError}
          />

          {selectedDroneStation && (
            <SingleFlightPlanWrapper>
              <FlightPlanInfoWrapper>
                <FlightPlanInfoTitle>{l10n(Text.selectedDevice.fileName)}</FlightPlanInfoTitle>
                {selectedDroneStation.title}
              </FlightPlanInfoWrapper>
            </SingleFlightPlanWrapper>
          )}
        </InputItem>

        <InputItem>
          <Label>{l10n(Text.inputFields.planTimer.label)}</Label>
          <PlanTimerContainer>
            <PlanTimerWrapper>
              {planTimersLists.map(planTimer => tabToButtonLostAction(planTimer, true))}
            </PlanTimerWrapper>
            {rawPlanTimerComponent}
          </PlanTimerContainer>
        </InputItem>

        <InputItem>
          <Label>{l10n(Text.inputFields.lostActions.label)}</Label>
          <LostActionsWrapper>
            {lostActionLists.map(lostAction => tabToButtonLostAction(lostAction, false))}
          </LostActionsWrapper>
        </InputItem>

        <InputItem>
          {/* <Label>{l10n(Text.inputFields.rthAltitude.label)}</Label> */}
          <CustomFlightScheduleInput
            inputStyle={getCmInputStyles(rthAltitudeError)}
            labelStyle={{ ...labelStyles, marginBottom: '14px' }}
            kind="altitude"
            value={rthAltitude}
            onChange={handleRthAltitudeChange}
          />
          <AltitudeRangeInfo error={Boolean(rthAltitudeError)}>
            {l10n(Text.inputFields.rthAltitude.info)}
          </AltitudeRangeInfo>
        </InputItem>
      </InputFieldsWrapper>
    </ScrollBarsWrapper>
  );
};

export default memo(FlightScheduleDetails);
