import React, { ChangeEvent, MouseEvent, FC, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { MidContainer, SeekTimerText, SliderInput, SliderRoot } from '../VideoPlayer/style';
import { formatTime } from '../PhotoList/util';

const fadeAnimation = keyframes`
 {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

const Bar = styled.div`
  animation: ${fadeAnimation} 1.2s linear infinite;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 22px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
  }
`;

const Bar2 = styled(Bar)`
  transform: rotate(30deg);
  animation-delay: -1.1s;
`;

const Bar3 = styled(Bar)`
  transform: rotate(60deg);
  animation-delay: -1s;
`;

const Bar4 = styled(Bar)`
  transform: rotate(90deg);
  animation-delay: -0.9s;
`;

const Bar5 = styled(Bar)`
  transform: rotate(120deg);
  animation-delay: -0.8s;
`;

const Bar6 = styled(Bar)`
  transform: rotate(150deg);
  animation-delay: -0.7s;
`;

const Bar7 = styled(Bar)`
  transform: rotate(180deg);
  animation-delay: -0.6s;
`;

const Bar8 = styled(Bar)`
  transform: rotate(210deg);
  animation-delay: -0.5s;
`;

const Bar9 = styled(Bar)`
  transform: rotate(240deg);
  animation-delay: -0.4s;
`;

const Bar10 = styled(Bar)`
  transform: rotate(270deg);
  animation-delay: -0.3s;
`;

const Bar11 = styled(Bar)`
  transform: rotate(300deg);
  animation-delay: -0.2s;
`;

const Bar12 = styled(Bar)`
  transform: rotate(330deg);
  animation-delay: -0.1s;
`;

const Bar13 = styled(Bar)`
  transform: rotate(0deg);
  animation-delay: 0s;
`;

const fadeIn1 = keyframes`
  0% {
    opacity: 0
  }

  17% {
    opacity: .9
  }

  33% {
    opacity: .6
  }

  50% {
    opacity: .3
  }

  67% {
    opacity: .3
  }

  83% {
    opacity: .3
  }

  100% {
    opacity: 0
  }
`;

const fadeIn2 = keyframes`
  0% {
    opacity: 0
  }

  17% {
    opacity: .3
  }

  33% {
    opacity: .9
  }

  50% {
    opacity: .6
  }

  67% {
    opacity: .3
  }

  83% {
    opacity: .3
  }

  100% {
    opacity: 0
  }
`;
const fadeIn3 = keyframes`
  0% {
    opacity: 0
  }

  17% {
    opacity: .3
  }

  33% {
    opacity: .3
  }

  50% {
    opacity: .9
  }

  67% {
    opacity: .6
  }

  83% {
    opacity: .3
  }

  100% {
    opacity: 0
  }
`;

const Triangle = styled.span<{ position: 'left' | 'right' }>`
  width: 0;
  height: 0;
  border-top: 6.5px solid transparent;
  border-bottom: 6.5px solid transparent;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  &:nth-child(1) {
    animation-name: ${fadeIn3};
  }

  &:nth-child(2) {
    animation-name: ${fadeIn2};
  }

  &:nth-child(3) {
    animation-name: ${fadeIn1};
  }

  /* Positioning based on prop */
  ${props =>
    props.position === 'left' &&
    `
    border-right: none;
    border-left: 8px solid #fff;
  `}

  ${props =>
    props.position === 'right' &&
    `
    border-right: 8px solid #fff;
  `}
`;

const TextBox = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
`;

export const IconWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
});

const TriangleWrapper = styled.div<{ direction: 'row' | 'row-reverse' }>(({ direction }) => ({
  display: 'flex',
  flexDirection: direction,
  gap: '3px',
}));

export const VideoSpinner = () => (
  <>
    <Bar2 />
    <Bar3 />
    <Bar4 />
    <Bar5 />
    <Bar6 />
    <Bar7 />
    <Bar8 />
    <Bar9 />
    <Bar10 />
    <Bar11 />
    <Bar12 />
    <Bar13 />
  </>
);

export const RewindIconWithTimer = () => (
  <IconWrapper>
    <TriangleWrapper direction="row">
      <Triangle position="right" />
      <Triangle position="right" />
      <Triangle position="right" />
    </TriangleWrapper>
    <TextBox>10 seconds</TextBox>
  </IconWrapper>
);

export const ForwardIconWithTimer = () => (
  <IconWrapper>
    <TriangleWrapper direction="row-reverse">
      <Triangle position="left" />
      <Triangle position="left" />
      <Triangle position="left" />
    </TriangleWrapper>
    <TextBox>10 seconds</TextBox>
  </IconWrapper>
);

interface SeekSliderProps {
  played: number;
  loaded?: number;
  onSeek?(event: ChangeEvent<HTMLInputElement>): void;
  onSeekMouseUp(event: MouseEvent<HTMLInputElement>): void;
  onMouseSeekDown(): void;
  duration: number;
}

export const VideoSeekSlider: FC<SeekSliderProps> = ({
  played,
  loaded,
  onMouseSeekDown,
  onSeek,
  onSeekMouseUp,
  duration,
}) => {
  const [seekMouse, setSeekMouse] = useState(0);
  const [seekTimer, setSeekTimer] = useState('');

  const sliderRef = useRef<HTMLInputElement>(null);

  const handleMouseMoveSeek: (event: React.MouseEvent<HTMLInputElement>) => void = event => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const width = rect.width;
      const value = (offsetX / width) * 100;

      const seekSeconds = (value / 100) * duration;
      const seekTime = formatTime(seekSeconds);
      setSeekTimer(seekTime);

      setSeekMouse(value > 0 ? value : 0);
    }
  };

  return (
    <MidContainer>
      <SliderRoot>
        <SeekTimerText seekMouse={seekMouse}>{seekTimer}</SeekTimerText>
        <SliderInput
          ref={sliderRef}
          changedValue={played ? played * 100 : 0}
          loaded={loaded ? loaded * 100 : 0}
          value={played}
          seekMouse={seekMouse}
          onMouseMove={handleMouseMoveSeek}
          onMouseLeave={() => {
            setSeekMouse(0);
            setSeekTimer('');
          }}
          type="range"
          min={0}
          max={0.999999}
          step="any"
          onMouseDown={onMouseSeekDown}
          onChange={onSeek}
          onMouseUp={onSeekMouseUp}
        />
      </SliderRoot>
    </MidContainer>
  );
};
