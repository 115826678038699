/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-param-reassign */

import {
  Camera,
  Frustum,
  Matrix4,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Vector2,
  Vector3,
} from 'three';
import * as jsts from 'jsts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { inv, matrix, multiply, transpose } from 'mathjs';
const _v1 = /* @__PURE__ */ new Vector3();
const _v2 = /* @__PURE__ */ new Vector3();
const _matrix4 = /* @__PURE__ */ new Matrix4();

/**
 * This help calculate the middle point of two 3D point
 * @param {number[] | Vector3} point1
 * @param {number[] | Vector3} point2
 */
export const middlePoint = (point1: number[] | Vector3, point2: number[] | Vector3): number[] => {
  if (point1 instanceof Vector3) {
    point1 = point1 as Vector3;
    point2 = point2 as Vector3;
    return [(point1.x + point2.x) / 2, (point1.y + point2.y) / 2, (point1.z + point2.z) / 2];
  } else {
    point1 = point1 as number[];
    point2 = point2 as number[];
    return [(point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2, (point1[2] + point2[2]) / 2];
  }
};

/**
 * This help create camera frustum
 * @param {PerspectiveCamera | OrthographicCamera} camera
 * @returns  Frustum
 */
export const getCameraFrustum = (camera: PerspectiveCamera | OrthographicCamera): Frustum => {
  camera.updateMatrix(); // make sure camera's local matrix is updated
  camera.updateMatrixWorld(); // make sure camera's world matrix is updated
  // camera.matrixWorldInverse.invert();
  const frustum = new Frustum();
  frustum.setFromProjectionMatrix(
    _matrix4.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
  );
  return frustum;
};

/**
 * Check a point inside 2d polygon
 * @param {Vector2} point
 * @param {Vector2[]} twoDPolygon
 * @link
 * + https://observablehq.com/@tmcw/understanding-point-in-polygon
 * @returns  boolean
 */
export const isPointInsideTwoDPolygon = (point: Vector2, twoDPolygon: Vector2[]): boolean => {
  let intersectCount = 0;
  const n = twoDPolygon.length;
  for (let i = 0, j = n - 1; i < n; j = i++) {
    const xi = twoDPolygon[i].x;
    const yi = twoDPolygon[i].y;
    const xj = twoDPolygon[j].x;
    const yj = twoDPolygon[j].y;

    // check point.y between segment.y
    const condition1 = yi > point.y !== yj > point.y;
    if (!condition1) {
      continue;
    }
    const condition2 = point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
    // check point in half-plane to the left
    if (condition2) {
      intersectCount++;
    }
  }

  return intersectCount % 2 === 1;
};

export const isPointOnTwoDPolygon = (point: Vector2, twoDPolygon: Vector2[]): boolean => {
  for (let i = 0; i < twoDPolygon.length; i++) {
    const pointA = new Vector3(twoDPolygon[i].x, twoDPolygon[i].y, 0);
    const pointB = new Vector3(
      twoDPolygon[(i + 1) % twoDPolygon.length].x,
      twoDPolygon[(i + 1) % twoDPolygon.length].y,
      0
    );
    if (isPointOnLineAndBetweenPoints(pointA, pointB, new Vector3(point.x, point.y, 0))) {
      return true;
    }
  }
  return false;
};
/**
 * Find the intersect scalar number between 2 segments
 * @param {Vector2[]} point
 * @param {Vector2[]} twoDPolygon
 * @link
 * + https://stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect
 * + https://blackpawn.com/texts/pointinpoly/
 * @returns { number | null} scalar number
 */
export const findIntersectionOfTwoSegments = (
  line1: Vector2[],
  line2: Vector2[]
): number | null => {
  const v1 = line2[0].clone().sub(line1[0]); // (q - p)
  const v2 = line1[1].clone().sub(line1[0]); //r
  const v3 = line2[1].clone().sub(line2[0]); //s
  const crossProduct = v2.clone().cross(v3); ///(r x s)

  // they are parallel
  if (Math.abs(crossProduct) < Number.EPSILON) {
    return null;
  }

  const t = v1.clone().cross(v3) / crossProduct; //(q - p) x s / (r x s)
  const u = v1.clone().cross(v2) / crossProduct; //(q - p) x r / (r x s)

  // the two line segments meet at the point p + t r = q + u s
  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    return t;
  } else {
    return null;
  }
};
/**
 * Find the Z value of 2d point by a plane
 * @param {Vector3} v1
 * @param {Vector3} v2
 * @param {Vector3} v3
 * @param {Vector2} point
 * @link
 * + https://en.wikipedia.org/wiki/Euclidean_planes_in_three-dimensional_space
 * + https://www.alecjacobson.com/weblog/1596.html
 * + https://stackoverflow.com/questions/5507762/how-to-find-z-by-arbitrary-x-y-coordinates-within-triangle-if-you-have-triangle
 *  @description
 * - The point–normal form of the equation of a plane:
 * - ax+by+cz+d=0
 * - a, b, c are normal vector
 * - d is constant
 *  @returns {number} z number
 */
export const findZCoordinate = (v1: Vector3, v2: Vector3, v3: Vector3, point: Vector2): number => {
  const normal = new Vector3()
    .crossVectors(new Vector3().subVectors(v2, v1), new Vector3().subVectors(v3, v1))
    .normalize();

  const d = -normal.dot(v1);
  const zCoordinate = (-normal.x * point.x - normal.y * point.y - d) / normal.z;
  return zCoordinate;
};
/**
 * Check triangle is clock wise
 * @param {Vector2} v1
 * @param {Vector2} v2
 * @param {Vector2} v3
 * @link
 * + https://stackoverflow.com/questions/1165647/how-to-determine-if-a-list-of-polygon-points-are-in-clockwise-order
 * + https://stackoverflow.com/questions/14505565/detect-if-a-set-of-points-in-an-array-that-are-the-vertices-of-a-complex-polygon
 *  @returns {boolean}
 */
export const isClockwise = (v1: Vector2, v2: Vector2, v3: Vector2): boolean => {
  const v1v2 = v2.clone().sub(v1);
  const v1v3 = v3.clone().sub(v1);
  const determinant = v1v2.x * v1v3.y - v1v2.y * v1v3.x;
  return determinant < 0;
};
/**
 * Calculate 3d sign volume
 * @param {Vector3} p1
 * @param {Vector3} p2
 * @param {Vector3} p3
 * @link
 * + http://chenlab.ece.cornell.edu/Publication/Cha/icip01_Cha.pdf
 *  @returns {number}
 */
export const signedVolumeOfTriangle = (p1: Vector3, p2: Vector3, p3: Vector3): number => {
  const v321 = p3.x * p2.y * p1.z;
  const v231 = p2.x * p3.y * p1.z;
  const v312 = p3.x * p1.y * p2.z;
  const v132 = p1.x * p3.y * p2.z;
  const v213 = p2.x * p1.y * p3.z;
  const v123 = p1.x * p2.y * p3.z;
  return (1 / 6) * (-v321 + v231 + v312 - v132 - v213 + v123);
};
/**
 * Calculate 2d sign area
 * @param {Vector2} p1
 * @param {Vector2} p2
 * @link
 * + https://www.mathopenref.com/coordpolygonarea.html
 * + https://stackoverflow.com/questions/1406029/how-to-calculate-the-volume-of-a-3d-mesh-object-the-surface-of-which-is-made-up
 *  @returns {number}
 */
export const signedAreaOfTriangle = (v1: Vector2, v2: Vector2, v3: Vector2): number =>
  (1 / 2) * (v1.x * (v2.y - v3.y) + v2.x * (v3.y - v1.y) + v3.x * (v1.y - v2.y));

/**
 * Check point on a line
 * @param {Vector3} p1
 * @param {Vector3} p2
 * @param {Vector3} pointToCheck
 * @returns {boolean}
 */
export const isPointOnLine = (pointA: Vector3, pointB: Vector3, pointToCheck: Vector3): boolean => {
  const c = new Vector3();
  c.crossVectors(pointA.clone().sub(pointToCheck), pointB.clone().sub(pointToCheck));
  return !c.length();
};

/**
 * Check point on a segments
 * @param {Vector3} p1
 * @param {Vector3} p2
 * @param {Vector3} pointToCheck
 * @returns {number}
 */
export const isPointOnLineAndBetweenPoints = (
  pointA: Vector3,
  pointB: Vector3,
  pointToCheck: Vector3
): boolean => {
  if (!isPointOnLine(pointA, pointB, pointToCheck)) {
    return false;
  }
  const d = pointA.distanceTo(pointB);

  return pointA.distanceTo(pointToCheck) <= d && pointB.distanceTo(pointToCheck) <= d;
};
/**
 * Check point inside 2d bounding box
 * @param {Vector2} point
 * @param {number} minX
 * @param {number} minY
 * @param {number} maxX
 * @param {number} maxY
 * @returns {boolean}
 */
export const isPointInsideTwoDBoundingBox = (
  point: Vector2,
  minX: number,
  minY: number,
  maxX: number,
  maxY: number
): boolean => point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;

/**
 * Offset 2d polygon
 * @param {Vector2[]} polygon
 * @param {number} distance
 * @returns {Vector2[]} offset polygon
 */
export const offsetTwoDPolygon = (polygon: Vector2[], distance: number = 0.01): Vector2[] => {
  const coordinates = [];
  const offset = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < polygon.length; i++) {
    coordinates.push(new jsts.geom.Coordinate(polygon[i].x, polygon[i].y));
  }
  const geometryFactory = new jsts.geom.GeometryFactory();

  const oCoordinates = geometryFactory
    .createPolygon(geometryFactory.createLinearRing(coordinates), [])
    .buffer(
      distance,
      jsts.operation.buffer.BufferParameters.DEFAULT_QUADRANT_SEGMENTS,
      jsts.operation.buffer.BufferParameters.CAP_FLAT
    );
  const coord = oCoordinates.getCoordinates();
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < coord.length; i++) {
    offset.push(new Vector2(coord[i].x, coord[i].y));
  }
  return offset;
};

/**
 * Scale object based on camera position
 * @param {Object3D} el
 * @param {Camera} camera
 * @returns {number}
 */
export const objectScale = (el: Object3D, camera: Camera): number => {
  if (camera instanceof OrthographicCamera) {
    return camera.zoom;
  } else if (camera instanceof PerspectiveCamera) {
    const objectPos = _v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = _v2.setFromMatrixPosition(camera.matrixWorld);
    const vFOV = (camera.fov * Math.PI) / 180;
    const dist = objectPos.distanceTo(cameraPos);
    const scaleFOV = 2 * Math.tan(vFOV / 2) * dist;
    return 1 / scaleFOV;
  } else {
    return 1;
  }
};

/**
 * Calculate plane fitting
 * @param {number[]} polygon
 * @link
 * + https://stackoverflow.com/questions/1400213/3d-least-squares-plane
 * @returns {number[]}
 */

export const planeFitting = (polygon: number[]): number[] => {
  const A = [];
  const B = [];
  for (let i = 0; i < polygon.length; i += 3) {
    A.push([polygon[i], polygon[i + 1], 1]);
    B.push(polygon[i + 2]);
  }
  const matrixA = matrix(A);
  const matrixB = matrix(B);
  const At = transpose(matrixA);
  const AtA = multiply(At, A);
  const invAtA = inv(AtA);
  const invAtA_At = multiply(invAtA, At);
  const coefficients = multiply(invAtA_At, matrixB);
  return coefficients.toArray() as number[];
};

/**
 * Check 2D box instect/contain/inside other 2D box
 * @param {number} minX1 - Minimum X coordinate of the first box.
 * @param {number} maxX1 - Maximum X coordinate of the first box.
 * @param {number} minY1 - Minimum Y coordinate of the first box.
 * @param {number} maxY1 - Maximum Y coordinate of the first box.
 * @param {number} minX2 - Minimum X coordinate of the second box.
 * @param {number} maxX2 - Maximum X coordinate of the second box.
 * @param {number} minY2 - Minimum Y coordinate of the second box.
 * @param {number} maxY2 - Maximum Y coordinate of the second box.
 * @returns {boolean}
 */

export const boxIntersect = (
  minX1: number,
  maxX1: number,
  minY1: number,
  maxY1: number,
  minX2: number,
  maxX2: number,
  minY2: number,
  maxY2: number
): boolean => {
  // Check for intersection
  const intersect = !(minX1 > maxX2 || maxX1 < minX2 || minY1 > maxY2 || maxY1 < minY2);

  // Check if the first box is inside the second box
  const inside = minX1 >= minX2 && maxX1 <= maxX2 && minY1 >= minY2 && maxY1 <= maxY2;

  // Check if the first box contains the second box
  const contain = minX1 <= minX2 && maxX1 >= maxX2 && minY1 <= minY2 && maxY1 >= maxY2;

  // Return true if any of the conditions are met
  return intersect || inside || contain;
};
