/* eslint-disable max-len */
import { Language } from '^/types';

export default {
  mapCenter: {
    [Language.KO_KR]: '지도 원점',
    [Language.EN_US]: 'Map to Center',
  },
  depthTestAgainstTerrain: {
    [Language.KO_KR]: '가려진 지형 보기',
    [Language.EN_US]: 'Show hidden terrain',
  },
  workRadius: {
    [Language.KO_KR]: '모든 작업반경 보기',
    [Language.EN_US]: 'Show all working radiuses',
  },
  showEssModelIcon: {
    [Language.KO_KR]: 'ESS 아이콘 표기',
    [Language.EN_US]: 'Show ESS Icon',
  },
  hideEssModelIcon: {
    [Language.KO_KR]: 'ESS 아이콘 숨김',
    [Language.EN_US]: 'Hide ESS Icon',
  },
  sketchMode: {
    [Language.KO_KR]: '스케치 모드',
    [Language.EN_US]: 'Sketch mode',
  },
  geolocation: {
    [Language.KO_KR]: '현위치',
    [Language.EN_US]: 'Current Location',
  },
  geolocationVisibility: {
    [Language.KO_KR]: '위치 표시',
    [Language.EN_US]: 'Show Coordinates',
  },
  repositionHeaderText: {
    [Language.KO_KR]: '위치 조정 필요',
    [Language.EN_US]: 'Reposition the model',
  },
  respositionDescriptionText: {
    [Language.KO_KR]: `모델이 빈 공간 위에 있을 경우, 모델이 지리적으로 올바르게 태그되지 않을 수 있습니다. 작업 현장으로 모델을 가져 오려면 아래 버튼을 클릭해주세요.`,
    [Language.EN_US]: `If your model is sitting on top of a blank space, the model is not correctly geo-tagged. Click on this button to bring your model to your working site.`,
  },
};
