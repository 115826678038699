import { create, useStore } from 'zustand';

interface OlState {
  zoomLevel: number;
}

interface OlStateActions {
  reset(): void;
  setZoomLevel(zoomLevel: number): void;
}

const initialState: OlState = {
  zoomLevel: 0,
};

type OlStoreInterface = OlState & OlStateActions;

export const olStore = create<OlStoreInterface>()(set => ({
  ...initialState,
  setZoomLevel: zoomLevel => set({ zoomLevel }),
  reset: () => set(initialState),
}));

export function useOlStore<T = OlStoreInterface>(selector?: (state: OlStoreInterface) => T): T {
  return useStore(olStore, selector ?? (state => state as T));
}
