import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requestDroneCapture } from './services';
import * as T from '^/types';
import { PhotoKeys } from '../photos/usePhoto';
import { useParams } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';
import { displayToast } from '^/hooks/usePresentationUtils';
import { useL10n } from '^/hooks/useL10n';
import Text from './text';
import palette from '^/constants/palette';

export const ViewportKeys = {
  requestViewpoint: ['saved', 'viewports'],
};

const toastOptions: ToastOptions = {
  position: 'bottom-right',
  hideProgressBar: true,
  style: {
    color: palette.darkBlack.toString(),
    fontWeight: 500,
  },
};

export function useRequestDroneCapture() {
  const [l10n] = useL10n();
  const { id: projectId } = useParams();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ photoAlbumId }: { photoAlbumId: T.RequestPhoto['id'] }) => {
      const res = await requestDroneCapture(photoAlbumId);
      return res;
    },
    onSuccess: async (...args) => {
      const variables = args[1];
      await queryClient.invalidateQueries(PhotoKeys.getPhotoAlbumById(variables.photoAlbumId));
      if (projectId) {
        await queryClient.invalidateQueries(PhotoKeys.photoAlbumQuery(Number(projectId)));
      }
      toast(displayToast(l10n(Text.requestCompleted)), toastOptions);
    },
  });
}
