import React, { useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import MaximizeSvg from '^/assets/icons/map-controller/maximize.svg';
import MinimizeSvg from '^/assets/icons/map-controller/minimize.svg';

import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { useThreeStore } from '^/components/three/ThreeStore';
import { olConstants as OC } from '^/constants/map-display';
import { isMobile } from '^/utilities/device';

export const ZoomWrapper = styled.div({
  height: 'auto',
  position: 'absolute',
  bottom: '10px',
  right: '10px',

  borderRadius: '3px',
  overflow: 'hidden',
});

export const SVGWrapper = styled.button<{ extraButtonStyling?: CSSObject }>(
  ({ extraButtonStyling }) => ({
    width: '32px',
    height: '28px',
    cursor: 'pointer',
    opacity: 0.7,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    backgroundColor: '#ffffff',
    ':hover': {
      opacity: 0.9,
    },

    ...extraButtonStyling,
  })
);

export const extraButtonStyling = {
  borderBottom: '1px solid #D5D5D8',
};

const intervalPeriod: number = 50;

const MapZoomForMini = ({ is2DMinimapSelected }: { is2DMinimapSelected: boolean }) => {
  const [interval, setInterval] = useState<number>(0);
  const threeViewer = useThreeStore(s => s.viewer);
  const { miniMap2D } = useThreePhotoAlbumStore(s => s.threeSixtyMiniMapDetails);

  const zoomInOnce: () => void = () => {
    if (is2DMinimapSelected) {
      zoomInOnce2D();
    } else {
      if (threeViewer) {
        threeViewer.controllers.photoAlbumController?.zoomInOutSplitView(30);
      }
    }
  };

  const zoomOutOnce: () => void = () => {
    if (is2DMinimapSelected) {
      zoomOutOnce2D();
    } else {
      if (threeViewer) {
        threeViewer.controllers.photoAlbumController?.zoomInOutSplitView(-30);
      }
    }
  };

  const zoomInOnce2D: () => void = () => {
    if (miniMap2D) {
      const miniMapView = miniMap2D.getView();
      const currentZoomLevel = miniMapView.getZoom();
      if (currentZoomLevel) {
        miniMapView.setZoom(currentZoomLevel + OC.zoom.defaultDiff);
      }
    }
  };

  const zoomOutOnce2D: () => void = () => {
    if (miniMap2D) {
      const miniMapView = miniMap2D.getView();
      const currentZoomLevel = miniMapView.getZoom();
      if (currentZoomLevel) {
        miniMapView.setZoom(currentZoomLevel - OC.zoom.defaultDiff);
      }
    }
  };

  const handleMousedownMapZoomIn: () => void = () => {
    setInterval(window.setInterval(zoomInOnce, intervalPeriod));
  };

  const handleMouseDownMapZoomOut: () => void = () => {
    setInterval(window.setInterval(zoomOutOnce, intervalPeriod));
  };

  const handleZoomRelease: () => void = () => {
    clearInterval(interval);
  };

  return isMobile() ? null : (
    <ZoomWrapper>
      <SVGWrapper
        onTouchEnd={handleZoomRelease}
        onTouchStart={handleMousedownMapZoomIn}
        onMouseUp={handleZoomRelease}
        onMouseDown={handleMousedownMapZoomIn}
        extraButtonStyling={extraButtonStyling}
      >
        <MaximizeSvg />
      </SVGWrapper>
      <SVGWrapper
        onTouchEnd={handleZoomRelease}
        onTouchStart={handleMouseDownMapZoomOut}
        onMouseUp={handleZoomRelease}
        onMouseDown={handleMouseDownMapZoomOut}
      >
        <MinimizeSvg />
      </SVGWrapper>
    </ZoomWrapper>
  );
};
export default MapZoomForMini;
