import { config } from '^/config';
import { attachmentsStore } from '^/store/attachmentsStore';
import { AuthHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { AddAttachment, APIToAttachment } from '^/store/duck/Attachments';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { uploadFileToGCS } from '^/utilities/gcs-upload';
import { useDispatch, useSelector } from 'react-redux';
import { ExifParserFactory } from 'ts-exif-parser';

interface Params {
  contentId: T.Content['id'];
  projectId?: T.Project['id'];
  attachmentType: T.AttachmentType;
  file: File;
  bucketFileName?: string;
}

export function useAttachment() {
  const dispatch = useDispatch();

  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const header: AuthHeader | undefined = makeAuthHeader(Auth, slug);

  async function createAttachment(params: Params) {
    if (!attachmentsStore.getState().isUploading) {
      return;
    }

    const xMetaHeaders: Record<string, string> = {};
    if (
      params.file.name.toUpperCase().endsWith('.JPG') ||
      params.file.name.toUpperCase().endsWith('.JPEG')
    ) {
      const data: ArrayBuffer = await params.file.arrayBuffer();
      const tags: any = ExifParserFactory.create(data).parse().tags;

      const metaPrefix = (() => {
        switch (config.region) {
          case T.Region.KSA:
            return 'x-goog-meta';
          case T.Region.DEFAULT:
          default:
            return 'x-amz-meta';
        }
      })();

      xMetaHeaders[`${metaPrefix}-altitude`] = (tags.GPSAltitude ?? '').toString();
      xMetaHeaders[`${metaPrefix}-altitude-ref`] = (tags.GPSAltitudeRef ?? '').toString();
      xMetaHeaders[`${metaPrefix}-latitude`] = (tags.GPSLatitude ?? '').toString();
      xMetaHeaders[`${metaPrefix}-latitude-ref`] = (tags.GPSLatitudeRef ?? '').toString();
      xMetaHeaders[`${metaPrefix}-longitude`] = (tags.GPSLongitude ?? '').toString();
      xMetaHeaders[`${metaPrefix}-longitude-ref`] = (tags.GPSLongitudeRef ?? '').toString();
    }

    const baseParams = params.projectId
      ? ['projects', params.projectId, 'processing_attachments']
      : ['contents', params.contentId, 'attachments'];

    const url = makeV2APIURL(
      ...baseParams,
      `new?type=${params.attachmentType}&filename=${params.bucketFileName ?? params.file.name}`
    );
    if (header === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    // Retreive signed url from Attachments-New
    const response = await http.get<{ url: string; key: string }>(url, {
      headers: { ...header, ...xMetaHeaders },
    });

    // Upload file to GCS
    await uploadFileToGCS(response.data.url, params.file, xMetaHeaders);

    if (!attachmentsStore.getState().isUploading) {
      return;
    }

    const callbackUrl = makeV2APIURL(...baseParams, 'callback');
    const attachmentResponse = await http.post(
      callbackUrl,
      { key: response.data.key },
      { headers: header }
    );

    const attachment = APIToAttachment(attachmentResponse.data.data);

    dispatch(AddAttachment({ attachment }));

    return attachmentResponse;
  }

  return { createAttachment };
}
