/* eslint import/order: [2, {"newlines-between": "always-and-inside-groups"}] */
'use strict';

/**
 * Polyfills for supporting IE11
 * 1. {@link https://github.com/zloirock/core-js#ecmascript-6-object}
 * 2. {@link https://github.com/zloirock/core-js#ecmascript-6-array}
 * 3. {@link https://github.com/zloirock/core-js#ecmascript-6-string}
 * 4. {@link https://github.com/zloirock/core-js#ecmascript-6-math}
 * 5. {@link https://github.com/zloirock/core-js#stage-4-proposals}
 */
import 'core-js/features/array';
import 'core-js/features/number';
import 'core-js/features/math';
import 'core-js/features/object';
import 'core-js/features/string';
import 'core-js/features/typed-array';

/**
 * @todo Choose this polyfill or `core-js/es6/promise`.
 */
import PromisePolyTrack from 'promise/lib/rejection-tracking';
import PromisePoly from 'promise/lib/es6-extensions';

/**
 * isomorphic fetch() polyfill for making API calls.
 */
import 'isomorphic-fetch';

if (typeof Promise === 'undefined') {
  /**
   * Rejection tracking prevents a common issue where React gets into an
   * inconsistent state due to an error, but it gets swallowed by a Promise,
   * and the user has no idea what causes React's erratic future behavior.
   */
  PromisePolyTrack.enable();
  window.Promise = PromisePoly;
}
