import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import PlusSVG from '^/assets/icons/photo/plus.svg';
import MinusSVG from '^/assets/icons/photo/minus.svg';

const ZoomSliderRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '210px',
  height: '33px',
  padding: '0 20px',
});

const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const MinusIcon = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 10px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
});

const PlusIcon = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 10px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
});

interface ZoomSliderProps {
  min: number;
  max: number;
  defaultValue: number;
  onChange(value: number, wheelMousePos?: { x: number; y: number }): void;
}
export default function ZoomSlider({ min, max, defaultValue, onChange }: ZoomSliderProps) {
  const [value, setValue] = useState(defaultValue);
  const handleSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const _value = Number(event.target.value);
    setValue(_value);
    onChange(_value);
  };

  const handlePlusClick = () => {
    setValue(prevValue => {
      let newValue = prevValue + (max - min) / 10;
      newValue = newValue < min ? min : newValue > max ? max : newValue;
      onChange(newValue);
      return newValue;
    });
  };
  const handleMinusClick = () => {
    setValue(prevValue => {
      let newValue = prevValue - (max - min) / 10;
      newValue = newValue < min ? min : newValue > max ? max : newValue;
      onChange(newValue);
      return newValue;
    });
  };

  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      setValue(prevValue => {
        let newValue = prevValue - event.deltaY / 5;
        newValue = newValue < min ? min : newValue > max ? max : newValue;

        onChange(newValue, { x: event.clientX / innerWidth, y: event.clientY / innerHeight });
        return newValue;
      });
    };
    document.addEventListener('wheel', handleScroll);
    return () => {
      document.removeEventListener('wheel', handleScroll);
    };
  }, [onChange]);

  return (
    <ZoomSliderRoot>
      <MinusIcon onClick={handleMinusClick}>
        <MinusSVG />
      </MinusIcon>
      <Slider type="range" min={min} max={max} value={value} onChange={handleSliderChange} />
      <PlusIcon onClick={handlePlusClick}>
        <PlusSVG />
      </PlusIcon>
    </ZoomSliderRoot>
  );
}
