import { Language } from '^/types';

export default {
  warningDescription: {
    [Language.KO_KR]: 'ESS 장비들을 보거나 사용하려면,',
    [Language.EN_US]: 'If you wish to use ESS,',
  },
  switchText: {
    [Language.KO_KR]: '3D 뷰어로 전환하십시오',
    [Language.EN_US]: 'please switch to 3D Map',
  },
};
