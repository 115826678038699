import Color from 'color';
import React, { FC } from 'react';

export const ErrorIconSVG: FC<{ color?: Color; width?: number; height?: number }> = ({
  color,
  width,
  height,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={height || '20'}
    viewBox="0 0 24 20"
    fill="none"
  >
    <path
      d="M10.6351 0.746723L0.953872 16.3941C0.269102 17.5039 0.607552 18.96 1.71735 
      19.6448C2.09516 19.8809 2.52018 19.999 2.96096 19.999L20.9932 19.9911C22.2998 
      19.9911 23.3545 18.9364 23.3545 17.6298C23.3545 17.1891 23.2286 16.764 23.0003 
      16.3862L13.3112 0.746723C12.8547 0.00685672 11.8866 -0.2214 11.1467 0.235113C10.9421 
      0.361048 10.7689 0.542079 10.6351 0.746723Z"
      fill={color?.toString() || '#E03A3A'}
    />
    <path d="M12.9882 7.62549H10.9575V9.65619H12.9882V7.62549Z" fill="white" />
    <path d="M12.9882 10.8682H10.9575V17.771H12.9882V10.8682Z" fill="white" />
  </svg>
);

export const RepositionIFCIcon: FC<{ width?: number; height?: number }> = ({
  // color,
  width,
  height,
}) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox={`0 0 ${width || '24'} ${height || '24'}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.931 8.41577L12.0732 8.41577L12.0732 1.99996L10.931 1.99996L10.931 8.41577Z"
      fill="#606060"
    />
    <path d="M14.7012 6.39781L11.5028 9.59619L8.3044 6.39781L14.7012 6.39781Z" fill="#606060" />
    <path
      d="M1.99728 12.0713L8.41309 12.0713L8.41309 10.929L1.99728 10.929L1.99728 12.0713Z"
      fill="#606060"
    />
    <path d="M6.39536 8.30172L9.59375 11.5001L6.39536 14.6985L6.39536 8.30172Z" fill="#606060" />
    <path
      d="M10.931 21.0002L12.0732 21.0002L12.0732 14.5844L10.931 14.5844L10.931 21.0002Z"
      fill="#606060"
    />
    <path d="M8.3044 16.6025L11.5028 13.4042L14.7012 16.6025L8.3044 16.6025Z" fill="#606060" />
    <path
      d="M14.5871 12.0713L21.0029 12.0713L21.0029 10.929L14.5871 10.929L14.5871 12.0713Z"
      fill="#606060"
    />
    <path d="M16.6045 14.6985L13.4061 11.5001L16.6045 8.30172L16.6045 14.6985Z" fill="#606060" />
  </svg>
);

export const WarningIconSvg: FC = () => (
  <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" width="29" height="10" fill="none" />
    <path d="M36 36L19 8L2 36H36Z" fill="#FFF3BA" />
    <path
      d="M38 37H0L19 5L38 37ZM4.40884 34.4869H33.5702L18.979 9.94241L4.38785 34.4869H4.40884Z"
      fill="#E3BE00"
    />
    <path d="M20 17H18V25H20V17Z" fill="#E3BE00" />
    <path
      d="M19 31C20.1046 31 21 30.1046 21 29C21 27.8954 20.1046 27 19 27C17.8954 27 17 27.8954 17 29C17 30.1046 17.8954 31 19 31Z"
      fill="#E3BE00"
    />
  </svg>
);
