import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { HEADER_SLUG_PROP } from '^/constants/network';
import { JWT } from '^/local';
import { AuthHeader } from '^/store/duck/API';
import * as T from '^/types';

export function useAuthHeader(): AuthHeader | undefined {
  const token: string | undefined = useSelector((state: T.State) => state.Auth?.authedUser?.token);
  const slug: T.PlanConfig['slug'] = useSelector((state: T.State) => state.PlanConfig.config?.slug);

  const jwt: JWT | undefined = useMemo(
    () => (token !== undefined ? jwtDecode(token) : undefined),
    [token]
  );

  return useMemo(() => {
    if (!token || !jwt?.exp) {
      return undefined;
    }

    return {
      Authorization: token,
      [HEADER_SLUG_PROP]: slug,
    };
  }, [token, jwt, slug]);
}
