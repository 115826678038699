/* eslint-disable max-lines */
import { Reducer } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { action as makeAction, props, union } from 'tsdux';

import * as T from '^/types';

export const UpdateConfirmDeletePermission = makeAction(
  'ddm/permissions/UPDATE_CONFIRM_DELETE_PERMISSION',
  props<{
    permission?: T.Permission;
  }>()
);

const Action = union([UpdateConfirmDeletePermission]);
export type Action = typeof Action;

export const epic: Epic<Action, Action, T.State> = combineEpics();

// Redux reducer
const initialState: T.PermissionsState = {};
const reducer: Reducer<T.PermissionsState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case UpdateConfirmDeletePermission.type: {
      if (action.permission === undefined) {
        return {
          ...state,
          confirmDeletePermission: undefined,
        };
      }

      return {
        ...state,
        confirmDeletePermission: action.permission,
      };
    }

    default:
      return state;
  }
};

export default reducer;
