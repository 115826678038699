import { useEffect, useRef, RefObject, useState } from 'react';

type PanCallback = (deltaX: number, deltaY: number) => void;

function usePan(ref: RefObject<HTMLElement>, callback: PanCallback): boolean {
  const panStartX = useRef<number>(0);
  const panStartY = useRef<number>(0);
  const [isPanning, setIsPanning] = useState(false);

  useEffect(() => {
    const element = ref.current;

    function startPan(event: MouseEvent | TouchEvent) {
      event.preventDefault();
      panStartX.current = (event as MouseEvent).clientX || (event as TouchEvent).touches[0].clientX;
      panStartY.current = (event as MouseEvent).clientY || (event as TouchEvent).touches[0].clientY;
    }

    function pan(event: MouseEvent | TouchEvent) {
      event.preventDefault();
      if (panStartX.current || panStartY.current) {
        setIsPanning(true);
        const currentX = (event as MouseEvent).clientX || (event as TouchEvent).touches[0].clientX;
        const currentY = (event as MouseEvent).clientY || (event as TouchEvent).touches[0].clientY;
        const deltaX = currentX - panStartX.current;
        const deltaY = currentY - panStartY.current;
        callback(deltaX, deltaY);

        panStartX.current =
          (event as MouseEvent).clientX || (event as TouchEvent).touches[0].clientX;
        panStartY.current =
          (event as MouseEvent).clientY || (event as TouchEvent).touches[0].clientY;
      }
    }

    function endPan(event: MouseEvent | TouchEvent) {
      event.preventDefault();
      panStartX.current = 0;
      panStartY.current = 0;
      setIsPanning(false);
    }

    if (element) {
      element.addEventListener('mousedown', startPan);
      element.addEventListener('mousemove', pan);
      element.addEventListener('mouseup', endPan);
      element.addEventListener('touchstart', startPan);
      element.addEventListener('touchmove', pan);
      element.addEventListener('touchend', endPan);
    }

    return () => {
      if (element) {
        element.removeEventListener('mousedown', startPan);
        element.removeEventListener('mousemove', pan);
        element.removeEventListener('mouseup', endPan);
        element.removeEventListener('touchstart', startPan);
        element.removeEventListener('touchmove', pan);
        element.removeEventListener('touchend', endPan);
      }
    };
  }, [ref, callback]);

  return isPanning;
}

export default usePan;
