import { Group, Mesh } from 'three';
import * as T from '^/types';
import { autobind } from 'core-decorators';
import { Runtime } from 'three-loader-3dtiles';
import { EditorDrawingController } from '../../Lib/Controllers/Drawing';
import { DisposeHelper } from '../../Lib/Helper';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { CADOverlay } from '../../ThreeTiles';

export class BlueprintObject extends Group {
  public readonly viewer;
  public contentId: number | string;
  public editor: EditorDrawingController | null = null;
  public model: CADOverlay | undefined;
  public dummy: Mesh | undefined;
  public readonly isTemporary: boolean = false;
  public center: [number, number] = [0, 0];
  // public colorMaterial: Color;
  public constructor(
    contentId: number | string,
    viewer: Viewer,
    content: T.BlueprintDWGContent | T.BlueprintDXFContent,
    runtime: Runtime
  ) {
    super();
    this.viewer = viewer;
    this.contentId = contentId;
    this.center = content.info.tms?.center ?? [0, 0];
    this.name = `blueprint-${contentId}`;
    // this.colorMaterial = new Color(color.hex());
    this.init(content, runtime);
    this.position.z = (content.config as T.BlueprintDWGConfigPerUser)?.zAdjust ?? 0;
  }
  /**
   * @private
   * @param {BlueprintDWGContent | BlueprintDXFContent} content
   * @param {Runtime} runtime
   * @description load dxf file
   */
  @autobind
  private init(content: T.BlueprintDWGContent | T.BlueprintDXFContent, runtime: Runtime) {
    const contentRectangle = content?.info?.tms?.bounds;
    this.model = new CADOverlay(contentRectangle ?? [0, 0, 0, 0], runtime, this.contentId, content);
    this.add(this.model);
  }
  /**
   * @public
   * @description set highlight line
   */
  @autobind
  public setHighlight(): void {
    // if (this.model) {
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   //@ts-ignore
    //   this.model.material.color.lerpColors(this.model.material.color, new Color(0x1f4782), 0.5);
    // }
  }
  /**
   * @private
   * @description set un highlight line
   */
  @autobind
  public setUnHighlight(): void {
    // if (this.model) {
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   //@ts-ignore
    //   this.model.material.color.setHex(this.colorMaterial);
    // }
  }
  /**
   * @public
   * @description Dispose all
   */
  @autobind
  public dispose(): void {
    if (this.dummy) {
      DisposeHelper.disposeHierarchy(this.dummy);
      this.viewer.scene.remove(this.dummy);
    }
    DisposeHelper.disposeHierarchy(this);
  }
  /**
   * @public
   * @description This function will auto-call when the scene render
   * @param {  boolean | undefined} force
   */
  @autobind
  public updateMatrixWorld(force: boolean | undefined) {
    super.updateMatrixWorld(force);
  }

  /**
   * @public
   * @description set depth test
   */
  @autobind
  public setDepthTest(depthTest: boolean) {
    if (this.model) {
      this.model.traverse(object => {
        if (object instanceof Mesh) {
          object.material.depthTest = depthTest;
        }
      });
    }
  }
}
