import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import * as T from '^/types';
import { notificationDescDateSort } from '^/utilities/notification-desc-date-sort';

interface NotificationState {
  isShowOnlyUnread: boolean;
  notifications: T.Notification[];
  unseenNotifications: number;
}

interface NotificationActions {
  setIsShowOnlyUnread(newIsShowOnlyUnread: boolean): void;
  initialLoadNotifications(newNotifications: T.Notification[]): void;
  appendNotification(newNotification: T.Notification): void;
  modifyNotificationStatus(id: T.Notification['id'], newStatus: T.Notification['status']): void;
  setUnseenNotifications(newUnseenNotifications: number): void;
}

type NotificationStore = NotificationState & NotificationActions;

export const useNotificationStore = create<NotificationStore>()(
  devtools(set => ({
    isShowOnlyUnread: false,
    notifications: [],
    unseenNotifications: 0,
    setIsShowOnlyUnread: (newIsShowOnlyUnread: boolean) =>
      set(notificationStore => ({
        ...notificationStore,
        isShowOnlyUnread: newIsShowOnlyUnread,
      })),
    initialLoadNotifications: (newNotifications: T.Notification[]) =>
      set(notificationStore => ({
        ...notificationStore,
        notifications: newNotifications.sort(notificationDescDateSort),
      })),
    appendNotification: (newNotification: T.Notification) =>
      set(notificationStore => ({
        ...notificationStore,
        notifications: [newNotification].concat(notificationStore.notifications),
      })),
    modifyNotificationStatus: (id: T.Notification['id'], newStatus: T.Notification['status']) =>
      set(notificationStore => {
        const index = notificationStore.notifications.findIndex(
          notification => notification.id === id
        );
        const newNotification: T.Notification = {
          ...notificationStore.notifications[index],
          status: newStatus,
        };
        const firstSlice = notificationStore.notifications.slice(0, index);
        const secondSlice = notificationStore.notifications.slice(index + 1);
        const newNotifications = firstSlice.concat([newNotification], secondSlice);
        return {
          ...notificationStore,
          notifications: newNotifications,
        };
      }),
    setUnseenNotifications: (newUnseenNotifications: number) =>
      set(notificationStore => ({
        ...notificationStore,
        unseenNotifications: Math.max(0, newUnseenNotifications),
      })),
  }))
);
