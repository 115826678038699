import html2canvas from 'html2canvas';

import { getUserAgent } from './userAgent';
import * as T from '^/types';
import { TryCatchOutput, tryCatch } from '^/utilities/async-util';
import download from '^/utilities/download';

export const runScreenCapture: (
  element: HTMLElement,
  capturedFileName: string,
  isDownload?: boolean
) => Promise<void | string> = async (element, capturedFileName, isDownload = true) => {
  /**
   * @description When browser is webkit based
   * and usePopup parameter of download function is false, then the browser redirects to blob url.
   */
  const isWebkit: boolean = getUserAgent() === T.UserAgent.SAFARI;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { data, error }: TryCatchOutput<HTMLCanvasElement> = await tryCatch(
    html2canvas(element, {
      allowTaint: true,
      useCORS: true,
    })
  );

  if (!error && data) {
    if (isDownload) {
      download(data.toDataURL(), isWebkit, true, capturedFileName);
    } else {
      return new Promise((resolve, reject) => {
        data.toBlob(handleBlob(resolve, reject), capturedFileName, 1);
      });
    }
  }
};

type BlobHandlerResolve = (value: string | PromiseLike<string>) => void;
type BlobHandlerReject = (reason?: any) => void;

function handleBlob(resolve: BlobHandlerResolve, reject: BlobHandlerReject): BlobCallback {
  return (blob: Blob | null) => {
    if (blob) {
      resolve(blob as unknown as string);
    } else {
      reject(new Error('Error generating blob creating attachment for presentation'));
    }
  };
}

interface TwoDHandleCameraClickParams {
  onStart(): void;
  onEnd(): void;
  mainScreenCaptureFunction(): Promise<void | string>;
}

type TwoDHandleCameraClick = ({
  onStart,
  onEnd,
  mainScreenCaptureFunction,
}: TwoDHandleCameraClickParams) => () => Promise<void | string>;

export const handleCameraClickFunctor: TwoDHandleCameraClick =
  ({ onStart, onEnd, mainScreenCaptureFunction }) =>
  async () => {
    onStart();
    await mainScreenCaptureFunction();
    onEnd();
  };
