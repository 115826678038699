import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import Text from './text';
import QuestionMarkSvg from '^/assets/icons/question-mark.svg';
import dsPalette from '^/constants/ds-palette';
import { UseL10n, useL10n } from '^/hooks';
import { PatchContent } from '^/store/duck/Contents';
import * as T from '^/types';
import TerrainEditingMethodDropdown from '^/components/organisms/TerrainEditingMethodDropdown';
import Tippy from '@tippyjs/react';

const HeaderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

interface ItemWrapperProps {
  margin?: 'top' | 'bottom';
}
const ItemWrapper = styled.div<ItemWrapperProps>(({ margin }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: margin === 'top' ? '10px' : '0px',
  marginBottom: margin === 'bottom' ? '10px' : '0px',
}));
const TitleWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});
const Title = styled.span({
  fontSize: '12px',
  color: dsPalette.title.toString(),
});

interface Props {
  content: T.FlattenContent;
}

export const Basic: FC<Props> = ({ content }) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch: Dispatch = useDispatch();

  const onSelect: (algorithm?: T.TerrainEditingMethod) => void = algorithm => {
    if (algorithm === undefined) {
      return;
    }
    dispatch(
      PatchContent({
        content: {
          id: content.id,
          config: {
            ...content.config,
            terrianEditingMethod: algorithm,
          },
        },
      })
    );
  };

  return (
    <HeaderWrapper>
      <ItemWrapper>
        <TitleWrapper>
          <Title>{l10n(Text.title.method)}</Title>
          <Tippy
            theme="angelsw"
            offset={T.TIPPY_OFFSET}
            arrow={false}
            placement={'bottom'}
            content={l10n(Text.title.tooltipMethod)}
          >
            <div>
              <QuestionMarkSvg />
            </div>
          </Tippy>
        </TitleWrapper>
        <TerrainEditingMethodDropdown content={content} onSelect={onSelect} />
      </ItemWrapper>
    </HeaderWrapper>
  );
};
