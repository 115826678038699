import {
  Camera,
  Matrix4,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Raycaster,
  Vector2,
  Vector3,
} from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { LabelObject } from '../../ThreeObjects/Label';
const v1 = /* @__PURE__ */ new Vector3();
const v2 = /* @__PURE__ */ new Vector3();
const v3 = /* @__PURE__ */ new Vector3();

export const createLabel = (
  viewer: Viewer,
  options: any = {
    center: false,
    sprite: true,
    transform: false,
  },
  contentHTML: string
): LabelObject => {
  const opts = {
    center: false,
    sprite: true,
    transform: false,
    ...options,
  };
  const label = new LabelObject(viewer, opts);
  label.setInnerHTHML(contentHTML);
  return label;
};

export const defaultCalculatePosition = (el: Object3D, camera: Camera, size: any) => {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  objectPos.project(camera);
  const widthHalf = size.width / 2;
  const heightHalf = size.height / 2;
  return [objectPos.x * widthHalf + widthHalf, -(objectPos.y * heightHalf) + heightHalf];
};
export const isObjectBehindCamera = (el: Object3D, camera: Camera) => {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
  const deltaCamObj = objectPos.sub(cameraPos);
  const camDir = camera.getWorldDirection(v3);
  return deltaCamObj.angleTo(camDir) > Math.PI / 2;
};
export const isObjectVisible = (
  el: Object3D,
  camera: Camera,
  raycaster: Raycaster,
  occlude: Object3D[]
) => {
  const elPos = v1.setFromMatrixPosition(el.matrixWorld);
  const screenPos = elPos.clone();
  screenPos.project(camera);
  raycaster.setFromCamera(screenPos as unknown as Vector2, camera);
  const intersects = raycaster.intersectObjects(occlude, true);
  if (intersects.length) {
    const intersectionDistance = intersects[0].distance;
    const pointDistance = elPos.distanceTo(raycaster.ray.origin);
    return pointDistance < intersectionDistance;
  }
  return true;
};

export const objectZIndex = (
  el: Object3D,
  camera: Camera,
  zIndexRange: number[] = [16777271, 0]
) => {
  if (camera instanceof PerspectiveCamera || camera instanceof OrthographicCamera) {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
    const dist = objectPos.distanceTo(cameraPos);
    const A = (zIndexRange[1] - zIndexRange[0]) / (camera.far - camera.near);
    const B = zIndexRange[1] - A * camera.far;
    return Math.round(A * dist + B);
  }
  return undefined;
};
export const epsilon = (value: number) => (Math.abs(value) < 1e-10 ? 0 : value);

// eslint-disable-next-line arrow-body-style
export const getCameraCSSMatrix = (multipliers => {
  return (matrix: Matrix4) => getCSSMatrix(matrix, multipliers);
})([1, -1, 1, 1, 1, -1, 1, 1, 1, -1, 1, 1, 1, -1, 1, 1]);
// eslint-disable-next-line arrow-body-style
export const getObjectCSSMatrix = (scaleMultipliers => {
  return (matrix: Matrix4, factor: number) =>
    getCSSMatrix(matrix, scaleMultipliers(factor), 'translate(-50%,-50%)');
})((f: number) => [
  1 / f,
  1 / f,
  1 / f,
  1,
  -1 / f,
  -1 / f,
  -1 / f,
  -1,
  1 / f,
  1 / f,
  1 / f,
  1,
  1,
  1,
  1,
  1,
]);

const getCSSMatrix = (matrix: Matrix4, multipliers: number[], prepend = '') => {
  let matrix3d = 'matrix3d(';
  for (let i = 0; i !== 16; i++) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    matrix3d += epsilon(multipliers[i] * matrix.elements[i]) + (i !== 15 ? ',' : ')');
  }
  return prepend + matrix3d;
};
