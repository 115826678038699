import React from 'react';
interface Props {
  url?: string | undefined;
  metadata?: {
    fileName: string;
    date: string;
    takenBy: string;
  };
  isShow?: boolean;
  isHover?: boolean;
}
export function PhotoThumbnail({ url, metadata, isShow = false }: Props) {
  return (
    <div>
      {isShow && metadata && (
        <div style={{ position: 'absolute', left: 10, top: 0 }}>
          <div className="square-vector">
            <img src={url} className="square-img" alt="Thumbnail" />
            <h3 className="square-title">{metadata.fileName}</h3>
            <p className="square-description">{metadata.date}</p>
            <p className="square-description">{`Uploaded by: ${metadata.takenBy || 'NA'}`}</p>
          </div>
        </div>
      )}
    </div>
  );
}
