import React, { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import CloseSvg from '^/assets/icons/close-small.svg';
import dsPalette from '^/constants/ds-palette';

const Root = styled.div({
  display: 'inline-block',
  verticalAlign: 'super',
});

interface CustomStyleProp {
  readonly customStyle?: CSSObject;
}

const IconWrapper = styled.div<CustomStyleProp>(({ customStyle }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '11px',
  height: '11px',
  borderRadius: '50%',

  cursor: 'pointer',

  ':hover': {
    backgroundColor: dsPalette.grey60.toString(),
  },

  ...customStyle,
}));

const CloseIcon = styled(CloseSvg)({
  fill: '#4D4C4C',
});

export interface Props {
  readonly iconWrapperCustomStyle?: CSSObject;
  onCloseClick(): void;
}

export const UnassignButton: FC<Props> = ({ onCloseClick, iconWrapperCustomStyle }) => (
  <Root>
    <IconWrapper onClick={onCloseClick} customStyle={iconWrapperCustomStyle}>
      <CloseIcon data-testid="popup-close" />
    </IconWrapper>
  </Root>
);
