/* eslint-disable @typescript-eslint/ban-ts-comment */
import { autobind } from 'core-decorators';
import {
  BufferAttribute,
  BufferGeometry,
  Camera,
  Float32BufferAttribute,
  Mesh,
  OrthographicCamera,
  PerspectiveCamera,
  Vector3,
} from 'three';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2';
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { DisposeHelper } from '../../Lib/Helper';
import { MERGE_MATERIAL } from '../../Lib/constant';

const _vector = /*@__PURE__*/ new Vector3();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const _camera = /*@__PURE__*/ new Camera();

/**
 *	- shows frustum, line of sight and up of the camera
 *	- suitable for fast updates
 * 	- based on frustum visualization in lightgl.js shadowmap example
 *		https://github.com/evanw/lightgl.js/blob/master/tests/shadowmap.html
 */

class ThreeCameraHelper extends LineSegments2 {
  public camera: PerspectiveCamera | OrthographicCamera;
  public pointMap: any;
  public lineSegments: LineSegments2 | undefined;
  public bufferGeometry: BufferGeometry | undefined;
  public surface: Mesh;
  public vertices: number[] = [];
  public viewer: Viewer;
  public constructor(camera: Camera, viewer: Viewer) {
    const geometry = new LineSegmentsGeometry();
    const material = new LineMaterial({ color: 0x156289, linewidth: 2, toneMapped: false });
    super(geometry, material);
    this.viewer = viewer;

    this.surface = new Mesh(new BufferGeometry(), MERGE_MATERIAL);
    this.surface.visible = false;
    this.add(this.surface);
    const pointMap: any = {};
    const vertices = this.vertices;
    // near

    addLine('n1', 'n2');
    addLine('n2', 'n4');
    addLine('n4', 'n3');
    addLine('n3', 'n1');

    // far

    addLine('f1', 'f2');
    addLine('f2', 'f4');
    addLine('f4', 'f3');
    addLine('f3', 'f1');

    // sides

    addLine('n1', 'f1');
    addLine('n2', 'f2');
    addLine('n3', 'f3');
    addLine('n4', 'f4');

    // up

    addLine('u1', 'u2');
    addLine('u2', 'u3');
    addLine('u3', 'u1');

    // cone

    addLine('p', 'n1');
    addLine('p', 'n2');
    addLine('p', 'n3');
    addLine('p', 'n4');

    function addLine(a: string, b: string) {
      addPoint(a);
      addPoint(b);
    }

    function addPoint(id: string) {
      vertices.push(0, 0, 0);

      if (pointMap[id] === undefined) {
        pointMap[id] = [];
      }

      pointMap[id].push(vertices.length / 3 - 1);
    }

    //@ts-ignore
    this.camera = camera;
    //@ts-ignore
    this.type = 'ThreeCameraHelper';

    if (this.camera.updateProjectionMatrix) {
      this.camera.updateProjectionMatrix();
    }

    this.matrix = camera.matrixWorld;
    this.matrixAutoUpdate = false;

    this.pointMap = pointMap;
    this.bufferGeometry = new BufferGeometry();
    this.bufferGeometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

    this.update();
    const { width, height } = viewer.renderer.domElement.getBoundingClientRect();
    this.material.resolution.set(width, height);
  }

  @autobind
  public update() {
    const geometry = new BufferGeometry();
    const pointMap = this.pointMap;
    geometry.setAttribute('position', new Float32BufferAttribute(this.vertices, 3));
    const w = 1,
      h = 1;

    // we need just camera projection matrix inverse
    // world matrix must be identity

    _camera.projectionMatrixInverse.copy(this.camera.projectionMatrixInverse);

    // center / target

    // setPoint('c', pointMap, geometry, _camera, 0, 0, -1);
    // setPoint('t', pointMap, geometry, _camera, 0, 0, 1);

    // near
    const n1 = this.setPoint('n1', pointMap, geometry, _camera, -w, -h, -1);
    const n2 = this.setPoint('n2', pointMap, geometry, _camera, w, -h, -1);
    const n3 = this.setPoint('n3', pointMap, geometry, _camera, -w, h, -1);
    const n4 = this.setPoint('n4', pointMap, geometry, _camera, w, h, -1);
    const array: number[] = [];
    this.genrateTriangles(array, n1, n2, new Vector3());
    this.genrateTriangles(array, n2, n4, new Vector3());
    this.genrateTriangles(array, n3, n4, new Vector3());
    this.genrateTriangles(array, n3, n1, new Vector3());
    this.surface.geometry.setAttribute('position', new BufferAttribute(new Float32Array(array), 3));

    // far

    // setPoint('f1', pointMap, geometry, _camera, -w, -h, 1);
    // setPoint('f2', pointMap, geometry, _camera, w, -h, 1);
    // setPoint('f3', pointMap, geometry, _camera, -w, h, 1);
    // setPoint('f4', pointMap, geometry, _camera, w, h, 1);

    // up

    this.setPoint('u1', pointMap, geometry, _camera, w * 0.7, h * 1.1, -1);
    this.setPoint('u2', pointMap, geometry, _camera, -w * 0.7, h * 1.1, -1);
    this.setPoint('u3', pointMap, geometry, _camera, 0, h * 2, -1);

    this.geometry.setPositions(geometry.attributes.position.array as Float32Array);
    geometry.dispose();
  }
  @autobind
  private genrateTriangles(array: number[], a: Vector3, b: Vector3, c: Vector3) {
    // const bufferGeometry = new BufferGeometry();

    // const position = this.surface.geometry.getAttribute('position');
    // position.setXYZ(index, a.x, a.y, a.z);
    // position.setXYZ(index + 1, b.x, b.y, b.z);
    // position.setXYZ(index + 2, c.x, c.y, c.z);
    array.push(a.x, a.y, a.z, b.x, b.y, b.z, c.x, c.y, c.z);
    // this.surface.geometry.setAttribute(
    //   'position',
    //   new BufferAttribute(new Float32Array([...a.toArray(), ...b.toArray(), ...c.toArray()]), 3)
    // );
  }
  @autobind
  public dispose() {
    DisposeHelper.disposeHierarchy(this);
  }
  @autobind
  private setPoint(
    point: string,
    pointMap: any,
    geometry: BufferGeometry,
    camera: Camera,
    x: number,
    y: number,
    z: number
  ): Vector3 {
    _vector.set(x, y, z).unproject(camera);

    const points = pointMap[point];

    if (points !== undefined) {
      const position = geometry.getAttribute('position');

      for (let i = 0, l = points.length; i < l; i++) {
        position.setXYZ(points[i], _vector.x, _vector.y, _vector.z);
      }
    }
    return _vector.clone();
  }
}

export { ThreeCameraHelper };
