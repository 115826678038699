import { Language } from '^/types';

export default {
  altitudeLabel: {
    [Language.KO_KR]: '스테이션 고도(m)',
    [Language.EN_US]: 'Enter Altitude Relative to Dock(m)',
  },
  batteryLevelLabel: {
    [Language.KO_KR]: '배터리 레벨 도달 시 비행 시작(%)',
    [Language.EN_US]: 'Start flight when battery level reaches(%)',
  },
  storageLevelLabel: {
    [Language.KO_KR]: '스토리지 수준에 도달하면 비행 시작(MB)',
    [Language.EN_US]: 'Start flight when storage level reaches (MB)',
  },
  rthAltitudePlaceholder: {
    [Language.KO_KR]: 'RTH 고도를 입력하세요',
    [Language.EN_US]: 'Enter RTH altitude',
  },
  batteryLevelLabelPlaceholder: {
    [Language.KO_KR]: '배터리 레벨 입력',
    [Language.EN_US]: 'Enter battery level',
  },
  storageLevelLabelPlaceholder: {
    [Language.KO_KR]: '저장 수준 입력',
    [Language.EN_US]: 'Enter storage level',
  },
};
