import * as T from '^/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface FlightPlanState {
  editingFlightPlanId: T.FlightPlanContent['id'] | undefined;
  deletingFlightPlanId: T.FlightPlanContent['id'] | undefined;
  flightPlans: Record<T.FlightPlanContent['id'], T.FlightPlanContent>;
  flightPlanIds: Array<T.FlightPlanContent['id']>;
  fetchFlightPlanStatus: T.APIStatus;
  selectedFlightPlanSort: T.FlightPlanSortByCriteria;
  selectedFlightPlanDropdownOption: T.FlightPlanContent['id'] | undefined;
}

interface FlightPlanActions {
  setEditingFlightPlanId(flightPlanContentId: T.FlightPlanContent['id'] | undefined): void;
  setDeletingFlightPlanId(flightPlanContentId: T.FlightPlanContent['id'] | undefined): void;
  setFlightPlans(flightPlans: Record<T.FlightPlanContent['id'], T.FlightPlanContent>): void;
  setSelectedFlightPlanSort(newSelectedFlightPlanSort: T.FlightPlanSortByCriteria): void;
  setSelectedFlightPlanDropdownOption(
    newSelectedFlightPlanSort: T.FlightPlanContent['id'] | undefined
  ): void;
  updateFlightPlans(singleFlightPlan: T.FlightPlanContent): void;
  setFlightPlanIds(): void;
}

const flightPlanInitialState: FlightPlanState = {
  editingFlightPlanId: undefined,
  deletingFlightPlanId: undefined,
  flightPlans: {},
  flightPlanIds: [],
  fetchFlightPlanStatus: T.APIStatus.IDLE,
  selectedFlightPlanSort: T.FlightPlanSortByCriteria.NEWEST,
  selectedFlightPlanDropdownOption: undefined,
};

type FlightPlanStore = FlightPlanState & FlightPlanActions;

export const useFlightPlanStore = create<FlightPlanStore>()(
  devtools((set, get) => ({
    ...flightPlanInitialState,
    setFlightPlans(newFlightPlans) {
      const { flightPlans } = get();
      const finalizedFlightPlans = {
        ...newFlightPlans,
        ...flightPlans,
      };
      set({ flightPlans: finalizedFlightPlans });
    },
    updateFlightPlans(singleFlightPlan: T.FlightPlanContent) {
      const { flightPlans } = get();
      const finalizedFlightPlans = {
        ...flightPlans,
        [singleFlightPlan.id]: singleFlightPlan,
      };
      set({ flightPlans: finalizedFlightPlans });
    },
    setSelectedFlightPlanSort(newSelectedFlightPlanSort: T.FlightPlanSortByCriteria) {
      set(flightPlanStore => ({
        ...flightPlanStore,
        selectedFlightPlanSort: newSelectedFlightPlanSort,
      }));
    },
    setEditingFlightPlanId(flightPlanContentId: T.FlightPlanContent['id']) {
      set(flightPlanStore => ({
        ...flightPlanStore,
        editingFlightPlanId: flightPlanContentId,
      }));
    },
    setDeletingFlightPlanId(flightPlanContentId: T.FlightPlanContent['id']) {
      set(flightPlanStore => ({
        ...flightPlanStore,
        deletingFlightPlanId: flightPlanContentId,
      }));
    },
    setSelectedFlightPlanDropdownOption(
      flightPlanContentId: T.FlightPlanContent['id'] | undefined
    ) {
      set(flightPlanStore => ({
        ...flightPlanStore,
        selectedFlightPlanDropdownOption: flightPlanContentId,
      }));
    },
    setFlightPlanIds() {
      const { flightPlans } = get();
      const flightPlanIds = Object.keys(flightPlans);
      set(flightPlanStore => ({
        ...flightPlanStore,
        flightPlanIds,
      }));
    },
    updateFlightPlanContent(flightPlanContent: T.FlightPlanContent | undefined) {
      const { flightPlans } = get();
      const changingContentId = flightPlanContent?.id;

      if (!flightPlanContent || !changingContentId) {
        return;
      }

      set(flightPlanStore => ({
        ...flightPlanStore,
        flightPlans: {
          ...flightPlans,
          [changingContentId]: flightPlanContent,
        },
      }));
    },
  }))
);
