import { Language } from '^/types';

export default {
  close: {
    [Language.KO_KR]: '닫기',
    [Language.EN_US]: 'Close',
  },
  playTooltip: {
    [Language.KO_KR]: '멈춰요',
    [Language.EN_US]: 'Pause',
  },
  pauseTooltip: {
    [Language.KO_KR]: `놀고요`,
    [Language.EN_US]: `Play`,
  },
  fullScreenTooltip: {
    [Language.KO_KR]: '전체 화면 입력',
    [Language.EN_US]: 'Enter FullScreen',
  },
  exitFullScreenTooltip: {
    [Language.KO_KR]: '전체화면 종료',
    [Language.EN_US]: 'Exit FullScreen',
  },
  timelapse: {
    [Language.KO_KR]: '타임랩스 플레이어',
    [Language.EN_US]: 'Timelapse player',
  },
  timelapsePlayerInfo: {
    [Language.EN_US]:
      'This is only the preview of images. You can download the actual timelapse video.',
    [Language.KO_KR]: '미리보기 입니다. 실제 타임랩스 동영상을 다운로드할 수 있습니다.',
  },
  changePlaybackSpeed: {
    [Language.KO_KR]: '재생 속도 변경',
    [Language.EN_US]: 'Change playback speed',
  },
  timelapseToast: {
    failed: {
      title: {
        [Language.KO_KR]: '문제가 발생하였습니다',
        [Language.EN_US]: 'Something went wrong',
      },
      description: {
        [Language.KO_KR]: '타임랩스 생성 중 오류가 발생했습니다.',
        [Language.EN_US]: 'An error occurred while creating timelapse.',
      },
    },

    cannotCreate: {
      title: {
        [Language.KO_KR]: '타임랩스를 생성할 수 없습니다',
        [Language.EN_US]: 'Cannot Create Timelapse',
      },
      description: {
        [Language.KO_KR]: '타임랩스를 생성할 이미지 수는 1개 이상이어야 합니다.',
        [Language.EN_US]: 'The number of images to create timelapse should be more than 1.',
      },
    },
    creating: {
      title: {
        [Language.KO_KR]: '타임랩스 동영상 생성중',
        [Language.EN_US]: 'Creating Timelapse Video',
      },
      description: {
        [Language.KO_KR]: '타임랩스 동영상을 만드는 동안 기다려주세요.',
        [Language.EN_US]: 'Please wait while creating the timelapse video.',
      },
      isLoading: true,
    },
    success: {
      title: {
        [Language.KO_KR]: '타임랩스 생성',
        [Language.EN_US]: 'Timelapse Created',
      },
      description: {
        [Language.KO_KR]: '이제 타임랩스 영상을 볼 수 있습니다.',
        [Language.EN_US]: 'You can now view the timelapse video.',
      },
      button: {
        [Language.KO_KR]: '지금 열기',
        [Language.EN_US]: 'Open now',
      },
    },
    download: {
      title: {
        [Language.KO_KR]: '저속 촬영이 다운로드되지 않았습니다.',
        [Language.EN_US]: 'Timelapse not downloaded',
      },
      description: {
        [Language.KO_KR]: '나중에 다시 시도해 주세요.',
        [Language.EN_US]: 'Please try again later',
      },
    },

    cancel: {
      title: {
        [Language.KO_KR]: '타임랩스 다운로드 취소',
        [Language.EN_US]: 'Timelapse download cancelled',
      },
      description: {
        [Language.KO_KR]: '나중에 다시 시도해 주세요.',
        [Language.EN_US]: 'Please try again later',
      },
    },
  },
  tooltip: {
    download: {
      [Language.EN_US]: 'Download',
      [Language.KO_KR]: '다운로드',
    },
    cancel: {
      [Language.EN_US]: 'Cancel Download',
      [Language.KO_KR]: '다운로드 취소',
    },
  },
};
