import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js';
import { useL10n } from '^/hooks';
import Text from './text';
import { EarthworkAnalysis } from '^/store/earthwork/types';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import { chartOptions, dataSeries, getLabelsInOrder } from './earthworkUtils';
import VolumeSelectDropdown from './EarthworkSidebarContents/VolumeSelectDropdown';

const ChartContainer = styled.div`
  background: #f4f4f4;
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ChartHeader = styled.div`
  margin-bottom: 24px;
`;

const ChartSubtitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 20px;
`;

const Tab = styled.button<{ $active: boolean }>`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: ${props => (props.$active ? ' var(--color-theme-primary-lighter)' : 'white')};
  color: ${props => (props.$active ? 'white' : ' var(--color-theme-primary-lighter)')};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s ease;

  &:active {
    transform: translateY(0);
  }
`;

const CanvasWrapper = styled.div`
  width: 100%;
  height: 300px;
`;

interface Props {
  analysis: EarthworkAnalysis;
}

const VolumeBurndownChart: React.FC<Props> = ({ analysis }) => {
  const [l10n] = useL10n();

  const { selectedVolumeId, setSelectedVolumeId } = useEarthWorkStore(s => ({
    selectedVolumeId: s.selectedVolumeId,
    setSelectedVolumeId: s.setSelectedVolumeId,
  }));

  const data = analysis.zones.find(zone => zone.id === selectedVolumeId) ?? analysis.zones[0];

  const [activeSoilType, setActiveSoilType] = useState<string>(data.cut[0].name);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  const labels = getLabelsInOrder(analysis.moments);

  const { earthworkAnalysis } = useEarthWorkStore();

  const volumeOptions = earthworkAnalysis?.zones.map(zone => ({
    text: zone.name,
    value: zone.id,
  }));

  const createChartData = useCallback(
    () => ({
      labels,
      datasets: dataSeries(data, activeSoilType),
    }),
    [data, activeSoilType]
  );

  useEffect(() => {
    if (!volumeOptions) {
      return;
    }
    if (volumeOptions.length) {
      setSelectedVolumeId(volumeOptions[0].value);
    }
  }, [earthworkAnalysis]);

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    if (ctx) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(ctx, chartOptions(createChartData));
    }
  }, [activeSoilType, selectedVolumeId]);

  const rocklayers = data.cut.map(item => item.name);

  return (
    <ChartContainer>
      <ChartHeader>
        <ChartSubtitle>{l10n(Text.volumeBurndownChart)}</ChartSubtitle>
        <TabContainer>
          <VolumeSelectDropdown
            options={volumeOptions}
            value={selectedVolumeId}
            onSelect={(value: string) => setSelectedVolumeId(value)}
          />
          {rocklayers.length > 0
            ? rocklayers.map((item, index) => (
                <Tab
                  $active={activeSoilType === item}
                  onClick={() => setActiveSoilType(item)}
                  key={`tab-${index}`}
                >
                  {item}
                </Tab>
              ))
            : null}
        </TabContainer>
      </ChartHeader>
      <CanvasWrapper>
        <canvas ref={chartRef} />
      </CanvasWrapper>
    </ChartContainer>
  );
};

export default VolumeBurndownChart;
