import { Language } from '^/types';

export default {
  weekday: {
    [Language.KO_KR]: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    [Language.EN_US]: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  year: {
    [Language.KO_KR]: '년',
    [Language.EN_US]: '',
  },
  month: {
    [Language.KO_KR]: [
      '1월',
      '2월',
      '3월',
      '4월',
      '5월',
      '6월',
      '7월',
      '8월',
      '9월',
      '10월',
      '11월',
      '12월',
    ],
    [Language.EN_US]: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  date: {
    [Language.KO_KR]: '일',
    [Language.EN_US]: '',
  },
  fileName: {
    [Language.KO_KR]: '파일 이름',
    [Language.EN_US]: 'File Name',
  },
  droneModel: {
    [Language.KO_KR]: '드론 모델',
    [Language.EN_US]: 'Drone Model',
  },
  createdAt: {
    [Language.KO_KR]: '생성된 날짜',
    [Language.EN_US]: 'Created At',
  },
};
