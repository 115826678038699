import Color from 'color';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IssueOnMapSVG from '^/assets/icons/annotation/issue-on-map.svg';
import IssueItem from '^/components/atoms/IssueAnnotation/IssueItem';
import { makeTextEliipsis } from '^/components/ol/styles';
import palette from '^/constants/palette';
import { FontFamily } from '^/constants/styles';
import * as T from '^/types';
import Tippy from '@tippyjs/react';
import {
  DimensionType,
  getCoordinatesIssueRectangle,
} from '^/utilities/getCoordinatesIssueRectangle';

const ResizeHandle = styled.div({
  zIndex: 1,
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  cursor: 'pointer',
  position: 'absolute',
  backgroundColor: 'white',
  boxShadow: palette.OlMeasurementBox.shadow,
});

const ResizeHandleTl = styled(ResizeHandle)({
  top: '-5px',
  left: '-5px',
});
const ResizeHandleBr = styled(ResizeHandle)({
  right: '-5px',
  bottom: '-5px',
});

interface IssuePhotoMarkerWrapperProps {
  issuePoint: {
    x: number;
    y: number;
  };
}

const IssuePhotoMarkerWrapper = styled.div<IssuePhotoMarkerWrapperProps>`
  z-index: 1;
  position: absolute;
  pointer-events: auto;
  transform: translate(-10px, -10px);
  top: ${props => props.issuePoint.y}px;
  left: ${props => props.issuePoint.x}px;
`;

const IssuePhotoAnnotation = styled(IssueOnMapSVG)<{ fill: Color }>`
  fill: ${props => props.fill.toString()};
  cursor: pointer;
`;

const IssuePhotoAnnotationTextWrapper = styled.div({});
const IssuePhotoAnnotationText = styled.div({
  fontSize: 10,
  padding: '6px',
  display: 'flex',
  cursor: 'pointer',
  fontWeight: 'bold',
  userSelect: 'none',
  borderRadius: '3px',
  alignItems: 'center',
  lineHeight: 'normal',
  backdropFilter: 'blur(6px)',
  fontFamily: FontFamily.NOTOSANS,
  boxShadow: palette.OlMeasurementBox.shadow,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: palette.OlMeasurementBox.title.toString(),
});

interface IssueBoundaryProps {
  top?: number;
  left?: number;
  width: number;
  height: number;
  isIssueEnabled?: boolean;
}

enum DragHandleKind {
  TOP_LEFT = 'tl',
  BOTTOM_RIGHT = 'br',
}

const IssueBoundary = styled.div<IssueBoundaryProps>`
  cursor: pointer;
  position: absolute;
  border: 2px solid red;
  box-sizing: border-box;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  cursor: ${props => (props.isIssueEnabled ? 'crosshair' : 'default')};
`;

interface Point {
  x: number;
  y: number;
}

interface InitialIssueRect {
  issuePoint: Point;
  coords: Point;
  dimensions: DimensionType;
}

export function IssuePhotoMarker({
  rect,
  onBlur,
  content,
  isEditing,
  onIssueClick,
  isIssueEnabled,
  onIssueRelocate,
}: Readonly<{
  isEditing: boolean;
  rect: DOMRect | null;
  onBlur(): void;
  isIssueEnabled?: boolean;
  content: T.IssuePhotoContent;
  onIssueClick(contentId: T.IssuePhotoContent['id']): void;
  onIssueRelocate(contentId: T.IssuePhotoContent['id'], newPoint: T.Point): void;
}>) {
  const issueMarkerRef = useRef<HTMLDivElement>(null);
  const [initialIssueRect, setInitialIssueRect] = useState<InitialIssueRect | undefined>();
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  const [isDragging, setIsDragging] = useState(false);
  const [dragHandle, setDragHandle] = useState<string | null>(null);
  const [isResizeEnabled, setIsResizeEnabled] = useState<boolean>(false);
  const [coords, setCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [issuePoint, setIssuePoint] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState<DimensionType>({
    width: { value: 0, sign: 0 },
    height: { value: 0, sign: 0 },
    top: 0,
    left: 0,
  });

  const processedText: string | null =
    content.title !== undefined ? makeTextEliipsis(content.title) : null;

  useEffect(() => {
    if (rect && content.info.imagePoint) {
      const [x1, y1, , , , y3, x4] = content.info.imagePoint;
      const tempX = x1 * rect.width;
      const tempY = (1 - y1) * rect.height;
      const width = (x4 - x1) * rect.width;
      const height = (y3 - y1) * rect.height;
      setIssuePoint({ x: tempX, y: tempY });
      setCoords({ x: tempX, y: tempY - height });
      setDimensions({
        width: { value: width, sign: Math.abs(width) },
        height: { value: height, sign: Math.abs(height) },
      });
      setInitialIssueRect({
        issuePoint: { x: tempX, y: tempY },
        coords: { x: tempX, y: tempY - height },
        dimensions: {
          width: { value: width, sign: Math.abs(width) },
          height: { value: height, sign: Math.abs(height) },
        },
      });
    }
  }, [rect, content.info.imagePoint]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>, handle: string) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(prev => !prev);
    setDragHandle(handle);

    if (isDragging && isResizeEnabled && rect) {
      onIssueRelocate(content.id, getCoordinatesIssueRectangle({ rect, coords, dimensions }));
      onBlur?.();
      setIsResizeEnabled(false);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (isDragging && rect) {
      const { clientX, clientY } = event;
      let newWidth = dimensions.width.value;
      let newHeight = dimensions.height.value;
      const newX = clientX - rect.left;
      const newY = clientY - rect.top;
      if (newX < 0 || newX > rect.width || newY < 0 || newY > rect.height) {
        // Only accept the point inside the image
        return;
      }

      if (dragHandle === DragHandleKind.BOTTOM_RIGHT) {
        newWidth = clientX - rect.left - coords.x;
        newHeight = clientY - rect.top - coords.y;
        if (newHeight > 0 && newWidth > 0) {
          setIssuePoint({
            x: clientX - rect.left - newWidth,
            y: clientY - rect.top,
          });
        }
      } else if (dragHandle === DragHandleKind.TOP_LEFT) {
        const tempX = Math.min(clientX - rect.left, newX);
        const tempY = Math.min(clientY - rect.top, newY);

        newWidth = dimensions.width.value + coords.x - tempX;
        newHeight = dimensions.height.value + coords.y - tempY;

        if (newHeight > 0 && newWidth > 0) {
          setCoords({
            x: clientX - rect.left,
            y: clientY - rect.top,
          });
          setIssuePoint({
            x: clientX - rect.left,
            y: clientY - rect.top + newHeight,
          });
        }
      }
      if (newHeight > 0 && newWidth > 0) {
        setDimensions({
          width: { value: newWidth, sign: Math.sign(newWidth) },
          height: { value: newHeight, sign: Math.sign(newHeight) },
        });
      }
    }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
    }
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [isDragging]);

  useEffect(() => {
    function handleIssueMarkerOutsideClick(event: MouseEvent) {
      const isInsideIssueMarkerParent = issueMarkerRef.current?.parentElement?.contains(
        event.target as Node
      );

      if (!isInsideIssueMarkerParent) {
        isEditing && onBlur?.();
      }
    }

    window.addEventListener('mousedown', handleIssueMarkerOutsideClick);
    return () => window.removeEventListener('mousedown', handleIssueMarkerOutsideClick);
  }, [isEditing]);

  const handleOnIssueClick = useCallback(() => {
    setIsButtonVisible(false);
    onIssueClick(content.id);
  }, [isButtonVisible, content.id]);

  useEffect(() => {
    setIsResizeEnabled(false);
    setIsDragging(false);
    if (initialIssueRect) {
      setIssuePoint(initialIssueRect.issuePoint);
      setCoords(initialIssueRect.coords);
      setDimensions(initialIssueRect.dimensions);
    }
  }, [isIssueEnabled]);

  return (
    <div>
      <IssuePhotoMarkerWrapper ref={issueMarkerRef} issuePoint={issuePoint}>
        <IssuePhotoAnnotation fill={new Color('red')} />

        <Tippy
          theme="zero-space-theme"
          arrow={false}
          trigger="click"
          interactive={true}
          appendTo={() => document.body}
          onClickOutside={() => {
            setIsButtonVisible(true);
          }}
          content={<IssueItem content={content} />}
          placement="bottom-start"
          animation={false}
        >
          <IssuePhotoAnnotationTextWrapper onClick={handleOnIssueClick}>
            {!isEditing && isButtonVisible && (
              <IssuePhotoAnnotationText>{processedText}</IssuePhotoAnnotationText>
            )}
          </IssuePhotoAnnotationTextWrapper>
        </Tippy>
      </IssuePhotoMarkerWrapper>
      <IssueBoundary
        top={coords.y}
        left={coords.x}
        width={dimensions.width.value}
        height={dimensions.height.value}
        onClick={() => {
          setIsResizeEnabled(true);
        }}
      >
        {isResizeEnabled && (
          <>
            <ResizeHandleTl onMouseDown={e => handleMouseDown(e, DragHandleKind.TOP_LEFT)} />
            <ResizeHandleBr onMouseDown={e => handleMouseDown(e, DragHandleKind.BOTTOM_RIGHT)} />
          </>
        )}
      </IssueBoundary>
    </div>
  );
}
