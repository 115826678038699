import buttonTokens from './button';
import COLORS from './colors';
import colorTokens from './colors/color-tokens';
import typography from './text';

const theme = {
  colors: COLORS,
  colorTokens: colorTokens,
  button: buttonTokens,
  typography,
};

export default theme;
