import React, { ReactElement } from 'react';
import { ResizableBox, ResizableProps } from 'react-resizable';
import 'react-resizable/css/styles.css';
import styled from 'styled-components';
import DraggableContainer from '../DraggableContainer';

type Props = ResizableProps & { children: ReactElement; handleClose(): void };

const ResizableBoxWrapper = styled.div({
  position: 'relative',
  margin: '12px',
  backgroundColor: '#EFEFF3',
  borderRadius: '4px',
});

const DraggableResizableContainer = (props: Props) => {
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <DraggableContainer handleClose={props.handleClose}>
      <ResizableBoxWrapper onMouseDown={handleMouseDown}>
        {/*FIXME: @ebraj Replace this package, cause this is not supported for the react 18 */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ResizableBox {...props}>{props.children}</ResizableBox>
      </ResizableBoxWrapper>
    </DraggableContainer>
  );
};

export default DraggableResizableContainer;
