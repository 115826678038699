import { useDispatch } from 'react-redux';
import _ from 'lodash-es';

import { calculateHash } from '^/utilities/file-util';
import * as T from '^/types';

import { useAttachment } from './useAttachment';
import { attachmentsStore, useAttachmentsStore } from '^/store/attachmentsStore';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import { runPromisesInBatches } from '^/utilities/promise';
import { RowValue } from '^/components/molecules/CoordinateTable';
import XLSX from 'xlsx';
import { rowValues2GCP } from '^/utilities/gcp-util';
import { gcpGroupInfo2CSV } from './useUploadContent';
import { formatWithOffset } from '^/utilities/date-format';
import { useCurrentProject } from './useCurrentProject';

interface UploadGCPProps {
  readonly files: File[];
  readonly noOfStream?: number;
}
interface FileObj {
  file: File;
  hash: string;
  bucketFileName: string;
}

export function useUploadGCPKSA() {
  const dispatch = useDispatch();
  const { createAttachment } = useAttachment();
  const { setIsUploading, resetAttachmentUploadStatus } = useAttachmentsStore(s => ({
    setIsUploading: s.setIsUploading,
    resetAttachmentUploadStatus: s.resetAttachmentUploadStatus,
  }));
  const project = useCurrentProject();

  async function uploadGCPKSA({ files, noOfStream }: UploadGCPProps) {
    try {
      setIsUploading(true);
      const padMaxLength: number = 4;
      const padPrefix: string = '0';
      const crs: T.ProjectionEnum =
        project?.coordinateSystem ?? T.ProjectionEnum.GRS80_EPSG_2952_EN;

      const readFile = async (file: File): Promise<T.GCP[]> =>
        new Promise<T.GCP[]>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent) => {
            if (!e.target) {
              reject('Error reading file');
              return;
            }
            const { result }: FileReader = e.target as FileReader;
            const fileData: Uint8Array = new Uint8Array(result as ArrayBuffer);
            const workbook: XLSX.WorkBook = XLSX.read(fileData, { type: 'array' });

            const firstWorkSheet: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];
            const fileRowValues: RowValue[] = XLSX.utils.sheet_to_json(firstWorkSheet, {
              header: 1,
              defval: '',
            });

            if (fileRowValues[0][0] === 'Label') {
              fileRowValues.shift();
            }

            const gcps = rowValues2GCP(fileRowValues);
            resolve(gcps);
          };

          reader.onerror = err => {
            reject(err);
          };

          reader.readAsArrayBuffer(file);
        });

      const gcps = await readFile(files[0]);
      const gcpsCSV: File = gcpGroupInfo2CSV({
        gcps: gcps,
        crs: crs,
      });

      const fileObjs: FileObj[] = [gcpsCSV].map((f, index: number) => {
        const datetime: string = formatWithOffset(0, new Date(), 'yyMMdd');
        const randomNumber: number = Math.floor(Math.random() * 10000);
        const fileName = `${datetime}` + `_${crs}` + `_${randomNumber}` + '.csv';
        return {
          file: f,
          hash: calculateHash(f),
          bucketFileName: `${(index + 1).toString().padStart(padMaxLength, padPrefix)}_${fileName}`,
        };
      });
      /**
       * @desc To keep files in the head of queue to be uploaded first,
       * we should spread files evenly into group instead of using splice method.
       */
      const groupNumber: number = noOfStream === undefined ? 1 : noOfStream;
      const fileGroups: FileObj[][] = _.zip(..._.chunk(fileObjs, groupNumber)).map(fileGroup =>
        fileGroup.filter((fileObj): fileObj is FileObj => fileObj !== undefined)
      );

      const uploadPromises = fileGroups.flatMap(filesInGroup =>
        filesInGroup.map(fileObj => async () => {
          if (!attachmentsStore.getState().isUploading) {
            return;
          }
          await createAttachment({
            contentId: '',
            projectId: project?.id,
            file: fileObj.file,
            attachmentType: T.AttachmentType.GCP,
            bucketFileName: fileObj.bucketFileName,
          });
        })
      );

      const results = await runPromisesInBatches(uploadPromises, 10);
      const isSuccess = results.every(result => result.status === 'fulfilled');
      if (!attachmentsStore.getState().isUploading) {
        return;
      }

      if (
        !isSuccess ||
        Object.keys(attachmentsStore.getState().attachmentUploadStatus).length !== files.length
      ) {
        throw new Error();
      }

      resetAttachmentUploadStatus();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsUploading(false);
      resetAttachmentUploadStatus();
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.SOURCE_ERROR,
        })
      );
    }
  }

  return { uploadGCPKSA };
}
