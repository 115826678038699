type TextTypes = 'label' | 'title' | 'heading' | 'caption';
type VariantTypes = 'primary' | 'secondary';
type Sizes = '5' | '4' | '3';

type Typography = {
  [K in TextTypes]: {
    [V in VariantTypes]: {
      [S in Sizes]?: {
        fontSize?: number;
        fontWeight?: number;
      };
    };
  };
};

const typography: Typography = {
  label: {
    primary: {
      '5': { fontSize: 15, fontWeight: 700 },
    },
    secondary: {
      '5': { fontSize: 15, fontWeight: 500 },
      '4': { fontSize: 14, fontWeight: 400 },
      '3': { fontSize: 12, fontWeight: 400 },
    },
  },
  title: {
    primary: { '5': { fontSize: 16, fontWeight: 700 }, '4': { fontSize: 14, fontWeight: 700 } },
    secondary: { '4': { fontSize: 14, fontWeight: 400 }, '3': { fontSize: 12, fontWeight: 400 } },
  },
  heading: {
    primary: {
      '5': { fontSize: 16, fontWeight: 400 },
      '4': { fontSize: 14, fontWeight: 700 },
    },
    secondary: {
      '4': { fontSize: 14, fontWeight: 400 },
      '3': { fontSize: 12, fontWeight: 400 },
    },
  },
  caption: {
    primary: {
      '5': { fontSize: 16, fontWeight: 500 },
      '4': { fontSize: 10, fontWeight: 400 },
    },
    secondary: {},
  },
};

export default typography;
