import palette from '^/constants/palette';
import React from 'react';
import styled from 'styled-components';
import AddSvg from '^/assets/icons/elevation-profile/add.svg';
import { UseL10n, useL10n } from '^/hooks';
import texts from './text';

const AddAndApplyWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '10px',
});

const AddButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',

  padding: '4px 9px',
  borderRadius: '5px',
  border: '1px solid var(--color-theme-primary)',
  color: 'var(--color-theme-primary)',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: palette.hoverGray.toString(),
  },
});

const ApplyButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: '5px 10px',
  borderRadius: '5px',
  backgroundColor: 'var(--color-theme-primary)',
  color: '#ffffff',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: palette.hoverWhite.toString(),
  },
});

const Text = styled.span({
  fontSize: '13px',
});

interface Props {
  onAdd(): void;
  onApply(): void;
}
export const ColorAndHeightAddAndApply = ({ onAdd, onApply }: Props) => {
  const [l10n]: UseL10n = useL10n();

  return (
    <AddAndApplyWrapper>
      <AddButton onClick={onAdd}>
        <AddSvg />
        <Text>{l10n(texts.addColor)}</Text>
      </AddButton>
      <ApplyButton onClick={onApply}>
        <Text>{l10n(texts.apply)}</Text>
      </ApplyButton>
    </AddAndApplyWrapper>
  );
};
