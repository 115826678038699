// This file uses redux store directly exported from "^/index"
//  and updates query client manually

import { QueryClient } from '@tanstack/react-query';
import { ERROR } from '../types';
import { UserKeys } from './constants';
import { getUserInfoQueryfn } from '.';
import { store } from '^/index';
import { getRequestErrorType } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { ShowSignUpTutorialPopup } from '^/store/duck/Pages';
import { FinishGetUserInfo } from '^/store/duck/Users';

export const getUserInfoQuery = async (queryClient: QueryClient, id: number) => {
  try {
    await queryClient.fetchQuery(UserKeys.getUserInfoKey(id), getUserInfoQueryfn(store));
    // store.dispatch(AddUserInfo({user}))
    store.dispatch(FinishGetUserInfo({}));
    store.dispatch(ShowSignUpTutorialPopup());
  } catch (error) {
    if (error.message === ERROR.AuthHeaderUndefinedError) {
      store.dispatch(ChangeAuthedUser({}));
    } else {
      store.dispatch(
        FinishGetUserInfo({
          error: getRequestErrorType(error),
        })
      );
    }
  }
};
