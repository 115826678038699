import * as T from '^/types';

export const flightPlanSortOptions = {
  [T.Language.KO_KR]: [
    {
      text: '최신순',
      value: T.FlightPlanSortByCriteria.NEWEST,
    },
    // {
    //   text: '이름',
    //   value: T.FlightPlanSortByCriteria.NAME,
    // },
    {
      text: '오래된순',
      value: T.FlightPlanSortByCriteria.OLDEST,
    },
  ],
  [T.Language.EN_US]: [
    {
      text: 'Newest',
      value: T.FlightPlanSortByCriteria.NEWEST,
    },
    // {
    //   text: 'Name',
    //   value: T.FlightPlanSortByCriteria.NAME,
    // },
    {
      text: 'Oldest',
      value: T.FlightPlanSortByCriteria.OLDEST,
    },
  ],
};

export const flightScheduleSortOptions = {
  [T.Language.KO_KR]: [
    {
      text: '최신순',
      value: T.FlightScheduleSortByCriteria.NEWEST,
    },

    // {
    //   text: '이름',
    //   value: T.FlightScheduleSortByCriteria.NAME,
    // },
    {
      text: '오래된순',
      value: T.FlightScheduleSortByCriteria.OLDEST,
    },
  ],
  [T.Language.EN_US]: [
    {
      text: 'Newest',
      value: T.FlightScheduleSortByCriteria.NEWEST,
    },
    // {
    //   text: 'Name',
    //   value: T.FlightScheduleSortByCriteria.NAME,
    // },
    {
      text: 'Oldest',
      value: T.FlightScheduleSortByCriteria.OLDEST,
    },
  ],
};
