/* eslint-disable max-lines */
import Color from 'color';
import { FeatureLike } from 'ol/Feature';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import OlText from 'ol/style/Text';
import React from 'react';

import PlusPNG from '^/assets/icons/annotation/add-point@2x.png';
import CheckSVG from '^/assets/icons/annotation/check.svg';
import CurrentLocationSVG from '^/assets/icons/annotation/current-location.svg';
import PlainWhitePNG from '^/assets/icons/annotation/edit-point@2x.png';
import IssueOnMapSVG from '^/assets/icons/annotation/issue-on-map.svg';
import {
  MarkerIconWithoutShadow,
  MarkerIconWithShadow,
} from '^/assets/icons/annotation/marker-on-map.svg';
import { ClickedOrMovingPointIcon } from '^/assets/icons/annotation/point-clicked.svg';
import BlueGCPMarker from '^/assets/icons/blue-gcp-marker.png';
import GCPMarkerDark from '^/assets/icons/GCPMarker.png';
import GCPMarker from '^/assets/icons/gcp-marker.png';
import PhotoMarkerSVG from '^/assets/icons/photo/photo-marker.svg';
import PhotoSelectedSVG from '^/assets/icons/photo/photo-selected.svg';
import RotateDefaultSVG from '^/assets/icons/rotate-default.svg';
import { GeometryType, IconAnchorUnits, OlCustomPropertyNames } from './constants';
import { photoPointIcon, photoPointIconSecondary } from '^/assets/icons/imageBase64';
import { createOlTextStyleFromGeometryType, OlTextStyleGeometryType } from './contentTypeSwitch';

import dsPalette from '^/constants/ds-palette';
import { commonConstants } from '^/constants/map-display';
import palette from '^/constants/palette';
import * as T from '^/types';
import { isMobile } from '^/utilities/device';
import { makeIntoOlIcon } from '^/utilities/ol-layer-util';

export enum ColorNumbers {
  Point05 = 0.5,
  Point02 = 0.2,
  Point17 = 0.17,
}

export interface DesignDxfStyleParam {
  color: Color;
  strokeWidth: number;
  fillAlpha?: number;
}

const ELLIPSIS_THRESHOLD: number = 18;

export function makeTextEliipsis(text: string): string {
  return text.length > ELLIPSIS_THRESHOLD ? `${text.substr(0, ELLIPSIS_THRESHOLD)}...` : text;
}

/**
 * @desc Do not use mere style but use styleFunctions, because they increase performance greatly
 * describe an action or a state for a key name
 */
export const olStyleFunctions: {
  mouseHoverOnLayer(color: Color): () => Style;
  newlyCreatedVectorLayer(): Style;
  markerWithoutShadow(color: Color): Style;
  markerWithShadow(
    color: Color,
    text?: string
  ): (featureLike: FeatureLike, resolution: number) => Style;
  emptyCircle(): Style;
  blueCircle(): Style;
  blueCircle2(isSelected?: boolean): Style;
  rotateDefaultPoint(): Style;
  plainWhitePoint(): Style;
  plusPoint(): Style;
  plusPointDebug(): Style;
  fadedPhotoPointIcon(): Style;
  photoPointIcon(): Style;
  checkPoint(scale: number): Style;
  defaultLayerStyle(
    color: Color,
    text?: string
  ): (featureLike?: FeatureLike, resolution?: number) => Style[];
  clickedWhitePoint(color: Color): () => Style;
  movingPoint(color: Color): () => Style;
  designDXFStyle(param: DesignDxfStyleParam): () => Style;
  photoMarker(isPhotoSelected?: boolean): Style;
  gcp(): (featureLike: FeatureLike, resolution: number) => Style;
  darkGcp(): (featureLike: FeatureLike, resolution: number) => Style;
  blueGCP(): (featureLike: FeatureLike, resolution: number) => Style;
  geolocation(): Style;
  geolocationAccuracy(): Style;
  issueMarker(
    color: Color,
    text?: string,
    opacity?: number
  ): (featureLike: FeatureLike, resolution: number) => Style;
} = {
  mouseHoverOnLayer: color => () =>
    new Style({
      fill: new Fill({
        color: color.alpha(ColorNumbers.Point17).toString(),
      }),
      stroke: new Stroke({
        color:
          color === undefined
            ? '#3399CC'
            : color.darken(ColorNumbers.Point02).lighten(ColorNumbers.Point05).toString(),
        width: 3,
      }),
    }),
  newlyCreatedVectorLayer: () =>
    new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: '#ffcc33',
        width: 2,
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: '#ffcc33',
        }),
      }),
    }),
  markerWithoutShadow: (color: Color) =>
    new Style({
      image: new Icon({
        /**
         * @todo change svg to png, because svg is 엄청 느려요. 걍 엄청 느립니다 바꿔야합니다.
         */
        src: makeIntoOlIcon(<MarkerIconWithoutShadow color={color} />),
        scale: commonConstants.markerIconScale,
      }),
    }),
  markerWithShadow: (color: Color, text?: string) => (_: FeatureLike, resolution: number) => {
    const processedText: string | null = text !== undefined ? makeTextEliipsis(text) : null;

    return new Style({
      image: new Icon({
        /**
         * @todo change svg to png, because svg is 엄청 느려요. 걍 엄청 느립니다 바꿔야합니다.
         */
        src: makeIntoOlIcon(<MarkerIconWithShadow color={color} />),
        anchorOrigin: 'bottom-center' as any,
        anchor: [0.2, 55],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.PIXELS,
        scale: getDynamicIconScale(resolution, true),
      }),
      text:
        processedText !== null
          ? new OlText({
              ...createOlTextStyleFromGeometryType({
                geometryType: OlTextStyleGeometryType.MARKER,
                textLength: processedText.length,
              }),
              text: processedText,
              scale: getDynamicLabelScale(resolution),
            })
          : undefined,
    });
  },
  emptyCircle: () =>
    new Style({
      image: new CircleStyle({
        radius: 0,
      }),
    }),
  blueCircle: () =>
    new Style({
      image: new CircleStyle({
        radius: increasePointSizeOnMobile(6),
        fill: new Fill({
          color: new Color(palette.white).toString(),
        }),
        stroke: new Stroke({
          color: dsPalette.themePrimaryLighter.toString(),
          width: 3,
        }),
      }),
    }),
  blueCircle2: isSelected =>
    new Style({
      image: new CircleStyle({
        radius: increasePointSizeOnMobile(6),
        fill: new Fill({
          color: isSelected
            ? dsPalette.themePrimaryLightest.toString()
            : dsPalette.themePrimaryLighter.toString(),
        }),
        stroke: new Stroke({
          color: new Color(palette.white).toString(),
        }),
      }),
    }),
  rotateDefaultPoint: () =>
    new Style({
      image: new Icon({
        src: makeIntoOlIcon(<RotateDefaultSVG />),
        scale: isMobile() ? 1.5 : 1,
      }),
    }),
  plainWhitePoint: () =>
    new Style({
      image: new Icon({
        src: encodeURI(PlainWhitePNG),
        scale: increasePointSizeOnMobile(0.5),
      }),
      zIndex: 9999999999999,
    }),
  plusPoint: () =>
    new Style({
      image: new Icon({
        src: encodeURI(PlusPNG),
        scale: increasePointSizeOnMobile(0.5),
      }),
      zIndex: 9999999999999,
    }),
  plusPointDebug: () =>
    new Style({
      image: new Icon({
        src: encodeURI(PlusPNG),
        scale: increasePointSizeOnMobile(0.8),
      }),
      zIndex: 9999999999999,
    }),
  checkPoint: (scale: number) =>
    new Style({
      image: new Icon({
        src: makeIntoOlIcon(<CheckSVG />),
        scale: isMobile() ? 1.5 + scale : 1 + scale,
      }),
      zIndex: 999999,
    }),
  defaultLayerStyle: (color: Color, text?: string) => (_: FeatureLike, resolution: number) => {
    const processedText: string | null = text !== undefined ? makeTextEliipsis(text) : null;

    return [
      new Style({
        fill: new Fill({
          color: color.alpha(ColorNumbers.Point17).toString(),
        }),
        stroke: new Stroke({
          color: color.darken(ColorNumbers.Point02).toString(),
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
        text:
          processedText !== null
            ? new OlText({
                ...createOlTextStyleFromGeometryType({
                  geometryType: OlTextStyleGeometryType.NON_MARKER,
                  textLength: processedText.length,
                }),
                text: processedText,
                scale: getDynamicLabelScale(resolution),
              })
            : undefined,
      }),
    ];
  },
  clickedWhitePoint: (color: Color) => () =>
    new Style({
      image: new Icon({
        src: makeIntoOlIcon(<ClickedOrMovingPointIcon color={color} />),
        scale: increasePointSizeOnMobile(1),
      }),
      zIndex: 9999999999999,
    }),
  movingPoint: (color: Color) => () =>
    new Style({
      image: new Icon({
        src: makeIntoOlIcon(<ClickedOrMovingPointIcon color={color} hasTranslucentCircle={true} />),
        scale: increasePointSizeOnMobile(1),
      }),
      zIndex: 9999999999999,
    }),
  designDXFStyle: (param: DesignDxfStyleParam) => () =>
    new Style({
      fill: param.fillAlpha
        ? new Fill({
            color: param.color.alpha(param.fillAlpha).toString(),
          })
        : undefined,
      stroke: new Stroke({
        width: param.strokeWidth,
        color: param.color.toString(),
      }),
    }),
  photoMarker: (isPhotoSelected: boolean) =>
    new Style({
      image: new Icon({
        src: isPhotoSelected
          ? makeIntoOlIcon(<PhotoSelectedSVG />)
          : makeIntoOlIcon(<PhotoMarkerSVG />),
        scale: isMobile() ? 0.7 : 0.8,
      }),
      zIndex: 9999999999999,
    }),
  gcp: () => (featureLike, resolution) => {
    const text: string | null = (() => {
      const unProcessedText: string | undefined = featureLike
        .get(OlCustomPropertyNames.GCP_LABEL)
        ?.toString();

      if (unProcessedText) {
        return makeTextEliipsis(unProcessedText);
      }

      return null;
    })();

    return new Style({
      image: new Icon({
        src: encodeURI(GCPMarker),
        scale: getDynamicIconScale(resolution),
      }),
      zIndex: 9999999999999,
      text: text
        ? new OlText({
            ...createOlTextStyleFromGeometryType({
              geometryType: OlTextStyleGeometryType.MARKER,
              textLength: text.length,
            }),
            text,
            scale: getDynamicLabelScale(resolution),
          })
        : undefined,
    });
  },
  darkGcp: () => (featureLike, resolution) => {
    const text: string | null = (() => {
      const unProcessedText: string | undefined = featureLike
        .get(OlCustomPropertyNames.GCP_LABEL)
        ?.toString();

      if (unProcessedText) {
        return makeTextEliipsis(unProcessedText);
      }

      return null;
    })();

    return new Style({
      image: new Icon({
        src: encodeURI(GCPMarkerDark),
        scale: getDynamicIconScale(resolution),
      }),
      zIndex: 9999999999999,
      text: text
        ? new OlText({
            ...createOlTextStyleFromGeometryType({
              geometryType: OlTextStyleGeometryType.MARKER,
              textLength: text.length,
            }),
            text,
            scale: getDynamicLabelScale(resolution),
          })
        : undefined,
    });
  },
  blueGCP: () => (featureLike, resolution) => {
    const gcpStyle: Style = olStyleFunctions.gcp()(featureLike, resolution);

    gcpStyle.setImage(
      new Icon({
        src: encodeURI(BlueGCPMarker),
        scale: getDynamicIconScale(resolution),
        // scale: increasePointSizeOnMobile(1),
      })
    );
    gcpStyle.setZIndex(Number(gcpStyle.getZIndex()) + 1);

    return gcpStyle;
  },
  geolocation: () =>
    new Style({
      image: new Icon({
        src: makeIntoOlIcon(<CurrentLocationSVG />),
        rotateWithView: true,
      }),
    }),
  fadedPhotoPointIcon: () =>
    new Style({
      image: new Icon({
        src: photoPointIconSecondary,
      }),
    }),
  photoPointIcon: () =>
    new Style({
      image: new Icon({
        src: photoPointIcon,
      }),
    }),
  geolocationAccuracy: () =>
    new Style({
      fill: new Fill({
        color: 'rgba(31, 71, 130, 0.2)',
      }),
    }),
  issueMarker:
    (color: Color, text?: string, opacity?: number) => (_: FeatureLike, resolution: number) => {
      const processedText: string | null = text !== undefined ? makeTextEliipsis(text) : null;
      return new Style({
        image: new Icon({
          src: makeIntoOlIcon(<IssueOnMapSVG fill={color} />),
          anchorOrigin: 'bottom-center' as any,
          anchor: [0.49, 10.5],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.PIXELS,
          scale: getDynamicIconScale(resolution),
          opacity: opacity ?? 1,
        }),
        text:
          processedText !== null
            ? new OlText({
                ...createOlTextStyleFromGeometryType({
                  geometryType: OlTextStyleGeometryType.MARKER,
                  textLength: processedText.length,
                }),
                text: processedText,
                scale: getDynamicLabelScale(resolution),
              })
            : undefined,
      });
    },
  // blueDots: () => () =>
  //   new Style({
  //     image: new Icon({
  //       src: makeIntoOlIcon(<ClickedOrMovingPointIcon color={color} hasTranslucentCircle={true} />),
  //       scale: increasePointSizeOnMobile(1),
  //     }),
  //     zIndex: 9999999999999,
  //   }),
};

/**
 * @description to override styles of draw interaction
 * don't change order of spread arrays
 */
export function makeCustomDrawStyle({
  contentType,
  geometryType,
}: {
  contentType: T.MeasurementContent['type'];
  geometryType: GeometryType;
}): Style[] {
  return [
    ...(geometryType === GeometryType.LINE_STRING
      ? [...olStyleFunctions.defaultLayerStyle(palette.measurements[contentType])()]
      : []),
    ...[
      new Style({
        fill: new Fill({
          color: palette.measurements[contentType].alpha(ColorNumbers.Point17).toString(),
        }),
      }),
    ],
    ...([T.ContentType.AREA, T.ContentType.VOLUME].includes(contentType)
      ? []
      : olStyleFunctions.defaultLayerStyle(palette.measurements[contentType])()),
  ];
}

const MOBILE_POINT_SCALE: number = 2;

function increasePointSizeOnMobile(initialScale: number): number {
  return isMobile() ? initialScale * MOBILE_POINT_SCALE : initialScale;
}
function clampScale(scale: number, minScale: number, maxScale: number): number {
  if (scale > maxScale) {
    return maxScale;
  }
  if (scale < minScale) {
    return minScale;
  }
  return scale;
}

export function getDynamicIconScale(resolution: number, isMaker?: boolean): number {
  const markerScaleFactor = commonConstants.markerIconScale / resolution;

  if (isMaker) {
    const markerMinScale = 0.4;
    const markerMaxScale = 0.6;
    return clampScale(markerScaleFactor, markerMinScale, markerMaxScale);
  } else {
    const defaultMinScale = 0.4;
    const defaultMaxScale = 1;
    const defaultScale = 1 / resolution;
    return clampScale(defaultScale, defaultMinScale, defaultMaxScale);
  }
}

export function getDynamicLabelScale(resolution: number): number {
  const labelScaleFactor = commonConstants.markerIconScale / resolution;
  const labelMaxScale = 1;
  const labelMinScale = 0.7;

  return clampScale(labelScaleFactor, labelMinScale, labelMaxScale);
}
