import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import XLSX from 'xlsx';

import { CRS_TITLE_LABEL_INDEX } from '^/components/molecules/SourcePhotoUpload/GCPInput';
import { config } from '^/config';
import { makeAuthHeader } from '^/store/duck/API';
import {
  RunDXF2Raster,
  UploadBim,
  UploadBlueprintDWG,
  UploadBlueprintDXF,
  UploadBlueprintPDF,
  UploadDesign,
  UploadDsm,
  UploadGCP,
  UploadLas,
  UploadOrthophoto,
  UploadSourcePhoto,
} from '^/store/duck/Contents';
import { usePostFlightPlanMutation } from '^/store/react-query/drone-station/flight-plan';
import * as T from '^/types';
import { getCoordinateTitles } from '^/utilities/coordinate-util';
import { formatWithOffset } from '^/utilities/date-format';
import { exhaustiveCheck } from '^/utilities/exhaustive-check';
import { s2ab } from '^/utilities/file-util';
import { generateUUID } from 'three/src/math/MathUtils';
import { TVslamIndoorFiles, use360IndoorDataUpload } from './useUpload360IndoorVideo';
import { useUploadGCPKSA } from './useUploadGcpKSA';
import usePhotoBulkUpload from './useUploadPhotos';
import { useUploadSingleContent } from './useUploadSingleContent';
import { useUploadSourcePhotos } from './useUploadSourcePhotos';
import { useUploadVideo } from './useUploadVideo';

const {
  PHOTO,
  DRONE_PHOTO,
  SOURCE,
  BLUEPRINT_PDF,
  BLUEPRINT_DXF,
  BLUEPRINT_DWG,
  DESIGN_DXF,
  DSM,
  ORTHO,
  POINTCLOUD,
  BIM,
  GCP,
  THREE_SIXTY,
  FLIGHT_PLAN,
  FLIGHT_VIDEO,
  FLIGHT_LOG,
  THREE_SIXTY_SOURCE,
  THREE_SIXTY_STITCHED,
  THREE_SIXTY_VSLAM,
  THREE_SIXTY_VIDEO,
  INSPECTION,
  VIEWPOINT,
  VSLAM_VIDEO,
}: typeof T.AttachmentType = T.AttachmentType;
export const gcpGroupInfo2CSV: (gcpGroupInfo: T.GCPGroupContent['info']) => File = ({
  gcps,
  crs,
}) => {
  const titles: string[] = ['Label', ...getCoordinateTitles(crs)];

  const eastingIndex: number = titles.findIndex(
    title => title === T.CoordinateTitle.EASTING || title === T.CoordinateTitle.LONGITUDE
  );
  const northingIndex: number = titles.findIndex(
    title => title === T.CoordinateTitle.NORTHING || title === T.CoordinateTitle.LATITUDE
  );
  const altitudeIndex: number = titles.findIndex(title => title === T.CoordinateTitle.ALTITUDE);

  const gcpRowsWithTitles: string[][] = gcps.map(({ label, easting, northing, altitude }) => {
    const row: string[] = [];

    row[CRS_TITLE_LABEL_INDEX] = label;
    row[eastingIndex] = easting?.toString();
    row[northingIndex] = northing?.toString();
    row[altitudeIndex] = altitude?.toString();

    return row;
  });

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([titles, ...gcpRowsWithTitles]);
  const csv: string = XLSX.utils.sheet_to_csv(ws);

  // Using Blob instead of `new File()` to support IE11
  const blob: Blob = new Blob([s2ab(csv)], {
    type: 'text/csv',
  }) as File;
  const datetime: string = formatWithOffset(0, new Date(), 'yyMMdd');

  return _.assign(blob, { name: `${datetime}_${crs}.csv` }) as File;
};

export type UseUploadContent = (props: Props) => void;

export interface Props {
  attachmentType: T.AttachmentType;
  title: string;
  files: File[];
  gcpGroupInfo?: T.GCPGroupContent['info'];
  coordinateSystem?: T.CoordinateSystem;
  screen?: T.Screen;
  photoType?: T.PhotoType;
  bimMeta?: T.BimContent['info']['bimMeta'];
  vslamIndoorFiles?: TVslamIndoorFiles;
  fromAlbum?: boolean;
  logFile?: File;
  photoAlbumId?: string | number;
  shouldShowConfirmationPopup?: boolean;
}

export const useUploadContent: () => UseUploadContent = () => {
  const dispatch: Dispatch = useDispatch();
  const { uploadSourcePhotos } = useUploadSourcePhotos();
  const { uploadGCPKSA } = useUploadGCPKSA();
  const { uploadSingleContent } = useUploadSingleContent();
  const { uploadPhotos } = usePhotoBulkUpload();
  const postFlightPlanMutation = usePostFlightPlanMutation();
  const { uploadVideos } = useUploadVideo();
  const { upload360IndoorData } = use360IndoorDataUpload();
  const projectId: T.Project['id'] | undefined = useSelector(
    (state: T.State) => state.Pages.Contents.projectId
  );
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const Auth = useSelector((s: T.State) => s.Auth);
  const authHeader = makeAuthHeader(Auth, slug);

  return async ({
    attachmentType,
    title,
    files,
    coordinateSystem,
    screen,
    gcpGroupInfo,
    photoType,
    bimMeta,
    vslamIndoorFiles,
    fromAlbum,
    photoAlbumId,
    shouldShowConfirmationPopup,
  }) => {
    switch (attachmentType) {
      case GCP:
        switch (config.region) {
          case T.Region.KSA:
            return uploadGCPKSA({ files: files, noOfStream: 3 });
          case T.Region.DEFAULT:
          default:
            return dispatch(UploadGCP({ files: files, noOfStream: 3 }));
        }

      case PHOTO:
      case DRONE_PHOTO:
      case THREE_SIXTY:
      case THREE_SIXTY_STITCHED:
      case THREE_SIXTY_VSLAM:
      case THREE_SIXTY_VIDEO:
      case INSPECTION:
      case VIEWPOINT:
        return uploadPhotos(files, photoType, undefined, photoAlbumId, shouldShowConfirmationPopup);
      case THREE_SIXTY_SOURCE:
        return uploadPhotos(files, photoType, generateUUID());

      case SOURCE: {
        const gcpsCSV: File | undefined = (() => {
          if (gcpGroupInfo === undefined) {
            return;
          }

          return gcpGroupInfo2CSV(gcpGroupInfo);
        })();

        switch (config.region) {
          case T.Region.KSA:
            return uploadSourcePhotos({
              files: gcpsCSV !== undefined ? [gcpsCSV, ...files] : files,
              noOfStream: 3,
              screen: screen!,
              gcpGroupInfo,
              fromAlbum,
            });
          case T.Region.DEFAULT:
          default:
            return dispatch(
              UploadSourcePhoto({
                files: gcpsCSV !== undefined ? [gcpsCSV, ...files] : files,
                noOfStream: 3,
                screen: screen!,
                gcpGroupInfo,
                fromAlbum,
              })
            );
        }
      }

      case BLUEPRINT_PDF:
        return dispatch(UploadBlueprintPDF({ file: files[0], title }));

      case BLUEPRINT_DXF: {
        if (coordinateSystem) {
          switch (config.region) {
            case T.Region.KSA:
              return uploadSingleContent({
                attachmentType,
                file: files[0],
                title,
                coordinateSystem,
              });
            case T.Region.DEFAULT:
            default:
              return dispatch(UploadBlueprintDXF({ file: files[0], title, coordinateSystem }));
          }
        }

        break;
      }

      case BLUEPRINT_DWG: {
        if (coordinateSystem) {
          switch (config.region) {
            case T.Region.KSA:
              return uploadSingleContent({
                attachmentType,
                file: files[0],
                title,
                coordinateSystem,
              });
            case T.Region.DEFAULT:
            default:
              return dispatch(UploadBlueprintDWG({ file: files[0], title, coordinateSystem }));
          }
        }

        break;
      }

      case DESIGN_DXF: {
        if (coordinateSystem) {
          switch (config.region) {
            case T.Region.KSA: {
              const content = await uploadSingleContent({
                attachmentType,
                file: files[0],
                title,
                coordinateSystem,
              });
              if (content) {
                dispatch(RunDXF2Raster({ contentId: content.id }));
              }
              return;
            }
            case T.Region.DEFAULT:
            default:
              return dispatch(UploadDesign({ file: files[0], title, coordinateSystem }));
          }
        }

        break;
      }

      case ORTHO: {
        switch (config.region) {
          case T.Region.KSA:
            return uploadSingleContent({
              attachmentType,
              file: files[0],
              screen,
            });
          case T.Region.DEFAULT:
          default:
            return dispatch(UploadOrthophoto({ file: files[0], screen: screen! }));
        }
      }

      case DSM: {
        switch (config.region) {
          case T.Region.KSA:
            return uploadSingleContent({
              attachmentType,
              file: files[0],
              screen,
            });
          case T.Region.DEFAULT:
          default:
            return dispatch(UploadDsm({ file: files[0], screen: screen! }));
        }
      }

      case POINTCLOUD: {
        switch (config.region) {
          case T.Region.KSA:
            return uploadSingleContent({
              attachmentType,
              file: files[0],
              screen,
            });
          case T.Region.DEFAULT:
          default:
            return dispatch(UploadLas({ file: files[0], screen: screen! }));
        }
      }

      case BIM: {
        switch (config.region) {
          case T.Region.KSA:
            return uploadSingleContent({
              attachmentType,
              file: files[0],
              title,
              bimMeta,
            });
          case T.Region.DEFAULT:
          default:
            return dispatch(UploadBim({ file: files[0], title, bimMeta }));
        }
      }
      /**
       * Case FLIGHT_PLAN file
       */
      case FLIGHT_PLAN: {
        const normalizedFilename = title.replace(/_/g, '-');
        const fileExtension = files[0].name.split('.').pop();
        const file = new File(files, `${normalizedFilename}.${fileExtension}`, {
          type: files[0].type,
        });

        switch (config.region) {
          case T.Region.KSA:
            /**
             * FIXME: update the upload functionality in KSA region
             */
            return uploadSingleContent({
              attachmentType,
              file,
            });

          case T.Region.DEFAULT:
          default:
            postFlightPlanMutation({ file, projectId, authHeader });
            return;
        }
      }
      case FLIGHT_VIDEO:
      case FLIGHT_LOG:
        switch (config.region) {
          // case T.Region.KSA:
          //   // Implement default region handling for FLIGHT_VIDEO and FLIGHT_LOG
          //   throw new Error(`Upload not implemented for ${attachmentType} in default region`);
          // case T.Region.DEFAULT:
          default:
            return uploadVideos({
              files,
              screen,
            });
        }

      case VSLAM_VIDEO:
        if (!vslamIndoorFiles) {
          return;
        }
        return upload360IndoorData({
          vslamIndoorFiles,
        });
      default:
        return exhaustiveCheck(attachmentType);
    }

    throw new Error(`try to upload wrong data: ${attachmentType}`);
  };
};
