import '../config/polyfills';

import * as Sentry from '@sentry/browser';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { Store } from 'redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import App from './app';
import { WindowSizeContextProvider } from './hooks';
import configStore from './store';
import { queryClient } from './store/react-query';
import { UserAgent, State } from './types';
import { getUserAgent } from './utilities/userAgent';
import { ToastifyContainer } from '^/components/atoms/ToastifyContainer';
import { CesiumContextProvider } from '^/components/cesium/CesiumContext';
import { config } from '^/config';
import { RELEASE_VERSION } from '^/constants/data';
import { initializeGA } from '^/utilities/load-ga';
import reportWebVitals from './reportWebVitals';
import reportHandler from './reportHandler';
import * as T from '^/types';
import { HelmetProvider } from 'react-helmet-async';
import OgTag from './components/organisms/OgTag';

import 'tippy.js/dist/tippy.css';
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';
// import 'react-toastify/dist/ReactToastify.min.css';
import './styles/index.scss';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import InvalidPageDisplay from './components/atoms/InvalidPageDisplay';

if (config.env !== T.NodeEnv.DEVELOPMENT) {
  /* istanbul ignore next */
  Sentry.init({
    dsn: 'https://10ffd1e6237547a792a79bab07719aee@sentry.io/1864893',
    environment: config.env,
    release: config.env === 'production' ? RELEASE_VERSION : undefined,
  });
}

/* istanbul ignore next */
initializeGA();

export const history = createBrowserHistory();
export const store: Store<State> = configStore();
if (!import.meta.env.VITE_MICROSOFT_CLIENT_ID) {
  // eslint-disable-next-line no-console
  console.error('Missing VITE_MICROSOFT_CLIENT_ID');
}
const msalConfiguration: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_MICROSOFT_CLIENT_ID as string,
    redirectUri: `${window.location.origin}/login`,
    // scopes: 'user.read',
  },
};
const msalInstance = new PublicClientApplication(msalConfiguration);

/* istanbul ignore next */
if (getUserAgent() === UserAgent.IE) {
  let msg: string;

  if (navigator.language.includes('ko')) {
    msg =
      '엔젤스윙 플랫폼은 Chrome 브라우저에 최적화되어 있습니다. 사용자분들께서는 Chrome을 통해 엔젤스윙 플랫폼을 이용해주시면 감사하겠습니다.';
  } else {
    msg =
      "[Important notice] Angelswing's website is best optimized for Chrome browser. Please use Chrome to have the best experience on Angelswing.";
  }

  window.alert(msg);
}

// eslint-disable-next-line: @typescript-eslint/strict-boolean-expressions
if (getUserAgent() === UserAgent.SAFARI && window.TouchEvent) {
  document.body.style.cssText = `${document.body.style.cssText}-webkit-touch-callout: none;`;
  document.body.style.webkitUserSelect = 'none';
  document.body.addEventListener('click', e => {
    if (e.target) {
      (e.target as HTMLElement).focus();
    }
  });
}

const rootEl = document.getElementById('root');

const root = createRoot(rootEl!);
root.render(
  <>
    <HelmetProvider>
      <ErrorBoundary fallback={<InvalidPageDisplay />}>
        <Provider store={store}>
          <OgTag />
          <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
              <CesiumContextProvider>
                <WindowSizeContextProvider>
                  <PersistGate loading={<div>Loading...</div>} persistor={persistStore(store)}>
                    <ToastifyContainer />
                    <App />
                  </PersistGate>
                </WindowSizeContextProvider>
              </CesiumContextProvider>
              <ReactQueryDevtools position="top-right" />
            </QueryClientProvider>
          </MsalProvider>
        </Provider>
      </ErrorBoundary>
    </HelmetProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (config.env === T.NodeEnv.DEVELOPMENT) {
  reportWebVitals(reportHandler);
}
