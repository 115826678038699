import React, { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import Controls from '../VideoControls';
import { PlayerWrapper } from './style';

interface VideoPlayerProps {
  url: string;
  id: number;
}

let count = 0;

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, id }) => {
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const controlRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [videoState, setVideoState] = useState<ReactPlayerProps>({
    playing: true,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
    controls: false,
    light: false,
    pip: false,
    loop: false,
    loaded: 0,
  });

  //Destructuring the properties from the videoState
  const {
    playing,
    muted,
    volume,
    playbackRate,
    played,
    seeking,
    buffer,
    controls,
    loop,
    pip,
    light,
    loaded,
  } = videoState;

  const currentTime = videoPlayerRef.current ? videoPlayerRef.current.getCurrentTime() : '00:00';
  const duration = videoPlayerRef.current ? videoPlayerRef.current.getDuration() : '00:00';

  const playPauseHandler = () => {
    // Plays and pause the video (toggling)
    setVideoState(prevState => ({ ...prevState, playing: !prevState.playing }));
  };

  const rewindHandler = () => {
    // Rewinds the video player reducing 5
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 10);
    }
  };

  const handleFastFoward = () => {
    //FastFowards the video player by adding 10
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
    }
  };

  const progressHandler = (state: ReactPlayerProps) => {
    setVideoState(prevState => ({ ...prevState, loaded: state.loaded }));
    if (count > 3 && controlRef.current) {
      controlRef.current.style.visibility = 'hidden'; // toggling player control container
    } else if (controlRef.current && controlRef.current.style.visibility === 'visible') {
      count += 1;
    }

    if (!seeking) {
      setVideoState(prevState => ({ ...prevState, ...state }));
    }
  };

  const seekHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setVideoState(prevState => ({ ...prevState, played: parseFloat(String(value)) }));
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(parseFloat(String(value)));
    }
  };

  const seekMouseUpHandler = (event: MouseEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setVideoState(prevState => ({ ...prevState, seeking: false }));
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(parseFloat(String(value)));
    }
  };

  const volumeChangeHandler = (value: number | number[]) => {
    const newVolume = parseFloat(String(value)) / 100;

    setVideoState(prevState => ({
      ...prevState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    }));
  };

  const volumeSeekUpHandler = (value: number | number[]) => {
    const newVolume = parseFloat(String(value)) / 100;

    setVideoState(prevState => ({
      ...prevState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    }));
  };

  const muteHandler = () => {
    // Mutes the video player
    setVideoState(prevState => ({ ...prevState, muted: !prevState.muted }));
  };

  const onSeekMouseDownHandler = () => {
    setVideoState(prevState => ({ ...prevState, seeking: true }));
  };

  const mouseMoveHandler = () => {
    if (controlRef.current) {
      controlRef.current.style.visibility = 'visible';
    }
    count = 0;
  };

  const bufferStartHandler = () => {
    setVideoState(prevState => ({ ...prevState, buffer: true }));
  };

  const bufferEndHandler = () => {
    setVideoState(prevState => ({ ...prevState, buffer: false }));
  };

  const handleClickFullscreen = () => {
    const videoPlayerElement = wrapperRef.current;

    if (videoPlayerElement) {
      if (videoPlayerElement.requestFullscreen && !fullScreenMode) {
        setFullScreenMode(true);
        void videoPlayerElement.requestFullscreen();
      } else {
        void document.exitFullscreen();
        setFullScreenMode(false);
      }
    }
  };

  const keyDownEventHandler = (event: { code: string }) => {
    if (videoPlayerRef.current) {
      if (event.code === 'Space') {
        playPauseHandler();
      }
    }
  };

  useEffect(() => {
    const handleExitFullscreen = () => {
      if (!document.fullscreenElement) {
        setFullScreenMode(false);
      }
    };
    document.addEventListener('fullscreenchange', handleExitFullscreen);
    document.addEventListener('keydown', keyDownEventHandler);
    return () => {
      document.removeEventListener('fullscreenchange', handleExitFullscreen);
      document.removeEventListener('keydown', keyDownEventHandler);
    };
  }, []);

  const handlePlayBackRateChange = (rate: number) => {
    setVideoState(prevState => ({ ...prevState, playbackRate: rate }));
  };

  return (
    <PlayerWrapper ref={wrapperRef} onMouseMove={mouseMoveHandler}>
      <ReactPlayer
        id={`videoplayer_${id}`}
        ref={videoPlayerRef}
        className="react-player"
        url={url}
        controls={controls}
        playing={playing}
        light={light}
        pip={pip}
        width="100%"
        height="100%"
        loop={loop}
        playbackRate={playbackRate}
        volume={volume}
        muted={muted}
        onProgress={progressHandler}
        onBuffer={bufferStartHandler}
        onBufferEnd={bufferEndHandler}
      />

      <Controls
        controlRef={controlRef}
        buffer={buffer}
        fullScreenMode={fullScreenMode}
        onPlayPause={playPauseHandler}
        playing={playing}
        loaded={loaded}
        onRewind={rewindHandler}
        onForward={handleFastFoward}
        played={played}
        onSeek={seekHandler}
        onSeekMouseUp={seekMouseUpHandler}
        volume={volume}
        onVolumeChangeHandler={volumeChangeHandler}
        onVolumeSeekUp={volumeSeekUpHandler}
        mute={muted}
        seeking={seeking}
        onMute={muteHandler}
        playRate={playbackRate}
        duration={duration as number}
        currentTime={currentTime as number}
        onMouseSeekDown={onSeekMouseDownHandler}
        handleClickFullscreen={handleClickFullscreen}
        handlePlayBackRateChange={handlePlayBackRateChange}
      />
    </PlayerWrapper>
  );
};

export default VideoPlayer;
