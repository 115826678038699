import { useMemo } from 'react';
import { getUserInfo, useAuthUserQuery } from '^/store/react-query/users';

import * as T from '^/types';

export const authedUserSelector: (state: T.State) => T.User | undefined = state =>
  state.Auth.authedUser?.id !== undefined ? getUserInfo(state.Auth.authedUser.id) : undefined;

export const checkIsInternalUser: (authState: T.AuthState) => boolean = authState => {
  const authedUserId = authState.authedUser?.id;
  if (!authedUserId) {
    return false;
  }
  const authedUser = getUserInfo(authedUserId);
  const isInternalUser = authedUser.email.endsWith('@angelswing.io') ?? false;
  return isInternalUser;
};

export function useAuthedUser() {
  const { data: authedUser } = useAuthUserQuery();

  const isInternalUser = useMemo(
    () => authedUser?.email.endsWith('@angelswing.io') ?? false,
    [authedUser]
  );

  return {
    authedUser,
    isInternalUser,
  };
}
