import { Language } from '^/types';

export default {
  back: {
    [Language.KO_KR]: '   뒤로',
    [Language.EN_US]: '   Back',
  },
  createNow: {
    [Language.EN_US]: 'Create Earthwork',
    [Language.KO_KR]: '지상자 생성',
  },

  title: {
    [Language.EN_US]: 'Title *',
    [Language.KO_KR]: '제목 *',
  },
  titlePlaceholder: {
    [Language.EN_US]: 'Please enter a title',
    [Language.KO_KR]: '제목을 입력하세요',
  },
  description: {
    [Language.EN_US]: 'Description',
    [Language.KO_KR]: '설명',
  },
  descPlaceholder: {
    [Language.EN_US]: 'Please enter a description',
    [Language.KO_KR]: '설명을 입력하세요',
  },
  earthwork: {
    emptyZone: {
      [Language.KO_KR]: '선택된 영역이 없습니다.',
      [Language.EN_US]: 'No earthwork available. Create an earthwork before continuing.',
    },
    selectEarthwork: {
      [Language.KO_KR]: '토목 공사 테이블 선택',
      [Language.EN_US]: 'Select Earthwork',
    },
    createNewEarthwork: {
      [Language.EN_US]: '+ Create',
      [Language.KO_KR]: '+ 생성',
    },
    placeholder: {
      [Language.KO_KR]: '토목 공사 테이블 선택',
      [Language.EN_US]: 'Select Earthwork',
    },
  },

  error: {
    title: {
      [Language.KO_KR]: '오류가 발생했습니다',
      [Language.EN_US]: 'An error occurred',
    },
    description: {
      [Language.EN_US]: 'Something went wrong. Please try again.',
      [Language.KO_KR]: '오류가 발생했습니다. 다시 시도해주세요.',
    },
  },
  showBurndown: {
    [Language.KO_KR]: '총량 버드로드 표시',
    [Language.EN_US]: 'Show Burndown Chart',
  },
  volumeBurndownChart: {
    [Language.KO_KR]: '면적 버드로드 차트',
    [Language.EN_US]: 'Volume Burndown Chart',
  },
  rockType: {
    label: {
      [Language.EN_US]: 'Rock Layers *',
      [Language.KO_KR]: '봉사물 레이어 *',
    },
    placeHolder: {
      [Language.EN_US]: 'Add Rock layer',
      [Language.KO_KR]: '봉사물 레이어 추가',
    },
  },

  intialSurvey: {
    label: {
      [Language.EN_US]: 'Initial Survey',
      [Language.KO_KR]: '초기 선별',
    },
    placeHolder: {
      [Language.EN_US]: 'Select initial survey',
      [Language.KO_KR]: '초기 선별 선택',
    },
  },

  finalEarthWork: {
    label: {
      [Language.EN_US]: 'Final Earthwork Design *',
      [Language.KO_KR]: '최종 천장 디자인 *',
    },
    placeHolder: {
      [Language.EN_US]: 'Upload or select ',
      [Language.KO_KR]: '업로드 / 선택 ',
    },
    fileExtensionNotification: {
      [Language.KO_KR]: '지원되는 파일 형식: ',
      [Language.EN_US]: 'Supported file formats: ',
    },
    existingDxf: {
      [Language.KO_KR]: '기존 dxf 파일 선택됨 ',
      [Language.EN_US]: 'Existing dxf file selected ',
    },
    newDxfUploaded: {
      [Language.KO_KR]: '새로운 dxf 파일 업로드됨 ',
      [Language.EN_US]: 'New dxf file uploaded ',
    },
    selectedZones: {
      [Language.EN_US]: 'Selected zones ',
      [Language.KO_KR]: '선택된 영역 ',
    },
    emptyZone: {
      [Language.KO_KR]: '사용 가능한 영역이 없습니다. 계속하기 전에 영역을 생성하세요.',
      [Language.EN_US]: 'No zones available. Create a zone before continuing.',
    },
    route: {
      [Language.EN_US]: 'Add Now',
      [Language.KO_KR]: '지금 추가',
    },
  },

  refresh: {
    [Language.KO_KR]: '다시 로드',
    [Language.EN_US]: 'Refresh',
  },

  completeCalculations: {
    [Language.EN_US]: 'All calculations have been completed.',
    [Language.KO_KR]: '모든 계산이 완료되었습니다.',
  },
  processingCalculations: {
    [Language.EN_US]: 'Processing calculations...',
    [Language.KO_KR]: '계산을 처리하는 중...',
  },
  idealCalculations: {
    [Language.EN_US]: 'Ideal calculations',
    [Language.KO_KR]: '아이디어 계산',
  },

  volume: {
    error: {
      [Language.KO_KR]: '오류가 발생했습니다. 다시 시도해주세요.',
      [Language.EN_US]: 'An error occurred. Please try again.',
    },
    placeholder: {
      [Language.KO_KR]: '볼륨 선택',
      [Language.EN_US]: 'Select Volume',
    },
  },

  dxfContent: {
    placeholder: {
      [Language.EN_US]: 'Upload .dxf or Select DXF',
      [Language.KO_KR]: '.dxf 업로드 또는 DXF 선택',
    },
  },
  sourcePhoto: {
    placeholder: {
      [Language.EN_US]: 'Select Source Photo',
      [Language.KO_KR]: '이미지 소스 선택',
    },
  },

  fileText: {
    [Language.EN_US]: 'Upload File',
    [Language.KO_KR]: '파일 업로드',
  },
  noPreviousFile: {
    [Language.KO_KR]: '다운로드 파일이 없습니다.',
    [Language.EN_US]: 'No previous file.',
  },
  tooltipDeleteAll: {
    [Language.KO_KR]: '모두 삭제',
    [Language.EN_US]: 'Delete all',
  },
  tooltipDelete: {
    [Language.KO_KR]: '삭제',
    [Language.EN_US]: 'Delete',
  },
  totalRockLayers: {
    [Language.EN_US]: 'Total layers',
    [Language.KO_KR]: '총 레이어',
  },
};
