/* eslint-disable max-lines */
import _ from 'lodash-es';
import React, {
  FC,
  Fragment,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useMemo,
  MouseEvent as ReactMouseEvent,
  useRef,
  useState,
  useEffect,
} from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import styled, { CSSObject } from 'styled-components';

import {
  Props as TutorialWrapperHoverableProps,
  TutorialWrapperHoverable,
} from '../TutorialWrapperHoverable';
import CaretSvg from '^/assets/icons/dropdown/dropdown-caret-default.svg';
import SearchSvg from '^/assets/icons/volume-calculation-method/search.svg';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';

export const DEFAULT_DROPDOWN_ITEM_HEIGHT: number = 33;

export interface Option {
  leftText: string;
  value: number | string;
  rightText?: string;
  isDisabled?: boolean;
  tutorial?: TutorialWrapperHoverableProps;
}

export const createOption: (text: string) => Option = text => ({
  leftText: text,
  value: text,
});

export const createOptions: (texts: string[]) => Option[] = texts => texts.map(createOption);

const borderWidth: number = 1;

interface VerticalTrackProps {
  readonly verticalTrackStyle?: CSSObject;
}
const VerticalTrack = styled.div<VerticalTrackProps>(({ verticalTrackStyle }) => ({
  position: 'absolute',
  bottom: '2px',
  right: '4px',
  top: '2px',
  width: '6px',
  borderRadius: '3px',
  ...verticalTrackStyle,
}));

interface VerticalThumbProps {
  readonly verticalThumbStyle?: CSSObject;
}
const VerticalThumb = styled.div<VerticalThumbProps>(({ verticalThumbStyle }) => ({
  borderRadius: '3.3px',
  width: '7px !important',
  backgroundColor: palette.dropdown.thumbColor.toString(),
  ...verticalThumbStyle,
}));

interface RootProps {
  rootStyle?: CSSObject;
}

const Root = styled.div<RootProps>(({ rootStyle }) => ({
  flexShrink: 0,
  width: '135px',
  height: `${DEFAULT_DROPDOWN_ITEM_HEIGHT}px`,
  ...rootStyle,
}));

interface WrapperProps {
  readonly fontSize?: string;
  readonly zIndex?: number;
  readonly hasError?: boolean;
  readonly isOpened?: boolean;
  readonly isDisabled?: boolean;
}
const Wrapper = styled.div<WrapperProps>(
  ({ fontSize, zIndex, hasError, isOpened, isDisabled }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',

    '> button > span': {
      color: !isOpened && hasError ? palette.error.toString() : undefined,
      marginTop: '-1px',
    },

    '& *': {
      fontSize: fontSize !== undefined ? fontSize : '11px',
      fontWeight: 500,
      color: !isDisabled ? dsPalette.title.toString() : palette.disabledFont.toString(),
    },

    zIndex,
  })
);

interface MainButtonProps {
  readonly mainButtonStyle?: CSSObject;
  readonly hasError?: boolean;
  readonly isOpened?: boolean;
  readonly isDisabled?: boolean;
}
const MainButton = styled.button<MainButtonProps>(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),
    textAlign: 'left',
    cursor: 'pointer',

    '& *': {
      width: 'unset !important',
    },
  },
  ({ isOpened, hasError, mainButtonStyle, isDisabled }) => {
    const buttonStyle: CSSObject =
      mainButtonStyle !== undefined
        ? {
            ...mainButtonStyle,
          }
        : {};

    return {
      ...buttonStyle,
      border: !isOpened && hasError ? `1px solid ${palette.error.toString()}` : undefined,
      borderRadius: !isOpened ? '6px' : '6px 6px 0px 0px',
      borderBottom: isOpened ? `1px solid ${palette.dropdown.dividerColor.toString()}` : undefined,
      backgroundColor: isDisabled
        ? palette.iconDisabled.toString()
        : !isOpened && hasError
        ? palette.error.alpha(0.05).toString()
        : undefined,

      cursor: isDisabled ? 'default' : 'pointer',
    };
  }
);

interface SearchIconProps {
  readonly searchIconStyle?: CSSObject;
}
const SearchIconWrapper = styled.span<SearchIconProps>(({ searchIconStyle }) => ({
  marginLeft: '10px',
  ...searchIconStyle,
}));

const ValueWrapper = styled.span({
  marginLeft: '10.8px',
  flexGrow: 1,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  pointerEvents: 'none',
  lineHeight: 'normal',
});

interface ValueEditiorProps {
  readonly valueEditorStyle?: CSSObject;
}
const ValueEditor = styled.input<ValueEditiorProps>(({ valueEditorStyle }) => ({
  marginLeft: '9px',
  flexGrow: 1,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  backgroundColor: 'transparent',
  lineHeight: 'normal',
  ...valueEditorStyle,
}));

interface CaretProps {
  readonly isOpened?: boolean;
  readonly hasError?: boolean;
  readonly caretStyle?: CSSObject;
}

const CaretWrapper = styled.div<CaretProps>(({ isOpened, hasError, caretStyle }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
  '> svg': {
    transform: isOpened ? undefined : 'rotate(180deg)',
  },
  '> svg path': {
    fill: !isOpened && hasError ? palette.error.toString() : undefined,
  },
  ...caretStyle,
}));

interface MenuProps {
  readonly menuStyle?: CSSObject;
  readonly height?: CSSObject['height'];
}

export const Menu = styled.ul.attrs({
  'data-testid': 'dropdown-menu',
})<MenuProps>(
  {
    position: 'absolute',
    zIndex: -1,
    boxSizing: 'border-box',
    top: '100%',
    width: '100%',
    marginTop: `${-borderWidth}px`,

    borderRadius: '0 0 6px 6px',
    borderTop: 0,
    '> div > div:first-of-type': {
      '> div:last-of-type li': {
        borderBottom: 0,
      },
    },

    overflow: 'auto',
  },
  ({ menuStyle, height }) =>
    menuStyle !== undefined
      ? {
          ...menuStyle,
          '&&&': menuStyle,
          height,
        }
      : { height }
);

const ItemCSS: CSSObject = {
  display: 'block',

  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  paddingLeft: '10px',

  fontWeight: 300,

  textAlign: 'left',
  backgroundColor: palette.white.toString(),

  cursor: 'pointer',
};
const ItemLabel = styled.li.attrs({
  'data-testid': 'dropdown-item-label',
})<ItemProps>({
  ...ItemCSS,

  fontWeight: 500,

  pointerEvents: 'none',
});

interface ItemProps {
  readonly itemStyle?: CSSObject;
  readonly isDisabled?: boolean;
}
const Item = styled.li.attrs({
  'data-testid': 'dropdown-item',
})<ItemProps & { hasRightText: boolean }>(
  ({ isDisabled, hasRightText }) => ({
    display: 'flex',
    alignItems: 'center',

    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
    paddingLeft: '10px',
    paddingRight: '10px',

    textAlign: 'left',
    backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),

    cursor: 'pointer',
    lineHeight: `${DEFAULT_DROPDOWN_ITEM_HEIGHT}px`,
    ':hover': {
      backgroundColor: !isDisabled ? palette.dropdown.dropdownHoverColor.toString() : undefined,
      cursor: !isDisabled ? 'pointer' : 'default',
    },

    justifyContent: hasRightText ? 'space-between' : 'left',
    borderBottom: `1px solid ${palette.dropdown.dividerColor.toString()}`,

    '> span': {
      ':first-of-type': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: hasRightText ? '5px' : undefined,
      },
      ':nth-of-type(2)': {
        color: 'red',
      },
      whiteSpace: 'nowrap',
    },
  }),
  ({ itemStyle }) =>
    itemStyle !== undefined
      ? {
          ...itemStyle,
          '&&&': itemStyle,
        }
      : {}
);

const itemLabelValue: string = '[]';

interface OptionItem {
  option: Option;
  itemStyle?: Props['itemStyle'];
  onClick(e: ReactMouseEvent<HTMLLIElement>): void;
  onMouseEnter(e: ReactMouseEvent<HTMLLIElement>): void;
}

const OptionItem: FC<OptionItem> = ({ option, itemStyle, onClick, onMouseEnter }) => {
  if (option.value === itemLabelValue) {
    return (
      <ItemLabel>
        <p>{option.leftText}</p>
      </ItemLabel>
    );
  } else {
    const isDisabled: boolean = Boolean(option.isDisabled);
    const hasRightText: boolean = option.rightText !== undefined;

    const rightText: ReactNode = useMemo(
      () => (hasRightText ? <span>{option.rightText}</span> : undefined),
      [option.rightText]
    );

    const item: ReactNode = (
      <Item
        itemStyle={itemStyle}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        isDisabled={isDisabled}
        hasRightText={hasRightText}
      >
        <span>{option.leftText}</span>
        {rightText}
      </Item>
    );

    if (option.tutorial === undefined) {
      return <>{item}</>;
    }

    return <TutorialWrapperHoverable {...option.tutorial}>{item}</TutorialWrapperHoverable>;
  }
};

export interface StyleProps
  extends RootProps,
    WrapperProps,
    MainButtonProps,
    CaretProps,
    MenuProps,
    ItemProps,
    VerticalThumbProps,
    VerticalTrackProps,
    ValueEditiorProps,
    SearchIconProps {}

export interface Props extends StyleProps {
  readonly value: number | string | null | undefined;
  readonly isOpened?: boolean;
  readonly options: Option[];
  readonly placeHolder: string;
  readonly className?: string;
  readonly hasError?: boolean;
  readonly isSearchEnabled?: boolean;
  readonly menuItemHeight?: string;
  readonly caretSVG?: ReactNode;
  readonly isDisabled?: boolean;
  onClick(option: Option, index: number): void;
  onDropdownItemMouseEnter?(option: Option, index: number): void;
  onDropdownMouseLeave?(): void;
  setIsDropdownOpen?(isOpened: boolean): void;
}

export interface State {
  readonly isOpened: boolean;
  readonly keyword: string;
  readonly filteredOptions: Option[];
}

/**
 * Dropdown element
 */
const Dropdown: FC<Props> = ({
  isDisabled,
  itemStyle,
  isSearchEnabled,
  height,
  menuItemHeight,
  placeHolder,
  verticalThumbStyle,
  menuStyle,
  hasError,
  caretStyle,
  caretSVG,
  searchIconStyle,
  valueEditorStyle,
  rootStyle,
  zIndex,
  fontSize,
  className,
  mainButtonStyle,
  verticalTrackStyle,
  options,
  value,
  onDropdownItemMouseEnter,
  onDropdownMouseLeave,
  onClick,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const [dropdownState, setDropdownState] = useState<State>({
    isOpened: false,
    keyword: '',
    filteredOptions: options,
  });

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    document.addEventListener('touchend', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
      document.removeEventListener('touchend', handleDocumentClick, false);
    };
  }, []);

  useEffect(() => {
    setDropdownState({ ...dropdownState, filteredOptions: options });
  }, [options]);

  const optionToElement: (option: Option, index: number) => ReactNode = (option, index) => {
    const isOptionDisabled: boolean | undefined = option.isDisabled;
    const onOptionClick: (event: SyntheticEvent) => void = event => {
      if (!isOptionDisabled) {
        handleItemClick(event, option, index);
      }
    };

    const onMouseEnter: (event: SyntheticEvent) => void = () => {
      if (!isOptionDisabled) {
        onDropdownItemMouseEnter?.(option, index);
      }
      if (isOptionDisabled) {
        onDropdownMouseLeave?.();
      }
    };

    return (
      <Fragment key={index}>
        <OptionItem
          option={option}
          itemStyle={itemStyle}
          onMouseEnter={onMouseEnter}
          onClick={onOptionClick}
        />
      </Fragment>
    );
  };

  const handleDocumentClick: ({ target }: MouseEvent) => void = ({ target }) => {
    if (
      rootRef.current !== null &&
      !rootRef.current.contains(target as Node) &&
      dropdownState.isOpened
    ) {
      setDropdownState({ ...dropdownState, isOpened: false });
    }
  };

  const handleButtonClick: (e: ReactMouseEvent) => void = e => {
    if (isDisabled) {
      return;
    }
    if (dropdownState.isOpened && dropdownState.keyword.length > 0 && e.nativeEvent.detail === 0) {
      return;
    }
    setDropdownState({ ...dropdownState, isOpened: !dropdownState.isOpened });
  };

  const handleSearchChange: (event: SyntheticEvent<HTMLInputElement>) => void = event => {
    const keyword: string = _.toLower(event.currentTarget.value.trim());

    /**
     * @info dirty trick to solve the AutoFill problem from Browsers
     */
    const matches: RegExpMatchArray | null = keyword.match(/[a-zA-Z\s]+/gi);
    if (keyword.length > 0 && matches && matches.length > 1) {
      return;
    }

    const hasKeyword: (option: Option) => boolean = ({ leftText: text, value: optionValue }) =>
      _.toLower(text).indexOf(keyword) > -1 ||
      _.toLower(optionValue.toString()).indexOf(keyword) > -1;
    const filteredOptions: Option[] = keyword.length === 0 ? options : options.filter(hasKeyword);
    setDropdownState({
      ...dropdownState,
      keyword: event.currentTarget.value,
      filteredOptions,
    });
  };

  const handleItemClick: (event: SyntheticEvent, option: Option, index: number) => void = (
    event,
    option,
    index
  ) => {
    setDropdownState({
      ...dropdownState,
      isOpened: false,
    });
    onClick(option, index);

    /**
     * @desc this preventDefault help the Dropdown react correctly when put it inside of a Label
     */
    event.preventDefault();
  };

  const { keyword, filteredOptions, isOpened }: State = dropdownState;

  const itemHeight: number = menuItemHeight
    ? parseInt(menuItemHeight, 10)
    : DEFAULT_DROPDOWN_ITEM_HEIGHT;
  const totalItemHeight: number = itemHeight * options.length;

  const menuHeight: number =
    parseInt(String(height), 10) > totalItemHeight ? totalItemHeight : parseInt(String(height), 10);

  const selectedOption: Option | undefined = options.find(
    ({ value: optionValue }) => optionValue === value
  );
  const selectedValue: string =
    selectedOption === undefined ? placeHolder : selectedOption.leftText;

  const verticalTrackElement: (props: any) => ReactElement = props => {
    const { style, ...restProps } = props;
    return (
      <VerticalTrack
        style={{ ...style, width: '0', marginRight: '4px' }}
        verticalTrackStyle={verticalTrackStyle}
        {...restProps}
      />
    );
  };
  const verticalThumbElement: (props: any) => ReactElement = props => (
    <VerticalThumb verticalThumbStyle={verticalThumbStyle} {...props} />
  );

  const filteredItems: ReactNode[] = filteredOptions.map(optionToElement);
  const menuItem: ReactNode =
    height && height !== 'auto' ? (
      <Scrollbars
        renderTrackVertical={verticalTrackElement}
        renderThumbVertical={verticalThumbElement}
      >
        {filteredItems}
      </Scrollbars>
    ) : (
      <>{filteredItems}</>
    );

  const filteredItemsLength: number = filteredOptions.length * itemHeight;
  const menu: ReactNode = isOpened ? (
    <Menu
      menuStyle={menuStyle}
      height={
        keyword !== '' && filteredItemsLength < menuHeight
          ? filteredOptions.length * itemHeight
          : menuHeight
      }
      onMouseLeave={onDropdownMouseLeave}
    >
      {menuItem}
    </Menu>
  ) : undefined;
  const caret: ReactNode = (
    <CaretWrapper
      isOpened={isOpened}
      hasError={hasError}
      caretStyle={caretStyle}
      data-testid="dropdown-caret"
    >
      {caretSVG ? caretSVG : <CaretSvg />}
    </CaretWrapper>
  );
  const searchIcon: ReactNode =
    isOpened && isSearchEnabled && (selectedOption === undefined || isOpened) ? (
      <SearchIconWrapper searchIconStyle={searchIconStyle}>
        <SearchSvg />
      </SearchIconWrapper>
    ) : undefined;
  const valueViewer: ReactNode =
    isOpened && isSearchEnabled ? (
      <ValueEditor
        value={keyword}
        valueEditorStyle={valueEditorStyle}
        autoFocus={true}
        onChange={handleSearchChange}
        data-testid="dropdown-value-input"
      />
    ) : (
      <ValueWrapper data-testid="dropdown-value">{selectedValue}</ValueWrapper>
    );

  return (
    <Root rootStyle={rootStyle} className={className} ref={rootRef}>
      <Wrapper
        hasError={hasError}
        zIndex={zIndex ? zIndex + (isOpened ? 1 : 0) : Number(isOpened)}
        fontSize={fontSize}
        isOpened={isOpened}
        isDisabled={isDisabled}
      >
        <MainButton
          mainButtonStyle={mainButtonStyle}
          hasError={hasError}
          onClick={handleButtonClick}
          type="button"
          data-testid="dropdown-mainbutton"
          isOpened={isOpened}
          isDisabled={isDisabled}
        >
          {searchIcon}
          {valueViewer}
          {caret}
        </MainButton>
        {menu}
      </Wrapper>
    </Root>
  );
};

export default Dropdown;
