import theme from '^/theme';
import styled, { CSSObject } from 'styled-components';

export const Title = styled.div<{ customStyles?: CSSObject }>(({ customStyles }) => ({
  fontSize: '16px',
  fontWeight: '400',
  display: 'flex',
  ...customStyles,
}));

export const Value = styled.div<{ bold?: boolean; customStyles?: CSSObject }>(
  ({ bold, customStyles }) => ({
    color: theme.colors.coolGray[700],
    fontSize: bold ? '16px' : '14px',
    fontWeight: bold ? '700' : '400',
    ...customStyles,
  })
);

export const TitleValuePair = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});
