import { defaultBlueprintPDFHeight, defaultBlueprintPDFWidth } from '^/constants/defaultContent';
import { makeBucketURL } from '^/store/duck/API';
import * as T from '^/types';
import { inRange } from 'lodash-es';
import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import ImageTile from 'ol/ImageTile';
import TileLayer from 'ol/layer/Tile';
import { addCoordinateTransforms, addProjection, fromLonLat, get, Projection } from 'ol/proj';
import TileSource from 'ol/source/Tile';
import XYZSource from 'ol/source/XYZ';
import { TileCoord } from 'ol/tilecoord';
import React, { useEffect, useState } from 'react';
import { ajax, AjaxRequest } from 'rxjs/ajax';

export interface TProps {
  readonly zIndex: number;
  readonly content: T.BlueprintPDFContent;
  readonly children?: React.ReactNode;
  readonly updatedImagePoint: [Coordinate, Coordinate];
  readonly updatedGeoPoint: [Coordinate, Coordinate];
  readonly baseMap: Map | null;
  readonly overlayOpacity?: number;
}
interface BlueprintDimension {
  readonly width: number;
  readonly height: number;
}
interface CalcCache {
  readonly zoom: number;
  readonly cos: number;
  readonly sin: number;
}

const defaultDimension: BlueprintDimension = {
  height: defaultBlueprintPDFHeight,
  width: defaultBlueprintPDFWidth,
};

interface TileBoundary {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}
interface TileBoundaries {
  [zoom: number]: TileBoundary | undefined;
}

export const isTileXYZValid: (
  boundaries: TileBoundaries,
  z: number,
  x: number,
  y: number
) => boolean = (boundaries, z, x, y) => {
  const boundary: TileBoundary | undefined = boundaries[z];
  if (boundary === undefined) {
    return false;
  }

  return (
    inRange(x, boundary.minX, boundary.maxX + 1) && inRange(y, boundary.minY, boundary.maxY + 1)
  );
};

export const getXFromLon: (z: number, lon: number, isMax?: boolean) => number = (
  z,
  lon,
  isMax = false
) => (lon / 180 + 1) * 2 ** (z - 1) - (isMax ? 1 : 0);
/**
 * @desc Inverse Latitude coordinate to Tile column number
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Derivation_of_tile_names
 * Sign is reversed since the origin is not top-left, but bottom-left.
 */
export const getYFromLat: (z: number, lat: number, isMax?: boolean) => number = (
  z,
  lat,
  isMax = false
) => {
  const latInRadian: number = (Math.PI * lat) / 180;
  const mercatorY: number = Math.log(Math.tan(latInRadian) + Math.cos(latInRadian));

  return 2 ** (z - 1) * (mercatorY / Math.PI + 1) - (isMax ? 1 : 0);
};

export const getTileBoundaries: (boundaries: T.MapBoundaries) => TileBoundaries = boundaries =>
  Object.keys(boundaries)
    .map(zoom => parseInt(zoom, 10))
    .reduce<TileBoundaries>((tileBoundaries, zoom) => {
      const { minLon, minLat, maxLon, maxLat, minX, minY, maxX, maxY }: T.MapBoundary =
        boundaries[zoom];

      return {
        ...tileBoundaries,
        [zoom]: {
          minX: minX ? minX : getXFromLon(zoom, minLon),
          minY: minY ? minY : getYFromLat(zoom, minLat),
          maxX: maxX ? maxX : getXFromLon(zoom, maxLon, true),
          maxY: maxY ? maxY : getYFromLat(zoom, maxLat, true),
        },
      };
    }, {});

const imageDimensionOf: (content: T.BlueprintPDFContent) => BlueprintDimension = content =>
  content.info.dimension !== undefined ? content.info.dimension : defaultDimension;
const calculateInfo: (
  imageDimension: BlueprintDimension,
  imagePoint: [T.Point, T.Point],
  geoPoint: [Coordinate, Coordinate]
) => CalcCache = (imageDimension, imagePoint, geoPoint) => {
  const coordinate: [Coordinate, Coordinate] = [fromLonLat(geoPoint[0]), fromLonLat(geoPoint[1])];
  const imagePointDiff: T.Point = [
    imagePoint[0][0] - imagePoint[1][0],
    imagePoint[0][1] - imagePoint[1][1],
  ];
  const imageVector: [number, number] = [
    imagePointDiff[0] * imageDimension.width,
    imagePointDiff[1] * imageDimension.height,
  ];
  const coordPointDiff: Coordinate = [
    coordinate[0][0] - coordinate[1][0],
    coordinate[0][1] - coordinate[1][1],
  ];
  const coordinateNorm: number = Math.hypot(...coordPointDiff);
  const imageNorm: number = Math.hypot(...imageVector);
  const innerProduct: number =
    imageVector[0] * coordPointDiff[0] + imageVector[1] * coordPointDiff[1];
  const outerProduct: number =
    imageVector[0] * coordPointDiff[1] - imageVector[1] * coordPointDiff[0];

  return {
    /**
     * @desc pixelPerCoord
     */
    zoom: imageNorm !== 0 ? coordinateNorm / imageNorm : 1,
    cos: imageNorm !== 0 && coordinateNorm !== 0 ? innerProduct / (imageNorm * coordinateNorm) : 1,
    sin: imageNorm !== 0 && coordinateNorm !== 0 ? outerProduct / (imageNorm * coordinateNorm) : 0,
  };
};

export const OlFloorPlanMapLayerVslamNewUI = (props: TProps) => {
  const {
    zIndex,
    content,
    baseMap,
    overlayOpacity,
    updatedImagePoint: imagePoint,
    updatedGeoPoint: geoPoint,
  } = props;
  const preload: number = 0;
  const projectionCode = `CUSTOM:${content.id}`;
  const [projection, setProjection] = useState(get(projectionCode));
  const [layer, setLayer] = useState<TileLayer<TileSource> | null>(null);
  const [calcCache] = useState(calculateInfo(imageDimensionOf(content), imagePoint, geoPoint));

  const loadTile = (tile: ImageTile, url: string) => {
    if (content.info.tms?.boundaries) {
      const tileBoundary = getTileBoundaries(content.info.tms?.boundaries);
      const imageElement: HTMLImageElement = tile.getImage() as HTMLImageElement;
      /**
       * @desc revise y value in the frontend instead of the backend
       * to make the request has values `z/x/y` same with the response `z/x/y`
       * Ref. https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames
       */
      const [z, x, y]: TileCoord = tile.getTileCoord();
      /**
       * @desc the conversion steps are
       * const wmsY2: number = 2 ** z - y - 1;
       */
      const wmsY: number = 2 ** z - y - 1;
      if (!isTileXYZValid(tileBoundary, z, x, wmsY)) {
        imageElement.src = '';

        return;
      }

      const request: AjaxRequest = {
        method: 'GET',
        url,
        responseType: 'blob',
        withCredentials: true,
      };
      ajax(request).subscribe({
        next: ({ response }) => {
          imageElement.src = window.URL.createObjectURL(response);
        },
        error: () => (imageElement.src = ''),
      });
    }
  };

  const fromEPSG3857 = (target: Coordinate): Coordinate => {
    const imageDimension: BlueprintDimension = imageDimensionOf(content);
    const coordinate: [Coordinate, Coordinate] = [fromLonLat(geoPoint[0]), fromLonLat(geoPoint[1])];
    const { zoom, cos, sin }: CalcCache = calcCache;
    const offset: Coordinate = [
      imageDimension.width * imagePoint[0][0],
      imageDimension.height * imagePoint[0][1],
    ];
    const relativePos: Coordinate = [target[0] - coordinate[0][0], target[1] - coordinate[0][1]];

    return [
      (cos * relativePos[0] + sin * relativePos[1]) / zoom + offset[0],
      (-sin * relativePos[0] + cos * relativePos[1]) / zoom + offset[1],
    ];
  };

  const toEPSG3857 = (target: Coordinate): Coordinate => {
    const imageDimension: BlueprintDimension = imageDimensionOf(content);
    const coordinate: [Coordinate, Coordinate] = [fromLonLat(geoPoint[0]), fromLonLat(geoPoint[1])];
    const { zoom, cos, sin }: CalcCache = calcCache;
    const offset: Coordinate = [
      imageDimension.width * imagePoint[0][0],
      imageDimension.height * imagePoint[0][1],
    ];
    const relativePos: Coordinate = [target[0] - offset[0], target[1] - offset[1]];

    return [
      (cos * relativePos[0] + -sin * relativePos[1]) * zoom + coordinate[0][0],
      (sin * relativePos[0] + cos * relativePos[1]) * zoom + coordinate[0][1],
    ];
  };

  useEffect(() => {
    if (!projection) {
      const earthRadius = 6378137;
      const halfSize = Math.PI * earthRadius;
      const customProjection = new Projection({
        code: projectionCode,
        units: 'm',
        extent: [-halfSize, -halfSize, halfSize, halfSize],
        getPointResolution: (resolution, [, pointY]) =>
          resolution / Math.cosh(pointY / earthRadius),
      });
      addProjection(customProjection);
      setProjection(customProjection);
    }
    if (projection) {
      addCoordinateTransforms('EPSG:3857', projectionCode, fromEPSG3857, toEPSG3857);
    }
  }, []);

  useEffect(() => {
    if (layer) {
      baseMap?.removeLayer(layer);
    }

    addCoordinateTransforms('EPSG:3857', projectionCode, fromEPSG3857, toEPSG3857);
    const newLayer = new TileLayer({
      source: new XYZSource({
        url: makeBucketURL(content.id, 'tiles', '{z}', '{x}', '{-y}.png'),
        projection: projectionCode ?? get('EPSG:3857'),
        maxZoom: 16,
        crossOrigin: 'use-credentials',
        tileLoadFunction: loadTile,
      }),
      preload: preload,
      zIndex: zIndex,
    });
    setLayer(newLayer);
    baseMap?.addLayer(newLayer);

    return () => {
      baseMap?.removeLayer(newLayer);
    };
  }, [projection, baseMap]);

  useEffect(() => {
    if (layer && overlayOpacity) {
      layer.setOpacity(overlayOpacity);
    }
  }, [overlayOpacity]);

  return <></>;
};
