import LeftBoldArrowSVG from '^/assets/icons/left-bold-arrow.svg';
import { CancelButton, ConfirmButton } from '^/components/atoms/Buttons';

import FlightScheduleDetails from '^/components/molecules/FlightScheduleCreation/FlightScheduleDetails';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { SideBar } from '^/constants/zindex';
import { UseL10n, UseState, useL10n } from '^/hooks';
import {
  ChangeContentsSidebarTab,
  ChangeIsInFlightScheduleCreation,
  ChangeIsTopBarShown,
} from '^/store/duck/Pages';
import {
  ErrorInputKeys,
  useFlightScheduleCreationStore,
} from '^/store/flightScheduleCreationStore';
import * as T from '^/types';
import _ from 'lodash-es';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import Text from './text';
import { usePostFlightScheduleMutation } from '^/store/react-query/drone-station/flight-schedule';
import { makeAuthHeader } from '^/store/duck/API';

const FlightScheduleUploadWrapper = styled.aside({
  position: 'absolute',
  boxSizing: 'border-box',

  zIndex: SideBar.FLIGHT_SCHEDULE_CREATION,

  width: '100%',
  height: '100vh',
  fontSize: '14px',

  display: 'flex',
  flexDirection: 'column',

  backgroundColor: palette.white.toString(),
});
FlightScheduleUploadWrapper.displayName = FlightScheduleUploadWrapper;

const Header = styled.header({
  boxSizing: 'border-box',
  position: 'relative',

  width: '100%',
  minHeight: '50px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  paddingLeft: '13px',

  borderBottom: `1px solid ${palette.UploadPopup.inputBorder.toString()}`,
});

const LeftArrow = styled(LeftBoldArrowSVG)({
  position: 'absolute',
  left: 26,

  cursor: 'pointer',
});

const Title = styled.span({
  color: dsPalette.title.toString(),
  fontWeight: 'bold',
});

const MaxPage = styled.span({});

const ButtonWrapper = styled.div({
  boxSizing: 'border-box',

  width: '100%',

  padding: '0 20px 20px 20px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '6px',
});

const FillerWrapper = styled.div({
  flexGrow: '1',
  margin: '20px',
});

type Page = number;
const INITIAL_PAGE: Page = 1;
const MAXIMUM_PAGE: Page = 1;

const pageErrorKeyMap: Record<Page, ErrorInputKeys[]> = {
  1: [
    'title',
    'selectedFlightPlan',
    'selectedDroneStation',
    'rthAltitude',
    'selectedExecuteDate',
    'selectedExecuteTime',
    'selectedExecuteEndTime',
    'batteryLevel',
    'storageLevel',
  ],
};

type InputErrors = Partial<Record<ErrorInputKeys, boolean>>;

const FlightScheduleUpload = () => {
  const projectId: T.Project['id'] | undefined = useSelector(
    (state: T.State) => state.Pages.Contents.projectId
  );
  const authHeader = useSelector((s: T.State) => makeAuthHeader(s.Auth, s.PlanConfig.config?.slug));
  const dispatch: Dispatch = useDispatch();

  const [page, setPage]: UseState<Page> = useState<Page>(INITIAL_PAGE);
  const [l10n]: UseL10n = useL10n();

  const { flightScheduleFields, setAllFlightScheduleFieldsError, resetToInitial } =
    useFlightScheduleCreationStore(s => ({
      flightScheduleFields: s.flightScheduleFields,
      setAllFlightScheduleFieldsError: s.setAllFlightScheduleFieldsError,
      resetToInitial: s.resetToInitial,
    }));

  const postFlightScheduleMutation = usePostFlightScheduleMutation();

  const validateInputFields: () => InputErrors = useCallback(() => {
    const errorsForContinuous: InputErrors =
      flightScheduleFields.selectedPlanTimer === T.FlightScheduleScheduleType.CONTINUOUS
        ? {
            batteryLevel: Number(flightScheduleFields.batteryLevel) <= 0,
            storageLevel: Number(flightScheduleFields.storageLevel) <= 0,
            selectedExecuteEndTime: !flightScheduleFields.selectedExecuteEndTime,
          }
        : {};
    const extraErrors: InputErrors =
      flightScheduleFields.selectedPlanTimer !== T.FlightScheduleScheduleType.IMMEDIATE
        ? {
            selectedExecuteDate:
              !flightScheduleFields.selectedExecuteDate.from ||
              !flightScheduleFields.selectedExecuteDate.to,
            ...errorsForContinuous,
            selectedExecuteTime: !flightScheduleFields.selectedExecuteTime,
          }
        : {};
    const nextErrors: InputErrors = {
      title: flightScheduleFields.title.trim().length < 1,
      rthAltitude:
        Number(flightScheduleFields.rthAltitude) > 500 ||
        Number(flightScheduleFields.rthAltitude) < 20,
      selectedFlightPlan: !flightScheduleFields.selectedFlightPlan?.id,
      selectedDroneStation: !flightScheduleFields.selectedDroneStation?.id,

      ...extraErrors,
    };

    return _.pick(nextErrors, pageErrorKeyMap[page]);
  }, [flightScheduleFields, page]);

  const handleConfirmClick: () => void = () => {
    const nextErrors: InputErrors = validateInputFields();

    const hasErrorInThisPage: boolean = Object.values(nextErrors).some(nextError => nextError);

    if (hasErrorInThisPage) {
      setAllFlightScheduleFieldsError(nextErrors);

      return;
    }

    if (page !== MAXIMUM_PAGE) {
      setPage(prevPage => prevPage + 1);
    }

    /**
     * TODO: @ebraj-angelswing
     * Experimenting...
     */
    postFlightScheduleMutation({
      datas: flightScheduleFields,
      projectId,
      authHeader,
    });
  };

  // useEffect(() => {
  //   dispatch(
  //     OpenContentPagePopup({
  //       popup: T.ContentPagePopupType.FLIGHT_SCHEDULE_CREATION_FAIL,
  //       popupContent: 'Render Content Test.',
  //     })
  //   );
  // }, []);

  const handlePreviousClick: () => void = useCallback(() => {
    if (page === INITIAL_PAGE) {
      dispatch(
        ChangeIsInFlightScheduleCreation({
          isInFlightScheduleCreation: false,
        })
      );
      dispatch(
        ChangeContentsSidebarTab({
          sidebarTab: T.ContentPageTabType.FLIGHT_SCHEDULE,
        })
      );
    } else {
      setPage(prevPage => prevPage - 1);
    }
  }, []);

  useEffect(() => {
    dispatch(ChangeIsTopBarShown({ isOpened: false }));

    return () => {
      resetToInitial();
      dispatch(ChangeIsTopBarShown({ isOpened: true }));
    };
  }, []);

  const pageComponentMap: Record<Page, React.ReactNode> = {
    1: <FlightScheduleDetails />,
  };

  return (
    <FlightScheduleUploadWrapper>
      <Header>
        <LeftArrow onClick={handlePreviousClick} />
        <Title>
          {l10n(Text.title)} ({page}
          <MaxPage>{`/${MAXIMUM_PAGE}`})</MaxPage>
        </Title>
      </Header>

      <FillerWrapper>{pageComponentMap[page]}</FillerWrapper>

      <ButtonWrapper>
        <ConfirmButton onClick={handleConfirmClick}>
          {page !== MAXIMUM_PAGE ? l10n(Text.buttons.next) : l10n(Text.buttons.confirm)}
        </ConfirmButton>
        <CancelButton onClick={handlePreviousClick}>{l10n(Text.buttons.cancel)}</CancelButton>
      </ButtonWrapper>
    </FlightScheduleUploadWrapper>
  );
};

export default memo(FlightScheduleUpload);
