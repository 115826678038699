import DraggableSvg from '^/assets/icons/draggable.svg';
import CloseSVG from '^/assets/icons/toast-close.svg';
import React from 'react';
import Draggable, { ControlPosition } from 'react-draggable';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  handleClose(): void;
}

const DraggableChildWrapper = styled.div({
  background: 'white',
  zIndex: '100',
  position: 'fixed',
  boxShadow: '2px 2px 7px 0px rgba(0,0,0,0.15)',
  borderRadius: '4px',
});

const DraggableActionsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 10px 0px 10px',
});

const SVGWrapper = styled.div<{ width: string; height: string }>(({ width, height }) => ({
  width,
  height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

const DraggableHander = styled.strong({
  cursor: 'pointer',
});

const DraggableContainer = (props: Props) => {
  const { children, handleClose } = props;

  /**
   * TODO: @ebraj-angelswing
   * Need to modify a lot later...
   */
  const defaultPosition: ControlPosition = {
    x: window.innerWidth - 480,
    y: -30,
  };

  return (
    <Draggable handle="strong" defaultPosition={defaultPosition}>
      <DraggableChildWrapper>
        <DraggableActionsWrapper>
          <DraggableHander>
            <SVGWrapper width="22px" height="22px">
              <DraggableSvg />
            </SVGWrapper>
          </DraggableHander>

          <SVGWrapper width="22px" height="22px" onClick={handleClose}>
            <CloseSVG />
          </SVGWrapper>
        </DraggableActionsWrapper>
        {children}
      </DraggableChildWrapper>
    </Draggable>
  );
};

export default DraggableContainer;
