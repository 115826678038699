import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type Size = 'sm' | 'md';
interface SidebarState {
  isResizing: boolean;
  photoAlbumSidebarWidth: number;
  photoWrapperRef: HTMLDivElement | null;
  photoSize: Size;
  height: number;
  slidePanelWidth: number;
  slidePanelWidthRef: HTMLDivElement | null;
}

interface SidebarActions {
  setIsResizing(isResizing: boolean): void;
  setPhotoAlbumSidebarWidth(photoAlbumSidebarWidth: number): void;
  setPhotoWrapperRef(photoWrapperRef: HTMLDivElement): void;
  setPhotoSize(photoSize: Size): void;
  setHeight(height: number): void;
  setSlidePanelWidth(slidePanelWidth: number): void;
  setSlidePanelWidthRef(slidePanelWidthRef: HTMLDivElement): void;
}

type SidebarSchema = SidebarState & SidebarActions;

const initialState: SidebarState = {
  isResizing: false,
  photoAlbumSidebarWidth: 466,

  photoWrapperRef: null,
  photoSize: 'sm',
  height: 0,
  slidePanelWidth: 466,
  slidePanelWidthRef: null,
};

export const useSidebarStore = create<SidebarSchema>()(
  devtools(set => ({
    ...initialState,
    setIsResizing: isResizing => set({ isResizing }),
    setPhotoAlbumSidebarWidth: photoAlbumSidebarWidth => set({ photoAlbumSidebarWidth }),
    setPhotoWrapperRef: photoWrapperRef => set({ photoWrapperRef }),
    setPhotoSize: photoSize => set({ photoSize }),
    setHeight: height => set({ height }),
    setSlidePanelWidth: slidePanelWidth => set({ slidePanelWidth }),
    setSlidePanelWidthRef: slidePanelWidthRef => set({ slidePanelWidthRef }),
  }))
);
