import { QueryContentWithTypeAndScreenId, getShouldOverwriteMapTab, queryContent } from '^/hooks';
import { AuthHeader, jsonContentHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { APIToScreen, AddScreenInStore, FinishPostScreen } from '^/store/duck/Screens';
import * as T from '^/types';
import {
  AttachmentType,
  Content,
  ContentType,
  ContentsQueryParam,
  MAP_TAB_CONTENTS,
  Screen,
  State,
} from '^/types';
import { http } from '^/utilities/api';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { formatWithOffset } from '^/utilities/date-format';
import { TEMP_SCREEN_ID } from '^/utilities/screen-util';
import { useDispatch, useSelector } from 'react-redux';
import { useContentDelete } from './useContentDelete';

interface PostScreenRequestBody {
  title?: string;
  appearAt?: string;
  contentIds?: Array<T.Content['id']>;
}

interface OverwriteCondition {
  contents: State['Contents']['contents'];
  screenId: Screen['id'];
  attachmentType: AttachmentType;
}

export function getContentOverwriteCondition({
  contents,
  screenId,
  attachmentType,
}: OverwriteCondition): boolean {
  if (screenId === TEMP_SCREEN_ID) {
    return false;
  }
  const getContentByTypeAndScreenId: QueryContentWithTypeAndScreenId = queryContent(
    contents,
    ContentsQueryParam.TYPE_AND_SCREENID
  ) as QueryContentWithTypeAndScreenId;

  const [hasDSM, hasTwoDOrtho, hasPointCloud, hasThreeDOrtho, hasThreeDMesh]: boolean[] = [
    ContentType.DSM,
    ContentType.MAP,
    ContentType.POINTCLOUD,
    ContentType.THREE_D_ORTHO,
    ContentType.THREE_D_MESH,
  ].map(c => Boolean(getContentByTypeAndScreenId(c, screenId, { processingOrFailed: true })));

  return getShouldOverwriteMapTab(attachmentType, {
    hasDSM,
    hasTwoDOrtho,
    hasPointCloud,
    hasThreeDOrtho,
    hasThreeDMesh,
  });
}

export const OverwritingContentTypes: {
  [K in
    | AttachmentType.SOURCE
    | AttachmentType.ORTHO
    | AttachmentType.POINTCLOUD
    | AttachmentType.DSM]: ContentType[];
} = {
  [AttachmentType.DSM]: [ContentType.DSM],
  [AttachmentType.ORTHO]: [ContentType.MAP],
  [AttachmentType.POINTCLOUD]: [ContentType.POINTCLOUD],
  [AttachmentType.SOURCE]: MAP_TAB_CONTENTS,
};

export interface CreateScreenParams {
  screen: Screen;
  attachmentType:
    | AttachmentType.DSM
    | AttachmentType.ORTHO
    | AttachmentType.POINTCLOUD
    | AttachmentType.SOURCE;
}

export function useScreen() {
  const dispatch = useDispatch();
  const { deleteContent } = useContentDelete();
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const timezoneOffset = useSelector((s: T.State) => s.Pages.Common.timezoneOffset);
  const byId = useContentsStore(s => s.contents.byId);
  const allIds = useContentsStore(s => s.contents.allIds);

  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);

  const headers = {
    ...authHeader,
    ...jsonContentHeader,
  };

  async function createScreen({ screen, attachmentType }: CreateScreenParams) {
    if (projectId === undefined || authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    const isNewScreen: boolean = screen.id === TEMP_SCREEN_ID;

    if (isNewScreen) {
      const URL: string = makeV2APIURL('projects', projectId, 'screens');

      const body: PostScreenRequestBody = {
        title: screen.title,
        contentIds: screen.contentIds,
        appearAt: screen.appearAt
          ? formatWithOffset(timezoneOffset, screen.appearAt, 'yyyy-MM-dd')
          : undefined,
      };

      const response = await http.post(URL, body, { headers });

      const newScreen = APIToScreen(response.data.data);

      dispatch(AddScreenInStore({ screen: newScreen }));
      dispatch(FinishPostScreen({}));

      return newScreen;
    } else {
      const mapContentsThatNeedOverwriting: Content[] = allIds
        .map(id => byId[id])
        .filter(
          ({ screenId, type }) =>
            screenId === screen.id && OverwritingContentTypes[attachmentType].includes(type)
        );

      const toBeDeletedContentsLength: number = mapContentsThatNeedOverwriting.length;

      if (toBeDeletedContentsLength <= 0) {
        return screen;
      }

      const deletePromises = mapContentsThatNeedOverwriting.map(async ({ id }) =>
        deleteContent(id)
      );

      const results = await Promise.allSettled(deletePromises);

      const isSuccess = results.every(result => result.status === 'fulfilled');

      if (!isSuccess) {
        alert('One or more contents failed to overwrite.');
        return null;
      }

      return screen;
    }
  }

  return { createScreen };
}
