import { PlayIcon } from '^/assets/icons/photo/video-icons';
import React from 'react';
import { ThumbNailWrapper } from '../PhotoList/style';
import { formatDayMonthYear, isVideo } from '../PhotoList/util';
import {
  TimelineBar,
  TimeLineText,
  PhotoThumbNail,
  DefaultImageElement,
  DefaultImageContainer,
  PhotoThumbNailWrapper,
  PhotoThumbNailInnerWrapper,
} from './style';
import Tippy from '@tippyjs/react';
import * as T from '^/types';
import { useIsInView } from '^/hooks/useIsInView';

interface ModifiedPhoto extends T.Photo {
  showDate: boolean;
  isSelected: boolean;
  isUniqueDate: boolean;
  highlightDate: boolean;
}

interface PhotoThumbNailComponentProps {
  index: number;
  lang: T.Language;
  photo: ModifiedPhoto;
  currentPhotoId?: number;
  photoThumbNailRefs: React.MutableRefObject<{
    [id: number]: HTMLDivElement;
  }>;
  handleThumbNailClick(photo: ModifiedPhoto): void;
  photoThumbNailBarRefs: React.MutableRefObject<{
    [id: number]: HTMLDivElement;
  }>;
}

interface TruncatedTimeLineTextProps {
  text: string;
  isUniqueDate: boolean;
  maxTextLength: number;
  highlightDate: boolean;
}

export const PhotoThumbNailComponent: React.FC<PhotoThumbNailComponentProps> = ({
  photo,
  index,
  lang,
  currentPhotoId,
  photoThumbNailRefs,
  handleThumbNailClick,
  photoThumbNailBarRefs,
}) => {
  const [thumbNailRef, isVisible] = useIsInView<HTMLDivElement>();

  const isSelected = photo.id === currentPhotoId;

  const TruncatedTimeLineText: React.FC<TruncatedTimeLineTextProps> = ({
    text,
    isUniqueDate,
    highlightDate,
    maxTextLength,
  }) => {
    const showTruncatedText = text.length > maxTextLength && isUniqueDate && !isSelected;
    const truncatedText = showTruncatedText ? `${text.slice(0, maxTextLength)}...` : text;

    return showTruncatedText ? (
      <Tippy offset={T.TIPPY_OFFSET} theme="angelsw" placement="top" arrow={false} content={text}>
        <TimeLineText highlightDate={highlightDate} isSelected={isSelected}>
          {truncatedText}
        </TimeLineText>
      </Tippy>
    ) : (
      <TimeLineText highlightDate={highlightDate} isSelected={isSelected}>
        {truncatedText}
      </TimeLineText>
    );
  };

  return (
    <>
      {photo.takenAt && photo.showDate ? (
        <TimelineBar
          isSelected={isSelected}
          highlightDate={photo.highlightDate}
          ref={ele => {
            if (ele) {
              photoThumbNailBarRefs.current[index] = ele;
            }
          }}
        >
          <TruncatedTimeLineText
            maxTextLength={10}
            isUniqueDate={photo.isUniqueDate}
            highlightDate={photo.highlightDate}
            text={formatDayMonthYear(photo.takenAt, lang)}
          />
        </TimelineBar>
      ) : (
        <></>
      )}
      <PhotoThumbNailWrapper
        isSelected={isSelected}
        id={`thumbnail_${photo.id}`}
        onClick={() => handleThumbNailClick(photo)}
        ref={ele => {
          if (ele) {
            photoThumbNailRefs.current[photo.id] = ele;
          }
        }}
      >
        <PhotoThumbNailInnerWrapper ref={thumbNailRef}>
          {isVideo(photo.fullUrl) && photo.id !== currentPhotoId ? (
            <ThumbNailWrapper>
              <PlayIcon width={21} height={30} />
            </ThumbNailWrapper>
          ) : null}
          {photo.status === T.PhotoProcessStatus.PENDING ||
          photo.status === T.PhotoProcessStatus.PROCESSING ? (
            <>
              {isVisible && (
                <DefaultImageContainer>
                  <DefaultImageElement scale={1} />
                </DefaultImageContainer>
              )}
            </>
          ) : (
            <>{isVisible && <PhotoThumbNail src={photo.boxThumbUrl} isSelected={isSelected} />}</>
          )}
        </PhotoThumbNailInnerWrapper>
      </PhotoThumbNailWrapper>
    </>
  );
};
