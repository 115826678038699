import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';

import ifcRespositionPNG from '^/assets/icons/ifc-icon/resposition_model.png';
import { RepositionIFCIcon } from '^/assets/icons/ifc-icon';
import {
  IFCTooltipContainer,
  IfcTopHeader,
  IfcTooltipTextWrapper,
  IfcTooltipHeaderText,
  IfcTooltipDescriptionText,
} from '../IFCLocationPreview/style';
import { ZoomWrapper } from '^/components/molecules/MapZoom';
import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import { MapCenterSvgWrapper, TooltipCustomStyle } from '../MapController';
import { UseL10n, useL10n, useProjectCoordinateSystem } from '^/hooks';
import Text from './text';
import * as T from '^/types';

import { moveCameraToViewObject } from '^/components/three/utils';
import { useThreeStore } from '^/components/three/ThreeStore';
import { IFCObject } from '^/components/three/ThreeObjects/Model';
import { PatchContent } from '^/store/duck/Contents';
import proj4 from 'proj4';
import { getEPSGfromProjectionLabel } from '^/utilities/coordinate-util';
import { useContentsStore } from '^/store/zustand/content/contentStore';

export const TooltipContentStyle: WrapperHoverableProps['customStyle'] = {
  ...TooltipCustomStyle,
  tooltipTextContentStyle: {
    position: 'absolute',
    transform: 'translate(-85%, 0%)',
  },
};

const IFCRepositionComponent = () => {
  const [isActive, setIsActive] = useState(false);
  // const [respositionIFC, setRepositionIFC] = useState<boolean>(false);
  const { viewer, tileset, runtime } = useThreeStore(s => ({
    viewer: s.viewer,
    tileset: s.tileset,
    runtime: s.runtime,
  }));

  const dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();
  const editingContentId: T.ContentsPageState['editingContentId'] = useSelector(
    (s: T.State) => s.Pages.Contents.editingContentId
  );
  const byId = useContentsStore(s => s.contents.byId);
  const content = byId[editingContentId ?? NaN];

  const collisionDetection = (modelMesh: IFCObject) => {
    if (!tileset || !viewer) {
      return;
    }
    const boundingBox = new THREE.Box3().setFromObject(tileset);
    // tileset.geometry.computeBoundingBox();
    const center = boundingBox.getCenter(new THREE.Vector3());
    modelMesh.position.set(center.x, center.y, center.z);

    const bimMeta = modelMesh?.bimMeta;
    const geo = viewer.runtime!.getLatLongHeightFromPosition(modelMesh.position);
    const cartesianPos = getCartesianFromLatLong(geo.long, geo.lat, geo.height);
    const geoPos = getLatLongFromCartesian(cartesianPos.x, cartesianPos.y, cartesianPos.z);
    bimMeta.position = new THREE.Vector3(geoPos.long, geoPos.lat, geoPos.height);
    dispatch(
      PatchContent({
        content: {
          id: modelMesh.contentId,
          info: { bimMeta },
        },
      })
    );
    moveCameraToViewObject((modelMesh as any).contentId);
  };
  const getCartesianFromLatLong = (long: number, lat: number, z: number) => {
    const [x, y] = proj4(getEPSGfromProjectionLabel(projectProjection), [long, lat]);
    return { x, y, z };
  };
  const getLatLongFromCartesian = (x: number, y: number, z: number) => {
    const [long, lat, height] = proj4(
      getEPSGfromProjectionLabel(projectProjection),
      'EPSG:4326'
    ).forward([x, y, z]);
    return { lat, long, height };
  };
  const handleRepositionModel: () => void = () => {
    if (!runtime || !editingContentId || !tileset) {
      return;
    }
    const ifcObject = viewer?.entities.ifcModelEntity?.getObjectByProperty(
      'contentId',
      editingContentId
    ) as IFCObject;

    if (ifcObject) {
      collisionDetection(ifcObject);
    }
  };

  useEffect(() => {
    if (editingContentId && content?.config?.selectedAt) {
      const ifcObject = viewer?.entities.ifcModelEntity?.getObjectByProperty(
        'contentId',
        editingContentId
      ) as IFCObject | undefined;

      if (ifcObject) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    } else {
      setIsActive(false);
    }
  }, [editingContentId, content]);

  const IFCToolTip: ReactNode = (
    <IFCTooltipContainer>
      <IfcTopHeader>
        <img src={ifcRespositionPNG} alt="" />
      </IfcTopHeader>
      <IfcTooltipTextWrapper>
        <IfcTooltipHeaderText>{l10n(Text.repositionHeaderText)} </IfcTooltipHeaderText>
        <IfcTooltipDescriptionText>
          {l10n(Text.respositionDescriptionText)}
        </IfcTooltipDescriptionText>
      </IfcTooltipTextWrapper>
    </IFCTooltipContainer>
  );

  return (
    <>
      {isActive ? (
        <ZoomWrapper onClick={handleRepositionModel}>
          <WrapperHoverable title="" content={IFCToolTip} customStyle={TooltipContentStyle}>
            <MapCenterSvgWrapper>
              <RepositionIFCIcon />
            </MapCenterSvgWrapper>
          </WrapperHoverable>
        </ZoomWrapper>
      ) : null}
    </>
  );
};

export default IFCRepositionComponent;
