import {
  DoubleSide,
  Group,
  LoadingManager,
  Material,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  REVISION,
  RingGeometry,
  Color,
  BoxGeometry,
} from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { disposeHierarchy } from '../../Lib/Helper/DisposeHelper';
import { autobind } from 'core-decorators';
import { EditorIFCController } from '../../Lib/Controllers/Model';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { LabelObject } from '../Label';
import { DisposeHelper } from '../../Lib/Helper';
import {
  ESS_INNER_CIRCLE_SIZE_THRESHOLD,
  ESS_INNER_CIRCLE_SIZE_SMALL,
  ESS_INNER_CIRCLE_SIZE_MEDIUM,
} from '^/constants/cesium';
import * as T from '^/types';
import { calculateDistance } from '^/utilities/imperial-unit';
import {
  getImperialMeasurementUnitFromGeometryType,
  getMeasurementUnitFromGeometryType,
} from '^/components/ol/contentTypeSwitch';
import { renderToString } from 'react-dom/server';
import { ESSIcon } from './Contents/ESSIcon';
import { LabelUtils } from '../../Lib/Utils';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

const contentDimension = (value: string | number) => `<div class="__label-three"> ${value} </div>`;

const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
const MANAGER = new LoadingManager();
const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(
  `${THREE_PATH}/examples/jsm/libs/draco/gltf/`
);
const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(
  `${THREE_PATH}/examples/jsm/libs/basis/`
);
const GLTF_LOADER = new GLTFLoader();
GLTF_LOADER.setDRACOLoader(DRACO_LOADER);
GLTF_LOADER.setMeshoptDecoder(MeshoptDecoder);

const HIGHLIGHT_COLOR = new Color().lerpColors(new Color(0xffffff), new Color(0x0000ff), 0.1);
const SELECTED_COLOR = new Color().lerpColors(new Color(0xffffff), new Color(0x359caf), 0.7);
const MATERIAL = new MeshBasicMaterial({ color: 0x0000ff });
const BOX = new BoxGeometry(0.001, 0.001, 0.001);
interface Metadata {
  thumbnailUrl: string;
  groupId: number | string | undefined;
}
class ESSObject extends Object3D {
  private readonly viewer: Viewer;
  public readonly contentId;
  public isLoaded: boolean = false;
  public isLoading: boolean = false;
  public model: Object3D | undefined;
  public editor: EditorIFCController | undefined;
  public miscMeta: any;
  public readonly url: string;
  private _isTemporary: boolean = false;
  public label: LabelObject | null = null;
  public workRadius: Group;
  public labelWorkRadius: LabelObject[] = [];
  public unitType: T.ValidUnitType = T.UnitType.METRIC;
  public radiusUnit: string;
  public metadata: Metadata;
  public depthTest: boolean = false;
  public instanceId: number | undefined;
  public dummyBox: Mesh;
  public constructor(contentId: string | number, url: string, viewer: Viewer, metadata: Metadata) {
    super();
    this.contentId = contentId;
    this.viewer = viewer;
    this.url = url;
    this.metadata = metadata;
    this.radiusUnit =
      this.unitType === T.UnitType.IMPERIAL
        ? getImperialMeasurementUnitFromGeometryType({
            geometryType: T.ContentType.ESS_MODEL_CUSTOM,
          })
        : getMeasurementUnitFromGeometryType({ geometryType: T.ContentType.ESS_MODEL_CUSTOM });

    this.workRadius = new Group();
    this.workRadius.rotation.x = Math.PI / 2;
    this.add(this.workRadius);
    this.dummyBox = new Mesh(BOX, MATERIAL);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.dummyBox.isMesh = false;
    // this.box.visible = false;
    this.add(this.dummyBox);
  }

  public get isTemporary(): boolean {
    return this._isTemporary;
  }
  public set isTemporary(value: boolean) {
    this._isTemporary = value;
  }

  /**
   * @public
   * @description create label on demand
   */
  public createLabelOnDemand() {
    this.label = LabelUtils.createLabel(
      this.viewer,
      {
        center: true,
        sprite: true,
        transform: false,
        zIndexRange: [0, 10],
        distanceFactor: 300,
        scaleRange: [0.9, 1.5],
      },
      renderToString(ESSIcon({ url: this.metadata.thumbnailUrl }))
    );
    this.label.labelVisible = false;
    this.add(this.label);
  }

  /**
   * @public
   * @description dispose label on demand
   */
  public deleteLabelOnDemand() {
    if (this.label) {
      this.remove(this.label);
      this.label.dispose();
      this.label = null;
    }
  }

  /**
   * @public
   * @description set depthTest for material
   */
  public setDepthTest(depthTest: boolean) {
    this.depthTest = depthTest;
    if (this.workRadius) {
      this.workRadius.traverse(child => {
        if (child instanceof Mesh) {
          child.renderOrder = 2;
          (child.material as Material).depthTest = this.depthTest;
        }
      });
    }
  }
  /**
   * @public
   * @description Dispose all
   * @returns { void}
   */
  @autobind
  public async loadModel(): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        GLTF_LOADER.setKTX2Loader(KTX2_LOADER.detectSupport(this.viewer.renderer));
        const instanceId =
          await this.viewer.entities.essModelEntity!.gltfInstancePool.generateInstance(this.url);
        this.instanceId = instanceId;
        this.viewer.entities.essModelEntity?.gltfInstancePool.setInstanceMatriuxAt(
          this.url,
          this.instanceId,
          this
        );
        // this.add(this.model);
        if (this.isTemporary) {
          this.isLoaded = false;
        } else {
          this.isLoaded = true;
        }
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  }

  /**
   * @public
   * @description set highlight model
   * @returns {void}
   */
  @autobind
  public setHighlight(): void {
    if (this.instanceId !== undefined) {
      this.viewer.entities.essModelEntity?.gltfInstancePool.setInstanceColorAt(
        this.url,
        this.instanceId,
        HIGHLIGHT_COLOR
      );
    }
  }
  /**
   * @public
   * @description set un highlight model
   * @returns {void}
   */
  @autobind
  public setUnHighlight(): void {
    if (this.instanceId !== undefined) {
      this.viewer.entities.essModelEntity?.gltfInstancePool.setInstanceColorAt(
        this.url,
        this.instanceId,
        new Color(0xffffff)
      );
    }
  }
  /**
   * @public
   * @description set selected model
   * @returns {void}
   */
  @autobind
  public setSelected(): void {
    if (this.instanceId !== undefined) {
      this.viewer.entities.essModelEntity?.gltfInstancePool.setInstanceColorAt(
        this.url,
        this.instanceId,
        SELECTED_COLOR
      );
    }
  }
  /**
   * @public
   * @description Create work radius
   */
  @autobind
  public createWorkRadius(workRadius: number): void {
    if (this.workRadius.children.length > 0) {
      DisposeHelper.disposeHierarchy(this.workRadius);
      const children: Object3D[] = [];
      this.workRadius.children.forEach(child => {
        children.push(child);
      });
      this.workRadius.remove(...children);
    }
    const innerCircleSizeRadius = (() => {
      if (workRadius <= 0) {
        return 0;
      }

      return Math.max(
        workRadius < ESS_INNER_CIRCLE_SIZE_THRESHOLD
          ? ESS_INNER_CIRCLE_SIZE_SMALL
          : ESS_INNER_CIRCLE_SIZE_MEDIUM,

        ESS_INNER_CIRCLE_SIZE_SMALL
      );
    })();

    const maxInnerCircleCount = Math.ceil(workRadius / innerCircleSizeRadius) + 1;
    let innerCircleCount = maxInnerCircleCount;

    while (--innerCircleCount) {
      const radius: number = Math.min(workRadius, innerCircleCount * innerCircleSizeRadius);
      const geometry = new RingGeometry(
        radius,
        innerCircleCount === maxInnerCircleCount - 1 ? radius + 0.2 : radius,
        100
      );
      const material = new MeshBasicMaterial({
        color: 0x00d1e5,
        side: DoubleSide,
        wireframe: !(innerCircleCount === maxInnerCircleCount - 1),
      });
      const circle = new Mesh(geometry, material);
      this.workRadius.add(circle);

      const labelRadius = LabelUtils.createLabel(
        this.viewer,
        { center: true, zIndexRange: [0, 10] },
        contentDimension(
          `${calculateDistance(radius, this.unitType).toFixed(0)} ${this.radiusUnit}`
        )
      );
      labelRadius.position.set(radius, 0, 0);
      this.workRadius.add(labelRadius);
      this.labelWorkRadius.push(labelRadius);
    }
  }
  /**
   * @public
   * @description set visivibe
   */
  @autobind
  public setVisible(visible: boolean): void {
    this.visible = visible;
    if (this.label) {
      this.label.labelVisible = visible;
    }
  }
  /**
   * @public
   * @description Dispose all
   * @returns { void}
   */
  @autobind
  public dispose(): void {
    this.labelWorkRadius = [];
    disposeHierarchy(this);
  }
  /**
   * @public
   * @description This function will auto-call when the scene render
   * @param {  boolean | undefined} force
   * @returns { void}
   */
  @autobind
  public updateMatrixWorld(force: boolean | undefined) {
    super.updateMatrixWorld(force);
    if (this.label) {
      if (this.viewer.camera.position.distanceTo(this.position) > 400) {
        // this.model.visible = false;
        this.label.labelVisible = true;
      } else {
        // this.model.visible = true;
        this.label.labelVisible = false;
      }
    }
    if (this.label && this.label.labelVisible) {
      if (this.viewer.frustum.containsPoint(this.position)) {
        this.label.labelVisible = true;
      } else {
        this.label.labelVisible = false;
      }
    }
    if (this.workRadius) {
      const visible = this.workRadius.visible;
      this.labelWorkRadius.forEach(child => {
        child.labelVisible = visible;
      });
    }
  }
}

export { ESSObject };
