import React, { FC, memo, useEffect, useState, useCallback, useMemo, ReactNode } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import Tippy from '@tippyjs/react';

import Text from '../PhotoList/text';
import BackArrowSvg from '^/assets/icons/photo/back-arrow-black.svg';
import { UseL10n, useL10n, useRole } from '^/hooks';
import { SetPhotoDateType, SetSelectedMedia } from '^/store/duck/Photos';
import * as T from '^/types';
import { SortedPhotos, formatDayMonthYear, formatMonthYear } from '../PhotoList/util';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import {
  PhotoWrapper,
  PhotoMonthHeader,
  PhotoListRoot,
  PreviousIconWrapper,
  PreviousIcon,
  FlexBoxWrapper,
  LoaderWrapper,
  SvgWrapper,
  SelectWrapper,
  FlexBox,
  SelectedTextElement,
  TopIconsWrapper,
} from '../PhotoList/style';

import CalendarEditSvg from '^/assets/icons/photo/calendar-edit.svg';
import DeleteSvg from '^/assets/icons/photo/delete-button.svg';
import DownloadSvg from '^/assets/icons/photo/download.svg';
import CloseSVG from '^/components/molecules/Notification/close-thinner.svg';
import styled from 'styled-components';
import TimeLineScrollBar from '../TimelineScrollBar';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import PhotoInspection from '../PhotoInspection';
import palette from '^/constants/palette';
import { useThreeStore } from '^/components/three/ThreeStore';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import PhotoUploadButton from '^/components/atoms/PhotoUploadButton';
import { isAllowToUpload } from '^/utilities/role-permission-check';

import { useGetPhotoData } from './useGetPhotoData';
import useSelectedActions from '^/hooks/timelapse/useSelectedActions';
import { L10nDictionary } from '^/utilities/l10n';

export interface SvgButtonProps {
  text: string;
  icon: ReactNode;
  onClick(): void;
  show: boolean;
}

export const PhotoInspectionHeader = styled.div({
  fontSize: '22.5px',
  lineHeight: '35px',
  fontWeight: 600,
  paddingBottom: '26px',
  cursor: 'pointer',
  color: palette.textBlack.toString(),
});
PhotoInspectionHeader.displayName = 'PhotoInspectionHeader';

const PhotoTimeLineWrapper = styled.div({
  display: 'flex',
  flexGrow: 1,
  paddingTop: '50px',
});

const PhotosRoot = styled.div({
  flexGrow: 1,
  overflow: 'auto',
  paddingRight: '35px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});

export const PhotoUploadButtonWrapper = styled.div({
  paddingBottom: '26px',
});

export const PhotoHeader = styled.div({
  fontSize: '22.5px',
  lineHeight: '35px',
  fontWeight: '600',
  paddingBottom: '26px',
  color: palette.textBlack.toString(),
});

export const PhotoInspectionHeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const HeaderWrapper = styled.div({
  width: '100%',
});

const photoTitle: Record<T.PhotoAlbumType, L10nDictionary> = {
  [T.PhotoAlbumType.INSPECTION]: Text.header.droneMode,
  [T.PhotoAlbumType.THREE_SIXTY]: Text.header.threeSixtyMode,
  [T.PhotoAlbumType.THREE_SIXTY_SOURCE]: Text.header.threeSixtySourceMode,
  [T.PhotoAlbumType.THREE_SIXTY_STITCHED]: Text.header.threeSixtyStitchedMode,
  [T.PhotoAlbumType.THREE_SIXTY_VIDEO]: Text.header.threeSixtyVideoMode,
  [T.PhotoAlbumType.THREE_SIXTY_VSLAM]: Text.header.threeSixtyVslamMode,
  [T.PhotoAlbumType.SOURCE]: Text.header.sourcePhoto,
  [T.PhotoAlbumType.NORMAL]: Text.header.sourcePhoto,
  [T.PhotoAlbumType.INTEGRATION]: Text.header.sourcePhoto,
  [T.PhotoAlbumType.DRONE]: Text.header.sourcePhoto,
  [T.PhotoAlbumType.VIEWPOINT]: Text.header.sourcePhoto,
};
const photoType: Record<T.PhotoAlbumType, string> = {
  [T.PhotoAlbumType.INSPECTION]: 'drone',
  [T.PhotoAlbumType.THREE_SIXTY]: 'three_sixty',
  [T.PhotoAlbumType.THREE_SIXTY_SOURCE]: 'three_sixty_source',
  [T.PhotoAlbumType.THREE_SIXTY_STITCHED]: 'three_sixty_stitched',
  [T.PhotoAlbumType.THREE_SIXTY_VIDEO]: 'three_sixty_video',
  [T.PhotoAlbumType.THREE_SIXTY_VSLAM]: 'three_sixty_vslam',
  [T.PhotoAlbumType.SOURCE]: 'drone',
  [T.PhotoAlbumType.NORMAL]: 'drone',
  [T.PhotoAlbumType.INTEGRATION]: 'drone',
  [T.PhotoAlbumType.DRONE]: 'drone',
  [T.PhotoAlbumType.VIEWPOINT]: 'viewpoint',
};

const PhotoInspectionList: FC = () => {
  const dispatch: Dispatch = useDispatch();
  const [l10n, lang]: UseL10n = useL10n();

  const [photoWrapperRef, setPhotoWrapperRef] = useState<HTMLDivElement | null>(null);
  const [photoSize, setPhotoSize] = useState<'sm' | 'md'>('md');
  const [height, setHeight] = useState(0);
  const [slidePanelWidthRef, setSlidePanelWidthRef] = useState<HTMLDivElement | null>(null);
  const [slidePanelWidth, setSlidePanelWidth] = useState(0);
  const {
    setIsInspectionMode,
    inspectionDate,
    photoMode,
    sourcePhotos,
    setSourcePhotos,
    sourceId,
  } = useThreePhotoAlbumStore(s => ({
    setIsInspectionMode: s.setIsInspectionMode,
    inspectionDate: s.inspectionDate,
    photoMode: s.photoMode,
    sourcePhotos: s.sourcePhotos,
    setSourcePhotos: s.setSourcePhotos,
    sourceId: s.sourceId,
  }));
  const viewer = useThreeStore(s => s.viewer);
  const { photos, bulkDeletePhotoStatus } = useSelector((s: T.State) => s.Photos);
  const [photoStatus, setPhotoStatus] = useState<T.APIStatus>(T.APIStatus.IDLE);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const isPhotoModeThreeSixty =
    photoMode === T.PhotoAlbumType.THREE_SIXTY ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_SOURCE ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_STITCHED ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_VSLAM ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_VIDEO;
  const isPhotoModeInspectionOrThreeSixty =
    photoMode === T.PhotoAlbumType.INSPECTION || isPhotoModeThreeSixty;

  const { setScreenId, setTakenAt } = useGetPhotoData({
    projectId,
    setPhotoStatus,
    setSourcePhotos,
  });

  useEffect(() => {
    /**
     * TODO: @ebraj-angelswing
     * Need to revisit again in future to make it more general as both the inspection and 360 images is getting handled by this component, but the name of component points to inspection only...
     */
    if (isPhotoModeInspectionOrThreeSixty) {
      setPhotoStatus(T.APIStatus.PROGRESS);

      const filteredPhotos = photos.filter(
        photo =>
          photo.takenAt &&
          photo.photoType === photoType[photoMode] &&
          formatDayMonthYear(photo.takenAt, lang) === inspectionDate
      );

      if (filteredPhotos.length !== 0) {
        setSourcePhotos(filteredPhotos);
        setPhotoStatus(T.APIStatus.SUCCESS);
      } else {
        setIsInspectionMode(false);
        setSourcePhotos([]);
        dispatch(SetPhotoDateType({ dateType: T.PhotoDateType.SORTED_PHOTO }));
        dispatch(SetSelectedMedia({ selectedMedia: [] }));
      }
    } else {
      const photoFilterd = photos.filter(
        photo =>
          photo.takenAt &&
          photo.photoType === 'source' &&
          photo.screenId === sourceId &&
          formatDayMonthYear(photo.takenAt, lang) === inspectionDate
      );
      if (photoFilterd.length !== 0 && photoFilterd[0].takenAt) {
        setScreenId(photoFilterd[0].screenId);
        setTakenAt(photoFilterd[0].takenAt);
      } else {
        setIsInspectionMode(false);
        setSourcePhotos([]);
        dispatch(SetPhotoDateType({ dateType: T.PhotoDateType.SORTED_PHOTO }));
        dispatch(SetSelectedMedia({ selectedMedia: [] }));
      }
    }
  }, [photos]);

  const { dateType, selectedMedia, isDownloading } = useSelector((s: T.State) => s.Photos);

  const role: T.PermissionRole = useRole();

  const setHeightOfPhotoList = () => {
    if (photoWrapperRef) {
      const newHeight = window.innerHeight - photoWrapperRef.getBoundingClientRect().y - 20;
      photoWrapperRef.style.height = `${newHeight}px`;
      setHeight(newHeight);
    }
  };

  const setWidthOfSlidePanel = () => {
    if (slidePanelWidthRef) {
      setSlidePanelWidth(slidePanelWidthRef.clientWidth);
    }
  };

  useEffect(() => {
    setHeightOfPhotoList();
    setWidthOfSlidePanel();
  }, [photoWrapperRef, dateType, Boolean(selectedMedia.length), slidePanelWidthRef]);

  useEffect(() => {
    const savedScrollTop = sessionStorage.getItem('inspectionScrollPosition');
    if (savedScrollTop && photoWrapperRef) {
      photoWrapperRef.scrollTop = Number(savedScrollTop);
    }
    const handleScroll = () => {
      if (photoWrapperRef) {
        sessionStorage.setItem('inspectionScrollPosition', photoWrapperRef.scrollTop.toString());
      }
    };
    photoWrapperRef?.addEventListener('scroll', handleScroll);
    return () => photoWrapperRef?.removeEventListener('scroll', handleScroll);
  }, [photoWrapperRef]);

  useEffect(() => {
    const onPhotoSlide = () => {
      if (photoWrapperRef) {
        setHeightOfPhotoList();
        if (photoWrapperRef.clientWidth < 650) {
          setPhotoSize('sm');
        } else {
          setPhotoSize('md');
        }
      }

      if (slidePanelWidthRef) {
        const width = slidePanelWidthRef.clientWidth;

        setSlidePanelWidth(width);
      }
    };
    window.addEventListener('photo-resize', onPhotoSlide);
    window.addEventListener('resize', onPhotoSlide);

    return () => {
      window.removeEventListener('photo-resize', onPhotoSlide);
      window.addEventListener('resize', onPhotoSlide);
    };
  }, [photoWrapperRef, slidePanelWidthRef]);

  const previousButtonClick = useCallback(() => {
    dispatch(SetPhotoDateType({ dateType: T.PhotoDateType.SORTED_PHOTO }));
    dispatch(SetSelectedMedia({ selectedMedia: [] }));
  }, []);

  const photoListsByDate = useMemo(() => {
    if (photoStatus !== T.APIStatus.SUCCESS) {
      return null;
    }

    const photosArray: SortedPhotos[] = [];
    if (sourcePhotos.length !== 0) {
      photosArray.push({
        title: formatMonthYear(sourcePhotos[0].takenAt!, lang),
        count: sourcePhotos.length,
        timeline: [
          {
            dayTimeline: {
              [formatDayMonthYear(sourcePhotos[0].takenAt!, lang)]: sourcePhotos.map(photo => ({
                ...photo,
              })),
            },
            droneUploads: [],
          },
        ],
      });
    }

    const photoListItems = (photosList: SortedPhotos['timeline']): any =>
      photosList.map(photosListItem =>
        Object.keys(photosListItem.dayTimeline).map(date => (
          <PhotoInspection
            key={date}
            date={date}
            photoList={photosListItem.dayTimeline}
            droneUploads={photosListItem.droneUploads}
          />
        ))
      );

    return (
      <React.Fragment>
        <SelectContent
          slidePanelWidth={slidePanelWidth}
          selectedMedia={selectedMedia}
          isDownloading={isDownloading}
          bulkDeletePhotoStatus={bulkDeletePhotoStatus}
          photoMode={photoMode}
        />
        <PhotoTimeLineWrapper>
          <PhotosRoot
            ref={setPhotoWrapperRef}
            style={{
              height: `calc(100vh - ${photoWrapperRef?.getBoundingClientRect()?.y || 0 - 30}px`,
            }}
          >
            {photosArray.map((photobyDate, index) => (
              <PhotoWrapper key={index}>
                <PhotoMonthHeader>
                  {photobyDate.timeline.length ? photobyDate.title : ''}
                </PhotoMonthHeader>
                {photobyDate.timeline.length ? photoListItems(photobyDate.timeline) : null}
              </PhotoWrapper>
            ))}
          </PhotosRoot>
          <TimeLineScrollBar
            photoWrapperRef={photoWrapperRef}
            photosPerRow={photoSize === 'sm' ? 1 : 3}
            height={height}
          />
        </PhotoTimeLineWrapper>
      </React.Fragment>
    );
  }, [height, selectedMedia, sourcePhotos, photoStatus]);

  const exitInspectionMode = () => {
    dispatch(SetSelectedMedia({ selectedMedia: [] }));
    void viewer?.controllers?.photoAlbumController?.exit();
    setIsInspectionMode(false);
  };

  const handleUploadPopupOpen = () => {
    if (!isAllowToUpload(role)) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));

      return;
    }

    dispatch(
      OpenContentPagePopup({
        popup: T.ContentPagePopupType.PHOTO_UPLOAD,
        photoType: 'drone',
        hideBackButton: true,
      })
    );
  };

  return (
    <PhotoListRoot ref={setSlidePanelWidthRef}>
      <FlexBoxWrapper style={{ padding: '0px 13px' }}>
        {(dateType === T.PhotoDateType.UNSORTED_PHOTO ||
          dateType === T.PhotoDateType.DRONE_UPLOADS ||
          dateType === T.PhotoDateType.TIMELAPSE) && (
          <PreviousIconWrapper onClick={previousButtonClick}>
            <PreviousIcon />
          </PreviousIconWrapper>
        )}
        <HeaderWrapper>
          <PhotoInspectionHeaderWrapper>
            <PhotoInspectionHeader onClick={exitInspectionMode}>
              <BackArrowSvg /> {photoMode && l10n(photoTitle[photoMode as T.PhotoAlbumType])}
            </PhotoInspectionHeader>
            {photos.length > 0 &&
              photoMode !== T.PhotoAlbumType.SOURCE &&
              !isPhotoModeThreeSixty && (
                <PhotoUploadButtonWrapper>
                  <PhotoUploadButton
                    photoSize={photoSize}
                    isIn3D={true}
                    onClick={handleUploadPopupOpen}
                  />
                </PhotoUploadButtonWrapper>
              )}
          </PhotoInspectionHeaderWrapper>
        </HeaderWrapper>
      </FlexBoxWrapper>

      {photoStatus === T.APIStatus.PROGRESS && (
        <LoaderWrapper>
          <LoadingIcon loadingDivCustomStyle={{ height: 32, width: 32 }} />
        </LoaderWrapper>
      )}
      {sourcePhotos.length !== 0 && photoStatus === T.APIStatus.SUCCESS && photoListsByDate}
    </PhotoListRoot>
  );
};

export default memo(PhotoInspectionList);

export const SvgButton = ({ text, icon, onClick, show }: SvgButtonProps): ReactNode =>
  show ? (
    <div onClick={onClick}>
      <Tippy offset={T.TIPPY_OFFSET} theme="angelsw" placement="top" arrow={false} content={text}>
        <SvgWrapper>{icon}</SvgWrapper>
      </Tippy>
    </div>
  ) : null;

interface SelectContentProps {
  slidePanelWidth?: number;
  selectedMedia: T.SelectedMedia[];
  isDownloading: boolean;
  bulkDeletePhotoStatus: T.APIStatus;
  photoMode?: string | null;
  children?: React.ReactNode;
}
export const SelectContent = ({
  slidePanelWidth,
  selectedMedia,
  isDownloading,
  bulkDeletePhotoStatus,
  photoMode,
  children,
}: SelectContentProps) => {
  const [l10n] = useL10n();
  const { handleDownload, handleDeletePhotos, handleTagPhotos, handleResetPhotoItems } =
    useSelectedActions();
  const isPhotoModeInspectionOrThreeSixty =
    photoMode === T.PhotoAlbumType.INSPECTION ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_SOURCE ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_STITCHED ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_VSLAM ||
    photoMode === T.PhotoAlbumType.THREE_SIXTY_VIDEO;

  return (
    <SelectWrapper slidePanelWidth={slidePanelWidth} isSelected={Boolean(selectedMedia.length)}>
      <FlexBox slidePanelWidth={slidePanelWidth}>
        {SvgButton({
          onClick: handleResetPhotoItems,
          text: l10n(Text.photo.deselectToolTip),
          icon: <CloseSVG />,
          show: true,
        })}
        <SelectedTextElement>
          {l10n(Text.photo.itemSelectedTooltip(selectedMedia.length))}
        </SelectedTextElement>
      </FlexBox>
      <TopIconsWrapper>
        {SvgButton({
          text: l10n(Text.photo.editDatesTooltip),
          icon: <CalendarEditSvg />,
          onClick: handleTagPhotos,
          show:
            photoMode === T.PhotoAlbumType.INSPECTION ||
            photoMode === T.TimelapseCollectionTypes.INSPECTION,
        })}
        {SvgButton({
          onClick: isDownloading ? () => {} : handleDownload,
          text: isDownloading
            ? l10n(Text.photo.DownloadProgressTooltip)
            : l10n(Text.photo.DownloadTooltip),
          icon: isDownloading ? <LoadingIcon isDownloading={true} /> : <DownloadSvg />,
          show: true,
        })}
        {SvgButton({
          text: l10n(Text.photo.deleteTooltip),
          icon:
            bulkDeletePhotoStatus !== T.APIStatus.IDLE ? (
              <LoadingIcon isDownloading={true} />
            ) : (
              <DeleteSvg />
            ),
          onClick: bulkDeletePhotoStatus !== T.APIStatus.IDLE ? () => {} : handleDeletePhotos,
          show: isPhotoModeInspectionOrThreeSixty,
        })}
        {children}
      </TopIconsWrapper>
    </SelectWrapper>
  );
};
