import React, { useRef, useState } from 'react';
import { FixedColumnsWrapper, ScrollableWrapper, Table, TableWrapper } from '../styles';
import { TableBody } from './TableBody';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import * as T from '^/types';
import TableHeader from './TableHeader';
import styled from 'styled-components';
import { useL10n } from '^/hooks';
import Text from '../text';
import { TableSkeleton } from './TableSkeleton';

import { exportTableToCSV, generateCSVData } from '../earthworkUtils';

import EmptySvg from '^/assets/icons/earthwork/Empty.svg';
import TitleWithDescription from '^/components/atoms/TitleWithDescription';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import VolumeBurndownChart from '../VolumeBurndownChart';
import dsPalette from '^/constants/ds-palette';
import { TitleSkeleton } from './TitleSkeleton';
import {
  EmptyText,
  EmptyZoneContent,
} from '../EarthworkSidebarContents/FinalEarthWorkSelectAttachment';

export const ExportCSVButton = styled.div({
  padding: '14px 20px',
  fontSize: '13px',
  backgroundColor: 'var(--color-theme-primary)',
  color: 'white',
  height: 'fit',
  borderRadius: '8px',
  cursor: 'pointer',
});

export const EmptyZoneContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90vh',
});

const SelectVolumeHeader = styled.div({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  marginBottom: '10px',
});

export const UploadEarthWorkWrapper = styled.div({
  padding: '0 25px 10px 20px',
  display: 'flex',
  flexDirection: 'column',
});

const LabelText = styled.div({
  fontSize: '12px',
  lineHeight: '17px',
  color: `${dsPalette.grey130.toString()}`,
});
const LoadersWrapper = styled.div({
  marginBottom: '10px',
});

const HeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'sticky',
  top: '0px',
  zIndex: '30',
  backgroundColor: '#ebebeb',
});
const EarthworkTitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

type Row = Array<string | number>;
export interface Measurement {
  [T.ContentType.MARKER]: Row[];
  [T.ContentType.LENGTH]: Row[];
  [T.ContentType.AREA]: Row[];
  [T.ContentType.VOLUME]: Row[];
}
interface Props {
  earthWOrkId: number | string;
}

export const EarthworkTable: React.FC<Props> = ({ earthWOrkId }) => {
  const [l10n] = useL10n();
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [showBurnDown, setShowBurnDown] = useState(false);
  const { earthworkFetchStatus, earthworkAnalysisById, earthworkAnalysisFetchStatus } =
    useEarthWorkStore();

  const earthworkAnalysis = earthworkAnalysisById[earthWOrkId]?.attributes;

  const emptyZone = (
    <EmptyZoneContentWrapper>
      <EmptyZoneContent>
        <EmptySvg />
        <EmptyText>{l10n(Text.noAnalysisData)}</EmptyText>
      </EmptyZoneContent>
    </EmptyZoneContentWrapper>
  );

  const isLoading =
    earthworkAnalysisFetchStatus === T.APIStatus.PROGRESS ||
    earthworkFetchStatus === T.APIStatus.PROGRESS;

  const dataTable = isLoading ? (
    <>
      <LoadersWrapper>
        <TitleSkeleton />
      </LoadersWrapper>
      <TableSkeleton />;
    </>
  ) : earthworkAnalysis &&
    earthworkAnalysis.moments.length > 0 &&
    earthworkAnalysis.zones.length > 0 ? (
    <div>
      <FixedColumnsWrapper>
        <Table>
          <TableHeader moments={earthworkAnalysis.moments} isFixed={true} />
          <TableBody zones={earthworkAnalysis.zones} isFixed={true} />
        </Table>
      </FixedColumnsWrapper>
      <ScrollableWrapper>
        <Table>
          <TableHeader moments={earthworkAnalysis.moments} isScrollable={true} />
          <TableBody zones={earthworkAnalysis.zones} isScrollable={true} />
        </Table>
      </ScrollableWrapper>
    </div>
  ) : (
    emptyZone
  );

  const handleExportCSV = () => {
    const { header, body } = generateCSVData(earthworkAnalysis);
    exportTableToCSV(header, body, 'earthwork_Table');
  };

  const exportCSV = (
    <ExportCSVButton
      onClick={handleExportCSV}
      data-ddm-track-action={T.TrackActions.EARTH_WORK}
      data-ddm-track-label={`${T.TrackLabels.BTN_EXPORT_EARTHWORK}`}
    >
      {l10n(Text.exportCSV)}
    </ExportCSVButton>
  );

  return (
    <>
      {!isLoading && (
        <>
          <HeaderWrapper>
            <EarthworkTitleWrapper>
              <TitleWithDescription
                description=""
                title={earthworkAnalysis?.name}
                subDescription={earthworkAnalysis?.description}
              />
              <SelectVolumeHeader>
                <LabelText>{l10n(Text.showBurndown)}</LabelText>
                <ToggleSlider
                  enabled={showBurnDown}
                  trackAction={T.TrackActions.EARTH_WORK}
                  onClick={() => setShowBurnDown(prev => !prev)}
                  trackLabel={T.TrackLabels.BTN_SHOW_BURNDOWN_CHART}
                />
              </SelectVolumeHeader>
            </EarthworkTitleWrapper>
            {exportCSV}
          </HeaderWrapper>
          {showBurnDown ? <VolumeBurndownChart analysis={earthworkAnalysis} /> : <></>}
        </>
      )}
      <TableWrapper ref={tableRef}>{dataTable}</TableWrapper>
    </>
  );
};
