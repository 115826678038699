// import InvalidPageDisplay from '^/components/atoms/InvalidPageDisplay-Old';
import InvalidPageDisplay from '^/components/atoms/InvalidPageDisplay';
import LoadingScreen from '^/components/molecules/LoadingScreenNew';
import { defaultFeatures } from '^/constants/defaultContent';
import { useContents } from '^/hooks/api/content/useContents';
import { useESSContents } from '^/hooks/useESSContents';
import { useESSModels } from '^/hooks/useESSModels';
import useIssueContents from '^/hooks/useIssueContents';
import { makeAuthHeader } from '^/store/duck/API';
import { ChangeFirstVisitStatus } from '^/store/duck/Pages';
import { contentPageEnterActions, contentPageExitActions } from '^/store/duck/router';
import { usePostDroneStationAuthDetailsMutation } from '^/store/react-query/drone-station/auth';
import { contentsStore, useContentsStore } from '^/store/zustand/content/contentStore';
import * as T from '^/types';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLoaderData } from 'react-router-dom';

const ContentLyout = () => {
  const loaderData = useLoaderData();
  const projectId = (loaderData as { projectId: number }).projectId;
  const dispatch = useDispatch();

  const { fetchESSContents } = useESSContents();
  const { fetchESSModelCategories } = useESSModels();
  const postDroneStationAuthDetailsMutation = usePostDroneStationAuthDetailsMutation();

  const { getInitialContents } = useContents();

  const { getIssueContents } = useIssueContents();

  const allIds = useSelector((s: T.State) => s.Projects.projects.allIds);
  const byId = useSelector((s: T.State) => s.Projects.projects.byId);
  const getProjectStatus = useSelector((s: T.State) => s.Projects.getProjectStatus);
  const getProjectError = useSelector((s: T.State) => s.Projects.getProjectError);

  const features: T.PermissionFeature =
    projectId && allIds.includes(projectId) ? byId[projectId].features : defaultFeatures;

  const getInitialContentsStatus = useContentsStore(s => s.getInitialContentsStatus);

  const auth = useSelector((s: T.State) => s.Auth);
  const planConfigSlug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const authHeader = makeAuthHeader(auth, planConfigSlug);
  const isReadyToShowContents = getInitialContentsStatus === T.APIStatus.SUCCESS;

  useEffect(() => {
    if (features.droneStation && projectId) {
      postDroneStationAuthDetailsMutation({ projectId, authHeader });
    }
  }, [features?.droneStation, projectId]);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    const fetchData = async () => {
      await Promise.all([
        getInitialContents(projectId),
        fetchESSContents(projectId),
        fetchESSModelCategories(projectId),
        getIssueContents(),
      ]);
    };
    void fetchData();
    contentPageEnterActions(projectId).forEach(dispatch);

    return () => {
      contentPageExitActions().forEach(dispatch);
      dispatch(ChangeFirstVisitStatus({ firstVisit: true }));
      contentsStore.setState({ getInitialContentsStatus: T.APIStatus.IDLE });
      contentsStore.setState({ getLonLatOn2D3DToggleStatus: T.APIStatus.IDLE });
    };
  }, [projectId]);

  if (getProjectStatus === T.APIStatus.ERROR) {
    return <InvalidPageDisplay httpErrorStatus={getProjectError} />;
  }

  return isReadyToShowContents ? <Outlet /> : <LoadingScreen />;
};

export default memo(ContentLyout);
