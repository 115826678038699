import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { ContextMenuItem } from '../ContextMenuItem';

interface ContextMenuRootProps {
  width?: number;
  height?: number;
}

const ContextMenuListRoot = styled.div<ContextMenuRootProps>`
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-direction: column;
  background-color: #252628;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;
const ContextMenuTitleWrapper = styled.div<ContextMenuRootProps>`
  gap: 3px;
  display: flex;
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 2px;
  height: 24px;
`;
export const ContextMenuIconContainer = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
`;
export const ContextMenuTextContainer = styled.span``;

export interface ContextMenuOption {
  id: string;
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  name?: string;
  preIcon?: any;
  postIcon?: any;
  shouldShowDividerAfter?: boolean;
  disabled?: boolean;
  hide?: boolean;
}

interface ContextMenuProps {
  title?: {
    text?: string;
    preIcon?: any;
    postIcon?: any;
  };
  options?: ContextMenuOption[];
  properties?: {
    width?: number;
    height?: number;
  };
}

export const ContextMenuList = (props: ContextMenuProps) => {
  const { title, properties, options } = props;
  return (
    <ContextMenuListRoot width={properties?.width} height={properties?.height}>
      {title ? (
        <ContextMenuTitleWrapper>
          <ContextMenuIconContainer>{title?.preIcon}</ContextMenuIconContainer>
          <ContextMenuTextContainer>{title?.text}</ContextMenuTextContainer>
          <ContextMenuIconContainer>{title?.postIcon}</ContextMenuIconContainer>
        </ContextMenuTitleWrapper>
      ) : null}
      {options
        ?.filter(o => !o?.hide)
        ?.map(option => (
          <ContextMenuItem key={option.id} {...option} />
        ))}
    </ContextMenuListRoot>
  );
};
