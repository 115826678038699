import JSZip from 'jszip';

export async function fetchAndExtractKMZ(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();
  const zip = await JSZip.loadAsync(blob);
  const kmlFile = zip.file(/.kml$/i)[0];
  if (!kmlFile) {
    throw new Error('Unable to process the KMZ file.');
  }
  return kmlFile.async('string');
}
