/* eslint-disable max-lines */
import React, { FC, ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import ExclamationMarkSvg from '^/assets/icons/exclamation-mark.svg';
import QuestionMarkSvg from '^/assets/icons/question-mark.svg';
import { SubmitButton } from '^/components/atoms/Buttons';
import { ContentsListItem, HorizontalDivider } from '^/components/atoms/ContentsListItem';
import SingleSlider from '^/components/atoms/SingleSlider';
import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import routes from '^/constants/routes';
import { DISABLED_CONTENT_OPACITY, FontFamily } from '^/constants/styles';
import { UseGoToZendesk, UseL10n, isCADContent, useGoToZendesk, useL10n } from '^/hooks';
import { PatchContent, contentsSelector } from '^/store/duck/Contents';
import {
  ChangeAligningBlueprintContent,
  ChangeIsTopBarShown,
  ChangeSidebarStatus,
  OpenContentPagePopup,
} from '^/store/duck/Pages';
import * as T from '^/types';
import { isPhone } from '^/utilities/device';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import { useSnappingStore } from '^/store/snapping/snappingStore';
import { UnleashFlagProvider } from '^/components/atoms/UnleashFeatureProvider';
import { useThreeStore } from '^/components/three/ThreeStore';
import { BlueprintObject } from '^/components/three/ThreeObjects/Drawing';
import { Vector3 } from 'three';
import { useContentsStore } from '^/store/zustand/content/contentStore';

interface DisabledProp {
  isDisabled: boolean;
}

const Opacity = styled.div({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  marginTop: '13px',
});
const ZAxisWrapper = styled.div({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  marginTop: '23px',
});

const ZAxisText = styled.div({
  fontSize: '13px',
  fontWeight: 'bold',
  color: '#4d4c4c',
});

const ZAxisSliderWrapper = styled.div({
  padding: '9px 0 5px',
  width: '100%',
});

const OpacityText = styled.div<DisabledProp>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '48.5px',

  marginBottom: '13px',

  color: 'var(--color-theme-primary)',
  fontFamily: FontFamily.ROBOTO,
  fontSize: '15px',
  fontWeight: 500,
}));

const GoToPinSettingButtonWrapper = styled.div({
  width: '100%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '13px',

  /**
   * @desc For simple div wrapper
   */
  '> div': {
    width: '100%',
  },
});

const GoToPinSettingButton = styled.div<DisabledProp>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '100%',
  height: '33px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:hover': {
    background: palette.ContentsList.hoverGray.toString(),
  },

  borderColor: 'var(--color-theme-primary-lightest)',
  borderRadius: '6px',

  color: dsPalette.title.toString(),
  background: palette.ContentsList.itemBackgroundGray.toString(),

  cursor: isDisabled ? 'default' : 'pointer',
  userSelect: isDisabled ? 'none' : 'auto',
}));

const GoToPinSettingText = styled.div({
  fontSize: '11px',

  paddingLeft: '10.5px',
  color: palette.font.alpha(0.84).toString(),
});

const TooltipWrapperStyle: CSSObject = {
  position: 'relative',
  marginTop: '3.43px',
  marginLeft: '9.5px',
  marginRight: '10.5px',
};

const TooltipInfoStyle: WrapperHoverableProps['customStyle'] = {
  tooltipWrapperStyle: TooltipWrapperStyle,
  tooltipBackgroundStyle: {
    width: '225px',
    height: '60px',
    borderRadius: '6px',
  },
  tooltipBalloonStyle: {
    top: '22px',
    left: 'auto',
    right: '-45px',

    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '12px',
    paddingBottom: '10px',
  },
  tooltipArrowStyle: {
    marginTop: '4px',

    top: '-10px',
    left: 'auto',
    right: '58px',
  },

  tooltipTextTitleStyle: {
    width: '195px',
    height: '38px',

    lineHeight: '1.58',

    fontSize: '12px',
    fontWeight: 'normal',

    whiteSpace: 'normal',

    wordBreak: 'break-all',
  },
};

const ErrorWrapper = styled.div({
  marginTop: '15px',
  paddingTop: '20px',
  borderTop: '1px solid #e6e6e6',
});

const DescriptionWrapper = styled.p({
  display: 'flex',
});

const Description = styled.p({
  fontSize: '11px',
  lineHeight: '16px',
  marginBottom: '15px',
  wordBreak: 'keep-all',
  color: dsPalette.typePrimary.toString(),
});

const SvgWrapper = styled.div({
  width: '13px',
  height: '13px',
  marginRight: '3px',
});

const Button = styled(SubmitButton)({
  width: '100%',
  fontSize: '12px',
});

const SnapToggleText = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: `${dsPalette.grey130.toString()}`,
});

const SnapToggleButtonWrapper = styled.div({
  width: '100%',
  display: 'flex',
  marginTop: '20px',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ZWrapperTile = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

const CMInputContainer = styled.div({
  position: 'relative',
});
const UnitText = styled.span({
  position: 'absolute',
  right: '18px',
  top: '5px',
  color: '#7E7E7E',
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: '400',
});

const CMInput = styled.input<{ error: boolean; width?: string; hasUnit?: boolean }>(
  ({ error, width, hasUnit }) => ({
    boxSizing: 'border-box',
    textAlign: 'right',

    width: width || '',
    height: '30px',
    padding: `0 ${hasUnit ? '33px' : '16px'} 0 10px`,

    border: '1px solid #D9D9D9',
    borderRadius: '3px',

    color: '#7E7E7E',

    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '400',

    '::placeholder': {
      color: '#7E7E7E',
    },

    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'inner-spin-button',
      width: '5px',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      margin: 0,
    },

    borderColor: (error ? palette.error : palette.border).toString(),
  })
);

const MAX_OPACITY: number = 100;
const HALF_OPACITY: number = 50;
const MAX_Z_ADJUST: number = 2000;
const MIN_Z_ADJUST: number = -2000;

export interface Props {
  readonly content: T.OverLayContent;
}

const RawContentsListBlueprintItem: FC<Props> = ({ content }) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch: Dispatch = useDispatch();
  const goToZendesk: UseGoToZendesk = useGoToZendesk();
  const {
    snapEnabledDxfIds,
    toggleSnapEnabledDxfIds,
    snapEnabledLimit,
    autoOffOldest,
    setShowSnapActionBar,
    setLoadedGeoJsonDxfIds,
  } = useSnappingStore();
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  const contentId = content.id;

  const opacity: number =
    content.config?.opacity !== undefined
      ? content.config.opacity
      : content.type === T.ContentType.BLUEPRINT_DXF
      ? HALF_OPACITY
      : MAX_OPACITY;

  const zAdjust: number =
    content.type === T.ContentType.BLUEPRINT_DWG || content.type === T.ContentType.BLUEPRINT_DXF
      ? (content.config as T.BlueprintDWGConfigPerUser)?.zAdjust ?? 0
      : 0;

  const isBlueprintPDF: boolean = content.type === T.ContentType.BLUEPRINT_PDF;
  const isBlueprintDWG: boolean = content.type === T.ContentType.BLUEPRINT_DWG;
  const isBlueprintDXF: boolean = content.type === T.ContentType.BLUEPRINT_DXF;
  const isIn3D: boolean = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const isIn3DMesh: boolean =
    useSelector((s: T.State) => s.Pages.Contents.currentMeshEngine) === T.MeshEngine.THREEJS &&
    isIn3D;
  const isProcessingOrFailed: boolean = contentsSelector.isProcessingOrFailedByContent(content);
  const isFailed: boolean = content.status === T.ContentProcessingStatus.FAILED;
  const viewer = useThreeStore(s => s.viewer);

  //   const [zAdjustInputValue, setZAdjustInputValue] = useState(zAdjust.toFixed(2));

  const handleOpacityChange: (opacity: number) => void = opacityValue => {
    updateContentConfig(contentId, {
      ...content.config,
      type: content.type,
      opacity: opacityValue,
    });
  };

  const handleOpacityMouseUp: () => void = () => {
    dispatch(
      PatchContent({
        content: {
          id: contentId,
          config: {
            opacity: content.config?.opacity,
          },
        },
      })
    );
  };

  const isLimitReached = snapEnabledLimit <= snapEnabledDxfIds.length;

  const handleSnapToggle = (dxfId: T.Content['id']) => {
    setShowSnapActionBar(true);
    const shouldDisableSnap = isLimitReached && snapEnabledDxfIds.includes(dxfId);
    if (shouldDisableSnap) {
      toggleSnapEnabledDxfIds(dxfId);
      setLoadedGeoJsonDxfIds(dxfId);
      return;
    }
    if (autoOffOldest || !isLimitReached) {
      toggleSnapEnabledDxfIds(dxfId);
    }
  };

  const handleZAdjustChange: (zAdjust: number) => void = zAdjustValue => {
    if (isBlueprintDWG || isBlueprintDXF) {
      const blueprint = viewer?.entities.drawingEntity?.getObjectByName(`blueprint-${contentId}`);
      if (blueprint && blueprint instanceof BlueprintObject) {
        blueprint.position.z = zAdjust;
        // setZAdjustInputValue(zAdjustValue.toFixed(2));
        if (blueprint.dummy) {
          const z = blueprint.model?.getWorldPosition(new Vector3(0, 0, 0)).z;
          if (z !== undefined) {
            blueprint.dummy.position.z = z;
          }
        }
        updateContentConfig(contentId, {
          ...content.config,
          type: content.type,
          zAdjust: zAdjustValue,
          opacity: opacity,
        });
      }
    }
  };

  const handleZAdjustMouseUp: () => void = () => {
    updateBlueprintPosition(zAdjust);
  };

  const updateBlueprintPosition: (zValue: number) => void = zValue => {
    if (isBlueprintDWG || isBlueprintDXF) {
      const blueprint = viewer?.entities.drawingEntity?.getObjectByName(`blueprint-${contentId}`);
      if (blueprint && blueprint instanceof BlueprintObject) {
        blueprint.position.z = zValue;
        if (blueprint.dummy) {
          const z = blueprint.model?.getWorldPosition(new Vector3(0, 0, 0)).z;
          if (z !== undefined) {
            blueprint.dummy.position.z = z;
          }
        }
        dispatch(
          PatchContent({
            content: {
              id: contentId,
              config: {
                zAdjust: blueprint.position.z,
              },
            },
          })
        );
      }
    }
  };

  const handleDetailButtonClick: () => void = useCallback(() => {
    goToZendesk(routes.externalLink.cadError);
  }, [goToZendesk]);

  const handleOnClick: () => void = () => {
    if (isProcessingOrFailed) {
      return;
    }

    dispatch(ChangeSidebarStatus({ open: false }));
    dispatch(ChangeIsTopBarShown({ isOpened: false }));
    dispatch(ChangeAligningBlueprintContent({ aligningBlueprintId: contentId }));
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.BLUEPRINT_ALIGN }));
  };

  const pinSettingDescription: ReactNode = (
    <WrapperHoverable title={l10n(Text.description)} customStyle={TooltipInfoStyle}>
      <QuestionMarkSvg />
    </WrapperHoverable>
  );
  const pinSettingButton: ReactNode = (
    <GoToPinSettingButton
      className={CANCELLABLE_CLASS_NAME}
      onClick={handleOnClick}
      isDisabled={isProcessingOrFailed}
    >
      <GoToPinSettingText>{l10n(Text.title)}</GoToPinSettingText>
      {pinSettingDescription}
    </GoToPinSettingButton>
  );

  const pinSetting: ReactNode =
    isPhone() || !isBlueprintPDF ? undefined : (
      <>
        <HorizontalDivider isDisabled={isProcessingOrFailed} />
        <GoToPinSettingButtonWrapper>{pinSettingButton}</GoToPinSettingButtonWrapper>
      </>
    );

  const opacitySetting: ReactNode = (
    <Opacity>
      <OpacityText isDisabled={isProcessingOrFailed}>{opacity.toFixed(0)}%</OpacityText>
      <SingleSlider
        minValue={0}
        maxValue={MAX_OPACITY}
        value={opacity}
        isDisabled={isProcessingOrFailed}
        onChange={handleOpacityChange}
        onMouseUp={handleOpacityMouseUp}
      />
    </Opacity>
  );

  const snapToggleButton: ReactNode = (
    <UnleashFlagProvider
      flagName="snapping"
      render={({ isEnabled }) =>
        isEnabled && !isIn3D ? (
          <SnapToggleButtonWrapper>
            {content.info.geojsonCompressed && (
              <>
                <SnapToggleText>{l10n(Text.snapToggleButton)}</SnapToggleText>
                <ToggleSlider
                  trackAction={T.TrackActions.MAP_TOOLS}
                  trackLabel={T.TrackLabels.BTN_SNAP_TOGGLE}
                  onClick={() => handleSnapToggle(contentId)}
                  enabled={snapEnabledDxfIds.includes(contentId)}
                />
              </>
            )}
          </SnapToggleButtonWrapper>
        ) : null
      }
    />
  );

  const zSetting: ReactNode = (
    <ZAxisWrapper>
      <ZWrapperTile>
        <ZAxisText>Z-Axis Altitude</ZAxisText>
        <CMInputContainer>
          <CMInput
            error={false}
            width={'100px'}
            hasUnit={true}
            value={zAdjust.toFixed(2)}
            defaultValue={zAdjust.toFixed(2)}
            type="number"
            step={1}
            min={MIN_Z_ADJUST}
            max={MAX_Z_ADJUST}
            onKeyDown={event => {
              event.stopPropagation();
            }}
            onChange={event => {
              const newValue = event.target.value;
              //   setZAdjustInputValue(newValue);
              const parsedValue = Number(newValue);
              if (!isNaN(parsedValue)) {
                updateBlueprintPosition(parsedValue);
              }
            }}
          />
          <UnitText>m</UnitText>
        </CMInputContainer>
      </ZWrapperTile>
      <ZAxisSliderWrapper>
        <SingleSlider
          minValue={MIN_Z_ADJUST}
          maxValue={MAX_Z_ADJUST}
          value={zAdjust}
          isDisabled={isProcessingOrFailed}
          onChange={handleZAdjustChange}
          onMouseUp={handleZAdjustMouseUp}
        />
      </ZAxisSliderWrapper>
    </ZAxisWrapper>
  );
  const ErrorInfo: ReactNode =
    isFailed && isCADContent(content) && content.info.error ? (
      <ErrorWrapper>
        <DescriptionWrapper>
          <SvgWrapper>
            <ExclamationMarkSvg />
          </SvgWrapper>
          <Description>{l10n(content.info.error?.message)}</Description>
        </DescriptionWrapper>
        <Button onClick={handleDetailButtonClick}>{l10n(Text.errorDetail)}</Button>
      </ErrorWrapper>
    ) : null;

  const firstBalloonTitle: string = (() => {
    if (content.type === T.ContentType.DESIGN_DXF) {
      return l10n(Text.designDXF);
    }
    if (isFailed && isCADContent(content)) {
      return l10n(Text.error);
    }

    return l10n(Text.firstBalloonTitle);
  })();

  return (
    <ContentsListItem content={content} firstBalloonTitle={firstBalloonTitle}>
      {opacitySetting}
      {snapToggleButton}
      {isIn3DMesh && (isBlueprintDXF || isBlueprintDWG) ? zSetting : null}
      {pinSetting}
      {ErrorInfo}
    </ContentsListItem>
  );
};

export const ContentsListBlueprintItem: FC<Props> = withErrorBoundary(RawContentsListBlueprintItem)(
  Fallback
);
