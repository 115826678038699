import * as T from '^/types';

export default {
  notes: {
    itemSelectedTooltip: (items: number) => ({
      [T.Language.KO_KR]: `${items}장 선택됨`,
      [T.Language.EN_US]: `${items} ${items > 1 ? 'items' : 'item'} selected`,
    }),
    deselectToolTip: {
      [T.Language.KO_KR]: '모두 선택 해제',
      [T.Language.EN_US]: 'Unselect all',
    },
  },
  exclude: {
    [T.Language.KO_KR]: '타임랩스 제외',
    [T.Language.EN_US]: 'Exclude from timelapse',
  },

  include: {
    [T.Language.KO_KR]: '타임랩스 포함',
    [T.Language.EN_US]: 'Include in timelapse',
  },

  subHeader: {
    timelapse: {
      [T.Language.KO_KR]: '타임랩스 이미지',
      [T.Language.EN_US]: 'Timelapse Images',
    },
    excluded: {
      [T.Language.KO_KR]: '제외된 이미지',
      [T.Language.EN_US]: 'Excluded Images',
    },
    timelapseButton: {
      [T.Language.KO_KR]: '타임랩스 보기',
      [T.Language.EN_US]: 'View Timelapse',
    },
    timelapseTooltip: {
      [T.Language.EN_US]: 'View timelapse from selected Images',
      [T.Language.KO_KR]: '선택한 이미지에서 타임랩스를 보기',
    },
  },

  photo: {
    editDatesTooltip: {
      [T.Language.KO_KR]: '날짜 수정',
      [T.Language.EN_US]: 'Edit dates',
    },
    deleteTooltip: {
      [T.Language.KO_KR]: '삭제',
      [T.Language.EN_US]: 'Delete',
    },
    DownloadTooltip: {
      [T.Language.KO_KR]: '선택한 항목 다운로드',
      [T.Language.EN_US]: 'Download selected items',
    },
    DownloadProgressTooltip: {
      [T.Language.KO_KR]: '진행중인 다운로드가 완료될 때까지 잠시 기다려주세요.',
      [T.Language.EN_US]: 'Please wait until the current download is complete.',
    },
    zippedTooltip: {
      [T.Language.KO_KR]: '압축중...',
      [T.Language.EN_US]: 'Zipping photos...',
    },
    downloadProgressMsg: {
      [T.Language.KO_KR]: '다운로드 중...',
      [T.Language.EN_US]: 'Downloading...',
    },
    downloadCompleteMsg: {
      [T.Language.KO_KR]: '다운로드를 완료했습니다.',
      [T.Language.EN_US]: 'Download complete.',
    },
    error: {
      title: {
        [T.Language.KO_KR]: '파일 업로드 오류',
        [T.Language.EN_US]: 'Something went wrong',
      },
      description: {
        [T.Language.KO_KR]: '파일을 업로드하는 중 오류가 발생했습니다. 나중에 다시 시도해주세요.',
        [T.Language.EN_US]: 'An error occurred while fetching images. Please try again later.',
      },
    },
  },

  goBack: {
    [T.Language.KO_KR]: '뒤로가기',
    [T.Language.EN_US]: 'Go back',
  },
};
