import Color from 'color';
import React, { FC } from 'react';

export const GroupImageSvgIcon: FC<{ color: Color; width?: number; height?: number }> = ({
  color,
  width,
  height,
}) => (
  <svg
    width={width || '45'}
    height={height || '34'}
    viewBox={`0 0 ${width || '45'} ${height || '34'}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.4293 17.1493C38.4293 18.7821 37.0643 
      20.1058 35.3804 20.1058C33.6965 20.1058 32.3315 
      18.7821 32.3315 17.1493C32.3315 15.5164 33.6965 
      14.1927 35.3804 14.1927C37.0643 14.1927 38.4293 
      15.5164 38.4293 17.1493Z"
      fill={color.toString()}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 7.66309H45V33.75H7.5V7.66309ZM41.4946 28.846L33.7989 21.6551L27.8252 27.237L17.6737 17.7501L11.0217 23.9606V11.0781H41.4946V28.846Z"
      fill={color.toString()}
    />
    <path d="M3.52174 3.57811V28.2881H0V0.163086H39.375V3.57811H3.52174Z" fill={color.toString()} />
  </svg>
);
