import proj4 from 'proj4';

import { getEPSGfromProjectionLabel } from './coordinate-util';
import * as T from '^/types';
import { RowValue } from '^/components/molecules/CoordinateTable';

export const gcpsToGeoPoints: (
  gcps: T.GCP[],
  gcpCRS: T.CoordinateSystem
) => T.GeoPoint[] | undefined = (gcps, gcpCRS) => {
  try {
    return gcps
      .map(gcp => [gcp.easting, gcp.northing])
      .filter(coordinate => coordinate.every(value => !Number.isNaN(value) && value !== 0))
      .map(([easting, northing]) =>
        proj4(getEPSGfromProjectionLabel(T.ProjectionEnum[gcpCRS]), 'EPSG:4326').forward([
          easting,
          northing,
        ])
      );
  } catch (e) {
    return undefined;
  }
};

export const dxfBordertoPoints: (borders: T.GeoPoint[]) => T.GeoPoint[] | undefined = borders =>
  borders.map(([easting, northing]) =>
    proj4('EPSG:3857', 'EPSG:4326').forward([easting, northing])
  );

export const gcpToGeoPoints = (gcps: T.GCP, gcpCRS: T.CoordinateSystem) => {
  const { easting, northing } = gcps;
  return proj4(getEPSGfromProjectionLabel(T.ProjectionEnum[gcpCRS]), 'EPSG:4326').forward([
    easting,
    northing,
  ]);
};

export const rowValues2GCP = (rowValues: RowValue[]): T.GCP[] => {
  const rows = rowValues;
  const gcpContent: T.GCP[] = rows.map(row => {
    const [label, northing, easting, altitude] = row;
    return {
      label,
      easting: Number(easting),
      northing: Number(northing),
      altitude: Number(altitude),
    };
  });
  return gcpContent;
};
