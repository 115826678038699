/* eslint-disable @typescript-eslint/ban-ts-comment */
import { autobind } from 'core-decorators';
import {
  BufferGeometry,
  Camera,
  Float32BufferAttribute,
  Mesh,
  OrthographicCamera,
  PerspectiveCamera,
  Vector3,
  DoubleSide,
  MeshStandardMaterial,
} from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { DisposeHelper } from '../../Lib/Helper';
import { MERGE_MATERIAL } from '../../Lib/constant';

const _vector = /*@__PURE__*/ new Vector3();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const _camera = /*@__PURE__*/ new Camera();

class ThreeCameraHelperMesh extends Mesh {
  public camera: PerspectiveCamera | OrthographicCamera;
  public pointMap: any;
  public surface: Mesh;
  public vertices: number[] = [];
  public viewer: Viewer;

  public constructor(camera: Camera, viewer: Viewer) {
    const geometry = new BufferGeometry();
    const material = new MeshStandardMaterial({
      color: 0x156289,
      wireframe: false,
      transparent: true,
      opacity: 0.5,
      side: DoubleSide,
      flatShading: true,
    });

    super(geometry, material);
    this.viewer = viewer;

    this.surface = new Mesh(new BufferGeometry(), MERGE_MATERIAL);
    this.surface.visible = false;
    this.add(this.surface);
    const pointMap: any = {};
    const vertices = this.vertices;

    // Define frustum points
    const frustumPoints = [
      'n1',
      'n2',
      'n3',
      'n4', // Near plane
      'f1',
      'f2',
      'f3',
      'f4', // Far plane
      'u1',
      'u2',
      'u3', // Up indicators or additional points as needed
      'p', // Cone tip
    ];

    // Initialize points
    frustumPoints.forEach(point => addPoint(point));

    function addPoint(id: string) {
      vertices.push(0, 0, 0);
      if (pointMap[id] === undefined) {
        pointMap[id] = [];
      }
      pointMap[id].push(vertices.length / 3 - 1);
    }

    //@ts-ignore
    this.camera = camera;
    //@ts-ignore
    this.type = 'ThreeCameraHelper';

    if (this.camera.updateProjectionMatrix) {
      this.camera.updateProjectionMatrix();
    }

    this.matrix = camera.matrixWorld;
    this.matrixAutoUpdate = false;

    this.pointMap = pointMap;
    this.geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

    this.update();
  }

  @autobind
  public update() {
    const geometry = this.geometry;
    const pointMap = this.pointMap;
    geometry.setAttribute('position', new Float32BufferAttribute(this.vertices, 3));
    const w = 1,
      h = 1;

    // Update camera projection matrix inverse
    _camera.projectionMatrixInverse.copy(this.camera.projectionMatrixInverse);

    // Near plane points
    const n1 = this.setPoint('n1', pointMap, geometry, _camera, -w, -h, -1);
    const n2 = this.setPoint('n2', pointMap, geometry, _camera, w, -h, -1);
    const n3 = this.setPoint('n3', pointMap, geometry, _camera, -w, h, -1);
    const n4 = this.setPoint('n4', pointMap, geometry, _camera, w, h, -1);

    // Far plane points
    const f1 = this.setPoint('f1', pointMap, geometry, _camera, -w, -h, 1);
    const f2 = this.setPoint('f2', pointMap, geometry, _camera, w, -h, 1);
    const f3 = this.setPoint('f3', pointMap, geometry, _camera, -w, h, 1);
    const f4 = this.setPoint('f4', pointMap, geometry, _camera, w, h, 1);

    // // Up points
    // const u1 = this.setPoint('u1', pointMap, geometry, _camera, w * 0.7, h * 1.1, -1);
    // const u2 = this.setPoint('u2', pointMap, geometry, _camera, -w * 0.7, h * 1.1, -1);
    // const u3 = this.setPoint('u3', pointMap, geometry, _camera, 0, h * 2, -1);

    // // Cone tip
    // const p = this.setPoint('p', pointMap, geometry, _camera, 0, 0, -2);

    // Define faces (triangles) for the frustum mesh
    const triangles: number[] = [];

    // // Near face
    // triangles.push(n1, n2, n3);
    // triangles.push(n2, n4, n3);

    // // Far face
    // triangles.push(f1, f3, f2);
    // triangles.push(f2, f3, f4);

    // Sides
    triangles.push(n1, f1, f2);
    triangles.push(n1, f2, n2);

    triangles.push(n2, f2, f4);
    triangles.push(n2, f4, n4);

    triangles.push(n4, f4, f3);
    triangles.push(n4, f3, n3);

    triangles.push(n3, f3, f1);
    triangles.push(n3, f1, n1);

    // Optionally, add cone sides
    // triangles.push(n1, p, n2);
    // triangles.push(n2, p, n4);
    // triangles.push(n4, p, n3);
    // triangles.push(n3, p, n1);

    // Set the index for the geometry
    geometry.setIndex(triangles);
    geometry.computeVertexNormals();

    // Optionally handle the surface mesh if needed
    // this.surface.geometry.setAttribute('position', new BufferAttribute(new Float32Array(array), 3));

    // Update geometry
    geometry.getAttribute('position').needsUpdate = true;
    geometry.computeBoundingSphere();
  }

  @autobind
  public dispose() {
    DisposeHelper.disposeHierarchy(this);
  }

  @autobind
  private setPoint(
    point: string,
    pointMap: any,
    geometry: BufferGeometry,
    camera: Camera,
    x: number,
    y: number,
    z: number
  ): number {
    _vector.set(x, y, z).unproject(camera);

    const points = pointMap[point];

    if (points !== undefined) {
      const position = geometry.getAttribute('position');
      for (let i = 0, l = points.length; i < l; i++) {
        position.setXYZ(points[i], _vector.x, _vector.y, _vector.z);
      }
    }
    return points ? points[0] : -1; // Return the first index or -1 if undefined
  }
}

export { ThreeCameraHelperMesh };
