import React, { FC } from 'react';
import styled from 'styled-components';

import Text from './text';
import {
  CancelButton as RawCancelButton,
  ConfirmButton as RawConfirmButton,
} from '^/components/atoms/Buttons';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import { MediaQuery } from '^/constants/styles';

import ProjectPermissionTable from '^/containers/organisms/ProjectPermissionTable';
import * as T from '^/types';

import { l10n } from '^/utilities/l10n';
import { MemberSearchInput } from '../MemberSearchInput';

const TableTitleWrapper = styled.div({
  display: 'flex',
  direction: 'ltr',
  justifyContent: 'space-between',
  alignItems: 'stretch',

  width: '100%',
  marginTop: '50px',
});

const RightSideItems = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const TableTitle = styled.h2({
  alignSelf: 'center',
  fontSize: '18px',
  lineHeight: 1,
  fontWeight: 500,
  color: palette.textBlack.toString(),
});

const ShareButton = styled(RawConfirmButton)({
  marginLeft: '28px',
  [MediaQuery[T.Device.MOBILE_L]]: {
    width: '80px',
    height: '32px',
  },
});

export const DeleteButton = styled(RawCancelButton)({
  backgroundColor: palette.textLight.toString(),
  color: palette.white.toString(),
  display: 'block',
  margin: 'auto',
  marginTop: '80px',
  width: '160px',
  height: '58px',
  fontSize: '16px',

  ':hover': {
    backgroundColor: palette.dividerLight.toString(),
  },
});

export interface Props {
  handleShareClick(): void;
  handleDeleteClick(): void;
  isUserAllowToCreateAndDeleteProject: boolean;
}

const MemberBoard: FC<Props & L10nProps> = ({
  handleShareClick,
  handleDeleteClick,
  language,
  isUserAllowToCreateAndDeleteProject,
}) => (
  <>
    <TableTitleWrapper>
      <TableTitle>{l10n(Text.tableTitle, language)}</TableTitle>
      <RightSideItems>
        <MemberSearchInput size="lg" />
        <ShareButton onClick={handleShareClick} data-testid="share-button">
          {l10n(Text.addMember, language)}
        </ShareButton>
      </RightSideItems>
    </TableTitleWrapper>
    <ProjectPermissionTable />
    {isUserAllowToCreateAndDeleteProject ? (
      <DeleteButton onClick={handleDeleteClick} data-testid="delete-button">
        {l10n(Text.delete, language)}
      </DeleteButton>
    ) : null}
  </>
);

export default withL10n(MemberBoard);
