import { LoaderProps } from 'three-loader-3dtiles';
import { Loader, LoadingManager } from 'three';
import * as OBC from '@thatopen/components';
import * as OBCV1 from 'openbim-components';
import { autobind } from 'core-decorators';
import { IfcStreamer } from './IFCStreamer';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { GeometryCullerRenderer } from './IFCStreamer/GeometryCullerRenderer';

// import { FragmentsGroup } from 'bim-fragment';

class IFCLoader extends Loader {
  private readonly props: LoaderProps | any;
  private readonly name: string | undefined;
  private viewer: Viewer | undefined;
  public culler: GeometryCullerRenderer | undefined;
  public readonly ifcStreamer: IfcStreamer | undefined;
  public constructor(manager?: LoadingManager | undefined, viewer?: Viewer) {
    super(manager);
    this.viewer = viewer;

    // const components = new OBC.Components();
    // const worlds = components.get(OBC.Worlds);
    // const world = worlds.create();
    // const ifcStreamer = new IfcStreamer(components, this.viewer!);
    // this.ifcStreamer = ifcStreamer;
    // this.ifcStreamer.world = world;
    // this.ifcStreamer.culler.threshold = 10;
    // this.ifcStreamer.culler.maxHiddenTime = 1000;
    // this.ifcStreamer.culler.maxLostTime = 3000;
    // this.ifcStreamer.useCache = true;
  }
  public load(url: string, onLoad: any, onProgress: any, onError: any): any {
    const isJson = url.endsWith('.json');
    if (isJson) {
      void this.loadTile(url, onLoad, onProgress, onError);
    } else {
      const isFragments = url.endsWith('.frag');
      if (isFragments) {
        void this.loadFragmentsV1(url, onLoad, onProgress, onError);
      } else {
        void this.loadIFCFileV1(url, onLoad, onProgress, onError);
      }
    }
  }
  @autobind
  private async loadIFCFileV1(url: string, onLoad: any, onProgress: any, onError: any) {
    try {
      const components = new OBCV1.Components();
      components.uiEnabled = false;
      const fragmentIfcLoader = new OBCV1.FragmentIfcLoader(components);
      fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
      fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;
      fragmentIfcLoader.settings.includeProperties = false;

      await fragmentIfcLoader.setup({});
      const file = await fetch(url, { credentials: 'include' });
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await fragmentIfcLoader.load(buffer, this.name === undefined ? '' : this.name);
      onLoad(model);
    } catch (err: unknown) {
      onError(err);
    }
  }
  @autobind
  private async loadFragmentsV1(url: string, onLoad: any, onProgress: any, onError: any) {
    try {
      const container = document.createElement('div');
      const components = new OBCV1.Components();
      components.scene = new OBCV1.SimpleScene(components);
      components.renderer = new OBCV1.PostproductionRenderer(components, container);
      components.camera = new OBCV1.SimpleCamera(components);
      components.raycaster = new OBCV1.SimpleRaycaster(components);
      components.uiEnabled = false;
      await components.init();
      const fragments = new OBCV1.FragmentManager(components);
      const file = await fetch(url, { credentials: 'include' });
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await fragments.load(buffer);
      onLoad(model);
    } catch (err: unknown) {
      onError(err);
    }
  }
  @autobind
  private async loadIFCFile(url: string, onLoad: any, onProgress: any, onError: any) {
    try {
      const components = new OBC.Components();
      const fragmentIfcLoader = components.get(OBC.IfcLoader);
      fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
      fragmentIfcLoader.settings.includeProperties = false;
      // await fragmentIfcLoader.setup();
      fragmentIfcLoader.settings.wasm = {
        path: 'https://cdn.jsdelivr.net/npm/web-ifc@0.0.59/',
        absolute: true,
      };
      const file = await fetch(url, { credentials: 'include' });
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await fragmentIfcLoader.load(
        buffer,
        true,
        this.name === undefined ? '' : this.name
      );
      onLoad(model);
    } catch (err: unknown) {
      onError(err);
    }
  }
  @autobind
  private async loadFragments(url: string, onLoad: any, onProgress: any, onError: any) {
    try {
      const components = new OBC.Components();
      const fragments = components.get(OBC.FragmentsManager);
      const file = await fetch(url, { credentials: 'include' });
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = fragments.load(buffer, {
        coordinate: true,
        name: this.name === undefined ? '' : this.name,
      });
      onLoad(model);
    } catch (err: unknown) {
      onError(err);
    }
  }
  @autobind
  private async loadTile(url: string, onLoad: any, onProgress: any, onError: any) {
    try {
      const folderPath = url.substring(0, url.lastIndexOf('/'));

      this.ifcStreamer!.url = folderPath + '/';
      const rawGeometryData = await fetch(url, { credentials: 'include' });
      const geometryData = await rawGeometryData.json();
      const model = await this.ifcStreamer!.load(geometryData, false, undefined);
      this.culler = this.ifcStreamer!.culler;

      onLoad(model);
    } catch (err: unknown) {
      onError(err);
    }
  }
  @autobind
  public async loadAsync(viewer: Viewer, url: string, onProgress: any) {
    return new Promise((resolve, reject) => {
      this.viewer = viewer;
      void this.load(url, resolve, onProgress, reject);
    });
  }
  public setProps(props: any) {
    Object.assign(this, props);
  }
}
export { IFCLoader };
