import { Language } from '^/types';

export default {
  weekday: {
    [Language.KO_KR]: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    [Language.EN_US]: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  year: {
    [Language.KO_KR]: '년',
    [Language.EN_US]: '',
  },
  month: {
    [Language.KO_KR]: [
      '1월',
      '2월',
      '3월',
      '4월',
      '5월',
      '6월',
      '7월',
      '8월',
      '9월',
      '10월',
      '11월',
      '12월',
    ],
    [Language.EN_US]: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  date: {
    [Language.KO_KR]: '일',
    [Language.EN_US]: '',
  },
  createdBy: {
    [Language.KO_KR]: '생성자',
    [Language.EN_US]: 'Created By',
  },
  createdAt: {
    [Language.KO_KR]: '생성된 날짜',
    [Language.EN_US]: 'Created Date',
  },
  dueDate: {
    [Language.KO_KR]: '마감 기한',
    [Language.EN_US]: 'Due Date',
  },
  dueDateNotSet: {
    [Language.KO_KR]: '미정',
    [Language.EN_US]: 'Not Set',
  },
  priority: {
    [Language.KO_KR]: '우선순위',
    [Language.EN_US]: 'Priority',
  },
  status: {
    [Language.KO_KR]: '상태',
    [Language.EN_US]: 'Status',
  },
  category: {
    title: {
      [Language.KO_KR]: '유형',
      [Language.EN_US]: 'Type',
    },
    placeHolder: {
      [Language.KO_KR]: '유형 선택',
      [Language.EN_US]: 'Choose Type',
    },
  },
};
