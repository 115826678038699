/* eslint-disable max-lines */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import palette from '^/constants/palette';
import { defaultToastErrorOption, UseL10n, useL10n, UseToast, useToast } from '^/hooks';
import * as T from '^/types';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import { DISABLED_CONTENT_OPACITY } from '^/constants/styles';
import Text from './text';
import dsPalette from '^/constants/ds-palette';

import { HorizontalDivider } from '^/components/molecules/ContentsListMapItem';
import {
  CreateEarthworkRequestParam,
  Earthwork,
  EarthWorkStore,
  ZoneInput,
} from '^/store/earthwork/types';
import FinalEarthWorkSelectAttachment, {
  EmptyText,
  EmptyZoneContent,
} from './FinalEarthWorkSelectAttachment';
import InitialSurveySelectAttachment from './InitialSurveySelectAttachment';
import SelectRockLayer, { CurrentSelectedContentWrapper } from './SelectRockLayer';
import useEarthWorkContents from '^/hooks/earthwork/useEarthWorkContents';
import useEarthWorkFile from '^/hooks/earthwork/useEarthWorkFile';

import { Input, InputArea } from '^/components/molecules/TimelapseCollectionList/Styles';
import { SelectButton } from './SelectedPlaceholder';
import {
  ChangeCurrentContentTypeFromAnnotationPicker,
  CloseContentPageMapPopup,
} from '^/store/duck/Pages';
import { useDispatch } from 'react-redux';
import EarthworkContentList from './EarthworkContentList';
import { TitleSkeleton } from '../EarthworkPageContents/TitleSkeleton';
import { generateWKT } from '../earthworkUtils';

const MeasurementContentsListWrapper = styled.div.attrs({
  className: 'ctxsort-scroller',
})({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowX: 'hidden',
  touchAction: 'none',
});
MeasurementContentsListWrapper.displayName = 'MeasurementContentsListWrapper';

export const DashboardContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',
  userSelect: 'none',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});
DashboardContentsListRoot.displayName = 'GroupedContentsListRoot';

const SkeletonWrapper = styled.ol({
  padding: '10px',
});

const ContentsRoot = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(90vh - 50px)',
  position: 'relative',
});

export const CustomButton = styled.button<{ disabled?: boolean }>(({ disabled }) => ({
  marginTop: '16px',
  width: '100%',
  padding: '12px 16px',
  border: 'none',
  backgroundColor: dsPalette.themePrimary.toString(),
  color: palette.white.toString(),
  borderRadius: '4px',
  fontSize: '12px',
  cursor: 'pointer',
  outline: 'none',
  opacity: disabled ? DISABLED_CONTENT_OPACITY : 1,
}));

export const CreateNowButton = styled.div({
  padding: '0px 20px 20px 20px',
});

export const LabelIcon = styled.div({
  fontWeight: 'bold',
  fontSize: '14px',
  color: dsPalette.themePrimary.toString(),
});

const SelectEarthworkWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const EarthworkContentListWrapper = styled.div({
  maxHeight: '400px',
  height: 'fit',
  overflow: 'scroll',
});

export const UploadEarthWorkWrapper = styled.div({
  padding: '0 25px 10px 20px',
  display: 'flex',
  flexDirection: 'column',
});

const LabelText = styled.div({
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 'bold',
});

const BackButtonWrapper = styled.div({
  margin: '20px  20px 10px 20px',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  width: 'fit-content',
  cursor: 'pointer',
  borderRadius: '8px',
});
const CustomButtonWrapper = styled.div({
  padding: '0px 10px 0px 10px',
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px',
});

const SWrapper = styled.div({
  marginTop: '8px',
});

export const Contents: FC = () => {
  const [l10n]: UseL10n = useL10n();
  const dispatch = useDispatch();
  const toastify: UseToast = useToast();
  const {
    rockLayers,
    initialSurvey,
    selectedZones,
    finalEarthWork,
    earthworkLists,
    earthworkBasics,
    createNewEarthwork,
    selectedEarthworkId,
    earthworkFetchStatus,
    createEarthworkStatus,
    earthworkAnalysisFetchStatus,
    setRockLayers,
    setShowBurndown,
    setInitialSurvey,
    setFinalEarthWork,
    setSelectedZones,
    setEarthworkBasics,
    setSelectedVolumeId,
    setCreateNewEarthwork,
    setSelectedEarthworkId,
    setEarthworkFetchStatus,
    setCreateEarthworkStatus,
    setEarthworkAnalysisFetchStatus,
  } = useEarthWorkStore((s: EarthWorkStore) => ({
    syncStatus: s.syncStatus,
    rockLayers: s.rockLayers,
    showBurndown: s.showBurnDown,
    initialSurvey: s.initialSurvey,
    selectedZones: s.selectedZones,
    finalEarthWork: s.finalEarthWork,
    earthworkLists: s.earthworkLists,
    earthworkBasics: s.earthworkBasics,
    createNewEarthwork: s.createNewEarthwork,
    selectedEarthworkId: s.selectedEarthworkId,
    earthworkFetchStatus: s.earthworkFetchStatus,
    createEarthworkStatus: s.createEarthworkStatus,
    earthworkAnalysisFetchStatus: s.earthworkAnalysisFetchStatus,
    reset: s.reset,
    setRockLayers: s.setRockLayers,
    setShowBurndown: s.setShowBurnDown,
    setInitialSurvey: s.setInitialSurvey,
    setSelectedZones: s.setSelectedZones,
    setFinalEarthWork: s.setFinalEarthWork,
    setEarthworkBasics: s.setEarthworkBasics,
    setSelectedVolumeId: s.setSelectedVolumeId,
    setCreateNewEarthwork: s.setCreateNewEarthwork,
    setEarthworkFetchStatus: s.setEarthworkFetchStatus,
    setSelectedEarthworkId: s.setSelectedEarthworkId,
    setCreateEarthworkStatus: s.setCreateEarthworkStatus,
    setEarthworkAnalysisFetchStatus: s.setEarthworkAnalysisFetchStatus,
  }));

  const { getEarthWorklists, getEarthWorkAnalysis, createEarthWork } = useEarthWorkContents();

  const isCreating = createEarthworkStatus === T.APIStatus.PROGRESS;
  const isLoading = earthworkFetchStatus === T.APIStatus.PROGRESS;

  const isError =
    createEarthworkStatus === T.APIStatus.ERROR ||
    earthworkFetchStatus === T.APIStatus.ERROR ||
    earthworkAnalysisFetchStatus === T.APIStatus.ERROR;

  const [isDisabled, setIsDisabled] = useState(true);

  const { contents } = useEarthWorkFile();

  const handleEarthWorkSelect = (earthworkId: Earthwork['id']) => {
    setSelectedEarthworkId(earthworkId);
    setShowBurndown(false);
    setSelectedVolumeId(undefined);
    void getEarthWorkAnalysis(earthworkId);
  };

  const handleBackButtonClick = () => {
    setCreateNewEarthwork(false);
    resetCredentials();
  };

  const newEarthworkToggle = (
    <CustomButtonWrapper>
      <CustomButton onClick={() => setCreateNewEarthwork(!createNewEarthwork)}>
        {l10n(Text.earthwork.createNewEarthwork)}
      </CustomButton>
    </CustomButtonWrapper>
  );

  const backButton = (
    <BackButtonWrapper onClick={handleBackButtonClick}>
      <LabelText> &larr; {l10n(Text.back)}</LabelText>
    </BackButtonWrapper>
  );

  const emptyZone = (
    <UploadEarthWorkWrapper>
      <CurrentSelectedContentWrapper>
        <EmptyZoneContent>
          <EmptyText>{l10n(Text.earthwork.emptyZone)}</EmptyText>
        </EmptyZoneContent>
      </CurrentSelectedContentWrapper>
    </UploadEarthWorkWrapper>
  );

  const selectEarthwork: ReactNode = (
    <SelectEarthworkWrapper>
      <Header>
        <LabelText>{l10n(Text.earthwork.selectEarthwork)}</LabelText>
      </Header>
      <EarthworkContentListWrapper className="no-scrollbar">
        {earthworkLists?.length > 0 ? (
          <EarthworkContentList value={selectedEarthworkId} onSelect={handleEarthWorkSelect} />
        ) : null}
        {isLoading ? (
          <SkeletonWrapper>
            <TitleSkeleton />
          </SkeletonWrapper>
        ) : (
          earthworkLists?.length === 0 && emptyZone
        )}
      </EarthworkContentListWrapper>
    </SelectEarthworkWrapper>
  );

  // const statusText = (
  //   <StatuslabelWrapper>
  //     {syncStatus === SyncStatus.SUCCESS ? (
  //       <CheckSvg />
  //     ) : syncStatus === SyncStatus.SYNCING ? (
  //       <LoadingIcon />
  //     ) : null}
  //     <StatusLabel>
  //       {syncStatus === SyncStatus.SUCCESS
  //         ? l10n(Text.completeCalculations)
  //         : syncStatus === SyncStatus.SYNCING
  //         ? l10n(Text.processingCalculations)
  //         : l10n(Text.idealCalculations)}
  //     </StatusLabel>
  //   </StatuslabelWrapper>
  // );

  const nameInput = (
    <UploadEarthWorkWrapper>
      <LabelText>{l10n(Text.title)}</LabelText>
      <SWrapper>
        <SelectButton isPlaceHolder={false}>
          <Input
            value={earthworkBasics?.name}
            onChange={e => setEarthworkBasics({ ...earthworkBasics, name: e.target.value })}
            placeholder={l10n(Text.titlePlaceholder)}
            type="text"
          />
        </SelectButton>
      </SWrapper>
    </UploadEarthWorkWrapper>
  );

  const descriptionInput = (
    <UploadEarthWorkWrapper>
      <LabelText>{l10n(Text.description)}</LabelText>
      <InputArea
        value={earthworkBasics?.description}
        onChange={e =>
          setEarthworkBasics({
            name: earthworkBasics?.name ?? '',
            description: e.target.value ?? '',
          })
        }
        placeholder={l10n(Text.descPlaceholder)}
      />
    </UploadEarthWorkWrapper>
  );

  const selectFinalEarthWork = (
    <UploadEarthWorkWrapper>
      <LabelText>{l10n(Text.finalEarthWork.label)}</LabelText>
      <FinalEarthWorkSelectAttachment />
    </UploadEarthWorkWrapper>
  );

  const selectInitialSurvey = (
    <UploadEarthWorkWrapper>
      <LabelText>{l10n(Text.intialSurvey.label)}</LabelText>
      <InitialSurveySelectAttachment />
    </UploadEarthWorkWrapper>
  );

  const selectRockLayer = (
    <UploadEarthWorkWrapper>
      <SelectRockLayer />
    </UploadEarthWorkWrapper>
  );

  const resetCredentials = () => {
    setInitialSurvey(undefined);
    setFinalEarthWork(undefined);
    setRockLayers(undefined);
    setSelectedZones([]);
    setEarthworkBasics(undefined);
    setCreateNewEarthwork(false);
  };

  const handleCreateNewEarthwork = () => {
    if (!finalEarthWork || !selectedZones?.length || !finalEarthWork?.dxfId) {
      return;
    }
    const zones = selectedZones.map(zoneId => {
      const zoneContent = contents.byId[zoneId];
      if (!zoneContent) {
        return undefined;
      }
      if (zoneContent.type !== T.ContentType.VOLUME) {
        return undefined;
      }
      const calculation = zoneContent.info.calculatedVolume.calculation as T.DesignCalculationInfo;
      const demType = calculation.dsmorDtmMode;

      return {
        name: zoneContent.title,
        wkt: generateWKT(zoneContent.info?.locations as Array<[number, number]>),
        demType: demType,
      };
    });

    const cuts = rockLayers?.length
      ? rockLayers.map(rockLayer => ({
          name: rockLayer?.name ?? '',
          designDxfId: rockLayer?.dxfId ?? '',
        }))
      : [];

    const payload: Partial<CreateEarthworkRequestParam> = {
      name: earthworkBasics?.name,
      description: earthworkBasics?.description ?? '',
      surfaces: {
        goal: {
          designDxfId: finalEarthWork?.dxfId,
        },
        initial: {
          designDxfId: initialSurvey?.dxfId ?? '',
        },
        cut: cuts,
        fill: {
          name: 'SOIL',
        },
      },
      zones: zones as ZoneInput[],
    };
    // REQ to backend
    void createEarthWork(payload, resetCredentials);
  };

  const createNowButton = (
    <CreateNowButton onClick={handleCreateNewEarthwork}>
      <CustomButton
        disabled={isDisabled || isCreating}
        data-ddm-track-action={T.TrackActions.EARTH_WORK}
        data-ddm-track-label={T.TrackLabels.BTN_CREATE_EARTHWROK}
      >
        {l10n(Text.createNow)}
      </CustomButton>
    </CreateNowButton>
  );

  const createContents = (
    <>
      {backButton}
      <HorizontalDivider />
      {nameInput}
      {descriptionInput}
      <HorizontalDivider />
      {selectFinalEarthWork}
      {selectInitialSurvey}
      {selectRockLayer}
      <HorizontalDivider />
      {createNowButton}
    </>
  );

  const regularContentsItem = (
    <>
      {selectEarthwork}
      {newEarthworkToggle}
    </>
  );
  useEffect(() => {
    void getEarthWorklists();
    dispatch(CloseContentPageMapPopup());
    dispatch(ChangeCurrentContentTypeFromAnnotationPicker({}));
    if (finalEarthWork?.dxfId) {
      setCreateNewEarthwork(true);
    }
  }, []);

  useEffect(() => {
    setIsDisabled(true);
    if (selectedZones) {
      if (
        selectedZones?.length > 0 &&
        finalEarthWork &&
        rockLayers?.length &&
        earthworkBasics?.name &&
        earthworkBasics?.name.length > 4
      ) {
        setIsDisabled(false);
      }
    }
  }, [selectedZones, finalEarthWork, rockLayers, earthworkBasics]);

  useEffect(() => {
    if (isError) {
      toastify({
        type: T.Toast.ERROR,
        content: {
          title: Text.error.title,
          description: Text.error.description,
        },
        option: defaultToastErrorOption,
      });
      setEarthworkFetchStatus(T.APIStatus.IDLE);
      setEarthworkAnalysisFetchStatus(T.APIStatus.IDLE);
      setCreateEarthworkStatus(T.APIStatus.IDLE);
    }
  }, [isError]);

  return <ContentsRoot>{createNewEarthwork ? createContents : regularContentsItem}</ContentsRoot>;
};
