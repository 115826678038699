import { useCallback, useState, SyntheticEvent, Dispatch, SetStateAction, useEffect } from 'react';

import { UseState } from '.';

type InputElementType = HTMLInputElement | HTMLTextAreaElement;
export type UseInput<T> = [
  T,
  (e: SyntheticEvent<InputElementType, Event>) => void,
  Dispatch<SetStateAction<string>>
];

export const useInput: (initialValue: string) => UseInput<typeof initialValue> = initialValue => {
  const [input, setInput]: UseState<typeof initialValue> = useState<typeof initialValue>('');

  const inputChange: (e: SyntheticEvent<InputElementType, Event>) => void = useCallback(
    (e: SyntheticEvent<InputElementType>) => {
      setInput(e.currentTarget.value);
    },
    []
  );

  useEffect(() => {
    setInput(initialValue);
  }, [initialValue]);

  return [input, inputChange, setInput];
};
