import { glMatrix } from 'gl-matrix';
import React, { FC, memo, useEffect } from 'react';
import { config } from '^/config';
import * as T from '^/types';

const useGlMatrix: () => void = () => {
  useEffect(() => {
    // Greatly improve performance of glMatrix ops.
    // https://github.com/toji/gl-matrix#learn-more
    glMatrix.setMatrixArrayType(Array);
  }, []);
};

export const Initializer: FC = memo(() => {
  useGlMatrix();

  if (config.region === T.Region.KSA) {
    document.title = 'Angelswing 🇸🇦';
  }

  return <></>;
});
