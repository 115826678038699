import {
  radarSvgIconToBase64,
  photoPointIcon,
  threeSixtyPointIcon,
} from '^/assets/icons/imageBase64';
import { OlCustomPropertyNames } from '^/components/ol/constants';
import { OlPhotoMarkerLayerMinimap } from '^/components/ol/OlPhotoMarkerLayer/OlPhotoMarkerLayerMinimap';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { PhotoAlbumObject } from '^/components/three/ThreeObjects';
import { PhotoThreeSixtyObject } from '^/components/three/ThreeObjects/Photo';
import { useThreeStore } from '^/components/three/ThreeStore';
import { UseState } from '^/hooks';
import { usePhotoAlbumIdQuery, usePhotoQuery } from '^/hooks/api/photos/usePhoto';
import { Feature, MapBrowserEvent } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as T from '^/types';

const RawOlVectorLayerMinimap = () => {
  const viewer3Js = useThreeStore(s => s.viewer);
  const [olLayerPhoto]: UseState<VectorLayer<Feature>> = useState(
    new VectorLayer({
      source: new VectorSource(),
      zIndex: 999999,
    })
  );
  const { selectedMedia } = useSelector((s: T.State) => s.Photos);

  const { albumId } = useParams();
  const { rawPhotos } = usePhotoQuery();
  const { photos: photosFromAlbum } = usePhotoAlbumIdQuery(Number(albumId));
  const selectedPhotos = (photosFromAlbum.length ? photosFromAlbum : rawPhotos).filter(photo =>
    selectedMedia.some(media => media.contentId === photo.id)
  );
  const { miniMap2D, photoCameraOrientation, selectedPhotoId, photoFov } = useThreePhotoAlbumStore(
    state => state.threeSixtyMiniMapDetails
  );

  const handleClick = (event: MapBrowserEvent<UIEvent>) => {
    const marker = miniMap2D?.forEachFeatureAtPixel(event.pixel, _feature => {
      const checkIfPhotoClicked: boolean = Boolean(
        _feature.getId()?.toString().includes('__photo')
      );
      if (checkIfPhotoClicked) {
        return _feature;
      } else {
        return undefined;
      }
    });
    if (marker) {
      const featureNumber = marker
        .getId()
        ?.toString()
        .replace(/[^0-9]/g, '');

      if (viewer3Js) {
        if (featureNumber) {
          const photoObject = viewer3Js.controllers.photoAlbumController?.getObjectByName(
            featureNumber.toString()
          ) as PhotoAlbumObject;
          if (photoObject instanceof PhotoThreeSixtyObject) {
            viewer3Js.controllers.photoAlbumController?.setThreeSixtyPhoto(photoObject);
          }
        }
      }
    }
  };

  useEffect(() => {
    olLayerPhoto.set('id', 'photo_vector');
  }, [olLayerPhoto]);

  useEffect(() => {
    miniMap2D?.on('singleclick', handleClick);
    return () => {
      miniMap2D?.un('singleclick', handleClick);
    };
  }, [miniMap2D]);

  useEffect(() => {
    miniMap2D?.addLayer(olLayerPhoto);
    return () => {
      miniMap2D?.removeLayer(olLayerPhoto);
    };
  }, [miniMap2D]);

  useEffect(() => {
    if (miniMap2D && selectedPhotoId) {
      const allSource = olLayerPhoto.getSource();
      if (!allSource) {
        return;
      }

      allSource.forEachFeature(feature => {
        const featureNumber = feature
          .getId()
          ?.toString()
          .replace(/[^0-9]/g, '');

        if (String(featureNumber) === selectedPhotoId) {
          const radarStyle = new Style({
            image: new Icon({
              src: radarSvgIconToBase64(photoFov),
              rotateWithView: true,
              rotation: -photoCameraOrientation.z,
            }),
          });
          const photoPointStyle = new Style({
            image: new Icon({
              src: photoPointIcon,
              rotateWithView: true,
            }),
          });
          feature.setStyle([radarStyle, photoPointStyle]);
        } else {
          const threeSixtyIconStyle = new Style({
            image: new Icon({
              src: threeSixtyPointIcon,
            }),
          });
          feature.setStyle(threeSixtyIconStyle);
        }
      });
    }
  }, [selectedPhotoId, photoCameraOrientation, photoFov]);

  if (miniMap2D) {
    return (
      <>
        {selectedPhotos
          .filter(photo => photo.latitude !== null && photo.longitude !== null)
          .map(photo => (
            <OlPhotoMarkerLayerMinimap
              key={`${OlCustomPropertyNames.PHOTO_FEATURE}_${photo.id}`}
              photo={photo}
              map={miniMap2D}
              photoLayer={olLayerPhoto}
            />
          ))}
      </>
    );
  }

  return null;
};

export const OlVectorLayerPhotoMinimap = memo(RawOlVectorLayerMinimap);
