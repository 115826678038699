import { Reducer } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { action as makeAction, props, union } from 'tsdux';
import { ofType } from 'tsdux-observable';

import { FinishProps } from '../Utils';
import { actionsForEpicReload } from './API';
import { ShowSignUpTutorialPopup } from './Pages';
import { ChangeLanguage } from './Pages/Common';
import { deleteSentryUser } from './vendor/sentry';
import * as T from '^/types';
import { deleteCDNCookie, setCDNCookie } from '^/utilities/cookie';

// Redux actions
export const SignIn = makeAction('ddm/auth/SIGN_IN');
export const CancelSignIn = makeAction('ddm/auth/CANCEL_SIGN_IN');
export const FinishSignIn = makeAction(
  'ddm/auth/FINISH_SIGN_IN',
  props<
    FinishProps & {
      readonly errorCode?: number;
    }
  >()
);

export const SignUp = makeAction('ddm/auth/SIGN_UP');
export const CancelSignUp = makeAction('ddm/auth/CANCEL_SIGN_UP');
export const ResetSignUpAPI = makeAction('ddm/auth/RESET_SIGN_UP_API');
export const FinishSignUp = makeAction('ddm/auth/FINISH_SIGN_UP', props<FinishProps>());

export const ChangeAuthedUser = makeAction(
  'ddm/auth/CHANGE_AUTHED_USER',
  props<{
    readonly authedUser?: T.AuthState['authedUser'];
  }>()
);

export const ChangeAuthedUserAfterSignUp = makeAction('ddm/auth/CHANGE_AUTHED_USER_AFTER_SIGN_UP');

export const ChangeTempUser = makeAction(
  'ddm/auth/CHANGE_TEMP_USER',
  props<{
    readonly tempUser?: T.AuthState['tempUser'];
  }>()
);
export const ChangeAutomaticSignIn = makeAction(
  'ddm/auth/CHANGE_AUTOMATIC_SIGN_IN',
  props<{
    readonly automaticSignIn: T.AuthState['automaticSignIn'];
  }>()
);

export const ChangePathBeforeAuth = makeAction(
  'ddm/auth/CHANGE_PATH_BEFORE_AUTH',
  props<{
    readonly path: T.AuthState['pathBeforeAuth'];
  }>()
);

const Action = union([
  SignIn,
  CancelSignIn,
  FinishSignIn,

  SignUp,
  CancelSignUp,
  ResetSignUpAPI,
  FinishSignUp,

  ChangeAuthedUser,

  ChangeAuthedUserAfterSignUp,

  ChangeTempUser,

  ChangeAutomaticSignIn,

  ChangePathBeforeAuth,

  // Out-duck actions
  // AddUserInfo,
  ChangeLanguage,
  ShowSignUpTutorialPopup,
]);
export type Action = typeof Action;

// Redux-Observable Epics
const changeAuthedUserEpic: Epic<Action, Action> = action$ =>
  action$.pipe(
    ofType(ChangeAuthedUser),
    mergeMap(action => {
      if (!action.authedUser) {
        deleteSentryUser();
      }
      return [];
    })
  );

export const epic: Epic<Action, Action> = combineEpics(
  changeAuthedUserEpic,
  actionsForEpicReload<Action>(CancelSignIn(), CancelSignUp())
);

// Redux reducer
/**
 * @desc
 * There is no deep-level modification for this reducer,
 * so introducing lens does not help restructuring this.
 */
const initialState: T.AuthState = {
  automaticSignIn: false,
  signInStatus: T.APIStatus.IDLE,
  signUpStatus: T.APIStatus.IDLE,
};
const reducer: Reducer<T.AuthState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case SignIn.type:
      return {
        ...state,
        signInStatus: T.APIStatus.PROGRESS,
        signInError: undefined,
        signInErrorCode: undefined,
      };
    case CancelSignIn.type:
      return {
        ...state,
        signInStatus: T.APIStatus.IDLE,
        signInError: undefined,
        signInErrorCode: undefined,
      };
    case FinishSignIn.type:
      return {
        ...state,
        signInStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        signInError: action.error,
        signInErrorCode: action.errorCode,
      };

    case SignUp.type:
      return {
        ...state,
        signUpStatus: T.APIStatus.PROGRESS,
        signUpError: undefined,
      };
    case CancelSignUp.type:
    case ResetSignUpAPI.type:
      return {
        ...state,
        signUpStatus: T.APIStatus.IDLE,
        signUpError: undefined,
      };
    case FinishSignUp.type:
      return {
        ...state,
        signUpStatus: action.error === undefined ? T.APIStatus.IDLE : T.APIStatus.ERROR,
        signUpError: action.error,
      };

    case ChangeAuthedUser.type: {
      if (action.authedUser) {
        setCDNCookie(action.authedUser);
      } else {
        deleteCDNCookie();
      }

      return {
        ...state,
        authedUser: action.authedUser,
      };
    }

    case ChangeAuthedUserAfterSignUp.type:
      return {
        ...state,
        authedUser: state.tempUser
          ? {
              ...state.tempUser,
              cloudFrontPolicy: '',
              cloudFrontSignature: '',
              cloudFrontKeyPairId: '',
            }
          : undefined,
        tempUser: undefined,
      };

    case ChangeTempUser.type:
      return {
        ...state,
        tempUser: action.tempUser,
      };

    case ChangeAutomaticSignIn.type:
      return {
        ...state,
        automaticSignIn: action.automaticSignIn,
      };

    case ChangePathBeforeAuth.type:
      return {
        ...state,
        pathBeforeAuth: action.path,
      };

    default:
      /**
       * @desc Because of using persist (Rehydrate), the state will be set
       * to be an empty object like this {}
       * To prevent the case, we add a check to return initialState instead
       */
      return Object.keys(state).length === 0 ? initialState : state;
  }
};
export default reducer;
