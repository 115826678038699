import { Language } from '^/types';

export default {
  tryBeta: {
    [Language.KO_KR]: '베타 3D 뷰어로 볼래요',
    [Language.EN_US]: 'Try Beta 3D Viewer',
  },
  tryLegacy: {
    [Language.KO_KR]: '기존 뷰어로 돌아갈래요',
    [Language.EN_US]: 'Use Original 3D Viewer',
  },
  maps: {
    [Language.KO_KR]: '지도',
    [Language.EN_US]: 'Maps',
  },
  engine: {
    [Language.KO_KR]: '3D 엔진',
    [Language.EN_US]: '3D Engine',
  },
};
