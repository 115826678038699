import { isSameDay } from 'date-fns';
import proj4 from 'proj4';
import { getEPSGfromProjectionLabel } from './coordinate-util';
import { dxfBordertoPoints, gcpsToGeoPoints } from './gcp-util';
import { isLonLat, typeGuardGroup } from '^/hooks';
import { TabToCategoryMapper } from '^/store/duck/Groups';
import * as T from '^/types';
import { getCenterBoundary, getMidPoint } from '^/utilities/map-util';
import { Runtime } from 'three-loader-3dtiles';
import { Vector3 } from 'three';
import { essContentsStore } from '^/store/essContentsStore';
import { Extent, extend } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import { Dispatch } from 'redux';
import { ChangeZoomToExtent } from '^/store/duck/Pages';
import { contentsStore } from '^/store/zustand/content/contentStore';
import { Viewer } from '^/components/three/ThreeInteraction/Viewer';
import { BlueprintObject } from '^/components/three/ThreeObjects/Drawing';
import { groupStore } from '^/store/zustand/groups/groupStore';
// Creating a small buffer around the extent
const buffer = 50;

export const isPinnable: (category: T.Content['category']) => boolean = category =>
  category === T.ContentCategory.MEASUREMENT;
export const isPersonalable: (category: T.Content['category']) => boolean = category =>
  T.IsPersonalableCategoryTypes.includes(category);
export const isContentPinned: (content: T.Content) => boolean = content =>
  content?.screenId === undefined;
export const isContentPersonal: (content: T.Content) => boolean = content =>
  Boolean(content?.isPersonal);

/**
 * @desc This function returns array of content titles about pinned, unpinned contents in specific date
 */
export const getMeasurementContentTitlesFromDate: (
  contents: T.ContentsState['contents']['byId'],
  date?: Date
) => string[] = (contents, date) =>
  Object.values(contents)
    .filter(
      (content: T.Content) =>
        T.MeasurementContentTypes.includes(content.type) ||
        T.IssueContentTypes.includes(content.type)
    )
    .filter(
      ({ appearAt }: T.MeasurementContent) =>
        appearAt === undefined || (date !== undefined && isSameDay(appearAt, date))
    )
    .map(({ title }) => title);

export const getContentTitlesByType: (
  contents: T.ContentsState['contents']['byId'],
  type: T.ESSContent['type']
) => string[] = (contents, type) =>
  Object.values(contents).reduce<string[]>((names, content) => {
    if (content.type === type) {
      names.push(content.title);
    }

    return names;
  }, []);

/**
 * Gets the first occurence of a group given the category.
 * This is used when no group id is selected.
 * If screen id is provided, it will go through ALL contents from category
 * if there is none, only then use the first pinned group.
 * When no screen id provided, it will use the first pinned group regardless.
 *
 * @param category All categories that have groups, i.e. measurements, overlay, ess
 * @param screenId
 * @returns The group id. If undefined is returned, this means this project has zero groups.
 */
const getFirstGroupByCategory: (
  category: T.ContentCategory,
  screenId?: T.Screen['id'],
  isESS?: boolean
) => T.GroupContent['id'] | undefined = (category, screenId, isESS) => {
  let personalGroup: T.GroupContent['id'] | undefined;
  let openGroup: T.GroupContent['id'] | undefined;
  const { byId, allIds: mainContentAllIds } = contentsStore.getState().contents;

  const allIds = isESS ? essContentsStore.getState().essContentIds : mainContentAllIds;
  const byIds = isESS ? essContentsStore.getState().essContents : byId;

  for (const id of allIds) {
    const content: T.Content | undefined = byIds[id];
    if (
      content === undefined ||
      content.type !== T.ContentType.GROUP ||
      content.category !== category ||
      content.groupId
    ) {
      continue;
    }

    if (content.screenId === undefined || content.screenId === screenId) {
      if (content.isPersonal) {
        personalGroup = content.id;
      } else {
        openGroup = content.id;
      }
    }
    if (openGroup && personalGroup) {
      break;
    }
  }

  return openGroup ?? personalGroup;
};

export const getCurrentGroupId: (
  state: T.State,
  tab: T.ContentPageTabType,
  lastSelectedScreenId?: T.Screen['id']
) => T.GroupContent['id'] = (state, tab, lastSelectedScreenId) => {
  const { essContents, selectedESSGroupIdByTab } = essContentsStore.getState();
  const selectedMainGroupIdByTab = groupStore.getState().selectedGroupIdByTab;
  const isESS = tab === T.ContentPageTabType.ESS;
  const selectedGroupIdByTab = isESS ? selectedESSGroupIdByTab : selectedMainGroupIdByTab;
  const selectedGroupId = selectedGroupIdByTab[tab];
  const byId = contentsStore.getState().contents.byId;

  const groupContent = typeGuardGroup(
    isESS ? essContents[selectedGroupId ?? NaN] : byId[selectedGroupId ?? NaN]
  );
  // There's a small chance that somehow the group does not exist.
  // If so, fallback to at least one group from the list below.
  if (groupContent !== undefined) {
    return groupContent.id;
  }
  let category = TabToCategoryMapper[tab];
  if (tab === T.ContentPageTabType.MAP) {
    /**
     * Terrain editing folder
     */
    category = T.ContentCategory.TERRAIN;
  }
  const firstGroupId = getFirstGroupByCategory(category, lastSelectedScreenId, isESS);
  if (firstGroupId === undefined) {
    throw new Error(
      'Unable to get the first group: at least one group should exist in the project to create a content.'
    );
  }

  return firstGroupId;
};

/**
 * Function to calculate the extent from multiple coordinates
 * @param coordinates - Array of coordinate pairs [longitude, latitude]
 * @returns The calculated extent [minX, minY, maxX, maxY]
 */
const calculateExtent = (coordinates: T.GeoPoint[]): Extent => {
  if (coordinates.length === 0) {
    throw new Error('Coordinates array is empty');
  }

  // Initialize the extent to the first transformed coordinate
  const firstTransformedCoord = fromLonLat(coordinates[0]) as Extent;
  let extent: Extent = [
    firstTransformedCoord[0],
    firstTransformedCoord[1],
    firstTransformedCoord[0],
    firstTransformedCoord[1],
  ];

  // Iterate through the coordinates and extend the extent
  coordinates.forEach(coord => {
    const transformedCoord = fromLonLat(coord);
    extend(extent, [
      transformedCoord[0],
      transformedCoord[1],
      transformedCoord[0],
      transformedCoord[1],
    ]);
  });

  // Apply buffer to the extent
  extent = [extent[0] - buffer, extent[1] - buffer, extent[2] + buffer, extent[3] + buffer];

  return extent;
};

/**
 * Function to create an extent from a single coordinate
 * @param coordinate - Coordinate pair [longitude, latitude]
 * @returns The calculated extent [minX, minY, maxX, maxY]
 */
export const createExtentFromCoordinate = (coordinate: T.GeoPoint): Extent => {
  const transformedCoord = fromLonLat(coordinate);
  return [
    transformedCoord[0] - buffer,
    transformedCoord[1] - buffer,
    transformedCoord[0] + buffer,
    transformedCoord[1] + buffer,
  ] as Extent;
};

/**
 * Get center point of any content.
 */
export const getCenterPointByContent: (
  content: T.Content,
  dispatch: Dispatch,
  projectProjection?: T.ProjectionEnum
) => T.GeoPoint | undefined = (content, dispatch, projectProjection) => {
  switch (content.type) {
    case T.ContentType.BLUEPRINT_PDF:
      return getCenterBoundary({
        minLon: content.info.geoPoint[0][0],
        maxLon: content.info.geoPoint[1][0],
        minLat: content.info.geoPoint[0][1],
        maxLat: content.info.geoPoint[1][1],
      });
    case T.ContentType.BLUEPRINT_DXF:
    case T.ContentType.BLUEPRINT_DWG:
      if (content.info.tms?.center) {
        return [content.info.tms.center[0], content.info.tms.center[1]];
      }
      if (content?.info.tms?.bounds) {
        return getCenterBoundary({
          minLon: content.info.tms.bounds[T.MapBounds.MIN_LON],
          maxLon: content.info.tms.bounds[T.MapBounds.MAX_LON],
          minLat: content.info.tms.bounds[T.MapBounds.MIN_LAT],
          maxLat: content.info.tms.bounds[T.MapBounds.MAX_LAT],
        });
      }

      return undefined;
    case T.ContentType.ESS_MODEL:
    case T.ContentType.ESS_MODEL_CUSTOM:
    case T.ContentType.ESS_TEXT:
    case T.ContentType.ISSUE_POINT:
    case T.ContentType.BIM:
      if (projectProjection !== undefined && content.info?.location?.length) {
        return isLonLat(content.info.location)
          ? content.info.location
          : proj4(getEPSGfromProjectionLabel(projectProjection), 'EPSG:4326').forward(
              content.info.location
            );
      }

      return undefined;

    case T.ContentType.MARKER: {
      if (projectProjection !== undefined && content.info?.location?.length) {
        const coordinate = isLonLat(content.info.location)
          ? content.info.location
          : proj4(getEPSGfromProjectionLabel(projectProjection), 'EPSG:4326').forward(
              content.info.location
            );
        const extent = createExtentFromCoordinate(coordinate);
        dispatch(
          ChangeZoomToExtent({
            coordinate: extent,
          })
        );
        return coordinate;
      }

      return undefined;
    }
    case T.ContentType.ESS_ARROW:
    case T.ContentType.ESS_POLYGON:
    case T.ContentType.ESS_POLYLINE:
    case T.ContentType.LENGTH:
    case T.ContentType.AREA:
    case T.ContentType.VOLUME: {
      const coordinates = getMidPoint(content.info.locations);
      // Calculate the extent
      const extent = calculateExtent(content.info.locations);
      dispatch(
        ChangeZoomToExtent({
          coordinate: extent,
        })
      );
      return coordinates;
    }

    case T.ContentType.DESIGN_DXF: {
      const geoPoints = dxfBordertoPoints(content.info.designBorder);
      if (geoPoints !== undefined) {
        return getMidPoint(geoPoints);
      }
      return undefined;
    }
    case T.ContentType.GCP_GROUP: {
      const geoPoints: T.GeoPoint[] | undefined = gcpsToGeoPoints(
        content.info.gcps,
        content.info.crs
      );
      if (geoPoints !== undefined) {
        return getMidPoint(geoPoints);
      }

      return undefined;
    }
    default:
      return undefined;
  }
};

export const getCenterPointByContentInMesh: (
  content: T.Content,
  viewer: Viewer,
  runtime: Runtime,
  projectProjection?: T.ProjectionEnum
) => Vector3 | undefined = (content, viewer, runtime, projectProjection) => {
  let position: T.GeoPoint = [];
  switch (content.type) {
    case T.ContentType.BLUEPRINT_PDF:
      position = getCenterBoundary({
        minLon: content.info.geoPoint[0][0],
        maxLon: content.info.geoPoint[1][0],
        minLat: content.info.geoPoint[0][1],
        maxLat: content.info.geoPoint[1][1],
      });
      break;
    case T.ContentType.BLUEPRINT_DXF:
      if (content.info.tms?.center) {
        position = [content.info.tms.center[1], content.info.tms.center[0]];
      } else if (content?.info.tms?.bounds) {
        position = getCenterBoundary({
          minLon: content.info.tms.bounds[T.MapBounds.MIN_LON],
          maxLon: content.info.tms.bounds[T.MapBounds.MAX_LON],
          minLat: content.info.tms.bounds[T.MapBounds.MIN_LAT],
          maxLat: content.info.tms.bounds[T.MapBounds.MAX_LAT],
        });
      }

      return runtime.getPositionFromLatLongHeight({
        lat: position[0],
        long: position[1],
        height: 0,
      });
    case T.ContentType.BLUEPRINT_DWG:
      if (!viewer) {
        return undefined;
      }
      // eslint-disable-next-line no-case-declarations
      const blueprint = viewer.entities.drawingEntity?.getObjectByName(`blueprint-${content.id}`);
      if (!blueprint) {
        return undefined;
      }
      if (blueprint instanceof BlueprintObject) {
        return blueprint.dummy?.getWorldPosition(new Vector3(0, 0, 0));
      }
      return undefined;
    case T.ContentType.ESS_MODEL:
    case T.ContentType.ESS_MODEL_CUSTOM:
      return runtime.getPositionFromLatLongHeight({
        lat: content.info.location[1],
        long: content.info.location[0],
        height: content.info.location[2] ?? 0,
      });
    case T.ContentType.ESS_TEXT:
    case T.ContentType.MARKER:
    case T.ContentType.BIM:
      if (projectProjection !== undefined && content.info?.location?.length) {
        position = isLonLat(content.info.location)
          ? content.info.location
          : proj4(getEPSGfromProjectionLabel(projectProjection), 'EPSG:4326').forward(
              content.info.location
            );
      }
      break;
    case T.ContentType.ESS_ARROW:
    case T.ContentType.ESS_POLYGON:
    case T.ContentType.ESS_POLYLINE:
    case T.ContentType.LENGTH:
    case T.ContentType.AREA:
    case T.ContentType.VOLUME:
      position = getMidPoint(content.info.locations);
      break;
    case T.ContentType.GCP_GROUP: {
      const geoPoints: T.GeoPoint[] | undefined = gcpsToGeoPoints(
        content.info.gcps,
        content.info.crs
      );
      if (geoPoints !== undefined) {
        position = getMidPoint(geoPoints);
      }

      return undefined;
    }
    default:
      return undefined;
  }

  return runtime.getPositionFromLatLongHeight({
    lat: position[1],
    long: position[0],
    height: 0,
  });
};

/**
 * Check if The value exists in the T.Content enum
 */
export const checkContentType = (value: string) =>
  typeof value === 'string' && Object.values(T.ContentType).includes(value as T.ContentType);

/**
 * Check if The category exists in the T.Content enum
 */
export const checkCategoryType = (value: string) =>
  typeof value === 'string' &&
  Object.values(T.ContentCategory).includes(value as T.ContentCategory);
