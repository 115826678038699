import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as T from '^/types';
import { getDDMSubdomain } from '^/store/duck/router';
import { GetPlanConfig } from '^/store/duck/PlanConfig';

export function usePlanConfig() {
  const isPlanConfigLoaded: boolean = useSelector((state: T.State) =>
    Boolean(state.PlanConfig.config)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isPlanConfigLoaded) {
      const slug = getDDMSubdomain();
      dispatch(GetPlanConfig({ slug }));
    }
  }, [isPlanConfigLoaded]);
}
