import { config } from '^/config';
import { ESSPlanConfig } from '^/store/duck/PlanConfig';
import { getDDMSubdomain } from '^/store/duck/router';
import * as T from '^/types';

const GA_TRACK_PROD_ID = 'G-P1XZT31558';
const GA_TRACK_DEV_ID = 'G-XV71PLLXWT';
const GA_TRACK_ESS_PROD_ID = 'UA-109998374-5';
const GA_TRACK_ESS_DEV_ID = 'UA-109998374-6';

export const loadGA: (global: any, measurementId: string, ...args: any[]) => void = (
  global,
  measurementId
) => {
  const newScript: HTMLScriptElement = document.createElement('script');
  newScript.async = true;
  newScript.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;

  const oldScript: HTMLScriptElement = document.getElementsByTagName('script')[0];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  oldScript.parentNode!.insertBefore(newScript, oldScript);
};

export const activateGA: boolean = config.isBrowser && !config.isNotProduction;

export const initializeGA: () => void = () => {
  if (activateGA) {
    loadGA(window, getTrackId());
  }
};

export const getTrackId: () => string = () => {
  const isDomainESS = getDDMSubdomain() === ESSPlanConfig.slug;
  if (config.buildLevel === T.BuildLevel.PRODUCTION) {
    return isDomainESS ? GA_TRACK_ESS_PROD_ID : GA_TRACK_PROD_ID;
  }

  return isDomainESS ? GA_TRACK_ESS_DEV_ID : GA_TRACK_DEV_ID;
};
