const easeInOut = (t: number) =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

export const smoothScrollTo = (
  divEle: HTMLDivElement,
  targetScrollTop: number,
  duration: number
) => {
  const startScrollTop = divEle.scrollTop;
  const startTime = performance.now();

  const updateScroll = () => {
    const elapsed = performance.now() - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const easedProgress = easeInOut(progress);
    const currentScrollTop = divEle.scrollTop;
    const newScrollTop = startScrollTop + (targetScrollTop - startScrollTop) * easedProgress;
    divEle.scrollTop = newScrollTop;
    if (currentScrollTop !== targetScrollTop && progress < 1) {
      requestAnimationFrame(updateScroll);
    }
  };
  requestAnimationFrame(updateScroll);
};
