/* eslint-disable max-lines */
import React, { FC, FormEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';
import _ from 'lodash-es';

import Text from '../ContentsListBlueprintItem/text';
import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import SingleSlider from '^/components/atoms/SingleSlider';
import WarningBox from '^/components/atoms/WarningBox';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { DISABLED_CONTENT_OPACITY, FontFamily } from '^/constants/styles';
import { UseL10n, useL10n, useProjectCoordinateSystem } from '^/hooks';
import { PatchContent, contentsSelector } from '^/store/duck/Contents';

import * as T from '^/types';
import { Cartesian3 } from 'cesium';
import { getEPSGfromProjectionLabel } from '^/utilities/coordinate-util';
import proj4 from 'proj4';
import { UNIT_SYMBOL, VALUES_PER_METER, determineUnitType } from '^/utilities/imperial-unit';
import { ELEVATION_FIX_FORMAT } from '^/constants/defaultContent';
import { convertToFixedNumber } from '^/utilities/react-util';
import { ChangeContentsSidebarTab } from '^/store/duck/Pages';
import { contentsStore, useContentsStore } from '^/store/zustand/content/contentStore';

interface DisabledProp {
  isDisabled: boolean;
}
interface CustomStyleProps {
  readonly customStyle?: CSSObject;
}

type KindProps = 'position.x' | 'position.y' | 'position.z' | 'heading' | 'pitch' | 'roll';
interface BIMProps {
  title: string;
  fields: Array<{
    kind: KindProps;
    name: string;
    value?: number;
    type: string;
    min?: number;
    max?: number;
    step: number;
    unit: string;
    hasUnit?: boolean;
  }>;
}

const Opacity = styled.div({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  margin: '13px 0 ',
});

const OpacityText = styled.div<DisabledProp>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '48.5px',

  marginBottom: '13px',

  color: 'var(--color-theme-primary)',
  fontFamily: FontFamily.ROBOTO,
  fontSize: '15px',
  fontWeight: 500,
}));

const CustomModelInputContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  // gridColumnGap: '5px',
  border: '1px solid #D9D9D9',
  padding: '12px',
  borderRadius: '3px',
  gridRowGap: '8px',
  marginBottom: '13px',
});

const Wrapper = styled.div<CustomStyleProps>(
  {
    positive: 'relative',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const Label = styled.label<CustomStyleProps>(
  {
    display: 'block',
    minWidth: '68px',

    fontWeight: 400,
    color: dsPalette.typePrimary.toString(),
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const CMInputStyle: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '12px',
};

const CMLabelStyle: CSSObject = {
  fontSize: '12px',
  lineHeight: '15px',
  paddingRight: '4px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 0,
};
const CMInputContainer = styled.div({
  position: 'relative',
});

const BIMHighLightText = styled.span({
  fontSize: '12px',
  fontWeight: 600,
  color: '#CBCBCB',
});

const MINMAXWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const MINMAXText = styled(BIMHighLightText)({
  fontSize: '10px',
});

const CMInput = styled.input<{ error: boolean; width?: string; hasUnit?: boolean }>(
  ({ error, width, hasUnit }) => ({
    boxSizing: 'border-box',
    textAlign: 'right',

    width: width || '',
    height: '30px',
    padding: `0 ${hasUnit ? '33px' : '16px'} 0 10px`,

    border: '1px solid #D9D9D9',
    borderRadius: '3px',

    color: '#7E7E7E',

    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '400',

    '::placeholder': {
      color: '#7E7E7E',
    },

    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'inner-spin-button',
      width: '5px',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      margin: 0,
    },

    borderColor: (error ? palette.error : palette.border).toString(),
  })
);

const UnitText = styled.span({
  position: 'absolute',
  right: '18px',
  top: '5px',
  color: '#7E7E7E',
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: '400',
});

const MAX_OPACITY: number = 100;
const MIN_RANGE: number = -180;
const MAX_RANGE: number = 180;
const DELAY_TIMER: number = 500;
const TO_METER_VALUE: number = 0.3048;

export interface Props {
  readonly content: T.BimContent;
  readonly isIn3DMesh: boolean;
}

export const RawCesiumContentsListBimtItem: FC<Props> = ({ content, isIn3DMesh }) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch: Dispatch = useDispatch();

  const contentId = content.id;

  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  if (!projectUnit && !projectId) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }

  const project: T.Project | undefined = projectId ? projectById[projectId] : undefined;
  const unitType: T.ValidUnitType = project
    ? determineUnitType(project.unit)
    : determineUnitType(projectUnit);

  const opacity: number =
    content.config?.opacity !== undefined ? content.config.opacity : MAX_OPACITY;

  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();
  const isLonLat: boolean = projectProjection === T.ProjectionEnum.WGS84_EPSG_4326_LL;

  const isProcessingOrFailed: boolean = contentsSelector.isProcessingOrFailedByContent(content);
  const isFailed: boolean = content.status === T.ContentProcessingStatus.FAILED;
  const [contentState, setContentState] = useState<T.BimContent>({
    ...content,
    info: {
      ...content.info,
      bimMeta: {
        dimensions: content.info?.bimMeta?.dimensions || [0, 0, 0],
        heading: content.info?.bimMeta?.heading || 0,
        pitch: content.info?.bimMeta?.pitch || 0,
        roll: content.info?.bimMeta?.roll || 0,
        scale: content.info?.bimMeta?.scale || 1,
        position: content.info?.bimMeta?.position || new Cartesian3(0, 0, 0),
      },
    },
  });

  useEffect(() => {
    setContentState({
      ...content,
      info: {
        ...content.info,
        bimMeta: {
          dimensions: content.info?.bimMeta?.dimensions || [0, 0, 0],
          heading: content.info?.bimMeta?.heading || 0,
          pitch: content.info?.bimMeta?.pitch || 0,
          roll: content.info?.bimMeta?.roll || 0,
          scale: content.info?.bimMeta?.scale || 1,
          position: content.info?.bimMeta?.position || new Cartesian3(0, 0, 0),
        },
      },
    });
  }, [content.info.bimMeta]);

  const handleOpacityChange: (opacity: number) => void = opacityValue => {
    updateContentConfig(contentId, {
      ...content.config,
      type: content.type,
      opacity: opacityValue,
    });
  };

  const handleRotationChange: (kind: KindProps, value: number) => void = (kind, value) => {
    const newContentState = {
      ...contentState,
      info: {
        ...contentState.info,
        bimMeta: {
          ...contentState.info.bimMeta,
          [kind]: Number(value),
        },
      },
    };
    setContentState(newContentState);
    contentsStore.getState().updateContentOpacity(contentId, {
      ...contentState.info,
      bimMeta: {
        ...contentState.info.bimMeta,
        [kind]: Number(value),
      },
    });
  };

  const handleOpacityMouseUp: () => void = () => {
    dispatch(
      PatchContent({
        content: {
          id: contentId,
          config: {
            opacity: content.config?.opacity,
          },
        },
      })
    );
  };

  const opacitySetting: ReactNode = isFailed ? null : (
    <Opacity>
      <OpacityText isDisabled={isProcessingOrFailed}>{opacity.toFixed(0)}%</OpacityText>
      <SingleSlider
        minValue={0}
        maxValue={MAX_OPACITY}
        value={opacity}
        isDisabled={isProcessingOrFailed}
        onChange={handleOpacityChange}
        onMouseUp={handleOpacityMouseUp}
      />
    </Opacity>
  );

  const getLatLongFromCartesian = (x: number, y: number, z: number) => {
    const [longitude, latitude, height] = proj4(
      getEPSGfromProjectionLabel(projectProjection),
      'EPSG:4326'
    ).forward([x, y, z]);
    return [longitude, latitude, height];
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>, kind: KindProps) => {
    if (!contentState || !kind) {
      return;
    }

    const { value } = event.currentTarget;

    let newContentState = contentState;
    const { info } = newContentState;

    if (kind === 'position.x' || kind === 'position.y' || kind === 'position.z') {
      const { position } = info.bimMeta;
      const location = info.location;

      if (kind === 'position.x') {
        position.x = convertToFixedNumber(value);
        const [longitude] = getLatLongFromCartesian(position.x, position.y, position.z);
        location[0] = longitude;
      } else if (kind === 'position.y') {
        position.y = convertToFixedNumber(value);
        const [, latitude] = getLatLongFromCartesian(position.x, position.y, position.z);
        location[1] = latitude;
      } else if (kind === 'position.z') {
        const convertedValue =
          unitType === T.UnitType.IMPERIAL
            ? convertToFixedNumber(value) * TO_METER_VALUE
            : convertToFixedNumber(value);

        position.z = convertedValue;
        const [, , height] = getLatLongFromCartesian(position.x, position.y, position.z);
        location[2] = height;
      }
    } else {
      newContentState = {
        ...contentState,
        info: {
          ...info,
          bimMeta: {
            ...info.bimMeta,
            [kind]: Number(value),
          },
        },
      };
    }

    setContentState(newContentState);
    debouncedFunction(newContentState);
  };

  // Debounced function
  const debouncedFunction = _.debounce(newValue => {
    handleUpdateContent(newValue);
  }, DELAY_TIMER);

  const handleUpdateContent = (newValue?: T.BimContent) => {
    dispatch(PatchContent({ content: newValue ? newValue : contentState }));
  };

  const bimSettings: ReactNode = useMemo(() => {
    if (!contentState.info?.location?.length || !contentState.info?.bimMeta?.position) {
      return;
    }

    const bimField: BIMProps[] = [
      {
        title: 'Position',
        fields: [
          {
            name: `X (${isLonLat ? 'Lon' : 'Easting'})`,
            value: convertToFixedNumber(contentState?.info.bimMeta.position.x),
            kind: 'position.x',
            type: 'number',
            step: 1,
            unit: '',
          },
          {
            name: `Y (${isLonLat ? 'Lat' : 'Northing'})`,
            value: convertToFixedNumber(contentState?.info.bimMeta.position.y),
            kind: 'position.y',
            type: 'number',
            step: 1,
            unit: '',
          },
          {
            name: 'Z (Altitude)',
            value: convertToFixedNumber(
              (contentState.info.bimMeta.position.z * VALUES_PER_METER[unitType]).toFixed(
                ELEVATION_FIX_FORMAT
              )
            ),
            kind: 'position.z',
            type: 'number',
            step: 1,
            unit: UNIT_SYMBOL[unitType],
            hasUnit: true,
          },
        ],
      },
      {
        title: 'Rotation',
        fields: [
          {
            name: 'Heading',
            value: convertToFixedNumber(contentState?.info.bimMeta.heading),
            type: 'range',
            kind: 'heading',
            min: MIN_RANGE,
            max: MAX_RANGE,
            step: 1,
            unit: '°',
            hasUnit: true,
          },
          {
            name: 'Pitch',
            value: convertToFixedNumber(contentState?.info.bimMeta.pitch),
            type: 'range',
            kind: 'pitch',
            min: MIN_RANGE,
            max: MAX_RANGE,
            step: 1,
            unit: '°',
            hasUnit: true,
          },
          {
            name: 'Roll',
            value: convertToFixedNumber(contentState?.info.bimMeta.roll),
            type: 'range',
            kind: 'roll',
            min: MIN_RANGE,
            max: MAX_RANGE,
            step: 1,
            unit: '°',
            hasUnit: true,
          },
        ],
      },
    ];

    return contentState.info?.location?.length
      ? bimField.map((data, index) => (
          <CustomModelInputContainer key={index}>
            <BIMHighLightText>{data.title}</BIMHighLightText>
            {data.fields.map((field, idx) => (
              <div key={idx}>
                <Wrapper customStyle={CMInputStyle}>
                  <Label customStyle={CMLabelStyle}>{field.name}</Label>
                  <CMInputContainer>
                    <CMInput
                      autoFocus={true}
                      key={field.value}
                      width={field.type === 'range' ? '69px' : '156px'}
                      error={false}
                      hasUnit={field?.hasUnit || false}
                      defaultValue={field.value}
                      type="number"
                      step={field.step}
                      min={field?.min}
                      max={field?.max}
                      onChange={event => handleInputChange(event, field.kind)}
                    />
                    <UnitText>{field.unit}</UnitText>
                  </CMInputContainer>
                </Wrapper>
                {field.type === 'range' ? (
                  <>
                    <div style={{ padding: '9px 0 5px' }}>
                      <SingleSlider
                        minValue={field.min ?? 0}
                        maxValue={field.max ?? 0}
                        value={field.value ?? 0}
                        isDisabled={isProcessingOrFailed}
                        onChange={value => handleRotationChange(field.kind, value)}
                        onMouseUp={handleUpdateContent}
                      />
                    </div>
                    <MINMAXWrapper>
                      <MINMAXText>{field.min}°</MINMAXText>
                      <MINMAXText>{field.max}°</MINMAXText>
                    </MINMAXWrapper>
                  </>
                ) : null}
              </div>
            ))}
          </CustomModelInputContainer>
        ))
      : null;
  }, [contentState.info]);

  const firstBalloonTitle: string = (() => l10n(Text.firstBalloonTitle))();

  const handleSwitchChange = () => {
    dispatch(ChangeContentsSidebarTab({ sidebarTab: T.ContentPageTabType.MAP }));
  };

  return (
    <ContentsListItem content={content} firstBalloonTitle={firstBalloonTitle}>
      {opacitySetting}
      {!isIn3DMesh && (
        <WarningBox
          description={l10n(Text.warningDescription)}
          actionText={l10n(Text.switchText)}
          onClick={handleSwitchChange}
        />
      )}
      {bimSettings}
    </ContentsListItem>
  );
};
