import { AuthHeader, jsonContentHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { FinishDeleteContent } from '^/store/duck/Contents';
import {
  ChangeSelectedGroupId,
  removeContentFromTree,
  RemoveGroupChildren,
} from '^/store/duck/Groups';
import { ChangeEditingContent } from '^/store/duck/Pages';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { contentsStore } from '^/store/zustand/content/contentStore';
import { getSingleContentId } from '^/utilities/state-util';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { isIssue } from './contents';

export function useContentDelete() {
  const dispatch = useDispatch();

  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const Pages = useSelector((s: T.State) => s.Pages);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);

  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);

  const headers = {
    ...authHeader,
    ...jsonContentHeader,
  };

  async function deleteContent(contentId: T.Content['id']) {
    const issueUrl: string = makeV2APIURL('issues', contentId);
    const URL: string = makeV2APIURL('contents', contentId);

    if (authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }
    const byId = contentsStore.getState().contents.byId;

    const contentType: T.ContentType = byId[contentId].type;
    const isMapOrDSM: boolean =
      T.ContentType.MAP === contentType || T.ContentType.DSM === contentType;
    const isThisIssueContent = isIssue(byId[contentId]);

    const additionalActions: Action[] = [];

    /**
     * @desc when you delete Map or DSM, the 3d Ortho content should be delete.
     * @tip Not working when you have more than two DSMs or two Maps
     * in date which is appearAt of deleting content.
     */
    if (isMapOrDSM) {
      const threeDOrthoId: T.ThreeDOrthoContent['id'] | undefined = getSingleContentId(
        Pages,
        ProjectConfigPerUser,
        T.ContentType.THREE_D_ORTHO
      );

      if (threeDOrthoId !== undefined) {
        contentsStore.getState().removeContent(threeDOrthoId);
      }
    } else if (contentType === T.ContentType.GROUP) {
      additionalActions.push(RemoveGroupChildren({ id: contentId }));
      additionalActions.push(ChangeSelectedGroupId({ tab: sidebarTab }));
    }

    await http.delete(isThisIssueContent ? issueUrl : URL, { headers });

    dispatch(ChangeEditingContent({ contentId: undefined }));
    // dispatch(RemoveContentFromTree({ content: byId[contentId] }));
    removeContentFromTree(byId[contentId]);
    contentsStore.getState().removeContent(contentId);
    additionalActions.forEach(action => dispatch(action));
    dispatch(FinishDeleteContent({ contentId }));

    return;
  }

  return { deleteContent };
}
