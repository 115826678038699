import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import * as T from '^/types';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import {
  ChangeContents,
  FinishGetInitialContents,
  FinishGetIssues,
  TurnOn2DOrthoOnToday,
  TurnOn3DOnToday,
} from '^/store/duck/Contents';
import { ChangeFirstVisitStatus, PersistFromPathname } from '^/store/duck/Pages';
import { useIssueStore } from '^/store/issue';
import { contentsStore } from '^/store/zustand/content/contentStore';
import { isErrorIgnorable } from '^/utilities/http-response';
import {
  getRequestErrorType,
  makeAuthHeader,
  makeV2APIURL,
  makeVersionHeader,
} from '^/store/duck/API';
import { fetchProject, fetchIssueCategories, fetchIssueStatuses, fetchContents } from './services';
import { queryClient } from '^/store/react-query';
import { APIToProjectWithConfig, ChangeProjects, FinishGetProject } from '^/store/duck/Projects';
import { ChangeProjectConfig } from '^/store/duck/ProjectConfig';
import { GetScreens } from '^/store/duck/Screens';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { AjaxError } from 'rxjs/ajax';

export const useContents = () => {
  const dispatch = useDispatch();
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const params = useParams();
  const authHeader = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();

  async function getInitialContents(projectId: number) {
    contentsStore.setState({ getInitialContentsStatus: T.APIStatus.PROGRESS });
    contentsStore.setState({ getLonLatOn2D3DToggleStatus: T.APIStatus.PROGRESS });
    if (!authHeader) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    try {
      const isInESSTab = params.tab === T.ContentPageTabType.ESS;
      const isInViewpointTab = params.tab === T.ContentPageTabType.VIEWPOINT_CAPTURE;

      let project: T.APIProject | undefined;

      try {
        project = await fetchProject(projectId, authHeader, versionHeader);
      } catch {
        dispatch(
          FinishGetProject({
            error: getRequestErrorType({ status: 403 } as AjaxError),
          })
        );
      }

      if (!project) {
        return;
      }

      const projectWithConfig = APIToProjectWithConfig(project);
      const contentURL = makeV2APIURL('projects', projectId, 'contents');

      const contents = await queryClient.fetchQuery({
        queryKey: ['projects', projectId, 'contents'],
        queryFn: async () => fetchContents(contentURL, authHeader, versionHeader),
        staleTime: Infinity,
      });

      dispatch(ChangeProjects({ projects: [_.omit(projectWithConfig, ['config'])] }));
      contentsStore.getState().changeContents(contents);
      dispatch(ChangeProjectConfig({ config: projectWithConfig.config }));
      dispatch(GetScreens({ projectId }));
      dispatch(ChangeContents({ contents }));
      dispatch(FinishGetProject({}));

      // Fire and forget
      void fetchIssueMetadata(projectId);

      dispatch(FinishGetInitialContents({}));
      dispatch(ChangeFirstVisitStatus({ firstVisit: true }));
      dispatch(FinishGetIssues({}));
      dispatch(PersistFromPathname());
      dispatch(isInESSTab || isInViewpointTab ? TurnOn3DOnToday() : TurnOn2DOrthoOnToday());
    } catch (error) {
      if (!isErrorIgnorable(error.status)) {
        Sentry.captureException(error);
      }
      dispatch(FinishGetInitialContents({ error: getRequestErrorType(error) }));
      dispatch(FinishGetIssues({ error: getRequestErrorType(error) }));
    }
    contentsStore.setState({ getInitialContentsStatus: T.APIStatus.SUCCESS });
  }

  async function fetchIssueMetadata(projectId: number) {
    try {
      const [categories, statuses] = await Promise.all([
        fetchIssueCategories(projectId, authHeader!, versionHeader),
        fetchIssueStatuses(projectId, authHeader!, versionHeader),
      ]);
      useIssueStore.getState().setIssueCategoryOptions(categories);
      useIssueStore.getState().setIssueStatusOptions(statuses);
    } catch (e) {
      console.error('Failed to fetch issue metadata:', e);
    }
  }

  return {
    getInitialContents,
  };
};

// This is the one is in use
export function useContentQuery() {
  //   const store = useStore();
  const params = useParams();
  const projectId = Number(params.id);
  const contentURL = makeV2APIURL('projects', projectId, 'contents');
  // const dispatch = useDispatch();
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const authHeader = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();

  return useQuery({
    queryKey: ['projects', projectId, 'contents'],
    queryFn: async () => fetchContents(contentURL, authHeader!, versionHeader),
    enabled: Boolean(projectId) && Boolean(authHeader),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}
