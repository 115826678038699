import Color from 'color';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import AreaPinnedSvg from '^/assets/icons/contents-list/area-pinned.svg';
import AreaSvg from '^/assets/icons/contents-list/area.svg';
import BIMContentSVG from '^/assets/icons/contents-list/bim-content.svg';
import DesignDxfSvg from '^/assets/icons/contents-list/design-dxf.svg';
import DisabledDesignDxfSvg from '^/assets/icons/contents-list/disabled-design-dxf-overlay.svg';
import DisabledDwgSvg from '^/assets/icons/contents-list/disabled-dwg-overlay.svg';
import DisabledDxfSvg from '^/assets/icons/contents-list/disabled-dxf-overlay.svg';
import DisabledPdfSvg from '^/assets/icons/contents-list/disabled-pdf-overlay.svg';
import DSMSvg from '^/assets/icons/contents-list/elevation-map.svg';
import DwgSvg from '^/assets/icons/contents-list/dwg.svg';
import DxfSvg from '^/assets/icons/contents-list/dxf.svg';
import ESSArrowToolIcon from '^/assets/icons/contents-list/ess-arrow.svg';
import ESSLineTextToolIcon from '^/assets/icons/contents-list/ess-line-text.svg';
import ESSModelWithBgIcon from '^/assets/icons/contents-list/ess-model-with-bg.svg';
import ESSPolygonToolIcon from '^/assets/icons/contents-list/ess-polygon.svg';
import ESSPolylineToolIcon from '^/assets/icons/contents-list/ess-polyline.svg';
import ESSTextToolIcon from '^/assets/icons/contents-list/ess-text.svg';
import FlightPlanSvg from '^/assets/icons/contents-list/flight-plan.svg';
import FlightScheduleSvg from '^/assets/icons/contents-list/flight-schedule.svg';
import FlattenMapSvg from '^/assets/icons/annotation/flatten-map.svg';
import GCPSvg from '^/assets/icons/contents-list/gcp.svg';
import GroupSvg from '^/assets/icons/contents-list/group.svg';
import IssueSvg from '^/assets/icons/contents-list/issue.svg';
import LengthPinnedSvg from '^/assets/icons/contents-list/length-pinned.svg';
import LengthSvg from '^/assets/icons/contents-list/length.svg';
import MapSvg from '^/assets/icons/contents-list/map.svg';
import MarkerPinnedSvg from '^/assets/icons/contents-list/marker-pinned.svg';
import MarkerSvg from '^/assets/icons/contents-list/marker.svg';
import PdfSvg from '^/assets/icons/contents-list/pdf.svg';
import PointCloudSvg from '^/assets/icons/contents-list/point-clouds.svg';
import ThreeDMeshSvg from '^/assets/icons/contents-list/three-d-mesh.svg';
import ThreeDOrthoSvg from '^/assets/icons/contents-list/three-d-ortho.svg';
import ThreeHeightPinnedSvg from '^/assets/icons/contents-list/three-height-pinned.svg';
import VolumeDTMSvg from '^/assets/icons/contents-list/volume-dtm.svg';
import VolumePinnedDTMSvg from '^/assets/icons/contents-list/volume-pinned-dtm.svg';
import VolumePinnedSvg from '^/assets/icons/contents-list/volume-pinned.svg';
import VolumeSvg from '^/assets/icons/contents-list/volume.svg';

import {
  UseShouldContentDisabled,
  useShouldContentDisabled,
} from '^/hooks/useShouldContentDisabled';
import * as T from '^/types';

const Root = styled.div<{ contentColor?: string; borderColor?: string }>(
  ({ contentColor, borderColor }) =>
    contentColor === undefined
      ? {}
      : {
          ' svg path:not(.no-fill)': {
            fill: contentColor,
          },
          ' svg circle': {
            fill: new Color(contentColor).alpha(0.25).toString(),
            stroke: new Color(contentColor).alpha(0.25).toString(),
          },
          ' svg ellipse': {
            stroke: borderColor ?? 'rgb(95, 95, 95)',
          },
        }
);

const SVGWrapper = styled.div<{ width: string; height: string }>(({ width, height }) => ({
  width,
  height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

/**
 * @todo change the icon
 * design_dxf, three_d_ortho, dsm
 */
const getTabToIcon: (
  contentType: T.ContentType,
  isPinned: boolean,
  isDTMMode: boolean
) => ReactNode = (contentType, isPinned, isDTMMode) => {
  switch (contentType) {
    case T.ContentType.MAP:
      return (
        <SVGWrapper width="18px" height="18px">
          <MapSvg />
        </SVGWrapper>
      );
    case T.ContentType.DSM:
      return (
        <SVGWrapper width="18px" height="18px">
          <DSMSvg />
        </SVGWrapper>
      );
    case T.ContentType.DTM:
      return (
        <SVGWrapper width="18px" height="18px">
          <DSMSvg />
        </SVGWrapper>
      );
    case T.ContentType.THREE_D_ORTHO:
      return (
        <SVGWrapper width="18px" height="18px">
          <ThreeDOrthoSvg />
        </SVGWrapper>
      );
    case T.ContentType.POINTCLOUD:
      return (
        <SVGWrapper width="18px" height="18px">
          <PointCloudSvg />
        </SVGWrapper>
      );
    case T.ContentType.THREE_D_MESH:
      return (
        <SVGWrapper width="18px" height="18px">
          <ThreeDMeshSvg />
        </SVGWrapper>
      );
    case T.ContentType.GCP_GROUP:
      return (
        <SVGWrapper width="18px" height="18px">
          <GCPSvg />
        </SVGWrapper>
      );
    case T.ContentType.BLUEPRINT_PDF:
      return (
        <SVGWrapper width="45px" height="15px">
          <PdfSvg />
        </SVGWrapper>
      );
    case T.ContentType.BLUEPRINT_DXF:
      return (
        <SVGWrapper width="44px" height="15px">
          <DxfSvg />
        </SVGWrapper>
      );
    case T.ContentType.BLUEPRINT_DWG:
      return (
        <SVGWrapper width="45px" height="15px">
          <DwgSvg />
        </SVGWrapper>
      );
    case T.ContentType.BIM:
      return (
        <SVGWrapper width="45px" height="15px">
          <BIMContentSVG />
        </SVGWrapper>
      );
    case T.ContentType.DESIGN_DXF:
      return (
        <SVGWrapper width="44px" height="15px">
          <DesignDxfSvg />
        </SVGWrapper>
      );
    case T.ContentType.MARKER:
      return isPinned ? (
        <SVGWrapper width="21px" height="23px" style={{ transform: 'translateX(-2px)' }}>
          <MarkerPinnedSvg />
        </SVGWrapper>
      ) : (
        <SVGWrapper width="17px" height="16px" style={{ transform: 'translateY(1px)' }}>
          <MarkerSvg />
        </SVGWrapper>
      );
    case T.ContentType.LENGTH:
      return isPinned ? (
        <SVGWrapper width="21px" height="23px" style={{ transform: 'translateX(-2px)' }}>
          <LengthPinnedSvg />
        </SVGWrapper>
      ) : (
        <SVGWrapper width="16px" height="16px" style={{ transform: 'translateY(1px)' }}>
          <LengthSvg />
        </SVGWrapper>
      );
    case T.ContentType.THREE_LENGTH:
      return isPinned ? (
        <SVGWrapper width="21px" height="23px" style={{ transform: 'translateX(-2px)' }}>
          <LengthPinnedSvg />
        </SVGWrapper>
      ) : (
        <SVGWrapper width="16px" height="16px" style={{ transform: 'translateY(1px)' }}>
          <LengthSvg />
        </SVGWrapper>
      );
    case T.ContentType.THREE_HEIGHT:
      return isPinned ? (
        <SVGWrapper width="21px" height="23px" style={{ transform: 'translateX(-2px)' }}>
          <ThreeHeightPinnedSvg />
        </SVGWrapper>
      ) : (
        <SVGWrapper width="16px" height="16px" style={{ transform: 'translateY(1px)' }}>
          <ThreeHeightPinnedSvg />
        </SVGWrapper>
      );
    case T.ContentType.AREA:
      return isPinned ? (
        <SVGWrapper width="21px" height="23px" style={{ transform: 'translateX(-2px)' }}>
          <AreaPinnedSvg />
        </SVGWrapper>
      ) : (
        <SVGWrapper width="18px" height="16px" style={{ transform: 'translateY(1px)' }}>
          <AreaSvg />
        </SVGWrapper>
      );
    case T.ContentType.THREE_AREA:
      return isPinned ? (
        <SVGWrapper width="21px" height="23px" style={{ transform: 'translateX(-2px)' }}>
          <AreaPinnedSvg />
        </SVGWrapper>
      ) : (
        <SVGWrapper width="18px" height="16px" style={{ transform: 'translateY(1px)' }}>
          <AreaSvg />
        </SVGWrapper>
      );
    case T.ContentType.VOLUME:
      return isPinned ? (
        isDTMMode ? (
          <SVGWrapper
            width="21px"
            height="23px"
            style={{ transform: 'translateX(0.715px) translateY(2px)' }}
          >
            <VolumePinnedDTMSvg />
          </SVGWrapper>
        ) : (
          <SVGWrapper width="21px" height="23px" style={{ transform: 'translateX(-2px)' }}>
            <VolumePinnedSvg />
          </SVGWrapper>
        )
      ) : isDTMMode ? (
        <SVGWrapper width="16px" height="17px" style={{ transform: 'translateY(1px)' }}>
          <VolumeDTMSvg />
        </SVGWrapper>
      ) : (
        <SVGWrapper width="16px" height="17px" style={{ transform: 'translateY(1px)' }}>
          <VolumeSvg />
        </SVGWrapper>
      );
    case T.ContentType.ESS_MODEL:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translateY(1px)' }}>
          <ESSModelWithBgIcon />
        </SVGWrapper>
      );
    case T.ContentType.ESS_MODEL_CUSTOM:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translateY(1px)' }}>
          <VolumePinnedSvg />
        </SVGWrapper>
      );
    case T.ContentType.ESS_ARROW:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <ESSArrowToolIcon />
        </SVGWrapper>
      );
    case T.ContentType.ESS_POLYGON:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <ESSPolygonToolIcon />
        </SVGWrapper>
      );
    case T.ContentType.ESS_POLYLINE:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <ESSPolylineToolIcon />
        </SVGWrapper>
      );
    case T.ContentType.ESS_TEXT:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <ESSTextToolIcon />
        </SVGWrapper>
      );
    case T.ContentType.ESS_LINE_TEXT:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <ESSLineTextToolIcon />
        </SVGWrapper>
      );
    case T.ContentType.GROUP:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <GroupSvg />
        </SVGWrapper>
      );
    case T.ContentType.ISSUE_POINT:
    case T.ContentType.ISSUE_PHOTO:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <IssueSvg />
        </SVGWrapper>
      );
    case T.ContentType.FLATTEN_MAP:
      return (
        <SVGWrapper width="21px" height="12px" style={{ transform: 'translate(2px, 1px)' }}>
          <FlattenMapSvg />
        </SVGWrapper>
      );
    case T.ContentType.FLIGHT_PLAN:
      return (
        <SVGWrapper width="20px" height="20px">
          <FlightPlanSvg />
        </SVGWrapper>
      );
    case T.ContentType.FLIGHT_SCHEDULE:
      return (
        <SVGWrapper width="20px" height="20px">
          <FlightScheduleSvg />
        </SVGWrapper>
      );
    default:
      return null;
  }
};

const DisabledTabToIcon: FC<{ contentType: T.OverLayContent['type'] }> = ({ contentType }) => {
  switch (contentType) {
    case T.ContentType.BLUEPRINT_DXF:
      return (
        <SVGWrapper width="44px" height="15px">
          <DisabledDxfSvg />
        </SVGWrapper>
      );
    case T.ContentType.BLUEPRINT_DWG:
      return (
        <SVGWrapper width="45px" height="15px">
          <DisabledDwgSvg />
        </SVGWrapper>
      );
    case T.ContentType.BLUEPRINT_PDF:
      return (
        <SVGWrapper width="44px" height="15px">
          <DisabledPdfSvg />
        </SVGWrapper>
      );
    case T.ContentType.DESIGN_DXF:
      return (
        <SVGWrapper width="44px" height="15px">
          <DisabledDesignDxfSvg />
        </SVGWrapper>
      );
    case T.ContentType.BIM:
      return (
        <SVGWrapper width="44px" height="15px">
          <BIMContentSVG />
        </SVGWrapper>
      );
    default:
      return null;
  }
};

export interface Props {
  className?: string;
  contentType: T.ContentType;
  color?: string;
  isPinned?: boolean;
  isDTMMode?: boolean;
  borderColor?: string;
}

export const ContentIcon: FC<Props> = ({
  className,
  contentType,
  color,
  isPinned = false,
  isDTMMode = false,
  borderColor,
}) => {
  const tabIcon: ReactNode = getTabToIcon(contentType, isPinned, isDTMMode);
  const ShouldContentDisabled: UseShouldContentDisabled = useShouldContentDisabled(contentType);
  const contentIcon: ReactNode = ShouldContentDisabled ? (
    <DisabledTabToIcon contentType={contentType as T.OverLayContent['type']} />
  ) : (
    tabIcon
  );
  return (
    <Root className={className} contentColor={color} borderColor={borderColor}>
      {contentIcon}
    </Root>
  );
};
