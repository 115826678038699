/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Mesh, Object3D, Sprite } from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
//@ts-ignore
import { Runtime } from 'three-loader-3dtiles';
import { DisposeHelper } from '../../Lib/Helper';
import { LabelObject } from '../Label';
import { autobind } from 'core-decorators';
import { renderToString } from 'react-dom/server';
// const SPRITE_MATERIAL_PHOTO = SPRITE_MATERIAL.PHOTO_POINT.clone();

interface GeoData {
  longitude: number;
  latitude: number;
  altitude: number;
}

interface Metadata {
  fileName: string;
  date: string;
  takenBy: string;
}
import { PhotoThumbnail } from './Contents/PhotoThumbnail';
import { SPRITE_MATERIAL } from '../../Lib/constant';
import { PhotoType } from '^/types';
import { threeAlbumStore } from '../../Lib/Store/ThreePhotoAlbumStore';
export class PhotoAlbumLocationObject extends Object3D {
  public photoPlane: Mesh | null = null;
  public label: LabelObject | null = null;
  public sprite: Sprite;
  public readonly contentId: string | number;
  public readonly contentType: PhotoType | undefined;
  private readonly tilesRuntime: Runtime;
  private readonly photoUrl: string;
  private readonly viewer: Viewer;
  private readonly metadata: Metadata;
  public selected: boolean = false;
  public hovered: boolean = false;
  public isTemporary: boolean = false;
  public constructor(
    contentId: string | number,
    viewer: Viewer,
    photoUrl: string,
    geoData: GeoData,
    metadata: Metadata,
    tilesRuntime: Runtime,
    contentType: PhotoType | undefined
  ) {
    super();
    this.name = contentId.toString();
    this.contentId = contentId;
    this.contentType = contentType;
    this.viewer = viewer;
    this.photoUrl = photoUrl;
    this.tilesRuntime = tilesRuntime;
    this.metadata = metadata;
    this.sprite = new Sprite(SPRITE_MATERIAL.PHOTO_POINT);
    this.sprite.scale.set(0.03, 0.03, 0.03);
    // this.sprite.visible = false;
    this.add(this.sprite);
    this.initPosition(geoData);
  }

  /**
   * @private
   * @description Funtion init position for photo album
   * @param {GeoData} geoData
   * @returns {void}
   */
  @autobind
  private initPosition(geoData: GeoData): void {
    const position = this.tilesRuntime.getPositionFromLatLongHeight({
      long: geoData.longitude,
      lat: geoData.latitude,
      height: geoData.altitude,
    });
    this.position.copy(position);
    this.updateMatrixWorld(true);
    this.matrixAutoUpdate = false;
    this.matrixWorldAutoUpdate = false;
  }
  /**
   * @public
   * @description Function to create label
   */
  @autobind
  public createLabel(): void {
    const label = new LabelObject(this.viewer, {
      center: true,
      sprite: true,
      transform: false,
      zIndexRange: [0, 10],
    });
    this.label = label;
  }
  /**
   * @public
   * @description set highlight photo
   */
  @autobind
  public setHighlight(isShow: boolean = true): void {
    this.hovered = true;
    if (!this.label) {
      this.createLabel();
      this.add(this.label!);
    }
    if (!this.selected) {
      this.label!.setInnerHTHML(
        renderToString(
          PhotoThumbnail({ url: this.photoUrl, metadata: this.metadata, isShow: isShow })
        )
      );
    }
  }
  /**
   * @public
   * @description set un highlight photo
   */
  @autobind
  public setUnHighlight(): void {
    this.hovered = false;
    this.selected = false;
    if (this.label) {
      this.remove(this.label);
      this.label.dispose();
      this.label = null;
      const store = threeAlbumStore.getState();
      if (store.selectedPhotoAlbumIdNew.photoId) {
        store.setSelectedPhotoAlbumIdNew({ photoId: null, photoType: undefined });
      }
    }
  }
  /**
   * @public
   * @description set selected photo
   */
  @autobind
  public setSelectedPhoto(check: boolean): void {
    if (!this.label) {
      this.createLabel();
      this.add(this.label!);
    }
    this.selected = check;
    if (this.selected) {
      this.label!.setInnerHTHML(
        renderToString(
          PhotoThumbnail({ url: this.photoUrl, metadata: this.metadata, isShow: true })
        )
      );
    } else {
      this.remove(this.label!);
      this.label!.dispose();
      this.label = null;
    }
  }
  /**
   * @public
   * @description toggle plane image
   */
  @autobind
  public togglePlaneImage(isShow: boolean): void {
    console.log('togglePlaneImage', isShow);
  }
  /**
   * @public
   * @description set texture
   */
  @autobind
  public setTexture(): void {
    console.log('setTexture');
  }
  /**
   * @public
   * @description This function will auto-call when the scene render
   * @param {boolean | undefined} force
   */
  @autobind
  public updateMatrixWorld(force: boolean | undefined) {
    super.updateMatrixWorld(force);
  }
  /**
   * @public
   * @description dispose all
   * @returns { void}
   */
  @autobind
  public dispose(): void {
    DisposeHelper.disposeHierarchy(this);
  }
}
