/* eslint-disable max-len */
import { Language } from '^/types';

export default {
  title: {
    [Language.KO_KR]: '오버레이 위치 조정',
    [Language.EN_US]: 'Overlay Position',
  },
  repositionHeaderText: {
    [Language.KO_KR]: '모델 위치 조정',
    [Language.EN_US]: 'Reposition the model',
  },
  respositionDescriptionText: {
    [Language.KO_KR]: `모델이 빈 공간 위에 있을 경우, 모델이 지리적으로 올바르게 태그되지 않을 수 있습니다. 작업 현장으로 모델을 가져 오려면 아래 버튼을 클릭해주세요.`,
    [Language.EN_US]: `If your model is sitting on top of a blank space, the model is not correctly geo-tagged. Click on this button to bring your model to your working site.`,
  },
  confirmButton: {
    [Language.KO_KR]: '업로드 확인',
    [Language.EN_US]: 'Confirm Upload',
  },
  mapCenter: {
    [Language.KO_KR]: '지도 원점',
    [Language.EN_US]: 'Map to Center',
  },
  respositioningText: {
    [Language.KO_KR]: 'IFC 모델의 위치 조정',
    [Language.EN_US]: 'Respositioning the IFC Model',
  },
  tooltipButton: {
    [Language.KO_KR]: '계속하기',
    [Language.EN_US]: 'Continue',
  },
};
