import SkeletonElement from '^/components/atoms/SkeletonElement';
import React from 'react';
import { Table, TableWrapper, Td, Th } from '../styles';

export const Skeleton: React.FC<{ width?: string; height?: string }> = ({
  width,
  height = '20px',
}) => <SkeletonElement width={width} height={height} />;

export const TableSkeleton: React.FC = () => (
  <TableWrapper>
    <Table>
      <thead>
        <tr>
          <Th>
            <Skeleton height="40px" />
          </Th>
          <Th>
            <Skeleton height="40px" />
          </Th>
          <Th>
            <Skeleton height="40px" />
          </Th>
          <Th colSpan={7}>
            <Skeleton height="40px" />
          </Th>
        </tr>
      </thead>
      <tbody>
        {[...Array(4)].map((_, index) => (
          <tr key={index}>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
          </tr>
        ))}
      </tbody>
    </Table>
  </TableWrapper>
);
