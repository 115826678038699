/* eslint-disable max-lines */
import palette from '^/constants/palette';
import React, { FC, memo } from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import styled, { CSSObject } from 'styled-components';

import { Contents } from '.';

export const DashboardContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',
  userSelect: 'none',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});
DashboardContentsListRoot.displayName = 'GroupedContentsListRoot';

const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

const EarthworkContents: FC = memo(() => (
  <DashboardContentsListRoot>
    <ScrollBars
      autoHide={true}
      renderView={ScrollBarView}
      autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
      autoHideDuration={HIDE_SCROLL_BAR_DURATION}
    >
      <Contents />
    </ScrollBars>
  </DashboardContentsListRoot>
));
export default memo(EarthworkContents);
