import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface PreferenceState {
  isSignUpTutorialPopupHidden: boolean;
  setIsSignUpTutorialPopupHidden(state: boolean): void;
}

export const usePreferenceStore = create<PreferenceState>()(
  devtools(
    persist(
      set => ({
        isSignUpTutorialPopupHidden: false,
        setIsSignUpTutorialPopupHidden: state => {
          set({ isSignUpTutorialPopupHidden: state });
        },
      }),
      { name: 'preferences' }
    )
  )
);
