/* eslint-disable max-lines */
import {
  defaultToastErrorOption,
  useClickOutside,
  UseL10n,
  useL10n,
  UseToast,
  useToast,
} from '^/hooks';
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import palette from '^/constants/palette';

import { ExitFullScreenMode, FullScreenMode, PlayBackRate } from '^/assets/icons/photo/video-icons';
import BackArrowSVG from '^/assets/icons/photo/back-arrow.svg';
import DownloadSVG from '^/assets/icons/photo/download-white.svg';

import Text from './text';
import { formatMsTime } from '../PhotoList/util';
import CustomTooltip from '^/components/atoms/CustomTooltip';
import { PlayPauseButton, TimelapseSeekSlider } from './Component';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import {
  Flexbox,
  PlayBackButton,
  PlayBackMenu,
  PlayBackRateText,
  PlayBackRateWrapper,
  PlayRateText,
  PlaySpeedText,
} from '../VideoPlayer/style';
import { imagesPerSecond } from './utils';
import * as T from '^/types';
import useTimelapseContents from '^/hooks/timelapse/useTimelapseContents';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import Tippy from '@tippyjs/react';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '100vh',
  width: '70%',
});

const TimelapseWrapper = styled.div({
  whiteSpace: 'nowrap',
  position: 'relative',
  height: '80vh',
  width: '100%',
  borderRadius: '5px',
});

const ImageWrapper = styled.img({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

const TimelapseControllers = styled.div<{ isHidden?: boolean }>(({ isHidden }) => ({
  position: 'absolute',
  bottom: '4%',
  left: '0',
  right: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  opacity: isHidden ? 0 : 1,
  transition: 'all 0.4s ease-in-out',
}));

const Timelapseinfo = styled.div<{ isHidden?: boolean }>(({ isHidden }) => ({
  position: 'absolute',
  top: '-5%',
  left: '0',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  opacity: isHidden ? 0 : 1,
  transition: 'all 0.4s ease-in-out',
}));

const TimelapseInfoText = styled.span({
  fontWeight: '600',
  fontSize: '100%',
  color: palette.white.toString(),
  whiteSpace: 'nowrap',
});

const TimelapsePlayerInfoText = styled.span<{ isVisible?: boolean }>(({ isVisible }) => ({
  fontWeight: '400',
  fontSize: '100%',
  textAlign: 'center',
  color: palette.white.toString(),
  whiteSpace: 'nowrap',
  backgroundColor: 'rgba(44, 50, 60, 0.86)',
  padding: '16px',
  position: 'absolute',
  top: isVisible ? '0' : '-32px',
  opacity: isVisible ? 1 : 0,
  left: '0',
  right: '0',
  transition: 'all 0.6s ease-in-out',
}));

const TimeLapseControllersWrapper = styled.div({
  height: '100%',
  width: '90%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
});

const TimelapseControllersBackground = styled.div<{ isHidden?: boolean }>(({ isHidden }) => ({
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  height: '40%',
  background: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%)',
  opacity: isHidden ? 0 : 0.6,
  transition: 'all 0.4s ease-in-out',
}));

const TimelapseSeekerWrapper = styled.div({
  flex: 1,
  zIndex: 999,
});

export const DurationWrapper = styled.div({
  backgroundColor: 'transparent',
});

export const DurationText = styled.span({
  fontWeight: '600',
  fontSize: '90%',
  color: palette.white.toString(),
  whiteSpace: 'nowrap',
});

export const IconButton = styled.button({
  height: '30px',
  width: '30px',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  position: 'relative',
});

export const BackArrowWrapper = styled.button<{ isHidden?: boolean }>(({ isHidden }) => ({
  position: 'fixed',
  zIndex: 301,
  top: '7.2%',
  left: '100px',
  borderRadius: '16px',
  padding: '20px',
  cursor: 'pointer',
  transform: 'translate(-50%, -50%)',

  opacity: isHidden ? 0 : 1,
  transition: 'all 0.4s ease-in-out',

  backgroundColor: `rgba(50, 64, 77, 0.8)`,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
}));

export const DownloadButtonWrapper = styled(BackArrowWrapper)({
  position: 'fixed',
  zIndex: 301,
  top: '6.2%',
  left: '84%',
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '8px',
});

export const TimelapsePlaybackMenu = styled(PlayBackMenu)({
  bottom: '40px',
  right: '6%',
});

interface TimelapseProps {
  photos: string[];
  photoIds: Array<T.Photo['id']>;
}

const Timelapse: FC<TimelapseProps> = ({ photos, photoIds }) => {
  const timerDelay = 1000;
  const [fps, setFps] = useState(0.3);
  const [openFps, setOpenFps] = useState(false);
  const [index, setIndex] = useState(0);
  const [showIcon, setShowIcon] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState(true);
  const [timer, setTimer] = useState(0);
  const { id: projectId } = useParams();

  const [pause, setPause] = useState(true);
  const [isInactive, setIsInactive] = React.useState(false);
  const [fullScreenMode, setFullScreenMode] = React.useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const playbackMenuRef = useRef<HTMLDivElement>(null);

  const { downloadTimelapseImage, cancelDownload } = useTimelapseContents(Number(projectId));

  const imageSpeed = useMemo(() => timerDelay * fps, [timerDelay, fps]);

  const duration = useMemo(
    () => photos.length * timerDelay * fps,
    [photos.length, timerDelay, fps]
  );
  const [l10n]: UseL10n = useL10n();
  const toast: UseToast = useToast();

  const {
    setTimelapseImages,
    selectedCollectionId,
    getTimelapseDownloadStatus,
    setTimelapseDownloadStatus,
  } = useTimelapseStore(s => ({
    setTimelapseImages: s.setTimelapseImages,
    selectedCollectionId: s.selectedCollectionId,
    getTimelapseDownloadStatus: s.getTimelapseDownloadStatus,
    setTimelapseDownloadStatus: s.setTimelapseDownloadStatus,
  }));

  const isDownloading = getTimelapseDownloadStatus === T.APIStatus.PROGRESS;
  const isDownloadingError = getTimelapseDownloadStatus === T.APIStatus.ERROR;

  const handleCloseButton = () => {
    setTimelapseImages([]);
  };

  const handleDownloadButton = async () => {
    if (!selectedCollectionId || !photoIds) {
      return;
    }

    if (isDownloading) {
      handleCancelDownloadButton();
      return;
    }

    await downloadTimelapseImage(photoIds, selectedCollectionId, fps);
  };

  const handleCancelDownloadButton = () => {
    cancelDownload();
  };

  const handleSeekChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentTime = Number(event.target.value);
    setTimer(currentTime);
    const idx = Math.floor((currentTime / duration) * photos.length);
    const newIndex = Math.min(idx, photos.length - 1);
    setIndex(newIndex);
  };

  const handleClickFullscreen = () => {
    const timelapsePlayerElement = wrapperRef.current;

    if (timelapsePlayerElement) {
      if (timelapsePlayerElement.requestFullscreen && !fullScreenMode) {
        setFullScreenMode(true);
        void timelapsePlayerElement.requestFullscreen();
      } else {
        void document.exitFullscreen();
        setFullScreenMode(false);
      }
    }
  };

  const keyDownEventHandler = (event: { code: string }) => {
    if (event.code === 'Space') {
      setPause(prev => !prev);
    }
  };

  const handlePlayPause = () => {
    if (timer === duration) {
      setIndex(0);
      setTimer(0);
      setPause(!pause);
      return;
    }
    setPause(!pause);
  };

  const handleMouseMove = () => {
    if (timeoutRef.current) {
      setIsInactive(false);
      clearTimeout(timeoutRef.current);
    }
    setIsInactive(false);
    timeoutRef.current = setTimeout(() => {
      setIsInactive(true);
    }, 1000);
  };

  const handleFpsChange = (value: number): void => {
    setTimer(0);
    setIndex(0);
    setFps(value);
  };

  // Check inactivity of mouse when timelapse is playing
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  // Handle timer and image change
  useEffect(() => {
    if (pause) {
      return;
    }

    if (timer + imageSpeed > duration && index === photos.length - 1) {
      setTimer(duration);
      return;
    }
    if (timer === duration) {
      return;
    }

    const timerTime = setTimeout(() => {
      setTimer(prev => prev + timerDelay);
    }, timerDelay);

    return () => {
      clearTimeout(timerTime);
    };
  }, [timer, pause]);

  useEffect(() => {
    if (pause) {
      return;
    }

    if (index === photos.length - 1) {
      return;
    }

    const imageTimerTime = setTimeout(() => {
      setIndex(prevIndex => prevIndex + 1);
    }, imageSpeed);

    return () => {
      clearTimeout(imageTimerTime);
    };
  }, [index, photos.length, fps, pause]);

  useEffect(() => {
    if (index === photos.length - 1 && timer === duration) {
      setIndex(photos.length - 1);
      setPause(true);
    }
  }, [timer, index, duration]);

  useEffect(() => {
    if (isDownloadingError) {
      // TODO : Toaster
      toast({
        type: T.Toast.ERROR,
        content: {
          title: isDownloadingError
            ? Text.timelapseToast.download.title
            : Text.timelapseToast.cancel.title,
          description: isDownloadingError
            ? Text.timelapseToast.download.description
            : Text.timelapseToast.cancel.description,
        },
        option: defaultToastErrorOption,
      });
      setTimelapseDownloadStatus(T.APIStatus.IDLE);
    }
  }, [isDownloadingError]);

  // Handle Full Screen
  useEffect(() => {
    const handleExitFullscreen = () => {
      if (!document.fullscreenElement) {
        setFullScreenMode(false);
      }
    };
    document.addEventListener('fullscreenchange', handleExitFullscreen);
    document.addEventListener('keydown', keyDownEventHandler);
    return () => {
      document.removeEventListener('fullscreenchange', handleExitFullscreen);
      document.removeEventListener('keydown', keyDownEventHandler);
    };
  }, []);

  // Handle  inactivity
  useEffect(() => {
    if (isInactive) {
      setShowIcon(pause);
      setShowControls(fullScreenMode && !pause);
    } else {
      setShowIcon(true);
      setShowControls(true);
    }
  }, [isInactive, pause, fullScreenMode]);

  //Handle view info
  useEffect(() => {
    if (!pause) {
      setIsInfoVisible(false);
    }
    let timeCheck: NodeJS.Timeout;
    if (isInfoVisible) {
      timeCheck = setTimeout(() => {
        setIsInfoVisible(false);
      }, 4000);
    }
    return () => {
      clearTimeout(timeCheck);
    };
  }, [pause]);

  useClickOutside({
    ref: playbackMenuRef,
    callback() {
      if (playbackMenuRef.current) {
        setOpenFps(false);
      }
    },
  });

  const closeButton = !fullScreenMode && (
    <BackArrowWrapper onClick={() => handleCloseButton()} isHidden={!pause}>
      <BackArrowSVG />
    </BackArrowWrapper>
  );

  const downloadButton = !fullScreenMode && (
    <Tippy
      offset={T.TIPPY_OFFSET}
      theme="angelsw"
      placement="top"
      arrow={false}
      content={isDownloading ? l10n(Text.tooltip.cancel) : l10n(Text.tooltip.download)}
    >
      <DownloadButtonWrapper onClick={handleDownloadButton} isHidden={!pause}>
        {isDownloading ? <LoadingIcon /> : <DownloadSVG />}
      </DownloadButtonWrapper>
    </Tippy>
  );

  const playPauseIcon = (
    <PlayPauseButton isMain={true} pause={pause} onClick={handlePlayPause} isHidden={!showIcon} />
  );

  const playPauseIconSecondary = (
    <PlayPauseButton isMain={false} pause={pause} onClick={handlePlayPause} />
  );

  const timelapseInfo = (
    <Timelapseinfo isHidden={!pause}>
      <TimelapseInfoText>{l10n(Text.timelapse)}</TimelapseInfoText>
    </Timelapseinfo>
  );

  const timelapsePlayerInfo = (
    <TimelapsePlayerInfoText isVisible={isInfoVisible}>
      {l10n(Text.timelapsePlayerInfo)}
    </TimelapsePlayerInfoText>
  );

  const timelapseControllers = (
    <TimelapseControllers isHidden={!showControls}>
      <TimeLapseControllersWrapper>
        {playPauseIconSecondary}
        {/* Seeker */}
        <TimelapseSeekerWrapper>
          <TimelapseSeekSlider
            duration={duration}
            onSeek={handleSeekChange}
            photos={photos}
            timer={timer}
          />
        </TimelapseSeekerWrapper>

        {/* Duration Time */}
        <DurationWrapper>
          <DurationText>
            {formatMsTime(timer)} / {formatMsTime(duration)}
          </DurationText>
        </DurationWrapper>

        <PlayBackButton isSelected={false} onClick={() => setOpenFps(!openFps)}>
          <span>
            <Flexbox>
              <PlayBackRate />
              <PlayBackRateText> {fps} Sec </PlayBackRateText>
            </Flexbox>
            {openFps ? (
              <TimelapsePlaybackMenu ref={playbackMenuRef}>
                <PlaySpeedText>Seconds per image</PlaySpeedText>
                <PlayBackRateWrapper>
                  {imagesPerSecond.map((rate, idx) => (
                    <PlayRateText
                      onClick={() => handleFpsChange(rate.value)}
                      role="button"
                      key={`playback_${idx}`}
                    >
                      {rate.label}
                    </PlayRateText>
                  ))}
                </PlayBackRateWrapper>
              </TimelapsePlaybackMenu>
            ) : null}
          </span>
        </PlayBackButton>

        {/*Full Screen Controls */}
        <IconButton onClick={handleClickFullscreen}>
          <CustomTooltip
            placement="near-item"
            content={
              !fullScreenMode ? l10n(Text.fullScreenTooltip) : l10n(Text.exitFullScreenTooltip)
            }
          >
            {!fullScreenMode ? <FullScreenMode /> : <ExitFullScreenMode />}
          </CustomTooltip>
        </IconButton>
      </TimeLapseControllersWrapper>
    </TimelapseControllers>
  );

  return (
    <Wrapper>
      <TimelapseWrapper
        ref={wrapperRef}
        onMouseMove={handleMouseMove}
        onMouseOver={() => {
          setShowIcon(true);
          setShowControls(true);
        }}
        onMouseLeave={() => {
          if (pause) {
            setShowControls(true);
          } else {
            setShowIcon(false);
          }
        }}
      >
        <ImageWrapper src={photos[index]} />
        <TimelapseControllersBackground isHidden={!showControls} />
        {playPauseIcon}
        {timelapseInfo}
        {timelapsePlayerInfo}
        {timelapseControllers}
        {closeButton}
        {downloadButton}
      </TimelapseWrapper>
    </Wrapper>
  );
};
export default Timelapse;
