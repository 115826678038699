import * as T from '^/types';

export default {
  photoText: (photoOverlay: number) => ({
    [T.Language.KO_KR]: `${
      photoOverlay > 1
        ? `날짜가 없는 사진이 ${photoOverlay}장 있습니다. 날짜를 태그하려면 '사진 보기' 버튼을 클릭해주세요.`
        : '날짜가 없는 사진이 하나 있습니다. 날짜를 태그로 지정하십시오'
    }  `,
    [T.Language.EN_US]: `${
      photoOverlay > 1
        ? `There are ${photoOverlay} photos without dates. Please tag a date to them.`
        : 'There is one photo without a date. Please tag it with a date'
    } `,
  }),
  videoText: (videoOverlay: number) => ({
    [T.Language.KO_KR]: `${
      videoOverlay > 1
        ? `날짜가 없는 비디오가  ${videoOverlay}장 있습니다. 날짜를 태그하려면 '비디오 보기' 버튼을 클릭해주세요.`
        : '날짜가 없는 비디오가 하나 있습니다. 날짜를 태그로 지정하십시오'
    } `,
    [T.Language.EN_US]: `${
      videoOverlay > 1
        ? `There are ${videoOverlay} videos without dates. Please tag a date to them.`
        : 'There is one video without a date. Please tag it with a date'
    } `,
  }),
  photoAndVideoText: (count: number) => ({
    [T.Language.KO_KR]: `${
      count > 1
        ? `날짜가 없는 비디오가 그리고 사진이 ${count}장 있습니다. 날짜를 태그하려면 '비디오 보기' 버튼을 클릭해주세요.`
        : '날짜가 없는 비디오가 그리고 사진이 하나 있습니다. 날짜를 태그로 지정하십시오'
    } `,
    [T.Language.EN_US]: `${
      count > 1
        ? `There are ${count} photos and videos without dates. Please tag a date to them.`
        : 'There is one photo and video without a date. Please tag it with a date'
    } `,
  }),
  buttonVideoAndPhotoText: (count: number) => ({
    [T.Language.KO_KR]: '보기',
    [T.Language.EN_US]: `${count > 1 ? 'View' : 'View'}`,
  }),
  buttonVideoText: (videoOverlay: number) => ({
    [T.Language.KO_KR]: '비디오 보기',
    [T.Language.EN_US]: `${videoOverlay > 1 ? 'View Videos' : 'View Video'}`,
  }),
  buttonPhotoText: (photoOverlay: number) => ({
    [T.Language.KO_KR]: '사진 보기',
    [T.Language.EN_US]: `${photoOverlay > 1 ? 'View Photos' : 'View Photo'}`,
  }),
};
