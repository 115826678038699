import * as T from '^/types';
import React, { FC, ReactNode, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Text from './text';

import { flightPlanSortOptions, flightScheduleSortOptions } from '^/constants/drone-station';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';
import DroneStationGroupOrSortBy from '../../DroneStationGroupOrSortBy';

const Root = styled.section(() => ({
  display: 'flex',
  alignItems: 'center',
  top: '49px',
  height: '44px',
  fontSize: '14px',
  paddingLeft: '18.5px',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
  color: palette.ContentsList.groupListHeaderTextGray.toString(),
}));
Root.displayName = 'GroupedContentsListHeader';

const TitleWrapper = styled.div`
  flex: 1;
`;
TitleWrapper.displayName = 'TitleWrapper';

type SelectedSort = T.FlightPlanSortByCriteria | T.FlightScheduleSortByCriteria;

type Props = Readonly<{
  selectedSortValue: SelectedSort;
  onChangeSortValue(newSelectedSortValue: SelectedSort): void;
}>;

export const GroupedContentsListHeader: FC<Props> = memo(
  ({ selectedSortValue, onChangeSortValue }) => {
    const [l10n, language]: UseL10n = useL10n();
    const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
    const isInFlightPlanTab: boolean = sidebarTab === T.ContentPageTabType.FLIGHT_PLAN;

    /**
     * TODO: @ebraj-angelswing
     * Remove the sort for flight-schedule after confirmation from BE.
     */
    const sortOptions = isInFlightPlanTab
      ? flightPlanSortOptions[language]
      : flightScheduleSortOptions[language];

    const titleText: string = isInFlightPlanTab
      ? l10n(Text.groupListHeader.flight_plans[sidebarTab])
      : l10n(Text.groupListHeader.flight_schedules[sidebarTab]);

    const sortFlightPlanGroup: ReactNode = (
      <DroneStationGroupOrSortBy
        mainTitle={l10n(Text.groupListHeader.orderBy)}
        placeHolder={l10n(Text.groupListHeader.orderByPlaceholder)}
        value={selectedSortValue}
        options={sortOptions}
        onClick={value => {
          onChangeSortValue(value.value as SelectedSort);
        }}
        zIndex={11}
        customWidth="83px"
        customTitleColor="#A5A5A6"
      />
    );

    return (
      <Root>
        <TitleWrapper>{titleText}</TitleWrapper>
        {isInFlightPlanTab && sortFlightPlanGroup}
      </Root>
    );
  }
);
