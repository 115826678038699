/* eslint-disable max-len */

import { Language } from '^/types';

export default {
  backToSignIn: {
    [Language.KO_KR]: '로그인 페이지로 이동',
    [Language.EN_US]: 'Back to Sign in',
  },
};
