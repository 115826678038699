import React from 'react';
import styled from 'styled-components';

import ToggleSlider from '^/components/atoms/ToggleSlider';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';
import Text from './text';

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '44px',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
  color: palette.ContentsList.groupListHeaderTextGray.toString(),
  fontSize: '12px',
  fontWeight: '500',

  paddingLeft: '18.5px',
  paddingRight: '11.5px',
});

const TitleWrapper = styled.div({});

interface Props {
  isEnabled: boolean;
  onClick(): void;
}

function SidebarIssueToggle({ isEnabled, onClick }: Props) {
  const [l10n]: UseL10n = useL10n();
  return (
    <Root>
      <TitleWrapper>{l10n(Text.title)}</TitleWrapper>
      <ToggleSlider enabled={isEnabled} onClick={onClick} />
    </Root>
  );
}

export default SidebarIssueToggle;
