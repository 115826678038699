import * as T from '^/types';

export default {
  inputFields: {
    planName: {
      label: {
        [T.Language.KO_KR]: '일정 이름',
        [T.Language.EN_US]: 'Schedule Name',
      },
      placeHolder: {
        [T.Language.KO_KR]: '일정 이름 입력',
        [T.Language.EN_US]: 'Enter flight schedule name',
      },
    },
    flightPlan: {
      label: {
        [T.Language.KO_KR]: '비행 경로',
        [T.Language.EN_US]: 'Flight Plan',
      },
    },
    flightPlanDropdown: {
      placeHolder: {
        [T.Language.KO_KR]: '비행 경로 선택',
        [T.Language.EN_US]: 'Select a flight plan',
      },
    },
    device: {
      label: {
        [T.Language.KO_KR]: '스테이션',
        [T.Language.EN_US]: 'Station',
      },
    },
    deviceDropdown: {
      placeHolder: {
        [T.Language.KO_KR]: '스테이션 선택',
        [T.Language.EN_US]: 'Select a station',
      },
    },
    planTimer: {
      label: {
        [T.Language.KO_KR]: '시작 종류',
        [T.Language.EN_US]: 'Schedule Timer',
      },
    },
    planTimerOptions: {
      immediate: {
        label: {
          [T.Language.KO_KR]: '즉시 실행',
          [T.Language.EN_US]: 'Immediate',
        },
      },
      timed: {
        label: {
          [T.Language.KO_KR]: '시간제',
          [T.Language.EN_US]: 'Timed',
        },
      },
      continuous: {
        label: {
          [T.Language.KO_KR]: '연속',
          [T.Language.EN_US]: 'Continuous',
        },
      },
    },
    lostActions: {
      label: {
        [T.Language.KO_KR]: '연결 유실 동작',
        [T.Language.EN_US]: 'Lost Action',
      },
    },
    lostActionsOptions: {
      returnToHome: {
        label: {
          [T.Language.KO_KR]: 'RTH',
          [T.Language.EN_US]: 'Return to Home',
        },
      },
      hover: {
        label: {
          [T.Language.KO_KR]: '호버링',
          [T.Language.EN_US]: 'Hover',
        },
      },
      land: {
        label: {
          [T.Language.KO_KR]: '착륙',
          [T.Language.EN_US]: 'Land',
        },
      },
    },
    rthAltitude: {
      label: {
        [T.Language.KO_KR]: '스테이션에 대한 RTH 고도(m)',
        [T.Language.EN_US]: 'RTH Altitude Relative to Station(m)',
      },
      info: {
        [T.Language.KO_KR]: '고도를 20~500 범위 내에서 입력해주시기 바랍니다',
        [T.Language.EN_US]: 'Please enter the altitude within the range of 20 to 500',
      },
    },
  },
  selectedFlightPlans: {
    fileName: {
      [T.Language.KO_KR]: '파일 이름',
      [T.Language.EN_US]: 'File Name',
    },
    createdAt: {
      [T.Language.KO_KR]: '생성된 날짜',
      [T.Language.EN_US]: 'Created At',
    },
  },
  selectedDevice: {
    fileName: {
      [T.Language.KO_KR]: '장치 이름',
      [T.Language.EN_US]: 'Device Name',
    },
    droneName: {
      [T.Language.KO_KR]: '드론 이름',
      [T.Language.EN_US]: 'Drone Name',
    },
    dockName: {
      [T.Language.KO_KR]: '스테이션 이름',
      [T.Language.EN_US]: 'Station Name',
    },
    createdAt: {
      [T.Language.KO_KR]: '생성된 날짜',
      [T.Language.EN_US]: 'Created At',
    },
  },
};
