import React, { FC, ReactNode, memo } from 'react';
import styled from 'styled-components';
import { ContentToItem } from '^/components/atoms/ContentToItem';
import { GroupedContentsHeader } from '^/components/molecules/GroupedContentsHeader';
import { useIsContentHiddenByFilter } from '^/hooks/useContentsSearchFilter';
import { useESSContentsStore } from '^/store/essContentsStore';
import * as T from '^/types';
import { useGroupStore } from '^/store/zustand/groups/groupStore';

const ContentsList = styled.div({
  position: 'relative',
});
ContentsList.displayName = 'ContentsList';

export const GroupList: FC<Readonly<{ content: T.GroupableContent }>> = ({ content }) => {
  const essContentGroupTree = useESSContentsStore(s => s.essContentGroupTree);
  const idsByGroup = useGroupStore(s => s.tree.idsByGroup);
  const isESS = content.category === T.ContentCategory.ESS;
  const childrenIds = isESS ? essContentGroupTree[content.id] || [] : idsByGroup[content.id] || [];
  const isHidden = useIsContentHiddenByFilter(content, isESS);

  if (isHidden) {
    return <></>;
  }

  return (
    <div key={content.id} data-ctxsort-parent="Group" data-ctxsort-key={content.id}>
      <GroupedContentsHeader groupId={content.id} childrenIds={childrenIds} isESS={isESS} />
      <ContentsList>
        {content?.info.isOpened ? (
          <ContentsTreeList contentIds={childrenIds} isESS={isESS} />
        ) : null}
      </ContentsList>
    </div>
  );
};

export const ContentsTreeList: FC<
  Readonly<{
    contentIds: Array<T.GroupContent['id']>;
    isESS?: boolean;
  }>
> = memo(({ contentIds, isESS }) => {
  const contentsTreeList: ReactNode = contentIds.map(id => (
    <ContentToItem key={id} contentId={id} isESS={isESS} />
  ));

  return <>{contentsTreeList}</>;
});
