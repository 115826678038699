import { isIssue } from '^/hooks';
import * as T from '^/types';

export function isThisIssueRelatedToMe(
  myId: T.User['id'] | undefined,
  content: T.Content,
  isShowOnlyMyIssue?: boolean
): boolean {
  if (!isShowOnlyMyIssue) {
    return true;
  }
  if (myId === undefined || !isIssue(content)) {
    return false;
  }
  return myId === content.createdBy?.id || content.assignees.some(assignee => myId === assignee.id);
}

export function isGeometricPoint(type?: T.ContentType) {
  return type === T.ContentType.MARKER || type === T.ContentType.ISSUE_POINT;
}
