import styled, { CSSObject } from 'styled-components';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import DefaultImageIcon from '^/assets/icons/photo/photo-marker.svg';
import DefaultFlightVideoSvg from '^/assets/icons/upload-popup/video.svg';

const zIndex = 300;
export const blurredBackground: CSSObject = {
  backgroundColor: 'rgba(44, 50, 60, 0.96)',
};
export const Root = styled.div({
  position: 'fixed',
  display: 'flex',
  // prevent overlapping contentsTabbar shadows
  zIndex,
  top: '0',
  left: 0,
  width: 'calc(100% )',
  height: '100%',
  overflow: 'hidden',

  color: palette.white.toString(),
  ...blurredBackground,
});
Root.displayName = 'PhotoViewerRoot';

export const PhotoViewerHeader = styled.div({
  position: 'relative',
  display: 'flex',
  height: '50px',

  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${palette.Photo.border.toString()}`,

  fontSize: '13px',
});
PhotoViewerHeader.displayName = 'PhotoViewerHeader';

export const PhotoViewerWrapper = styled.div({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: '64%',
  width: '100%',
  // margin: '150px 0px 160px 0px',
  // padding: '0 70px',

  // height: 'calc(100vh - 266px)',
});

export const PhotoViewerItemWrapper = styled.div({
  // flexGrow: 1,
  height: '100%',
  width: '100%',
  // padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
export const PhotoViewerItem = styled.img({
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  // userSelect: 'none',
  // objectFit: 'contain',
});
PhotoViewerItem.displayName = 'PhotoViewerItem';

export const ArrowSvgWrapper = styled.div<{ readonly isLeft: boolean }>(({ isLeft }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  zIndex: 1,

  padding: '18px 30px',
  transform: isLeft ? 'rotate(180deg)' : undefined,
}));
ArrowSvgWrapper.displayName = 'ArrowSvgWrapper';

export const PhotoViewerItemHandle = styled.div<{
  readonly isLeft: boolean;
  readonly isVisible: boolean;
}>(({ isVisible, isLeft }) => ({
  position: 'absolute',
  left: isLeft ? '60px' : '',
  right: !isLeft ? '60px' : '',
  flexShrink: 0,
  display: 'flex',
  visibility: isVisible ? 'visible' : 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  ':hover div': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
}));
PhotoViewerItemHandle.displayName = 'PhotoViewerItemHandle';

export const BackArrowWrapper = styled.button<{ showBackground: boolean }>(
  ({ showBackground }) => ({
    position: 'fixed',
    zIndex: 301,
    top: '7.2%',
    left: '100px',
    borderRadius: '16px',
    padding: '20px',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)',

    backgroundColor: showBackground ? `rgba(50, 64, 77, 0.8)` : 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  })
);

export const PhotoViewerToolsWrapper = styled.div<{ showBackground: boolean }>(
  ({ showBackground }) => ({
    display: 'flex',
    flexShrink: 0,
    height: showBackground ? '10.4%' : '14.4%',
    boxSizing: 'border-box',
    justifyContent: 'center',
    position: showBackground ? 'fixed' : 'static',
    zIndex: 300,
    left: showBackground ? '38vw' : 0,
    top: '30px',

    alignItems: 'center',
    padding: '25px',
    borderRadius: '16px',
    backgroundColor: showBackground ? `rgba(50, 64, 77, 0.8)` : 'transparent',
  })
);
export const PhotoIndexLabel = styled.div({
  padding: '1rem',
  fontSize: '16px',
  fontWeight: 600,
  flexShrink: 0,
});

export const VerticalDivider = styled.div({
  height: '35px',
  width: '1px',
  backgroundColor: palette.white.toString(),
  opacity: 0.4,
});

export const toolItemStyle: CSSObject = {
  padding: '10px',
  marginLeft: '15px',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
};
export const ZoomButton = styled.button<{ isSelected: boolean; disabled?: boolean }>(
  ({ isSelected, disabled }) => ({
    ...toolItemStyle,
    backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  })
);

export const InfoButton = styled.button<{ isSelected: boolean }>(({ isSelected }) => ({
  ...toolItemStyle,
  backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
}));

export const InfoRoot = styled.div({
  position: 'relative',
});

export const InfoWrapper = styled.div({
  position: 'absolute',
  top: 'calc(100% + 14px)',
  transform: 'translateX(-40%)',

  width: 377,
  backgroundColor: '#fff',
  color: 'black',
  borderRadius: '16px',
  padding: '18px',
});
export const InfoItem = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '20px',
  fontWeight: 400,
  lineHeight: '20px',
  fontSize: '12px',
});
export const InfoItemKey = styled.div({
  textAlign: 'right',
  textTransform: 'capitalize',
});
export const InfoItemValue = styled.div({
  textAlign: 'left',
});

export const ChangeDateButton = styled.button<{ isSelected: boolean; disabled?: boolean }>(
  ({ isSelected, disabled }) => ({
    ...toolItemStyle,
    backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  })
);
export const IssueButton = styled.button<{ isSelected: boolean }>(({ isSelected }) => ({
  ...toolItemStyle,
  backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
}));
export const DownloadButton = styled.button<{ isSelected: boolean }>(({ isSelected }) => ({
  ...toolItemStyle,
  backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
}));
export const DeleteButton = styled.button<{ isSelected: boolean; disabled?: boolean }>(
  ({ isSelected, disabled }) => ({
    ...toolItemStyle,
    color: '#ffffff',
    backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  })
);
export const PhotoThumbNailsWrapper = styled.div({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '50px 1fr 50px',
  height: '21.6%',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100vw',
  padding: '0 150px 0px 150px',
  overflowX: 'scroll',
});

export const PhotoThumbNails = styled.div({
  overflowX: 'scroll',
  scrollBehavior: 'smooth',
  height: '100%',
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  boxSizing: 'border-box',

  '::-webkit-scrollbar': {
    height: 4,
  },
  '::-webkit-scrollbar-track': {
    boxshadow: 'inset 0 0 5px grey',
    borderRadius: 10,
    background: 'rgba(255,255,255,0.15)',
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(255,255,255,0.5)',
    borderRadius: 10,
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(255,255,255,1)',
    cursor: 'pointer',
  },
});

export const PhotoThumbNailInnerWrapper = styled.div({
  height: '100%',
  width: '100%',
});
export const PhotoThumbNailWrapper = styled.div<{
  isSelected: boolean;
}>(({ isSelected }) => ({
  flexShrink: 0,
  width: isSelected ? '114px' : '60px',
  height: isSelected ? '114px' : '60px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  opacity: isSelected ? 1 : 0.6,
  flexDirection: 'column',

  '&:hover': {
    opacity: 1,
  },
}));

export const PhotoThumbNail = styled.img<{ isSelected: boolean }>(({ isSelected }) => ({
  width: isSelected ? '107px' : '58px',
  height: isSelected ? '107px' : '58px',
  transition: 'width 0.2s, height 0.2s',
  borderRadius: '6px',
  // opacity: isSelected ? 1 : 0.6,

  borderColor: '#fff',
  borderWidth: isSelected ? 2 : 0,
  borderStyle: 'solid',
  userSelect: 'none',
  cursor: 'pointer',
}));

export const PhotoThumbNailItemHandle = styled.div<{
  readonly isLeft: boolean;
  readonly isVisible: boolean;
}>(({ isVisible, isLeft }) => ({
  width: '50px',
  flexShrink: 0,
  display: 'flex',
  visibility: isVisible ? 'visible' : 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transform: isLeft ? 'rotate(180deg)' : undefined,
}));
PhotoThumbNailItemHandle.displayName = 'PhotoThumbNailItemHandle';

export const PhotoThumbNailIconWrapper = styled.div({
  padding: '6px 10px',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0.5,
  ':hover': {
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
});

export const TimelineBar = styled.div<{ isSelected?: boolean; highlightDate: boolean }>(
  ({ highlightDate, isSelected }) => ({
    flexShrink: 0,
    position: 'sticky',
    left: 0,
    width: '2px',

    height: isSelected ? '139px' : highlightDate ? '106px' : '85px',
    background: 'transparent',

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '16px',
      width: '2px',
      left: 0,
      height: isSelected ? '132px' : highlightDate ? '110px' : '85px',
      background: highlightDate || isSelected ? dsPalette.white.toString() : '#6B6B6B',
    },
  })
);

export const TimeLineText = styled.span<{ isSelected?: boolean; highlightDate: boolean }>(
  ({ highlightDate, isSelected }) => ({
    color: highlightDate || isSelected ? '#FFF' : '#6B6B6B',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '600',
    position: 'absolute',
    left: '18px',
    // top: highlightDate && !isSelected ? '-42px' : '-26px',
    top: !isSelected ? '-20px' : '-8px',
    whiteSpace: 'nowrap',
  })
);

export const PhotoViewerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const DefaultImageContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: dsPalette.themeSecondary.alpha(0.5).toString(),
  flexDirection: 'column',
});

export const DefaultImageElement = styled(DefaultImageIcon)<{ scale: number }>(({ scale }) => ({
  transform: `scale(${scale})`,
}));

export const DefaultFlightVideoElement = styled(DefaultFlightVideoSvg)<{ scale: number }>(
  ({ scale }) => ({
    transform: `scale(${scale})`,
  })
);

export const DefaultImageText = styled.span({
  position: 'absolute',
  bottom: '30%',
  padding: '16px',
  fontSize: '15px',
  color: '#f5f5f5',
  fontStyle: 'normal',
  fontWeight: '400',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
});

export const VideoProcessingText = styled.span({
  paddingTop: '10px',
  fontSize: '15px',
  color: '#f5f5f5',
  fontStyle: 'normal',
  fontWeight: '600',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  zIndex: 100,
});
