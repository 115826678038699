/* eslint-disable max-lines */
import { AxiosError } from 'axios';
// eslint-disable-next-line no-restricted-imports
import { zipObject, defaultTo } from 'lodash';
import { QueryFunctionContext, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Store } from 'redux';

import { queryClient } from '..';
import { ERROR } from '../types';
import { UserKeys, UserURLs } from './constants';
import { AuthHeader, getRequestErrorType, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import {
  FinishPatchNotice,
  FinishPatchPassword,
  FinishPatchUserInfo,
  FinishPostPasswordReset,
  PatchNotice,
  PatchPassword,
  PatchUserInfo,
  PostPasswordReset,
} from '^/store/duck/Users';
import * as T from '^/types';
import { getFeaturePermissionFromSlug } from '^/utilities/withFeatureToggle';
import { useNotificationStore } from '^/store/notification';
import { useCallback } from 'react';
import { http } from '^/utilities/api';

export interface GetUserInfoResponse {
  readonly data: T.APIUser;
}

interface APIRelease {
  id: string;
  type: 'userReleaseNotes';
  attributes: {
    isShown: boolean;
    isHidden: boolean;
    isRead: boolean;
    releaseNote: {
      id: number;
      title: string;
      headings: {
        headings: string[];
      };
      notice_url: string;
      released_at: string;
      created_at: string;
      updated_at: string;
    };
  };
}

export interface GetNoticeResponse {
  data: APIRelease[];
}

export interface GetNotificationResponse {
  data: T.Notification[];
  meta: {
    unseen: number;
  };
}

interface PatchNoticeBody {
  id: T.Notice['id'];
  isShown?: T.Notice['isShown'];
  isRead?: T.Notice['isRead'];
  isHidden?: T.Notice['isHidden'];
}

interface PatchNoticeResponse {
  data: APIRelease;
}

interface APIRelease {
  id: string;
  type: 'userReleaseNotes';
  attributes: {
    isShown: boolean;
    isHidden: boolean;
    isRead: boolean;
    releaseNote: {
      id: number;
      title: string;
      headings: {
        headings: string[];
      };
      notice_url: string;
      released_at: string;
      created_at: string;
      updated_at: string;
    };
  };
}

interface PatchPasswordBody extends Pick<T.UserPassword, 'password'> {
  readonly token: string;
}

export interface PostPasswordResetBody extends Pick<T.User, 'email'> {
  readonly language?: T.Language;
}

interface PatchUserInfoResponse {
  readonly data: T.APIUser;
}
export interface PatchUserInfoBody extends Partial<Omit<T.MyPageFormValues, 'avatar'>> {
  readonly currentPassword?: T.UserPassword['password'];
  readonly avatar?: File;
}

export const APIToLanguage: (language?: string) => T.Language | undefined = language => {
  switch (language) {
    case T.Language.KO_KR:
    case T.Language.EN_US:
      return language;
    default:
      return undefined;
  }
};

export const APIToUser: (response: T.APIUser, slug: T.PlanConfig['slug']) => T.FullUser = (
  { id, attributes },
  slug
) => ({
  ...attributes,
  id: Number(id),
  contactNumber: defaultTo(attributes.contactNumber, ''),
  organization: defaultTo(attributes.organization, ''),
  purpose: defaultTo(attributes.purpose, ''),
  country: defaultTo(attributes.country, ''),
  language: APIToLanguage(attributes.language),
  avatar: defaultTo(attributes.avatar, undefined),
  createdAt: new Date(attributes.createdAt),
  featurePermission: getFeaturePermissionFromSlug(slug),
  role: attributes.role,
});

export const APIReleaseToNotice: (
  response: APIRelease[] | APIRelease
) => T.Notice[] | T.Notice = release$ =>
  Array.isArray(release$)
    ? release$.map((release: APIRelease) => ({
        id: release.attributes.releaseNote.id,
        type: release.type,
        url: release.attributes.releaseNote.notice_url,
        title: release.attributes.releaseNote.title,
        headings: release.attributes.releaseNote.headings.headings,
        isRead: release.attributes.isRead,
        isShown: release.attributes.isShown,
        isHidden: release.attributes.isHidden,
        createdAt: new Date(release.attributes.releaseNote.released_at),
      }))
    : {
        id: release$.attributes.releaseNote.id,
        type: release$.type,
        url: release$.attributes.releaseNote.notice_url,
        title: release$.attributes.releaseNote.title,
        headings: release$.attributes.releaseNote.headings.headings,
        isRead: release$.attributes.isRead,
        isShown: release$.attributes.isShown,
        isHidden: release$.attributes.isHidden,
        createdAt: new Date(release$.attributes.releaseNote.released_at),
      };

export const getUserInfoQueryfn =
  (store: Store<T.State>) =>
  async ({ queryKey }: QueryFunctionContext<ReturnType<typeof UserKeys.getUserInfoKey>>) => {
    const state = store.getState();
    const id = queryKey[1].id;

    if (!id) {
      throw new Error('Id is undefined');
    }
    const URL: string = UserURLs.getUserInfo(id);
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );

    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    const getUserFn = async (data: T.APIUser) => {
      const user = APIToUser(data, state.PlanConfig.config?.slug);
      appendAllUserInfo(user);
      return user;
    };

    const response = await http.get<GetUserInfoResponse>(URL, { headers });
    return getUserFn(response.data.data);
  };

export const getUserInfo = (id: T.FullUser['id']) =>
  queryClient.getQueryData(UserKeys.getUserInfoKey(id)) as T.FullUser;
export const setUserInfo = (user: T.FullUser) =>
  queryClient.setQueriesData(UserKeys.getUserInfoKey(user.id), user);
export const appendAllUserInfo = (user: T.FullUser) => {
  queryClient.setQueryData(UserKeys.getAllUserInfoKey, (state: T.RQUsersState) => {
    const allIds = state?.allIds ?? [];
    const byId = state?.byId ?? {};
    return {
      byId: {
        ...byId,
        [user.id]: user,
      },
      allIds: Array.from(new Set([...allIds, user.id])),
    };
  });
};

export function useAllUserInfos() {
  return useQuery<T.RQUsersState>({
    queryKey: UserKeys.getAllUserInfoKey,
    async queryFn() {
      return queryClient.getQueryData(UserKeys.getAllUserInfoKey) as T.RQUsersState;
    },
    initialData: {
      byId: {},
      allIds: [],
    },
  });
}

export function useUserInfoQuery(id: number) {
  const store = useStore();
  return useQuery(UserKeys.getUserInfoKey(id), getUserInfoQueryfn(store), {
    enabled: Boolean(id),
  });
}

export function useUserInfoQueries(userIds: number[]) {
  const store = useStore();

  return useQueries({
    queries: userIds.map(id => ({
      queryKey: UserKeys.getUserInfoKey(id),
      queryFn: getUserInfoQueryfn(store),
    })),
  });
}

// This is the one is in use
export function useAuthUserQuery() {
  const store = useStore();
  const id = useSelector((s: T.State) => s.Auth.authedUser?.id);

  return useQuery(UserKeys.getUserInfoKey(id), getUserInfoQueryfn(store), {
    enabled: Boolean(id),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

const patchUserInfoMutationFn =
  (store: Store<T.State>) =>
  async ({ user: { id, ...body } }: { user: Pick<T.User, 'id'> & PatchUserInfoBody }) => {
    const state = store.getState();
    const URL: string = makeV2APIURL('users', id);
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );

    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    const turnBooleanIntoString: (boolVal: boolean) => string = boolVal => (boolVal ? '1' : '0');

    const formdata: FormData = new FormData();
    Object.keys(body).forEach((key: keyof PatchUserInfoBody) => {
      const val: PatchUserInfoBody[keyof PatchUserInfoBody] = body[key];
      if (val !== undefined) {
        formdata.append(key, typeof val === 'boolean' ? turnBooleanIntoString(val) : val);
      }
    });
    const response = await http.patch<PatchUserInfoResponse>(URL, formdata, { headers });
    const user = APIToUser(response.data.data, state.PlanConfig.config?.slug);
    return user;
  };
export const usePatchUserInfoMutation = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const mutationFn = useCallback(patchUserInfoMutationFn(store), [store]);

  return useMutation(mutationFn, {
    onMutate(data) {
      dispatch(PatchUserInfo(data));
    },
    onSuccess() {
      dispatch(FinishPatchUserInfo({}));
    },
    onError(error: AxiosError) {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      } else {
        dispatch(
          FinishPatchUserInfo({
            error: getRequestErrorType({ status: error.response?.status } as any),
          })
        );
      }
    },
  });
};

const getNoticeQueryfn = (store: Store<T.State>, auth: T.AuthState, slug?: string) => async () => {
  const URL: string = makeV2APIURL('releases', 'get-release-note');
  const headers: AuthHeader | undefined = makeAuthHeader(auth, slug);
  if (headers === undefined) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const getNoticeFn = async (data: APIRelease[] | APIRelease) => {
    const notices = APIReleaseToNotice(data);
    const noticesArr = Array.isArray(notices) ? notices : [notices];
    const allIds: Array<T.Notice['id']> = noticesArr.map((notice: T.Notice) => notice.id);
    return zipObject(allIds, noticesArr);
  };

  const response = await http.get<GetNoticeResponse>(URL, { headers });
  return getNoticeFn(response.data.data);
};

export const useNoticeQuery = () => {
  const store = useStore();
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  return useQuery(UserKeys.getNoticeKey, getNoticeQueryfn(store, Auth, slug), {
    enabled: Boolean(Auth),
  });
};

const getNotificationQueryfn = (auth: T.AuthState, slug?: string) => async () => {
  const URL: string = makeV2APIURL('notifications');

  const headers: AuthHeader | undefined = makeAuthHeader(auth, slug);
  if (headers === undefined) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const { data } = await http.get<GetNotificationResponse>(URL, { headers });
  const convertedData = data.data.map(datum => ({
    ...datum,
    createdAt: new Date(datum.createdAt),
    statusUpdatedAt: new Date(datum.statusUpdatedAt),
  }));

  return {
    data: convertedData,
    meta: data.meta,
  };
};

export const useNotificationQuery = () => {
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  return useQuery(UserKeys.getNotificationKey, getNotificationQueryfn(Auth, slug), {
    onSuccess({ data, meta }) {
      useNotificationStore.getState().setUnseenNotifications(meta.unseen);
      useNotificationStore.getState().initialLoadNotifications(data);
    },
    enabled: Boolean(Auth),
  });
};

export enum PatchNotificationType {
  VIEW = 'view',
  READ = 'read',
  UNREAD = 'unread',
}

interface PatchNotificationParameter {
  notification: T.Notification;
  type: PatchNotificationType;
}

const patchNotificationMutationFn =
  (store: Store<T.State>) =>
  async ({ notification, type }: PatchNotificationParameter) => {
    const state = store.getState();
    const { id } = notification;
    const URL: string = makeV2APIURL('notifications', id, type);
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );

    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    await http.post<void>(URL, null, { headers });
    return { notification, type };
  };

export const usePatchNotificationMutation = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const mutationFn = useCallback(patchNotificationMutationFn(store), [store]);

  return useMutation(mutationFn, {
    onSuccess({ notification, type }) {
      switch (type) {
        case PatchNotificationType.READ:
          useNotificationStore
            .getState()
            .modifyNotificationStatus(notification.id, T.NotificationStatus.READ);
          break;
        case PatchNotificationType.VIEW:
        case PatchNotificationType.UNREAD:
          useNotificationStore
            .getState()
            .modifyNotificationStatus(notification.id, T.NotificationStatus.VIEWED);
          break;
        default:
          break;
      }
    },
    onError(error: AxiosError) {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      } else {
        dispatch(
          FinishPatchNotice({
            error: getRequestErrorType({ status: error.response?.status } as any),
          })
        );
      }
    },
  });
};

const patchNoticeMutationFn =
  (store: Store<T.State>) =>
  async ({ notice }: { notice: T.Notice }) => {
    const state = store.getState();
    const { id, isHidden, isShown, isRead } = notice;
    const URL: string = makeV2APIURL('releases', 'update-release-note');
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );
    const body: PatchNoticeBody = { id, isHidden, isShown, isRead };

    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    const formData: FormData = new FormData();
    Object.keys(body).forEach((key: keyof PatchNoticeBody) => {
      const val: PatchNoticeBody[keyof PatchNoticeBody] = body[key];
      if (val !== undefined) {
        formData.append(key, val.toString());
      }
    });
    const response = await http.patch<PatchNoticeResponse>(URL, formData, { headers });
    const notices = APIReleaseToNotice(response.data.data);
    if (Array.isArray(notices)) {
      return notices;
    } else {
      return [notices];
    }
  };
export const usePatchNoticeMutation = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const mutationFn = useCallback(patchNoticeMutationFn(store), [store]);

  return useMutation(mutationFn, {
    onMutate(notice) {
      dispatch(PatchNotice(notice));
    },
    onSuccess(notices) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      notices.forEach(_notice => {
        // mutate
        // dispatch(ChangeNotice({ notice }));
      });
      dispatch(FinishPatchNotice({}));
    },
    onError(error: AxiosError) {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      } else {
        dispatch(
          FinishPatchNotice({
            error: getRequestErrorType({ status: error.response?.status } as any),
          })
        );
      }
    },
  });
};

const patchPasswordMutationFn = async ({ token, password }: PatchPasswordBody) => {
  const URL: string = makeV2APIURL('users', 'password');
  const body: PatchPasswordBody = {
    token,
    password,
  };
  await http.patch<PatchPasswordBody>(URL, body);
};
export const usePatchPasswordMutation = () => {
  const dispatch = useDispatch();

  return useMutation(patchPasswordMutationFn, {
    onMutate(PatchPasswordBody) {
      dispatch(PatchPassword(PatchPasswordBody));
    },
    onSuccess() {
      dispatch(FinishPatchPassword({}));
    },
    onError(error: AxiosError) {
      dispatch(
        FinishPatchPassword({
          error: getRequestErrorType({ status: error.response?.status } as any),
        })
      );
    },
  });
};

const postPasswordResetMutationFn = async (resetPasswordRequestData: {
  resetPasswordRequestData: PostPasswordResetBody;
}) => {
  const URL: string = makeV2APIURL('users', 'password');

  const { resetPasswordRequestData: data } = resetPasswordRequestData;
  await http.post(URL, data);
};

export const usePostPasswordResetMutation = () => {
  const dispatch = useDispatch();
  return useMutation(postPasswordResetMutationFn, {
    onMutate(resetPasswordRequestData) {
      dispatch(PostPasswordReset(resetPasswordRequestData));
    },
    onSuccess() {
      dispatch(FinishPostPasswordReset({}));
    },
    onError(error: AxiosError) {
      dispatch(
        FinishPostPasswordReset({
          error: getRequestErrorType({ status: error.response?.status } as any),
        })
      );
    },
  });
};
