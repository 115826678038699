import { makeWSURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import * as T from '^/types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from './text';
import { toast } from 'react-toastify';
import CustomToast from '^/components/atoms/LogoutToast';
import { defaultToastInfoOption } from './useToastify';
import { useL10n } from './useL10n';

const overrideToastStyle = {
  width: '430px',
  padding: '20px',
  borderRadius: '8px',
};

export const SESSION_LOGOUT_TOAST_ID = 'session-logout-toast';

export const useSessionLogout = () => {
  const dispatch = useDispatch();
  const [l10n] = useL10n();
  const authedUser = useSelector((state: T.State) => state.Auth.authedUser);
  useEffect(() => {
    const token = authedUser?.token;
    const sessionId = authedUser?.currentSession?.id;

    if (!sessionId || !token) {
      return;
    }
    const socket = new WebSocket(`${makeWSURL('cable')}?token=${token}`);
    socket.addEventListener('open', () => {
      const channelIdentifier = JSON.stringify({
        channel: 'SessionChannel',
        id: sessionId,
      });
      const subscribeMessage = JSON.stringify({
        command: 'subscribe',
        identifier: channelIdentifier,
      });
      socket.send(subscribeMessage);
    });
    socket.addEventListener('message', event => {
      try {
        const data = JSON.parse(event.data);
        if (data.message.type === 'LOGOUT') {
          const unsubscribeSessionChannel = JSON.stringify({
            command: 'unsubscribe',
            identifier: JSON.stringify({ channel: 'SessionChannel' }),
          });
          toast(<CustomToast title={l10n(Text.title)} message={l10n(Text.description)} />, {
            ...defaultToastInfoOption,
            autoClose: false,
            style: overrideToastStyle,
            toastId: SESSION_LOGOUT_TOAST_ID,
          });
          dispatch(ChangeAuthedUser({}));
          socket.send(unsubscribeSessionChannel);
        }
      } catch (error) {}
    });
    return () => {
      socket.close();
    };
  }, [authedUser]);
};
