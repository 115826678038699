import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import Text from './text';
import { HorizontalDivider, ContentsListItem as Item } from '^/components/atoms/ContentsListItem';
import SingleSlider from '^/components/atoms/SingleSlider';
import { DEFAULT_OPACITY } from '^/constants/defaultContent';
import { DISABLED_CONTENT_OPACITY, FontFamily } from '^/constants/styles';
import { UseL10n, UseState, useConstant, useL10n } from '^/hooks';
import { PatchContent, contentsSelector } from '^/store/duck/Contents';
import * as T from '^/types';
import { Fallback } from './fallback';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import dsPalette from '^/constants/ds-palette';
import { useCalibrateHeight } from '^/hooks/useCalibrateHeight';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import QuestionMarkSvg from '^/assets/icons/question-mark.svg';

const SINGLE_SLIDER_MIN_VALUE: number = 0;
const SINGLE_SLIDER_MAX_VALUE: number = 100;

const Balloon2 = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Balloon3 = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});

const CalibratedText = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: dsPalette.title.toString(),
});

const Opacity = styled.div({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',

  marginTop: '13px',
});

const OpacityText = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  marginBottom: '13px',

  color: 'var(--color-theme-primary)',
  fontFamily: FontFamily.ROBOTO,
  fontSize: '15px',
  fontWeight: 500,
}));

const tooltipStyle: WrapperHoverableProps['customStyle'] = {
  tooltipWrapperStyle: {
    position: 'relative',
    fontSize: '10px',
    display: 'inline-block',
    textAlign: 'center',
  },
  tooltipBalloonStyle: {
    maxWidth: 'unset',
    left: '50%',
    bottom: 'auto',
    padding: '5px',
    width: '180px',
    transform: 'translate(-50%, 3px)',
  },
  tooltipTextTitleStyle: {
    whiteSpace: 'pre-wrap',
    lineHeight: 1.25,
  },
};

const InfoContainer = styled.div({
  width: '12px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  marginLeft: '3px',
});

export interface Props {
  readonly content: T.ThreeDMeshContent;
}

export const RawContentsListThreeDMeshItem: FC<Props> = ({ content }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const { isLoading, calibrateOffsetHeight } = useCalibrateHeight();

  const currentMeshEngine: T.MeshEngine = useSelector(
    (s: T.State) => s.Pages.Contents.currentMeshEngine
  );
  const contentId: T.Content['id'] = useConstant<T.Content['id']>(() => content.id);
  const isThreeDMeshCalibrated = content.info?.offsetCalibrated ?? false;
  // const meshRef = useRef<THREE.Object3D | undefined>(undefined);

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const project: T.Project | undefined = useSelector((s: T.State) =>
    projectId ? s.Projects.projects.byId[projectId] : undefined
  );
  const coordinateSystem: T.ProjectionEnum | undefined = project?.coordinateSystem;
  const isBesselCoordinateSystem =
    coordinateSystem && coordinateSystem === T.ProjectionEnum.Bessel_EPSG_5174_EN;

  const [opacity, setOpacity]: UseState<T.ThreeDMeshConfigPerUser['opacity']> = useState<
    T.ThreeDMeshConfigPerUser['opacity']
  >(content.config?.opacity !== undefined ? content.config.opacity : DEFAULT_OPACITY);

  const isProcessingOrFailed: boolean = contentsSelector.isProcessingOrFailedByContent(content);

  const handleOpacityChange: (opacity: T.ThreeDMeshConfigPerUser['opacity']) => void = useCallback(
    changingOpacity => {
      setOpacity(changingOpacity);
    },
    []
  );
  const handleOpacityMouseUp: () => void = useCallback(() => {
    dispatch(
      PatchContent({
        content: {
          id: contentId,
          config: {
            type: T.ContentType.THREE_D_MESH,
            opacity,
          },
        },
      })
    );
  }, [opacity]);

  const pinSettingDescription: ReactNode = (
    <WrapperHoverable title={l10n(Text.calibrateDescription)} customStyle={tooltipStyle}>
      <InfoContainer>
        <QuestionMarkSvg />
      </InfoContainer>
    </WrapperHoverable>
  );

  const calibratedToggleButton = useMemo(() => {
    if (isBesselCoordinateSystem) {
      return (
        <>
          <HorizontalDivider />
          <Balloon2>
            <>
              <Balloon3>
                <CalibratedText>{l10n(Text.calibrateText)}</CalibratedText>
                {pinSettingDescription}
              </Balloon3>
              {isLoading ? (
                <LoadingIcon />
              ) : (
                <ToggleSlider
                  onClick={async () => calibrateOffsetHeight(!isThreeDMeshCalibrated)}
                  enabled={isThreeDMeshCalibrated}
                  data-ddm-track-action={T.TrackActions.CONTENT_ITEM}
                  data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_CALIBRATE_LOCATION}-${
                    isThreeDMeshCalibrated ? 'off' : 'on'
                  }`}
                />
              )}
            </>
          </Balloon2>
        </>
      );
    }
    return null;
  }, [isBesselCoordinateSystem, isThreeDMeshCalibrated, isLoading]);

  return (
    <Item content={content} firstBalloonTitle={l10n(Text.firstBalloonTitle)}>
      <Opacity>
        {/* threejs mesh doesn't support opacity */}
        <OpacityText isDisabled={isProcessingOrFailed}>
          {currentMeshEngine === T.MeshEngine.THREEJS ? 100 : opacity.toFixed(0)}%
        </OpacityText>
        <SingleSlider
          minValue={SINGLE_SLIDER_MIN_VALUE}
          maxValue={SINGLE_SLIDER_MAX_VALUE}
          value={currentMeshEngine === T.MeshEngine.THREEJS ? 100 : opacity}
          onChange={handleOpacityChange}
          onMouseUp={handleOpacityMouseUp}
          isDisabled={currentMeshEngine === T.MeshEngine.THREEJS ? true : false}
        />
      </Opacity>
      {calibratedToggleButton}
    </Item>
  );
};

export const ContentsListThreeDMeshItem: FC<Props> = withErrorBoundary(
  RawContentsListThreeDMeshItem
)(Fallback);
