import * as T from '^/types';

export default {
  emptyData: {
    [T.Language.KO_KR]: '데이터가 없습니다.',
    [T.Language.EN_US]: 'No datas at the moment',
  },
  createText: {
    [T.Language.KO_KR]: '비행 일정 만들기',
    [T.Language.EN_US]: 'Create Flight Schedule',
  },
};
