import { Language } from '^/types';

export default {
  Yes: {
    [Language.KO_KR]: '예',
    [Language.EN_US]: 'Yes',
  },
  No: {
    [Language.KO_KR]: '아니오',
    [Language.EN_US]: 'No',
  },

  info: {
    [Language.KO_KR]: '드론 스테이션에서 수집한 원본 사진을 자동으로 처리하기 위한 설정입니다.',
    [Language.EN_US]:
      'These settings are for the automatic processing of the source photo collected from drone station.',
  },
  rtk: {
    [Language.KO_KR]: 'RTK를 사용한 자동 처리',
    [Language.EN_US]: 'Auto Processing with RTK',
  },
  gcp: {
    [Language.KO_KR]: 'GCP를 사용한 자동 처리',
    [Language.EN_US]: 'Auto Processing with GCPs',
  },
};
