import { createHeightsAndColors } from '^/constants/palette';
import * as T from '^/types';
import { VALUES_PER_METER, determineUnitType } from '^/utilities/imperial-unit';
import { convertPercentToRanged } from '^/utilities/math';
import { useSelector } from 'react-redux';

interface DSMDTM {
  minHeight: T.DSMContent['info']['minHeight'] | T.DTMContent['info']['minHeight'] | null;
  maxHeight: T.DSMContent['info']['maxHeight'] | T.DTMContent['info']['maxHeight'] | null;
}

export function useCalculateDSMDTMParams() {
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);
  const project = useSelector((s: T.State) => s.Projects.projects.byId[projectId ?? NaN]);
  const unitType = project ? determineUnitType(project?.unit) : determineUnitType(projectUnit);

  function getDSMDTMParams(
    content1?: T.DSMContent | T.DTMContent,
    content2?: T.DSMContent | T.DTMContent,
    content3?: T.DSMContent | T.DTMContent,
    content4?: T.DSMContent | T.DTMContent
  ) {
    if (!content1 && !content2 && !content3 && !content4) {
      return {
        minInput: null,
        maxInput: null,
        defaultHeightsAndColors: createHeightsAndColors(0, 255),
      };
    }

    const contents = [content1, content2, content3, content4].filter(Boolean);

    const calculatedMinHeight = Math.min(
      ...contents.map(content => content!.info.minHeight!).filter(Boolean)
    );

    const calculatedMaxHeight = Math.max(
      ...contents.map(content => content!.info.maxHeight!).filter(Boolean)
    );

    const { minHeight, maxHeight }: DSMDTM = {
      minHeight: calculatedMinHeight ? calculatedMinHeight * VALUES_PER_METER[unitType] : null,
      maxHeight: calculatedMaxHeight ? calculatedMaxHeight * VALUES_PER_METER[unitType] : null,
    };

    const minInput: string | null = convertPercentToRanged(
      0,
      minHeight as NonNullable<DSMDTM['minHeight']>,
      maxHeight as NonNullable<DSMDTM['maxHeight']>
    ).toFixed(2);

    const maxInput: string | null = convertPercentToRanged(
      1,
      minHeight as NonNullable<DSMDTM['minHeight']>,
      maxHeight as NonNullable<DSMDTM['maxHeight']>
    ).toFixed(2);

    const defaultHeightsAndColors = createHeightsAndColors(
      Number(minInput) || 0,
      Number(maxInput) || 255
    );

    return { minInput, maxInput, defaultHeightsAndColors };
  }

  return { getDSMDTMParams };
}
