import { Language, TerrainEditingMethod } from '^/types';

//FIXME:  correct text
export default {
  itemTitle: {
    planeFitting: {
      [Language.EN_US]: 'Plane Fitting',
      [Language.KO_KR]: '평면 맞추기',
    },
    triangulation: {
      [Language.EN_US]: 'Triangulation',
      [Language.KO_KR]: '삼각망 구성',
    },
  },
  placeholder: {
    [Language.EN_US]: 'Please select the a base plane',
    [Language.KO_KR]: '기준 밑면을 선택해주세요',
  },
  tutorial: {
    [TerrainEditingMethod.TRIANGULATION]: {
      title: {
        [Language.EN_US]: 'Triangulation',
        [Language.KO_KR]: '삼각망 구성',
      },
      description: {
        [Language.EN_US]: '',
        [Language.KO_KR]: '',
      },
    },
    [TerrainEditingMethod.PLANE_FITTING]: {
      title: {
        [Language.EN_US]: 'Plane Fitting',
        [Language.KO_KR]: '평면 맞추기',
      },
      description: {
        [Language.EN_US]: '',
        [Language.KO_KR]: '.',
      },
    },
  },
};
