import SegmentedButton, { TButtonPosition } from '^/components/atoms/SegmentedButton';
import React from 'react';
import styled, { CSSObject } from 'styled-components';

const Switch2d3dMinimapWrapper = styled.div({
  position: 'absolute',
  top: '10px',
  left: '10px',
  display: 'flex',
});
interface TProps {
  is2DMinimapSelected: boolean;
  handle2DChangeTo3DMap(value: boolean): void;
  switchButtonWrapperStyle?: CSSObject;
}

const getSegmentedButtonStylss = (isSelected: boolean, is2DBtn: boolean) => ({
  width: '36px',
  backgroundColor: isSelected ? '#DEE3EB' : '#ffffff',
  color: isSelected ? '#656D82' : '#9BA9C0',
  borderColor: '#D5D5D8',
  height: '32px',
  border: '1px solid #D5D5D8',
  borderRightWidth: is2DBtn ? '0px' : '1px',
  borderLeftWidth: !is2DBtn ? '0px' : '1px',
  fontSize: '12px',
});

const Switch2d3dMinimap = (props: TProps) => {
  const { is2DMinimapSelected, handle2DChangeTo3DMap, switchButtonWrapperStyle } = props;
  return (
    <Switch2d3dMinimapWrapper style={switchButtonWrapperStyle}>
      <SegmentedButton
        onClick={() => {
          handle2DChangeTo3DMap(true);
        }}
        isSelected={is2DMinimapSelected}
        btnPosition={TButtonPosition.LEFT}
        text="2D"
        segmentedButtonStyles={getSegmentedButtonStylss(is2DMinimapSelected, true)}
      />
      <SegmentedButton
        onClick={() => {
          handle2DChangeTo3DMap(false);
        }}
        isSelected={!is2DMinimapSelected}
        btnPosition={TButtonPosition.RIGHT}
        text="3D"
        segmentedButtonStyles={getSegmentedButtonStylss(!is2DMinimapSelected, false)}
      />
    </Switch2d3dMinimapWrapper>
  );
};

export default Switch2d3dMinimap;
