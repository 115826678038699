import * as Sentry from '@sentry/browser';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '^/config';
import { AuthHeader, makeAuthHeader, makeV2APIURL, makeVersionHeader } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import {
  FinishPostPhoto,
  FinishUploadPhotos,
  GetPhotos,
  PostBulkPhotoUploadResponse,
  SetUploadedPhotoCount,
} from '^/store/duck/Photos';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { isErrorIgnorable } from '^/utilities/http-response';
import { runPromisesInBatches } from '^/utilities/promise';
// import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import {
  FileMultipartUpload,
  isFileMultipartUploadProgress,
} from '^/store/rxjs/fileMultipartUpload';
import axios from 'axios';
import { useCallback } from 'react';
import { PhotoKeys } from './api/photos/usePhoto';
import { useViewpointStore } from '^/store/viewpointStore';

interface UploadPhotoProps {
  readonly files: File[];
  readonly photoType?: T.PhotoType;
}

/**
 * TODO: @ebraj-angelswing
 * Need to update it...
 */
const postPhotoLists: Record<T.PhotoAlbumType, string> = {
  [T.PhotoAlbumType.DRONE]: 'drone',
  [T.PhotoAlbumType.THREE_SIXTY]: 'three_sixty',
  [T.PhotoAlbumType.THREE_SIXTY_SOURCE]: 'three_sixty_source',
  [T.PhotoAlbumType.THREE_SIXTY_STITCHED]: 'three_sixty_stitched',
  [T.PhotoAlbumType.THREE_SIXTY_VSLAM]: 'three_sixty_vslam',
  [T.PhotoAlbumType.THREE_SIXTY_VIDEO]: 'three_sixty_video',
  [T.PhotoAlbumType.INSPECTION]: 'normal',
  [T.PhotoAlbumType.SOURCE]: 'normal',
  [T.PhotoAlbumType.NORMAL]: 'normal',
  [T.PhotoAlbumType.INTEGRATION]: 'normal',
  [T.PhotoAlbumType.VIEWPOINT]: 'viewpoint',
};

/**
 * WIP: refactor UploadPhotos
 * @author hyunwoo-angelswing
 */
export function useUploadPhotos() {
  const dispatch = useDispatch();

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();

  const headers = {
    ...authHeader,
    ...versionHeader,
  };

  async function uploadPhotos({ files, photoType }: UploadPhotoProps) {
    if (projectId === undefined || authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    const URL: string = makeV2APIURL('projects', projectId, 'photos');

    let finishedRequestCount: number = 0;
    let failedRequestCount: number = 0;

    const uploadPromises = files.map(async file => {
      try {
        const formData: FormData = new FormData();
        formData.append('image', file);
        formData.append('type', photoType === 'drone' ? 'drone' : 'normal');

        await http.post(URL, formData, { headers });
        finishedRequestCount++;

        dispatch(SetUploadedPhotoCount({ count: finishedRequestCount + 1 }));
        dispatch(FinishPostPhoto());
      } catch (error) {
        failedRequestCount++;
      }
    });

    const results = await Promise.allSettled(uploadPromises);

    const isSuccess = results.every(result => result.status === 'fulfilled');

    if (!isSuccess) {
      alert('One or more photos failed to upload.');
      return;
    }

    dispatch(GetPhotos({ projectId }));
    dispatch(FinishUploadPhotos());

    if (files.length === finishedRequestCount) {
      if (failedRequestCount > 0) {
        dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.PHOTO_UPLOAD_FAIL }));
        return;
      }
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.PHOTO_UPLOAD_SUCCESS }));
    } else {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.PHOTO_UPLOAD_FAIL }));
    }
  }

  return { uploadPhotos };
}

const usePhotoBulkUpload = () => {
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const setIsViewpointUploading = useViewpointStore(s => s.setIsViewpointUploading);
  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const setViewpointCaptureStep = useViewpointStore(s => s.setViewpointCaptureStep);

  const uploadPhotos = useCallback(
    async (
      files: UploadPhotoProps['files'],
      photoType?: UploadPhotoProps['photoType'],
      panoUUID?: string,
      albumId?: string | number,
      shouldShowConfirmationPopup = true
    ) => {
      if (!projectId) {
        return;
      }

      if (sidebarTab === T.ContentPageTabType.VIEWPOINT_CAPTURE) {
        setViewpointCaptureStep(T.ViewpointCaptureSteps.UPLOADING);
      }

      const isKSAregion = config.region === T.Region.KSA;
      const URL = makeV2APIURL('projects', projectId, 'photos');
      const PANO_GENERATION_URL = makeV2APIURL('projects', projectId, 'photos', 'process_panorama');
      const header = makeAuthHeader(Auth, slug);
      let finishedRequestCount: number = 0;
      let failedRequestCount: number = 0;

      if (!header) {
        dispatch(ChangeAuthedUser({}));
        return;
      }

      const postPhoto = async (file: File) => {
        const finalPhotoType = photoType ? postPhotoLists[photoType as T.PhotoAlbumType] : 'normal';

        try {
          const formData = new FormData();
          formData.append('image', file.name);
          formData.append('type', finalPhotoType);
          if (albumId) {
            formData.append('photo_album_id', String(albumId));
          }
          if (photoType === 'three_sixty_source') {
            formData.append('pano_uuid', panoUUID!);
          }

          const res = await http.post(URL, formData, { headers: header });
          const metaResponse: PostBulkPhotoUploadResponse = res.data;
          const uploadFormData = new FormData();

          if (!isKSAregion) {
            Object.entries(metaResponse.fields).forEach(([key, value]) => {
              uploadFormData.append(key, value);
            });
            uploadFormData.append('file', file);
          }

          if (isKSAregion) {
            const uploadRequest = axios.put(metaResponse.url, file, {
              headers: { 'Content-Type': file.type },
            });
            await uploadRequest;
          } else {
            const fileMultipartUpload = new FileMultipartUpload({
              file,
              header,
              bucketFileName: metaResponse.key,
            });

            const uploadPromise = new Promise<string>((resolve, reject) => {
              fileMultipartUpload.create(metaResponse).subscribe({
                next: response => {
                  if (isFileMultipartUploadProgress(response)) {
                    return;
                  }

                  resolve(response);
                },
                error: error => {
                  reject(error);
                },
                complete: async () => {},
              });
            });

            await uploadPromise;
          }

          const finishUrl = makeV2APIURL('photos', metaResponse.photo_id, 'upload_complete');
          await http.put(finishUrl, null, { headers: header });

          finishedRequestCount++;
          dispatch(SetUploadedPhotoCount({ count: finishedRequestCount + 1 }));
          dispatch(FinishPostPhoto());
        } catch (e) {
          if (!isErrorIgnorable(e.status)) {
            Sentry.captureException(e);
          }
          finishedRequestCount++;
          failedRequestCount++;
          dispatch(SetUploadedPhotoCount({ count: finishedRequestCount + 1 }));
          dispatch(FinishPostPhoto());
        }
      };

      const postPhotosPromises = files.map((file: File) => async () => postPhoto(file));

      if (photoType === 'three_sixty') {
        /**
         * For three-sixty if we trigger the post photos simultaneously (multiple at a time),
         * then we might get the error as the BE needs time to generate.
         */
        await runPromisesInBatches(postPhotosPromises, 8);
      } else {
        await runPromisesInBatches(postPhotosPromises, 8);
      }

      if (failedRequestCount === 0 && photoType === T.PhotoAlbumType.THREE_SIXTY_SOURCE) {
        const panoramaPayload = {
          panoUuid: panoUUID!,
        };
        try {
          await http.post(PANO_GENERATION_URL, panoramaPayload, { headers: header });
        } catch (error) {
          console.log('Error on pano generation trigger', error);
        }
      }

      dispatch(GetPhotos({ projectId }));
      dispatch(FinishUploadPhotos());
      setIsViewpointUploading(false);
      setViewpointCaptureStep(T.ViewpointCaptureSteps.FETCHING_DATA);
      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: PhotoKeys.getPhotoAlbumById(Number(albumId)),
        }),
        queryClient.invalidateQueries({
          queryKey: PhotoKeys.photoAlbumQuery(projectId),
        }),
        queryClient.invalidateQueries({
          queryKey: PhotoKeys.getNonPhotoAlbumKey(projectId),
        }),
      ]);
      setViewpointCaptureStep(null);

      if (shouldShowConfirmationPopup) {
        dispatch(
          OpenContentPagePopup({
            popup:
              failedRequestCount > 0
                ? T.ContentPagePopupType.PHOTO_UPLOAD_FAIL
                : T.ContentPagePopupType.PHOTO_UPLOAD_SUCCESS,
            photoType: failedRequestCount === 0 ? photoType : undefined,
          })
        );
      }
    },
    [projectId, Auth, slug, dispatch]
  );

  return { uploadPhotos };
};

export default usePhotoBulkUpload;
