import React, { ChangeEvent, FC, useState, MouseEvent, useRef, useEffect } from 'react';

import { UseL10n, useL10n } from '^/hooks';
import Text from './text';
import {
  ControlContainer,
  DurationText,
  DurationWrapper,
  IconButton,
  BottomContainer,
  SliderInput,
  SliderRoot,
  VolumeContainer,
  VolumeWrapper,
  PlayBackRateText,
  Flexbox,
  PlayBackMenu,
  PlayRateText,
  PlayBackRateWrapper,
  PlaySpeedText,
  PlayBackButton,
  TopContainer,
  Loader,
  ScreenPlayPauseWrapper,
} from '../VideoPlayer/style';
import {
  ExitFullScreenMode,
  FastForwardIcon,
  FullScreenMode,
  MuteIcon,
  PauseIcon,
  PlayBackRate,
  PlayIcon,
  RewindIcon,
  VolumeIcon,
} from '^/assets/icons/photo/video-icons';
import {
  ForwardIconWithTimer,
  RewindIconWithTimer,
  VideoSeekSlider,
  VideoSpinner,
} from './Component';
import { formatTime } from '../PhotoList/util';
import CustomTooltip from '^/components/atoms/CustomTooltip';

interface ControlProps {
  onPlayPause(): void;
  playing?: boolean;
  onRewind(): void;
  onForward(): void;
  played: number;
  onSeek?(event: ChangeEvent<HTMLInputElement>): void;
  onSeekMouseUp(event: MouseEvent<HTMLInputElement>): void;
  onMouseSeekDown(): void;
  buffer: boolean;

  onVolumeChangeHandler(value: number | number[]): void;
  onVolumeSeekUp(value: number | number[]): void;
  volume?: number;
  mute?: boolean;
  onMute(): void;
  duration: number;
  currentTime: number;
  controlRef: React.Ref<HTMLDivElement> | undefined;
  playRate?: number;
  handleClickFullscreen(): void;
  fullScreenMode: boolean;
  handlePlayBackRateChange(value: number): void;
  seeking?: boolean;
  loaded?: number;
}

const MIN_VALUE: number = 0;
const MAX_VALUE: number = 100;
const PLAY_PAUSE_TIMER = 1000;
const FAST_REWIND_TIMER = 900;

const playbackRates = [
  { value: 2, label: '2x' },
  { value: 1.5, label: '1.5x' },
  { value: 1, label: 'Normal ( 1x )' },
  { value: 0.5, label: '0.5x' },
  { value: 0.75, label: '0.75x' },
];

const Controls: FC<ControlProps> = ({
  onPlayPause,
  playing,
  onRewind,
  onForward,
  played,
  onSeek,
  onSeekMouseUp,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  onMouseSeekDown,
  handlePlayBackRateChange,
  mute,
  onMute,
  duration,
  currentTime,
  controlRef,
  fullScreenMode,
  handleClickFullscreen,
  playRate,
  buffer,
  loaded,
}) => {
  const [openPlayback, setOpenPlayBack] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showforwardIcon, setShowForwardIcon] = useState(false);
  const [showRewindIcon, setShowRewindIcon] = useState(false);

  const [l10n]: UseL10n = useL10n();
  const playbackContainerRef = useRef<HTMLDivElement | null>(null);

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  const handleDocumentClick: ({ target }: any) => void = ({ target }) => {
    if (
      playbackContainerRef.current !== null &&
      !playbackContainerRef.current.contains(target as Node)
    ) {
      setOpenPlayBack(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    document.addEventListener('touchend', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
      document.removeEventListener('touchend', handleDocumentClick, false);
    };
  }, []);

  const handlePlayPauseRewindForward: (task: string) => void = task => {
    let timeout: NodeJS.Timeout;

    switch (task) {
      case 'play':
        {
          setShowIcon(true);
          onPlayPause();

          timeout = setTimeout(() => {
            setShowIcon(false);
          }, PLAY_PAUSE_TIMER);
        }
        break;

      case 'forward':
        {
          setShowForwardIcon(true);
          onForward();

          timeout = setTimeout(() => {
            setShowForwardIcon(false);
          }, FAST_REWIND_TIMER);
        }
        break;

      case 'rewind':
        {
          setShowRewindIcon(true);
          onRewind();

          timeout = setTimeout(() => {
            setShowRewindIcon(false);
          }, FAST_REWIND_TIMER);
        }
        break;

      default:
        break;
    }

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleChange: (evt: ChangeEvent<HTMLInputElement>) => void = ({
    target: { value: changedValue },
  }: ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseFloat(changedValue);

    onVolumeChangeHandler(numberValue);
  };

  const onMouseUp: React.MouseEventHandler<HTMLInputElement> = evt => {
    const changedValue = parseFloat(evt.currentTarget.value);
    onVolumeSeekUp(changedValue);
  };

  return (
    <ControlContainer ref={controlRef}>
      <ScreenPlayPauseWrapper
        onDoubleClick={handleClickFullscreen}
        onClick={() => handlePlayPauseRewindForward('play')}
      />

      <TopContainer>
        {buffer && (
          <Loader side="center">
            <VideoSpinner />
          </Loader>
        )}

        <Loader side="center" hide={!showIcon}>
          {!playing ? (
            <span style={{ paddingLeft: '10px' }}>
              <PlayIcon height={42} width={35} />
            </span>
          ) : (
            <PauseIcon height={42} width={28} />
          )}
        </Loader>

        <Loader hide={!showRewindIcon} side="left">
          <RewindIconWithTimer />
        </Loader>

        <Loader hide={!showforwardIcon} side="right">
          <ForwardIconWithTimer />
        </Loader>
      </TopContainer>

      <VideoSeekSlider
        played={played}
        loaded={loaded}
        onMouseSeekDown={onMouseSeekDown}
        onSeek={onSeek}
        onSeekMouseUp={onSeekMouseUp}
        duration={duration}
      />

      <BottomContainer>
        <Flexbox>
          {/* Rewind */}

          <IconButton onClick={() => handlePlayPauseRewindForward('rewind')}>
            <CustomTooltip placement="top-start" content={l10n(Text.rewindTooltip)}>
              <RewindIcon />
            </CustomTooltip>
          </IconButton>
          {/* Play Pause */}
          <IconButton onClick={onPlayPause}>
            <CustomTooltip
              placement="top"
              content={!playing ? l10n(Text.pauseTooltip) : l10n(Text.playTooltip)}
            >
              {playing ? <PauseIcon /> : <PlayIcon />}
            </CustomTooltip>
          </IconButton>
          {/* // Forward */}
          <IconButton onClick={() => handlePlayPauseRewindForward('forward')}>
            <CustomTooltip placement="top" content={l10n(Text.forwardlTooltip)}>
              <FastForwardIcon />
            </CustomTooltip>
          </IconButton>
          {/* // Volume Controls */}
          <VolumeContainer>
            <CustomTooltip
              placement="top"
              content={!mute ? l10n(Text.volumeMute) : l10n(Text.volumeUnMute)}
            >
              <IconButton style={{ padding: '5px' }} onClick={onMute}>
                {mute ? <MuteIcon /> : <VolumeIcon />}
              </IconButton>
            </CustomTooltip>
            <VolumeWrapper>
              <SliderRoot>
                <SliderInput
                  onChange={handleChange}
                  changedValue={volume ? volume * 100 : 0}
                  onMouseUp={onMouseUp}
                  type="range"
                  min={MIN_VALUE}
                  max={MAX_VALUE}
                  // value={volume}
                />
              </SliderRoot>
            </VolumeWrapper>
          </VolumeContainer>
          {/* // Duration Time */}
          <DurationWrapper>
            <DurationText>
              {formatCurrentTime} / {formatDuration}
            </DurationText>
          </DurationWrapper>
        </Flexbox>

        <Flexbox ref={playbackContainerRef}>
          {/* // Play Back Rate */}

          <PlayBackButton isSelected={openPlayback} onClick={() => setOpenPlayBack(!openPlayback)}>
            <span>
              <CustomTooltip placement="top-end" content={l10n(Text.changePlaybackSpeed)}>
                <Flexbox>
                  <PlayBackRate />
                  <PlayBackRateText> {playRate}x </PlayBackRateText>
                </Flexbox>
              </CustomTooltip>

              {openPlayback ? (
                <PlayBackMenu>
                  <PlaySpeedText>Playback Speed</PlaySpeedText>
                  <PlayBackRateWrapper>
                    {playbackRates.map((rate, index) => (
                      <PlayRateText
                        onClick={() => handlePlayBackRateChange(rate.value)}
                        role="button"
                        key={index}
                      >
                        {rate.label}
                      </PlayRateText>
                    ))}
                  </PlayBackRateWrapper>
                </PlayBackMenu>
              ) : null}
            </span>
          </PlayBackButton>

          {/* 
          // Full Screen Controls */}

          <IconButton onClick={handleClickFullscreen}>
            <CustomTooltip
              placement="top-end"
              content={
                !fullScreenMode ? l10n(Text.fullScreenTooltip) : l10n(Text.exitFullScreenTooltip)
              }
            >
              <span>{!fullScreenMode ? <FullScreenMode /> : <ExitFullScreenMode />}</span>
            </CustomTooltip>
          </IconButton>
        </Flexbox>
      </BottomContainer>
    </ControlContainer>
  );
};

export default Controls;
