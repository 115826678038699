import React, { FC, useCallback, useEffect, useState, MouseEvent, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { EditableText, Props as EditableTextProps } from '../EditableText';
import route from '^/constants/routes';
import {
  UseEditableTextOutput,
  UseL10n,
  UseShouldContentDisabled,
  UseState,
  useEditableText,
  useIsRoleX,
  useL10n,
  useRouteIsMatching,
  useShouldContentDisabled,
} from '^/hooks';
import { PatchContent } from '^/store/duck/Contents';
import * as T from '^/types';
import { getContentTitle } from '^/utilities/annotation-content-util';
import { isRoleViewer } from '^/utilities/role-permission-check';
import { ContentFilterContext } from '^/components/molecules/ContentsSearchBox/context';
import { useESSContents } from '^/hooks/useESSContents';
import useIssueContents from '^/hooks/useIssueContents';
import { useUtilsStore } from '^/store/utilsStore';

export interface Props {
  readonly id?: string;
  readonly content: T.Content;
  readonly isEditing?: boolean;
  readonly fromUI: T.EditableTextUI;
}

export const ContentsListItemTitle: FC<Props> = ({ id, content, isEditing = false, fromUI }) => {
  const { updateESSContent } = useESSContents();
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const isOnSharePage: boolean = useRouteIsMatching(route.share.main);
  const isViewer: boolean = useIsRoleX(isRoleViewer);
  const shouldContentDisabled: UseShouldContentDisabled = useShouldContentDisabled(content.type);
  const { filterText } = useContext(ContentFilterContext);
  const setEditingTitleContentId = useUtilsStore(s => s.setEditingTitleContentId);

  const { patchIssueContent } = useIssueContents();

  const [isTitleEditing, setIsTitleEditing]: UseState<Readonly<boolean>> =
    useState<Readonly<boolean>>(false);

  const title: string = getContentTitle(content, l10n);

  const isTitleEditable: boolean =
    content.type !== T.ContentType.FLIGHT_PLAN &&
    content.type !== T.ContentType.FLIGHT_SCHEDULE &&
    !T.DSMorMapContentTypes.includes(content.type);

  const updateTitle: (newTitle: string) => void = useCallback(
    newTitle => {
      switch (content.category) {
        case T.ContentCategory.ESS: {
          void updateESSContent({
            content: { id: content.id, title: newTitle },
            isUndoable: true,
            isTitle: true,
          });
          break;
        }
        case T.ContentCategory.ISSUE: {
          patchIssueContent({ content: { id: content.id, title: newTitle }, isUndoable: true });
          break;
        }
        default:
          dispatch(
            PatchContent({ content: { id: content.id, title: newTitle }, isUndoable: true })
          );
      }
    },
    [content.category]
  );

  useEffect(
    () => () => {
      const lastTitleBeforeUnmount: string | undefined = editingTextRef.current?.value;
      if (
        lastTitleBeforeUnmount !== undefined &&
        lastTitleBeforeUnmount !== content.title &&
        lastTitleBeforeUnmount.trim().length
      ) {
        updateTitle(lastTitleBeforeUnmount);
      }
    },
    [updateTitle]
  );

  const handleTitleSave: () => void = () => {
    if (!editingText.trim().length) {
      setIsTitleEditing(false);
      setEditingTitleContentId(null);

      return;
    }

    if (content.title !== editingText) {
      updateTitle(editingText);
    }
    setIsTitleEditing(false);
    setEditingTitleContentId(null);
  };

  const { editingTextRef, editingText, setEditingText, ...otherProps }: UseEditableTextOutput =
    useEditableText({
      handleTextSave: handleTitleSave,
      defaultText: content.title,
      isEditing: isTitleEditing,
      setIsEditing: setIsTitleEditing,
      contentId: content.id,
    });

  const handleTextDivClick: ((e: MouseEvent<HTMLDivElement>) => void) | undefined =
    !shouldContentDisabled && isTitleEditable && !isOnSharePage && !isViewer
      ? e => {
          if (isEditing) {
            e.stopPropagation();
          }

          setEditingText(content.title);
          setIsTitleEditing(true);
        }
      : undefined;

  const editableTextProps: EditableTextProps = {
    ...otherProps,
    id,
    editingTextRef,
    editingText,
    isTextEditable: isTitleEditable,
    isTextEditing: isTitleEditing,
    fromUI,
    text: title,
    isGenericName: T.DSMorMapContentTypes.includes(content.type),
    handleTextDivClick,
    isDisabled: shouldContentDisabled,
    filterText,
  };

  return <EditableText {...editableTextProps}>{title}</EditableText>;
};
