import { config } from '^/config';
import { AuthHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import {
  FinishPostPhoto,
  FinishUploadPhotos,
  GetDroneVideos,
  PostBulkPhotoUploadResponse,
} from '^/store/duck/Photos';
import * as T from '^/types';
import axios from 'axios';
import { mediaInfoFactory, VideoTrack } from 'mediainfo.js';
import { useDispatch, useSelector } from 'react-redux';
import { useLastSelectedScreen } from './useLastSelectedScreen';

interface UploadVideoProps {
  readonly files: File[];
  readonly photoType?: T.PhotoType;
  readonly screen?: T.Screen;
}

interface VideoDimensions {
  width: number;
  height: number;
  // takenDate: Date;
}

export async function getVideoDimensions(file: File): Promise<VideoDimensions> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const readChunk = async (chunkSize: number, offset: number) =>
        new Uint8Array(await file.slice(offset, offset + chunkSize).arrayBuffer());

      mediaInfoFactory({ format: 'object' }, mediainfo => {
        mediainfo
          .analyzeData(file.size, readChunk)
          .then(result => {
            const videoTrack = result.media?.track.find(
              track => track['@type'] === 'Video'
            ) as VideoTrack;
            if (videoTrack) {
              resolve({
                width: videoTrack.Sampled_Width ?? 0,
                height: videoTrack.Sampled_Height ?? 0,
              });
            } else {
              throw new Error('Failed to extract video metadata');
            }
          })
          .catch(() => {
            reject(new Error('Failed to extract video metadata'));
          });
      });
    } catch (error) {
      reject(new Error('Failed to extract video metadata'));
    }
  });
}

export function useUploadVideo() {
  const dispatch = useDispatch();
  const lastSelectedScreen: T.Screen | undefined = useLastSelectedScreen();

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);

  async function uploadVideos({ files }: UploadVideoProps) {
    if (projectId === undefined || authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    try {
      const { width, height } = await getVideoDimensions(files[0]);

      if (!lastSelectedScreen) {
        throw new Error('No last selected screen');
      }
      const originalFilename = files[0].name;

      const isKSAregion = config.region === T.Region.KSA;
      let URL: string;
      if (isKSAregion) {
        URL = makeV2APIURL('projects', projectId, 'videos', `new?fileName=${originalFilename}`);
      } else {
        URL = makeV2APIURL('projects', projectId, 'videos', 'new');
      }
      const header = makeAuthHeader(Auth, slug);

      const res = await axios.get(URL, { headers: header });
      const metaResponse: PostBulkPhotoUploadResponse = res.data;

      const randomString = Math.random().toString(36).substring(15);

      const fileExtension = originalFilename.split('.').pop();
      const fileName = encodeURIComponent(originalFilename.split('/').pop()!);
      const newFilename = `${fileName.split('.')[0]}_${randomString}.${fileExtension}`;

      let keyFileName: string;
      if (isKSAregion) {
        // eslint-disable-next-line no-template-curly-in-string
        keyFileName = metaResponse.key.replace('${filename}', newFilename);
      } else {
        // eslint-disable-next-line no-template-curly-in-string
        keyFileName = metaResponse.fields.key.replace('${filename}', newFilename);
      }
      const uploadFormData = new FormData();

      if (!isKSAregion) {
        Object.entries(metaResponse.fields).forEach(([key, value]) => {
          if (key === 'key') {
            uploadFormData.append(key, keyFileName);
          } else {
            uploadFormData.append(key, value);
          }
        });
        uploadFormData.append('file', files[0]);
      }

      const modifiedFile = new File([files[0]], newFilename, { type: files[0].type });
      const uploadRequest = !isKSAregion
        ? axios.post(metaResponse.url, uploadFormData)
        : axios.put(metaResponse.url, modifiedFile, {
            headers: { 'Content-Type': modifiedFile.type },
          });
      await uploadRequest;

      const uploadLogFormData = new FormData();
      if (files.length > 1 && lastSelectedScreen) {
        uploadLogFormData.append('flight_log', files[1]);
        uploadLogFormData.append('screen_id', lastSelectedScreen.id.toString());
        uploadLogFormData.append('width', width.toString());
        uploadLogFormData.append('height', height.toString());
        // uploadLogFormData.append('takenAt', takenDate.toISOString());
      }
      uploadLogFormData.append('key', keyFileName);
      const finishUrl = makeV2APIURL('projects', projectId, 'videos');
      await axios.post(finishUrl, uploadLogFormData, { headers: header });

      dispatch(FinishPostPhoto());

      dispatch(GetDroneVideos({ projectId }));
      dispatch(FinishUploadPhotos());

      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.FLIGHT_VIDEO_UPLOAD_SUCCESS }));
    } catch (e) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.FLIGHT_VIDEO_UPLOAD_FAIL }));
    }
  }

  return { uploadVideos };
}
