import React, { useRef, useState, useMemo, useEffect, MouseEvent } from 'react';
import ReactDOM from 'react-dom';

import { useL10n, useClickOutside } from '^/hooks';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import {
  FilterPortalWrapper,
  FilterRoot,
  FilterItem,
  IconWrapper,
  FilterName,
  FilterWrapper,
  FilterTitle,
} from '../TimelapseCollectionList/Styles';
import CheckSvg from '^/assets/icons/contents-list/check.svg';
import UncheckSvg from '^/assets/icons/contents-list/uncheck.svg';
import FilterSVG from '^/assets/icons/filter.svg';

import Text from './text';
import * as T from '^/types';
import { useFlag } from '@unleash/proxy-client-react';

export const PhotoCollectionFilter: React.FC = () => {
  const isPhotoAlbumNewFeaturesEnabled = useFlag(T.FEATURE_FLAGS.PHOTO_ALBUM_NEW_FEATURES);
  const isVslamIndoorFeatureAvailable = useFlag(T.FEATURE_FLAGS.VSLAM_INDOOR);
  const filterPopDiv = useRef<HTMLDivElement>(document.createElement('div'));
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterPopRef = useRef<HTMLDivElement>(null);
  const [l10n] = useL10n();

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleFilterIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    setIsFilterOpen(_isFilterOpen => !_isFilterOpen);
    const { x, y, height } = event.currentTarget.getBoundingClientRect();
    setPosition({ x, y: y + height + 10 });
  };

  const { photoFilter, setPhotoFilter } = useTimelapseStore(s => ({
    photoFilter: s.photoCollectionFilter,
    setPhotoFilter: s.setPhotoCollectionFilter,
  }));

  useClickOutside({
    ref: filterPopRef,
    callback() {
      if (filterPopRef.current) {
        setIsFilterOpen(false);
      }
    },
  });

  const collectionTypes = useMemo(
    () =>
      Object.values(T.PhotoCollectionFilterTypes).filter(type => {
        // Filter out viewpoint if new features not enabled
        if (type === T.PhotoCollectionFilterTypes.VIEWPOINT) {
          return isPhotoAlbumNewFeaturesEnabled;
        }

        // Filter out 360 video/vslam if vslam feature not available
        if (
          [
            T.PhotoCollectionFilterTypes.THREE_SIXTY_VIDEO,
            T.PhotoCollectionFilterTypes.THREE_SIXTY_VSLAM,
          ].includes(type)
        ) {
          return isVslamIndoorFeatureAvailable;
        }

        return true;
      }),
    [isPhotoAlbumNewFeaturesEnabled, isVslamIndoorFeatureAvailable]
  );

  const handleFilterItemClick = (collectionType?: T.PhotoCollectionFilterTypes) => () => {
    if (collectionType) {
      setPhotoFilter(collectionType);
    }
  };

  const filterPopup = isFilterOpen ? (
    <React.Fragment>
      {ReactDOM.createPortal(
        <FilterPortalWrapper>
          <FilterRoot ref={filterPopRef} style={{ left: position.x, top: position.y }}>
            {collectionTypes.map(name => (
              <FilterItem key={name} onClick={handleFilterItemClick(name)}>
                <IconWrapper style={{ marginRight: 13 }}>
                  {photoFilter === name ? <CheckSvg /> : <UncheckSvg />}
                </IconWrapper>
                <FilterName style={{ marginLeft: 13 }}>
                  {name ? l10n(Text.filterContentTexts[name]) : l10n(Text.filterText)}
                </FilterName>
              </FilterItem>
            ))}
          </FilterRoot>
        </FilterPortalWrapper>,
        filterPopDiv.current
      )}
    </React.Fragment>
  ) : null;

  useEffect(() => {
    document.body.appendChild(filterPopDiv.current);
  }, []);

  useEffect(
    () => () => {
      document.body.removeChild(filterPopDiv.current);
    },
    [filterPopDiv]
  );

  return (
    <FilterWrapper onClick={handleFilterIconClick}>
      <IconWrapper isActive={isFilterOpen}>
        <FilterSVG />
      </IconWrapper>
      <FilterTitle>{l10n(Text.filterContentTexts[photoFilter])}</FilterTitle>
      {filterPopup}
    </FilterWrapper>
  );
};
