import CheckedSvg from '^/assets/icons/photo-request/checked.svg';
import UnCheckedSvg from '^/assets/icons/photo-request/unchecked.svg';
import NewLoadingIcon from '^/components/atoms/LoadingIcon/NewLoadingIcon';
import { formatDayMonthYear } from '^/components/molecules/PhotoList/util';
import { useL10n } from '^/hooks';
import { useViewpointStore } from '^/store/viewpointStore';
import theme from '^/theme';
import * as T from '^/types';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import Text from './text';

const ViewpointListContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginBottom: '10px',
});

ViewpointListContainer.displayName = 'ViewpointListContainer';

const ViewpointItem = styled.div<{ isChecked?: boolean; disablePointer?: boolean }>(
  ({ isChecked, disablePointer }) => ({
    pointerEvents: disablePointer ? 'none' : 'auto',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: '244px',
    height: '183px',
    '&:hover div.checkmark': {
      opacity: 1,
    },
    transition: 'all 0.4s ease',
    ...(isChecked && {
      backgroundColor: theme.colors.vividBlue[50],
      padding: '6px',
      width: '232px',
      height: '171px',
    }),
  })
);

const ViewpointImage = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ViewpointNumber = styled.div({
  position: 'absolute',
  top: '6px',
  right: '6px',
  backgroundColor: theme.colors.black[50],
  color: theme.colors.white[100],
  fontSize: '14px',
  fontWeight: '700',
  padding: '2px',
  borderRadius: '4px',
  height: '16px',
  width: '20px',
  textAlign: 'center',
});

const Checkmark = styled.div<{ checked: boolean }>(({ checked }) => ({
  position: 'absolute',
  top: checked ? '14px' : '8px',
  left: checked ? '14px' : '8px',
  opacity: checked ? 1 : 0,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
  },
}));

const BlueOverlay = styled.div({
  position: 'absolute',
  inset: 0,
  backgroundColor: theme.colors.vividBlue[200],
  opacity: 0.15,
});

const SkeletonCard = styled.div({
  width: '244px',
  height: '183px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.coolGray[50],
});

const RequestedDateContainer = styled.div({
  display: 'flex',
  gap: '5px',
  width: '100%',
});

const DateText = styled.div({
  fontWeight: '700',
  color: theme.colors.coolGray[800],
  fontSize: '14px',
});

const RequestedText = styled.div({
  fontWeight: '400',
  fontSize: '14px',
});

const ViewpointImagesList = ({
  viewpoints,
  isRequested,
  requestedAt,
}: {
  viewpoints: T.RequestPhoto[];
  isRequested?: boolean;
  requestedAt?: string;
}) => {
  const [l10n, lang] = useL10n();
  const selectedViewpointIds = useViewpointStore(s => s.selectedViewpointIds);
  const setSelectedViewpointIds = useViewpointStore(s => s.setSelectedViewpointIds);
  const isViewpointUploading = useViewpointStore(s => s.isViewpointUploading);
  const skeletonRef = useRef<HTMLDivElement>(null);

  const toggleCheckmark = (id: number, isChecked: boolean) => {
    const oldSelected = [...(selectedViewpointIds || [])];
    const newSelected = isChecked
      ? oldSelected.filter(viewpointId => viewpointId !== id)
      : [...oldSelected, id];
    setSelectedViewpointIds(newSelected.length ? newSelected : null);
  };

  useEffect(() => {
    if (isViewpointUploading) {
      skeletonRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isViewpointUploading]);

  const formattedDate = useMemo(() => {
    if (!requestedAt) {
      return null;
    }
    const date = new Date(requestedAt);
    const dayMonthYear = formatDayMonthYear(date, lang);
    return dayMonthYear;
  }, [requestedAt]);

  const skeleton = (
    <SkeletonCard ref={skeletonRef}>
      <NewLoadingIcon />
    </SkeletonCard>
  );

  return (
    <ViewpointListContainer>
      {isRequested && formattedDate ? (
        <RequestedDateContainer>
          <DateText>{formattedDate}</DateText>
          <RequestedText>{l10n(Text.requested)}</RequestedText>
        </RequestedDateContainer>
      ) : null}
      {viewpoints.map((viewpoint, index) => {
        const isChecked = Boolean(selectedViewpointIds?.includes(viewpoint.id));
        return (
          <ViewpointItem
            key={viewpoint.id}
            onClick={() => toggleCheckmark(viewpoint.id, isChecked)}
            data-testid={`viewpoint-${viewpoint.id}`}
            isChecked={isChecked}
            disablePointer={isRequested}
          >
            {isRequested ? <BlueOverlay /> : null}
            <ViewpointImage src={viewpoint.imageUrl} alt={viewpoint.originalFilename} />
            <ViewpointNumber>{index + 1}</ViewpointNumber>
            <Checkmark
              className="checkmark"
              checked={isChecked}
              data-testid={`viewpoint-checkmark-${viewpoint.id}`}
            >
              {isChecked ? <CheckedSvg /> : <UnCheckedSvg />}
            </Checkmark>
          </ViewpointItem>
        );
      })}
      {isViewpointUploading ? skeleton : null}
    </ViewpointListContainer>
  );
};

export default ViewpointImagesList;
