import React, { FC } from 'react';
import styled from 'styled-components';

import RetroGrid from '../RetroGrid';
import animationData from '^/assets/lotties/logo-lottie-a.json';
import Lottie from 'lottie-react';

const Wrapper = styled.div({
  position: 'relative',

  width: '100%',
  height: '100%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const LoadingIconContainer = styled.div({
  height: '60px',
  zIndex: 100,
  width: '60px',
  background: 'black',
  borderRadius: '10px',
});

interface Props {}

const LoadingScreen: FC<Props> = () => (
  <Wrapper data-testid="loading-wrapper">
    <LoadingIconContainer data-testid="loading-animation-wrapper">
      <Lottie animationData={animationData} loop={true} />
    </LoadingIconContainer>
    <RetroGrid theme="lightGray" />
  </Wrapper>
);
export default LoadingScreen;
