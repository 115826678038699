import React from 'react';
import '^/styles/retroGrid.scss';

export default function RetroGrid({ theme = 'lightGray' }) {
  return (
    <div className={`retro-grid ${theme}`}>
      <div className="grid-container">
        <div className={`grid ${theme}`} />
      </div>
      <div className={`bg-gradient ${theme}`} />
    </div>
  );
}
