import { useCallback } from 'react';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import {
  ChangeCurrentMeshEngine,
  ChangeIn3D,
  ChangeIn3DPointCloud,
  SetPreventAutoSelect,
} from '^/store/duck/Pages';
import { PatchContent } from '^/store/duck/Contents';
import { getSingleContentId } from '^/utilities/state-util';

import * as T from '^/types';

export const useToggle3D = () => {
  const dispatch = useDispatch();

  // const [isInit, setIsInit] = useState<boolean>(false);

  const isIn3D: T.ContentsPageState['in3D'] = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const Pages = useSelector((s: T.State) => s.Pages);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const isThreeDOrthoAvailableOnCurrentDate: boolean =
    getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.THREE_D_ORTHO) !== undefined;

  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const isOnPhotoTab: boolean = sidebarTab === T.ContentPageTabType.PHOTO;
  const { currentMeshEngine, hasThreeJsSupport } = useSelector((s: T.State) => s.Pages.Contents);

  const threeDMapId: T.MapContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.THREE_D_MESH
  );
  const threeDOrthoId: T.MapContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.THREE_D_ORTHO
  );
  const twoDOrthoId = getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.MAP);

  const on3DToggle = useCallback(() => {
    const actions: Action[] = [];
    if (isThreeDOrthoAvailableOnCurrentDate || isIn3D) {
      actions.push(ChangeIn3DPointCloud({ in3DPointCloud: false }));
      if (threeDOrthoId) {
        actions.push(
          PatchContent({
            content: {
              id: threeDOrthoId,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      }
    }

    if (isOnPhotoTab && currentMeshEngine !== T.MeshEngine.THREEJS && hasThreeJsSupport) {
      actions.push(ChangeCurrentMeshEngine({ engine: T.MeshEngine.THREEJS, hasThreeJsSupport }));
    }

    // TODO: This is a patch to fix an issue when selecting a measurement
    // then toggles the 2D/3D, the map content isn't being selected.
    // The actual issue is in the logic of toggling the contents in the contentlist* components.
    // Will be fixed in https://angelswing.atlassian.net/browse/DFE-1192.
    if (threeDMapId && !isIn3D) {
      actions.push(
        PatchContent({
          content: {
            id: threeDMapId,
            config: {
              selectedAt: new Date(),
            },
          },
        })
      );
    } else if (isIn3D && twoDOrthoId) {
      actions.push(
        PatchContent({
          content: {
            id: twoDOrthoId,
            config: { selectedAt: new Date() },
          },
        })
      );
    }

    actions.push(ChangeIn3D({ in3D: !isIn3D }), SetPreventAutoSelect({ value: false }));
    actions.forEach(dispatch);
  }, [isIn3D, isThreeDOrthoAvailableOnCurrentDate, isOnPhotoTab, currentMeshEngine, threeDMapId]);

  return { isIn3D, isOnPhotoTab, on3DToggle };
};
