import Tippy from '@tippyjs/react';
import { Cartesian3 } from 'cesium';
import { LineString } from 'ol/geom';
import React, { FC, ReactNode, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import HorizontalLengthSVG from '^/assets/icons/contents-list/horizontal-length.svg';
import PointToPointLengthSVG from '^/assets/icons/contents-list/point-to-point-length.svg';
import SurfaceLengthSVG from '^/assets/icons/contents-list/surface-length.svg';
import { ContentsListItem, HorizontalDivider } from '^/components/atoms/ContentsListItem';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import { isAvailablePointToPoint, isContentPointToPoint } from '^/components/cesium/cesium-util';
import LengthMetricList, { MetricsContainer } from '^/components/molecules/LengthMetricList';
import {
  createGeometryFromLocations,
  getImperialMeasurementFromGeometry,
  getMeasurementFromGeometry,
} from '^/components/ol/contentTypeSwitch';
import dsPalette from '^/constants/ds-palette';
import { UseL10n, UseLastSelectedScreen, useL10n, useLastSelectedScreen } from '^/hooks';
import { PatchContent, RequestLengthElevation } from '^/store/duck/Contents';
import { ChangeElevationExpansionStatus, ChangeMapHorizontalTabStatus } from '^/store/duck/Pages';
import * as T from '^/types';
import { Formats, formatWithOffset } from '^/utilities/date-format';
import { isPhone } from '^/utilities/device';
import { determineUnitType, VALUES_PER_METER } from '^/utilities/imperial-unit';
import { getSingleContentId } from '^/utilities/state-util';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { useThreeStore } from '^/components/three/ThreeStore';
import ReloadSVG from '^/assets/icons/contents-list/reload.svg';
import { mergeTileset } from '^/components/three/Lib/Utils/TilesetUtils';
import { DimensionLengthObject } from '^/components/three/ThreeObjects/Dimension';
import InaccurateMeasurementWarning from '^/components/atoms/InaccurateMeasurementWarning';
import { useContentsStore } from '^/store/zustand/content/contentStore';

const Balloon2 = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ElevationText = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: dsPalette.title.toString(),
});

const LengthMetricWrapper = styled.div({
  width: '100%',
  height: '100%',
});

const ClampedText = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: dsPalette.title.toString(),
});

const Balloon3 = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});
const ReloadContainer = styled.div({
  width: '14px',
  height: '14px',
  display: 'flex',
  alignItems: 'flex-end',
});

type MetricType = keyof NonNullable<T.LengthContent['info']['metrics']>;

export interface Props {
  content: T.LengthContent;
  isPinned?: boolean;
}

const ContentsListLengthItem: FC<Props> = ({ content, isPinned = false }) => {
  const timezoneOffset = useSelector((state: T.State) => state.Pages.Common.timezoneOffset);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);

  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const Pages = useSelector((s: T.State) => s.Pages);
  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  if (!projectUnit && !projectId) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }

  const dispatch: Dispatch = useDispatch();
  const [l10n, language]: UseL10n = useL10n();
  const lastSelectedScreen: UseLastSelectedScreen = useLastSelectedScreen();
  const viewer = useThreeStore(s => s.viewer);
  // const isEditing: boolean = useSelector(
  //   (s: T.State) => content.id === s.Pages.Contents.editingContentId
  // );
  const editingContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);
  const isEditing: boolean = content.id === editingContentId;
  const byId = useContentsStore(s => s.contents.byId);
  const contentId: T.Content['id'] = content.id;

  // DsmMapContent
  const dsmId: T.Content['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.DSM
  );

  const dsmContent: T.DSMContent | undefined =
    dsmId !== undefined ? (byId[dsmId] as T.DSMContent) : undefined;

  const project: T.Project | undefined = projectById[projectId ?? NaN] ?? undefined;

  const unitType: T.ValidUnitType = project
    ? determineUnitType(project.unit)
    : determineUnitType(projectUnit);

  const isDsmAvailable: boolean = Boolean(dsmContent?.status);
  const isElevationGenerated: boolean =
    content.info.elevations !== undefined && content.info.elevations.length !== 0;
  const isElevationToggled: boolean = Boolean(content.config?.isElevationToggled);
  const isInIfc: boolean = Boolean(content.config?.isInIfc);
  const isIn3D: T.ContentsPageState['in3D'] = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const isClampedToggled: boolean = Boolean(content.config?.isClampedToggled);

  useEffect(() => {
    if (!isElevationGenerated && isElevationToggled) {
      dispatch(
        PatchContent({
          content: {
            id: content.id,
            config: { isElevationToggled: false },
          },
        })
      );
    }
  }, [isDsmAvailable, isEditing]);

  useEffect(() => {
    if (isElevationToggled) {
      dispatch(
        PatchContent({
          content: {
            id: content.id,
            config: { isElevationToggled: false, isClampedToggled: false },
          },
        })
      );
    }
  }, []);

  const handleToggle: () => void = () => {
    if (dsmId === undefined) {
      return;
    }

    const isElevationToggledAfterClick: boolean = !isElevationToggled;

    updateContentConfig(contentId, {
      ...content.config,
      type: content.type,
      isElevationToggled: isElevationToggledAfterClick,
      isClampedToggled: isElevationToggledAfterClick ?? isClampedToggled,
    });

    if (!isElevationGenerated && isElevationToggledAfterClick) {
      generateElevation(dsmId);
    }

    if (isElevationToggledAfterClick) {
      dispatch(
        ChangeMapHorizontalTabStatus({ status: T.MapHorizontalTabStatus.ELEVATION_PROFILE })
      );
      dispatch(ChangeElevationExpansionStatus({ open: true }));
    } else {
      dispatch(ChangeMapHorizontalTabStatus({ status: T.MapHorizontalTabStatus.HIDDEN }));
    }
  };

  const generateElevation: (dsmContentId: T.DSMContent['id']) => void = id => {
    dispatch(ChangeElevationExpansionStatus({ previousOpen: true, open: true }));
    dispatch(
      RequestLengthElevation({
        contentId,
        comparisonContentId: id,
        comparison: {
          title:
            lastSelectedScreen?.appearAt !== undefined
              ? formatWithOffset(timezoneOffset, lastSelectedScreen?.appearAt, Formats.YYYYMMDD)
              : '',
          color: content.color.toString(),
        },
      })
    );
  };

  const getMetricContent: (type: MetricType) => string | undefined = useCallback(
    type => {
      const metrics: T.LengthContent['info']['metrics'] = content.info.metrics;
      if (!isDsmAvailable || isInIfc) {
        return '-';
      }

      return metrics === undefined
        ? undefined
        : (metrics[type] * VALUES_PER_METER[unitType]).toFixed(2);
    },
    [content.info.metrics, isDsmAvailable]
  );

  const elevationToggleButton: ReactNode = isDsmAvailable ? (
    <>
      <ElevationText>{l10n(Text.elevation)}</ElevationText>
      <ToggleSlider
        onClick={handleToggle}
        enabled={isElevationToggled}
        data-ddm-track-action={T.TrackActions.CONTENT_ITEM}
        data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_ELEVATION_PROFILE}-${
          isElevationToggled ? 'off' : 'on'
        }`}
      />
    </>
  ) : (
    <ElevationText>{l10n(Text.noDsmElevation)}</ElevationText>
  );

  // For showing the warning text if the length is built on top of IFC models
  const warningText = isInIfc ? (
    <InaccurateMeasurementWarning warningText={l10n(Text.inaccurateMeasurementMessage)} />
  ) : null;

  // TODO: There was a bug with the tooltip wrapperhoverable,
  // where it would get into the sidebar and clipped due to overflow: hidden;
  // This positions the tooltip exactly where it needs to be.
  // It only needs this hack on english language where the text are longer.
  // Please update this if the text gets updated...
  const getTooltipCustomStyle: (type: 'horizontal' | 'pointToPoint' | MetricType) => CSSObject =
    useCallback(
      type =>
        language === T.Language.KO_KR
          ? {}
          : type === 'pointToPoint'
          ? { width: '180px', transform: 'translate(-66%, 3px)' }
          : { width: '180px' },
      [language]
    );

  const stringifiedLocations: string = content.info.locations.toString();
  const stringifiedMetrics: string | undefined = content.info.metrics?.toString();
  const measurement: string = useMemo(() => {
    const length: LineString = createGeometryFromLocations({
      locations: content.info.locations,
      geometryType: content.type,
    }) as LineString;

    if (unitType === T.UnitType.IMPERIAL) {
      return getImperialMeasurementFromGeometry({ geometry: length, geometryType: content.type });
    }

    return getMeasurementFromGeometry({ geometry: length, geometryType: content.type });
  }, [stringifiedLocations]);
  const pointToPoint: string | undefined = useMemo(() => {
    if (isAvailablePointToPoint(content.info.locations)) {
      const positions: Cartesian3[] = content.info.locations.map(location =>
        Cartesian3.fromDegrees(location[0], location[1], location[2])
      );

      let total: number = 0;
      positions.forEach((position, index) => {
        const nextIdx = index + 1;
        if (nextIdx < positions.length) {
          total += Cartesian3.distance(position, positions[nextIdx]);
        }
      });

      total *= VALUES_PER_METER[unitType];

      return String(total.toFixed(2));
    }

    return getMetricContent('pointToPoint');
  }, [stringifiedLocations, stringifiedMetrics]);

  const handleToggleClamp: () => void = () => {
    if (!viewer) {
      return;
    }
    if (viewer.entities.dimensionEntity) {
      viewer.entities.dimensionEntity.editor.detached();
    }

    updateContentConfig(contentId, {
      ...content.config,
      type: content.type,
      isElevationToggled: isElevationToggled,
      isClampedToggled: !isClampedToggled,
    });
  };

  const reloadSurface = (e: any) => {
    e.preventDefault();
    if (!content.config?.isClampedToggled) {
      return;
    }
    if (viewer?.tileset?.children.length === 0) {
      return;
    }

    if (viewer) {
      const object = viewer.entities.dimensionEntity?.getObjectByName(`dimension-${content.id}`);
      if (object) {
        const objectVolume = object as DimensionLengthObject;
        const mergeMesh = mergeTileset(viewer.tileset!);
        objectVolume.intersectMesh(mergeMesh, objectVolume.pointerArray);
        objectVolume.changeDisplayType2(true);
      }
    }
  };
  const clampToggleButton: ReactNode = viewer ? (
    <>
      <HorizontalDivider />
      <Balloon2>
        <Balloon3>
          <ClampedText>{l10n(Text.clamped)}</ClampedText>
          {isClampedToggled && (
            <ReloadContainer onClick={reloadSurface}>
              <ReloadSVG />
            </ReloadContainer>
          )}
        </Balloon3>

        <ToggleSlider
          onClick={handleToggleClamp}
          enabled={isClampedToggled}
          data-ddm-track-action={T.TrackActions.CONTENT_ITEM}
          data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_CLAMPED_VOLUME}-${
            isClampedToggled ? 'off' : 'on'
          }`}
        />
      </Balloon2>
    </>
  ) : null;

  const balloon2: ReactNode = isPhone() ? undefined : (
    <>
      {clampToggleButton}
      <HorizontalDivider />
      <Balloon2>{elevationToggleButton}</Balloon2>
    </>
  );

  const onChangeDistanceType = (type: T.DistanceType) => () => {
    dispatch(
      PatchContent({
        content: {
          id: contentId,
          config: {
            distanceType: type,
          },
        },
      })
    );
  };

  const isSurface: boolean =
    isIn3D &&
    (content.config?.distanceType === T.DistanceType.SURFACE ||
      !isAvailablePointToPoint(content.info.locations));
  const isPointToPoint: boolean = isIn3D && !isSurface && isContentPointToPoint(content);

  const isSelectAvailablePointToPoint: boolean =
    isIn3D && isAvailablePointToPoint(content.info.locations);

  return (
    <ContentsListItem
      isPinned={isPinned}
      className={CANCELLABLE_CLASS_NAME}
      content={content}
      firstBalloonTitle={l10n(Text.firstBalloonTitle)}
    >
      <MetricsContainer>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="right"
          arrow={false}
          content={l10n(Text.twoDDisabled)}
          disabled={!isIn3D}
        >
          <LengthMetricWrapper>
            <LengthMetricList
              type={content.type}
              title={l10n(Text.horizontalLength)}
              tooltip={l10n(Text.horizontalLengthTooltip)}
              icon={<HorizontalLengthSVG />}
              value={measurement}
              isActive={!isIn3D}
              isSelectDisabled={isIn3D}
              tooltipCustomStyle={getTooltipCustomStyle('horizontal')}
              onClick={onChangeDistanceType(T.DistanceType.HORIZONTAL)}
              unitType={unitType}
            />
          </LengthMetricWrapper>
        </Tippy>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="right"
          arrow={false}
          content={l10n(Text.threeDDisabled)}
          disabled={isIn3D}
        >
          <LengthMetricWrapper>
            <LengthMetricList
              type={content.type}
              title={l10n(Text.surfaceLength)}
              tooltip={l10n(Text.surfaceLengthTooltip)}
              icon={<SurfaceLengthSVG />}
              value={getMetricContent('surface')}
              isActive={isSurface}
              isSelectDisabled={!isIn3D || isInIfc}
              tooltipCustomStyle={getTooltipCustomStyle('surface')}
              onClick={onChangeDistanceType(T.DistanceType.SURFACE)}
              unitType={unitType}
            />
          </LengthMetricWrapper>
        </Tippy>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="right"
          arrow={false}
          content={l10n(Text.threeDDisabled)}
          disabled={isIn3D}
        >
          <LengthMetricWrapper>
            <LengthMetricList
              type={content.type}
              title={l10n(Text.pointToPointLength)}
              tooltip={l10n(Text.pointToPointLengthTooltip)}
              icon={<PointToPointLengthSVG />}
              value={pointToPoint}
              isActive={isPointToPoint}
              isSelectDisabled={!isSelectAvailablePointToPoint}
              tooltipCustomStyle={getTooltipCustomStyle('pointToPoint')}
              onClick={onChangeDistanceType(T.DistanceType.POINT_TO_POINT)}
              unitType={unitType}
            />
          </LengthMetricWrapper>
        </Tippy>
      </MetricsContainer>
      {warningText}
      {balloon2}
    </ContentsListItem>
  );
};

export default memo(withErrorBoundary(ContentsListLengthItem)(Fallback));
