import React, { FC, SyntheticEvent, memo, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import PhotoCameraSvg from '^/assets/icons/photo/photo-camera.svg';
import { defaultToastInfoOption, UseL10n, useL10n, useToast, UseToast } from '^/hooks';
import {
  SetPhotoOnMapId,
  SetHoveredPhotoId,
  SetCurrentPhotoId,
  SetSelectedMedia,
} from '^/store/duck/Photos';
import {
  StyledPhotoRequestWrapper,
  Wrapper,
  Checkbox,
  PhotoSvgWrapper,
  InfoText,
  InfoContainer,
  StyledPhotoRequestDiv,
  StyledPhotoListItem,
  StyledPhotoStaticListItem,
} from '../PhotoList/style';
import * as T from '^/types';
import Text from '../PhotoList/text';
import * as THREE from 'three';

import { useThreeStore } from '^/components/three/ThreeStore';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { PhotoAlbumLocationObject, PhotoAlbumObject } from '^/components/three/ThreeObjects/Photo';
import { isImageGeoReferenced } from '^/utilities/image';
// import { useIsInView } from '^/hooks/useIsInView';
import { ChangeTwoDDisplayCenter } from '^/store/duck/Pages';
import { useSidebarStore } from '^/store/sidebarStore';

interface PhotoItemProps {
  isSelected: boolean;
  photoDate: T.Photo;
  birdEyePhoto: T.Photo;
}

const RawRequestPhotoItem: FC<PhotoItemProps> = memo(({ isSelected, photoDate, birdEyePhoto }) => {
  const { selectedMedia } = useSelector((s: T.State) => s.Photos);
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();

  //   const [ref, isVisible] = useIsInView<HTMLDivElement>();
  const toast: UseToast = useToast();

  const viewer = useThreeStore(s => s.viewer);
  const cameraControls = useThreeStore(s => s.cameraControls);
  const runtime = useThreeStore(s => s.runtime);
  // Zustand stores
  const setPhotoAlbumSidebarWidth = useSidebarStore(s => s.setPhotoAlbumSidebarWidth);

  const isPhotoProcessing =
    birdEyePhoto.status === T.PhotoProcessStatus.PENDING ||
    birdEyePhoto.status === T.PhotoProcessStatus.PROCESSING;
  const setSelectedPhotoAlbumIdNew = useThreePhotoAlbumStore(s => s.setSelectedPhotoAlbumIdNew);

  const handlePhotoListItemClick: (photoId: T.Photo['id']) => () => void = photoId => () => {
    if (isImageGeoReferenced(birdEyePhoto) && viewer) {
      if (selectedMedia.some(media => media.contentId === birdEyePhoto.id)) {
        photoAlbumAnimation();
      } else {
        const temp = isSelected
          ? selectedMedia.filter(media => media.contentId !== birdEyePhoto.id)
          : [
              ...selectedMedia,
              { type: birdEyePhoto.photoType ?? null, contentId: birdEyePhoto.id },
            ];
        dispatch(SetSelectedMedia({ selectedMedia: temp }));
        setTimeout(() => {
          photoAlbumAnimation();
        }, 500);
      }
    } else {
      dispatch(SetCurrentPhotoId({ photoId }));
    }
  };

  const phototooltipContent = () =>
    birdEyePhoto.latitude && birdEyePhoto.longitude
      ? l10n(Text.photo.viewTooltip)
      : l10n(Text.photo.noLocationTooltip);

  const handleSelectedHoveredItems: (photo: T.Photo, isHovered: boolean) => () => void =
    (photo, isHovered) => () => {
      if (isSelected && isHovered) {
        handleHighlightPhoto();

        dispatch(SetHoveredPhotoId({ hoveredId: photo.id }));
      } else if (isSelected && !isHovered) {
        handleUnHighlightPhoto();

        dispatch(SetHoveredPhotoId({ hoveredId: undefined }));
      }
    };

  const handleInputChange: (
    event?: SyntheticEvent<HTMLInputElement, MouseEvent>
  ) => void = event => {
    event?.stopPropagation();
    const temp = isSelected
      ? selectedMedia.filter(media => media.contentId !== birdEyePhoto.id)
      : [...selectedMedia, { type: birdEyePhoto.photoType ?? null, contentId: birdEyePhoto.id }];

    dispatch(SetSelectedMedia({ selectedMedia: temp }));
    if (!isSelected) {
      setTimeout(() => {
        handleHighlightPhoto();
      }, 200);
    }
  };

  const handleHighlightPhoto = () => {
    if (viewer) {
      const photoAlbumController = viewer.controllers.photoAlbumController;
      if (photoAlbumController) {
        const object = photoAlbumController?.getObjectByName(birdEyePhoto.id.toString());

        if (object instanceof PhotoAlbumLocationObject) {
          if (photoAlbumController.hoverObject) {
            photoAlbumController.hoverObject.setUnHighlight();
          }
          photoAlbumController.hoverObject = object;
          photoAlbumController.hoverObject?.setHighlight(false);
        }
      }
    }
  };
  const handleUnHighlightPhoto = () => {
    if (viewer) {
      const photoAlbumController = viewer.controllers.photoAlbumController;
      if (photoAlbumController) {
        if (photoAlbumController.hoverObject) {
          photoAlbumController.hoverObject.setUnHighlight();
          photoAlbumController.hoverObject = null;
        }
      }
    }
  };

  const handlePhotoOnMapId: (
    event: SyntheticEvent<HTMLDivElement, MouseEvent>
  ) => void = async event => {
    event.stopPropagation();
    if (isPhotoProcessing) {
      toast({
        type: T.Toast.INFO,
        content: {
          title: Text.drone.flightVideoTitle,
          description: Text.drone.flightVideoProcessing,
        },
        option: defaultToastInfoOption,
      });
      return;
    }
    if (!birdEyePhoto.latitude || !birdEyePhoto.longitude || !birdEyePhoto.altitude) {
      return;
    }

    if (!isSelected) {
      dispatch(
        SetSelectedMedia({
          selectedMedia: [
            ...selectedMedia,
            { type: birdEyePhoto.photoType ?? null, contentId: birdEyePhoto.id },
          ],
        })
      );
    }

    if (viewer) {
      /**
       *  This part for move camera on top of icon for threejs
       */
      setSelectedPhotoAlbumIdNew({ photoId: birdEyePhoto.id, photoType: birdEyePhoto.photoType });
      const center = runtime!.getPositionFromLatLongHeight({
        long: birdEyePhoto.longitude,
        lat: birdEyePhoto.latitude,
        height: birdEyePhoto.altitude,
      });
      const sphere = new THREE.Mesh(
        new THREE.SphereGeometry(15, 32, 16),
        new THREE.MeshBasicMaterial({ color: 0xffff00 })
      );
      sphere.position.set(center.x, center.y, center.z);
      await cameraControls?.rotateTo(0, 0);
      await cameraControls?.fitToSphere(sphere, true);
      sphere.geometry.dispose();
      sphere.material.dispose();
    } else {
      /**
       *  This part for move camera on top of icon for openlayer
       */

      dispatch(SetPhotoOnMapId({ photoId: birdEyePhoto.id }));
      dispatch(SetHoveredPhotoId({ hoveredId: birdEyePhoto.id }));

      const { longitude: lon, latitude: lat }: T.Photo = birdEyePhoto;
      if (!lat || !lon) {
        return;
      }
      dispatch(ChangeTwoDDisplayCenter({ twoDDisplayCenter: [lon, lat] }));
    }
  };
  const photoAlbumAnimation = (): boolean => {
    if (isPhotoProcessing) {
      toast({
        type: T.Toast.INFO,
        content: {
          title: Text.drone.flightVideoTitle,
          description: Text.drone.flightVideoProcessing,
        },
        option: defaultToastInfoOption,
      });
      return false;
    }

    const photoObject = viewer?.controllers.photoAlbumController?.getObjectByName(
      birdEyePhoto.id.toString()
    );

    if (photoObject) {
      const object = photoObject as PhotoAlbumObject;
      viewer?.controllers.photoAlbumController?.setPhotoObject(object);
      return true;
    }
    return false;
  };

  useEffect(() => {
    setPhotoAlbumSidebarWidth(750);
  }, []);

  return (
    <>
      <StyledPhotoRequestDiv style={{ gridColumn: 'span 2' }} isSelected={isSelected}>
        <StyledPhotoRequestWrapper isSelected={isSelected}>
          <StyledPhotoListItem
            isVisible={true}
            isSelected={isSelected}
            onMouseEnter={handleSelectedHoveredItems(birdEyePhoto, true)}
            onMouseLeave={handleSelectedHoveredItems(birdEyePhoto, false)}
            url={birdEyePhoto.thumbUrl}
            status={photoDate.status}
            onClick={handlePhotoListItemClick(birdEyePhoto.id)}
            id={`photo_${birdEyePhoto.id}`}
          >
            {birdEyePhoto.photoType === 'source' && !birdEyePhoto.takenAt ? (
              <InfoContainer>
                <InfoText color="#f5f5f5">{l10n(Text.drone.sourcePhotoProcessing)}</InfoText>
              </InfoContainer>
            ) : null}

            <Wrapper>
              <Checkbox checked={isSelected} onChange={() => handleInputChange()} />
            </Wrapper>

            <Tippy
              offset={T.TIPPY_OFFSET}
              theme="angelsw"
              placement="top"
              arrow={false}
              content={phototooltipContent()}
            >
              <PhotoSvgWrapper onClick={handlePhotoOnMapId} disabled={false}>
                <PhotoCameraSvg />
              </PhotoSvgWrapper>
            </Tippy>
          </StyledPhotoListItem>
          <StyledPhotoStaticListItem
            isVisible={true}
            isSelected={isSelected}
            onMouseEnter={handleSelectedHoveredItems(photoDate, true)}
            onMouseLeave={handleSelectedHoveredItems(photoDate, false)}
            url={photoDate.thumbUrl}
            status={photoDate.status}
            id={`photo_${photoDate.id}`}
          />
        </StyledPhotoRequestWrapper>
      </StyledPhotoRequestDiv>
    </>
  );
});

export const PhotoRequestItem: FC<PhotoItemProps> = RawRequestPhotoItem;
