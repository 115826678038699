import { Matrix4, Object3D, Vector2, Vector3 } from 'three';

import * as T from '^/types';
import { isEqual } from 'lodash-es';

export interface UserDataType {
  roll: number;
  pitch: number;
  heading: number;
  latitude: number;
  longitude: number;
  elevation: number;
}

export const IFC_NAME = 'IFC';
export const generateIFCName = (id: T.BimContent['id']) => `${IFC_NAME}_${id}`;
export const getIdFromIFCName = (name: string) => Number(name.split('-').pop());

/**
 * This help create a rectangular with top-left and bottom-right
 * @param {Object3D} object
 * @param {Vector2} initialPoint
 * @param {Vector2} finalPoint
 */

export const generateRecTangularByTwoPoints = (
  object: Object3D,
  initialPoint: Vector2,
  finalPoint: Vector2
): number[] => {
  const minU = Math.min(initialPoint.x, finalPoint.x);
  const minV = Math.min(initialPoint.y, finalPoint.y);
  const maxU = Math.max(initialPoint.x, finalPoint.x);
  const maxV = Math.max(initialPoint.y, finalPoint.y);

  const p1 = texturePosToPlaneWorld(object.matrixWorld, new Vector2(minU, minV));
  const p2 = texturePosToPlaneWorld(object.matrixWorld, new Vector2(minU, maxV));
  const p3 = texturePosToPlaneWorld(object.matrixWorld, new Vector2(maxU, maxV));
  const p4 = texturePosToPlaneWorld(object.matrixWorld, new Vector2(maxU, minV));

  function texturePosToPlaneWorld(matrix: Matrix4, texcoord: Vector2) {
    return new Vector3(texcoord.x - 0.5, texcoord.y - 0.5).applyMatrix4(matrix);
  }

  return [...p1.toArray(), ...p2.toArray(), ...p3.toArray(), ...p4.toArray(), ...p1.toArray()];
};

export const getIsTheSameLocation = (userData: UserDataType, bimMeta: T.BimMeta) => {
  const {
    latitude: y,
    longitude: x,
    elevation: z,
    roll: originalRoll,
    pitch: originalPitch,
    heading: originalHeading,
  } = userData;
  const originalPosition = { x, y, z };
  // current bimMeta
  const { roll, pitch, position, heading } = bimMeta;
  return isEqual(
    { roll, pitch, position, heading },
    {
      roll: originalRoll,
      pitch: originalPitch,
      heading: originalHeading,
      position: originalPosition,
    }
  );
};
