import React from 'react';
import SkeletonElement from '^/components/atoms/SkeletonElement';
import styled from 'styled-components';

export const SkeletonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
});

export const TitleSkeleton: React.FC = () => (
  <SkeletonWrapper>
    <SkeletonElement height="20px" width={'200px'} />
    <SkeletonElement height="20px" width={'300px'} />
    <SkeletonElement height="20px" width={'100px'} />
  </SkeletonWrapper>
);
