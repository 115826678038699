import React from 'react';
import styled from 'styled-components';
import BackArrowSVG from '^/assets/icons/back-arrow-dark.svg';
import colorTokens from '^/theme/colors/color-tokens';
import { Link } from 'react-router-dom';
import routes from '^/constants/routes';
import { useDispatch } from 'react-redux';
import { ChangePathBeforeAuth } from '^/store/duck/Auth';

interface BackToPageButtonProps {
  label: string;
  route?: string;
}

const BackButton = styled(Link as any)`
  width: fit-content;
  gap: 10px;
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  color: ${colorTokens.textCool};
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const BackToPageButton = ({ label, route }: BackToPageButtonProps) => {
  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(ChangePathBeforeAuth({ path: undefined }));
  };

  return (
    <BackButton onClick={onClickHandler} to={route ?? routes.login.main}>
      <BackArrowSVG /> {label}
    </BackButton>
  );
};
