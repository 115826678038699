import { Language } from '^/types';

export default {
  tooltipProjectLogo: {
    [Language.KO_KR]: '프로젝트 보드',
    [Language.EN_US]: 'Project board',
  },
  tooltipMap: {
    [Language.KO_KR]: '지도',
    [Language.EN_US]: 'Map',
  },
  tooltipOverlay: {
    [Language.KO_KR]: '도면',
    [Language.EN_US]: 'CAD Overlay',
  },
  tooltipBeamo: {
    [Language.KO_KR]: '360 Reality View',
    [Language.EN_US]: '360 Reality View',
  },
  tooltipMeasurement: {
    [Language.KO_KR]: '측정',
    [Language.EN_US]: 'Measurement',
  },
  tooltipPhoto: {
    [Language.KO_KR]: '사진첩',
    [Language.EN_US]: 'Photo Album',
  },
  tooltipDownload: {
    [Language.KO_KR]: '다운로드',
    [Language.EN_US]: 'Download',
  },
  tooltipUpload: {
    [Language.KO_KR]: '업로드',
    [Language.EN_US]: 'Upload',
  },
  tooltipESS: {
    [Language.KO_KR]: '안전관리',
    [Language.EN_US]: 'Site Logistics',
  },
  tooltipESSMKT: {
    [Language.KO_KR]: '안전관리*',
    [Language.EN_US]: 'Site Logistics*',
  },
  tooltipDisabled: {
    [Language.KO_KR]: '기능은 현재 사용할 수 없습니다',
    [Language.EN_US]: 'feature is currently unavailable',
  },
  tooltipIssue: {
    [Language.KO_KR]: '작업 관리',
    [Language.EN_US]: 'Issues',
  },
  tooltipDashboard: {
    [Language.KO_KR]: '대시보드',
    [Language.EN_US]: 'Dashboard',
  },
  tooltipDroneStation: {
    [Language.KO_KR]: '드론 스테이션',
    [Language.EN_US]: 'Drone Station',
  },
  tooltipFlightPlan: {
    [Language.KO_KR]: '비행 경로',
    [Language.EN_US]: 'Flight Plan',
  },
  tooltipFlightSchedule: {
    [Language.KO_KR]: '비행 일정',
    [Language.EN_US]: 'Flight Schedule',
  },
  tooltipSupport: {
    [Language.KO_KR]: '가이드',
    [Language.EN_US]: 'Support',
  },
  tooltipPresentation: {
    [Language.KO_KR]: '슬라이드',
    [Language.EN_US]: 'Slides',
  },
  tooltipViewpointCapture: {
    [Language.KO_KR]: '사진 요청 (베타)',
    [Language.EN_US]: 'Photo Request (Beta)',
  },
};
