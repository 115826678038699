import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as T from '^/types';
import { Option as DropdownOption } from '^/components/atoms/Dropdown/1';
import { Option as AttachmentOption } from '^/components/atoms/AttachDropdown';

const useEarthWorkFile = () => {
  const contents = useSelector((s: T.State) => s.Contents?.contents || { byId: {} });
  const { photos } = useSelector((s: T.State) => s.Photos);

  const dxfOptions = useMemo(() => {
    const options: AttachmentOption[] = [];
    Object.values(contents.byId || {}).forEach(content => {
      if (content.type === T.ContentType.DESIGN_DXF) {
        options.push({
          text: content.title ?? '',
          value: content.id?.toString() ?? '',
          isProcessing:
            content.status === T.ContentProcessingStatus.PROCESSING ||
            content.status === T.ContentProcessingStatus.READY
              ? true
              : false,
        });
      }
    });
    return options;
  }, [contents]);

  const sourcePhotosOption = useMemo(() => {
    const options: DropdownOption[] = [];
    photos.forEach(photo => {
      if (photo.photoType === 'source') {
        options.push({
          text: photo.screenTitle?.toString() ?? '',
          value: photo.contentId?.toString() ?? '',
        });
      }
    });
    return options;
  }, [photos]);

  const getZones = useMemo(
    () => (designDxfIdx: string | number) => {
      const zones: DropdownOption[] = [];
      Object.values(contents.byId || {}).forEach(content => {
        if (content.type !== T.ContentType.VOLUME) {
          return;
        }
        const calculation = content.info.calculatedVolume.calculation as T.DesignCalculationInfo;
        const designDxfId = calculation?.designDxfId;
        if (!designDxfId) {
          return;
        }
        if (designDxfId.toString() === designDxfIdx.toString()) {
          zones.push({
            text: content.title ?? '',
            value: content.id,
          });
        }
      });
      return zones;
    },
    [contents]
  );

  return { dxfOptions, sourcePhotosOption, getZones, contents };
};

export default useEarthWorkFile;
