/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-for-of */
import {
  BoxGeometry,
  BufferGeometry,
  Intersection,
  LinearFilter,
  Material,
  Mesh,
  MeshBasicMaterial,
  RGBAFormat,
  Raycaster,
  TextureLoader,
  Vector3,
} from 'three';
import { CadNodeGeometry } from './CADNodeGeometry';
import { CADOverlay } from '.';
import * as T from '^/types';
import { makeBucketURL } from '^/store/duck/API';
import { BlueprintObject } from '../ThreeObjects/Drawing';

const MAX_ZOOM = 22;

const MAP_CHILD_NODES = 4;
/* eslint-disable @typescript-eslint/ban-ts-comment */
const BOX_GEOMETRY = new BoxGeometry(50, 50, 50);
const MATERIAL = new MeshBasicMaterial({ color: 'blue', depthTest: false });
export class CADNode extends Mesh {
  public parentNode: CADNode | null = null;
  public level: number = 0;
  public x: number = 0;
  public y: number;
  public geometry: CadNodeGeometry = new CadNodeGeometry();
  public nodesLoaded: number = 0;
  public subdivided: boolean = false;
  public cadOverlay: CADOverlay;
  public textureLoaded: boolean = false;

  /**
   * Flag to indicate if the map node was disposed.
   *
   * When a map node is disposed its resources are dealocated to save memory.
   */
  public disposed: boolean = false;

  /**
   * Flag to check if the node is a mesh by the renderer.
   *
   * Used to toggle the visibility of the node. The renderer skips the node rendering if this is set false.
   */
  // @ts-ignore
  public isMesh: boolean = true;

  public isInside: boolean = false;

  public constructor(
    parentNode: CADNode | null,
    level: number,
    x: number,
    y: number,
    cadOverlay: CADOverlay,
    geometry: BufferGeometry,
    material: MeshBasicMaterial
  ) {
    super(geometry, material);
    this.level = level;
    this.parentNode = parentNode;
    this.x = x;
    this.y = y;
    this.cadOverlay = cadOverlay;

    // material.depthTest = false;

    if (this.cadOverlay?.content?.type !== T.ContentType.THREE_D_ORTHO) {
      this.renderOrder = 1;
    }

    void this.initialize();
  }

  public isPointBetween(
    point: { x: number; y: number },
    point1: { x: number; y: number },
    point2: { x: number; y: number }
  ): boolean {
    const minX = Math.min(point1.x, point2.x);
    const minY = Math.min(point1.y, point2.y);
    const maxX = Math.max(point1.x, point2.x);
    const maxY = Math.max(point1.y, point2.y);

    return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
  }

  public updateOpacity(opacity: number): void {
    // @ts-ignore
    this.material.transparent = true;

    // @ts-ignore
    this.material.opacity = opacity / 100;
    // @ts-ignore
    this.material.needsUpdate = true;
  }

  public createChildNodes(): void {
    const level = this.level + 1;
    const x = this.x * 2;
    const y = this.y * 2;

    const node1 = new CADNode(
      this,
      level,
      x,
      y,
      this.cadOverlay,
      this.geometry,
      new MeshBasicMaterial({ transparent: true, opacity: 0 })
    );
    node1.scale.set(0.5, 1.0, 0.5);
    node1.position.set(-0.25, 0, -0.25);
    this.add(node1);
    node1.updateMatrix();
    node1.updateMatrixWorld(true);

    const node2 = new CADNode(
      this,
      level,
      x + 1,
      y,
      this.cadOverlay,
      this.geometry,
      new MeshBasicMaterial({ transparent: true, opacity: 0 })
    );
    node2.scale.set(0.5, 1.0, 0.5);
    node2.position.set(0.25, 0, -0.25);
    this.add(node2);
    node2.updateMatrix();
    node2.updateMatrixWorld(true);

    const node3 = new CADNode(
      this,
      level,
      x,
      y + 1,
      this.cadOverlay,
      this.geometry,
      new MeshBasicMaterial({ transparent: true, opacity: 0 })
    );
    node3.scale.set(0.5, 1.0, 0.5);
    node3.position.set(-0.25, 0, 0.25);
    this.add(node3);
    node3.updateMatrix();
    node3.updateMatrixWorld(true);

    const node4 = new CADNode(
      this,
      level,
      x + 1,
      y + 1,
      this.cadOverlay,
      this.geometry,
      new MeshBasicMaterial({ transparent: true, opacity: 0 })
    );
    node4.scale.set(0.5, 1.0, 0.5);
    node4.position.set(0.25, 0, 0.25);
    this.add(node4);
    node4.updateMatrix();
    node4.updateMatrixWorld(true);
  }
  public subdivide(): void {
    if (
      this.children.length > 0 ||
      this.level + 1 > MAX_ZOOM ||
      (this.parentNode !== null && this.parentNode.nodesLoaded < MAP_CHILD_NODES) ||
      !this.isInside
    ) {
      return;
    }
    this.createChildNodes();

    this.subdivided = true;
  }

  public simplify(): void {
    // Dispose resources in use
    for (let i = 0; i < this.children.length; i++) {
      (this.children[i] as CADNode).dispose();
    }

    // Clear children and reset flags
    this.subdivided = false;
    this.isMesh = true;
    this.children = [];
    this.nodesLoaded = 0;
  }

  public async loadData(): Promise<void> {
    if (this.level < 12 || this.level > MAX_ZOOM) {
      return;
    }

    const [tileX, tileY] = this.cadOverlay.getTopMostTile(this.level);
    const [bottomX, bottomY] = this.cadOverlay.getBottomMostTile(this.level);
    const n = Math.pow(2, this.level);
    const isInside = this.isPointBetween(
      { x: this.x, y: n - 1 - this.y },
      { x: tileX, y: tileY },
      { x: bottomX, y: bottomY }
    );

    if (!isInside) {
      return;
    }
    if (this.disposed) {
      return;
    }

    try {
      const textureLoader = new TextureLoader();

      const isThreeDOrtho = this.cadOverlay.content.type === T.ContentType.THREE_D_ORTHO;
      const orthoPhotoURLCheck = isThreeDOrtho ? '' : '@2x';
      const orthoPhotoTilesCheck = isThreeDOrtho ? 'tiles' : 'raster_tiles';

      const NEW_URL = `${this.cadOverlay.contentId}/${orthoPhotoTilesCheck}/${this.level}/${
        this.x
      }/${n - 1 - this.y}${orthoPhotoURLCheck}.png`;

      const response = await fetch(makeBucketURL(NEW_URL), { credentials: 'include' });

      const value2 = await response.blob();
      if (response.status === 200) {
        const texture = textureLoader.load(URL.createObjectURL(value2));
        texture.generateMipmaps = false;
        texture.format = RGBAFormat;
        texture.magFilter = LinearFilter;
        texture.minFilter = LinearFilter;

        // @ts-ignore
        this.material.map = texture;

        // @ts-ignore
        this.material.transparent = true;

        // @ts-ignore
        this.material.opacity = this.cadOverlay.opacity ?? 1;
        // @ts-ignore
        this.material.needsUpdate = true;

        texture.needsUpdate = true;
        this.textureLoaded = true;
        if (!(this.cadOverlay.parent as BlueprintObject).dummy) {
          const mesh = new Mesh(BOX_GEOMETRY, MATERIAL);
          //@ts-ignore
          mesh.isMesh = false;
          (this.cadOverlay.parent as BlueprintObject).dummy = mesh;

          (this.cadOverlay.parent as BlueprintObject).viewer.scene.add(mesh);
          const position = (
            this.cadOverlay.parent as BlueprintObject
          ).viewer.runtime?.getPositionFromLatLongHeight({
            lat: (this.cadOverlay.parent as BlueprintObject).center[1],
            long: (this.cadOverlay.parent as BlueprintObject).center[0],
            height: 0,
          });
          if (position) {
            mesh.position.x = position.x;
            mesh.position.y = position.y;
            const z = this.cadOverlay.getWorldPosition(new Vector3(0, 0, 0)).z;
            mesh.position.z = z;
          }
        }
      }
    } catch (e) {
      if (this.disposed) {
        return;
      }
    }
  }

  public async initialize(): Promise<void> {
    const n = Math.pow(2, this.level);

    const [tileX, tileY] = this.cadOverlay.getTopMostTile(this.level);
    const [bottomX, bottomY] = this.cadOverlay.getBottomMostTile(this.level);

    const isInside = this.isPointBetween(
      { x: this.x, y: n - 1 - this.y },
      { x: tileX, y: tileY },
      { x: bottomX, y: bottomY }
    );

    if (isInside) {
      this.isInside = true;

      await this.loadData();
    }

    this.nodeReady();
  }

  /**
   * Increment the child loaded counter.
   *
   * Should be called after a map node is ready for display.
   */
  public nodeReady(): void {
    if (this.disposed) {
      this.dispose();
      return;
    }

    if (this.parentNode !== null) {
      this.parentNode.nodesLoaded++;

      if (this.parentNode.nodesLoaded === MAP_CHILD_NODES) {
        if (this.parentNode.subdivided) {
          // @ts-ignore
          this.parentNode.isMesh = false;
        }

        for (let i = 0; i < this.parentNode.children.length; i++) {
          this.parentNode.children[i].visible = true;
        }
      }
    } else {
      this.visible = true;
    }
  }

  /**
   * Dispose the map node and its resources.
   *
   * Should cancel all pending processing for the node.
   */
  public dispose(): void {
    this.disposed = true;

    const self = this as Mesh;

    try {
      const material = self.material as Material;
      material.dispose();

      // @ts-ignore
      if (material.map) {
        // @ts-ignore
        material.map.dispose();
      }
    } catch (e) {
      console.log(e);
    }

    try {
      self.geometry.dispose();
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Overrides normal raycasting, to avoid raycasting when isMesh is set to false.
   */
  public raycast(raycaster: Raycaster, intersects: Intersection[]): void {
    if (this.isMesh) {
      super.raycast(raycaster, intersects);
    }
  }
}
