import styled from 'styled-components';

export const Root = styled.div<{ sidebarWidth: number }>`
  margin-top: 50px;
  margin-left: ${props => props.sidebarWidth}px;
  padding: 40px 40px 20px 40px;
  background-color: #ebebeb;
  height: 100%;
  max-width: calc(100% - ${props => props.sidebarWidth}px - 80px);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.3s ease-in-out;
`;

export const TableWrapper = styled.div`
  position: relative;
`;
export const TableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  overflow: scroll;
`;

export const BurndownChartWrapper = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  border-collapse: separate;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  background-color: #f4f4f4;
  width: max-content;
  min-width: 100%;
`;

export const Tr = styled.tr`
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const ScrollableWrapper = styled.div`
  overflow-x: auto;
  margin-left: 750px;
  position: relative;
  width: calc(100% - 750px);

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

export const FixedColumnsWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 750px;
  overflow: hidden;
  background: #f4f4f4;
  z-index: 2;
`;

export const Th = styled.th<{ isScrollable?: boolean }>`
  padding: 12px 16px;
  border-right: 1px solid #e5e5e6;
  border-bottom: 1px solid #e5e5e6;
  background-color: #f1f3f5;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  color: #495057;
  position: relative;
  z-index: ${props => (props.isScrollable ? 1 : 2)};

  &.volume-col {
    min-width: 70px;
    text-align: left;
  }

  &.soil-type-col {
    min-width: 60px;
    text-align: left;
  }

  &.number-col {
    min-width: 60px;
    text-align: right;
  }

  &.changes-col {
    min-width: 100px;
  }

  &.fixed-col {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background-color: transparent;
  }

  &.hidden-col {
    height: 14px;
    background-color: transparent;
    border-right: none;
    border-bottom: none;
    border-left: none;
  }
`;

export const Td = styled.td<{
  isNegative?: boolean;
  backgroundColor?: string;
  textColor?: string;
  isFixed?: boolean;
}>`
  border-right: 1px solid #e5e5e6;
  border-bottom: 1px solid #e5e5e6;
  text-align: right;
  vertical-align: middle;
  color: ${props => (props.isNegative ? '#dc3545' : props.textColor ? props.textColor : '#212529')};
  background-color: ${props => props.backgroundColor || 'transparent'};
  min-width: 50px;
  position: relative;
  padding: 20px;

  &.volume-col {
    min-width: 70px;
    text-align: center;
  }

  &.soil-type-col {
    min-width: 50px;
    text-align: left;
  }

  &.cut-col {
    min-width: 60px;
    text-align: left;
    padding: 15px;
  }

  &.fill-col {
    min-width: 60px;
    text-align: left;
    padding: 15px;
  }
`;

export const IconCell = styled(Td)`
  text-align: right;
  min-width: 20px;
  padding: 15px;
`;

export const TitleCell = styled(Td)`
  text-align: center;
  font-weight: 500;
  color: #495057;
  min-width: 60px;
  padding: 15px;
`;

export const HeaderRow = styled(Tr)`
  position: relative;
  &:hover {
    background-color: #f8f9fa;
  }
`;

export const SubHeaderRow = styled(HeaderRow)`
  background-color: #f8f9fa;
`;

export const EditableInput = styled.input`
  border: 1px solid transparent;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  text-align: inherit;
  color: inherit;
  outline: none;
  position: absolute;
  inset: 0;
  padding: 0px 6px;

  &:focus {
    border-color: #4dabf7;
  }

  &:hover:not(:focus) {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const AddRowButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #4dabf7;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #339af0;
  }

  &:active {
    background-color: #228be6;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;
