/* eslint-disable max-lines */
import React, {
  FC,
  FormEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { HorizontalWideDivider } from '^/components/atoms/ContentsListItem';
import {
  DoubleSlider,
  DoubleSliderIndex,
  DoubleSliderValues,
} from '^/components/atoms/DoubleSlider';
import HillshadeMultiplyToggle from '^/components/atoms/HillshadeMultiplyToggle';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import RainbowHistogram from '^/components/atoms/RainbowHistogram';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { FontFamily } from '^/constants/styles';
import { UseL10n, UseState, useL10n, useRole } from '^/hooks';
import {
  AuthHeader,
  jsonContentHeader,
  makeAuthHeader,
  makeV2APIURL,
  makeVersionHeader,
} from '^/store/duck/API';
import { DTMHillshadeMultiplyToggled, PatchContent, contentsSelector } from '^/store/duck/Contents';
import { ChangeIn3D, ChangeIn3DPointCloud, OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { UNIT_SYMBOL, VALUES_PER_METER, determineUnitType } from '^/utilities/imperial-unit';
import { convertPercentToRanged, convertRangedToPercent } from '^/utilities/math';
import { isAllowToggleDSMElevation } from '^/utilities/role-permission-check';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { Fallback } from './fallback';
import Text from './text';
import { contentsStore, useContentsStore } from '^/store/zustand/content/contentStore';

export const contentsListMapItemClassName: string = 'contents-list-map-item';
export const contentsListDtmItemClassName: string = 'contents-list-dsm-item';

const ElevationWrapper = styled.div({
  display: 'flex',
  justfiyContent: 'space-around',
  alignItems: 'space-around',
  flexDirection: 'column',

  width: '100%',
  padding: '10px',
  border: '1px solid #EAEFF3',
  borderBottom: 'none',
  borderRadius: '5px 5px 0px 0px',
});

const ElevationText = styled.div({
  fontSize: '13px',
  fontWeight: 'bold',

  color: dsPalette.title.toString(),
});

const HistogramWrapper = styled.div({
  marginTop: '8px',
  marginBottom: '12px',
});

const InputWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',

  paddingTop: '12px',
});

const MinMaxWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
});

const MinMaxLabel = styled.input({
  boxSizing: 'border-box',
  width: '65px',
  height: '29px',

  padding: '10px',
  marginRight: '3px',

  borderRadius: '5px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: palette.ContentsList.inputBorder.toString(),

  fontSize: '12px',
  fontFamily: FontFamily.ROBOTO,
  fontWeight: 500,
  color: 'var(--color-theme-primary)',
  textAlign: 'center',
});

const Unit = styled.div({
  width: '12px',
  height: '13px',

  marginBottom: '2.7px',

  fontSize: '13px',

  fontFamily: FontFamily.ROBOTO,
  lineHeight: 1.31,

  color: dsPalette.title.toString(),
});

const GenerateHillshadeButton = styled.button({
  backgroundColor: 'var(--color-theme-primary)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: palette.white.toString(),
  padding: '8px 16px',
  borderRadius: '5px',
  fontSize: '13px',
  fontFamily: FontFamily.ROBOTO,
  fontWeight: 500,
  lineHeight: 1.31,
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
});

const GenerateHillshadeWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const WarningText = styled.p({
  backgroundColor: palette.ContentsList.itemHoverGray.toString(),
  padding: '8px 16px',
  borderRadius: '5px',
  fontSize: '12px',
  fontFamily: FontFamily.ROBOTO,
  fontWeight: 300,
  lineHeight: 1.31,
});

const loadingDivCustomStyle = {
  width: '14px',
  height: '14px',
};

interface MinMaxString {
  min: string;
  max: string;
}

interface MinMaxNumber {
  min: number;
  max: number;
}

interface DTM {
  elevationCounts: T.DTMContent['info']['elevation']['counts'] | null;
  minHeight: T.DTMContent['info']['minHeight'] | null;
  maxHeight: T.DTMContent['info']['maxHeight'] | null;
}

export interface Props {
  readonly content: T.DTMContent;
  readonly children: React.ReactNode;
}

export const RawContentsListDTMItem: FC<Props> = ({ content, children }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const role: T.PermissionRole = useRole();

  const [editedHeights, setEditedHeights]: UseState<MinMaxString | undefined> = useState<
    MinMaxString | undefined
  >();
  const [percents, setPercents]: UseState<MinMaxNumber> = useState<MinMaxNumber>(
    content.config?.type === T.ContentType.DTM && content.config.percents
      ? content.config.percents
      : { min: 0, max: 1 }
  );

  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);
  const project = useSelector((s: T.State) => s.Projects.projects.byId[projectId ?? NaN]);
  const editingContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);
  const in3D = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const in3DPointCloud = useSelector((s: T.State) => s.Pages.Contents.in3DPointCloud);
  const isHillshadeMultiplyToggled = useSelector(
    (s: T.State) => s.Contents.isDTMHillshadeMultiplyToggled
  );
  const Contents = useSelector((s: T.State) => s.Contents);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  // Memoize the computation using useMemo
  const { unitType, opacity, isSelected, hasHillshade, isHillshadeProcessing } = useMemo(() => {
    if (!projectUnit && !projectId) {
      throw new Error('No Project Id in Pages.Contents.projectId');
    }

    const _hasHillshade: boolean =
      content.type === T.ContentType.DTM && Boolean(content.info.hasHillshade);
    const _isHillshadeProcessing: boolean =
      content.info.hillshadeStatus === T.HillshadeStatus.PROCESSING;
    const _isSelected: boolean = contentsSelector.isSelected(ProjectConfigPerUser)(content.id);

    return {
      unitType: project ? determineUnitType(project?.unit) : determineUnitType(projectUnit),
      opacity: content.config?.opacity !== undefined ? content.config.opacity : 100,
      isSelected: _isSelected,
      hasHillshade: _hasHillshade,
      isHillshadeProcessing: _isHillshadeProcessing,
    };
  }, [project, projectUnit, projectId, editingContentId, Contents, ProjectConfigPerUser]);

  useEffect(() => {
    if (in3D && isSelected) {
      dispatch(
        PatchContent({
          content: {
            id: content.id,
            config: {
              selectedAt: undefined,
            },
          },
        })
      );
    }
  }, [in3D, isSelected]);

  useEffect(() => {
    if (editingContentId !== content.id || !isSelected) {
      return;
    }
    if (in3D) {
      dispatch(ChangeIn3D({ in3D: false }));
    }
    if (in3DPointCloud) {
      dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
    }
  }, [editingContentId]);

  const { elevationCounts, minHeight, maxHeight }: DTM =
    content.type === T.ContentType.DTM
      ? {
          elevationCounts: content.info.elevation.counts,
          minHeight: content.info.minHeight
            ? content.info.minHeight * VALUES_PER_METER[unitType]
            : null,
          maxHeight: content.info.maxHeight
            ? content.info.maxHeight * VALUES_PER_METER[unitType]
            : null,
        }
      : {
          elevationCounts: null,
          minHeight: null,
          maxHeight: null,
        };

  const updateContent: (opacity: number, percents: T.DTMConfigPerUser['percents']) => void = (
    newOpacity,
    newPercents
  ) => {
    updateContentConfig(content.id, {
      ...content.config,
      type: T.ContentType.DTM,
      opacity: newOpacity,
      percents: newPercents,
    });
  };

  const patchContent: (opacity: number, percents: T.DTMConfigPerUser['percents']) => void = (
    newOpacity,
    newPercents
  ) => {
    dispatch(
      PatchContent({
        content: {
          id: content.id,
          config: { type: T.ContentType.DTM, opacity: newOpacity, percents: newPercents },
        },
      })
    );
  };

  const handleElevationChange: (values: DoubleSliderValues) => void = values => {
    if (isAllowToggleDSMElevation(role)) {
      setPercents({
        min: values[DoubleSliderIndex.FIRST_SLIDER],
        max: values[DoubleSliderIndex.SECOND_SLIDER],
      });

      updateContent(opacity, percents);
    } else {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
    }
  };

  const handleMinInputChange: (event: FormEvent<HTMLInputElement>) => void = event => {
    /**
     * @todo
     * Following conversion should handle invalid user input
     */
    /* istanbul ignore next: following is impossible case */
    if (minHeight === null || maxHeight === null) {
      throw new Error('Error code m/CDII:hMinIC');
    }
    const min: string = event.currentTarget.value;
    const max: string =
      editedHeights === undefined
        ? convertPercentToRanged(percents.max, minHeight, maxHeight).toFixed(2)
        : editedHeights.max;

    setEditedHeights({ min, max });
  };

  const handleMaxInputChange: (event: FormEvent<HTMLInputElement>) => void = event => {
    /**
     * @todo
     * Following conversion should handle invalid user input
     */
    /* istanbul ignore next: following is impossible case */
    if (minHeight === null || maxHeight === null) {
      throw new Error('Error code m/CDII:hMaxIC');
    }
    const min: string =
      editedHeights === undefined
        ? convertPercentToRanged(percents.min, minHeight, maxHeight).toFixed(2)
        : editedHeights.min;
    const max: string = event.currentTarget.value;

    setEditedHeights({ min, max });
  };

  const handleBlur: () => void = () => {
    if (editedHeights === undefined) {
      return;
    }

    const min: number = parseFloat(editedHeights.min);
    const max: number = parseFloat(editedHeights.max);

    if (minHeight !== null && maxHeight !== null && !isNaN(min) && !isNaN(max) && max > min) {
      let minPercent: number = convertRangedToPercent(min, minHeight, maxHeight);
      let maxPercent: number = convertRangedToPercent(max, minHeight, maxHeight);

      if (minPercent < 0) {
        minPercent = 0;
      }

      if (maxPercent > 1) {
        maxPercent = 1;
      }

      setPercents({ min: minPercent, max: maxPercent });
      updateContent(opacity, { min: minPercent, max: maxPercent });
      patchContent(opacity, { min: minPercent, max: maxPercent });
    }

    setEditedHeights(undefined);
  };

  const handleKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void = event => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  const handleMouseUp: () => void = () => {
    patchContent(opacity, percents);
  };

  const handleHillshadeToggle: () => void = () => {
    dispatch(
      DTMHillshadeMultiplyToggled({ isDTMHillshadeMultiplyToggled: !isHillshadeMultiplyToggled })
    );
  };

  const handleGenerateHillshade: () => Promise<void> = async () => {
    if (isHillshadeProcessing || hasHillshade) {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.CONTENT_PROCESSING,
          errorMessage: l10n(Text.hillshadeAlreadyBeingProcessed),
        })
      );
      return;
    }
    const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);
    const versionHeader = makeVersionHeader();
    const headers = {
      ...authHeader,
      ...versionHeader,
      ...jsonContentHeader,
    };
    const url = makeV2APIURL('contents', content.id, 'run_hillshade');
    try {
      const response = await http.post(url, {}, { headers });
      if (response.status === 201) {
        dispatch(
          OpenContentPagePopup({
            popup: T.ContentPagePopupType.CONTENT_PROCESSING,
            errorMessage: l10n(Text.hillshadeBeingProcessed),
          })
        );
        contentsStore.getState().addContent({
          ...content,
          info: {
            ...content.info,
            hillshadeStatus: T.HillshadeStatus.PROCESSING,
          },
        });
      }
    } catch (error) {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.CONTENT_PROCESSING,
          errorMessage: l10n(Text.hillshadeAlreadyBeingProcessed),
        })
      );
    }
  };

  const dtmMinInput: string | null =
    editedHeights !== undefined
      ? editedHeights.min
      : convertPercentToRanged(
          percents.min,
          minHeight as NonNullable<DTM['minHeight']>,
          maxHeight as NonNullable<DTM['maxHeight']>
        ).toFixed(2);

  const dtmMaxInput: string | null =
    editedHeights !== undefined
      ? editedHeights.max
      : convertPercentToRanged(
          percents.max,
          minHeight as NonNullable<DTM['minHeight']>,
          maxHeight as NonNullable<DTM['maxHeight']>
        ).toFixed(2);

  const histogram: ReactNode = elevationCounts ? (
    <RainbowHistogram data={elevationCounts} percents={percents} />
  ) : null;

  const minMaxInputs: ReactNode = (
    <>
      <MinMaxWrapper>
        <MinMaxLabel
          value={dtmMinInput ?? ''}
          onChange={handleMinInputChange}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
        />
        <Unit>{UNIT_SYMBOL[unitType]}</Unit>
      </MinMaxWrapper>
      <MinMaxWrapper>
        <MinMaxLabel
          value={dtmMaxInput ?? ''}
          onChange={handleMaxInputChange}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
        />
        <Unit>{UNIT_SYMBOL[unitType]}</Unit>
      </MinMaxWrapper>
    </>
  );

  const elevation: ReactNode =
    content.status === T.ContentProcessingStatus.COMPLETED ? (
      <>
        {/* <HorizontalWideDivider /> */}
        <ElevationWrapper>
          <ElevationText>{l10n(Text.elevation)}</ElevationText>
          <HistogramWrapper>{histogram}</HistogramWrapper>
          <DoubleSlider
            min={0}
            max={1}
            gap={0.01}
            /* eslint-disable: @typescript-eslint/strict-boolean-expressions */
            values={[percents.min || 0, percents.max || 1]}
            onChange={handleElevationChange}
            onAfterChange={handleMouseUp}
          />
          <InputWrapper>{minMaxInputs}</InputWrapper>
          <HorizontalWideDivider style={{ marginBottom: '0px' }} />
        </ElevationWrapper>
      </>
    ) : undefined;

  const dtmHillshadeMultiplyToggle =
    content?.status === T.ContentProcessingStatus.COMPLETED && hasHillshade ? (
      <>
        <HorizontalWideDivider />
        <HillshadeMultiplyToggle
          isEnabled={Boolean(isHillshadeMultiplyToggled)}
          onClick={handleHillshadeToggle}
        />
        <HorizontalWideDivider />
      </>
    ) : undefined;
  const generateHillshade =
    content?.status === T.ContentProcessingStatus.COMPLETED && !hasHillshade ? (
      <GenerateHillshadeWrapper>
        <HorizontalWideDivider />
        <WarningText>
          {isHillshadeProcessing ? l10n(Text.processingHillshade) : l10n(Text.noHillshadeAvailable)}
        </WarningText>
        <GenerateHillshadeButton disabled={isHillshadeProcessing} onClick={handleGenerateHillshade}>
          {isHillshadeProcessing ? (
            <LoadingIcon loadingDivCustomStyle={loadingDivCustomStyle} />
          ) : (
            l10n(Text.generateHillshade)
          )}
        </GenerateHillshadeButton>
        <HorizontalWideDivider />
      </GenerateHillshadeWrapper>
    ) : undefined;

  return (
    <>
      {dtmHillshadeMultiplyToggle}
      {generateHillshade}
      {children}
      <HorizontalWideDivider />
      {elevation}
    </>
  );
};

export const ContentsListDTMItem: FC<Props> = withErrorBoundary(RawContentsListDTMItem)(Fallback);
