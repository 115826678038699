import React from 'react';
import styled from 'styled-components';
import SkeletonElement from '../SkeletonElement';

interface ContentItemSkeletonLoaderProps {
  width?: string;
  height?: string;
}

const ContentItemSkeletonLoaderWrapper = styled.div`
  padding: 0 15px 0 20px;
  height: 40px;
`;

const ContentItemSkeletonLoader: React.FC<ContentItemSkeletonLoaderProps> = ({
  height = '35px',
  width,
}) => (
  <ContentItemSkeletonLoaderWrapper>
    <SkeletonElement width={width} height={height} />
  </ContentItemSkeletonLoaderWrapper>
);

export default ContentItemSkeletonLoader;
