import {
  GetTimelapseCollectionsResponse,
  GetTimelapseImagesResponse,
  PostBulkPhotosEditResponse,
  TimelapseCollection,
  TimelapseDownloadResponse,
  useTimelapseStore,
} from '^/store/zustand/timelapse/timelapseStore';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { makeV2APIURL } from '^/store/duck/API';
import { useAuthHeader } from '../useAuthHeader';
import download from '^/utilities/download';
import { useRef } from 'react';

const useTimelapseContents = (projectId: number) => {
  const authHeader = useAuthHeader();
  const abortControllerRef = useRef<AbortController | null>(null);
  const {
    setTimeLapseDownloadStatus,
    setTimelapseCollectionsStatus,
    setTimelapseInspectionImagesInfo,
    setTimelapseInspectionImagesStatus,
    setTimelapseCollections,
    setTimelapseInspectionImages,
    timelapseCollections,
  } = useTimelapseStore(s => ({
    setTimeLapseDownloadStatus: s.setTimelapseDownloadStatus,
    setTimelapseCollectionsStatus: s.setTimelapseCollectionsStatus,
    setTimelapseInspectionImagesStatus: s.setTimelapseInspectionImagesStatus,
    setTimelapseCollections: s.setTimelapseCollections,
    setTimelapseInspectionImagesInfo: s.setTimelapseInspectionImagesInfo,
    setTimelapseInspectionImages: s.setTimelapseInspectionImages,
    timelapseCollections: s.timelapseCollections,
  }));

  async function includeExcludeTimelapseImage(
    isExcluded: boolean,
    imageIds: Array<T.Photo['id']>,
    collectionId: TimelapseCollection['id']
  ) {
    if (!projectId || !authHeader) {
      return;
    }
    try {
      const body = {
        photo_ids: imageIds,
        exclude: isExcluded,
      };

      await http.patch<PostBulkPhotosEditResponse>(
        makeV2APIURL('timelapse_locations', collectionId, 'exclude'),
        body,
        { headers: authHeader }
      );
    } catch (e) {
      setTimelapseInspectionImagesStatus(T.APIStatus.ERROR);
      throw new Error('Something went wrong');
    }
  }

  async function getTimelapseCollections(type: T.TimelapseCollectionTypes) {
    if (!projectId || !authHeader) {
      return;
    }
    setTimelapseCollectionsStatus(T.APIStatus.PROGRESS);

    try {
      const response = await http.get<GetTimelapseCollectionsResponse>(
        makeV2APIURL('projects', projectId, 'timelapse_locations'),
        {
          headers: authHeader,
          params: { type },
        }
      );
      const _collections = response.data.data;
      setTimelapseCollections(_collections);
      setTimelapseCollectionsStatus(T.APIStatus.SUCCESS);
    } catch (e) {
      setTimelapseCollections([]);
      setTimelapseCollectionsStatus(T.APIStatus.ERROR);
    }
  }

  async function getTimelapseInspectionImages(collectionId: TimelapseCollection['id']) {
    setTimelapseInspectionImagesStatus(T.APIStatus.PROGRESS);

    try {
      const response = await http.get<GetTimelapseImagesResponse>(
        makeV2APIURL('timelapse_locations', collectionId),
        { headers: authHeader }
      );
      const images = response.data.data.photos;
      const info = response.data.data.info;
      setTimelapseInspectionImages(images);
      setTimelapseInspectionImagesInfo(info);
      setTimelapseInspectionImagesStatus(T.APIStatus.SUCCESS);
    } catch (e) {
      setTimelapseInspectionImages([]);
      setTimelapseInspectionImagesStatus(T.APIStatus.ERROR);
    }
  }

  async function renameTimelapseCollection(
    collectionId: TimelapseCollection['id'],
    newName: string
  ) {
    try {
      const URL = makeV2APIURL('timelapse_locations', collectionId);
      const response = await http.patch(
        URL,
        {
          id: collectionId,
          name: newName,
        },
        { headers: authHeader }
      );
      const updatedCollections = [...timelapseCollections];
      const idx = updatedCollections.findIndex(col => col.id === collectionId);
      updatedCollections[idx] = response.data.data;
      setTimelapseCollections(updatedCollections);
    } catch (e) {
      console.error('Failed to rename collection.', e);
    }
  }

  async function downloadTimelapseImage(
    photoIds: Array<T.Photo['id']>,
    locationId: TimelapseCollection['id'],
    speed: number
  ) {
    setTimeLapseDownloadStatus(T.APIStatus.PROGRESS);
    if (!projectId || !authHeader) {
      return;
    }

    const body = {
      photoIds: photoIds,
      locationId,
      speed,
      type: 'time-lapse',
    };
    abortControllerRef.current = new AbortController();
    try {
      const response = await http.post<TimelapseDownloadResponse>(
        makeV2APIURL('projects', projectId, 'timelapse_locations/generate_timelapse'),
        body,
        { headers: authHeader, signal: abortControllerRef.current.signal }
      );
      const url = response.data.video_url;
      if (url && !abortControllerRef.current.signal.aborted) {
        download(url, false, true, 'timelapse.mp4');
        setTimeLapseDownloadStatus(T.APIStatus.SUCCESS);
      }
    } catch (e) {
      setTimeLapseDownloadStatus(T.APIStatus.ERROR);
    }
  }

  function cancelDownload() {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort('AbortError');
      abortControllerRef.current = null;
    }
  }

  return {
    getTimelapseCollections,
    getTimelapseInspectionImages,
    includeExcludeTimelapseImage,
    downloadTimelapseImage,
    cancelDownload,
    renameTimelapseCollection,
  };
};

export default useTimelapseContents;
