/* eslint-disable max-lines */
import React, { ReactNode, useMemo } from 'react';
import { useThreeStore } from '^/components/three/ThreeStore';
import { getCenterInteraction } from '^/components/three/utils';
import { APIToContent, contentsSelector, PatchContent } from '^/store/duck/Contents';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getCurrentScreenContentIds, useGetCurrentScreenContentIds } from './contents';
import * as T from '^/types';
import {
  AuthHeader,
  getRequestErrorTypeAxios,
  jsonContentHeader,
  makeV2APIURL,
  makeVersionHeader,
  VersionHeader,
} from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { http } from '^/utilities/api';
import { defaultGroup } from '^/constants/defaultContent';
import { getSingleContentId } from '^/utilities/state-util';
import { useESSContentsStore } from '^/store/essContentsStore';
import { runScreenCapture } from '^/utilities/camera-button-util';
import { usePresentationStore } from '^/store/presentationStore';
import { toast, ToastOptions } from 'react-toastify';
import {
  ChangeContentsSidebarTab,
  ChangeCurrentMeshEngine,
  ChangeIn3D,
  ChangeRotation,
  ChangeTwoDDisplayCenter,
  ChangeTwoDDisplayZoom,
  OpenContentPagePopup,
} from '^/store/duck/Pages';
import palette from '^/constants/palette';
import styled from 'styled-components';
import CheckSVG from '^/assets/icons/photo/check-mark.svg';
import { PatchProjectConfig } from '^/store/duck/ProjectConfig';
import { updateContentSelectedAtInServer } from '^/utilities/updateContentSelectedAtInServer';
import { useAuthHeader } from './useAuthHeader';
import { CtxSortEvent } from '^/utilities/ctxsort';
import Text from '^/components/molecules/PresentationContentsHorizontalList/text';
import { useL10n, UseL10n } from './useL10n';
import { useOlStore } from '^/store/olStore';
import { useNavigate } from 'react-router-dom';
import route from '^/constants/routes';
import { useAnnotationStore } from '^/store/annotationStore';
import { useShallow } from 'zustand/react/shallow';
import { Vector3 } from 'three';
import { useContentsStore } from '^/store/zustand/content/contentStore';

export const presentationGroupName: { [K in T.Language]: string } = {
  [T.Language.KO_KR]: '새 프레젠테이션',
  [T.Language.EN_US]: 'Presentation Title',
};

const ToastWrapper = styled.div({
  display: 'flex',
  background: palette.white.alpha(0.9).toString(),
  borderRadius: '5px',
  height: '68px',
  alignItems: 'center',
  gap: '20px',
});

const ToastContent = styled.p({
  color: '#4D4C4C',
  fontSize: '13px',
  fontWeight: 700,
});

const toastOptions: ToastOptions = {
  position: 'top-right',
  hideProgressBar: true,
  style: {
    color: palette.darkBlack.toString(),
    fontWeight: 500,
    marginTop: 'calc(100vh - 300px)',
  },
};

const ToastSvgWrapper = styled.div({
  width: 'fit-content',
});

export const displayToast = (message: string): ReactNode => (
  <ToastWrapper>
    <ToastSvgWrapper>
      <CheckSVG />
    </ToastSvgWrapper>
    <ToastContent>{message}</ToastContent>
  </ToastWrapper>
);

const getPresentationTitle = (presentationCount: number, lang: T.Language) =>
  `${presentationGroupName[lang]} ${presentationCount}`;

const getSlideTitle = (slideCount: number) => `Slide ${slideCount}`;

export function usePresentationUtils() {
  const [l10n]: UseL10n = useL10n();

  const { runtime, scene, camera, cameraControls, viewer } = useThreeStore(
    useShallow(s => ({
      runtime: s.runtime,
      scene: s.scene,
      camera: s.camera,
      cameraControls: s.cameraControls,
      viewer: s.viewer,
    }))
  );

  const {
    setCurrentlySelectedSlideId,
    setSlidesIdsByGroupId,
    setPresentationGroupIds,
    setSlideContentApiStatus,
    setPresentationAttachments,
    setPresentationContentById,
    setIsActiveSlide,
    setSelectedGroupId,
    setDeletingPresentationId,
    setDeletingSlideid,
    setSelectedThumbnails,
    selectedGroupId: currentlySelectedGroupId,
    attachments,
    presentationGroupIds,
    presentationContentsById,
    slideIdsByGroupId,
    isInPresentationMode,
    cameraPosition,
    cameraRotation,
  } = usePresentationStore(
    useShallow(s => ({
      setCurrentlySelectedSlideId: s.setCurrentlySelectedSlideId,
      setSlidesIdsByGroupId: s.setSlidesIdsByGroupId,
      setPresentationGroupIds: s.setPresentationGroupIds,
      setSlideContentApiStatus: s.setSlideContentApiStatus,
      setPresentationAttachments: s.setPresentationAttachments,
      setPresentationContentById: s.setPresentationContentById,
      setIsActiveSlide: s.setIsActiveSlide,
      setSelectedGroupId: s.setSelectedGroupId,
      setDeletingPresentationId: s.setDeletingPresentationId,
      setDeletingSlideid: s.setDeletingSlideid,
      setSelectedThumbnails: s.setSelectedThumbnails,
      selectedGroupId: s.selectedGroupId,
      attachments: s.attachments,
      presentationGroupIds: s.presentationGroupIds,
      presentationContentsById: s.presentationContentsById,
      slideIdsByGroupId: s.slideIdsByGroupId,
      isInPresentationMode: s.isInPresentationMode,
      cameraPosition: s.cameraPosition,
      cameraRotation: s.cameraRotation,
    }))
  );
  const updateContentsSelectedAtInStore = useContentsStore(s => s.updateContentsSelectedAtInStore);

  const zoomLevel = useOlStore(s => s.zoomLevel);

  const dispatch = useDispatch();
  const state = useStore<T.State>().getState();
  const currentScreenContentIds: Array<T.Content['id']> = useGetCurrentScreenContentIds();
  const authHeader: AuthHeader | undefined = useAuthHeader();

  const annotationIdsForSlide = useAnnotationStore(s => s.annotationIdsBySlideId);
  const setAnnotationIdsBySlideId = useAnnotationStore(s => s.setAnnotationIdsBySlideId);
  const setIsAnnotationModeOpen = useAnnotationStore(s => s.setIsAnnotationModeOpen);

  const navigate = useNavigate();

  const isIn3D: boolean = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const currentMeshEngine: T.MeshEngine = useSelector(
    (s: T.State) => s.Pages.Contents.currentMeshEngine
  );
  const hasThreeJsSupport: boolean = useSelector(
    (s: T.State) => s.Pages.Contents.hasThreeJsSupport
  );

  const changeToThree = () => {
    dispatch(ChangeCurrentMeshEngine({ engine: T.MeshEngine.THREEJS, hasThreeJsSupport }));
  };

  const changeThreeEngine = () => {
    if (currentMeshEngine === T.MeshEngine.CESIUM && hasThreeJsSupport) {
      changeToThree();
    }
  };

  const byId = useContentsStore(s => s.contents.byId);
  const lang = useSelector((s: T.State) => s.Pages.Common.language);

  const currentSelectedTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);

  const Pages = useSelector((s: T.State) => s.Pages);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const projectId = useSelector((s: T.State) => s.ProjectConfigPerUser.config?.projectId);
  const lastSelectedScreenId = useSelector(
    (s: T.State) => s.ProjectConfigPerUser.config?.lastSelectedScreenId
  );

  const meshId = getSingleContentId(state.Pages, ProjectConfigPerUser, T.ContentType.THREE_D_MESH);
  const orthoId = getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.MAP);
  const { essContentIds, essContents } = useESSContentsStore(s => ({
    essContents: s.essContents,
    essContentIds: s.essContentIds,
  }));

  const selectedContentIds: Array<T.Content['id']> = currentScreenContentIds.filter(id => {
    const content = byId[id];

    if (!content) {
      return false;
    }

    const isFlattenMap: boolean = content.category === T.ContentCategory.TERRAIN;
    const isOverlay: boolean = content.category === T.ContentCategory.OVERLAY;
    const isMeasurement: boolean = content.category === T.ContentCategory.MEASUREMENT;
    const isIssue: boolean = content.type === T.ContentType.ISSUE_POINT;
    const isMapType: boolean = content.category === T.ContentCategory.MAP;

    // do not include any content that is not measurement, overlay, or flatten map
    if (!isMeasurement && !isOverlay && !isFlattenMap && !isIssue && !isMapType) {
      return false;
    }

    // If the content is personal, don't include in the export at this moment.
    if (content?.isPersonal === true) {
      return false;
    }

    return (
      !contentsSelector.isProcessingOrFailed()(id) &&
      contentsSelector.isSelected(ProjectConfigPerUser)(id)
    );
  });

  const selectedContentsByCategory = useMemo(() => {
    const allCategories: Partial<Record<T.ContentCategory, Array<T.Content['id']>>> = {};
    selectedContentIds.forEach(id => {
      const content = byId[id];
      if (!content.category) {
        return;
      }
      if (!allCategories[content.category]) {
        allCategories[content.category] = [];
      }
      allCategories[content.category]!.push(content.id);
    });
    return allCategories;
  }, [selectedContentIds]);

  // TODO: make it optimal, try to reuse the logics
  const createPresentationSlide = async (
    groupId?: number | string,
    successMessage?: string,
    recentlyCreatedGroup?: T.Content
  ) => {
    const selectedGroupId = groupId;
    if (!selectedGroupId || !groupId) {
      const newGroup = await createPresentationFolder();
      if (newGroup) {
        await createPresentationSlide(newGroup.id, successMessage, newGroup);
        return;
      }
      return;
    }

    const slideCount = slideIdsByGroupId[selectedGroupId]?.length ?? 0;
    const title = getSlideTitle(slideCount + 1);
    const contentUrl = makeV2APIURL('projects', projectId!, 'contents');

    const createSlideInfo = (
      position: number[],
      rotation: number[],
      target: number[],
      baseMapType: '2D' | '3D',
      _screenId: string | number,
      _mapContents: Array<T.Content['id']>,
      _measurements?: Array<T.Content['id']>,
      _overlayIds?: Array<T.Content['id']>,
      _essContentIds?: Array<T.Content['id']>
    ): T.SlideContent['info'] => ({
      cameraPosition: position,
      cameraRotation: rotation,
      target,
      zoomLevel,
      baseMapType,
      screenId: _screenId,
      tab: currentSelectedTab,
      mapIds: _mapContents,
      measurementIds: _measurements || [],
      overlayIds: _overlayIds || [],
      essIds: _essContentIds || [],
    });

    const postSlide = async (slideInfo: T.SlideContent['info']) => {
      const body = {
        title,
        type: T.ContentType.SLIDE,
        color: '',
        info: JSON.stringify(slideInfo),
        ...(selectedGroupId && { groupId: selectedGroupId }),
      };
      const versionHeader: VersionHeader = makeVersionHeader();
      setSlideContentApiStatus(T.APIStatus.PROGRESS);

      const response = await http.post(contentUrl, body, {
        headers: {
          ...authHeader,
          ...versionHeader,
          ...jsonContentHeader,
        },
      });

      return response.data.data as T.SlideContent;
    };

    const updateStateAndCapture = async (
      content: T.SlideContent,
      viewElement: HTMLElement | null
    ) => {
      const presentationGroupId = content.groupId;
      if (!presentationGroupId) {
        return;
      }

      const recentlyCreatedSlide = { [content.id]: content };
      const newGroup = recentlyCreatedGroup;
      let updatedPresentationList = {};

      if (newGroup) {
        updatedPresentationList = {
          ...presentationContentsById,
          ...recentlyCreatedSlide,
          [newGroup?.id]: newGroup,
        };
      } else {
        updatedPresentationList = {
          ...presentationContentsById,
          ...recentlyCreatedSlide,
        };
      }
      const updatedGroupIdsWithChildIds = {
        ...slideIdsByGroupId,
        [presentationGroupId]: [...(slideIdsByGroupId[presentationGroupId] ?? []), content.id],
      };

      toast(displayToast(successMessage || ''), toastOptions);
      setPresentationContentById(updatedPresentationList);
      setSlidesIdsByGroupId(updatedGroupIdsWithChildIds);
      setCurrentlySelectedSlideId(content.id);

      if (content.id && viewElement) {
        const image = await runScreenCapture(viewElement, `slide_${content.id}.png`, false);
        if (image) {
          const attachmentApiUrl = makeV2APIURL('contents', content.id, 'attachments');
          const newFile = new File([image], `slide_${content.id}.png`, {
            type: 'image/png',
            lastModified: Date.now(),
          });
          const payload = new FormData();
          payload.append('file', newFile);
          payload.append('type', T.ContentType.SLIDE);

          const blobAttachment = getDataUrlAttachment(content.id, newFile);

          setPresentationAttachments(
            attachments ? [...attachments, blobAttachment] : [blobAttachment]
          );

          http
            .post(attachmentApiUrl, payload)
            .then(resp => {
              setPresentationAttachments(
                attachments ? [...attachments, resp.data.data] : [resp.data.data]
              );
            })
            .catch(console.log);
        }
      }
    };

    // Handle 3D
    if (runtime && scene && camera && viewer && lastSelectedScreenId) {
      const intersection = getCenterInteraction(camera, scene);
      if (intersection && cameraControls) {
        // const contentIds =
        //   meshId !== undefined ? [meshId, ...selectedContentIds] : selectedContentIds;

        const selectedEssContentIds = essContentIds.filter(
          essContentId =>
            essContents[essContentId].config?.selectedAt !== undefined &&
            essContents[essContentId].type !== T.ContentType.GROUP
        );

        const target = viewer.cameraControls.getTarget(new Vector3()).toArray();
        const threeCameraPosition = viewer.cameraControls.getPosition(new Vector3()).toArray();
        const focalOffset = viewer.cameraControls.getFocalOffset(new Vector3()).toArray();

        const slideInfo = createSlideInfo(
          threeCameraPosition,
          focalOffset,
          target,
          '3D',
          lastSelectedScreenId,
          selectedContentsByCategory[T.ContentCategory.MAP]!,
          selectedContentsByCategory[T.ContentCategory.MEASUREMENT],
          selectedContentsByCategory[T.ContentCategory.OVERLAY],
          selectedEssContentIds
        );

        const content = await postSlide(slideInfo);
        if (viewer.renderArea) {
          viewer?.renderer.domElement.getContext('webgl', { preserveDrawingBuffer: true });
          viewer?.render();
          await updateStateAndCapture(content, viewer.renderArea);
        }
      }
    } else if (lastSelectedScreenId) {
      // Handle 2D
      // const contentIds =
      //   orthoId !== undefined ? [orthoId, ...selectedContentIds] : selectedContentIds;
      const slideInfo = createSlideInfo(
        cameraPosition,
        cameraRotation,
        [],
        '2D',
        lastSelectedScreenId,
        selectedContentsByCategory[T.ContentCategory.MAP] ?? [],
        selectedContentsByCategory[T.ContentCategory.MEASUREMENT],
        selectedContentsByCategory[T.ContentCategory.OVERLAY],
        []
      );

      const content = await postSlide(slideInfo);
      const twoDView = document.getElementById('as-ol-view-wrapper');
      await updateStateAndCapture(content, twoDView);
    }
    setSlideContentApiStatus(T.APIStatus.SUCCESS);
  };

  const createPresentationFolder = async () => {
    if (!projectId) {
      return;
    }
    const URL: string = makeV2APIURL('projects', projectId, 'contents');
    const versionHeader: VersionHeader = makeVersionHeader();
    if (authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    const presentationCount = presentationGroupIds.length;
    const title = getPresentationTitle(presentationCount + 1, lang);
    const group = defaultGroup({ title });

    const body = {
      ...group,
      category: T.ContentCategory.PRESENTATION,
      color: group.color.toString(),
      groupId: undefined,
      isPersonal: false,
    };
    const response = await http.post(URL, body, {
      headers: {
        ...authHeader,
        ...versionHeader,
        ...jsonContentHeader,
      },
    });

    const content = APIToContent(response.data.data) as T.SlideContent;

    if (content) {
      const contentGroup: { [groupId: string]: T.SlideContent[] } = {};
      const individual: { [id: string]: T.SlideContent } = {};
      if (content.groupId) {
        contentGroup[content.groupId].push(content);
      } else {
        contentGroup[content.id] = [];
      }
      individual[content.id] = content;
      setPresentationContentById({ ...presentationContentsById, ...individual });
      setPresentationGroupIds([...presentationGroupIds, ...Object.keys(contentGroup)]);
      setSelectedGroupId(content.id);
    }
    return content;
  };

  const updatePresentationFolderName = async (groupId: T.Content['id'], title: string) => {
    const URL = makeV2APIURL('contents', groupId);
    const versionHeader: VersionHeader = makeVersionHeader();
    if (authHeader === undefined) {
      return dispatch(ChangeAuthedUser({}));
    }
    const body = {
      title,
    };
    const response = await http.patch(URL, body, {
      headers: {
        ...authHeader,
        ...versionHeader,
        ...jsonContentHeader,
      },
    });
    if (response.status === 200) {
      const content = APIToContent(response.data.data) as T.SlideContent;
      if (content) {
        const individual: { [id: string]: T.SlideContent } = {};
        individual[content.id] = content;
        setPresentationContentById({ ...presentationContentsById, ...individual });
      }
    }
    return;
  };

  const getPresentationList = () => {
    const URL = makeV2APIURL('projects', projectId!, `contents`, `?categories=["presentation"]`);
    http
      .get(URL)
      .then(resp => {
        const presentationAndSlideContentList = resp.data?.data?.map((apiContent: T.APIContent) =>
          APIToContent(apiContent)
        );

        const presentationContents: { [id: string]: T.SlideContent } = {};
        const groups: { [groupId: string]: T.SlideContent[] } = {};
        const groupIdsWithChildIds: { [groupId: string]: Array<T.Content['id']> } = {};
        const annotationIdsBySlideId: { [id: string]: Array<T.Content['id']> } = {};

        if (presentationAndSlideContentList) {
          for (const item of presentationAndSlideContentList) {
            if (item.groupId && item.type === T.ContentType.SLIDE) {
              if (!groups[item.groupId]) {
                groups[item.groupId] = [];
                groupIdsWithChildIds[item.groupId] = [];
              }
              groups[item.groupId].push(item);
              if (item.id) {
                groupIdsWithChildIds[item.groupId].unshift(item.id);
              }
            } else if (item.info.slideId && item.type === T.ContentType.ANNOTATION) {
              if (!annotationIdsBySlideId[item.info.slideId]) {
                annotationIdsBySlideId[item.info.slideId] = [];
              }
              annotationIdsBySlideId[item.info.slideId].push(item.id);
            } else {
              if (item.id && !groups[item.id]) {
                groups[item.id] = [];
                groupIdsWithChildIds[item.id] = [];
              }
            }
            presentationContents[item.id] = item;
          }

          setSlidesIdsByGroupId(groupIdsWithChildIds);
          setPresentationContentById(presentationContents);
          setPresentationGroupIds(Object.keys(groups));
          setAnnotationIdsBySlideId(annotationIdsBySlideId);
        }
      })
      .catch(error => console.log(error));
  };

  const getPresentationAttachments = (groupId: T.GroupContent['groupId']) => {
    if (groupId) {
      const URL = makeV2APIURL('contents', groupId, 'attachments');
      void http.get(URL).then(resp => {
        if (resp.data.data) {
          setPresentationAttachments(resp.data.data);
        }
      });
    }
  };

  const deletePresentationGroup = (groupId: T.GroupContent['groupId']) => {
    if (groupId) {
      const URL = makeV2APIURL('contents', groupId);
      http
        .delete(URL)
        .then(resp => {
          if (resp) {
            const updatedIds = presentationGroupIds.filter(id => String(groupId) !== String(id));

            setPresentationGroupIds(updatedIds);
            toast(displayToast(l10n(Text.successfullyDeletedPresentation)), toastOptions);
            setDeletingPresentationId(undefined);
          }
        })
        .catch(error => {
          if (T.HTTPError.CLIENT_UNAUTHORIZED_ERROR === getRequestErrorTypeAxios(error)) {
            dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
          }
        });
    }
  };

  const deletePresentationSlide = (slideId: T.SlideContent['groupId']) => {
    if (slideId && currentlySelectedGroupId) {
      const URL = makeV2APIURL('contents', slideId);
      http
        .delete(URL)
        .then(resp => {
          if (resp) {
            const { [slideId]: deletingContent, ...rest } = presentationContentsById;
            setPresentationContentById(rest);
            const selectedGroupSlideIds = slideIdsByGroupId[currentlySelectedGroupId];
            const updatedIds = selectedGroupSlideIds.filter(id => id !== slideId);
            const updatedSlideIdsByGroupId = {
              ...slideIdsByGroupId,
              [currentlySelectedGroupId]: updatedIds,
            };
            setSlidesIdsByGroupId(updatedSlideIdsByGroupId);
            setDeletingSlideid(undefined);
            toast(displayToast(l10n(Text.successfullyDeletedSlide)), toastOptions);
          }
        })
        .catch(error => {
          if (T.HTTPError.CLIENT_UNAUTHORIZED_ERROR === getRequestErrorTypeAxios(error)) {
            dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
          }
        });
    }
  };

  const deleteMultipleSlides = (slideIds: Array<T.SlideContent['id']>) => {
    if (slideIds.length > 0 && currentlySelectedGroupId) {
      // Create an array of promises to delete each slide
      const deletePromises = slideIds.map(async slideId => {
        const URL = makeV2APIURL('contents', slideId);
        return http.delete(URL);
      });

      Promise.all(deletePromises)
        .then(responses => {
          if (responses.every(resp => resp)) {
            const remainingSlidesById = { ...presentationContentsById };
            slideIds.forEach(slideId => {
              delete remainingSlidesById?.[slideId];
            });

            setPresentationContentById(remainingSlidesById);

            const selectedGroupSlideIds = slideIdsByGroupId[currentlySelectedGroupId];
            const updatedIds = selectedGroupSlideIds.filter(id => !slideIds.includes(id));

            const updatedSlideIdsByGroupId = {
              ...slideIdsByGroupId,
              [currentlySelectedGroupId]: updatedIds,
            };

            setSlidesIdsByGroupId(updatedSlideIdsByGroupId);
            setDeletingSlideid(undefined);

            toast(displayToast(l10n(Text.successfullyDeletedSlide)), toastOptions);
            setSelectedThumbnails(new Set());
          }
        })
        .catch(error => {
          if (T.HTTPError.CLIENT_UNAUTHORIZED_ERROR === getRequestErrorTypeAxios(error)) {
            dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
          }
        });
    }
  };

  const updateSlideInfo = async (slideId: T.SlideContent['id']) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const currentSlideContent = presentationContentsById[slideId] as T.SlideContent;
    const baseMapType = currentSlideContent.info.baseMapType;
    if (currentSlideContent) {
      if (!isInPresentationMode) {
        dispatch(
          ChangeContentsSidebarTab({
            sidebarTab: currentSlideContent.info.tab as T.ContentPageTabType,
            changeHorizontalTab: false,
            shouldChangeto3D: false,
          })
        );
      }
      dispatch(
        PatchProjectConfig({
          projectId: currentSlideContent.projectId,
          config: {
            lastSelectedScreenId: currentSlideContent?.info?.screenId,
          },
        })
      );
      // Selected contents
      const mapIds = currentSlideContent?.info?.mapIds || [];
      const measurementIds = currentSlideContent?.info?.measurementIds || [];
      const slideEssContentIds = currentSlideContent?.info?.essIds || [];
      const overlayIds = currentSlideContent?.info.overlayIds || [];
      // const needToSelectIds = slide?.info.contentIds;
      const needToSelectIds = [...measurementIds, ...overlayIds];
      const twoDMapId = mapIds?.find(id => byId[id].type === T.ContentType.MAP);
      const dsmId = mapIds?.find(id => byId[id].type === T.ContentType.DSM);
      const dtmId = mapIds?.find(id => byId[id].type === T.ContentType.DTM);
      const threeDMeshId = mapIds.find(id => byId[id].type === T.ContentType.THREE_D_MESH);

      const mapIdsToSelect: Array<string | number> = [];

      if (baseMapType === '3D' && threeDMeshId) {
        mapIdsToSelect.push(threeDMeshId);
      }
      if (twoDMapId && baseMapType === '2D') {
        mapIdsToSelect.push(twoDMapId);
      }
      if (dsmId && baseMapType === '2D') {
        mapIdsToSelect.push(dsmId);
      }
      if (dtmId && baseMapType === '2D') {
        mapIdsToSelect.push(dtmId);
      }

      selectedContentsByCategory?.map?.forEach(id => {
        if (mapIdsToSelect.includes(id)) {
          return;
        }
        dispatch(
          PatchContent({
            content: {
              id,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      });

      const screenId = currentSlideContent?.info?.screenId;
      // TODO: State_Management: if a function needs whole state, use it inside the function instead of passing like this.
      // Root state used like this won't have the latest state.
      const allScreenIds = getCurrentScreenContentIds({
        state,
        isESSDisabled: false,
        screenId,
        isMapDisabled: true,
      });

      const alreadySelectedIDs = allScreenIds.filter(id => byId[id]?.config?.selectedAt);
      const alreadyUnselectedIDs = allScreenIds.filter(id => byId[id]?.config?.selectedAt == null);
      const needUnselectedIDs = allScreenIds.filter(id => !needToSelectIds.includes(id));

      const selectContentIds =
        needToSelectIds.filter(id => !alreadySelectedIDs?.includes(id)) ?? [];

      const unSelectContentIds = needUnselectedIDs.filter(id => !alreadyUnselectedIDs.includes(id));

      // This will handle measurement contents for local store
      if (unSelectContentIds.length) {
        updateContentsSelectedAtInStore(unSelectContentIds, undefined);
      }
      if (selectContentIds.length) {
        updateContentsSelectedAtInStore([...selectContentIds, ...slideEssContentIds], new Date());
      }
      const selectedContents = selectContentIds.map(id => byId[id]);
      const unselectedContents = unSelectContentIds.map(id => byId[id]);

      // update contents at server
      const requestsForSelected = updateContentSelectedAtInServer(
        selectedContents,
        authHeader,
        projectId,
        new Date()
      );
      const requestsForUnselected = updateContentSelectedAtInServer(
        unselectedContents,
        authHeader,
        projectId,
        undefined
      );
      void Promise.all([requestsForSelected, requestsForUnselected]);

      const shouldToggleTo3D = !isIn3D && baseMapType === '3D';
      const shouldToggleTo2D = isIn3D && baseMapType === '2D';

      mapIdsToSelect.forEach(id =>
        dispatch(
          PatchContent({
            content: {
              id: id,
              config: {
                selectedAt: new Date(),
              },
            },
          })
        )
      );

      // This will handle map contents
      if (shouldToggleTo3D) {
        changeThreeEngine();
        dispatch(ChangeIn3D({ in3D: true }));
        dispatch(
          PatchContent({
            content: {
              id: orthoId!,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      } else if (shouldToggleTo2D) {
        dispatch(ChangeIn3D({ in3D: false, transition: false }));
        dispatch(
          PatchContent({
            content: {
              id: meshId!,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      }
      if (baseMapType === '2D') {
        const annotationIds = annotationIdsForSlide?.[slideId] || [];
        dispatch(
          ChangeTwoDDisplayCenter({ twoDDisplayCenter: currentSlideContent.info.cameraPosition })
        );
        dispatch(ChangeTwoDDisplayZoom({ twoDDisplayZoom: currentSlideContent.info.zoomLevel }));
        dispatch(ChangeRotation({ rotation: currentSlideContent.info.cameraRotation[0] || 0 }));

        const areMarkersAvailable = Boolean(
          (presentationContentsById[annotationIds[0]] as T.AnnotationContent)?.info?.element
            ?.markers?.length
        );

        if (areMarkersAvailable) {
          setTimeout(() => {
            setIsActiveSlide(false);
            setIsAnnotationModeOpen(true);
          }, 1000);
        } else {
          setIsActiveSlide(false);
        }
      }

      if (isIn3D) {
        void viewer?.moveCameraToPresentation();
      }

      const tab = currentSlideContent.info.tab;

      if (projectId && tab && lastSelectedScreenId) {
        navigate(
          route.content.createMain(String(projectId), tab, String(lastSelectedScreenId), null, null)
        );
      }
    }
  };

  const moveSlide = async (e: CtxSortEvent) => {
    const contentId = Number(e.key);
    const nearestContentId = Number(e.nearestKey);
    const moveSlideApiUrl = makeV2APIURL('contents', contentId, 'move');

    const slideContent = presentationContentsById[contentId];

    const presentationGroupId = slideContent?.groupId;

    if (!nearestContentId && !presentationGroupId) {
      return;
    }

    let newSlideArray = [...slideIdsByGroupId[presentationGroupId ?? NaN]];

    const contentIndex = newSlideArray.indexOf(contentId);
    const nearestContentIndex = newSlideArray.indexOf(nearestContentId);

    if (contentIndex === -1 || nearestContentIndex === -1) {
      return;
    }
    newSlideArray = newSlideArray.filter(id => id !== contentId);
    const insertIndex =
      contentIndex < nearestContentIndex ? nearestContentIndex : nearestContentIndex + 1;
    newSlideArray.splice(insertIndex, 0, contentId);

    setSlidesIdsByGroupId({
      ...slideIdsByGroupId,
      [presentationGroupId ?? NaN]: newSlideArray,
    });

    if (nearestContentId) {
      const body = {
        appendMode: false,
        posContentId: Number(nearestContentId),
      };
      const versionHeader: VersionHeader = makeVersionHeader();
      await http.put(moveSlideApiUrl, body, {
        headers: {
          ...authHeader,
          ...versionHeader,
          ...jsonContentHeader,
        },
      });
    } else {
      console.log(`The slide ${contentId} can not be moved`);
    }
  };

  return {
    selectedContentIds,
    getPresentationList,
    deletePresentationGroup,
    createPresentationSlide,
    createPresentationFolder,
    getPresentationAttachments,
    updatePresentationFolderName,
    deletePresentationSlide,
    updateSlideInfo,
    deleteMultipleSlides,
    moveSlide,
  };
}

const getDataUrlAttachment = (contentId: T.SlideContent['id'], blob: Blob) => ({
  id: contentId.toString(),
  type: 'v2Attachments',
  attributes: {
    contentId: contentId as number,
    file: {
      url: URL.createObjectURL(blob),
      blueprintThumb: {
        url: null,
      },
      markerThumb: {
        url: null,
      },
    },
    type: T.ContentType.SLIDE as T.ContentType.SLIDE,
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
  },
});
