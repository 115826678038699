import React from 'react';
import { isMobile } from '^/utilities/device';
interface Props {
  url: string;
}
export function ESSIcon({ url }: Props) {
  return (
    <div>
      <div
        style={{
          transform: isMobile() ? 'scale(0.7)' : 'scale(0.8)',
          background: 'white',
          borderRadius: '50%',
        }}
      >
        <img width={32} src={url} className="square-img" alt="Thumbnail" />
      </div>
    </div>
  );
}
