import { createStore, useStore } from 'zustand';
import { EarthWorkStore, SyncStatus } from './types';

import * as T from '^/types';

const initialState = {
  rockLayers: [],
  earthworkLists: [],
  showBurnDown: false,
  selectedEarthworkId: undefined,
  createNewEarthwork: false,
  earthworkAnalysisById: {},
  isAddNowIsActive: false,
  deletingEarthworkId: undefined,
  syncStatus: SyncStatus.SUCCESS,
  earthworkFetchStatus: T.APIStatus.IDLE,
  createEarthworkStatus: T.APIStatus.IDLE,
  earthworkAnalysisFetchStatus: T.APIStatus.IDLE,
};

export const earthworkStore = createStore<EarthWorkStore>()(set => ({
  ...initialState,
  setSelectedVolumeId: value => set(() => ({ selectedVolumeId: value })),
  setIsAddNowIsActive: value => set(() => ({ isAddNowIsActive: value })),
  setSelectedEarthworkId: value => set(() => ({ selectedEarthworkId: value })),

  setCreateNewEarthwork: value => set(() => ({ createNewEarthwork: value })),
  setEarthworkLists: value => set(() => ({ earthworkLists: value })),
  setEarthworkFetchStatus: value => set(() => ({ earthworkFetchStatus: value })),

  setEarthworkAnalysis: value => set(() => ({ earthworkAnalysis: value })),
  setEarthworkAnalysisFetchStatus: value => set(() => ({ earthworkAnalysisFetchStatus: value })),

  setEarthworkBasics: value => set(() => ({ earthworkBasics: value })),
  setFinalEarthWork: value => set(() => ({ finalEarthWork: value })),
  setSelectedZones: value => set(() => ({ selectedZones: value })),
  setInitialSurvey: value => set(() => ({ initialSurvey: value })),
  setRockLayers: value => set(() => ({ rockLayers: value })),
  setSyncStatus: value => set(() => ({ syncStatus: value })),

  setShowBurnDown: value => set(() => ({ showBurnDown: value })),
  setCreateEarthworkStatus: value => set(() => ({ createEarthworkStatus: value })),
  setDeletingEarthworkId: value => set(() => ({ deletingEarthworkId: value })),
  setEarthworkAnalysisById: value =>
    set(state => {
      const existingData = state.earthworkAnalysisById;
      return { earthworkAnalysisById: { ...value, ...existingData } };
    }),
  reset: () => set(initialState),
}));

export function useEarthWorkStore(): EarthWorkStore;
export function useEarthWorkStore<T>(selector: (state: EarthWorkStore) => T): T;
export function useEarthWorkStore<T>(selector?: (state: EarthWorkStore) => T) {
  return useStore(earthworkStore, selector!);
}
