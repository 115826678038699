import { create } from 'zustand';
import { UndoItem } from '^/hooks/useUndoAction';
import * as T from '^/types';

const MAX_STACK_SIZE = 30;

interface UndoStore {
  undoStack: UndoItem[];
  undoUpdatesById: Record<T.Content['id'], Date>;
  pop(): UndoItem | undefined;
  pushUndoItem(item: UndoItem): void;
  lastItem: any | null;
}

// global store to keep history
export const useUndoStore = create<UndoStore>()((set, get) => ({
  undoStack: [] as UndoItem[],
  undoUpdatesById: {},
  lastItem: null,

  pop() {
    const { undoStack, undoUpdatesById } = get();
    const lastItem: UndoItem | undefined = undoStack.pop();
    if (lastItem) {
      undoUpdatesById[lastItem.id] = lastItem.pushedAt;
      set({ undoStack, undoUpdatesById });
    }
    set({ lastItem });
    return lastItem;
  },

  pushUndoItem(undoItem: UndoItem) {
    set(({ undoStack }) => {
      const newStack = undoStack.concat(undoItem);
      if (newStack.length > MAX_STACK_SIZE) {
        newStack.shift();
      }
      return {
        undoStack: newStack,
      };
    });
  },
}));

// for debugging
// useUndoStore.subscribe(state => {
//   console.log('State Changed ---- ', {
//     undoStack: state.undoStack,
//     undoUpdatesById: state.undoUpdatesById,
//   });
// });
