import { Color, Object3D } from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { disposeHierarchy } from '../../Lib/Helper/DisposeHelper';
import { autobind } from 'core-decorators';
import { IFCLoader } from '../../Lib/Loaders';
import { FragmentsGroup } from 'bim-fragment';
import { isArray } from 'lodash-es';
import { EditorIFCController } from '../../Lib/Controllers/Model';
import { extractIFCProperties } from '^/utilities/extractIFCProperties';
import { GeometryCullerRenderer } from '../../Lib/Loaders/IFCStreamer/GeometryCullerRenderer';
// import * as OBC from '@thatopen/components';
// import * as OBCF from '@thatopen/components-front';
// import { GeometryCullerRenderer } from './GeometryCullerRenderer';
// import { IfcStreamer } from './IfcStreamer';
const HIGHLIGHT_COLOR = new Color(0xffffff);
const SELECTED_COLOR = new Color(0x359caf);

export default class IFCObject extends Object3D {
  public readonly viewer: Viewer;
  public readonly contentId;
  public isLoaded: boolean = false;
  public isLoading: boolean = false;
  public ifcModel: FragmentsGroup | undefined;
  public editor: EditorIFCController | undefined;
  public bimMeta: any;
  private readonly url: string;
  public projectionSystemLabel: string;
  private readonly ifcLoader: IFCLoader;
  public culler: GeometryCullerRenderer | undefined;
  public constructor(
    contentId: string | number,
    url: string,
    viewer: Viewer,
    projectionSystemLabel: string
  ) {
    super();
    this.contentId = contentId;
    this.viewer = viewer;
    this.url = url;
    this.projectionSystemLabel = projectionSystemLabel;
    this.ifcLoader = new IFCLoader(undefined, this.viewer);
  }
  /**
   * @public
   * @description Dispose all
   * @returns { void}
   */
  @autobind
  public async loadModel(): Promise<void> {
    // this.viewModel();
    // return;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const model = await this.ifcLoader.loadAsync(this.viewer, this.url, () => {});
        // here to get the coordinates from the model and when press the button update the model location with it
        this.ifcModel = model as FragmentsGroup;
        this.ifcModel.name = `IFC_${this.contentId}`;
        extractIFCProperties(this.ifcModel, this.projectionSystemLabel, undefined);
        this.ifcModel.traverse((node: any) => {
          if (node.material) {
            if (isArray(node.material)) {
              node.material.forEach((m: any) => {
                m.userData = {
                  color: m.color.getHex(),
                  highlightColor: new Color().lerpColors(m.color, HIGHLIGHT_COLOR, 0.1).getHex(),
                  selectedColor: new Color().lerpColors(m.color, SELECTED_COLOR, 0.7).getHex(),
                };
              });
            } else {
              node.material.userData = {
                color: node.material.color.getHex(),
                highlightColor: new Color()
                  .lerpColors(node.material.color, HIGHLIGHT_COLOR, 0.1)
                  .getHex(),
                selectedColor: new Color()
                  .lerpColors(node.material.color, SELECTED_COLOR, 0.7)
                  .getHex(),
              };
            }
          }
        });
        this.ifcModel.userData.isIfc = true;
        this.add(this.ifcModel);
        this.isLoaded = true;
        if (this.ifcLoader.culler) {
          this.ifcLoader.culler.setModelTransformation(this.ifcModel.uuid, this.matrixWorld);
        }
        this.culler = this.ifcLoader.culler;
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  }

  /**
   * @public
   * @description set highlight model
   * @returns {void}
   */
  @autobind
  public setHighlight(): void {
    this.ifcModel!.traverse((node: any) => {
      if (node.material) {
        if (isArray(node.material)) {
          node.material.forEach((m: any) => {
            m.color.set(m.userData.highlightColor);
          });
        } else {
          node.material.color.set(node.material.userData.highlightColor);
        }
      }
    });
  }
  /**
   * @public
   * @description set un highlight model
   * @returns {void}
   */
  @autobind
  public setUnHighlight(): void {
    this.ifcModel!.traverse((node: any) => {
      if (node.material) {
        if (isArray(node.material)) {
          node.material.forEach((m: any) => {
            m.color.set(m.userData.color);
          });
        } else {
          node.material.color.set(node.material.userData.color);
        }
      }
    });
  }
  /**
   * @public
   * @description set selected model
   * @returns {void}
   */
  @autobind
  public setSelected(): void {
    this.ifcModel!.traverse((node: any) => {
      if (node.material) {
        if (isArray(node.material)) {
          node.material.forEach((m: any) => {
            m.color.set(m.userData.selectedColor);
          });
        } else {
          node.material.color.set(node.material.userData.selectedColor);
        }
      }
    });
  }
  /**
   * @public
   * @description set visivibe
   */
  @autobind
  public setVisible(visible: boolean): void {
    this.visible = visible;
  }
  /**
   * @public
   * @description Dispose all
   * @returns { void}
   */
  @autobind
  public dispose(): void {
    disposeHierarchy(this);
    if (this.ifcLoader.ifcStreamer) {
      void this.ifcLoader.ifcStreamer.clearCache();
    }
    this.culler?.dispose();
  }
}

export { IFCObject };
