/* eslint-disable max-lines */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import * as THREE from 'three';
import { Loader3DTiles, Runtime } from 'three-loader-3dtiles';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { CustomEffectsPass } from '^/components/organisms/ThreeESSDisplay/PostRenderer/custom-effects-pass';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

import Popup from '../Popup';
import { UseL10n, useL10n } from '^/hooks';
import Text from './text';

import { RawMapControls, RawTransformControls } from '../MapGizmoControls';

import { ChangeInUploadIFC } from '^/store/duck/Pages';
import ifcRespositionPNG from '^/assets/icons/ifc-icon/resposition_model.png';
import {
  TooltipContentStyle,
  MapCenterSvgWrapper,
  TooltipCustomStyle,
} from '^/components/molecules/MapController';
import MapCenterSvg from '^/assets/icons/map-controller/map-center.svg';
import Stats from 'three/examples/jsm/libs/stats.module';

import WrapperHoverable from '^/components/atoms/WrapperHoverable';
import { ZoomWrapper } from '^/components/molecules/MapZoom';
import { RepositionIFCIcon } from '^/assets/icons/ifc-icon';
import * as T from '^/types';
import { getSingleContentId } from '^/utilities/state-util';
import { makeBucketURL } from '^/store/duck/API';
import CameraControls from 'camera-controls';
import { useIFCFileStore } from '../IFCFileStore';
import { BrowseButton } from '../BIMFileInput';
import { centerObjectForView } from '^/components/three/utils';
import { ProgressBar } from '^/components/atoms/ProgressBar';
import {
  IFCTooltipContainer,
  IfcTopHeader,
  IfcTooltipTextWrapper,
  IfcTooltipHeaderText,
  IfcTooltipDescriptionText,
  ViewerWrapper,
  ControllerRoot,
  Root,
  LoadingWrapper,
  loadingDivCustomStyle,
  LoadingText,
  TooltipContainer,
  TooltipWrapper,
  TooltipButton,
  LoadingBackground,
} from './style';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { isStatsEnabled } from '^/utilities/query-param';
import { far, fov, near } from '^/components/three/ThreeStore';
import { UseUploadContent, useUploadContent } from '^/hooks/useUploadContent';

interface Props {
  zIndex: number;
  onPreviousClick(): void;
  onCloseClick(): void;
  files: File;
  title: string;
}

const clock = new THREE.Clock();
const POPUP_ALPHA: number = 0.39;

export const IFCLocationPreview: FC<Props> = ({
  zIndex,
  onPreviousClick,
  onCloseClick,
  files,
  title,
}) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch: Dispatch = useDispatch();

  const [tileset, setTileset] = useState<THREE.Object3D<THREE.Event> | null>(null);
  const [isActiveTooltip, setIsActiveTooltip] = useState<boolean>(true);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [respositionIFC] = useState<boolean>(false);

  const cameraControls = useRef<CameraControls | null>(null);

  const tilesRuntime = useRef<Runtime | null>(null);
  const [modelIfc, setModelIfc] = useState<THREE.Object3D | null>(null);

  const renderer = useRef<THREE.WebGLRenderer | null>(null);
  const scene = useRef(new THREE.Scene());
  const camera = useRef<THREE.PerspectiveCamera | null>(null);
  const viewerRef = useRef<HTMLDivElement>(null);

  const basePass = useRef<RenderPass | null>(null);
  const gammaPass = useRef<ShaderPass | null>(null);
  const customEffects = useRef<CustomEffectsPass | null>(null);
  const composer = useRef<EffectComposer | null>(null);

  const transformControls = useRef<TransformControls | null>(null);
  const [transformControlState, setTransformControls] = useState<TransformControls | null>(null);
  const stats: any = new Stats();
  const ifcModel = useIFCFileStore(s => s.ifcModel);

  const uploadContent: UseUploadContent = useUploadContent();

  // const boxHelper = useRef<THREE.BoxHelper>();

  const { Pages, ProjectConfigPerUser }: T.State = useSelector((state: T.State) => state);
  const meshId = getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.THREE_D_MESH);

  const renders = () => {
    requestAnimationFrame(renders);

    if (renderer.current && camera.current) {
      const dt = clock.getDelta();
      if (tilesRuntime.current) {
        tilesRuntime.current.update(dt, renderer.current, camera.current);
      }
      if (cameraControls.current) {
        cameraControls.current.update(dt);
      }

      stats.update();
      // boxHelper.current?.update();

      if (composer.current) {
        composer.current.render();
      } else {
        renderer.current.render(scene.current, camera.current);
      }
    }
  };

  const initiliazeRenderer = (viewerDiv: HTMLElement) => {
    if (!renderer.current) {
      return;
    }

    const { width, height } = {
      width: viewerDiv.clientWidth,
      height: viewerDiv.clientHeight,
    };

    // const pixelRatio = Math.min(window.devicePixelRatio, 2);

    camera.current = new THREE.PerspectiveCamera(fov, width / height, near, far);

    const depthTexture = new THREE.DepthTexture(width, height);
    const renderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, {
      depthTexture: depthTexture,
      depthBuffer: true,
    });
    renderTarget.texture.colorSpace = 'srgb-linear';

    composer.current = new EffectComposer(renderer.current, renderTarget);
    composer.current.setSize(width, height);
    // composer.current.setPixelRatio(pixelRatio);

    // base pass
    basePass.current = new RenderPass(scene.current, camera.current);
    basePass.current.camera = camera.current;

    gammaPass.current = new ShaderPass(GammaCorrectionShader);
    gammaPass.current.renderToScreen = true;

    // Antialias pass.
    const effectFXAA = new ShaderPass(FXAAShader);
    effectFXAA.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight);

    customEffects.current = new CustomEffectsPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      scene.current,
      camera.current
    );
    customEffects.current.renderCamera = camera.current;
    customEffects.current.outlineEnabled = true;
    customEffects.current.renderToScreen = true;
    customEffects.current.glossEnabled = false;

    composer.current.addPass(basePass.current);
    composer.current.addPass(gammaPass.current);
    composer.current.addPass(customEffects.current);
    composer.current.addPass(effectFXAA);

    camera.current.position.z = 15;
    camera.current.position.y = 13;
    camera.current.position.x = 8;

    cameraControls.current = new CameraControls(camera.current, renderer.current.domElement);
    cameraControls.current.dollyToCursor = true;
    cameraControls.current.mouseButtons.left = CameraControls.ACTION.TRUCK;
    cameraControls.current.mouseButtons.right = CameraControls.ACTION.ROTATE;
    camera.current.up.copy(new THREE.Vector3(0, 0, 1));
    cameraControls.current.updateCameraUp();

    const ambientLight = new THREE.AmbientLight(0xe6e7e4, 1);
    const directionalLight = new THREE.DirectionalLight(0xf9f9f9, 0.75);
    directionalLight.position.set(10, 50, 10);
    scene.current.add(ambientLight, directionalLight);
  };

  useEffect(() => {
    dispatch(ChangeInUploadIFC({ isInUploadIFC: true }));
    renderer.current = new THREE.WebGLRenderer({
      alpha: true,
      logarithmicDepthBuffer: false,
      preserveDrawingBuffer: true,
      antialias: true,
    });

    const { current: container } = viewerRef;
    if (container && renderer.current) {
      initiliazeRenderer(container);

      resize();
      window.addEventListener('resize', resize);

      void loadTileset();
      renderer.current.pixelRatio = Math.min(window.devicePixelRatio, 2);
      renderer.current.outputColorSpace = 'srgb';
      renderer.current.toneMapping = THREE.NoToneMapping;
      renderer.current.setClearColor(0x000000);

      renders();
      if (isStatsEnabled()) {
        container.appendChild(stats.dom);
        stats.dom.className = 'threejs_stats';
      }

      container.appendChild(renderer.current.domElement);
    }

    return () => {
      if (container && renderer.current) {
        if (tilesRuntime.current) {
          tilesRuntime.current.dispose();
        }

        if (composer.current) {
          composer.current.dispose();
        }
        if (cameraControls.current) {
          cameraControls.current.dispose();
        }
        container.removeChild(renderer.current.domElement);
      }
    };
  }, []);

  const resize = () => {
    const viewerDiv: HTMLElement | null = viewerRef.current;

    if (viewerDiv !== null && camera.current && renderer.current) {
      const { width, height }: DOMRect = viewerDiv.getBoundingClientRect();
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();

      if (composer.current) {
        composer.current.setSize(width, height);
        basePass.current?.setSize(width, height);
        gammaPass.current?.setSize(width, height);
        customEffects.current?.setSize(width, height);
      }

      renderer.current.setSize(width, height);
    }
  };

  const handleMapCenterClick: () => Promise<void> = async () => {
    if (tileset && camera.current) {
      await cameraControls.current?.rotateTo(0, 0, false);
      await cameraControls.current?.fitToBox(tileset, true, {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
      });
    }
  };

  const addTransformControlListener = () => {
    if (!transformControls.current) {
      return;
    }

    transformControls.current.addEventListener('mouseDown', () => {
      if (cameraControls.current) {
        cameraControls.current.enabled = false;
      }
    });
    transformControls.current.addEventListener('mouseUp', () => {
      if (cameraControls.current) {
        cameraControls.current.enabled = true;
      }
    });
    // transformControls.current.addEventListener('change', () => {
    //   if (!model || !tiles) {
    //     return;
    //   }
    //   const groupdPos = getGroundPosition(model.position, tiles);
    //   // const height = Number(ifcModel.userData.height);
    //   if (groupdPos && groupdPos.z /** + height**/ > model.position.z) {
    //     model.position.copy(groupdPos);
    //   }
    // });
  };

  const collisionDetection = (runtime: Runtime, modelMesh: THREE.Object3D) => {
    if (!tileset) {
      return;
    }
    const boundingBox = new THREE.Box3().setFromObject(tileset);
    // tileset.geometry.computeBoundingBox();
    const center = boundingBox.getCenter(new THREE.Vector3());
    modelMesh.position.set(center.x, center.y, center.z);
    centerObjectForView(modelMesh, cameraControls.current);
  };

  const handleRepositionModel: (
    object: THREE.Object3D | undefined
  ) => Promise<void> = async object => {
    const meshObject = modelIfc || object;
    if (!meshObject || !tilesRuntime.current) {
      return;
    }

    meshObject.position.set(0, 0, 0);
    collisionDetection(tilesRuntime.current, meshObject);
  };

  const loadIFCModel = () => {
    if (ifcModel && camera.current && renderer.current && tilesRuntime.current) {
      const { latitude, longitude, elevation } = ifcModel.userData;
      //Creates the lights of the scene
      const lightColor = 0xffffff;
      const point = tilesRuntime.current?.getPositionFromLatLongHeight({
        lat: latitude,
        long: longitude,
        height: elevation,
      });

      ifcModel.position.set(point.x, point.y, point.z);

      const ambientLight = new THREE.AmbientLight(lightColor, 0.5);
      scene.current.add(ambientLight);

      ifcModel.rotation.x = Math.PI / 2;
      scene.current.add(ifcModel);

      // You can change the color (0xffff00) to your desired outline color.
      // boxHelper.current = new THREE.BoxHelper(ifcModel, 0xffff00);
      // scene.current.add(boxHelper.current);

      transformControls.current = new TransformControls(
        camera.current,
        renderer.current.domElement
      );
      setTransformControls(transformControls.current);
      // transformControls.current.position.copy(location);
      // ifcModel.position.copy(location);

      scene.current.add(transformControls.current);
      transformControls.current.attach(ifcModel);

      addTransformControlListener();

      setModelIfc(ifcModel);
      // collisionDetection(tilesRuntime.current, ifcModel, latitude, longitude);
      centerObjectForView(ifcModel, cameraControls.current);
    }
  };

  async function loadTileset() {
    if (!renderer.current || !camera.current) {
      return;
    }
    const result = await Loader3DTiles.load({
      url: makeBucketURL(meshId!, 'tiled_model', 'tileset.json'),
      renderer: renderer.current,
      onProgress(progress: number, total: number) {
        const progressP = Math.trunc((progress / total) * 100);
        setProgressPercent(progressP);
      },
      options: {
        maximumMemoryUsage: 1024,
        maxConcurrency: 4,
        maximumScreenSpaceError: 32,
        viewDistanceScale: 0.5,
        dracoDecoderPath: 'https://cdn.jsdelivr.net/npm/three/examples/jsm/libs/draco',
        basisTranscoderPath: 'https://cdn.jsdelivr.net/npm/three/examples/jsm/libs/basis',
      },
    });
    const { model, runtime } = result;
    setTileset(model);
    tilesRuntime.current = runtime;
    model.name = 'ground';

    scene.current.add(model);

    if (customEffects.current) {
      customEffects.current.excludedMeshes.push(model as THREE.Mesh);
    }

    loadIFCModel();

    return model;
  }

  const zoomIn = () => {
    if (cameraControls.current) {
      void cameraControls.current.dolly(30);
    }
  };
  const zoomOut = () => {
    if (cameraControls.current) {
      void cameraControls.current.dolly(-30);
    }
  };

  const handleIfcUpload: () => void = () => {
    if (tilesRuntime.current && modelIfc) {
      const pos = tilesRuntime.current?.getLatLongHeightFromPosition(modelIfc.position);
      const bimMeta: T.BimContent['info']['bimMeta'] = {
        dimensions: [0, 0, 0],
        heading: THREE.MathUtils.radToDeg(modelIfc.rotation.x),
        pitch: THREE.MathUtils.radToDeg(modelIfc.rotation.y),
        roll: THREE.MathUtils.radToDeg(modelIfc.rotation.z),
        scale: new THREE.Vector3(modelIfc.scale.x, modelIfc.scale.y, modelIfc.scale.z),
        position: new THREE.Vector3(pos.long, pos.lat, pos.height),
      };

      uploadContent({
        attachmentType: T.AttachmentType.BIM,
        title,
        files: [files],
        bimMeta,
      });
    }
  };

  const IFCToolTip: FC<{ showButton: boolean }> = ({ showButton }) => (
    <IFCTooltipContainer>
      <IfcTopHeader>
        <img src={ifcRespositionPNG} alt="" />
      </IfcTopHeader>
      <IfcTooltipTextWrapper>
        <IfcTooltipHeaderText>{l10n(Text.repositionHeaderText)} </IfcTooltipHeaderText>
        <IfcTooltipDescriptionText>
          {l10n(Text.respositionDescriptionText)}
        </IfcTooltipDescriptionText>
        {showButton && (
          <TooltipButton onClick={() => setIsActiveTooltip(false)}>
            {l10n(Text.tooltipButton)}
          </TooltipButton>
        )}
      </IfcTooltipTextWrapper>
    </IFCTooltipContainer>
  );

  return (
    <Popup
      zIndex={zIndex}
      alpha={POPUP_ALPHA}
      hasBlur={true}
      title={l10n(Text.title)}
      onPreviousClick={onPreviousClick}
      onCloseClick={onCloseClick}
    >
      <Root>
        <ProgressBar position="relative" percent={progressPercent} />
        <ViewerWrapper id="as-3d-view-wrapper" ref={viewerRef} />
        {/* TODO: Refactor later */}
        {isActiveTooltip ? (
          <>
            <LoadingBackground />
            <ControllerRoot style={{ zIndex: 301, top: '165px' }}>
              <ZoomWrapper onClick={async () => handleRepositionModel(undefined)}>
                <WrapperHoverable
                  title=""
                  content={<IFCToolTip showButton={false} />}
                  customStyle={TooltipContentStyle}
                >
                  <MapCenterSvgWrapper>
                    <RepositionIFCIcon />
                  </MapCenterSvgWrapper>
                </WrapperHoverable>
              </ZoomWrapper>
            </ControllerRoot>
            <TooltipWrapper>
              <TooltipContainer>
                <IFCToolTip showButton={true} />
              </TooltipContainer>
            </TooltipWrapper>
          </>
        ) : null}

        {respositionIFC && (
          <LoadingWrapper>
            <LoadingIcon loadingDivCustomStyle={loadingDivCustomStyle} />
            <LoadingText>{l10n(Text.respositioningText)}</LoadingText>
          </LoadingWrapper>
        )}
        <ControllerRoot>
          {transformControlState && (
            <RawTransformControls
              selectedModelType={true}
              isPreviewScreen={true}
              transformControls={transformControlState}
            />
          )}
          <RawMapControls zoomIn={zoomIn} zoomOut={zoomOut} />
          <ZoomWrapper onClick={handleMapCenterClick}>
            <WrapperHoverable title={l10n(Text.mapCenter)} customStyle={TooltipCustomStyle}>
              <MapCenterSvgWrapper>
                <MapCenterSvg />
              </MapCenterSvgWrapper>
            </WrapperHoverable>
          </ZoomWrapper>
          <ZoomWrapper onClick={async () => handleRepositionModel(undefined)}>
            <WrapperHoverable
              title=""
              content={<IFCToolTip showButton={false} />}
              customStyle={TooltipContentStyle}
            >
              <MapCenterSvgWrapper>
                <RepositionIFCIcon />
              </MapCenterSvgWrapper>
            </WrapperHoverable>
          </ZoomWrapper>
        </ControllerRoot>
        <BrowseButton onClick={handleIfcUpload} style={{ width: '196px' }}>
          {l10n(Text.confirmButton)}
        </BrowseButton>
      </Root>
    </Popup>
  );
};

export default IFCLocationPreview;
