import { Language } from '^/types';

export default {
  account: {
    title: {
      [Language.EN_US]: 'Account',
      [Language.KO_KR]: '계정',
    },
    profile: {
      title: {
        [Language.EN_US]: 'Profile',
        [Language.KO_KR]: '프로필',
      },
      email: {
        [Language.EN_US]: 'Email',
        [Language.KO_KR]: '이메일',
      },
      name: {
        [Language.EN_US]: 'Name',
        [Language.KO_KR]: '이름',
      },
      phoneNumber: {
        [Language.EN_US]: 'Phone Number',
        [Language.KO_KR]: '전화번호',
      },
    },
    affiliation: {
      title: {
        [Language.EN_US]: 'Affiliation',
        [Language.KO_KR]: '소속',
      },
      organization: {
        [Language.EN_US]: 'Organization',
        [Language.KO_KR]: '조직',
      },
      purpose: {
        [Language.EN_US]: 'Purpose',
        [Language.KO_KR]: '담당목적',
      },
    },
    session: {
      title: {
        [Language.EN_US]: 'Session',
        [Language.KO_KR]: '세션',
      },
      signOut: {
        [Language.EN_US]: 'Sign out',
        [Language.KO_KR]: '로그아웃',
      },
    },
  },
  security: {
    title: {
      [Language.EN_US]: 'Security',
      [Language.KO_KR]: '보안',
    },
    password: {
      [Language.EN_US]: 'Password',
      [Language.KO_KR]: '비밀번호',
    },
    deleteAccount: {
      [Language.EN_US]: 'Delete Account',
      [Language.KO_KR]: '계정 삭제',
    },
  },
  plan: {
    subscription: {
      title: {
        [Language.EN_US]: 'Subscription',
        [Language.KO_KR]: '구독',
      },
      currentPlan: {
        [Language.EN_US]: 'Current Plan',
        [Language.KO_KR]: '현재 구독',
      },
      free: {
        [Language.EN_US]: 'Free',
        [Language.KO_KR]: '무료',
      },
    },
  },
  settings: {
    locale: {
      title: {
        [Language.EN_US]: 'Locale',
        [Language.KO_KR]: '언어',
      },
      country: {
        [Language.EN_US]: 'Country',
        [Language.KO_KR]: '국가',
      },
      language: {
        [Language.EN_US]: 'Language',
        [Language.KO_KR]: '언어',
      },
    },
  },
};
