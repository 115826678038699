import React, { ChangeEvent, useState, useRef, useCallback } from 'react';
import { formatMsTime } from '../PhotoList/util';
import {
  ControlButtonIcon,
  ControlButtonIconWrapper,
  SeekTimerText,
  SliderContainer,
  SliderInput,
} from './styles';
import { PauseIcon, PlayIcon } from '^/assets/icons/photo/video-icons';

interface TimelapseSeekSliderProps {
  duration: number;
  onSeek: (event: ChangeEvent<HTMLInputElement>) => void;
  photos: string[];
  timer: number;
}

export const TimelapseSeekSlider = ({
  duration,
  onSeek,
  photos,
  timer,
}: TimelapseSeekSliderProps) => {
  const [seekMouse, setSeekMouse] = useState(0);
  const [seekTimer, setSeekTimer] = useState('');
  const [seekPhoto, setSeekPhoto] = useState<string | null>(null);

  const sliderRef = useRef<HTMLInputElement>(null);

  const handleMouseMoveSeek = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      if (sliderRef.current) {
        const rect = sliderRef.current.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const width = rect.width;
        const value = Math.min(Math.max((offsetX / width) * duration, 0), duration);
        const seekIndex = Math.floor((value / duration) * photos.length);
        const seekSeconds = value;
        const seekTime = formatMsTime(seekSeconds);
        if (seekTime === formatMsTime(duration)) {
          return;
        }
        // Update state
        setSeekTimer(seekTime);
        setSeekMouse((value / duration) * 100);
        setSeekPhoto(photos[seekIndex]);
      }
    },
    [sliderRef, duration, photos]
  );

  return (
    <SliderContainer>
      <SeekTimerText seekMouse={seekMouse}>
        <img src={seekPhoto ?? ''} alt="seekphoto" />
        {seekTimer}
      </SeekTimerText>
      <SliderInput
        ref={sliderRef}
        type="range"
        min={0}
        max={duration}
        step="any"
        value={timer}
        onChange={onSeek}
        onMouseMove={handleMouseMoveSeek}
        onMouseLeave={() => {
          setSeekTimer('');
          setSeekMouse(0);
        }}
      />
    </SliderContainer>
  );
};

interface PlayPauseButtonProps {
  isMain: boolean;
  pause: boolean;
  onClick: () => void;
  isHidden?: boolean;
}

export const PlayPauseButton = ({ isMain, pause, onClick, isHidden }: PlayPauseButtonProps) => (
  <ControlButtonIconWrapper onClick={onClick} isMain={isMain} isHidden={isHidden}>
    {pause ? (
      <ControlButtonIcon>
        <PlayIcon height={22} width={22} />
      </ControlButtonIcon>
    ) : (
      <ControlButtonIcon>
        <PauseIcon height={22} width={22} />
      </ControlButtonIcon>
    )}
  </ControlButtonIconWrapper>
);
