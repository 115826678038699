import * as T from '^/types';

export default {
  rename: {
    [T.Language.EN_US]: 'Rename',
    [T.Language.KO_KR]: '이름 바꾸기',
  },
  photo: {
    error: {
      title: {
        [T.Language.KO_KR]: '파일 업로드 오류',
        [T.Language.EN_US]: 'Something went wrong',
      },
      description: {
        [T.Language.KO_KR]: '컬렉션을 가져오는 동안 오류가 발생했습니다. 나중에 다시 시도하십시오.',
        [T.Language.EN_US]: 'An error occurred while fetching collections. Please try again later.',
      },
    },
  },
  filterText: {
    [T.Language.KO_KR]: '필터',
    [T.Language.EN_US]: 'Filter',
  },
  emptyText: {
    [T.Language.KO_KR]: '컬렉션을 찾을 수 없습니다',
    [T.Language.EN_US]: 'No collections found.',
  },
  filterContentTexts: {
    source: {
      [T.Language.KO_KR]: '소스포토',
      [T.Language.EN_US]: 'Source',
    },
    drone: {
      [T.Language.KO_KR]: '시설물 점검사진',
      [T.Language.EN_US]: 'Inspection',
    },
    integration: {
      [T.Language.KO_KR]: '전경사진',
      [T.Language.EN_US]: 'Bird Eye View',
    },
  },
};
