import { RequestPhoto, ViewpointCaptureSteps } from '^/types';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

interface ViewpointsStoreState {
  selectedViewpointIds: Array<RequestPhoto['id']> | null;
  isViewpointUploading: boolean;
  viewpointCaptureStep: ViewpointCaptureSteps | null;
}
interface ViewpointStoreActions {
  setSelectedViewpointIds(selectedViewpointIds: Array<RequestPhoto['id']> | null): void;
  setIsViewpointUploading(isViewpointUploading: boolean): void;
  setViewpointCaptureStep(viewpointCaptureStep: ViewpointCaptureSteps | null): void;
}

const initialState: ViewpointsStoreState = {
  viewpointCaptureStep: null,
  selectedViewpointIds: null,
  isViewpointUploading: false,
};

type ViewpointStore = ViewpointsStoreState & ViewpointStoreActions; // extend here

export const utilsStore = createStore<ViewpointStore>()(
  devtools(set => ({
    ...initialState,

    setSelectedViewpointIds(selectedViewpointIds) {
      set({ selectedViewpointIds });
    },
    setIsViewpointUploading(isViewpointUploading) {
      set({ isViewpointUploading });
    },
    setViewpointCaptureStep(viewpointCaptureStep) {
      set({ viewpointCaptureStep });
    },
    reset() {
      set(initialState);
    },
  }))
);

export function useViewpointStore(): ViewpointStore;
export function useViewpointStore<T>(selector: (state: ViewpointStore) => T): T;
export function useViewpointStore<T>(selector?: (state: ViewpointStore) => T) {
  return useStore(utilsStore, selector!);
}
