import React, { FC } from 'react';
import styled from 'styled-components';
import { usePermissionStore } from '^/store/permissionStore';
import SearchIcon from '^/assets/icons/search.svg';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';

import text from './text';

const Wrapper = styled.div<{ size: MemberSearchInputProps['size'] }>(({ size }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  minHeight: '29px',
  width: size === 'md' ? '284px' : '351px',
  padding: '0 8px',

  borderWidth: size === 'md' ? '1px' : '2px',
  borderStyle: 'solid',
  borderColor: palette.UploadPopup.tableBorderGray.toString(),
  boxSizing: 'border-box',
  borderRadius: '3px',

  fontSize: size === 'md' ? '11px' : '13px',
}));

const Input = styled.input({
  flexGrow: 1,
  marginLeft: '5px',
  backgroundColor: 'transparent',

  color: dsPalette.typePrimary.toString(),
  fontSize: 'inherit',
  '&::placeholder': {
    color: '#c7c7c7',
    opacity: 1,
  },
});

const ClearButton = styled.button({
  marginLeft: '5px',

  backgroundColor: 'transparent',
  cursor: 'pointer',
  fontWeight: 500,
  color: dsPalette.themePrimary.toString(),

  fontSize: 'inherit',
});

const SVGWrapper = styled.svg({});

interface MemberSearchInputProps {
  size?: 'md' | 'lg';
}
export const MemberSearchInput: FC<MemberSearchInputProps> = ({ size = 'md' }) => {
  const setSearchText = usePermissionStore(s => s.setSearchText);
  const searchText = usePermissionStore(s => s.searchText);
  const [l10n]: UseL10n = useL10n();

  const handleSearchchange = (e: any) => {
    setSearchText(e.target.value);
  };
  const handleClearClick = () => {
    setSearchText('');
  };

  return (
    <Wrapper size={size}>
      <SVGWrapper width={17} height={17}>
        <SearchIcon />
      </SVGWrapper>
      <Input
        placeholder={l10n(text.placeholderText)}
        onChange={handleSearchchange}
        value={searchText}
      />
      {searchText && <ClearButton onClick={handleClearClick}>{l10n(text.clear)}</ClearButton>}
    </Wrapper>
  );
};
