import React from 'react';
import Text from './text';
import dsPalette from '^/constants/ds-palette';
import styled from 'styled-components';
import { useL10n } from '^/hooks';
import * as T from '^/types';
import { RockLayer } from '^/store/earthwork/types';

import DeleteSvg from '^/assets/icons/close-new-thin.svg';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import { Label } from '^/components/molecules/SourcePhotoFileInput';
import palette from '^/constants/palette';
import SelectedPlaceholder from './SelectedPlaceholder';
import useEarthWorkFile from '^/hooks/earthwork/useEarthWorkFile';
import { usePopupStore } from '^/store/popup';

export const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const LabelText = styled.div({
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 'bold',
});

export const RefreshIconWrapper = styled.div({
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '8px',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: dsPalette.grey10.toString(),
  },
});

export const LabelIcon = styled.div({
  fontWeight: 'bold',
  fontSize: '14px',
  color: dsPalette.themePrimary.toString(),
});

const ITEM_HEIGHT: number = 26;

export const Item = styled.li({
  height: `${ITEM_HEIGHT}px`,
  display: 'flex',
  paddingLeft: '10px',
  alignItems: 'center',
  paddingRight: '36px',
  position: 'relative',
  justifyContent: 'space-between',
  backgroundColor: palette.white.toString(),
});

export const ItemName = styled.span({
  fontSize: '13px',
  overflow: 'hidden',
  marginRight: '13px',
  whiteSpace: 'nowrap',
  paddingBottom: '3px',
  paddingLeft: '4px',
  textOverflow: 'ellipsis',
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  color: 'var(--color-theme-primary)',
});

export const DeleteIcon = styled(DeleteSvg)({
  right: '3px',
  cursor: 'pointer',
  position: 'absolute',
  top: 'calc(-12px + 50%)',
  transform: 'scale(0.35) translateY(-50%)',
  '> path': {
    fill: dsPalette.title.toString(),
  },
});

export const CurrentSelectedContentWrapper = styled.div<{ hasError?: boolean }>(({ hasError }) => ({
  marginTop: '10px',
  borderRadius: '5px',
  marginBottom: '7px',
  width: 'calc(100% - 1px)',
  border: `1px solid ${palette.UploadPopup[hasError ? 'error' : 'inputBorder'].toString()}`,
  backgroundColor: palette.white.toString(),
}));

export const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '6px',
  maxHeight: '150px',
  overflowY: 'auto',
});

const SelectRockLayer = () => {
  const [l10n] = useL10n();

  const { rockLayers, setRockLayers } = useEarthWorkStore();
  const { dxfOptions } = useEarthWorkFile();
  const { setMinorPopup } = usePopupStore();

  const handleOpenUploadPopup = () => {
    setMinorPopup(T.ContentPageMinorPopupType.ADD_ROCK_LAYER);
  };

  const uploadLabel = <LabelText>{l10n(Text.rockType.label)}</LabelText>;

  const SelectedContent = ({ rockLayer, index }: { rockLayer: RockLayer; index: number }) => {
    const { uploadedDXF, dxfId, name } = rockLayer;
    const tName = dxfOptions.find(o => o.value.toString() === dxfId?.toString())?.text;
    const label = uploadedDXF ? `${name} - DXF (${uploadedDXF.name})` : `${name} - DXF (${tName})`;
    const text = `${label}`;
    const onDeleteClick = (idx: number) => {
      setRockLayers(rockLayers?.filter((_, i) => i !== idx));
    };
    return (
      <Item>
        <DeleteIcon onClick={() => onDeleteClick(index)} />
        <ItemName>{text}</ItemName>
      </Item>
    );
  };

  const selectedUploadedContent = rockLayers?.length ? (
    <CurrentSelectedContentWrapper>
      <Label hasError={false}>
        {l10n(Text.totalRockLayers)}: {rockLayers.length}
      </Label>
      {rockLayers.length > 0
        ? rockLayers.map((rockLayer, index) => (
            <SelectedContent
              key={`selected-rock-type-${index}`}
              index={index}
              rockLayer={rockLayer}
            />
          ))
        : null}
    </CurrentSelectedContentWrapper>
  ) : null;

  const uploadRockType = (
    <>
      {uploadLabel}
      <SelectedPlaceholder
        onClick={handleOpenUploadPopup}
        placeHolderText={l10n(Text.rockType.placeHolder)}
      />
      {selectedUploadedContent}
    </>
  );

  return <Root>{uploadRockType}</Root>;
};

export default SelectRockLayer;
