import { useMutation, useQuery } from '@tanstack/react-query';

import { APIToContent } from '^/store/duck/Contents';

import { useAuthHeader } from '^/hooks';
import { droneStationAuthStore } from '^/store/droneStationAuthStore';
import { useDroneStationStore } from '^/store/droneStationStore';
import { AuthHeader } from '^/store/duck/API';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { useSelector } from 'react-redux';
import { ERROR } from '../types';
import { getDroneStationFetchUrl, getLiveSteamStartUrl, getLiveSteamUpdateUrl } from './common';

export interface GetDroneStationResponse {
  readonly code: number;
  readonly message: string;
  readonly data: T.RawDroneStation[];
}

export interface TGetLiveStreamingResponse {
  readonly code: number;
  readonly message: string;
}

export interface TLiveStreamingPayload {
  url: string;
  video_id: string;
  url_type: number;
  video_quality: number;
}

export enum DRONE_STATION_QUERY {
  GetAllDroneStations = 'GetAllDroneStations',
}

async function getAllDroneStations(
  projectId: T.Project['id'] | undefined,
  paramAuthHeader: AuthHeader | undefined
) {
  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const { accessToken } = droneStationAuthStore.getState().authDetails;

  const authHeader = {
    Authorization: `Bearer ${paramAuthHeader.Authorization}`,
    projectId: projectId,
    'X-Auth-Token': accessToken,
  };
  const fetchDroneStationResponse = await http.get<GetDroneStationResponse>(
    getDroneStationFetchUrl(),
    { headers: authHeader }
  );

  const droneStations = fetchDroneStationResponse.data.data.map(rawDroneStation => {
    const serializedDroneStation = {
      deviceSn: rawDroneStation.device_sn,
      deviceName: rawDroneStation.device_name,
      workspaceId: rawDroneStation.workspace_id,
      controlSource: rawDroneStation.control_source,
      deviceDesc: rawDroneStation.device_desc,
      childDeviceSn: rawDroneStation.child_device_sn,
      domain: rawDroneStation.domain,
      subType: rawDroneStation.sub_type,
      iconUrl: {
        normalIconUrl: rawDroneStation.icon_url.normal_icon_url,
        selectedIconUrl: rawDroneStation.icon_url.selected_icon_url,
      },
      status: rawDroneStation.status,
      boundStatus: rawDroneStation.bound_status,
      loginTime: rawDroneStation.login_time,
      boundTime: rawDroneStation.bound_time,
      nickname: rawDroneStation.nickname,
      firmwareVersion: rawDroneStation.firmware_version,
      workspaceName: rawDroneStation.workspace_name,
      children: rawDroneStation.children,
      firmwareStatus: rawDroneStation.firmware_status,
      thingVersion: rawDroneStation.thing_version,
      payloadsList: rawDroneStation.payloads_list,
    } as T.ADSDroneStation;
    return APIToContent({
      ...serializedDroneStation,

      /**
       * NOTE:
       * I used the type below the rawDroneStation otherwise the type from rawDroneStation will override the type we provide...
       */
      type: T.ContentType.DRONE_STATION,
      projectId,
    } as T.APIDroneStationContent);
  });

  return droneStations;
}

export const useGetDroneStationsQuery = () => {
  const authHeader = useAuthHeader();
  const projectId: T.Project['id'] | undefined = useSelector(
    (state: T.State) => state.Pages.Contents.projectId
  );

  const setDroneStations = useDroneStationStore(s => s.setDroneStations);

  const query = useQuery({
    queryKey: [DRONE_STATION_QUERY.GetAllDroneStations],
    queryFn: async () => getAllDroneStations(projectId, authHeader),
    retry: 3,
    staleTime: 1000 * 60 * 5, // 5 minutes
    onSuccess(data) {
      const modifiedDroneStationsList = data.reduce(
        (acc, droneStation) => ({
          ...acc,
          [droneStation.id]: droneStation,
        }),
        {}
      );
      setDroneStations(modifiedDroneStationsList);
    },
  });
  return query;
};

interface TLiveStreaming {
  datas: Partial<TLiveStreamingPayload>;
  projectId: T.Project['id'] | undefined;
  authHeader: AuthHeader | undefined;
}

async function getLiveStream({ datas, projectId, authHeader: paramAuthHeader }: TLiveStreaming) {
  const { accessToken } = droneStationAuthStore.getState().authDetails;

  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const authHeader = {
    Authorization: `Bearer ${paramAuthHeader.Authorization}`,
    projectId: projectId,
    'X-Auth-Token': accessToken,
  };

  const liveStreamResponse = await http.post<TGetLiveStreamingResponse>(
    getLiveSteamStartUrl(),
    datas,
    { headers: authHeader }
  );

  return liveStreamResponse;
}

export const useGetDroneLiveStreamMutation = () => {
  const liveStreamMutation = useMutation({
    mutationFn: getLiveStream,
    // onSuccess(data) {
    //   console.log({ data });
    // },
    // onError(err) {
    //   console.log({ err });
    // },
  });
  return liveStreamMutation;
};

async function updateLiveStream({ datas, projectId, authHeader: paramAuthHeader }: TLiveStreaming) {
  const { accessToken } = droneStationAuthStore.getState().authDetails;

  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const authHeader = {
    Authorization: `Bearer ${paramAuthHeader.Authorization}`,
    projectId: projectId,
    'X-Auth-Token': accessToken,
  };

  const liveStreamResponse = await http.post<TGetLiveStreamingResponse>(
    getLiveSteamUpdateUrl(),
    datas,
    { headers: authHeader }
  );

  return liveStreamResponse;
}

export const useUpdateDroneLiveStreamMutation = () => {
  const liveStreamMutation = useMutation({
    mutationFn: updateLiveStream,
  });
  return liveStreamMutation;
};
