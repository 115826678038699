import React, { FC } from 'react';
import styled from 'styled-components';

import palette from '^/constants/palette';

interface EnabledProp {
  enabled: boolean;
  backgroundColor: string;
}

const Root = styled.div<EnabledProp>(
  {
    display: 'flex',
    boxSizing: 'border-box',
    width: '29px',
    height: '16px',

    paddingRight: '2px',
    paddingLeft: '2px',

    alignItems: 'center',

    borderRadius: '10px',

    cursor: 'pointer',
  },
  ({ enabled, backgroundColor }) => ({
    justifyContent: enabled ? 'flex-end' : 'flex-start',
    backgroundColor: (enabled ? backgroundColor : palette.icon).toString(),
  })
);

const ToggleButton = styled.div({
  boxSizing: 'border-box',
  position: 'relative',
  width: '13px',
  height: '12px',
  borderRadius: '10px',
  backgroundColor: palette.white.toString(),
});

export type Props = Omit<EnabledProp, 'backgroundColor'> & {
  onClick?(): void;
  backgroundColor?: string;
  trackAction?: string;
  trackLabel?: string;
};

const ToggleSlider: FC<Props> = ({
  enabled,
  onClick,
  trackLabel,
  trackAction,
  backgroundColor,
}) => {
  const onToggle: () => void = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Root
      enabled={enabled}
      onClick={onToggle}
      data-testid={trackLabel ?? 'toggle-switch'}
      data-selected={enabled}
      data-ddm-track-label={trackLabel}
      data-ddm-track-action={trackAction}
      backgroundColor={backgroundColor || palette.ContentsList.toggleSwitchBlue.toString()}
    >
      <ToggleButton />
    </Root>
  );
};

export default ToggleSlider;
