import { Language } from '^/types';

export default {
  nameLabel: {
    [Language.KO_KR]: '이름',
    [Language.EN_US]: 'Name',
  },
  specificationLabel: {
    [Language.KO_KR]: '제원',
    [Language.EN_US]: 'Specification',
  },
  heightLabel: {
    [Language.KO_KR]: '높이',
    [Language.EN_US]: 'Height',
  },
  lengthLabel: {
    [Language.KO_KR]: '세로',
    [Language.EN_US]: 'Length',
  },
  widthLabel: {
    [Language.KO_KR]: '가로',
    [Language.EN_US]: 'Width',
  },
  radiusLabel: {
    [Language.KO_KR]: '반경',
    [Language.EN_US]: 'Radius',
  },
  namePlaceholder: {
    [Language.KO_KR]: '이름',
    [Language.EN_US]: 'Name',
  },
  specificationPlaceholder: {
    [Language.KO_KR]: '2 톤',
    [Language.EN_US]: '2 ton',
  },
  heightPlaceholder: {
    [Language.KO_KR]: '2',
    [Language.EN_US]: '2',
  },
  lengthPlaceholder: {
    [Language.KO_KR]: '2',
    [Language.EN_US]: '2',
  },
  widthPlaceholder: {
    [Language.KO_KR]: '2',
    [Language.EN_US]: '2',
  },
  radiusPlaceholder: {
    [Language.KO_KR]: '2',
    [Language.EN_US]: '2',
  },
};
