import {
  AuthHeader,
  jsonContentHeader,
  makeAuthHeader,
  makeV2APIURL,
  VersionHeader,
} from '^/store/duck/API';
import { http } from '^/utilities/api';
import * as T from '^/types';
import { APIToDroneVideo, APIToPhoto, APIToSourcePhoto } from '^/store/duck/Photos';
import { formatDayMonthYear, formatMonthYear } from '^/components/molecules/PhotoList/util';
import { threeAlbumStore } from '^/components/three/Lib/Store/ThreePhotoAlbumStore';

export interface GetPhotoAlbumResponse {
  readonly data: T.PhotoAlbum[] | T.APIPhoto[] | T.APIDroneVideo[];
  readonly meta: T.APIPhotoMeta[];
}

export interface GetPhotoAlbumByIdResponse {
  data: T.PhotoAlbumbyId;
}
export interface FormattedPhotoAlbumResponse {
  albumInfo: T.PhotoAlbum;
  photos: T.FinalPhoto[];
}

type PhotoType = 'photo' | 'photo_album' | 'video' | 'source';
export type AlbumMapType = Map<
  string,
  Map<string, Map<string, Array<T.PhotoAlbum | T.FinalPhoto>>>
>;

export const APIToPhotoAlbum: (rawPhoto: T.PhotoAlbum) => T.PhotoAlbum = rawPhoto => ({
  ...rawPhoto,
  takenAt: rawPhoto.takenAt ? new Date(rawPhoto.takenAt) : null,
  count: rawPhoto.photosCount,
  photoType: rawPhoto.type as any,
});

/**
 * Fetches photos and updates the store.
 */
export async function fetchPhotoAlbums(
  photoUrl: string,
  authHeader: AuthHeader,
  versionHeader: VersionHeader,
  type: PhotoType
) {
  const response: { data: GetPhotoAlbumResponse } = await http.get(photoUrl, {
    headers: { ...authHeader, ...versionHeader },
  });
  const photos = response.data.data;
  const meta = response.data.meta;

  switch (type) {
    case 'photo': {
      const dataPhoto = (photos as T.APIPhoto[]).map(APIToPhoto);
      const sourcePhoto = meta?.map(APIToSourcePhoto) ?? [];
      return [...dataPhoto, ...sourcePhoto];
    }

    case 'source': {
      const dataPhoto = (photos as T.APIPhoto[]).map(APIToPhoto);
      threeAlbumStore.getState().setSourcePhotos(dataPhoto);
      return dataPhoto;
    }

    case 'video':
      return (photos as T.APIDroneVideo[]).map(APIToDroneVideo);

    case 'photo_album':
      return (photos as T.PhotoAlbum[]).map(APIToPhotoAlbum);

    default:
      return photos;
  }
}

export async function fetchPhotoAlbumsById(
  photoUrl: string,
  authHeader: AuthHeader,
  versionHeader: VersionHeader
) {
  const response: { data: GetPhotoAlbumByIdResponse } = await http.get(photoUrl, {
    headers: { ...authHeader, ...versionHeader },
  });
  const { photos, ...albumInfo } = response.data.data;

  return { albumInfo: albumInfo as T.PhotoAlbum, photos: photos.map(APIToPhoto) };
}

export const sortPhotosforPhotoAlbum = (photos: Array<T.PhotoAlbum | T.FinalPhoto>) => {
  photos.sort((a, b) => {
    if (a.takenAt && b.takenAt) {
      return b.takenAt.getTime() - a.takenAt.getTime();
    }
    return 0;
  });

  return photos;
};

export const groupPhotoAlbums = (albums: Array<T.PhotoAlbum | T.FinalPhoto>, lang: T.Language) => {
  const createDateMaps = (date: Date, albumMap: AlbumMapType) => {
    const year = date.getFullYear().toString();
    const monthYear = formatMonthYear(date, lang);
    const dayMonthYear = formatDayMonthYear(date, lang);

    if (!albumMap.has(year)) {
      albumMap.set(year, new Map());
    }

    const yearMap = albumMap.get(year)!;
    if (!yearMap.has(monthYear)) {
      yearMap.set(monthYear, new Map());
    }

    const monthMap = yearMap.get(monthYear)!;
    if (!monthMap.has(dayMonthYear)) {
      monthMap.set(dayMonthYear, []);
    }

    return { year, monthYear, dayMonthYear };
  };

  const albumMap: AlbumMapType = new Map();
  const photosWithDates = albums.filter(photo => photo.takenAt !== null);

  const sortedPhotos = sortPhotosforPhotoAlbum(photosWithDates);

  sortedPhotos.forEach(album => {
    if (!album.takenAt) {
      return;
    }

    const date = new Date(album.takenAt);
    const { year, monthYear, dayMonthYear } = createDateMaps(date, albumMap);

    albumMap.get(year)?.get(monthYear)?.get(dayMonthYear)?.push(album);
  });

  return albumMap;
};

export const groupnewPhotoAlbums = (
  albums: Array<T.PhotoAlbum | T.FinalPhoto>,
  lang: T.Language
) => {
  const createDateMaps = (date: Date, albumMap: AlbumMapType) => {
    const year = date.getFullYear().toString();
    const monthYear = formatMonthYear(date, lang);
    const dayMonthYear = formatDayMonthYear(date, lang);

    if (!albumMap.has(year)) {
      albumMap.set(year, new Map());
    }

    const yearMap = albumMap.get(year)!;
    if (!yearMap.has(monthYear)) {
      yearMap.set(monthYear, new Map());
    }

    const monthMap = yearMap.get(monthYear)!;
    if (!monthMap.has(dayMonthYear)) {
      monthMap.set(dayMonthYear, []);
    }

    return { year, monthYear, dayMonthYear };
  };

  const albumMap: AlbumMapType = new Map();
  const photosWithDates = albums.filter(photo => photo.takenAt !== null);

  const sortedPhotos = sortPhotosforPhotoAlbum(photosWithDates);

  sortedPhotos.forEach(album => {
    if (!album.takenAt) {
      return;
    }

    const date = new Date(album.takenAt);
    const { year, monthYear, dayMonthYear } = createDateMaps(date, albumMap);

    albumMap.get(year)?.get(monthYear)?.get(dayMonthYear)?.push(album);
  });

  return albumMap;
};

interface EditTagPhotoDateParams {
  projectId: T.Project['id'] | undefined;
  tagMedia: T.SelectedMedia[];
  takenAt: Date;
  auth: T.AuthState;
  planConfigSlug?: string;
}

interface DeleteBulkPhotosParams {
  projectId: T.Project['id'] | undefined;
  photoIds: Array<T.Photo['id']>;
  videoIds: Array<T.DroneVideo['id']>;
  auth: T.AuthState;
  planConfigSlug?: string;
}

interface DeleteVideoParams {
  projectId: T.Project['id'];
  videoIds: Array<T.DroneVideo['id']>;
  auth: T.AuthState;
  planConfigSlug?: string;
}

interface DeletePhotoParams {
  photoId: T.Photo['id'];
  auth: T.AuthState;
  planConfigSlug?: string;
}

interface RenamePhotoAlbumParams {
  photoAlbumId: T.PhotoAlbum['id'];
  newTitle: string;
  auth: T.AuthState;
  planConfigSlug?: string;
}

interface CreateAlbumParams {
  albumType: string;
  projectId: T.Project['id'];
  auth: T.AuthState;
  title?: string;
  planConfigSlug?: string;
  contentId?: string | number;
}

interface MovePhotoParams {
  albumId: T.PhotoAlbum['id'];
  photoIds: Array<T.Photo['id']>;
  projectId: T.Project['id'];
  auth: T.AuthState;
  planConfigSlug?: string;
}

export const photoService = {
  editTagPhotoDate: async ({
    projectId,
    tagMedia,
    takenAt,
    auth,
    planConfigSlug,
  }: EditTagPhotoDateParams) => {
    const URL = makeV2APIURL('projects', projectId!, 'medias', 'bulk');
    const header = makeAuthHeader(auth, planConfigSlug);

    const photoIds = tagMedia
      .filter(media => media.type !== 'overlay')
      .map(media => media.contentId);

    const videoIds = tagMedia
      .filter(media => media.type === 'overlay')
      .map(media => media.contentId);

    const response = await http.patch(
      URL,
      {
        photoIds,
        videoIds,
        takenAt: takenAt.toISOString(),
      },
      {
        headers: {
          ...header,
          ...jsonContentHeader,
        },
      }
    );

    const data = response.data;
    return {
      photos: APIToPhoto(data.data),
    };
  },

  deleteBulkPhotos: async ({
    projectId,
    photoIds,
    videoIds,
    auth,
    planConfigSlug,
  }: DeleteBulkPhotosParams) => {
    const URL = makeV2APIURL('projects', projectId!, 'medias', 'bulk');
    const header = makeAuthHeader(auth, planConfigSlug);

    await http.delete(URL, {
      data: { photoIds, videoIds },
      headers: { ...header, ...jsonContentHeader },
    });

    return { photoIds, videoIds };
  },

  deleteVideo: async ({ projectId, videoIds, auth, planConfigSlug }: DeleteVideoParams) => {
    const URL = makeV2APIURL('projects', projectId, 'videos');
    const header = makeAuthHeader(auth, planConfigSlug);

    await http.delete(URL, {
      data: { ids: videoIds },
      headers: { ...header, ...jsonContentHeader },
    });

    return { videoIds };
  },

  deletePhoto: async ({ photoId, auth, planConfigSlug }: DeletePhotoParams) => {
    const URL = makeV2APIURL('photos', photoId);
    const header = makeAuthHeader(auth, planConfigSlug);

    await http.delete(URL, { headers: header });

    return { photoId };
  },

  renamePhotoAlbum: async ({
    photoAlbumId,
    newTitle,
    auth,
    planConfigSlug,
  }: RenamePhotoAlbumParams) => {
    const URL = makeV2APIURL('photo_albums', photoAlbumId);
    const header = makeAuthHeader(auth, planConfigSlug);

    const response = await fetch(URL, {
      method: 'PATCH',
      headers: {
        ...header,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: photoAlbumId,
        title: newTitle,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to rename photo album');
    }

    const data = await response.json();
    const updatedAlbum = data?.data as T.PhotoAlbum;
    return { photoAlbumId, updatedAlbum };
  },

  createAlbum: async ({
    albumType,
    title,
    projectId,
    auth,
    planConfigSlug,
    contentId,
  }: CreateAlbumParams) => {
    const URL = makeV2APIURL('projects', projectId, 'photo_albums');
    const header = makeAuthHeader(auth, planConfigSlug);

    // to test -> increase the day by one and proceed
    // const appear_at = '2000-12-22';

    const response = await http.post(
      URL,
      {
        type: albumType,
        // appear_at,
        ...(contentId && { contentId }),
        ...(title && { title }),
      },
      { headers: header }
    );
    if (response.status !== 201) {
      throw new Error('Failed to create album');
    }
    return response.data.data as T.PhotoAlbum;
  },

  movePhoto: async ({ albumId, photoIds, projectId, planConfigSlug, auth }: MovePhotoParams) => {
    const URL = makeV2APIURL('projects', projectId, 'photos', 'move');
    const header = makeAuthHeader(auth, planConfigSlug);

    try {
      const response = await http.patch(
        URL,
        {
          photo_album_id: albumId,
          photo_ids: photoIds,
        },
        {
          headers: header,
        }
      );

      return response.data.data as T.PhotoType[];
    } catch (e) {
      throw new Error(e);
    }
  },
};
