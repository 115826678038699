import * as _ from 'lodash-es';
import React, {
  ReactNode,
  MouseEventHandler,
  FC,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import styled, { CSSObject } from 'styled-components';

import Text from './text';
import RawDeleteRowSvg from '^/assets/icons/upload-popup/minus.svg';
import RawAddRowSvg from '^/assets/icons/upload-popup/plus.svg';
import { CancelButton } from '^/components/atoms/Buttons';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import CoordinateTitleDropdown from '^/components/molecules/CoordinateTitleDropdown';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { usePrevProps } from '^/hooks';
import * as T from '^/types';
import { l10n } from '^/utilities/l10n';

interface ErrorProps {
  hasError?: boolean;
}

const border: CSSObject = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: palette.UploadPopup.tableBorderGray.toString(),
};

const Root = styled.div({
  position: 'relative',
});

const ReactDataSheetWrapper = styled.div<ErrorProps>(({ hasError }) => ({
  width: '100%',
  marginRight: '30px',

  border: hasError ? `1px solid ${palette.UploadPopup.error.toString()}` : undefined,
}));

const Table = styled.table({
  borderCollapse: 'collapse',

  width: 'calc(100% + 24px)',
  height: '100%',

  tableLayout: 'fixed',
});
const TableHead = styled.thead({
  '> tr > th:nth-child(1)': {
    width: '72px',
  },
  '> tr > th:nth-child(4)': {
    width: '100px',
  },
});
const TableHeadCell = styled.th({
  ...border,
  backgroundColor: palette.UploadPopup.itemBackgroundGray.toString(),

  fontWeight: 'normal',
  fontSize: '13px',
  color: dsPalette.title.toString(),

  verticalAlign: 'middle',
});
const TableHeadCellText = styled.span({
  marginLeft: '5px',
});
const TableHiddenHeadCell = styled.th({
  border: 'none',
  backgroundColor: 'transparent',
  width: '24px',
});

const TableBody = styled.tbody({});
const TableRow = styled.tr({
  ':hover': {
    '> td > svg': {
      visibility: 'visible',
    },
  },

  '> td:nth-child(1) > span, > td:nth-child(1) > input': {
    textAlign: 'left',
    paddingLeft: '6px',
  },
});

const TableCell = styled.td<ErrorProps>(({ hasError }) => ({
  ...border,
  padding: 0,

  backgroundColor: `${
    hasError ? palette.UploadPopup.error.alpha(0.15).toString() : palette.white.toString()
  } !important`,

  verticalAlign: 'top',

  '> span, > input': {
    border: 'none !important',
    padding: '0px',

    width: 'calc(100% - 9.5px) !important',
    height: '30.3px !important',

    lineHeight: '1.8 !important',
    color: hasError ? palette.UploadPopup.error.toString() : dsPalette.title.toString(),
    fontSize: '13px',

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const TableHiddenCell = styled.td({
  border: 'none',
  backgroundColor: 'transparent',
  verticalAlign: 'bottom',
});

const TableBottomWrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',

  marginTop: '7.5px',
});

const ResetButtonAndLastTitle = styled.div({
  position: 'relative',

  width: '100%',

  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

const ResetButton = styled(CancelButton)({
  width: '66px',
  height: '34px',
  marginTop: '5px',

  fontWeight: 500,
  fontSize: '11px',
  color: dsPalette.title.toString(),

  ':disabled': {
    cursor: 'default',
  },
});

const AddRowIcon = styled(RawAddRowSvg)({
  cursor: 'pointer',

  position: 'absolute',
  left: 'calc(100% + 8px)',
});

const DeleteRowIcon = styled(RawDeleteRowSvg)({
  marginLeft: '7.5px',

  cursor: 'pointer',
  visibility: 'hidden',
});

export type RowValue = string[];

const defaultRowNumber: number = 5;

export const getTableFilledRows: (rows: string[][]) => string[][] = rows =>
  rows.filter(row => _.reduce(row, (sum, col) => sum + col.length, 0) !== 0);

const paddingRow: (rows: RowValue[]) => RowValue[] = rows => {
  const filledRows: string[][] = getTableFilledRows(rows);
  const rowLength: number = rows.length;
  const filledRowLength: number = filledRows.length;

  let blankRowNeedToAdd: number = 0;
  if (rowLength < defaultRowNumber) {
    blankRowNeedToAdd = defaultRowNumber - rowLength;
  } else if (rowLength === filledRowLength) {
    blankRowNeedToAdd = 0;
  }
  const newRows: RowValue[] = [...rows];
  _.times(blankRowNeedToAdd, () => newRows.push(['', '', '', '']));

  return newRows;
};

export interface GridElement extends ReactDataSheet.Cell<GridElement, string> {
  value: string | null;
}

export interface Props {
  readonly coordinateSystem: T.CoordinateSystem;
  readonly isDisabled?: boolean;
  readonly titles: string[];
  readonly disabledTitleIndexes?: number[];
  readonly disabledRowIndexes?: number[];
  readonly rows: RowValue[];
  readonly errorRowIndexes?: number[];
  readonly hasTableError?: boolean;
  onRowsChange(rows: RowValue[]): void;
  onTitlesChange(titles: string[]): void;
  onRowHover?(rowIndex?: number): void;
}

export interface State {
  rows: RowValue[];
  selectingRowIndex?: number;
}

/**
 * @desc The editable table
 */
const CoordinateTable: FC<Props & L10nProps> = ({
  coordinateSystem,
  isDisabled,
  titles,
  disabledTitleIndexes,
  rows: rowValues,
  errorRowIndexes,
  hasTableError,
  language,
  onRowsChange,
  onTitlesChange,
  onRowHover,
}) => {
  const [rows, setRows] = useState<RowValue[]>(paddingRow(rowValues));
  const prevRows = usePrevProps<RowValue[]>(rowValues);

  useEffect(() => {
    if (!_.isEqual(rowValues, prevRows)) {
      setRows(paddingRow(rowValues));
    }
  });

  const clearAll: () => void = () => {
    const rowArrays: RowValue[] = [];
    _.times(defaultRowNumber, () => rowArrays.push(['', '', '', '']));
    setRows(rowArrays);
    onRowsChange(rowArrays);
  };

  const deleteRow: (rowIndex: number) => void = rowIndex => {
    const newRows: RowValue[] = [...rows];
    newRows.splice(rowIndex, 1);
    setRows(newRows);
    onRowsChange(newRows);
  };

  const addRow: () => void = () => {
    const newRows: RowValue[] = [...rows];
    newRows.push(['', '', '', '']);
    setRows(newRows);
  };

  const handleSelectCoordinate: (index: number, coordinate: string) => void = (
    index,
    coordinate
  ) => {
    titles[index] = coordinate;
    onTitlesChange(titles);
  };

  const tableHeaders: ReactNode = titles.map((title, index) =>
    disabledTitleIndexes?.includes(index) ? (
      <TableHeadCell key={index}>
        <TableHeadCellText>{title}</TableHeadCellText>
      </TableHeadCell>
    ) : (
      <TableHeadCell key={index}>
        <CoordinateTitleDropdown
          coordinateSystem={coordinateSystem}
          value={title}
          onSelect={_.partial(handleSelectCoordinate, index)}
        />
      </TableHeadCell>
    )
  );

  const data: Array<Array<{ value: string }>> = rows.map(rowValue =>
    rowValue.map(value => ({ value }))
  );
  const valueRenderer: (cell: any) => string = cell => cell.value;
  const sheetRenderer: ReactDataSheet.SheetRenderer<GridElement, string> = (
    props: PropsWithChildren
  ) => (
    <span className={'data-grid-container'}>
      <Table className={'data-grid'} data-testid="coordinatetable-table">
        <TableHead>
          <TableRow>
            {tableHeaders}
            <TableHiddenHeadCell />
          </TableRow>
        </TableHead>
        <TableBody>{props.children}</TableBody>
      </Table>
    </span>
  );
  const rowRenderer = (props: ReactDataSheet.RowRenderer<GridElement, string>) => {
    const onClick: () => void = () => deleteRow(props.row);

    return (
      <TableRow data-testid="coordinatetable-row">
        {props.children}
        <TableHiddenCell>
          <DeleteRowIcon
            isDisabled={isDisabled}
            onClick={onClick}
            data-testid="coordinatetable-delete-row-icon"
          />
        </TableHiddenCell>
      </TableRow>
    );
  };
  const cellRenderer = (props: ReactDataSheet.CellRenderer<GridElement, string>) => {
    const {
      cell,
      row,
      col,
      attributesRenderer,
      selected,
      editing,
      updated,
      style,
      className,
      ...rest
    }: ReactDataSheet.CellRendererProps<GridElement, string> = props;

    cell.disableEvents = isDisabled;

    const handleMouseEnter: MouseEventHandler<HTMLElement> = () => {
      onRowHover?.(row);
    };

    const handleMouseLeave: MouseEventHandler<HTMLElement> = () => {
      onRowHover?.();
    };

    return (
      <TableCell
        {...rest}
        className={className}
        hasError={errorRowIndexes?.includes(row)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.children}
      </TableCell>
    );
  };

  const deleteLastEmptyElement: (array: any) => object[] = array =>
    array.slice(-1)[0].value === '' ? array.slice(0, -1) : array;

  const onCellsChanged: (changes: any, additional: any) => void = (changes, additional) => {
    const editedChanges: object[] = Object.values(deleteLastEmptyElement(changes));

    editedChanges.forEach(({ row, col, value }: any) => {
      const newRows: RowValue[] = [...rows];
      newRows[row][col] = value;

      setRows(newRows);
    });

    if (additional !== undefined) {
      const editedAdditional: object[] = Object.values(deleteLastEmptyElement(additional));
      const maxColumnNumber: number = 4;
      let rowCounter: number = -1;
      editedAdditional.forEach(({ row, col, value }: any) => {
        if (col >= maxColumnNumber) {
          return;
        }

        if (rowCounter !== row) {
          rowCounter = row;
          addRow();
        }
        const newRows: RowValue[] = [...rows];
        newRows[row][col] = value;

        setRows(newRows);
      });
    }
    onRowsChange(rows);
  };

  return (
    <Root>
      <ReactDataSheetWrapper hasError={hasTableError}>
        <ReactDataSheet
          data={data}
          valueRenderer={valueRenderer}
          sheetRenderer={sheetRenderer}
          rowRenderer={rowRenderer}
          cellRenderer={cellRenderer}
          onCellsChanged={onCellsChanged}
          data-testid="coordinatetable-table"
        />
      </ReactDataSheetWrapper>
      <TableBottomWrapper>
        <ResetButtonAndLastTitle>
          <ResetButton
            disabled={isDisabled}
            onClick={clearAll}
            data-testid="coordinatetable-reset-button"
          >
            {l10n(Text.clearAll, language)}
          </ResetButton>
        </ResetButtonAndLastTitle>
        <AddRowIcon
          isDisabled={isDisabled}
          onClick={addRow}
          data-testid="coordinatetable-add-row-icon"
        />
      </TableBottomWrapper>
    </Root>
  );
};

export default withL10n(CoordinateTable);
