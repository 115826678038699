import RotateSVG from '^/assets/icons/rotation.svg';
import { ColorAndHeightAddAndApply } from '^/components/molecules/ColorAndHeightAddAndApply';
import { ColorAndHeightListItem } from '^/components/molecules/ColorAndHeightListItem';
import { DISABLED_CONTENT_OPACITY } from '^/constants/styles';
import { UseL10n, useL10n } from '^/hooks';
import {
  Action,
  UpdateDSMHeightsAndColors,
  UpdateDTMHeightsAndColors,
} from '^/store/duck/Contents';
import { DSMHeightAndColor, DTMHeightAndColor } from '^/types';
import Color from 'color';
import React, { FC, useCallback, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import styled from 'styled-components';
import text from './text';

const PanelContainer = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  height: 'fit',
  width: '100%',
  border: '1px solid #EAEFF3',
  borderTop: 'none',
  padding: '10px',
  borderRadius: '0px 0px 5px 5px',
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  userSelect: 'none',
  pointerEvents: isDisabled ? 'none' : 'auto',
}));

const AddAndApplyWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ResetButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '10px',
  marginBottom: '15px',
});

const ResetButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',

  fontSize: '14px',
  padding: '2px 8px',
  borderRadius: '5px',
  color: 'var(--color-theme-primary)',
  cursor: 'pointer',
});

const ResetText = styled.span({});

type HeightAndColor = DSMHeightAndColor | DTMHeightAndColor;
interface Props {
  min: number;
  max: number;
  defaultHeightsAndColors?: HeightAndColor[];
  updatedHeightsAndColors?: HeightAndColor[];
  isDisabled?: boolean;
}

export const DSMDTMEditPanel: FC<Props> = ({
  max,
  min,
  defaultHeightsAndColors,
  updatedHeightsAndColors,
  isDisabled,
}) => {
  const [heightsAndColors, setheightsAndColors] = useState<HeightAndColor[]>(
    updatedHeightsAndColors ?? []
  );
  const dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const isDeleteDisabled = heightsAndColors.length === 2;
  const actions: Action[] = [
    UpdateDTMHeightsAndColors({
      dtmColorsAndHeights: heightsAndColors,
      minHeight: min,
      maxHeight: max,
    }),
    UpdateDSMHeightsAndColors({
      dsmColorsAndHeights: heightsAndColors,
      minHeight: min,
      maxHeight: max,
    }),
  ];

  const onDelete = useCallback(
    (id: number) => {
      if (isDeleteDisabled) {
        return;
      }
      const newheightsAndColors = heightsAndColors.filter(item => item.id !== id);
      setheightsAndColors(newheightsAndColors);
    },
    [heightsAndColors]
  );

  const onHeightChange = useCallback(
    (id: number, height: number) => {
      const newheightsAndColors = heightsAndColors.map(item =>
        item.id === id ? { ...item, height } : item
      );
      setheightsAndColors(newheightsAndColors);
    },
    [heightsAndColors]
  );

  const onAdd = useCallback(() => {
    const newheightsAndColors = [
      ...heightsAndColors,
      {
        id: heightsAndColors[heightsAndColors.length - 1].id + 1,
        color: heightsAndColors[heightsAndColors.length - 1].color,
        height: heightsAndColors[heightsAndColors.length - 1].height + 1,
      },
    ];
    setheightsAndColors(newheightsAndColors);
  }, [heightsAndColors]);

  const onColorChange = useCallback(
    (id: number, color: Color) => {
      const newHeightsAndColors = heightsAndColors.map(item =>
        item.id === id ? { ...item, color } : item
      );
      setheightsAndColors(newHeightsAndColors);
    },
    [heightsAndColors]
  );

  const onReset = useCallback(() => {
    setheightsAndColors(defaultHeightsAndColors ?? []);
    batch(() => actions.forEach(dispatch));
  }, []);

  const onApply = () => {
    if (isDisabled) {
      return;
    }
    batch(() => actions.forEach(dispatch));
  };

  return (
    <PanelContainer isDisabled={Boolean(isDisabled)}>
      <ResetButtonWrapper>
        <ResetButton onClick={onReset}>
          <ResetText>{l10n(text.reset)}</ResetText>
          <RotateSVG />
        </ResetButton>
      </ResetButtonWrapper>
      {heightsAndColors.map((listItem, idx) => (
        <ColorAndHeightListItem
          key={`list-item-${idx}`}
          id={listItem.id}
          colorValue={listItem.color}
          height={listItem.height}
          onDelete={onDelete}
          onHeightChange={onHeightChange}
          onColorChange={onColorChange}
          isDeleteDisabled={Boolean(isDeleteDisabled)}
        />
      ))}
      <AddAndApplyWrapper>
        <ColorAndHeightAddAndApply onAdd={onAdd} onApply={onApply} />
      </AddAndApplyWrapper>
    </PanelContainer>
  );
};
