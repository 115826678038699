import { sortBy } from 'lodash-es';
import { useDispatch, useStore } from 'react-redux';
import { makeV2APIURL } from '^/store/duck/API';
import * as T from '^/types';
import { useAuthHeader } from './useAuthHeader';
import { useESSModelsStore } from '^/store/essModelsStore';
import { nameLanguageMapper } from '^/utilities/l10n';
import { ESS_CUSTOM_MODEL_CATEGORY_ID } from '^/constants/cesium';
import { ChangeMapHorizontalTabStatus, CloseContentPagePopup } from '^/store/duck/Pages';
import { http } from '^/utilities/api';

interface GetAllESSModelsResponse {
  readonly data: T.ESSModelInstance[];
}

interface GetESSModelResponse {
  readonly data: T.ESSModelInstance;
}

interface GetESSCustomModelResponse {
  readonly data: T.ESSCustomModelInstance[];
}

interface CreateESSContentResponse {
  readonly data: T.ESSCustomModelInstance;
}

interface CreateESSCategoryResponse {
  readonly data: T.ESSModelCategory;
}

interface CreateESSCustomModelParams {
  readonly nameEn: string;
  readonly nameKo: string;
  readonly detailEn?: string;
  readonly detailKo?: string;
  readonly thumbnail: Blob;
  readonly width?: string;
  readonly length?: string;
  readonly height?: string;
  readonly heading?: string;
  readonly workRadius?: string;
  readonly projectId: string;
  readonly categoryId?: string;
  readonly file?: File;
  readonly info?: {
    readonly miscMeta: {
      readonly color: string;
      readonly opacity: number;
    };
  };
}

/**
 * Sort either the category or the instance of ESS by its name depending on the language,
 * since it would be easier for users to browse.
 */
const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const sortByLanguage: <K extends T.ESSModelInstance | T.ESSModelCategory>(
  members: K[],
  language: T.Language
) => K[] = (members, language) =>
  sortBy(members, member => member[nameLanguageMapper[language]].toLocaleLowerCase());

export function useESSModels() {
  const authHeader = useAuthHeader();
  const {
    setSelectedESSCategoryId,
    setSelectedESSModelId,
    addESSModelCategory,
    setESSModelCategories,
    setESSModel,
    setESSModelIdsByCategory,
    setESSCustomModel,
    setESSCustomModels,
    setFetchESSModelStatus,
    setCreateESSCustomModelStatus,
    setCreateESSModelCategoryStatus,
  } = useESSModelsStore(s => ({
    setSelectedESSCategoryId: s.setSelectedESSCategoryId,
    setSelectedESSModelId: s.setSelectedESSModelId,
    addESSModelCategory: s.addESSModelCategory,
    setESSModelCategories: s.setESSModelCategories,
    setESSModel: s.setESSModel,
    setESSModelIdsByCategory: s.setESSModelIdsByCategory,
    setESSCustomModel: s.setESSCustomModel,
    setESSCustomModels: s.setESSCustomModels,
    setFetchESSModelStatus: s.setFetchESSModelStatus,
    setCreateESSCustomModelStatus: s.setCreateESSCustomModelStatus,
    setCreateESSModelCategoryStatus: s.setCreateESSModelCategoryStatus,
  }));
  const dispatch = useDispatch();
  const state$ = useStore().getState();

  async function fetchESSModelCategories(projectId: T.Content['id']) {
    try {
      setFetchESSModelStatus(T.APIStatus.PROGRESS);
      const response = await http.get<{ data: T.ESSModelCategory[] }>(
        makeV2APIURL('ess_model_categories', `?project_id=${projectId}`),
        { headers: authHeader }
      );
      setFetchESSModelStatus(T.APIStatus.SUCCESS);

      const categories = sortByLanguage(response.data.data, state$.Pages.Common.language);
      setESSModelCategories(categories);

      if (categories) {
        await fetchESSModelsByCategory({
          categoryId: categories[0].id,
        });
        setSelectedESSCategoryId(categories[0].id);
      }
    } catch (error) {
      setFetchESSModelStatus(T.APIStatus.ERROR);
    }
  }

  async function fetchESSModelsByCategory({
    categoryId,
    projectId,
  }: {
    categoryId: NonNullable<T.ESSModelsState['selectedCategoryId']>;
    projectId?: T.ESSModelInstance['projectId'];
  }) {
    const projectIdQuery = projectId ? `?project_id=${projectId}` : '';
    const url = makeV2APIURL('ess_model_categories', categoryId, 'ess_models', projectIdQuery);

    try {
      setFetchESSModelStatus(T.APIStatus.PROGRESS);
      const response = await http.get<GetAllESSModelsResponse>(url, {
        headers: authHeader,
      });
      setFetchESSModelStatus(T.APIStatus.SUCCESS);

      const instances = response.data.data;
      instances.forEach(instance => {
        setESSModel(instance);
      });
      setESSModelIdsByCategory(
        categoryId,
        instances.map(instance => instance.id)
      );
      setSelectedESSModelId(undefined);
    } catch (error) {
      setFetchESSModelStatus(T.APIStatus.ERROR);
    }
  }

  async function fetchESSCustomModels() {
    try {
      setESSCustomModels({});
      const projectId = state$.Pages.Contents.projectId!;

      setFetchESSModelStatus(T.APIStatus.PROGRESS);
      const response = await http.get<GetESSCustomModelResponse>(
        makeV2APIURL('ess_models', `?project_id=${projectId}`),
        { headers: authHeader }
      );
      setFetchESSModelStatus(T.APIStatus.SUCCESS);

      const instances = response.data.data;

      const essCustomModels: Record<T.ESSCustomModelInstance['id'], T.ESSCustomModelInstance> = {};
      instances.forEach(instance => {
        essCustomModels[instance.id] = instance;
      });

      setESSCustomModels(essCustomModels);
      setSelectedESSCategoryId(ESS_CUSTOM_MODEL_CATEGORY_ID);
    } catch (error) {
      setFetchESSModelStatus(T.APIStatus.ERROR);
    }
  }

  async function getESSModelById(id: T.ESSModelInstance['id']) {
    try {
      const response = await http.get<GetESSModelResponse>(makeV2APIURL('ess_models', id), {
        headers: authHeader,
      });

      setESSModel(response.data.data);
    } catch (error) {
      setFetchESSModelStatus(T.APIStatus.ERROR);
    }
  }

  async function createESSCustomModel(content: CreateESSCustomModelParams) {
    const projectId = state$.Pages.Contents.projectId!;
    const formData = new FormData();

    Object.keys(content).forEach((key: keyof CreateESSCustomModelParams) => {
      if (key === 'info') {
        formData.append('info', JSON.stringify(content[key]));
      } else {
        const value = content[key];
        if (value) {
          formData.append(camelToSnakeCase(key), value);
        }
      }
    });

    try {
      setCreateESSCustomModelStatus(T.APIStatus.PROGRESS);
      const response = await http.post<CreateESSContentResponse>(
        makeV2APIURL('ess_models'),
        formData,
        { headers: authHeader }
      );

      setESSCustomModel(response.data.data);
      await fetchESSModelsByCategory({
        categoryId:
          content.categoryId === undefined
            ? ESS_CUSTOM_MODEL_CATEGORY_ID
            : Number(content.categoryId),
        projectId,
      });

      dispatch(CloseContentPagePopup());
      dispatch(
        ChangeMapHorizontalTabStatus({
          status: T.MapHorizontalTabStatus.ESS_CUSTOM_MODEL,
        })
      );
      if (content.categoryId) {
        setSelectedESSCategoryId(Number(content.categoryId));
      } else {
        setSelectedESSCategoryId(ESS_CUSTOM_MODEL_CATEGORY_ID);
      }
      setCreateESSCustomModelStatus(T.APIStatus.SUCCESS);
    } catch (error) {
      setCreateESSCustomModelStatus(T.APIStatus.ERROR);
    }
  }

  async function createESSCustomModelCategory({ nameEn }: { nameEn: string }) {
    const projectId = state$.Pages.Contents.projectId!;

    const formData = new FormData();
    formData.append('nameEn', nameEn);
    formData.append('nameKo', nameEn);
    formData.append('projectId', projectId.toString());

    try {
      setCreateESSModelCategoryStatus(T.APIStatus.PROGRESS);
      const response = await http.post<CreateESSCategoryResponse>(
        makeV2APIURL('ess_model_categories'),
        formData,
        { headers: authHeader }
      );
      setCreateESSModelCategoryStatus(T.APIStatus.SUCCESS);

      addESSModelCategory(response.data.data);
    } catch (error) {
      setCreateESSModelCategoryStatus(T.APIStatus.ERROR);
    }
  }

  return {
    fetchESSModelCategories,
    fetchESSModelsByCategory,
    fetchESSCustomModels,
    getESSModelById,
    createESSCustomModel,
    createESSCustomModelCategory,
  };
}
