import React, { FC, useMemo, useState, memo } from 'react';
import styled, { CSSObject } from 'styled-components';

import Text from './text';
import Dropdown, {
  Option as DropdownOption,
  StyleProps as DropdownStyleProps,
} from '^/components/atoms/Dropdown';
import palette from '^/constants/palette';
import { UseL10n, UseState, useL10n } from '^/hooks';
import { contentsSelector } from '^/store/duck/Contents';
import * as T from '^/types';
import { isBoundaryViolated } from '^/utilities/math';
import { arePropsEqual } from '^/utilities/react-util';
import { useContentsStore } from '^/store/zustand/content/contentStore';

const Root = styled.div({
  display: 'block',
});

const dropdownMainButtonStyle: CSSObject = {
  color: palette.textGray.toString(),
  borderColor: palette.textGray.toString(),

  width: '100%',
};

const initCurrentValue: (
  defaultValue?: T.VolumeContent['id'],
  content?: T.VolumeContent
) => T.VolumeContent['id'] | undefined = (defaultValue, content) => {
  if (defaultValue) {
    return defaultValue;
  }
  if (!content || content.info.calculatedVolume.calculation.type !== T.VolumeCalcMethod.DESIGN) {
    return;
  }

  return content.info.calculatedVolume.calculation.designDxfId;
};

export interface Props {
  readonly content?: T.VolumeContent;
  readonly defaultValue?: T.VolumeContent['id'];
  readonly dropdownStyle?: DropdownStyleProps;
  readonly isSearchEnabled?: boolean;
  readonly isDisabled?: boolean;
  readonly isViolationCheckNeeded?: boolean;
  onSelect(option: DropdownOption): void;
  onDropdownItemMouseEnter?(option: DropdownOption, index: number): void;
  onDropdownMouseLeave?(): void;
}

export const DesignDXFDropdown: FC<Props> = memo(
  ({
    onDropdownMouseLeave,
    onDropdownItemMouseEnter,
    onSelect,
    isSearchEnabled,
    content: volumeContent,
    dropdownStyle,
    defaultValue,
    isDisabled,
    isViolationCheckNeeded,
  }) => {
    const [l10n]: UseL10n = useL10n();
    const byId = useContentsStore(s => s.contents.byId);
    const allIds = useContentsStore(s => s.contents.allIds);

    const options: DropdownOption[] = useMemo(initOptions, [volumeContent, allIds.length]);
    const [currentValue, setCurrentValue]: UseState<T.VolumeContent['id'] | undefined> = useState(
      initCurrentValue(defaultValue, volumeContent)
    );

    function initOptions(): DropdownOption[] {
      return allIds
        .sort((a, b) => String(b).localeCompare(String(a)))
        .map(id => byId[id])
        .filter(
          content =>
            content.type === T.ContentType.DESIGN_DXF &&
            !contentsSelector.isProcessingOrFailedByContent(content)
        )
        .map((content: T.DesignDXFContent) => ({
          leftText: content.title,
          rightText:
            isViolationCheckNeeded &&
            volumeContent !== undefined &&
            isBoundaryViolated(volumeContent.info.locations, content)
              ? l10n(Text.violatedBoundary)
              : undefined,
          value: content.id,
        }));
    }

    const handleDropdownItemClick: (option: DropdownOption, index: number) => void = option => {
      const value: number = option.value as number;
      setCurrentValue(value);
      onSelect(option);
    };

    return (
      <Root>
        <Dropdown
          isSearchEnabled={isSearchEnabled}
          mainButtonStyle={dropdownMainButtonStyle}
          value={currentValue}
          placeHolder={l10n(Text.placeholder)}
          options={options}
          rootStyle={{
            width: '100%',
            minWidth: '160px',
            maxWidth: '160px',
          }}
          zIndex={1}
          valueEditorStyle={{
            width: '100% !important',
          }}
          onClick={handleDropdownItemClick}
          height={'112px'}
          menuItemHeight={'34px'}
          onDropdownItemMouseEnter={onDropdownItemMouseEnter}
          onDropdownMouseLeave={onDropdownMouseLeave}
          isDisabled={isDisabled}
          {...dropdownStyle}
        />
      </Root>
    );
  },
  arePropsEqual
);
