import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import ContentAttachments from '../ContentAttachments';
import ContentDescription from '../ContentDescription';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import { ContentsListItem, HorizontalDivider } from '^/components/atoms/ContentsListItem';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import dsPalette from '^/constants/ds-palette';
import { useL10n, UseL10n } from '^/hooks';
import { GetESSAttachments } from '^/store/duck/ESSAttachments';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useESSModelsStore } from '^/store/essModelsStore';

const ToolContainer = styled.div({
  margin: '-14px 20px 0 36px',
  width: '100%',
});

const ToggleContainer = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ToggleLabel = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: dsPalette.title.toString(),
});

export interface Props {
  content: T.ESSModelContent;
  isPinned?: boolean;
}

const RawContentsListESSModelItem: FC<Props> = ({ content, isPinned = false }) => {
  const editingESSContentId = useESSContentsStore(s => s.editingESSContentId);
  const essModels = useESSModelsStore(s => s.essModels);
  const [l10n]: UseL10n = useL10n();
  const dispatch: Dispatch = useDispatch();
  const isEditing: boolean = content.id === editingESSContentId;

  const model: T.ESSModelInstance | undefined = content
    ? essModels[content.info.modelId]
    : undefined;

  useEffect(() => {
    if (isEditing) {
      dispatch(GetESSAttachments({ contentId: content.id }));
    }
  }, [isEditing]);

  const toggleWorkRadiusVis: () => void = useCallback(() => {
    // TODO: update this later for cesium
    // dispatch(
    //   ChangeESSModelContentWorkRadiusViz({
    //     id: content.id,
    //     isWorkRadiusVisEnabled: !content?.info.isWorkRadiusVisEnabled,
    //   })
    // );
  }, [content?.info.isWorkRadiusVisEnabled]);

  const workRadiusToggle: ReactNode = useMemo(() => {
    if (!model?.workRadius || content.config?.temporaryContentId) {
      return null;
    }

    return (
      <>
        <HorizontalDivider />
        <ToggleContainer>
          <ToggleLabel>{l10n(Text.workRadiusToggleLabel)}</ToggleLabel>
          <ToggleSlider
            enabled={Boolean(content?.info.isWorkRadiusVisEnabled)}
            onClick={toggleWorkRadiusVis}
          />
        </ToggleContainer>
      </>
    );
  }, [model?.workRadius, content?.info.isWorkRadiusVisEnabled]);

  const contentToolContainer = useMemo(() => {
    if (content.config?.temporaryContentId) {
      return null;
    }

    return (
      <ToolContainer>
        <ContentDescription content={content} />
        <ContentAttachments content={content} />
      </ToolContainer>
    );
  }, [content.config?.temporaryContentId, content]);

  return (
    <ContentsListItem isPinned={isPinned} className={CANCELLABLE_CLASS_NAME} content={content}>
      {contentToolContainer}
      {workRadiusToggle}
    </ContentsListItem>
  );
};

export const ContentsListESSModelItem: FC<Props> = withErrorBoundary(RawContentsListESSModelItem)(
  Fallback
);
