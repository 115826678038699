import { useL10n } from '^/hooks';
import theme from '^/theme';
import React from 'react';
import styled from 'styled-components';
import Text from './text';

const Card = styled.div({
  width: '196px',
  backgroundColor: theme.colors.coolGray[50],
  padding: '21px 23px',
  borderRadius: '8px',
  fontFamily: 'Arial, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const Title = styled.p({
  fontWeight: '700',
  fontSize: '15px',
  color: theme.colorTokens.accentBlackLighter,
  marginBottom: '8px',
});

const TextInfo = styled.p({
  fontSize: '14px',
  color: theme.colors.coolGray[600],
  margin: '4px 0',
  lineHeight: '160%',
});

export function NoViewpointsInfo() {
  const [l10n] = useL10n();
  return (
    <Card>
      <Title>{l10n(Text.tryCapturing)}</Title>
      <TextInfo>{l10n(Text.easilyRequest)}</TextInfo>
    </Card>
  );
}
