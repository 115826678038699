import React, { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIcon from '^/components/atoms/LoadingIcon';
import {
  usePhotoAlbumIdQuery,
  usePhotoQuery,
  useSourcePhotoQuery,
} from '^/hooks/api/photos/usePhoto';
import { LoaderWrapper } from '../PhotoList/style';
import { PhotoListContent } from '../PhotoList/PhotoListContent';
import { groupPhotoAlbums } from '^/hooks/api/photos/services';
import { useL10n } from '^/hooks';
import * as T from '^/types';

const PhotoTypeList: FC = () => {
  const { contentType, takenAt, id } = useParams();
  const [, lang] = useL10n();
  const isSourcePhoto = contentType === T.PhotoAlbumType.SOURCE;
  const isViewPoint = contentType === T.PhotoAlbumType.VIEWPOINT;
  const is360VslamVideo = contentType === T.PhotoAlbumType.THREE_SIXTY_VIDEO;

  // Use the new photo album query
  //   const { photosFromAlbums, isError, isLoading } = usePhotoAlbumIdQuery(Number(contentId));
  const {
    rawPhotos,
    isError: isPhotoError,
    isLoading: isPhotoLoading,
    rawVideos,
  } = usePhotoQuery();
  const filteredPhotos = rawPhotos.filter(
    photo =>
      photo.photoType === contentType && photo.takenAt?.toISOString().split('T')[0] === takenAt
  );
  const filteredVideos = rawVideos.filter(
    video =>
      video.photoType === contentType && video.takenAt?.toISOString().split('T')[0] === takenAt
  );

  const photoAlbum = groupPhotoAlbums(filteredPhotos, lang);
  const videoAlbum = groupPhotoAlbums(filteredVideos, lang);
  const {
    photosFromAlbums: viewPointAlbums,
    isError: isViewPointError,
    isLoading: isViewPointLoading,
  } = usePhotoAlbumIdQuery(Number(filteredPhotos[0]?.viewpointAlbumId));

  const {
    sourcePhotosAlbum,
    isError: isSourceError,
    isLoading: isSourceLoading,
  } = useSourcePhotoQuery(
    Number(id!),
    filteredPhotos.length ? filteredPhotos[0]?.screenId : undefined,
    isSourcePhoto
  );

  const isLoading = isSourcePhoto
    ? isSourceLoading
    : isViewPoint
    ? isViewPointLoading
    : isPhotoLoading;
  const isError = isSourcePhoto ? isSourceError : isViewPoint ? isViewPointError : isPhotoError;
  const photosFromAlbums = isSourcePhoto
    ? sourcePhotosAlbum
    : isViewPoint
    ? viewPointAlbums
    : is360VslamVideo
    ? videoAlbum
    : photoAlbum;

  // Show error state if query fails
  if (isError) {
    return <div>Error loading photo album</div>;
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <LoaderWrapper>
          <LoadingIcon loadingDivCustomStyle={{ height: 32, width: 32 }} />
        </LoaderWrapper>
      ) : (
        <PhotoListContent groupedAlbums={photosFromAlbums} />
      )}
    </React.Fragment>
  );
};

export default memo(PhotoTypeList);
