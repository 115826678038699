import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import styled, { CSSObject } from 'styled-components';

export const FILTER_Z_INDEX = 900;
export const Root = styled.div({
  padding: '0rem 0.75rem 0.5rem 1.25rem',
});

export const SearchBox = styled.div({
  border: '1px solid #D9D9D9',
  height: '1.75rem',
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.5rem',
  gap: '5px',
});
export const Input = styled.input({
  flexGrow: 1,
  fontWeight: 400,
  fontSize: 13,
  color: '#4D4C4C',
  backgroundColor: 'transparent',
  '::-webkit-input-placeholder': {
    color: '#B5B5B5',
  },
});

export const InputArea = styled.textarea({
  flexGrow: 1,
  marginTop: '8px',
  fontWeight: 400,
  fontSize: 13,
  color: palette.textGray.toString(),
  border: `1px solid ${palette.border.toString()}`,
  borderRadius: '2px',
  backgroundColor: palette.white.toString(),
  minHeight: '41px',
  maxHeight: '250px',
  padding: '10px 18px',
  '::-webkit-input-placeholder': {
    color: '#B5B5B5',
  },
  rows: '4',
  resize: 'none',
});

export const VerticalDivider = styled.div({
  width: 1,
  height: '80%',
  backgroundColor: '#D9D9D9',
});

export const iconStyles: CSSObject = {
  height: 20,
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: 'transparent',
};
export const SearchIconWrapper = styled.div(iconStyles);
export const IconWrapper = styled.button<{ isActive?: boolean }>(({ isActive }) => ({
  ...iconStyles,
  cursor: 'pointer',
  backgroundColor: isActive ? '#CED7E5' : 'transparent',
  ':hover': {
    backgroundColor: '#CED7E5',
  },
}));
export const FilterWrapper = styled.button({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor: 'transparent',
});
export const FilterPortalWrapper = styled.div({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: FILTER_Z_INDEX,
  top: 0,
});

export const FilterRoot = styled.div({
  position: 'fixed',
  zIndex: FILTER_Z_INDEX,
  backgroundColor: '#fff',
  minWidth: '164px',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  padding: '5px 0',
  borderRadius: '4px',
});
export const FilterItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '9px 13px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#E5E5E5',
    color: 'red',
  },
  ':hover svg': {
    color: '#8d8d8d',
  },
  [`:hover ${IconWrapper}`]: {
    backgroundColor: '#CED7E5',
  },
});
export const FilterName = styled.div({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '15px',
  color: '#4D4C4C',
  textTransform: 'capitalize',
});

export const FilterTitle = styled.div({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '19px',
  textTransform: 'capitalize',
  color: dsPalette.typeGrey.toString(),
});
