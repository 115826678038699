import { Language } from '^/types';

export default {
  rewindTooltip: {
    [Language.KO_KR]: '뒤로 이동(10초)',
    [Language.EN_US]: 'Go back ( 10 seconds )',
  },
  forwardlTooltip: {
    [Language.KO_KR]: '앞으로 이동(10초)',
    [Language.EN_US]: 'Go forward ( 10 seconds )',
  },
  playTooltip: {
    [Language.KO_KR]: '멈춰요',
    [Language.EN_US]: 'Pause',
  },
  pauseTooltip: {
    [Language.KO_KR]: `놀고요`,
    [Language.EN_US]: `Play`,
  },
  fullScreenTooltip: {
    [Language.KO_KR]: '전체 화면 입력',
    [Language.EN_US]: 'Enter FullScreen',
  },
  exitFullScreenTooltip: {
    [Language.KO_KR]: '전체화면 종료',
    [Language.EN_US]: 'Exit FullScreen',
  },
  changePlaybackSpeed: {
    [Language.KO_KR]: '속도 변경',
    [Language.EN_US]: 'Change Playback Speed',
  },
  volumeMute: {
    [Language.KO_KR]: '음소거',
    [Language.EN_US]: 'Mute',
  },
  volumeUnMute: {
    [Language.KO_KR]: '음소거 해제',
    [Language.EN_US]: 'Unmute',
  },
};
