import * as T from '^/types';

export default {
  title: {
    [T.Language.KO_KR]: '비행 일정 시간 선택',
    [T.Language.EN_US]: 'Flight Schedule Time Picker',
  },
  startTime: {
    [T.Language.KO_KR]: '시작 시각 선택',
    [T.Language.EN_US]: 'Select start time',
  },
  endTime: {
    [T.Language.KO_KR]: '종료 시각 선택',
    [T.Language.EN_US]: 'Select end time',
  },
  confirm: {
    [T.Language.KO_KR]: '확인',
    [T.Language.EN_US]: 'Confirm',
  },
  cancel: {
    [T.Language.KO_KR]: '취소',
    [T.Language.EN_US]: 'Cancel',
  },
};
