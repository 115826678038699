import { typeGuardCADContent } from '^/hooks';
import { makeBucketURL } from '^/store/duck/API';
import * as T from '^/types';
import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat, get } from 'ol/proj';
import TileSource from 'ol/source/Tile';
import XYZSource from 'ol/source/XYZ';
import React, { useEffect, useState } from 'react';

export interface TProps {
  readonly zIndex: number;
  readonly content: T.BlueprintDWGContent | T.BlueprintDXFContent;
  readonly children?: React.ReactNode;
  readonly baseMap: Map | null;
  readonly overlayOpacity?: number;
}

export const OlVslamDWGDXF = (props: TProps) => {
  const { zIndex, content, baseMap, overlayOpacity } = props;
  const preload: number = 0;
  const projectionCode = `CUSTOM:${content.id}`;
  const [projection] = useState(get(projectionCode));
  const [layer, setLayer] = useState<TileLayer<TileSource> | null>(null);

  const extent: Extent | undefined = (() => {
    const finalizedContent: T.CADContent | undefined = typeGuardCADContent(content);

    const tms: T.CADContent['info']['tms'] = finalizedContent?.info?.tms;

    if (!tms || !tms.bounds) {
      return undefined;
    }

    const [b1, b2, b3, b4]: Extent = tms.bounds;
    const [p1, p2]: Coordinate = fromLonLat([b1, b2]);
    const [p3, p4]: Coordinate = fromLonLat([b3, b4]);

    return [p1 - 100, p2 - 100, p3 + 100, p4 + 100];
  })();

  useEffect(() => {
    if (layer) {
      baseMap?.removeLayer(layer);
    }
    const newLayer = new TileLayer({
      source: new XYZSource({
        url: makeBucketURL(content.id, 'raster_tiles', '{z}', '{x}', '{-y}@2x.png'),
        projection: get('EPSG:3857') ?? undefined,
        maxZoom: 16,
        crossOrigin: 'use-credentials',
      }),
      preload: preload,
      zIndex: zIndex,
      extent: extent,
    });
    setLayer(newLayer);
    baseMap?.addLayer(newLayer);

    return () => {
      baseMap?.removeLayer(newLayer);
    };
  }, [projection, baseMap]);

  useEffect(() => {
    if (layer && overlayOpacity) {
      layer.setOpacity(overlayOpacity);
    }
  }, [overlayOpacity]);

  return <></>;
};
