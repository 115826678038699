import * as _ from 'lodash-es';
import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  Dispatch,
  SetStateAction,
  MutableRefObject,
  useState,
  ReactText,
} from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow';

import Text from './text';
import DeleteSvg from '^/assets/icons/close-new-thin.svg';
import DeleteAllSvg from '^/assets/icons/delete.svg';
import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { UseL10n, useL10n, usePrevProps } from '^/hooks';
import { fileNeq } from '^/utilities/file-util';
import formatValue from '^/utilities/value-format';
import DragAndDropFileInput, { SelectPhotoFromAlbumSelector } from '../DragAndDropFileInput';
import { useExifr } from '^/hooks/useExifr';
import { useDispatch, useSelector } from 'react-redux';
import * as T from '^/types';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import { setSourcePhotoContentId } from '^/store/duck/Photos';
import { ChangeTwoDDisplayCenter, OpenContentPagePopup } from '^/store/duck/Pages';
import { PhotoItemContainer } from '../SourcePhotoSelector';
import { useExifrPhotosStore } from '^/store/exifrParsedPhotosStore';
import { useGetPhotoData } from '../PhotoInspectionList/useGetPhotoData';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { toast } from 'react-toastify';
import {
  defaultToasterOptions,
  DownloadProgressDisplay,
  ProgressType,
} from '^/components/atoms/DownloadProgressPercent';

interface Validation {
  hasError: boolean;
}
interface ListProps {
  isOverwritingNeeded?: boolean;
}

export const TextLabel = styled.p({
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: dsPalette.title.toString(),
});

const Wrapper = styled.div<Validation>(({ hasError }) => ({
  marginTop: '20px',
  borderRadius: '5px',
  marginBottom: '7px',
  width: 'calc(100% - 1px)',
  border: `1px solid ${palette.UploadPopup[hasError ? 'error' : 'inputBorder'].toString()}`,
  backgroundColor: (hasError ? palette.UploadPopup.error : palette.white).alpha(0.05).toString(),
}));

const InputId: HTMLAttributes<HTMLInputElement>['id'] = 'upload-popup-input';

export const Label = styled.label.attrs({
  htmlFor: InputId,
})<Validation>(({ hasError }) => ({
  height: '10px',
  padding: '10px',
  display: 'flex',
  fontSize: '13px',
  marginTop: '5px',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: (hasError ? palette.UploadPopup.error : palette.dividerLight).toString(),
  cursor: 'pointer',
  path: {
    fill: hasError ? palette.UploadPopup.error.toString() : undefined,
  },
}));

const ITEM_HEIGHT: number = 32;
const LIST_MAX_ITEM: number = 10;
const List = styled.ul<ListProps>`
  max-height: ${props =>
    props.isOverwritingNeeded ? `calc(100vh - 670px)` : `calc(100vh - 550px)`};
  overflow-y: scroll;
  > li + li {
    border-top: 1px solid ${palette.UploadPopup.inputBorder.toString()};
  }
`;

const Item = styled.li({
  height: `${ITEM_HEIGHT}px`,
  display: 'flex',
  paddingLeft: '36px',
  alignItems: 'center',
  paddingRight: '36px',
  position: 'relative',
  justifyContent: 'space-between',
});

const ItemName = styled.span({
  fontSize: '13px',
  overflow: 'hidden',
  marginRight: '13px',
  whiteSpace: 'nowrap',
  paddingBottom: '3px',
  textOverflow: 'ellipsis',
  color: 'var(--color-theme-primary)',
});

export const ItemSize = styled.span({
  fontWeight: 500,
  fontSize: '13px',
  whiteSpace: 'nowrap',
  paddingBottom: '3px',
  color: dsPalette.title.toString(),
});

const DeleteIcon = styled(DeleteSvg)({
  left: '3px',
  cursor: 'pointer',
  position: 'absolute',
  top: 'calc(-12px + 50%)',
  transform: 'scale(0.35) translateY(-50%)',
  '> path': {
    fill: dsPalette.title.toString(),
  },
});

const Extension = styled.p<Validation>(({ hasError }) => ({
  color: (hasError ? palette.UploadPopup.error : palette.font).toString(),
  fontWeight: 500,
  fontSize: '12px',
  marginTop: '7px',
}));

export const tooltipCustomStyle: WrapperHoverableProps['customStyle'] = {
  tooltipWrapperStyle: { position: 'relative' },
  tooltipArrowStyle: {
    left: '50%',
    transform: 'translate(-50%)',
  },
  tooltipBalloonStyle: {
    left: '50%',
    bottom: 'auto',
    fontWeight: 'bold',
    transform: 'translate(-50%, 3px)',
  },
};

export const SubTextLabel = styled.p({
  fontSize: '12px',
  fontWeight: 'bold',
  color: dsPalette.title.toString(),
});

const FromAlbumWrapper = styled.div({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SelectedItemWrapper = styled.div({ marginTop: '10px' });

const DetailsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
});

const SelectedText = styled.p({
  fontSize: '13px',
  color: palette.dividerLight.toString(),
});

export interface Props {
  readonly files: File[];
  readonly hasError?: boolean;
  readonly extensions: string[];
  isOverwritingNeeded?: boolean;
  readonly hasMultipleFiles?: boolean;
  setFiles: Dispatch<SetStateAction<File[]>>;
}

export const SourcePhotoFileInput: FC<Props> = ({
  files,
  setFiles,
  extensions,
  hasError = false,
  isOverwritingNeeded,
  hasMultipleFiles = false,
}) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch = useDispatch();
  const listRef: MutableRefObject<HTMLUListElement | null> = useRef(null);
  const toastIdRef = useRef<ReactText | null>(null);
  const prevFiles: File[] | undefined = usePrevProps<File[]>(files);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const [, setPhotoStatus] = useState<T.APIStatus>(T.APIStatus.IDLE);
  const { photos, selectedSourceAlbum } = useSelector((s: T.State) => s.Photos);
  const { setSourcePhotos, sourcePhotos } = useThreePhotoAlbumStore(s => ({
    sourcePhotos: s.sourcePhotos,
    setSourcePhotos: s.setSourcePhotos,
  }));

  const { parseRawImage } = useExifr();

  /** --------For the location on map while album is selected --------- */

  const [fromAlbum, setFromAlbum] = useState<boolean>(Boolean(selectedSourceAlbum));
  const {
    imagesWithLocation,
    progressPercent,
    setParsedPhotos,
    setProgressPercent,
    setRenderProgress,
  } = useExifrPhotosStore(
    useShallow(state => ({
      imagesWithLocation: state.imagesWithLocation,
      setParsedPhotos: state.setParsedPhotos,
      setProgressPercent: state.setProgressPercent,
      progressPercent: state.progressPercent,
      setRenderProgress: state.setRenderProgress,
      renderProgress: state.renderProgress,
    }))
  );
  const selectedAlbum = useMemo(
    () => photos.find(photo => photo.contentId === selectedSourceAlbum),
    [photos, selectedSourceAlbum]
  );
  const { setScreenId, setTakenAt } = useGetPhotoData({
    projectId,
    setPhotoStatus,
    setSourcePhotos,
  });
  const handleSetFromAlbum = () => {
    setFiles([]);
    setParsedPhotos([]);
    setRenderProgress(true);
    setFromAlbum(prev => {
      if (prev) {
        dispatch(setSourcePhotoContentId({ contentId: undefined }));
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (selectedAlbum?.screenId && selectedAlbum?.takenAt) {
      setScreenId(selectedAlbum.screenId);
      setTakenAt(selectedAlbum.takenAt);
    }
  }, [selectedAlbum]);

  useEffect(() => {
    if (sourcePhotos?.length) {
      toast.dismiss();
      toastIdRef.current = null;
      const albumParseData = sourcePhotos?.map(photo => ({
        id: photo.id,
        takenAt: photo.takenAt as Date,
        latitude: photo.latitude as number,
        longitude: photo.longitude as number,
        originalFilename: photo.originalFilename,
      }));
      setParsedPhotos(albumParseData);
    }
  }, [sourcePhotos?.length]);

  /** ----------End of album---------- */

  const isFileAttached: boolean = selectedSourceAlbum ? false : files.length !== 0;
  const extensionsText: string = useMemo(
    () => extensions.map(extension => extension.replace('.', '').toUpperCase()).join(', '),
    [extensions]
  );

  const getAcceptedFiles = useMemo(
    () => extensions.map(extension => `image/${extension.replace('.', '')}`),
    [extensions]
  );

  useEffect(() => {
    const areFilesOverLimit: boolean = Boolean(
      prevFiles && prevFiles.length < files.length && files.length > LIST_MAX_ITEM
    );
    if (areFilesOverLimit) {
      listRef?.current?.scrollTo(0, files.length * ITEM_HEIGHT);
    }
    if (imagesWithLocation.length !== files.length && files.length > 0) {
      void parseRawImage(files);
    }
    if (files.length === 0 && !selectedSourceAlbum) {
      setParsedPhotos([]);
    }
  }, [files.length, selectedSourceAlbum]);

  useEffect(() => {
    const { longitude, latitude } = imagesWithLocation[0] ?? {};
    if (longitude && latitude) {
      dispatch(ChangeTwoDDisplayCenter({ twoDDisplayCenter: [longitude, latitude] }));
    }
  }, [imagesWithLocation?.[0]?.id]); // Utilize primitive data type to avoid unnecessary rerenders

  const onAttach = (rawFiles: File[]) => {
    if (rawFiles?.length) {
      setFiles(prevState => {
        // Create a Set of existing file identifiers for quick lookup
        const existingFileSet = new Set(
          prevState.map(file => `${file.name}-${file.size}-${file.lastModified}`)
        );

        // Filter files based on extension and whether they are already attached
        const filteredFiles = rawFiles.filter(file => {
          const fileExtension = `.${file.type.split('/')[1]}`;
          const isValidExtension = extensions.includes(fileExtension);
          const fileIdentifier = `${file.name}-${file.size}-${file.lastModified}`;

          return isValidExtension && !existingFileSet.has(fileIdentifier);
        });

        // Return the updated file list
        return hasMultipleFiles ? [...prevState, ...filteredFiles] : filteredFiles;
      });
    }
  };

  useEffect(() => {
    if (progressPercent > 0 && progressPercent !== 100) {
      if (toastIdRef.current === null) {
        toastIdRef.current = toast(
          <DownloadProgressDisplay progress={progressPercent} progressType={ProgressType.PARSE} />,
          defaultToasterOptions
        );
      } else {
        toast.update(toastIdRef.current, {
          render: (
            <DownloadProgressDisplay progress={progressPercent} progressType={ProgressType.PARSE} />
          ),
          ...defaultToasterOptions,
        });
      }
    }
    if (progressPercent === 100) {
      toast.dismiss();
      setProgressPercent(0);
      setRenderProgress(false);
      toastIdRef.current = null;
    }
  }, [progressPercent, files.length]);

  useEffect(() => {
    if (selectedSourceAlbum && !sourcePhotos?.length) {
      if (toastIdRef.current === null) {
        toastIdRef.current = toast(
          <DownloadProgressDisplay progress={0} progressType={ProgressType.FETCH} />,
          defaultToasterOptions
        );
      } else {
        toast.update(toastIdRef.current, {
          render: <DownloadProgressDisplay progress={0} progressType={ProgressType.FETCH} />,
          ...defaultToasterOptions,
        });
      }
    }
  }, [selectedSourceAlbum, sourcePhotos?.length]);

  const onDeleteAllClick: (e: MouseEvent) => void = e => {
    e.preventDefault();
    setFiles([]);
  };

  const fileToItem: (file: File, idx: number) => ReactNode = (file, index) => {
    const onDeleteClick: () => void = () => {
      setFiles(prevState => _.filter(prevState, fileNeq(file)));
      setParsedPhotos(
        imagesWithLocation.filter(imageWithLoc => imageWithLoc.originalFilename !== file.name)
      );
    };

    return (
      <Item key={index}>
        <DeleteIcon onClick={onDeleteClick} />
        <ItemName>{file.name}</ItemName>
        <ItemSize>{formatValue(file.size, { unit: 'B', gap: 1024, digit: 2 })}</ItemSize>
      </Item>
    );
  };

  const handleOpenSelectPhotoModal: () => void = () => {
    if (fromAlbum) {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.SELECT_IMAGE,
        })
      );
    }
  };

  const select: ReactNode = fromAlbum && !selectedSourceAlbum && (
    <SelectPhotoFromAlbumSelector onClick={handleOpenSelectPhotoModal} />
  );

  const deleteAllIcon: ReactNode = isFileAttached ? (
    <WrapperHoverable title={l10n(Text.tooltipDeleteAll)} customStyle={tooltipCustomStyle}>
      <DeleteAllSvg onClick={onDeleteAllClick} />
    </WrapperHoverable>
  ) : undefined;

  const handleDelete = () => {
    dispatch(setSourcePhotoContentId({ contentId: undefined }));
  };

  const deleteIcon: ReactNode = selectedSourceAlbum && (
    <WrapperHoverable title={l10n(Text.changeAlbum)} customStyle={tooltipCustomStyle}>
      <DeleteAllSvg onClick={handleDelete} />
    </WrapperHoverable>
  );

  const fileInput: ReactNode = (
    <>
      <Label hasError={hasError}>
        {deleteAllIcon}
        {l10n(Text.totalSelected)}: {files.length}
      </Label>
      <List isOverwritingNeeded={isOverwritingNeeded} ref={listRef}>
        {files.map(fileToItem)}
      </List>
    </>
  );

  return (
    <>
      <TextLabel>{l10n(Text.attachFile)}</TextLabel>
      <FromAlbumWrapper>
        <SubTextLabel>{l10n(Text.fromAlbum)}</SubTextLabel>
        <ToggleSlider enabled={fromAlbum} onClick={handleSetFromAlbum} />
      </FromAlbumWrapper>
      {!fromAlbum && (
        <>
          <DragAndDropFileInput
            multiple={hasMultipleFiles}
            onDrop={onAttach}
            accept={getAcceptedFiles.join(', ')}
          />
          <Extension hasError={hasError}>
            {l10n(Text.fileExtensionNotification)}
            {extensionsText}
          </Extension>
        </>
      )}
      {select}
      {fromAlbum && selectedSourceAlbum && selectedAlbum && (
        <SelectedItemWrapper>
          <Wrapper hasError={false}>
            <DetailsWrapper>
              <SelectedText>{selectedAlbum.screenTitle}</SelectedText>
              {deleteIcon}
            </DetailsWrapper>
          </Wrapper>
          <PhotoItemContainer photo={selectedAlbum} isDisabled={true} />
        </SelectedItemWrapper>
      )}
      {isFileAttached && <Wrapper hasError={hasError}>{fileInput}</Wrapper>}
    </>
  );
};
