import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import GlobeIcon from '^/assets/icons/glob-icon.svg';
import TickMark from '^/assets/icons/tick-mark.svg';
import Chevron from '^/assets/icons/contents-list/chevron.svg';
import { useClickOutside, useL10n, UseL10n } from '^/hooks';
import { useDispatch } from 'react-redux';
import { ChangeLanguage } from '^/store/duck/Pages';
import * as T from '^/types';
import { languageTypeToLanguageText } from '^/components/molecules/UserInfoDropdownFields';
import colorTokens from '^/theme/colors/color-tokens';

const DropdownContainer = styled.div`
  gap: 8px;
  padding: 8px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  font-family: Arial, sans-serif;
`;

const SelectedLanguage = styled.span`
  font-size: 14px;
  color: ${colorTokens.textGray};
`;

const ChevronIcon = styled(Chevron)<{ isOpen: boolean }>`
  font-size: 20px;
  margin-top: 2px;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownMenu = styled.ul`
  left: 0;
  top: 40px;
  padding: 8px;
  width: 120px;
  list-style: none;
  background: white;
  position: absolute;
  border-radius: 8px;
  border: 1px solid ${colorTokens.lineCoolLight};
`;

interface DropdownItemProps {
  isLast?: boolean;
  isFirst?: boolean;
}

const DropdownItem = styled.li<DropdownItemProps>`
  gap: 10px;
  display: flex;
  padding: 10px;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  &:hover {
    background: ${colorTokens.lineCoolLight};
  }
`;

const LanguageDropdown: React.FC = () => {
  const menusRef = useRef(null);
  const dispatch = useDispatch();
  const [, language]: UseL10n = useL10n();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useClickOutside({
    ref: menusRef,
    callback: () => {
      if (menusRef.current) {
        setIsOpen(false);
      }
    },
  });

  const changeLanguage = (changedLanguage: T.Language) => {
    setIsOpen(false);
    dispatch(ChangeLanguage({ language: changedLanguage }));
  };

  const languageList = [
    { id: T.Language.EN_US, label: languageTypeToLanguageText[T.Language.EN_US] },
    { id: T.Language.KO_KR, label: languageTypeToLanguageText[T.Language.KO_KR] },
  ];

  return (
    <DropdownContainer onClick={toggleDropdown}>
      <GlobeIcon />
      <SelectedLanguage>{languageTypeToLanguageText[language]}</SelectedLanguage>
      <ChevronIcon isOpen={isOpen} />
      {isOpen && (
        <DropdownMenu ref={menusRef}>
          {languageList.map((languageOption, index) => {
            const isFirst = index === 0;
            const isLast = languageList.length - 1 === index;
            const isActive = languageOption.id === language;

            return (
              <DropdownItem
                isLast={isLast}
                isFirst={isFirst}
                key={languageOption.id}
                onClick={() => changeLanguage(languageOption.id)}
              >
                {languageOption.label}
                {isActive && <TickMark />}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default LanguageDropdown;
