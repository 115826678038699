import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';

import CopySvg from '^/assets/icons/copy.svg';
import palette from '^/constants/palette';
import { typeGuardESSContent } from '^/hooks';
import { useESSContents } from '^/hooks/useESSContents';
import { useESSContentsStore } from '^/store/essContentsStore';
import * as T from '^/types';
import { getCopiedContentTitle } from '^/utilities/annotation-content-util';
import { getContentTitlesByType } from '^/utilities/content-util';

export interface Props {
  readonly content: T.Content;
  readonly isDisabled: boolean;
}

const CopySvgWrapper = styled.div<Pick<Props, 'isDisabled'>>(({ isDisabled }) => ({
  '& > svg > path': {
    fill: isDisabled
      ? palette.iconDisabled.toString()
      : palette.ContentsList.balloonHeaderIconGray.toString(),
  },
  '& > svg > rect': {
    stroke: isDisabled
      ? palette.iconDisabled.toString()
      : palette.ContentsList.balloonHeaderIconGray.toString(),
  },
}));
CopySvgWrapper.displayName = 'CopySvgWrapper';

const CopyIcon = styled(CopySvg)({
  width: '13.5px',
  height: '13.5px',
});

const CopyContentButton: FC<Props> = ({ content, isDisabled }) => {
  const { duplicateESSContent } = useESSContents();
  const { editingESSContentId, essContents } = useESSContentsStore(s => ({
    editingESSContentId: s.editingESSContentId,
    essContents: s.essContents,
  }));

  const handleCopyContent: (e: MouseEvent<HTMLOrSVGElement>) => void = e => {
    if (isDisabled || content.id !== editingESSContentId) {
      return;
    }
    e.stopPropagation();

    const ESSContent: T.ESSContent | undefined = typeGuardESSContent(content);

    if (ESSContent === undefined) {
      return;
    }
    const allContentTitlesWithSameType = getContentTitlesByType(essContents, ESSContent.type);
    const copiedContent = {
      ...ESSContent,
      title: getCopiedContentTitle(ESSContent.title, allContentTitlesWithSameType),
    };
    void duplicateESSContent({ content: copiedContent });
  };

  return (
    <CopySvgWrapper isDisabled={isDisabled}>
      <CopyIcon data-testid="copy-svg-button" onClick={handleCopyContent} />
    </CopySvgWrapper>
  );
};

export default CopyContentButton;
