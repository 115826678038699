import * as Sentry from '@sentry/browser';
import { Overlay } from 'ol';
import OlMap from 'ol/Map';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent, getCenter } from 'ol/extent';
import { LineString } from 'ol/geom';
import Interaction from 'ol/interaction/Interaction';
import Modify from 'ol/interaction/Modify';
import { fromLonLat } from 'ol/proj';
import { Observable, forkJoin, of } from 'rxjs';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { INVALID, OlCustomPropertyNames, SINGLE_LENGTH_SEGMENT_OVERLAY_OFFSET } from '../constants';
import {
  createGeometryFromLocations,
  getImperialMeasurementFromGeometry,
  getImperialMeasurementUnitFromGeometryType,
  getMeasurementFromGeometry,
  getMeasurementUnitFromGeometryType,
} from '../contentTypeSwitch';
import {
  AuthHeader,
  jsonContentHeader,
  makeAuthHeader,
  volumeServiceHostname,
} from '^/store/duck/API';
import * as T from '^/types';
import { isErrorIgnorable } from '^/utilities/http-response';
import { calcSlopeOfLength } from '^/utilities/math';

export interface CoordinateAndElevation {
  coordinate: Coordinate;
  elevation?: T.ElevationInfo['value'];
}

export function makeLengthSegmentOverlayId(idPostfix: string | number): string {
  return `${OlCustomPropertyNames.LENGTH_SEGMENT}${idPostfix}`;
}

export function setLengthSegmentOverlayId(overlay: Overlay, idPostfix: string | number): Overlay {
  overlay.set('id', makeLengthSegmentOverlayId(idPostfix));

  return overlay;
}

function getOverlaysArrayFromMap(olMap: OlMap): Overlay[] {
  return olMap.getOverlays().getArray();
}

function filterLengthSegmentOverlay(overlay: Overlay): boolean {
  return overlay?.get('id')?.toString().startsWith(OlCustomPropertyNames.LENGTH_SEGMENT);
}

export function getLengthSegmentOverlayById(
  olMap: OlMap,
  id: string | number
): Overlay | undefined {
  return getOverlaysArrayFromMap(olMap).find(o => o.get('id') === makeLengthSegmentOverlayId(id));
}

export function deleteAllLengthSegmentOverlays(olMap: OlMap): void {
  getOverlaysArrayFromMap(olMap)
    .filter(filterLengthSegmentOverlay)
    .forEach(overlay => olMap.removeOverlay(overlay));
}

export function hasLengthSegmentOverlays(olMap: OlMap): boolean {
  return getOverlaysArrayFromMap(olMap).some(filterLengthSegmentOverlay);
}

/**
 * Get measurement from geometry
 *
 * @param pairOfCoordinates Pair of coordinates [Coordinate, Coordinate]
 * @param geometryType Geometry type T.GeometryContent['type']
 * @param unitType Unit type T.UnitType
 * @returns Measurement with value and unit
 */
export function getMeasurementGeometry({
  pairOfCoordinates,
  geometryType,
  unitType,
}: {
  pairOfCoordinates: [Coordinate, Coordinate];
  geometryType: T.GeometryContent['type'];
  unitType: T.UnitType;
}) {
  const singleSegment: LineString = createGeometryFromLocations({
    locations: pairOfCoordinates,
    geometryType,
  }) as LineString;

  switch (unitType) {
    case T.UnitType.IMPERIAL:
      return {
        value: Number(
          getImperialMeasurementFromGeometry({
            geometry: singleSegment,
            geometryType,
          })
        ),
        unit: getImperialMeasurementUnitFromGeometryType({ geometryType }),
      };
    case T.UnitType.METRIC:
    default:
      return {
        value: Number(
          getMeasurementFromGeometry({
            geometry: singleSegment,
            geometryType,
          })
        ),
        unit: getMeasurementUnitFromGeometryType({ geometryType }),
      };
  }
}

export function makeLengthSegmentOverlay({
  coordinate0,
  coordinate1,
  idPostfix,
  customOffset,
  zoomLevel,
}: {
  coordinate0: Coordinate;
  coordinate1: Coordinate;
  zoomLevel?: number;
  /**
   * indicates index from coordinates array
   */
  idPostfix: number | string;
  customOffset?: [number, number];
}): Overlay {
  const div: HTMLDivElement = document.createElement('div');
  if (zoomLevel) {
    div.style.transform = `scale(${calculateScale(zoomLevel)})`;
  }

  const overlay: Overlay = new Overlay({
    position: getCenter(boundingExtent([coordinate0, coordinate1].map(c => fromLonLat(c)))),
    element: div,
    className: `${OlCustomPropertyNames.OL_REALTIME_MEASUREMENT_TOOLTIP_LENGTH_CLASSNAME} ${OlCustomPropertyNames.OL_LOADING_TOOLTIP_SMALL}`,
    offset: customOffset === undefined ? [7.5, 7.5] : customOffset,
    stopEvent: false,
  });
  /**
   * id starts from 0
   */
  setLengthSegmentOverlayId(overlay, idPostfix);
  const measurementElement: HTMLParagraphElement = document.createElement('p');
  measurementElement.className = OlCustomPropertyNames.OL_LENGTH_SEGMENT_MEASUREMENT;
  const slopeElement: HTMLParagraphElement = document.createElement('p');
  slopeElement.className = OlCustomPropertyNames.OL_LENGTH_SEGMENT_SLOPE;
  div.appendChild(measurementElement);
  div.appendChild(slopeElement);

  const { value, unit } = getMeasurementGeometry({
    pairOfCoordinates: [coordinate0, coordinate1],
    geometryType: T.ContentType.LENGTH,
    unitType: T.UnitType.METRIC,
  });

  updateMeasurementOnLengthSegmentOverlay({
    overlay,
    value,
    unit,
  });
  updateSlopeOnLengthSegmentOverlay({
    overlay,
    customText: '- %',
  });

  return overlay;
}

export function makeImperialLengthSegmentOverlay({
  coordinate0,
  coordinate1,
  idPostfix,
  customOffset,
}: {
  coordinate0: Coordinate;
  coordinate1: Coordinate;
  /**
   * indicates index from coordinates array
   */
  idPostfix: number | string;
  customOffset?: [number, number];
}): Overlay {
  const div: HTMLDivElement = document.createElement('div');
  const overlay: Overlay = new Overlay({
    position: getCenter(boundingExtent([coordinate0, coordinate1].map(c => fromLonLat(c)))),
    element: div,
    className: `${OlCustomPropertyNames.OL_REALTIME_MEASUREMENT_TOOLTIP_LENGTH_CLASSNAME} ${OlCustomPropertyNames.OL_LOADING_TOOLTIP_SMALL}`,
    offset: customOffset === undefined ? [7.5, 7.5] : customOffset,
    stopEvent: false,
  });
  /**
   * id starts from 0
   */
  setLengthSegmentOverlayId(overlay, idPostfix);
  const measurementElement: HTMLParagraphElement = document.createElement('p');
  measurementElement.className = OlCustomPropertyNames.OL_LENGTH_SEGMENT_MEASUREMENT;
  const slopeElement: HTMLParagraphElement = document.createElement('p');
  slopeElement.className = OlCustomPropertyNames.OL_LENGTH_SEGMENT_SLOPE;
  div.appendChild(measurementElement);
  div.appendChild(slopeElement);

  const { value, unit } = getMeasurementGeometry({
    pairOfCoordinates: [coordinate0, coordinate1],
    geometryType: T.ContentType.LENGTH,
    unitType: T.UnitType.IMPERIAL,
  });

  updateMeasurementOnLengthSegmentOverlay({
    overlay,
    value,
    unit,
  });
  updateSlopeOnLengthSegmentOverlay({
    overlay,
    customText: '- %',
  });

  return overlay;
}

// eslint-disable-next-line @typescript-eslint/promise-function-async
export function requestElevationsFromCoordinates({
  coordinates,
  targetDSMId,
  authHeader,
}: {
  coordinates: Coordinate[];
  targetDSMId?: T.DSMContent['id'];
  authHeader?: AuthHeader;
}): Promise<Array<T.ElevationInfo['value']>> {
  if (targetDSMId === undefined || authHeader === undefined) {
    return new Promise(resolve => resolve(new Array(coordinates.length).fill(0)));
  }

  const elevationsArr: Array<Observable<T.ElevationInfo['value']>> = [];
  for (const [lon, lat] of coordinates) {
    const ob$: Observable<T.ElevationInfo['value']> = ajax
      .get(`https://${volumeServiceHostname}/elev/${targetDSMId}?lon=${lon}&lat=${lat}`, authHeader)
      .pipe(
        map(({ response }): T.ElevationInfo => response),
        map(({ value }) => value),
        publishLast(),
        refCount(),
        catchError((err: AjaxError) => {
          if (!isErrorIgnorable(err.status)) {
            Sentry.captureException(err);
          }

          return new Array(coordinates.length).fill(0);
        })
      );
    elevationsArr.push(ob$);
  }

  return forkJoin<Array<Observable<T.ElevationInfo['value']>>>(elevationsArr).toPromise();
}

export async function requestTriangulationFromCoordinates({
  targetDSMId,
  coordinates,
  authState,
  planConfig,
}: {
  coordinates: number[];
  targetDSMId: T.DSMContent['id'];
  authState: T.AuthState;
  planConfig: T.PlanConfig;
}): Promise<T.TriangulationInfo['value'] | null> {
  if (authState === undefined || targetDSMId === undefined || planConfig === undefined) {
    return Promise.resolve(null);
  }
  const auth: AuthHeader | undefined = makeAuthHeader(authState, planConfig?.slug);
  return (
    ajax
      .post(`https://${volumeServiceHostname}/triangulation/${targetDSMId}`, coordinates, {
        ...auth,
        ...jsonContentHeader,
      })
      // .post(`http://localhost:5000/triangulation/${targetDSMId}`, coordinates, {
      //   ...auth,
      //   ...jsonContentHeader,
      // })
      .pipe(
        map(({ response }): T.TriangulationInfo => response),
        map(({ value }) => value),
        catchError((err: AjaxError) => {
          if (!isErrorIgnorable(err.status)) {
            Sentry.captureException(err);
          }

          return of(null);
        })
      )
      .toPromise()
  );
}

export function makeManyMeasurementOverlays({
  lengthCoordinatesElevations,
  olMap,
  unitType,
  zoomLevel,
}: {
  lengthCoordinatesElevations: CoordinateAndElevation[];
  olMap: OlMap;
  zoomLevel?: number;
  unitType: T.UnitType;
}): void {
  for (const [index, { elevation, coordinate }] of lengthCoordinatesElevations.entries()) {
    /**
     * Length of overlays = lengthCoordinatesElevations.length - 1
     */
    if (index === lengthCoordinatesElevations.length - 1) {
      continue;
    }

    if (lengthCoordinatesElevations[index + 1].coordinate === undefined) {
      return;
    }

    const anyExistingOverlay: Overlay | undefined = getLengthSegmentOverlayById(olMap, index);
    if (anyExistingOverlay) {
      olMap.removeOverlay(anyExistingOverlay);
    }

    const overlay: Overlay =
      unitType === T.UnitType.IMPERIAL
        ? makeImperialLengthSegmentOverlay({
            coordinate0: coordinate,
            coordinate1: lengthCoordinatesElevations[index + 1].coordinate,
            idPostfix: index,
            customOffset:
              lengthCoordinatesElevations.length === 2
                ? SINGLE_LENGTH_SEGMENT_OVERLAY_OFFSET
                : undefined,
          })
        : makeLengthSegmentOverlay({
            coordinate0: coordinate,
            coordinate1: lengthCoordinatesElevations[index + 1].coordinate,
            idPostfix: index,
            zoomLevel,
            customOffset:
              lengthCoordinatesElevations.length === 2
                ? SINGLE_LENGTH_SEGMENT_OVERLAY_OFFSET
                : undefined,
          });

    updateSlopeOnLengthSegmentOverlay({
      overlay,
      pairOfCoordinateAndElevations: [
        { elevation, coordinate },
        lengthCoordinatesElevations[index + 1],
      ],
      length: getMeasurementGeometry({
        pairOfCoordinates: [coordinate, lengthCoordinatesElevations[index + 1].coordinate],
        geometryType: T.ContentType.LENGTH,
        unitType: T.UnitType.METRIC,
      }).value,
    });
    olMap.addOverlay(overlay);
  }
}

export function updateMeasurementOnLengthSegmentOverlay({
  overlay,
  value,
  unit,
}: {
  overlay: Overlay;
  value: number | string;
  unit: string;
}): Overlay {
  const measurementHTMLElement: Element = (
    (overlay as any).element as HTMLElement
  ).getElementsByClassName(OlCustomPropertyNames.OL_LENGTH_SEGMENT_MEASUREMENT)[0];

  measurementHTMLElement.textContent = `${value}${unit},`;

  return overlay;
}

interface UpdateOverlayParams {
  overlay: Overlay;
  pairOfCoordinateAndElevations?: CoordinateAndElevation[];
  length?: number;
  customText?: string;
}

export function updateSlopeOnLengthSegmentOverlay({
  overlay,
  pairOfCoordinateAndElevations,
  length,
  customText,
}: UpdateOverlayParams): Overlay {
  const slopeHTMLElement: Element = (
    (overlay as any).element as HTMLElement
  ).getElementsByClassName(OlCustomPropertyNames.OL_LENGTH_SEGMENT_SLOPE)[0];

  if (customText !== undefined) {
    slopeHTMLElement.textContent = customText;

    return overlay;
  }
  if (pairOfCoordinateAndElevations !== undefined && length !== undefined) {
    const slope: string = calcSlopeOfLength(pairOfCoordinateAndElevations, length);
    slopeHTMLElement.textContent = `${slope === INVALID ? '-' : slope}%`;

    return overlay;
  }

  return overlay;
}

export function isMapModifying(olMap: OlMap): boolean {
  return olMap
    .getInteractions()
    .getArray()
    .some(interaction => interaction instanceof Modify);
}

export function getInteraction(
  olMap: OlMap,
  I: new (...params: any) => Interaction
): Interaction | undefined {
  return olMap
    .getInteractions()
    .getArray()
    .find(i => i instanceof I);
}

/**
 * Function to calculate the scale based on zoomLevel.
 *
 * @param {number} zoomLevel - The current zoom level.
 * @returns {number} - The calculated scale value.
 */
export function calculateScale(zoomLevel: number) {
  const scaleValue = zoomLevel / 21;
  return Math.max(0.8, Math.min(1, scaleValue));
}
