import { Language } from '^/types';

export default {
  tooltipSelectAllText: {
    [Language.KO_KR]: '모두 선택',
    [Language.EN_US]: 'Select All',
  },
  tooltipUnselectAllText: {
    [Language.KO_KR]: '모두 해제',
    [Language.EN_US]: 'Unselect All',
  },
  tooltipPinText: {
    [Language.KO_KR]: '모든 지도에 보이게 하기',
    [Language.EN_US]: 'Click to show in all maps',
  },
  tooltipUnpinText: {
    [Language.KO_KR]: '현재 지도에서만 보이게 하기',
    [Language.EN_US]: 'Click to only show in current map',
  },
  tooltipExpandText: {
    [Language.KO_KR]: '그룹 펼치기',
    [Language.EN_US]: 'Expand group',
  },
  tooltipCollapseText: {
    [Language.KO_KR]: '그룹 접기',
    [Language.EN_US]: 'Collapse group',
  },
};
