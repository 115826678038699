/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-lines */
import { ellipsoidToEgm96 } from 'egm96-universal';
import {
  BoxGeometry,
  Color,
  DoubleSide,
  Euler,
  Group,
  Material,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Raycaster,
  Sprite,
  SpriteMaterial,
  Texture,
  TextureLoader,
  Vector3,
} from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
//@ts-ignore
import { Runtime } from 'three-loader-3dtiles';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import { DisposeHelper } from '../../Lib/Helper';
import { LabelObject } from '../Label';
import { autobind } from 'core-decorators';
import { setupPictureFromCamera } from '../../Lib/Utils//TilesetUtils';
import { closeIcon } from '^/assets/icons/imageBase64';
import { OBB } from 'three/examples/jsm/math/OBB';
import { ThreeCameraHelper, ThreeCameraHelper2 } from '../Camera';
import { MERGE_MATERIAL } from '../../Lib/constant';
import { IssuePhotoObject } from '../Issue';
import { IssuePhotoEntity } from '../../Lib/Entities';
import * as T from '^/types';

export interface GeoData {
  longitude: number;
  latitude: number;
  altitude: number;
  gimbalYawDegree: number;
  gimbalPitchDegree: number;
  gimbalRollDegree: number;
  width: number;
  height: number;
  focalLength: number;
  relativeAltitude: number;
  calibratedFocalLength: number;
  absoluteAltitude: number;
  sphRecalibrated: boolean;
  make: string;
}
interface ProcessData {
  main: Mesh;
  border: Mesh;
  position: Vector3;
  rotation: Euler;
  camera: PerspectiveCamera;
}
interface ProcessData {
  main: Mesh;
  border: Mesh;
  position: Vector3;
  rotation: Euler;
  camera: PerspectiveCamera;
}
export enum TYPE {
  PROGRESS = 'progress',
  ICON = 'icon',
  HIGHTLIGHT_ICON = 'highlight_icon',
}
const HIGHLIGHT_COLOR = new Color();
const TEXTURE_LOADER = new TextureLoader();
const SPRITE_MATERIAL = new SpriteMaterial({
  // sizeAttenuation: false,
  map: TEXTURE_LOADER.setCrossOrigin('anonymous').load(closeIcon),
  depthTest: false,
});

TEXTURE_LOADER.setCrossOrigin('use-credentials');
const DRONE_CAMERA = new PerspectiveCamera(
  45,
  1.5,
  5,
  7
  // geoData.focalLength / 1.5,
  // geoData.focalLength
);
const raycaster = new Raycaster();
export class PhotoAlbumObject extends Object3D {
  public calcDroneCamera: PerspectiveCamera | null = null;
  public tempCamera: OrthographicCamera | null = null;
  public droneHelper: ThreeCameraHelper | ThreeCameraHelper2;

  public photoPlane: Mesh | null = null;
  public group: Group;
  public label: LabelObject | null = null;
  public altitude: number = 0;
  public focalLength: number = 0;
  public readonly contentId: string | number;
  public readonly contentType: T.PhotoType | undefined;
  private readonly tilesRuntime: Runtime;
  private readonly photoUrl: string;
  private readonly thumbUrl: string;
  private imageTexture: Texture | null = null;
  private readonly viewer: Viewer;
  private readonly cameraHelperMaterialColor: number;
  public scaleMainPlane: number[] = [1, 1];
  private scaleBorderPlane: number[] = [1, 1];
  public spriteIcon: Sprite | undefined;
  public cube: Mesh | undefined;
  public isHighResolution: boolean = false;
  /** 
  This space used for calculate camera
  */
  private readonly localSpace: Object3D | undefined;

  public baseSize: number = 0.75;
  public aspect = 1;
  public constructor(
    contentId: string | number,
    viewer: Viewer,
    photoUrl: string,
    thumbUrl: string,
    geoData: GeoData,
    tilesRuntime: Runtime,
    contentType: T.PhotoType
  ) {
    super();
    geoData.gimbalPitchDegree =
      geoData.make === 'SONY'
        ? geoData.gimbalPitchDegree - 90
        : geoData.sphRecalibrated
        ? geoData.gimbalPitchDegree - 90
        : geoData.gimbalPitchDegree;

    // geoData.gimbalYawDegree = 267.185;
    // geoData.gimbalPitchDegree = -0.882 - 90;
    // geoData.gimbalRollDegree = -0.377;
    this.name = contentId.toString();
    this.contentId = contentId;
    this.contentType = contentType;
    this.viewer = viewer;
    this.photoUrl = photoUrl;
    this.thumbUrl = thumbUrl;
    this.altitude =
      contentType === 'viewpoint'
        ? geoData.absoluteAltitude
        : geoData.sphRecalibrated
        ? geoData.altitude
        : Boolean(this.viewer.metadata && this.viewer.metadata.totalErrorAlt)
        ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          geoData.absoluteAltitude + this.viewer.metadata!.totalErrorAlt!
        : !this.viewer.metadata?.hasGCP
        ? geoData.absoluteAltitude
        : ellipsoidToEgm96(geoData.latitude, geoData.longitude, 0) + geoData.absoluteAltitude;
    // Math.max(geoData.relativeAltitude, geoData.absoluteAltitude);
    this.focalLength = geoData.focalLength;
    this.group = new Group();
    this.group.visible = false;
    this.renderOrder = 10;
    // const fov = radToDeg(2 * Math.atan(geoData.width / 2 / geoData.calibratedFocalLength));
    this.droneHelper = new ThreeCameraHelper2(DRONE_CAMERA.clone(), viewer);
    this.tilesRuntime = tilesRuntime;
    this.add(this.droneHelper);
    this.initPosition(geoData);
    //@ts-ignore
    this.cameraHelperMaterialColor = 0x156289;
  }
  /**
   * @private
   * @description Funtion init position for photo album
   * @param {GeoData} geoData
   * @returns {void}
   */
  @autobind
  private initPosition(geoData: GeoData): void {
    const { main, border, position, rotation, camera } = this.processGeoData(
      geoData,
      this.altitude
    );
    this.position.copy(position);
    this.rotation.copy(rotation);
    this.calcDroneCamera = camera;

    main.renderOrder = 8;
    border.renderOrder = 8;
    (main.material as Material).depthTest = false;
    (border.material as Material).depthTest = false;
    this.photoPlane = main;
    this.scaleMainPlane = [main.scale.x, main.scale.y];
    this.scaleBorderPlane = [border.scale.x, border.scale.y];
    this.group.add(main, border);
    this.droneHelper.add(this.group);

    this.spriteIcon = new Sprite(SPRITE_MATERIAL);
    const spriteScale = geoData.focalLength / 12.28;
    this.spriteIcon.scale.set(spriteScale, spriteScale, spriteScale);
    this.spriteIcon.renderOrder = 9;
    this.spriteIcon.visible = false;
    this.spriteIcon.name = 'close';
    this.add(this.spriteIcon);

    const size = 1;
    const geometry = new BoxGeometry(size, size, size);

    // setup OBB on geometry level (doing this manually for now)

    geometry.userData.obb = new OBB();
    geometry.userData.obb.halfSize.copy(new Vector3(size, size, size)).multiplyScalar(0.5);

    this.cube = new Mesh(geometry, MERGE_MATERIAL);
    this.cube.visible = false;
    this.cube.matrixAutoUpdate = false;
    this.add(this.cube);

    // bounding volume on object level (this will reflect the current world transform)
    this.cube.userData.obb = new OBB();

    this.updateMatrixWorld(true);

    this.cube.userData.obb.copy(this.cube.geometry.userData.obb);
    this.cube.userData.obb.applyMatrix4(this.cube.matrixWorld);
    // this.matrixWorldNeedsUpdate = true;
    // this.updateMatrix();
    // this.updateMatrixWorld();
    // this.updateWorldMatrix(false, true);
    this.matrixAutoUpdate = false;
    this.matrixWorldAutoUpdate = false;
  }
  /**
   * @private
   * @description process geo data
   * @param {GeoData} geoData
   * @returns {void}
   */
  @autobind
  private processGeoData(geoData: GeoData, altitude: number): ProcessData {
    const position = this.tilesRuntime.getPositionFromLatLongHeight({
      long: geoData.longitude,
      lat: geoData.latitude,
      height: altitude,
    });

    const headingRad = degToRad(-geoData.gimbalYawDegree);
    const pitchRad = degToRad(geoData.gimbalPitchDegree);
    const rollRad = degToRad(geoData.gimbalRollDegree);
    const rotation = new Euler();
    rotation.set(pitchRad + degToRad(90), rollRad, headingRad, 'ZYX');
    let focalLength = 10.0;
    {
      //TODO: this part from rearech team
      if (geoData.focalLength > 10.0) {
        focalLength = geoData.height * (Math.round(geoData.focalLength - 1) / geoData.focalLength);
      } else {
        focalLength = geoData.height;
      }
    }

    const fov = radToDeg(2 * Math.atan(geoData.height / 2 / focalLength));
    const camera =
      this.calcDroneCamera ?? new PerspectiveCamera(fov, geoData.width / geoData.height, 1, 10 * 2);
    camera.up.set(0, 0, 1);
    camera.updateProjectionMatrix();
    camera.rotation.set(pitchRad + degToRad(90), rollRad, headingRad, 'ZYX');
    const { main, border }: any = setupPictureFromCamera(
      camera,
      10,
      this.group.children.length > 0
        ? { main: this.group.children[0] as Mesh, border: this.group.children[1] as Mesh }
        : undefined
    );
    return { main, border, position, rotation, camera };
  }
  /**
   * @public
   * @description set photo texture for the image plane
   * @returns {void}
   */
  @autobind
  public setTexture(): void {
    if (!this.imageTexture && this.photoUrl) {
      // this.label = new LabelObject(this.viewer, {
      //   center: true,
      //   sprite: true,
      //   transform: false,
      // });
      // this.label.setInnerHTHML(content(0, TYPE.PROGRESS));
      // this.add(this.label);

      TEXTURE_LOADER.load(
        this.thumbUrl,
        (texture: Texture) => {
          if (!this.isHighResolution) {
            this.imageTexture = texture;
            texture.colorSpace = 'srgb';
            this.photoPlane!.material = new MeshBasicMaterial({
              map: texture,
              side: DoubleSide,
              transparent: true,
              depthTest: false,
            });
          }
          this.loadHighResTexture();
          // this.label!.setInnerHTHML(content(100, TYPE.PROGRESS));
          // this.remove(this.label!);
          // this.label!.dispose();
          // this.label = null;
        },
        () => {
          // this.label!.setInnerHTHML(content((e.loaded / e.total) * 100, TYPE.PROGRESS));
        },
        () => {
          // this.remove(this.label!);
          // this.label!.dispose();
        }
      );
    }
  }
  private loadHighResTexture() {
    this.imageTexture?.dispose();
    TEXTURE_LOADER.load(
      this.photoUrl,
      (texture: Texture) => {
        this.isHighResolution = true;
        this.imageTexture = texture;
        texture.colorSpace = 'srgb';
        this.photoPlane!.material = new MeshBasicMaterial({
          map: texture,
          side: DoubleSide,
          transparent: true,
          depthTest: false,
        });
        // this.label!.setInnerHTHML(content(100, TYPE.PROGRESS));
        // this.remove(this.label!);
        // this.label!.dispose();
        // this.label = null;
      },
      () => {
        // this.label!.setInnerHTHML(content((e.loaded / e.total) * 100, TYPE.PROGRESS));
      },
      () => {
        // this.remove(this.label!);
        // this.label!.dispose();
      }
    );
  }
  /**
   * @public
   * @description creat close icon for photo plane
   * @returns {void}
   */
  @autobind
  public createCloseIcon(): void {
    if (!this.photoPlane || !this.spriteIcon) {
      return;
    }
    const videoAspectRatio = this.photoPlane.scale.x / this.photoPlane.scale.y;
    const iconSize = Math.min(this.photoPlane.scale.x, this.photoPlane.scale.y) * 0.1; // 10% of the smaller dimension

    this.spriteIcon.position.x = this.photoPlane.scale.x / 2;
    this.spriteIcon.position.y = this.photoPlane.scale.y / 2;
    this.spriteIcon.position.z = this.photoPlane.position.z + 0.1;
    if (videoAspectRatio > 1) {
      // Landscape video
      this.spriteIcon.scale.set(iconSize / videoAspectRatio, iconSize / videoAspectRatio, 1);
    } else {
      // Portrait or square video
      this.spriteIcon.scale.set(iconSize, iconSize, 1);
    }

    this.group.add(this.spriteIcon);
    this.spriteIcon.visible = true;

    // this.spriteIcon!.position.x = this.photoPlane!.scale.x / 2;
    // this.spriteIcon!.position.y = this.photoPlane!.scale.y / 2;
    // this.spriteIcon!.position.z = this.photoPlane!.position.z + 0.1;

    // const position = new Vector3();
    // this.spriteIcon!.getWorldPosition(position);
    // this.spriteIcon!.position.copy(position);
    // this.viewer.scene.add(this.spriteIcon!);
    // this.spriteIcon!.visible = true;
  }
  /**
   * @public
   * @description toggle plane image
   * @param {number} color
   * @returns {void}
   */
  @autobind
  public togglePlaneImage(visible: boolean): void {
    //@ts-ignore
    this.droneHelper.isLine =
      this.viewer.sidebarTab !== T.ContentPageTabType.PHOTO ? false : !visible;
    //@ts-ignore
    this.droneHelper.isLineSegments =
      this.viewer.sidebarTab !== T.ContentPageTabType.PHOTO ? false : !visible;
    this.droneHelper.visible = true;
    this.group.visible = visible;
    const photoMain = this.group.children[0] as Mesh;
    const photoBorder = this.group.children[1] as Mesh;
    (photoMain.material as MeshBasicMaterial).opacity = 1;
    (photoBorder.material as MeshBasicMaterial).opacity = 1;
    if (!visible) {
      this.spriteIcon!.visible = false;
      photoMain.scale.set(this.scaleMainPlane[0], this.scaleMainPlane[1], 1);
      photoBorder.scale.set(this.scaleBorderPlane[0], this.scaleBorderPlane[1], 1);
      this.add(this.spriteIcon!);
    }
  }
  /**
   * @public
   * @description set highlight close icon
   * @param {number} color
   */
  @autobind
  public setHighlightCloseIcon(color: number = 0xefeff3): void {
    //@ts-ignore
    this.spriteIcon?.material.color.set(color);
  }
  /**
   * @public
   * @description set un highlight camera helper
   */
  @autobind
  public setUnHighlightCloseIcon(): void {
    //@ts-ignore
    this.spriteIcon?.material.color.set(0xffffff);
  }
  /**
   * @public
   * @description set visivibe
   */
  @autobind
  public setVisible(visible: boolean): void {
    this.visible = visible;
    // this.surfaceArea.visible = visible;
    // this.pointArea.visible = visible;
    // if (this.label) {
    //   this.label.labelVisible = visible;
    // }
    this.droneHelper.visible = visible;
  }
  /**
   * @public
   * @description set highlight camera helper
   * @param {number} color
   * @returns {void}
   */
  @autobind
  public setHighlight(color: number = 0xff0000): void {
    HIGHLIGHT_COLOR.set(color);
    //@ts-ignore
    this.droneHelper.material.color.set(HIGHLIGHT_COLOR);
    // this.droneHelper?.setColors(HIGHLIGHT_COLOR, HIGHLIGHT_COLOR);
  }
  /**
   * @public
   * @description set un highlight camera helper
   */
  @autobind
  public setUnHighlight(): void {
    HIGHLIGHT_COLOR.set(this.cameraHelperMaterialColor);
    //@ts-ignore
    this.droneHelper.material.color.set(HIGHLIGHT_COLOR);
    // this.droneHelper?.setColors(HIGHLIGHT_COLOR, HIGHLIGHT_COLOR);
  }
  /**
   * @public
   * @description This function will auto-call when the scene render
   * @param {boolean | undefined} force
   */
  @autobind
  public updateMatrixWorld(force?: boolean | undefined) {
    super.updateMatrixWorld(force);
    if (this.viewer.frustum.containsPoint(this.position)) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }
  /**
   * @public
   * @description dispose all
   * @returns { void}
   */
  @autobind
  public dispose(): void {
    if (this.localSpace) {
      DisposeHelper.disposeHierarchy(this.localSpace);
    }
    DisposeHelper.disposeHierarchy(this.group);
    DisposeHelper.disposeHierarchy(this);
  }

  /**
   * @public
   * @description set distance from camera
   * @returns { void}
   */
  @autobind
  public setDistanceFromCamera(camera: PerspectiveCamera) {
    const s = this.baseSize;
    const o = camera.fov;
    const w = camera.aspect;
    this.scalePlaneFromAspect(w);
    const G = (2 * Math.atan(Math.tan(degToRad(o) / 2) * w) * 180) / Math.PI,
      k = (0.5 * s) / Math.tan(degToRad(0.5 * G));
    this.position.copy(camera.position);
    this.position.add(camera.getWorldDirection(new Vector3()).multiplyScalar(k));
  }
  /**
   * @public
   * @description scale plane from aspect
   * @returns { void}
   */
  @autobind
  public scalePlaneFromAspect(aspect: number) {
    if (aspect !== this.aspect) {
      this.aspect = aspect;
    }
    this.scale.set(this.baseSize, this.baseSize, this.baseSize);
    if (aspect > 1) {
      this.scale.setY(this.scale.y * (1 / aspect));
    } else {
      this.scale.setX(this.scale.x * aspect);
    }
  }
  /**
   * @public
   * @description add issue to photo overlay
   * @returns { void}
   */
  @autobind
  public addIssueToPhoto(issuePhoto: IssuePhotoObject, entity: IssuePhotoEntity) {
    issuePhoto.isReference = false;
    issuePhoto.issueIcon.scale.copy(this.spriteIcon!.scale.clone().multiplyScalar(0.07));
    const normal = this.photoPlane?.getWorldDirection(new Vector3());
    const position = new Vector3();
    {
      const x = issuePhoto.imagePoint[0] * this.photoPlane!.scale.x - this.photoPlane!.scale.x / 2;
      const y = issuePhoto.imagePoint[1] * this.photoPlane!.scale.y - this.photoPlane!.scale.y / 2;
      position.set(x, y, this.photoPlane!.position.z);
      position.addScaledVector(normal!, 0.01);
      position.applyMatrix4(this.matrixWorld);
      issuePhoto.issueIcon.position.copy(position);
      issuePhoto.label?.position.copy(position);
    }

    if (issuePhoto.imagePoint.length > 2) {
      const points = [];
      for (let i = 0; i < issuePhoto.imagePoint.length; i += 2) {
        const x =
          issuePhoto.imagePoint[i] * this.photoPlane!.scale.x - this.photoPlane!.scale.x / 2;
        const y =
          issuePhoto.imagePoint[i + 1] * this.photoPlane!.scale.y - this.photoPlane!.scale.y / 2;
        position.set(x, y, this.photoPlane!.position.z);
        position.addScaledVector(normal!, 0.01);
        position.applyMatrix4(this.matrixWorld);
        points.push(...position.toArray());
      }
      points.push(...points.slice(0, 3));
      issuePhoto.createBoundary(points);
    }

    entity.add(issuePhoto);
    issuePhoto.setVisible(true);
  }

  /**
   * @public
   * @description add other issue to photo overlay
   * @returns { void}
   */
  @autobind
  public addOtherIssueToPhoto(issuePhoto: IssuePhotoObject, entity: IssuePhotoEntity) {
    if (!issuePhoto.geoData) {
      return;
    }

    const altitude =
      ellipsoidToEgm96(issuePhoto.geoData.latitude, issuePhoto.geoData.longitude, 0) +
      issuePhoto.geoData.absoluteAltitude;
    /**
     * @description process geodata from issue photo to get position, rotaion and photo plane
     */
    const { main, position, rotation } = this.processGeoData(issuePhoto.geoData, altitude);

    const group = new Group();
    group.position.copy(position);
    group.rotation.copy(rotation);
    group.updateMatrixWorld(true);

    {
      let sumX = 0;
      let sumY = 0;
      let sumZ = 0;
      const pointCount = issuePhoto.imagePoint.length / 2;
      const normal = main?.getWorldDirection(new Vector3());
      if (issuePhoto.imagePoint.length > 2) {
        /**
         * @description get center point in world coordinate system
         */

        for (let i = 0; i < issuePhoto.imagePoint.length; i += 2) {
          const x = issuePhoto.imagePoint[i] * main.scale.x - main.scale.x / 2;
          const y = issuePhoto.imagePoint[i + 1] * main.scale.y - main.scale.y / 2;
          position.set(x, y, main.position.z);
          position.addScaledVector(normal, 0.01);
          position.applyMatrix4(group.matrixWorld);

          sumX += position.x;
          sumY += position.y;
          sumZ += position.z;
        }
        const centerX = sumX / pointCount;
        const centerY = sumY / pointCount;
        const centerZ = sumZ / pointCount;
        const center = new Vector3(centerX, centerY, centerZ);

        /**
         * @description find intersect between tileset and photo camera (not current photo camera)
         */
        const direction1 = group.position?.clone().sub(center).normalize().negate();
        raycaster.set(position, direction1);
        const intersectObject1 = raycaster.intersectObject(this.viewer.tileset!);
        if (intersectObject1.length === 0) {
          return;
        }
        {
          /**
           * @description find intersect between current photo plane and the intersect point above
           */
          // const intersectPointWithTileset = intersectObject1[0].point;
          // const currentPhotoPosition = this.group.getWorldPosition(new Vector3());
          // const direction2 = currentPhotoPosition
          //   ?.clone()
          //   .sub(intersectPointWithTileset)
          //   .normalize()
          //   .negate();
          // raycaster.set(currentPhotoPosition, direction2);
          // const intersectObject2 = raycaster.intersectObject(this.photoPlane!);
          // if (intersectObject2.length === 0) {
          //   return;
          // }
        }
        issuePhoto.isReference = true;
        issuePhoto.issueIcon.scale.copy(this.spriteIcon!.scale.clone().multiplyScalar(0.07));
        issuePhoto.issueIcon.position.copy(intersectObject1[0].point);
        issuePhoto.label?.position.copy(intersectObject1[0].point);
        entity.add(issuePhoto);
        issuePhoto.setVisible(true);
      }
    }
  }
}
