import { Language } from '^/types';

export default {
  back: {
    [Language.KO_KR]: '뒤로가기',
    [Language.EN_US]: 'back',
  },
  newPresentation: {
    [Language.KO_KR]: '새 프레젠테이션',
    [Language.EN_US]: 'New Presentation',
  },
  newPresentationSlide: {
    [Language.KO_KR]: '슬라이드 캡처',
    [Language.EN_US]: 'Capture viewpoint as slide',
  },
  noSlidesAvailable: {
    [Language.KO_KR]: '슬라이드가 없습니다.',
    [Language.EN_US]: 'No Slides Available',
  },
  deletePresentation: {
    [Language.KO_KR]: '프레젠테이션 삭제',
    [Language.EN_US]: 'Delete presentation',
  },

  deleteSelectedSlides: {
    [Language.KO_KR]: '선택한 슬라이드 삭제',
    [Language.EN_US]: 'Delete selected slides',
  },

  unselectSlides: {
    [Language.KO_KR]: '선택 취소',
    [Language.EN_US]: 'Unselect',
  },

  saveText: {
    [Language.KO_KR]: '저장',
    [Language.EN_US]: 'Save',
  },
  cancelText: {
    [Language.KO_KR]: '취소',
    [Language.EN_US]: 'Cancel',
  },
  editPresentation: {
    [Language.KO_KR]: '프레젠테이션 이름 수정',
    [Language.EN_US]: 'Edit name',
  },
  successfullyEditedPresentation: {
    [Language.KO_KR]: '프레젠테이션 이름 수정 성공',
    [Language.EN_US]: 'Updated presentation name.',
  },
  successfullyCreatedPresentation: {
    [Language.KO_KR]: '프레젠테이션 생성 성공',
    [Language.EN_US]: 'Presentation has been created successfully',
  },

  successfullyDeletedPresentation: {
    [Language.KO_KR]: '프레젠테이션 삭제 성공',
    [Language.EN_US]: 'Deleted presentation',
  },
  successfullyCreatedSlide: {
    [Language.KO_KR]: '슬라이드 생성 성공',
    [Language.EN_US]: 'Slide has been created successfully',
  },
  successfullyDeletedSlide: {
    [Language.KO_KR]: '프레젠테이션 슬라이드 삭제 성공',
    [Language.EN_US]: 'Slide deleted',
  },
  presentationMode: {
    [Language.KO_KR]: '프레젠테이션 모드',
    [Language.EN_US]: 'Presentation Mode',
  },
  draw: {
    [Language.KO_KR]: '그리기',
    [Language.EN_US]: 'Draw',
  },
  present: {
    [Language.EN_US]: 'Present Mode',
    [Language.KO_KR]: '프레젠테이션 모드',
  },
  rename: {
    [Language.EN_US]: 'Rename',
    [Language.KO_KR]: '이름 바꾸기',
  },
  delete: {
    [Language.EN_US]: 'Delete',
    [Language.KO_KR]: '삭제',
  },
  slideCreationLimitMessage: {
    [Language.EN_US]: 'You can only create up to 30 slides.',
    [Language.KO_KR]: '슬라이드는 30장까지 만들 수 있습니다.',
  },
  hidePresentation: {
    [Language.EN_US]: 'Hide Presentation',
    [Language.KO_KR]: '프레젠테이션 숨기기',
  },
};
