import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import Text from './text';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import ProjectPermissionItem from '^/components/molecules/ProjectPermissionItem';
import palette from '^/constants/palette';
import { MediaQuery } from '^/constants/styles';
import * as T from '^/types';
import { l10n } from '^/utilities/l10n';
import { isRoleAdmin } from '^/utilities/role-permission-check';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { usePermissionStore } from '^/store/permissionStore';

const Root = styled.table<{ isCompactSize: boolean }>(({ isCompactSize }) => ({
  width: '100%',
  marginTop: isCompactSize ? '0px' : '20px',

  borderTopWidth: isCompactSize ? ' 0px' : '2px',
  borderTopStyle: 'solid',
  borderTopColor: palette.icon.toString(),
  borderBottomWidth: isCompactSize ? '0px' : '2px',
  borderBottomStyle: 'solid',
  borderBottomColor: palette.icon.toString(),
}));

const EmptyPermission = styled.div<{ isCompactSize: boolean }>(({ isCompactSize }) => ({
  height: isCompactSize ? '70px' : '140px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#c7c7c7',
}));

const TableHeaderRow = styled.tr({
  zIndex: 10,
  position: 'sticky',
  top: 0,
  boxShadow: '0px 1px 0px #C1C1C1',
  transform: `translateY(-1px)`,

  background: 'white',
});

interface HeaderColumnProps {
  readonly columnMinWidth: number;
  readonly columnRatio: number;
}

const HeaderColumn = styled.th<HeaderColumnProps>(
  {
    paddingTop: '18px',
    paddingBottom: '12px',
    ':not(:first-of-type) > span': {
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderLeftColor: palette.icon.toString(),
    },

    [MediaQuery.MOBILE_L]: {
      display: 'none',
    },
  },
  ({ columnMinWidth, columnRatio }) => ({
    minWidth: `${columnMinWidth}px`,
    width: `${columnRatio}%`,
  })
);

const HeaderText = styled.span<{ isCompactSize: boolean }>(({ isCompactSize }) => ({
  display: 'inline-block',
  width: '100%',
  fontSize: isCompactSize ? '11.5px' : '13px',
  lineHeight: 1,
  fontWeight: 500,
  color: palette.icon.toString(),
  textAlign: 'center',
}));

const Body = styled.tbody({
  '& > tr + tr': {
    marginTop: '10px',
  },
});

const Spacer = styled.tr({
  height: '10px',
  backgroundColor: palette.transparent.toString(),
});

const Center = styled('div')({
  display: 'flex',
  height: '100px',
  justifyContent: 'center',
  alignItems: 'center',
});

export interface Props {
  readonly authedId?: T.User['id'];
  readonly authedRole: T.PermissionRole;
  readonly permissions: T.Permission[];
  readonly featurePermission: T.PermissionFeature;
  readonly isCompactSize: boolean;
  readonly isLoading: boolean;
  onDelete(permission: T.Permission, isNoPermission: boolean): void;
  onPermissionChange(id: T.Permission['id'], role: T.PermissionRole, isNoPermission: boolean): void;
  onFeaturePermissionChange(
    id: T.Permission['id'],
    role: T.PermissionRole,
    isESS: boolean,
    isNoPermission: boolean
  ): void;
}

/**
 * Component for table of permission on manage page
 */

const ProjectPermissionTable: FC<Props & L10nProps> = ({
  permissions,
  language,
  onDelete,
  onPermissionChange,
  authedRole,
  authedId,
  onFeaturePermissionChange,
  featurePermission,
  isCompactSize,
  isLoading,
}) => {
  const searchText = usePermissionStore(s => s.searchText);

  const loader = isLoading ? (
    <Center>
      <LoadingIcon />
    </Center>
  ) : null;
  const emptyPermission =
    permissions.length === 0 && !isLoading ? (
      <EmptyPermission isCompactSize={isCompactSize}>
        {language === T.Language.KO_KR
          ? `“${searchText}” ${l10n(Text.noResultsFound, language)}`
          : `${l10n(Text.noResultsFound, language)} “${searchText}”`}
      </EmptyPermission>
    ) : null;

  const generateItem: () => ReactNode = () => {
    const result: Array<ReactElement<object>> = [];

    permissions.forEach(permission => {
      const hasPermission: boolean = isRoleAdmin(authedRole) && authedId !== permission.userId;

      const handleDelete: () => void = () => onDelete(permission, hasPermission);
      const handlePermissionChange: (id: T.Permission['id'], role: T.PermissionRole) => void = (
        id,
        role
      ) => onPermissionChange(id, role, hasPermission);

      const handleFeaturePermissionChange: (
        id: T.Permission['id'],
        role: T.PermissionRole,
        isESS: boolean
      ) => void = (id, role, isESS: boolean) => {
        onFeaturePermissionChange(id, role, isESS, hasPermission);
      };

      if (result.length !== 0 && !isCompactSize) {
        result.push(<Spacer key={`spacer${permission.userId}`} />);
      }
      result.push(
        <ProjectPermissionItem
          key={permission.userId}
          permission={permission}
          zIndex={0}
          onDelete={handleDelete}
          onPermissionChange={handlePermissionChange}
          onFeaturePermissionChange={handleFeaturePermissionChange}
          featurePermission={featurePermission}
          isCompactSize={isCompactSize}
        />
      );
    });

    return result;
  };

  return (
    <>
      <Root isCompactSize={isCompactSize}>
        <thead>
          <TableHeaderRow>
            <HeaderColumn columnMinWidth={350} columnRatio={30}>
              <HeaderText isCompactSize={isCompactSize}>{l10n(Text.members, language)}</HeaderText>
            </HeaderColumn>
            <HeaderColumn columnMinWidth={90} columnRatio={10}>
              <HeaderText isCompactSize={isCompactSize}>
                {l10n(Text.authority, language)}
              </HeaderText>
            </HeaderColumn>
            {/* <HeaderColumn columnMinWidth={170} columnRatio={30}>
              <HeaderText isCompactSize={isCompactSize}>{l10n(Text.email, language)}</HeaderText>
            </HeaderColumn> */}
            <HeaderColumn columnMinWidth={100} columnRatio={20}>
              <HeaderText isCompactSize={isCompactSize}>{l10n(Text.team, language)}</HeaderText>
            </HeaderColumn>
            {featurePermission.ess && (
              <HeaderColumn columnMinWidth={100} columnRatio={10}>
                <HeaderText isCompactSize={isCompactSize}>
                  {l10n(Text.featureToggle.ess, language)}
                </HeaderText>
              </HeaderColumn>
            )}
            <HeaderColumn columnMinWidth={60} columnRatio={10}>
              <HeaderText isCompactSize={isCompactSize}>{l10n(Text.delete, language)}</HeaderText>
            </HeaderColumn>
          </TableHeaderRow>
        </thead>
        <Body>{generateItem()}</Body>
      </Root>
      {loader}
      {emptyPermission}
    </>
  );
};

export default withL10n(ProjectPermissionTable);
