import React from 'react';
import styled from 'styled-components';
import Dropdown, { Option } from '^/components/atoms/Dropdown/ForIssueGroup';
import palette from '^/constants/palette';

const IssueSortByRoot = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',

  color: palette.issue.sidebarItemFont.toString(),
  fontSize: '12px',
  fontWeight: '400',

  height: '40px',
});

interface IssueSortByTitleProps {
  customTitleColor?: string;
}

const IssueSortByTitle = styled.div<IssueSortByTitleProps>(({ customTitleColor }) => ({
  color: customTitleColor,
}));

interface IssueSortByDropdownProps {
  customWidth?: string;
}
const IssueSortByDropdown = styled.div<IssueSortByDropdownProps>(({ customWidth }) => ({
  marginLeft: '10px',
  marginRight: '13px',
  boxSizing: 'border-box',
  width: customWidth ?? '94px',
}));

interface Props {
  mainTitle: string;
  value: string | number | null | undefined;
  options: Option[];
  onClick(option: Option, index: number): void;
  placeHolder: string;
  zIndex: number;
  customWidth?: string;
  customTitleColor?: string;
}

function IssueGroupOrSortBy(props: Props) {
  return (
    <IssueSortByRoot>
      <IssueSortByTitle customTitleColor={props.customTitleColor}>
        {props.mainTitle}
      </IssueSortByTitle>
      <IssueSortByDropdown customWidth={props.customWidth}>
        <Dropdown {...props} />
      </IssueSortByDropdown>
    </IssueSortByRoot>
  );
}

export default IssueGroupOrSortBy;
