import { groupBy } from 'lodash-es';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState, WheelEvent } from 'react';
import styled from 'styled-components';
import ScrollBarIcon from '^/assets/icons/scroll-bar.svg';
import { smoothScrollTo } from './util';
import { formatDayMonthYear } from '../PhotoList/util';
import { UseL10n, useL10n } from '^/hooks';
import { usePhotoAlbumIdQuery, usePhotoQuery } from '^/hooks/api/photos/usePhoto';
import { useParams } from 'react-router-dom';

const SCROLLBAR_HEIGHT = 30;
const TimeLineWrapper = styled.div({
  width: 30,
  position: 'relative',
});

const YearDot = styled.div<{ translateY: number }>(({ translateY }) => ({
  transform: `translateY(${translateY}px )`,

  position: 'absolute',
  height: 10,
  width: 10,
  backgroundColor: '#d9d9d9',
  borderRadius: '50%',
  left: 'calc(50% - 5px)',
}));

const YearLabel = styled.div<{ translateY: number }>(({ translateY }) => ({
  transform: `translateY(${translateY}px )`,

  position: 'absolute',
  right: 'calc(100%)',
  color: '#8F8F8F',
  fontSize: '10px',
}));

const FullDateLabel = styled.div<{ translateY: number }>(({ translateY }) => ({
  // 30 is the total height of scroll button icon
  transform: `translateY(${translateY - SCROLLBAR_HEIGHT / 2}px )`,

  right: 'calc(100%)',
  position: 'absolute',
  width: 'max-content',
  padding: '10px',
  borderColor: '#d9d9d9',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '4px',
  backgroundColor: 'white',
  pointerEvents: 'none',
  fontSize: '12px',
  fontWeight: 600,
  color: '#000',
}));

const ScrollButton = styled.button<{ translateY: number }>(({ translateY }) => ({
  position: 'absolute',
  height: 10,
  width: 10,
  color: '#ffffff',

  // 30 is the total height of scroll button icon
  transform: `translateY(${translateY - SCROLLBAR_HEIGHT / 2}px )`,

  borderRadius: '50%',
  left: 'calc(50% - 8px)',
  cursor: 'pointer',
  '&:hover': {
    color: '#d9d9d9',
  },
}));

const ScrollLine = styled.div({
  height: '100%',
  width: 2,
  position: 'absolute',
  backgroundColor: '#d9d9d9',
  left: '50%',
});

interface TimeLineScrollBarProps {
  photoWrapperRef: HTMLDivElement | null;
  //   photos: Array<T.FinalPhoto | T.PhotoAlbum>;
  photosPerRow: number;
  height: number;
}

const TimeLineScrollBar: FC<TimeLineScrollBarProps> = ({
  photoWrapperRef,
  photosPerRow = 4,
  height,
}) => {
  const [, lang]: UseL10n = useL10n();
  const labelTimeout = useRef<number | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const { albumId } = useParams();

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [mousePosY, setmousePosY] = useState(0);
  const [hideLabel, setHideLabel] = useState(true);
  const isDragging = useRef(false);

  const { rawPhotos, rawVideos } = usePhotoQuery();
  //   const { rawPhotos, rawVideos, photoAlbums } = usePhotoQuery();
  const { photos: photoAlbumPhotos } = usePhotoAlbumIdQuery(Number(albumId));
  const photos = useMemo(
    () => (albumId ? photoAlbumPhotos : [...rawPhotos, ...rawVideos]),
    [albumId, photoAlbumPhotos, rawPhotos, rawVideos]
  );

  const photosSorted = useMemo(() => {
    const photosGroupByDate = groupBy(
      photos.filter(photo => photo.takenAt),
      'takenAt'
    );
    const photosArr = Object.entries(photosGroupByDate).map(([_date, _photos]) => ({
      date: _date,
      rows: Math.ceil(_photos.length / photosPerRow),
    }));
    return photosArr.sort((a, b) => {
      if (new Date(a.date) > new Date(b.date)) {
        return -1;
      } else if (new Date(a.date) < new Date(b.date)) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [photos]);

  const totalPhotos = useMemo(
    () => photosSorted.reduce((left, right) => left + right.rows, 0),
    [photosSorted]
  );

  const dataByYearArr = useMemo(() => {
    const dataByYear = photosSorted.reduce((acc: { [year: string]: number }, photo) => {
      const year = new Date(photo.date).getFullYear();
      const row = photo.rows;

      if (acc[year]) {
        acc[year] += row / totalPhotos;
      } else {
        acc[year] = row / totalPhotos;
      }

      return acc;
    }, {});
    return Object.entries(dataByYear).sort(([a], [b]) => Number(b) - Number(a));
  }, [photosSorted, totalPhotos]);

  const changeDate = useCallback(
    (scrolledTop: number) => {
      let i = 0;

      for (const p of photosSorted) {
        i += p.rows;
        if (scrolledTop <= i / totalPhotos) {
          if (p.date === 'null') {
            setDate(null);
          } else {
            setDate(p.date);
          }
          break;
        }
      }
    },
    [photosSorted, totalPhotos, setDate]
  );

  const handleScroll = useCallback(() => {
    setHideLabel(false);
    if (photoWrapperRef) {
      const { scrollTop, scrollHeight, clientHeight } = photoWrapperRef;
      const scrolledTop = scrollTop / (scrollHeight - clientHeight);
      setmousePosY(scrolledTop);
      changeDate(scrolledTop);
      if (labelTimeout.current) {
        clearInterval(labelTimeout.current);
      }
      labelTimeout.current = setTimeout(() => {
        setHideLabel(true);
      }, 400) as unknown as number;
    }
  }, [photoWrapperRef, setHideLabel, setmousePosY, changeDate, labelTimeout]);

  const handleScrollOnSlider = (e: WheelEvent<HTMLDivElement>) => {
    if (photoWrapperRef && scrollRef.current) {
      const scrollAmount = e.deltaY;
      photoWrapperRef.scrollTop += scrollAmount;
    }
  };

  const handleScrollBarMouseDown = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      isDragging.current = true;
    },
    [isDragging]
  );

  const handleScrollBarMouseMove = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setHideLabel(false);
      if (isDragging.current) {
        const _mousePosY = e.clientY - (scrollRef.current?.getBoundingClientRect().y || 0);
        const scrolledTop = _mousePosY / height;
        setmousePosY(scrolledTop);
        changeDate(scrolledTop);
        if (photoWrapperRef) {
          const { scrollHeight, clientHeight } = photoWrapperRef;
          photoWrapperRef.scrollTop = scrolledTop * (scrollHeight - clientHeight);
        }
      }
    },
    [setHideLabel, isDragging, height, setmousePosY, changeDate, photoWrapperRef, scrollRef]
  );

  const handleScrollBarMouseUp = useCallback(
    (e: any) => {
      e.preventDefault();
      isDragging.current = false;
      return true;
    },
    [isDragging]
  );

  const handleTimelineClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (photoWrapperRef) {
        const _mousePosY = e.clientY - (scrollRef.current?.getBoundingClientRect().y || 0);
        const scrolledTop = _mousePosY / height;
        const { scrollHeight, clientHeight } = photoWrapperRef;
        const newScrollTop = scrolledTop * (scrollHeight - clientHeight);
        smoothScrollTo(photoWrapperRef, newScrollTop, 400);
      }
    },
    [photoWrapperRef, scrollRef, height]
  );

  const handleScrollBarMouseLeave = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      isDragging.current = false;

      setTimeout(() => {
        setHideLabel(true);
      }, 400);
    },
    [setHideLabel, isDragging]
  );

  useEffect(() => {
    if (photoWrapperRef) {
      photoWrapperRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (photoWrapperRef) {
        photoWrapperRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [photoWrapperRef, handleScroll]);

  const yeatDotAndLabel = useMemo(
    () =>
      dataByYearArr.map(([year], index) => {
        const fromTop =
          dataByYearArr
            .slice(0, index + 1)
            .reduce((totalLength, [, _length]) => totalLength + _length, 0) * height;
        return (
          <React.Fragment key={year}>
            <YearDot translateY={fromTop} key={year + 'dot'} />
            <YearLabel key={year + 'label'} translateY={fromTop}>
              {Number(year) ? new Date(year).getFullYear() : ''}
            </YearLabel>
          </React.Fragment>
        );
      }),
    [dataByYearArr, height]
  );

  const scrollButtonPos =
    mousePosY * height < SCROLLBAR_HEIGHT / 2 ? SCROLLBAR_HEIGHT / 2 : mousePosY * height;

  if (photos.length === 0) {
    return null;
  }

  return (
    <TimeLineWrapper
      onClick={handleTimelineClick}
      onMouseLeave={handleScrollBarMouseLeave}
      onMouseMove={handleScrollBarMouseMove}
      onMouseUp={handleScrollBarMouseUp}
      ref={scrollRef}
      onWheel={handleScrollOnSlider}
      style={{ height }}
    >
      <ScrollLine />
      <YearDot translateY={0} />
      {yeatDotAndLabel}
      <ScrollButton translateY={scrollButtonPos} onMouseDown={handleScrollBarMouseDown}>
        <ScrollBarIcon />
      </ScrollButton>
      {!hideLabel && date && (
        <FullDateLabel translateY={scrollButtonPos}>{formatDayMonthYear(date, lang)}</FullDateLabel>
      )}
    </TimeLineWrapper>
  );
};

export default TimeLineScrollBar;
