function getQueryParam(parameterName: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(parameterName);
}

const FPS_ENABLED = (() => {
  if (['test.angelswing.io', 'dev.angelswing.io'].includes(window.location.hostname)) {
    return true;
  }
  return getQueryParam('fps') === 'true';
})();

export function isStatsEnabled() {
  return FPS_ENABLED;
}
