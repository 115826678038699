import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDroneStationAuthStore } from '^/store/droneStationAuthStore';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ERROR } from '../types';
import { getLoginUrl } from './common';
import * as T from '^/types';
import { AuthHeader } from '^/store/duck/API';
import { http } from '^/utilities/api';

export interface TGetDroneStationAuthDetails {
  readonly code: number;
  readonly message: string;
  readonly data: T.RawDroneStationAuthDetails;
}

interface TPostDroneStationAuthDetails {
  readonly projectId: T.Project['id'] | undefined;
  readonly authHeader: AuthHeader | undefined;
}
async function postDroneStationAuthDetails({
  projectId,
  authHeader,
}: TPostDroneStationAuthDetails) {
  if (!authHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const payload = {
    username: import.meta.env.VITE_DJI_USER_NAME,
    password: import.meta.env.VITE_DJI_USER_PASSWORD,
    flag: import.meta.env.VITE_DJI_USER_FLAG,
  };

  try {
    const response = await http.post<TGetDroneStationAuthDetails>(getLoginUrl(), payload, {
      headers: {
        Authorization: `Bearer ${authHeader.Authorization}`,
        projectId: projectId,
      },
    });

    return response;
  } catch (error) {
    throw new Error(ERROR.OtherError);
  }
}

export const usePostDroneStationAuthDetailsMutation = () => {
  const { setAuthDetails } = useDroneStationAuthStore(s => ({
    authDetails: s.authDetails,
    setAuthDetails: s.setAuthDetails,
  }));
  const queryClient = useQueryClient();
  const dispatch: Dispatch = useDispatch();

  const { mutate: postDroneStationAuthDetailsMutation } = useMutation({
    mutationFn: postDroneStationAuthDetails,

    onSuccess: async data => {
      await queryClient.invalidateQueries(['PostDroneStationAuthDetails']);

      const apiResonse = data.data.data;
      const serializedAuthDetails = {
        username: apiResonse.username,
        userId: apiResonse.user_id,
        workspaceId: apiResonse.workspace_id,
        userType: apiResonse.user_type,
        mqttUsername: apiResonse.mqtt_username,
        mqttPassword: apiResonse.mqtt_password,
        accessToken: apiResonse.access_token,
        mqttAddress: apiResonse.mqtt_addr,
      };
      setAuthDetails(serializedAuthDetails);
    },
    onError: (error: AxiosError) => {
      /**
       * TODO: @ebraj-angelswing
       * Add the error handling
       */
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      }
    },
  });

  return postDroneStationAuthDetailsMutation;
};
