import colorTokens from '^/theme/colors/color-tokens';
import React from 'react';
import styled from 'styled-components';

interface HorizontalLineProps {
  color?: string;
  width?: string;
}

const Line = styled.hr<HorizontalLineProps>`
  height: 1px;
  border: none;
  width: ${({ width }) => width || '100%'};
  background-color: ${({ color }) => color};
`;

export const HorizontalLine: React.FC<HorizontalLineProps> = ({
  color = colorTokens.lineCool,
  width = '100%',
}) => <Line color={color} width={width} />;
