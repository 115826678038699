import * as T from '^/types';

export default {
  header: {
    title: {
      [T.Language.KO_KR]: '프로젝트에 업로드된 사진이 없습니다',
      [T.Language.EN_US]: 'There are no photos uploaded to the project',
    },
    subText: {
      [T.Language.KO_KR]: '왼쪽 하단에 있는 버튼을 클릭하면 갤러리에 사진을 추가할 수 있어요.',
      [T.Language
        .EN_US]: `Press the button on the bottom left part of the window to \n upload photos to the gallery.`,
    },
  },
  uploadButtonText: {
    [T.Language.KO_KR]: '사진 업로드',
    [T.Language.EN_US]: 'Upload Photos',
  },
};
