import { GetSignInResponse } from '^/store/react-query/auth';

export const getAuthedUserSerializer = (response: GetSignInResponse) => {
  const { data, meta } = response;
  return {
    id: parseInt(data.id, 10),
    token: data.attributes?.token ?? '',
    cloudCdnCookie: meta?.cloudCdnCookie,
    cloudFrontPolicy: meta?.cloudFrontPolicy,
    cloudFrontSignature: meta?.cloudFrontSignature,
    cloudFrontKeyPairId: meta?.cloudFrontKeyPairId,
    refreshToken: data.attributes?.refreshToken ?? '',
    userEmail: data.attributes.email,
    currentSession: data.attributes?.currentSession,
  };
};
