import React, { ReactNode, useState, useRef, useEffect, FC, PropsWithChildren } from 'react';
import styled, { CSSObject } from 'styled-components';
import palette from '^/constants/palette';
interface CustomStyleProps {
  readonly customStyle: CSSObject;
}
interface TooltipBalloonProps {
  tooltipArrowStyle: CSSObject;
}

type TextBalloonProps = TooltipBalloonProps & CustomStyleProps;

const Root = styled.div<CustomStyleProps>({}, ({ customStyle }) => customStyle);

const Target = styled.div<CustomStyleProps>({}, ({ customStyle }) => customStyle);

const TooltipBalloon = styled.div<TextBalloonProps>(
  {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    bottom: '-36px',

    display: 'inline-block',

    padding: '4px 5px 5px 5px',

    maxWidth: '175px',

    borderRadius: '3px',

    pointerEvents: 'none',
  },
  ({ customStyle, tooltipArrowStyle }) => ({
    ...customStyle,

    '::after': {
      ...tooltipArrowStyle,
    },
  })
);

const TooltipBalloonBackground = styled.div<CustomStyleProps>(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',

    borderRadius: '2px',

    backgroundColor: palette.tooltipBackground.toString(),
    backdropFilter: 'blur(3px)',
  },
  ({ customStyle }) => customStyle
);

const TooltipTitle = styled.div<CustomStyleProps>(
  {
    position: 'relative',

    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1,

    color: palette.white.toString(),

    whiteSpace: 'nowrap',
  },
  ({ customStyle }) => customStyle
);

const TooltipContent = styled.div<CustomStyleProps>(
  {
    position: 'relative',
    fontSize: '12px',
    lineHeight: 1.17,

    color: palette.measurements.volume.toString(),

    whiteSpace: 'nowrap',
  },
  ({ customStyle }) => customStyle
);

export interface Props {
  readonly title: string;
  readonly content?: string | ReactNode;
  readonly forceTurnOffHover?: boolean;
  /**
   * Use this when you need to check if mouse is outside of wrapperhoverable
   * for example, to remove tooltip after some screen animation
   */
  readonly allowForceCheckMouseout?: boolean;
  readonly allowForceCheckTouchend?: boolean;

  readonly customStyle: {
    readonly tooltipWrapperStyle?: CSSObject;
    readonly tooltipTargetStyle?: CSSObject;
    readonly tooltipBackgroundStyle?: CSSObject;
    readonly tooltipBalloonStyle?: CSSObject;
    readonly tooltipTextTitleStyle?: CSSObject;
    readonly tooltipTextContentStyle?: CSSObject;
    readonly tooltipArrowStyle?: CSSObject;
  };
}

const WrapperHoverable: FC<PropsWithChildren<Props>> = ({
  title,
  content,
  customStyle,
  forceTurnOffHover,
  children,
  allowForceCheckMouseout,
  allowForceCheckTouchend,
}) => {
  const wrapperHoverableRef = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const forceCheckMouseoutFunction: (event: MouseEvent) => void = _event => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let forceCheckTouchendFunction: (event: TouchEvent) => void = _event => {};

  const turnHoverOn: () => void = () => {
    setHovered(true);
  };

  const turnHoverOff: () => void = () => {
    setHovered(false);
  };

  const forceCheckMouseout: (divRect: DOMRect) => (event: MouseEvent) => void =
    divRect => (event: MouseEvent) => {
      if (
        !(
          event.clientX >= divRect.left &&
          event.clientX <= divRect.right &&
          event.clientY >= divRect.top &&
          event.clientY <= divRect.bottom
        )
      ) {
        setHovered(false);
      }
    };

  const forceCheckTouchend: (divRect: DOMRect) => (event: TouchEvent) => void =
    divRect => (event: TouchEvent) => {
      if (
        !(
          event.touches[0].clientX >= divRect.left &&
          event.touches[0].clientX <= divRect.right &&
          event.touches[0].clientY >= divRect.top &&
          event.touches[0].clientY <= divRect.bottom
        )
      ) {
        setHovered(false);
      }
    };

  useEffect(
    () => () => {
      document.body.removeEventListener('mousemove', forceCheckMouseoutFunction);
      document.body.removeEventListener('touchmove', forceCheckTouchendFunction);
    },
    []
  );

  useEffect(() => {
    if (hovered && allowForceCheckMouseout && allowForceCheckTouchend) {
      const body: HTMLElement = document.body;
      body.removeEventListener('mousemove', forceCheckMouseoutFunction);
      body.removeEventListener('touchmove', forceCheckTouchendFunction);
      const divRect: DOMRect = wrapperHoverableRef?.current?.getBoundingClientRect() as DOMRect;
      forceCheckMouseout(divRect);
      body.addEventListener('mousemove', forceCheckMouseoutFunction, { once: true });
      forceCheckTouchendFunction = forceCheckTouchend(divRect);
      body.addEventListener('touchmove', forceCheckTouchendFunction, { once: true });
    }
  }, [hovered, allowForceCheckMouseout, allowForceCheckTouchend]);

  const tooltipBalloonStyle: CSSObject =
    customStyle.tooltipBalloonStyle !== undefined ? customStyle.tooltipBalloonStyle : {};

  const tooltipArrowStyle: CSSObject =
    customStyle.tooltipArrowStyle !== undefined ? customStyle.tooltipArrowStyle : {};

  const tooltipTextTitleStyle: CSSObject =
    customStyle.tooltipTextTitleStyle !== undefined ? customStyle.tooltipTextTitleStyle : {};

  const tooltipTextContentStyle: CSSObject =
    customStyle.tooltipTextContentStyle !== undefined ? customStyle.tooltipTextContentStyle : {};

  const tooltipBackgroundStyle: CSSObject =
    customStyle.tooltipBackgroundStyle !== undefined ? customStyle.tooltipBackgroundStyle : {};

  const tooltipWrapperStyle: CSSObject =
    customStyle.tooltipWrapperStyle !== undefined ? customStyle.tooltipWrapperStyle : {};

  const tooltipTargetStyle: CSSObject =
    customStyle.tooltipTargetStyle !== undefined ? customStyle.tooltipTargetStyle : {};

  const tooltip: ReactNode = hovered ? (
    <TooltipBalloon customStyle={tooltipBalloonStyle} tooltipArrowStyle={tooltipArrowStyle}>
      <TooltipBalloonBackground customStyle={tooltipBackgroundStyle} />
      <TooltipTitle customStyle={tooltipTextTitleStyle}>{title}</TooltipTitle>
      <TooltipContent customStyle={tooltipTextContentStyle}>{content}</TooltipContent>
    </TooltipBalloon>
  ) : undefined;

  /**
   * @info The Tooltip is created outside of the target component to overcome the limitation of
   * property 'overflow: hidden'
   * To use the Tooltip correctly, the holder of the target component should set 'position'
   */

  if (forceTurnOffHover) {
    turnHoverOff();
  }

  return (
    <Root customStyle={tooltipWrapperStyle}>
      <Target
        ref={wrapperHoverableRef}
        onMouseEnter={turnHoverOn}
        data-testid="wrapperhoverable-target"
        onMouseLeave={turnHoverOff}
        onTouchEnd={turnHoverOff}
        customStyle={tooltipTargetStyle}
      >
        {children}
      </Target>
      {tooltip}
    </Root>
  );
};

export default WrapperHoverable;
