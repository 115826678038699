import React from 'react';
import { HeaderRow, Th } from '../styles';
import { Moment } from '^/store/earthwork/types';

interface Props {
  moments: Moment[];
  isFixed?: boolean;
  isScrollable?: boolean;
}

const TableHeader: React.FC<Props> = ({ moments, isFixed, isScrollable }) => (
  <thead>
    <HeaderRow>
      {isFixed ? (
        <>
          <Th rowSpan={2} colSpan={2}>
            Zone
          </Th>
          <Th rowSpan={2}>Cut / Fill</Th>
          <Th rowSpan={2}>Soil Type</Th>
          <Th colSpan={3}>Volume (m³)</Th>
        </>
      ) : null}
      {isScrollable ? (
        <>
          <Th colSpan={0} className="fixed-col">
            Changes by Date (m³)
          </Th>
          <Th colSpan={2} className="hidden-col" />
        </>
      ) : null}
    </HeaderRow>
    <HeaderRow>
      {isFixed ? (
        <>
          <Th>Initial</Th>
          <Th>Remaining</Th>
          <Th>Total Change</Th>
        </>
      ) : null}
      {isScrollable ? (
        <>
          {moments.map(moment => (
            <Th
              key={moment.id}
              style={{
                borderTop: '1px solid #e5e5e6',
                backgroundColor: 'transparent',
              }}
            >
              {moment.data}
            </Th>
          ))}
        </>
      ) : null}
    </HeaderRow>
  </thead>
);

export default TableHeader;
