import React from 'react';
import styled, { css, CSSObject } from 'styled-components';

interface FlexBoxProps {
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: string;
  flexGrow?: string;
  height?: string;
  children: React.ReactNode;
  customStyles?: CSSObject;
}

const StyledFlexBox = styled.div<FlexBoxProps>`
  display: flex;
  gap: ${({ gap }) => gap || '0'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  height: ${({ height }) => height || 'auto'};
  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

export const FlexBox: React.FC<FlexBoxProps> = props => {
  const { children, ...restProps } = props;
  return <StyledFlexBox {...restProps}>{children}</StyledFlexBox>;
};
