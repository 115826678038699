import Tippy from '@tippyjs/react';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import * as THREE from 'three';

import Text from './text';

import { DEFAULT_COORDINATE_TITLE_TEXT } from '^/constants/coordinate';

import { useL10n, UseL10n, UseState } from '^/hooks';
import {
  ChangeEditingGCPIndex,
  ChangeTwoDDisplayCenter,
  ChangeZoomToExtent,
} from '^/store/duck/Pages';
import * as T from '^/types';
import CenteredViewSvg from '^/assets/icons/centered-view.svg';
import { createExtentFromCoordinate } from '^/utilities/content-util';
import { useThreeStore } from '^/components/three/ThreeStore';
import { gcpToGeoPoints } from '^/utilities/gcp-util';
import styled from 'styled-components';
import palette from '^/constants/palette';
import dsPalette from '^/constants/ds-palette';

export interface Props {
  readonly gcp: T.GCP;
  readonly crs: T.ProjectionEnum;
  readonly index: number;
  readonly isIn3DMesh: boolean;
  readonly coordinateTitles: T.CoordinateTitle[];
}

const LabelItem = styled.li({
  width: '100%',
  height: '40px',

  boxSizing: 'border-box',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  color: dsPalette.title.toString(),
  fontSize: '14px',

  cursor: 'pointer',
  borderBottom: '1px solid #ffffff',

  ':hover': {
    borderBottom: `1px solid ${palette.toggleButtonGray.toString()}`,
  },
});

const ContentCenterButton = styled.div({
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  marginRight: '8.5px',
  marginLeft: '3px',
  ':hover': {
    backgroundColor: palette.dropdown.dividerColor.toString(),
  },
});

const TiffyContentText = styled.span({
  fontWeight: 'bold',
});

const Contentlabel = styled.p({
  ':hover': {
    fontWeight: 'bold',
  },
});

const ContentsListGCPLabelItem = ({ gcp, crs, index, isIn3DMesh, coordinateTitles }: Props) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const { label, easting, northing, altitude } = gcp;
  const coordinateMap: { [K in T.CoordinateTitle]: number } = {
    [T.CoordinateTitle.EASTING]: easting,
    [T.CoordinateTitle.NORTHING]: northing,
    [T.CoordinateTitle.LATITUDE]: easting,
    [T.CoordinateTitle.LONGITUDE]: northing,
    [T.CoordinateTitle.ALTITUDE]: altitude,
  };

  const [isHovered, setIsHovered]: UseState<boolean> = useState<boolean>(false);
  const [isCenterButtonSelected, setIsCenterButtonSelected]: UseState<boolean> =
    useState<boolean>(false);

  const scene = useThreeStore.getState().viewer?.scene;
  const runtime = useThreeStore.getState().viewer?.runtime;
  const cameraControls = useThreeStore.getState().viewer?.cameraControls;

  const handleGCPMouseEnter: (gcpIndex: number) => void = useCallback(
    gcpIndex => {
      dispatch(ChangeEditingGCPIndex({ editingGCPIndex: gcpIndex }));
    },
    [isHovered]
  );

  const handleGCPMouseLeave: () => void = useCallback(() => {
    dispatch(ChangeEditingGCPIndex({}));
  }, [isHovered]);

  const handleMouseEnter: () => void = () => {
    handleGCPMouseEnter(index);
    setIsHovered(true);
  };

  const handleMouseLeave: () => void = () => {
    handleGCPMouseLeave();
    setIsHovered(false);
  };

  const handleContentCenterClick: () => void = async () => {
    const actions: Action[] = [];

    const centerPoint: T.GeoPoint | undefined = gcpToGeoPoints(gcp, crs);
    if (centerPoint !== undefined) {
      if (isIn3DMesh && runtime && scene) {
        // Handling 3D Mesh display
        const object = scene.getObjectByName(`gcp-${index}`);
        if (!object) {
          return;
        }

        await cameraControls?.rotateTo(0, 0, false);
        await cameraControls?.fitToBox(object, true, {
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
        });
        await cameraControls?.rotate(
          45 * THREE.MathUtils.DEG2RAD,
          45 * THREE.MathUtils.DEG2RAD,
          true
        );
      } else {
        // Handling 2D display
        const extent = createExtentFromCoordinate(centerPoint);
        actions.push(
          ChangeTwoDDisplayCenter({
            twoDDisplayCenter: centerPoint,
          })
        );
        actions.push(
          ChangeZoomToExtent({
            coordinate: extent,
          })
        );
      }
    }
    batch(() => actions.forEach(dispatch));
  };

  const contentCenterButton: ReactNode = useMemo(() => {
    if (!isHovered) {
      return null;
    }
    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="top"
        arrow={false}
        content={l10n(Text.tooltipCenter)}
      >
        <ContentCenterButton
          onClick={handleContentCenterClick}
          onMouseOver={() => {
            setIsCenterButtonSelected(true);
          }}
          onMouseLeave={() => {
            setIsCenterButtonSelected(false);
          }}
        >
          <CenteredViewSvg />
        </ContentCenterButton>
      </Tippy>
    );
  }, [isHovered, handleContentCenterClick]);

  const coordinate: JSX.Element = (
    <>
      {coordinateTitles.map((title, idx) => (
        <React.Fragment key={title}>
          {`${l10n(DEFAULT_COORDINATE_TITLE_TEXT[title])}`}
          <TiffyContentText> {`: ${coordinateMap[title]}`}</TiffyContentText>
          {idx < coordinateTitles.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );

  return (
    <LabelItem onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Tippy
        key={`gcppoint_${index}`}
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom-start"
        arrow={false}
        content={coordinate}
        disabled={isCenterButtonSelected}
      >
        <Contentlabel>{label}</Contentlabel>
      </Tippy>
      {contentCenterButton}
    </LabelItem>
  );
};

export default ContentsListGCPLabelItem;
