import Tippy from '@tippyjs/react';
import React, { ReactElement, ReactNode, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Placement as TippyPlacement } from 'tippy.js';

import Text from './text';
import ContentInfoSvg from '^/assets/icons/contents-list/content-info.svg';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';
import * as T from '^/types';
import { formatCreatedAt } from '^/utilities/date-format';
import { L10nDictionary } from '^/utilities/l10n';

const CREATED_AT_TITLE_MAP: { [key in T.ContentType]: L10nDictionary } = {
  [T.ContentType.AREA]: Text.tooltipCreatedAt,
  [T.ContentType.LENGTH]: Text.tooltipCreatedAt,
  [T.ContentType.THREE_LENGTH]: Text.tooltipCreatedAt,
  [T.ContentType.THREE_HEIGHT]: Text.tooltipCreatedAt,
  [T.ContentType.THREE_AREA]: Text.tooltipCreatedAt,
  [T.ContentType.MARKER]: Text.tooltipCreatedAt,
  [T.ContentType.VOLUME]: Text.tooltipCreatedAt,
  [T.ContentType.BLUEPRINT_PDF]: Text.tooltipUploadedAt,
  [T.ContentType.BLUEPRINT_DXF]: Text.tooltipUploadedAt,
  [T.ContentType.BLUEPRINT_DWG]: Text.tooltipUploadedAt,
  [T.ContentType.BIM]: Text.tooltipUploadedAt,
  [T.ContentType.DESIGN_DXF]: Text.tooltipUploadedAt,
  [T.ContentType.MAP]: Text.tooltipCreatedUploadedAt,
  [T.ContentType.DSM]: Text.tooltipCreatedUploadedAt,
  [T.ContentType.DTM]: Text.tooltipCreatedUploadedAt,
  [T.ContentType.THREE_D_ORTHO]: Text.tooltipAutoCreatedAt,
  [T.ContentType.POINTCLOUD]: Text.tooltipCreatedUploadedAt,
  [T.ContentType.THREE_D_MESH]: Text.tooltipCreatedUploadedAt,
  [T.ContentType.GCP_GROUP]: Text.tooltipCreatedUploadedAt,
  [T.ContentType.ESS_MODEL]: Text.tooltipCreatedAt,
  [T.ContentType.ESS_MODEL_CUSTOM]: Text.tooltipCreatedAt,
  [T.ContentType.ESS_ARROW]: Text.tooltipCreatedAt,
  [T.ContentType.ESS_POLYGON]: Text.tooltipCreatedAt,
  [T.ContentType.ESS_POLYLINE]: Text.tooltipCreatedAt,
  [T.ContentType.ESS_TEXT]: Text.tooltipCreatedAt,
  [T.ContentType.ESS_LINE_TEXT]: Text.tooltipCreatedAt,
  [T.ContentType.GROUP]: Text.tooltipCreatedAt,
  [T.ContentType.ISSUE_POINT]: Text.tooltipCreatedAt,
  [T.ContentType.FLIGHT_PLAN]: Text.tooltipCreatedAt,
  [T.ContentType.FLIGHT_SCHEDULE]: Text.tooltipCreatedAt,
  [T.ContentType.DRONE_STATION]: Text.tooltipCreatedAt,
  [T.ContentType.ISSUE_PHOTO]: Text.tooltipCreatedAt,
  [T.ContentType.SLIDE]: Text.tooltipAutoCreatedAt,
  [T.ContentType.FLATTEN_MAP]: Text.tooltipCreatedAt,
  [T.ContentType.ANNOTATION]: Text.tooltipAutoCreatedAt,
};

const Content = styled.span({
  fontWeight: 'bold',
});

export interface Props {
  readonly content: T.Content;
  readonly tooltipPlacement?: TippyPlacement;
}

const InfoIcon = styled(ContentInfoSvg)({
  width: '13px',
  height: '13px',
  fill: palette.ContentsList.balloonHeaderIconGray.toString(),
});

function ContentInfo({ content, tooltipPlacement: placement = 'bottom' }: Props): ReactElement {
  const [l10n, lang]: UseL10n = useL10n();

  const timezoneOffset = useSelector((s: T.State) => s.Pages.Common.timezoneOffset);

  const tooltipCreatedAt: ReactNode = useMemo(
    () =>
      content.createdAt !== undefined ? (
        <div>
          <span>{`${l10n(CREATED_AT_TITLE_MAP[content.type])}: `}</span>
          <Content>{formatCreatedAt(timezoneOffset, content.createdAt!, lang)}</Content>
        </div>
      ) : null,
    [content.createdAt, l10n]
  );

  const createdBy = T.MAP_TAB_CONTENTS.includes(content.type) ? undefined : content.createdBy?.name;

  const tooltipCreatedBy: ReactNode = useMemo(
    () =>
      createdBy !== undefined ? (
        <div>
          <span>{`${l10n(Text.tooltipCreatedBy)}: `}</span>
          <Content>{createdBy}</Content>
        </div>
      ) : null,
    [createdBy, l10n]
  );

  const tooltipContentId: ReactNode = useMemo(
    () => (
      <div>
        <span>{`${l10n(Text.tooltipContentId)}: `}</span>
        <Content>{content.id}</Content>
      </div>
    ),
    [content.id, l10n]
  );

  const tooltipContent: ReactNode = useMemo(
    () => (
      <>
        {tooltipCreatedAt}
        {tooltipCreatedBy}
        {tooltipContentId}
      </>
    ),
    [content.type, content.createdAt, tooltipCreatedBy, l10n]
  );

  return (
    <Tippy
      theme="angelsw"
      offset={T.TIPPY_OFFSET}
      arrow={false}
      placement={placement}
      content={tooltipContent}
    >
      {/* Do not remove <div>. Without the <div>, the tooltip won't work. */}
      <div>
        <InfoIcon />
      </div>
    </Tippy>
  );
}

export default memo(ContentInfo);
