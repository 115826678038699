import Dropdown, { Option as DropdownOption } from '^/components/atoms/Dropdown/1';
import AgoraLiveStreaming from '^/components/molecules/AgoraLiveStreaming';
import DraggableResizableContainer from '^/components/molecules/DraggableResizableContainer';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';
import { useDroneStationStore } from '^/store/droneStationStore';
import { useGetDroneStationsQuery } from '^/store/react-query/drone-station/drone-station';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import React, { FC, memo, useState } from 'react';
import ScrollBars, { ScrollbarProps } from 'react-custom-scrollbars-2';
import styled, { AnyStyledComponent, CSSObject } from 'styled-components';
import { Fallback } from './fallback';
import Texts from './text';

export const DroneStationContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',

  fontSize: '15px',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});
DroneStationContentsListRoot.displayName = 'GroupedContentsListRoot';

const DroneStationWrapper = styled.div({
  padding: '14px 18px',

  fontSize: '14px',
  color: palette.issue.sidebarItemFont.toString(),
});
const DroneStationContentsListWrapper = styled.div({
  paddingBottom: '14px',
});

const DroneStationContentsListHeader = styled.div({
  display: 'grid',
  gridTemplateColumns: '1.5fr 2fr',
  alignItems: 'center',

  fontSize: '12px',
  backgroundColor: dsPalette.white.toString(),
  borderBottom: `1px solid ${palette.borderGray.toString()}`,
});

const DroneStationContentsListDrone = styled(DroneStationContentsListHeader)({
  borderBottom: 'none',
});

const DroneStationContentsListDock = styled(DroneStationContentsListHeader)({
  borderBottom: `1px solid ${palette.borderGray.toString()}`,
});

const ListContainer = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
});

const ListItem = styled.li({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '5px',
});

const VerticalFlexWrapper = styled.div({
  padding: '10px',

  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const DroneStationStatus = styled.p({
  color: dsPalette.white.toString(),
  textAlign: 'center',

  padding: '10px 10px',
});
const DroneStationStatusProgress = styled(DroneStationStatus)({
  backgroundColor: palette.DroneStation.green.toString(),
});
const DroneStationStatusConnected = styled(DroneStationStatus)({
  backgroundColor: palette.DroneStation.green.toString(),
});
// const DroneStationStatusCompleted = styled(DroneStationStatus)({
//   backgroundColor: palette.DroneStation.orange.toString(),
// });
const DroneStationStatusDisconnected = styled(DroneStationStatus)({
  backgroundColor: palette.DroneStation.red.toString(),
});

const DroneStationName = styled.p({
  padding: '10px',
  fontSize: '12px',
});

const ImageWrapper = styled.div({
  textAlign: 'center',
  padding: '15px',

  backgroundColor: '#EFEFF3',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'stretch',
});

const Image = styled.img({
  width: '100%',
  paddingBottom: '5px',
});

const Title = styled.p({
  padding: '14px 18px 14px',

  fontWeight: 'regular',
  fontSize: '14px',

  color: palette.ContentsList.groupListHeaderTextGray.toString(),
});

const Text = styled.p({});

const HighlightedText = styled.p({
  fontWeight: 'bold',

  color: dsPalette.themePrimary.toString(),
});

const ButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2px',
  justifyContent: 'space-between',
});

const Button = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '24px',
  width: '100%',

  borderRadius: '4px',
  border: '1px solid #3669B5',
  backgroundColor: dsPalette.white.toString(),

  fontSize: '12px',
  fontWeight: '500',
  cursor: 'pointer',
  color: `${dsPalette.themePrimary.toString()}`,
});

const HorizontalDividerWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  paddingTop: '3px',
  paddingBottom: '3px',
  paddingLeft: '10px',
  paddingRight: '10px',

  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});

const HorizontalDivider = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '1px',
  background: palette.toggleButtonGray.toString(),
});

// const LoaderIconWrapper = styled.div({
//   width: '100%',
//   height: '60px',

//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   color: palette.white.toString(),
// });

const LoaderText = styled.p({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '44px',
  fontSize: '14px',
  textAlign: 'center',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
  color: palette.ContentsList.groupListHeaderTextGray.toString(),
});

const LiveStreamQualityDropdown = styled(Dropdown as AnyStyledComponent)({
  position: 'absolute',
  top: '-36px',
  left: '24px',
  width: '100px',
  height: '28px',
});

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const ScrollBarsWrapper = styled(ScrollBars as any)<ScrollbarProps>({
  height: '100%',
});
const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

const TEMP_DRONE_IMAGE =
  'https://droniq.de/wp-content/uploads/2024/04/EA220_drone-V2_%E7%99%BD%E5%BA%95%E5%9B%BE_0829_010065-1.png';
const TEMP_DOCK_STATION_IMAGE =
  'https://volatusdrones.com/cdn/shop/files/EA220___0828_020060-Copy-478919_800x.png';

const RawStatus = memo(({ taskStatus }: { taskStatus: boolean }) => {
  const [l10n]: UseL10n = useL10n();

  switch (taskStatus) {
    case false:
      return (
        <DroneStationStatusDisconnected>
          {l10n(Texts.taskStatus.disconnected)}
        </DroneStationStatusDisconnected>
      );
    case true:
      return (
        <DroneStationStatusConnected>
          {l10n(Texts.taskStatus.connected)}
        </DroneStationStatusConnected>
      );
    // case true:
    //   return (
    //     <DroneStationStatusCompleted>
    //       {l10n(Texts.taskStatus.completed)}
    //     </DroneStationStatusCompleted>
    //   );
    // case 'in-progress':
    default:
      return (
        <DroneStationStatusProgress>{l10n(Texts.taskStatus.inProgress)}</DroneStationStatusProgress>
      );
  }
});

const Contents: FC = () => {
  const [l10n]: UseL10n = useL10n();
  const [isLiveStreamOn, setIsLiveStreamOn] = useState<boolean>(false);
  const [, setSelectedLiveCamera] = useState<string>('');
  const { data: droneStations, isLoading, isError } = useGetDroneStationsQuery();
  const { liveStreamQuality, setLiveStreamQuality } = useDroneStationStore(s => ({
    liveStreamQuality: s.liveStreamQuality,
    setLiveStreamQuality: s.setLiveStreamQuality,
  }));

  const handleDroneLiveCameraClick = () => {
    setIsLiveStreamOn(true);
    setSelectedLiveCamera('drone');
  };
  const handleDockLiveCameraClick = () => {
    setIsLiveStreamOn(true);
    setSelectedLiveCamera('dock');
  };
  const handleLiveStreamClose = () => {
    setIsLiveStreamOn(false);
    setSelectedLiveCamera('');
  };

  // const rawLoadingDiv: React.ReactNode = (
  //   <LoaderIconWrapper>
  //     <LoadingIcon />
  //   </LoaderIconWrapper>
  // );

  const rawLoadingText: React.ReactNode = <LoaderText>{l10n(Texts.emptyData)}</LoaderText>;

  if (isLoading || isError) {
    return <>{rawLoadingText}</>;
  }

  const options: DropdownOption[] = T.liveStreamQuality.map(quality => ({
    text: T.LiveStreamQuality[quality],
    value: quality,
  }));

  return (
    <DroneStationWrapper>
      {isLiveStreamOn ? (
        <DraggableResizableContainer
          width={720}
          height={480}
          minConstraints={[200, 200]}
          maxConstraints={[720, 480]}
          handleClose={handleLiveStreamClose}
        >
          <>
            <LiveStreamQualityDropdown
              options={options}
              value={liveStreamQuality}
              placeHolder="Quality"
              onClick={(option: DropdownOption) =>
                setLiveStreamQuality(option.value as T.LiveStreamQuality)
              }
              zIndex={1}
            />
            <AgoraLiveStreaming />
          </>
        </DraggableResizableContainer>
      ) : null}

      {droneStations.map((droneStation: T.DroneStationContent, droneStationIdx) => (
        <DroneStationContentsListWrapper key={droneStationIdx}>
          <DroneStationContentsListHeader>
            <RawStatus taskStatus={droneStation.info.status} />
            <DroneStationName>{droneStation.info.nickname}</DroneStationName>
          </DroneStationContentsListHeader>

          <DroneStationContentsListDrone>
            <ImageWrapper>
              <Image
                src={TEMP_DRONE_IMAGE}
                alt={`${droneStation.info.children?.childDeviceSn} | Drone | Angelswing`}
              />
              <Text>{droneStation.info.children?.deviceName}</Text>
            </ImageWrapper>
            <VerticalFlexWrapper>
              <HighlightedText>Idle</HighlightedText>
              <ListItem>
                <Text>{l10n(Texts.droneFields.battery)}</Text>
                <Text>86%</Text>
              </ListItem>
              <ButtonWrapper>
                <Button onClick={handleDroneLiveCameraClick}>
                  {l10n(Texts.buttons.liveCamera)}
                </Button>
              </ButtonWrapper>
            </VerticalFlexWrapper>
          </DroneStationContentsListDrone>

          <DroneStationContentsListDock>
            <ImageWrapper>
              <Image src={TEMP_DOCK_STATION_IMAGE} alt="Dock Image | Angelswing" />
              <Text>{droneStation.info.deviceName}</Text>
            </ImageWrapper>
            <VerticalFlexWrapper>
              <HighlightedText>Standby</HighlightedText>
              <ListContainer>
                <ListItem>
                  <Text>{l10n(Texts.dockFields.temperature)}</Text>
                  <Text>33 deg/C </Text>
                </ListItem>
                <ListItem>
                  <Text>{l10n(Texts.dockFields.internetSpeed)}</Text>
                  <Text>10mb/s</Text>
                </ListItem>
              </ListContainer>
              <ButtonWrapper>
                <Button onClick={handleDockLiveCameraClick}>
                  {l10n(Texts.buttons.liveCamera)}
                </Button>
              </ButtonWrapper>
            </VerticalFlexWrapper>
          </DroneStationContentsListDock>
        </DroneStationContentsListWrapper>
      ))}
    </DroneStationWrapper>
  );
};

const DroneStationContentsList: FC = memo(() => {
  const [l10n]: UseL10n = useL10n();

  return (
    <DroneStationContentsListRoot>
      <ScrollBarsWrapper
        renderView={ScrollBarView}
        autoHide={true}
        autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
        autoHideDuration={HIDE_SCROLL_BAR_DURATION}
      >
        <Title>{l10n(Texts.title)}</Title>
        <HorizontalDividerWrapper>
          <HorizontalDivider />
        </HorizontalDividerWrapper>
        <Contents />
      </ScrollBarsWrapper>
    </DroneStationContentsListRoot>
  );
});

export default memo(withErrorBoundary(DroneStationContentsList)(Fallback));
