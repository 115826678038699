import { Viewer } from 'cesium';
import React, {
  ComponentType,
  FC,
  PropsWithChildren,
  useState,
  createContext,
  useContext,
} from 'react';

import CesiumInteraction from '../CesiumInteraction';

export interface CesiumContextProps {
  viewer?: Viewer;
  interaction?: CesiumInteraction;
  setViewer?(viewer?: Viewer): void;
  setInteraction?(interaction: CesiumInteraction): void;
}

export const CesiumContext = createContext<CesiumContextProps>({});

export const CesiumContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [viewer, setViewer] = useState<Viewer | undefined>();
  const [interaction, setInteraction] = useState<CesiumInteraction | undefined>();

  const value: CesiumContextProps = {
    viewer,
    interaction,
    setViewer,
    setInteraction,
  };

  return <CesiumContext.Provider value={value}>{children}</CesiumContext.Provider>;
};

/**
 * Custom hook to use the Cesium context
 */
export const useCesiumContext = () => {
  const context = useContext(CesiumContext);
  if (context === undefined) {
    throw new Error('useCesiumContext must be used within a CesiumContextProvider');
  }
  return context;
};

/**
 * Higher-Order Component to inject Cesium context as props
 */
export const withCesiumViewer = <T extends object>(
  TargetComponent: ComponentType<T & CesiumContextProps>
) => {
  const WrappedComponent: FC<T> = props => {
    const cesiumContext = useCesiumContext();
    return <TargetComponent {...props} {...cesiumContext} />;
  };

  return WrappedComponent;
};
