/* eslint-disable max-lines */
import { Reducer } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { action as makeAction, props, union } from 'tsdux';

import { FinishProps } from '../Utils';
import { PatchUserInfoBody, PostPasswordResetBody } from '../react-query/users';
import { actionsForEpicReload } from './API';
import { ChangeAuthedUser } from './Auth';
import { ShowSignUpTutorialPopup } from './Pages';
import * as T from '^/types';

// Redux actions

export const FinishGetUserInfo = makeAction('ddm/users/FINISH_GET_USER_INFO', props<FinishProps>());

export const PatchUserInfo = makeAction(
  'ddm/users/PATCH_USER_INFO',
  props<{
    readonly user: Pick<T.User, 'id'> & PatchUserInfoBody;
  }>()
);
export const CancelPatchUserInfo = makeAction('ddm/users/CANCEL_PATCH_USER_INFO');
export const FinishPatchUserInfo = makeAction(
  'ddm/users/FINISH_PATCH_USER_INFO',
  props<FinishProps>()
);

export const PostPasswordReset = makeAction(
  'ddm/users/POST_PASSWORD_RESET',
  props<{
    readonly resetPasswordRequestData: PostPasswordResetBody;
  }>()
);
export const CancelPostPasswordReset = makeAction('ddm/users/CANCEL_POST_PASSWORD_RESET');
export const FinishPostPasswordReset = makeAction(
  'ddm/users/FINISH_POST_PASSWORD_RESET',
  props<FinishProps>()
);

export const PatchPassword = makeAction(
  'ddm/users/PATCH_PASSWORD_ACTION',
  props<{
    readonly token: string;
    readonly password: T.UserPassword['password'];
  }>()
);
export const CancelPatchPassword = makeAction('ddm/users/CANCEL_PATCH_PASSWORD_ACTION');
export const FinishPatchPassword = makeAction(
  'ddm/users/FINISH_PATCH_PASSWORD_ACTION',
  props<FinishProps>()
);

export const PatchNotice = makeAction(
  'ddm/users/PATCH_NOTICE',
  props<{
    readonly notice: T.Notice;
  }>()
);
export const CancelPatchNotice = makeAction('ddm/users/CANCEL_PATCH_NOTICE');
export const FinishPatchNotice = makeAction('ddm/users/FINISH_PATCH_NOTICE', props<FinishProps>());

const Action = union([
  FinishGetUserInfo,

  PatchUserInfo,
  CancelPatchUserInfo,
  FinishPatchUserInfo,

  PostPasswordReset,
  CancelPostPasswordReset,
  FinishPostPasswordReset,

  PatchPassword,
  CancelPatchPassword,
  FinishPatchPassword,

  PatchNotice,
  CancelPatchNotice,
  FinishPatchNotice,

  // Out-duck actions
  ChangeAuthedUser,
  ShowSignUpTutorialPopup,
]);
export type Action = typeof Action;

export const epic: Epic<Action, Action, T.State> = combineEpics(
  actionsForEpicReload<Action>(
    // CancelGetUserInfo(),

    CancelPatchUserInfo(),
    CancelPostPasswordReset(),
    CancelPatchPassword()
    // CancelGetNotice()
  )
);

// Redux reducer
const initialState: T.UsersState = {
  getUserInfoStatus: T.APIStatus.IDLE,
  patchUserInfoStatus: T.APIStatus.IDLE,
  postPasswordResetStatus: T.APIStatus.IDLE,
  patchPasswordStatus: T.APIStatus.IDLE,
  getNoticeStatus: T.APIStatus.IDLE,
  patchNoticeStatus: T.APIStatus.IDLE,
};
const reducer: Reducer<T.UsersState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case FinishGetUserInfo.type:
      return {
        ...state,
        getUserInfoStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        getUserInfoError: action.error,
      };

    case PatchUserInfo.type:
      return {
        ...state,
        patchUserInfoStatus: T.APIStatus.PROGRESS,
        patchUserInfoError: undefined,
      };
    case CancelPatchUserInfo.type:
      return {
        ...state,
        patchUserInfoStatus: T.APIStatus.IDLE,
        patchUserInfoError: undefined,
      };
    case FinishPatchUserInfo.type:
      return {
        ...state,
        patchUserInfoStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        patchUserInfoError: action.error,
      };

    case PostPasswordReset.type:
      return {
        ...state,
        postPasswordResetStatus: T.APIStatus.PROGRESS,
        postPasswordResetError: undefined,
      };
    case CancelPostPasswordReset.type:
      return {
        ...state,
        postPasswordResetStatus: T.APIStatus.IDLE,
        postPasswordResetError: undefined,
      };
    case FinishPostPasswordReset.type:
      return {
        ...state,
        postPasswordResetStatus:
          action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        postPasswordResetError: action.error,
      };

    case PatchPassword.type:
      return {
        ...state,
        patchPasswordStatus: T.APIStatus.PROGRESS,
        patchPasswordError: undefined,
      };
    case CancelPatchPassword.type:
      return {
        ...state,
        patchPasswordStatus: T.APIStatus.IDLE,
        patchPasswordError: undefined,
      };
    case FinishPatchPassword.type:
      return {
        ...state,
        patchPasswordStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        patchPasswordError: action.error,
      };

    case PatchNotice.type:
      return {
        ...state,
        patchNoticeStatus: T.APIStatus.PROGRESS,
      };

    case CancelPatchNotice.type:
      return {
        ...state,
        patchNoticeStatus: T.APIStatus.IDLE,
        patchNoticeError: undefined,
      };

    case FinishPatchNotice.type:
      return {
        ...state,
        patchNoticeStatus: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        patchNoticeError: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
