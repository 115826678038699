import { Language } from '^/types';

//FIXME:  correct text
export default {
  title: {
    method: {
      [Language.KO_KR]: '방법',
      [Language.EN_US]: 'Method',
    },
    tooltipMethod: {
      [Language.KO_KR]: '지도를 편집하는 데 사용되는 방법',
      [Language.EN_US]: 'The method used to edit the map',
    },
  },
};
