import theme from '^/theme';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import styled, { CSSObject } from 'styled-components';

const TitleInput = styled.input<{ onLightBg?: boolean; customStyles?: CSSObject }>(
  ({ onLightBg, customStyles }) => ({
    fontSize: '14px',
    color: onLightBg ? theme.colors.black[70] : theme.colors.white[100],
    width: '100%',
    padding: '0',
    margin: '0',
    border: 'none',
    borderBottom: `1px solid ${onLightBg ? theme.colors.black[70] : theme.colors.white[100]}`,
    outline: 'none',
    backgroundColor: 'transparent',

    '&:focus': {
      borderBottom: `2px solid ${onLightBg ? theme.colors.black[70] : theme.colors.white[100]}`,
    },
    ...customStyles,
  })
);

const INPUT_LIMIT = 30;

interface Props {
  editedTitle: string;
  isEditing: boolean;
  setEditedTitle(title: string): void;
  setIsEditing(isEditing: boolean): void;
  onSave?(): void;
  onLightBg?: boolean;
  customStyles?: CSSObject;
}
const PhotoAlbumTitleInput = ({
  editedTitle,
  isEditing,
  setEditedTitle,
  setIsEditing,
  onSave,
  onLightBg,
  customStyles,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSaveTitle = () => {
    if (editedTitle.trim()) {
      onSave?.();
      setIsEditing(false);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveTitle();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const handleTitleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > INPUT_LIMIT) {
      return;
    }
    setEditedTitle(e.target.value);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  return (
    <TitleInput
      ref={inputRef}
      value={editedTitle}
      onChange={handleTitleInputChange}
      onBlur={handleSaveTitle}
      onKeyDown={handleKeyDown}
      onClick={e => e.stopPropagation()}
      maxLength={INPUT_LIMIT}
      onLightBg={onLightBg}
      customStyles={customStyles}
    />
  );
};

export default PhotoAlbumTitleInput;
