import axios from 'axios';

/**
 * @autor Junyoung Clare Jang
 * @desc Fri Jan 19 22:02:25 2018 UTC
 * This code depends on `window` object.
 * It can make problems with SSR.
 * @todo remove istanbul ignore (add tests)
 */
/* istanbul ignore next */
const download: (url: string, usePopup: boolean, useBlob: boolean, name?: string) => void = (
  url,
  usePopup,
  useBlob,
  name
) => {
  if (usePopup) {
    window.open(url);
    return;
  }

  const fileName: string =
    name !== undefined ? name : url.substring(url.lastIndexOf('/') + 1).split('?')[0];

  const temp: HTMLAnchorElement = document.createElement('a');
  temp.href = url;

  /**
   * @desc Browser checks whether URL is valid or not
   */
  if (temp.href.indexOf(url) !== -1) {
    /**
     * @fixme `useBlob` cases could have performance issue.
     */
    if (useBlob) {
      /**
       * @desc Following code depends on `window`.
       */
      void axios({
        url: url,
        method: 'get',
        responseType: 'blob',
        withCredentials: true,
      }).then(response => {
        const blob = response.data;
        if ((window.navigator as any).msSaveOrOpenBlob !== undefined) {
          (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
        } else {
          download(window.URL.createObjectURL(blob), false, false, name);
        }
      });
    } else {
      temp.href = url;
      temp.download = fileName;
      temp.target = '_blank';
      temp.style.display = 'none';
      document.body.appendChild(temp);
      temp.click();
      document.body.removeChild(temp);
    }
  }
};

export const simpleDownload: (url: string, name?: string) => void = async (url, name) => {
  const fileName: string =
    name !== undefined ? name : url.substring(url.lastIndexOf('/') + 1).split('?')[0];

  const response = await axios.get(url, {
    responseType: 'blob',
    withCredentials: true,
  });
  const blob = response.data;

  const contentUrl = URL.createObjectURL(blob);
  const temp: HTMLAnchorElement = document.createElement('a');
  temp.href = contentUrl;
  temp.download = fileName;
  temp.style.display = 'none';

  document.body.appendChild(temp);
  temp.click();
  document.body.removeChild(temp);

  URL.revokeObjectURL(contentUrl);
};

export default download;
