import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';
import { Tuple } from '^/components/molecules/MyPage/Tuple';
import theme from '^/theme';
import { useAuthUserQuery } from '^/store/react-query/users';
import Text from './text';
import { useL10n } from '^/hooks';
import { useDispatch } from 'react-redux';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import UserSvg from '^/assets/icons/mypage/user.svg';
import CameraSvg from '^/assets/icons/mypage/camera-icon.svg';
import { usePopupStore } from '^/store/popup';
import * as T from '^/types';
const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

const AvatarWrapper = styled.div({
  position: 'relative',
  height: '100px',
  width: '100px',
  marginTop: '20px',
});

const AvatarContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#56CB8C',
  borderRadius: '50%',
});

const CameraButtonWrapper = styled.button({
  display: 'flex',
  position: 'absolute',
  bottom: '0',
  right: '0',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  ':hover': {
    filter: 'brightness(0.9)',
  },
});

const AvatarImage = styled.img({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  objectFit: 'cover',
});

const MyPageAccount = () => {
  const [l10n] = useL10n();
  const { data: authedUser } = useAuthUserQuery();
  const dispatch = useDispatch();

  const setMinorPopup = usePopupStore(s => s.setMinorPopup);
  const setPopupOptions = usePopupStore(s => s.setPopupOptions);
  const fullName = useMemo(() => {
    if (!authedUser?.firstName && !authedUser?.lastName) {
      return 'N/A';
    }
    if (authedUser?.firstName && authedUser?.lastName) {
      return `${authedUser.firstName} ${authedUser.lastName}`;
    }
    return authedUser?.firstName ? authedUser.firstName : authedUser?.lastName;
  }, [authedUser?.firstName, authedUser?.lastName]);

  const logout = () => {
    dispatch(ChangeAuthedUser({}));
  };

  const handleOpenPopup = (type: T.MyPagePopupContentTypes) => {
    setPopupOptions({ myPagePopupType: type });
    setMinorPopup(T.ContentPageMinorPopupType.MY_PAGE_POPUP);
  };

  const avatar = (
    <AvatarWrapper>
      <AvatarContainer>
        {authedUser?.avatar ? <AvatarImage src={authedUser.avatar} /> : <UserSvg />}
      </AvatarContainer>
      <CameraButtonWrapper>
        <CameraSvg />
      </CameraButtonWrapper>
    </AvatarWrapper>
  );

  return (
    <Root>
      <GroupedSection title={l10n(Text.account.profile.title)} componentToShowWithTitle={avatar}>
        <Tuple
          title={l10n(Text.account.profile.email)}
          value={authedUser?.email}
          boldValue={true}
          nonEditable={true}
        />
        <Tuple
          title={l10n(Text.account.profile.name)}
          value={fullName}
          boldValue={true}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_NAME)}
        />
        <Tuple
          title={l10n(Text.account.profile.phoneNumber)}
          value={authedUser?.contactNumber}
          boldValue={true}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_PHONE)}
        />
      </GroupedSection>
      <GroupedSection title={l10n(Text.account.affiliation.title)} topBorder={true}>
        <Tuple
          title={l10n(Text.account.affiliation.organization)}
          value={authedUser?.organization}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_ORGANIZATION)}
        />
        <Tuple
          title={l10n(Text.account.affiliation.purpose)}
          value={authedUser?.purpose}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_PURPOSE)}
        />
      </GroupedSection>
      <GroupedSection title={l10n(Text.account.session.title)} topBorder={true}>
        <Tuple
          title={l10n(Text.account.session.signOut)}
          titleCustomStyles={{
            fontWeight: '700',
            color: theme.colorTokens.errorRed,
          }}
          onClick={logout}
        />
      </GroupedSection>
    </Root>
  );
};

export default MyPageAccount;
