/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-lines */
import { ellipsoidToEgm96 } from 'egm96-universal';
import {
  Color,
  DoubleSide,
  LinearFilter,
  LoadingManager,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  SphereGeometry,
  Sprite,
  SpriteMaterial,
  Texture,
  TextureLoader,
  TorusGeometry,
} from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
//@ts-ignore
import { nonSequentialPanoSvgToBase64, svgToBase64For360 } from '^/assets/icons/imageBase64';
import { PhotoType } from '^/types';
import { autobind } from 'core-decorators';
import { Runtime } from 'three-loader-3dtiles';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { degToRad } from 'three/src/math/MathUtils';
import { DisposeHelper } from '../../Lib/Helper';
import { SpriteMaterialPool } from '../../Lib/Pools/SpriteMaterialPool';

export interface GeoData {
  longitude: number;
  latitude: number;
  altitude: number;
  gimbalYawDegree: number;
  gimbalPitchDegree: number;
  gimbalRollDegree: number;
  width: number;
  height: number;
  focalLength: number;
  relativeAltitude: number;
  calibratedFocalLength: number;
  absoluteAltitude: number;
  sphRecalibrated: boolean;
  make: string;
  model: string;
}

const threeSixtyLoadingManager = new LoadingManager();
const HIGHLIGHT_COLOR = new Color();
const TEXTURE_LOADER = new TextureLoader();
const KTX2_LOADER = new KTX2Loader();

const SPRITE_MATERIAL = new SpriteMaterial({
  sizeAttenuation: false,
  map: TEXTURE_LOADER.setCrossOrigin('anonymous').load(svgToBase64For360(), texture => {
    texture.colorSpace = 'srgb';
  }),
});
const MATERIAL_POOL: SpriteMaterialPool = new SpriteMaterialPool(
  SPRITE_MATERIAL,
  svgToBase64For360
);

const SPRITE_MATERIAL_NEW = new SpriteMaterial({
  sizeAttenuation: false,
  map: TEXTURE_LOADER.setCrossOrigin('anonymous').load(
    nonSequentialPanoSvgToBase64(new Color('white'))
  ),
  opacity: 0.5,
  depthTest: false,
});

const MATERIAL_POOL_NEW: SpriteMaterialPool = new SpriteMaterialPool(
  SPRITE_MATERIAL_NEW,
  nonSequentialPanoSvgToBase64
);

TEXTURE_LOADER.setCrossOrigin('use-credentials');
KTX2_LOADER.setWithCredentials(true);

export const PANORAMA_MATERIAL = new MeshBasicMaterial({
  side: DoubleSide,
  transparent: true,
  depthTest: false,
});

const createLoaderActionHandler =
  (domElement: HTMLCanvasElement, actionType: string, isInitial?: boolean) => () => {
    if (isInitial) {
      switch (actionType) {
        case 'start':
          domElement.style.visibility = 'hidden';
          domElement.parentElement!.style.background = '#9BA9C0';
          break;
        case 'load':
        case 'error':
        default:
          domElement.style.visibility = 'visible';
          domElement.parentElement!.style.background = 'transparent';
          break;
      }
    }
  };

const getMakerFn = (make: string | undefined) => {
  let minDistance, maxDistance;
  switch (make) {
    case 'Arashi Vision':
      minDistance = 25;
      maxDistance = 100;
      break;
    case 'DJI':
    default:
      minDistance = 200;
      maxDistance = 1000;
      break;
  }

  return { minDistance, maxDistance };
};

export class PhotoThreeSixtyObject extends Object3D {
  public altitude: number = 0;
  public focalLength: number = 0;
  public readonly contentId: string | number;
  public readonly contentType: PhotoType | undefined;
  private readonly tilesRuntime: Runtime;
  public readonly photoUrl: string;
  public readonly thumbUrl: string;
  private readonly viewer: Viewer;
  public spriteIcon: Sprite | undefined;
  public panoramaSphere: Mesh | undefined;
  private isNewIcon: boolean = false;
  private depthTest: boolean = false;
  private readonly make: string | undefined;
  private readonly model: string | undefined;
  public isHighResolution: boolean = false;
  private imageTexture: Texture | null = null;
  public thumbUrlTexture: Texture | null = null;

  public baseSize: number = 0.75;
  public minOpacity: number = 0.1;
  public maxOpacity: number = 0.5;
  public aspect = 1;
  public constructor(
    contentId: string | number,
    viewer: Viewer,
    photoUrl: string,
    thumbUrl: string,
    geoData: GeoData,
    tilesRuntime: Runtime,
    contentType: PhotoType | undefined
  ) {
    super();
    geoData.gimbalPitchDegree = geoData.sphRecalibrated
      ? geoData.gimbalPitchDegree - 90
      : geoData.gimbalPitchDegree;
    this.name = contentId.toString();
    this.contentId = contentId;
    this.contentType = contentType;
    this.viewer = viewer;
    this.photoUrl = photoUrl;
    this.thumbUrl = thumbUrl;
    this.tilesRuntime = tilesRuntime;
    this.make = geoData.make;
    this.model = geoData.model;
    this.altitude = geoData.sphRecalibrated
      ? geoData.altitude
      : Boolean(this.viewer.metadata && this.viewer.metadata.totalErrorAlt)
      ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        geoData.absoluteAltitude + this.viewer.metadata!.totalErrorAlt!
      : !this.viewer.metadata?.hasGCP
      ? geoData.absoluteAltitude
      : ellipsoidToEgm96(geoData.latitude, geoData.longitude, 0) + geoData.absoluteAltitude;
    this.renderOrder = 10000000;
    this.rotation.set(Math.PI / 2, -degToRad(geoData.gimbalYawDegree - 90), 0, 'XZY');
    // this.rotation.set(Math.PI / 2, Math.PI / 2, 0, 'XZY');
    this.scale.set(1, 1, -1);
    this.initPosition(geoData);
    // this.preloadThumbTexture();
  }

  /**
   * @public
   * @param {boolean} depthTest
   * @returns {void}
   */
  @autobind
  public setDepthTest(depthTest: boolean): void {
    this.depthTest = depthTest;
    this.spriteIcon!.material.depthTest = this.depthTest;
  }

  /**
   * @private
   * @description Funtion init position for photo album
   * @param {GeoData} geoData
   * @returns {void}
   */
  @autobind
  private initPosition(geoData: GeoData): void {
    const position = this.tilesRuntime.getPositionFromLatLongHeight({
      long: geoData.longitude,
      lat: geoData.latitude,
      height: geoData.altitude,
    });
    this.position.copy(position);

    this.spriteIcon = new Sprite(MATERIAL_POOL.getMaterial('#ffffff').clone());
    this.spriteIcon.renderOrder = 1000000;
    this.spriteIcon.scale.set(0.045, 0.045, 0.045);
    this.add(this.spriteIcon);
    this.spriteIcon.userData.url = this.photoUrl;
    // this.spriteIcon.material.depthTest = this.depthTest;

    const yaw = degToRad(geoData?.gimbalYawDegree);
    const pitch = degToRad(geoData?.gimbalPitchDegree);
    const roll = degToRad(geoData?.gimbalRollDegree);

    /**
     * @ebraj-angelswing
     * Checking for orientation
     */
    const torusGeometry = new TorusGeometry(10, 3, 16, 100);
    const torusMaterial = new MeshBasicMaterial({ color: 0xff0000 });
    const torus = new Mesh(torusGeometry, torusMaterial);
    torus.rotation.set(pitch, yaw, roll, 'XYZ');
    torus.position.copy(position);
    // this.viewer.scene.add(torus);
  }

  @autobind
  private initPanoramaSphere() {
    const geometry = new SphereGeometry(2000, 60, 40);
    this.panoramaSphere = new Mesh(geometry, PANORAMA_MATERIAL);
    this.panoramaSphere.name = 'panoramaSphere';
    this.panoramaSphere.visible = false;
    this.add(this.panoramaSphere);
  }

  @autobind
  public preloadThumbTexture() {
    if (!this.thumbUrlTexture) {
      TEXTURE_LOADER.load(this.thumbUrl, (texture: Texture) => {
        if (!this.isHighResolution) {
          texture.colorSpace = 'srgb';
          this.thumbUrlTexture = texture;
        }
      });
    }
  }

  @autobind
  public async setTexture(isInitial?: boolean): Promise<void> {
    const domElement = this.viewer.renderer.domElement;
    if (this.panoramaSphere) {
      return;
    }

    TEXTURE_LOADER.manager = threeSixtyLoadingManager;
    threeSixtyLoadingManager.onStart = createLoaderActionHandler(domElement, 'start', isInitial);
    threeSixtyLoadingManager.onLoad = () => {
      createLoaderActionHandler(domElement, 'load', isInitial)();
      TEXTURE_LOADER.manager = new LoadingManager();
    };
    threeSixtyLoadingManager.onError = createLoaderActionHandler(domElement, 'error', isInitial);

    if (!this.imageTexture && this.photoUrl) {
      this.initPanoramaSphere();
      if (!this.thumbUrlTexture) {
        /**
         * @ebraj-angelswing
         * This was needed so that we do the transition only after the texture is loaded...
         */
        await new Promise<void>((resolve, reject) => {
          TEXTURE_LOADER.load(
            this.thumbUrl,
            (texture: Texture) => {
              if (!this.isHighResolution) {
                texture.colorSpace = 'srgb';
                this.imageTexture = texture;
                this.thumbUrlTexture = texture;
                (this.panoramaSphere!.material as MeshBasicMaterial).map = this.thumbUrlTexture;
              }
              resolve();
            },
            undefined,
            reject
          );
        });
      } else {
        (this.panoramaSphere!.material as MeshBasicMaterial).map = this.thumbUrlTexture;
      }

      const ktxFile = this.photoUrl.replace(/\.(jpg|jpeg|png)$/i, '.ktx2');
      this.loadKTX2AndImage(ktxFile);
    }
  }

  @autobind
  private loadKTX2AndImage(ktxFile: string) {
    KTX2_LOADER.setTranscoderPath('/ktx-files/ktx2-transcoders/');
    KTX2_LOADER.detectSupport(this.viewer.renderer);

    KTX2_LOADER.load(
      ktxFile,
      async texture => {
        const fixedTexture = texture;

        fixedTexture.offset.y = 1;
        fixedTexture.repeat.y = -1;

        this.imageTexture = fixedTexture;
        this.panoramaSphere!.material = new MeshBasicMaterial({
          map: this.imageTexture,
          side: DoubleSide,
        });
      },
      undefined,
      () => {
        this.loadHighResTexture();
      }
    );
  }

  /**
   * @private
   * @description load high resolution texture
   * @returns {void}
   */
  @autobind
  private loadHighResTexture(): void {
    this.imageTexture?.dispose();
    TEXTURE_LOADER.load(this.photoUrl, (texture: Texture) => {
      this.isHighResolution = true;
      this.imageTexture = texture;
      texture.colorSpace = 'srgb';
      texture.minFilter = texture.magFilter = LinearFilter;
      (this.panoramaSphere!.material as MeshBasicMaterial).map = texture;
    });
  }

  @autobind
  public togglePlaneImage(visible: boolean): void {
    if (this.panoramaSphere) {
      this.panoramaSphere.visible = visible;
    }
  }

  @autobind
  public updateIconToNew(is360View: boolean): void {
    if (this.spriteIcon) {
      this.isNewIcon = is360View;
      if (is360View) {
        this.spriteIcon.material = MATERIAL_POOL_NEW.getMaterial('#ffffff').clone();
      } else {
        this.spriteIcon.material = MATERIAL_POOL.getMaterial('#ffffff').clone();
      }
    }
  }

  @autobind
  public setVisible(visible: boolean): void {
    this.visible = visible;
  }

  @autobind
  public setHighlight(color: number = 0x5891ff): void {
    HIGHLIGHT_COLOR.set(color);
    if (this.isNewIcon) {
      this.spriteIcon!.material = MATERIAL_POOL_NEW.getMaterial(
        '#' + HIGHLIGHT_COLOR.getHexString()
      ).clone();
      this.spriteIcon!.material.needsUpdate = true;
    }
    /**
     * TODO: @ebraj-angelswing
     * Not setting the depthtest for now as we do not want it to hide under the terrain
     */
    // this.spriteIcon!.material.depthTest = this.depthTest;
  }

  @autobind
  public setUnHighlight(): void {
    if (this.isNewIcon) {
      this.spriteIcon!.material = MATERIAL_POOL_NEW.getMaterial('#ffffff').clone();
      this.spriteIcon!.material.needsUpdate = true;
    }
    // this.spriteIcon!.material.depthTest = this.depthTest;
  }

  /**
   * @public
   * @description This function will auto-call when the scene render
   * @param {boolean | undefined} force
   */
  @autobind
  public updateMatrixWorld(force?: boolean | undefined) {
    super.updateMatrixWorld(force);
    if (this.isNewIcon) {
      const threeSixtyCamera = this.viewer.controllers.photoAlbumController!.getThreeSixtyCamera;
      const distanceBetween = threeSixtyCamera?.position.distanceTo(this.position);
      const { minDistance, maxDistance } = getMakerFn(this.make);

      if (distanceBetween) {
        const clampedDistance = Math.min(Math.max(distanceBetween, minDistance), maxDistance);

        const opacity =
          this.maxOpacity -
          ((clampedDistance - minDistance) / (maxDistance - minDistance)) *
            (this.maxOpacity - this.minOpacity);

        if (this.spriteIcon!.material) {
          this.spriteIcon!.material.opacity = opacity;
          this.spriteIcon!.material.transparent = true;
        }
      }
    }
  }

  /**
   * @public
   * @description dispose all
   * @returns { void}
   */
  @autobind
  public dispose(): void {
    DisposeHelper.disposeHierarchy(this);
  }
}
