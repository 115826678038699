import * as T from '^/types';

export default {
  header: {
    title: {
      [T.Language.KO_KR]: '사진 갤러리',
      [T.Language.EN_US]: 'Photo Gallery',
    },
    text: {
      [T.Language.KO_KR]: '사진 뷰어 시작하기',
      [T.Language.EN_US]: 'Start Photo Viewer',
    },
    subtext: {
      [T.Language.KO_KR]: '사진 뷰어를 시작하려면, 이와 같은 방법으로 업로드 해보세요.',
      [T.Language.EN_US]:
        'To start the Photo Viewer, upload your photos using the following methods.',
    },
    back: {
      [T.Language.KO_KR]: '이전',
      [T.Language.EN_US]: 'Back',
    },
    droneMode: {
      [T.Language.KO_KR]: '드론 모드',
      [T.Language.EN_US]: 'Drone Mode',
    },
    threeSixtyMode: {
      [T.Language.KO_KR]: '360 모드',
      [T.Language.EN_US]: '360 Mode',
    },
    threeSixtySourceMode: {
      [T.Language.KO_KR]: '360 스티치 모드',
      [T.Language.EN_US]: '360 Source Mode',
    },
    threeSixtyStitchedMode: {
      [T.Language.KO_KR]: '360 모드',
      [T.Language.EN_US]: '360 Stitched Mode',
    },
    threeSixtyVideoMode: {
      [T.Language.KO_KR]: '360 VSLAM 비디오 모드',
      [T.Language.EN_US]: '360 VSLAM Videos Mode',
    },
    threeSixtyVslamMode: {
      [T.Language.KO_KR]: '360° VSLAM 모드',
      [T.Language.EN_US]: '360° VSLAM Mode',
    },
    sourcePhoto: {
      [T.Language.KO_KR]: '소스포토',
      [T.Language.EN_US]: 'Source Photo',
    },
    viewpoint: {
      [T.Language.KO_KR]: '요청 사진',
      [T.Language.EN_US]: 'Requested Photo',
    },
  },
  description: {
    clickUploadButton: {
      [T.Language.KO_KR]: '업로드 버튼을 클릭하고',
      [T.Language.EN_US]: 'Click the Upload button and',
    },
    selectPhoto: {
      [T.Language.KO_KR]: "'사진' 을 선택하세요.",
      [T.Language.EN_US]: "select 'Job Site Photos'.",
    },
  },
  goBack: {
    [T.Language.KO_KR]: '뒤로가기',
    [T.Language.EN_US]: 'Go back',
  },
  noDate: {
    [T.Language.KO_KR]: '촬영일자가 없는 사진/비디오',
    [T.Language.EN_US]: 'Photos/Videos without dates',
  },
  noDateSubText: {
    [T.Language.KO_KR]: '태그를 지정할 사진을 선택해주세요.',
    [T.Language.EN_US]: 'Select the photos that you want to tag',
  },
  noDateSelectText: {
    [T.Language.KO_KR]: '모두 선택',
    [T.Language.EN_US]: 'Select all',
  },
  photo: {
    viewTooltip: {
      [T.Language.KO_KR]: '지도에서 사진 보기',
      [T.Language.EN_US]: 'View photo on map',
    },
    noLocationTooltip: {
      [T.Language.KO_KR]: '이 사진은 위치 데이터가 없습니다',
      [T.Language.EN_US]: 'This photo does not have location data',
    },
    selectMonthTooltip: {
      [T.Language.KO_KR]: '모든 사진 선택',
      [T.Language.EN_US]: 'Select all photos',
    },
    itemSelectedTooltip: (items: number) => ({
      [T.Language.KO_KR]: `${items}장 선택됨`,
      [T.Language.EN_US]: `${items} ${items > 1 ? 'items' : 'item'} selected`,
    }),
    deselectToolTip: {
      [T.Language.KO_KR]: '모두 선택 해제',
      [T.Language.EN_US]: 'Unselect all',
    },
    editDatesTooltip: {
      [T.Language.KO_KR]: '날짜 수정',
      [T.Language.EN_US]: 'Edit dates',
    },
    deleteTooltip: {
      [T.Language.KO_KR]: '삭제',
      [T.Language.EN_US]: 'Delete',
    },
    DownloadTooltip: {
      [T.Language.KO_KR]: '선택한 항목 다운로드',
      [T.Language.EN_US]: 'Download selected items',
    },
    DownloadProgressTooltip: {
      [T.Language.KO_KR]: '진행중인 다운로드가 완료될 때까지 잠시 기다려주세요.',
      [T.Language.EN_US]: 'Please wait until the current download is complete.',
    },
    zippedTooltip: {
      [T.Language.KO_KR]: '압축중...',
      [T.Language.EN_US]: 'Zipping photos...',
    },
    downloadProgressMsg: {
      [T.Language.KO_KR]: '다운로드 중...',
      [T.Language.EN_US]: 'Downloading...',
    },
    downloadCompleteMsg: {
      [T.Language.KO_KR]: '다운로드를 완료했습니다.',
      [T.Language.EN_US]: 'Download complete.',
    },
    addToAlbum: {
      [T.Language.KO_KR]: '앨범에 추가',
      [T.Language.EN_US]: 'Add to Album',
    },
    videosCannotBeAdded: {
      [T.Language.EN_US]: 'Videos cannot be added into albums',
      [T.Language.KO_KR]: '비디오는 앨범에 추가할 수 없습니다',
    },
  },
  drone: {
    uploadsText: {
      [T.Language.KO_KR]: '드론 업로드',
      [T.Language.EN_US]: 'Drone Uploads',
    },
    photos: {
      [T.Language.KO_KR]: '사진',
      [T.Language.EN_US]: 'photos',
    },
    videos: {
      [T.Language.KO_KR]: '동영상',
      [T.Language.EN_US]: 'videos',
    },
    surveyText: {
      [T.Language.KO_KR]: '측량 업로드',
      [T.Language.EN_US]: 'Survey Uploads',
    },
    userText: {
      [T.Language.KO_KR]: '날짜기준',
      [T.Language.EN_US]: 'Timeline',
    },
    sourcePhotoProcessing: {
      [T.Language.KO_KR]: '소스포토 처리가 완료될 때까지 기다려주세요.',
      [T.Language.EN_US]: 'Please wait until the source photo processing is done.',
    },
    flightVideoTitle: {
      [T.Language.KO_KR]: '비행 동영상',
      [T.Language.EN_US]: 'Flight Video',
    },
    flightVideoProcessing: {
      [T.Language.KO_KR]: '비행 동영상 처리가 완료될 때까지 기다려주세요.',
      [T.Language.EN_US]: 'Please wait until the flight video processing is done.',
    },
    flightVideoProcessingText: {
      [T.Language.KO_KR]: '비행 동영상 처리중...',
      [T.Language.EN_US]: 'Processing...',
    },
    timelapse: {
      [T.Language.KO_KR]: '위치기준',
      [T.Language.EN_US]: 'Location',
    },
    album: {
      [T.Language.KO_KR]: 'Album',
      [T.Language.EN_US]: 'Album',
    },
  },
  processingPano: {
    [T.Language.KO_KR]: '360 파노라마 생성하기',
    [T.Language.EN_US]: 'Generating 360 Panorama',
  },

  filterContentTexts: {
    all: {
      [T.Language.KO_KR]: 'All',
      [T.Language.EN_US]: 'All',
    },
    overlay: {
      [T.Language.KO_KR]: 'Overlay Videos',
      [T.Language.EN_US]: 'Overlay Videos',
    },
    normal: {
      [T.Language.KO_KR]: '전경사진',
      [T.Language.EN_US]: 'Bird Eye View Photos',
    },
    source: {
      [T.Language.KO_KR]: '소스포토',
      [T.Language.EN_US]: 'Source Photos',
    },
    drone: {
      [T.Language.KO_KR]: '시설물 점검사진',
      [T.Language.EN_US]: 'Inspection Photos',
    },
    three_sixty: {
      [T.Language.KO_KR]: '360',
      [T.Language.EN_US]: '360° Photos',
    },
    three_sixty_source: {
      [T.Language.KO_KR]: '360° 원본 사진',
      [T.Language.EN_US]: '360° Source Photos',
    },
    three_sixty_stitched: {
      [T.Language.KO_KR]: '360° 스티치 사진',
      [T.Language.EN_US]: '360° Stitched Photos',
    },
    three_sixty_video: {
      [T.Language.KO_KR]: '360° VSLAM 비디오',
      [T.Language.EN_US]: '360° VSLAM Videos',
    },
    viewpoint: {
      [T.Language.KO_KR]: '요청 사진',
      [T.Language.EN_US]: 'Requested Photos',
    },
    three_sixty_vslam: {
      [T.Language.KO_KR]: '360° VSLAM 사진',
      [T.Language.EN_US]: '360° VSLAM Photos',
    },
  },
  filterText: {
    [T.Language.KO_KR]: '필터',
    [T.Language.EN_US]: 'Filter',
  },
};
