import * as T from '^/types';

export default {
  groupListHeader: {
    pinned: {
      [T.ContentPageTabType.MAP]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.OVERLAY]: {
        [T.Language.KO_KR]: '도면 그룹 목록',
        [T.Language.EN_US]: 'Overlay groups',
      },
      [T.ContentPageTabType.MEASUREMENT]: {
        [T.Language.KO_KR]: '모든 지도에서 볼 수 있는 측정',
        [T.Language.EN_US]: 'Measurements in all maps',
      },
      [T.ContentPageTabType.PHOTO]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.ESS]: {
        [T.Language.KO_KR]: '안전작업계획 그룹 목록',
        [T.Language.EN_US]: 'List of safety workplan group',
      },
      [T.ContentPageTabType.ISSUE]: {
        [T.Language.KO_KR]: '작업 목록',
        [T.Language.EN_US]: 'Issues in the Map',
      },
      [T.ContentPageTabType.DASHBOARD]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.DRONE_STATION]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_PLAN]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_SCHEDULE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.PRESENTATION]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.VIEWPOINT_CAPTURE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
    },
    unpinned: {
      [T.ContentPageTabType.MAP]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.OVERLAY]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.MEASUREMENT]: {
        [T.Language.KO_KR]: '현재 지도에서만 볼 수 있는 측정',
        [T.Language.EN_US]: 'Measurements in current map',
      },
      [T.ContentPageTabType.PHOTO]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.ESS]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.ISSUE]: {
        [T.Language.KO_KR]: '작업 목록',
        [T.Language.EN_US]: 'Issues in the Map',
      },
      [T.ContentPageTabType.DASHBOARD]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.DRONE_STATION]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_PLAN]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_SCHEDULE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.PRESENTATION]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.VIEWPOINT_CAPTURE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
    },
    groupBy: {
      [T.Language.KO_KR]: '그룹 기준',
      [T.Language.EN_US]: 'Group By',
    },
    groupByPlaceholder: {
      [T.Language.KO_KR]: '기준 선택',
      [T.Language.EN_US]: 'Select',
    },
    [T.ContentPageTabType.VIEWPOINT_CAPTURE]: {
      [T.Language.KO_KR]: '',
      [T.Language.EN_US]: '',
    },
  },
};
