import { AuthHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { APIToContent, PatchContent } from '^/store/duck/Contents';
import { SetLoader } from '^/store/duck/Pages';
import { contentsStore } from '^/store/zustand/content/contentStore';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { getSingleContentId } from '^/utilities/state-util';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

export function useCalibrateHeight() {
  const dispatch: Dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);

  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const Pages = useSelector((s: T.State) => s.Pages);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);
  const dsmId = getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.DSM);
  const meshId = getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.THREE_D_MESH);

  async function calibrateOffsetHeight(offsetCalibrated: boolean) {
    dispatch(SetLoader({ loadingMesh: true }));

    if (projectId === undefined || authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }
    const header = makeAuthHeader(Auth, slug);

    const URL: string = makeV2APIURL('projects', projectId, 'contents', 'offset_height');
    const body = {
      dsmId,
      tilesetId: meshId,
      info: {
        offsetCalibrated,
      },
    };
    try {
      setIsLoading(true);
      void http.patch(URL, body, { headers: header }).then(res => {
        const content = APIToContent(res.data.data);
        contentsStore.getState().addContent(content);
        dispatch(
          PatchContent({
            content: {
              id: meshId!,
              config: {
                selectedAt: new Date(),
              },
            },
          })
        );
        setIsLoading(false);
        dispatch(SetLoader({ loadingMesh: false }));
      });
    } catch (error) {
      setIsLoading(false);
      dispatch(SetLoader({ loadingMesh: false }));
    }
  }

  return { calibrateOffsetHeight, isLoading };
}
