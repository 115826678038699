/* eslint-disable max-lines */
import { LensGenerator, LensS } from '@typed-f/lens';
import _ from 'lodash-es';
import { pathToRegexp } from 'path-to-regexp';
import { Reducer } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, mergeMapTo, takeUntil } from 'rxjs/operators';
import { action as makeAction, props, union } from 'tsdux';
import { ofType } from 'tsdux-observable';

import { FinishProps } from '../Utils';

import {
  FileMultipartUpload,
  FileMultipartUploadResponse,
  isFileMultipartUploadProgress,
} from '../rxjs/fileMultipartUpload';

import {
  FileUploadProgress,
  FileUploadResponse,
  fileUpload,
  isFileUploadProgress,
} from '../rxjs/fileUpload';

import {
  AuthHeader,
  ShareHeader,
  getRequestErrorType,
  makeAuthHeader,
  makeShareHeader,
  makeV2APIURL,
  jsonContentHeader,
} from './API';
import { ChangeAuthedUser } from './Auth';
import { EPIC_RELOAD } from '^/constants/epic';
import routes from '^/constants/routes';
import * as T from '^/types';
import { calculateHash } from '^/utilities/file-util';
import { APIToPhoto, APIToSourcePhoto, UpdatePhotosInStore } from './Photos';
import { rxjsHttp } from '^/utilities/api';
// API related types
interface PostAttachmentBody {
  readonly file: File;
  readonly type: T.AttachmentType;
}

export interface PostAttachmentNewResponse {
  readonly url: string;
  readonly 'aws-id': string;
  readonly bucket: string;
  readonly region: string;
  readonly key: string;
}

export const APIToAttachment: (rawAttachment: T.APIAttachment) => T.Attachment = rawAttachment => ({
  ...rawAttachment.attributes,
  id: Number(rawAttachment.id),
  createdAt: new Date(rawAttachment.attributes.createdAt),
  updatedAt: new Date(rawAttachment.attributes.updatedAt),
});

interface GetAttachmentsResponse {
  readonly data: T.APIAttachment[];
}
interface PostAttachmentResponse {
  readonly data: T.APIAttachment;
}

interface AttachmentCallbackBody {
  readonly key: string;
}

//Redux actions

export const GetAttachments = makeAction(
  'ddm/attachments/GET_ATTACHMENTS',
  props<
    Readonly<{
      contentId: T.Content['id'];
    }>
  >()
);
export const CancelGetAttachments = makeAction(
  'ddm/attachments/CANCEL_GET_ATTACHMENTS',
  props<
    Readonly<{
      contentId: T.Content['id'];
    }>
  >()
);
export const FinishGetAttachments = makeAction(
  'ddm/attachments/FINISH_GET_ATTACHMENTS',
  props<
    Readonly<{
      contentId: T.Content['id'];
    }> &
      FinishProps
  >()
);

export const GetProcessingAttachments = makeAction(
  'ddm/attachments/GET_PROCESSING_ATTACHMENTS',
  props<
    Readonly<{
      projectId: T.Project['id'];
    }>
  >()
);
export const CancelGetProcessingAttachments = makeAction(
  'ddm/attachments/CANCEL_GET_PROCESSING_ATTACHMENTS',
  props<
    Readonly<{
      projectId: T.Project['id'];
    }>
  >()
);
export const FinishGetProcessingAttachments = makeAction(
  'ddm/attachments/FINISH_GET_PROCESSING_ATTACHMENTS',
  props<
    Readonly<{
      projectId: T.Project['id'];
    }> &
      FinishProps
  >()
);

export const PostAttachment = makeAction(
  'ddm/attachments/POST_ATTACHMENT',
  props<
    Readonly<{
      contentId: T.Content['id'];
      file: PostAttachmentBody['file'];
      attachmentType: PostAttachmentBody['type'];
    }>
  >()
);
export const CancelPostAttachment = makeAction(
  'ddm/attachments/CANCEL_POST_ATTACHMENT',
  props<
    Readonly<{
      contentId: T.Content['id'];
      hash: string;
    }>
  >()
);
export const FinishPostAttachment = makeAction(
  'ddm/attachments/FINISH_POST_ATTACHMENT',
  props<
    Readonly<{
      contentId: T.Content['id'];
      hash: string;
    }> &
      FinishProps
  >()
);

export const PostAttachmentNew = makeAction(
  'ddm/attachments/POST_ATTACHMENT_NEW',
  props<
    Readonly<{
      contentId: T.Content['id'];
      file: PostAttachmentBody['file'];
      attachmentType: PostAttachmentBody['type'];
      bucketFileName?: string;
    }>
  >()
);
export const CancelPostAttachmentNew = makeAction(
  'ddm/attachments/CANCEL_POST_ATTACHMENT_NEW',
  props<
    Readonly<{
      contentId: T.Content['id'];
      hash: string;
    }>
  >()
);

export const FinishPostAttachmentNew = makeAction(
  'ddm/attachments/FINISH_POST_ATTACHMENT_NEW',
  props<
    Readonly<{
      contentId: T.Content['id'];
      hash: string;
      meta?: T.APIPhotoMeta;
    }> &
      FinishProps
  >()
);

export const PostProcessingAttachmentNew = makeAction(
  'ddm/attachments/POST_PROCESSING_ATTACHMENT_NEW',
  props<
    Readonly<{
      projectId: T.Project['id'];
      file: PostAttachmentBody['file'];
      attachmentType: PostAttachmentBody['type'];
      bucketFileName?: string;
    }>
  >()
);

export const CancelPostProcessingAttachmentNew = makeAction(
  'ddm/attachments/CANCEL_POST_PROCESSING_ATTACHMENT_NEW',
  props<
    Readonly<{
      projectId: T.Project['id'];
      hash: string;
    }>
  >()
);

export const FinishPostProcessingAttachmentNew = makeAction(
  'ddm/attachments/FINISH_POST_PROCESSING_ATTACHMENT_NEW',
  props<
    Readonly<{
      projectId: T.Project['id'];
      hash: string;
      meta?: T.APIPhotoMeta;
    }> &
      FinishProps
  >()
);

export const UpdateAttachmentUploadStatus = makeAction(
  'ddm/attachments/UPDATE_ATTACHMENT_UPLOAD_STATUS',
  props<
    Readonly<{
      contentId: T.Content['id'];
      hash: string;
      status: FileUploadProgress;
    }>
  >()
);

export const UpdateProcessingAttachmentUploadStatus = makeAction(
  'ddm/attachments/UPDATE_PROCESSING_ATTACHMENT_UPLOAD_STATUS',
  props<
    Readonly<{
      projectId: T.Project['id'];
      hash: string;
      status: FileUploadProgress;
    }>
  >()
);

export const AddAttachment = makeAction(
  'ddm/attachments/ADD_ATTACHMENT',
  props<
    Readonly<{
      attachment: T.Attachment;
    }>
  >()
);

export const AddProcessingAttachment = makeAction(
  'ddm/attachments/ADD_PROCESSING_ATTACHMENT',
  props<
    Readonly<{
      attachment: T.Attachment;
    }>
  >()
);

export const AddProcessingAttachments = makeAction(
  'ddm/attachments/ADD_PROCESSING_ATTACHMENTS',
  props<
    Readonly<{
      attachments: T.Attachment[];
    }>
  >()
);

export const AddAttachments = makeAction(
  'ddm/attachments/ADD_ATTACHMENTS',
  props<
    Readonly<{
      attachments: T.Attachment[];
    }>
  >()
);

export const RemoveAttachment = makeAction(
  'ddm/attachments/REMOVE_ATTACHMENT',
  props<
    Readonly<{
      attachmentId: T.Attachment['id'];
    }>
  >()
);
export const CancelRemoveAttachment = makeAction('ddm/attachments/CANCEL_REMOVE_ATTACHMENT');
export const FinishRemoveAttachment = makeAction(
  'ddm/attachments/FINISH_REMOVE_ATTACHMENT',
  props<FinishProps>()
);
export const InitializeAttatchment = makeAction('ddm/attachments/INITIALZE_ATTACHMENT');

const Action = union([
  GetAttachments,
  CancelGetAttachments,
  FinishGetAttachments,

  GetProcessingAttachments,
  CancelGetProcessingAttachments,
  FinishGetProcessingAttachments,

  PostAttachment,
  CancelPostAttachment,
  FinishPostAttachment,

  PostAttachmentNew,
  CancelPostAttachmentNew,
  FinishPostAttachmentNew,

  PostProcessingAttachmentNew,
  CancelPostProcessingAttachmentNew,
  FinishPostProcessingAttachmentNew,

  UpdateAttachmentUploadStatus,
  UpdateProcessingAttachmentUploadStatus,

  AddAttachment,
  AddAttachments,

  AddProcessingAttachment,
  AddProcessingAttachments,

  RemoveAttachment,
  CancelRemoveAttachment,
  FinishRemoveAttachment,

  InitializeAttatchment,
  // Out-duck actions
  ChangeAuthedUser,

  makeAction(EPIC_RELOAD),
]);
export type Action = typeof Action;

// Redux-Observable Epics
const getAttachmentsEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(GetAttachments),
    mergeMap(({ contentId }) => {
      const location: Location | null = window.location;
      const URL: string = makeV2APIURL('contents', contentId, 'attachments');
      const isShared: boolean = pathToRegexp(routes.share.main).test(location.pathname);
      let headers: AuthHeader | ShareHeader;

      if (isShared) {
        const temp: ShareHeader | undefined = makeShareHeader(state$.value.SharedContents);

        if (temp === undefined) {
          return [
            FinishGetAttachments({
              contentId,
              error: T.HTTPError.UNKNOWN_ERROR,
            }),
          ];
        }

        headers = temp;
      } else {
        const temp: AuthHeader | undefined = makeAuthHeader(
          state$.value.Auth,
          state$.value.PlanConfig.config?.slug
        );

        if (temp === undefined) {
          return [ChangeAuthedUser({})];
        }

        headers = temp;
      }

      return rxjsHttp.get(URL, { headers }).pipe(
        map(({ response }): GetAttachmentsResponse => response),
        map(response => response.data),
        map(data => data.map(APIToAttachment)),
        map(attachments => AddAttachments({ attachments })),
        mergeMap(addAttachmentsAction => [
          addAttachmentsAction,
          FinishGetAttachments({ contentId }),
        ]),
        catchError((ajaxError: AjaxError) => [
          FinishGetAttachments({
            contentId,
            error: getRequestErrorType(ajaxError),
          }),
        ]),
        takeUntil(
          action$.pipe(
            ofType(CancelGetAttachments),
            filter(({ contentId: cancelId }) => cancelId === contentId)
          )
        )
      );
    })
  );

const getProcessingAttachmentsEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(GetProcessingAttachments),
    mergeMap(({ projectId }) => {
      const location: Location | null = window.location;
      const URL: string = makeV2APIURL('projects', projectId, 'processing_attachments');
      const isShared: boolean = pathToRegexp(routes.share.main).test(location.pathname);
      let headers: AuthHeader | ShareHeader;

      if (isShared) {
        const temp: ShareHeader | undefined = makeShareHeader(state$.value.SharedContents);

        if (temp === undefined) {
          return [
            FinishGetProcessingAttachments({
              projectId,
              error: T.HTTPError.UNKNOWN_ERROR,
            }),
          ];
        }

        headers = temp;
      } else {
        const temp: AuthHeader | undefined = makeAuthHeader(
          state$.value.Auth,
          state$.value.PlanConfig.config?.slug
        );

        if (temp === undefined) {
          return [ChangeAuthedUser({})];
        }

        headers = temp;
      }

      return rxjsHttp.get(URL, { headers }).pipe(
        map(({ response }): GetAttachmentsResponse => response),
        map(response => response.data),
        map(data => data.map(APIToAttachment)),
        map(attachments => AddProcessingAttachments({ attachments })),
        mergeMap(addAttachmentsAction => [
          addAttachmentsAction,
          FinishGetProcessingAttachments({ projectId }),
        ]),
        catchError((ajaxError: AjaxError) => [
          FinishGetProcessingAttachments({
            projectId,
            error: getRequestErrorType(ajaxError),
          }),
        ]),
        takeUntil(
          action$.pipe(
            ofType(CancelGetProcessingAttachments),
            filter(({ projectId: cancelId }) => cancelId === projectId)
          )
        )
      );
    })
  );

const postAttachmentEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(PostAttachment),
    mergeMap(({ contentId, attachmentType, file }) => {
      const URL: string = makeV2APIURL('contents', contentId, 'attachments');
      const header: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );

      if (header === undefined) {
        return [ChangeAuthedUser({})];
      }

      const formData: FormData = new FormData();
      formData.append('type', attachmentType);
      formData.append('file', file);

      const hash: string = calculateHash(file);

      // TODO: Interceptors, utilize http or rxjsHttp
      return fileUpload(URL, formData, header).pipe(
        mergeMap<FileUploadResponse, any>(fileUploadResponse => {
          if (isFileUploadProgress(fileUploadResponse)) {
            return [
              UpdateAttachmentUploadStatus({
                contentId,
                hash,
                status: fileUploadResponse,
              }),
            ];
          } else {
            const response: PostAttachmentResponse = fileUploadResponse.response;

            return [
              AddAttachment({ attachment: APIToAttachment(response.data) }),
              FinishPostAttachment({ contentId, hash }),
            ];
          }
        }),
        catchError((ajaxError: AjaxError) => [
          FinishPostAttachment({
            contentId,
            hash,
            error: getRequestErrorType(ajaxError),
          }),
        ]),
        takeUntil(
          action$.pipe(
            ofType(CancelPostAttachment),
            filter(
              ({ contentId: cancelId, hash: cancelHash }) =>
                cancelId === contentId && cancelHash === hash
            )
          )
        )
      );
    })
  );
const postAttachmentNewEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(PostAttachmentNew),
    mergeMap(({ contentId, file, attachmentType, bucketFileName }) => {
      const URL: string = makeV2APIURL(
        'contents',
        contentId,
        'attachments',
        `new?type=${attachmentType}`
      );
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );

      // const sourcePhotos = photos.filter(val => val.screenId) as T.FinalPhoto[];

      if (authHeader === undefined) {
        return [ChangeAuthedUser({})];
      }

      const hash: string = calculateHash(file);
      const fileMultipartUpload: FileMultipartUpload = new FileMultipartUpload({
        file,
        header: authHeader,
        bucketFileName,
      });

      return rxjsHttp.get(URL, { headers: authHeader }).pipe(
        map(({ response }): PostAttachmentNewResponse => response),
        mergeMap<PostAttachmentNewResponse, any>(response => fileMultipartUpload.create(response)),
        mergeMap<FileMultipartUploadResponse, any>(fileMultipartUploadResponse => {
          if (isFileMultipartUploadProgress(fileMultipartUploadResponse)) {
            return [
              UpdateAttachmentUploadStatus({
                contentId,
                hash,
                status: fileMultipartUploadResponse,
              }),
            ];
          } else {
            const callbackBaseUrl: string = makeV2APIURL(
              'contents',
              contentId,
              'attachments',
              'callback'
            );
            const body: AttachmentCallbackBody = {
              key: fileMultipartUploadResponse,
            };

            return rxjsHttp
              .post(callbackBaseUrl, body, {
                headers: {
                  ...authHeader,
                  ...jsonContentHeader,
                },
              })
              .pipe(
                /**
                 * meta is for storing uploaded source photo in photo album
                 */
                map(
                  ({ response }): { data: T.APIAttachment; meta: T.APIPhoto | undefined } =>
                    response
                ),
                map(response => ({
                  attachment: APIToAttachment(response.data),
                  meta: response?.meta,
                })),
                map(response => ({
                  attachment: AddAttachment({ attachment: response.attachment }),
                  meta: response?.meta,
                })),
                mergeMap(({ attachment: addAttachmentAction, meta }) => {
                  /**
                   * Prevent duplicate source photo folder when upload source photo
                   * @author Lanbao
                   * @see uploadSourcePhotoEpic
                   */
                  const photos: T.Photo[] = [...state$.value.Photos.photos];
                  if (meta) {
                    const screenPhoto = photos.find(val => val.screenId === meta.screen.id);
                    if (screenPhoto && screenPhoto.count) {
                      screenPhoto.count = screenPhoto.count + 1;
                    } else {
                      photos.push(
                        APIToSourcePhoto({
                          screenId: meta.screen.id,
                          screenTitle: meta.screen.title,
                          count: 1,
                          takenAt: meta.takenAt,
                        } as T.APIPhotoMeta)
                      );
                    }
                  }
                  return [
                    addAttachmentAction,
                    FinishPostAttachmentNew({
                      contentId,
                      hash,
                    }),
                    UpdatePhotosInStore({
                      photos: [...photos.map(APIToPhoto)],
                    }),
                  ];
                })
              );
          }
        }),
        catchError((error: AjaxError) => [
          FinishPostAttachmentNew({
            contentId,
            hash,
            error: getRequestErrorType(error),
          }),
        ]),
        takeUntil(
          action$.pipe(
            ofType(CancelPostAttachmentNew),
            filter(
              ({ contentId: cancelId, hash: cancelHash }) =>
                cancelId === contentId && cancelHash === hash
            ),
            map(res => {
              fileMultipartUpload.cancel();

              return res;
            })
          )
        )
      );
    })
  );

const postProcessingAttachmentNewEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(PostProcessingAttachmentNew),
    mergeMap(({ projectId, file, attachmentType, bucketFileName }) => {
      const URL: string = makeV2APIURL(
        'projects',
        projectId,
        'processing_attachments',
        `new?type=${attachmentType}`
      );
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );

      // const sourcePhotos = photos.filter(val => val.screenId) as T.FinalPhoto[];

      if (authHeader === undefined) {
        return [ChangeAuthedUser({})];
      }

      const hash: string = calculateHash(file);

      const fileMultipartUpload: FileMultipartUpload = new FileMultipartUpload({
        file,
        header: authHeader,
        bucketFileName,
      });

      return rxjsHttp.get(URL, { headers: authHeader }).pipe(
        map(({ response }): PostAttachmentNewResponse => response),
        mergeMap<PostAttachmentNewResponse, any>(response => fileMultipartUpload.create(response)),
        mergeMap<FileMultipartUploadResponse, any>(fileMultipartUploadResponse => {
          if (isFileMultipartUploadProgress(fileMultipartUploadResponse)) {
            return [
              // Implement the upload progress
            ];
          } else {
            const callbackBaseUrl: string = makeV2APIURL(
              'projects',
              projectId,
              'processing_attachments',
              'callback'
            );

            const body: AttachmentCallbackBody = {
              key: fileMultipartUploadResponse,
            };

            return rxjsHttp
              .post(callbackBaseUrl, body, {
                headers: {
                  ...authHeader,
                  ...jsonContentHeader,
                },
              })
              .pipe(
                /**
                 * meta is for storing uploaded source photo in photo album
                 */
                map(
                  ({
                    response,
                  }): { data: T.APIAttachment; meta: T.APIProcessingAttachment | undefined } =>
                    response
                ),
                map(response => ({
                  attachment: APIToAttachment(response.data),
                  meta: response?.meta,
                })),
                map(response => AddProcessingAttachment({ attachment: response.attachment }))
              );
          }
        }),
        catchError((error: AjaxError) => [
          FinishPostAttachmentNew({
            contentId: projectId,
            hash,
            error: getRequestErrorType(error),
          }),
        ]),
        takeUntil(
          action$.pipe(
            ofType(CancelPostProcessingAttachmentNew),
            filter(
              ({ projectId: cancelId, hash: cancelHash }) =>
                cancelId === projectId && cancelHash === hash
            ),
            map(res => {
              fileMultipartUpload.cancel();

              return res;
            })
          )
        )
      );
    })
  );

const reloadEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.ofType(EPIC_RELOAD).pipe(
    mergeMap(() => {
      const { Attachments }: T.State = state$.value;

      const cancelGetActions: Action[] = Object.keys(Attachments.getAttachmentsStatus)
        .map(Number)
        .map(contentId => ({ contentId }))
        .map(CancelGetAttachments);
      const cancelPostActions: Action[] = Object.keys(Attachments.postAttachmentStatus)
        .map(Number)
        .reduce(
          (acc, contentId) => [
            ...acc,
            ...Object.keys(Attachments.postAttachmentStatus[contentId]).map(hash =>
              CancelPostAttachment({ contentId, hash })
            ),
          ],
          []
        );

      return [...cancelGetActions, ...cancelPostActions];
    })
  );

const removeAttachmentEpic: Epic<Action, any, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(RemoveAttachment),
    mergeMap(({ attachmentId }) => {
      const URL: string = makeV2APIURL('attachments', attachmentId.toString());
      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );

      if (authHeader === undefined) {
        return [ChangeAuthedUser({})];
      }

      return rxjsHttp.delete(URL, { headers: authHeader }).pipe(
        mergeMapTo([FinishRemoveAttachment({})]),
        catchError((ajaxError: AjaxError) => [
          FinishRemoveAttachment({ error: getRequestErrorType(ajaxError) }),
        ]),
        takeUntil(action$.pipe(ofType(CancelRemoveAttachment)))
      );
    })
  );

export const epic: Epic<Action, Action, T.State> = combineEpics(
  getAttachmentsEpic,
  getProcessingAttachmentsEpic,
  postAttachmentEpic,
  postAttachmentNewEpic,
  postProcessingAttachmentNewEpic,
  reloadEpic,
  removeAttachmentEpic
);

export const attachmentsStateLens: LensS<T.AttachmentsState, T.AttachmentsState> =
  new LensGenerator<T.AttachmentsState>().fromKeys();

type AttachmentsFocusLens<K extends keyof T.AttachmentsState> = LensS<
  T.AttachmentsState[K],
  T.AttachmentsState
>;
export const postAttachmentStatusLens: AttachmentsFocusLens<'postAttachmentStatus'> =
  attachmentsStateLens.focusTo('postAttachmentStatus');

export const postProcessingAttachmentStatusLens: AttachmentsFocusLens<'postProcessingAttachmentStatus'> =
  attachmentsStateLens.focusTo('postProcessingAttachmentStatus');

export const getAttachmentStatusLens: AttachmentsFocusLens<'getAttachmentsStatus'> =
  attachmentsStateLens.focusTo('getAttachmentsStatus');

export const attachmentsLens: AttachmentsFocusLens<'attachments'> =
  attachmentsStateLens.focusTo('attachments');

// Redux reducer
const initialState: T.AttachmentsState = {
  attachments: {
    byId: {},
    allIds: [],
  },
  processing_attachments: {
    byId: {},
    allIds: [],
  },
  getAttachmentsStatus: {},
  postAttachmentStatus: {},
  postProcessingAttachmentStatus: {},
};
const reducer: Reducer<T.AttachmentsState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case GetAttachments.type:
      return getAttachmentStatusLens.focusTo(action.contentId).set()(state)({
        status: T.APIStatus.PROGRESS,
      });
    case CancelGetAttachments.type:
      return getAttachmentStatusLens.map()(state)(getAttachmentsStatus =>
        _.omit(getAttachmentsStatus, action.contentId)
      );

    case GetProcessingAttachments.type:
      return getAttachmentStatusLens.focusTo(action.projectId).set()(state)({
        status: T.APIStatus.PROGRESS,
      });
    case CancelGetProcessingAttachments.type:
      return getAttachmentStatusLens.map()(state)(getAttachmentsStatus =>
        _.omit(getAttachmentsStatus, action.projectId)
      );

    case FinishGetProcessingAttachments.type:
      return getAttachmentStatusLens.focusTo(action.projectId).set()(state)({
        status: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        error: action.error,
      });

    case FinishGetAttachments.type:
      return getAttachmentStatusLens.focusTo(action.contentId).set()(state)({
        status: action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        error: action.error,
      });

    case PostAttachment.type:
    case PostAttachmentNew.type:
      return postAttachmentStatusLens
        .focusTo(action.contentId)
        .focusTo(calculateHash(action.file))
        .set()(state)({
        total: action.file.size,
        progress: 0,
      });
    case CancelPostAttachment.type:
    case CancelPostAttachmentNew.type:
      return postAttachmentStatusLens.focusTo(action.contentId).map()(state)(statusOfContent =>
        _.omit(statusOfContent, action.hash)
      );
    case FinishPostAttachment.type:
    case FinishPostAttachmentNew.type:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return postAttachmentStatusLens
        .focusTo(action.contentId)
        .focusTo(action.hash)
        .focusTo('error')
        .set()(state)(action.error);

    case UpdateAttachmentUploadStatus.type:
      return postAttachmentStatusLens
        .focusTo(action.contentId)
        .focusTo(action.hash)
        .focusTo('progress')
        .set()(state)(action.status.progress);

    case UpdateProcessingAttachmentUploadStatus.type:
      return postProcessingAttachmentStatusLens
        .focusTo(action.projectId)
        .focusTo(action.hash)
        .focusTo('progress')
        .set()(state)(action.status.progress);

    case AddAttachment.type:
      /**
       * @fixme This one looks bit complex... :(
       * Maybe adding some default lens to array will be helpful.
       */
      return attachmentsLens.focusTo('allIds').map()(
        attachmentsLens.focusTo('byId').focusTo(action.attachment.id).set()(state)(
          action.attachment
        )
      )(allIds => _.orderBy(_.union([action.attachment.id], allIds)));

    case AddAttachments.type: {
      const allAttachmentIds: Array<T.Attachment['id']> = action.attachments.map(item => item.id);

      const stateWithNewById: T.AttachmentsState = attachmentsLens.focusTo('byId').map()(state)(
        byId => ({
          ...byId,
          ..._.zipObject(allAttachmentIds, action.attachments),
        })
      );

      return attachmentsLens.focusTo('allIds').map()(stateWithNewById)(allIds =>
        _.orderBy(_.union(allAttachmentIds, allIds))
      );
    }

    case AddProcessingAttachment.type:
      return {
        ...state,
        processing_attachments: {
          byId: {
            ...state.processing_attachments.byId,
            [action.attachment.id]: action.attachment,
          },
          allIds: [...state.processing_attachments.allIds, action.attachment.id],
        },
      };

    case AddProcessingAttachments.type: {
      // Create a map of existing attachments by ID
      const existingAttachmentsById = state.processing_attachments.byId;

      // Create a set of existing IDs for quick lookup
      const existingIdsSet = new Set(Object.keys(existingAttachmentsById));

      // Separate new and existing attachments
      const newAttachments = action.attachments.filter(
        item => !existingIdsSet.has(item?.id.toString())
      );
      const updatedAttachments = action.attachments.filter(item =>
        existingIdsSet.has(item?.id.toString())
      );

      return {
        ...state,
        processing_attachments: {
          byId: {
            ...state.processing_attachments.byId,
            // Merge new attachments, which won't duplicate existing ones
            ..._.zipObject(
              newAttachments.map(item => item.id),
              newAttachments
            ),
            // Optionally update existing attachments
            ..._.zipObject(
              updatedAttachments.map(item => item.id),
              updatedAttachments
            ),
          },
          allIds: [...state.processing_attachments.allIds, ...newAttachments.map(item => item.id)],
        },
      };
    }

    case RemoveAttachment.type:
      return attachmentsLens.map()(state)(attachments => ({
        byId: _.omit(attachments.byId, action.attachmentId),
        allIds: _.without(attachments.allIds, action.attachmentId),
      }));
    case CancelRemoveAttachment.type:
      return {
        ...state,
        RemoveAttachmentStatus: T.APIStatus.IDLE,
      };
    case FinishRemoveAttachment.type:
      return {
        ...state,
        RemoveAttachmentStatus:
          action.error === undefined ? T.APIStatus.SUCCESS : T.APIStatus.ERROR,
        RemoveAttachmentError: action.error,
      };
    case InitializeAttatchment.type:
      return {
        ...state,
        getAttachmentsStatus: {},
        postAttachmentStatus: {},
        attachments: {
          byId: {},
          allIds: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
