import { Language } from '^/types';

export default {
  title: {
    [Language.KO_KR]: '엔젤스윙 — 드론 데이터 플랫폼 | Angelswing',
    [Language.EN_US]: 'Angelswing — Drone Data Platform',
  },
  description: {
    [Language.KO_KR]:
      '기획부터 실행까지 차세대 드론 데이터 플랫폼으로 프로젝트를 혁신하세요. Angelswing과 함께 더 스마트하고 안전한 미래를 구축하세요.',
    [Language.EN_US]:
      'Elevate your projects with our next-generation drone data platform from planning to execution. Build a Smarter, Safer Future with Angelswing',
  },
};
