import { generalErrorDescription } from '^/hooks';
import { Language } from '^/types';

export default {
  firstBalloonTitle: {
    [Language.KO_KR]: '불투명도',
    [Language.EN_US]: 'Opacity',
  },
  fallback: {
    content: {
      title: {
        [Language.KO_KR]: '3D 메쉬 데이터에 오류가 발생했습니다.',
        [Language.EN_US]: 'Error has occurred in 3D Mesh.',
      },
      description: generalErrorDescription,
    },
  },
  calibrateText: {
    [Language.KO_KR]: '높이 보정',
    [Language.EN_US]: 'Calibrate Offset',
  },
  calibrateDescription: {
    [Language.KO_KR]:
      '높이 보정 기능은 3D 메쉬가 공중에 떠있는 현상을 개선하는데 도움이 될 수 있습니다.',
    [Language.EN_US]: 'The calibrate offset adjusts 3D mesh elevation issue or errors.',
  },
};
