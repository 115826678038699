/* eslint-disable max-lines */
import { Reducer } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { concat } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { action as makeAction, props, union } from 'tsdux';
import { ofType } from 'tsdux-observable';

import {
  AuthHeader,
  getRequestErrorType,
  jsonContentHeader,
  makeAuthHeader,
  makeV2APIURL,
} from './API';
import {
  APIToContent,
  PostContent,
  PostContentBody,
  FinishPostContent,
  ChangeContents,
  PatchContent,
} from './Contents';
import { ChangeEditingContent } from './Pages';
import { defaultGroup } from '^/constants/defaultContent';
import {
  GROUP_NUMBER_GAP,
  GROUP_TITLE_PREFIX,
  NO_GROUP_NUMBER_ATTACHED,
  START_GROUP_NUMBER,
  groupName,
  groupTerrainEdittingName,
  presentationGroupName,
  subGroupName,
  subGroupTerrainEdittingName,
} from '^/constants/group';
import { typeGuardESSContent, typeGuardGroup } from '^/hooks';
import { currentProjectSelector } from '^/hooks/useCurrentProject';
import { lastSelectedScreenSelector } from '^/hooks/useLastSelectedScreen';
import * as T from '^/types';
import { CtxSortEvent, InsertPos } from '^/utilities/ctxsort';
import { essContentsStore } from '^/store/essContentsStore';
import { PatchESSContentProps } from '^/hooks/useESSContents';
import { rxjsHttp } from '^/utilities/api';
import { contentsStore } from '../zustand/content/contentStore';
import { groupStore } from '../zustand/groups/groupStore';
export const CategoryToTabMapper: Record<T.ContentCategory, T.ContentPageTabType> = {
  [T.ContentCategory.MEASUREMENT]: T.ContentPageTabType.MEASUREMENT,
  [T.ContentCategory.OVERLAY]: T.ContentPageTabType.OVERLAY,
  [T.ContentCategory.ESS]: T.ContentPageTabType.ESS,
  [T.ContentCategory.METADATA]: T.ContentPageTabType.MAP,
  [T.ContentCategory.MAP]: T.ContentPageTabType.MAP,
  [T.ContentCategory.ISSUE]: T.ContentPageTabType.ISSUE,
  [T.ContentCategory.TERRAIN]: T.ContentPageTabType.MAP,
  [T.ContentCategory.DASHBOARD]: T.ContentPageTabType.DASHBOARD,
  [T.ContentCategory.DRONE_STATION]: T.ContentPageTabType.DRONE_STATION,
  [T.ContentCategory.PRESENTATION]: T.ContentPageTabType.PRESENTATION,
  [T.ContentCategory.FLIGHT_PLAN]: T.ContentPageTabType.FLIGHT_PLAN,
  [T.ContentCategory.FLIGHT_SCHEDULE]: T.ContentPageTabType.FLIGHT_SCHEDULE,
  [T.ContentCategory.VIEWPOINT_CAPTURE]: T.ContentPageTabType.VIEWPOINT_CAPTURE,
};

export const TabToCategoryMapper: Record<T.ContentPageTabType, T.ContentCategory> = {
  [T.ContentPageTabType.MEASUREMENT]: T.ContentCategory.MEASUREMENT,
  [T.ContentPageTabType.OVERLAY]: T.ContentCategory.OVERLAY,
  [T.ContentPageTabType.ESS]: T.ContentCategory.ESS,
  [T.ContentPageTabType.MAP]: T.ContentCategory.METADATA,
  [T.ContentPageTabType.PHOTO]: T.ContentCategory.MAP,
  [T.ContentPageTabType.ISSUE]: T.ContentCategory.ISSUE,
  [T.ContentPageTabType.DASHBOARD]: T.ContentCategory.DASHBOARD,
  [T.ContentPageTabType.DRONE_STATION]: T.ContentCategory.DRONE_STATION,
  [T.ContentPageTabType.FLIGHT_PLAN]: T.ContentCategory.FLIGHT_PLAN,
  [T.ContentPageTabType.PRESENTATION]: T.ContentCategory.PRESENTATION,
  [T.ContentPageTabType.FLIGHT_SCHEDULE]: T.ContentCategory.FLIGHT_SCHEDULE,
  [T.ContentPageTabType.VIEWPOINT_CAPTURE]: T.ContentCategory.VIEWPOINT_CAPTURE,
};

export const getNewGroupTitle: (
  titles: string[],
  lang: T.Language,
  isSubFolder: boolean,
  category?: T.ContentCategory,
  isPresentation?: boolean
) => T.GroupContent['title'] = (titles, lang, isSubFolder, category, isPresentation) => {
  const defaultGroupName = isSubFolder
    ? category === T.ContentCategory.TERRAIN
      ? subGroupTerrainEdittingName[lang]
      : isPresentation
      ? presentationGroupName[lang]
      : subGroupName[lang]
    : category === T.ContentCategory.TERRAIN
    ? groupTerrainEdittingName[lang]
    : groupName[lang];
  const currentGroupNumbers: number[] = titles
    .filter(title => title.includes(defaultGroupName))
    .map(title => {
      const parsedNum: number = Number(title.replace(defaultGroupName, ''));

      return parsedNum === NO_GROUP_NUMBER_ATTACHED ? START_GROUP_NUMBER : parsedNum;
    })
    .filter(num => !isNaN(num))
    .sort((a, b) => a - b);

  const nextGroupNum: number = currentGroupNumbers.length
    ? currentGroupNumbers[currentGroupNumbers.length - 1] + GROUP_NUMBER_GAP
    : START_GROUP_NUMBER;
  return `${defaultGroupName}${
    nextGroupNum === START_GROUP_NUMBER ? '' : `${GROUP_TITLE_PREFIX}${nextGroupNum}`
  }`;
};

export const getCopiedGroupTitle: (
  existingGroupTitles: Array<T.GroupContent['title']>,
  selectedGroupTitle: T.GroupContent['title'],
  lang: T.Language
) => T.GroupContent['title'] = (existingGroupTitles, selectedGroupTitle) => {
  const postfixOfExistingGroups: number[] = existingGroupTitles
    .filter(title => title.includes(selectedGroupTitle) && title.indexOf(selectedGroupTitle) === 0)
    .map(title => {
      const postfix = title.replace(selectedGroupTitle, '');
      return Number(postfix.match(/\(([^)]+)\)/)?.pop()) ?? START_GROUP_NUMBER;
    })
    .filter(num => !isNaN(num))
    .sort((a, b) => a - b);

  const postfixOfNextGroup: number = postfixOfExistingGroups.length
    ? postfixOfExistingGroups[postfixOfExistingGroups.length - 1] + GROUP_NUMBER_GAP
    : START_GROUP_NUMBER + GROUP_NUMBER_GAP;

  return `${selectedGroupTitle}${GROUP_TITLE_PREFIX}(${postfixOfNextGroup})`;
};

export const createInitialContentsTree: () => T.GroupsState['tree'] = () => ({
  idsByGroup: {},
  rootIdsByCategory: {
    [T.ContentCategory.OVERLAY]: { pinned: [], unpinned: {} },
    [T.ContentCategory.MEASUREMENT]: { pinned: [], unpinned: {} },
    [T.ContentCategory.ESS]: { pinned: [], unpinned: {} },
    [T.ContentCategory.MAP]: { pinned: [], unpinned: {} },
    [T.ContentCategory.METADATA]: { pinned: [], unpinned: {} },
    [T.ContentCategory.ISSUE]: { pinned: [], unpinned: {} },
    [T.ContentCategory.TERRAIN]: { pinned: [], unpinned: {} },
    [T.ContentCategory.DASHBOARD]: { pinned: [], unpinned: {} },
    [T.ContentCategory.DRONE_STATION]: { pinned: [], unpinned: {} },
    [T.ContentCategory.FLIGHT_PLAN]: { pinned: [], unpinned: {} },
    [T.ContentCategory.PRESENTATION]: { pinned: [], unpinned: {} },
    [T.ContentCategory.FLIGHT_SCHEDULE]: { pinned: [], unpinned: {} },
    [T.ContentCategory.VIEWPOINT_CAPTURE]: { pinned: [], unpinned: {} },
  },
  rootIdsBySpace: {
    [T.ContentCategory.OVERLAY]: { personal: [], open: [] },
    [T.ContentCategory.MEASUREMENT]: { personal: [], open: [] },
    [T.ContentCategory.ESS]: { personal: [], open: [] },
    [T.ContentCategory.MAP]: { personal: [], open: [] },
    [T.ContentCategory.METADATA]: { personal: [], open: [] },
    [T.ContentCategory.ISSUE]: { personal: [], open: [] },
    [T.ContentCategory.TERRAIN]: { personal: [], open: [] },
    [T.ContentCategory.DASHBOARD]: { personal: [], open: [] },
    [T.ContentCategory.DRONE_STATION]: { personal: [], open: [] },
    [T.ContentCategory.PRESENTATION]: { personal: [], open: [] },
    [T.ContentCategory.FLIGHT_PLAN]: { personal: [], open: [] },
    [T.ContentCategory.FLIGHT_SCHEDULE]: { personal: [], open: [] },
    [T.ContentCategory.VIEWPOINT_CAPTURE]: { personal: [], open: [] },
  },
});

type Movable = Pick<T.Content, 'id' | 'groupId' | 'screenId' | 'category' | 'type' | 'isPersonal'>;

interface MovableParams {
  readonly current: Movable;
  readonly target: Movable;
  readonly targetList: Array<T.Content['id']>;
  readonly insertTo: InsertPos;
  readonly screenId?: T.Screen['id'];
}

/**
 * The item movement parameters that BE needs
 * in order to move a content in a list.
 */
export interface TreeInfoParams {
  /**
   * The screen id of the current screen
   */
  readonly screenId?: T.Screen['id'];
  /**
   * The content id to target to.
   */
  readonly posContentId: T.Content['id'] | null;
  /**
   * Determines whether content should be at the bottom of the list or not.
   */
  readonly appendMode: boolean;
}

/**
 * Returns the tree info params that BE needs.
 * See TreeInfoParams for more details.
 */
export const getTreeInfoParams: (params: MovableParams) => TreeInfoParams = ({
  current,
  targetList,
  target,
  insertTo,
  screenId,
}) => {
  if (current.id === target.groupId) {
    throw new Error(
      `Not allowed to move a root group inside it's own sub-group for now. Current: ${current.id}, target: ${target.id}`
    );
  }

  // Bring sub-group to root
  if (
    target.type === T.ContentType.GROUP &&
    target.groupId === undefined &&
    current.type === T.ContentType.GROUP &&
    insertTo !== InsertPos.INSIDE
  ) {
    return {
      appendMode: false,
      posContentId: target.id,
      screenId: target.screenId,
    };
  }

  // Add content to group
  if (target.type === T.ContentType.GROUP && insertTo === InsertPos.INSIDE) {
    return {
      appendMode: target.category === T.ContentCategory.ESS ? false : true,
      posContentId: target.id,
      screenId,
    };
  }

  // This happens when a content is drag-and-dropped on a group.
  // It should immediately go to the bottom of the group.
  if (current.groupId !== undefined && target.groupId === undefined) {
    return {
      appendMode: true,
      posContentId: target.id,
      screenId,
    };
  }

  const targetIndex = targetList.indexOf(target.id);
  const shouldInsertToNext = insertTo === InsertPos.NEXT;
  const isTargetLast = targetIndex === targetList.length - 1 && shouldInsertToNext;
  const posContentId = isTargetLast
    ? // The only time posContentId is null is when
      // it's moving root-level content (groupId === undefined) and it's in append mode.
      target.groupId ?? null
    : targetList[targetIndex + Number(shouldInsertToNext)] ?? null;

  return {
    appendMode: isTargetLast,
    posContentId,
    screenId,
  };
};

/**
 * Gets the list of either the root-level contentids in a category
 * (pinned or by screenid) or all contentids inside a group
 * for non-root-level content.
 */
const getContentListFromTree: (
  content: Movable,
  category: T.ContentCategory,
  tree: T.GroupsState['tree'],
  isESS?: boolean
) => Array<T.Content['id']> = (content, category, tree, isESS) => {
  const rootIdsByCategory = tree.rootIdsByCategory[category];
  const rootIdsBySpace = tree.rootIdsBySpace[category];
  const essContentGroupTree = essContentsStore.getState().essContentGroupTree;

  if (content.type === T.ContentType.GROUP && category === T.ContentCategory.MEASUREMENT) {
    return content.isPersonal ? rootIdsBySpace.personal : rootIdsBySpace.open;
  }

  if (content.groupId === undefined) {
    return content.screenId === undefined
      ? rootIdsByCategory.pinned
      : rootIdsByCategory.unpinned[content.screenId] ?? [];
  }

  return isESS ? essContentGroupTree[content.groupId] : tree.idsByGroup[content.groupId] ?? [];
};

export const AddNewGroup = makeAction(
  'ddm/groups/ADD_NEW_GROUP',
  props<{
    isPersonal: boolean;
    skipTriggerRename?: boolean;
    customTitle?: string;
    parentGroupId?: T.GroupContent['id'];
    contentCategory?: T.ContentCategory;
    handleCreateSlideItem?(groupId?: number): void;
  }>()
);

export const CopySelectedGroup = makeAction(
  'ddm/groups/COPY_SELECTED_GROUP',
  props<{
    isPinned: boolean;
    isPersonal: boolean;
    skipTriggerRename?: boolean;
    customTitle?: string;
    selectedGroupId?: T.GroupContent['id'];
  }>()
);

export const MoveContent = makeAction(
  'ddm/group/MOVE_CONTENT',
  props<{ e: CtxSortEvent; updateESSContent?(content: PatchESSContentProps): void }>()
);

export const ChangeSelectedGroupId = makeAction(
  'ddm/group/CHANGE_SELECTED_GROUP_ID',
  props<{ selectedGroupId?: T.Content['groupId']; tab: T.ContentPageTabType }>()
);

export const RemoveGroupChildren = makeAction(
  'ddm/group/REMOVE_GROUP_CHILDREN',
  props<{ id: T.GroupContent['id'] }>()
);

export const CheckAndRemoveGroup = makeAction(
  'ddm/group/CHECK_AND_REMOVE_GROUP',
  props<{ id: T.Content['id'] }>()
);

export const RebuildTree = makeAction(
  'ddm/group/REBUILD_TREE',
  props<{ tree: T.GroupsState['tree'] }>()
);

const Action = union([
  AddNewGroup,
  CopySelectedGroup,

  MoveContent,

  ChangeSelectedGroupId,
  RemoveGroupChildren,
  CheckAndRemoveGroup,
  RebuildTree,

  // Out-duck actions
  PostContent,
  PatchContent,
  FinishPostContent,
  ChangeEditingContent,
  ChangeContents,
]);
export type Action = typeof Action;

type GroupContentBody = PostContentBody & {
  readonly groupId?: T.Content['groupId'];
};

// Redux-Observable Epics
const addNewGroupEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(AddNewGroup),
    mergeMap(
      ({
        isPersonal,
        skipTriggerRename,
        customTitle,
        parentGroupId: _parentGroupId,
        contentCategory,
        handleCreateSlideItem,
      }) => {
        const s: T.State = state$.value;
        const lang = s.Pages.Common.language;
        const sidebarTab = s.Pages.Contents.sidebarTab;
        const byId = contentsStore.getState().contents.byId;
        const tree = groupStore.getState().tree;

        let category: T.ContentCategory;
        if (sidebarTab === T.ContentPageTabType.MAP) {
          category = T.ContentCategory.TERRAIN;
        } else {
          category = contentCategory ?? TabToCategoryMapper[sidebarTab];
        }
        const isMeasurementTab = category === T.ContentCategory.MEASUREMENT;
        let parentGroupId;
        if (isMeasurementTab) {
          parentGroupId = _parentGroupId;
        }
        const currentProject = currentProjectSelector(s);
        const currentScreen = lastSelectedScreenSelector(s);
        const parentGroup: T.Content | undefined = byId[parentGroupId ?? NaN];
        if (parentGroup?.groupId) {
          // eslint-disable-next-line no-console
          console.warn("Can't add a group to sub-group");
          return [];
        }

        if (currentProject === undefined || currentScreen === undefined) {
          return [];
        }

        const parentGroupIds = tree.idsByGroup[parentGroupId ?? NaN];
        const ids =
          parentGroupIds ?? isPersonal
            ? tree.rootIdsBySpace[category].personal
            : tree.rootIdsBySpace[category].open;

        const titles = ids
          .filter(id => byId[id]?.type === T.ContentType.GROUP)
          .map(id => byId[id]?.title);
        const title = customTitle ?? getNewGroupTitle(titles, lang, Boolean(parentGroupIds));
        const group = defaultGroup({ title });
        const screenId = (() => {
          switch (category) {
            case T.ContentCategory.MEASUREMENT: {
              return parentGroupId ? parentGroup.screenId ?? null : currentScreen.id;
            }
            case T.ContentCategory.PRESENTATION: {
              return parentGroupId ? parentGroup.screenId ?? null : currentScreen.id;
            }
            default:
              return null;
          }
        })();
        const content: GroupContentBody = {
          ...group,
          category,
          screenId,
          color: group.color.toString(),
          groupId: parentGroupId,
          isPersonal,
        };

        // User should be able to immediately rename after creating a group
        // since the title is a placeholder. However, there are cases
        // where it doesn't need to be, since it's just to create dummy group.
        if (!skipTriggerRename) {
          groupStore.setState({ isCreatingNewGroup: true });
        }
        const actions: Action[] = [];
        // const actions: Action[] = skipTriggerRename
        //   ? []
        //   : [ChangeIsCreatingNewGroup({ isCreatingNewGroup: true })];
        actions.push(
          PostContent({
            projectId: currentProject.id,
            content,
            handleCreateSlideItem,
          })
        );

        if (parentGroupId) {
          actions.push(
            PatchContent({
              content: {
                id: parentGroupId,
                config: { selectedAt: new Date() },
                info: { isOpened: true },
              },
            })
          );
        }

        return actions;
      }
    )
  );

const copySelectedGroupEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(CopySelectedGroup),
    mergeMap(({ isPinned, isPersonal, skipTriggerRename, customTitle, selectedGroupId }) => {
      const s: T.State = state$.value;
      const byId = contentsStore.getState().contents.byId;

      const lang = s.Pages.Common.language;
      const sidebarTab = s.Pages.Contents.sidebarTab;
      const currentProject = currentProjectSelector(s);
      const currentScreen = lastSelectedScreenSelector(s);
      const tree = groupStore.getState().tree;

      if (currentProject === undefined || currentScreen === undefined) {
        return [];
      }

      const category: T.ContentCategory = TabToCategoryMapper[sidebarTab];
      const { pinned, unpinned } = tree.rootIdsByCategory[category];
      const ids = isPinned ? pinned : unpinned[currentScreen.id] ?? [];

      const titles = ids
        .filter(id => byId[id]?.type === T.ContentType.GROUP)
        .map(id => byId[id]?.title);

      const selectedGroupTitle = selectedGroupId ? byId[selectedGroupId]?.title : '';

      const copiedGroupTitle = customTitle ?? getCopiedGroupTitle(titles, selectedGroupTitle, lang);

      const group = defaultGroup({ title: copiedGroupTitle });
      const newGroup: PostContentBody = {
        ...group,
        category,
        screenId: currentScreen.id,
        color: group.color.toString(),
        isPersonal,
      };

      const childrenIds = selectedGroupId ? tree.idsByGroup[selectedGroupId] : [];

      const ESSContentfGroup: T.ESSContent[] = [];

      childrenIds.forEach(contentId => {
        const content = byId[contentId];
        const ESSContent = typeGuardESSContent(content);
        if (ESSContent !== undefined) {
          ESSContentfGroup.push(ESSContent);
        }
      });

      // User should be able to immediately rename after creating a group
      // since the title is a placeholder. However, there are cases
      // where it doesn't need to be, since it's just to create dummy group.
      if (!skipTriggerRename) {
        groupStore.setState({ isCreatingNewGroup: true });
      }
      const actions: Action[] = [];
      // const actions: Action[] = skipTriggerRename
      //   ? []
      //   : [ChangeIsCreatingNewGroup({ isCreatingNewGroup: true })];

      // if (category === T.ContentCategory.ESS) {
      //   return actions.concat([
      //     CopyESSGroupContent({ group: newGroup, ESSContent: ESSContentfGroup }),
      //   ]);
      // }

      return actions.concat([
        PostContent({
          projectId: currentProject.id,
          content: newGroup,
        }),
      ]);
    })
  );

const moveContentEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(MoveContent),
    mergeMap(({ e, updateESSContent }) => {
      const id: T.Content['id'] = parseInt(e.key, 10);
      const { essContents, essContentIds } = essContentsStore.getState();
      const mainContentsById = contentsStore.getState().contents.byId;
      const tree = groupStore.getState().tree;

      const isESS = essContentIds.includes(id);
      const byId = isESS ? essContents : mainContentsById;

      const content: T.Content | undefined = byId[id];
      const category = content.category;
      if (content === undefined || content.category === T.ContentCategory.ISSUE) {
        return [];
      }

      const URL: string = makeV2APIURL(
        content.category === T.ContentCategory.ESS ? 'ess_contents' : 'contents',
        id,
        'move'
      );
      const header: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );
      const lastSelectedScreen: T.Screen | undefined = lastSelectedScreenSelector(state$.value);

      if (lastSelectedScreen?.id === undefined) {
        return [];
      }

      const target: Movable | undefined = (() => {
        const nearestId = parseInt(e.nearestKey ?? '', 10);
        const nearestContent = byId[nearestId];
        if (nearestContent !== undefined) {
          return nearestContent;
        }

        // Normally, nearestId should always exist.
        // There are three cases when it doesnt:

        // 1. Invalid target, e.g. moving a content into its own or somewhere else in the UI.
        // In this case, do nothing since the content should not move.
        if (e.isInvalidTarget) {
          return undefined;
        }

        // 2. Moving a content to the top of a list.
        // In this case, the first item after the header is
        // the first content on the list.
        const firstContentIdInList = parseInt(e.nextSiblingKey ?? '', 10);
        if (!isNaN(firstContentIdInList)) {
          return byId[firstContentIdInList];
        }

        // 3. Moving a content to the top of an empty list.
        // In this case, the next element on the list is not a content (i.e. empty).
        // Make up an empty target with an inexisting id.
        // The screen should be the opposite of the moving content, because
        // the only way to move to an empty list right now is when there are
        // at least two lists (pinned & unpinned) and one of them is empty.
        return {
          id: NaN,
          groupId: undefined,
          screenId: content.screenId === undefined ? lastSelectedScreen.id : undefined,
          category,
          type: content.type,
        };
      })();

      if (target === undefined) {
        // eslint-disable-next-line no-console
        console.warn('Unable to find target to move the content.', e);

        return [];
      }

      const targetList = getContentListFromTree(target, category, tree, isESS);

      let params: TreeInfoParams | null = null;
      try {
        const currentScreenId = state$.value.ProjectConfigPerUser.config?.lastSelectedScreenId;
        if (!currentScreenId) {
          throw new Error('Current screen is not defined.');
        }
        params = getTreeInfoParams({
          current: content,
          target,
          targetList,
          insertTo: e.insertTo,
          screenId: currentScreenId,
        });

        // Do not proceed if the content does not move at all (target and content is the same).
        if (params.posContentId === content.id && params.screenId === content.screenId) {
          return [];
        }
        if (content.type === T.ContentType.GROUP) {
          const essContentGroupTree = essContentsStore.getState().essContentGroupTree;
          const idsByGroup = isESS ? essContentGroupTree[content.id] : tree.idsByGroup[content.id];
          const hasChildGroup = idsByGroup.find(
            childId => byId[childId].type === T.ContentType.GROUP
          );
          if (hasChildGroup && (e.insertTo === InsertPos.INSIDE || target.groupId)) {
            throw new Error(`Can't move group having sub-group to another group`);
          }
          if (
            target.type === T.ContentType.GROUP &&
            target.groupId &&
            e.insertTo === InsertPos.INSIDE
          ) {
            throw new Error(
              `Can't move subgroup ${content.id} inside another subgroup ${target.groupId}`
            );
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        return [];
      }
      return concat(
        rxjsHttp
          .put(URL, params, {
            headers: {
              ...header,
              ...jsonContentHeader,
            },
          })
          .pipe(
            map(({ response }) => response),
            mergeMap(response => {
              const movedContents: T.Content[] = response.data.map((rawContent: T.APIContent) => ({
                ...APIToContent(rawContent),
                category,
                // Since this endpoint does not change config,
                // always use config from store, because some config can be inconsistent (like ESS).
                config: byId[rawContent.id].config,
              }));

              const movedCurrent = movedContents.find(
                movedContent => movedContent.id === content.id
              );
              if (movedCurrent === undefined) {
                return [];
              }
              // BE will update groupId and/or screenId whenever it changes,
              // so update it to the store.
              groupStore.setState({
                moveContentStatus: response.error ? T.APIStatus.ERROR : T.APIStatus.SUCCESS,
                moveContentError: response.error,
              });
              contentsStore.getState().updateContents(movedContents);
              const actions: Action[] = [];

              const moveOption = (() => {
                // Following the current UX,
                // dropping a non-root content to a root content (group)
                // should be placed at the top of the list.
                if (target.type === T.ContentType.GROUP && e.insertTo === InsertPos.INSIDE) {
                  return T.MoveOption.LAST;
                }

                return e.insertTo === InsertPos.NEXT ? T.MoveOption.NEXT : T.MoveOption.PREVIOUS;
              })();

              // Since the movement happens within a group,
              // there is no need to update the tree.idsByGroup.
              if (content.groupId === undefined && target.groupId === undefined) {
                const movingActions: Action[] = [];

                if (!isESS) {
                  groupStore.getState().removeContentFromCategoryTree(content);
                  addContentToTree(movedCurrent as T.Movable, moveOption, target);
                } else {
                  // this is for moving folder to sub-folder not working section
                  const { setESSContent, buildESSContentGroupTree } = essContentsStore.getState();

                  const essContentResponse = response.data.find(
                    (res: T.APIContent) => res.id === movedCurrent.id
                  );
                  setESSContent(
                    movedCurrent as T.ESSContent,
                    essContentResponse,
                    moveOption,
                    target
                  );
                  buildESSContentGroupTree();
                }
                return actions.concat(movingActions);
              }

              // Automatically open/expand a group when a content is moved into,
              // so that users can immediately see the content in the group (otherwise it's collapsed).
              const openGroupAction: Action[] = (() => {
                const targetGroupId =
                  target.type === T.ContentType.GROUP ? target.id : target.groupId;
                const targetGroup = typeGuardGroup(byId[targetGroupId ?? NaN]);
                if (
                  targetGroup === undefined ||
                  targetGroup.info.isOpened ||
                  e.insertTo !== InsertPos.INSIDE
                ) {
                  return [];
                }

                switch (category) {
                  case T.ContentCategory.ESS:
                    updateESSContent?.({
                      content: { id: targetGroup.id, info: { isOpened: true } },
                    });

                    essContentsStore.getState().buildESSContentGroupTree();
                    return [];
                  default:
                    return [
                      PatchContent({ content: { id: targetGroup.id, info: { isOpened: true } } }),
                    ];
                }
              })();

              if (isESS) {
                const { setESSContent, buildESSContentGroupTree } = essContentsStore.getState();
                const essContentResponse = response.data.find(
                  (res: T.APIContent) => res.id === movedCurrent.id
                );
                setESSContent(movedCurrent as T.ESSContent, essContentResponse, moveOption, target);
                buildESSContentGroupTree();
                return actions;
              }
              if (content.groupId === undefined) {
                groupStore.getState().removeContentFromCategoryTree(content);
              } else {
                removeContentFromTree(content);
              }
              addContentToTree(movedCurrent, moveOption, target);
              return (
                actions
                  // .concat([AddContentToTree({ content: movedCurrent, target, moveOption })])
                  .concat(openGroupAction)
              );
            }),
            catchError((err: AjaxError) => {
              // eslint-disable-next-line no-console
              console.error(err);

              const httpError = getRequestErrorType(err);
              if (httpError === T.HTTPError.CLIENT_NOT_FOUND_ERROR) {
                groupStore.setState({
                  moveContentStatus: T.APIStatus.ERROR,
                  moveContentError: httpError,
                });
                removeContentFromTree(content);
                contentsStore.getState().removeContent(id);

                return [CheckAndRemoveGroup({ id }), CheckAndRemoveGroup({ id: target.id })];
              }

              return [];
            })
          )
      );
    }),
    tap(() => groupStore.setState({ moveContentStatus: T.APIStatus.PROGRESS }))
  );

const removeGroupChildrenEpic: Epic<Action, Action, T.State> = action$ =>
  action$.pipe(
    ofType(RemoveGroupChildren),
    mergeMap(({ id }) => {
      const { allIds, byId } = contentsStore.getState().contents;
      const childrenIds: Array<T.Content['id']> = allIds.reduce<Array<T.Content['id']>>(
        (acc, cid) => {
          const child: T.Content | undefined = byId[cid];
          if (child?.groupId === id) {
            return acc.concat(cid);
          }

          return acc;
        },
        []
      );

      childrenIds.map(cid => contentsStore.getState().removeContent(cid));
      return [];
    })
  );

const checkAndRemoveGroupEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(CheckAndRemoveGroup),
    mergeMap(({ id }) => {
      const { byId } = contentsStore.getState().contents;
      const selectedGroupIdByTab = groupStore.getState().selectedGroupIdByTab;
      const content: T.Content | undefined = byId[id];

      if (content === undefined) {
        return [];
      }

      const groupId = content.type === T.ContentType.GROUP ? content.id : content.groupId;
      if (groupId === undefined) {
        return [];
      }

      const selectedGroupId = selectedGroupIdByTab[CategoryToTabMapper[content.category]];

      // Always clear editing content because
      // there's a great chance user is currently selecting a deleted content.
      const baseActions: Action[] = [ChangeEditingContent({})];

      // If it happens to be the currently selected group, deselect them.
      const actions: Action[] =
        selectedGroupId === content.id
          ? baseActions.concat([
              ChangeSelectedGroupId({ tab: CategoryToTabMapper[content.category] }),
            ])
          : baseActions;

      const authHeader: AuthHeader | undefined = makeAuthHeader(
        state$.value.Auth,
        state$.value.PlanConfig.config?.slug
      );
      const URL: string = `${makeV2APIURL(
        'projects',
        content.projectId,
        'contents'
      )}?contentIds=[${groupId}]`;

      return concat<Action>(
        rxjsHttp.get(URL, { headers: authHeader }).pipe(
          map(({ response }) => response.data),
          mergeMap((groups: T.GroupContent[]) => {
            // This means the group is already gone in DB,
            // possibly by another user. Remove them in the client.
            if (groups.length === 0) {
              removeContentFromTree(content);
              contentsStore.getState().removeContent(groupId);
              return actions.concat([RemoveGroupChildren({ id: groupId })]);
            }

            return [];
          }),
          catchError(err => {
            // eslint-disable-next-line no-console
            console.error(err);

            return [];
          })
        )
      );
    })
  );

const rebuildTreeEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(ChangeContents),
    mergeMap(({ contents }) => {
      const tree = createInitialContentsTree();
      const lastSelectedScreenId = state$.value.ProjectConfigPerUser.config?.lastSelectedScreenId;
      contents.forEach(content => {
        const isGroupType = content.type === T.ContentType.GROUP;

        const groupId = content.groupId ?? (isGroupType ? content.id : undefined);
        if (groupId && tree.idsByGroup[groupId] === undefined) {
          tree.idsByGroup[groupId] = [];
        }
        if (isGroupType && tree.idsByGroup[content.id] === undefined) {
          tree.idsByGroup[content.id] = [];
        }

        // Only root-level content should be in the rootIdsByCategory,
        // otherwise it should at least belong to a group in idsByGroup.
        if (content.groupId === undefined) {
          const contentCategory = tree.rootIdsByCategory[content.category];
          const spaceCategory = tree.rootIdsBySpace[content.category];

          // Sort the content by its screen.
          if (!content.screenId) {
            contentCategory.pinned.push(content.id);
          } else {
            if (contentCategory.unpinned[content.screenId] === undefined) {
              contentCategory.unpinned[content.screenId] = [];
            }
            contentCategory.unpinned[content.screenId].push(content.id);
          }
          if (!content.screenId) {
            if (content.isPersonal) {
              spaceCategory.personal.push(content.id);
            } else {
              spaceCategory.open.push(content.id);
            }
          } else if (
            content.screenId === lastSelectedScreenId &&
            content.category !== T.ContentCategory.PRESENTATION
          ) {
            if (content.isPersonal) {
              spaceCategory.personal.push(content.id);
            } else {
              spaceCategory.open.push(content.id);
            }
          } else if (content.category === T.ContentCategory.PRESENTATION) {
            if (content.isPersonal) {
              spaceCategory.personal.push(content.id);
            } else {
              spaceCategory.open.push(content.id);
            }
          }
        } else {
          tree.idsByGroup[content.groupId].push(content.id);
        }
      });
      groupStore.setState({ tree });
      return [];
      // return [RebuildTree({ tree })];
    })
  );

// const addContentToTreeEpic: Epic<Action, Action, T.State> = action$ =>
//   action$.pipe(
//     ofType(AddContentToTree),
//     mergeMap(({ content, target, moveOption }) => [
//       AddContentToGroupTree({ content, target, moveOption }),
//       AddContentToCategoryTree({ content, target, moveOption }),
//     ])
//   );

export const addContentToTree = (
  content: T.Movable,
  moveOption: T.MoveOption,
  target?: T.Movable
) => {
  groupStore.getState().addContentToGroupTree(content, moveOption, target);
  groupStore.getState().addContentToCategoryTree(content, moveOption, target);
};

export const removeContentFromTree = (content: T.Movable) => {
  groupStore.getState().removeContentFromGroupTree(content);
  groupStore.getState().removeContentFromCategoryTree(content);
};

const changeSelectedGroupEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.pipe(
    ofType(ChangeSelectedGroupId),
    mergeMap(({ selectedGroupId }) => {
      const actions: Action[] = [];
      const s = state$.value;
      const sidebarTab = s.Pages.Contents.sidebarTab;
      const category: T.ContentCategory = TabToCategoryMapper[sidebarTab];
      const byId = contentsStore.getState().contents.byId;

      if (selectedGroupId) {
        const group = byId[selectedGroupId];
        if (group?.groupId) {
          switch (category) {
            case T.ContentCategory.ESS:
              break;
            default:
              actions.push(
                PatchContent({
                  content: { id: group.groupId, info: { isOpened: true } },
                })
              );
          }
        }
      }
      groupStore.getState().changeSelectedGroupId(sidebarTab, selectedGroupId);
      return actions;
    })
  );

export const epic: Epic<Action, Action, T.State> = combineEpics(
  addNewGroupEpic,
  copySelectedGroupEpic,
  moveContentEpic,
  removeGroupChildrenEpic,
  checkAndRemoveGroupEpic,
  rebuildTreeEpic,
  changeSelectedGroupEpic
);

// Redux reducer
const initialState: T.GroupsState = {
  selectedGroupIdByTab: {
    [T.ContentPageTabType.OVERLAY]: undefined,
    [T.ContentPageTabType.MEASUREMENT]: undefined,
    [T.ContentPageTabType.ESS]: undefined,
    [T.ContentPageTabType.MAP]: undefined,
    [T.ContentPageTabType.PHOTO]: undefined,
    [T.ContentPageTabType.ISSUE]: undefined,
    [T.ContentPageTabType.DASHBOARD]: undefined,
    [T.ContentPageTabType.DRONE_STATION]: undefined,
    [T.ContentPageTabType.PRESENTATION]: undefined,
    [T.ContentPageTabType.FLIGHT_PLAN]: undefined,
    [T.ContentPageTabType.FLIGHT_SCHEDULE]: undefined,
    [T.ContentPageTabType.VIEWPOINT_CAPTURE]: undefined,
  },
  isCreatingNewGroup: false,
  isGroupAlreadyDeleted: false,
  isCreatingContentOnDeletedGroup: false,
  tree: createInitialContentsTree(),
  moveContentStatus: T.APIStatus.IDLE,
};

const reducer: Reducer<T.GroupsState> = (state = initialState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
