import React, { FC, FormEvent, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import * as T from '^/types';
import Text from './text';

import CMInput from '^/components/atoms/CMInput';
import dsPalette from '^/constants/ds-palette';
import { l10n } from '^/utilities/l10n';

interface CustomStyleProps {
  readonly customStyle?: CSSObject;
}
const Root = styled.div<CustomStyleProps>(({ customStyle }) => ({
  ...customStyle,
}));

const Wrapper = styled.div<CustomStyleProps>(
  {
    positive: 'relative',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const Label = styled.label<CustomStyleProps>(
  {
    display: 'block',
    marginBottom: '10px',

    fontWeight: 500,
    color: palette.darkBlack.toString(),
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const Message = styled.p({
  display: 'block',
  marginTop: '10px',

  lineHeight: 1.3,
  fontSize: '14px',
});

const ErrorMessage = styled(Message)({
  color: palette.error.toString(),
});

const CFSInputContainer = styled.div({
  position: 'relative',
});

const CFSInputUnit = styled.div({
  position: 'absolute',
  right: '7px',
  top: '50%',
  transform: 'translateY(-50%)',
  color: dsPalette.themePrimary.toString(),
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: '700',
});

export type CustomFlightScheduleInputFieldKind = 'altitude' | 'batteryLevel' | 'storageLevel';

const kindToType: Record<CustomFlightScheduleInputFieldKind, string> = {
  altitude: 'number',
  batteryLevel: 'number',
  storageLevel: 'number',
};

const kindToLabel: Record<CustomFlightScheduleInputFieldKind, (language: T.Language) => string> = {
  altitude: language => l10n(Text.altitudeLabel, language),
  batteryLevel: language => l10n(Text.batteryLevelLabel, language),
  storageLevel: language => l10n(Text.storageLevelLabel, language),
};

type LangToStr = (language: T.Language) => string;
/**
 * @todo many duplicated functions, we could reduce these functions with lodash
 */
const kindToPlaceholder: Record<CustomFlightScheduleInputFieldKind, LangToStr> = {
  altitude: language => l10n(Text.rthAltitudePlaceholder, language),
  batteryLevel: language => l10n(Text.batteryLevelLabelPlaceholder, language),
  storageLevel: language => l10n(Text.storageLevelLabelPlaceholder, language),
};

export interface Props {
  readonly kind: CustomFlightScheduleInputFieldKind;
  readonly value: number | string;
  readonly error?: string;
  readonly rootStyle?: CSSObject;
  readonly labelStyle?: CSSObject;
  readonly wrapperStyle?: CSSObject;
  readonly inputStyle?: CSSObject;
  onChange(kind: CustomFlightScheduleInputFieldKind, value: string): void;
  onBlur?(kind: CustomFlightScheduleInputFieldKind, value: string): void;
}

const CustomFlightScheduleInput: FC<Props & L10nProps> = ({
  kind,
  value,
  error,
  rootStyle,
  labelStyle,
  wrapperStyle,
  inputStyle,
  language,
  onChange,
}) => {
  const handleInputChange: (event: FormEvent<HTMLInputElement>) => void = event => {
    onChange(kind, event.currentTarget.value);
  };

  const withError: boolean = error !== undefined;
  const errorMessage: ReactNode = withError ? (
    <ErrorMessage data-testid="sign-up-input-field-error">{`* ${error}`}</ErrorMessage>
  ) : undefined;

  return (
    <Root customStyle={rootStyle}>
      <Wrapper customStyle={wrapperStyle}>
        <Label customStyle={labelStyle}>{kindToLabel[kind](language)}</Label>
        <CFSInputContainer>
          <CMInput
            customStyle={inputStyle}
            type={kindToType[kind]}
            placeholder={kindToPlaceholder[kind](language)}
            error={withError}
            value={value}
            onChange={handleInputChange}
          />
          {kind === 'batteryLevel' && <CFSInputUnit>%</CFSInputUnit>}
          {kind === 'storageLevel' && <CFSInputUnit>MB</CFSInputUnit>}
        </CFSInputContainer>
        {errorMessage}
      </Wrapper>
    </Root>
  );
};
export default withL10n(CustomFlightScheduleInput);
