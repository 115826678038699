import React from 'react';
import LoadingSvg from '^/assets/icons/photo-request/loading.svg';
import styled, { css, keyframes, Keyframes } from 'styled-components';

const rotationAnimation: Keyframes = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `;

const LoadingDiv = styled.div`
  animation: ${css`
      ${rotationAnimation}`} 1s infinite;
`;

const SvgWrapper = styled.div<{ spinnerSize: number }>(({ spinnerSize }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > svg': {
    width: `${spinnerSize}px`,
    height: `${spinnerSize}px`,
  },
}));

interface Props {
  spinnerSize?: number;
}
export default function NewLoadingIcon({ spinnerSize = 30 }: Props) {
  return (
    <LoadingDiv>
      <SvgWrapper spinnerSize={spinnerSize}>
        <LoadingSvg />
      </SvgWrapper>
    </LoadingDiv>
  );
}
