import palette from '^/constants/palette';
import theme from '^/theme';
import React from 'react';
import styled, { CSSObject } from 'styled-components';

interface SegmentedButtonProps {
  isSelected: boolean;
  btnPosition: TButtonPosition;
  isDisabled?: boolean;
}

export enum TButtonPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  DEFAULT = 'default',
}
const SegmentedButtonWrapper = styled.button<SegmentedButtonProps>(
  ({ btnPosition, isSelected, isDisabled = false }) => ({
    opacity: isDisabled ? 0.6 : 1,
    cursor: isDisabled ? 'default' : 'pointer',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '50px',
    height: '32px',
    backgroundColor: isSelected
      ? theme.colorTokens.accentBlue
      : palette.white.alpha(0.64).toString(),
    fontSize: '10px',
    fontWeight: 'bold',
    color: isSelected ? palette.white.toString() : 'var(--color-theme-primary-lighter)',

    ...(() => {
      switch (btnPosition) {
        case 'left': {
          return {
            borderRadius: '5px 0px 0px 5px',
            borderRight: `0.5px solid ${palette.borderGray.toString()}`,
          };
        }
        case 'right': {
          return {
            borderRadius: '0px 5px 5px 0px',
          };
        }
        case 'center': {
          return {
            borderRadius: '0px',
            borderRight: `0.5px solid ${palette.borderGray.toString()}`,
          };
        }
        case 'default':
        default: {
          return {
            borderRadius: '5px',
          };
        }
      }
    })(),

    ':hover': !(isSelected || isDisabled)
      ? {
          backgroundColor: palette.ContentsList.hoverGray.toString(),
        }
      : undefined,
  })
);

interface TProps {
  text: string;
  isSelected: boolean;
  onClick(): void;
  btnPosition: TButtonPosition;
  isDisabled?: boolean;
  segmentedButtonStyles?: CSSObject;
}
const SegmentedButton = (props: TProps) => {
  const { text, onClick, btnPosition, isSelected, isDisabled, segmentedButtonStyles } = props;
  return (
    <>
      <SegmentedButtonWrapper
        onClick={onClick}
        isSelected={isSelected}
        data-selected={isSelected}
        data-testid={text}
        btnPosition={btnPosition}
        isDisabled={isDisabled}
        style={segmentedButtonStyles}
        data-state={isDisabled ? 'disabled' : 'enabled'}
        type="button"
      >
        {text}
      </SegmentedButtonWrapper>
    </>
  );
};

export default SegmentedButton;
