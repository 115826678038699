import React, { memo } from 'react';
import styled, { CSSObject } from 'styled-components';

const WarningWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
  width: '100%',
});

const WarningText = styled.span<{ color: CSSObject['color']; underline?: boolean }>(
  ({ color, underline }) => ({
    color: color,
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    textDecoration: underline ? 'underline' : 'none',
    cursor: underline ? 'pointer' : 'auto',
    wordWrap: 'break-word',
  })
);

const WarningContainer = styled.div({
  display: 'flex',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  background: '#FFF3BA',
  flexDirection: 'column',
  width: '100%',
  boxSizing: 'border-box',
});

interface Props {
  readonly description: string;
  readonly actionText: string;
  onClick(): void;
}

function WarningBox({ description, actionText, onClick }: Props) {
  return (
    <WarningWrapper>
      <WarningContainer>
        <WarningText color="#4D4C4C">{description}</WarningText>
        <WarningText underline={true} onClick={onClick} color="#4D4C4C">
          {actionText}
        </WarningText>
      </WarningContainer>
    </WarningWrapper>
  );
}

export default memo(WarningBox);
