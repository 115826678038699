/* eslint-disable max-lines */
import React, { FC, memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import {
  ContentsListItem,
  HorizontalDivider,
} from '^/components/atoms/ContentsListItem/ForIssueItem';
import PriorityDropdown, { PriorityOption } from '^/components/atoms/Dropdown/ForIssuePriority';
import StatusDropdown, {
  StatusOption,
  createOptions,
} from '^/components/atoms/Dropdown/ForIssueStatus';
import CategoryDropdown, {
  CategoryOption,
  createOptions as createCategoryOptions,
} from '^/components/atoms/Dropdown/ForIssueCategory';
import ContentDescriptionLarge from '^/components/molecules/ContentDescriptionLarge';
import { UseL10n, useL10n } from '^/hooks';
import { RequestMarkerElevationInfo /*, SetIssuePinSelected */ } from '^/store/duck/Contents';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import ConcernedParties from '^/components/molecules/ConcernedParties';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import Tippy from '@tippyjs/react';
import { issuePriorityOptions } from '^/constants/issue';
import palette from '^/constants/palette';
import { useIssueStore } from '^/store/issue';
import { usePostIssueCategory } from '^/store/react-query/issue';
import IssuePhotoAttachments from '^/components/molecules/IssuePhotoAttachments';
import IssueDueDatePicker from '^/components/molecules/IssueDueDatePicker';
import useIssueContents from '^/hooks/useIssueContents';

const truncateStyle: CSSObject = {
  height: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Tooltip = styled(Tippy)({
  wordWrap: 'break-word',
});

const Header = styled.div({
  width: '100%',

  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),

  position: 'relative',

  top: '-12px',

  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  pointerEvents: 'none',
});

const ContentsList = styled.ul({
  width: '100%',
  boxSizing: 'border-box',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ContentSummaryWrapper = styled.div({
  width: '100%',
  marginBottom: '-8px',
});

const ContentTitle = styled.div({
  maxWidth: '230px',
  marginBottom: '25px',
  pointerEvents: 'visible',
  ...truncateStyle,
});

const ContentCreatedWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  pointerEvents: 'visible',
});
const ContentCreatedTitle = styled.div({});
const ContentCreatedDetail = styled.div({
  maxWidth: '168px',
  ...truncateStyle,
});

const DropdownWrapper = styled.div({
  width: '100%',

  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '22px',

  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),

  marginBottom: '10px',
});

const DropdownArea = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),
});
const DropdownTitle = styled.div({
  marginBottom: '6px',
});

export interface Props {
  content: T.IssueContent;
  isPinned?: boolean;
}

const RawContentsListIssueItem: FC<Props> = memo(({ content, isPinned = false }) => {
  const dispatch: Dispatch = useDispatch();
  const issueStatusOptions = useIssueStore(s => s.issueStatusOptions);
  const issueCategoryOptions = useIssueStore(s => s.issueCategoryOptions);
  // const editingContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);
  const editingContentId = useIssueStore().editingIssueContentId;
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const isEditing: boolean = content.id === editingContentId;
  const [l10n, language]: UseL10n = useL10n();
  const contentTitleRef = useRef<HTMLDivElement | null>(null);
  const createdByRef = useRef<HTMLDivElement | null>(null);
  const createdAtRef = useRef<HTMLDivElement | null>(null);
  const postIssueCategory = usePostIssueCategory();
  const { patchIssueContent } = useIssueContents();

  if (projectId === undefined) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }

  useEffect(() => {
    if (isEditing) {
      dispatch(RequestMarkerElevationInfo({ contentId: content.id }));
    }
  }, [isEditing]);

  const createdAtData: Record<string, number> = {
    fullYear: content.createdAt ? content.createdAt.getFullYear() : 0,
    monthIndex: content.createdAt ? content.createdAt.getMonth() : 0,
    weekdayIndex: content.createdAt ? content.createdAt.getDay() : 0,
    date: content.createdAt ? content.createdAt.getDate() : 0,
  };

  const createdAtString: string = (() => {
    switch (language) {
      case T.Language.KO_KR:
        // eslint-disable-next-line max-len
        return `${createdAtData.fullYear}${Text.year[T.Language.KO_KR]} ${
          Text.month[T.Language.KO_KR][createdAtData.monthIndex]
        } ${createdAtData.date}${Text.date[T.Language.KO_KR]} ${
          Text.weekday[T.Language.KO_KR][createdAtData.weekdayIndex]
        }`;
      case T.Language.EN_US:
        // eslint-disable-next-line max-len
        return `${Text.weekday[T.Language.EN_US][createdAtData.weekdayIndex]}, ${
          Text.month[T.Language.EN_US][createdAtData.monthIndex]
        } ${createdAtData.date}, ${createdAtData.fullYear}`;
      default:
        return '';
    }
  })();

  const rawContentTitleDiv = <ContentTitle ref={contentTitleRef}>{content.title}</ContentTitle>;
  const contentTitleDiv =
    contentTitleRef.current &&
    contentTitleRef.current.offsetWidth < contentTitleRef.current.scrollWidth ? (
      <Tooltip
        theme="angelsw"
        offset={T.TIPPY_OFFSET}
        arrow={false}
        content={content.title}
        placement="bottom"
      >
        {rawContentTitleDiv}
      </Tooltip>
    ) : (
      rawContentTitleDiv
    );

  const rawCreatedByDiv = (
    <ContentCreatedDetail ref={createdByRef}>{content.createdBy?.name}</ContentCreatedDetail>
  );
  const createdByDiv =
    createdByRef.current &&
    createdByRef.current?.offsetWidth < createdByRef.current?.scrollWidth ? (
      <Tooltip
        theme="angelsw"
        offset={T.TIPPY_OFFSET}
        arrow={false}
        content={content.createdBy?.name}
        placement="bottom"
      >
        {rawCreatedByDiv}
      </Tooltip>
    ) : (
      rawCreatedByDiv
    );

  const rawCreatedAtDiv = (
    <ContentCreatedDetail ref={createdAtRef}>{createdAtString}</ContentCreatedDetail>
  );
  const createdAtDiv =
    createdByRef.current &&
    createdByRef.current?.offsetWidth < createdByRef.current?.scrollWidth ? (
      <Tooltip
        theme="angelsw"
        offset={T.TIPPY_OFFSET}
        arrow={false}
        content={createdAtString}
        placement="bottom"
      >
        {rawCreatedAtDiv}
      </Tooltip>
    ) : (
      rawCreatedAtDiv
    );

  const onClickPriorityDropdown = (option: PriorityOption) => {
    patchIssueContent({
      content: {
        id: content.id,
        priority: option.value,
      },
      isUndoable: true,
    });
  };

  const onClickStatusDropdown = (option: StatusOption) => {
    patchIssueContent({
      content: {
        id: content.id,
        issueStatusId: option.value.id,
      },
      isUndoable: true,
    });
  };

  const onClickCategoryDropdown = (option: CategoryOption) => {
    patchIssueContent({
      content: {
        id: content.id,
        issueCategoryId: option.value.id,
      },
    });
  };

  const onAddCategory = (rawNewCategory: string) => {
    const newCategory = rawNewCategory.trim();
    if (newCategory.length <= 0) {
      return;
    }

    postIssueCategory({
      id: content.id,
      label: newCategory,
    });
  };

  return (
    <ContentsListItem isPinned={isPinned} className={CANCELLABLE_CLASS_NAME} content={content}>
      <Header>
        <ContentSummaryWrapper>
          {contentTitleDiv}
          <ContentCreatedWrapper style={{ marginBottom: '8px' }}>
            <ContentCreatedTitle>{l10n(Text.createdBy)}</ContentCreatedTitle>
            {createdByDiv}
          </ContentCreatedWrapper>
          <ContentCreatedWrapper>
            <ContentCreatedTitle>{l10n(Text.createdAt)}</ContentCreatedTitle>
            {createdAtDiv}
          </ContentCreatedWrapper>
        </ContentSummaryWrapper>
      </Header>
      <HorizontalDivider />
      <ContentsList>
        <DropdownArea style={{ marginBottom: '10px' }}>
          <DropdownTitle>{l10n(Text.category.title)}</DropdownTitle>
          <CategoryDropdown
            language={language}
            isSearchEnable={true}
            valueId={content.issueCategoryId}
            options={createCategoryOptions(issueCategoryOptions)}
            onClick={onClickCategoryDropdown}
            onAddCategory={onAddCategory}
            placeHolder={l10n(Text.category.placeHolder)}
            horizontalPadding="10.5px"
            labelVerticalPadding="16px"
            zIndex={0}
          />
        </DropdownArea>
        <IssueDueDatePicker content={content} />
        <DropdownWrapper>
          <DropdownArea>
            <DropdownTitle>{l10n(Text.priority)}</DropdownTitle>
            <PriorityDropdown
              value={content.priority}
              options={issuePriorityOptions[language]}
              onClick={onClickPriorityDropdown}
              placeHolder={l10n(Text.priority)}
              zIndex={0}
            />
          </DropdownArea>
          <DropdownArea>
            <DropdownTitle>{l10n(Text.status)}</DropdownTitle>
            <StatusDropdown
              valueId={content.issueStatusId}
              options={createOptions(issueStatusOptions, language)}
              onClick={onClickStatusDropdown}
              placeHolder={l10n(Text.status)}
              zIndex={0}
            />
          </DropdownArea>
        </DropdownWrapper>
        <ContentDescriptionLarge content={content} />
        <ConcernedParties content={content} />
        <IssuePhotoAttachments content={content} />
      </ContentsList>
    </ContentsListItem>
  );
});

export const ContentsListIssueItem: FC<Props> =
  withErrorBoundary(RawContentsListIssueItem)(Fallback);
