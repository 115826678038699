import {
  BufferAttribute,
  BufferGeometry,
  Color,
  Group,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  WireframeGeometry,
} from 'three';

import { Viewer } from '../../ThreeInteraction/Viewer';
import { autobind } from 'core-decorators';
import { DisposeHelper } from '../../Lib/Helper';
import { LabelObject } from '../Label';
import palette from '^/constants/palette';
import * as T from '^/types';
import * as ColorParam from 'color';
import { EditorMarkerController } from '../../Lib/Controllers/Marker';
import proj4 from 'proj4';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import earcut from 'earcut';
export class DesignDXFObject extends Group {
  private readonly viewer;
  public contentId: number | string;
  public label: LabelObject | null = null;
  public editor: EditorMarkerController | null = null;
  private readonly line: LineSegments = new LineSegments();
  public colorMaterial: Color;
  private depthTestAgainstTerrain: boolean = false;
  private materialOpacity: number = 1;
  private readonly surface: Mesh = new Mesh();
  public constructor(
    contentId: number | string,
    viewer: Viewer,
    url: string,
    color: ColorParam = palette.overlays[T.ContentType.DESIGN_DXF]
  ) {
    super();
    this.viewer = viewer;
    this.contentId = contentId;
    this.colorMaterial = new Color(color.hex());
    this.renderOrder = 2;
    this.surface.renderOrder = 100;
    this.line.renderOrder = 101;
    this.add(this.surface, this.line);
    this.init(url);
  }
  public get opacity(): number {
    return this.materialOpacity;
  }
  public set opacity(value: number) {
    this.materialOpacity = value / 100;
    this.setOpacity();
  }
  public get depthTest(): boolean {
    return this.depthTestAgainstTerrain;
  }
  public set depthTest(value: boolean) {
    this.depthTestAgainstTerrain = value;
    this.setDepthTest();
  }
  /**
   * @private
   * @param {  string } string
   * @description load dxf file
   */
  @autobind
  private init(url: string) {
    const surfaceVertices: number[] = [];
    void fetch(url, { credentials: 'include' }).then(async value => {
      value
        .json()
        .then(json => {
          json.features.forEach((feature: any) => {
            // Check if the feature's geometry type is "Polygon."
            if (feature.geometry.type === 'Polygon') {
              // 3. Extract the 3D coordinates for the polygon.
              const data = earcut.flatten(feature.geometry.coordinates);

              // 4. Convert 3D coordinates to surface.
              const triangles = earcut(data.vertices, data.holes, data.dimensions);
              for (let i = 0; i < triangles.length; i += 3) {
                for (let j = 0; j < 3; j++) {
                  const index = triangles[i + j] * 3;
                  const latLon = proj4('EPSG:3857', 'EPSG:4326', [
                    data.vertices[index],
                    data.vertices[index + 1],
                  ]);
                  const { x, y, z } = this.viewer.runtime!.getPositionFromLatLongHeight({
                    lat: latLon[1],
                    long: latLon[0],
                    height: 0,
                  });
                  surfaceVertices.push(x, y, z + Number(data.vertices[index + 2]));
                }
              }
            }
          });

          // 5. Create Three.js geometry.
          const surFaceGeometry = new BufferGeometry();
          surFaceGeometry.setAttribute(
            'position',
            new BufferAttribute(new Float32Array(surfaceVertices), 3)
          );

          // 6. Create Three.js mesh and add it to the scene.

          this.surface.geometry.dispose();
          this.surface.geometry = surFaceGeometry;
          this.surface.material = new MeshStandardMaterial({
            color: this.colorMaterial,
            depthTest: this.depthTestAgainstTerrain,
            transparent: true,
            opacity: this.materialOpacity,
            flatShading: true,
          });

          const wireframe = new WireframeGeometry(this.surface.geometry);
          this.line.geometry.dispose();
          this.line.geometry = wireframe;
          this.line.material = new LineBasicMaterial({
            color: this.colorMaterial,
            depthTest: this.depthTestAgainstTerrain,
            transparent: true,
            opacity: this.materialOpacity,
          });
        })
        .catch(ex => {
          // eslint-disable-next-line no-console
          console.log(ex);
        });
    });
  }
  /**
   * @public
   * @description set depthtest for material
   * @returns {void}
   */
  @autobind
  public setDepthTest(): void {
    (this.line.material as LineBasicMaterial).depthTest = this.depthTestAgainstTerrain;
    (this.surface.material as MeshBasicMaterial).depthTest = this.depthTestAgainstTerrain;
  }
  /**
   * @public
   * @description set line color
   * @returns {void}
   */
  @autobind
  public setColor(color: string): void {
    (this.line.material as LineBasicMaterial).color.set(color);
    (this.surface.material as MeshBasicMaterial).color.set(color);
  }
  /**
   * @public
   * @description set highlight line
   */
  @autobind
  public setHighlight(): void {
    (this.line.material as LineBasicMaterial).color.lerpColors(
      (this.line.material as LineBasicMaterial).color,
      new Color(0x1f4782),
      0.5
    );
    (this.surface.material as MeshBasicMaterial).color.lerpColors(
      (this.surface.material as MeshBasicMaterial).color,
      new Color(0x1f4782),
      0.5
    );
  }

  /**
   * @private
   * @description set un highlight line
   */
  @autobind
  public setUnHighlight(): void {
    (this.line.material as LineBasicMaterial).color.set(this.colorMaterial);
    (this.surface.material as MeshBasicMaterial).color.set(this.colorMaterial);
  }
  /**
   * @private
   * @description set opacity
   */
  @autobind
  private setOpacity(): void {
    (this.line.material as MeshBasicMaterial).opacity = this.materialOpacity;
    (this.surface.material as MeshBasicMaterial).opacity = this.materialOpacity;
  }
  /**
   * @public
   * @description Dispose all
   */
  @autobind
  public dispose(): void {
    DisposeHelper.disposeHierarchy(this);
  }
  /**
   * @public
   * @description This function will auto-call when the scene render
   * @param {  boolean | undefined} force
   * @returns { void}
   */
  @autobind
  public updateMatrixWorld(force: boolean | undefined) {
    super.updateMatrixWorld(force);
  }
}
