import { GOOGLE_MAPS_MAX_ZOOM } from '^/constants/map-display';
import { Map } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { get } from 'ol/proj';
import XYZSource from 'ol/source/XYZ';
import { FC, useEffect, useState } from 'react';

export interface Props {
  readonly zIndex: number;
  readonly baseMap: Map | null;
}
export const OlGoogleMapLayerVslam: FC<Props> = props => {
  const { zIndex, baseMap } = props;
  // const { baseMap } = useVslamAnd360Store(state => state.georeferencingMapDetails);

  const preload: number = 2;
  const [layer] = useState(
    new TileLayer({
      source: new XYZSource({
        url: 'https://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
        projection: get('EPSG:3857') ?? undefined,
        maxZoom: GOOGLE_MAPS_MAX_ZOOM,
      }),
      preload: preload,
      zIndex: zIndex,
    })
  );

  useEffect(() => {
    baseMap?.addLayer(layer);

    return () => {
      baseMap?.removeLayer(layer);
    };
  }, [layer, baseMap]);

  return null;
};
