import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { mergeMap, take } from 'rxjs/operators';
import { config } from '^/config';

import { RELEASE_VERSION } from '^/constants/data';

import { EPIC_RELOAD } from '^/constants/epic';
import { HEADER_SLUG_PROP } from '^/constants/network';

import { AuthState, HTTPError, PlanConfig, Region, SharedContentsState } from '^/types';
import { AxiosError } from 'axios';

type ActionsForEpicEnd = <A extends Action>(...actions: A[]) => Epic<A, any>;
export const actionsForEpicReload: ActionsForEpicEnd =
  (...actions) =>
  action$ =>
    action$.ofType(EPIC_RELOAD).pipe(
      take(1),
      mergeMap(() => actions)
    );

export const getRequestErrorTypeAxios: (axiosError: AxiosError) => HTTPError = axiosError => {
  const HTTPStatusBoundary: number = 100;
  const ClientErrorDigit: number = 4;
  const ServerErrorDigit: number = 5;

  const status = axiosError.request.status;

  if (status === 409) {
    return HTTPError.CLIENT_OUTDATED_ERROR;
  }

  // One example is when a content is created with inexistent group id.
  if (status === 406) {
    return HTTPError.CLIENT_NOT_ACCEPTED_ERROR;
  }

  // 403 means user is unauthorized to do certain action.
  // This includes logging in to a plan that doesn't belong to them,
  // or accessing a project that doesn't belong to them.
  if (status === 403) {
    return HTTPError.CLIENT_UNAUTHORIZED_ERROR;
  }

  if (status === 404) {
    return HTTPError.CLIENT_NOT_FOUND_ERROR;
  }

  switch (Math.round(status / HTTPStatusBoundary)) {
    case ClientErrorDigit:
      return HTTPError.CLIENT_ERROR;
    case ServerErrorDigit:
      return HTTPError.SERVER_ERROR;
    default:
      return HTTPError.UNKNOWN_ERROR;
  }
};

export const getRequestErrorType: (ajaxError: AjaxError) => HTTPError = ajaxError => {
  const HTTPStatusBoundary: number = 100;
  const ClientErrorDigit: number = 4;
  const ServerErrorDigit: number = 5;

  if (ajaxError.status === 409) {
    return HTTPError.CLIENT_OUTDATED_ERROR;
  }

  // One example is when a content is created with inexistent group id.
  if (ajaxError.status === 406) {
    return HTTPError.CLIENT_NOT_ACCEPTED_ERROR;
  }

  // 403 means user is unauthorized to do certain action.
  // This includes logging in to a plan that doesn't belong to them,
  // or accessing a project that doesn't belong to them.
  if (ajaxError.status === 403) {
    return HTTPError.CLIENT_UNAUTHORIZED_ERROR;
  }

  if (ajaxError.status === 404) {
    return HTTPError.CLIENT_NOT_FOUND_ERROR;
  }

  switch (Math.round(ajaxError.status / HTTPStatusBoundary)) {
    case ClientErrorDigit:
      return HTTPError.CLIENT_ERROR;
    case ServerErrorDigit:
      return HTTPError.SERVER_ERROR;
    default:
      return HTTPError.UNKNOWN_ERROR;
  }
};

export interface ContentTypeHeader {
  readonly 'Content-Type': string;
}
export const wwwFormUrlEncoded: ContentTypeHeader = {
  'Content-Type': 'application/x-www-form-urlencoded',
};
export const jsonContentHeader: ContentTypeHeader = {
  'Content-Type': 'application/json',
};

export type AuthHeader = Readonly<{
  Authorization: string;
}>;
export const makeAuthHeader: (
  state: AuthState,
  slug?: PlanConfig['slug'],
  refreshToken?: string
) => AuthHeader | undefined = (state, slug) => {
  if (state.authedUser === undefined) {
    return undefined;
  }

  return {
    Authorization: state.authedUser.token,
    [HEADER_SLUG_PROP]: slug,
  };
};

export type ShareHeader = Readonly<{
  'Share-Token': string;
}>;
export const makeShareHeader: (state: SharedContentsState) => ShareHeader | undefined = ({
  shareToken,
}) => {
  if (shareToken === undefined) {
    return undefined;
  }

  return {
    'Share-Token': shareToken,
  };
};

export type VersionHeader = Readonly<{
  'X-Angelswing-Version': string;
}>;
export const makeVersionHeader: () => VersionHeader = () => ({
  'X-Angelswing-Version': RELEASE_VERSION,
});

export const protocol: string = 'https';
export const wsProtocol: string = 'wss';
export const version2: string = 'v2';
export const serverHostname: string | undefined = (() => {
  switch (config.region) {
    case Region.KSA:
      return import.meta.env.VITE_KSA_API_HOSTNAME;
    case Region.DEFAULT:
    default:
      return import.meta.env.VITE_API_HOSTNAME;
  }
})();
export const volumeServiceHostname: string | undefined = (() => {
  switch (config.region) {
    case Region.KSA:
      return import.meta.env.VITE_KSA_VCM_HOSTNAME;
    case Region.DEFAULT:
    default:
      return import.meta.env.VITE_VCM_HOSTNAME;
  }
})();
export const bucketUrl: string | undefined = (() => {
  switch (config.region) {
    case Region.KSA:
      return import.meta.env.VITE_KSA_GCS_BUCKET_URL;
    case Region.DEFAULT:
    default:
      return import.meta.env.VITE_S3_BUCKET_URL;
  }
})();
export const DjiBucketUrl: string | undefined = (() => import.meta.env.VITE_DJI_S3_BUCKET_URL)();

export const resourceBucketUrl: string | undefined = import.meta.env.VITE_RESOURCE_BUCKET_URL;
export const beamoAPIHostname = 'api.3inc.xyz';
export const beamoVendorHostname = 'angelswing-dev.3inc.xyz';

if (
  config.isBrowser &&
  (!serverHostname || !volumeServiceHostname || !bucketUrl || !resourceBucketUrl)
) {
  // eslint-disable-next-line no-console
  console.error(
    'VCM/API/Bucket URL not found. Some functionalities might not work. Please check packages/app/.env.'
  );
}

/**
 * @todo revert to normal host after screen is done or migrated
 */
export const makeV2APIURL: (...path: Array<string | number>) => string = (...path) =>
  `${protocol}://${serverHostname}/${version2}/${path.join('/')}`;

export const makeWSURL: (...path: Array<string | number>) => string = (...path) =>
  `${wsProtocol}://${serverHostname}/${path.join('/')}`;

export const makeBucketURL: (...path: Array<string | number>) => string = (...path) =>
  `${protocol}://${bucketUrl}/${path.join('/')}`;

export const makeDJIBucketURL: (...path: Array<string | number>) => string = (...path) =>
  `${protocol}://${DjiBucketUrl}/${path.join('/')}`;

export const makeResourceBucketURL: (...path: Array<string | number>) => string = (...path) =>
  `${protocol}://${resourceBucketUrl}/${path.join('/')}`;

export const makeVolumeAPIURL: (...path: Array<string | number>) => string = (...path) =>
  `${protocol}://${volumeServiceHostname}/${path.join('/')}`;

export const makeBeamoAPIURL: (...path: Array<string | number>) => string = (...path) =>
  `${protocol}://${beamoAPIHostname}/${path.join('/')}`;

export const djiString: string = 'dji';
export const makeDJIAPIURL: (...path: Array<string | number>) => string = (...path) =>
  `${protocol}://${serverHostname}/${version2}/${djiString}/${path.join('/')}`;

/**
 * This util is used to create a URL with query parameters.
 * @param urlResolvable string or URL type of the URL
 * @param query Record<string, string | number> type of query parameters object
 * @returns url with query parameters
 */
export const makeSearchURL = (
  urlResolvable: string | URL,
  query: Record<string, string | number>
) => {
  const url = new URL(urlResolvable);
  const searchParams = new URLSearchParams(
    Object.entries(query).map(([key, value]) => [key, value.toString()])
  );
  url.search = searchParams.toString();

  return url.href;
};
