/* eslint-disable max-lines */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Fallback } from './fallback';

import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { RawThreeContentsListBimtItem } from './Three';
import { RawCesiumContentsListBimtItem } from './Cesium';

export interface Props {
  readonly content: T.BimContent;
  readonly isIn3DMesh: boolean;
}

const RawContentsListBimtItem: FC<Props> = props => {
  const currentMeshEngine = useSelector((s: T.State) => s.Pages.Contents.currentMeshEngine);
  const isIn3DMesh = currentMeshEngine === T.MeshEngine.THREEJS;

  return isIn3DMesh ? (
    <RawThreeContentsListBimtItem {...props} />
  ) : (
    <RawCesiumContentsListBimtItem {...props} />
  );
};

export const ContentsListBimtItem: FC<Props> = withErrorBoundary(RawContentsListBimtItem)(Fallback);
