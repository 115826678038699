import {
  pointPlusIcon,
  pointTickIcon,
  sphereIcon,
  markerLocation,
  moveIcon,
  photoPointIcon,
  issuePointIcon,
  gcpPointIcon,
} from '^/assets/icons/imageBase64';
import {
  DoubleSide,
  MeshBasicMaterial,
  Sprite,
  SpriteMaterial,
  TextureLoader,
  Color,
  Mesh,
  BoxGeometry,
} from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { MarkerLocationObject } from '../ThreeObjects/Marker';
import { IssuePhotoObject, IssuePointObject } from '../ThreeObjects/Issue';
import ColorParam from 'color';
import Photo360PanoSvg from '^/assets/icons/photo/photo-360-icon.svg';

export const ZOOM_LEVEL_CORRECTION_SCALAR = 25;

const DEFAULT_MATERIAL_PARAMETER = {
  color: new Color(0xffffff),
  depthTest: false,
  depthWrite: false,
  sizeAttenuation: false,
};
export const DEFAULT_ISSUE_COLOR = new ColorParam('rgba(0, 0, 0, 0.2)');

export const SPRITE_MATERIAL = {
  MEASURE_ADD: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(pointPlusIcon, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  MEASURE_BLANK: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(sphereIcon, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  MEASURE_CHECK: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(pointTickIcon, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  MARKER_LOCATION: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(markerLocation, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  THREE_SIXTY_ICON: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(Photo360PanoSvg, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  TEXT_MOVE: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(moveIcon, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  PHOTO_POINT: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(photoPointIcon, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  ISSUE_POINT: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(issuePointIcon, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
  GCP_POINT: new SpriteMaterial({
    ...DEFAULT_MATERIAL_PARAMETER,
    map: new TextureLoader().setCrossOrigin('anonymous').load(gcpPointIcon, texture => {
      texture.colorSpace = 'srgb';
    }),
  }),
};

export const MEASURE_ELEMENT = {
  TEMP_LINE: new Line2(
    new LineGeometry().setPositions([0, 0, 0, 0, 0, 0]),
    new LineMaterial({ color: 0xcc0000, linewidth: 2, depthTest: false })
  ),
  TEMP_SPRITE: new Sprite(SPRITE_MATERIAL.MEASURE_BLANK),
  LINE: {
    MATERIAL: new LineMaterial({ color: 0xff0000, linewidth: 2, depthTest: true }),
  },
  AREA: {
    MATERIAL: new MeshBasicMaterial({
      transparent: true,
      color: 0xff9900,
      opacity: 0.2,
    }),
  },
};

export const MERGE_MATERIAL = new MeshBasicMaterial({
  color: 'blue',
  wireframe: true,
  side: DoubleSide,
});
MEASURE_ELEMENT.TEMP_LINE.renderOrder = 9999;
MEASURE_ELEMENT.TEMP_SPRITE.scale.set(0.02, 0.02, 0.02);

export const TEMP_MESH = new Mesh(new BoxGeometry(1, 1, 1), MERGE_MATERIAL);

export type ThreePointObjectType = MarkerLocationObject | IssuePointObject | IssuePhotoObject;
