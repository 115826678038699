/* eslint-disable max-lines */
import * as _ from 'lodash-es';
import React, { FC, ReactNode, memo, useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import { fileExtensions, fileSignatures } from './fileInformation';
import Text from './text';
import { ConfirmButton as RawConfirmButton } from '^/components/atoms/Buttons';
import { AttachUploadCoordinateSystem as CoordinateSystemDropdown } from '^/components/molecules/AttachUploadCoordinateSystem';
import { AttachUploadTitleInput as TitleInput } from '^/components/molecules/AttachUploadTitleInput';
import { CadUploadConfirmPopup } from '^/components/molecules/CadUploadConfirmPopup';
import { DateScreenInput } from '^/components/molecules/DateScreenInput';
import { FileInput, TextLabel } from '^/components/molecules/FileInput';
import Popup from '^/components/molecules/Popup';
import palette from '^/constants/palette';
import { UseL10n, UseState, useL10n } from '^/hooks';
import {
  UseIsDefaultScreenTitle,
  useGetDefaultScreenTitle,
  useIsDefaultScreenTitle,
} from '^/hooks/screens';
import { UseUploadContent, useUploadContent } from '^/hooks/useUploadContent';
import { getContentOverwriteCondition } from '^/store/duck/Contents/contentOverwriteManager';
import { ChangeInUploadIFC, CloseContentPagePopup, OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';
import { changeWordsOrderOnLang } from '^/utilities/l10n';
import { TEMP_SCREEN_ID } from '^/utilities/screen-util';
import { BIMFileInput } from '^/components/molecules/BIMFileInput';
import IFCLocationPreview from '^/components/molecules/IFCLocationPreview';
import { WarningIconSvg } from '^/assets/icons/ifc-icon';
import { FileLogInput } from '^/components/molecules/FileLogInput';
import { SetUploadedPhotoCount } from '^/store/duck/Photos';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import { EarthWorkStore } from '^/store/earthwork/types';

const Root = styled.div({
  width: 'auto',
  minWidth: '310px',
  paddingTop: '11px',
  paddingLeft: '48px',
  paddingRight: '48px',
  paddingBottom: '48px',
});
const Wrapper = styled.div({
  width: '100%',
  marginTop: '24px',
});
const ButtonsWrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '35px',
  '> button + button': {
    marginLeft: '9px',
  },
});
const ConfirmButton = styled(RawConfirmButton)(({ isDisabled }) => ({
  cursor: 'pointer',
  opacity: 1,

  ...(isDisabled
    ? {
        backgroundColor: palette.iconDisabled.toString(),
        color: palette.buttonFontColor.toString(),
      }
    : undefined),
}));

const OverwritingWarningMsg = styled.p({
  marginTop: 7,

  color: palette.UploadPopup.error.toString(),
  fontSize: '12px',
  lineHeight: 1.35,
});

// const PreventClickOverlay = styled.div({
//   position: 'fixed',
//   width: '100%',
//   height: '100%',
//   left: '0',
//   top: '0',
//   zIndex: 9999,
// });

const WarningWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
  width: '440px',
});

const WarningText = styled.span<{ color: CSSObject['color'] }>(({ color }) => ({
  color: color,
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '23px',
  whiteSpace: 'pre-wrap',
  textAlign: 'left',
  wordWrap: 'break-word',
}));

const WarningContainer = styled.div({
  display: 'inline-flex',
  padding: '8px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '8px',
  background: '#FFF3BA',
});

export type UploadPagePopupTypes =
  | T.ContentPagePopupType.PHOTO_UPLOAD
  | T.ContentPagePopupType.PANORAMA_UPLOAD
  | T.ContentPagePopupType.THREE_SIXTY_SOURCE_UPLOAD
  | T.ContentPagePopupType.BLUEPRINT_UPLOAD
  | T.ContentPagePopupType.ORTHO_UPLOAD
  | T.ContentPagePopupType.DSM_UPLOAD
  | T.ContentPagePopupType.LAS_UPLOAD
  | T.ContentPagePopupType.DESIGN_UPLOAD
  | T.ContentPagePopupType.BIM_UPLOAD
  | T.ContentPagePopupType.FLIGHT_PLAN_UPLOAD
  | T.ContentPagePopupType.FLIGHT_VIDEO_UPLOAD
  | T.ContentPagePopupType.VSLAM_VIDEO_UPLOAD;

enum UI {
  TITLE = 'TITLE',
  ATTACH = 'ATTACH',
  COORDINATE = 'COORDINATE',
  SCREEN = 'SCREEN',
  BIM_ATTACH = 'BIM_ATTACH',
  LOG = 'LOG',
  VIDEO = 'VIDEO',
}

const { TITLE, ATTACH, COORDINATE, SCREEN, BIM_ATTACH, LOG, VIDEO }: typeof UI = UI;
const {
  PHOTO,
  DRONE_PHOTO,
  ORTHO,
  BLUEPRINT_PDF,
  BLUEPRINT_DXF,
  BLUEPRINT_DWG,
  DESIGN_DXF,
  DSM,
  POINTCLOUD,
  BIM,
  FLIGHT_PLAN,
  GCP,
  FLIGHT_VIDEO,
  FLIGHT_LOG,
  VSLAM_VIDEO,
  THREE_SIXTY,
  THREE_SIXTY_SOURCE,
  INSPECTION,
}: typeof T.AttachmentType = T.AttachmentType;

const attachmentTypeUIMap: {
  [K in Exclude<
    T.AttachmentType,
    | T.AttachmentType.SOURCE
    | T.AttachmentType.THREE_SIXTY_STITCHED
    | T.AttachmentType.VIEWPOINT
    | T.AttachmentType.THREE_SIXTY_VSLAM
    | T.AttachmentType.THREE_SIXTY_VIDEO
  >]: UI[];
} = {
  [FLIGHT_VIDEO]: [VIDEO, LOG],
  [PHOTO]: [ATTACH],
  [THREE_SIXTY]: [ATTACH],
  [THREE_SIXTY_SOURCE]: [ATTACH],
  [DRONE_PHOTO]: [ATTACH],
  [INSPECTION]: [ATTACH],
  [BLUEPRINT_PDF]: [TITLE, ATTACH],
  [BLUEPRINT_DXF]: [TITLE, ATTACH, COORDINATE],
  [BLUEPRINT_DWG]: [TITLE, ATTACH, COORDINATE],
  [DESIGN_DXF]: [TITLE, ATTACH, COORDINATE],
  [ORTHO]: [ATTACH, SCREEN],
  [DSM]: [ATTACH, SCREEN],
  [POINTCLOUD]: [ATTACH, SCREEN],
  [BIM]: [TITLE, COORDINATE, BIM_ATTACH],
  [FLIGHT_PLAN]: [TITLE, ATTACH],
  [GCP]: [ATTACH],
  [FLIGHT_LOG]: [ATTACH],
  [VSLAM_VIDEO]: [VIDEO],
};

const isInUI: (
  attachmentType: Exclude<
    T.AttachmentType,
    | T.AttachmentType.SOURCE
    | T.AttachmentType.THREE_SIXTY_STITCHED
    | T.AttachmentType.THREE_SIXTY_VSLAM
    | T.AttachmentType.VIEWPOINT
    | T.AttachmentType.THREE_SIXTY_VIDEO
  >,
  ui: UI
) => boolean = (attachmentType, ui) => attachmentTypeUIMap[attachmentType].includes(ui);

const popupAttachmentTypeMap: {
  [K in UploadPagePopupTypes]: Exclude<
    T.AttachmentType,
    | T.AttachmentType.BLUEPRINT_DXF
    | T.AttachmentType.BLUEPRINT_DWG
    | T.AttachmentType.SOURCE
    | T.AttachmentType.THREE_SIXTY_STITCHED
    | T.AttachmentType.THREE_SIXTY_VSLAM
    | T.AttachmentType.THREE_SIXTY_VIDEO
    | T.AttachmentType.VIEWPOINT
  >;
} = {
  [T.ContentPagePopupType.PHOTO_UPLOAD]: PHOTO,
  [T.ContentPagePopupType.PANORAMA_UPLOAD]: THREE_SIXTY,
  [T.ContentPagePopupType.THREE_SIXTY_SOURCE_UPLOAD]: THREE_SIXTY_SOURCE,
  [T.ContentPagePopupType.BLUEPRINT_UPLOAD]: BLUEPRINT_PDF,
  [T.ContentPagePopupType.DESIGN_UPLOAD]: DESIGN_DXF,
  [T.ContentPagePopupType.ORTHO_UPLOAD]: ORTHO,
  [T.ContentPagePopupType.DSM_UPLOAD]: DSM,
  [T.ContentPagePopupType.LAS_UPLOAD]: POINTCLOUD,
  [T.ContentPagePopupType.BIM_UPLOAD]: BIM,
  [T.ContentPagePopupType.FLIGHT_PLAN_UPLOAD]: FLIGHT_PLAN,
  [T.ContentPagePopupType.FLIGHT_VIDEO_UPLOAD]: FLIGHT_VIDEO,
  [T.ContentPagePopupType.VSLAM_VIDEO_UPLOAD]: VSLAM_VIDEO,
};

type ErrorsKeys = UI.TITLE | UI.ATTACH | UI.SCREEN | UI.BIM_ATTACH | UI.LOG;
type Errors = Record<
  ErrorsKeys,
  {
    isError: boolean;
    errorText?: string;
  }
>;

const INITIAL_ERRORS: Errors = {
  [TITLE]: {
    isError: false,
  },
  [ATTACH]: {
    isError: false,
  },
  [SCREEN]: {
    isError: false,
  },
  [BIM_ATTACH]: {
    isError: false,
  },
  [LOG]: {
    isError: false,
  },
};

/**
 * TODO: @ebraj-angelswing
 * Need to refactor...
 */
export const checkIsImage360: (
  files: File[],
  errorCallback: (hasError: boolean) => void
) => void = (files, errorCallback) => {
  const THREE_SIXTY_ASPECT_RATIO: number = 2;
  let fileCount: number = files.length;
  let remainFilesCount: number = files.length;
  files.forEach(file => {
    if (file && file.type.startsWith('image/')) {
      const threeSixtyImage = new Image();
      threeSixtyImage.src = URL.createObjectURL(file);
      threeSixtyImage.onload = () => {
        const width = threeSixtyImage.width;
        const height = threeSixtyImage.height;
        const aspectRatio = width / height;

        if (aspectRatio === THREE_SIXTY_ASPECT_RATIO) {
          remainFilesCount -= 1;
        }
        fileCount -= 1;
        if (fileCount === 0) {
          errorCallback(!(remainFilesCount === 0));
        }

        URL.revokeObjectURL(threeSixtyImage.src);
      };
    }
  });
};

export const checkSignatureFromFiles: (
  files: File[],
  attachmentType: T.AttachmentType,
  errorCallback: (hasError: boolean) => void
) => void = (files, attachmentType, errorCallback) => {
  let fileCount: number = files.length;
  let remainFilesCount: number = files.length;
  files.forEach(file => {
    const fileReader: FileReader = new FileReader();
    const blob: Blob = file.slice(
      0,
      _.max(fileSignatures[attachmentType].map(array => array.length))
    );
    fileReader.onloadend = () => {
      const result: string | ArrayBuffer | null = fileReader.result;
      if (result instanceof ArrayBuffer) {
        if (validateSignature(result, fileSignatures[attachmentType])) {
          remainFilesCount -= 1;
        }
      }
      fileCount -= 1;
      if (fileCount === 0) {
        errorCallback(!(remainFilesCount === 0));
      }
    };
    fileReader.readAsArrayBuffer(blob);
  });
};

const validateSignature: (bytes: ArrayBuffer, signatures: number[][]) => boolean = (
  bytes,
  signatures
) => {
  const uint8Blob: Uint8Array = new Uint8Array(bytes);

  return signatures.some(signature =>
    isEqual(uint8Blob.slice(0, signature.length), new Uint8Array(signature))
  );
};

export interface Props {
  readonly zIndex: number;
  readonly popupType: UploadPagePopupTypes;
}

const POPUP_ALPHA: number = 0.39;

export const AttachUploadPopup: FC<Props> = memo(({ zIndex, popupType: rawPopupType }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n, lang]: UseL10n = useL10n();
  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const isEarthworkDashboard = sidebarTab === T.ContentPageTabType.DASHBOARD;

  const { createNewEarthwork } = useEarthWorkStore((s: EarthWorkStore) => ({
    createNewEarthwork: s.createNewEarthwork,
  }));

  const contents = useSelector((state: T.State) => state.Contents.contents);
  const screens = useSelector((state: T.State) => state.Screens.screens);
  const uploadedPhotoCount = useSelector((state: T.State) => state.Photos.uploadedPhotoCount);
  const hideBackButton = useSelector(
    (state: T.State) => state.Pages.Contents.popup?.hideBackButton
  );
  const photoType = useSelector((state: T.State) => state.Pages.Contents.popup?.photoType);
  const currentMeshEngine = useSelector((s: T.State) => s.Pages.Contents.currentMeshEngine);

  const uploadContent: UseUploadContent = useUploadContent();
  const isDefaultScreenTitle: UseIsDefaultScreenTitle = useIsDefaultScreenTitle();
  const defaultScreenTitle: string = useGetDefaultScreenTitle()(new Date());
  const { byId, allIds } = useContentsStore(s => s.contents);

  const isJustCreatedProject: boolean =
    screens.length === 1 &&
    isDefaultScreenTitle(screens[0].title) &&
    allIds
      .filter(id => byId[id].screenId === screens[0].id)
      .map(id => byId[id])
      .every(({ type }) => !T.MAP_TAB_CONTENTS.includes(type));

  const [selectedScreen, setSelectedScreen]: UseState<T.Screen> = useState(
    isJustCreatedProject
      ? screens[0]
      : {
          id: TEMP_SCREEN_ID,
          title: defaultScreenTitle,
          contentIds: [],
          appearAt: new Date(),
          createdAt: new Date(),
          updatedAt: new Date(),
        }
  );

  const [attachmentType, setPopupType]: UseState<
    Exclude<
      T.AttachmentType,
      | T.AttachmentType.SOURCE
      | T.AttachmentType.THREE_SIXTY_STITCHED
      | T.AttachmentType.THREE_SIXTY_VSLAM
      | T.AttachmentType.THREE_SIXTY_VIDEO
      | T.AttachmentType.VIEWPOINT
    >
  > = useState<
    Exclude<
      T.AttachmentType,
      | T.AttachmentType.SOURCE
      | T.AttachmentType.THREE_SIXTY_STITCHED
      | T.AttachmentType.THREE_SIXTY_VSLAM
      | T.AttachmentType.THREE_SIXTY_VIDEO
      | T.AttachmentType.VIEWPOINT
    >
  >(popupAttachmentTypeMap[rawPopupType]);
  const [title, setTitle]: UseState<string> = useState<string>('');
  const [files, setFiles]: UseState<File[]> = useState<File[]>([]);
  const [logFiles, setLogFiles]: UseState<File[]> = useState<File[]>([]);
  const [coordinateSystem, setCoordinateSystem]: UseState<T.CoordinateSystem | undefined> =
    useState<T.CoordinateSystem>();
  const [errors, setErrors]: UseState<Errors> = useState<Errors>(INITIAL_ERRORS);
  const [isValidated, setIsValidated]: UseState<boolean> = useState<boolean>(false);
  const [isUploading, setIsUploading]: UseState<boolean> = useState(false);
  const [isConfirming, setIsConfirming]: UseState<boolean> = useState(false);
  const [isMissingLogFile, setIsMissingLogFile]: UseState<boolean> = useState(false);
  const hasMoreThanOneError: boolean = Object.values(errors).some(hasError => hasError.isError);
  const hasNoError: boolean = Object.values(errors).every(hasError => !hasError.isError);
  const fileName: string = files.length ? files[0].name : '';
  const isBlueprint: boolean =
    attachmentType === BLUEPRINT_PDF ||
    attachmentType === BLUEPRINT_DXF ||
    attachmentType === BLUEPRINT_DWG;

  const isCesiumEngine =
    rawPopupType === T.ContentPagePopupType.BIM_UPLOAD &&
    currentMeshEngine !== T.MeshEngine.THREEJS;

  useEffect(() => {
    if (isBlueprint) {
      setPopupType(
        fileName
          ? (() => {
              const fileExtension: string = fileName
                .substring(fileName.lastIndexOf('.') + 1)
                .toLowerCase();

              if (BLUEPRINT_DXF.includes(fileExtension)) {
                return BLUEPRINT_DXF;
              }
              if (BLUEPRINT_DWG.includes(fileExtension)) {
                return BLUEPRINT_DWG;
              }

              return BLUEPRINT_PDF;
            })()
          : BLUEPRINT_PDF
      );
    }
    if (photoType === 'drone') {
      setPopupType(T.AttachmentType.DRONE_PHOTO);
    }
  }, [fileName]);

  useEffect(() => {
    const hasErrors: Errors = {
      [TITLE]: { isError: title.trim().length < 1 },
      [ATTACH]: { isError: errors[ATTACH].isError },
      [SCREEN]: { isError: errors[SCREEN].isError },
      [BIM_ATTACH]: { isError: errors[BIM_ATTACH].isError },
      [LOG]: { isError: errors[LOG].isError },
    };

    Object.keys(hasErrors).forEach((ui: keyof Errors) => {
      if (!isInUI(attachmentType, ui)) {
        hasErrors[ui].isError = false;
      }
    });
    setErrors(hasErrors);

    if (hasNoError) {
      setIsValidated(false);
    }
  }, [title.length]);

  useEffect(() => {
    if (files.length > 0) {
      checkSignatureFromFiles(files, attachmentType, hasSignatureError => {
        if (attachmentType === T.AttachmentType.THREE_SIXTY) {
          checkIsImage360(files, has360Error => {
            setErrors(prevState => ({
              ...prevState,
              [ATTACH]: {
                isError: hasSignatureError || has360Error,
                errorText: l10n(Text.invalid360ErrorText),
              },
              [BIM_ATTACH]: {
                isError: hasSignatureError || has360Error,
              },
            }));
          });
        } else if (attachmentType === T.AttachmentType.FLIGHT_VIDEO) {
          setErrors(prevState => ({
            ...prevState,
            [ATTACH]: {
              isError: hasSignatureError,
            },
            [LOG]: {
              isError: hasSignatureError || logFiles.length === 0,
            },
            [BIM_ATTACH]: {
              isError: hasSignatureError,
            },
          }));
        } else {
          setErrors(prevState => ({
            ...prevState,
            [ATTACH]: {
              isError: hasSignatureError,
            },
            [BIM_ATTACH]: {
              isError: hasSignatureError,
            },
          }));
        }
      });
    } else {
      setErrors(prevState => ({
        ...prevState,
        [ATTACH]: {
          isError: true,
        },
        [BIM_ATTACH]: {
          isError: true,
        },
      }));
    }
  }, [fileName, files.length, logFiles]);

  const handleCloseClick: () => void = useCallback(() => {
    dispatch(CloseContentPagePopup());
    dispatch(SetUploadedPhotoCount({ count: 0 }));
    dispatch(ChangeInUploadIFC({ isInUploadIFC: false }));
  }, []);
  const handleBackClick: () => void = useCallback(() => {
    dispatch(SetUploadedPhotoCount({ count: 0 }));
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.UPLOAD }));
  }, []);
  const handlePreviousClick: () => void = useCallback(() => {
    setIsConfirming(false);
  }, []);
  const handleSubmitClick: () => Promise<void> = async () => {
    if (hasMoreThanOneError) {
      setIsValidated(true);

      return;
    }

    if (attachmentType === T.AttachmentType.FLIGHT_VIDEO) {
      if (logFiles.length === 0) {
        setIsMissingLogFile(true);
        return;
      } else {
        setIsMissingLogFile(false);
      }
      if (files.length !== 0) {
        const isValidVideoFile = files[0].type === 'video/mp4';
        if (!isValidVideoFile) {
          return;
        }
      }
    }
    if (
      attachmentType === T.AttachmentType.PHOTO ||
      attachmentType === T.AttachmentType.DRONE_PHOTO ||
      attachmentType === T.AttachmentType.FLIGHT_PLAN ||
      attachmentType === T.AttachmentType.FLIGHT_VIDEO ||
      attachmentType === T.AttachmentType.THREE_SIXTY ||
      attachmentType === T.AttachmentType.THREE_SIXTY_SOURCE
    ) {
      setIsUploading(true);
    }

    if (
      attachmentType === T.AttachmentType.BLUEPRINT_DXF ||
      attachmentType === T.AttachmentType.BLUEPRINT_DWG ||
      attachmentType === T.AttachmentType.BIM
    ) {
      setIsConfirming(true);

      return;
    }

    handleUpload();
  };

  const handleUpload: () => void = () => {
    const tempFiles = [...files];
    if (attachmentType === T.AttachmentType.FLIGHT_VIDEO && logFiles.length !== 0) {
      tempFiles.push(logFiles[0]);
    }
    uploadContent({
      attachmentType,
      title,
      files: tempFiles,
      coordinateSystem,
      screen: selectedScreen,
      photoType,
    });
  };

  const handleTitleChange: (str: string) => void = str => {
    setTitle(str);
  };
  const handleFilesChange: (files: File[]) => void = changedFiles => {
    setFiles(changedFiles);
  };
  const handleLogFileChange: (files: File[]) => void = changedFiles => {
    setLogFiles(changedFiles);
  };
  const handleFileLoaded: (loaded: boolean) => void = loaded => {
    setErrors(prevState => ({
      ...prevState,
      [BIM_ATTACH]: {
        isError: !loaded,
      },
    }));
  };

  const handleScreenChange: (screen: T.Screen) => void = screen => {
    setSelectedScreen(() => screen);
  };

  const handleScreenError: (hasError: boolean) => void = useCallback(hasError => {
    setErrors(prevState => ({
      ...prevState,
      [SCREEN]: { isError: hasError },
    }));
  }, []);

  const overwritingWarningMsg: ReactNode = getContentOverwriteCondition({
    contents,
    screenId: selectedScreen.id,
    attachmentType,
  }) ? (
    <OverwritingWarningMsg>{l10n(Text.overwritingWarningMsg)}</OverwritingWarningMsg>
  ) : null;

  const uiComponentMap: { [K in UI]: ReactNode } = {
    [TITLE]: (
      <TitleInput
        title={title}
        hasError={isValidated && errors[TITLE].isError}
        setTitle={handleTitleChange}
      />
    ),
    [BIM_ATTACH]: (
      <BIMFileInput
        hasError={isValidated && errors[BIM_ATTACH].isError}
        hasMultipleFiles={[BIM].includes(attachmentType)}
        extensions={fileExtensions[attachmentType]}
        files={files}
        coordinateSystem={coordinateSystem}
        setFiles={handleFilesChange}
        setFilesLoaded={handleFileLoaded}
      />
    ),
    [ATTACH]: (
      <FileInput
        hasError={isValidated && errors[ATTACH].isError}
        errorText={errors[ATTACH].errorText}
        hasMultipleFiles={[PHOTO, DRONE_PHOTO, THREE_SIXTY, THREE_SIXTY_SOURCE].includes(
          attachmentType
        )}
        extensions={fileExtensions[attachmentType]}
        files={files}
        setFiles={handleFilesChange}
      />
    ),
    [VIDEO]: (
      <FileInput
        hasError={(isValidated && errors[ATTACH].isError) || isMissingLogFile}
        extensions={fileExtensions[attachmentType]}
        files={files}
        setFiles={handleFilesChange}
      />
    ),
    [LOG]: (
      <FileLogInput
        hasError={(isValidated && errors[ATTACH].isError) || isMissingLogFile}
        extensions={fileExtensions[FLIGHT_LOG]}
        files={logFiles}
        setFiles={handleLogFileChange}
      />
    ),
    [COORDINATE]: (
      <CoordinateSystemDropdown
        coordinateSystem={coordinateSystem}
        setCoordinateSystem={setCoordinateSystem}
      />
    ),
    [SCREEN]: (
      <>
        <TextLabel>{l10n(Text.datasetDateAndName)}</TextLabel>
        <DateScreenInput
          buttonType={T.DateScreenButton.MAP_CONTENTS_UPLOAD}
          screen={selectedScreen}
          placement={T.ModalPlacement.MIDDLE_RIGHT}
          hasError={errors[SCREEN].isError}
          onScreenChange={handleScreenChange}
          onError={handleScreenError}
        />
        {overwritingWarningMsg}
      </>
    ),
  };

  const popupTypeUI: ReactNode = attachmentTypeUIMap[attachmentType].map((ui, index) => (
    <Wrapper key={index}>{uiComponentMap[ui]}</Wrapper>
  ));

  const submitButtonText: string = (() => {
    if (isUploading) {
      return l10n(Text.photoUploadingText);
    }
    if (
      attachmentType === T.AttachmentType.BLUEPRINT_DXF ||
      attachmentType === T.AttachmentType.BLUEPRINT_DWG ||
      attachmentType === T.AttachmentType.BIM
    ) {
      return l10n(Text.next);
    }

    return l10n(Text.upload);
  })();

  const photoUploadProgressText: string | null = isUploading
    ? `${uploadedPhotoCount}/${files.length}`
    : null;

  if (isConfirming && attachmentType === T.AttachmentType.BIM) {
    return (
      <IFCLocationPreview
        zIndex={zIndex}
        title={title}
        files={files[0]}
        onPreviousClick={handlePreviousClick}
        onCloseClick={handleCloseClick}
      />
    );
  } else if (isConfirming) {
    return (
      <CadUploadConfirmPopup
        zIndex={zIndex}
        onPreviousClick={handlePreviousClick}
        onSubmitClick={handleUpload}
        onCloseClick={handleCloseClick}
      />
    );
  }

  return (
    <Popup
      zIndex={zIndex}
      alpha={POPUP_ALPHA}
      hasBlur={true}
      title={changeWordsOrderOnLang(l10n(Text.content[attachmentType]), l10n(Text.upload), lang)}
      onPreviousClick={
        hideBackButton || (isEarthworkDashboard && createNewEarthwork) ? undefined : handleBackClick
      }
      onCloseClick={handleCloseClick}
      isUploading={isUploading}
    >
      <Root>
        {/* {isUploading ? <PreventClickOverlay /> : null} */}
        {isCesiumEngine ? (
          <WarningWrapper>
            <WarningText color="#6E6D6D">{l10n(Text.warningText)}</WarningText>
            <WarningContainer>
              <WarningIconSvg />
              <WarningText color="#4D4C4C">{l10n(Text.warningDescription)}</WarningText>
            </WarningContainer>
          </WarningWrapper>
        ) : (
          <>
            {popupTypeUI}
            <ButtonsWrapper>
              <ConfirmButton
                isDisabled={hasMoreThanOneError || isUploading}
                onClick={handleSubmitClick}
              >
                {submitButtonText}
                {photoUploadProgressText}
              </ConfirmButton>
            </ButtonsWrapper>
          </>
        )}
      </Root>
    </Popup>
  );
});
