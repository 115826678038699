/* eslint-disable max-lines */
import * as Sentry from '@sentry/browser';
import CheckSvg from '^/assets/icons/content-sidebar/check.svg';
import { zoomToCoordinate } from '^/components/cesium/cesium-util';
import { CesiumContext, CesiumContextProps } from '^/components/cesium/CesiumContext';
import { usePotreeStore } from '^/components/potree/PotreeStore';
import { CAMERA_ZOOM_MEASUREMENT_CENTER } from '^/components/three/constants';
import { useThreeStore } from '^/components/three/ThreeStore';
import {
  findAverage,
  findPointBetween,
  geoPointToPotreeCloud,
  moveCameraToViewObject,
} from '^/components/three/utils';
import {
  isESSCustomModel,
  useAuthHeader,
  useDeleteContent,
  useIsRoleX,
  useL10n,
  useLastSelectedScreen,
  useProjectCoordinateSystem,
} from '^/hooks';
import { useESSContents } from '^/hooks/useESSContents';
import { AuthHeader } from '^/store/duck/API';
import { PatchContent, PatchGCPContent } from '^/store/duck/Contents';
import { ChangeSelectedGroupId } from '^/store/duck/Groups';
import {
  ChangeEditingContent,
  ChangeIn3D,
  ChangeIn3DPointCloud,
  ChangeTwoDDisplayCenter,
  OpenContentPagePopup,
} from '^/store/duck/Pages';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useUtilsStore } from '^/store/utilsStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useGroupStore } from '^/store/zustand/groups/groupStore';
import * as T from '^/types';
import { getCenterPointByContent, getCenterPointByContentInMesh } from '^/utilities/content-util';
import { isErrorIgnorable } from '^/utilities/http-response';
import { isRoleAdmin, isRolePilot } from '^/utilities/role-permission-check';
import { getAllChildren, getSingleContentId } from '^/utilities/state-util';
import { updateContentSelectedAtInServer } from '^/utilities/updateContentSelectedAtInServer';
import axios from 'axios';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { MathUtils } from 'three';
import { useShallow } from 'zustand/react/shallow';
import { ContextMenuList, ContextMenuOption } from '../ContextMenuList';
import Text from './Text';

export function SidebarContextMenu() {
  const [l10n] = useL10n();
  const dispatch = useDispatch();

  const { viewer }: CesiumContextProps = useContext(CesiumContext);

  const authHeader: AuthHeader | undefined = useAuthHeader();
  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);

  const contentType = useUtilsStore(s => s.contextMenuContentType);
  const contentId = useUtilsStore(s => s.contextMenuContentId);
  const setSidebarContextMenuOpen = useUtilsStore(s => s.setIsSidebarContextMenuOpen);
  const setEditingTitleContentId = useUtilsStore(s => s.setEditingTitleContentId);

  const byId = useContentsStore(s => s.contents.byId);
  const updateContentsSelectedAtInStore = useContentsStore(s => s.updateContentsSelectedAtInStore);

  const idsByGroup = useGroupStore(s => s.tree.idsByGroup);

  const { updateESSContent } = useESSContents();

  const currentScreen = useLastSelectedScreen();
  const isPilot: boolean = useIsRoleX(isRolePilot);
  const isAdmin: boolean = useIsRoleX(isRoleAdmin);

  const {
    essContents,
    essContentGroupTree,
    selectedESSGroupIdByTab,
    editingESSContentId,
    updateSelectedAtInESSContents,
    setSelectedESSGroupIdByTab,
    setDeletingESSContentId,
    setEditingESSContentId,
  } = useESSContentsStore(
    useShallow(s => ({
      essContents: s.essContents,
      essContentGroupTree: s.essContentGroupTree,
      selectedESSGroupIdByTab: s.selectedESSGroupIdByTab,
      editingESSContentId: s.editingESSContentId,
      setDeletingESSContentId: s.setDeletingESSContentId,
      updateSelectedAtInESSContents: s.updateSelectedAtInESSContents,
      setSelectedESSGroupIdByTab: s.setSelectedESSGroupIdByTab,
      setEditingESSContentId: s.setEditingESSContentId,
    }))
  );

  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);

  const isESS = sidebarTab === T.ContentPageTabType.ESS;
  const isOverlayTab = sidebarTab === T.ContentPageTabType.OVERLAY;

  const editingMainContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);
  const editingContentId = isESS ? editingESSContentId : editingMainContentId;
  const printingContentId = useSelector((s: T.State) => s.Pages.Contents.printingContentId);
  const Pages = useSelector((s: T.State) => s.Pages);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);

  const content = isESS ? essContents[contentId ?? NaN] : byId[contentId ?? NaN];

  const isPinned = !content?.screenId;

  const selectedGroupIdByTab = useGroupStore(s => s.selectedGroupIdByTab);
  const selectedGroupId = isESS
    ? selectedESSGroupIdByTab[T.ContentPageTabType.ESS]
    : selectedGroupIdByTab[sidebarTab];

  const viewer3Js = useThreeStore(s => s.viewer);
  const cameraControls = useThreeStore(s => s.cameraControls);
  const runtime = useThreeStore(s => s.runtime);

  const isIn3D: T.ContentsPageState['in3D'] = Pages.Contents.in3D;
  const isIn3DPointCloud: T.ContentsPageState['in3DPointCloud'] = Pages.Contents.in3DPointCloud;
  const currentPointCloudEngine: T.ContentsPageState['currentPointCloudEngine'] =
    Pages.Contents.currentPointCloudEngine;
  const currentMeshEngine: T.ContentsPageState['currentMeshEngine'] =
    Pages.Contents.currentMeshEngine;
  const isInPotree = isIn3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.POTREE;

  const threeDMeshId: T.MapContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.THREE_D_MESH
  );
  const isInCesium =
    isIn3D || (isIn3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.CESIUM);

  const isIn3DMesh = isIn3D && currentMeshEngine === T.MeshEngine.THREEJS;
  const cameraP = usePotreeStore(s => s.camera);
  const sceneP = usePotreeStore(s => s.scene);
  const PCO = usePotreeStore(s => s.PCO);
  const deleteContent = useDeleteContent();

  if (!contentType || !contentId) {
    return null;
  }

  const handleShowHideLayer = () => {
    if (isESS) {
      void updateESSContent({
        content: {
          id: contentId,
          config: {
            selectedAt: content?.config?.selectedAt ? undefined : new Date(),
          },
        },
        skipDBUpdate: true,
      });
    }
    dispatch(
      PatchContent({
        content: {
          ...content,
          config: {
            ...content.config,
            selectedAt: content?.config?.selectedAt ? undefined : new Date(),
          },
        },
      })
    );
    setSidebarContextMenuOpen(false);
  };

  const handleShowHideDetails = () => {
    const isGoingToBeEditing: boolean = contentId !== editingContentId;
    if (isESS) {
      setEditingESSContentId(isGoingToBeEditing ? contentId : undefined);
    } else {
      dispatch(ChangeEditingContent({ contentId: isGoingToBeEditing ? contentId : undefined }));
    }
    setSidebarContextMenuOpen(false);
  };

  const handleShowHideFolder = () => {
    try {
      const childrenIds = isESS
        ? essContentGroupTree[content.id] || []
        : idsByGroup[content.id] || [];

      const children = getAllChildren(
        childrenIds,
        isESS ? essContentGroupTree : idsByGroup,
        isESS ? essContents : byId
      );
      const selectedChildren = children.filter((child: T.Content) => child.config?.selectedAt);
      const noChildrenSelected = selectedChildren.length === 0;
      const hasAllChildrenSelected =
        !noChildrenSelected && selectedChildren.length === children.length;
      const isChecked =
        (children.length === 0 && Boolean(content?.config?.selectedAt)) || hasAllChildrenSelected;

      if (isESS && contentId) {
        const essGroup = essContents[contentId];
        if (essGroup.groupId) {
          setSelectedESSGroupIdByTab(essGroup.groupId);
          void updateESSContent({
            content: { id: essGroup.groupId, info: { isOpened: true } },
            skipDBUpdate: true,
          });
        }
      } else {
        dispatch(ChangeSelectedGroupId({ selectedGroupId: contentId, tab: sidebarTab }));
      }

      const selectedAt: Date | undefined = isChecked ? undefined : new Date();

      // Note: Technically an empty group doesn't need to have
      // the ability to be toggled, but it's done anyway to make it consistent
      // with the previous behavior.
      switch (content.category) {
        case T.ContentCategory.ESS: {
          void updateESSContent({
            content: { id: contentId, config: { selectedAt } },
            skipDBUpdate: true,
          });
          break;
        }
        default:
          dispatch(PatchContent({ content: { id: contentId, config: { selectedAt } } }));
      }
      const childrenContents = getAllChildren(
        childrenIds,
        isESS ? essContentGroupTree : idsByGroup,
        isESS ? essContents : byId
      );
      if (childrenContents.length === 0) {
        return;
      }
      const outdatedContents: T.Content[] = childrenContents.filter(c =>
        isChecked ? true : !c?.config?.selectedAt
      );
      void updateContentSelectedAtInServer(outdatedContents, authHeader, projectId, selectedAt);

      if (isESS) {
        const ids = outdatedContents.map(c => c.id);
        updateSelectedAtInESSContents(ids, selectedAt);
      } else {
        const outdatedContentsIds = outdatedContents.map(c => c.id);
        updateContentsSelectedAtInStore(outdatedContentsIds, selectedAt);
      }
    } catch (event) {
      // eslint-disable-next-line no-console
      console.error(event);
      if (!axios.isAxiosError(event) || !isErrorIgnorable(event.response?.status)) {
        Sentry.captureException(event);
      }
    } finally {
      setSidebarContextMenuOpen(false);
    }
  };

  const handleFoldUnfoldGroup = () => {
    if (isESS) {
      void updateESSContent({
        content: {
          id: contentId,
          info: { isOpened: !(content as T.GroupContent)?.info?.isOpened },
        },
        skipDBUpdate: true,
      });
    } else {
      dispatch(
        PatchContent({
          content: {
            id: contentId,
            info: { isOpened: !(content as T.GroupContent)?.info?.isOpened },
          },
        })
      );
    }
    setSidebarContextMenuOpen(false);
  };

  const handlePinUnpinContent = () => {
    const newScreenId: number | undefined = content.screenId ? (null as any) : currentScreen?.id;
    dispatch(
      PatchContent({
        content: { id: content.id, screenId: newScreenId },
      })
    );
    setSidebarContextMenuOpen(false);
  };

  const handleDeleteGroup = () => {
    setSidebarContextMenuOpen(false);
    if (printingContentId) {
      return;
    }
    // only the user who has a role as pilot or admin of project can delete the group
    if (isPilot || isAdmin) {
      if (isESS) {
        setDeletingESSContentId(content.id);
      }
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.DELETE_GROUP }));

      return;
    }
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
  };

  const handleContentCenterClick = async () => {
    setSidebarContextMenuOpen(false);

    const actions: Action[] = [];

    if (!content.config?.selectedAt && content.type !== T.ContentType.GCP_GROUP) {
      switch (content.category) {
        case T.ContentCategory.ESS: {
          void updateESSContent({
            content: {
              id: contentId,
              config: {
                selectedAt: new Date(),
              },
            },
            skipDBUpdate: true,
          });
          break;
        }
        default:
          actions.push(
            PatchContent({
              content: {
                id: contentId,
                config: {
                  selectedAt: new Date(),
                },
              },
            })
          );
      }
    }

    const centerPoint: T.GeoPoint | undefined = getCenterPointByContent(
      content,
      dispatch,
      projectProjection
    );

    if (content.type === T.ContentType.GCP_GROUP) {
      actions.push(
        PatchGCPContent({
          content: {
            ...content,
            config: {
              ...content.config,
              selectedAt: new Date(),
            },
          },
        })
      );
    }

    if (content.type === T.ContentType.BIM) {
      if (threeDMeshId) {
        actions.push(
          ChangeIn3D({ in3D: true }),
          ChangeIn3DPointCloud({ in3DPointCloud: false }),
          PatchContent({
            content: {
              id: threeDMeshId,
              config: {
                selectedAt: new Date(),
              },
            },
          })
        );
      }

      await Promise.all(actions.map(dispatch)).then(() => moveCameraToViewObject(contentId));
      return;
    }

    if (centerPoint !== undefined) {
      if (isInCesium && viewer !== undefined) {
        if (isESSCustomModel(content)) {
          zoomToCoordinate(viewer, centerPoint, content.info.miscMeta.dimensions[0]);
        } else {
          zoomToCoordinate(viewer, centerPoint);
        }
      } else if (isInPotree) {
        if (cameraP && PCO && T.PointCloudMeasurementContentTypes.includes(content.type)) {
          const points = (content as T.ThreeDLengthContent).info.locations.map(
            location => geoPointToPotreeCloud(location, projectProjection, PCO).point
          );
          const measure = sceneP.measurements.find((v: any) => v.contentId === content.id);

          const center = findAverage(points);
          const newCameraPos = findPointBetween(
            center,
            cameraP.position,
            CAMERA_ZOOM_MEASUREMENT_CENTER
          );
          if (!newCameraPos) {
            return;
          }
          sceneP.view.lookAt(center);
          sceneP.view.setView(newCameraPos, measure);
        }
      } else {
        actions.push(ChangeTwoDDisplayCenter({ twoDDisplayCenter: centerPoint }));
      }
    }

    if (isIn3DMesh && runtime && viewer3Js) {
      const center = getCenterPointByContentInMesh(content, viewer3Js, runtime, projectProjection);
      if (
        (content.type === T.ContentType.BLUEPRINT_DXF ||
          content.type === T.ContentType.BLUEPRINT_DWG ||
          content.type === T.ContentType.ESS_MODEL) &&
        center
      ) {
        await cameraControls?.moveTo(center.x, center.y, center.z);
        await cameraControls?.rotateTo(0, 0);
        await cameraControls?.setFocalOffset(0, 0, 0);
        if (content.type === T.ContentType.ESS_MODEL) {
          await cameraControls?.dollyTo(20, true);
        } else {
          await cameraControls?.dollyTo(500, true);
        }
        await cameraControls?.rotate(45 * MathUtils.DEG2RAD, 45 * MathUtils.DEG2RAD, true);
      } else {
        moveCameraToViewObject(contentId);
      }
    }

    actions.forEach(dispatch);
  };

  const handleDeleteContent = () => {
    setSidebarContextMenuOpen(false);
    deleteContent(
      contentId,
      content.type,
      content.category === T.ContentCategory.ESS,
      content.category === T.ContentCategory.FLIGHT_PLAN
    );
  };

  const handleRename = () => {
    setSidebarContextMenuOpen(false);
    setEditingTitleContentId(contentId);
  };

  const contentTypeOptions: ContextMenuOption[] = [
    {
      id: '1',
      text: l10n(Text.showHideLayer),
      name: 'show-hide-layer',
      preIcon: <></>,
      onClick: handleShowHideLayer,
      shouldShowDividerAfter: true,
    },
    {
      id: '2',
      text: l10n(Text.rename),
      name: 'content-rename',
      preIcon: <></>,
      onClick: handleRename,
      disabled: content?.category === T.ContentCategory.MAP,
    },
    {
      id: '3',
      text: l10n(Text.delete),
      name: 'content-delete',
      preIcon: <></>,
      onClick: handleDeleteContent,
      shouldShowDividerAfter: true,
    },
    {
      id: '4',
      text: l10n(Text.centerOnMap),
      name: 'center-on-map',
      preIcon: <></>,
      onClick: handleContentCenterClick,
    },
    {
      id: '5',
      text: l10n(Text.showHideDetails),
      name: 'show-hide-details',
      preIcon: <></>,
      onClick: handleShowHideDetails,
    },
  ];
  const groupOptions: ContextMenuOption[] = [
    {
      id: '1',
      text: l10n(Text.showHideFolder),
      name: 'show-hide-folder',
      preIcon: <></>,
      onClick: handleShowHideFolder,
      shouldShowDividerAfter: true,
    },
    {
      id: '2',
      text: l10n(Text.rename),
      name: 'group-rename',
      preIcon: <></>,
      onClick: handleRename,
    },
    {
      id: '3',
      text: l10n(Text.delete),
      name: 'group-delete',
      preIcon: <></>,
      onClick: handleDeleteGroup,
      shouldShowDividerAfter: true,
      disabled: content?.id !== selectedGroupId || !content.config?.selectedAt,
    },
    {
      id: '4',
      text: l10n(Text.foldUnfoldFolder),
      name: 'fold-unfold-folder',
      preIcon: <></>,
      onClick: handleFoldUnfoldGroup,
    },
    {
      id: '5',
      text: l10n(Text.showForAllDates),
      name: 'show-for-all-dates',
      preIcon: isPinned ? <CheckSvg /> : <></>,
      onClick: handlePinUnpinContent,
      hide: Boolean(content?.groupId) || isESS || isOverlayTab,
    },
  ];
  const options = contentType === 'content' ? contentTypeOptions : groupOptions;
  return <ContextMenuList options={options} properties={{ width: 224 }} />;
}
