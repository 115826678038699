/* eslint-disable max-lines */
import { createStore, useStore } from 'zustand';
import _ from 'lodash-es';

import * as T from '^/types';
import {
  defaultMeasurementFilters,
  defaultOverlayFilters,
  defaultTerrainFilters,
  defaultThreeFilters,
  initialContentState,
} from './constant';
import { defaultESSFilters } from '../../essContentsStore';
import { devtools } from 'zustand/middleware';

interface ContentStateActions {
  // For setting intialcontents state
  setState(key: keyof T.ContentsState, value: T.ContentsState[keyof T.ContentsState]): void;

  changeContents(contents: T.Content[]): void; // reducer: ChangeContents
  changeUploadContent(content: T.UploadContent): void; //ChangeUploadContent
  deleteUploadContent(contentId?: T.Content['id']): void;
  addContent(content: T.Content): void;
  removeContent(contentId: T.Content['id']): void;
  finishUploadContent(contentId?: T.Content['id'], error?: T.HTTPError): void;
  cancelUploadLas(contentId?: T.Content['id']): void;
  updateMarkerAttachmentsCount(
    contentId: T.Content['id'],
    count: T.MarkerContent['attachmentsCount']
  ): void;
  updateContentConfig(contentId: T.Content['id'], config: T.Content['config']): void;
  updateContentOpacity(contentId: T.Content['id'], info: T.Content['info']): void;
  updateContentsSelectedAtInStore(ids: Array<T.Content['id']>, selectedAt: Date | undefined): void;
  updateContentPinSettings(
    contentId: T.Content['id'],
    screenId: T.Screen['id'],
    pinEventAt: number
  ): void;
  updateLengthAreaVolumeLocations(
    contentId: T.Content['id'],
    locations: T.LengthAreaVolumeContent['info']['locations']
  ): void;
  setMarkerAndIssuePinSelected(
    contentId: T.Content['id'],
    move: T.IssueContent['info']['move']
  ): void;

  resetContentsAPIStatusInStore(): void;
  changeLasDownSamplingStatus(
    screenId: T.Screen['id'],
    contentId: T.Content['id'],
    status: T.LasDownSamplingStatus
  ): void;
  changeContentDownloadables(
    screenId: T.Screen['id'],
    downloadables: Partial<Record<T.ResourceType, boolean>>
  ): void;
  updateContents(contents: T.Content[]): void;
  applyFilter(
    filterType: T.ContentsListType,
    filterText: string,
    filterContents: T.ContentType[]
  ): void;
  clearFilter(filterType: T.ContentsListType, isInPotree: boolean): void;
  addAssignee(issueId: T.IssueContent['id'], user: T.IssueAssignee): void;
  removeAssignee(issueId: T.IssueContent['id'], userId: T.IssueAssignee['id']): void;
  //   appendIssueAttachment(issueId: T.IssueContent['id'], attachment: T.IssueAttachment): void;
  //   removeIssueAttachment(issueId: T.IssueContent['id'], attachmentId: T.IssueAttachment['id']): void;
  updateDSMHeightsAndColors(
    dsmColorsAndHeights: T.DSMHeightAndColor[],
    minHeight: number,
    maxHeight: number
  ): void;
  updateDTMHeightsAndColors(
    dtmColorsAndHeights: T.DSMHeightAndColor[],
    minHeight: number,
    maxHeight: number
  ): void;
}

type ContentStoreInterface = T.ContentsState & ContentStateActions;

export const contentsStore = createStore<ContentStoreInterface>()(
  devtools(set => ({
    ...initialContentState,
    //   setInitialContentsStatus: getInitialContentsStatus => set({ getInitialContentsStatus }),
    setState: <K extends keyof T.ContentsState>(key: K, value: T.ContentsState[K]) =>
      set({ [key]: value } as Pick<T.ContentsState, K>),

    reset: () => set(initialContentState),

    changeContents: contents =>
      set(() => {
        const allIds = contents.map(({ id }) => id);
        return {
          contents: {
            byId: _.zipObject(allIds, contents),
            allIds,
          },
        };
      }),

    changeUploadContent: content =>
      set(state => ({
        uploadContents: {
          ...state.uploadContents,
          [content.id]: content,
        },
      })),

    deleteUploadContent: contentId =>
      set(state => ({
        uploadContents: contentId ? _.omit(state.uploadContents, contentId) : {},
      })),

    addContent: content => {
      set(state => ({
        contents: {
          byId: {
            ...state.contents.byId,
            [content.id]: content,
          },
          allIds: state.contents.allIds.includes(content.id)
            ? state.contents.allIds
            : [...state.contents.allIds, content.id],
        },
      }));
    },

    removeContent: contentId =>
      set(state => ({
        contents: {
          byId: _.omit(state.contents.byId, contentId),
          allIds: _.without(state.contents.allIds, contentId),
        },
      })),

    finishUploadContent: (contentId, error) =>
      set(state => ({
        uploadContents: contentId
          ? {
              ...state.uploadContents,
              [contentId]: {
                ...state.uploadContents[contentId],
                error,
                status: error ? T.APIStatus.ERROR : T.APIStatus.SUCCESS,
              },
            }
          : { ...state.uploadContents },
      })),

    cancelUploadLas: contentId =>
      set(state => ({
        uploadContents: contentId
          ? {
              ...state.uploadContents,
              [contentId]: {
                ...state.uploadContents[contentId],
                status: T.APIStatus.IDLE,
              },
            }
          : {},
      })),

    updateMarkerAttachmentsCount: (contentId, count) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [contentId]: {
              ...state.contents.byId[contentId],
              attachmentsCount: count,
            },
          },
        },
      })),

    updateContentConfig: (contentId, config) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [contentId]: {
              ...state.contents.byId[contentId],
              config,
            } as T.Content,
          },
        },
      })),

    updateContentOpacity: (contentId, info) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [contentId]: {
              ...state.contents.byId[contentId],
              info,
            } as T.Content,
          },
        },
      })),

    updateContentsSelectedAtInStore: (ids, selectedAt) =>
      set(state => {
        const newById = ids.reduce((acc, id) => {
          const content = state.contents.byId[id];
          if (content) {
            acc[id] = {
              ...content,
              config: { ...content.config, selectedAt },
            } as T.Content;
          }
          return acc;
        }, {} as { [id: T.Content['id']]: T.Content });

        return {
          contents: {
            ...state.contents,
            byId: {
              ...state.contents.byId,
              ...newById,
            },
          },
        };
      }),

    updateContentPinSettings: (contentId, screenId, pinEventAt) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [contentId]: {
              ...state.contents.byId[contentId],
              screenId,
              pinEventAt,
            } as T.PinnableContent,
          },
        },
      })),

    updateLengthAreaVolumeLocations: (contentId, locations) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [contentId]: {
              ...state.contents.byId[contentId],
              info: {
                ...state.contents.byId[contentId].info,
                locations,
              },
            } as T.LengthAreaVolumeContent,
          },
        },
      })),

    setMarkerAndIssuePinSelected: (contentId, move) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [contentId]: {
              ...state.contents.byId[contentId],
              info: {
                ...state.contents.byId[contentId].info,
                move,
              },
            } as T.Content,
          },
        },
      })),

    resetContentsAPIStatusInStore: () =>
      set(() => ({
        getContentsStatus: T.APIStatus.IDLE,
        getInitialContentsStatus: T.APIStatus.IDLE,
        postContentStatus: T.APIStatus.IDLE,
        deleteContentStatus: T.APIStatus.IDLE,
        patchContentStatus: T.APIStatus.IDLE,
        dsm2tilesStatus: T.APIStatus.IDLE,
        printMapStatus: T.APIStatus.IDLE,
        dxf2RasterStatus: T.APIStatus.IDLE,
      })),

    changeLasDownSamplingStatus: (screenId, contentId, status) =>
      set(state => ({
        lasDownSamplingStatus: {
          ...state.lasDownSamplingStatus,
          [screenId]: { contentId, status },
        },
      })),

    changeContentDownloadables: (screenId, downloadables) =>
      set(state => ({
        contentDownloadables: {
          ...state.contentDownloadables,
          [screenId]: downloadables,
        },
      })),

    updateContents: contents =>
      set(state => ({
        contents: {
          byId: contents.reduce(
            (acc, content) => {
              acc[content.id] = content;
              return acc;
            },
            { ...state.contents.byId }
          ),
          allIds: state.contents.allIds,
        },
      })),

    applyFilter: (filterType, filterText, filterContents) =>
      set(state => ({
        contentFilters: {
          ...state.contentFilters,
          [filterType]: { filterText, filterContents },
        },
      })),

    clearFilter: (filterType, isInPotree) =>
      set(state => {
        let defaultFilterContents: T.ContentType[] = [];
        switch (filterType) {
          case T.ContentsListType.OPEN_MEASUREMENT:
          case T.ContentsListType.PERSONAL_MEASUREMENT:
            defaultFilterContents = isInPotree ? defaultThreeFilters : defaultMeasurementFilters;
            break;
          case T.ContentsListType.PERSONAL_OVERLAY:
          case T.ContentsListType.OPEN_OVERLAY:
            defaultFilterContents = defaultOverlayFilters;
            break;
          case T.ContentsListType.PERSONAL_ESS:
          case T.ContentsListType.OPEN_ESS:
            defaultFilterContents = defaultESSFilters;
            break;
          case T.ContentsListType.OPEN_TERRAIN:
            defaultFilterContents = defaultTerrainFilters;
            break;
          default:
            console.error('Unknown content filter type');
        }
        return {
          contentFilters: {
            ...state.contentFilters,
            [filterType]: { filterText: '', filterContents: defaultFilterContents },
          },
        };
      }),

    addAssignee: (issueId, user) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [issueId]: {
              ...state.contents.byId[issueId],
              assignees: [
                ...((state.contents.byId[issueId] as T.IssueContent)?.assignees || []),
                user,
              ],
            },
          },
        },
      })),

    removeAssignee: (issueId, userId) =>
      set(state => ({
        contents: {
          ...state.contents,
          byId: {
            ...state.contents.byId,
            [issueId]: {
              ...state.contents.byId[issueId],
              assignees: (state.contents.byId[issueId] as T.IssueContent).assignees.filter(
                user => user.id !== userId
              ),
            },
          },
        },
      })),

    updateDSMHeightsAndColors: (dsmColorsAndHeights, minHeight, maxHeight) =>
      set(() => ({
        dsmHeightsAndColors: dsmColorsAndHeights,
        dsmMinHeight: minHeight,
        dsmMaxHeight: maxHeight,
      })),

    updateDTMHeightsAndColors: (dtmColorsAndHeights, minHeight, maxHeight) =>
      set(() => ({
        dtmHeightsAndColors: dtmColorsAndHeights,
        dtmMinHeight: minHeight,
        dtmMaxHeight: maxHeight,
      })),
  }))
);

export function useContentsStore(): ContentStoreInterface;
export function useContentsStore<T>(selector: (state: ContentStoreInterface) => T): T;
export function useContentsStore<T>(selector?: (state: ContentStoreInterface) => T) {
  return useStore(contentsStore, selector!);
}
