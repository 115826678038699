import React, { useMemo, FC, memo, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import HorizontalAreaSVG from '^/assets/icons/contents-list/horizontal-area.svg';
import SurfaceAreaSVG from '^/assets/icons/contents-list/surface-area.svg';
import { ContentsListItem, HorizontalDivider } from '^/components/atoms/ContentsListItem';
import MetricList, { MetricsContainer } from '^/components/molecules/MetricList';
import { getHorizontalAreaFromGeometry } from '^/components/ol/contentTypeSwitch';
import { UseL10n, useL10n } from '^/hooks';
import * as T from '^/types';
import { calculateArea, determineUnitType } from '^/utilities/imperial-unit';
import { getSingleContentId } from '^/utilities/state-util';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { useThreeStore } from '^/components/three/ThreeStore';
import { PatchContent } from '^/store/duck/Contents';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import dsPalette from '^/constants/ds-palette';
import ReloadSVG from '^/assets/icons/contents-list/reload.svg';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import { isPhone } from '^/utilities/device';
import { DimensionAreaObject } from '^/components/three/ThreeObjects/Dimension';
import { mergeTileset } from '^/components/three/Lib/Utils/TilesetUtils';
import InaccurateMeasurementWarning from '^/components/atoms/InaccurateMeasurementWarning';
import { checkLocationIntersectFlattenContent } from '^/utilities/map-util';
import { useContentsStore } from '^/store/zustand/content/contentStore';

export interface Props {
  readonly content: T.AreaContent;
  readonly isPinned?: boolean;
}
const Balloon2 = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ClampedText = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: dsPalette.title.toString(),
});

const Balloon3 = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});
const ReloadContainer = styled.div({
  width: '14px',
  height: '14px',
  display: 'flex',
  alignItems: 'flex-end',
});
const ContentsListAreaItemRaw: FC<Props> = ({ content, isPinned = false }) => {
  const [l10n]: UseL10n = useL10n();

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);
  const isClampedToggled: boolean = Boolean(content.config?.isClampedToggled);
  const { byId, allIds } = useContentsStore(s => s.contents);
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  if (!projectUnit && !projectId) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }

  const unitType: T.ValidUnitType = useSelector((s: T.State) => {
    const project = s.Projects.projects.byId[projectId ?? NaN];
    return project ? determineUnitType(project.unit) : determineUnitType(projectUnit);
  });

  const stringifiedLocations: string = content.info.locations.toString();
  const measurement: string = useMemo(
    () => getHorizontalAreaFromGeometry(content, unitType),
    [stringifiedLocations, unitType]
  );

  const isDsmAvailable: boolean = useSelector((state: T.State) => {
    const dsmId: T.Content['id'] | undefined = getSingleContentId(
      state.Pages,
      state.ProjectConfigPerUser,
      T.ContentType.DSM
    );
    const dsmContent: T.DSMContent | undefined =
      dsmId !== undefined ? (byId[dsmId] as T.DSMContent) : undefined;

    return Boolean(dsmContent?.status);
  });
  const isInIfc: boolean = Boolean(content?.config?.isInIfc);

  const metricValue: string | undefined = useMemo(() => {
    if (!isDsmAvailable || isInIfc) {
      return '-';
    }

    return content.info.surface === undefined
      ? undefined
      : calculateArea(content.info.surface, unitType).toFixed(2);
  }, [content.info.surface, isDsmAvailable]);

  const contentId: T.Content['id'] = content.id;

  const dispatch: Dispatch = useDispatch();

  const { viewer } = useThreeStore();

  useEffect(() => {
    if (viewer) {
      if (viewer.newContentId === content.id) {
        viewer.newContentId = undefined;
      } else {
        if (content.config?.isClampedToggled && !content.config?.temporaryContentId) {
          dispatch(
            PatchContent({
              content: {
                id: contentId,
                config: {
                  isClampedToggled: false,
                },
              },
            })
          );
        }
      }
    }
  }, [viewer]);

  const handleToggleClamp: () => void = () => {
    if (!viewer) {
      return;
    }
    if (checkWithFlattenContents()) {
      return;
    }
    if (viewer.entities.dimensionEntity) {
      viewer.entities.dimensionEntity.editor.detached();
    }

    updateContentConfig(contentId, {
      ...content.config,
      type: content.type,
      isClampedToggled: !isClampedToggled,
    });
  };

  const checkWithFlattenContents = (): boolean => {
    const [tfWkts]: [string[], string[]] = checkLocationIntersectFlattenContent(
      content.info.locations,
      allIds,
      byId
    );
    return tfWkts.length !== 0;
  };
  const reloadSurface = (e: any) => {
    e.preventDefault();
    if (!content.config?.isClampedToggled) {
      return;
    }
    if (viewer?.tileset?.children.length === 0) {
      return;
    }

    if (viewer) {
      const object = viewer.entities.dimensionEntity?.getObjectByName(`dimension-${content.id}`);
      if (object) {
        const objectVolume = object as DimensionAreaObject;
        const mergeMesh = mergeTileset(viewer.tileset!);
        objectVolume.intersectMesh(mergeMesh, objectVolume.boundary.pointerArray);
        objectVolume.changeDisplayType2(true);
      }
    }
  };

  const warningText = isInIfc ? (
    <InaccurateMeasurementWarning warningText={l10n(Text.inaccurateMeasurementMessage)} />
  ) : null;

  const clampToggleButton: ReactNode = viewer ? (
    <>
      <HorizontalDivider />
      <Balloon2>
        <Balloon3>
          <ClampedText>{l10n(Text.clamped)}</ClampedText>
          {isClampedToggled && (
            <ReloadContainer onClick={reloadSurface}>
              <ReloadSVG />
            </ReloadContainer>
          )}
        </Balloon3>

        <ToggleSlider
          onClick={handleToggleClamp}
          enabled={isClampedToggled}
          data-ddm-track-action={T.TrackActions.CONTENT_ITEM}
          data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_CLAMPED_VOLUME}-${
            isClampedToggled ? 'off' : 'on'
          }`}
        />
      </Balloon2>
    </>
  ) : null;

  const balloon2: ReactNode = isPhone() ? undefined : clampToggleButton;
  return (
    <ContentsListItem
      isPinned={isPinned}
      className={CANCELLABLE_CLASS_NAME}
      content={content}
      firstBalloonTitle={l10n(Text.firstBalloonTitle)}
    >
      <MetricsContainer>
        <MetricList
          type={content.type}
          title={l10n(Text.horizontalArea)}
          tooltip={l10n(Text.horizontalAreaTooltip)}
          icon={<HorizontalAreaSVG />}
          value={measurement}
          unitType={unitType}
        />
        <MetricList
          type={content.type}
          title={l10n(Text.surfaceArea)}
          tooltip={l10n(Text.surfaceAreaTooltip)}
          icon={<SurfaceAreaSVG />}
          value={metricValue}
          unitType={unitType}
        />
      </MetricsContainer>
      {warningText}
      {balloon2}
    </ContentsListItem>
  );
};
const ContentsListAreaItem = memo<FC<Props>>(ContentsListAreaItemRaw);
export default withErrorBoundary(ContentsListAreaItem)(Fallback);
