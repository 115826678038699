import { useMutation } from '@tanstack/react-query';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { FinishPatchUserInfo } from '^/store/duck/Users';
import { AxiosError } from 'axios';
import { useDispatch, useStore } from 'react-redux';
import { ERROR } from '^/store/react-query/types';
import { getRequestErrorType } from '^/store/react-query/utilities/API';

import * as T from '^/types';
import { makeV2APIURL, AuthHeader, makeAuthHeader } from '^/store/duck/API';
import { useIssueStore } from '^/store/issue';
import { http } from '^/utilities/api';
import useIssueContents from '^/hooks/useIssueContents';

export interface PostIssueCategoryBody {
  label: string;
  color?: number;
}

export interface PostIssueCategoryParameter extends PostIssueCategoryBody {
  id: T.IssueContent['id'];
}

export interface PostIssueCategoryResponse {
  data: T.IssueCategory;
}

const postIssueCategoryFn =
  (state: T.State) =>
  async ({ id, label, color }: PostIssueCategoryParameter) => {
    const projectId = state.Pages.Contents.projectId;
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );

    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    if (projectId === undefined) {
      throw new Error(ERROR.ProjectIdMissingError);
    }

    const URL = makeV2APIURL('projects', projectId, 'issue_categories');
    const body: PostIssueCategoryBody = {
      label,
      color,
    };

    try {
      const response = await http.post<PostIssueCategoryResponse>(URL, body, { headers });
      return {
        issueId: id,
        category: response.data.data,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(ERROR.OtherError);
    }
  };

export const usePostIssueCategory = () => {
  const dispatch = useDispatch();
  const { patchIssueContent } = useIssueContents();
  const state = useStore().getState();
  const { mutate } = useMutation(postIssueCategoryFn(state), {
    onSuccess({ issueId, category }) {
      useIssueStore
        .getState()
        .setIssueCategoryOptions(useIssueStore.getState().issueCategoryOptions.concat([category]));
      patchIssueContent({
        content: {
          id: issueId,
          issueCategoryId: category.id,
        },
      });
    },
    onError(error: AxiosError) {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      } else {
        dispatch(
          FinishPatchUserInfo({
            error: getRequestErrorType({ status: error.response?.status } as any),
          })
        );
      }
    },
  });

  return mutate;
};
