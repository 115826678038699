import { create } from 'zustand';

export interface ThreeDimensionStore {
  resetDimensionStore(): void;
}
const initialState = {};
export const useThreeDimensionStore = create<ThreeDimensionStore>(set => ({
  ...initialState,
  resetDimensionStore: () => set(initialState),
}));
