import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as T from '^/types';
import { makeV2APIURL } from '^/store/duck/API';
import axios from 'axios';
import { useAuthHeader } from '^/hooks';
import { APIToPhoto } from '^/store/duck/Photos';

interface GetPhotosResponse {
  readonly data: T.APIPhoto[];
}

interface UseGetPhotoDataParams {
  setPhotoStatus: Dispatch<SetStateAction<T.APIStatus>>;
  projectId: number | undefined;
  setSourcePhotos: Dispatch<SetStateAction<T.FinalPhoto[]>>;
}

export const useGetPhotoData = ({
  projectId,
  setPhotoStatus,
  setSourcePhotos,
}: UseGetPhotoDataParams) => {
  const authHeader = useAuthHeader();
  const [screenId, setScreenId] = useState<number>();
  const [takenAt, setTakenAt] = useState<Date>();

  const { isLoading, data, isError } = useQuery({
    staleTime: 300000,
    queryFn: async () =>
      axios.get<GetPhotosResponse>(
        makeV2APIURL('projects', projectId!, 'photos', 'screen', screenId!),
        {
          headers: { Authorization: authHeader!.Authorization },
        }
      ),
    queryKey: [`${screenId}-${takenAt}-${projectId}`],
    enabled: Boolean(projectId && screenId && takenAt),
  });

  useEffect(() => {
    if (isLoading) {
      setPhotoStatus(T.APIStatus.PROGRESS);
    } else if (isError) {
      setPhotoStatus(T.APIStatus.ERROR);
    } else {
      setPhotoStatus(T.APIStatus.SUCCESS);
    }
  }, [isLoading, isError]);

  useEffect(() => {
    if (data && takenAt) {
      const result = data.data.data.map(photo => APIToPhoto(photo));
      setSourcePhotos(result);
    }
    // return () => setSourcePhotos([]); This is hiding the viewer after creating the issue in photo
  }, [data, projectId]);

  return {
    setTakenAt,
    setScreenId,
  };
};
