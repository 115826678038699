import { useNavigate } from 'react-router-dom';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import ContentsTabbar, { Props } from '^/components/molecules/ContentsTabbar';
import { defaultFeatures } from '^/constants/defaultContent';
import route from '^/constants/routes';

import {
  ChangeContentsSidebarTab,
  ChangeIn3D,
  ChangeIn3DPointCloud,
  OpenContentPagePopup,
  SetPreventAutoSelect,
} from '^/store/duck/Pages';
import { useAuthUserQuery } from '^/store/react-query/users';
import * as T from '^/types';

import { isAllowToUpload } from '^/utilities/role-permission-check';
import { getSingleContentId, getViewableThreeDContentId } from '^/utilities/state-util';
import { DEFAULT_USER_FEATURE_PERMISSION } from '^/utilities/withFeatureToggle';
import { usePresentationStore } from '^/store/presentationStore';
import { contentsSelector, PatchContent } from '^/store/duck/Contents';
import { SetSelectedMedia } from '^/store/duck/Photos';
import { Action } from 'redux';
import { useLastSelectedScreen } from '^/hooks';
import { useContentsStore } from '^/store/zustand/content/contentStore';

type StatePropKeys =
  | 'currentTab'
  | 'role'
  | 'printingContentId'
  | 'squareLogoUrl'
  | 'has3DView'
  | 'userFeaturePermission'
  | 'features'
  | 'integrations';
type DispatchPropKeys = 'onTabClick' | 'onUploadClick' | 'onLogoClick' | 'preventAutoSelect';
export type OwnProps = Omit<Props, StatePropKeys | DispatchPropKeys>;
export type StateProps = Pick<Props, StatePropKeys>;
export type DispatchProps = Pick<Props, DispatchPropKeys>;

export default function ContentsTabbarContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: authedUser } = useAuthUserQuery();
  const projectId: number | undefined = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const squareLogoUrl = useSelector((s: T.State) => s.PlanConfig.config?.squareLogoUrl);
  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const printingContentId = useSelector((s: T.State) => s.Pages.Contents.printingContentId);
  const role: T.PermissionRole = useSelector((s: T.State) =>
    projectId && s.Projects.projects.allIds.includes(projectId)
      ? s.Projects.projects.byId[projectId].permissionRole
      : T.PermissionRole.VIEWER
  );
  const features: T.PermissionFeature = useSelector((s: T.State) =>
    projectId && s.Projects.projects.allIds.includes(projectId)
      ? s.Projects.projects.byId[projectId].features
      : defaultFeatures
  );
  const integrations = useSelector((s: T.State) =>
    projectId && s.Projects.projects.allIds.includes(projectId)
      ? s.Projects.projects.byId[projectId].integrations
      : undefined
  );
  const has3DView = useSelector((s: T.State) =>
    Boolean(getViewableThreeDContentId(s.Pages, s.ProjectConfigPerUser))
  );

  const lastSelectedScreen = useLastSelectedScreen();

  const byId = useContentsStore(s => s.contents.byId);
  const Pages = useSelector((state: T.State) => state.Pages);
  const ProjectConfigPerUser = useSelector((state: T.State) => state.ProjectConfigPerUser);

  const setIsInPresentationmode = usePresentationStore(s => s.setIsInPresentationMode);

  const userFeaturePermission: T.User['featurePermission'] =
    authedUser?.id !== undefined ? authedUser.featurePermission : DEFAULT_USER_FEATURE_PERMISSION;

  const threeDOrthoId: T.ThreeDOrthoContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.THREE_D_ORTHO
  );
  const twoDOrthoId: T.MapContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.MAP
  );
  const pointCloudsId: T.PointCloudContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.POINTCLOUD
  );
  const is3DOrthoSelected: boolean =
    contentsSelector.isSelected(ProjectConfigPerUser)(threeDOrthoId);
  const is2DOrthoSelected: boolean = contentsSelector.isSelected(ProjectConfigPerUser)(twoDOrthoId);
  const isPointCloudsSelected: boolean =
    contentsSelector.isSelected(ProjectConfigPerUser)(pointCloudsId);

  const stateProps: StateProps = {
    role,
    currentTab: sidebarTab,
    printingContentId: printingContentId,
    squareLogoUrl,
    has3DView,
    userFeaturePermission,
    features,
    integrations,
  };

  const dispatchProps: DispatchProps = {
    onTabClick(_sidebarTab: T.ContentPageTabType): void {
      const idToDisable = (function () {
        if (is3DOrthoSelected) {
          return threeDOrthoId;
        } else if (isPointCloudsSelected) {
          return pointCloudsId;
        } else if (is2DOrthoSelected) {
          return twoDOrthoId;
        } else {
          return undefined;
        }
      })();

      if (_sidebarTab === T.ContentPageTabType.ISSUE && isPointCloudsSelected && pointCloudsId) {
        dispatch(ChangeIn3D({ in3D: false }));

        const actions: Action[] = [];
        actions.push(
          PatchContent({
            content: {
              id: pointCloudsId,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );

        batch(() => actions.forEach(dispatch));
      }

      if (_sidebarTab === T.ContentPageTabType.ISSUE && isPointCloudsSelected && pointCloudsId) {
        dispatch(ChangeIn3D({ in3D: false }));

        const actions: Action[] = [];
        actions.push(
          PatchContent({
            content: {
              id: pointCloudsId,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );

        batch(() => actions.forEach(dispatch));
      }

      if (_sidebarTab === T.ContentPageTabType.ESS && idToDisable) {
        if (isPointCloudsSelected) {
          dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
        }
        const oldConfig = byId[idToDisable].config;
        dispatch(
          PatchContent({
            content: {
              id: idToDisable,
              config: {
                ...oldConfig,
                selectedAt: undefined,
              },
            },
          })
        );
      }
      if (lastSelectedScreen && lastSelectedScreen.id) {
        navigate(
          route.content.createMain(
            projectId!.toString(),
            _sidebarTab,
            lastSelectedScreen.id.toString(),
            null,
            null
          )
        );
      }

      if (_sidebarTab === T.ContentPageTabType.VIEWPOINT_CAPTURE) {
        if (isPointCloudsSelected) {
          dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
        }
        if (idToDisable) {
          const oldConfig = byId[idToDisable].config;
          dispatch(
            PatchContent({
              content: {
                id: idToDisable,
                config: {
                  ...oldConfig,
                  selectedAt: undefined,
                },
              },
            })
          );
        }
      }

      batch(() => {
        dispatch(SetSelectedMedia({ selectedMedia: [] }));
        dispatch(ChangeContentsSidebarTab({ sidebarTab: _sidebarTab }));
        setIsInPresentationmode(_sidebarTab === T.ContentPageTabType.PRESENTATION);
      });
    },
    onUploadClick(permissionRole: T.PermissionRole): void {
      dispatch(
        OpenContentPagePopup({
          popup: isAllowToUpload(permissionRole)
            ? T.ContentPagePopupType.UPLOAD
            : T.ContentPagePopupType.NO_PERMISSION,
        })
      );
    },
    onLogoClick(): void {
      navigate(route.project.main);
    },
    preventAutoSelect(): void {
      dispatch(
        SetPreventAutoSelect({
          value: true,
        })
      );
    },
  };

  return <ContentsTabbar {...stateProps} {...dispatchProps} />;
}
