import { ChangeCurrentMeshEngine } from '^/store/duck/Pages';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as T from '^/types';
import { useL10n } from '^/hooks';
import Text from './text';
import { CustomBaseButton } from '^/components/atoms/Buttons';

const Root = styled.div({
  padding: '0px 15px 15px 20px',
});

export const ChooseEngineButton = () => {
  const dispatch = useDispatch();
  const [l10n] = useL10n();
  const { currentMeshEngine, hasThreeJsSupport } = useSelector((s: T.State) => s.Pages.Contents);
  const in3D: boolean = useSelector((s: T.State) => s.Pages.Contents.in3D);

  const changeToThree = () => {
    dispatch(ChangeCurrentMeshEngine({ engine: T.MeshEngine.THREEJS, hasThreeJsSupport }));
  };

  const changeToCesium = () => {
    dispatch(ChangeCurrentMeshEngine({ engine: T.MeshEngine.CESIUM, hasThreeJsSupport }));
  };

  if (in3D && hasThreeJsSupport) {
    return (
      <Root>
        {/* <Label>{l10n(Text.engine)}</Label> */}
        {currentMeshEngine === T.MeshEngine.THREEJS ? (
          <CustomBaseButton isSelected={true} onClick={changeToCesium}>
            {l10n(Text.tryLegacy)}
          </CustomBaseButton>
        ) : (
          <CustomBaseButton isSelected={false} onClick={changeToThree}>
            {l10n(Text.tryBeta)}
          </CustomBaseButton>
        )}
        {/* <Label style={{ marginTop: '15px' }}>{l10n(Text.maps)}</Label> */}
      </Root>
    );
  }
  return <></>;
};
