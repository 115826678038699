import * as T from '^/types';

export default {
  addColor: {
    [T.Language.KO_KR]: '색상 추가',
    [T.Language.EN_US]: 'Add Color',
  },
  apply: {
    [T.Language.KO_KR]: '적용',
    [T.Language.EN_US]: 'Apply',
  },
};
