import React, { FC } from 'react';

import { useL10n, UseL10n } from '^/hooks';
import PaperClipSvg from '^/assets/icons/photo/paper-clip.svg';
import Text from './text';
import styled from 'styled-components';
import palette from '^/constants/palette';
import { useDispatch } from 'react-redux';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';
import dsPalette from '^/constants/ds-palette';

const Root = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  flexDirection: 'column',
  rowGap: '24px',
  height: '75vh',
  paddingRight: '38px',
});

const HeaderText = styled.p({
  fontSize: '20px',
  fontWeight: 600,
  color: '#7C7B7B',
  lineHeight: '26px',
  textAlign: 'center',
});

const Button = styled.button({
  backgroundColor: dsPalette.themePrimary.toString(),
  borderRadius: '6px',
  padding: '10px 32px',
  color: dsPalette.white.toString(),
  fontSize: '14px',
  fontWeight: 700,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: dsPalette.themePrimaryLightest.toString(),
  },
});

const SubHeaderText = styled.span({
  color: palette.dropdown.caretColor.toString(),
  fontSize: '14px',
  whiteSpace: 'pre-line',
  fontWeight: 600,
  textAlign: 'center',
  lineHeight: '26px',
});

const DefaultPhotoView: FC = () => {
  const [l10n]: UseL10n = useL10n();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      OpenContentPagePopup({ popup: T.ContentPagePopupType.PHOTO_UPLOAD, hideBackButton: true })
    );
  };

  return (
    <Root>
      <PaperClipSvg />
      <HeaderText>{l10n(Text.header.title)}</HeaderText>
      <SubHeaderText>{l10n(Text.header.subText)}</SubHeaderText>
      <Button onClick={handleClick}> {l10n(Text.uploadButtonText)} </Button>
    </Root>
  );
};

export default DefaultPhotoView;
