import { Language } from '^/types';

export default {
  attachFile: {
    [Language.KO_KR]: '파일 첨부',
    [Language.EN_US]: 'Attach files',
  },
  attachFileNotification: {
    [Language.KO_KR]: '파일을 첨부해 주세요.',
    [Language.EN_US]: 'Please upload files.',
  },
  fileExtensionNotification: {
    [Language.KO_KR]: '지원되는 파일 형식: ',
    [Language.EN_US]: 'Supported file formats: ',
  },
  tooltipDeleteAll: {
    [Language.KO_KR]: '모두 삭제',
    [Language.EN_US]: 'Delete all',
  },
  totalSelected: {
    [Language.KO_KR]: '선택된 개수',
    [Language.EN_US]: 'Total selected',
  },
  selectedSourceAlbum: {
    [Language.KO_KR]: '앨범에서 선택',
    [Language.EN_US]: 'Selected from album',
  },

  fromAlbum: {
    [Language.KO_KR]: '앨범에서 선택',
    [Language.EN_US]: 'Select from album',
  },
  selectedTitle: {
    [Language.KO_KR]: '선택된 사진',
    [Language.EN_US]: 'Selected Album',
  },
  changeAlbum: {
    [Language.KO_KR]: '앨범 변경',
    [Language.EN_US]: 'Change Album',
  },
};
