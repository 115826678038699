import { Language } from '^/types';

export default {
  title: {
    [Language.KO_KR]: '로그아웃',
    [Language.EN_US]: 'Logged out',
  },
  description: {
    [Language.KO_KR]: '다른기기에서 로그인이 확인되어 로그아웃 되었습니다.',
    [Language.EN_US]: 'Login from another device has logged out this device.',
  },
};
