import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import usePan from '^/hooks/usePan';
import ZoomSlider from './ZoomSlider';

const MagnifiedImage = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: '2',
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0px 0px',
  backgroundSize: 'contain',
  backgroundColor: '#333',
});
const MagnifyContainer = styled.div({
  position: 'fixed',
  left: 100,
  bottom: 25,
  backgroundSize: '100% 100%',
  cursor: 'pointer',
});
const MagnifyArea = styled.div({
  position: 'absolute',
  boxShadow: '0 0 0 2px #ffffff',
  pointerEvents: 'none',
  boxSizing: 'border-box',
  backgroundRepeat: 'no-repeat',
});

const ZoomSliderWrapper = styled.div({
  position: 'fixed',
  top: 174,
  left: '50%',
  backgroundColor: `rgba(50, 64, 77, 0.8)`,
  borderRadius: '16px',
  transform: 'translate(-50%, -50%)',
});

const DEFAULT_ZOOM = 101;
const MIN_ZOOM = 100;
const MAX_ZOOM = 250;
const PAN_SENSITIVITY = 10;
interface MagnifyImageProps {
  imageURI: string;
  onMinZoomLevel(): void;
  wheelMousePos: { x: number; y: number };
}
export default function MagnifyImage({
  imageURI,
  onMinZoomLevel,
  wheelMousePos,
}: MagnifyImageProps) {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 });
  const [magnifyArea, setMagnifyArea] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    zoomLevel: 0,
  });
  const [isSelectingArea, setIsSelectingArea] = useState(false);

  const isPanning = usePan(rootRef, (deltaX, deltaY) => {
    setMagnifyArea(area => {
      let offsetX = area.x - deltaX / PAN_SENSITIVITY,
        offsetY = area.y - deltaY / PAN_SENSITIVITY;
      if (offsetX < 0 || offsetY < 0) {
        return area;
      }
      if (offsetX + magnifyArea.width >= magnifyAreaContainerDimension.width) {
        offsetX = area.x;
      }
      if (offsetY + magnifyArea.height >= magnifyAreaContainerDimension.height) {
        offsetY = area.y;
      }
      return { ...area, x: offsetX, y: offsetY };
    });
  });

  const magnifyAreaContainerDimension = {
    width: 200,
    height: 200 * (imageDimension.height / imageDimension.width),
  };

  function zoomLensLeft(left: number) {
    const leftMin = magnifyArea.width / 2;
    let value = left;

    if (value < leftMin) {
      value = leftMin;
    } else if (value > imageDimension.width - leftMin) {
      value = imageDimension.width - leftMin;
    }

    return value - leftMin;
  }

  function zoomLensTop(top: number) {
    const topMin = magnifyArea.height / 2;
    let value = top;

    if (value < topMin) {
      value = topMin;
    } else if (value > imageDimension.height - topMin) {
      value = imageDimension.height - topMin;
    }

    return value - topMin;
  }

  const magnifyAreaPosition = (clientX: number, clientY: number, isRatio?: boolean) => {
    if (containerRef.current) {
      const offset = containerRef.current.getBoundingClientRect();
      let left = clientX - offset.left;
      let top = clientY - offset.top;
      if (isRatio) {
        left = clientX * magnifyAreaContainerDimension.width;
        top = clientY * magnifyAreaContainerDimension.height;
      }
      let offsetX = zoomLensLeft(left);
      let offsetY = zoomLensTop(top);

      if (offsetX + magnifyArea.width >= magnifyAreaContainerDimension.width) {
        offsetX = magnifyAreaContainerDimension.width - magnifyArea.width;
      }
      if (offsetY + magnifyArea.height >= magnifyAreaContainerDimension.height) {
        offsetY = magnifyAreaContainerDimension.height - magnifyArea.height;
      }
      setMagnifyArea(area => ({ ...area, x: offsetX, y: offsetY }));
    }
  };

  const onZoomLevelChange = (zoomLevel: number, _wheelMousePos?: { x: number; y: number }) => {
    setMagnifyArea(prev => {
      if (zoomLevel === prev.zoomLevel) {
        return prev;
      }
      let x = prev.x,
        y = prev.y,
        _width = 200 * (100 / zoomLevel),
        _height = _width * (window.innerHeight / window.innerWidth);

      x = prev.x - (_width - prev.width) / (1 / (_wheelMousePos?.x || 0.5));
      x = x > 0 ? x : 0;
      y = prev.y - (_height - prev.height) / (1 / (_wheelMousePos?.y || 0.5));
      y = y > 0 ? y : 0;

      if (prev.x + _width > magnifyAreaContainerDimension.width) {
        _width = prev.width + (magnifyAreaContainerDimension.width - (prev.x + _width));
        x = x - (magnifyAreaContainerDimension.width - (prev.x + _width));
        _height = _width * (window.innerHeight / window.innerWidth);
      }
      if (prev.y + _height > magnifyAreaContainerDimension.height) {
        _height = prev.height + (magnifyAreaContainerDimension.height - (prev.y + _height));
        y = y - (magnifyAreaContainerDimension.height - (prev.y + _height));
        _width = (_height * window.innerWidth) / window.innerHeight;
      }
      if (y < 0 || x < 0) {
        return prev;
      }
      return {
        x,
        y,
        width: _width,
        height: _height,
        zoomLevel,
      };
    });
    if (zoomLevel === MIN_ZOOM) {
      onMinZoomLevel();
    }
  };

  const handleMouseDown = (e: any) => {
    setIsSelectingArea(true);
    magnifyAreaPosition(e.clientX, e.clientY);
  };

  const handleMouseMove = (e: any) => {
    if (isSelectingArea) {
      magnifyAreaPosition(e.clientX, e.clientY);
    }
  };

  const handleMouseUp = () => {
    setIsSelectingArea(false);
  };

  useEffect(() => {
    const img = new Image();
    img.src = imageURI;

    img.onload = function () {
      setImageDimension({ width: img.naturalWidth, height: img.naturalHeight });
    };
  }, []);

  useEffect(() => {
    onZoomLevelChange(DEFAULT_ZOOM);
    magnifyAreaPosition(wheelMousePos.x, wheelMousePos.y, true);
  }, [imageDimension.width, imageDimension.height]);

  const magnifiedBackgroundSize = {
    width: (magnifyAreaContainerDimension.width / magnifyArea.width) * window.innerWidth,
    height: (magnifyAreaContainerDimension.height / magnifyArea.height) * window.innerHeight,
  };

  return (
    <>
      <MagnifiedImage
        ref={rootRef}
        style={{
          backgroundImage: `url(${imageURI})`,
          backgroundSize: `${magnifiedBackgroundSize.width}px ${magnifiedBackgroundSize.height}px`,
          backgroundPosition: `-${
            magnifiedBackgroundSize.width * (magnifyArea.x / magnifyAreaContainerDimension.width)
          }px -${
            magnifiedBackgroundSize.height * (magnifyArea.y / magnifyAreaContainerDimension.height)
          }px`,
          cursor: isPanning ? 'grabbing' : 'grab',
        }}
      />
      <MagnifyContainer
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${imageURI})`,
          ...magnifyAreaContainerDimension,
        }}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        ref={containerRef}
      >
        <MagnifyArea
          style={{
            transform: `translate(${magnifyArea.x}px, ${magnifyArea.y}px)`,
            backgroundSize: `${magnifyAreaContainerDimension.width}px ${magnifyAreaContainerDimension.height}px`,
            backgroundImage: `url(${imageURI})`,
            backgroundPosition: `-${magnifyArea.x}px -${magnifyArea.y}px`,
            height: magnifyArea.height,
            width: magnifyArea.width,
          }}
        />
      </MagnifyContainer>
      <ZoomSliderWrapper>
        <ZoomSlider
          onChange={onZoomLevelChange}
          defaultValue={DEFAULT_ZOOM}
          min={MIN_ZOOM}
          max={MAX_ZOOM}
        />
      </ZoomSliderWrapper>
    </>
  );
}
