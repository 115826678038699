import * as T from '^/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface DroneStationState {
  editingDroneStationId: T.DroneStationContent['id'] | undefined;
  droneStations: Record<T.DroneStationContent['id'], T.DroneStationContent>;
  fetchDroneStationStatus: T.APIStatus;
  selectedDroneStationDropdownOption: T.DroneStationContent['id'] | undefined;
  liveStreamQuality: T.LiveStreamQuality;
}

interface DroneStationActions {
  setEditingDroneStationId(droneStationContentId: T.DroneStationContent['id'] | undefined): void;
  setDroneStations(droneStations: Record<T.DroneStationContent['id'], T.DroneStationContent>): void;
  setFetchDroneStationStatus(status: T.APIStatus): void;
  setSelectedDroneStationDropdownOption(
    newSelectedDroneStationSort: T.DroneStationContent['id'] | undefined
  ): void;
  setLiveStreamQuality(quality: T.LiveStreamQuality): void;
}

const droneStationInitialState: DroneStationState = {
  editingDroneStationId: undefined,
  droneStations: {},
  fetchDroneStationStatus: T.APIStatus.IDLE,
  selectedDroneStationDropdownOption: undefined,
  liveStreamQuality: T.LiveStreamQuality.Auto,
};

type DroneStationStore = DroneStationState & DroneStationActions;

export const useDroneStationStore = create<DroneStationStore>()(
  devtools(set => ({
    ...droneStationInitialState,
    setDroneStations(droneStations) {
      set(droneStationStore => ({ ...droneStationStore, droneStations }));
    },
    setFetchDroneStationStatus(status) {
      set(droneStationStore => ({ ...droneStationStore, fetchDroneStationStatus: status }));
    },
    setEditingDroneStationId(droneStationContentId: T.DroneStationContent['id']) {
      set(droneStationStore => ({
        ...droneStationStore,
        editingDroneStationId: droneStationContentId,
      }));
    },
    setSelectedDroneStationDropdownOption(
      droneStationContentId: T.DroneStationContent['id'] | undefined
    ) {
      set(droneStationStore => ({
        ...droneStationStore,
        selectedDroneStationDropdownOption: droneStationContentId,
      }));
    },
    setLiveStreamQuality(quality) {
      set({ liveStreamQuality: quality });
    },
  }))
);
