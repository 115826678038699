import React, { FC, ReactNode, memo, useCallback, useEffect, useRef } from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import styled, { CSSObject } from 'styled-components';

import { ContentsTreeList } from '../ContentsTreeList';
import { Fallback } from './fallback';
import { CONTENTSLIST_CTXSORT_KEY } from '^/components/atoms/ContentsListItem';
import { GroupedContentsListHeader } from '^/components/molecules/GroupedContentsListHeader';
import { ESSContentFilterContextProvider } from '^/components/molecules/ContentsSearchBox/essContext';

import palette from '^/constants/palette';
import { useIsRoleX, UseL10n, useL10n } from '^/hooks';
import Text from './text';
import * as T from '^/types';
import {
  CtxSort,
  CtxSortCollisionMode,
  CtxSortContext,
  CtxSortEvent,
  CtxSortEventOptions,
} from '^/utilities/ctxsort';
import { arePropsEqual } from '^/utilities/react-util';
import { isRoleViewer } from '^/utilities/role-permission-check';
import { getUserAgent } from '^/utilities/userAgent';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import ContentsSearchBox from '^/components/molecules/ContentsSearchBox';
import { defaultESSFilters } from '^/store/duck/Contents';
import { useESSContentsStore } from '^/store/essContentsStore';
import { MoveContent } from '^/store/duck/Groups';
import { useESSContents } from '^/hooks/useESSContents';
import WarningBox from '^/components/atoms/WarningBox';
import { ChangeIn3D } from '^/store/duck/Pages';

const ESSContentsListWrapper = styled.div.attrs({
  className: 'ctxsort-scroller',
})({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowX: 'hidden',
  touchAction: 'none',
});
ESSContentsListWrapper.displayName = 'ESSContentsListWrapper';

export const ESSContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',
  userSelect: 'none',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});
ESSContentsListRoot.displayName = 'GroupedContentsListRoot';

const GroupListDivider = styled.div({
  position: 'sticky',
  zIndex: 10,
  top: '43px',
  height: '6px',
  marginTop: '17.5px',
  borderTop: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  borderBottom: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  backgroundColor: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  boxSizing: 'border-box',
});
GroupListDivider.displayName = 'GroupListDivider';

const WarningBoxWrapper = styled.div({
  padding: '10px 20px',
});

const Contents: FC = () => {
  const { essContents, essContentIds, essContentStatus, rootESSContentIdsBySpace } =
    useESSContentsStore(s => ({
      essContents: s.essContents,
      essContentIds: s.essContentIds,
      essContentStatus: s.fetchESSContentStatus,
      essContentsResponse: s.essContentsResponse,
      rootESSContentIdsBySpace: s.rootESSContentIdsBySpace,
      updateSelectedAtInESSContents: s.updateSelectedAtInESSContents,
    }));
  const dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();

  const isIn3D: boolean = useSelector((state: T.State) => state.Pages.Contents.in3D);

  const { createESSGroupContent } = useESSContents();

  useEffect(() => {
    const isFetchSuccess = essContentStatus === T.APIStatus.SUCCESS;

    if (isFetchSuccess) {
      const hasOpenItem = essContentIds.some(contentId => !essContents[contentId].isPersonal);
      const hasPersonalItem = essContentIds.some(contentId => essContents[contentId].isPersonal);

      const createDefaultContentGroup = async (): Promise<void> => {
        if (!hasPersonalItem) {
          await createESSGroupContent({
            parentGroupId: undefined,
            isPersonal: true,
          });
        }
        if (!hasOpenItem) {
          await createESSGroupContent({
            parentGroupId: undefined,
            isPersonal: false,
          });
        }
      };
      void createDefaultContentGroup();
    }
  }, [essContentStatus, essContentIds]);

  return (
    <>
      <ESSContentFilterContextProvider
        contentTypes={defaultESSFilters}
        filterType={T.ContentsListType.PERSONAL_ESS}
        rootGroupIds={rootESSContentIdsBySpace.personal}
      >
        <GroupedContentsListHeader
          rootGroupIds={rootESSContentIdsBySpace.personal}
          isPersonalGroups={true}
        />

        <ContentsSearchBox isESS={true} />
        <ContentsTreeList contentIds={rootESSContentIdsBySpace.personal} isESS={true} />
      </ESSContentFilterContextProvider>
      <GroupListDivider />
      <ESSContentFilterContextProvider
        contentTypes={defaultESSFilters}
        filterType={T.ContentsListType.OPEN_ESS}
        rootGroupIds={rootESSContentIdsBySpace.open}
      >
        <GroupedContentsListHeader
          rootGroupIds={rootESSContentIdsBySpace.open}
          isPersonalGroups={false}
        />
        <ContentsSearchBox isESS={true} />

        {isIn3D ? (
          <ContentsTreeList contentIds={rootESSContentIdsBySpace.open} isESS={true} />
        ) : (
          <WarningBoxWrapper>
            <WarningBox
              description={l10n(Text.warningDescription)}
              actionText={l10n(Text.switchText)}
              onClick={() => dispatch(ChangeIn3D({ in3D: true }))}
            />
          </WarningBoxWrapper>
        )}
      </ESSContentFilterContextProvider>
    </>
  );
};

const GroupedContentsList: FC = () => {
  const contentFilters = useESSContentsStore(s => s.contentFilters);
  const { updateESSContent } = useESSContents();

  const dispatch = useDispatch();
  const contentListItemCtxSortRef = useRef<CtxSortContext | null>(null);
  const groupCtxSortRef = useRef<CtxSortContext | null>(null);

  const userAgent: T.UserAgent = getUserAgent();
  const isViewer: boolean = useIsRoleX(isRoleViewer);

  const printingContentId: T.ContentsPageState['printingContentId'] = useSelector(
    (s: T.State) => s.Pages.Contents.printingContentId
  );
  const handleContentMoveThroughDnD: CtxSortEventOptions['onSortEnd'] = useCallback(
    (e: CtxSortEvent) => {
      dispatch(MoveContent({ e, updateESSContent: updateESSContent }));
    },
    []
  );

  useEffect(() => {
    if (isViewer || printingContentId) {
      return;
    }
    const filterText =
      contentFilters[T.ContentsListType.OPEN_ESS].filterText ||
      contentFilters[T.ContentsListType.PERSONAL_ESS].filterText;
    const hasFilteredContents =
      contentFilters[T.ContentsListType.OPEN_ESS].filterContents.length !==
        defaultESSFilters.length ||
      contentFilters[T.ContentsListType.PERSONAL_ESS].filterContents.length !==
        defaultESSFilters.length;
    if (filterText || hasFilteredContents) {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();
    } else {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();

      contentListItemCtxSortRef.current = CtxSort({
        target: CONTENTSLIST_CTXSORT_KEY,
        owner: 'Group',
        scroller: '.ctxsort-scroller',
        portalId: 'ctxsort-portal-content-list-item',
        delay: 200,
        onSortEnd: handleContentMoveThroughDnD,
        psuedoOwnerClassName: 'ctxsort-contentlistitem-owner-active',
      });

      groupCtxSortRef.current = CtxSort({
        target: 'Group',
        owner: 'GroupedContentsListHeader',
        scroller: '.ctxsort-scroller',
        portalId: 'ctxsort-portal-content-group',
        collisionMode: CtxSortCollisionMode.PARENT,
        delay: 200,
        onSortEnd: handleContentMoveThroughDnD,
        restrictSubGroup: true,
      });
    }
    return () => {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();
    };
  }, [contentFilters]);

  const scrollBarViewStyle: CSSObject = {
    height: '100%',
    overflowX: 'hidden',
    paddingBottom: '2.5px',
    touchAction: 'none',
  };
  const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
    <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
  );
  const hiddenAfterMilliSec: number = 0;
  const hiddenViaMilliSec: number = 500;

  /**
   * Safari does not support Scrollbars
   */
  const Layout: ReactNode =
    userAgent === T.UserAgent.SAFARI ? (
      <ESSContentsListWrapper>
        <ESSContentsListRoot>
          <Contents />
        </ESSContentsListRoot>
      </ESSContentsListWrapper>
    ) : (
      <ESSContentsListRoot>
        <ScrollBars
          renderView={ScrollBarView}
          autoHide={true}
          autoHideTimeout={hiddenAfterMilliSec}
          autoHideDuration={hiddenViaMilliSec}
        >
          <Contents />
        </ScrollBars>
      </ESSContentsListRoot>
    );

  return <>{Layout}</>;
};

export default memo(withErrorBoundary(GroupedContentsList)(Fallback), arePropsEqual);
