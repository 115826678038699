import * as T from '^/types';
import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { createStore, useStore } from 'zustand';
import { FilterContentEnum } from '^/components/molecules/ContentsSearchBox/overlayContext';

interface ContentFilters {
  [filterType: string]: {
    filterText: string;
    filterContents: T.ContentType[];
  };
}
interface TGeoreferencingMapDetails {
  baseMap: Map | null;
  floorPlanBaseMap: Map | null;
  imagePoint: [Coordinate, Coordinate] | null;
  geoPoint: [Coordinate, Coordinate] | null;
  startEndPoint: [Coordinate, Coordinate] | null;
  selectedStartAndEndPoint: [Coordinate, Coordinate] | null;

  photoLongLat: T.GeoPoint;
  photoCameraOrientation: { x: number; y: number; z: number };
  photoFov: number;
  selectedPhotoId: string | undefined;
  selectedVideoId: string | number | undefined;
  selectedFloorPlanId: string | number | undefined;
  shouldRetriggerCalc: boolean;
  isPhotosFromVSLAM: boolean;
  overlayOpacity: number;
  selectedPinType: 'start' | 'end' | undefined;
  startPointFromGeoReferencing: Coordinate | null;
  endPointFromGeoReferencing: Coordinate | null;
  selectedFloorPlanFilter: FilterContentEnum;
}

export const defaultVslamOverlayFilters = [
  T.ContentType.GROUP,
  T.ContentType.BLUEPRINT_PDF,
  T.ContentType.BLUEPRINT_DWG,
  T.ContentType.BLUEPRINT_DXF,
];

interface VSLAMAnd360StoreState {
  georeferencingMapDetails: TGeoreferencingMapDetails;
  contentFilters: ContentFilters;
}
interface VSLAMAnd360StoreActions {
  setGeoreferencingMapDetails(value: Partial<TGeoreferencingMapDetails>): void;
  setContentFilters(contentFilters: ContentFilters): void;
  resetVSLAMAnd360Store(): void;
}

type VSLAMAnd360Store = VSLAMAnd360StoreState & VSLAMAnd360StoreActions;
const initialState: VSLAMAnd360StoreState = {
  georeferencingMapDetails: {
    baseMap: null,
    floorPlanBaseMap: null,
    imagePoint: null,
    geoPoint: null,
    startEndPoint: null,
    selectedStartAndEndPoint: null,

    photoLongLat: [],
    photoCameraOrientation: {
      x: 0,
      y: 0,
      z: 0,
    },
    photoFov: 50,
    selectedPhotoId: undefined,
    selectedVideoId: undefined,
    selectedFloorPlanId: undefined,
    shouldRetriggerCalc: false,
    overlayOpacity: 1,
    selectedPinType: undefined,
    startPointFromGeoReferencing: null,
    endPointFromGeoReferencing: null,
    selectedFloorPlanFilter: FilterContentEnum.SELECT_ALL,
    isPhotosFromVSLAM: false,
  },

  contentFilters: {
    [T.ContentsListType.PERSONAL_OVERLAY]: {
      filterText: '',
      filterContents: defaultVslamOverlayFilters,
    },
    [T.ContentsListType.OPEN_OVERLAY]: {
      filterText: '',
      filterContents: defaultVslamOverlayFilters,
    },
  },
};

export const vslamAnd360Store = createStore<VSLAMAnd360Store>()((set, get) => ({
  ...initialState,
  setGeoreferencingMapDetails: (value: Partial<TGeoreferencingMapDetails>) =>
    set(state => ({
      georeferencingMapDetails: {
        ...state.georeferencingMapDetails,
        ...value,
      },
    })),
  setContentFilters(contents) {
    const { contentFilters } = get();
    set({ contentFilters: { ...contentFilters, ...contents } });
  },
  resetVSLAMAnd360Store: () => set(initialState),
}));

export function useVslamAnd360Store(): VSLAMAnd360Store;
export function useVslamAnd360Store<T>(selector: (state: VSLAMAnd360Store) => T): T;
export function useVslamAnd360Store<T>(selector?: (state: VSLAMAnd360Store) => T) {
  return useStore(vslamAnd360Store, selector!);
}
