import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  defaultBim,
  defaultBlueprintDWG,
  defaultBlueprintDXF,
  defaultBlueprintPDF,
  defaultDesignDXF,
  defaultDSM,
  defaultMap,
  defaultPointCloud,
} from '^/constants/defaultContent';
import { useAttachmentsStore } from '^/store/attachmentsStore';
import { AuthHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { APIToContent, PostContentArguments, UploadContentBody } from '^/store/duck/Contents';
import { addContentToTree, ChangeSelectedGroupId } from '^/store/duck/Groups';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';
import { getCurrentGroupId } from '^/utilities/content-util';
import { Formats, formatWithOffset } from '^/utilities/date-format';
import { calculateHash } from '^/utilities/file-util';
import { useAttachment } from './useAttachment';
import { useScreen } from './useScreen';
import { http } from '^/utilities/api';
import { contentsStore } from '^/store/zustand/content/contentStore';

interface PostContentResponse {
  readonly data: T.APIContent;
}

interface UploadSingleContentParams {
  readonly attachmentType: T.AttachmentType;
  readonly file: File;
  readonly title?: string;
  readonly appearAt?: T.Content['appearAt'];
  readonly coordinateSystem?: T.CoordinateSystem;
  readonly screen?: T.Screen;
  readonly bimMeta?: T.BimContent['info']['bimMeta'];
}

export function useUploadSingleContent() {
  const state$ = useStore<T.State>().getState();
  const dispatch = useDispatch();
  const { createAttachment } = useAttachment();
  const setIsUploading = useAttachmentsStore(s => s.setIsUploading);
  const resetAttachmentUploadStatus = useAttachmentsStore(s => s.resetAttachmentUploadStatus);
  const { createScreen } = useScreen();

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const center = useSelector((s: T.State) => s.Pages.Contents.twoDDisplayCenter);

  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);

  const headers = {
    ...authHeader,
  };

  function changeSelectedGroupId({ tab }: { tab: T.ContentPageTabType }) {
    let groupId: T.GroupContent['id'] | undefined;
    try {
      // TODO: State_Management: if a function needs whole state, use it inside the function instead of passing like this.
      // Root state used like this won't have the latest state.
      groupId = getCurrentGroupId(state$, tab);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return;
    }
    dispatch(ChangeSelectedGroupId({ selectedGroupId: groupId, tab }));

    return groupId;
  }

  async function getBody(params: UploadSingleContentParams) {
    const body = (() => {
      switch (params.attachmentType) {
        case T.AttachmentType.BLUEPRINT_PDF: {
          const geoPoint: [T.GeoPoint, T.GeoPoint] = [center, [center[0] + 0.01, center[1]]];

          const groupId = changeSelectedGroupId({ tab: T.ContentPageTabType.OVERLAY });

          const blueprintPDFContent: Pick<T.BlueprintPDFContent, PostContentArguments> =
            defaultBlueprintPDF({ title: params.title!, geoPoint });
          return {
            ...blueprintPDFContent,
            groupId,
            color: blueprintPDFContent.color.toString(),
            info: JSON.stringify(blueprintPDFContent.info),
            appearAt: params.appearAt
              ? formatWithOffset(
                  state$.Pages.Common.timezoneOffset,
                  params.appearAt,
                  Formats.YYYY_MM_DD
                )
              : undefined,
          } satisfies UploadContentBody;
        }
        case T.AttachmentType.BLUEPRINT_DXF: {
          if (!params.coordinateSystem) {
            throw new Error('Coordinate system is required');
          }

          const groupId = changeSelectedGroupId({ tab: T.ContentPageTabType.OVERLAY });

          const defaultContent: Pick<T.BlueprintDXFContent, PostContentArguments> =
            defaultBlueprintDXF({
              title: params.title!,
              coordinateSystem: T.ProjectionEnum[params.coordinateSystem],
            });

          return {
            ...defaultContent,
            groupId,
            color: defaultContent.color.toString(),
            info: JSON.stringify(defaultContent.info),
            appearAt: params.appearAt
              ? formatWithOffset(
                  state$.Pages.Common.timezoneOffset,
                  params.appearAt,
                  Formats.YYYY_MM_DD
                )
              : undefined,
          } satisfies UploadContentBody;
        }
        case T.AttachmentType.BLUEPRINT_DWG: {
          if (!params.coordinateSystem) {
            throw new Error('Coordinate system is required');
          }

          const groupId = changeSelectedGroupId({ tab: T.ContentPageTabType.OVERLAY });

          const defaultContent: Pick<T.BlueprintDWGContent, PostContentArguments> =
            defaultBlueprintDWG({
              title: params.title!,
              coordinateSystem: T.ProjectionEnum[params.coordinateSystem],
            });

          return {
            ...defaultContent,
            groupId,
            color: defaultContent.color.toString(),
            info: JSON.stringify(defaultContent.info),
            appearAt: params.appearAt
              ? formatWithOffset(
                  state$.Pages.Common.timezoneOffset,
                  params.appearAt,
                  Formats.YYYY_MM_DD
                )
              : undefined,
          } satisfies UploadContentBody;
        }
        case T.AttachmentType.DESIGN_DXF: {
          if (!params.coordinateSystem) {
            throw new Error('Coordinate system is required');
          }

          const groupId = changeSelectedGroupId({ tab: T.ContentPageTabType.OVERLAY });

          const defaultContent = defaultDesignDXF({
            title: params.title!,
            coordinateSystem: T.ProjectionEnum[params.coordinateSystem],
          });
          return {
            ...defaultContent,
            groupId,
            color: defaultContent.color.toString(),
            info: JSON.stringify(defaultContent.info),
            appearAt: params.appearAt
              ? formatWithOffset(
                  state$.Pages.Common.timezoneOffset,
                  params.appearAt,
                  Formats.YYYY_MM_DD
                )
              : undefined,
          } satisfies UploadContentBody;
        }
        case T.AttachmentType.ORTHO: {
          const defaultMapContent: Pick<T.MapContent, PostContentArguments> = defaultMap();

          return {
            ...defaultMapContent,
            color: defaultMapContent.color.toString(),
            info: JSON.stringify(defaultMapContent.info),
          } satisfies UploadContentBody;
        }
        case T.AttachmentType.DSM: {
          const defaultDSMContent = defaultDSM();
          return {
            ...defaultDSMContent,
            color: defaultDSMContent.color.toString(),
            info: JSON.stringify(defaultDSMContent.info),
          } satisfies UploadContentBody;
        }
        case T.AttachmentType.POINTCLOUD: {
          const defaultPoincloudContent = defaultPointCloud();
          return {
            ...defaultPoincloudContent,
            color: defaultPoincloudContent.color.toString(),
            info: JSON.stringify(defaultPoincloudContent.info),
          } satisfies UploadContentBody;
        }
        case T.AttachmentType.BIM: {
          const groupId = changeSelectedGroupId({ tab: T.ContentPageTabType.OVERLAY });

          const defaultContent = defaultBim({
            title: params.title!,
            bimMeta: params.bimMeta,
          });

          return {
            ...defaultContent,
            groupId,
            color: defaultContent.color.toString(),
            info: JSON.stringify(defaultContent.info),
            appearAt: params.appearAt
              ? formatWithOffset(
                  state$.Pages.Common.timezoneOffset,
                  params.appearAt,
                  Formats.YYYY_MM_DD
                )
              : undefined,
          } satisfies UploadContentBody;
        }
        default: {
          throw new Error('Invalid content type');
        }
      }
    })();

    const newScreen = (async () => {
      switch (params.attachmentType) {
        case T.AttachmentType.DSM:
        case T.AttachmentType.ORTHO:
        case T.AttachmentType.POINTCLOUD:
        case T.AttachmentType.SOURCE:
          return createScreen({
            attachmentType: params.attachmentType,
            screen: params.screen!,
          });
        default:
          return params.screen;
      }
    })();

    return {
      ...body,
      screenId: (await newScreen)?.id ?? params.screen?.id,
    };
  }

  async function uploadSingleContent(params: UploadSingleContentParams) {
    if (projectId === undefined || authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }
    resetAttachmentUploadStatus();

    setIsUploading(true);

    const URL: string = makeV2APIURL('projects', projectId, 'contents');
    const body = await getBody(params);

    const response = await http.post<PostContentResponse>(URL, body, { headers });

    const content = APIToContent(response.data.data);
    const contentId = content.id;

    // contentsStore.getState().addContent(content);
    contentsStore.getState().changeUploadContent({
      id: contentId,
      type: params.attachmentType,
      file: [{ size: params.file.size, hash: calculateHash(params.file) }],
      uploadedAt: new Date(),
      status: T.APIStatus.PROGRESS,
    });

    dispatch(
      OpenContentPagePopup({
        popup: T.ContentPagePopupType.PROGRESS_BAR,
        contentId: content.id,
      })
    );

    await createAttachment({
      contentId,
      attachmentType: params.attachmentType,
      file: params.file,
    });

    contentsStore.getState().addContent(content);
    addContentToTree(content, T.MoveOption.FIRST);
    contentsStore.getState().finishUploadContent(contentId);

    setIsUploading(false);

    return content;
  }

  return { uploadSingleContent };
}
