import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import Text from './text';
import ChannelTalkSVG from '^/assets/icons/channeltalk.svg';
import { config } from '^/config';

import palette from '^/constants/palette';
import { Global } from '^/constants/zindex';
import {
  defaultToastInfoOption,
  generalErrorDescription,
  useL10n,
  UseL10n,
  UseState,
  useToast,
  UseToast,
} from '^/hooks';
import { useChannelIO } from '^/hooks/channelIO';
import { useAuthUserQuery } from '^/store/react-query/users';
import * as T from '^/types';
import { getDDMSubdomain } from '^/store/duck/router';

interface User {
  alert: number;
}

const Button = styled.button<{ isOpen: boolean }>(
  {
    zIndex: Global.GUIDE,
    position: 'fixed',
    bottom: '24px',
    right: '24px',
    width: '56px',
    height: '56px',
    background: 'transparent',
    borderRadius: '22px',
    cursor: 'pointer',
    boxShadow:
      'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.15) 0px 8px 30px',
    animation: '400ms cubic-bezier(0.36, 0, 0, 1)',

    ':active': {
      transform: 'scale(0.9, 0.9)',
    },
  },
  ({ isOpen }) => ({
    display: isOpen ? 'none' : 'block',
  })
);

const UnreadMark = styled.div({
  width: '14px',
  height: '14px',
  background: palette.guide.unreadMark.toString(),
  position: 'absolute',
  borderRadius: '50%',
  top: '-3px',
  right: '-3px',
});

const GUIDE_BUTTON_ID = 'channel-talk-btn';
const GUIDE_BUTTON_SELECTOR = `#${GUIDE_BUTTON_ID}`;

const GuideButton: FC = () => {
  const [, lang]: UseL10n = useL10n();
  const [isOpen, setOpen]: UseState<boolean> = useState(false);
  const [isExistUnread, setExistUnread]: UseState<boolean> = useState(false);
  const { service, boot } = useChannelIO();
  const toastify: UseToast = useToast();

  if (config.buildLevel !== T.BuildLevel.PRODUCTION) {
    return null;
  }

  // Custom Plan Feature for Grootech - Don't render Guide Button
  if (getDDMSubdomain()?.indexOf('groo') !== -1) {
    return null;
  }

  const { data: authedUser } = useAuthUserQuery();

  function getChannelOptions() {
    if (authedUser) {
      return {
        customLauncherSelector: GUIDE_BUTTON_SELECTOR,
        memberId: String(authedUser.id),
        profile: {
          name:
            lang === T.Language.KO_KR
              ? `${authedUser.lastName}${authedUser.firstName}`
              : `${authedUser.firstName} ${authedUser.lastName}`,
          mobileNumber: authedUser.contactNumber,
          email: authedUser.email,
          company: authedUser.organization,
        },
      };
    }
    return {
      customLauncherSelector: GUIDE_BUTTON_SELECTOR,
    };
  }

  function handleChannelBootError(error: Error | null, user: User | null) {
    if (error) {
      toastify({
        type: T.Toast.ERROR,
        content: {
          title: Text.error.title,
          description: generalErrorDescription,
        },
        option: defaultToastInfoOption,
      });
    } else {
      setExistUnread(Boolean(user?.alert));
    }
  }

  useEffect(() => {
    setOpen(false);

    boot(getChannelOptions(), handleChannelBootError);

    service.onShowMessenger(() => setOpen(true));
    service.onHideMessenger(() => setOpen(false));
    service.onBadgeChanged((unreadCount: number) => setExistUnread(unreadCount > 0));
  }, [authedUser]);

  return (
    <Button isOpen={isOpen} id={GUIDE_BUTTON_ID}>
      <ChannelTalkSVG />
      {isExistUnread && <UnreadMark />}
    </Button>
  );
};

export default GuideButton;
