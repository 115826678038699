import { contentsSelector } from '^/store/duck/Contents';
import { contentsStore } from '^/store/zustand/content/contentStore';
import * as T from '^/types';

export const getSingleContentId: (
  Pages: T.State['Pages'],
  ProjectConfig: T.State['ProjectConfigPerUser'],
  contentType: T.ContentType
) => T.Content['id'] | undefined = (Pages, ProjectConfig, contentType) => {
  const { byId, allIds } = contentsStore.getState().contents;
  const projectId: T.Project['id'] | undefined = Pages.Contents.projectId;
  const lastSelectedScreenId: T.Screen['id'] | undefined =
    ProjectConfig.config?.lastSelectedScreenId;

  if (projectId === undefined) {
    return;
  }
  if (lastSelectedScreenId === undefined) {
    return;
  }

  return allIds.find(contentId => {
    const content: T.Content = byId[contentId];

    if (content.screenId === undefined) {
      return;
    }

    return content.type === contentType && content.screenId === lastSelectedScreenId;
  });
};

/**
 * Returns either 3D mesh or 3D ortho id that isn't failing or processing.
 * 3D mesh takes precedence because it has a better quality than 3D ortho.
 */
export const getViewableThreeDContentId: (
  Pages: T.State['Pages'],
  ProjectConfig: T.State['ProjectConfigPerUser']
) => T.ThreeDMeshContent['id'] | T.ThreeDOrthoContent['id'] | undefined = (
  Pages,
  ProjectConfig
) => {
  const threeDMeshId: T.ThreeDMeshContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfig,
    T.ContentType.THREE_D_MESH
  );

  const threeDOrthoId: T.ThreeDMeshContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfig,
    T.ContentType.THREE_D_ORTHO
  );

  if (threeDMeshId && !contentsSelector.isProcessingOrFailed()(threeDMeshId)) {
    return threeDMeshId;
  }

  if (threeDOrthoId && !contentsSelector.isProcessingOrFailed()(threeDOrthoId)) {
    return threeDOrthoId;
  }

  return undefined;
};

export const getRole: (
  Projects: T.ProjectsState,
  Contents: T.ContentsPageState
) => T.PermissionRole = (Projects, Contents) => {
  const projectId: T.Project['id'] | undefined = Contents.projectId;
  const project: T.Project | undefined = projectId ? Projects.projects.byId[projectId] : undefined;

  return project ? project.permissionRole : T.PermissionRole.VIEWER;
};

export const getAllChildren = (
  rootIds: Array<T.Content['id']>,
  idsByGroup: Record<T.Content['id'], Array<T.Content['id']>>,
  contentsById: Record<T.Content['id'], T.Content>
): T.Content[] => {
  const childrenIds: Array<T.Content['id']> = [];

  const getChildren = (contentId: T.Content['id']): Array<T.Content['id']> => {
    const child = contentsById[contentId];
    if (!child) {
      return [];
    }

    const _childrenIds: Array<T.Content['id']> = [contentId];

    const isGroupType = child.type === T.ContentType.GROUP;

    if (isGroupType) {
      const groupChildren = idsByGroup[contentId] || [];
      const childIds: Array<T.Content['id']> = [];
      for (const childId of groupChildren) {
        const children = getChildren(childId);
        childIds.push(...children);
      }
      return _childrenIds.concat(childIds);
    } else {
      return _childrenIds;
    }
  };

  for (const rootId of rootIds) {
    const children = getChildren(rootId);
    childrenIds.push(...children);
  }
  return childrenIds.map(id => contentsById[id]);
};
