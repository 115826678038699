/* eslint-disable max-lines */
import React, {
  FC,
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';
import * as THREE from 'three';

import Text from '../ContentsListBlueprintItem/text';
import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import SingleSlider from '^/components/atoms/SingleSlider';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { DISABLED_CONTENT_OPACITY, FontFamily } from '^/constants/styles';
import { UseL10n, useL10n, useProjectCoordinateSystem } from '^/hooks';
import { PatchContent } from '^/store/duck/Contents';

import * as T from '^/types';
import { getEPSGfromProjectionLabel } from '^/utilities/coordinate-util';
import proj4 from 'proj4';
import { UNIT_SYMBOL, VALUES_PER_METER, determineUnitType } from '^/utilities/imperial-unit';
import { ELEVATION_FIX_FORMAT } from '^/constants/defaultContent';
import { convertToFixedNumber } from '^/utilities/react-util';
import { useThreeStore } from '^/components/three/ThreeStore';
import { round, debounce } from 'lodash-es';
import { useThreeModelStore } from '^/components/three/Lib/Store';
import { CustomBaseButton } from '^/components/atoms/Buttons';
import ReloadSvg from '^/assets/icons/contents-list/reload-small.svg';
import Tippy from '@tippyjs/react';
import { moveCameraToViewObject } from '^/components/three/utils';
import { Vector3 } from 'three';
import {
  generateIFCName,
  getIsTheSameLocation,
  UserDataType,
} from '^/components/three/Lib/Utils/GeneralUtils';
import { IFCObject } from '^/components/three/ThreeObjects/Model';

interface DisabledProp {
  isDisabled: boolean;
}
interface CustomStyleProps {
  readonly customStyle?: CSSObject;
}

type KindProps = 'position.x' | 'position.y' | 'position.z' | 'heading' | 'pitch' | 'roll';
interface BIMProps {
  title: string;
  fields: Array<{
    kind: KindProps;
    name: string;
    value?: number;
    type: string;
    min?: number;
    max?: number;
    step: number;
    unit: string;
    hasUnit?: boolean;
  }>;
}

const Opacity = styled.div({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  margin: '13px 0 ',
});

const OpacityText = styled.div<DisabledProp>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '48.5px',

  marginBottom: '13px',

  color: 'var(--color-theme-primary)',
  fontFamily: FontFamily.ROBOTO,
  fontSize: '15px',
  fontWeight: 500,
}));

const CustomModelInputContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  // gridColumnGap: '5px',
  border: '1px solid #D9D9D9',
  padding: '12px',
  borderRadius: '3px',
  gridRowGap: '8px',
  marginBottom: '13px',
});

const Wrapper = styled.div<CustomStyleProps>(
  {
    positive: 'relative',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const Label = styled.label<CustomStyleProps>(
  {
    display: 'block',
    minWidth: '68px',

    fontWeight: 400,
    color: dsPalette.typePrimary.toString(),
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const CMInputStyle: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '12px',
};

const CMLabelStyle: CSSObject = {
  fontSize: '12px',
  lineHeight: '15px',
  paddingRight: '4px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 0,
};
const CMInputContainer = styled.div({
  position: 'relative',
});

const BIMHighLightText = styled.span({
  fontSize: '12px',
  fontWeight: 600,
  color: '#CBCBCB',
});

const MINMAXWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const MINMAXText = styled(BIMHighLightText)({
  fontSize: '10px',
});

const CMInput = styled.input<{ error: boolean; width?: string; hasUnit?: boolean }>(
  ({ error, width, hasUnit }) => ({
    boxSizing: 'border-box',
    textAlign: 'right',

    width: width || '',
    height: '30px',
    padding: `0 ${hasUnit ? '33px' : '16px'} 0 10px`,

    border: '1px solid #D9D9D9',
    borderRadius: '3px',

    color: '#7E7E7E',

    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: '400',

    '::placeholder': {
      color: '#7E7E7E',
    },

    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'inner-spin-button',
      width: '5px',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      margin: 0,
    },

    borderColor: (error ? palette.error : palette.border).toString(),
  })
);

const UnitText = styled.span({
  position: 'absolute',
  right: '18px',
  top: '5px',
  color: '#7E7E7E',
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: '400',
});

const MAX_OPACITY: number = 100;
const MIN_RANGE: number = -180;
const MAX_RANGE: number = 180;
// const DELAY_TIMER: number = 500;
// const TO_METER_VALUE: number = 0.3048;

const InputPreview = styled.div<{ width?: string; hasUnit?: boolean }>(({ width, hasUnit }) => ({
  boxSizing: 'border-box',
  textAlign: 'right',

  width: width || '',
  height: '30px',
  padding: `2px ${hasUnit ? '34px' : '17px'} 0 10px`,
  color: '#7E7E7E',

  fontSize: '12px',
  lineHeight: '25px',
  fontWeight: '400',
}));

const InputPreviewContainer = styled.div({
  position: 'relative',
});

export interface Props {
  readonly content: T.BimContent;
}

const getBimMetaFromContent = (content: T.BimContent) => ({
  dimensions: content.info?.bimMeta?.dimensions || [0, 0, 0],
  heading: content.info?.bimMeta?.heading || 0,
  pitch: content.info?.bimMeta?.pitch || 0,
  roll: content.info?.bimMeta?.roll || 0,
  scale: content.info?.bimMeta?.scale || new Vector3(1, 1, 1),
  position: content.info?.bimMeta?.position || new Vector3(0, 0, 0),
});

export const RawThreeContentsListBimtItem: FC<Props> = ({ content }) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch: Dispatch = useDispatch();
  const scene = useThreeStore(s => s.scene);
  const runtime = useThreeStore(s => s.runtime);

  const selectedControlType = useThreeStore(s => s.selectedControlType);
  const contentId = content.id;
  const bimRef = useRef<THREE.Object3D>();

  const getBIM = () => {
    if (scene) {
      const obj = scene.getObjectByName(generateIFCName(contentId.toString()));
      bimRef.current = obj;
    }
  };

  const projectById = useSelector((s: T.State) => s.Projects.projects.byId);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);

  if (!projectUnit && !projectId) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }

  const project: T.Project | undefined = projectId ? projectById[projectId] : undefined;
  const unitType: T.ValidUnitType = project
    ? determineUnitType(project.unit)
    : determineUnitType(projectUnit);

  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();
  const isLonLat: boolean = projectProjection === T.ProjectionEnum.WGS84_EPSG_4326_LL;
  const isProcessingOrFailed: boolean = false; // contentsSelector.isProcessingOrFailedByContent(content);
  const isFailed: boolean = content.status === T.ContentProcessingStatus.FAILED;
  const [opacity, setOpacity] = useState(content.config?.opacity ?? MAX_OPACITY);
  const [bimMeta, setBimMeta] = useState(getBimMetaFromContent(content));
  const [showResetLocationButton, setShowResetLocationButton] = useState(false);
  const isLocked = useThreeModelStore(s => s.isLocked);

  useEffect(() => {
    if (!bimRef.current) {
      getBIM();
    }
    if (bimRef.current && bimMeta) {
      // Original data
      const userData = bimRef.current.userData as UserDataType;
      const isTheSameLocation = getIsTheSameLocation(userData, bimMeta);
      if (!isTheSameLocation) {
        setShowResetLocationButton(true);
      } else {
        setShowResetLocationButton(false);
      }
    }
  }, [bimMeta, bimRef, getBIM]);

  useEffect(() => {
    setBimMeta(getBimMetaFromContent(content));
  }, [content.info.bimMeta]);

  const handleOpacityChange: (opacity: number) => void = opacityValue => {
    if (!bimRef.current) {
      getBIM();
    }
    if (bimRef.current) {
      (bimRef.current as any).material.forEach((material: any) => {
        material.transparent = true;
        material.opacity = opacityValue;
      });
    }
    setOpacity(opacityValue);
  };

  // Memo is used to prevent creating a new debounce function when it is called
  const dispatchContentOnBimMetaChange = useMemo(
    () =>
      debounce((updatedBimMeta: T.BimMeta) => {
        dispatch(
          PatchContent({
            content: {
              id: contentId,
              info: {
                bimMeta: updatedBimMeta,
              },
            },
          })
        );
      }, 300),
    []
  );

  const updateopacity = useCallback(() => {
    dispatch(
      PatchContent({
        content: {
          id: contentId,
          config: {
            opacity: opacity,
          },
        },
      })
    );
  }, [opacity, bimMeta, contentId, dispatch]);

  const opacitySetting: ReactNode = isFailed ? null : (
    <Opacity>
      <OpacityText isDisabled={isProcessingOrFailed}>{opacity.toFixed(0)}%</OpacityText>
      <SingleSlider
        minValue={0}
        maxValue={MAX_OPACITY}
        value={opacity}
        isDisabled={true}
        onChange={handleOpacityChange}
        onMouseUp={updateopacity}
      />
    </Opacity>
  );

  const getLatLongFromCartesian = (x: number, y: number, z: number) => {
    const [long, lat, height] = proj4(
      getEPSGfromProjectionLabel(projectProjection),
      'EPSG:4326'
    ).forward([x, y, z]);
    return { lat, long, height };
  };

  const getCartesianFromLatLong = (long: number, lat: number, z: number) => {
    const [x, y] = proj4(getEPSGfromProjectionLabel(projectProjection), [long, lat]);
    return { x, y, z };
  };

  const handleRotationChange: (kind: KindProps, value: number) => void = (kind, value) => {
    if (!bimRef.current) {
      getBIM();
    }
    switch (kind) {
      case 'heading':
        setBimMeta(prev => {
          dispatchContentOnBimMetaChange({ ...prev, heading: value });
          return { ...prev, heading: value };
        });
        break;
      case 'pitch':
        setBimMeta(prev => {
          dispatchContentOnBimMetaChange({ ...prev, pitch: value });
          return { ...prev, pitch: value };
        });
        break;
      case 'roll':
        setBimMeta(prev => {
          dispatchContentOnBimMetaChange({ ...prev, roll: value });
          return { ...prev, roll: value };
        });
        break;
      default:
        break;
    }
    if (bimRef.current && bimRef.current instanceof IFCObject) {
      bimRef.current.culler?.setModelTransformation(
        bimRef.current.ifcModel!.uuid,
        bimRef.current.matrixWorld
      );
    }
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>, kind: KindProps) => {
    if (!kind) {
      return;
    }
    const value = Number(event.currentTarget.value);
    if (!Number.isFinite(value)) {
      return;
    }
    if (!bimRef.current) {
      getBIM();
    }
    setBimMeta(prev => {
      const cartesianPos = getCartesianFromLatLong(
        prev.position.x,
        prev.position.y,
        prev.position.z
      );
      switch (kind) {
        case 'position.x':
          cartesianPos.x = value;
          break;
        case 'position.y':
          cartesianPos.y = value;
          break;
        case 'position.z':
          cartesianPos.z = value;
          break;
        case 'heading':
        case 'pitch':
        case 'roll':
          handleRotationChange(kind, value);
          return prev;
        default:
          break;
      }
      const geoPos = getLatLongFromCartesian(cartesianPos.x, cartesianPos.y, cartesianPos.z);
      prev.position = new Vector3(geoPos.long, geoPos.lat, geoPos.height);
      dispatchContentOnBimMetaChange({ ...prev });
      if (bimRef.current && bimRef.current instanceof IFCObject) {
        bimRef.current.culler?.setModelTransformation(
          bimRef.current.ifcModel!.uuid,
          bimRef.current.matrixWorld
        );
      }
      return { ...prev };
    });
  };

  const onResetLocationClickHandler = async () => {
    if (!bimRef.current) {
      getBIM();
    }
    if (bimRef.current && runtime) {
      const userData = bimRef.current.userData;
      const { latitude: y, longitude: x, elevation: z, pitch, roll, heading } = userData;
      const position = { x, y, z } as Vector3;
      setBimMeta(prev => ({ ...prev, position }));
      dispatchContentOnBimMetaChange({
        ...bimMeta,
        roll,
        pitch,
        heading,
        position: position,
      });
      const parentMesh = bimRef.current.parent as IFCObject;
      const pos = runtime.getPositionFromLatLongHeight({
        long: position.x,
        lat: position.y,
        height: position.z,
      });
      parentMesh.position.set(pos.x, pos.y, pos.z);
      moveCameraToViewObject((parentMesh as any).contentId);
    }
  };
  const bimSettings: ReactNode = useMemo(() => {
    try {
      const cartesianPos = getCartesianFromLatLong(
        bimMeta.position.x,
        bimMeta.position.y,
        bimMeta.position.z
      );

      const bimField: BIMProps[] = [
        {
          title: 'Position',
          fields: [
            {
              name: `X (${isLonLat ? 'Lon' : 'Easting'})`,
              value: round(cartesianPos.x, 2),
              kind: 'position.x',
              type: 'number',
              step: 1,
              unit: '',
            },
            {
              name: `Y (${isLonLat ? 'Lat' : 'Northing'})`,
              value: round(cartesianPos.y, 2),
              kind: 'position.y',
              type: 'number',
              step: 1,
              unit: '',
            },
            {
              name: 'Z (Altitude)',
              value: convertToFixedNumber(
                (cartesianPos.z * VALUES_PER_METER[unitType]).toFixed(ELEVATION_FIX_FORMAT)
              ),
              kind: 'position.z',
              type: 'number',
              step: 1,
              unit: UNIT_SYMBOL[unitType],
              hasUnit: true,
            },
          ],
        },
        {
          title: 'Rotation',
          fields: [
            {
              name: 'Heading',
              value: Math.floor(bimMeta.heading),
              type: 'range',
              kind: 'heading',
              min: MIN_RANGE,
              max: MAX_RANGE,
              step: 1,
              unit: '°',
              hasUnit: true,
            },
            {
              name: 'Pitch',
              value: Math.floor(bimMeta.pitch),
              type: 'range',
              kind: 'pitch',
              min: MIN_RANGE,
              max: MAX_RANGE,
              step: 1,
              unit: '°',
              hasUnit: true,
            },
            {
              name: 'Roll',
              value: Math.floor(bimMeta.roll),
              type: 'range',
              kind: 'roll',
              min: MIN_RANGE,
              max: MAX_RANGE,
              step: 1,
              unit: '°',
              hasUnit: true,
            },
          ],
        },
      ];
      return bimField.map(data => (
        <CustomModelInputContainer key={data.title}>
          <BIMHighLightText>{data.title}</BIMHighLightText>
          {data.fields.map((field, idx) => (
            <div key={idx}>
              <Wrapper customStyle={CMInputStyle}>
                <Label customStyle={CMLabelStyle}>{field.name}</Label>
                {
                  // selectedControlType !== 'lock' && selectedControlType !== 'free_move'
                  !isLocked ? (
                    <CMInputContainer>
                      <CMInput
                        key={field.value}
                        width={field.type === 'range' ? '69px' : '156px'}
                        error={false}
                        hasUnit={field?.hasUnit || false}
                        defaultValue={field.value}
                        type="number"
                        step={field.step}
                        min={field?.min}
                        max={field?.max}
                        onKeyDown={event => {
                          event.stopPropagation();
                        }}
                        onChange={event => {
                          handleInputChange(event, field.kind);
                        }}
                        // disabled={isLocked}
                      />
                      <UnitText>{field.unit}</UnitText>
                    </CMInputContainer>
                  ) : (
                    <InputPreviewContainer>
                      <InputPreview
                        width={field.type === 'range' ? '69px' : '156px'}
                        hasUnit={field?.hasUnit || false}
                      >
                        {field.value}
                      </InputPreview>
                      <UnitText>{field.unit}</UnitText>
                    </InputPreviewContainer>
                  )
                }
              </Wrapper>
              {field.type === 'range' ? (
                <>
                  <div style={{ padding: '9px 0 5px' }}>
                    <SingleSlider
                      minValue={field.min ?? 0}
                      maxValue={field.max ?? 0}
                      value={field.value ?? 0}
                      isDisabled={
                        isLocked || isProcessingOrFailed
                        // ||
                        // selectedControlType === 'lock' ||
                        // selectedControlType === 'free_move'
                      }
                      onChange={value => handleRotationChange(field.kind, value)}
                      // onMouseUp={updateData}
                    />
                  </div>
                  <MINMAXWrapper>
                    <MINMAXText>{field.min}°</MINMAXText>
                    <MINMAXText>{field.max}°</MINMAXText>
                  </MINMAXWrapper>
                </>
              ) : null}
            </div>
          ))}
        </CustomModelInputContainer>
      ));
    } catch {
      return null;
    }
  }, [bimMeta, selectedControlType, isLocked]);

  const firstBalloonTitle: string = (() => l10n(Text.firstBalloonTitle))();

  return (
    <ContentsListItem content={content} firstBalloonTitle={firstBalloonTitle}>
      {opacitySetting}
      {bimSettings}
      {showResetLocationButton && (
        <Tippy arrow={false} theme="angelsw" content={l10n(Text.originalPositionTooltip)}>
          <CustomBaseButton
            isSelected={true}
            disabled={isLocked}
            onClick={onResetLocationClickHandler}
          >
            <ReloadSvg />
            {l10n(Text.originalPosition)}
          </CustomBaseButton>
        </Tippy>
      )}
    </ContentsListItem>
  );
};
