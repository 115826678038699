import React, { FC } from 'react';
import styled from 'styled-components';
import * as T from '^/types';

import { PhotoListItem } from '../PhotoList/style';
import InspectionPhoto from '../PhotoAlbum/Inspection-photo.png';
import SourcePhoto from '../PhotoAlbum/Source-photo.png';
import InspectionIconSvg from '../PhotoAlbum/inspection-icon.svg';
import SourceIconSvg from '../PhotoAlbum/source-icon.svg';
import Photo360PanoSvg from '^/assets/icons/photo/photo-360-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';

interface PhotoListItemProps {
  photoAlbum: T.PhotoAlbum;
}

const InspectionWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const InspectionContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 10,
});

const InspectionTile = styled.div({
  color: 'white',
});

const InspectionSubTile = styled.div({
  fontSize: 12,
  color: 'white',
});

const PhotoListItemComponent: FC<PhotoListItemProps> = ({ photoAlbum }) => {
  const isPhotoAlbumNewFeaturesEnabled = useFlag(T.FEATURE_FLAGS.PHOTO_ALBUM_NEW_FEATURES);
  const photoType = photoAlbum?.type;
  const navigate = useNavigate();
  const photoCover =
    photoType === T.PhotoAlbumFilterTypes.INSPECTION ? InspectionPhoto : SourcePhoto;
  const isPhotoModeThreeSixty =
    photoType === T.PhotoAlbumFilterTypes.THREE_SIXTY ||
    photoType === T.PhotoAlbumFilterTypes.THREE_SIXTY_SOURCE ||
    photoType === T.PhotoAlbumFilterTypes.THREE_SIXTY_STITCHED ||
    photoType === T.PhotoAlbumFilterTypes.THREE_SIXTY_VSLAM;

  const title: Record<T.PhotoAlbumFilterTypes, string> = {
    [T.PhotoAlbumFilterTypes.INSPECTION]: 'Inspection Photo',
    [T.PhotoAlbumFilterTypes.THREE_SIXTY]: '360 Photos',
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_SOURCE]: '360 Raw Source Photos',
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_STITCHED]: '360 Stitched Photos',
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_VIDEO]: '360 VSLAM Videos',
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_VSLAM]: '360 Vslam Photos',
    [T.PhotoAlbumFilterTypes.SOURCE]: 'Source Photo',
    [T.PhotoAlbumFilterTypes.BIRD_EYE_VIEW]: 'Bird Eye Photo',
    [T.PhotoAlbumFilterTypes.VIEWPOINT]: 'Request Photo',
    [T.PhotoAlbumFilterTypes.ALL]: 'ALL',
  };

  const photoIcon: Record<T.PhotoAlbumFilterTypes, JSX.Element> = {
    [T.PhotoAlbumFilterTypes.INSPECTION]: <InspectionIconSvg />,
    [T.PhotoAlbumFilterTypes.THREE_SIXTY]: <Photo360PanoSvg />,
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_SOURCE]: <SourceIconSvg />,
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_STITCHED]: <Photo360PanoSvg />,
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_VIDEO]: <Photo360PanoSvg />,
    [T.PhotoAlbumFilterTypes.THREE_SIXTY_VSLAM]: <Photo360PanoSvg />,
    [T.PhotoAlbumFilterTypes.SOURCE]: <SourceIconSvg />,
    [T.PhotoAlbumFilterTypes.BIRD_EYE_VIEW]: <SourceIconSvg />,
    [T.PhotoAlbumFilterTypes.VIEWPOINT]: <SourceIconSvg />,
    [T.PhotoAlbumFilterTypes.ALL]: <></>,
  };

  const onOpenInspection = () => {
    navigate(`${photoAlbum.photoType}/${photoAlbum.takenAt?.toISOString().split('T')[0]}`);
  };

  // handle feature flags here
  if (photoType === T.PhotoAlbumFilterTypes.VIEWPOINT && !isPhotoAlbumNewFeaturesEnabled) {
    return null;
  }

  return (
    <>
      <PhotoListItem
        isSelected={false}
        url={photoCover}
        isVisible={true}
        onClick={onOpenInspection}
        key={photoAlbum.id}
        data-ddm-track-action={
          isPhotoModeThreeSixty ? T.TrackActions.THREESIXTY_PHOTO_ALBUM : T.TrackActions.PHOTO_ALBUM
        }
        data-ddm-track-label={`${T.TrackLabels.BTN_FOLDER_PHOTO_ALBUM}-${photoAlbum.type}`}
      >
        <InspectionWrapper>
          <InspectionContent>
            {photoIcon[photoType]}
            <InspectionTile>{photoType && title[photoType]}</InspectionTile>
            {<InspectionSubTile>{photoAlbum.title}</InspectionSubTile>}
            <InspectionSubTile>{photoAlbum.photosCount} Photos</InspectionSubTile>
          </InspectionContent>
        </InspectionWrapper>
      </PhotoListItem>
    </>
  );
};

export default PhotoListItemComponent;
