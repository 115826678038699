import React, { FC, ComponentType, PropsWithChildren } from 'react';
import { create } from 'zustand';
import * as THREE from 'three';
import CameraControls from 'camera-controls';
import { without } from 'lodash-es';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

import * as T from '^/types';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { CADOverlay } from '../ThreeTiles';
import { Viewer } from '../ThreeInteraction/Viewer';
import { Runtime } from 'three-loader-3dtiles';
import { CustomEffectsPass } from '^/components/organisms/ThreeESSDisplay/PostRenderer/custom-effects-pass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';

CameraControls.install({ THREE: THREE });

export interface IFCLoadedProps {
  contentId: string | number;
  fileSize: number;
  show: boolean;
  showModal: boolean;
}

export type GizmoMode = 'free_move' | 'translate' | 'rotate' | 'scale' | 'lock';
type loadingMessageType = 'downloading' | 'loading' | '';

export interface ThreeInitialState {
  viewerDiv: null | HTMLElement;
  scene: THREE.Scene | null;
  viewer: null | Viewer;
  runtime: Runtime | null;
  renderer: THREE.WebGLRenderer | null;
  map: THREE.Object3D<THREE.Event> | null;
  orthophoto: CADOverlay | null;
  isOnSharePage: boolean;
  IFCObjects: THREE.Object3D[];
  depthTestAgainstTerrain: boolean;
  selectedControlType: GizmoMode;
  ifcModelLoadedList: T.BimContent[];
  ifcLoadingMessage: loadingMessageType;
  tileset: THREE.Object3D<THREE.Event> | null;
  hoverLengthLocation: T.GeoPoint | undefined;
  cameraControls: CameraControls | null;
  camera: THREE.PerspectiveCamera | null;
  transformControls: TransformControls | null;
  isTilesetReady: boolean;
  boxHelper: THREE.BoxHelper | null;
  draggingObject: THREE.Object3D<THREE.Event> | null;
  ifcLoadedContent: IFCLoadedProps[];
  largeFileContent: IFCLoadedProps | null;
  // shading and line effects
  composer: EffectComposer | null;
  effectFXAA: ShaderPass | null;
  customEffect: CustomEffectsPass | null;
  isInPresentationMode?: boolean;
  isRenderable: boolean;
}

const initialState: ThreeInitialState = {
  hoverLengthLocation: undefined,
  cameraControls: null,
  viewer: null,
  runtime: null,
  scene: null,
  viewerDiv: null,
  transformControls: null,
  isTilesetReady: false,
  map: null,
  orthophoto: null,
  isOnSharePage: false,
  boxHelper: null,
  IFCObjects: [],
  draggingObject: null,
  ifcLoadedContent: [],
  largeFileContent: null,
  effectFXAA: new ShaderPass(FXAAShader),
  composer: null,
  customEffect: null,
  depthTestAgainstTerrain: false,
  selectedControlType: 'free_move',
  ifcModelLoadedList: [],
  ifcLoadingMessage: '',
  camera: null,
  renderer: null,
  tileset: null,
  isRenderable: false,
};
export interface ThreeStateActions {
  setBIMLoadedContent(ifcContent: T.BimContent): void;
  setBIMRemoveContent(ifcContent: T.BimContent): void;
  setBIMLoadedMessage(ifcLoadingMessage: loadingMessageType): void;
  setViewerSceneAndCamera(viewer: Viewer): void;
  zoomIn(): void;
  zoomOut(): void;
  mapToCenter3DMesh(): void;
  setTransformControls(): void;
  setTilesetAndRuntime(tileset: THREE.Object3D<THREE.Event>, runtime: Runtime): void;
  setIsTilesetReady(isTilesetReady: boolean): void;
  setHoverLengthLocation(point: T.GeoPoint | undefined): void;
  setOrthophoto(orthophoto: CADOverlay | null): void;
  setIsOnSharePage(isOnSharePage: boolean): void;
  addToIFCObject(obj: THREE.Object3D): void;
  removeFromIFCObject(obj: THREE.Object3D): void;
  setDraggingObject(obj: THREE.Object3D<THREE.Event> | null): void;
  setifcLoadedContent(ifcLoadedContent: IFCLoadedProps[]): void;
  setLargeFileContent(largeFileContent: IFCLoadedProps | null): void;
  setCustomEffect(customEffect: CustomEffectsPass): void;
  setDepthTestAgainstTerrain(depthTestAgainstTerrain: boolean): void;
  setSelectedControlType(selectedControlType: GizmoMode): void;
  setIsRenderable(isRenderable: boolean): void;
  resetAllThreejs(): void;
}

export const fov: number = 50;
export const near: number = 0.1;
export const far: number = 50000000000;

export type ThreeStore = ThreeInitialState & ThreeStateActions;

export const useThreeStore = create<ThreeStore>()((set, get) => ({
  ...initialState,
  setIsRenderable(isRenderable) {
    set({ isRenderable });
  },
  resetAllThreejs() {
    set(initialState);
  },
  setCustomEffect(customEffect) {
    set({ customEffect });
  },
  setViewerSceneAndCamera: (viewer: Viewer) => {
    if (!viewer) {
      return;
    }
    const scene = viewer.scene;
    const cameraControls = viewer.cameraControls;
    const camera = viewer.camera;
    const renderer = viewer.renderer;

    set({ viewer, scene, camera, cameraControls, renderer });
  },

  setBoxHelper(boxHelper: THREE.BoxHelper) {
    set({ boxHelper });
  },
  zoomIn() {
    const { cameraControls } = get();
    void cameraControls?.dolly(30);
  },
  zoomOut() {
    const { cameraControls } = get();
    void cameraControls?.dolly(-30);
  },
  mapToCenter3DMesh() {
    const { cameraControls, tileset } = get();

    if (tileset?.children.length) {
      void cameraControls?.rotateTo(0, 0, true);
      void cameraControls?.fitToBox(tileset, true, {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
      });
    }
  },
  setTilesetAndRuntime(tileset, runtime) {
    set({ runtime, tileset });
  },
  setIsTilesetReady(isTilesetReady) {
    set({ isTilesetReady });
  },
  setMap(map: THREE.Object3D<THREE.Event>) {
    set({ map });
  },
  setBIMLoadedContent(ifcContent) {
    const { ifcModelLoadedList } = get();
    set({
      ifcModelLoadedList: [...ifcModelLoadedList, ifcContent],
    });
  },
  setBIMLoadedMessage(ifcLoadingMessage) {
    set({
      ifcLoadingMessage,
    });
  },
  setBIMRemoveContent(ifcContent) {
    const { ifcModelLoadedList } = get();
    set({
      ifcModelLoadedList: ifcModelLoadedList.filter(content => content.id !== ifcContent.id),
    });
  },
  setTransformControls() {
    const { viewer } = get();
    if (!viewer) {
      return;
    }
    const { camera, renderer } = viewer;

    set({ transformControls: new TransformControls(camera, renderer.domElement) });
  },
  setHoverLengthLocation(hoverLengthLocation) {
    set({ hoverLengthLocation });
  },
  setOrthophoto(orthophoto) {
    set({ orthophoto });
  },
  setIsOnSharePage(isOnSharePage) {
    set({ isOnSharePage });
  },
  setifcLoadedContent(ifcLoadedContent: IFCLoadedProps[]) {
    set({ ifcLoadedContent });
  },

  setLargeFileContent(largeFileContent: IFCLoadedProps | null) {
    set({ largeFileContent });
  },
  addToIFCObject(obj) {
    set(({ IFCObjects }) => ({
      IFCObjects: [...IFCObjects, obj],
    }));
  },
  // set function temp save
  removeFromIFCObject(obj) {
    set(({ IFCObjects }) => ({
      IFCObjects: without(IFCObjects, obj),
    }));
  },
  setDraggingObject(draggingObject) {
    set({ draggingObject });
  },
  setDepthTestAgainstTerrain(depthTestAgainstTerrain) {
    set({ depthTestAgainstTerrain });
  },
  setSelectedControlType(selectedControlType) {
    set({ selectedControlType });
  },
}));

export function withThreeStore<T>(TargetComponent: ComponentType<T & ThreeStore>): FC<T> {
  return (subProps: PropsWithChildren<T>) => {
    const threeStore = useThreeStore();
    return <TargetComponent {...subProps} {...threeStore} />;
  };
}
