import * as T from '^/types';

export default {
  error: {
    [T.Language.KO_KR]: [
      '이미 사용하고 있는 데이터세트 이름입니다.',
      '다른 이름으로 수정해 주세요.',
    ],
    [T.Language.EN_US]: [
      'The Dataset name already exists.',
      'Please use a different Dataset name.',
    ],
  },
};
