import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface PermissionStore {
  searchText: string;
  setSearchText(text: string): void;
}
export const usePermissionStore = create<PermissionStore>()(
  devtools(set => ({
    searchText: '',
    setSearchText: (searchText: string) => {
      set({ searchText: searchText.trimStart() });
    },
  }))
);
