import * as T from '^/types';

// Redux reducer
export const initialGroupState: T.GroupsState = {
  selectedGroupIdByTab: {
    [T.ContentPageTabType.OVERLAY]: undefined,
    [T.ContentPageTabType.MEASUREMENT]: undefined,
    [T.ContentPageTabType.ESS]: undefined,
    [T.ContentPageTabType.MAP]: undefined,
    [T.ContentPageTabType.PHOTO]: undefined,
    [T.ContentPageTabType.ISSUE]: undefined,
    [T.ContentPageTabType.DASHBOARD]: undefined,
    [T.ContentPageTabType.DRONE_STATION]: undefined,
    [T.ContentPageTabType.PRESENTATION]: undefined,
    [T.ContentPageTabType.FLIGHT_PLAN]: undefined,
    [T.ContentPageTabType.FLIGHT_SCHEDULE]: undefined,
    [T.ContentPageTabType.VIEWPOINT_CAPTURE]: undefined,
  },
  isCreatingNewGroup: false,
  isGroupAlreadyDeleted: false,
  isCreatingContentOnDeletedGroup: false,
  tree: {
    idsByGroup: {},
    rootIdsByCategory: {
      [T.ContentCategory.OVERLAY]: { pinned: [], unpinned: {} },
      [T.ContentCategory.MEASUREMENT]: { pinned: [], unpinned: {} },
      [T.ContentCategory.ESS]: { pinned: [], unpinned: {} },
      [T.ContentCategory.MAP]: { pinned: [], unpinned: {} },
      [T.ContentCategory.METADATA]: { pinned: [], unpinned: {} },
      [T.ContentCategory.ISSUE]: { pinned: [], unpinned: {} },
      [T.ContentCategory.TERRAIN]: { pinned: [], unpinned: {} },
      [T.ContentCategory.DASHBOARD]: { pinned: [], unpinned: {} },
      [T.ContentCategory.DRONE_STATION]: { pinned: [], unpinned: {} },
      [T.ContentCategory.FLIGHT_PLAN]: { pinned: [], unpinned: {} },
      [T.ContentCategory.PRESENTATION]: { pinned: [], unpinned: {} },
      [T.ContentCategory.FLIGHT_SCHEDULE]: { pinned: [], unpinned: {} },
      [T.ContentCategory.VIEWPOINT_CAPTURE]: { pinned: [], unpinned: {} },
    },
    rootIdsBySpace: {
      [T.ContentCategory.OVERLAY]: { personal: [], open: [] },
      [T.ContentCategory.MEASUREMENT]: { personal: [], open: [] },
      [T.ContentCategory.ESS]: { personal: [], open: [] },
      [T.ContentCategory.MAP]: { personal: [], open: [] },
      [T.ContentCategory.METADATA]: { personal: [], open: [] },
      [T.ContentCategory.ISSUE]: { personal: [], open: [] },
      [T.ContentCategory.TERRAIN]: { personal: [], open: [] },
      [T.ContentCategory.DASHBOARD]: { personal: [], open: [] },
      [T.ContentCategory.DRONE_STATION]: { personal: [], open: [] },
      [T.ContentCategory.PRESENTATION]: { personal: [], open: [] },
      [T.ContentCategory.FLIGHT_PLAN]: { personal: [], open: [] },
      [T.ContentCategory.FLIGHT_SCHEDULE]: { personal: [], open: [] },
      [T.ContentCategory.VIEWPOINT_CAPTURE]: { personal: [], open: [] },
    },
  },
  moveContentStatus: T.APIStatus.IDLE,
};
