import React from 'react';
import styled from 'styled-components';

import ToggleSlider from '^/components/atoms/ToggleSlider';
import { UseL10n, useL10n } from '^/hooks';
import Text from './text';
import { TrackActions, TrackLabels } from '^/types';

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  fontSize: '12px',
  fontWeight: '500',
});

const TitleWrapper = styled.div({});

interface Props {
  isEnabled: boolean;
  onClick(): void;
}

function HillshadeMultiplyToggle({ isEnabled, onClick }: Props) {
  const [l10n]: UseL10n = useL10n();
  return (
    <Root
      data-ddm-track-action={TrackActions.CONTENT_ITEM}
      data-ddm-track-label={TrackLabels.BTN_TOGGLE_HILLSHADE}
    >
      <TitleWrapper>{l10n(Text.hillshadeMultiplyToggleText)}</TitleWrapper>
      <ToggleSlider
        trackAction={TrackActions.CONTENT_ITEM}
        trackLabel={TrackLabels.BTN_TOGGLE_HILLSHADE}
        enabled={isEnabled}
        onClick={onClick}
      />
    </Root>
  );
}

export default HillshadeMultiplyToggle;
