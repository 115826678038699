import * as T from '^/types';

export default {
  status: {
    'to-do': {
      [T.Language.KO_KR]: '할 일',
      [T.Language.EN_US]: 'To Do',
    },
    'in progress': {
      [T.Language.KO_KR]: '진행 중',
      [T.Language.EN_US]: 'In Progress',
    },
    done: {
      [T.Language.KO_KR]: '완료',
      [T.Language.EN_US]: 'Done',
    },
  },
};
