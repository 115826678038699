import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
// import dsPalette from '^/constants/ds-palette';
export interface Props {
  content: T.FlightScheduleContent;
}

const Header = styled.div({
  width: '100%',

  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),

  position: 'relative',

  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const ContentSummaryWrapper = styled.div({
  width: '100%',
  marginBottom: '-8px',
  paddingTop: '10px',
});

// const ButtonWrapper = styled.div({
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   gap: '2px',
//   justifyContent: 'center',
// });

// const Button = styled.button({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',

//   height: '24px',
//   width: '100%',
//   padding: '0 10px',

//   borderRadius: '4px',

//   fontSize: '12px',
//   fontWeight: '500',
//   cursor: 'pointer',
// });

// const StartResumeButton = styled(Button)(() => ({
//   border: '1px solid #3669B5',
//   backgroundColor: dsPalette.white.toString(),

//   color: `${dsPalette.themePrimary.toString()}`,
// }));
// const StopButton = styled(Button)(() => ({
//   border: '1px solid #3669B5',
//   backgroundColor: dsPalette.white.toString(),

//   color: `${dsPalette.themePrimary.toString()}`,
// }));
// const CancelButton = styled(Button)(() => ({
//   border: '1px solid #b5364f',
//   backgroundColor: dsPalette.white.toString(),

//   color: `#b5364f`,
// }));

export const ContentsWrapper = styled.div({
  border: '1px solid #D9D9D9',
  borderRadius: '3px',
  padding: '12px',
  gridRowGap: '8px',
  marginBottom: '13px',
});

export const HighlightedTitle = styled.p({
  fontWeight: 600,
  color: '#a4a3a3',
  paddingBottom: '15px',
});

export const ListItemsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  fontSize: '12.5px',
});

export const ItemWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Label = styled.p({});

export const Value = styled.p({});

export const getYearDateMonth = (providedDateTime: Date): Record<string, number> => ({
  fullYear: providedDateTime ? providedDateTime.getFullYear() : 0,
  monthIndex: providedDateTime ? providedDateTime.getMonth() : 0,
  weekdayIndex: providedDateTime ? providedDateTime.getDay() : 0,
  date: providedDateTime ? providedDateTime.getDate() : 0,
});

export const getYearDateMonthInReadableFormat = (
  providedDate: Record<string, number>,
  language: string
) => {
  switch (language) {
    case T.Language.KO_KR:
      return `${providedDate.fullYear}${Text.year[T.Language.KO_KR]} ${
        Text.month[T.Language.KO_KR][providedDate.monthIndex]
      } ${providedDate.date}${Text.date[T.Language.KO_KR]} ${
        Text.weekday[T.Language.KO_KR][providedDate.weekdayIndex]
      }`;
    case T.Language.EN_US:
      return `${Text.weekday[T.Language.EN_US][providedDate.weekdayIndex]}, ${
        Text.month[T.Language.EN_US][providedDate.monthIndex]
      } ${providedDate.date}, ${providedDate.fullYear}`;
    default:
      return '';
  }
};

const RawContentsListFlightScheduleItem = ({ content }: Props) => {
  const [l10n, language]: UseL10n = useL10n();
  const beginDateInFormat = new Date(content.info.beginTime);
  const beginDateInYrMonthDayFormat = getYearDateMonth(beginDateInFormat);
  const beginDateInYrMonthDayReadableFormat = getYearDateMonthInReadableFormat(
    beginDateInYrMonthDayFormat,
    language
  );

  const endDateInYrMonthDayFormat = getYearDateMonth(beginDateInFormat);
  const endDateInYrMonthDayReadableFormat = getYearDateMonthInReadableFormat(
    endDateInYrMonthDayFormat,
    language
  );

  // const handleStartResumeClick = () => {};
  // const handleStopClick = () => {};
  // const handleCancelClick = () => {};

  const getScheduleType = useCallback((currentValue: number) => {
    switch (currentValue) {
      case T.FlightScheduleScheduleType.TIMED:
        return 'Timed';
      case T.FlightScheduleScheduleType.CONTINUOUS:
        return 'Continuous';
      case T.FlightScheduleScheduleType.IMMEDIATE:
      default:
        return 'Immediate';
    }
  }, []);

  return (
    <ContentsListItem className={`${CANCELLABLE_CLASS_NAME}`} content={content} isPinned={true}>
      <Header>
        <ContentSummaryWrapper>
          <ContentsWrapper>
            <HighlightedTitle>{l10n(Text.scheduleDetails)}</HighlightedTitle>

            <ListItemsWrapper>
              <ItemWrapper>
                <Label>{l10n(Text.fileName)}</Label>
                <Value>{content.title}</Value>
              </ItemWrapper>
              <ItemWrapper>
                <Label>{l10n(Text.scheduleType)}</Label>
                <Value>{getScheduleType(content.info.taskType)}</Value>
              </ItemWrapper>
              <ItemWrapper>
                <Label>{l10n(Text.rthAltitude)}</Label>
                <Value>{content.info.rthAltitude}</Value>
              </ItemWrapper>
              <ItemWrapper>
                <Label>{l10n(Text.beginTime)}</Label>
                <Value>{beginDateInYrMonthDayReadableFormat}</Value>
              </ItemWrapper>
              <ItemWrapper>
                <Label>{l10n(Text.endTime)}</Label>
                <Value>{endDateInYrMonthDayReadableFormat}</Value>
              </ItemWrapper>
            </ListItemsWrapper>
          </ContentsWrapper>
          {/* <ContentsWrapper>
            <HighlightedTitle>{l10n(Text.taskActions)}</HighlightedTitle>

            <ButtonWrapper>
              <StartResumeButton onClick={handleStartResumeClick}>
                {l10n(Text.buttons.startResume)}
              </StartResumeButton>
              <StopButton onClick={handleStopClick}>{l10n(Text.buttons.stop)}</StopButton>
              <CancelButton onClick={handleCancelClick}>{l10n(Text.buttons.cancel)}</CancelButton>
            </ButtonWrapper>
          </ContentsWrapper> */}
        </ContentSummaryWrapper>
      </Header>
    </ContentsListItem>
  );
};

export const ContentsListFlightScheduleItem: FC<Props> = withErrorBoundary(
  RawContentsListFlightScheduleItem
)(Fallback);
