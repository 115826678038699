/* eslint-disable max-lines */
import {
  BufferGeometry,
  DoubleSide,
  Group,
  Mesh,
  OrthographicCamera,
  PerspectiveCamera,
  PlaneGeometry,
  Raycaster,
  ShaderMaterial,
  Vector3,
} from 'three';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import reactToCSS from 'react-style-object-to-css';
import { DisposeHelper } from '../../Lib/Helper';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { autobind } from 'core-decorators';
import { LabelUtils, MathUtils } from '../../Lib/Utils';

const vertexShader = (transform: boolean): string | undefined =>
  !transform
    ? /* glsl */ `
        /*
        This shader is from the THREE's SpriteMaterial.
        We need to turn the backing plane into a Sprite
        (make it always face the camera) if "transfrom" 
        is false. 
        */
        #include <common>

        void main() {
        vec2 center = vec2(0., 1.);
        float rotation = 0.0;
        
        // This is somewhat arbitrary, but it seems to work well
        // Need to figure out how to derive this dynamically if it even matters
        float size = 0.03;

        vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
        vec2 scale;
        scale.x = length( vec3( modelMatrix[ 0 ].x, modelMatrix[ 0 ].y, modelMatrix[ 0 ].z ) );
        scale.y = length( vec3( modelMatrix[ 1 ].x, modelMatrix[ 1 ].y, modelMatrix[ 1 ].z ) );

        bool isPerspective = isPerspectiveMatrix( projectionMatrix );
        if ( isPerspective ) scale *= - mvPosition.z;

        vec2 alignedPosition = ( position.xy - ( center - vec2( 0.5 ) ) ) * scale * size;
        vec2 rotatedPosition;
        rotatedPosition.x = cos( rotation ) * alignedPosition.x - sin( rotation ) * alignedPosition.y;
        rotatedPosition.y = sin( rotation ) * alignedPosition.x + cos( rotation ) * alignedPosition.y;
        mvPosition.xy += rotatedPosition;

        gl_Position = projectionMatrix * mvPosition;
        }
    `
    : undefined;
const fragmentShader = (): string => /* glsl */ `
        void main() {
        gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
        }
    `;

function isRefObject(ref: Object | undefined) {
  return ref && typeof ref === 'object' && 'current' in ref;
}
const eps = 0.001;

const raycaster = new Raycaster();
raycaster.firstHitOnly = true;
export class LabelObject extends Group {
  public renderer;
  public camera;
  public domElement;
  public scene;

  public occlusionMeshRef: Mesh | null = null;

  public size: { width: number; height: number };

  public transform: boolean = false;
  public sprite: boolean = false;
  public center: boolean = false;

  public occlude: any;

  public onOcclude: any;
  public zIndexRange: number[] = [16777271, 0]; // [16777271, 0];
  public distanceFactor: undefined = undefined;
  public props: any = {};

  public fullscreen: boolean = false;

  public style: Object = {};
  public wrapperClass: string = '';
  public className: string = '';
  public wrapperGeoemtry: BufferGeometry | null = null;

  public el: HTMLElement | null = null;
  public html: HTMLElement | null = null;

  public labelVisible: boolean = true;
  private isMeshSizeSet: boolean = false;
  private transformOuterRef: HTMLElement | null = null;
  private transformInnerRef: HTMLElement | null = null;
  private oldPosition = [0, 0];
  private oldZoom = 0;
  private isRayCastOcclusion = false;
  private readonly isUpdatezIndex: boolean = true;

  public centerX: number = -50;
  public offsetX: number = 0;

  public centerY: number = -50;
  public offsetY: number = 0;

  public scaleRange: number[] | null = null;
  public constructor(viewer: Viewer, options: Object = {}) {
    super();

    Object.assign(this, options);
    this.setRaycastOcclusion(this.occlude);
    this.camera = viewer.camera;
    this.domElement = viewer.renderer.domElement;
    this.scene = viewer.scene;
    this.renderer = viewer.renderer;
    this.size = this.domElement.getBoundingClientRect();

    this.createDomElement();
  }
  /**
   * @private
   * @description raycast for label
   */
  @autobind
  private setRaycastOcclusion(occlude?: any): void {
    this.occlude = occlude;
    this.isRayCastOcclusion =
      (occlude && occlude !== 'blending') ||
      (Array.isArray(occlude) && occlude.length && isRefObject(occlude[0]));
  }
  /**
   * @public
   * @description re-create root div for label
   */
  @autobind
  public reCreateDomElement(options: Object = {}): void {
    Object.assign(this, options);
    this.dispose();
    if (this.occlude && !this.isRayCastOcclusion) {
      this.occlusionMeshRef = new Mesh(
        new PlaneGeometry(),
        new ShaderMaterial({
          side: DoubleSide,
          vertexShader: vertexShader(this.transform),
          fragmentShader: fragmentShader(),
        })
      );
      this.add(this.occlusionMeshRef);
    }
    this.createDomElement();
  }
  /**
   * @private
   * @description create root div for label
   */
  @autobind
  private createDomElement(): void {
    this.el = document.createElement('div');
    this.scene.updateMatrixWorld();
    if (this.transform) {
      // eslint-disable-next-line max-len
      this.el.style.cssText = `position:absolute;top:0;left:0;pointer-events:none;overflow:hidden;user-select:none;touch-action:none;pointer-events: none;`;
    } else {
      const vec = LabelUtils.defaultCalculatePosition(this, this.camera, this.size);
      // eslint-disable-next-line max-len
      this.el.style.cssText = `position:absolute;top:0;left:0;transform:translate3d(${vec[0]}px,${vec[1]}px,0);transform-origin:0 0;user-select:none;touch-action:none;pointer-events: none;`;
    }
    if (this.wrapperClass) {
      this.el.className = this.wrapperClass;
    }
    this.renderer.domElement.parentNode!.appendChild(this.el);
    this.createTransformDiv();
  }
  /**
   * @private
   * @description setup css of tranform div
   */
  @autobind
  private styles(): string {
    if (this.transform) {
      return reactToCSS({
        position: 'absolute',
        top: 0,
        left: 0,
        width: this.size.width,
        height: this.size.height,
        transformStyle: 'preserve-3d',
        pointerEvents: 'none',
      });
    } else {
      return reactToCSS({
        position: 'absolute',
        transform: this.center
          ? `translate3d(calc(${this.centerX}% - ${this.offsetX}px),calc(${this.centerY}% - ${this.offsetY}px),0)`
          : 'none',
        ...(this.fullscreen && {
          top: -this.size.height / 2,
          left: -this.size.width / 2,
          width: this.size.width,
          height: this.size.height,
        }),
        ...this.style,
      });
    }
  }
  /**
   * @private
   * @description setup css of tranform div
   */
  @autobind
  private transformInnerStyles(): string {
    return reactToCSS({
      position: 'absolute',
      pointerEvents: 'auto',
    });
  }
  /**
   * @private
   * @description setup css of parent of canvas
   */
  @autobind
  private setupGLDomElement(): void {
    const glDomEl = this.renderer.domElement;
    if (this.occlude && this.occlude === 'blending') {
      glDomEl.style.zIndex = `${Math.floor(this.zIndexRange[0] / 2)}`;
      glDomEl.style.position = 'absolute';
      glDomEl.style.pointerEvents = 'none';
    } else {
      glDomEl.style.zIndex = '';
      glDomEl.style.position = '';
      glDomEl.style.pointerEvents = '';
    }
  }
  /**
   * @private
   * @description setup css for div
   */
  @autobind
  private setupCss(): void {
    this.setupGLDomElement();
    if (this.transform) {
      this.transformOuterRef!.style.cssText = this.styles();
      this.transformInnerRef!.style.cssText = this.transformInnerStyles();
    } else {
      this.html!.style.cssText = this.styles();
    }
  }
  /**
   * @private
   * @description create transform div
   */
  @autobind
  private createTransformDiv(): void {
    this.html = document.createElement('div');
    this.html.className = this.className;
    if (this.transform) {
      this.transformOuterRef = document.createElement('div');
      this.el!.appendChild(this.transformOuterRef);
      this.transformInnerRef = document.createElement('div');

      this.transformOuterRef.appendChild(this.transformInnerRef);
      this.transformInnerRef.appendChild(this.html);
    } else {
      this.el!.appendChild(this.html);
    }
    this.setupCss();
  }
  /**
   * @public
   * @description set html content for label
   * @param {  html } string
   */
  @autobind
  public setInnerHTHML(html: string): void {
    this.html!.innerHTML = html;
  }
  /**
   * @public
   * @description This function will auto-call when the scene render
   */
  @autobind
  public updateMatrixWorld(): void {
    if (!this.labelVisible) {
      this.html!.style.display = 'none';
      return;
    }
    this.setupCss();
    this.html!.style.display = 'block';
    this.camera.updateMatrixWorld();
    this.updateWorldMatrix(true, false);
    this.size = this.domElement.getBoundingClientRect();
    const vec = this.transform
      ? this.oldPosition
      : LabelUtils.defaultCalculatePosition(this, this.camera, this.size);
    if (vec.length && isNaN(vec[0])) {
      return;
    }
    if (
      this.transform ||
      Math.abs(this.oldZoom - (this.camera as PerspectiveCamera | OrthographicCamera).zoom) > eps ||
      Math.abs(this.oldPosition[0] - vec[0]) > eps ||
      Math.abs(this.oldPosition[1] - vec[1]) > eps
    ) {
      const isBehindCamera = LabelUtils.isObjectBehindCamera(this, this.camera);
      let raytraceTarget: any = false;
      if (this.isRayCastOcclusion) {
        if (Array.isArray(this.occlude)) {
          raytraceTarget = this.occlude.map(item => item);
        } else if (this.occlude !== 'blending') {
          raytraceTarget = [this.scene];
        }
      }
      const previouslyVisible = this.visible;
      if (raytraceTarget) {
        const isvisible = LabelUtils.isObjectVisible(this, this.camera, raycaster, raytraceTarget);
        this.visible = isvisible && !isBehindCamera;
      } else {
        this.visible = !isBehindCamera; //TODO: check this for label visible
      }
      if (previouslyVisible !== this.visible) {
        if (this.onOcclude) {
          this.onOcclude(!this.visible);
        } else {
          this.el!.style.display = this.visible ? 'block' : 'none';
        }
      }

      const halfRange = Math.floor(this.zIndexRange[0] / 2);
      const zRange = this.occlude
        ? this.isRayCastOcclusion //
          ? [this.zIndexRange[0], halfRange]
          : [halfRange - 1, 0]
        : this.zIndexRange;
      if (this.isUpdatezIndex) {
        this.el!.style.zIndex = `${LabelUtils.objectZIndex(this, this.camera, zRange)}`;
      }

      if (this.transform) {
        const [widthHalf, heightHalf] = [this.size.width / 2, this.size.height / 2];
        const fov = this.camera.projectionMatrix.elements[5] * heightHalf;
        const { isOrthographicCamera, top, left, bottom, right } = this
          .camera as unknown as OrthographicCamera;
        const cameraMatrix = LabelUtils.getCameraCSSMatrix(this.camera.matrixWorldInverse);
        const cameraTransform = isOrthographicCamera
          ? `scale(${fov})translate(${LabelUtils.epsilon(
              -(right + left) / 2
            )}px,${LabelUtils.epsilon((top + bottom) / 2)}px)`
          : `translateZ(${fov}px)`;
        let matrix = this.matrixWorld;
        if (this.sprite) {
          matrix = this.camera.matrixWorldInverse
            .clone()
            .transpose()
            .copyPosition(matrix)
            .scale(this.scale);
          matrix.elements[3] = matrix.elements[7] = matrix.elements[11] = 0;
          matrix.elements[15] = 1;
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        this.el!.style.width = this.size.width + 'px';
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        this.el!.style.height = this.size.height + 'px';
        this.el!.style.perspective = isOrthographicCamera ? '' : `${fov}px`;
        if (this.transformOuterRef && this.transformInnerRef) {
          this.transformOuterRef.style.transform = `${cameraTransform}${cameraMatrix}translate(${widthHalf}px,${heightHalf}px)`;
          this.transformInnerRef.style.transform = LabelUtils.getObjectCSSMatrix(
            matrix,
            1 / ((this.distanceFactor || 10) / 400)
          );
        }
      } else {
        let scale =
          this.distanceFactor === undefined
            ? 1
            : MathUtils.objectScale(this, this.camera) * this.distanceFactor;
        if (this.scaleRange) {
          scale = Math.min(Math.max(this.scaleRange[0], scale), this.scaleRange[1]);
        }
        this.el!.style.transform = `translate3d(${vec[0]}px,${vec[1]}px,0) scale(${scale})`;
      }
      this.oldPosition = vec;
      this.oldZoom = (this.camera as PerspectiveCamera | OrthographicCamera).zoom;
    }
    if (!this.isRayCastOcclusion && this.occlusionMeshRef && !this.isMeshSizeSet) {
      if (this.transform) {
        if (this.transformOuterRef) {
          const el = this.transformOuterRef.children[0];
          if (el != null && el.clientWidth && el != null && el.clientHeight) {
            const { isOrthographicCamera } = this.camera as unknown as OrthographicCamera;
            if (isOrthographicCamera || this.wrapperGeoemtry) {
              if (this.props.scale) {
                if (!Array.isArray(this.scale)) {
                  this.occlusionMeshRef.scale.setScalar(1 / this.props.scale);
                } else if (this.scale instanceof Vector3) {
                  this.occlusionMeshRef.scale.copy(this.props.scale.clone().divideScalar(1));
                } else {
                  this.occlusionMeshRef.scale.set(
                    1 / this.props.scale[0],
                    1 / this.props.scale[1],
                    1 / this.props.scale[2]
                  );
                }
              }
            } else {
              const ratio = (this.distanceFactor || 10) / 400;
              const w = el.clientWidth * ratio;
              const h = el.clientHeight * ratio;
              this.occlusionMeshRef.scale.set(w, h, 1);
            }
            this.isMeshSizeSet = true;
          }
        }
      } else {
        const ele = this.el!.children[0];
        if (ele != null && ele.clientWidth && ele != null && ele.clientHeight) {
          const ratio = this.camera.aspect;
          const w = ele.clientWidth * ratio;
          const h = ele.clientHeight * ratio;
          this.occlusionMeshRef.scale.set(w, h, 1);
          this.isMeshSizeSet = true;
        }
        this.occlusionMeshRef.lookAt(this.camera.position);
      }
    }
  }
  /**
   * @public
   * @description dispose all
   * @returns { void}
   */
  @autobind
  public dispose(): void {
    if (this.el && document.body.contains(this.renderer.domElement)) {
      this.renderer.domElement?.parentNode?.removeChild(this.el);
    }
    DisposeHelper.disposeHierarchy(this);
  }
}
