export const CAMERA_ZOOM_MEASUREMENT_CENTER = 100;
export const LONG_MOUSE_PRESS_TIMER = 300;
export const ESS_TEXT_LENGTH_LIMIT = 250;

export enum MeasureTypes {
  MARKER = 'marker',
  LENGTH = 'length',
  P2P_TRIANGLE = 'p2p_triangle',
  THREE_AREA = 'three_area',
  AREA = 'area',
  VOLUME = 'volume',
  ESS_POLYLINE = 'polyline',
  ESS_POLYGON = 'polygon',
  ESS_TEXT = 'text',
  ESS_ARROW = 'waypoint',
}
export enum KeyWord {
  DELETE = 'Delete',
  ESC = 'Escape',
  BACKSPACE = 'Backspace',
}

export const TILESET_JSON_PATH = 'tiled_model';
