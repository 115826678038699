import { Tuple } from '^/components/molecules/MyPage/Tuple';
import { useL10n } from '^/hooks';
import theme from '^/theme';
import React from 'react';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';
import Text from './text';

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

export default function MyPageSecurity() {
  const [l10n] = useL10n();

  return (
    <Root>
      <GroupedSection title={l10n(Text.security.title)}>
        <Tuple title={l10n(Text.security.password)} value="Last changed Aug 8, 2017" />
        <Tuple
          title={l10n(Text.security.deleteAccount)}
          titleCustomStyles={{
            fontWeight: '700',
            color: theme.colorTokens.errorRed,
          }}
        />
      </GroupedSection>
    </Root>
  );
}
