import React from 'react';
import { Tr, IconCell, TitleCell, Td } from '../styles';
import { ContentIcon } from '^/components/atoms/ContentIcon';
import { useL10n } from '^/hooks';
import Text from '../text';
import { Zone } from '^/store/earthwork/types';
import { ContentType } from '^/types';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';

interface Props {
  zones: Zone[];
  isFixed?: boolean;
  isScrollable?: boolean;
}

const expectedColor = '#f3dbea4a';
const remainingColor = '#eff5e15c';
const totalColor = '#c6e6e952';

export const TableBody: React.FC<Props> = ({ zones, isFixed, isScrollable }) => {
  const [l10n] = useL10n();
  const { showBurnDown, selectedVolumeId } = useEarthWorkStore(s => ({
    showBurnDown: s.showBurnDown,
    selectedVolumeId: s.selectedVolumeId,
  }));

  let volumeZones = zones;
  if (showBurnDown && selectedVolumeId) {
    volumeZones = zones.filter(zone => zone.id === selectedVolumeId);
  }

  return (
    <tbody>
      {volumeZones.map(item => {
        const rowSpan = item.cut.length + item.fill.length;
        //  + item.fill.length;
        return (
          <React.Fragment key={`dashboard-item__${item.id}`}>
            {/* Render the first row */}
            {isFixed && (
              <Tr>
                <IconCell rowSpan={rowSpan} isFixed={isFixed}>
                  <ContentIcon contentType={ContentType.VOLUME} color={'#FF0000'} />
                </IconCell>
                <TitleCell className="volume-col" rowSpan={rowSpan} isFixed={isFixed}>
                  {item.name}
                </TitleCell>
                <Td rowSpan={item.cut.length} className="cut-col" isFixed={isFixed}>
                  {l10n(Text.cut)}
                </Td>
                <Td className="soil-type-col" isFixed={isFixed}>
                  {item.cut[0]?.name || '-'}
                </Td>
                <Td className="number-col" backgroundColor={expectedColor} isFixed={isFixed}>
                  {item.cut[0]?.initial}
                </Td>
                <Td
                  className="number-col"
                  isNegative={item.cut[0]?.remaining < 0}
                  backgroundColor={remainingColor}
                  isFixed={isFixed}
                >
                  {item.cut[0]?.remaining}
                </Td>
                <Td className="number-col" backgroundColor={totalColor} isFixed={isFixed}>
                  {item.cut[0]?.totalChange}
                </Td>
              </Tr>
            )}
            {isScrollable && (
              <Tr>
                {item.cut[0]?.changes.map((survey, surveyIndex) => (
                  <Td
                    key={`cut-survey-${surveyIndex}`}
                    className="number-col"
                    isNegative={survey < 0}
                  >
                    {survey}
                  </Td>
                ))}
              </Tr>
            )}
            {/* Render remaining cut rows */}
            {item.cut.slice(1).map((cut, cutIndex) => (
              <React.Fragment key={`dashboard-item__${item.id}__cut__${cutIndex + 1}`}>
                {isFixed && (
                  <Tr>
                    <Td className="soil-type-col" isFixed={isFixed}>
                      {cut.name}
                    </Td>
                    <Td className="number-col" backgroundColor={expectedColor} isFixed={isFixed}>
                      {cut.initial}
                    </Td>
                    <Td
                      className="number-col"
                      isNegative={cut.remaining < 0}
                      backgroundColor={remainingColor}
                      isFixed={isFixed}
                    >
                      {cut.remaining}
                    </Td>
                    <Td className="number-col" backgroundColor={totalColor} isFixed={isFixed}>
                      {cut.totalChange}
                    </Td>
                  </Tr>
                )}
                {isScrollable && (
                  <Tr>
                    {cut.changes.map((survey, surveyIndex) => (
                      <Td
                        key={`cut-survey-${cutIndex}-${surveyIndex}`}
                        className="number-col"
                        isNegative={survey < 0}
                      >
                        {survey}
                      </Td>
                    ))}
                  </Tr>
                )}
              </React.Fragment>
            ))}
            {/* Render fill rows */}
            {item.fill.map((fill, fillIndex) => (
              <React.Fragment key={`dashboard-item__${item.id}__fill__${fillIndex}`}>
                {!isScrollable && (
                  <Tr>
                    {fillIndex === 0 && (
                      <Td rowSpan={item.fill.length} className="fill-col" isFixed={isFixed}>
                        {l10n(Text.fill)}
                      </Td>
                    )}
                    <Td className="soil-type-col" isFixed={isFixed}>
                      {fill.name}
                    </Td>
                    <Td className="number-col" backgroundColor={expectedColor} isFixed={isFixed}>
                      {fill.initial}
                    </Td>
                    <Td
                      className="number-col"
                      isNegative={fill.remaining < 0}
                      backgroundColor={remainingColor}
                      isFixed={isFixed}
                    >
                      {fill.remaining}
                    </Td>
                    <Td className="number-col" backgroundColor={totalColor} isFixed={isFixed}>
                      {fill.totalChange}
                    </Td>
                  </Tr>
                )}
                {isScrollable && (
                  <Tr>
                    {fill.changes.map((survey, surveyIndex) => (
                      <Td
                        key={`fill-survey-${fillIndex}-${surveyIndex}`}
                        className="number-col"
                        isNegative={survey < 0}
                      >
                        {survey}
                      </Td>
                    ))}
                  </Tr>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};
