import COLORS from '../colors';
import colorTokens from '../colors/color-tokens';

const buttonTokens = {
  bg: {
    primary: colorTokens.accentBlue,
    primaryHover: colorTokens.accentBlueDark,
    primaryPressed: colorTokens.accentBlueDarker,
    primaryDisabled: colorTokens.disabledGray,
    secondary: COLORS.white[100],
    secondaryHover: colorTokens.accentWhiteDark,
    secondaryPressed: colorTokens.accentWhiteDarker,
    secondaryDisabled: colorTokens.disabledGrayLight,
    primaryBlack: colorTokens.bgBlack,
    primaryBlackHover: colorTokens.bgBlackLight,
    primaryBlackPressed: colorTokens.bgBlackLight,
  },
  title: {
    secondaryDisabled: colorTokens.disabledGrayDark,
    secondary: colorTokens.textCool,
    primary: COLORS.white[100],
    primaryDisabled: colorTokens.disabledGrayLighter,
    secondaryError: colorTokens.textCool,
    secondarySuccess: colorTokens.successBlue,
  },
  border: {
    secondary: colorTokens.lineCool,
    error: colorTokens.errorRed,
    success: colorTokens.successBlue,
  },
};

export default buttonTokens;
