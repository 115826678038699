/* eslint-disable no-invalid-this */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CADNode } from './CADNode';
import {
  BufferGeometry,
  Camera,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Raycaster,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';
import { CADOverlayRaycaster } from './CADRaycaster';
import { CadNodeGeometry } from './CADNodeGeometry';
import { degToRad } from 'three/src/math/MathUtils';
import { UnitsUtils } from 'geo-three';

import * as T from '^/types';
import { Runtime } from 'three-loader-3dtiles';

export class CADOverlay extends Mesh {
  public root: CADNode | null = null;
  public lod: CADOverlayRaycaster;
  public contentId: T.BlueprintDXFContent['id'];
  public bounds: number[];
  public content: T.BlueprintDXFContent | T.BlueprintDWGContent | T.ThreeDOrthoContent;
  public opacity: number;

  public constructor(
    bounds: number[],
    runtime: Runtime,
    contentId:
      | T.BlueprintDWGContent['id']
      | T.BlueprintDXFContent['id']
      | T.ThreeDOrthoContent['id'],
    content: T.BlueprintDXFContent | T.BlueprintDWGContent | T.ThreeDOrthoContent
  ) {
    super(undefined, new MeshBasicMaterial({ transparent: true, opacity: 0.0 }));
    this.lod = new CADOverlayRaycaster();
    this.bounds = bounds;
    this.root = new CADNode(
      null,
      0,
      0,
      0,
      this,
      new BufferGeometry(),
      new MeshBasicMaterial({ transparent: true, opacity: 0.0 })
    );
    this.contentId = contentId;
    this.setGeometry(runtime);
    this.content = content;
    this.opacity = (content.config?.opacity ?? 100) / 100;
    this.renderOrder = 15;
  }

  public getDistanceInMeters(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const earthRadius: number = 6371008; // Radius of the Earth in meters

    // Convert latitude and longitude from degrees to radians
    const lat1Rad: number = (lat1 * Math.PI) / 180;
    const lon1Rad: number = (lon1 * Math.PI) / 180;
    const lat2Rad: number = (lat2 * Math.PI) / 180;
    const lon2Rad: number = (lon2 * Math.PI) / 180;

    // Haversine formula
    const dLat: number = lat2Rad - lat1Rad;
    const dLon: number = lon2Rad - lon1Rad;
    const a: number =
      Math.sin(dLat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;
    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance: number = earthRadius * c;

    return distance;
  }

  public getTileXY(latDeg: number, longDeg: number, zoom: number): [number, number] {
    const latRad: number = degToRad(latDeg);
    const n: number = Math.pow(2, zoom);
    const xtile: number = Math.floor(((longDeg + 180.0) / 360.0) * n);
    const ytile: number = Math.floor(((1.0 - Math.asinh(Math.tan(latRad)) / Math.PI) / 2.0) * n);

    return [xtile, n - 1 - ytile]; // Use reverse Y coordinate
  }

  public getTopMostTile(zoom: number): [number, number] {
    const latDeg = this.bounds[3];
    const longDeg = this.bounds[0];
    return this.getTileXY(latDeg, longDeg, zoom);
  }

  public getBottomMostTile(zoom: number): [number, number] {
    const latDeg = this.bounds[1];
    const longDeg = this.bounds[2];
    return this.getTileXY(latDeg, longDeg, zoom);
  }

  public calculateEarthRadiusInWebMercator(latitude: number) {
    // Convert latitude and longitude to radians
    const latRad = (latitude * Math.PI) / 180;

    // Calculate the Earth's radius at the given latitude
    const earthRadius = 6378137.0 / Math.cos(latRad);

    return earthRadius;
  }

  public setGeometry(runtime: Runtime): void {
    if (this.root) {
      const { lat, long, height } = runtime.getLatLongHeightFromPosition(new Vector3(0, 0, 0));
      const earthRadius = this.calculateEarthRadiusInWebMercator(lat);
      const newScale = 6378137.0 / earthRadius;
      this.geometry = new CadNodeGeometry(1, 1, 1, 1);
      this.scale.copy(
        new Vector3(UnitsUtils.EARTH_PERIMETER * newScale, 1, UnitsUtils.EARTH_PERIMETER * newScale)
      );
      this.add(this.root);
      void this.root.initialize();
      const coords = UnitsUtils.datumsToSpherical(lat, long);
      this.position.set(-coords.x * newScale, -coords.y * newScale, -height);

      this.rotateX(degToRad(90));
    }
  }

  public onBeforeRender: (renderer: WebGLRenderer, scene: Scene, camera: Camera) => void = (
    renderer,
    scene,
    camera
  ) => {
    // eslint-disable-next-line no-invalid-this
    this.lod.updateLOD(this, camera, renderer, scene);
  };
  /**
   * Clears all tiles from memory and reloads data. Used when changing the provider.
   *
   * Should be called manually if any changed to the provider are made without setting the provider.
   */
  public clear(): any {
    this.traverse(function (children: Object3D): void {
      // @ts-ignore
      if (children.childrenCache) {
        // @ts-ignore
        children.childrenCache = null;
      }

      // @ts-ignore
      if (children.initialize) {
        // @ts-ignore
        children.initialize();
      }
    });

    return this;
  }

  public preSubdivide(): void {
    function subdivide(node: CADNode, depth: number): void {
      if (depth <= 2) {
        return;
      }

      node.subdivide();

      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i] instanceof CADNode) {
          const child = node.children[i] as CADNode;
          subdivide(child, depth - 1);
        }
      }
    }

    if (this.root) {
      subdivide(this.root, 12);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public raycast(raycaster: Raycaster, intersects: any[]): boolean {
    return false;
  }

  public updateOpacity(opacity: number) {
    this.opacity = opacity;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.traverse(function (children: CADNode): void {
      // @ts-ignore
      if (children.textureLoaded) {
        children.updateOpacity(opacity);
      }
    });
  }
}
