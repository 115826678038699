import React, { memo, useEffect } from 'react';
import { SetHoveredPhotoId, SetPhotoOnMapId, SetSelectedMedia } from '^/store/duck/Photos';
import IncludeWhiteSvg from '^/assets/icons/timelapse/include-white.svg';
import ExcludeWhiteSvg from '^/assets/icons/timelapse/exclude-white.svg';
import PhotoCameraSvg from '^/assets/icons/photo/photo-camera.svg';
import {
  TIMELAPSE_IMAGE_TYPE,
  TimelapseImageType,
  useTimelapseStore,
} from '^/store/zustand/timelapse/timelapseStore';
import { useIsInView, UseL10n, useL10n } from '^/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  CenterCameraSvgWrapper,
  Checkbox,
  DateWrapper,
  ExcludeImageSvgWrapper,
  PhotoDiv,
  PhotoInspectionListItem,
  PhotoInspectionListItemWrapper,
  Wrapper,
} from '../PhotoList/style';
import Tippy from '@tippyjs/react';
import * as T from '^/types';
import Text from './text';
import usePhotoActions from '^/hooks/timelapse/usePhotoActions';

interface TimelapseImagesItemProps {
  item: TimelapseImageType;
  type: TIMELAPSE_IMAGE_TYPE;
  onExcludeInclude(selectedIds: Array<TimelapseImageType['id']>): void;
}

const RawTimelapseImagesItem: React.FC<TimelapseImagesItemProps> = memo(
  ({ item, type, onExcludeInclude }) => {
    const [l10n]: UseL10n = useL10n();
    const dispatch = useDispatch();
    const [ref, isInView] = useIsInView<HTMLDivElement>();

    const { selectedMedia } = useSelector((s: T.State) => s.Photos);
    const isIn3D: boolean = useSelector((state: T.State) => state.Pages.Contents.in3D);

    const {
      selectedState,
      setSelectedState,
      setlastSelectedPhotoId,
      lastSelectedPhotoId,
      timelapseInspectionImages,
    } = useTimelapseStore(s => ({
      setSelectedState: s.setSelectedState,
      selectedState: s.selectedState,
      setlastSelectedPhotoId: s.setlastSelectedPhotoId,
      lastSelectedPhotoId: s.lastSelectedPhotoId,
      timelapseInspectionImages: s.timelapseInspectionImages,
    }));

    const isSelected = selectedMedia.some(
      media => media.contentId === item.id && media.type === item.photoType
    );
    const isDisabled = selectedState && selectedState !== type;

    const { handlePhotoListItemClick, handlePhotoOnMapId } = usePhotoActions(item, isSelected);

    const lastSelectedIndex = timelapseInspectionImages.findIndex(
      photo => photo.id === lastSelectedPhotoId
    );

    const updateSelectionState = (
      stateType: TIMELAPSE_IMAGE_TYPE,
      isChecked: boolean,
      value: T.FinalPhoto
    ) => {
      if (!selectedState) {
        setSelectedState(stateType);
      }

      dispatch(
        SetSelectedMedia({
          selectedMedia: isChecked
            ? [...selectedMedia, { contentId: value.id, type: value.photoType ?? null }]
            : selectedMedia.filter(media => media.contentId !== value.id),
        })
      );
      dispatch(SetPhotoOnMapId({ photoId: value.id }));
      dispatch(SetHoveredPhotoId({ hoveredId: value.id }));
      if (isIn3D) {
        handlePhotoListItemClick(value.id);
      }
    };

    const updateRangeSelection = (start: number, end: number, isChecked: boolean) => {
      const selectedRange = timelapseInspectionImages.slice(start, end + 1).map(photo => ({
        contentId: photo.id,
        type: photo.photoType ?? null,
      }));
      const newSelectedMedia = Array.from(new Set([...selectedMedia, ...selectedRange]));

      dispatch(
        SetSelectedMedia({
          selectedMedia: isChecked
            ? newSelectedMedia
            : selectedMedia.filter(
                media => !selectedRange.some(range => range.contentId === media.contentId)
              ),
        })
      );
    };

    const handleOnSelect = (
      isChecked: boolean,
      value: T.FinalPhoto,
      stateType: TIMELAPSE_IMAGE_TYPE,
      event: React.MouseEvent<Element, MouseEvent>
    ) => {
      const isShift = event.shiftKey;
      const currentIndex = timelapseInspectionImages.findIndex(photo => photo.id === value.id);

      if (selectedState && selectedState !== stateType) {
        return;
      }

      if (isShift && lastSelectedIndex !== -1) {
        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);
        updateRangeSelection(start, end, isChecked);
      } else {
        updateSelectionState(stateType, isChecked, value);
      }

      if (isChecked) {
        setlastSelectedPhotoId(value.id);
      } else {
        setlastSelectedPhotoId(undefined);
      }
    };

    const handleItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      photoId: T.Photo['id'],
      stateType: TIMELAPSE_IMAGE_TYPE,
      isChecked: boolean
    ) => {
      const isShift = event.shiftKey;
      const isCTRL = event.ctrlKey;
      const currentIndex = timelapseInspectionImages.findIndex(photo => photo.id === photoId);

      if (selectedState && selectedState !== stateType) {
        return;
      }

      if (!isShift && !isCTRL) {
        if (!selectedState && isIn3D) {
          setSelectedState(stateType);
        }
        handlePhotoListItemClick(photoId);
        return;
      }

      if (isShift && lastSelectedIndex !== -1 && !isCTRL) {
        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);
        updateRangeSelection(start, end, isChecked);
      }

      if (isCTRL && !isShift) {
        updateSelectionState(stateType, isChecked, timelapseInspectionImages[currentIndex]);
      }

      if (isChecked) {
        setlastSelectedPhotoId(timelapseInspectionImages[currentIndex].id);
      } else {
        setlastSelectedPhotoId(undefined);
      }
    };

    const handleIncludeExclude = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      onExcludeInclude([item.id]);
    };

    useEffect(() => {
      if (selectedMedia.length === 0) {
        setSelectedState(undefined);
      }
    }, [selectedMedia]);

    const takenAt = new Date(item?.takenAt as string);

    if (!item) {
      return null;
    }

    return (
      <PhotoDiv
        isSelected={isSelected}
        isInspection={false}
        isDisabled={isDisabled}
        onClick={e => handleItemClick(e, item.id, type, !isSelected)}
        ref={ref}
      >
        <PhotoInspectionListItemWrapper isSelected={isSelected}>
          <PhotoInspectionListItem
            isSelected={isSelected}
            key={item.id}
            url={isInView ? item.thumbUrl : ''}
          >
            <DateWrapper>{takenAt?.toDateString()}</DateWrapper>
            <Wrapper disabled={isDisabled}>
              <Checkbox
                checked={isSelected}
                onChange={(isChecked, event) => {
                  handleOnSelect(isChecked, item, type, event);
                }}
              />
            </Wrapper>
            <Tippy
              offset={T.TIPPY_OFFSET}
              theme="angelsw"
              placement="top"
              arrow={false}
              content={'Center on map'}
            >
              <CenterCameraSvgWrapper onClick={handlePhotoOnMapId} disabled={false}>
                <PhotoCameraSvg />
              </CenterCameraSvgWrapper>
            </Tippy>
            <Tippy
              offset={T.TIPPY_OFFSET}
              theme="angelsw"
              placement="top"
              arrow={false}
              content={
                type === TIMELAPSE_IMAGE_TYPE.TIMELAPSE ? l10n(Text.exclude) : l10n(Text.include)
              }
            >
              <ExcludeImageSvgWrapper onClick={handleIncludeExclude} disabled={isSelected}>
                {type === TIMELAPSE_IMAGE_TYPE.TIMELAPSE ? (
                  <ExcludeWhiteSvg />
                ) : (
                  <IncludeWhiteSvg />
                )}
              </ExcludeImageSvgWrapper>
            </Tippy>
          </PhotoInspectionListItem>
        </PhotoInspectionListItemWrapper>
      </PhotoDiv>
    );
  }
);

export const TimelapseImagesItem: React.FC<TimelapseImagesItemProps> = RawTimelapseImagesItem;
