import React, {
  Context,
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useMemo,
} from 'react';

import { checkIfContentIsFilteredOut } from '^/hooks/useContentsSearchFilter';
import { useVslamAnd360Store } from '^/store/vslamAnd360Store';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useGroupStore } from '^/store/zustand/groups/groupStore';
import * as T from '^/types';
import { getAllChildren } from '^/utilities/state-util';

export enum FilterContentEnum {
  'SELECT_ALL' = 'SELECT_ALL',
  'BLUEPRINT_PDF' = 'blueprint_pdf',
  'BLUEPRINT_DWG' = 'blueprint_dwg',
  'BLUEPRINT_DXF' = 'blueprint_dxf',
}

export const filterContentObject = [
  { [FilterContentEnum.SELECT_ALL]: 'Select All' },
  { [FilterContentEnum.BLUEPRINT_PDF]: 'PDF' },
  { [FilterContentEnum.BLUEPRINT_DWG]: 'DWG' },
  { [FilterContentEnum.BLUEPRINT_DXF]: 'DXF' },
];

interface OverlayContentsFilter {
  filterText: string;
  selectedFilterContents: T.ContentType[];
  filterType: T.ContentsListType.PERSONAL_OVERLAY | T.ContentsListType.OPEN_OVERLAY;
  filterContentType?: FilterContentEnum;
  contentTypes?: T.ContentType[];
  rootGroupIds: Array<T.Content['id']>;
  filteredContentIds: Array<T.Content['id']>;
  count: number;
}

export const OverlayContentFilterContext: Context<OverlayContentsFilter> =
  createContext<OverlayContentsFilter>({
    filterText: '',
    selectedFilterContents: [],
    rootGroupIds: [],
    filteredContentIds: [],
    count: 0,
    filterType: T.ContentsListType.OPEN_OVERLAY,
  });

interface OverlayContentFilterContextProviderProps {
  contentTypes: T.ContentType[];
  filterType: T.ContentsListType.PERSONAL_OVERLAY | T.ContentsListType.OPEN_OVERLAY;
  rootGroupIds: Array<T.Content['id']>;
}

const OverlayContentFilterContextProviderRoot: FC<
  PropsWithChildren<OverlayContentFilterContextProviderProps>
> = ({ filterType, contentTypes, children, rootGroupIds }) => {
  const contentFilters = useVslamAnd360Store(state => state.contentFilters);
  const filterText = contentFilters[filterType].filterText;
  const selectedFilterContents = contentFilters[filterType].filterContents;

  const idsByGroup = useGroupStore(s => s.tree.idsByGroup);
  const contentByIds = useContentsStore(state => state.contents.byId);

  const allChildren = useMemo(
    () => getAllChildren(rootGroupIds, idsByGroup, contentByIds),
    [rootGroupIds, idsByGroup, contentByIds]
  );

  const filteredContent = useMemo(
    () =>
      allChildren.filter(
        _content => !checkIfContentIsFilteredOut(_content, filterText, selectedFilterContents)
      ),
    [filterText, selectedFilterContents, allChildren]
  );

  const filteredContentIds = useMemo(() => {
    const filteredParents = filteredContent
      .map(_content => contentByIds[_content?.groupId ?? NaN])
      .filter(_content => _content);
    const filteredGrandParents = filteredParents
      .map(_content => contentByIds[_content?.groupId ?? NaN])
      .filter(_content => _content);
    const Ids = filteredContent
      .concat(filteredParents)
      .concat(filteredGrandParents)
      .map(_content => _content.id);
    return Ids;
  }, [allChildren, filteredContent]);

  const filterState = useMemo(
    () => ({
      filterText,
      selectedFilterContents,
      filterType,
      contentTypes,
      rootGroupIds,
      filteredContentIds,
      count: filteredContentIds.length,
    }),
    [filterText, selectedFilterContents, filterType, contentTypes, rootGroupIds, filteredContentIds]
  );

  return (
    <OverlayContentFilterContext.Provider value={filterState}>
      {children}
    </OverlayContentFilterContext.Provider>
  );
};

export const OverlayContentFilterContextProvider = memo<
  FC<PropsWithChildren<OverlayContentFilterContextProviderProps>>
>(OverlayContentFilterContextProviderRoot);
export const useOverlayOverlayContentsFilter = () => useContext(OverlayContentFilterContext);
