import { Language } from '^/types';

export default {
  firstBalloonTitle: {
    [Language.KO_KR]: '불투명도',
    [Language.EN_US]: 'Opacity',
  },
  elevation: {
    [Language.KO_KR]: '고도',
    [Language.EN_US]: 'Elevation',
  },
  noHillshadeAvailable: {
    [Language.KO_KR]:
      '음영기복도를 사용할 수 없습니다. 아래 버튼을 클릭하면 음영기복도 데이터를 생성할 수 있습니다.',
    [Language.EN_US]:
      'No hillshade available. You can generate hillshade data for this content by clicking the button below.',
  },
  generateHillshade: {
    [Language.KO_KR]: '음영기복도 생성',
    [Language.EN_US]: 'Generate Hillshade',
  },
  processingHillshade: {
    [Language.KO_KR]: '음영기복도 처리 중...',
    [Language.EN_US]: 'Processing Hillshade...',
  },
  hillshadeBeingProcessed: {
    [Language.KO_KR]: '현재 음영기복도가 처리 중입니다. 처리가 완료되면 이메일 알림을 받게 됩니다.',
    [Language.EN_US]:
      'The hillshade is currently being processed. You will receive an email notification once the processing is complete.',
  },
  hillshadeAlreadyBeingProcessed: {
    [Language.KO_KR]: '음영기복도가 이미 처리 중입니다. 처리가 완료되면 이메일 알림을 받게 됩니다.',
    [Language.EN_US]:
      'The hillshade is already being processed. You will receive an email notification once the processing is complete.',
  },
  dtmBeingProcessed: {
    [Language.KO_KR]: '현재 DTM이 처리 중입니다. 처리가 완료되면 이메일 알림을 받게 됩니다.',
    [Language.EN_US]:
      'The DTM is currently being processed. You will receive an email notification once the processing is complete.',
  },
  dtmAlreadyBeingProcessed: {
    [Language.KO_KR]: 'DTM이 이미 처리 중입니다. 처리가 완료되면 이메일 알림을 받게 됩니다.',
    [Language.EN_US]:
      'The DTM is already being processed. You will receive an email notification once the processing is complete.',
  },
  noDTMAvailable: {
    [Language.KO_KR]: 'DTM을 사용할 수 없습니다. 아래 버튼을 클릭하면 DTM을 생성할 수 있습니다.',
    [Language.EN_US]:
      'No DTM available. You can generate DTM for this content by clicking the button below.',
  },
  generateDTM: {
    [Language.KO_KR]: 'DTM 생성',
    [Language.EN_US]: 'Generate DTM',
  },
  processingDTM: {
    [Language.KO_KR]: 'DTM 처리 중...',
    [Language.EN_US]: 'Processing DTM...',
  },
  terrain: {
    [Language.KO_KR]: '지형',
    [Language.EN_US]: 'Terrain',
  },
};
