import { makeV2APIURL } from '^/store/duck/API';

enum USERCONFIG {
  getUserConfig = 'GetUserConfig',
}

export const UserConfigKeys = {
  getUserConfig: (): [USERCONFIG.getUserConfig] => [USERCONFIG.getUserConfig],
};

export const UserConfigURLs = {
  getUserConfig: () => makeV2APIURL('users', 'config'),
  patchUserConfig: () => makeV2APIURL('users', 'config'),
};
