/* eslint-disable max-lines */
import { Language } from '^/types';

export default {
  [Language.EN_US]: [
    'Afghanistan',
    'Åland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia, Plurinational State of',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo, The Democratic Republic of the',
    'Cook Islands',
    'Costa Rica',
    "Côte d'Ivoire",
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands (Malvinas)',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (Vatican City State)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran, Islamic Republic of',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    "Korea, Democratic People's Republic of",
    'Korea, Republic of',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republic",
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia, Republic of',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia, Federated States of',
    'Moldova, Republic of',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine, State of',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Réunion',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'South Sudan',
    'Svalbard and Jan Mayen',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'United States Minor Outlying Islands',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela, Bolivarian Republic of',
    'Viet Nam',
    'Virgin Islands, British',
    'Virgin Islands, U.S.',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ],
  [Language.KO_KR]: [
    '아프가니스탄',
    '알랜드 제도',
    '알바니아',
    '알제리',
    '미국령 사모아',
    '안도라',
    '앙골라',
    '앙귈라',
    '남극',
    '안티가 바부다',
    '아르헨티나',
    '아르메니아',
    '아루바',
    '오스트레일리아',
    '오스트리아',
    '아제르바이잔',
    '바하마',
    '바레인',
    '방글라데시',
    '바르바도스',
    '벨라루스',
    '벨기에',
    '벨리즈',
    '베닌',
    '버뮤다',
    '부탄',
    '볼리비아 다국가 연합국',
    '보네르, 신트외스타티위스, 사바 섬',
    '보스니아 헤르체코비나',
    '보츠와나',
    '보우벳 섬',
    '브라질',
    '영국령 인도양 식민지',
    '브루나이 다루살람',
    '불가리아',
    '부르키나 파소',
    '부룬디',
    '캄보디아',
    '카메룬',
    '캐나다',
    '케이프 베르드',
    '케이만 제도',
    '중앙 아프리카 공화국',
    '챠드',
    '칠레',
    '중국',
    '크리스마스 섬',
    '코코스 (킬링) 제도',
    '콜롬비아',
    '코모로스',
    '콩고',
    '콩고 민주 공화국',
    '쿡 제도',
    '코스타리카',
    '코트 디브와르',
    '크로아티아',
    '쿠바',
    '퀴라소',
    '사이프러스',
    '체코 공화국',
    '덴마크',
    '지부티',
    '도미니카',
    '도미니카 공화국',
    '에콰도르',
    '이집트',
    '엘 살바도르',
    '적도기니',
    '에리트리아',
    '에스토니아',
    '에티오피아',
    '포클랜드 제도 (말비나스)',
    '패로 제도',
    '피지',
    '핀란드',
    '프랑스',
    '프랑스령 기아나',
    '프랑스령 폴리네시아',
    '프랑스령 남 자치구역',
    '가봉',
    '잠비아',
    '조지아',
    '독일',
    '가나',
    '지브롤터',
    '그리스',
    '그린랜드',
    '그레나다',
    '과달루프',
    '괌',
    '과테말라',
    '건지섬',
    '기니',
    '기니비사우',
    '가이아나',
    '하이티',
    '허드 맥도널드 제도',
    '바티칸 시티 (Holy See)',
    '혼두라스',
    '홍콩',
    '헝가리',
    '아이슬랜드',
    '인도',
    '인도네시아',
    '이란 이슬람 공화국',
    '이라크',
    '아일랜드',
    '맨섬',
    '이스라엘',
    '이탈리아',
    '자메이카',
    '일본',
    '저지섬',
    '요르단',
    '카자흐스탄',
    '케냐',
    '키리바티',
    '조선민주주의인민공화국',
    '대한민국',
    '쿠웨이트',
    '키르기즈스탄',
    '라오 인민 민주주의 공화국',
    '라트비아',
    '레바논',
    '레소토',
    '라이베리아',
    '리비아',
    '리히텐슈타인',
    '리투아니아',
    '룩셈부르크',
    '마카오',
    '마케도니아, 전 유고슬라비아 공화국',
    '마다가스카르',
    '말라위',
    '말레이시아',
    '몰디브',
    '말리',
    '몰타',
    '마샬 제도',
    '마르띠니크',
    '모리타니아',
    '모리셔스',
    '마요트',
    '멕시코',
    '미크로네시아 연방',
    '몰도바 공화국',
    '모나코',
    '몽골라',
    '몬테네그로',
    '몽세라트',
    '모로코',
    '모잠비크',
    '미얀마',
    '나미비아',
    '나우루',
    '네팔',
    '네덜란드',
    '뉴 칼레도니아',
    '뉴질랜드',
    '니카라과',
    '니제르',
    '나이지리아',
    '니우에',
    '노퍽 섬',
    '북 마리아나 제도',
    '노르웨이',
    '오만',
    '파키스탄',
    '팔라우',
    '팔레스타인',
    '파나마',
    '파푸아뉴기니',
    '파라과이',
    '페루',
    '필리핀',
    '피트케언',
    '폴란드',
    '포르투갈',
    '푸에르토리코',
    '카타르',
    '레위니옹',
    '루마니아',
    '러시아 연방',
    '르완다',
    '쌩 바르텔레미',
    '세인트 헬레나 어센션 트리탄다쿠냐',
    '세인트 키츠 네비스',
    '세인트 루시아',
    '쌩 마르텡 (프랑스령)',
    '쌩 피에르 미켈론',
    '세인트 빈센트 그레나다인즈',
    '사모아',
    '산마리노',
    '상투메 프린시페',
    '사우디아라비아',
    '세네갈',
    '세르비아',
    '세이셸',
    '시에라리온',
    '싱가포르',
    '신트마르턴 (네덜란드령)',
    '슬로바키아',
    '슬로베니아',
    '솔로몬 제도',
    '소말리아',
    '남아프리카',
    '남 조지아 남 샌드위치 제도',
    '스페인',
    '스리랑카',
    '수단',
    '수리남',
    '남수단',
    '스발바르드 얀마이엔',
    '스와질란드',
    '스웨덴',
    '스위스',
    '시리아 아랍 공화국',
    '타이완',
    '타지키스탄',
    '탄자니아 연방 공화국',
    '태국',
    '동티모르',
    '토고',
    '토켈로',
    '통가',
    '트리니다드 토바고',
    '튀니지',
    '터키',
    '투르크멘',
    '터크 스케이커스 제도',
    '투발루',
    '우간다',
    '우크라이나',
    '아랍에미리트연합',
    '영국',
    '미국',
    '미국령 소군도',
    '우루과이',
    '우즈베키스탄',
    '바누아투',
    '베네수엘라 볼리베리안 공화국',
    '베트남',
    '버진 제도, 영국령',
    '버진 제도, 미국령',
    '왈리스 후투나',
    '서부 사하라',
    '예멘',
    '잠비아',
    '짐바브웨',
  ],
};
