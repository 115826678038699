export const delayPromise: (delay: number) => Promise<void> = async delay =>
  new Promise<void>(resolve => {
    window.setTimeout(() => {
      resolve();
    }, delay);
  });

const minDelayPromise: <T>(promise: Promise<T>, delay: number) => Promise<T> = async <T>(
  promise: Promise<T>,
  delay: number
) => {
  const [result]: [...any[]] = await Promise.all([promise, delayPromise(delay)]);

  return result;
};
export default minDelayPromise;
