import * as T from '^/types';

export default {
  title: {
    [T.Language.KO_KR]: '비행 일정 만들기',
    [T.Language.EN_US]: 'Create Flight Schedule',
  },
  buttons: {
    next: {
      [T.Language.KO_KR]: '다음',
      [T.Language.EN_US]: 'Next',
    },
    confirm: {
      [T.Language.KO_KR]: '비행 일정 저장',
      [T.Language.EN_US]: 'Save flight schedule',
    },
    cancel: {
      [T.Language.KO_KR]: '취소',
      [T.Language.EN_US]: 'Cancel',
    },
  },
};
