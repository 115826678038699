import { Language } from '^/types';

export default {
  zoomTooltip: {
    [Language.KO_KR]: '확대/축소하려면 클릭',
    [Language.EN_US]: 'Click to zoom',
  },
  detailTooltip: (isPhoto: boolean) => ({
    [Language.KO_KR]: `${isPhoto ? '사진' : '동영상'} 세부 정보`,
    [Language.EN_US]: `${isPhoto ? 'Photo' : 'Video'} Details`,
  }),
  issueTooltip: {
    [Language.KO_KR]: '작업 관리 생성',
    [Language.EN_US]: 'Add Issue',
  },
  editDateTooltip: {
    [Language.KO_KR]: '날짜 편집',
    [Language.EN_US]: 'Edit Date',
  },
  downloadTooltip: (isPhoto: boolean) => ({
    [Language.KO_KR]: `${isPhoto ? '사진' : '동영상'} 다운로드`,
    [Language.EN_US]: `Download  ${isPhoto ? 'Photo' : 'Video'}`,
  }),
  deleteTooltip: (isPhoto: boolean) => ({
    [Language.KO_KR]: `${isPhoto ? '사진' : '동영상'} 삭제`,
    [Language.EN_US]: `Delete  ${isPhoto ? 'Photo' : 'Video'}`,
  }),
  filter: {
    [Language.KO_KR]: '현재 이미지와 유사한 이미지 필터',
    [Language.EN_US]: 'Filter images similar to current image',
  },
  unfilter: {
    [Language.KO_KR]: '필터 제거',
    [Language.EN_US]: 'Remove filter',
  },
};
