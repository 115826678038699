import { create, useStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import * as T from '^/types';

interface DroneStationAuthState {
  authDetails: T.DroneStationAuthDetails;
}

interface DroneStationAuthActions {
  setAuthDetails: (updatedAuthDetails: T.DroneStationAuthDetails) => void;
  resetAuthDetails: () => void;
}

type DroneStationAuthStore = DroneStationAuthState & DroneStationAuthActions;

const authInitialState: DroneStationAuthState = {
  authDetails: {} as T.DroneStationAuthDetails,
};
export const droneStationAuthStore = create<DroneStationAuthStore>()(
  persist(
    set => ({
      ...authInitialState,
      setAuthDetails(updatedAuthDetails) {
        set(authStore => ({
          ...authStore,
          authDetails: updatedAuthDetails,
        }));
      },
      resetAuthDetails() {
        set(() => ({ ...authInitialState }));
      },
    }),
    {
      storage: createJSONStorage(() => localStorage),
      name: 'drone-station-auth-store',
    }
  )
);

export function useDroneStationAuthStore(): DroneStationAuthStore;
export function useDroneStationAuthStore<T>(selector: (state: DroneStationAuthStore) => T): T;
export function useDroneStationAuthStore<T>(selector?: (state: DroneStationAuthStore) => T) {
  return useStore(droneStationAuthStore, selector!);
}
