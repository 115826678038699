import * as T from '^/types';
export async function getImageDimensions(src: string): Promise<{ width: number; height: number }> {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = function () {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.src = src;

    setTimeout(() => {
      resolve({
        width: 0,
        height: 0,
      });
    }, 5000);
  });
}

export const isImageGeoReferenced = (photo: T.Photo) => {
  if (
    photo.geotags &&
    photo.geotags?.gimbalPitchDegree !== undefined &&
    photo.geotags?.gimbalPitchDegree !== null &&
    photo.geotags?.gimbalRollDegree !== undefined &&
    photo.geotags?.gimbalRollDegree !== null &&
    photo.geotags?.gimbalYawDegree !== undefined &&
    photo.geotags?.gimbalYawDegree !== null &&
    photo.geotags?.focalLength !== undefined &&
    photo.geotags?.focalLength !== null
  ) {
    return true;
  } else {
    return false;
  }
};

export const isImageGeoLocated = (photo: T.Photo) => {
  if (photo.latitude && photo.longitude && photo.altitude) {
    return true;
  }
  return false;
};
