import { useSelector } from 'react-redux';
import * as T from '^/types';

export const useLastSelectedScreen = (): T.Screen | undefined => {
  const lastSelectedScreenId = useSelector(
    (state: T.State) => state.ProjectConfigPerUser.config?.lastSelectedScreenId
  );

  const screens = useSelector((state: T.State): T.Screen[] => state.Screens.screens);

  return lastSelectedScreenId ? screens.find(({ id }) => id === lastSelectedScreenId) : undefined;
};

export type AreScreensEqual<S extends T.Screen> = (prev: S, next: S) => boolean;
export type UseLastSelectedScreen = ReturnType<typeof useLastSelectedScreen>;

export function lastSelectedScreenSelector(s: T.State): T.Screen | undefined {
  const screens: T.Screen[] = s.Screens.screens;
  const lastSelectedScreenId = s.ProjectConfigPerUser.config?.lastSelectedScreenId;

  if (lastSelectedScreenId === undefined) {
    return;
  }

  return screens.find(({ id }) => id === lastSelectedScreenId);
}
