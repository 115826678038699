import { useSelector } from 'react-redux';
import { lastSelectedScreenSelector } from '.';
import * as T from '^/types';
import { contentsStore } from '^/store/zustand/content/contentStore';

interface LastDSMOrDSMContentSelectorOptions {
  needsSelectedAt?: boolean;
}

export function lastDSMOrMapContentSelector<Content extends T.DSMorMapContent>(
  s: T.State,
  contentType: T.ContentType,
  options: LastDSMOrDSMContentSelectorOptions = {
    needsSelectedAt: false,
  }
): Content | undefined {
  const lastSelectedScreenId: T.Screen['id'] | undefined = lastSelectedScreenSelector(s)?.id;
  const byId: T.ContentsState['contents']['byId'] = contentsStore.getState().contents.byId;
  const allIds = contentsStore.getState().contents.allIds;
  const lastDSMOrMapContentId: Content['id'] | undefined = allIds.find(
    id => byId[id].type === contentType && byId[id].screenId === lastSelectedScreenId
  );

  if (lastDSMOrMapContentId === undefined) {
    return undefined;
  }
  if (options.needsSelectedAt && !byId[lastDSMOrMapContentId]?.config?.selectedAt) {
    return undefined;
  }

  return byId[lastDSMOrMapContentId] as Content;
}

export function useLastDSMOrMapContent<Content extends T.DSMorMapContent>(
  contentType: T.ContentType,
  options?: LastDSMOrDSMContentSelectorOptions
): Content | undefined {
  return useSelector((s: T.State) => lastDSMOrMapContentSelector(s, contentType, options));
}
