import React, { FC, ReactNode, useCallback, useMemo } from 'react';

import styled from 'styled-components';
import * as T from '^/types';
import { useDispatch, useSelector } from 'react-redux';
import { CloseContentPagePopup } from '^/store/duck/Pages';
import palette from '^/constants/palette';
import { useL10n, UseL10n } from '^/hooks';
import Text from './text';
import { setSourcePhotoContentId } from '^/store/duck/Photos';
import { PhotoContainer, PhotoListItem } from '../PhotoList/style';
import SourcePhoto from './Source-photo.png';
import SourceIconSvg from './source-icon.svg';
import { format } from 'date-fns';
import { Formats } from '^/utilities/date-format';
import PaperClipSvg from '^/assets/icons/photo/paper-clip2.svg';

const Root = styled.div({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  overflowY: 'auto',
});

const GridRoot = styled.div({
  display: 'grid',
  width: '100%',
  height: '100%',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: '10px',
  overflowY: 'auto',
});

const Content = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingTop: '15px',
  flexWrap: 'wrap',
  gap: 10,
});

const EmptyWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '15px',
  flexWrap: 'wrap',
  gap: 10,
});

const EmptyText = styled.p({
  fontSize: '13px',
  color: palette.textGray.toString(),
});

export const ContentText = styled.p({
  fontSize: '13px',
  color: palette.white.toString(),
});

export const ContentScreenTitle = styled.p({
  fontSize: '10px',
  color: palette.white.toString(),
});

const SourcePhotoSelector: FC<{}> = () => {
  const { photos } = useSelector((s: T.State) => s.Photos);
  const [l10n]: UseL10n = useL10n();
  const dispatch = useDispatch();

  const sourcePhotosAlbum = useMemo(
    () =>
      photos
        .filter(photo => photo.photoType === 'source')
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    [photos]
  );

  const handleClose: () => void = useCallback(() => {
    dispatch(CloseContentPagePopup());
  }, []);

  const paperClipIcon: ReactNode = <PaperClipSvg />;

  const emptyPlaceHolder = !sourcePhotosAlbum.length && (
    <EmptyWrapper>
      {paperClipIcon}
      <EmptyText>{l10n(Text.emptyInfo)}</EmptyText>
      <EmptyText>{l10n(Text.info)}</EmptyText>
    </EmptyWrapper>
  );

  return (
    <Root>
      <GridRoot>
        {sourcePhotosAlbum.length
          ? sourcePhotosAlbum.map(photo => (
              <PhotoItemContainer
                key={`photoItem_${photo.id}}`}
                photo={photo}
                handleClose={handleClose}
              />
            ))
          : null}
      </GridRoot>
      {emptyPlaceHolder}
    </Root>
  );
};

export default SourcePhotoSelector;

interface Props {
  photo: T.FinalPhoto;
  isDisabled?: boolean;
  handleClose?(): void;
}

export const PhotoItemContainer: FC<Props> = ({ photo, isDisabled, handleClose }) => {
  const dispatch = useDispatch();

  const handleSelected = (contentId?: T.Photo['contentId']) => () => {
    if (isDisabled) {
      return;
    }

    dispatch(setSourcePhotoContentId({ contentId }));
    handleClose?.();
  };

  const isSelected = false;
  const title = 'Source Photo';
  const screenTitle = photo.screenTitle;
  const photoCover = SourcePhoto;
  const photoIcon = <SourceIconSvg />;
  const date = format(photo.createdAt, Formats.PP);

  return (
    <PhotoListItem
      onClick={handleSelected(photo?.contentId)}
      isSelected={isSelected}
      url={photoCover}
      isVisible={true}
    >
      <PhotoContainer>
        <Content>
          {photoIcon}
          <ContentText>{date}</ContentText>
          <ContentText>{title}</ContentText>
          <ContentScreenTitle>{screenTitle}</ContentScreenTitle>
          <ContentText>{photo.count} Photos</ContentText>
        </Content>
      </PhotoContainer>
    </PhotoListItem>
  );
};
