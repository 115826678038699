import React, { ReactElement, useMemo } from 'react';
import styled from 'styled-components';

import {
  HorizontalDivider,
  ContentsListItem as ListItem,
} from '^/components/atoms/ContentsListItem';
import dsPalette from '^/constants/ds-palette';
import { UseL10n, useL10n } from '^/hooks';
import * as T from '^/types';
import { getCoordinateTitles, projectionSystemLabel } from '^/utilities/coordinate-util';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import Text from './text';

import { useSelector } from 'react-redux';
import ContentsListGCPLabelItem from '../ContentsListGCPLabelItem';

const Balloon = styled.div({
  width: '100%',
});

const CoordinateText = styled.p({
  marginLeft: '10px',
  marginTop: '13px',

  fontSize: '13px',
  color: dsPalette.title.toString(),
});

const Title = styled.p({
  display: 'flex',
  alignItems: 'center',

  fontSize: '13px',
  fontWeight: 'bold',
  color: dsPalette.title.toString(),
});

const LabelList = styled.ul({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  marginTop: '9px',

  listStyle: 'none',
});

export interface Props {
  readonly content: T.GCPGroupContent;
}

export function ContentsListGCPGroupItem({ content }: Props): ReactElement {
  const [l10n]: UseL10n = useL10n();

  const coordinateTitles: T.CoordinateTitle[] = useMemo(
    () => getCoordinateTitles(content.info.crs),
    [content.info.crs]
  );

  const isIn3D: T.ContentsPageState['in3D'] = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const currentMeshEngine: T.ContentsPageState['currentMeshEngine'] = useSelector(
    (s: T.State) => s.Pages.Contents.currentMeshEngine
  );
  const isIn3DMesh = isIn3D && currentMeshEngine === T.MeshEngine.THREEJS;

  // const isIn3D: boolean = useSelector((s: T.State) => s.Pages.Contents.in3D);
  // const isSelected: boolean = useSelector((s: T.State) =>
  //   contentsSelector.isSelected(s.Contents, s.ProjectConfigPerUser)(content.id)
  // );

  const numberOfGCPs: number = useMemo(() => content.info.gcps.length, [content.info.gcps.length]);

  // useEffect(() => {
  //   if (isIn3D && isSelected) {
  //     dispatch(
  //       PatchContent({
  //         content: {
  //           id: content.id,
  //           config: { selectedAt: undefined },
  //         },
  //       })
  //     );
  //   }
  // }, [isIn3D]);

  // useEffect(() => {
  //   if (isSelected && isIn3D) {
  //     dispatch(ChangeIn3D({ in3D: false }));
  //   }
  // }, [isSelected]);

  return (
    <ListItem
      className={CANCELLABLE_CLASS_NAME}
      content={content}
      firstBalloonTitle={l10n(Text.coordinateSystem)}
    >
      <Balloon>
        <CoordinateText>{l10n(projectionSystemLabel[content.info.crs])}</CoordinateText>
      </Balloon>
      <HorizontalDivider />
      <Balloon>
        <Title>{`${l10n(Text.list)} (${numberOfGCPs})`}</Title>
        <LabelList>
          {content?.info.gcps.map((gcp, index) => (
            <ContentsListGCPLabelItem
              gcp={gcp}
              crs={content.info.crs}
              index={index}
              key={`gcp-label-${index}`}
              coordinateTitles={coordinateTitles}
              isIn3DMesh={isIn3DMesh}
            />
          ))}
        </LabelList>
      </Balloon>
    </ListItem>
  );
}
