import React, { ReactNode } from 'react';
import styled from 'styled-components';
import dsPalette from '^/constants/ds-palette';
import SearchTeammate, {
  combineName,
} from '^/components/molecules/ConcernedParties/SearchTeammate';
import { useL10n, UseL10n } from '^/hooks';
import * as T from '^/types';
import Text from './text';
import IssuePerson from '^/components/atoms/IssuePerson';
import palette from '^/constants/palette';
import useIssueContents from '^/hooks/useIssueContents';

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),

  marginBottom: '20px',
});

const PartyListWrapper = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '8px',
  padding: '11px 9px',
});

const ContentTitle = styled.div({
  fontSize: '12px',
  color: dsPalette.title.toString(),
  marginBottom: '7px',
});

const ContentWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  border: `1px solid ${palette.ContentsList.inputBorder.toString()}`,
  borderRadius: '5px',
});

const InputWrapper = styled.div({
  boxSizing: 'border-box',
  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),
});

interface Props {
  content: T.IssueContent;
}

function ConcernedParties({ content }: Props) {
  const [l10n, language]: UseL10n = useL10n();
  const { addAssignee, removeAssignee } = useIssueContents();

  const people: T.IssueAssignee[] | undefined = content?.assignees;
  const nameContent: ReactNode =
    people && people.length > 0 ? (
      <PartyListWrapper>
        {people.map(user => (
          <IssuePerson
            key={`assignee-${user.id}`}
            id={user.id}
            name={combineName({
              firstName: user.firstName,
              lastName: user.lastName,
              language,
            })}
            email={user.email}
            avatar={user.avatar}
            onDelete={() => {
              removeAssignee({ issueId: content.id, user });
            }}
          />
        ))}
      </PartyListWrapper>
    ) : null;

  return (
    <Root>
      <ContentTitle>{l10n(Text.title)}</ContentTitle>
      <ContentWrapper>
        {nameContent}
        <InputWrapper>
          <SearchTeammate
            content={content}
            onAddAssignee={user => {
              addAssignee({ issueId: content.id, user });
            }}
          />
        </InputWrapper>
      </ContentWrapper>
    </Root>
  );
}

export default ConcernedParties;
