import Tippy from '@tippyjs/react';
import Button from '^/components/atoms/Button';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { useL10n } from '^/hooks';
import {
  useCreateAlbumMutation,
  usePhotoAlbumIdQuery,
  usePhotoAlbumQuery,
} from '^/hooks/api/photos/usePhoto';
import { useRequestDroneCapture } from '^/hooks/api/viewpoint-capture/useViewpoint';
import { useViewpointStore } from '^/store/viewpointStore';
import theme from '^/theme';
import * as T from '^/types';
import React, { ReactNode, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ViewpointImagesList from '../ViewpointImagesList';
import { NoViewpointsInfo } from './NoViewpointsInfo';
import { RequestedInfo } from './RequestedInfo';
import Text from './text';
import SelectionBar from './ViewpointSelectionBar';

const Root = styled.div({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.white[100],
  width: '100%',
});

const ScrollableContainer = styled.div<{ isSelectionOpen?: boolean }>(({ isSelectionOpen }) => ({
  overflowY: 'auto',
  height: isSelectionOpen ? 'calc(100vh - 290px)' : 'calc(100vh - 195px)',
}));

const TitleContainer = styled.div({
  display: 'flex',
  marginBottom: '20px',
  gap: '5px',
  alignItems: 'center',
});
const TabTitle = styled.h2({
  fontSize: '24px',
  fontWeight: '700',
});

const BetaTag = styled.span({
  fontSize: '24px',
});

const ContentsContainer = styled.div({
  paddingTop: '32px',
});

const LoadingContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

const ButtonContainer = styled.div({
  display: 'flex',
});

export function ViewpointCaptureContentsList() {
  const [l10n] = useL10n();

  const { viewpointAlbum, isLoading: isPhotoAlbumsLoading } = usePhotoAlbumQuery();
  const { mutate: createPhotoAlbum } = useCreateAlbumMutation();

  useEffect(() => {
    if (isPhotoAlbumsLoading) {
      return;
    }

    // create a new album if not already
    if (!viewpointAlbum) {
      createPhotoAlbum({
        albumType: T.PhotoAlbumType.VIEWPOINT,
      });
    }
  }, [isPhotoAlbumsLoading, viewpointAlbum]);

  const { photos: viewpoints, isLoading } = usePhotoAlbumIdQuery(viewpointAlbum?.id);
  const { mutate: requestDroneCapture, isLoading: isRequestLoading } = useRequestDroneCapture();
  const selectedViewpointIds = useViewpointStore(s => s.selectedViewpointIds);
  const isViewpointUploading = useViewpointStore(s => s.isViewpointUploading);

  const handleRequestPhoto = () => {
    if (!viewpointAlbum?.id) {
      return;
    }
    requestDroneCapture({ photoAlbumId: viewpointAlbum.id });
  };

  const isPhotoRequested = viewpointAlbum?.status !== T.RequestPhotoStatus.PENDING;

  const tooltipContent = useMemo(() => {
    if (isPhotoRequested) {
      return l10n(Text.alreadyRequested);
    }
    if (viewpoints && viewpoints?.length > 10) {
      return l10n(Text.freeLimit);
    }
    return '';
  }, [viewpoints]);

  const shouldRequestButtonBeDisabled =
    Boolean(tooltipContent) || !viewpoints?.length || isRequestLoading || isPhotoRequested;

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingIcon />
      </LoadingContainer>
    );
  }

  const contents = (() => {
    const displayableContents: ReactNode[] = [];
    if (isPhotoRequested) {
      displayableContents.push(<RequestedInfo key="requested-info" />);
    }
    if (viewpoints?.length || isViewpointUploading) {
      displayableContents.push(
        <ViewpointImagesList
          viewpoints={viewpoints}
          isRequested={isPhotoRequested}
          requestedAt={viewpointAlbum?.requestedAt}
          key="viewpoint lists"
        />
      );
    } else {
      displayableContents.push(<NoViewpointsInfo key="no-viewpoints-info" />);
    }
    return (
      <ScrollableContainer isSelectionOpen={Boolean(selectedViewpointIds?.length)}>
        {displayableContents}
      </ScrollableContainer>
    );
  })();

  return (
    <Root>
      <ContentsContainer>
        <TitleContainer>
          <TabTitle>{l10n(Text.photoRequest)}</TabTitle>
          <BetaTag>{l10n(Text.beta)}</BetaTag>
        </TitleContainer>

        <SelectionBar />

        {contents}
      </ContentsContainer>
      <Tippy
        content={tooltipContent}
        theme="angelsw"
        disabled={!tooltipContent}
        maxWidth={200}
        arrow={false}
      >
        <ButtonContainer>
          <Button
            size="large"
            disabled={shouldRequestButtonBeDisabled}
            onClick={handleRequestPhoto}
            customStyles={{
              width: '100%',
            }}
          >
            {isPhotoRequested ? l10n(Text.requestCompleted) : l10n(Text.requestPhoto)}
          </Button>
        </ButtonContainer>
      </Tippy>
    </Root>
  );
}
