import { makeV2APIURL } from '^/store/duck/API';
import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getAuthedUserSerializer } from '../serializers-utils';
import { store } from '^/index';
import { ChangeAuthedUser } from '^/store/duck/Auth';

export enum METHODS {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface CustomAjaxResponse {
  response: any;
  status: number;
  originalEvent: { type: string; timeStamp: number };
  xhr: {
    statusText?: string;
    status: number;
    responseType?: string;
    responseURL?: string;
  };
  request: AxiosRequestConfig;
  responseText: string;
  responseType?: string;
}

interface QueueItem {
  reject(reason?: any): void;
  resolve(
    value: InternalAxiosRequestConfig<any> | PromiseLike<InternalAxiosRequestConfig<any>>
  ): void;
  config: InternalAxiosRequestConfig<any> | AxiosResponse<any, any>;
}

let isRefreshing = false;
let queue: QueueItem[] = [];

const processQueue = (token: string | null = null) => {
  queue.forEach(prom => {
    if (prom.config) {
      prom.config.headers = {
        ...prom.config.headers,
        Authorization: token,
      };
      prom.resolve(axios(prom.config));
    } else {
      throw new Error('In axios interceptors prom.config is undefined');
    }
  });
  queue = [];
};

// We are calling this instance which may have different base urls so we didn't configure it here
// Similarly we also can't configure the content type here because we may have dynamic content types
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async config => {
    const token = store.getState().Auth.authedUser?.token;
    if (token) {
      config.headers.Authorization = token;
    }
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        queue.push({ resolve, reject, config });
      });
    }
    return config;
  },
  async error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        originalRequest._retry = true;
        isRefreshing = true;
        try {
          const refreshToken = store.getState().Auth.authedUser?.refreshToken;
          if (refreshToken) {
            const URL = makeV2APIURL('auth', 'refresh-token');
            const response = await axios.post(URL, null, {
              headers: { Authorization: refreshToken },
            });
            const newToken = response?.data?.data?.attributes?.token;
            if (newToken) {
              isRefreshing = false;
              const newAuthUser = getAuthedUserSerializer(response.data);
              store.dispatch(ChangeAuthedUser({ authedUser: newAuthUser }));
              processQueue(newToken);
              originalRequest.headers.Authorization = newToken;
              return axiosInstance(originalRequest);
            }
          } else {
            store.dispatch(ChangeAuthedUser({}));
          }
        } catch (err) {
          store.dispatch(ChangeAuthedUser({}));
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          queue.push({ resolve, reject, config: originalRequest });
        });
      }
    }
    return Promise.reject(error);
  }
);

export const http = axiosInstance;

export const rxjsHttp = {
  get: function get<T = unknown>(
    url: string,
    config?: AxiosRequestConfig
  ): Observable<CustomAjaxResponse> {
    return defer(async () => http.get<T>(url, config)).pipe(
      map(response => serializeAxiosToAjax(response))
    );
  },
  post: function post<T = unknown>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Observable<CustomAjaxResponse> {
    return defer(async () => http.post<T>(url, data, config)).pipe(
      map(response => serializeAxiosToAjax(response))
    );
  },
  put: function put<T = unknown>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Observable<CustomAjaxResponse> {
    return defer(async () => http.put<T>(url, data, config)).pipe(
      map(response => serializeAxiosToAjax(response))
    );
  },
  patch: function patch<T = unknown>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Observable<CustomAjaxResponse> {
    return defer(async () => http.patch<T>(url, data, config)).pipe(
      map(response => serializeAxiosToAjax(response))
    );
  },
  delete: function _delete<T = unknown>(
    url: string,
    config?: AxiosRequestConfig
  ): Observable<CustomAjaxResponse> {
    return defer(async () => http.delete<T>(url, config)).pipe(
      map(response => serializeAxiosToAjax(response))
    );
  },
};

function serializeAxiosToAjax<T>(axiosResponse: AxiosResponse<T>): CustomAjaxResponse {
  return {
    response: axiosResponse.data,
    status: axiosResponse.status,
    originalEvent: {
      type: 'Axios',
      timeStamp: Date.now(),
    },
    xhr: {
      statusText: axiosResponse.statusText,
      status: axiosResponse.status,
      responseType: axiosResponse.config.responseType,
      responseURL: axiosResponse.config.url,
    },
    request: axiosResponse.config,
    responseText: JSON.stringify(axiosResponse.data),
    responseType: axiosResponse.config.responseType,
  };
}
