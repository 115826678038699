import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import * as T from '^/types';

interface ESSModelsState {
  selectedESSCategoryId: T.ESSModelsState['selectedCategoryId'] | undefined;
  selectedESSModelId: T.ESSModelInstance['id'] | undefined;
  essModelCategories: T.ESSModelCategory[];
  essModels: Record<T.ESSModelInstance['id'], T.ESSModelInstance>;
  essModelIds: Array<T.ESSModelInstance['id']>;
  essModelIdsByCategory: Record<T.ESSModelInstance['categoryId'], Array<T.ESSModelInstance['id']>>;
  essCustomModels: Record<T.ESSCustomModelInstance['id'], T.ESSCustomModelInstance>;
  fetchESSModelStatus: T.APIStatus;
  createESSCustomModelStatus: T.APIStatus;
  createESSModelCategoryStatus: T.APIStatus;
}
interface ESSModelsActions {
  setSelectedESSCategoryId(categoryId: T.ESSModelsState['selectedCategoryId'] | undefined): void;
  setSelectedESSModelId(essModelId: T.ESSModelInstance['id'] | undefined): void;
  addESSModelCategory(category: T.ESSModelCategory): void;
  setESSModelCategories(categories: T.ESSModelsState['categories']): void;
  setESSModel(instance: T.ESSModelInstance): void;
  setESSModels(essModels: Record<T.ESSModelInstance['id'], T.ESSModelInstance>): void;
  setESSModelIds(essModelIds: Array<T.ESSModelInstance['id']>): void;
  setESSModelIdsByCategory(
    categoryId: T.ESSModelInstance['categoryId'],
    instanceIds: Array<T.ESSModelInstance['id']>
  ): void;
  setESSCustomModel(instance: T.ESSCustomModelInstance): void;
  setESSCustomModels(
    essCustomModels: Record<T.ESSCustomModelInstance['id'], T.ESSCustomModelInstance>
  ): void;
  setFetchESSModelStatus(status: T.APIStatus): void;
  setCreateESSCustomModelStatus(status: T.APIStatus): void;
  setCreateESSModelCategoryStatus(status: T.APIStatus): void;
  reset(): void;
}

const initialState: ESSModelsState = {
  selectedESSCategoryId: undefined,
  selectedESSModelId: undefined,
  essModelCategories: [],
  essModels: {},
  essModelIds: [],
  essModelIdsByCategory: {},
  essCustomModels: {},
  fetchESSModelStatus: T.APIStatus.IDLE,
  createESSCustomModelStatus: T.APIStatus.IDLE,
  createESSModelCategoryStatus: T.APIStatus.IDLE,
};

type ESSModelsStore = ESSModelsState & ESSModelsActions;

export const essModelsStore = createStore<ESSModelsStore>()(
  devtools(set => ({
    ...initialState,
    setSelectedESSCategoryId(categoryId) {
      set({ selectedESSCategoryId: categoryId });
    },
    setSelectedESSModelId(essModelId) {
      set({ selectedESSModelId: essModelId });
    },
    addESSModelCategory(category) {
      set(state => {
        const essModelCategories = [...state.essModelCategories, category];
        return { essModelCategories };
      });
    },
    setESSModelCategories(categories) {
      set({ essModelCategories: categories });
    },
    setESSModel(instance) {
      set(state => {
        const essModels = { ...state.essModels, [instance.id]: instance };
        const essModelIds = state.essModelIds.includes(instance.id)
          ? state.essModelIds
          : [...state.essModelIds, instance.id];
        return {
          essModels,
          essModelIds,
        };
      });
    },
    setESSModels(essModels) {
      set({ essModels });
    },
    setESSModelIds(essModelIds) {
      set({ essModelIds });
    },
    setESSModelIdsByCategory(categoryId, instanceIds) {
      set(state => {
        const essModelIdsByCategory = {
          ...state.essModelIdsByCategory,
          [categoryId]: instanceIds,
        };
        return { essModelIdsByCategory };
      });
    },
    setESSCustomModel(instance) {
      set(state => {
        const essCustomModels = { ...state.essCustomModels, [instance.id]: instance };
        return { essCustomModels };
      });
    },
    setESSCustomModels(essCustomModels) {
      set({ essCustomModels });
    },
    setFetchESSModelStatus(status) {
      set({ fetchESSModelStatus: status });
    },
    setCreateESSCustomModelStatus(status) {
      set({ createESSCustomModelStatus: status });
    },
    setCreateESSModelCategoryStatus(status) {
      set({ createESSModelCategoryStatus: status });
    },
    reset() {
      set(initialState);
    },
  }))
);

export function useESSModelsStore(): ESSModelsStore;
export function useESSModelsStore<T>(selector: (state: ESSModelsStore) => T): T;
export function useESSModelsStore<T>(selector?: (state: ESSModelsStore) => T) {
  return useStore(essModelsStore, selector!);
}
