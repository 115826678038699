export const preloadImages = async (urls: string[]): Promise<void> =>
  new Promise((resolve, reject) => {
    let loadedImages = 0;
    const totalImages = urls.length;

    if (totalImages === 0) {
      resolve();
      return;
    }

    urls.forEach(url => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages += 1;
        if (loadedImages === totalImages) {
          resolve();
        }
      };
      img.onerror = err => reject(err);
    });
  });

export const imagesPerSecond = [
  {
    value: 2.0,
    label: '2 sec',
  },
  {
    value: 1.0,
    label: '1 sec',
  },
  {
    value: 0.9,
    label: '0.9 sec',
  },
  {
    value: 0.8,
    label: '0.8 sec',
  },
  {
    value: 0.7,
    label: '0.7 sec',
  },
  {
    value: 0.6,
    label: '0.6 sec',
  },
  { value: 0.5, label: '0.5 sec' },
  {
    value: 0.4,
    label: '0.4 sec',
  },
  {
    value: 0.3,
    label: '0.3 sec',
  },
  {
    value: 0.2,
    label: '0.2 sec',
  },
  {
    value: 0.1,
    label: '0.1 sec',
  },
];
