import * as T from '^/types';
import produce from 'immer';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface FlightScheduleCreationState {
  flightScheduleFields: T.RawFlightScheduleFields;
  flightScheduleErrors: FlightScheduleFieldsError;
}

export type FlightScheduleFieldsKey = keyof T.RawFlightScheduleFields;
export type FlightScheduleFieldsValue = T.RawFlightScheduleFields[FlightScheduleFieldsKey];

export type ErrorInputKeys = FlightScheduleFieldsKey;
export type FlightScheduleFieldsError = Partial<Record<ErrorInputKeys, boolean>>;

export interface FlightScheduleCreationActions {
  setFlightScheduleFields(key: FlightScheduleFieldsKey, value: FlightScheduleFieldsValue): void;
  setFlightScheduleFieldsError(key: ErrorInputKeys, value: boolean): void;
  setAllFlightScheduleFieldsError(
    updatedFlightScheduleFieldsErrors: FlightScheduleFieldsError
  ): void;
  resetToInitial(): void;
  resetSingleFlightScheduleField(key: FlightScheduleFieldsKey): void;
  resetFlightScheduleFields(): void;
}

const flightScheduleIntitialState: FlightScheduleCreationState = {
  flightScheduleFields: {
    title: '',
    selectedFlightPlan: undefined,
    selectedDroneStation: undefined,
    selectedPlanTimer: T.FlightScheduleScheduleType.IMMEDIATE,
    selectedLostAction: T.FlightScheduleLostActionType.RETURN_TO_HOME,
    rthAltitude: 20,
    selectedExecuteDate: {
      from: undefined,
      to: undefined,
    },
    selectedExecuteTime: '',
    selectedExecuteEndTime: '',
    batteryLevel: 0,
    storageLevel: 0,
  },
  flightScheduleErrors: {
    title: false,
    selectedFlightPlan: false,
    selectedDroneStation: false,
    selectedPlanTimer: false,
    selectedLostAction: false,
    rthAltitude: false,
    selectedExecuteDate: false,
    selectedExecuteTime: false,
    selectedExecuteEndTime: false,
    batteryLevel: false,
    storageLevel: false,
  },
};

type FlightScheduleCreationStore = FlightScheduleCreationState & FlightScheduleCreationActions;

export const useFlightScheduleCreationStore = create<FlightScheduleCreationStore>()(
  devtools(set => ({
    ...flightScheduleIntitialState,
    setFlightScheduleFields(key, value) {
      set(
        produce(state => {
          state.flightScheduleFields[key] = value;
        })
      );
    },
    setFlightScheduleFieldsError(key, value) {
      set(
        produce(state => {
          state.flightScheduleErrors[key] = value;
        })
      );
    },
    setAllFlightScheduleFieldsError(updatedFlightScheduleFieldsErrors) {
      set(
        produce(state => {
          state.flightScheduleErrors = updatedFlightScheduleFieldsErrors;
        })
      );
    },
    resetToInitial() {
      set(() => ({ ...flightScheduleIntitialState }));
    },
    resetSingleFlightScheduleField(key) {
      set(
        produce(state => {
          state.flightScheduleFields[key] = flightScheduleIntitialState.flightScheduleFields[key];
        })
      );
    },
    resetFlightScheduleFields() {
      const {
        selectedExecuteDate,
        batteryLevel,
        storageLevel,
        selectedExecuteTime,
        selectedExecuteEndTime,
      } = flightScheduleIntitialState.flightScheduleFields;
      const {
        selectedExecuteDate: selectedExecuteDateError,
        batteryLevel: batteryLevelError,
        storageLevel: storageLevelError,
        selectedExecuteTime: selectedExecuteTimeError,
        selectedExecuteEndTime: selectedExecuteEndTimeError,
      } = flightScheduleIntitialState.flightScheduleErrors;
      set(
        produce(state => {
          state.flightScheduleFields = {
            ...state.flightScheduleFields,
            selectedExecuteDate,
            batteryLevel,
            storageLevel,
            selectedExecuteTime,
            selectedExecuteEndTime,
          };
          state.flightScheduleErrors = {
            ...state.flightScheduleErrors,
            selectedExecuteDate: selectedExecuteDateError,
            batteryLevel: batteryLevelError,
            storageLevel: storageLevelError,
            selectedExecuteTime: selectedExecuteTimeError,
            selectedExecuteEndTime: selectedExecuteEndTimeError,
          };
        })
      );
    },
  }))
);
