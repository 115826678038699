import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import * as T from '^/types';

interface IssueState {
  issueCategoryOptions: T.IssueCategory[];
  issueStatusOptions: T.IssueStatus[];
  isShowingIssuesRelatedToMe: boolean;
  selectedGroupByCriteria: T.IssueGroupByCriteria;
  issuesContentAllIds: Array<number | string>;
  issuesContentsById: Record<string | number, T.IssueContent>;
  editingIssueContentId?: number | string;
  hoverIssueId: number | null;
  deletingIssueContentId?: number | string;
  selectedIssueId: number | null;
}

interface IssueActions {
  setIssueCategoryOptions(newIssueCategoryOptions: T.IssueCategory[]): void;
  setIssueStatusOptions(newIssueStatusOptions: T.IssueStatus[]): void;
  setIsShowingIssuesRelatedToMe(newIsShowingIssuesRelatedToMe: boolean): void;
  setSelectedGroupByCriteria(newSelectedGroupByCriteria: T.IssueGroupByCriteria): void;
  setIssuesContentsById(issuesContentsById: Record<string | number, T.IssueContent>): void;
  setIssuesContentAllIds(issueIds: Array<number | string>): void;
  setEditingIssueContentId(id?: number | string): void;
  setDeletingIssueContentId(id?: number | string): void;
  setHoverIssueId(value: number | null): void;
  setSelectedIssueId(value: number | null): void;
  resetHoverAndSelected(): void;
}

type IssueStore = IssueState & IssueActions;

export const useIssueStore = create<IssueStore>()(
  devtools(set => ({
    issueCategoryOptions: [],
    issueStatusOptions: [],
    isShowingIssuesRelatedToMe: false,
    issuesContentsById: {},
    issuesContentAllIds: [],
    issueContents: [],
    editingIssueContentId: undefined,
    hoverIssueId: null,
    selectedIssueId: null,
    selectedGroupByCriteria: T.IssueGroupByCriteria.STATUS,
    setIssueCategoryOptions: (newIssueCategoryOptions: T.IssueCategory[]) =>
      set(issueStore => ({
        ...issueStore,
        issueCategoryOptions: newIssueCategoryOptions,
      })),
    setIssueStatusOptions: (newIssueStatusOptions: T.IssueStatus[]) =>
      set(issueStore => ({
        ...issueStore,
        issueStatusOptions: newIssueStatusOptions,
      })),
    setIsShowingIssuesRelatedToMe: (newIsShowingIssuesRelatedToMe: boolean) =>
      set(issueStore => ({
        ...issueStore,
        isShowingIssuesRelatedToMe: newIsShowingIssuesRelatedToMe,
      })),
    setSelectedGroupByCriteria: (newSelectedGroupByCriteria: T.IssueGroupByCriteria) =>
      set(issueStore => ({
        ...issueStore,
        selectedGroupByCriteria: newSelectedGroupByCriteria,
      })),
    setIssuesContentsById: (issuesContentsById: Record<number, T.IssueContent>) => {
      set(issueStore => ({
        ...issueStore,
        issuesContentsById,
      }));
    },
    setIssuesContentAllIds: (issueIds: number[]) =>
      set(issueStore => ({
        ...issueStore,
        issuesContentAllIds: issueIds,
      })),
    setEditingIssueContentId: (issueId: number) =>
      set(issueStore => ({
        ...issueStore,
        editingIssueContentId: issueId,
      })),
    setDeletingIssueContentId: (value?: number | string) =>
      set(issueStore => ({
        ...issueStore,
        deletingIssueContentId: value,
      })),
    // For 3d models
    setHoverIssueId: (value: number | null) =>
      set(issueStore => ({
        ...issueStore,
        hoverIssueId: value,
        selectedIssueId: null,
      })),
    setSelectedIssueId: (value: number | null) =>
      set(issueStore => ({
        ...issueStore,
        selectedIssueId: value,
        hoverIssueId: null,
      })),
    resetHoverAndSelected: () =>
      set(issueStore => ({
        ...issueStore,
        hoverIssueId: null,
        selectedIssueId: null,
      })),
  }))
);
