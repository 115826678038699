import { DeleteContent, PatchContent, PatchIssueContent } from '^/store/duck/Contents';
import { ChangeEditingContent } from '^/store/duck/Pages';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useUndoStore } from '^/store/undo';
import * as T from '^/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from 'typesafe-actions';
import { useESSContents } from './useESSContents';
import useIssueContents from './useIssueContents';

enum UndoType {
  PATCH = 'patch',
  POST = 'post',
}
type PatchContentPayload = Omit<ActionType<typeof PatchContent>, 'type'>;
type PatchIssueContentPayload = Omit<ActionType<typeof PatchIssueContent>, 'type'>;

export interface UndoPatchItem {
  type: UndoType.PATCH;
  pushedAt: Date;
  id: T.Content['id'];
  content:
    | (PatchContentPayload & {
        category: Omit<T.ContentCategory, T.ContentCategory.ESS | T.ContentCategory.ISSUE>;
      })
    | (PatchIssueContentPayload & {
        category: T.ContentCategory.ISSUE;
      });
}

export interface UndoPostItem {
  type: UndoType.POST;
  pushedAt: Date;
  id: T.Content['id'];
  content: {
    category: Omit<T.ContentCategory, T.ContentCategory.ISSUE>;
    contentId: T.Content['id'];
  };
}
export type UndoItem = UndoPatchItem | UndoPostItem;

export const createPatchUndoItem = (content: UndoPatchItem['content']): UndoPatchItem => ({
  content,
  type: UndoType.PATCH,
  pushedAt: new Date(),
  id: content.content.id,
});

export const createPostUndoItem = (content: UndoPostItem['content']): UndoPostItem => ({
  content,
  type: UndoType.POST,
  pushedAt: new Date(),
  id: content.contentId,
});

/**
 * Hooks to sync with redux on undo
 * @author Gaurav Pandey
 */
export function useUndoAction() {
  const { deleteESSContent, updateESSContent } = useESSContents();
  const { patchIssueContent } = useIssueContents();
  const { setEditingESSContentId } = useESSContentsStore(s => ({
    setEditingESSContentId: s.setEditingESSContentId,
  }));

  const patchContentStatus = useSelector((s: T.State) => s.Contents.patchContentStatus);

  const dispatch = useDispatch();
  const undoStorePop = useUndoStore(s => s.pop);
  const undoStack = useUndoStore(s => s.undoStack);

  function isUndoPossible(): boolean {
    return patchContentStatus !== T.APIStatus.PROGRESS;
  }

  function changeEditingId(id: T.Content['id'], category: T.ContentCategory): void {
    switch (category) {
      case T.ContentCategory.ESS: {
        dispatch(ChangeEditingContent({}));
        setEditingESSContentId(id);
        break;
      }
      default: {
        dispatch(ChangeEditingContent({ contentId: id }));
        break;
      }
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        const undoItem = undoStack[undoStack.length - 1];
        if (!undoItem) {
          return;
        }
        switch (undoItem.type) {
          case UndoType.PATCH: {
            const { content, category } = undoItem.content;

            undoStorePop();
            switch (undoItem.content.category) {
              case T.ContentCategory.ESS:
                void updateESSContent({
                  content,
                  undoTimeStamp: undoItem.pushedAt,
                });
                break;
              case T.ContentCategory.ISSUE: {
                patchIssueContent({ content, undoTimeStamp: undoItem.pushedAt });
                break;
              }
              default: {
                dispatch(PatchContent({ content, undoTimeStamp: undoItem.pushedAt }));
              }
            }

            changeEditingId(content.id, category as T.ContentCategory);

            break;
          }

          case UndoType.POST: {
            const { contentId, category } = undoItem.content;
            if (isUndoPossible()) {
              undoStorePop();
              switch (undoItem.content.category) {
                case T.ContentCategory.ESS: {
                  void deleteESSContent({ id: contentId });
                  break;
                }
                case T.ContentCategory.ISSUE: {
                  // dispatch(deleteIssue({ id }));
                  break;
                }
                default: {
                  dispatch(DeleteContent({ contentId }));
                }
              }
            } else {
              changeEditingId(contentId, category as T.ContentCategory);
            }
            break;
          }
          default:
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isUndoPossible, changeEditingId, undoStack]);
}
