import { Language } from '^/types';

export default {
  tooltipProjectLogo: {
    [Language.KO_KR]: '프로젝트 보드',
    [Language.EN_US]: 'Project board',
  },
  backToWorkspace: {
    [Language.KO_KR]: '워크스페이스로 돌아가기',
    [Language.EN_US]: 'Back to Workspace',
  },
  downloadData: {
    [Language.KO_KR]: '데이터 다운로드',
    [Language.EN_US]: 'Download Data',
  },
  dataHistory: {
    [Language.KO_KR]: '데이터 이력',
    [Language.EN_US]: 'Data History',
  },
  print: {
    [Language.KO_KR]: '인쇄 파일 요청',
    [Language.EN_US]: 'Request Files to Print',
  },
  measurementReport: {
    [Language.KO_KR]: '측정 보고서',
    [Language.EN_US]: 'Measurement Report',
  },
  invite: {
    [Language.KO_KR]: '초대',
    [Language.EN_US]: 'Invite',
  },
  share: {
    [Language.KO_KR]: '외부 공유',
    [Language.EN_US]: 'Share',
  },
  goToUserGuide: {
    [Language.KO_KR]: '유저 가이드',
    [Language.EN_US]: 'Go to User Guide',
  },
};
