import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { parse } from '@loaders.gl/core';
import { KMLLoader } from '@loaders.gl/kml';
import { useAuthHeader } from '^/hooks';
import { droneStationAuthStore } from '^/store/droneStationAuthStore';
import { AuthHeader, makeDJIBucketURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { APIToContent } from '^/store/duck/Contents';
import { OpenContentPagePopup } from '^/store/duck/Pages/Content';
import { useFlightPlanStore } from '^/store/flightPlanStore';
import * as T from '^/types';
import { http } from '^/utilities/api';
import { fetchAndExtractKMZ } from '^/utilities/extract-kmz';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ERROR } from '../types';
import { getFlightPlanDeleteUrl, getFlightPlanFetchUrl, getFlightPlanUploadUrl } from './common';

export interface RawPagination {
  readonly page: number;
  readonly total: number;
  readonly page_size: number;
}
export interface CreateFlightPlanResponse {
  readonly code: number;
  readonly message: string;
  readonly data?: string;
}

export type PatchFlightPlanResponse = CreateFlightPlanResponse;
export type DeleteFlightPlanResponse = CreateFlightPlanResponse;

export interface TGetFlightPlanResponse {
  readonly code: number;
  readonly message: string;
  readonly data: {
    readonly list: T.RawFlightPlan[];
    readonly pagination: RawPagination;
  };
}

interface TGetAllFlightPlans {
  readonly pageParam: number;
  readonly pageLimit?: number;
  readonly projectId: T.Project['id'] | undefined;
  readonly selectedFlightPlanSort: T.FlightPlanSortByCriteria;
  readonly authHeader: AuthHeader | undefined;
}

export enum FLIGHT_PLAN_QUERY {
  GetAllFlightPlans = 'GetAllFlightPlans',
}

async function getAllFlightPlans({
  pageParam,
  pageLimit,
  projectId,
  selectedFlightPlanSort,
  authHeader: paramAuthHeader,
}: TGetAllFlightPlans) {
  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const sortOrOrderBy = (() => {
    switch (selectedFlightPlanSort) {
      case T.FlightPlanSortByCriteria.OLDEST:
        return 'update_time asc';
      // case T.FlightPlanSortByCriteria.NAME:
      case T.FlightPlanSortByCriteria.NEWEST:
      default:
        return 'update_time desc';
    }
  })();

  const flightPlanFetchUrl = getFlightPlanFetchUrl({
    page: pageParam || 1,
    page_size: pageLimit || 10,
    order_by: sortOrOrderBy,
    favorited: false,
  });

  const fetchFlightPlanResponse = await http.get<TGetFlightPlanResponse>(flightPlanFetchUrl, {
    headers: {
      Authorization: `Bearer ${paramAuthHeader.Authorization}`,
      projectId: projectId,
      'X-Auth-Token': droneStationAuthStore.getState().authDetails.accessToken,
    },
  });

  const flightPlans = await Promise.all(
    fetchFlightPlanResponse.data.data.list.map(async rawFlightPlan => {
      const kmlString = await fetchAndExtractKMZ(makeDJIBucketURL(rawFlightPlan.object_key));
      const parsedKmlData = (await parse(kmlString, KMLLoader)) as T.GeoJSONTableKML;
      const finalParsedKmlData = parsedKmlData.features.map(singleParsedKmlData => ({
        geometryType: singleParsedKmlData.geometry.type.toLowerCase() || undefined,
        geometryCoords: singleParsedKmlData.geometry.coordinates[0],
        geometryProperties: singleParsedKmlData.properties,
      }));

      const serializedFlightPlan = {
        name: rawFlightPlan.name,
        id: rawFlightPlan.id,
        sign: rawFlightPlan.sign,
        favorited: rawFlightPlan.favorited,
        droneModelKey: rawFlightPlan.drone_model_key,
        payloadModelKeys: rawFlightPlan.payload_model_keys,
        templateTypes: rawFlightPlan.template_types,
        objectKey: rawFlightPlan.object_key,
        userName: rawFlightPlan.user_name,
        updateTime: rawFlightPlan.update_time,
        createTime: rawFlightPlan.create_time,
        parsedKmlData: finalParsedKmlData,
      } as T.FlightPlan;

      return APIToContent({
        type: T.ContentType.FLIGHT_PLAN,
        projectId,
        ...serializedFlightPlan,
      } as T.APIFlightPlanContent);
    })
  );

  return {
    list: flightPlans,
    pagination: fetchFlightPlanResponse.data.data.pagination,
  };
}
export const useGetFlightPlansQuery = () => {
  const authHeader = useAuthHeader();
  const projectId: T.Project['id'] | undefined = useSelector(
    (state: T.State) => state.Pages.Contents.projectId
  );
  const dispatch: Dispatch = useDispatch();

  const { selectedFlightPlanSort, setFlightPlans, setFlightPlanIds } = useFlightPlanStore(s => ({
    selectedFlightPlanSort: s.selectedFlightPlanSort,
    setFlightPlans: s.setFlightPlans,
    setFlightPlanIds: s.setFlightPlanIds,
  }));

  return useInfiniteQuery({
    queryKey: [FLIGHT_PLAN_QUERY.GetAllFlightPlans, selectedFlightPlanSort],
    queryFn: async ({ pageParam }) =>
      getAllFlightPlans({ pageParam, projectId, selectedFlightPlanSort, authHeader }),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    getNextPageParam: lastPage => {
      const { pagination } = lastPage;
      const { page, total, page_size } = pagination;
      const totalPages = Math.ceil(total / page_size);

      if (page < totalPages) {
        return page + 1;
      } else {
        return undefined;
      }
    },
    onSuccess(data) {
      const receievedFlightPlans = data.pages.flatMap(page => page.list) || [];
      const modifiedReceievedFlightPlans = receievedFlightPlans.reduce(
        (acc, flightPlan) => ({ ...acc, [flightPlan.id]: flightPlan }),
        {}
      );
      setFlightPlans(modifiedReceievedFlightPlans);
      setFlightPlanIds();
    },
    onError: (error: AxiosError) => {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      }
    },
  });
};

/**
 * All about the drone-station mutations...
 */
interface TPostFlightPlan {
  readonly file: File;
  readonly projectId: T.Project['id'] | undefined;
  readonly authHeader: AuthHeader | undefined;
}
async function postFlightPlan({ file, projectId, authHeader: paramAuthHeader }: TPostFlightPlan) {
  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const { accessToken } = droneStationAuthStore.getState().authDetails;

  const authHeader = {
    Authorization: `Bearer ${paramAuthHeader.Authorization}`,
    projectId: projectId,
    'X-Auth-Token': accessToken,
  };

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await http.post<CreateFlightPlanResponse>(getFlightPlanUploadUrl(), formData, {
      headers: authHeader,
    });

    /**
     * Added this condition as even for failure it response with status code 200.
     */
    if (response.data.code === -1) {
      throw new Error(response.data.message || 'Something went wrong. Please try again.');
    }
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
}

export const usePostFlightPlanMutation = () => {
  const queryClient = useQueryClient();
  const dispatch: Dispatch = useDispatch();

  const { mutate: postFlightPlanMutation } = useMutation({
    mutationFn: postFlightPlan,
    onSuccess: async () => {
      await queryClient.invalidateQueries([FLIGHT_PLAN_QUERY.GetAllFlightPlans]);
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.FLIGHT_PLAN_UPLOAD_SUCCESS,
        })
      );
    },
    onError: (error: AxiosError) => {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      }
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.FLIGHT_PLAN_UPLOAD_FAIL,
          errorMessage: error.message,
        })
      );
    },
  });

  return postFlightPlanMutation;
};

interface TDeleteFlightPlan {
  readonly id: T.FlightPlanContent['id'];
  readonly projectId: T.Project['id'] | undefined;
  readonly authHeader: AuthHeader | undefined;
}
async function deleteFlightPlan({ id, projectId, authHeader: paramAuthHeader }: TDeleteFlightPlan) {
  if (!paramAuthHeader) {
    throw new Error(ERROR.AuthHeaderUndefinedError);
  }

  const { accessToken } = droneStationAuthStore.getState().authDetails;

  const authHeader = {
    Authorization: `Bearer ${paramAuthHeader.Authorization}`,
    projectId: projectId,
    'X-Auth-Token': accessToken,
  };

  const flightUploadUrl: string = getFlightPlanDeleteUrl(id);
  try {
    const response = await http.delete<DeleteFlightPlanResponse>(flightUploadUrl, {
      headers: authHeader,
    });

    if (response.data.code === -1) {
      throw new Error(response.data.message || 'Something went wrong. Please try again.');
    }
    return response;
  } catch (error) {
    throw new Error(ERROR.OtherError);
  }
}
export const useDeleteFlightPlanMutation = () => {
  const queryClient = useQueryClient();
  const dispatch: Dispatch = useDispatch();

  const { mutate: deleteFlightPlanMutation } = useMutation({
    mutationFn: deleteFlightPlan,
    onSuccess: async () => {
      await queryClient.invalidateQueries([FLIGHT_PLAN_QUERY.GetAllFlightPlans]);
    },
    onError: (error: AxiosError) => {
      if (error.message === ERROR.AuthHeaderUndefinedError) {
        dispatch(ChangeAuthedUser({}));
      }
    },
  });

  return deleteFlightPlanMutation;
};
