import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import { useEffect } from 'react';

export function useChannelIO() {
  function boot(
    options?: Partial<ChannelService.BootOption>,
    errorCallback?: ChannelService.Callback
  ) {
    ChannelService.boot(
      {
        pluginKey: import.meta.env.VITE_CHANNEL_PLUGIN_KEY,
        hideChannelButtonOnBoot: true,
        ...options,
      },
      errorCallback
    );
  }

  useEffect(() => {
    ChannelService.loadScript();
  }, []);

  return { service: ChannelService, boot };
}
