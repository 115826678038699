import { useClickOutside } from '^/hooks';
import { usePartialVisibility } from '^/hooks/usePartialVisibility';
import theme from '^/theme';
import React, { FC, PropsWithChildren } from 'react';
import styled, { CSSObject } from 'styled-components';

interface ContextMenuRootProps {
  top: number;
  left: number;
  partiallyVisibleStyles?: CSSObject;
}
interface CallbackTypes {
  onClose(): void;
}
const ContextMenuRoot = styled.div<ContextMenuRootProps>(
  ({ top, left, partiallyVisibleStyles }) => ({
    zIndex: 9999,
    display: 'flex',
    fontSize: '10.5px',
    lineHeight: '18px',
    position: 'fixed',
    borderRadius: '4px',
    backgroundColor: theme.colors.blackVariants.blackLight,
    boxShadow: theme.colors.black[10],
    top: `${top}px`,
    left: `${left}px`,
    color: theme.colors.white[100],
    overflow: 'hidden',
    transition: 'opacity 0.2s ease',
    // this animation is to prevent the flash of untranslated menu when its partially visible in the viewport
    animation: 'appear-later 0.35s ease',
    '@keyframes appear-later': {
      '0%': {
        opacity: 0,
      },
      '95%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    ...partiallyVisibleStyles,
  })
);

export const ContextMenuWrapper: FC<PropsWithChildren<ContextMenuRootProps & CallbackTypes>> = ({
  top,
  left,
  onClose,
  partiallyVisibleStyles,
  children,
}) => {
  const [ref, isPartiallyVisible] = usePartialVisibility();
  useClickOutside({ ref, callback: onClose });

  return (
    <ContextMenuRoot
      ref={ref}
      left={left}
      top={top}
      partiallyVisibleStyles={isPartiallyVisible ? partiallyVisibleStyles : undefined}
    >
      {children}
    </ContextMenuRoot>
  );
};
