import * as T from '^/types';

export default {
  dueDate: {
    [T.Language.KO_KR]: '마감 기한',
    [T.Language.EN_US]: 'Due Date',
  },
  placeholder: {
    [T.Language.KO_KR]: '날짜 선택',
    [T.Language.EN_US]: 'Choose a Date',
  },
  weekday: {
    [T.Language.KO_KR]: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
    [T.Language.EN_US]: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  year: {
    [T.Language.KO_KR]: '년',
    [T.Language.EN_US]: '',
  },
  month: {
    [T.Language.KO_KR]: [
      '1월',
      '2월',
      '3월',
      '4월',
      '5월',
      '6월',
      '7월',
      '8월',
      '9월',
      '10월',
      '11월',
      '12월',
    ],
    [T.Language.EN_US]: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  date: {
    [T.Language.KO_KR]: '일',
    [T.Language.EN_US]: '',
  },
  weekdaysShort: {
    [T.Language.KO_KR]: '일월화수목금토일'.split(''),
    [T.Language.EN_US]: 'SMTWTFS'.split(''),
  },
  /**
   * @desc React-daypicker uses attr tag with alt
   * Giving it as [''], it makes alt text hide
   */
  weekdaysLong: {
    [T.Language.KO_KR]: [''],
    [T.Language.EN_US]: [''],
  },
  cancel: {
    [T.Language.KO_KR]: '취소',
    [T.Language.EN_US]: 'Cancel',
  },
  setDate: {
    [T.Language.KO_KR]: '확인',
    [T.Language.EN_US]: 'Confirm',
  },
};
