import React from 'react';
import styled from 'styled-components';
import { MediaQuery } from '^/constants/styles';
import NameContent from '^/components/atoms/NameContent';
import palette from '^/constants/palette';

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  direction: 'ltr',
  alignItems: 'center',

  fontSize: '10px',

  borderTop: 'solid 1px #CCCCCC',
  ':first-of-type': {
    borderTop: 'none',
  },

  padding: '10px',

  [MediaQuery.MOBILE_L]: {
    width: '100%',
  },

  cursor: 'pointer',

  '&:hover': {
    background: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  },
});

interface Props {
  id: number;
  name: string;
  email: string;
  avatar?: string;
  onSelect?(): void;
}

function IssuePersonDetail({ id, name, email, avatar, onSelect }: Props) {
  function handleOnSelect() {
    onSelect?.();
  }

  return (
    <Root onClick={handleOnSelect}>
      <NameContent id={id} name={name} email={email} avatar={avatar} />
    </Root>
  );
}

export default IssuePersonDetail;
