import { Tuple } from '^/components/molecules/MyPage/Tuple';
import theme from '^/theme';
import React from 'react';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

const Badge = styled.div({
  padding: '4px 8px',
  border: `1px solid ${theme.colors.neutralGray[200]}`,
  borderRadius: '8px',
  color: theme.colors.neutralGray[500],
  fontSize: '20px',
  fontWeight: '700',
  width: 'fit-content',
});

export default function MyPagePlan() {
  return (
    <Root>
      <GroupedSection title="Subscription">
        <Tuple title="Current Plan">
          <Badge>Free</Badge>
        </Tuple>
      </GroupedSection>
    </Root>
  );
}
