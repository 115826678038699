import { makeV2APIURL } from '^/store/duck/API';

export enum USER_KEYS {
  GetUserInfo = 'GetUserInfo',
  GetAllUserIds = 'GetAllUsers',
  GetNotice = 'GetNotice',
  GetNotification = 'GetNotification',
}

export const UserKeys = {
  getAllUserInfoKey: [USER_KEYS.GetAllUserIds],
  getUserInfoKey: (id?: number): [USER_KEYS.GetUserInfo, { id?: number }] => [
    USER_KEYS.GetUserInfo,
    { id },
  ],
  getNoticeKey: [USER_KEYS.GetNotice],
  getNotificationKey: [USER_KEYS.GetNotification],
};

export const UserURLs = {
  getUserInfo: (id: number) => makeV2APIURL('users', id),
};
