import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import styled from 'styled-components';

// <-- Seeker Design --> //
export const SliderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
});

export const SeekTimerText = styled.span<{ seekMouse: number }>(({ seekMouse }) => ({
  position: 'absolute',
  bottom: '20px',
  left: `${seekMouse}%`,
  transform: 'translateX(-50%)',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  textAlign: 'center',
  color: palette.white.toString(),
  borderRadius: '5px',
  opacity: seekMouse > 0 ? 1 : 0,
  transition: 'opacity 0.2s',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  img: {
    width: '150px',
    height: '100px',
    borderRadius: '5px',
    border: '4px solid white',
    marginBottom: '10px',
    objectFit: 'cover',
  },
}));

export const SliderInput = styled.input<{ value: number; max: number }>(({ value, max }) => ({
  '-webkit-appearance': 'none',
  width: '100%',
  height: '5px',
  position: 'relative',
  background: `linear-gradient(
    to right,
    ${palette.white.toString()} ${(value / max) * 100}%,
    ${palette.gray.toString()} ${(value / max) * 100}%
  )`,
  borderRadius: '5px',
  outline: 'none',
  transition: 'all 1s ease-in',

  '&::-webkit-slider-thumb': {
    '-webkit-appearance': 'none',
    appearance: 'none',
    width: '20px',
    height: '20px',
    background: palette.white.toString(),
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all 1s ease',
  },

  '&::-moz-range-thumb': {
    width: '20px',
    height: '20px',
    background: palette.white.toString(),
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all 1s ease',
  },
}));

export const ControlButtonIconWrapper = styled.div<{ isMain?: boolean; isHidden?: boolean }>(
  ({ isMain, isHidden }) => ({
    position: isMain ? 'absolute' : 'initial',
    zIndex: 999,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: isMain ? '60px' : '0',
    width: isMain ? '60px' : '0',
    borderRadius: isMain ? '50%' : '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isMain ? dsPalette.black.toString() : 'transparent',
    opacity: isHidden ? '0' : '1',
    transition: 'all 0.4s ease-in-out',
    cursor: 'pointer',

    ':hover': {
      height: isMain ? '65px' : '0',
      width: isMain ? '65px' : '0',
    },
  })
);

export const ControlButtonIcon = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
