import * as T from '^/types';

export default {
  download: {
    progressMessage: {
      [T.Language.KO_KR]: '다운로드 중...',
      [T.Language.EN_US]: 'Downloading...',
    },
  },
  parse: {
    progressMessage: {
      [T.Language.KO_KR]: '위치 가져오기...',
      [T.Language.EN_US]: 'Getting Locations...',
    },
  },
  fetch: {
    progressMessage: {
      [T.Language.KO_KR]: '싣고 있는...',
      [T.Language.EN_US]: 'Loading...',
    },
  },
};
