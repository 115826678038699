import React, { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import {
  TIMELAPSE_IMAGE_TYPE,
  TimelapseImageType,
  useTimelapseStore,
} from '^/store/zustand/timelapse/timelapseStore';

import { SelectContent, SvgButton } from '../PhotoInspectionList';
import { Checkbox, PhotoListWrapper } from '../PhotoList/style';
import * as T from '^/types';

import dsPalette from '^/constants/ds-palette';
import ExcludeSvg from '^/assets/icons/timelapse/exclude.svg';
import IncludeSvg from '^/assets/icons/timelapse/include.svg';
import TimelapseBlueSvg from '^/assets/icons/timelapse/timelapse-blue.svg';

import { defaultToastErrorOption, useL10n, UseL10n, UseToast, useToast } from '^/hooks';

import Text from './text';
import { ParentTimeLapse } from '../Timelapse';
import useTimelapseContents from '^/hooks/timelapse/useTimelapseContents';
import { useDispatch, useSelector } from 'react-redux';
import { TimelapseImagesItem } from './Components';
import SkeletonLoaderImages from '../TimelapseCollectionList/SkeletonLoaderImages';
import { SetSelectedMedia } from '^/store/duck/Photos';
import { useParams } from 'react-router-dom';
import { TimelapseTitleEditable } from './TimelapseTitleEditable';

const TimelapsePhotosWrapper = styled(PhotoListWrapper)({
  paddingBottom: '20px',
});

const TimelapseInspectionlistContainer = styled.div({
  height: '100%',
  width: '100%',
  overflowY: 'scroll',
  marginTop: '20px',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
});

const SubHeadingWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '20px',
  minHeight: '50px',
  position: 'sticky',
  top: '0px',
  zIndex: 4,
  backgroundColor: dsPalette.white.toString(),
});

export const CheckboxWrapper = styled.div<{ isSelected: boolean }>(({ isSelected }) => ({
  display: isSelected ? 'flex' : 'none',
  alignItems: 'center',
}));

const SubHeadingTitleWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginLeft: '8px',

  [`&:hover ${CheckboxWrapper}`]: {
    display: 'flex',
  },
});

const SubHeadingTitle = styled.div({
  fontSize: '16px',
  fontWeight: '600',
  color: dsPalette.typeGrey.toString(),
});

const CreateTimelapseButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: dsPalette.themePrimary.toString(),
  borderRadius: '6px',
  padding: '15px 25px',
  fontSize: '12px',
  fontWeight: '600',
  color: dsPalette.white.toString(),
  transition: 'opacity 0.3s ease-in-out',
  cursor: 'pointer',

  ':hover': {
    opacity: 0.8,
  },
});

interface Groups {
  TimelapseImages: TimelapseImageType[];
  ExcludeImages: TimelapseImageType[];
}

const TimelapseInspectionlist: React.FC = () => {
  const [l10n]: UseL10n = useL10n();
  const toastify: UseToast = useToast();

  const {
    selectedState,
    timelapseCollections,
    timelapseImages: showTimelapseImage,
    selectedCollectionId,
    timelapseInspectionImages,
    setSelectedState,
    setTimelapseImages,
    setTimelapseInspectionImages,
    getTimelapseInspectionImagesStatus,
    setTimelapseInspectionImagesStatus,
  } = useTimelapseStore(s => ({
    timelapseCollections: s.timelapseCollections,
    selectedState: s.selectedState,
    selectedCollectionId: s.selectedCollectionId,
    timelapseImages: s.timelapseImages,
    timelapseInspectionImages: s.timelapseInspectionImages,
    getTimelapseInspectionImagesStatus: s.getTimelapseInspectionImagesStatus,
    setSelectedState: s.setSelectedState,
    setSelectedCollectionId: s.setSelectedCollectionId,
    setTimelapseImages: s.setTimelapseImages,
    setTimelapseInspectionImages: s.setTimelapseInspectionImages,
    setTimelapseInspectionImagesStatus: s.setTimelapseInspectionImagesStatus,
  }));

  const selectedTimelapseCollection = timelapseCollections?.find(
    collection => selectedCollectionId === collection.id
  );

  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const { bulkDeletePhotoStatus, isDownloading, selectedMedia } = useSelector(
    (s: T.State) => s.Photos
  );
  const isTimelapseMode = selectedState === TIMELAPSE_IMAGE_TYPE.TIMELAPSE;
  const isInProgress = getTimelapseInspectionImagesStatus === T.APIStatus.PROGRESS;
  const hasError = getTimelapseInspectionImagesStatus === T.APIStatus.ERROR;
  const { getTimelapseInspectionImages, includeExcludeTimelapseImage } = useTimelapseContents(
    Number(projectId)
  );

  const contentImages = useMemo(
    () =>
      timelapseInspectionImages.reduce<Groups>(
        (acc, item) => {
          if (
            item.locationsWithExcluded?.filter(
              location => location.timelapseLocationId === selectedCollectionId
            )?.[0]?.excluded
          ) {
            acc.ExcludeImages.push(item);
          } else {
            acc.TimelapseImages.push(item);
          }
          return acc;
        },
        { TimelapseImages: [], ExcludeImages: [] }
      ),
    [timelapseInspectionImages]
  );

  const timelapseImages = useMemo(() => contentImages?.TimelapseImages, [contentImages]);
  const excludedImages = useMemo(() => contentImages?.ExcludeImages, [contentImages]);

  const checkItem =
    selectedState === TIMELAPSE_IMAGE_TYPE.TIMELAPSE ? timelapseImages[0] : excludedImages[0];

  const handleExcludeIncludeFromTimelapse = (ids: T.SelectedMedia[], isExcluded: boolean) => {
    if (ids.length === 0) {
      return;
    }
    if (!selectedCollectionId) {
      return;
    }

    // Update on store
    const newTimelapseCollectionImages = timelapseInspectionImages.map(item =>
      ids.some(id => id.contentId === item.id)
        ? {
            ...item,
            locationsWithExcluded: item?.locationsWithExcluded?.length
              ? [
                  ...item?.locationsWithExcluded?.map(it => {
                    if (it.timelapseLocationId === selectedCollectionId) {
                      return { timelapseLocationId: selectedCollectionId, excluded: isExcluded };
                    }
                    return it;
                  }),
                ]
              : [],
          }
        : item
    );
    setTimelapseInspectionImages(newTimelapseCollectionImages);
    // Update on backend
    void includeExcludeTimelapseImage(
      isExcluded,
      ids.map(id => Number(id)),
      selectedCollectionId
    );
    handleResetPhotoItems();
  };

  const handleResetPhotoItems: () => void = () => {
    dispatch(SetSelectedMedia({ selectedMedia: [] }));
    setSelectedState(undefined);
  };

  const handleOpenTimelapse = (timelapeImages?: TimelapseImageType[]) => {
    if (!timelapeImages) {
      return;
    }
    if (selectedMedia.length > 0) {
      const images = timelapeImages.filter(image =>
        selectedMedia.some(media => media.contentId === image.id && media.type === image.photoType)
      );
      setTimelapseImages(images);
    } else {
      setTimelapseImages(timelapeImages);
    }
  };

  const handleSelectAll = (
    isChecked: boolean,
    stateType: TIMELAPSE_IMAGE_TYPE,
    images?: TimelapseImageType[]
  ) => {
    if (!images) {
      return;
    }
    const selectedPhotoIdxs = isChecked
      ? [...images.map(image => ({ contentId: image.id, type: image.photoType ?? null }))]
      : [];
    dispatch(SetSelectedMedia({ selectedMedia: selectedPhotoIdxs }));
    if (!selectedState) {
      setSelectedState(stateType);
      return;
    }
  };

  useEffect(() => {
    if (selectedCollectionId) {
      void getTimelapseInspectionImages(selectedCollectionId);
    }
  }, [selectedCollectionId, projectId]);

  useEffect(() => {
    if (hasError) {
      toastify({
        type: T.Toast.ERROR,
        content: {
          title: Text.photo.error.title,
          description: Text.photo.error.description,
        },
        option: defaultToastErrorOption,
      });

      setTimelapseInspectionImagesStatus(T.APIStatus.IDLE);
    }
  }, [hasError]);

  const subHeadingTimelapse = (
    <SubHeadingWrapper>
      <SubHeadingTitleWrapper>
        <CheckboxWrapper
          isSelected={selectedState === TIMELAPSE_IMAGE_TYPE.TIMELAPSE && selectedMedia.length > 0}
        >
          <Checkbox
            isMonth={true}
            show={selectedState !== TIMELAPSE_IMAGE_TYPE.EXCLUDED}
            checked={selectedMedia.length === timelapseImages?.length}
            onChange={isChecked =>
              handleSelectAll(isChecked, TIMELAPSE_IMAGE_TYPE.TIMELAPSE, timelapseImages)
            }
          />
        </CheckboxWrapper>
        <SubHeadingTitle>{l10n(Text.subHeader.timelapse)}</SubHeadingTitle>
      </SubHeadingTitleWrapper>
      <CreateTimelapseButton onClick={() => handleOpenTimelapse(timelapseImages)}>
        {l10n(Text.subHeader.timelapseButton)}
      </CreateTimelapseButton>
    </SubHeadingWrapper>
  );

  const subHeadingExcluded = (
    <SubHeadingWrapper>
      <SubHeadingTitleWrapper>
        <CheckboxWrapper
          isSelected={selectedState === TIMELAPSE_IMAGE_TYPE.EXCLUDED && selectedMedia.length > 0}
        >
          <Checkbox
            isMonth={true}
            show={selectedState !== TIMELAPSE_IMAGE_TYPE.TIMELAPSE}
            checked={selectedMedia.length === excludedImages?.length}
            onChange={isChecked =>
              handleSelectAll(isChecked, TIMELAPSE_IMAGE_TYPE.EXCLUDED, excludedImages)
            }
          />
        </CheckboxWrapper>
        <SubHeadingTitle>{l10n(Text.subHeader.excluded)}</SubHeadingTitle>
      </SubHeadingTitleWrapper>
    </SubHeadingWrapper>
  );

  const notes =
    selectedMedia.length > 0 ? (
      <SelectContent
        selectedMedia={selectedMedia}
        isDownloading={isDownloading}
        bulkDeletePhotoStatus={bulkDeletePhotoStatus}
        photoMode={checkItem?.photoType}
      >
        {SvgButton({
          text: isTimelapseMode ? l10n(Text.exclude) : l10n(Text.include),
          icon: isTimelapseMode ? <ExcludeSvg /> : <IncludeSvg />,
          onClick: () =>
            isTimelapseMode
              ? handleExcludeIncludeFromTimelapse(selectedMedia, true)
              : handleExcludeIncludeFromTimelapse(selectedMedia, false),
          show: selectedMedia.length > 0,
        })}
        {isTimelapseMode
          ? SvgButton({
              text: l10n(Text.subHeader.timelapseTooltip),
              icon: <TimelapseBlueSvg />,
              onClick: () => handleOpenTimelapse(timelapseImages),
              show: selectedMedia.length > 0,
            })
          : null}
      </SelectContent>
    ) : null;

  const timelapseImagesContent = (
    <TimelapseInspectionlistContainer>
      {selectedTimelapseCollection ? (
        <TimelapseTitleEditable timelapseCollection={selectedTimelapseCollection} />
      ) : null}
      {timelapseImages?.length || isInProgress ? subHeadingTimelapse : null}
      <TimelapsePhotosWrapper>
        {isInProgress ? (
          <SkeletonLoaderImages count={12} />
        ) : timelapseImages?.length ? (
          timelapseImages.map((item, i) => (
            <TimelapseImagesItem
              key={i}
              item={item}
              type={TIMELAPSE_IMAGE_TYPE.TIMELAPSE}
              onExcludeInclude={() =>
                handleExcludeIncludeFromTimelapse(
                  [{ contentId: item.id, type: item.photoType ?? null }],
                  true
                )
              }
            />
          ))
        ) : null}
      </TimelapsePhotosWrapper>
      {excludedImages?.length ? subHeadingExcluded : null}
      <TimelapsePhotosWrapper>
        {isInProgress ? (
          <SkeletonLoaderImages count={12} />
        ) : excludedImages.length ? (
          excludedImages.map((item, i) => (
            <TimelapseImagesItem
              key={i}
              item={item}
              type={TIMELAPSE_IMAGE_TYPE.EXCLUDED}
              onExcludeInclude={() =>
                handleExcludeIncludeFromTimelapse(
                  [{ contentId: item.id, type: item.photoType ?? null }],
                  false
                )
              }
            />
          ))
        ) : null}
      </TimelapsePhotosWrapper>
    </TimelapseInspectionlistContainer>
  );

  if (showTimelapseImage.length > 0) {
    return <ParentTimeLapse />;
  }

  return (
    <>
      {notes}
      {timelapseImagesContent}
    </>
  );
};

export default memo(TimelapseInspectionlist);
