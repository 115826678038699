import React, { FC, ReactNode, useEffect, useState } from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import styled, { CSSObject } from 'styled-components';
import palette from '^/constants/palette';
// import RawSendSvg from '^/assets/icons/annotation/send.svg';

import * as T from '^/types';
import Text from './text';
import { useL10n, UseL10n } from '^/hooks';
import IssuePersonDetail from '^/components/atoms/IssuePersonDetail';
import { usePermissionQuery } from '^/store/react-query/permission';

const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

interface NameProps {
  firstName: string;
  lastName: string;
  language: T.Language;
}

export function combineName({ firstName, lastName, language }: NameProps): string {
  switch (language) {
    case T.Language.KO_KR:
      return `${lastName} ${firstName}`;
    case T.Language.EN_US:
      return `${firstName} ${lastName}`;
    default:
      return `${firstName} ${lastName}`;
  }
}

const Root = styled.div({
  boxSizing: 'border-box',
});

const EntryArea = styled.div({
  boxSizing: 'border-box',

  width: '100%',
  height: '33px',
});

const InputWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',

  paddingTop: '5px',
  paddingBottom: '7px',
  paddingLeft: '12px',
  paddingRight: '12px',
});

const InputArea = styled.input({
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  background: 'transparent',

  '::placeholder': {
    color: '#D5D5D8',
  },
});

const OverflownPartyList = styled.div({
  height: '159px',
  width: '100%',
});
const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

interface CandidateListWrapperProps {
  isOpen?: boolean;
}

const CandidateListWrapper = styled.div<CandidateListWrapperProps>(({ isOpen }) => ({
  display: isOpen ? '' : 'none',
  position: 'absolute',
  boxSizing: 'border-box',
  width: '100%',
  marginLeft: '-1px',

  backgroundColor: 'white',
  border: `1px solid ${palette.ContentsList.inputBorder.toString()}`,
  borderRadius: '2px',

  zIndex: 9090,
}));

interface Props {
  content: T.IssueContent;
  onAddAssignee(user: T.IssueAssignee): void;
}

function SearchTeammate({ content, onAddAssignee }: Props) {
  const [l10n, language]: UseL10n = useL10n();
  const [searchName, setSearchName] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { data: rawUsersOnTheProject } = usePermissionQuery(content.projectId);
  const usersOnTheProject = rawUsersOnTheProject
    ? rawUsersOnTheProject.map(
        (user): T.IssueAssignee => ({
          id: user.userId,
          name: combineName({
            firstName: user.firstName,
            lastName: user.lastName,
            language,
          }),
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          avatar: user.avatar,
        })
      )
    : [];
  const candidates = usersOnTheProject.filter(user => {
    const isMatchingName =
      searchName.trim().length > 0
        ? user.name.toLowerCase().includes(searchName.toLowerCase())
        : true;
    const isNotAssigned = content.assignees.every(registered => registered.id !== user.id);
    return isMatchingName && isNotAssigned;
  });
  const isCandidateOpen = candidates.length > 0 && isFocused;

  useEffect(() => {
    const blur = () => setIsFocused(false);

    // Native click event
    document.addEventListener('click', blur);
    return () => document.removeEventListener('click', blur);
  }, []);

  const rawCandidateList: ReactNode = isCandidateOpen
    ? candidates.map(user => (
        <IssuePersonDetail
          key={`assignee-${user.id}`}
          id={user.id}
          name={user.name}
          email={user.email}
          avatar={user.avatar}
          onSelect={() => {
            onAddAssignee(user);
            setSearchName('');
          }}
        />
      ))
    : null;

  const candidateList: ReactNode =
    isCandidateOpen && rawCandidateList && candidates.length >= 4 ? (
      <OverflownPartyList>
        <ScrollBars
          renderView={ScrollBarView}
          autoHide={true}
          autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
          autoHideDuration={HIDE_SCROLL_BAR_DURATION}
        >
          {rawCandidateList}
        </ScrollBars>
      </OverflownPartyList>
    ) : (
      rawCandidateList
    );

  return (
    <Root
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        // Need to natively handling events
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.preventDefault();
      }}
    >
      <EntryArea>
        <InputWrapper>
          <InputArea
            placeholder={l10n(Text.placeholder)}
            value={searchName}
            onChange={event => setSearchName(event.currentTarget.value)}
            onFocus={() => setIsFocused(true)}
          />
        </InputWrapper>
      </EntryArea>
      <CandidateListWrapper isOpen={isCandidateOpen} onClick={() => setIsFocused(false)}>
        {candidateList}
      </CandidateListWrapper>
    </Root>
  );
}

export default SearchTeammate;
