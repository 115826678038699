/* eslint-disable max-len */
import { Language } from '^/types';

export default {
  repositionHeaderText: {
    [Language.KO_KR]: '모델 위치 조정',
    [Language.EN_US]: 'Reposition the model',
  },
  respositionDescriptionText: {
    [Language.KO_KR]: `모델이 빈 공간 위에 있을 경우, 모델이 지리적으로 올바르게 태그되지 않을 수 있습니다. 작업 현장으로 모델을 가져 오려면 아래 버튼을 클릭해주세요.`,
    [Language.EN_US]: `If your model is sitting on top of a blank space, the model is not correctly geo-tagged. Click on this button to bring your model to your working site.`,
  },
  respositioningText: {
    [Language.KO_KR]: 'IFC 모델의 위치 조정',
    [Language.EN_US]: 'Respositioning the IFC Model',
  },
};
