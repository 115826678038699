import React, { useCallback, useEffect, useRef } from 'react';
import styled, { CSSObject } from 'styled-components';
import palette from '^/constants/palette';

import { ContentIcon as RawContentIcon } from '^/components/atoms/ContentIcon';
import { ContentsListItemTitle as Title } from '^/components/atoms/ContentsListItemTitle';

import * as T from '^/types';
import { useL10n, UseL10n } from '^/hooks';
import { FontFamily } from '^/constants/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RequestMarkerElevationInfo, SetMarkerPinSelected } from '^/store/duck/Contents';
import { Dispatch } from 'redux';
import Tippy from '@tippyjs/react';
import Text from './text';
import ContentDescription from '^/components/molecules/ContentDescription/ForIssueAnnotation';
import PriorityDropdown, { PriorityOption } from '^/components/atoms/Dropdown/ForIssuePriority';
import StatusDropdown, {
  createOptions,
  StatusOption,
} from '^/components/atoms/Dropdown/ForIssueStatus';
import { issuePriorityOptions } from '^/constants/issue';
import { useIssueStore } from '^/store/issue';
import { usePostIssueCategory } from '^/store/react-query/issue';
import CategoryDropdown, {
  CategoryOption,
  createOptions as createCategoryOptions,
} from '^/components/atoms/Dropdown/ForIssueCategory';
import dsPalette from '^/constants/ds-palette';
import { useThreeStore } from '^/components/three/ThreeStore';
import useIssueContents from '^/hooks/useIssueContents';

function getFormattedDateString(date: Date, language: T.Language) {
  const createdAtData: Record<string, number> = {
    fullYear: date.getFullYear(),
    monthIndex: date.getMonth(),
    weekdayIndex: date.getDay(),
    date: date.getDate(),
  };

  switch (language) {
    case T.Language.KO_KR:
      // eslint-disable-next-line max-len
      return `${createdAtData.fullYear}${Text.year[T.Language.KO_KR]} ${
        Text.month[T.Language.KO_KR][createdAtData.monthIndex]
      } ${createdAtData.date}${Text.date[T.Language.KO_KR]} ${
        Text.weekday[T.Language.KO_KR][createdAtData.weekdayIndex]
      }`;
    case T.Language.EN_US:
      // eslint-disable-next-line max-len
      return `${Text.weekday[T.Language.EN_US][createdAtData.weekdayIndex]}, ${
        Text.month[T.Language.EN_US][createdAtData.monthIndex]
      } ${createdAtData.date}, ${createdAtData.fullYear}`;
    default:
      return '';
  }
}

interface ContainerProps {
  readonly type: T.IssueContent['type'];
  readonly in3D: Boolean;
}

const comfomfortableTypesIfMarginLeft: T.ContentType[] = [
  T.ContentType.ISSUE_POINT,
  // T.ContentType.ISSUE_PHOTO,
];

const Container = styled.form<ContainerProps>(({ type, in3D }) => ({
  position: 'absolute',

  minWidth: '220px',

  display: 'flex',

  backdropFilter: 'blur(6px)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  boxShadow: palette.OlMeasurementBox.shadow,
  borderRadius: '3px',

  color: palette.OlMeasurementBox.title.toString(),

  fontFamily: FontFamily.NOTOSANS,
  fontSize: 10,
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',

  marginLeft: in3D ? '-5px' : comfomfortableTypesIfMarginLeft.includes(type) ? '15px' : undefined,
  marginTop: in3D && '-5px',
}));

const AnnotationWrapper = styled.section({
  width: '100%',
});

const Header = styled.div({
  paddingTop: '8px',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingBottom: '7px',
  display: 'flex',
  alignItems: 'center',

  fontWeight: 500,
});

const ContentIcon = styled(RawContentIcon)({
  width: '16px',
  height: '16px',
  fontSize: '11px',
});

const HeaderName = styled.div({
  position: 'relative',
  flex: 1,

  height: '23px',

  margin: '0 5px',
  marginLeft: '10px',
});

const Divider = styled.div({
  width: '100%',
  opacity: 0.78,
  borderBottom: `thin solid ${palette.issue.annotationDivider.toString()}`,
});

const Content = styled.span({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  justifyContent: 'center',

  padding: '10px 18px',

  fontWeight: 'normal',
});

const truncateStyle: CSSObject = {
  maxWidth: '110px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Tooltip = styled(Tippy)({
  wordWrap: 'break-word',
});

const ContentSummaryWrapper = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
});

const ContentCreatedBy = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  pointerEvents: 'visible',
});
const ContentCreatedByTitle = styled.div({});
const ContentCreatedByWho = styled.div({
  ...truncateStyle,
});

const ContentCreatedAt = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  pointerEvents: 'visible',
});
const ContentCreatedAtTitle = styled.div({});
const ContentCreatedAtWhen = styled.div({
  ...truncateStyle,
});

const ContentDueDate = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  pointerEvents: 'visible',
});
const ContentDueDateTitle = styled.div({});
const ContentDueDatetWhen = styled.div<{ isSet: boolean }>(({ isSet }) => ({
  ...truncateStyle,
  color: isSet ? palette.OlMeasurementBox.title.toString() : dsPalette.typeTertiary.toString(),
}));

const DropdownWrapper = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '10px',
});

const DropdownArea = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export interface Props {
  readonly content: T.IssueContent;
  readonly setIsHovering?: React.Dispatch<React.SetStateAction<boolean>>;
  readonly handleOnClickMemo?: React.MouseEventHandler<HTMLElement>;
}

function IssueItem({ content, setIsHovering, handleOnClickMemo }: Props) {
  const dispatch: Dispatch = useDispatch();
  const { editingIssueContentId } = useIssueStore();
  const issueStatusOptions = useIssueStore(s => s.issueStatusOptions);
  const issueCategoryOptions = useIssueStore(s => s.issueCategoryOptions);
  const isEditing: boolean = content.id === editingIssueContentId;
  const [l10n, language]: UseL10n = useL10n();
  const in3D = useThreeStore(s => s.viewer !== null && s.viewer !== undefined);
  const postIssueCategory = usePostIssueCategory();

  const { patchIssueContent } = useIssueContents();

  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);

  if (projectId === undefined) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }

  useEffect(() => {
    if (isEditing) {
      dispatch(RequestMarkerElevationInfo({ contentId: content.id }));
    }
  }, [isEditing]);

  useEffect(() => {
    const isEditingAndPinNotSelected: boolean = isEditing && !Boolean(content.info.move);
    const isNotEditingAndPinSelected: boolean = !isEditing && Boolean(content.info.move);

    if (isEditingAndPinNotSelected) {
      dispatch(SetMarkerPinSelected({ contentId: content.id, move: true }));
    } else if (isNotEditingAndPinSelected) {
      dispatch(SetMarkerPinSelected({ contentId: content.id, move: false }));
    }
  }, [isEditing]);

  const createdAtString: string = content.createdAt
    ? getFormattedDateString(content.createdAt, language)
    : '';

  const handleMouseEnter: () => void = useCallback(() => {
    if (setIsHovering) {
      setIsHovering(true);
    }
  }, []);

  const handleMouseLeave: () => void = useCallback(() => {
    if (setIsHovering) {
      setIsHovering(false);
    }
  }, []);

  const createdByRef = useRef<HTMLDivElement>(null);
  const rawCreatedByDiv = (
    <ContentCreatedByWho ref={createdByRef}>{content.createdBy?.name}</ContentCreatedByWho>
  );
  const createdByDiv =
    createdByRef.current &&
    createdByRef.current?.offsetWidth < createdByRef.current?.scrollWidth ? (
      <Tooltip
        theme="angelsw"
        offset={T.TIPPY_OFFSET}
        arrow={false}
        content={content.createdBy?.name}
        placement="bottom"
      >
        {rawCreatedByDiv}
      </Tooltip>
    ) : (
      rawCreatedByDiv
    );

  const createdAtRef = useRef<HTMLDivElement>(null);
  const rawCreatedAtDiv = (
    <ContentCreatedAtWhen ref={createdAtRef}>{createdAtString}</ContentCreatedAtWhen>
  );
  const createdAtDiv =
    createdByRef.current &&
    createdByRef.current?.offsetWidth < createdByRef.current?.scrollWidth ? (
      <Tooltip
        theme="angelsw"
        offset={T.TIPPY_OFFSET}
        arrow={false}
        content={createdAtString}
        placement="bottom"
      >
        {rawCreatedAtDiv}
      </Tooltip>
    ) : (
      rawCreatedAtDiv
    );

  const onClickPriorityDropdown = (option: PriorityOption) => {
    patchIssueContent({
      content: {
        id: content.id,
        priority: option.value,
      },
    });
  };

  const onClickStatusDropdown = (option: StatusOption) => {
    patchIssueContent({
      content: {
        id: content.id,
        issueStatusId: option.value.id,
      },
    });
  };

  const onClickCategoryDropdown = (option: CategoryOption) => {
    patchIssueContent({
      content: {
        id: content.id,
        issueCategoryId: option.value.id,
      },
    });
  };

  const onAddCategory = (rawNewCategory: string) => {
    const newCategory = rawNewCategory.trim();
    if (newCategory.length <= 0) {
      return;
    }

    postIssueCategory({
      id: content.id,
      label: newCategory,
    });
  };

  const isDueDateSet = content.dueDate !== null;
  const dueDateString = isDueDateSet
    ? getFormattedDateString(content.dueDate, language)
    : l10n(Text.dueDateNotSet);

  return (
    <Container
      in3D={in3D}
      type={content.type}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <AnnotationWrapper onClick={handleOnClickMemo}>
        <Header>
          <ContentIcon contentType={content.type} />
          <HeaderName>
            <Title content={content} fromUI={T.EditableTextUI.OL_CONTENT_TITLE} />
          </HeaderName>
        </Header>
        <Divider />
        <Content>
          <ContentSummaryWrapper>
            <ContentCreatedBy>
              <ContentCreatedByTitle>{l10n(Text.createdBy)}</ContentCreatedByTitle>
              {createdByDiv}
            </ContentCreatedBy>
            <ContentCreatedAt>
              <ContentCreatedAtTitle>{l10n(Text.createdAt)}</ContentCreatedAtTitle>
              {createdAtDiv}
            </ContentCreatedAt>
            <ContentDueDate>
              <ContentDueDateTitle>{l10n(Text.dueDate)}</ContentDueDateTitle>
              <ContentDueDatetWhen isSet={isDueDateSet}>{dueDateString}</ContentDueDatetWhen>
            </ContentDueDate>
          </ContentSummaryWrapper>
        </Content>
        <Divider />
        <Content>
          <DropdownArea style={{ marginBottom: '10px' }}>
            <CategoryDropdown
              language={language}
              isSearchEnable={true}
              valueId={content.issueCategoryId}
              options={createCategoryOptions(issueCategoryOptions)}
              onClick={onClickCategoryDropdown}
              onAddCategory={onAddCategory}
              placeHolder={l10n(Text.category.placeHolder)}
              zIndex={0}
              rootHeight="24px"
              centerPos="21px"
              nonFaFontSize="8px"
              horizontalPadding="8.5px"
              labelVerticalPadding="10px"
            />
          </DropdownArea>
          <DropdownWrapper>
            <DropdownArea>
              <PriorityDropdown
                value={content.priority}
                options={issuePriorityOptions[language]}
                onClick={onClickPriorityDropdown}
                placeHolder={l10n(Text.priority)}
                zIndex={0}
                rootHeight="24px"
                centerPos="21px"
                nonFaFontSize="8px"
              />
            </DropdownArea>
            <DropdownArea>
              <StatusDropdown
                valueId={content.issueStatusId}
                options={createOptions(issueStatusOptions, language)}
                onClick={onClickStatusDropdown}
                placeHolder={l10n(Text.status)}
                zIndex={0}
                rootHeight="24px"
                centerPos="21px"
                nonFaFontSize="8px"
              />
            </DropdownArea>
          </DropdownWrapper>
          <ContentDescription content={content} />
        </Content>
      </AnnotationWrapper>
    </Container>
  );
}

export default IssueItem;
