import Color from 'color';
import React, {
  FC,
  ReactNode,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
  MouseEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { AnyStyledComponent, CSSObject } from 'styled-components';

import ColorNoFillSvg from '^/assets/icons/contents-list/color-no-fill.svg';
import RawColorPickerPopup, {
  Props as ColorPickerPopupProps,
} from '^/components/atoms/ColorPickerPopup';
import palette, { getColorPickerPalette } from '^/constants/palette';
import route from '^/constants/routes';
import { UseState, useRouteIsMatching, isESSContent } from '^/hooks';
import { useESSContents } from '^/hooks/useESSContents';
import { PatchContent } from '^/store/duck/Contents';
import * as T from '^/types';
import { isTransparent, isWhite } from '^/utilities/color';

const RectangularColor = styled.div<{
  customColor: Color;
  customStyle?: CSSObject;
}>(({ customColor, customStyle }) => ({
  position: 'relative',

  marginLeft: '8px',
  padding: isWhite(customColor) || isTransparent(customColor) ? '4.5px' : '5.5px',

  // borderRadius: '50%',
  backgroundColor: customColor.toString(),
  border:
    isWhite(customColor) || isTransparent(customColor)
      ? `1px solid ${palette.black.toString()}`
      : undefined,

  cursor: 'pointer',
  boxSizing: 'border-box',

  ...customStyle,
}));

const ColorPickerPopup = styled(RawColorPickerPopup as AnyStyledComponent)<ColorPickerPopupProps>({
  zIndex: 1,
  position: 'absolute',
  right: '50%',
  top: '100%',
  transform: 'translate(50%, 0)',
});

const NoFillSvgContainer = styled.div({
  position: 'absolute',
  top: '-6px',
  left: '-5px',

  '> svg': {
    transform: 'scale(0.7)',
  },
});

export interface Props {
  readonly content: T.Content;
  readonly balloonColorCustomStyle?: CSSObject;
  readonly hasWhite?: boolean;
  readonly hasNoFill?: boolean;
}

const ContentsListItemBorderColor: FC<Props> = ({
  content,
  balloonColorCustomStyle,
  hasWhite,
  hasNoFill,
}) => {
  const { updateESSContent } = useESSContents();
  const dispatch: Dispatch = useDispatch();
  const [isColorEditing, setIsColorEditing]: UseState<boolean> = useState<boolean>(false);
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const isOnSharePage: boolean = useRouteIsMatching(route.share.main);

  const handleColorClick: (e: MouseEvent<HTMLDivElement>) => void = e => {
    if (isOnSharePage) {
      return;
    }

    e.stopPropagation();

    setIsColorEditing((prevState: boolean) => !prevState);
  };

  const handleColorEdit: (color: Color) => void = clickedColor => {
    if (isOnSharePage) {
      return;
    }

    if (
      clickedColor.toString() !== (content as T.ESSLineTextContent)?.info?.borderColor?.toString()
    ) {
      setIsColorEditing(false);

      if (isESSContent(content)) {
        void updateESSContent({
          content: { id: content.id, info: { borderColor: clickedColor } },
          isUndoable: true,
        });
      } else {
        dispatch(
          PatchContent({
            content: { id: (content as T.Content).id, info: { borderColor: clickedColor } },
            isUndoable: true,
          })
        );
      }
    }
  };

  const handleClickPickerOutside: () => void = () => {
    if (isColorEditing) {
      setIsColorEditing(false);
    }
  };

  const paletteColors: Color[][] = getColorPickerPalette({ hasWhite, hasNoFill });
  const colorPickerPopup: ReactNode = isColorEditing ? (
    <ColorPickerPopup
      parentRef={ref}
      colors={paletteColors}
      clickedColor={(content as T.ESSLineTextContent)?.info?.borderColor}
      setColor={handleColorEdit}
      onClickOutside={handleClickPickerOutside}
    />
  ) : undefined;

  const noFillIcon: ReactNode = useMemo(() => {
    if (
      !(content as T.ESSLineTextContent)?.info?.borderColor ||
      (content as T.ESSLineTextContent)?.info?.borderColor?.alpha() > 0
    ) {
      return undefined;
    }

    return (
      <NoFillSvgContainer>
        <ColorNoFillSvg />
      </NoFillSvgContainer>
    );
  }, [(content as T.ESSLineTextContent)?.info?.borderColor]);

  return (
    <RectangularColor
      ref={ref}
      customColor={(content as T.ESSLineTextContent)?.info?.borderColor}
      onClick={handleColorClick}
      customStyle={balloonColorCustomStyle}
      data-ddm-track-action={T.TrackActions.COLOR_SELECT}
      data-ddm-track-label={`${T.TrackLabels.BTN_FONT_COLOR}-${content.type}`}
    >
      {noFillIcon}
      {colorPickerPopup}
    </RectangularColor>
  );
};

export default ContentsListItemBorderColor;
