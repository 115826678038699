import React, { FC, memo } from 'react';
import styled, { css, CSSObject, Keyframes, keyframes } from 'styled-components';
import dsPalette from '^/constants/ds-palette';

interface LoadingDivProps {
  isDownloading?: boolean;
}

const rotationAnimation: Keyframes = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `;

const LoadingDiv = styled.div<LoadingDivProps>`
  border: ${props =>
    props.isDownloading ? '3px solid #8aade2' : `3px solid ${dsPalette.grey40.toString()}`};
  border-top: 3px solid var(--color-theme-primary);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transform-origin: center;
  animation: ${css`
      ${rotationAnimation}`} 1s infinite;
`;

export interface Props {
  readonly loadingDivCustomStyle?: CSSObject;
  readonly isDownloading?: boolean;
}

const LoadingIcon: FC<Props> = ({ loadingDivCustomStyle, isDownloading }) => (
  <LoadingDiv
    isDownloading={isDownloading}
    data-testid="loading-button"
    style={loadingDivCustomStyle}
  />
);
/**
 * Never update loading icon again because there is no point of doing that
 * But there would be a problem if you are trying to change the custom style
 * dynamically
 */
export default memo(LoadingIcon, () => true);
