import { L10nDictionary } from '^/utilities/l10n';
import * as T from '^/types';

/**
 * @todo: should be changed after guideline comes out
 */
export const contentTexts: { [K in T.ContentType]: L10nDictionary } = {
  [T.ContentType.AREA]: {
    [T.Language.KO_KR]: '면적',
    [T.Language.EN_US]: 'Area',
  },
  [T.ContentType.THREE_AREA]: {
    [T.Language.KO_KR]: '면적',
    [T.Language.EN_US]: 'Area',
  },
  [T.ContentType.LENGTH]: {
    [T.Language.KO_KR]: '거리',
    [T.Language.EN_US]: 'Distance',
  },
  [T.ContentType.THREE_LENGTH]: {
    [T.Language.KO_KR]: '3D 거리',
    [T.Language.EN_US]: '3D Distance',
  },
  [T.ContentType.THREE_HEIGHT]: {
    [T.Language.KO_KR]: '세 지점 거리',
    [T.Language.EN_US]: 'Three Point Distance',
  },
  [T.ContentType.MARKER]: {
    [T.Language.KO_KR]: '위치',
    [T.Language.EN_US]: 'Location',
  },
  [T.ContentType.VOLUME]: {
    [T.Language.KO_KR]: '체적',
    [T.Language.EN_US]: 'Volume',
  },
  [T.ContentType.BLUEPRINT_PDF]: {
    [T.Language.KO_KR]: '도면 (.PDF)',
    [T.Language.EN_US]: 'PDF',
  },
  [T.ContentType.BLUEPRINT_DXF]: {
    [T.Language.KO_KR]: '도면 (.DXF)',
    [T.Language.EN_US]: 'DXF',
  },
  [T.ContentType.BLUEPRINT_DWG]: {
    [T.Language.KO_KR]: '도면 (.DWG)',
    [T.Language.EN_US]: 'DWG',
  },
  [T.ContentType.DESIGN_DXF]: {
    [T.Language.KO_KR]: '계획고 (.DXF)',
    [T.Language.EN_US]: 'DXF',
  },
  [T.ContentType.BIM]: {
    [T.Language.KO_KR]: 'BIM (.IFC)',
    [T.Language.EN_US]: 'BIM',
  },
  [T.ContentType.MAP]: {
    [T.Language.KO_KR]: '정사영상',
    [T.Language.EN_US]: '2D Orthomosaic',
  },
  [T.ContentType.DSM]: {
    // [T.Language.KO_KR]: '수치표면모델',
    [T.Language.KO_KR]: '지형',
    [T.Language.EN_US]: 'Terrain',
  },
  [T.ContentType.DTM]: {
    [T.Language.KO_KR]: 'DTM',
    [T.Language.EN_US]: 'DTM',
  },
  [T.ContentType.POINTCLOUD]: {
    [T.Language.KO_KR]: '포인트 클라우드',
    [T.Language.EN_US]: 'Point Cloud',
  },
  [T.ContentType.THREE_D_ORTHO]: {
    [T.Language.KO_KR]: '3D 정사영상',
    [T.Language.EN_US]: '3D Orthomosaic',
  },
  [T.ContentType.THREE_D_MESH]: {
    [T.Language.KO_KR]: '3D 메쉬 모델',
    [T.Language.EN_US]: '3D Mesh Model',
  },
  [T.ContentType.GCP_GROUP]: {
    [T.Language.KO_KR]: '지상기준점',
    [T.Language.EN_US]: 'GCP',
  },
  [T.ContentType.ESS_MODEL]: {
    [T.Language.KO_KR]: '장비',
    [T.Language.EN_US]: 'Equipment',
  },
  [T.ContentType.ESS_MODEL_CUSTOM]: {
    [T.Language.KO_KR]: '사용자 장비',
    [T.Language.EN_US]: 'Custom Library',
  },
  [T.ContentType.ESS_ARROW]: {
    [T.Language.KO_KR]: '화살표',
    [T.Language.EN_US]: 'Arrow',
  },
  [T.ContentType.ESS_POLYGON]: {
    [T.Language.KO_KR]: '다각형',
    [T.Language.EN_US]: 'Polygon',
  },
  [T.ContentType.ESS_POLYLINE]: {
    [T.Language.KO_KR]: '선',
    [T.Language.EN_US]: 'Polyline',
  },
  [T.ContentType.ESS_TEXT]: {
    [T.Language.KO_KR]: '텍스트',
    [T.Language.EN_US]: 'Text',
  },
  [T.ContentType.ESS_LINE_TEXT]: {
    [T.Language.KO_KR]: '떠있는 텍스트',
    [T.Language.EN_US]: 'Line Text',
  },
  [T.ContentType.GROUP]: {
    [T.Language.KO_KR]: '그룹 제목',
    [T.Language.EN_US]: 'Group Title',
  },
  [T.ContentType.ISSUE_POINT]: {
    [T.Language.KO_KR]: '작업 관리',
    [T.Language.EN_US]: 'Issue',
  },
  [T.ContentType.FLATTEN_MAP]: {
    [T.Language.KO_KR]: '지도 평탄화하기',
    [T.Language.EN_US]: 'Flatten map',
  },
  [T.ContentType.ISSUE_PHOTO]: {
    [T.Language.KO_KR]: '작업 관리',
    [T.Language.EN_US]: 'Issue',
  },
  [T.ContentType.FLIGHT_PLAN]: {
    [T.Language.KO_KR]: '플라이트 플라',
    [T.Language.EN_US]: 'Flight Plan',
  },
  [T.ContentType.FLIGHT_SCHEDULE]: {
    [T.Language.KO_KR]: '비행 일정',
    [T.Language.EN_US]: 'Flight Schedule',
  },
  [T.ContentType.DRONE_STATION]: {
    [T.Language.KO_KR]: '드론 스테이션',
    [T.Language.EN_US]: 'Drone Station',
  },
  [T.ContentType.SLIDE]: {
    [T.Language.KO_KR]: '슬라이드 쇼',
    [T.Language.EN_US]: 'Slide',
  },
  [T.ContentType.ANNOTATION]: {
    [T.Language.EN_US]: 'Annotation',
    [T.Language.KO_KR]: '낙서',
  },
};

export const DEFAULT_MEASUREMENT_TEXT: {
  [K in
    | T.GeometryContent['type']
    | T.ContentType.ESS_TEXT
    | T.ContentType.ESS_LINE_TEXT
    | T.ContentType.ISSUE_PHOTO]: {
    [K: string]: L10nDictionary;
  };
} = {
  [T.ContentType.MARKER]: {
    title: contentTexts[T.ContentType.MARKER],
    description: {
      [T.Language.KO_KR]: '내용을 입력하세요.',
      [T.Language.EN_US]: 'Type here.',
    },
  },
  [T.ContentType.LENGTH]: {
    title: contentTexts[T.ContentType.LENGTH],
  },
  [T.ContentType.THREE_HEIGHT]: {
    title: contentTexts[T.ContentType.THREE_HEIGHT],
  },
  [T.ContentType.THREE_LENGTH]: {
    title: contentTexts[T.ContentType.THREE_LENGTH],
  },
  [T.ContentType.AREA]: {
    title: contentTexts[T.ContentType.AREA],
  },
  [T.ContentType.THREE_AREA]: {
    title: contentTexts[T.ContentType.THREE_AREA],
  },
  [T.ContentType.VOLUME]: {
    title: contentTexts[T.ContentType.VOLUME],
  },
  [T.ContentType.ESS_ARROW]: {
    title: contentTexts[T.ContentType.ESS_ARROW],
  },
  [T.ContentType.ESS_POLYGON]: {
    title: contentTexts[T.ContentType.ESS_POLYGON],
  },
  [T.ContentType.ESS_POLYLINE]: {
    title: contentTexts[T.ContentType.ESS_POLYLINE],
  },
  [T.ContentType.ESS_TEXT]: {
    title: contentTexts[T.ContentType.ESS_TEXT],
  },
  [T.ContentType.ESS_LINE_TEXT]: {
    title: contentTexts[T.ContentType.ESS_LINE_TEXT],
  },
  [T.ContentType.ISSUE_POINT]: {
    title: contentTexts[T.ContentType.ISSUE_POINT],
  },
  [T.ContentType.FLATTEN_MAP]: {
    title: contentTexts[T.ContentType.FLATTEN_MAP],
  },
  [T.ContentType.ISSUE_PHOTO]: {
    title: contentTexts[T.ContentType.ISSUE_PHOTO],
  },
};

export const DEFAULT_ESS_TEXT_PROMPT: Record<T.Language, string> = {
  [T.Language.EN_US]: 'Please enter a title',
  [T.Language.KO_KR]: '내용을 입력하세요',
};

export const SUPPORT_EMAIL = 'csx@angelswing.io';
