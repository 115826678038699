import React from 'react';
import styled from 'styled-components';
import Dropdown, { Option } from '^/components/atoms/Dropdown/ForIssueGroup';
import palette from '^/constants/palette';

const DroneStationSortByRoot = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',

  color: palette.issue.sidebarItemFont.toString(),
  fontSize: '12px',
  fontWeight: '400',

  height: '40px',
});

interface DroneStationSortByTitleProps {
  customTitleColor?: string;
}

const DroneStationSortByTitle = styled.div<DroneStationSortByTitleProps>(
  ({ customTitleColor }) => ({
    color: customTitleColor,
  })
);

interface DroneStationSortByDropdownProps {
  customWidth?: string;
}
const DroneStationSortByDropdown = styled.div<DroneStationSortByDropdownProps>(
  ({ customWidth }) => ({
    marginLeft: '10px',
    marginRight: '13px',
    boxSizing: 'border-box',
    width: customWidth ?? '94px',
  })
);

interface Props {
  mainTitle: string;
  value: string | number | null | undefined;
  options: Option[];
  onClick(option: Option, index: number): void;
  placeHolder: string;
  zIndex: number;
  customWidth?: string;
  customTitleColor?: string;
}

function DroneStationGroupOrSortBy(props: Props) {
  return (
    <DroneStationSortByRoot>
      <DroneStationSortByTitle customTitleColor={props.customTitleColor}>
        {props.mainTitle}
      </DroneStationSortByTitle>
      <DroneStationSortByDropdown customWidth={props.customWidth}>
        <Dropdown {...props} />
      </DroneStationSortByDropdown>
    </DroneStationSortByRoot>
  );
}

export default DroneStationGroupOrSortBy;
