import { AttachmentType, Language } from '^/types';

const {
  PHOTO,
  DRONE_PHOTO,
  ORTHO,
  BLUEPRINT_PDF,
  BLUEPRINT_DXF,
  BLUEPRINT_DWG,
  DESIGN_DXF,
  DSM,
  POINTCLOUD,
  BIM,
  FLIGHT_PLAN,
  GCP,
  FLIGHT_VIDEO,
  FLIGHT_LOG,
  THREE_SIXTY,
  THREE_SIXTY_SOURCE,
  THREE_SIXTY_STITCHED,
  THREE_SIXTY_VSLAM,
  INSPECTION,
  VSLAM_VIDEO,
}: typeof AttachmentType = AttachmentType;

export default {
  upload: {
    [Language.KO_KR]: '업로드',
    [Language.EN_US]: 'Upload',
  },
  back: {
    [Language.KO_KR]: '이전',
    [Language.EN_US]: 'Back',
  },
  datasetDateAndName: {
    [Language.KO_KR]: '데이터 세트 날짜/이름',
    [Language.EN_US]: 'Date and title of dataset',
  },
  overwritingWarningMsg: {
    [Language.KO_KR]:
      '선택된 데이터 세트에 덮어써야 하는 데이터가 존재합니다. 업로드를 진행하면 데이터를 덮어쓰게 됩니다.',
    [Language.EN_US]:
      'Data in the selected dataset needs to be overwritten. If you proceed, the data will be overwritten.',
  },
  next: {
    [Language.KO_KR]: '다음',
    [Language.EN_US]: 'Next',
  },
  content: {
    [GCP]: {
      [Language.KO_KR]: 'GCP',
      [Language.EN_US]: 'GCP',
    },
    [PHOTO]: {
      [Language.KO_KR]: '전경 사진',
      [Language.EN_US]: 'Bird Eye View Photos',
    },
    [THREE_SIXTY]: {
      [Language.KO_KR]: '360 파노라마 사진',
      [Language.EN_US]: '360 Panorama Photos',
    },
    [THREE_SIXTY_SOURCE]: {
      [Language.KO_KR]: '360 파노라마 소스포토',
      [Language.EN_US]: '360 Panorama Source Photos',
    },
    [THREE_SIXTY_STITCHED]: {
      [Language.KO_KR]: '360 파노라마 이미지',
      [Language.EN_US]: '360 Panorama Stitched Photos',
    },
    [THREE_SIXTY_VSLAM]: {
      [Language.KO_KR]: '360 VSLAM 파노라마 사진',
      [Language.EN_US]: '360 VSLAM Panorama Photos',
    },
    [DRONE_PHOTO]: {
      [Language.KO_KR]: '시설물 점검사진',
      [Language.EN_US]: 'Inspection Photos',
    },
    [INSPECTION]: {
      [Language.KO_KR]: '시설물 점검사진',
      [Language.EN_US]: 'Inspection Photos',
    },
    [ORTHO]: {
      [Language.KO_KR]: '정사영상',
      [Language.EN_US]: 'Orthomosaic',
    },
    [BLUEPRINT_PDF]: {
      [Language.KO_KR]: '도면',
      [Language.EN_US]: 'Overlay',
    },
    [BLUEPRINT_DXF]: {
      [Language.KO_KR]: '도면',
      [Language.EN_US]: 'Overlay',
    },
    [BLUEPRINT_DWG]: {
      [Language.KO_KR]: '도면',
      [Language.EN_US]: 'Overlay',
    },
    [BIM]: {
      [Language.KO_KR]: '도면',
      [Language.EN_US]: 'Overlay',
    },
    [POINTCLOUD]: {
      [Language.KO_KR]: '포인트 클라우드',
      [Language.EN_US]: 'Point Cloud',
    },
    [DSM]: {
      [Language.KO_KR]: '수치표면모델',
      [Language.EN_US]: 'DSM',
    },
    [DESIGN_DXF]: {
      [Language.KO_KR]: '계획고',
      [Language.EN_US]: 'Design Surface',
    },
    [FLIGHT_PLAN]: {
      [Language.KO_KR]: '비행 경로',
      [Language.EN_US]: 'Flight Plan',
    },
    [VSLAM_VIDEO]: {
      [Language.KO_KR]: '데이터 업로드',
      [Language.EN_US]: 'Upload Data',
    },
    [FLIGHT_VIDEO]: {
      [Language.KO_KR]: '비행 영상',
      [Language.EN_US]: 'Flight Video',
    },
    [FLIGHT_LOG]: {
      [Language.KO_KR]: '비행 로그',
      [Language.EN_US]: 'Flight Log',
    },
  },
  invalid360ErrorText: {
    [Language.KO_KR]: '360 이미지 포멧을 확인해주세요.',
    [Language.EN_US]: 'Please upload a valid 360 images',
  },
  photoUploadingText: {
    [Language.KO_KR]: '업로드 중...',
    [Language.EN_US]: 'Uploading...',
  },
  warningDescription: {
    [Language.KO_KR]:
      'BIM 오버레이를 사용하시려면 저희 팀에 연락하여 3D 메쉬를 업데이트해 주시기 바랍니다.',
    [Language.EN_US]:
      'If you wish to use BIM overlays, please contact our team to update your 3D Mesh.',
  },
  warningText: {
    [Language.KO_KR]:
      'BIM 오버레이를 지원하지 않는 3D Mesh 버전을 사용하시는 것 같습니다. 불편을 끼쳐드려 죄송합니다.',
    [Language.EN_US]:
      'It looks like you are using a version of 3D Mesh that does not support BIM Overlays. We apologize for the inconvenience.',
  },
};
