import _, { isEqual } from 'lodash-es';

import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { defaultMapZoom } from '^/constants/defaultContent';
import * as T from '^/types';
import { boxIntersect } from '^/components/three/Lib/Utils/MathUtils';

export const getCenterBoundary: (boundary: T.MapBoundary) => T.GeoPoint = ({
  minLon,
  maxLon,
  minLat,
  maxLat,
}) => [_.mean([minLon, maxLon]), _.mean([minLat, maxLat])];

export const getZoom: (
  tms: T.MapContent['info']['tms'],
  currentZoom: number,
  defaultZoom?: number,
  sharedMaxZoom?: number,
  isShared?: boolean
) => number = (tms, currentZoom, defaultZoom = defaultMapZoom, sharedMaxZoom = 19, isShared) => {
  if (!tms) {
    return defaultZoom;
  }
  let zoom: number = currentZoom;
  if (tms.zoomLevels.length !== 0 && !tms.zoomLevels.includes(defaultZoom)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const smallestZoom: number = _.min(tms.zoomLevels)!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const unsharedLargestZoom: number = _.max(tms.zoomLevels)!;
    const largestZoom: number = isShared
      ? Math.min(unsharedLargestZoom, sharedMaxZoom)
      : unsharedLargestZoom;
    zoom = _.clamp(currentZoom, smallestZoom, largestZoom);
  }
  return zoom;
};

export const getSquareOrBoundary: (
  square: [T.GeoPoint, T.GeoPoint],
  boundary: T.MapBoundary
) => [T.GeoPoint, T.GeoPoint] = (
  [squareMinXY, squareMaxXY],
  { minLon, minLat, maxLon, maxLat }
) => {
  if (
    !_.inRange(squareMinXY[0], minLon, maxLon) ||
    !_.inRange(squareMinXY[1], minLat, maxLat) ||
    !_.inRange(squareMaxXY[0], minLon, maxLon) ||
    !_.inRange(squareMaxXY[1], minLat, maxLat)
  ) {
    return [
      [minLon, maxLat],
      [maxLon, minLat],
    ];
  }

  return [squareMinXY, squareMaxXY];
};

export const getMidPoint: (locations: T.GeoPoint[]) => T.GeoPoint = locations => {
  const lonAverage: number = _.meanBy(_.map(locations, val => val[0]));
  const latAverage: number = _.meanBy(_.map(locations, val => val[1]));

  return [lonAverage, latAverage];
};

export const getClosestZoomLevel: (tms: T.MapContent['info']['tms'], zoom: number) => number = (
  tms,
  zoom
) => {
  if (!tms) {
    return defaultMapZoom;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const minZoom: number = _.min(tms.zoomLevels)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const maxZoom: number = _.max(tms.zoomLevels)!;

  return _.clamp(zoom, minZoom, maxZoom);
};

export function getExtentAndMaxZoom(
  content: T.DSMContent | T.DTMContent | T.MapContent | T.BlueprintDXFContent | undefined,
  isShared?: boolean
): { extent?: Extent; maxZoom?: number } {
  const tms:
    | (T.DSMContent | T.DTMContent | T.MapContent | T.BlueprintDXFContent)['info']['tms']
    | undefined = content?.info?.tms;
  if (tms === undefined || tms.zoomLevels.length === 0) {
    return { extent: undefined, maxZoom: undefined };
  }

  const boundary: (typeof tms)['boundaries'][0] =
    tms.boundaries[tms.zoomLevels[tms.zoomLevels.length - 1]];
  if (boundary === undefined) {
    return { extent: undefined, maxZoom: undefined };
  }
  const minCoord: Coordinate = fromLonLat([boundary.minLon, boundary.minLat]);
  const maxCoord: Coordinate = fromLonLat([boundary.maxLon, boundary.maxLat]);
  const extent: Extent = [minCoord[0], minCoord[1], maxCoord[0], maxCoord[1]];

  const defaultZoom: number = 12;
  const unsharedLargestZoom: number = _.max(tms.zoomLevels) || defaultZoom;
  const sharedMaxZoom: number = 19;
  const largestZoom = isShared ? Math.min(unsharedLargestZoom, sharedMaxZoom) : unsharedLargestZoom;

  return { extent, maxZoom: largestZoom };
}

export const getExtentFromVolumeContent: (content: T.VolumeContent) => Extent = ({
  info: { locations },
}) => new Polygon([locations.map(points => fromLonLat(points))]).getExtent();

export const checkLocationIntersectFlattenContent = (
  geoPoints: T.GeoPoint[],
  allIds: Array<string | number>,
  byId: {
    readonly [id: string]: T.Content;
    readonly [id: number]: T.Content;
  }
): [string[], string[]] => {
  const flattenContents: T.FlattenContent[] = [];
  allIds.forEach((id: number) => {
    const flattenContent: T.Content = byId[id];

    if (flattenContent.type === T.ContentType.FLATTEN_MAP) {
      if (flattenContent.config?.selectedAt) {
        flattenContents.push(flattenContent);
      }
    }
  });
  const wkts: string[] = [];
  const tfMethods: string[] = [];
  let minX1 = Infinity;
  let maxX1 = -Infinity;
  let minY1 = Infinity;
  let maxY1 = -Infinity;
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < geoPoints.length; i++) {
    const pointerArray = geoPoints[i];
    const x = pointerArray[0];
    const y = pointerArray[1];
    minX1 = Math.min(minX1, x);
    maxX1 = Math.max(maxX1, x);
    minY1 = Math.min(minY1, y);
    maxY1 = Math.max(maxY1, y);
  }
  flattenContents.forEach((v: T.FlattenContent) => {
    if (v.config?.selectedAt) {
      const locations: Array<[number, number]> = [];
      let minX2 = Infinity;
      let maxX2 = -Infinity;
      let minY2 = Infinity;
      let maxY2 = -Infinity;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < v.info.locations.length; i++) {
        const pointerArray = v.info.locations[i];
        const x = pointerArray[0];
        const y = pointerArray[1];
        minX2 = Math.min(minX2, x);
        maxX2 = Math.max(maxX2, x);
        minY2 = Math.min(minY2, y);
        maxY2 = Math.max(maxY2, y);
        locations.push([x, y]);
      }
      if (boxIntersect(minX1, maxX1, minY1, maxY1, minX2, maxX2, minY2, maxY2)) {
        const unduplicatedLocations: Array<[number, number]> = locations.filter(
          val => !isEqual(val, locations[0])
        );
        unduplicatedLocations.unshift(locations[0]);
        unduplicatedLocations.push(locations[0]);
        const locationPoints: string = unduplicatedLocations
          .map(point => fromLonLat(point))
          .map(([x, y]) => `${x} ${y}`)
          .join(',');
        const wkt: string = `POLYGON ((${locationPoints}))`;
        wkts.push(wkt);
        tfMethods.push(v.config?.terrianEditingMethod ?? T.TerrainEditingMethod.PLANE_FITTING);
      }
    }
  });
  return [wkts, tfMethods];
};
