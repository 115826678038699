import * as T from '^/types';

const tail: string = 'path=/; domain=.angelswing.io;';

/**
 * @desc This function is for setting cookie. Receives key & value.
 */
const setCookie: (key: string, value: string, expiredAt?: Date) => void = (key, value, date) => {
  const expiredAt: string = date ? `expires = ${date.toUTCString()};` : '';
  document.cookie = `${key}=${value}; ${expiredAt} ${tail}`;
};

/**
 * @desc This function is for setting cookie with Object. Receives object.
 */
const setCookieWithObject: (obj: { [K: string]: string }, expiredAt?: Date) => void = (
  obj,
  expiredAt
) => Object.keys(obj).forEach((key: string) => setCookie(key, obj[key], expiredAt));

/**
 * @desc This function is for deleting cookie. Receives key or keys.
 */
const deleteCookie: (...key: string[]) => void = (...key) =>
  key.forEach(
    (k: string) => (document.cookie = `${k}= ; ${tail} expires=Thu, 01 Jan 1970 00:00:00 GMT`)
  );

/**
 * This function is for setting cloudfront cookie. Receives authedUser in AuthState.
 */
export function setCDNCookie({
  cloudFrontPolicy,
  cloudFrontSignature,
  cloudFrontKeyPairId,
  cloudCdnCookie,
}: {
  cloudFrontPolicy?: T.CloudFront['cloudFrontPolicy'];
  cloudFrontSignature?: T.CloudFront['cloudFrontSignature'];
  cloudFrontKeyPairId?: T.CloudFront['cloudFrontKeyPairId'];
  cloudCdnCookie?: T.CloudCDN['cloudCdnCookie'];
}) {
  const cookie: Partial<T.CookieCDN> = {};

  if (cloudFrontPolicy) {
    cookie['CloudFront-Policy'] = cloudFrontPolicy;
    cookie['CloudFront-Signature'] = cloudFrontSignature;
    cookie['CloudFront-Key-Pair-Id'] = cloudFrontKeyPairId;
  } else if (cloudCdnCookie) {
    // eslint-disable-next-line dot-notation
    cookie['Cloud-CDN-Cookie'] = cloudCdnCookie;
  }
  const expiredAt: Date = new Date();
  expiredAt.setDate(expiredAt.getDate() + 1);

  setCookieWithObject({ ...cookie }, expiredAt); // Use spread for Type Error
}

export const deleteCDNCookie: () => void = () => {
  const keys: Array<keyof T.CookieCDN> = [
    'CloudFront-Key-Pair-Id',
    'CloudFront-Policy',
    'CloudFront-Signature',
    'Cloud-CDN-Cookie',
  ];
  deleteCookie(...keys);
};
