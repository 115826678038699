import palette from '^/constants/palette';
import React, { useCallback, ReactNode, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import Text from './text';

import AttachSvg from '^/assets/icons/upload-popup/attach-file.svg';

import WrapperHoverable, {
  Props as WrapperHoverableProps,
} from '^/components/atoms/WrapperHoverable';
import { UseL10n, useL10n } from '^/hooks';
import dsPalette from '^/constants/ds-palette';

const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: ${palette.UploadPopup.inputBorder.toString()};
  background-color: #fafafa;
`;

const TextWrapper = styled.p`
  font-size: 12px;
  margin-top: 5px;
  line-height: 1.5;
  text-align: center;
  color: ${palette.textLight.toString()};
`;

const tooltipCustomStyle: WrapperHoverableProps['customStyle'] = {
  tooltipWrapperStyle: { position: 'relative' },
  tooltipArrowStyle: {
    left: '50%',
    transform: 'translate(-50%)',
  },
  tooltipBalloonStyle: {
    left: '50%',
    transform: 'translate(-50%, 3px)',
    bottom: 'auto',

    fontWeight: 'bold',
  },
};

const SelectNowButton = styled.button({
  width: '100%',
  backgroundColor: dsPalette.themePrimary.toString(),
  border: 'none',
  color: palette.white.toString(),
  fontSize: '12px',
  fontWeight: 'bold',
  marginTop: '10px',
  padding: '10px',
  cursor: 'pointer',
  borderRadius: '5px',
  '&:hover': {
    opacity: 0.8,
  },
});

interface DragAndDropFileInputProps {
  onDrop(acceptedFiles: File[]): void;
  multiple?: boolean;
  accept?: string;
}

const DragAndDropFileInput: React.FC<DragAndDropFileInputProps> = ({
  onDrop,
  accept,
  multiple = true,
}) => {
  const onDropCallback = useCallback(onDrop, [onDrop]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
    accept: {
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
  });
  const [l10n]: UseL10n = useL10n();
  const attachIcon: ReactNode = (
    <WrapperHoverable title={l10n(Text.attachFile)} customStyle={tooltipCustomStyle}>
      <AttachSvg />
    </WrapperHoverable>
  );

  return (
    <Dropzone {...getRootProps()}>
      <input multiple={multiple} {...getInputProps()} accept={accept} />
      {isDragActive ? (
        <TextWrapper>{l10n(Text.dragActive)}</TextWrapper>
      ) : (
        <>
          {attachIcon}
          <TextWrapper>{l10n(Text.dropzoneText)}</TextWrapper>
        </>
      )}
    </Dropzone>
  );
};

export default DragAndDropFileInput;

interface Props {
  onClick: () => void;
}

export const SelectPhotoFromAlbumSelector: FC<Props> = ({ onClick }) => {
  const [l10n]: UseL10n = useL10n();

  const attachIcon: ReactNode = (
    <WrapperHoverable title={l10n(Text.selectPhotos)} customStyle={tooltipCustomStyle}>
      <AttachSvg />
    </WrapperHoverable>
  );

  const selectNowButton: ReactNode = (
    <SelectNowButton onClick={onClick}>{l10n(Text.selectPhotos)}</SelectNowButton>
  );

  return (
    <Dropzone style={{ cursor: 'default' }}>
      {attachIcon}
      <TextWrapper>{l10n(Text.selectFromAlbumSelectorText)}</TextWrapper>
      {selectNowButton}
    </Dropzone>
  );
};
