export interface DimensionType {
  width: { value: number; sign: number };
  height: { value: number; sign: number };
  top?: number;
  left?: number;
}

interface GetCoordinatesIssueRectangleTypes {
  coords: { x: number; y: number };
  dimensions: DimensionType;
  rect: DOMRect;
}
export const getCoordinatesIssueRectangle = ({
  coords,
  dimensions,
  rect,
}: GetCoordinatesIssueRectangleTypes) => {
  const { x, y } = coords;
  const { width, height } = dimensions;
  const tempX = x / rect.width;
  const tempY = 1 - y / rect.height;

  const relativeWidth = Math.abs(width.value) / rect.width;
  const relativeHeight = Math.abs(height.value) / rect.height;

  let pt1, pt2, pt3, pt4;
  if (height.sign >= 0 && width.sign >= 0) {
    pt1 = [tempX, tempY - relativeHeight]; // bottom left
    pt2 = [tempX, tempY]; // top left
    pt3 = [tempX + relativeWidth, tempY]; // top right
    pt4 = [tempX + relativeWidth, tempY - relativeHeight]; // bottom right
  } else if (height.sign < 0 && width.sign >= 0) {
    pt1 = [tempX, tempY]; // bottom left
    pt2 = [tempX, tempY + relativeHeight]; // top left
    pt3 = [tempX + relativeWidth, tempY + relativeHeight]; // top right
    pt4 = [tempX + relativeWidth, tempY]; // bottom right
  } else if (height.sign >= 0 && width.sign < 0) {
    pt1 = [tempX - relativeWidth, tempY - relativeHeight]; // bottom left
    pt2 = [tempX - relativeWidth, tempY]; // top left
    pt3 = [tempX, tempY]; // top right
    pt4 = [tempX, tempY - relativeHeight]; // bottom right
  } else {
    pt1 = [tempX - relativeWidth, tempY]; // bottom left
    pt2 = [tempX - relativeWidth, tempY + relativeHeight]; // top left
    pt3 = [tempX, tempY + relativeHeight]; // top right
    pt4 = [tempX, tempY]; // bottom right
  }

  return [...pt1, ...pt2, ...pt3, ...pt4];
};
