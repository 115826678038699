/* eslint-disable max-lines */
/**
 * @todo Move store only types to `store/Types.ts`
 * @todo Refactor components to refer this module.
 */
import { Cartesian2, Cartesian3 } from 'cesium';
import Color from 'color';
import { Coordinate } from 'ol/coordinate';
import { Extent as olExtent } from 'ol/extent';

import { Vector3 } from 'three';
import { DDMSESSIONObject } from './store/customStorage';
import { MarkerAreaState } from 'markerjs2';

export enum BuildLevel {
  PR = 'pr',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export enum NodeEnv {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export enum Region {
  DEFAULT = 'default',
  KSA = 'ksa',
}

// APIs related types
/**
 * Enum for classification of HTTP errors.
 */
export enum HTTPError {
  CLIENT_ERROR = 'client_error',
  CLIENT_AUTH_ERROR = 'client_auth_error',
  CLIENT_UNAUTHORIZED_ERROR = 'unauthorized',
  CLIENT_NOT_FOUND_ERROR = 'not_found',
  CLIENT_OUTDATED_ERROR = 'outdated',
  CLIENT_NOT_ACCEPTED_ERROR = 'not_accepted',
  CLIENT_UNPROCESSABLE_ENTITY_ERROR = 'unproccessable_entity',
  SERVER_ERROR = 'internal_server_error',
  UNKNOWN_ERROR = 'unknown_error',
}

/**
 * Enum for representing API status
 */
export enum APIStatus {
  IDLE = 'IDLE',
  PROGRESS = 'PROGRESS',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum APIQUERYSTATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  IDLE = 'idle',
}

/**
 * Enum for representing user class
 */
export enum UserRole {
  DUMMY = 'dummy',
  BASIC = 'basic',
  PRO = 'pro', // allow to create and delete projects
  PREMIUM = 'premium',
  MANAGER = 'manager',
  ADMIN = 'admin', // allow to create and delete projects
  MASTER = 'master',
}

export interface Pagination {
  offset: number;
  limit: number;
  nextOffset: number;
}

/**
 * @desc Enum for representing calender type
 */
export enum CalendarType {
  FROM_TODAY_UNTIL_2100,
  FROM_2010_UNTIL_TODAY,
  FROM_2016_UNTIL_PLUS_4,
  SELECTED_DATE,
}

export enum CalendarScreenSize {
  S = 'S',
  M = 'M',
  L = 'L',
}

export const enum EditableTextUI {
  TOPBAR = 'TOPBAR',
  GROUP_TITLE = 'GROUP_TITLE',
  CONTENT_TITLE = 'CONTENT_TITLE',
  OL_CONTENT_TITLE = 'OL_CONTENT_TITLE',
  INPUT_S = 'INPUT_S',
  INPUT_L = 'INPUT_L',
  MARKER_PINPOINTER_MAP = 'MARKER_PINPOINTER_MAP',
  MARKER_PINPOINTER_SIDEBAR = 'MARKER_PINPOINTER_SIDEBAR',
}

export const TooltipAvailableEditableTextUI: Readonly<EditableTextUI[]> = [
  EditableTextUI.CONTENT_TITLE,
  EditableTextUI.GROUP_TITLE,
  EditableTextUI.OL_CONTENT_TITLE,
];

export enum CalendarScreenTab {
  LIST = 'list',
  CALENDAR = 'calendar',
}

export enum DateScreenButton {
  SPLIT_VIEW = 'split_view',
  MAP_CONTENTS_UPLOAD = 'MAP_CONTENTS_UPLOAD',
  DOWNLOAD = 'download',
  SBVC = 'sbvc',
  SBVC_POPUP = 'sbvc_popup',
}

export enum ModalPlacement {
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  TOP,
  BOTTOM,
  MIDDLE_RIGHT,
}

/**
 * Enum for 2D cut & fill
 */
export enum SurveyType {
  CUT_MIN,
  CUT_MAX,
  FILL_MIN,
  FILL_MAX,
}

/**
 * @desc Index of kind of survey type
 */
export enum VolumeSlider {
  CUT = 'CUT',
  FILL = 'FILL',
}

export type SurveyDoubleSlider = [number, number, number, number];
export type SurveySingleSlider = [number, number];

/**
 * @desc Enum for representing volume calculation method
 */
export enum VolumeCalcMethod {
  BASIC = 'vc',
  SURVEY = 'sbvc',
  DESIGN = 'dbvc',
}

export enum PointCloudEngine {
  POTREE = 'potree',
  CESIUM = 'cesium',
}

export enum MeshEngine {
  CESIUM = 'cesium',
  THREEJS = 'threejs',
}

export enum TerrainEditingMethod {
  PLANE_FITTING = 'plane_fitting',
  TRIANGULATION = 'triangulation',
}

/**
 * @desc type of toast
 */
export enum Toast {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  DARK = 'dark',
}

export enum Feature {
  DDM = 'ddm',
  ESS = 'ess',
  // TODO: @ebraj-angelswing
  // WIP
  ADS = 'ads',
}

export enum MoveOption {
  FIRST = 'first',
  PREVIOUS = 'previous',
  NEXT = 'next',
  LAST = 'last',
}

export interface FullUser {
  readonly id: number;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly name?: string;
  readonly contactNumber: string;
  readonly organization: string;
  readonly country: string;
  readonly language?: Language;
  readonly purpose: string;
  readonly avatar?: string;
  readonly role: UserRole;
  readonly createdAt: Date;
  readonly featurePermission: number;
  readonly unit?: UnitType;
  readonly currentSession: CurrentSession;
  readonly accessibleDroneStations?: DroneStation[];
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RestrictedUser extends Omit<FullUser, 'role'> {}
export type User = FullUser | RestrictedUser;

export interface APIUser {
  readonly id: string;
  readonly type: 'users';
  /**
   * @todo
   * Please remove `?`s from these fields,
   * after adding the fields to old users.
   * DO NOT REMOVE `avatar`.
   */
  readonly attributes: Overwrite<
    Omit<User, 'id'>,
    {
      readonly contactNumber?: string | null;
      readonly organization?: string | null;
      readonly country?: string;
      readonly purpose?: string;
      readonly language?: string;
      readonly avatar: string | null;
      readonly role: UserRole;
    }
  >;
}

export interface CurrentSession {
  id: number;
  createdAt: string;
}

export interface APIAuthUser extends APIUser {
  readonly attributes: APIUser['attributes'] & {
    readonly token: string;
    readonly refreshToken: string;
    readonly createdAt: string;
    readonly currentSession: CurrentSession;
  };
}

export interface UserPassword {
  readonly password: string;
  readonly passwordConfirmation: string;
}

export interface AutoSignIn {
  autoSignIn: boolean;
}

export interface UserConfig {
  readonly isPointEditTutorialPanelShown?: boolean;
}

export interface PlanConfig {
  /**
   * Unique id of the plan owner (?).
   * This is embedded in the URL, it has to be a slug-like string.
   * Example: Samsung Corp. Ltd. -> https://app.angelswing.io/login/samsung
   */
  readonly slug?: string;
  /**
   * Name of the plan owner. This is rendered in view as-is.
   * Example: Samsung Corp. Ltd.
   */
  readonly companyName?: string;
  /**
   * The plan owner logo. This is rendered on the hero section of the login page,
   * Optimal width is 300px, to fit in most scenarios of the hero container.
   */
  readonly logoUrl?: string;
  /**
   * The plan owner login background.
   * Optimal size is 1920x1080, most common user screen size.
   */
  readonly bgUrl?: string;
  /**
   * The logo specifically for the navbar.
   * The maximum size that would fit our UI nicely is 200x24 px.
   * Which is exactly the navbar size logo.
   */
  readonly navbarLogoUrl?: string;
  /**
   * The logo for the square-shaped top-left corner.
   * The maximum size that would fit our UI nicely is 30x30 px.
   */
  readonly squareLogoUrl?: string;
  /**
   * Primary color. For buttons, checkboxes, etc.
   */
  readonly primaryColor?: string;
  /**
   * Logo for the one right on top of the login and other non-scrollable non-auth pages.
   */
  readonly loginLogoTopUrl?: string;
  /**
   * Logo for the one right on the bottom of the login and other non-scrollable non-auth pages.
   */
  readonly loginLogoBottomUrl?: string;
  /**
   * Logo for top left corner of the non-auth page.
   */
  readonly logoTopLeftUrl?: string;
  /**
   * Logo for top left corner of the non-auth page.
   */
  readonly logoTopRightUrl?: string;
}

export interface PlanType {
  readonly id: number;
  readonly name: string;
  readonly userLimit: number;
  readonly processingLimit: number;
  readonly projectLimit: number;
  readonly sourcePhotoLimit: number;
  readonly inactiveDaysToNotify: number;
}

export interface Plan {
  readonly id: number;
  readonly planConfig: PlanConfig;
  readonly planType: PlanType;
  readonly companyName: string;
  readonly slug: string;
  readonly contractStartAt: string;
  readonly contractEndAt: string;
}

export interface Notice {
  id: number;
  type: string;
  url: string;
  title: string;
  headings: string[];
  isRead: boolean;
  isShown: boolean;
  isHidden: boolean;
  createdAt: Date;
}

export enum NotificationType {
  USER = 'user',
  INFORMATIONAL = 'informational',
  PROMOTIONAL = 'promotional',
}

export enum NotificationStatus {
  SENT = 'sent',
  VIEWED = 'viewed',
  READ = 'read',
}

export enum NotificationSubTypes {
  ISSUE = 'issue',
  PANORAMA = 'panorama',
}

export enum IssueNotiMsgEvent {
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
}

export interface IssueNotiMsgPayload {
  issueId: number;
  projectId?: number;
  event: IssueNotiMsgEvent;
}
export interface ThreeSixtyNotiMsgPayload {
  photoIds: Array<number | string>;
  projectId: number | string;
}

export type NotiMsgPayloadType = IssueNotiMsgPayload | ThreeSixtyNotiMsgPayload;

export interface NotificationContainer {
  id: number;
  type: NotificationType;
  title: string;
  body: string;
  subType: NotificationSubTypes;
  messagePayload: NotiMsgPayloadType;
}

export interface Notification {
  id: number;
  userId?: number;
  createdAt: Date;
  statusUpdatedAt: Date;
  status: NotificationStatus;
  notification: NotificationContainer;
}

export interface WSNotificationMessage {
  id: Notification['id'];
  type: Notification['notification']['type'];
  title: Notification['notification']['title'];
  body: Notification['notification']['body'];
  created_at: Notification['createdAt'];
  updated_at: Notification['statusUpdatedAt'];
  sub_type: Notification['notification']['subType'];
  message_payload: Notification['notification']['messagePayload'];
}

export interface APIScreen {
  readonly id: number | string;
  readonly projectId: number;
  readonly title: string | null;
  readonly appearAt: string;
  readonly contentIds: number[];
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface Screen {
  readonly id: number | string;
  readonly title: string;
  readonly appearAt: Date;
  readonly contentIds: Array<Content['id']>;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

/**
 * Enum for representing project data processing status
 */
export enum ContentProcessingStatus {
  READY = 'ready',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

/**
 * Enum for representing project data processing status
 */
export enum AcceptStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  DENIED = 'denied',
}
export interface ProjectWithConfig extends Project {
  /**
   * @todo
   * need to change this to `configs` like `Projects.projects`
   * Ex) configs: { [projectId]: ProjectConfig }
   */
  config: ProjectConfig;
}

export enum ProjectStatus {
  ENDED = 'ended',
}
export interface Project {
  readonly id: number;
  readonly permissionInviter: {
    readonly id: User['id'];
    readonly avatar?: User['avatar'];
    readonly lastName: User['lastName'];
    readonly firstName: User['firstName'];
    readonly email: User['email'];
    readonly organization: User['organization'];
  };
  readonly owner: {
    readonly id: User['id'];
    readonly avatar?: User['avatar'];
    readonly lastName: User['lastName'];
    readonly firstName: User['firstName'];
    readonly email: User['email'];
    readonly organization: User['organization'];
  };
  readonly title: string;
  readonly coordinateSystem?: ProjectionEnum;
  readonly description: string;
  readonly logo?: string;
  readonly planId?: string;
  readonly thumbnail?: string;
  readonly permissionStatus: AcceptStatus;
  readonly permissionRole: PermissionRole;
  readonly features: PermissionFeature;
  readonly permissionsCount: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly lastOpenedAt?: Date;
  readonly availableDates: Date[];
  readonly type: ProjectType;
  readonly unit?: UnitType;
  readonly integrations?: ProjectIntegrations;
  readonly allowedEmailDomains: string[];
  readonly projectConfig?: {
    readonly withGcp: boolean;
    readonly withRtk: boolean;
    readonly graceUntil?: string;
    readonly contractEndedAt?: string;
  };
  readonly status?: string;
}

export interface APIProject {
  readonly id: number;
  readonly owner: {
    readonly id: number;
    readonly avatar: string | null;
    readonly lastName: string;
    readonly firstName: string;
    readonly email: string;
    readonly organization: string;
  };
  readonly permissionInviter: {
    readonly id: User['id'];
    readonly avatar?: User['avatar'];
    readonly lastName: User['lastName'];
    readonly firstName: User['firstName'];
    readonly email: User['email'];
    readonly organization: User['organization'];
  };
  readonly title: string;
  readonly description: string;
  readonly logo: string | null;
  readonly thumbnail: string | null;
  readonly coordinateSystem: CoordinateSystem | null;
  readonly status: string;
  readonly permissionStatus: AcceptStatus;
  readonly permissionRole: PermissionRole;
  readonly features: PermissionFeature;
  readonly permissionsCount: number;
  readonly config: ProjectConfig;
  readonly projectConfig?: Project['projectConfig'];
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly lastOpenedAt?: string;
  readonly availableDates: string[];
  readonly type: ProjectType;
  readonly unit: UnitType;
  readonly integrations?: ProjectIntegrations;
  readonly allowedEmailDomains: string[];
}

export interface DroneStation {
  readonly id: number;
  readonly title: string;
  readonly description: string;
  readonly clientUserId: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly serviceUrl: string;
}

export interface ProjectIntegrations {
  readonly '3i': boolean;
  readonly droneStation: boolean;
}

export interface PermissionFeature {
  readonly ddm: boolean;
  readonly ess: boolean;
  readonly oneD: boolean;
  readonly droneStation: boolean;
}

export enum ProjectType {
  USER = 'user',
  DEMO = 'demo',
}

/**
 * Enum for representing permission level
 */
export enum PermissionRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  VIEWER = 'viewer',
  PILOT = 'pilot',
  DEMO = 'demo',
}

/**
 * Enum for representing permission status
 */
export enum PermissionStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  DENIED = 'denied',
}

export interface Permission {
  readonly id: number;
  readonly projectId: Project['id'];
  readonly userId: User['id'];
  readonly avatar: User['avatar'];
  readonly lastName: User['lastName'];
  readonly firstName: User['firstName'];
  readonly email: User['email'];
  readonly organization: User['organization'];
  readonly role: PermissionRole;
  readonly status: PermissionStatus;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly featureEss?: boolean;
  readonly features?: PermissionFeature;
}

export interface APIPermission {
  readonly id: number;
  readonly userId: number;
  readonly avatar: string | null;
  readonly lastName: string;
  readonly firstName: string;
  readonly email: string;
  readonly organization: string;
  readonly status: PermissionStatus;
  readonly role: PermissionRole;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly user: FullUser;
}

export interface CustomModel {
  readonly name: string;
  readonly specification: string;
  readonly length: string;
  readonly width: string;
  readonly height: string;
  readonly radius: string;
}

export type AttachmentContentTypeMap = Partial<Record<AttachmentType, ContentType>>;
/**
 * Enum for representing Content type
 */
export enum ContentType {
  MAP = 'orthophoto',
  DSM = 'dsm',
  DTM = 'dtm',
  POINTCLOUD = 'las',
  THREE_D_MESH = 'tiled_model',
  THREE_D_ORTHO = 'terrain',
  BLUEPRINT_PDF = 'blueprint_pdf',
  BLUEPRINT_DXF = 'blueprint_dxf',
  BLUEPRINT_DWG = 'blueprint_dwg',
  DESIGN_DXF = 'design_dxf',
  MARKER = 'marker',
  LENGTH = 'length',
  THREE_HEIGHT = 'three_height',
  THREE_LENGTH = 'three_length',
  THREE_AREA = 'three_area',
  AREA = 'area',
  VOLUME = 'volume',
  GCP_GROUP = 'gcp_group',
  ESS_MODEL = 'model',
  ESS_ARROW = 'waypoint',
  ESS_POLYGON = 'polygon',
  ESS_POLYLINE = 'polyline',
  ESS_MODEL_CUSTOM = 'model_custom',
  ESS_TEXT = 'text',
  ESS_LINE_TEXT = 'line_text',
  GROUP = 'content_group',
  ISSUE_POINT = 'issue_point',
  ISSUE_PHOTO = 'issue_photo',
  BIM = 'bim',

  FLIGHT_PLAN = 'flight-plan',
  FLIGHT_SCHEDULE = 'flight-schedule',
  DRONE_STATION = 'drone-station',

  SLIDE = 'slide',
  ANNOTATION = 'annotation',
  // THREE_PHOTO_ALBUM = 'three_photo_album',
  FLATTEN_MAP = 'flatten_map',
}

/**
 * Related to KMZ/KML...
 */
export enum KMLContentType {
  KML_POLYLINE = 'polyline',
  KML_POLYGON = 'polygon',
}

export enum ContentCategory {
  MAP = 'map',
  OVERLAY = 'overlay',
  MEASUREMENT = 'measurement',
  ESS = 'ess',
  METADATA = 'metadata',
  ISSUE = 'issue',
  TERRAIN = 'terrain',
  DASHBOARD = 'dashboard',
  DRONE_STATION = 'drone-station',
  FLIGHT_PLAN = 'flight-plan',
  FLIGHT_SCHEDULE = 'flight-schedule',
  PRESENTATION = 'presentation',
  VIEWPOINT_CAPTURE = 'viewpoint',
  // PHOTO = 'photo',
}

export enum ContentsQueryParam {
  SCREEN = 'screen',
  TITLE = 'title',
  TYPE = 'type',
  TYPE_AND_SCREENID = 'type-and-screenid',
  ID = 'id',
}

export enum ScreensQueryParam {
  TITLE_AND_DATE = 'title-and-date',
  DATE = 'date',
  ID = 'id',
  CONTENT_ID = 'content-id',
}

/**
 * Volume algorithms
 */
export enum BasicCalcBasePlane {
  LOWEST_POINT = 'lowest',
  HIGHEST_POINT = 'highest',
  AVERAGE = 'average',
  TRIANGULATED = 'triangulation',
  CUSTOM = 'custom',
}

/**
 * Track Actions
 */

export enum TrackActions {
  MAP_VIEW = 'map-view',
  MAP_DOWNLOAD = 'map-download',
  MAP_CONTROLS = 'map-controls',
  MAP_OUTPUT = 'map-output',
  MAP_UPLOAD = 'map-upload',
  MAP_TOOLS = 'map-tools',
  EARTH_WORK = 'earth-work',

  COLOR_SELECT = 'color-select',
  ESS_TOOLS_CLICK = 'ess-tools-click',

  CONTENT_ITEM = 'content-item',
  CHANGE_TAB = 'change-tab',

  ESS_MODEL_CATEGORY_LIST_CLICK = 'ess-model-category-list-click',
  ESS_MODEL_LIST_CLICK = 'ess-model-list-click',

  MEASUREMENT_EXPORT = 'measurement-export',
  MEASUREMENT_CREATE = 'measurement-create',

  CONTENT_GROUP = 'content-group',
  CONTENT_UPLOAD = 'content-upload',

  ELEVATION_PROFILE_TOOLS = 'elevation-profile-tools',

  PRESENTATION_HORIZONTAL_LIST = 'presentation-horizontal-list',

  EARTHWORK_REPORT = 'earthwork-report',

  LOGIN = 'login',

  PHOTO_ALBUM = 'photo-album',
  THREESIXTY_PHOTO_ALBUM = 'threesixty-photo-album',
  THREESIXTY_MAP = 'threesixty-map',
}

/**
 * Track Labels
 */

export enum TrackLabels {
  BTN_TOGGLE_BASEMAP = 'btn-toggle-basemap',
  BTN_CONTENTS_LIST = 'btn-contents-list',
  BTN_FONT_COLOR = 'btn-font-color',
  BTN_BACKGROUND_COLOR = 'btn-background-color',
  BTN_FONT_SIZE_INCREASE = 'btn-font-size-increase',
  BTN_GEOLOCATION = 'btn-geolocation',
  BTN_TOGGLE_HILLSHADE = 'btn-toggle-hillshade',
  BTN_TRANSFORM_ROTATE = 'btn-transform-rotate',
  BTN_TRANSFORM_SCALE = 'btn-transform-scale',
  BTN_LOCK = 'btn-lock',
  BTN_SCREEN_CAPTURE = 'btn-screen-capture',
  BTN_TOGGLE_ELEVATION_PROFILE = 'btn-toggle-elevation-profile',
  BTN_TOGGLE_CLAMPED_VOLUME = 'btn-toggle-clamped-volume',
  BTN_TOGGLE_CALIBRATE_LOCATION = 'btn-toggle-calibrate-location',
  BTN_TAB = 'btn-tab',
  BTN_CONTENT_UPLOAD = 'btn-content-upload',
  BTN_MEASUREMENT_EXPORT = 'btn-measurement-export',
  BTN_CREATE_CONTENT_GROUP = 'btn-create-content-group',
  BTN_TOGGLE_WORK_RADIUS = 'btn-toggle-work-radius',
  BTN_TOGGLE_ESS_MODEL = 'btn-toggle-ess-model',
  BTN_TOGGLE_3D_TERRAIN = 'btn-toggle-3d-terrain',
  BTN_CENTER = 'btn-center',
  BTN_INVITE = 'btn-invite',
  BTN_DOWNLOAD = 'btn-download',
  BTN_REPORT = 'btn-report',
  BTN_PRINT = 'btn-print',
  BTN_HISTORY = 'btn-history',
  BTN_SHARE = 'btn-share',
  BTN_2D_VIEW = 'btn-2d-view',
  BTN_ZOOM_IN = 'btn-zoom-in',
  BTN_ZOOM_OUT = 'btn-zoom-out',
  BTN_SLIDE_CREATE = 'btn-slide-create',
  BTN_ENTER_PRESENT_MODE = 'btn-enter-present-mode',
  BTN_PRESENTATION_FOLDER_CREATE = 'btn-presentation-folder-create',
  BTN_ANNOTATE = 'btn-annotate',
  BTN_UPLOAD_OVERLAY = 'btn-upload-overlay',
  BTN_DOWNLOAD_REPORT = 'btn-download-report',
  BTN_LOGIN_SAML = 'btn-login-saml',
  BTN_PRESENTATION_LIST_OPEN = 'btn-presentation-list-open',
  BTN_PRESENTATION_LIST_CLOSE = 'btn-presentation-list-close',
  BTN_THREESIXTY_2D = 'btn-threesixty-2d',
  BTN_THREESIXTY_3D = 'btn-threesixty-3d',
  BTN_FOLDER_PHOTO_ALBUM = 'btn-folder-photo-album',
  BTN_SNAP_TOGGLE = 'btn-snap-toggle',
  BTN_EXPORT_EARTHWORK = 'btn-export-earthwork',
  BTN_SHOW_BURNDOWN_CHART = 'btn-show-burndown-chart',
  BTN_CREATE_EARTHWROK = 'btn-create-earthwork',
  BTN_VOLUME_NOW = 'btn-add-volume-now',
}

/**
 * Point is a pair of x and y.
 * @example [x, y]
 */
export type Point = number[];

/**
 * Geopoint is a pair of longitude and latitude.
 * @example [lon, lat]
 */
export type GeoPoint = number[];

/**
 * Dimension is a pair of longitude and latitude.
 * @example [length, width, height, radius]
 */
export type Dimension = [number, number, number];

/**
 * @todo please combine this with other types, `[number, number]`
 */
export type GCPPoint = [number, number];

/**
 * To optimize the data store in State & Database (network transfer)
 * The data is store by its values but not an object
 * @example [lon, lat, dist, alt]
 */
export type LengthElevationRawData = [number, number, number, number];

/**
 * Predefined Projection
 */
export enum ProjectionEnum {
  GRS80_EPSG_5182_EN = 'GRS80_EPSG_5182_EN',
  GRS80_EPSG_5185_EN = 'GRS80_EPSG_5185_EN',
  GRS80_EPSG_5186_EN = 'GRS80_EPSG_5186_EN',
  GRS80_EPSG_5187_EN = 'GRS80_EPSG_5187_EN',
  GRS80_EPSG_5188_EN = 'GRS80_EPSG_5188_EN',
  WGS84_EPSG_3414_EN = 'WGS84_EPSG_3414_EN',
  WGS84_EPSG_4326_LL = 'WGS84_EPSG_4326_LL',

  // Turkey
  GRS80_EPSG_5255_EN = 'GRS80_EPSG_5255_EN',
  GRS80_EPSG_4917_EN = 'GRS80_EPSG_4917_EN',
  GRS80_EPSG_4918_EN = 'GRS80_EPSG_4918_EN',
  GRS80_EPSG_4919_EN = 'GRS80_EPSG_4919_EN',
  GRS80_EPSG_4896_EN = 'GRS80_EPSG_4896_EN',
  GRS80_EPSG_5332_EN = 'GRS80_EPSG_5332_EN',
  GRS80_EPSG_7789_EN = 'GRS80_EPSG_7789_EN',
  GRS80_EPSG_9988_EN = 'GRS80_EPSG_9988_EN',

  // Indonesia
  WGS84_EPSG_2310_EN = 'WGS84_EPSG_2310_EN',
  WGS84_EPSG_9489_EN = 'WGS84_EPSG_9489_EN',
  WGS84_EPSG_23879_EN = 'WGS84_EPSG_23879_EN',
  WGS84_EPSG_32749_EN = 'WGS84_EPSG_32749_EN',
  WGS84_EPSG_32750_EN = 'WGS84_EPSG_32750_EN',
  WGS84_EPSG_32754_EN = 'WGS84_EPSG_32754_EN',

  // WSG84 UTM
  WGS84_EPSG_32601_EN = 'WGS84_EPSG_32601_EN',
  WGS84_EPSG_32602_EN = 'WGS84_EPSG_32602_EN',
  WGS84_EPSG_32603_EN = 'WGS84_EPSG_32603_EN',
  WGS84_EPSG_32604_EN = 'WGS84_EPSG_32604_EN',
  WGS84_EPSG_32605_EN = 'WGS84_EPSG_32605_EN',
  WGS84_EPSG_32606_EN = 'WGS84_EPSG_32606_EN',
  WGS84_EPSG_32607_EN = 'WGS84_EPSG_32607_EN',
  WGS84_EPSG_32608_EN = 'WGS84_EPSG_32608_EN',
  WGS84_EPSG_32609_EN = 'WGS84_EPSG_32609_EN',
  WGS84_EPSG_32610_EN = 'WGS84_EPSG_32610_EN',
  WGS84_EPSG_32611_EN = 'WGS84_EPSG_32611_EN',
  WGS84_EPSG_32612_EN = 'WGS84_EPSG_32612_EN',
  WGS84_EPSG_32613_EN = 'WGS84_EPSG_32613_EN',
  WGS84_EPSG_32614_EN = 'WGS84_EPSG_32614_EN',
  WGS84_EPSG_32615_EN = 'WGS84_EPSG_32615_EN',
  WGS84_EPSG_32616_EN = 'WGS84_EPSG_32616_EN',
  WGS84_EPSG_32617_EN = 'WGS84_EPSG_32617_EN',
  WGS84_EPSG_32618_EN = 'WGS84_EPSG_32618_EN',
  WGS84_EPSG_32619_EN = 'WGS84_EPSG_32619_EN',

  // US NAD
  WGS84_EPSG_6355_EN = 'WGS84_EPSG_6355_EN',
  WGS84_EPSG_9748_EN = 'WGS84_EPSG_9748_EN',
  WGS84_EPSG_6356_EN = 'WGS84_EPSG_6356_EN',
  WGS84_EPSG_9749_EN = 'WGS84_EPSG_9749_EN',
  WGS84_EPSG_6403_EN = 'WGS84_EPSG_6403_EN',
  WGS84_EPSG_6395_EN = 'WGS84_EPSG_6395_EN',
  WGS84_EPSG_6396_EN = 'WGS84_EPSG_6396_EN',
  WGS84_EPSG_6397_EN = 'WGS84_EPSG_6397_EN',
  WGS84_EPSG_6398_EN = 'WGS84_EPSG_6398_EN',
  WGS84_EPSG_6399_EN = 'WGS84_EPSG_6399_EN',
  WGS84_EPSG_6400_EN = 'WGS84_EPSG_6400_EN',
  WGS84_EPSG_6401_EN = 'WGS84_EPSG_6401_EN',
  WGS84_EPSG_6402_EN = 'WGS84_EPSG_6402_EN',
  WGS84_EPSG_6404_EN = 'WGS84_EPSG_6404_EN',
  WGS84_EPSG_6405_EN = 'WGS84_EPSG_6405_EN',
  WGS84_EPSG_6406_EN = 'WGS84_EPSG_6406_EN',
  WGS84_EPSG_6407_EN = 'WGS84_EPSG_6407_EN',
  WGS84_EPSG_6408_EN = 'WGS84_EPSG_6408_EN',
  WGS84_EPSG_6409_EN = 'WGS84_EPSG_6409_EN',
  WGS84_EPSG_6410_EN = 'WGS84_EPSG_6410_EN',
  WGS84_EPSG_6411_EN = 'WGS84_EPSG_6411_EN',
  WGS84_EPSG_6412_EN = 'WGS84_EPSG_6412_EN',
  WGS84_EPSG_6413_EN = 'WGS84_EPSG_6413_EN',
  WGS84_EPSG_6415_EN = 'WGS84_EPSG_6415_EN',
  WGS84_EPSG_6416_EN = 'WGS84_EPSG_6416_EN',
  WGS84_EPSG_6417_EN = 'WGS84_EPSG_6417_EN',
  WGS84_EPSG_6418_EN = 'WGS84_EPSG_6418_EN',
  WGS84_EPSG_6419_EN = 'WGS84_EPSG_6419_EN',
  WGS84_EPSG_6420_EN = 'WGS84_EPSG_6420_EN',
  WGS84_EPSG_6421_EN = 'WGS84_EPSG_6421_EN',
  WGS84_EPSG_6422_EN = 'WGS84_EPSG_6422_EN',
  WGS84_EPSG_6423_EN = 'WGS84_EPSG_6423_EN',
  WGS84_EPSG_6424_EN = 'WGS84_EPSG_6424_EN',
  WGS84_EPSG_6425_EN = 'WGS84_EPSG_6425_EN',
  WGS84_EPSG_6426_EN = 'WGS84_EPSG_6426_EN',
  WGS84_EPSG_6427_EN = 'WGS84_EPSG_6427_EN',
  WGS84_EPSG_6428_EN = 'WGS84_EPSG_6428_EN',
  WGS84_EPSG_6429_EN = 'WGS84_EPSG_6429_EN',
  WGS84_EPSG_6430_EN = 'WGS84_EPSG_6430_EN',
  WGS84_EPSG_6431_EN = 'WGS84_EPSG_6431_EN',
  WGS84_EPSG_6432_EN = 'WGS84_EPSG_6432_EN',
  WGS84_EPSG_6433_EN = 'WGS84_EPSG_6433_EN',
  WGS84_EPSG_6434_EN = 'WGS84_EPSG_6434_EN',
  WGS84_EPSG_6435_EN = 'WGS84_EPSG_6435_EN',
  WGS84_EPSG_6436_EN = 'WGS84_EPSG_6436_EN',
  WGS84_EPSG_6437_EN = 'WGS84_EPSG_6437_EN',
  WGS84_EPSG_6438_EN = 'WGS84_EPSG_6438_EN',
  WGS84_EPSG_6440_EN = 'WGS84_EPSG_6440_EN',
  WGS84_EPSG_6441_EN = 'WGS84_EPSG_6441_EN',
  WGS84_EPSG_6442_EN = 'WGS84_EPSG_6442_EN',
  WGS84_EPSG_6443_EN = 'WGS84_EPSG_6443_EN',
  WGS84_EPSG_6444_EN = 'WGS84_EPSG_6444_EN',
  WGS84_EPSG_6445_EN = 'WGS84_EPSG_6445_EN',
  WGS84_EPSG_6446_EN = 'WGS84_EPSG_6446_EN',
  WGS84_EPSG_6447_EN = 'WGS84_EPSG_6447_EN',
  WGS84_EPSG_6448_EN = 'WGS84_EPSG_6448_EN',
  WGS84_EPSG_6449_EN = 'WGS84_EPSG_6449_EN',
  WGS84_EPSG_6450_EN = 'WGS84_EPSG_6450_EN',
  WGS84_EPSG_6451_EN = 'WGS84_EPSG_6451_EN',
  WGS84_EPSG_6452_EN = 'WGS84_EPSG_6452_EN',
  WGS84_EPSG_6453_EN = 'WGS84_EPSG_6453_EN',
  WGS84_EPSG_6454_EN = 'WGS84_EPSG_6454_EN',
  WGS84_EPSG_6455_EN = 'WGS84_EPSG_6455_EN',
  WGS84_EPSG_6456_EN = 'WGS84_EPSG_6456_EN',
  WGS84_EPSG_6457_EN = 'WGS84_EPSG_6457_EN',
  WGS84_EPSG_6458_EN = 'WGS84_EPSG_6458_EN',
  WGS84_EPSG_6459_EN = 'WGS84_EPSG_6459_EN',
  WGS84_EPSG_6460_EN = 'WGS84_EPSG_6460_EN',
  WGS84_EPSG_6461_EN = 'WGS84_EPSG_6461_EN',
  WGS84_EPSG_6462_EN = 'WGS84_EPSG_6462_EN',
  WGS84_EPSG_6463_EN = 'WGS84_EPSG_6463_EN',
  WGS84_EPSG_6464_EN = 'WGS84_EPSG_6464_EN',
  WGS84_EPSG_6465_EN = 'WGS84_EPSG_6465_EN',
  WGS84_EPSG_6466_EN = 'WGS84_EPSG_6466_EN',
  WGS84_EPSG_6467_EN = 'WGS84_EPSG_6467_EN',
  WGS84_EPSG_6468_EN = 'WGS84_EPSG_6468_EN',
  WGS84_EPSG_6469_EN = 'WGS84_EPSG_6469_EN',
  WGS84_EPSG_6470_EN = 'WGS84_EPSG_6470_EN',
  WGS84_EPSG_6471_EN = 'WGS84_EPSG_6471_EN',
  WGS84_EPSG_6472_EN = 'WGS84_EPSG_6472_EN',
  WGS84_EPSG_6473_EN = 'WGS84_EPSG_6473_EN',
  WGS84_EPSG_6474_EN = 'WGS84_EPSG_6474_EN',
  WGS84_EPSG_6475_EN = 'WGS84_EPSG_6475_EN',
  WGS84_EPSG_6476_EN = 'WGS84_EPSG_6476_EN',
  WGS84_EPSG_6477_EN = 'WGS84_EPSG_6477_EN',
  WGS84_EPSG_6478_EN = 'WGS84_EPSG_6478_EN',
  WGS84_EPSG_6479_EN = 'WGS84_EPSG_6479_EN',
  WGS84_EPSG_6483_EN = 'WGS84_EPSG_6483_EN',
  WGS84_EPSG_6484_EN = 'WGS84_EPSG_6484_EN',
  WGS84_EPSG_6485_EN = 'WGS84_EPSG_6485_EN',
  WGS84_EPSG_6486_EN = 'WGS84_EPSG_6486_EN',
  WGS84_EPSG_6487_EN = 'WGS84_EPSG_6487_EN',
  WGS84_EPSG_6488_EN = 'WGS84_EPSG_6488_EN',
  WGS84_EPSG_6489_EN = 'WGS84_EPSG_6489_EN',
  WGS84_EPSG_6490_EN = 'WGS84_EPSG_6490_EN',
  WGS84_EPSG_6491_EN = 'WGS84_EPSG_6491_EN',
  WGS84_EPSG_6492_EN = 'WGS84_EPSG_6492_EN',
  WGS84_EPSG_6493_EN = 'WGS84_EPSG_6493_EN',
  WGS84_EPSG_6494_EN = 'WGS84_EPSG_6494_EN',
  WGS84_EPSG_6495_EN = 'WGS84_EPSG_6495_EN',
  WGS84_EPSG_6496_EN = 'WGS84_EPSG_6496_EN',
  WGS84_EPSG_6498_EN = 'WGS84_EPSG_6498_EN',
  WGS84_EPSG_6499_EN = 'WGS84_EPSG_6499_EN',
  WGS84_EPSG_6500_EN = 'WGS84_EPSG_6500_EN',
  WGS84_EPSG_6501_EN = 'WGS84_EPSG_6501_EN',
  WGS84_EPSG_6502_EN = 'WGS84_EPSG_6502_EN',
  WGS84_EPSG_6503_EN = 'WGS84_EPSG_6503_EN',
  WGS84_EPSG_6504_EN = 'WGS84_EPSG_6504_EN',
  WGS84_EPSG_6505_EN = 'WGS84_EPSG_6505_EN',
  WGS84_EPSG_6506_EN = 'WGS84_EPSG_6506_EN',
  WGS84_EPSG_6507_EN = 'WGS84_EPSG_6507_EN',
  WGS84_EPSG_6509_EN = 'WGS84_EPSG_6509_EN',
  WGS84_EPSG_6510_EN = 'WGS84_EPSG_6510_EN',
  WGS84_EPSG_6511_EN = 'WGS84_EPSG_6511_EN',
  WGS84_EPSG_6512_EN = 'WGS84_EPSG_6512_EN',
  WGS84_EPSG_6513_EN = 'WGS84_EPSG_6513_EN',
  WGS84_EPSG_6514_EN = 'WGS84_EPSG_6514_EN',
  WGS84_EPSG_6515_EN = 'WGS84_EPSG_6515_EN',
  WGS84_EPSG_6516_EN = 'WGS84_EPSG_6516_EN',
  WGS84_EPSG_6880_EN = 'WGS84_EPSG_6880_EN',
  WGS84_EPSG_6518_EN = 'WGS84_EPSG_6518_EN',
  WGS84_EPSG_6519_EN = 'WGS84_EPSG_6519_EN',
  WGS84_EPSG_6520_EN = 'WGS84_EPSG_6520_EN',
  WGS84_EPSG_6521_EN = 'WGS84_EPSG_6521_EN',
  WGS84_EPSG_6522_EN = 'WGS84_EPSG_6522_EN',
  WGS84_EPSG_6523_EN = 'WGS84_EPSG_6523_EN',
  WGS84_EPSG_6524_EN = 'WGS84_EPSG_6524_EN',
  WGS84_EPSG_6525_EN = 'WGS84_EPSG_6525_EN',
  WGS84_EPSG_6526_EN = 'WGS84_EPSG_6526_EN',
  WGS84_EPSG_6527_EN = 'WGS84_EPSG_6527_EN',
  WGS84_EPSG_6528_EN = 'WGS84_EPSG_6528_EN',
  WGS84_EPSG_6529_EN = 'WGS84_EPSG_6529_EN',
  WGS84_EPSG_6530_EN = 'WGS84_EPSG_6530_EN',
  WGS84_EPSG_6531_EN = 'WGS84_EPSG_6531_EN',
  WGS84_EPSG_6532_EN = 'WGS84_EPSG_6532_EN',
  WGS84_EPSG_6533_EN = 'WGS84_EPSG_6533_EN',
  WGS84_EPSG_6534_EN = 'WGS84_EPSG_6534_EN',
  WGS84_EPSG_6535_EN = 'WGS84_EPSG_6535_EN',
  WGS84_EPSG_6536_EN = 'WGS84_EPSG_6536_EN',
  WGS84_EPSG_6537_EN = 'WGS84_EPSG_6537_EN',
  WGS84_EPSG_6538_EN = 'WGS84_EPSG_6538_EN',
  WGS84_EPSG_6539_EN = 'WGS84_EPSG_6539_EN',
  WGS84_EPSG_6540_EN = 'WGS84_EPSG_6540_EN',
  WGS84_EPSG_6541_EN = 'WGS84_EPSG_6541_EN',
  WGS84_EPSG_6542_EN = 'WGS84_EPSG_6542_EN',
  WGS84_EPSG_6543_EN = 'WGS84_EPSG_6543_EN',
  WGS84_EPSG_6544_EN = 'WGS84_EPSG_6544_EN',
  WGS84_EPSG_6545_EN = 'WGS84_EPSG_6545_EN',
  WGS84_EPSG_6546_EN = 'WGS84_EPSG_6546_EN',
  WGS84_EPSG_6547_EN = 'WGS84_EPSG_6547_EN',
  WGS84_EPSG_6548_EN = 'WGS84_EPSG_6548_EN',
  WGS84_EPSG_6549_EN = 'WGS84_EPSG_6549_EN',
  WGS84_EPSG_6550_EN = 'WGS84_EPSG_6550_EN',
  WGS84_EPSG_6551_EN = 'WGS84_EPSG_6551_EN',
  WGS84_EPSG_6552_EN = 'WGS84_EPSG_6552_EN',
  WGS84_EPSG_6553_EN = 'WGS84_EPSG_6553_EN',
  WGS84_EPSG_6554_EN = 'WGS84_EPSG_6554_EN',
  WGS84_EPSG_6555_EN = 'WGS84_EPSG_6555_EN',
  WGS84_EPSG_6558_EN = 'WGS84_EPSG_6558_EN',
  WGS84_EPSG_6559_EN = 'WGS84_EPSG_6559_EN',
  WGS84_EPSG_6560_EN = 'WGS84_EPSG_6560_EN',
  WGS84_EPSG_6561_EN = 'WGS84_EPSG_6561_EN',
  WGS84_EPSG_6562_EN = 'WGS84_EPSG_6562_EN',
  WGS84_EPSG_6563_EN = 'WGS84_EPSG_6563_EN',
  WGS84_EPSG_6564_EN = 'WGS84_EPSG_6564_EN',
  WGS84_EPSG_6565_EN = 'WGS84_EPSG_6565_EN',
  WGS84_EPSG_6567_EN = 'WGS84_EPSG_6567_EN',
  WGS84_EPSG_6568_EN = 'WGS84_EPSG_6568_EN',
  WGS84_EPSG_6569_EN = 'WGS84_EPSG_6569_EN',
  WGS84_EPSG_6570_EN = 'WGS84_EPSG_6570_EN',
  WGS84_EPSG_6571_EN = 'WGS84_EPSG_6571_EN',
  WGS84_EPSG_6572_EN = 'WGS84_EPSG_6572_EN',
  WGS84_EPSG_6573_EN = 'WGS84_EPSG_6573_EN',
  WGS84_EPSG_6574_EN = 'WGS84_EPSG_6574_EN',
  WGS84_EPSG_6575_EN = 'WGS84_EPSG_6575_EN',
  WGS84_EPSG_6576_EN = 'WGS84_EPSG_6576_EN',
  WGS84_EPSG_6577_EN = 'WGS84_EPSG_6577_EN',
  WGS84_EPSG_6578_EN = 'WGS84_EPSG_6578_EN',
  WGS84_EPSG_6581_EN = 'WGS84_EPSG_6581_EN',
  WGS84_EPSG_6582_EN = 'WGS84_EPSG_6582_EN',
  WGS84_EPSG_6583_EN = 'WGS84_EPSG_6583_EN',
  WGS84_EPSG_6584_EN = 'WGS84_EPSG_6584_EN',
  WGS84_EPSG_6585_EN = 'WGS84_EPSG_6585_EN',
  WGS84_EPSG_6586_EN = 'WGS84_EPSG_6586_EN',
  WGS84_EPSG_6587_EN = 'WGS84_EPSG_6587_EN',
  WGS84_EPSG_6588_EN = 'WGS84_EPSG_6588_EN',
  WGS84_EPSG_6619_EN = 'WGS84_EPSG_6619_EN',
  WGS84_EPSG_6625_EN = 'WGS84_EPSG_6625_EN',
  WGS84_EPSG_6620_EN = 'WGS84_EPSG_6620_EN',
  WGS84_EPSG_6626_EN = 'WGS84_EPSG_6626_EN',
  WGS84_EPSG_6621_EN = 'WGS84_EPSG_6621_EN',
  WGS84_EPSG_6627_EN = 'WGS84_EPSG_6627_EN',
  WGS84_EPSG_6589_EN = 'WGS84_EPSG_6589_EN',
  WGS84_EPSG_6590_EN = 'WGS84_EPSG_6590_EN',
  WGS84_EPSG_6592_EN = 'WGS84_EPSG_6592_EN',
  WGS84_EPSG_6593_EN = 'WGS84_EPSG_6593_EN',
  WGS84_EPSG_6594_EN = 'WGS84_EPSG_6594_EN',
  WGS84_EPSG_6595_EN = 'WGS84_EPSG_6595_EN',
  WGS84_EPSG_6598_EN = 'WGS84_EPSG_6598_EN',
  WGS84_EPSG_6599_EN = 'WGS84_EPSG_6599_EN',
  WGS84_EPSG_6600_EN = 'WGS84_EPSG_6600_EN',
  WGS84_EPSG_6601_EN = 'WGS84_EPSG_6601_EN',
  WGS84_EPSG_6602_EN = 'WGS84_EPSG_6602_EN',
  WGS84_EPSG_6603_EN = 'WGS84_EPSG_6603_EN',
  WGS84_EPSG_6879_EN = 'WGS84_EPSG_6879_EN',
  WGS84_EPSG_6605_EN = 'WGS84_EPSG_6605_EN',
  WGS84_EPSG_6606_EN = 'WGS84_EPSG_6606_EN',
  WGS84_EPSG_6607_EN = 'WGS84_EPSG_6607_EN',
  WGS84_EPSG_6608_EN = 'WGS84_EPSG_6608_EN',
  WGS84_EPSG_6609_EN = 'WGS84_EPSG_6609_EN',
  WGS84_EPSG_6611_EN = 'WGS84_EPSG_6611_EN',
  WGS84_EPSG_6612_EN = 'WGS84_EPSG_6612_EN',
  WGS84_EPSG_6613_EN = 'WGS84_EPSG_6613_EN',
  WGS84_EPSG_6614_EN = 'WGS84_EPSG_6614_EN',
  WGS84_EPSG_6615_EN = 'WGS84_EPSG_6615_EN',
  WGS84_EPSG_6616_EN = 'WGS84_EPSG_6616_EN',
  WGS84_EPSG_6617_EN = 'WGS84_EPSG_6617_EN',
  WGS84_EPSG_6618_EN = 'WGS84_EPSG_6618_EN',

  // NAD83 - Guam
  GRS80_EPSG_4414_EN = 'GRS80_EPSG_4414_EN',

  // KSA
  International1924_EPSG_2318_EN = 'International1924_EPSG_2318_EN',

  WGS84_EPSG_32636_EN = 'WGS84_EPSG_32636_EN',
  WGS84_EPSG_32637_EN = 'WGS84_EPSG_32637_EN',
  WGS84_EPSG_32638_EN = 'WGS84_EPSG_32638_EN',
  WGS84_EPSG_32639_EN = 'WGS84_EPSG_32639_EN',
  WGS84_EPSG_32640_EN = 'WGS84_EPSG_32640_EN',

  International1924_EPSG_20436_EN = 'International1924_EPSG_20436_EN',
  International1924_EPSG_20437_EN = 'International1924_EPSG_20437_EN',
  International1924_EPSG_20438_EN = 'International1924_EPSG_20438_EN',
  International1924_EPSG_20439_EN = 'International1924_EPSG_20439_EN',
  International1924_EPSG_20440_EN = 'International1924_EPSG_20440_EN',

  GRS80_EPSG_8836_EN = 'GRS80_EPSG_8836_EN',
  GRS80_EPSG_8837_EN = 'GRS80_EPSG_8837_EN',
  GRS80_EPSG_8838_EN = 'GRS80_EPSG_8838_EN',
  GRS80_EPSG_8839_EN = 'GRS80_EPSG_8839_EN',
  GRS80_EPSG_8840_EN = 'GRS80_EPSG_8840_EN',

  // India
  WGS84_EPSG_32643_EN = 'WGS84_EPSG_32643_EN',
  WGS84_EPSG_32644_EN = 'WGS84_EPSG_32644_EN',
  WGS84_EPSG_32646_EN = 'WGS84_EPSG_32646_EN',
  WGS84_EPSG_32650_EN = 'WGS84_EPSG_32650_EN',
  WGS84_EPSG_32651_EN = 'WGS84_EPSG_32651_EN',
  WGS84_EPSG_32652_EN = 'WGS84_EPSG_32652_EN',
  WGS84_EPSG_32653_EN = 'WGS84_EPSG_32653_EN',
  WGS84_EPSG_32654_EN = 'WGS84_EPSG_32654_EN',
  WGS84_EPSG_32655_EN = 'WGS84_EPSG_32655_EN',

  // Korea
  Bessel_EPSG_5173_EN = 'Bessel_EPSG_5173_EN',
  Bessel_EPSG_5174_EN = 'Bessel_EPSG_5174_EN',
  Bessel_EPSG_5175_EN = 'Bessel_EPSG_5175_EN',
  Bessel_EPSG_5176_EN = 'Bessel_EPSG_5176_EN',
  Bessel_EPSG_5177_EN = 'Bessel_EPSG_5177_EN',

  // ETC
  WGS84_EPSG_32641_EN = 'WGS84_EPSG_32641_EN',
  WGS84_EPSG_32642_EN = 'WGS84_EPSG_32642_EN',
  HD72_EPSG_23700_EN = 'HD72_EPSG_23700_EN',
  QND95_EPSG_2932_EN = 'QND95_EPSG_2932_EN',
  Peru96_EPSG_5387_EN = 'Peru96_EPSG_5387_EN',
  ETRS89_EPSG_2180_EN = 'ETRS89_EPSG_2180_EN',
  ETRS89_EPSG_2177_EN = 'ETRS89_EPSG_2177_EN',

  // Thailand
  WGS84_EPSG_24047_EN = 'WGS84_EPSG_24047_EN',
  WGS84_EPSG_32647_EN = 'WGS84_EPSG_32647_EN',

  // Hong Kong
  International1924_EPSG_2326_EN = 'International1924_EPSG_2326_EN',

  // Canada
  GRS80_EPSG_2952_EN = 'GRS80_EPSG_2952_EN',

  // Australia
  GRS1980_EPSG_7850_EN = 'GRS1980_EPSG_7850_EN',
  GRS1980_EPSG_7851_EN = 'GRS1980_EPSG_7851_EN',
  GRS1980_EPSG_7852_EN = 'GRS1980_EPSG_7852_EN',
  GRS1980_EPSG_7853_EN = 'GRS1980_EPSG_7853_EN',
  GRS1980_EPSG_7854_EN = 'GRS1980_EPSG_7854_EN',
  GRS1980_EPSG_7855_EN = 'GRS1980_EPSG_7855_EN',
  GRS1980_EPSG_7856_EN = 'GRS1980_EPSG_7856_EN',
}
export type CoordinateSystem = keyof typeof ProjectionEnum;
export enum CoordinateTitle {
  EASTING = 'Easting(X)',
  NORTHING = 'Northing(Y)',
  LATITUDE = 'Latitude',
  LONGITUDE = 'Longitude',
  ALTITUDE = 'ALTITUDE',
}

/**
 * Response data from the backend
 */
export interface LengthElevationData {
  readonly lon: number;
  readonly lat: number;
  readonly dist: number;
  readonly alt: number;
}

// The reason why the surface and the point to point distance
// is using primitive types instead of mapping it from the state is that
// BE gives us a list of numbers whereas we only need the total of it,
// which is just a number, hence the type differences.
export interface LengthSurfaceResponse {
  distances: number[];
}

export interface LengthPointToPointResponse {
  distances: number[];
}

export interface BaseContent {
  readonly id: number | string;
  readonly projectId: Project['id'];
  readonly title: string;
  readonly color: Color;
  readonly type: ContentType;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info?: {
    readonly move?: boolean;
  };
  readonly config?: ContentConfigPerUser;
  readonly attachmentsCount: number;
  readonly createdAt: Date | null;
  readonly createdBy:
    | {
        readonly id: User['id'];
        readonly name: string;
        readonly email: User['email'];
      }
    | undefined;
  readonly updatedAt: Date;
  /**
   * @desc show this content on the map and left sidebar at this date only.
   * _**appearAt === undefined always means the content is PINNED.**_
   */
  readonly appearAt?: Screen['appearAt'];
  /**
   * @desc the date a content was actually pinned.
   * This is NOT the date that was selected when the content was pinned.
   */
  readonly pinEventAt?: Date;
  readonly screenId?: Screen['id'];
  readonly groupId?: string | number;
  readonly isPersonal?: boolean;
  readonly photoId?: string | number | undefined;
}

export interface MapBoundary {
  readonly minLon: number;
  readonly minLat: number;
  readonly maxLon: number;
  readonly maxLat: number;
  /**
   * @desc To reverse Tile coordinates from float number of Longitude & Latitude does not make sure
   * that we will `get` the correct values.
   * Therefore, we need to get the original values of Tile coordinate from the Backend
   */
  readonly minX?: number; // The Left coordinate
  readonly minY?: number; // The Bottom coordinate
  readonly maxX?: number; // The Right coordinate
  readonly maxY?: number; // The Top coordinate
}

export interface MapBoundaries {
  readonly [zoom: number]: MapBoundary;
}

interface TMS {
  zoomLevels: readonly number[];
  boundaries: MapBoundaries;
}

export enum HillshadeStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export interface MapContent extends BaseContent {
  readonly appearAt: Date;
  readonly type: ContentType.MAP;
  readonly category: ContentCategory.MAP;
  readonly status?: ContentProcessingStatus;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly tms?: TMS;
    readonly opacity?: number;
  };
  readonly config?: MapConfigPerUser;
}

export interface DSMContent extends BaseContent {
  readonly appearAt: Date;
  readonly type: ContentType.DSM;
  readonly category: ContentCategory.MAP;
  readonly status?: ContentProcessingStatus;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly elevation: {
      readonly counts: number[];
      readonly bins: number[];
    };
    readonly tms?: TMS;
    readonly minHeight: number | null;
    readonly maxHeight: number | null;
    readonly opacity?: number;
    readonly hasHillshade?: boolean;
    readonly hillshadeStatus?: HillshadeStatus | undefined;
  };
  readonly config?: DSMConfigPerUser;
}

export interface DTMContent extends BaseContent {
  readonly appearAt: Date;
  readonly type: ContentType.DTM;
  readonly category: ContentCategory.MAP;
  readonly status?: ContentProcessingStatus;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly elevation: {
      readonly counts: number[];
      readonly bins: number[];
    };
    readonly tms?: TMS;
    readonly minHeight: number | null;
    readonly maxHeight: number | null;
    readonly opacity?: number;
    readonly hasHillshade?: boolean;
    readonly hillshadeStatus?: HillshadeStatus | undefined;
  };
  readonly config?: DTMConfigPerUser;
}

export interface ThreeDOrthoContent extends BaseContent {
  readonly appearAt: Date;
  readonly type: ContentType.THREE_D_ORTHO;
  readonly category: ContentCategory.MAP;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    dsm: DSMContent['id'];
    map: MapContent['id'];
  };
  readonly status?: ContentProcessingStatus;
  readonly config?: ThreeDOrthoConfigPerUser;
}

export interface PointCloudContent extends BaseContent {
  readonly appearAt: Date;
  readonly type: ContentType.POINTCLOUD;
  readonly category: ContentCategory.MAP;
  readonly status?: ContentProcessingStatus;
  readonly config?: PointCloudConfigPerUser;
  readonly info?: BaseContent['info'] & {
    downsample_status?: APILasDownSamplingStatus;
    engine: PointCloudEngine;
  };
}

export interface ThreeDMeshContent extends BaseContent {
  readonly appearAt: Date;
  readonly type: ContentType.THREE_D_MESH;
  readonly category: ContentCategory.MAP;
  readonly status?: ContentProcessingStatus;
  readonly info?: BaseContent['info'] & {
    // 0-1 scale for the LOD quality. A lower value loads tiles from lower LODs (increases performance).
    viewDistanceScale?: number;
    // determines the distance from tiles in which they are refined,
    // depending on their geometrical size. increase the value to load lower lod tiles from the same view distance (increases performnace)
    maximumScreenSpaceError?: number;
    // This fix implies for any projects to adjust height according to their desire.
    customTilesetHeight?: number;
    // This fix implies for 5174 project that needs offset height calibration from lambda.
    offsetCalibrated?: boolean;
    // This number from SPH to recalibrate bird-eye, inspection photo
    totalErrorAlt?: number | undefined;
    calibratedDelta?: number;
  };
  readonly config?: ThreeDMeshConfigPerUser;
}

export interface BlueprintPDFContent extends BaseContent {
  readonly type: ContentType.BLUEPRINT_PDF;
  readonly category: ContentCategory.OVERLAY;
  readonly status?: ContentProcessingStatus;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly geojsonCompressed?: string;
    // ImagePoint stores the pin point location based on Image (width, height)
    readonly imagePoint: [Point, Point];

    // GeoPoint stores the pin point location based on Geo (longitude, latitude)
    readonly geoPoint: [Coordinate, Coordinate];

    // Dimension of blueprint image after processing (pdf --> png tiles), info from Backend server
    readonly dimension?: {
      width: number;
      height: number;
    };
    readonly tms?: MapContent['info']['tms'];
    readonly opacity?: number;
  };
  readonly config?: BlueprintPDFConfigPerUser;
}

export enum MapBounds {
  MIN_LAT = 0,
  MAX_LON,
  MAX_LAT,
  MIN_LON,
}

export interface BlueprintDXFContent extends BaseContent {
  readonly type: ContentType.BLUEPRINT_DXF;
  readonly category: ContentCategory.OVERLAY;
  readonly status?: ContentProcessingStatus;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly coordinateSystem: ProjectionEnum;
    readonly opacity?: number;
    readonly geojsonCompressed?: string;
    readonly tms?: MapContent['info']['tms'] & {
      bounds?: [number, number, number, number];
      center?: [number, number];
      maxzoom?: number;
      minzoom?: number;
    };
    readonly error?: {
      readonly message: Record<Language, string> & {
        readonly [Language.KO_KR]: string;
        readonly [Language.EN_US]: string;
      };
    };
  };
  readonly config?: BlueprintDXFConfigPerUser;
}

export enum IssueGroupByCriteria {
  PRIORITY = 'priority',
  STATUS = 'status',
  CATEGORY = 'category',
}

export enum IssueSortByCriteria {
  PRIORITY = 'priority',
  STATUS = 'status',
  CREATED_BY = 'created_by',
  CREATED_DATE = 'created_date',
}

export enum IssuePriority {
  LOW = 'low',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export interface FlightScheduleSearch {
  readonly id: number;
  readonly label: string;
}
export interface IssueCategory {
  readonly color: string;
  readonly id: number;
  readonly label: string;
  readonly projectId: number;
}

export interface IssueStatus {
  readonly order: number;
  readonly id: number;
  readonly label: string;
  readonly projectId: number;
}

export interface IssueAssignee {
  readonly id: User['id'];
  readonly name: string;
  readonly firstName: User['firstName'];
  readonly lastName: User['lastName'];
  readonly email: User['email'];
  readonly avatar?: User['avatar'];
}

export interface IssueAttachment {
  id: number;
  attachmentType: 'image' | 'video';
  imageUrl: string | null;
  videoThumbnails: string | null;
  videoUrl: string | null;
  uploadedBy: {
    id: User['id'];
    email: User['email'];
    name: User['name'];
  };
}

export interface PresentationAttachment {
  id: string;
  attributes: {
    contentId: number;
    file: {
      url: string;
    };
    type: ContentType.SLIDE;
    createdAt: string;
    updatedAt: string;
  };
}

export enum FlightPlanSortByCriteria {
  NAME = 'name',
  NEWEST = 'newest',
  OLDEST = 'oldest',
}
export enum FlightScheduleSortByCriteria {
  NAME = 'name',
  NEWEST = 'newest',
  OLDEST = 'oldest',
}

export enum FEATURE_FLAGS {
  TERRAIN_EDITING = 'terrain_editing',
  PHOTO_ALBUM_NEW_FEATURES = 'photo_album_new_features',
  V2B_TAB = 'v2b_tab',
  VIDEO_OVERLAY = 'video_overlay',
  VSLAM_INDOOR = 'vslam_indoor',
  PANORAMA = 'panorama',
}

export interface BaseIssueContent extends BaseContent {
  readonly type: ContentType.ISSUE_POINT | ContentType.ISSUE_PHOTO;
  readonly category: ContentCategory.ISSUE;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly assignees: IssueAssignee[];
  readonly description: string;
  readonly issueCategory: IssueCategory;
  readonly issueCategoryId: IssueCategory['projectId'];
  readonly issueStatus: IssueStatus;
  readonly issueStatusId: IssueStatus['projectId'];
  readonly priority: IssuePriority;
  readonly dueDate: Date | null;
  readonly issueAttachments: IssueAttachment[];
  readonly photoId?: string | number | undefined;
  readonly photo?: Photo;
}

export interface IssuePointContent extends BaseIssueContent {
  readonly type: ContentType.ISSUE_POINT;
  readonly info: BaseContent['info'] & {
    location: GeoPoint;
    misc: Record<any, any>;
  };
  readonly config?: IssuePointConfigPerUser;
}

export interface IssuePhotoContent extends BaseIssueContent {
  readonly type: ContentType.ISSUE_PHOTO;
  readonly info: BaseContent['info'] & {
    imagePoint: Point;
    misc: Record<any, any>;
  };
  readonly config?: IssuePhotoConfigPerUser;
}

export interface SlideContent extends BaseContent {
  readonly type: ContentType.SLIDE;
  readonly category: ContentCategory.PRESENTATION;
  readonly slideThumbUrl: string;
  readonly slideUrl: string;
  readonly info: BaseContent['info'] & {
    readonly mapIds: Array<Content['id']>;
    readonly overlayIds?: Array<Content['id']>;
    readonly measurementIds?: Array<Content['id']>;
    readonly terrainIds?: Array<Content['id']>;
    readonly issueIds?: Array<Content['id']>;
    readonly essIds?: Array<Content['id']>;

    cameraPosition: number[];
    // camera rotation will act as focalOffset for 3D
    cameraRotation: number[];
    target: number[];
    zoomLevel: number;
    baseMapType: string;
    screenId: number | string;
    tab?: ContentPageTabType;
  };
}

export interface AnnotationContent extends BaseContent {
  readonly type: ContentType.ANNOTATION;
  readonly category: ContentCategory.PRESENTATION;
  readonly info: BaseContent['info'] & {
    slideId: Content['id'];
    element: MarkerAreaState;
  };
}

export interface BlueprintDWGContent extends BaseContent {
  readonly type: ContentType.BLUEPRINT_DWG;
  readonly category: ContentCategory.OVERLAY;
  readonly status?: ContentProcessingStatus;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly coordinateSystem: ProjectionEnum;
    readonly opacity?: number;
    readonly geojsonCompressed?: string;
    readonly tms?: MapContent['info']['tms'] & {
      bounds?: [number, number, number, number];
      center?: [number, number];
      maxzoom?: number;
      minzoom?: number;
    };
    readonly error?: {
      readonly message: Record<Language, string> & {
        readonly [Language.KO_KR]: string;
        readonly [Language.EN_US]: string;
      };
    };
  };
  readonly config?: BlueprintDWGConfigPerUser;
}

export interface BimContent extends BaseContent {
  readonly type: ContentType.BIM;
  readonly category: ContentCategory.OVERLAY;
  readonly status?: ContentProcessingStatus;

  readonly info: BaseContent['info'] & {
    readonly location: GeoPoint;
    readonly opacity?: number;
    readonly geojsonCompressed?: string;
    readonly bimMeta: BimMeta;
    readonly isConverted?: boolean;
  };
  readonly config?: BimConfigPerUser;
}

/**
 * Related to the KML
 */
export interface GeoJSONTableKML {
  shape: string;
  type: string;
  features: FeaturesItemKML[];
}

interface FeaturesItemKML {
  type: string;
  geometry: {
    type: string;
    coordinates: number[][][];
  };
  properties: PropertiesKML;
}
interface PropertiesKML {}

export interface ParsedKmlType {
  geometryType: KMLContentType;
  geometryCoords: number[][];
  geometryProperties: PropertiesKML;
}

export interface RawFlightPlan {
  readonly name: string;
  readonly id: number;
  readonly sign: string;
  readonly favorited: boolean;
  readonly drone_model_key: string;
  readonly payload_model_keys: string[];
  readonly template_types: number[];
  readonly object_key: string;
  readonly user_name: string;
  readonly update_time: number;
  readonly create_time: number;
}
export interface FlightPlan {
  readonly name: string;
  readonly id: number;
  readonly sign: string;
  readonly favorited: boolean;
  readonly droneModelKey: string;
  readonly payloadModelKeys: string[];
  readonly templateTypes: number[];
  readonly objectKey: string;
  readonly userName: string;
  readonly updateTime: number;
  readonly createTime: number;
  readonly parsedKmlData: ParsedKmlType[];
}

export interface FlightPlanContent extends BaseContent {
  readonly type: ContentType.FLIGHT_PLAN;
  readonly category: ContentCategory.FLIGHT_PLAN;
  readonly status?: ContentProcessingStatus;

  config?: FlightPlanConfigPerUser;

  readonly info: BaseContent['info'] & FlightPlan;
}

export interface RawDroneStation {
  readonly device_sn: string;
  readonly device_name: string;
  readonly workspace_id: string;
  readonly control_source: string;
  readonly device_desc: string;
  readonly child_device_sn: string;
  readonly domain: number;
  readonly sub_type: number;
  readonly icon_url: {
    readonly normal_icon_url: string;
    readonly selected_icon_url: string;
  };
  readonly status: boolean;
  readonly bound_status: boolean;
  readonly login_time: Date;
  readonly bound_time: Date;
  readonly nickname: string;
  readonly firmware_version: string;
  readonly workspace_name: string;
  readonly children?: RawDroneStation;
  readonly firmware_status: number;
  readonly thing_version: string;
  readonly payloads_list?: [];
}
export interface ADSDroneStation {
  readonly deviceSn: string;
  readonly deviceName: string;
  readonly workspaceId: string;
  readonly controlSource: string;
  readonly deviceDesc: string;
  readonly childDeviceSn: string;
  readonly domain: number;
  readonly subType: number;
  readonly iconUrl: {
    readonly normalIconUrl: string;
    readonly selectedIconUrl: string;
  };
  readonly status: boolean;
  readonly boundStatus: boolean;
  readonly loginTime: Date;
  readonly boundTime: Date;
  readonly nickname: string;
  readonly firmwareVersion: string;
  readonly workspaceName: string;
  readonly children?: ADSDroneStation;
  readonly firmwareStatus: number;
  readonly thingVersion: string;
  readonly payloadsList?: [];
}

export enum LiveStreamQuality {
  Auto = 0,
  '360p',
  '720p',
  '1080p',
  '2160p',
}

export const liveStreamQuality = [
  LiveStreamQuality.Auto,
  LiveStreamQuality['360p'],
  LiveStreamQuality['720p'],
  LiveStreamQuality['1080p'],
  LiveStreamQuality['2160p'],
] as const;

export interface DroneStationContent extends BaseContent {
  readonly type: ContentType.DRONE_STATION;
  readonly category: ContentCategory.DRONE_STATION;
  readonly status?: ContentProcessingStatus;

  readonly config?: DroneStationConfigPerUser;

  readonly info: BaseContent['info'] & ADSDroneStation;
}

export interface RawFlightSchedule {
  job_id: string;
  job_name: string;
  file_id: string;
  dock_sn: string;
  workspace_id: string;
  wayline_type: number;
  task_type: number;
  begin_time: string | Date;
  end_time: string | Date;
  // status: number;
  username: string;
  rth_altitude: number;
  out_of_control_action: number;
  media_count: number;
}
export interface FlightSchedule {
  jobId: string;
  jobName: string;
  fileId: string;
  dockSn: string;
  workspaceId: string;
  waylineType: number;
  taskType: number;
  beginTime: string | Date;
  endTime: string | Date;
  // status: number;
  username: string;
  rthAltitude: number;
  outOfControlAction: number;
  mediaCount: number;
}

export interface FlightScheduleContent extends BaseContent {
  readonly type: ContentType.FLIGHT_SCHEDULE;
  readonly category: ContentCategory.FLIGHT_SCHEDULE;
  readonly status?: ContentProcessingStatus;

  config?: FlightScheduleConfigPerUser;

  readonly info: BaseContent['info'] & FlightSchedule;
}

export interface RawDroneStationAuthDetails {
  readonly username: string;
  readonly user_id: string;
  readonly workspace_id: string;
  readonly user_type: number;
  readonly mqtt_username: string;
  readonly mqtt_password: string;
  readonly access_token: string;
  readonly mqtt_addr: string;
}

export interface DroneStationAuthDetails {
  readonly username: string;
  readonly userId: string;
  readonly workspaceId: string;
  readonly userType: number;
  readonly mqttUsername: string;
  readonly mqttPassword: string;
  readonly accessToken: string;
  readonly mqttAddress: string;
}

export interface RawFlightScheduleFields {
  readonly title: string;
  readonly selectedFlightPlan: FlightPlanContent | undefined;
  readonly selectedDroneStation: DroneStationContent | undefined;
  readonly selectedPlanTimer: FlightScheduleScheduleType;
  readonly selectedLostAction: FlightScheduleLostActionType;
  readonly rthAltitude: number;

  readonly selectedExecuteDate: {
    readonly from?: Date;
    readonly to?: Date;
  };

  readonly selectedExecuteTime: string;
  readonly selectedExecuteEndTime: string;
  readonly batteryLevel: number;
  readonly storageLevel: number;
}

export interface DesignDXFContent extends BaseContent {
  readonly type: ContentType.DESIGN_DXF;
  readonly category: ContentCategory.OVERLAY;
  readonly status?: ContentProcessingStatus;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly geojsonCompressed?: string;
    readonly coordinateSystem: ProjectionEnum;
    readonly opacity?: number;
    readonly designBorder: Array<[number, number]>;
    readonly useAlt?: boolean;
  };
  readonly config?: DesignDXFConfigPerUser;
}

export enum UnitType {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
  ANY = 'any',
}

export type ValidUnitType = Exclude<UnitType, UnitType.ANY>;
export type UnitValue<T> = Omit<Record<UnitType, T>, UnitType.ANY>;

export interface TriangulationInfo {
  readonly value: TriangleInfo[];
}
export interface TriangleInfo {
  readonly value: number[];
}

export interface ElevationInfo {
  readonly value: number;
  readonly unit?: string;
}
export interface MarkerContent extends BaseContent {
  readonly type: ContentType.MARKER;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly location: number[];
    readonly description: string;
    readonly targetMapContentId?: MapContent['id'];
    readonly elevationInfo?: ElevationInfo;
    readonly opacity?: number;
  };
  readonly config?: MarkerConfigPerUser;
}

export interface ThreeDLengthContent extends BaseContent {
  readonly type: ContentType.THREE_LENGTH;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly locationsType?: 'geo_point';
    readonly elevations?: Array<{
      comparisonContentId: DSMContent['id'] | DesignDXFContent['id'];
      points: LengthElevationRawData[];
      comparison: { title: string; color: string };
    }>;
    readonly metrics?: {
      surface: number;
      pointToPoint: number;
    };
  };
  readonly config?: Length3dLengthConfigPerUser;
}

export interface LengthContent extends BaseContent {
  readonly type: ContentType.LENGTH;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly elevations?: Array<{
      comparisonContentId: DSMContent['id'] | DesignDXFContent['id'];
      points: LengthElevationRawData[];
      comparison: { title: string; color: string };
    }>;
    readonly metrics?: {
      surface: number;
      pointToPoint: number;
    };
  };
  readonly config?: LengthConfigPerUser;
}

export interface ThreeDLengthContent extends BaseContent {
  readonly type: ContentType.THREE_LENGTH;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly locationsType?: 'geo_point';
    readonly elevations?: Array<{
      comparisonContentId: DSMContent['id'] | DesignDXFContent['id'];
      points: LengthElevationRawData[];
      comparison: { title: string; color: string };
    }>;
    readonly metrics?: {
      surface: number;
      pointToPoint: number;
    };
  };
  readonly config?: Length3dLengthConfigPerUser;
}

export interface ThreeDHeightContent extends BaseContent {
  readonly type: ContentType.THREE_HEIGHT;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly locationsType?: 'geo_point';
    readonly elevations?: Array<{
      comparisonContentId: DSMContent['id'] | DesignDXFContent['id'];
      points: LengthElevationRawData[];
      comparison: { title: string; color: string };
    }>;
    readonly metrics?: {
      surface: number;
      pointToPoint: number;
    };
  };
  readonly config?: Length3dHeightConfigPerUser;
}

export interface ThreeAreaContent extends BaseContent {
  readonly type: ContentType.THREE_AREA;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly surface?: number;
    // readonly locationsType?: string;
  };
  readonly config?: ThreeAreaConfigPerUser;
}

export interface FlattenContent extends BaseContent {
  readonly type: ContentType.FLATTEN_MAP;
  readonly category: ContentCategory.MEASUREMENT;
  readonly status?: ContentProcessingStatus | undefined;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly surface?: number;
    // readonly locationsType?: string;
  };
  readonly config?: TerrainEditingConfigPerUser;
}

export interface AreaContent extends BaseContent {
  readonly type: ContentType.AREA;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly surface?: number;
    // readonly locationsType?: string;
  };
  readonly config?: AreaConfigPerUser;
}

export interface GroupContent extends BaseContent {
  readonly type: ContentType.GROUP;
  readonly category: ContentCategory;
  readonly config?: ContentGroupConfigPerUser;
  readonly info: BaseContent['info'] & {
    readonly isOpened?: boolean;
  };
}

// There might be more contents added in the future
export type GroupableContent = GroupContent;

export interface ESSModelInstance {
  /**
   * The model's id.
   */
  readonly id: number;
  /**
   * The model's readable name.
   * In English.
   */
  readonly nameEn: string;
  /**
   * The model's readable name.
   * In Korean.
   */
  readonly nameKo: string;
  /**
   * The model's detail.
   * Could be the model size, weight in English.
   */
  readonly detailEn: string;
  /**
   * The model's detail.
   * Could be the model size, weight in Korean.
   */
  readonly detailKo: string;
  /**
   * The model's category id.
   * FE will use this as the unique identifier
   * to group the models with.
   */
  readonly categoryId: number;
  /**
   * The model's category readable name.
   * In English.
   */
  readonly categoryNameEn: string;
  /**
   * The model's category readable name.
   * In Korean.
   */
  readonly categoryNameKo: string;
  /**
   * The model's source URL.
   */
  readonly url: string;
  /**
   * The model's thumbnail URL.
   */
  readonly thumbnailUrl: string;
  /**
   * The model's width (in meters).
   * This value is independent of the scale (original width).
   */
  readonly width: number;
  /**
   * The model's length (in meters).
   * This value is independent of the scale (original length).
   */
  readonly length: number;
  /**
   * The model's height (in meters).
   * This value is independent of the scale (original height).
   */
  readonly height: number;
  /**
   * The model's predefined heading in radians (or degrees?).
   * Default should be 0.
   * Typically models face the north, but if for some reason it isn't,
   * the value tells how much it's headed against the north.
   */
  readonly heading?: number;
  /**
   * The model's scale.
   * Default is 1 (1:1 with the original),
   * but it can be decided if it would be smaller/bigger than the original.
   */
  readonly scale?: number;
  /**
   * Whether the model's ground is at the center of the model.
   * Default is false, because models usually lay flat on the ground.
   * Some models lay their center point on the ground.
   * (this is a bit hard to explain in text, so let me know if you need more explanation)
   */
  readonly groundAtCenter?: boolean;
  /**
   * Model's owrk radius. Some models need to show the range
   * the arm covering around the model.
   */
  readonly workRadius?: number;
  /**
   * Model's projectId
   */
  readonly projectId?: number;
}

export interface ESSCustomModelInstance {
  /**
   * The model's project id.
   */
  readonly projectId: number;
  /**
   * The model's id.
   */
  readonly id: number;
  /**
   * The model's readable name.
   * In English.
   */
  readonly nameEn: string;
  /**
   * The model's readable name.
   * In Korean.
   */
  readonly nameKo: string;
  /**
   * The model's detail.
   * Could be the model size, weight in English.
   */
  readonly url: string;
  /**
   * The model's thumbnail URL.
   */
  readonly thumbnailUrl: string;
  /**
   * The model's thumbnail.
   */
  readonly thumbnail?: File;
  /**
   * The model's width (in meters).
   * This value is independent of the scale (original width).
   */
  readonly width: number;
  /**
   * The model's length (in meters).
   * This value is independent of the scale (original length).
   */
  readonly length: number;
  /**
   * The model's height (in meters).
   * This value is independent of the scale (original height).
   */
  readonly height: number;
  /**
   * The model's height (in meters).
   * This value is independent of the scale (original height).
   */
  readonly workRadius: number;
  /**
   * The model's orientation (in radians).
   */
  readonly heading?: number;

  /**
   * The model's additional information
   */

  readonly info?: {
    readonly miscMeta?: {
      readonly color?: Color;
      readonly opacity?: number;
    };
  };
}

export interface ESSModelCategory {
  /**
   * Id of the ESS model category.
   */
  readonly id: number;
  /**
   * The name of the category in English.
   */
  readonly nameEn: string;
  /**
   * The name of the category in Korean.
   */
  readonly nameKo: string;
  /**
   * If the model is custom or predefined.
   */
  readonly isCustom?: boolean;
  /**
   *If it belongs to a project
   */
  readonly projectId?: number;
}

export interface APIWMSInfo {
  layer: string;
  nativeBoundingBox: {
    minx: number;
    miny: number;
    maxx: number;
    maxy: number;
  };
  url: string;
}

export interface BaseCalculationInfo<U> {
  readonly volumeAlgorithm: BasicCalcBasePlane;
  readonly volumeElevation: number;
  readonly type: U;
  readonly dsmorDtmMode?: ContentType.DSM | ContentType.DTM;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BasicCalculationInfo extends BaseCalculationInfo<VolumeCalcMethod.BASIC> {}

export interface SurveyCalculationInfo extends BaseCalculationInfo<VolumeCalcMethod.SURVEY> {
  /**
   * @desc this also takes DSMs after current date, need to change request parameter & backend code & exisitng data to change this name
   */
  readonly previousDsmId: DSMContent['id'];
}
export interface DesignCalculationInfo extends BaseCalculationInfo<VolumeCalcMethod.DESIGN> {
  readonly designDxfId: DesignDXFContent['id'];
}

export type CalculationInfo = BasicCalculationInfo | SurveyCalculationInfo | DesignCalculationInfo;
export interface CalculatedVolumeInfo {
  readonly calculation: CalculationInfo;
  readonly fill: number;
  readonly cut: number;
  readonly total: number;
  readonly wmsInfo?: APIWMSInfo;
  readonly minMaxElevation?: {
    readonly minHeight: number;
    readonly maxHeight: number;
  };
}
export interface VolumeContent extends BaseContent {
  readonly type: ContentType.VOLUME;
  readonly category: ContentCategory.MEASUREMENT;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly calculatedVolume: CalculatedVolumeInfo;
    readonly isBoundaryViolated?: boolean;
  };
  readonly config?: VolumeConfigPerUser;
}

export interface GCP {
  readonly label: string;
  readonly easting: number;
  readonly northing: number;
  readonly altitude: number;
}

export interface GCPGroupContent extends BaseContent {
  readonly type: ContentType.GCP_GROUP;
  readonly category: ContentCategory.METADATA;
  /**
   * @warning
   * if you want to change content.info, please discuss with BE team
   * because there will be implications when recovering content
   */
  readonly info: BaseContent['info'] & {
    readonly crs: ProjectionEnum;
    readonly gcps: GCP[];
  };
  readonly config?: GCPGroupConfigPerUser;
}

export type Content =
  | MapContent
  | ThreeDOrthoContent
  | PointCloudContent
  | DSMContent
  | DTMContent
  | BlueprintPDFContent
  | BlueprintDXFContent
  | BlueprintDWGContent
  | DesignDXFContent
  | MarkerContent
  | LengthContent
  | ThreeDLengthContent
  | ThreeDHeightContent
  | AreaContent
  | ThreeAreaContent
  | VolumeContent
  | ThreeDMeshContent
  | GCPGroupContent
  | ESSModelContent
  | ESSArrowContent
  | ESSPolygonContent
  | ESSPolylineContent
  | ESSCustomModelContent
  | ESSTextContent
  | ESSLineTextContent
  | GroupContent
  | IssueContent
  | BimContent
  | FlattenContent
  | FlightPlanContent
  | FlightScheduleContent
  | DroneStationContent
  | SlideContent
  | AnnotationContent;

export type CADContent = BlueprintDXFContent | BlueprintDWGContent;
export type IssueContent = IssuePointContent | IssuePhotoContent;

export interface RawAPIBaseContent {
  readonly id: number;
  readonly projectId: number;
  readonly title: string;
  readonly color: string;
  readonly attachmentsCount: number;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy:
    | {
        readonly id: number;
        readonly name: string;
        readonly email: string;
      }
    | undefined;
  readonly groupId: string | number | null;
  readonly photoId?: string | number | undefined;
}

export interface APIBaseContent extends RawAPIBaseContent {
  readonly path: string;
  readonly pinEventAt: string | null;
  readonly appearAt: string | null;
  readonly screenId: Screen['id'] | null;
  readonly isPersonal?: boolean;
}

export interface APIMapContent extends APIBaseContent {
  readonly appearAt: string;
  readonly type: MapContent['type'];
  readonly category: ContentCategory.MAP;
  readonly status: ContentProcessingStatus | null;
  readonly size: number | null;
  readonly info: MapContent['info'] | SlideContent['info'] | string;
  readonly config: MapConfigPerUser | null;
}

export interface APIBlueprintPDFContent extends APIBaseContent {
  readonly type: BlueprintPDFContent['type'];
  readonly category: ContentCategory.OVERLAY;
  readonly status: ContentProcessingStatus | null;
  readonly info: BlueprintPDFContent['info'];
  readonly config: BlueprintPDFConfigPerUser | null;
}

export interface APIBlueprintDXFContent extends APIBaseContent {
  readonly type: BlueprintDXFContent['type'];
  readonly category: ContentCategory.OVERLAY;
  readonly status: ContentProcessingStatus | null;
  readonly info: BlueprintDXFContent['info'];
  readonly config: BlueprintDXFConfigPerUser | null;
}

export interface APIBlueprintDWGContent extends APIBaseContent {
  readonly type: BlueprintDWGContent['type'];
  readonly category: ContentCategory.OVERLAY;
  readonly status: ContentProcessingStatus | null;
  readonly info: BlueprintDWGContent['info'];
  readonly config: BlueprintDWGConfigPerUser | null;
}

export interface APIBimContent extends APIBaseContent {
  readonly type: BimContent['type'];
  readonly category: ContentCategory.OVERLAY;
  readonly status: ContentProcessingStatus | null;
  readonly info: BimContent['info'];
  readonly config: BimConfigPerUser | null;
}

export interface APIFlightPlanContent extends APIBaseContent, FlightPlan {
  readonly type: FlightPlanContent['type'];
  readonly category: ContentCategory.FLIGHT_PLAN;
  readonly status: ContentProcessingStatus | null;
  readonly info: FlightPlanContent['info'];
  readonly config: FlightPlanConfigPerUser | null;

  // // DJI API types that needs serialization
  // readonly name: string;
  // readonly sign: string;
  // readonly favorited: boolean;
  // readonly drone_model_key: string;
  // readonly payload_model_keys: string[];
  // readonly template_types: number[];
  // readonly object_key: string;
  // readonly user_name: string;
  // readonly update_time: number;
  // readonly create_time: number;
}

export interface APIFlightScheduleContent extends APIBaseContent, FlightSchedule {
  readonly type: FlightScheduleContent['type'];
  readonly category: ContentCategory.FLIGHT_SCHEDULE;
  readonly status: ContentProcessingStatus | null;
  readonly info: FlightScheduleContent['info'];
  readonly config: FlightScheduleConfigPerUser | null;
}

export interface APIDroneStationContent extends APIBaseContent, ADSDroneStation {
  readonly type: DroneStationContent['type'];
  readonly category: ContentCategory.DRONE_STATION;
  /**
   * TODO: @ebraj-angelswing
   * Discuss about this status with @hyunwoo-angelswing
   */
  // readonly status: ContentProcessingStatus | null;
  readonly info: DroneStationContent['info'];
  readonly config: DroneStationConfigPerUser | null;
}

export interface APIDesignDXFContent extends APIBaseContent {
  readonly type: DesignDXFContent['type'];
  readonly category: ContentCategory.OVERLAY;
  readonly status: ContentProcessingStatus | null;
  readonly info: DesignDXFContent['info'];
  readonly config: DesignDXFConfigPerUser | null;
}

export interface APIMarkerContent extends APIBaseContent {
  readonly type: MarkerContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: MarkerContent['info'];
  readonly config: MarkerConfigPerUser | null;
}

export interface APILengthContent extends APIBaseContent {
  readonly type: LengthContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: LengthContent['info'];
  readonly config: LengthConfigPerUser | null;
}

export interface API3DLengthContent extends APIBaseContent {
  readonly type: ThreeDLengthContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: ThreeDLengthContent['info'];
  readonly config: Length3dLengthConfigPerUser | null;
}
export interface API3DHeightContent extends APIBaseContent {
  readonly type: ThreeDHeightContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: ThreeDHeightContent['info'];
  readonly config: Length3dHeightConfigPerUser | null;
}

export interface APIAreaContent extends APIBaseContent {
  readonly type: AreaContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: AreaContent['info'];
  readonly config: AreaConfigPerUser | null;
}

export interface APIFlattenContent extends APIBaseContent {
  readonly type: FlattenContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: FlattenContent['info'];
  readonly config: AreaConfigPerUser | null;
}
export interface APIThreeAreaContent extends APIBaseContent {
  readonly type: ThreeAreaContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: ThreeAreaContent['info'];
  readonly config: ThreeAreaConfigPerUser | null;
}

export interface APIVolumeContent extends APIBaseContent {
  readonly type: VolumeContent['type'];
  readonly category: ContentCategory.MEASUREMENT;
  readonly info: VolumeContent['info'];
  readonly config: VolumeConfigPerUser | null;
}

export interface APIDSMContent extends APIBaseContent {
  readonly appearAt: string;
  readonly type: DSMContent['type'];
  readonly category: ContentCategory.MAP;
  readonly info: DSMContent['info'];
  readonly elevation: DSMContent['info']['elevation'];
  readonly minHeight: DSMContent['info']['minHeight'];
  readonly maxHeight: DSMContent['info']['maxHeight'];
  readonly opacity: DSMContent['info']['maxHeight'];
  readonly status: ContentProcessingStatus | null;
  readonly config: DSMConfigPerUser | null;
}

export interface APIDTMContent extends APIBaseContent {
  readonly appearAt: string;
  readonly type: DTMContent['type'];
  readonly category: ContentCategory.MAP;
  readonly info: DTMContent['info'];
  readonly elevation: DTMContent['info']['elevation'];
  readonly minHeight: DTMContent['info']['minHeight'];
  readonly maxHeight: DTMContent['info']['maxHeight'];
  readonly opacity: DTMContent['info']['maxHeight'];
  readonly status: ContentProcessingStatus | null;
  readonly config: DTMConfigPerUser | null;
}
export interface APIThreeDOrthoContent extends APIBaseContent {
  readonly appearAt: string;
  readonly type: ContentType.THREE_D_ORTHO;
  readonly category: ContentCategory.MAP;
  readonly info: ThreeDOrthoContent['info'];
  readonly status: ContentProcessingStatus | null;
  readonly config: ThreeDOrthoConfigPerUser | null;
}

export interface APIPointCloudContent extends APIBaseContent {
  readonly appearAt: string;
  readonly type: ContentType.POINTCLOUD;
  readonly category: ContentCategory.MAP;
  readonly info: PointCloudContent['info'];
  readonly status: ContentProcessingStatus | null;
  readonly config: PointCloudConfigPerUser | null;
}

export interface APIThreeDMeshContent extends APIBaseContent {
  readonly appearAt: string;
  readonly type: ContentType.THREE_D_MESH;
  readonly category: ContentCategory.MAP;
  readonly info: ThreeDMeshContent['info'];
  readonly status: ContentProcessingStatus | null;
  readonly config: ThreeDMeshConfigPerUser | null;
}

export interface APIGCPGroupContent extends APIBaseContent {
  readonly type: ContentType.GCP_GROUP;
  readonly category: ContentCategory.METADATA;
  readonly info: GCPGroupContent['info'];
  readonly status: ContentProcessingStatus | null;
  readonly config: GCPGroupConfigPerUser;
}

export interface APICustomESSModelContent {
  readonly id: number;
  readonly nameEn: string;
  readonly nameKo: string;
  readonly url: string;
  readonly thumbnail: string;
  readonly width: number;
  readonly length: number;
  readonly height: number;
}

export enum APIESSContentType {
  MODEL = 'model',
  MODEL_CUSTOM = 'model_custom',
  ARROW = 'waypoint',
  POLYGON = 'polygon',
  POLYLINE = 'polyline',
  TEXT = 'text',
  LINE_TEXT = 'line_text',
  GROUP = 'content_group',
}

export interface APIESSModelContent extends APIBaseContent {
  readonly type: APIESSContentType.MODEL;
  readonly category: ContentCategory.ESS;
  readonly memo: ESSModelContent['info']['description'];
  readonly info: {
    readonly locations: ESSModelContent['info']['location'];
    readonly modelId: ESSModelContent['info']['modelId'];
    readonly heading: ESSModelContent['info']['heading'];
    readonly speed: ESSModelContent['info']['speed'];
    readonly waypointId: ESSModelContent['info']['waypointId'];
  };
  readonly config: ESSModelContentConfigPerUser;
}

export interface APIESSCustomModelContent extends APIBaseContent {
  readonly type: APIESSContentType.MODEL_CUSTOM;
  readonly category: ContentCategory.ESS;
  readonly memo: ESSModelContent['info']['description'];
  readonly info: {
    readonly locations: ESSCustomModelContent['info']['location'];
    readonly miscMeta: {
      readonly dimensions: ESSCustomModelContent['info']['miscMeta'] &
        ESSCustomModelContent['info']['miscMeta']['dimensions'];
      readonly opacity: ESSCustomModelContent['info']['miscMeta'] &
        ESSCustomModelContent['info']['miscMeta']['opacity'];
      readonly radius: ESSCustomModelContent['info']['miscMeta'] &
        ESSCustomModelContent['info']['miscMeta']['radius'];
      readonly specification: ESSCustomModelContent['info']['miscMeta'] &
        ESSCustomModelContent['info']['miscMeta']['specification'];
    };
  };
  readonly config: ESSModelCustomContentConfigPerUser;
}

export interface APIESSArrowContent extends APIBaseContent {
  readonly type: APIESSContentType.ARROW;
  readonly category: ContentCategory.ESS;
  readonly info: {
    readonly locations: ESSArrowContent['info']['locations'];
  };
  readonly config: ESSArrowContentConfigPerUser;
}

export interface APIESSPolygonContent extends APIBaseContent {
  readonly type: APIESSContentType.POLYGON;
  readonly category: ContentCategory.ESS;
  readonly info: {
    readonly locations: ESSPolygonContent['info']['locations'];
  };
  readonly config: ESSPolygonContentConfigPerUser;
}

export interface APIESSPolylineContent extends APIBaseContent {
  readonly type: APIESSContentType.POLYLINE;
  readonly category: ContentCategory.ESS;
  readonly info: {
    readonly locations: ESSPolylineContent['info']['locations'];
  };
  readonly config: ESSPolylineContentConfigPerUser;
}

export interface APIESSTextContent extends APIBaseContent {
  readonly type: APIESSContentType.TEXT;
  readonly category: ContentCategory.ESS;
  readonly info: {
    readonly locations: ESSTextContent['info']['location'];
    readonly fontSize: ESSTextContent['info']['fontSize'];
    readonly description: ESSTextContent['info']['description'];
    readonly fontColor: string;
  };
  readonly config: ESSTextContentConfigPerUser;
}
export interface APIESSLineTextContent extends APIBaseContent {
  readonly type: APIESSContentType.LINE_TEXT;
  readonly category: ContentCategory.ESS;
  readonly info: {
    readonly locations: ESSLineTextContent['info']['locations'];
    readonly fontSize: ESSLineTextContent['info']['fontSize'];
    readonly description: ESSLineTextContent['info']['description'];
    readonly fontColor: string;
    readonly borderColor: Color;
  };
  readonly config: ESSTextContentConfigPerUser;
}
export interface APIContentGroup extends APIBaseContent {
  readonly type: ContentType.GROUP;
  readonly category: ContentCategory;
  readonly config: ContentGroupConfigPerUser;
  readonly info: GroupContent['info'];
}

export interface APIIssuePointContent extends APIBaseContent {
  readonly type: IssueContent['type'];
  readonly category: ContentCategory.ISSUE;
  readonly assignees: IssueAssignee[];
  readonly description: string;
  readonly info: IssueContent['info'];
  readonly issueCategory: IssueCategory;
  readonly issueCategoryId: IssueCategory['projectId'];
  readonly issueStatus: IssueStatus;
  readonly issueStatusId: IssueStatus['projectId'];
  readonly priority: IssuePriority;
  readonly config: IssuePointConfigPerUser | null;
  readonly dueDate: string | null;
  readonly issueAttachments: IssueAttachment[];
  readonly photo: Photo;
}

export interface APISlideContent extends APIBaseContent {
  readonly type: ContentType.SLIDE;
  readonly category: ContentCategory.PRESENTATION;
  readonly config: ContentConfigPerUser;
  readonly info: BaseContent['info'] & {
    readonly mapIds: Array<Content['id']>;
    readonly overlayIds?: Array<Content['id']>;
    readonly measurementIds?: Array<Content['id']>;
    readonly terrainIds?: Array<Content['id']>;
    readonly issueIds?: Array<Content['id']>;
    readonly essIds?: Array<Content['id']>;

    cameraPosition: number[];
    // camera rotation will act as focalOffset for 3D
    cameraRotation: number[];
    target: number[];
    readonly essContentIds: number[];
    readonly baseMapType: string;
    readonly screenId: number | string;
  };
  readonly slideThumbUrl: string;
  readonly slideUrl: string;
}

export interface APIAnnotationContent extends APIBaseContent {
  readonly type: ContentType.ANNOTATION;
  readonly category: ContentCategory.PRESENTATION;
  readonly info: BaseContent['info'] & {
    readonly slideId: Content['id'];
    readonly element: MarkerAreaState;
  };
  readonly config: ContentConfigPerUser;
}

export type APIContent =
  | APIMapContent
  | APIBlueprintPDFContent
  | APIBlueprintDXFContent
  | APIBlueprintDWGContent
  | APIDesignDXFContent
  | APIMarkerContent
  | APILengthContent
  | API3DLengthContent
  | API3DHeightContent
  | APIAreaContent
  | APIThreeAreaContent
  | APIVolumeContent
  | APIDSMContent
  | APIDTMContent
  | APIThreeDOrthoContent
  | APIPointCloudContent
  | APIThreeDMeshContent
  | APIGCPGroupContent
  | APIESSContent
  | APIContentGroup
  | APIIssueContent
  | APIBimContent
  | APIESSPolylineContent
  | APIFlattenContent
  | APIFlightPlanContent
  | APIFlightScheduleContent
  | APIDroneStationContent
  | APISlideContent
  | APIAnnotationContent;

export type APIESSContent =
  | APIESSModelContent
  | APIESSCustomModelContent
  | APIESSArrowContent
  | APIESSPolygonContent
  | APIESSPolylineContent
  | APIESSTextContent
  | APIESSLineTextContent
  | APIContentGroup;

export type APIIssueContent = APIIssuePointContent;

/**
 * Enum for classification of resources
 */
export enum ResourceType {
  SOURCE = 'sourcephoto.zip',
  ORTHO = 'orthophoto.tif',
  ORTHO_COMPRESSED_10 = 'orthophoto_reduced_10.tif',
  ORTHO_COMPRESSED_20 = 'orthophoto_reduced_20.tif',
  ORTHO_TFW = 'orthophoto.tfw',
  ORTHO_COMPRESSED_10_TFW = 'orthophoto_reduced_10.tfw',
  ORTHO_COMPRESSED_20_TFW = 'orthophoto_reduced_20.tfw',
  DSM = 'dsm.tif',
  DSM_COMPRESSED = 'dsm_reduced.tif',
  DTM = 'dtm.tif',
  DTM_COMPRESSED = 'dtm_reduced.tif',
  POINT_CLOUD = 'pointcloud.las',
  POINT_CLOUD_COMPRESSED_100 = 'pointcloud_100.las',
  POINT_CLOUD_COMPRESSED_25 = 'pointcloud_25.las',
  POINT_CLOUD_COMPRESSED_4 = 'pointcloud_4.las',
  MESH = '3dmodel.zip',
  THREED_MESH = '3d_model.zip',
}

export type OrthophotoDownloadTypes =
  | ResourceType.ORTHO
  | ResourceType.ORTHO_COMPRESSED_10
  | ResourceType.ORTHO_COMPRESSED_20;

export type OrthophotoTfwDownloadTypes =
  | ResourceType.ORTHO_TFW
  | ResourceType.ORTHO_COMPRESSED_10_TFW
  | ResourceType.ORTHO_COMPRESSED_20_TFW;

/**
 * Enum for classification of attatchment file
 */
export enum AttachmentType {
  PHOTO = 'photo',
  DRONE_PHOTO = 'drone_photo',
  SOURCE = 'source',
  ORTHO = 'orthophoto',
  BLUEPRINT_PDF = 'blueprint_pdf',
  BLUEPRINT_DXF = 'blueprint_dxf',
  BLUEPRINT_DWG = 'blueprint_dwg',
  DESIGN_DXF = 'design_dxf',
  DSM = 'dsm',
  POINTCLOUD = 'las',
  BIM = 'bim',
  GCP = 'gcp',
  THREE_SIXTY = 'three_sixty',
  THREE_SIXTY_SOURCE = 'three_sixty_source',
  THREE_SIXTY_STITCHED = 'three_sixty_stitched',
  THREE_SIXTY_VIDEO = 'three_sixty_video',
  VSLAM_VIDEO = 'vslam_video',
  THREE_SIXTY_VSLAM = 'three_sixty_vslam',
  FLIGHT_PLAN = 'FLIGHT_PLAN',
  FLIGHT_VIDEO = 'flight_video',
  FLIGHT_LOG = 'flight_log',
  INSPECTION = 'inspection',
  VIEWPOINT = 'viewpoint',
}

export interface Attachment {
  readonly id: number | string;
  readonly contentId: Content['id'];
  readonly projectId?: Project['id'];
  readonly displayName?: string;
  readonly file: {
    readonly url: string;
    readonly markerThumb: {
      url: string;
    };
    readonly photoThumb?: {
      readonly url: string;
    };
  };
  readonly type: AttachmentType;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface APIAttachment {
  id: string;
  readonly type: 'attachments';
  readonly attributes: Overwrite<
    Omit<Attachment, 'id'>,
    {
      readonly createdAt: string;
      readonly updatedAt: string;
    }
  >;
}

export interface APIProcessingAttachment {
  id: string;
  readonly type: 'v2ProcesssingAttachments';
  readonly attributes: Overwrite<
    Omit<Attachment, 'id'>,
    {
      readonly createdAt: string;
      readonly updatedAt: string;
    }
  >;
}

export const AttachmentOverlayTypes: Readonly<AttachmentType[]> = [
  AttachmentType.BLUEPRINT_DXF,
  AttachmentType.BLUEPRINT_PDF,
  AttachmentType.BLUEPRINT_DWG,
  AttachmentType.DESIGN_DXF,
  AttachmentType.FLIGHT_PLAN,
];

export interface SurveyContent {
  readonly maxCut: number;
  readonly maxFill: number;
  readonly minLow: number;
  readonly minHigh: number;
  readonly maxLow: number;
  readonly maxHigh: number;
}
export interface DSMPercents {
  readonly min: number;
  readonly max: number;
  readonly survey?: SurveyContent;
}

export interface DSMInfo {
  /**
   * @warn
   * Legacy DSMInfo does not have `isOn`.
   */
  readonly isOn?: boolean;
  readonly opacity: number;
  readonly percents: DSMPercents;
}

export interface DTMPercents {
  readonly min: number;
  readonly max: number;
  readonly survey?: SurveyContent;
}

export interface DTMInfo {
  /**
   * @warn
   * Legacy DTMInfo does not have `isOn`.
   */
  readonly isOn?: boolean;
  readonly opacity: number;
  readonly percents: DTMPercents;
}

export type ContentConfigPerUser =
  | MarkerConfigPerUser
  | LengthConfigPerUser
  | Length3dHeightConfigPerUser
  | Length3dLengthConfigPerUser
  | VolumeConfigPerUser
  | AreaConfigPerUser
  | ThreeAreaConfigPerUser
  | BlueprintConfigPerUser
  | DesignDXFConfigPerUser
  | MapConfigPerUser
  | DSMConfigPerUser
  | DTMConfigPerUser
  | PointCloudConfigPerUser
  | ThreeDOrthoConfigPerUser
  | ThreeDMeshConfigPerUser
  | GCPGroupConfigPerUser
  | ESSModelContentConfigPerUser
  | ESSModelCustomContentConfigPerUser
  | ESSArrowContentConfigPerUser
  | ESSPolygonContentConfigPerUser
  | ESSPolylineContentConfigPerUser
  | ESSTextContentConfigPerUser
  | ContentGroupConfigPerUser
  | IssuePointConfigPerUser
  | IssuePhotoConfigPerUser
  | TerrainEditingConfigPerUser
  | FlightPlanConfigPerUser
  | DroneStationConfigPerUser
  | FlightScheduleConfigPerUser;

/**
 * @desc Config for each content and each user (content 별 user 별 설정)
 */
export interface BaseContentConfigPerUser {
  readonly type: ContentType;
  readonly selectedAt?: Date | null;
  isClampedToggled?: boolean;
  temporaryContentId?: string | number;
  isVisualizeVolumeToggled?: boolean;
  isInIfc?: boolean;
}

export interface MarkerConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.MARKER;
}

export enum DistanceType {
  HORIZONTAL = 'horizontal',
  SURFACE = 'surface',
  POINT_TO_POINT = 'point-to-point',
}

export interface LengthConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.LENGTH;
  readonly isElevationToggled: boolean;
  readonly distanceType?: DistanceType;
}
export interface Length3dLengthConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.THREE_LENGTH;
  readonly isElevationToggled: boolean;
  readonly distanceType?: DistanceType;
}
export interface Length3dHeightConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.THREE_HEIGHT;
  readonly isElevationToggled: boolean;
  readonly distanceType?: DistanceType;
}
export interface AreaConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.AREA;
  readonly distanceType?: DistanceType;
  isDsmElev?: boolean;
}

export interface TerrainEditingConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.FLATTEN_MAP;
  terrianEditingMethod?: TerrainEditingMethod;
  isLoading?: boolean;
}

export interface ThreeAreaConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.THREE_AREA;
}
export interface VolumeConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.VOLUME;
  readonly dsm?: DSMInfo;
  isDsmElev?: boolean;

  /**
   * TODO: @ebraj-angelswing
   */
  readonly dtm?: DTMInfo;
  isDtmElev?: boolean;
}

interface WithOpacity {
  readonly opacity: number;
}

export interface BlueprintDXFConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.BLUEPRINT_DXF;
}
export interface BlueprintDWGConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.BLUEPRINT_DWG;
  readonly zAdjust?: number;
}

export interface BimConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.BIM;
}

export interface FlightPlanConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.FLIGHT_PLAN;
}
export interface FlightScheduleConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.FLIGHT_SCHEDULE;
}
export interface DroneStationConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.DRONE_STATION;
}

export interface BlueprintPDFConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.BLUEPRINT_PDF;
}
export interface DesignDXFConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.DESIGN_DXF;
}

export type BlueprintConfigPerUser =
  | BlueprintDXFConfigPerUser
  | BlueprintPDFConfigPerUser
  | BlueprintDWGConfigPerUser
  | BimConfigPerUser;

export interface MapConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.MAP;
}
export interface DSMConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.DSM;
  readonly percents?: {
    min: number;
    max: number;
  };
}

export interface DTMConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.DTM;
  readonly percents?: {
    min: number;
    max: number;
  };
}

export interface PointCloudConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.POINTCLOUD;
  readonly points: {
    numberOfPointsInMil: number;
    sizeOfPoint: number;
  };
}

export interface ThreeDMeshConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.THREE_D_MESH;
}

export interface ThreeDOrthoConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.THREE_D_ORTHO;
}

export interface GCPGroupConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.GCP_GROUP;
}

export interface ESSModelContentConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ESS_MODEL;
}

export interface ESSModelCustomContentConfigPerUser extends BaseContentConfigPerUser, WithOpacity {
  readonly type: ContentType.ESS_MODEL_CUSTOM;
}

export interface ESSArrowContentConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ESS_ARROW;
}

export interface ESSPolygonContentConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ESS_POLYGON;
}

export interface ESSPolylineContentConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ESS_POLYLINE;
}

export interface ESSTextContentConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ESS_TEXT;
}
export interface ESSLineTextContentConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ESS_LINE_TEXT;
}
export interface ContentGroupConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.GROUP;
}

export interface IssuePointConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ISSUE_POINT;
}

export interface IssuePhotoConfigPerUser extends BaseContentConfigPerUser {
  readonly type: ContentType.ISSUE_PHOTO;
}

/**
 * This config exists by project and by user (프로젝트 별 유저 별)
 */
export interface ProjectConfig {
  readonly projectId: Project['id'];
  readonly lastSelectedScreenId?: Screen['id'];
  /**
   * @desc used to display last uploaded GCP in GCP upload popup
   */
  readonly lastGcp?: ProjectConfigLastGcp;
  readonly isMapShown?: boolean;
}

export interface ProjectConfigLastGcp {
  readonly coordinateSystem: string;
  readonly gcpTitles?: string[];
  readonly gcpData: string[][];
  readonly uploadedAt: Date;
}

export interface UploadContent {
  readonly id: Content['id'];
  readonly type: AttachmentType;
  readonly file: Array<{
    size: File['size'];
    hash: string;
  }>;
  readonly uploadedAt: Date;
  readonly status: APIStatus;
  readonly error?: HTTPError;
}
// Pages related types
/**
 * Type of front page tabs.
 */
export enum FrontPageTabType {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  PROMOTION = 'PROMOTION',
}

export enum PhotoPathType {
  TIMELINE = '',
  LOCATION = 'location',
  NO_DATE = 'no_date',
  TIMELAPSE = 'timelapse',
  ALBUM = 'album',
}

export enum PhotoDateType {
  SORTED_PHOTO = 'SORTED_PHOTO',
  UNSORTED_PHOTO = 'UNSORTED_PHOTO',
  DRONE_UPLOADS = 'DRONE_UPLOADS',
  SURVEY_UPLOADS = 'SURVEY_UPLOADS',
  TIMELAPSE = 'TIMELAPSE',
}

export type SignInFormUserKeys = 'email';
export type SignInFormUserPasswordKeys = 'password';
export type SignInFormKeys =
  | keyof Pick<User, SignInFormUserKeys>
  | keyof Pick<UserPassword, SignInFormUserPasswordKeys>;

export type PasswordFormKeys = 'email';
export type PasswordFormValues = Pick<User, PasswordFormKeys>;
export type NewPasswordFormKeys = 'password' | 'passwordConfirmation';
export type NewPasswordFormValues = Pick<UserPassword, NewPasswordFormKeys>;

export type SignUpFormUserKeys =
  | 'email'
  | 'avatar'
  | 'firstName'
  | 'lastName'
  | 'contactNumber'
  | 'organization'
  | 'country'
  | 'purpose'
  | 'language';
export type SignUpFormUserPasswordKeys = 'password' | 'passwordConfirmation';
export type RequiredSignUpFormKeys =
  | keyof Pick<User, Exclude<SignUpFormUserKeys, 'avatar'>>
  | keyof Pick<UserPassword, SignUpFormUserPasswordKeys>
  | 'eula';
export type SignUpFormKeys = RequiredSignUpFormKeys | 'avatar';

export type RequiredSignUpFormValues = Pick<User, Exclude<SignUpFormUserKeys, 'avatar'>> &
  Pick<UserPassword, SignUpFormUserPasswordKeys> & { readonly eula: boolean };
export type SignUpFormValues = RequiredSignUpFormValues & { avatar?: File };
export type SignUpFormDirties = Readonly<Record<SignUpFormKeys, boolean>>;

/**
 * Type of project page tabs.
 */
export enum ProjectPageTabType {
  LIST = 'LIST',
  MANAGE = 'MANAGE',
  DRONE_STATION = 'DRONE_STATION',
  MYPAGE = 'MYPAGE',
  MARKET = 'MARKET',
  PILOT = 'PILOT',
}

export type MyPageFormUserKeys =
  | 'contactNumber'
  | 'organization'
  | 'country'
  | 'purpose'
  | 'language';
export type MyPageFormUserPasswordKeys = 'password' | 'passwordConfirmation';
export type MyPageFormFileKey = 'avatar';

export type MyPageFormValues = Pick<User, MyPageFormUserKeys> &
  Pick<UserPassword, MyPageFormUserPasswordKeys> &
  Partial<Record<MyPageFormFileKey, { readonly file: File; readonly url: string }>>;

export type CustomModelKeys = 'name' | 'specification' | 'length' | 'width' | 'height' | 'radius';
export type CustomModelFormValues = Pick<CustomModel, CustomModelKeys>;
/**
 * Type of project page popups.
 */
export enum ProjectPagePopupType {
  ADD = 'ADD',
  DELETE = 'DELETE',
  INVITE = 'INVITE',
  INVITE_ALERT = 'INVITE_ALERT',
  SHARE = 'SHARE',
  SHARE_ALERT = 'SHARE_ALERT',
  ACCEPT = 'ACCEPT',
  CONFIRM_USER_UPDATE = 'CONFIRM_USER_UPDATE',
  USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS',
  NO_PERMISSION = 'NO_PERMISSION',
  SIGN_UP_TUTORIAL = 'SIGN_UP_TUTORIAL',
  CONFIRM_DELETE_PERMISSION = 'CONFIRM_DELETE_PERMISSION',
  DRONE_STATION_ADD = 'DRONE_STATION_ADD',
}

/**
 * Type of content page service tabs.
 */
export enum ContentPageServiceTabType {
  DATA = 'data',
  FLIGHT = 'flight',
}

/**
 * Type of the flight schedule fields
 */
export enum FlightScheduleScheduleType {
  'IMMEDIATE' = 0,
  'TIMED' = 1,
  'CONTINUOUS' = 2,
}

export enum FlightScheduleLostActionType {
  'RETURN_TO_HOME' = 0,
  'HOVER' = 1,
  'LAND' = 2,
}

/**
 * Type of content page tabs.
 */
export enum ContentPageTabType {
  MAP = 'map',
  OVERLAY = 'overlay',
  MEASUREMENT = 'measurement',
  ESS = 'safety',
  PHOTO = 'photo',
  ISSUE = 'issue',
  DASHBOARD = 'dashboard',

  /**
   * Added the dash instead of the underscore as it will be also used in url, but we can use underscore if need to maintain consistency..
   */
  DRONE_STATION = 'drone-station',
  FLIGHT_PLAN = 'flight-plan',
  FLIGHT_SCHEDULE = 'flight-schedule',

  PRESENTATION = 'presentation',
  VIEWPOINT_CAPTURE = 'viewpoint-capture',
}

/**
 * Type of map horizontal tabs.
 */
export enum MapHorizontalTabStatus {
  HIDDEN = 'HIDDEN',
  ESS = 'ESS',
  ESS_CUSTOM_MODEL = 'ESS_CUSTOM_MODEL',
  ELEVATION_PROFILE = 'ELEVATION_PROFILE',
  PRESENTATION = 'PRESENTATION',
}

/**
 * @desc type for map popups
 */
export enum ContentPagePopupOnMapType {
  DESIGN_DXF_SELECT = 'DESIGN_DXF_SELECT',
  SURVEY_SELECT = 'SURVEY_SELECT',
}

/**
 * Type of content page popups with lower zindex.
 */
export enum ContentPageMinorPopupType {
  ESS_CAMPAIGN = 'ESS_CAMPAIGN',
  SHARE = 'SHARE',
  ADD_ROCK_LAYER = 'ADD_ROCK_LAYER',
  SELECT_INTIAL_SURVEY = 'SELECT_INTIAL_SURVEY',
  SAVE_ANNOTATIONS = 'SAVE_ANNOTATIONS',
  CONFIRM_DELETE_ANNOTATION = 'CONFIRM_DELETE_ANNOTATION',
  UPLOAD_TO_ALBUM = 'UPLOAD_TO_ALBUM',
  CONFIRM_DELETE_VIEWPOINTS = 'CONFIRM_DELETE_VIEWPOINTS',
  VIEWPOINT_OUT_OF_BOUND = 'VIEWPOINT_OUT_OF_BOUND',
  VSLAM_GEOREFERENCING_BASE = 'VSLAM_GEOREFERENCING_BASE',
  VSLAM_SELECTING_BLUEPRINT = 'VSLAM_SELECTING_BLUEPRINT',
  MY_PAGE_POPUP = 'MY_PAGE_POPUP',
}

/**
 * Type of content page popups.
 */
export enum ContentPagePopupType {
  IMAGE = 'IMAGE',
  UPLOAD = 'UPLOAD',
  SELECT_IMAGE = 'SELECT_IMAGE',
  DOWNLOAD = 'DOWNLOAD',
  UNDOWNLOADABLE = 'UNDOWNLOADABLE',
  BLUEPRINT_ALIGN = 'BLUEPRINT_ALIGN',

  DELETE_CONFIRM = 'DELETE_CONFIRM',

  DELETE_GROUP = 'DELETE_GROUP',
  DELETE_SCREEN = 'DELETE_SCREEN',
  OVERWRITE_SCREEN = 'OVERWRITE_SCREEN',

  PHOTO_UPLOAD = 'PHOTO_UPLOAD',
  PHOTO_UPLOAD_SUCCESS = 'PHOTO_UPLOAD_SUCCESS',
  PHOTO_UPLOAD_FAIL = 'PHOTO_UPLOAD_FAIL',
  DELETE_PHOTO = 'DELETE_PHOTO',

  // Related to the 360 panorama photo
  PANORAMA_UPLOAD = 'PANORAMA_UPLOAD',
  THREE_SIXTY_SOURCE_UPLOAD = 'THREE_SIXTY_SOURCE_UPLOAD',

  BLUEPRINT_UPLOAD = 'BLUEPRINT_UPLOAD',
  SOURCE_ERROR = 'SOURCE_ERROR',
  ORTHO_UPLOAD = 'ORTHO_UPLOAD',
  LAS_UPLOAD = 'LAS_UPLOAD',
  DSM_UPLOAD = 'DSM_UPLOAD',
  DESIGN_UPLOAD = 'DESIGN_UPLOAD',
  BIM_UPLOAD = 'BIM_UPLOAD',

  FLIGHT_PLAN_UPLOAD = 'FLIGHT_PLAN_UPLOAD',
  FLIGHT_PLAN_UPLOAD_SUCCESS = 'FLIGHT_PLAN_UPLOAD_SUCCESS',
  FLIGHT_PLAN_UPLOAD_FAIL = 'FLIGHT_PLAN_UPLOAD_FAIL',

  FLIGHT_SCHEDULE_CREATION_SUCCESS = 'FLIGHT_SCHEDULE_CREATION_SUCCESS',
  FLIGHT_SCHEDULE_CREATION_FAIL = 'FLIGHT_SCHEDULE_CREATION_FAIL',

  PROGRESS_BAR = 'PROGRESS_BAR',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
  PROCESSING_FAILED = 'PROCESSING_FAILED',

  REPORT_DOWNLOAD = 'REPORT_DOWNLOAD',

  SHARE_ALERT = 'SHARE_ALERT',
  EXTERNAL_SHARE = 'EXTERNAL_SHARE',

  NO_SELECTED_MAP = 'NO_SELECTED_MAP',
  PROCESSING = 'PROCESSING',
  CANCEL = 'CANCEL',
  NO_PERMISSION = 'NO_PERMISSION',

  EXPORT_START = 'EXPORT_START',
  EXPORT_SUCCESS = 'EXPORT_SUCCESS',

  PRINT_START = 'PRINT_START',
  PRINT_SUCCESS = 'PRINT_SUCCESS',

  DXF2RASTER_PROCESSING_FAIL = 'DXF2RASTER_PROCESSING_FAIL',
  CANCEL_VOLUME_CREATION_POPUP = 'CANCEL_VOLUME_CREATION_POPUP',

  CUSTOM_MODEL_SAVE = 'CUSTOM_MODEL_SAVE',
  CUSTOM_USER_MODEL_SAVE = 'CUSTOM_USER_MODEL_SAVE',

  CONFIRM_DELETE_PERMISSION = 'CONFIRM_DELETE_PERMISSION',
  TAG_DATE_POPUP = 'TAG_DATE_POPUP',

  PRESENTATION_CREATION_SUCCESS = 'PRESENTATION_CREATION_SUCCESS',

  CONTENT_PROCESSING = 'CONTENT_PROCESSING',
  DTM_PROCESSING = 'DTM_PROCESSING',

  DELETE_SELECTED_SLIDES = 'DELETE_SELECTED_SLIDES',

  VSLAM_VIDEO_UPLOAD = 'VSLAM_VIDEO_UPLOAD',
  VSLAM_VIDEO_UPLOAD_SUCCESS = 'VSLAM_VIDEO_UPLOAD_SUCCESS',
  VSLAM_VIDEO_UPLOAD_FAIL = 'VSLAM_VIDEO_UPLOAD_FAIL',

  FLIGHT_VIDEO_UPLOAD = 'FLIGHT_VIDEO_UPLOAD',
  FLIGHT_VIDEO_UPLOAD_SUCCESS = 'FLIGHT_VIDEO_UPLOAD_SUCCESS',
  FLIGHT_VIDEO_UPLOAD_FAIL = 'FLIGHT_VIDEO_UPLOAD_FAIL',
  DELETE_EARTHWORK_CONFIRM = 'DELETE_EARTHWORK_CONFIRM',
}

/**
 * type of Attachment popup type
 */
export enum AttachPopupTypes {
  PHOTO = 'photo',
  ORTHO = 'orthophoto',
  BLUEPRINT_PDF = 'blueprint_pdf',
  BLUEPRINT_DXF = 'blueprint_dxf',
  BLUEPRINT_DWG = 'blueprint_dwg',
  DESIGN_DXF = 'design_dxf',
  DSM = 'dsm',
  POINTCLOUD = 'las',
}

/**
 * Type of printing format
 */
export enum BlueprintFormat {
  PDF = 'PDF',
  DXF = 'DXF',
}

/**
 * Type of printing format
 */
export enum PrintFormat {
  JPG = 'JPG',
}
/**
 * Type of printing Size
 */
export enum PrintSize {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
}

export interface PrintFormatAndSize {
  format: PrintFormat;
  size: PrintSize;
}

/**
 * Type of Print Format and its sizes
 */
export type PrintFormatsAndSizes = PrintFormatAndSize[];

/**
 * Modes of TwoDDisplay
 */
export enum TwoDDisplayMode {
  NORMAL = 'NORMAL',
  SLIDER = 'SLIDER',
  COMPARISON2 = 'COMPARISON2',
  COMPARISON4 = 'COMPARISON4',
}

export enum VideoOverlayMode {
  FOLLOW_DRONE = 'FOLLOW_DRONE',
  DIGITAL_VIEW = 'DIGITAL_VIEW',
  VIDEO_VIEW = 'VIDEO_VIEW',
  SETTING = 'SETTING',
}

export enum GeolocationError {
  PERMISSION = 1,
}

export interface Compare2Contents {
  readonly leftId?: Content['id'];
  readonly rightId?: Content['id'];
}

export interface Compare4Contents {
  readonly firstId?: Content['id'];
  readonly secondId?: Content['id'];
  readonly thirdId?: Content['id'];
  readonly fourthId?: Content['id'];
}

export type PointCloudContents = ThreeDHeightContent | ThreeDLengthContent | ThreeAreaContent;

export type MeasurementContent =
  | AreaContent
  | ThreeAreaContent
  | MarkerContent
  | LengthContent
  | VolumeContent
  | ThreeDLengthContent
  | ThreeDHeightContent
  | FlattenContent;

export type ESSMeasurementContent =
  | ESSPolylineContent
  | ESSArrowContent
  | ESSPolygonContent
  | ESSLineTextContent
  | ESSTextContent;

export type DSMorMapContent =
  | DTMContent
  | DSMContent
  | MapContent
  | ThreeDOrthoContent
  | PointCloudContent
  | ThreeDMeshContent
  | GCPGroupContent;
export type OverLayContent =
  | BlueprintDXFContent
  | BlueprintDWGContent
  | BlueprintPDFContent
  | DesignDXFContent
  | BimContent;
export type LengthAreaVolumeContent = LengthContent | AreaContent | VolumeContent;
export type GeometryContent =
  | MeasurementContent
  | ESSArrowContent
  | ESSPolygonContent
  | ESSPolylineContent
  | IssuePointContent
  | IssuePhotoContent;

export type ProcessibleContent =
  | MapContent
  | BlueprintPDFContent
  | BlueprintDXFContent
  | BlueprintDWGContent
  | DesignDXFContent
  | ThreeDOrthoContent
  | DSMContent
  | PointCloudContent
  | ThreeDMeshContent
  | FlattenContent;
export type PinnableContent = MeasurementContent & OverLayContent;
export type OlLayerContentType = MeasurementContent | IssueContent;

export interface PinnedAndUnpinnedContents {
  pinnedContents: Content[];
  unpinnedContents: Content[];
}

export const MeasurementContentTypes: ContentType[] = [
  ContentType.AREA,
  ContentType.LENGTH,
  ContentType.MARKER,
  ContentType.VOLUME,
  ContentType.THREE_LENGTH,
  ContentType.THREE_HEIGHT,
  ContentType.THREE_AREA,
  ContentType.FLATTEN_MAP,
];
export const TerrainContentTypes: ContentType[] = [ContentType.FLATTEN_MAP];

export const PointCloudMeasurementContentTypes: ContentType[] = [
  ContentType.THREE_HEIGHT,
  ContentType.THREE_LENGTH,
  ContentType.THREE_AREA,
];

export const DSMorMapContentTypes: ContentType[] = [
  ContentType.MAP,
  ContentType.THREE_D_ORTHO,
  ContentType.DSM,
  ContentType.DTM,
  ContentType.POINTCLOUD,
  ContentType.GCP_GROUP,
  ContentType.THREE_D_MESH,
];
export const OverlayContentTypes: ContentType[] = [
  ContentType.BLUEPRINT_PDF,
  ContentType.BLUEPRINT_DXF,
  ContentType.BLUEPRINT_DWG,
  ContentType.DESIGN_DXF,
  ContentType.BIM,
];

export const ESSContentTypes: ContentType[] = [
  ContentType.ESS_MODEL,
  ContentType.ESS_MODEL_CUSTOM,
  ContentType.ESS_ARROW,
  ContentType.ESS_POLYGON,
  ContentType.ESS_POLYLINE,
  ContentType.ESS_TEXT,
  ContentType.ESS_LINE_TEXT,
];
export const IssueContentTypes: ContentType[] = [ContentType.ISSUE_POINT, ContentType.ISSUE_PHOTO];

export const PresentationContentTypes: ContentType[] = [ContentType.SLIDE];

export const DroneStationContentypes: ContentType[] = [ContentType.DRONE_STATION];
export const FlightPlanContentTypes: ContentType[] = [ContentType.FLIGHT_PLAN];
export const FlightScheduleContentTypes: ContentType[] = [ContentType.FLIGHT_SCHEDULE];

export type ESSLineBasedContent = ESSArrowContent | ESSPolygonContent | ESSPolylineContent;
export type ESSWorkToolContent =
  | ESSLineBasedContent
  | ESSTextContent
  | ESSLineTextContent
  | ESSCustomModelContent;
export type ESSLineBasedType = ESSLineBasedContent['type'];
export type ESSWorkToolType = ESSWorkToolContent['type'];
export type LocationBasedContentType =
  | GeometryContent['type']
  | ContentType.ESS_MODEL
  | ContentType.ESS_MODEL_CUSTOM
  | ContentType.BIM
  | ContentType.ESS_TEXT
  | ContentType.ESS_LINE_TEXT
  | ContentType.ISSUE_POINT
  | ContentType.ISSUE_PHOTO;

export const ProcessibleContentTypes: ContentType[] = [
  ContentType.MAP,
  ContentType.BLUEPRINT_PDF,
  ContentType.BLUEPRINT_DXF,
  ContentType.BLUEPRINT_DWG,
  ContentType.DESIGN_DXF,
  ContentType.THREE_D_ORTHO,
  ContentType.DSM,
  ContentType.DTM,
  ContentType.POINTCLOUD,
  ContentType.THREE_D_MESH,
  ContentType.BIM,
  ContentType.FLATTEN_MAP,
];
export const IsPinnableContentTypes: ContentType[] = [...MeasurementContentTypes];
export const IsPinnableCategoryTypes: ContentCategory[] = [ContentCategory.MEASUREMENT];

export const IsPersonalableCategoryTypes = [
  ContentCategory.MEASUREMENT,
  ContentCategory.OVERLAY,
  ContentCategory.ESS,
];

export enum ContentEvent {
  CREATED = 'create',
  DESTROY = 'destroy',
  RECOVERED = 'recover',
  MOVE = 'move',
  UNDO_MOVE = 'undo_move',
}

export enum ContentEventStatus {
  OK = 'ok',
  EXPIRED = 'expired',
  PERFORMED = 'performed',
}

export interface ContentEventLog {
  readonly id: number;
  readonly relatedLogId: ContentEventLog['id'] | null;
  readonly event: ContentEvent;
  readonly status: ContentEventStatus;

  readonly screen: {
    readonly id: Screen['id'] | null;
    readonly appearAt: Date | null;
    readonly title: string;
  };

  readonly content: {
    readonly id: Content['id'];
    readonly type: Content['type'];
    readonly description: string;
    readonly color: Content['color'];

    readonly createdBy: {
      readonly id: User['id'];
      readonly name: string;
      readonly email: string;
    };

    readonly createdAt: Content['createdAt'];
    readonly deletedAt: Date | null;
  };

  readonly createdBy: {
    readonly id: User['id'];
    readonly name: string;
    readonly email: string;
  };
  readonly createdAt: Date;
}

export interface APIContentEventLog {
  readonly id: number;
  readonly relatedLogId: ContentEventLog['id'] | null;
  readonly event: ContentEvent;
  readonly status: ContentEventStatus;

  readonly screen: {
    readonly id: Screen['id'] | null;
    readonly appearAt: string | null;
    readonly title: string;
  };

  readonly content: {
    readonly id: number;
    readonly type: Content['type'];
    readonly description: string;
    readonly color: string | null;

    readonly createdBy: {
      readonly id: User['id'];
      readonly name: string;
      readonly email: string;
    };

    readonly createdAt: string;
    readonly deletedAt: string | null;
  };

  readonly createdBy: {
    readonly id: User['id'];
    readonly name: string;
    readonly email: string;
  };
  readonly createdAt: string;
}

export enum ContentEventLogKey {
  ID = 'id',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  EVENT = 'event',
  SCREEN = 'screen',
  CONTENT = 'content',
  ACTION = 'action',
}

export enum LasDownSamplingStatus {
  NOT_GENERATED = 'not-generated',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

// In BE, when it has not yet been generated, it's empty/undefined, hence the exclude.
// In FE, it is made more explicit by setting it as NOT_GENERATED.
export type APILasDownSamplingStatus = Exclude<
  LasDownSamplingStatus,
  LasDownSamplingStatus.NOT_GENERATED
>;

export interface AttachmentPostStatus {
  readonly total: number;
  readonly progress: number;
  readonly error?: HTTPError;
}

/**
 * @author Junyoung Clare Jang
 * @desc Languages we will use to localize
 */
export enum Language {
  KO_KR = 'ko-KR',
  EN_US = 'en-US',
}

export interface APICloudFront {
  readonly cloudFrontPolicy: string;
  readonly cloudFrontSignature: string;
  readonly cloudFrontKeyPairId: string;
}

export interface APICloudCDN {
  readonly cloudCdnCookie: string;
}

export type APICDNCookie = APICloudFront & APICloudCDN;

export interface CloudFront {
  readonly cloudFrontPolicy: APICloudFront['cloudFrontPolicy'];
  readonly cloudFrontSignature: APICloudFront['cloudFrontSignature'];
  readonly cloudFrontKeyPairId: APICloudFront['cloudFrontKeyPairId'];
}

export interface CloudCDN {
  readonly cloudCdnCookie: APICloudCDN['cloudCdnCookie'];
}

export interface CookieCloudFront {
  'CloudFront-Policy': CloudFront['cloudFrontPolicy'];
  'CloudFront-Signature': CloudFront['cloudFrontSignature'];
  'CloudFront-Key-Pair-Id': CloudFront['cloudFrontKeyPairId'];
}

export interface CookieCloudCDN {
  'Cloud-CDN-Cookie': CloudCDN['cloudCdnCookie'];
}

export type CookieCDN = CookieCloudFront & CookieCloudCDN;

// Model states for APIs
export interface AuthState {
  readonly authedUser?: Pick<User, 'id'> & {
    readonly token: string;
    readonly refreshToken: string;
    readonly userEmail?: string;
    readonly currentSession?: CurrentSession;
  } & Partial<CloudFront & CloudCDN>;
  readonly tempUser?: Pick<User, 'id'> & {
    readonly token: string;
    readonly refreshToken: string;
  };
  readonly automaticSignIn: boolean;
  readonly signInStatus: APIStatus;
  readonly signUpStatus: APIStatus;
  readonly signInError?: HTTPError;
  readonly signInErrorCode?: number;
  readonly signUpError?: HTTPError;
  readonly pathBeforeAuth?: string;
}
export interface RQUsersState {
  readonly byId: {
    readonly [id: number]: User;
  };
  readonly allIds: Array<User['id']>;
}

export interface UsersState {
  // readonly users: {
  //   readonly byId: {
  //     readonly [id: number]: User;
  //   };
  //   readonly allIds: Array<User['id']>;
  // };
  // readonly notices: {
  //   readonly [id: number]: Notice;
  // };
  readonly getUserInfoStatus: APIStatus;
  readonly getUserInfoError?: HTTPError;
  readonly patchUserInfoStatus: APIStatus;
  readonly patchUserInfoError?: HTTPError;
  readonly postPasswordResetStatus: APIStatus;
  readonly postPasswordResetError?: HTTPError;
  readonly patchPasswordStatus: APIStatus;
  readonly patchPasswordError?: HTTPError;
  readonly getNoticeStatus: APIStatus;
  readonly getNoticeError?: HTTPError;
  readonly patchNoticeStatus: APIStatus;
  readonly patchNoticeError?: HTTPError;
}

export interface UserConfigState {
  readonly config?: UserConfig;

  readonly getUserConfigStatus: APIStatus;
  readonly getUserConfigError?: HTTPError;
  readonly patchUserConfigStatus: APIStatus;
  readonly patchUserConfigError?: HTTPError;
}

export interface PlanConfigState {
  readonly config?: PlanConfig;

  readonly getPlanConfigStatus: APIStatus;
  readonly getPlanConfigError?: HTTPError;
}

export interface ESSModelsState {
  readonly byId: Partial<Record<ESSModelInstance['id'], ESSModelInstance>>;
  readonly byCategory?: Partial<Record<ESSModelCategory['id'], Array<ESSModelInstance['id']>>>;
  readonly categories?: ESSModelCategory[];
  readonly selectedCategoryId?: number;
  readonly selectedModelId?: number;
  readonly customModels?: Partial<Record<ESSCustomModelInstance['id'], ESSCustomModelInstance>>;

  readonly createESSModelCategoryStatus: APIStatus;
  readonly createESSModelCategoryError?: HTTPError;
  readonly createCustomESSModelStatus: APIStatus;
  readonly createCustomESSModelError?: HTTPError;
  readonly getESSModelsByCategoryStatus: APIStatus;
  readonly getESSModelsByCategoryError?: HTTPError;
  readonly getESSModelByIdStatus: APIStatus;
  readonly getESSModelByIdError?: HTTPError;
  readonly getESSModelCategoriesStatus: APIStatus;
  readonly getESSModelCategoriesError?: HTTPError;
  readonly getESSCustomModelsStatus?: APIStatus;
  readonly getESSCustomModelsError?: HTTPError;
}

export interface ESSModelContent extends BaseContent {
  readonly type: ContentType.ESS_MODEL;
  readonly category: ContentCategory.ESS;
  readonly info: BaseContent['info'] & {
    /**
     * The location of the model.
     */
    readonly location: GeoPoint;
    /**
     * Description to write on the model.
     */
    readonly description: string;
    /**
     * Model id. This will be cross-referenced to the list of models in the ESSModels
     * to retrieve info such as the model url itself.
     */
    readonly modelId: number;
    /**
     * Whether this model's work radius visualization is enabled or not.
     */
    readonly isWorkRadiusVisEnabled: boolean;
    /**
     * User can set the model to connect with an arrow/waypoint content
     * so that it moves along the track.
     */
    readonly waypointId?: number;
    /**
     * Model's custom heading. User can define their own model heading.
     * Heading is in degrees.
     */
    readonly heading?: number;
    /**
     * Model's speed. User can control the speed of the model when moving.
     */
    readonly speed?: number;
    /**
     *
     */
    readonly miscMeta?: MiscMetaModel;
  };
}

export interface MiscMetaModel {
  readonly rotation?: {
    readonly x: number;
    readonly y: number;
    readonly z: number;
    readonly w: number;
  };

  readonly scale?: {
    readonly x: number;
    readonly y: number;
    readonly z: number;
  };
}

export interface MiscMeta {
  /**
   * The dimension of the model.
   */
  readonly dimensions: Dimension;

  /**
   * The opacity of the model.
   */
  readonly opacity: number;

  /**
   * The work of the model.
   */
  readonly radius: number;

  /**
   * The specification of the model.
   */
  readonly specification: string;

  readonly rotation?: {
    readonly x: number;
    readonly y: number;
    readonly z: number;
    readonly w: number;
  };

  readonly scale?: {
    readonly x: number;
    readonly y: number;
    readonly z: number;
  };
}

export interface BimMeta {
  /**
   * The cartesian position of the model.
   */
  readonly position: Vector3;
  /**
   * The dimension of the model.
   */
  readonly dimensions: Dimension;
  /**
   * The rotation of the model based on X-axis.
   */
  readonly heading: number;

  /**
   * The rotation of the model based on Y-axis.
   */
  readonly pitch: number;

  /**
   * TThe rotation of the model based on Z-axis.
   */
  readonly roll: number;

  /**
   * The scale of the model.
   */
  readonly scale: Vector3;
}

export interface ESSCustomModelContent extends BaseContent {
  readonly type: ContentType.ESS_MODEL_CUSTOM;
  readonly category: ContentCategory.ESS;
  readonly info: BaseContent['info'] & {
    /**
     * The location of the model.
     */
    readonly location: GeoPoint;
    /**
     * Description to write on the model.
     */
    readonly description: string;
    /**
     * Whether this model's work radius visualization is enabled or not.
     */
    readonly isWorkRadiusVisEnabled: boolean;
    /**
     * Model's custom heading. User can define their own model heading.
     * Heading is in degrees.
     */
    readonly heading?: number;
    /**
     * Model's extra data. User can add extra information.
     */
    readonly miscMeta: MiscMeta;
  };
}

export interface ESSArrowContent extends BaseContent {
  readonly type: ContentType.ESS_ARROW;
  readonly category: ContentCategory.ESS;
  readonly info: BaseContent['info'] & {
    /**
     * The locations of each segment of the arrow.
     */
    readonly locations: GeoPoint[];
  };
}

export interface ESSPolygonContent extends BaseContent {
  readonly type: ContentType.ESS_POLYGON;
  readonly category: ContentCategory.ESS;
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
  };
}

export interface ESSPolylineContent extends BaseContent {
  readonly type: ContentType.ESS_POLYLINE;
  readonly category: ContentCategory.ESS;
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
  };
}

export interface ESSTextContent extends BaseContent {
  readonly type: ContentType.ESS_TEXT;
  readonly category: ContentCategory.ESS;
  readonly info: BaseContent['info'] & {
    readonly location: GeoPoint;
    readonly fontSize: number;
    readonly fontColor: Color;
    readonly description: string;
  };
}

export interface ESSLineTextContent extends BaseContent {
  readonly type: ContentType.ESS_LINE_TEXT;
  readonly category: ContentCategory.ESS;
  readonly info: BaseContent['info'] & {
    readonly locations: GeoPoint[];
    readonly fontSize: number;
    readonly fontColor: Color;
    readonly description: string;
    readonly borderColor: Color;
  };
}
export type ESSContent =
  | ESSModelContent
  | ESSCustomModelContent
  | ESSArrowContent
  | ESSPolygonContent
  | ESSPolylineContent
  | ESSTextContent
  | ESSLineTextContent
  | GroupContent;

export interface ESSContentsState {
  readonly createESSContentStatus: APIStatus;
  readonly createESSContentError?: HTTPError;
  readonly createESSGroupContentStatus: APIStatus;
  readonly createESSGroupContentError?: HTTPError;
  readonly patchESSContentStatus: APIStatus;
  readonly patchESSContentError?: HTTPError;
  readonly deleteESSContentStatus: APIStatus;
  readonly deleteESSContentError?: HTTPError;
}

export interface ProjectsState {
  readonly projects: {
    readonly byId: {
      readonly [id: number]: Project;
    };
    readonly allIds: Array<Project['id']>;
  };
  readonly getProjectsStatus: APIStatus;
  readonly postProjectStatus: APIStatus;
  readonly patchProjectStatus: APIStatus;
  readonly deleteProjectStatus: APIStatus;
  readonly getCalendarStatus: APIStatus;
  readonly getProjectStatus: APIStatus;
  readonly getCalendarError?: HTTPError;
  readonly getProjectError?: HTTPError;
  readonly getProjectsError?: HTTPError;
  readonly postProjectError?: HTTPError;
  readonly patchProjectError?: HTTPError;
  readonly deleteProjectError?: HTTPError;
}

export interface PermissionsState {
  readonly confirmDeletePermission?: Permission;
}

export interface ContentApiStatus {
  readonly status: APIStatus;
  readonly error?: HTTPError;
}

export enum ContentsListType {
  PERSONAL_MEASUREMENT = 'personal_measurement',
  OPEN_MEASUREMENT = 'open_measurement',
  OPEN_PRESENTATION = 'open_presentation',
  PERSONAL_OVERLAY = 'personal_overlay',
  OPEN_OVERLAY = 'open_overlay',
  PERSONAL_ESS = 'personal_ess',
  OPEN_ESS = 'open_ess',
  OPEN_TERRAIN = 'open_terrain',
}
export enum ContentsListESSType {
  PERSONAL_ESS = 'personal_ess',
  OPEN_ESS = 'open_ess',
}

export interface ContentsState {
  readonly contents: {
    readonly byId: {
      readonly [id: Content['id']]: Content;
    };
    readonly allIds: Array<Content['id']>;
  };
  readonly measurement: {
    readonly [contentId: MeasurementContent['id']]: string;
  };
  readonly uploadContents: {
    readonly [id: UploadContent['id']]: UploadContent;
  };
  readonly contentFilters: {
    readonly [filter in ContentsListType]: {
      filterText: string;
      filterContents: ContentType[];
    };
  };
  readonly getContentsStatus: APIStatus;
  readonly getInitialContentsStatus: APIStatus;
  readonly getIssuesStatus: APIStatus;
  readonly postContentStatus: APIStatus;
  readonly patchContentStatus: APIStatus;
  readonly deleteContentStatus: APIStatus;
  readonly printMapStatus: APIStatus;
  readonly dxf2RasterStatus: APIStatus;
  readonly getContentsError?: HTTPError;
  readonly getInitialContentsError?: HTTPError;
  readonly getIssuesError?: HTTPError;
  readonly postContentError?: HTTPError;
  readonly patchContentError?: HTTPError;
  readonly deleteContentError?: HTTPError;
  readonly printMapError?: HTTPError;
  readonly dxf2RasterError?: HTTPError;
  readonly runPotreeError?: HTTPError;
  readonly contentUploadError?: HTTPError;
  /**
   * @desc Please delete these properties. This should be in content.
   */
  readonly requestVolumeCalculation: {
    readonly [id: VolumeContent['id']]: ContentApiStatus;
  };
  readonly requestMarkerElevationInfo: {
    readonly [id: MarkerContent['id']]: ContentApiStatus;
  };
  readonly requestLengthElevationInfo: {
    readonly [id: LengthContent['id']]: ContentApiStatus;
  };
  readonly getLengthMetrics: {
    readonly [id: LengthContent['id']]: ContentApiStatus;
  };
  readonly getAreaSurface: {
    readonly [contentId: AreaContent['id']]: ContentApiStatus;
  };

  readonly outdatedVolumeIds: Array<VolumeContent['id']>;

  readonly requestLasDownSamplingStatus: APIStatus;
  readonly requestLasDownSamplingError?: HTTPError;
  readonly getContentDownloadablesStatus: APIStatus;
  readonly getContentDownloadablesError?: HTTPError;
  readonly lasDownSamplingStatus: Partial<
    Record<Screen['id'], { contentId: PointCloudContent['id']; status: LasDownSamplingStatus }>
  >;
  readonly contentDownloadables: Partial<
    Record<Screen['id'], Partial<Record<ResourceType, boolean>>>
  >;
  readonly requestLasReprocessingStatus: APIStatus;
  readonly requestLasReprocessingError?: HTTPError;
  readonly isDSMHillshadeMultiplyToggled?: boolean;
  readonly isDTMHillshadeMultiplyToggled?: boolean;
  readonly from2DTo3D360?: {
    isFrom2DTo3D360: boolean;
    photoId: number | undefined;
  };
  readonly threeSixty2DMapDetails?: {
    photoLongLat: GeoPoint;
    photoCameraOrientation: Vector3;
  };
  readonly dsmHeightsAndColors: DSMHeightAndColor[];
  readonly dsmMinHeight: number;
  readonly dsmMaxHeight: number;
  readonly dtmHeightsAndColors: DTMHeightAndColor[];
  readonly dtmMinHeight: number;
  readonly dtmMaxHeight: number;
  readonly getLonLatOn2D3DToggleStatus: APIStatus;
}

export interface ScreensState {
  readonly screens: Screen[];
  readonly getScreensStatus: APIStatus;
  readonly patchScreensStatus: APIStatus;
  readonly postScreensStatus: APIStatus;
  readonly deleteScreensStatus: APIStatus;

  readonly getScreensError?: HTTPError;
  readonly patchScreensError?: HTTPError;
  readonly postScreensError?: HTTPError;
  readonly deleteScreensError?: HTTPError;
}

export interface AttachmentsState {
  readonly attachments: {
    readonly byId: {
      readonly [id: Attachment['id']]: Attachment;
    };
    readonly allIds: Array<Attachment['id']>;
  };
  readonly processing_attachments: {
    readonly byId: {
      readonly [id: Attachment['id']]: Attachment;
    };
    readonly allIds: Array<Attachment['id']>;
  };
  readonly getAttachmentsStatus: {
    readonly [contentId: Attachment['id']]: ContentApiStatus;
  };
  readonly postAttachmentStatus: {
    readonly [contentId: Attachment['id']]: {
      readonly [hash: string]: AttachmentPostStatus;
    };
  };

  readonly postProcessingAttachmentStatus: {
    readonly [projectId: Attachment['id']]: {
      readonly [hash: string]: AttachmentPostStatus;
    };
  };
}

export interface ESSAttachmentsState {
  readonly attachments: {
    readonly byId: {
      readonly [id: Attachment['id']]: Attachment;
    };
    readonly allIds: Array<Attachment['id']>;
  };
  readonly getESSAttachmentsStatus: {
    readonly [contentId: Attachment['id']]: ContentApiStatus;
  };
  readonly postESSAttachmentStatus: {
    readonly [contentId: Attachment['id']]: {
      readonly [hash: string]: AttachmentPostStatus;
    };
  };
}

export interface SharedContentsState {
  readonly shareToken?: string;
  readonly showAt?: Date;
  readonly projectName: string;
  readonly projection: ProjectionEnum;
  readonly screenTitle: NonNullable<Screen['title']>;
  readonly navbarLogoUrl: string;
  readonly initialCameraPosition: Coordinate;
  readonly getSharedContentsStatus: APIStatus;
  readonly postShareRequestStatus: APIStatus;
  readonly getSharedContentsError?: HTTPError;
  readonly postShareRequestError?: HTTPError;
  readonly projectUnit?: UnitType;
}

// Model states for pages
export interface CommonPageState {
  readonly language: Language;
  readonly timezoneOffset: number; // Offset in minute
}

export interface FrontPageState {
  readonly tab: FrontPageTabType;
  readonly newPasswordToken?: string;
  readonly passwordFormValues: PasswordFormValues;
  readonly newPasswordFormValues: NewPasswordFormValues;
  readonly signUpFormValues: SignUpFormValues;
  readonly signUpFormDirties: SignUpFormDirties;
}

export interface ProjectPageState {
  readonly tab: ProjectPageTabType;
  readonly popup?: ProjectPagePopupType;
  readonly editingProjectId?: Project['id'];
  readonly myPageFormValues: MyPageFormValues;
  readonly isSignUpTutorialPopupHidden?: boolean;
}

/**
 * @fixme compare2Contents/compare4Contents and twoDDisplayMode have
 * redundant information
 */
export interface ContentsPageState {
  readonly projectId?: Project['id'];
  readonly in3D: boolean;

  readonly isInPresentationMode: boolean;

  readonly isInUploadIFC: boolean;
  readonly isOnWorkRadius: boolean;
  readonly isSketchMode: boolean;
  readonly in3DPointCloud: boolean;
  readonly currentPointCloudEngine: PointCloudEngine;
  readonly currentMeshEngine: MeshEngine;
  readonly loadingMesh: boolean;
  readonly hasThreeJsSupport: boolean;
  readonly showSidebar: boolean;
  readonly isTopbarShown: boolean;
  readonly editingContentId?: Content['id'];
  readonly deletingContentId?: Content['id'];
  readonly compare2: Compare2Contents;
  readonly compare4: Compare4Contents;
  readonly twoDDisplayMode: TwoDDisplayMode;
  readonly isInContentsEventLogTable: boolean;
  readonly isInSourcePhotoUpload: boolean;
  readonly isInFlightScheduleCreation: boolean;
  readonly currentContentTypeFromAnnotationPicker?:
    | MeasurementContent['type']
    | ESSWorkToolType
    | IssuePointContent['type']
    | IssuePhotoContent['type'];
  readonly previousContentTypeFromAnnotationPicker?:
    | MeasurementContent['type']
    | ESSWorkToolType
    | IssuePointContent['type']
    | IssuePhotoContent['type'];
  readonly isMeasurementClickedFromMap: boolean;
  readonly coordinate: olExtent | [];
  readonly twoDDisplayCenter: GeoPoint;
  readonly twoDDisplayZoom: number;
  readonly rotation: number;
  readonly sidebarTab: ContentPageTabType;
  readonly previousSidebarTab?: ContentPageTabType;
  readonly popup?: {
    readonly type: ContentPagePopupType;
    readonly contentId?: Content['id'];
    readonly hideBackButton?: boolean;
    readonly photoType?: PhotoType;
    readonly errorMessage?: string;
    readonly category?: ContentCategory;
  };
  readonly imageViewerStatus: {
    readonly contentId: Content['id'];
    readonly attachmentId: Attachment['id'];
  };
  readonly printingContentId?: Content['id'];
  readonly printingSquare?: Coordinate[];
  readonly printingAngle?: number;
  readonly isFirstVisit?: boolean;
  /**
   * @desc Using combination of (editingContentId, aligningBlueprintId)
   * to determine correct action of Blueprint: Edit or Align
   */
  readonly aligningBlueprintId?: BlueprintPDFContent['id'];
  readonly aligningBlueprintScratchpad?: Partial<BlueprintPDFContent['info']>;
  readonly mapHorizontalTabStatus: MapHorizontalTabStatus;

  readonly getLonLatOn2D3DToggleStatus: APIStatus;

  readonly mapPopup?: {
    readonly type: ContentPagePopupOnMapType;
  };

  readonly creatingVolumeInfo?: {
    readonly type?: VolumeCalcMethod;
    readonly designDxfId?: DesignDXFContent['id'];
    readonly previousDsmId?: DSMContent['id'];
    readonly isDrawing?: boolean;
  };

  readonly creatingGCPGroupInfo?: {
    readonly gcps: GCP[];
    readonly crs: ProjectionEnum;
  };
  readonly editingGCPIndex?: number;

  readonly previewingDesignId?: DesignDXFContent['id'];

  readonly isPreventAutoSelect?: boolean;

  readonly shouldUpdateTwoDDisplayZoom: boolean;
  readonly shouldUpdateTwoDDisplayCenter: boolean;
  readonly threeDTilesetBounds?: {
    readonly min: Cartesian3;
    readonly max: Cartesian3;
  };
}

export interface ScreenTree {
  readonly pinned: Array<Content['id']>;
  readonly unpinned: Record<Screen['id'], Array<Content['id']>>;
}

export interface SpaceTree {
  readonly personal: Array<Content['id']>;
  readonly open: Array<Content['id']>;
}

export interface GroupsState {
  readonly selectedGroupIdByTab: Record<ContentPageTabType, GroupContent['id'] | undefined>;
  readonly isCreatingNewGroup: boolean;
  readonly isGroupAlreadyDeleted: boolean;
  readonly isCreatingContentOnDeletedGroup: boolean;
  readonly tree: {
    readonly idsByGroup: Record<GroupContent['id'], Array<Content['id']>>;
    readonly rootIdsByCategory: Record<ContentCategory, ScreenTree>;
    readonly rootIdsBySpace: Record<ContentCategory, SpaceTree>;
  };
  readonly moveContentStatus: APIStatus;
  readonly moveContentError?: HTTPError;
}

export interface PagesState {
  readonly Common: CommonPageState;
  readonly Front: FrontPageState;
  readonly Project: ProjectPageState;
  readonly Contents: ContentsPageState;
}

export interface ProjectConfigPerUserState {
  readonly config?: ProjectConfig;
  readonly patchProjectConfigStatus: APIStatus;
  readonly patchProjectConfigError?: HTTPError;
}
export interface SelectedMedia {
  readonly type: PhotoType | ContentType | null;
  readonly contentId: Content['id'];
  readonly parentId?: Content['id'];
}
export interface PhotosState {
  readonly photos: FinalPhoto[];
  readonly droneVideos: FinalPhoto[];
  readonly currentPhotoId?: Photo['id'];
  readonly currentVideoId?: DroneVideo['id'];
  readonly photoOnMapId?: Photo['id'];
  readonly videoOnMapId?: DroneVideo['id'];
  readonly uploadedPhotoCount: number;
  readonly selectedMedia: SelectedMedia[];
  readonly tagPhotoIds: Array<Photo['id']>;
  readonly isDownloading: boolean;
  readonly dateType: PhotoDateType;
  readonly hoveredId?: Photo['id'];
  readonly hoveredVideoId?: DroneVideo['id'];
  readonly getPhotoStatus: APIStatus;
  readonly getDroneVidoeStatus: APIStatus;
  readonly bulkDeletePhotoStatus: APIStatus;
  readonly droneDate: Date | undefined;
  readonly selectedSourceAlbum?: Photo['id'];
}
export interface PhotoScreen {
  id: number;
  projectId: number;
  title: string;
  appearAt: string;
  createdAt: string;
  updatedAt: string;
  contentIds: number[];
  config: any;
}

export type PhotoType =
  | 'normal'
  | 'drone'
  | 'source'
  | 'inspection'
  | 'integration'
  | 'overlay'
  | 'three_sixty'
  | 'three_sixty_source'
  | 'three_sixty_stitched'
  | 'three_sixty_video'
  | 'viewpoint'
  | 'three_sixty_vslam';

export enum TimelapseCollectionTypes {
  BIRD_EYE_VIEW = 'integration',
  SOURCE = 'source',
  INSPECTION = 'drone',
}

export enum PhotoCollectionFilterTypes {
  ALL = 'all',
  OVERLAY = 'overlay',
  BIRD_EYE_VIEW = 'normal',
  INSPECTION = 'drone',
  SOURCE = 'source',
  THREE_SIXTY = 'three_sixty',
  THREE_SIXTY_SOURCE = 'three_sixty_source',
  THREE_SIXTY_STITCHED = 'three_sixty_stitched',
  THREE_SIXTY_VIDEO = 'three_sixty_video',
  VIEWPOINT = 'viewpoint',
  THREE_SIXTY_VSLAM = 'three_sixty_vslam',
}

export enum PhotoAlbumFilterTypes {
  ALL = 'all',
  BIRD_EYE_VIEW = 'bird_eye',
  INSPECTION = 'inspection',
  SOURCE = 'source',
  THREE_SIXTY = 'three_sixty',
  THREE_SIXTY_SOURCE = 'three_sixty_source',
  THREE_SIXTY_STITCHED = 'three_sixty_stitched',
  THREE_SIXTY_VIDEO = 'three_sixty_video',
  VIEWPOINT = 'viewpoint',
  THREE_SIXTY_VSLAM = 'three_sixty_vslam',
}

export interface LocationWithExcluded {
  timelapseLocationId: number;
  excluded: boolean;
}

export interface BasePhoto {
  readonly id: number;
  readonly originalFilename: string;
  readonly imageUrl: string;
  readonly thumbUrl: string;
  readonly fullUrl: string;
  readonly boxThumbUrl: string;
  readonly lowQualityUrl?: string;
  readonly midQualityUrl?: string;
  readonly geotags: PhotoGeotag | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly altitude: number | null;
  readonly direction: number | null;
  readonly takenBy: string | null;
  readonly uploadType?: string | null;
  readonly photoType?: PhotoType;
  readonly status?: PhotoProcessStatus;
  readonly locationsWithExcluded?: LocationWithExcluded[];
}
export interface Photo extends BasePhoto {
  dateString?: string | null;
  count?: number;
  isFolder?: boolean;
  readonly screenTitle?: string;
  readonly albumTitle?: string;
  readonly screenId?: number;
  readonly takenAt: Date | null | string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly contentId?: number;
  readonly isFromDroneStation?: boolean;
  readonly sphRecalibrated?: boolean;
  readonly viewpointPhotoId?: BasePhoto['id'];
}
export interface APIPhoto extends BasePhoto {
  takenAt: string;
  createdAt: string;
  updatedAt: string;
  screen: PhotoScreen;
}
export interface APIPhotoMeta extends BasePhoto {
  screenId: number;
  screenTitle: string;
  count: number;
  takenAt: string;
  viewpointAlbumId?: number;
}
export interface APIPhotoMeta extends BasePhoto {
  screenId: number;
  screenTitle: string;
  count: number;
  takenAt: string;
}

export interface PhotoAlbumbyId extends PhotoAlbum {
  photos: FinalPhoto[];
}

export enum RequestPhotoStatus {
  PENDING = 'pending',
  REQUESTED = 'requested',
  FAILED = 'failed',
  COMPLETED = 'completed',
  READY = 'ready',
}
export interface PhotoAlbum {
  id: number;
  title: string;
  type: PhotoAlbumFilterTypes;
  takenAt: Date | null;
  photosCount: number;
  count?: number;
  photoType?: PhotoType;
  contentId: number | null;
  status?: RequestPhotoStatus;
  requestedAt?: string;
}

export type FinalPhoto = Photo &
  DroneVideo &
  VSLAMVideo & {
    readonly flightLogUrl?: string;
    readonly viewpointAlbumId?: BasePhoto['id'];
  };

export interface RequestPhoto extends BasePhoto {
  readonly state?: string;
}

export interface BaseVideo {
  readonly id: number;
  readonly originalFilename: string;
  readonly uploadSource: 'application' | 'drone' | 'survey' | 'photo';
  readonly videoUrl: string;
  readonly videoThumbUrl: string;
  readonly thumbUrl: string;
  readonly geotags: PhotoGeotag | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly direction: number | null;
  readonly takenBy: string | null;
  readonly altitude: number | null;
  readonly type?: 'overlay';
}
export interface DroneVideo extends BaseVideo {
  readonly takenAt: Date | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly flightLogUrl?: string;
}
export interface VSLAMVideo extends BaseVideo {
  readonly takenAt: Date | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly videoId?: number;
  readonly floorPlan?: {
    id: number;
    type: 'floor_plan';
    fileUrl: string | null;
    videoId: number;
    status: ContentProcessingStatus;
    createdAt: Date | string;
    updatedAt: Date | string;
    contentId: number;
  };
}
export interface APIDroneVideo extends BaseVideo {
  readonly takenAt: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly flightLogUrl?: string;
}
export interface PhotoGeotag {
  readonly gpsLatitude: [number, number, number] | null;
  readonly gpsLongitude: [number, number, number] | null;
  readonly gpsAltitude: number | null;
  readonly gpsSpeed: number | null;
  readonly gpsImgDirection: number | null;
  readonly gpsDestBearing: number | null;

  readonly gpsLatitudeRef: string | null;
  readonly gpsLongitudeRef: string | null;
  readonly gpsAltitudeRef: number | null;
  readonly gpsSpeedRef: string | null;
  readonly gpsImgDirectionRef: string | null;
  readonly gpsDestBearingRef: string | null;

  readonly gpsDateStamp: string | null;

  readonly gimbalYawDegree: number | null;
  readonly gimbalPitchDegree: number | null;
  readonly gimbalRollDegree: number | null;
  readonly absoluteAltitude: number | null;
  readonly focalLength: string | null;
  readonly relativeAltitude: number | null;

  readonly imageWidth?: number;
  readonly imageHeight?: number;
  readonly calibratedFocalLength?: number;
  readonly make?: string | null;
}

// Merged model state
export interface State {
  readonly Auth: AuthState;
  readonly Users: UsersState;
  readonly PlanConfig: PlanConfigState;
  readonly Projects: ProjectsState;
  readonly Permissions: PermissionsState;
  readonly Contents: ContentsState;
  readonly Screens: ScreensState;
  readonly Attachments: AttachmentsState;
  readonly ESSAttachments: ESSAttachmentsState;
  readonly SharedContents: SharedContentsState;
  readonly ProjectConfigPerUser: ProjectConfigPerUserState;
  readonly Photos: PhotosState;
  readonly Groups: GroupsState;

  readonly Pages: PagesState;
  // readonly router: RouterState;
}
export interface SerialState extends DDMSESSIONObject {
  readonly version?: string;
  readonly authedUser?: State['Auth']['authedUser'];
  readonly automaticSignIn?: State['Auth']['automaticSignIn'];
}

export interface Extent {
  left: number;
  right: number;
  bottom: number;
  top: number;
}
export interface ExtentTileInfo {
  X: number;
  Y: number;
  Z: number;
  top: number;
  left: number;
}

export interface MapCenter {
  lon: number;
  lat: number;
  alt: number;
  rotation: number;
}

export const CHANGE_COORDS_ON_PROJECTIONSYSTEM_CHANGE: ContentType[] = [ContentType.MARKER];

export type MapTabTwoDContent = MapContent | DSMContent;
export type MapTabThreeDContent = ThreeDOrthoContent | ThreeDMeshContent | PointCloudContent;
export type MapTabContent = MapTabTwoDContent | MapTabThreeDContent;

export const MAP_TAB_THREE_D_CONTENTS: ContentType[] = [
  ContentType.POINTCLOUD,
  ContentType.THREE_D_ORTHO,
  ContentType.THREE_D_MESH,
];
export const MAP_TAB_TWO_D_CONTENTS: ContentType[] = [
  ContentType.MAP,
  ContentType.DSM,
  ContentType.DTM,
  // ContentType.GCP_GROUP,
];
export const MAP_TAB_CONTENTS: ContentType[] = [
  ...MAP_TAB_THREE_D_CONTENTS,
  ...MAP_TAB_TWO_D_CONTENTS,
];

export const MAP_TAB_CONTENTS_ORDER: { [key: string]: number } = {
  [ContentType.MAP]: 1,
  [ContentType.THREE_D_ORTHO]: 4,
  [ContentType.POINTCLOUD]: 5,
  [ContentType.DSM]: 2,
  [ContentType.DTM]: 3,
  [ContentType.THREE_D_MESH]: 6,
  [ContentType.GCP_GROUP]: 8,
};

export type MouseOrTouchEvent = MouseEvent | TouchEvent;
/*
 * @desc Location-related props between MouseEvent and Touch(TouchEvent.ChangedTouches: Touch[]).
 */
export interface MouseOrTouchEventLocation {
  clientX: number;
  clientY: number;
  pageX: number;
  pageY: number;
  screenX: number;
  screenY: number;
  // Alias of clientX/Y, in MouseEvent
  x?: number;
  y?: number;
  // MouseEvent-Only Props
  layerX?: number;
  layerY?: number;
  movementX?: number;
  movementY?: number;
  offsetX?: number;
  offsetY?: number;
  // TouchEvent-Only Props
  radiusX?: number;
  radiusY?: number;
}

export interface LocationOverlayUpdater<T> {
  getLonLat(param: T): Coordinate;
  getProjectCoordinate(param: T): Coordinate;
  setCoordXY(param: T): void;
}

export interface LocationOverlayUpdaterState {
  lastDebounceTimeout: number;
  isDebouncing: boolean;
  debounceTime: number;
}

export interface CesiumMovement {
  startPosition: Cartesian2;
  endPosition: Cartesian2;
}

export enum MarkerPinpointerLocation {
  LEFT_SIDEBAR = 'left-sidebar',
  MAP = 'map',
}
export enum PhotoProcessStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  PROCESSING = 'processing',
}

export const enum UserAgent {
  FIREFOX = 'FIREFOX',
  CHROME = 'CHROME',
  SAMSUNG_INTERNET = 'SAMSUNG_INTERNET',
  IE = 'IE',
  EDGE = 'EDGE',
  SAFARI = 'SAFARI',
  OPERA = 'OPERA',
  UNKNOWN = 'UNKNOWN',
}

export enum Device {
  MOBILE_S = 'MOBILE_S',
  MOBILE_L = 'MOBILE_L',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export enum PhotoAlbumType {
  INSPECTION = 'inspection',
  SOURCE = 'source',
  THREE_SIXTY = 'three_sixty',
  INTEGRATION = 'integration',
  NORMAL = 'normal',
  DRONE = 'drone',
  THREE_SIXTY_SOURCE = 'three_sixty_source',
  THREE_SIXTY_STITCHED = 'three_sixty_stitched',
  THREE_SIXTY_VIDEO = 'three_sixty_video',
  VIEWPOINT = 'viewpoint',
  THREE_SIXTY_VSLAM = 'three_sixty_vslam',
}

export const TIPPY_OFFSET: [number, number] = [0, 3];

export enum HotKey {
  MINUS = '-',
  PERIOD = '.',
  C = 'c',
  D = 'd',
  DELETE = 'Delete',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  TAB = 'Tab',
}

export enum ShortCut {
  CONTROL = 'Control',
  DELETE = 'Delete',
  D = 'd',
}

export enum TagName {
  INPUT = 'INPUT',
}

export enum ExcludedContentType {
  THERMAL = 'thermal',
}

export enum TimeDiffRange {
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export interface CSVLangHeaders {
  distance: string;
  MapDateOrDXFName: string;
}

export type Movable = Pick<
  Content,
  'id' | 'groupId' | 'screenId' | 'category' | 'type' | 'isPersonal'
>;

export interface DSMHeightAndColor {
  id: number;
  color: Color;
  height: number;
}
export interface DTMHeightAndColor {
  id: number;
  color: Color;
  height: number;
}

/**
 * Identifiers used for targeting content-related DOM elements
 * Used in components such as ContentsListItem and GroupedContentsHeader
 */
export enum ContentIdentifier {
  CLASSNAME = 'content-identifier',
  SELECTOR = '.content-identifier',
}

export enum ViewpointCaptureSteps {
  CAPTURING = 'capturing',
  WRITING_EXIF = 'writing_exif',
  UPLOADING = 'uploading',
  FETCHING_DATA = 'fetching_data',
}

export enum MyPageTabs {
  ACCOUNT = 'account',
  SECURITY = 'security',
  PLAN = 'plan',
  SETTINGS = 'settings',
}

export enum MyPagePopupContentTypes {
  CHANGE_NAME = 'CHANGE_NAME',
  CHANGE_PHONE = 'CHANGE_PHONE',
  CHANGE_ORGANIZATION = 'CHANGE_ORGANIZATION',
  CHANGE_PURPOSE = 'CHANGE_PURPOSE',
  CHANGE_PROFILE_IMAGE = 'CHANGE_PROFILE_IMAGE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  CHANGE_PLAN = 'CHANGE_PLAN',
  CHANGE_COUNTRY = 'CHANGE_COUNTRY',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
}
