import AuthMessageBox from '^/components/molecules/AuthMessageBox';
import { AuthMessageBoxLayout } from '^/components/molecules/AuthMessageBoxLayout';
import React from 'react';

import Text from './text';

import routes from '^/constants/routes';
import { UseL10n, useL10n } from '^/hooks';
import * as T from '^/types';

interface InvalidPageDisplayProps {
  httpErrorStatus?: T.HTTPError;
}

const InvalidPageDisplay = ({ httpErrorStatus }: InvalidPageDisplayProps) => {
  const [l10n]: UseL10n = useL10n();

  const { title, description } = (() => {
    switch (httpErrorStatus) {
      case T.HTTPError.CLIENT_UNAUTHORIZED_ERROR:
      case T.HTTPError.CLIENT_NOT_FOUND_ERROR: {
        return Text.errors[httpErrorStatus];
      }
      default: {
        return Text.errors.other;
      }
    }
  })();

  const buttonDetails = {
    text: l10n(Text.goToWorkspace),
    link: routes.project.main,
    isError: true,
  };

  return (
    <AuthMessageBoxLayout>
      <AuthMessageBox
        title={l10n(title)}
        showBackButton={false}
        showSupportEmail={true}
        buttonDetails={buttonDetails}
        description={l10n(description)}
      />
    </AuthMessageBoxLayout>
  );
};

export default InvalidPageDisplay;
