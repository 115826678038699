/* istanbul ignore file: only styled components */
import Color from 'color';
import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import styled from 'styled-components';

import palette from '^/constants/palette';
import dsPalette from '^/constants/ds-palette';

export interface BaseButtonProps {
  customColor?: string;
  isDisabled?: boolean;
}

export const SubmitButton = styled.button({
  width: '212px',
  height: '33px',
  borderRadius: '6px',
  backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),

  color: palette.buttonFontColor.toString(),
  fontSize: '11px',
  fontWeight: 500,

  cursor: 'pointer',
});

export const BaseButton = styled.button<BaseButtonProps>(({ isDisabled }) => ({
  minWidth: '90px',
  paddingLeft: '15px',
  paddingRight: '15px',
  height: '48px',
  borderRadius: '6px',

  fontSize: '15px',
  fontWeight: 'bold',

  cursor: isDisabled ? 'default' : 'pointer',
  opacity: isDisabled ? '0.6' : '1',
}));

/**
 * Confirm button takes in a custom color prop.
 * Ideally this would not be the case since buttons should follow the DS palette.
 * However, in the customized plan login, user can set their own color.
 */
export const ConfirmButton = styled(BaseButton)(({ customColor, isDisabled }) => {
  if (customColor) {
    const colorInstance: Color = new Color(customColor);

    return {
      backgroundColor: colorInstance.toString(),
      color: palette.white.toString(),

      ':hover': isDisabled
        ? undefined
        : {
            backgroundColor: colorInstance.lighten(0.2).toString(),
          },
    };
  }

  return {
    backgroundColor: 'var(--color-theme-primary)',
    color: palette.white.toString(),

    ':hover': isDisabled
      ? undefined
      : {
          backgroundColor: 'var(--color-theme-primary-lightest)',
        },
  };
});

export const DeleteButton = styled(BaseButton)(({ isDisabled }) => ({
  backgroundColor: palette.error.toString(),
  color: palette.white.toString(),
  ':hover': isDisabled
    ? undefined
    : {
        backgroundColor: palette.error.lighten(0.1).toString(),
      },
}));

export const CancelButton = styled(BaseButton)(({ isDisabled }) => ({
  backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),
  color: palette.buttonFontColor.toString(),
  ':hover': isDisabled
    ? undefined
    : {
        backgroundColor: palette.UploadPopup.hoverGray.toString(),
      },

  ':disabled': {
    color: palette.disabledFont.toString(),
    backgroundColor: palette.iconDisabled.toString(),
  },
}));

/**
 * According to mockup https://www.figma.com/file/JV8evx9HcLsfKGrJjLhdH3/Done_Plan?node-id=0%3A1
 * The buttons on the customized login/signup/password page is different from the normal login page.
 * Ideally, normal or customized login page should use the same button, but
 * we haven't got into that phase yet. For now, having these two buttons will do.
 */
export const NonAuthNormalConfirmButton = styled(ConfirmButton)({
  width: '100%',
  height: '48px',
  fontSize: '16px',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NonAuthPlanConfirmButton = styled(ConfirmButton)({
  width: '100%',
  height: '37px',
  fontSize: '13px',
  marginTop: '30px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const CustomBaseButton = styled.button<{ isSelected: boolean }>(({ isSelected }) => ({
  width: '100%',
  padding: '3px 6px',
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'center',
  justifyItems: 'center',
  alignItems: 'center',
  gap: '10px',
  color: isSelected ? dsPalette.themePrimary.toString() : palette.white.toString(),
  marginTop: '10px',
  backgroundColor: isSelected ? 'transparent' : dsPalette.themePrimary.toString(),
  border: `solid 2px ${dsPalette.themePrimary.toString()}`,
  cursor: 'pointer',
  fontSize: '14px',
  ':hover': {
    backgroundColor: isSelected
      ? dsPalette.themePrimary.toString()
      : dsPalette.themePrimary.lighten(0.2).toString(),
    color: palette.white.toString(),
  },
  ':disabled': {
    opacity: '0.5',
    cursor: 'not-allowed',
  },
}));

type Props = BaseButtonProps & {
  disabled?: boolean;
  onClick?: MouseEventHandler;
};

export const NonAuthConfirmButton: FC<PropsWithChildren<Props>> = props =>
  props.customColor ? (
    <NonAuthPlanConfirmButton {...props} />
  ) : (
    <NonAuthNormalConfirmButton {...props} />
  );
