import { create } from 'zustand';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export interface TimeStamp {
  start?: number;
  end?: number;
}

declare global {
  interface Window {
    OBC: any;
  }
}

export interface IFCLoadedProps {
  contentId: string | number;
  fileSize: number;
  show: boolean;
  showModal: boolean;
}

export interface IFCFileStore {
  progress: number;
  scene: THREE.Scene;
  threeDiv: HTMLDivElement | null;
  camera: THREE.PerspectiveCamera | null;
  orbit: OrbitControls | null;
  ifcLoader: any | null;
  components: any | null;
  ifcModel: THREE.Object3D | null;
  startTimeStamp: number;
  endTimeStamp: number;

  setProgress(progress: number): void;

  setIFCModel(ifcModel: any | null): void;
  setThreeDivElement(threeDiv: HTMLDivElement | null): void;
  setStartTimeStamp(start: number): void;
  setEndTimeStamp(end: number): void;
  resetScene(): void;

  ifcLoadedContent: IFCLoadedProps[];
  largeFileContent: IFCLoadedProps | null;
  setifcLoadedContent(ifcLoadedContent: IFCLoadedProps[]): void;
  setLargeFileContent(largeFileContent: IFCLoadedProps | null): void;
}

export const useIFCFileStore = create<IFCFileStore>()((set, get) => ({
  progress: 0,
  scene: new THREE.Scene(),
  threeDiv: null,
  camera: null,
  orbit: null,
  ifcLoader: null,
  ifcModel: null,
  startTimeStamp: 0,
  endTimeStamp: 0,
  components: null,
  ifcLoadedContent: [],
  largeFileContent: null,

  resetScene() {
    const { scene, ifcModel } = get();

    if (scene && ifcModel) {
      scene.remove(ifcModel);
    }
  },

  setifcLoadedContent(ifcLoadedContent: IFCLoadedProps[]) {
    set({ ifcLoadedContent });
  },

  setLargeFileContent(largeFileContent: IFCLoadedProps | null) {
    set({ largeFileContent });
  },

  setProgress(progress: number) {
    set({ progress });
  },
  setStartTimeStamp(startTimeStamp: number) {
    set({ startTimeStamp });
  },

  setEndTimeStamp(endTimeStamp: number) {
    set({ endTimeStamp });
  },

  setIFCModel(ifcModel: any) {
    set({ ifcModel });
  },

  setThreeDivElement(ref: HTMLDivElement) {
    set({ threeDiv: ref });
  },
}));
