/* eslint-disable max-lines */
import React, { FC, ReactElement, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import DSMSVG from '^/assets/icons/contents-list/elevation-map.svg';
import ESSSVG from '^/assets/icons/contents-tab-bar/ess.svg';
import IssueSVG from '^/assets/icons/contents-tab-bar/issue.svg';
import MapSVG from '^/assets/icons/contents-tab-bar/map.svg';
import MeasurementSVG from '^/assets/icons/contents-tab-bar/measurement.svg';
import OverlaySVG from '^/assets/icons/contents-tab-bar/overlay.svg';
import PhotoSVG from '^/assets/icons/contents-tab-bar/photo-1.svg';
import DroneStationSVG from '^/assets/icons/contents-tab-bar/tab-drone-station.svg';
import FlightPlanSVG from '^/assets/icons/contents-tab-bar/tab-flight-plan.svg';
import FlightScheduleSVG from '^/assets/icons/contents-tab-bar/tab-flight-schedule.svg';
import ViewpointSVG from '^/assets/icons/contents-tab-bar/viewpoint.svg';
import UploadSVG from '^/assets/icons/contents-tab-bar/upload.svg';
import { Fallback } from './fallback';
import Text from './text';

import { UnleashFlagProvider } from '^/components/atoms/UnleashFeatureProvider';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import { useChannelIO } from '^/hooks/channelIO';
import { useAuthedUser } from '^/hooks/useAuthedUser';
import { usePopupStore } from '^/store/popup';
import { useServiceTabStore } from '^/store/serviceTabStore';
import * as T from '^/types';
import { isPhoneOrTablet } from '^/utilities/device';
import { exhaustiveCheck } from '^/utilities/exhaustive-check';
import { l10n } from '^/utilities/l10n';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { useSelector } from 'react-redux';
import { IconButtonWithPopover } from '../IconButtonWithPopover';
import { LogoTab } from '../LogoTab';

export const flightTabsLists = [
  T.ContentPageTabType.FLIGHT_SCHEDULE,
  T.ContentPageTabType.FLIGHT_PLAN,
  T.ContentPageTabType.DRONE_STATION,
];
export const getSidebarTabsByFeature = (
  features: T.PermissionFeature,
  sidebarTab?: T.ContentPageTabType,
  integrations?: T.ProjectIntegrations,
  isInternalUser?: boolean
) => {
  const tabs: T.ContentPageTabType[] = [];

  if (!sidebarTab) {
    return [];
  }

  if (flightTabsLists.includes(sidebarTab)) {
    if (features.droneStation) {
      tabs.push(
        T.ContentPageTabType.DRONE_STATION,
        T.ContentPageTabType.FLIGHT_PLAN,
        T.ContentPageTabType.FLIGHT_SCHEDULE
      );
    }
  } else {
    tabs.push(T.ContentPageTabType.MAP);

    tabs.push(
      T.ContentPageTabType.OVERLAY,
      T.ContentPageTabType.MEASUREMENT,
      T.ContentPageTabType.ISSUE,
      T.ContentPageTabType.PHOTO
    );

    if (features.ess) {
      tabs.push(T.ContentPageTabType.ESS);
    }

    if (isInternalUser) {
      tabs.push(T.ContentPageTabType.DASHBOARD);
    }
    tabs.push(T.ContentPageTabType.VIEWPOINT_CAPTURE);
  }

  return tabs;
};

export const Root = styled.aside<{ isMobile?: boolean }>(({ isMobile }) => ({
  position: 'relative',
  zIndex: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  width: '100%',
  height: isMobile ? 'calc(100% - 100px)' : '100%',

  backgroundColor: palette.SideBar.background.toString(),
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
}));

export const tabItemStyle: CSSObject = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '44px',
  margin: '8px',
  borderRadius: '5px',
};

const UploadSupportContainer = styled.section({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const tabIconMap: Partial<Record<T.ContentPageTabType, ReactElement>> = {
  [T.ContentPageTabType.MAP]: <MapSVG />,
  [T.ContentPageTabType.OVERLAY]: <OverlaySVG />,
  [T.ContentPageTabType.MEASUREMENT]: <MeasurementSVG />,
  [T.ContentPageTabType.PHOTO]: <PhotoSVG />,
  [T.ContentPageTabType.ESS]: <ESSSVG />,
  [T.ContentPageTabType.ISSUE]: <IssueSVG />,
  [T.ContentPageTabType.DASHBOARD]: <DSMSVG />,
  [T.ContentPageTabType.DRONE_STATION]: <DroneStationSVG />,
  [T.ContentPageTabType.FLIGHT_PLAN]: <FlightPlanSVG />,
  [T.ContentPageTabType.FLIGHT_SCHEDULE]: <FlightScheduleSVG />,
  [T.ContentPageTabType.VIEWPOINT_CAPTURE]: <ViewpointSVG />,
};

/**
 * @desc Retrieving Tooltip text regarding to the Tab Type
 */
function tabToTooltip(tabType: T.ContentPageTabType, language: T.Language): string {
  /* istanbul ignore next */
  switch (tabType) {
    case T.ContentPageTabType.MAP:
      return l10n(Text.tooltipMap, language);
    case T.ContentPageTabType.OVERLAY:
      return l10n(Text.tooltipOverlay, language);
    case T.ContentPageTabType.MEASUREMENT:
      return l10n(Text.tooltipMeasurement, language);
    case T.ContentPageTabType.PHOTO:
      return l10n(Text.tooltipPhoto, language);
    case T.ContentPageTabType.ESS:
      return l10n(Text.tooltipESS, language);
    case T.ContentPageTabType.ISSUE:
      return l10n(Text.tooltipIssue, language);
    case T.ContentPageTabType.DASHBOARD:
      return l10n(Text.tooltipDashboard, language);
    case T.ContentPageTabType.DRONE_STATION:
      return l10n(Text.tooltipDroneStation, language);
    case T.ContentPageTabType.FLIGHT_PLAN:
      return l10n(Text.tooltipFlightPlan, language);
    case T.ContentPageTabType.FLIGHT_SCHEDULE:
      return l10n(Text.tooltipFlightSchedule, language);
    case T.ContentPageTabType.PRESENTATION:
      return l10n(Text.tooltipPresentation, language);
    case T.ContentPageTabType.VIEWPOINT_CAPTURE:
      return l10n(Text.tooltipViewpointCapture, language);
    default:
      return exhaustiveCheck(tabType);
  }
}

export interface Props {
  readonly currentTab: T.ContentPageTabType;
  readonly role: T.PermissionRole;
  readonly printingContentId: T.ContentsPageState['printingContentId'];
  readonly squareLogoUrl?: T.PlanConfig['squareLogoUrl'];
  readonly has3DView: boolean;
  readonly userFeaturePermission: T.User['featurePermission'];
  readonly features: T.PermissionFeature;
  readonly integrations?: T.ProjectIntegrations;
  onTabClick(tab: T.ContentPageTabType): void;
  onUploadClick(role: T.PermissionRole): void;
  onLogoClick(): void;
  preventAutoSelect(): void;
}

/**
 * Component for sidebar tab of contents page
 */
const ContentsTabbar: FC<Props & L10nProps> = ({
  printingContentId,
  features,
  integrations,
  has3DView,
  language,
  currentTab,
  role,
  onTabClick,
  preventAutoSelect,
  onUploadClick,
}) => {
  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const selectedServiceTab = useServiceTabStore(s => s.selectedServiceTab);
  const { isInternalUser } = useAuthedUser();

  const popupStore = usePopupStore();
  const { service } = useChannelIO();

  const isESSCTAButtonShown: boolean =
    selectedServiceTab !== T.ContentPageServiceTabType.FLIGHT &&
    !features.ess &&
    language === T.Language.KO_KR;

  const isDisabled: (tabType?: T.ContentPageTabType) => boolean = tabType => {
    if (printingContentId) {
      return tabType !== T.ContentPageTabType.OVERLAY;
    }

    if (tabType === T.ContentPageTabType.ESS) {
      if (!features.ess) {
        return true;
      }
      return !has3DView;
    }

    return false;
  };

  const BETA_FEATURES = [T.ContentPageTabType.VIEWPOINT_CAPTURE];

  const renderTabItem = (
    tabType: T.ContentPageTabType,
    tooltipContent: string,
    currentTabb: T.ContentPageTabType,
    disabled: boolean,
    handleTabButtonClick: () => void
  ) => (
    <IconButtonWithPopover
      key={tabType}
      isSelected={currentTabb === tabType}
      onClick={handleTabButtonClick}
      popoverContent={tooltipContent}
      trackAction="change-tab"
      trackLabel={`btn-tab-${tabType.toLowerCase()}`}
      isDisabled={disabled}
      dataTestId={`tab-btn-${tabType.toLowerCase()}`}
      showBeta={BETA_FEATURES.includes(tabType)}
    >
      {tabIconMap[tabType]}
    </IconButtonWithPopover>
  );

  const tabToButton: (tabType: T.ContentPageTabType) => ReactNode = tabType => {
    const disabled = isDisabled(tabType);
    const handleTabButtonClick: () => void = () => {
      if (disabled) {
        return;
      }

      onTabClick(tabType);
      preventAutoSelect();
    };

    const tooltipContent: string = `${tabToTooltip(tabType, language)}${
      isDisabled(tabType) ? ` ${l10n(Text.tooltipDisabled, language)}` : ''
    }`;

    if (tabType === T.ContentPageTabType.DASHBOARD) {
      return (
        <UnleashFlagProvider
          key={tabType}
          flagName="earthwork-dashboard"
          render={({ isEnabled }) =>
            isEnabled
              ? renderTabItem(tabType, tooltipContent, currentTab, disabled, handleTabButtonClick)
              : null
          }
        />
      );
    }

    if (tabType === T.ContentPageTabType.VIEWPOINT_CAPTURE) {
      return (
        <UnleashFlagProvider
          key={tabType}
          flagName={T.FEATURE_FLAGS.V2B_TAB}
          render={({ isEnabled }) =>
            isEnabled
              ? renderTabItem(tabType, tooltipContent, currentTab, disabled, handleTabButtonClick)
              : null
          }
        />
      );
    }

    return renderTabItem(tabType, tooltipContent, currentTab, disabled, handleTabButtonClick);
  };

  function ESSCTAButton(): JSX.Element {
    function handleESSCTAButtonClick() {
      service.track('marketing-ess');
      popupStore.setMinorPopup(T.ContentPageMinorPopupType.ESS_CAMPAIGN);
    }

    return (
      <IconButtonWithPopover
        popoverContent={l10n(Text.tooltipESSMKT, language)}
        onClick={handleESSCTAButtonClick}
        dataTestId="ess-cta-btn"
      >
        <ESSSVG />
      </IconButtonWithPopover>
    );
  }

  const handleUpload = (): void => {
    if (printingContentId) {
      return;
    }
    onUploadClick(role);
  };

  const allTabs: T.ContentPageTabType[] = getSidebarTabsByFeature(
    features,
    sidebarTab,
    integrations,
    isInternalUser
  );

  const upload: ReactNode = (
    <IconButtonWithPopover
      popoverContent={l10n(Text.tooltipUpload, language)}
      onClick={handleUpload}
      trackAction={T.TrackActions.MAP_UPLOAD}
      trackLabel={T.TrackLabels.BTN_CONTENT_UPLOAD}
      customStyles={{
        border: '2px solid var(--color-theme-primary)',
        backgroundColor: 'white',
        borderRadius: '4px',
      }}
      dataTestId="upload-btn"
    >
      <UploadSVG />
    </IconButtonWithPopover>
  );

  return (
    <Root isMobile={isPhoneOrTablet()}>
      <section>
        <LogoTab />
        {allTabs.map(tabToButton)}
        {isESSCTAButtonShown ? <ESSCTAButton /> : null}
      </section>
      <UploadSupportContainer>{upload}</UploadSupportContainer>
    </Root>
  );
};

export default withL10n(withErrorBoundary(ContentsTabbar)(Fallback));
