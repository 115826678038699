import React from 'react';
import styled from 'styled-components';

import dsPalette from '^/constants/ds-palette';
import { EmptyAlbumCollectionItem, AlbumCollectionFilter, AlbumCollectionItem } from './Components';
import { PhotoContainer, PhotoListWrapper } from '../PhotoList/style';
import SkeletonLoaderImages from './SkeletonLoaderImages';
import { usePhotoAlbumQuery } from '^/hooks/api/photos/usePhoto';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import { PhotoAlbum, PhotoAlbumFilterTypes } from '^/types';

const Root = styled(PhotoContainer)({
  maxHeight: 'calc(100vh - 200px)',
  padding: '0 15px 3.188rem 15px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
});

const FilterWrapper = styled.div({
  position: 'sticky',
  top: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-end',
  gap: '10px',
  flexWrap: 'wrap',
  padding: '20px 0px',
  backgroundColor: dsPalette.white.toString(),
  zIndex: 1,
});

const SkeletonLoaderWrapper = styled(PhotoListWrapper)({});

const PhotoAlbumCollectionList: React.FC = () => {
  const loaderCount = 20;

  const { photoAlbums, isLoading } = usePhotoAlbumQuery();
  const photoFilter = useTimelapseStore(s => s.photoFilter);

  //   const sortedlists = useMemo(() => {
  //     if (!timelapseCollections) {
  //       return [];
  //     }
  //     return timelapseCollections.sort((a, b) => {
  //       if (!a.photoCount || !b.photoCount) {
  //         return -1;
  //       }

  //       return b.photoCount - a.photoCount;
  //     });
  //   }, [timelapseCollections]);

  //   useEffect(() => {
  //     if (isError) {
  //       toastify({
  //         type: T.Toast.ERROR,
  //         content: {
  //           title: Text.photo.error.title,
  //           description: Text.photo.error.description,
  //         },
  //         option: defaultToastErrorOption,
  //       });
  //       setAlbumCollectionsStatus(T.APIStatus.IDLE);
  //     }
  //   }, [isError]);

  const filterPhotos = (photo: PhotoAlbum) => {
    if (photoFilter === PhotoAlbumFilterTypes.ALL) {
      return true;
    }
    return photo.type === photoFilter;
  };

  return (
    <Root>
      <FilterWrapper>
        <AlbumCollectionFilter />
      </FilterWrapper>
      {isLoading ? (
        <SkeletonLoaderWrapper>
          <SkeletonLoaderImages count={loaderCount} />
        </SkeletonLoaderWrapper>
      ) : photoAlbums.length ? (
        <PhotoListWrapper>
          {photoAlbums.filter(filterPhotos).map((albumCollection, i) => (
            <AlbumCollectionItem key={`album_collection_${i}`} albumCollection={albumCollection} />
          ))}
        </PhotoListWrapper>
      ) : (
        <EmptyAlbumCollectionItem />
      )}
    </Root>
  );
};

export default PhotoAlbumCollectionList;
