import { autobind } from 'core-decorators';
import { Material } from 'three';
import { ColorUtils } from '../Utils';

export class MaterialPool {
  private readonly pool: Map<string, Material> = new Map();
  private readonly baseMaterial: Material;
  public constructor(baseMaterial: Material) {
    this.baseMaterial = baseMaterial;
  }
  @autobind
  public getMaterial(colorString: string, opacity: number = 1): Material {
    const colorStringWAlpha = ColorUtils.addAlpha(colorString, opacity);
    if (!this.pool.has(colorStringWAlpha)) {
      const cloneMaterial = this.baseMaterial.clone();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      cloneMaterial.color.set(colorString);
      if (opacity !== 1) {
        cloneMaterial.opacity = opacity;
        cloneMaterial.transparent = true;
      }
      this.pool.set(colorStringWAlpha, cloneMaterial);
    }
    return this.pool.get(colorStringWAlpha)!;
  }

  @autobind
  public reset() {
    this.pool.forEach(v => v.dispose());
    this.pool.clear();
  }
}
