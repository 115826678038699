import { create } from 'zustand';

type LoadingMessageType = 'loading' | 'loadingEss' | 'downloading' | '';

export interface ThreeModelStore {
  isTransformModel: boolean;
  isLocked: boolean;
  isLoadingIFC: boolean;
  loadingMessage: LoadingMessageType;
  setIsLocked(value: boolean): void;
  setIsLoadingIFC(value: boolean): void;
  setIsTransformModel(value: boolean): void;
  setLoadingMessage(value: LoadingMessageType): void;
  resetModelStore(): void;
}
const initialState = {
  isTransformModel: false,
  isLocked: true,
  isLoadingIFC: false,
  loadingMessage: 'loading' as LoadingMessageType,
};
export const useThreeModelStore = create<ThreeModelStore>(set => ({
  ...initialState,
  setIsLocked: (value: boolean) => set(() => ({ isLocked: value })),
  setIsLoadingIFC: (value: boolean) => set(() => ({ isLoadingIFC: value })),
  setIsTransformModel: (value: boolean) => set(() => ({ isTransformModel: value })),
  setLoadingMessage: (value: LoadingMessageType) => set(() => ({ loadingMessage: value })),
  resetModelStore: () => set(initialState),
}));
