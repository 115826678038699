import styled from 'styled-components';

export interface Props {
  faNames: string | string[];
  fontSize?: string;
  color?: string;
  className?: string;
}

const FaIcon = styled.i.attrs<Props>(({ faNames }) => ({
  className: Array.isArray(faNames)
    ? `fa ${faNames.map(faName => `fa-${faName}`).join(' ')}`
    : `fa fa-${faNames}`,
}))<Props>(({ fontSize, color }) => ({
  fontSize,
  color,
}));

FaIcon.displayName = 'FaIcon';
export default FaIcon;
