import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';

import DeleteSvg from '^/assets/icons/delete.svg';
import palette from '^/constants/palette';
import { Earthwork } from '^/store/earthwork/types';
import { useDispatch } from 'react-redux';
import { OpenContentDeletingConfirmPopup } from '^/store/duck/Pages';
import * as T from '^/types';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';

export interface Props {
  readonly earthwork: Earthwork;
  readonly isDisabled: boolean;
}

const DeleteSvgWrapper = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  ' > svg > path': {
    fill: isDisabled ? palette.iconDisabled.toString() : '',
  },
}));
DeleteSvgWrapper.displayName = 'DeleteSvgWrapper';

const DeleteEarthworkButton: FC<Props> = ({ earthwork, isDisabled }) => {
  const dispatch = useDispatch();
  const { setDeletingEarthworkId } = useEarthWorkStore();

  const handleDeleteContent: (e: MouseEvent<HTMLOrSVGElement>) => void = e => {
    if (isDisabled) {
      return;
    }
    e.stopPropagation();
    setDeletingEarthworkId(earthwork.id);
    dispatch(
      OpenContentDeletingConfirmPopup({
        popup: T.ContentPagePopupType.DELETE_EARTHWORK_CONFIRM,
        contentId: earthwork.id,
      })
    );
  };

  return (
    <DeleteSvgWrapper isDisabled={isDisabled}>
      <DeleteSvg data-testid="delete-svg-button" onClick={handleDeleteContent} />
    </DeleteSvgWrapper>
  );
};

export default DeleteEarthworkButton;
