/* eslint-disable max-lines */
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { newsortPhotosByDate } from '^/components/molecules/PhotoList/util';
import { useL10n } from '^/hooks/useL10n';
import {
  getRequestErrorType,
  makeAuthHeader,
  makeV2APIURL,
  makeVersionHeader,
} from '^/store/duck/API';
import { CloseContentPagePopup } from '^/store/duck/Pages';
import {
  FinishDeleteBulkPhotos,
  SetCurrentPhotoId,
  SetSelectedMedia,
  StartDeleteBulkPhotos,
} from '^/store/duck/Photos';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import * as T from '^/types';
import {
  AlbumMapType,
  fetchPhotoAlbums,
  fetchPhotoAlbumsById,
  FormattedPhotoAlbumResponse,
  groupPhotoAlbums,
  photoService,
} from './services';
import { queryClient } from '^/store/react-query';
import { useFlag } from '@unleash/proxy-client-react';

export const PhotoKeys = {
  getPhotoAlbumKey: (projectId: number) => ['projects', projectId, 'photo_albums'],
  getNonPhotoAlbumKey: (projectId: number) => ['projects', projectId, 'photos'],
  getVideoKey: (projectId: number) => ['projects', projectId, 'videos'],
  getPhotoAlbumById: (albumId: number) => ['photo_albums', albumId],
  photoAlbumQuery: (projectId: number) => ['photoAlbums', projectId],
  sourcePhotoQuery: (projectId: number, screenId: number) => [
    'projects',
    projectId,
    'photos',
    'screen',
    screenId,
  ],
};

const commonQueryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export function usePhotoQuery() {
  const params = useParams();
  const projectId = Number(params.id);

  //   const photoURL: string = makeV2APIURL('projects', projectId, 'photo_albums');
  const nonAlbumPhotoURL: string = makeV2APIURL('projects', projectId, 'photos');
  //   const nonAlbumPhotoURL: string = makeV2APIURL('projects', projectId, 'photos', '?type=non_album');
  const videoURL: string = makeV2APIURL('projects', projectId, 'videos');
  const photoCollectionFilter = useTimelapseStore(s => s.photoCollectionFilter);
  const isVslamIndoorFeatureAvailable = useFlag(T.FEATURE_FLAGS.VSLAM_INDOOR);

  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const authHeader = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();
  const [, lang] = useL10n();

  const results = useQueries({
    queries: [
      //   {
      //     queryKey: PhotoKeys.getPhotoAlbumKey(projectId),
      //     queryFn: async () => fetchPhotoAlbums(photoURL, authHeader!, versionHeader, 'photo_album'),
      //     enabled: Boolean(projectId) && Boolean(authHeader),
      //     ...commonQueryOptions,
      //   },
      {
        queryKey: PhotoKeys.getNonPhotoAlbumKey(projectId),
        queryFn: async () =>
          fetchPhotoAlbums(nonAlbumPhotoURL, authHeader!, versionHeader, 'photo'),
        enabled: Boolean(projectId) && Boolean(authHeader),
        ...commonQueryOptions,
      },
      {
        queryKey: PhotoKeys.getVideoKey(projectId),
        queryFn: async () => fetchPhotoAlbums(videoURL, authHeader!, versionHeader, 'video'),
        enabled: Boolean(projectId) && Boolean(authHeader),
        ...commonQueryOptions,
      },
    ],
  });

  // Extract data from queries
  //   const [photoAlbumsQuery, nonAlbumPhotosQuery, videosQuery] = results;
  const [nonAlbumPhotosQuery, videosQuery] = results;

  const isLoading = nonAlbumPhotosQuery.isLoading || videosQuery.isLoading;
  // const isLoading =
  //   photoAlbumsQuery.isLoading || nonAlbumPhotosQuery.isLoading || videosQuery.isLoading;
  const isError = nonAlbumPhotosQuery.isError || videosQuery.isError;

  //   const photoAlbums = (photoAlbumsQuery.data || []) as T.PhotoAlbum[];
  const rawPhotos = (nonAlbumPhotosQuery.data || []) as T.FinalPhoto[];
  const rawVideos = (videosQuery.data || []) as T.FinalPhoto[];

  const filteredPhotosForVslam = isVslamIndoorFeatureAvailable
    ? rawPhotos
    : rawPhotos.filter(photo => photo.photoType !== T.PhotoAlbumType.THREE_SIXTY_VSLAM);
  const filteredVideosForVslam = isVslamIndoorFeatureAvailable
    ? rawVideos
    : rawVideos.filter(video => video.photoType !== T.PhotoAlbumType.THREE_SIXTY_VIDEO);

  // Merge both datasets
  const combinedPhotos = [...filteredPhotosForVslam, ...filteredVideosForVslam];
  const filteredPhotos =
    photoCollectionFilter === T.PhotoCollectionFilterTypes.ALL
      ? combinedPhotos
      : combinedPhotos.filter(photo => photo.photoType === photoCollectionFilter);
  //   const combinedPhotos = [...photoAlbums, ...rawPhotos, ...rawVideos];
  // Group the merged data
  //   const oldAlbum = sortPhotosByDate(combinedPhotos, l10n, lang);
  const { photoMap, photosByType } = newsortPhotosByDate(filteredPhotos, lang);
  //   const groupedAlbums = groupPhotoAlbums(combinedPhotos, lang);
  const nonDatePhotos = combinedPhotos.filter(photo => photo.takenAt === null);
  const birdEyeLinkViewPointPhotos = combinedPhotos.filter(photo =>
    Boolean(photo.viewpointPhotoId)
  );

  return {
    groupedAlbums: photoMap,
    isLoading,
    isError,
    rawPhotos: filteredPhotosForVslam,
    rawVideos: filteredVideosForVslam,
    nonDatePhotos,
    photosByType,
    birdEyeLinkViewPointPhotos,
  };
  //   return { groupedAlbums, isLoading, isError, rawPhotos, rawVideos, photoAlbums, nonDatePhotos };
}

export const usePhotoAlbumIdQuery = (
  photoAlbumId?: number
): {
  photosFromAlbums: AlbumMapType;
  photos: T.FinalPhoto[];
  albumInfo: T.PhotoAlbum;
  isLoading: boolean;
  isError: boolean;
} => {
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const authHeader = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();
  const [, lang] = useL10n();
  const photoAlbumByIdURL: string = makeV2APIURL('photo_albums', photoAlbumId!);

  const result = useQuery({
    queryKey: PhotoKeys.getPhotoAlbumById(photoAlbumId!),
    queryFn: async () => fetchPhotoAlbumsById(photoAlbumByIdURL, authHeader!, versionHeader),
    enabled: Boolean(photoAlbumId) && Boolean(authHeader),
    ...commonQueryOptions,
  });

  const photosQueryData = result.data?.photos || [];
  const albumInfo = result.data?.albumInfo as T.PhotoAlbum;
  const photosFromAlbums = groupPhotoAlbums(photosQueryData, lang);
  const isError = result.isError;
  const isLoading = result.isLoading;

  return {
    photosFromAlbums: photosFromAlbums,
    albumInfo,
    photos: photosQueryData,
    isLoading,
    isError,
  };
};

export const useEditTagPhotoDate = (photoAlbumId?: number) => {
  const auth = useSelector((state: T.State) => state.Auth);
  const planConfigSlug = useSelector((state: T.State) => state.PlanConfig.config?.slug);
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);

  const photoQueryKey = photoAlbumId
    ? PhotoKeys.getPhotoAlbumById(photoAlbumId)
    : PhotoKeys.getNonPhotoAlbumKey(projectId!);

  return useMutation({
    mutationFn: async ({ tagMedia, takenAt }: { tagMedia: T.SelectedMedia[]; takenAt: Date }) =>
      photoService.editTagPhotoDate({
        projectId,
        tagMedia,
        takenAt,
        auth,
        planConfigSlug,
      }),
    onMutate: async ({ tagMedia, takenAt }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: photoQueryKey });
      if (!photoAlbumId) {
        await queryClient.cancelQueries({ queryKey: PhotoKeys.getVideoKey(projectId!) });
      }

      // Snapshot the previous value
      const previousPhotos = queryClient.getQueryData(photoQueryKey);

      // snapshot videos only if in root.
      const previousVideos = photoAlbumId
        ? null
        : queryClient.getQueryData(PhotoKeys.getVideoKey(projectId!));

      // Optimistically update photos and videos
      queryClient.setQueryData(photoQueryKey, (old: any) => {
        const updated = photoAlbumId ? old : [...old];
        tagMedia.forEach(media => {
          if (media.type !== 'overlay') {
            const idx = photoAlbumId
              ? updated.photos.findIndex((photo: T.Photo) => photo.id === media.contentId)
              : updated.findIndex((photo: T.Photo) => photo.id === media.contentId);
            if (idx !== -1) {
              if (photoAlbumId) {
                updated.photos[idx] = { ...updated.photos[idx], takenAt };
              } else {
                updated[idx] = { ...updated[idx], takenAt };
              }
            }
          }
        });
        return updated;
      });

      if (previousVideos) {
        queryClient.setQueryData(PhotoKeys.getVideoKey(projectId!), (old: T.DroneVideo[]) => {
          const updated = [...old];
          tagMedia.forEach(media => {
            if (media.type === 'overlay') {
              const idx = updated.findIndex(video => video.id === media.contentId);
              if (idx !== -1) {
                updated[idx] = { ...updated[idx], takenAt };
              }
            }
          });
          return updated;
        });
      }

      return { previousPhotos, previousVideos };
    },
    onError: (err, variables, context) => {
      // Rollback on error
      if (context?.previousPhotos) {
        queryClient.setQueryData(photoQueryKey, context.previousPhotos);
      }
      if (context?.previousVideos) {
        queryClient.setQueryData(PhotoKeys.getVideoKey(projectId!), context.previousVideos);
      }
    },
    onSettled: (...args) => {
      const previousVideos = args[3]?.previousVideos;
      // Invalidate and refetch
      void queryClient.invalidateQueries({ queryKey: photoQueryKey });
      if (previousVideos) {
        void queryClient.invalidateQueries({ queryKey: PhotoKeys.getVideoKey(projectId!) });
      }
    },
  });
};

export const useDeleteBulkPhotos = (photoAlbumId?: number) => {
  const auth = useSelector((state: T.State) => state.Auth);
  const planConfigSlug = useSelector((state: T.State) => state.PlanConfig.config?.slug);
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);
  const dispatch = useDispatch();

  const photoQueryKey = photoAlbumId
    ? PhotoKeys.getPhotoAlbumById(photoAlbumId)
    : PhotoKeys.getNonPhotoAlbumKey(projectId!);

  return useMutation({
    mutationFn: async ({ photoIds, videoIds }: { photoIds: number[]; videoIds: number[] }) => {
      if (!projectId) {
        throw new Error('Project ID is required');
      }
      dispatch(StartDeleteBulkPhotos());

      return photoService.deleteBulkPhotos({
        projectId,
        photoIds,
        videoIds,
        auth,
        planConfigSlug,
      });
    },
    onMutate: async ({ photoIds, videoIds }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: photoQueryKey });
      if (!photoAlbumId) {
        await queryClient.cancelQueries({ queryKey: PhotoKeys.getVideoKey(projectId!) });
      }

      // Snapshot the previous values
      const previousPhotos = queryClient.getQueryData(photoQueryKey);

      //snapshot videos only if in root
      const previousVideos = photoAlbumId
        ? null
        : queryClient.getQueryData(PhotoKeys.getVideoKey(projectId!));

      // Optimistically update photos
      if (photoAlbumId) {
        queryClient.setQueryData(
          photoQueryKey,
          (old: { albumInfo: T.PhotoAlbum; photos: T.Photo[] }) => ({
            albumInfo: old.albumInfo,
            photos: old.photos.filter(photo => !photoIds.includes(photo.id)),
          })
        );
      } else {
        queryClient.setQueryData(photoQueryKey, (old: T.Photo[]) =>
          old.filter(photo => !photoIds.includes(photo.id))
        );
      }

      // Optimistically update videos
      if (previousVideos) {
        queryClient.setQueryData(PhotoKeys.getVideoKey(projectId!), (old: T.DroneVideo[]) =>
          old.filter(video => !videoIds.includes(video.id))
        );
      }

      return { previousPhotos, previousVideos };
    },
    onSuccess: () => {
      dispatch(SetCurrentPhotoId({ photoId: undefined }));
      dispatch(SetSelectedMedia({ selectedMedia: [] }));
      dispatch(CloseContentPagePopup());
      dispatch(FinishDeleteBulkPhotos({}));
    },
    onError: (error, variables, context) => {
      // Rollback on error
      if (context?.previousPhotos) {
        queryClient.setQueryData(photoQueryKey, context.previousPhotos);
      }
      if (context?.previousVideos) {
        queryClient.setQueryData(PhotoKeys.getVideoKey(projectId!), context.previousVideos);
      }
      dispatch(FinishDeleteBulkPhotos({ error: getRequestErrorType(error as any) }));
      console.error('Failed to delete photos:', error);
    },
    onSettled: (...args) => {
      const previousVideos = args[3]?.previousVideos;
      // Invalidate and refetch
      void queryClient.invalidateQueries({ queryKey: photoQueryKey });
      if (previousVideos) {
        void queryClient.invalidateQueries({ queryKey: PhotoKeys.getVideoKey(projectId!) });
      }
    },
  });
};

export const useDeleteVideo = () => {
  const auth = useSelector((state: T.State) => state.Auth);
  const planConfigSlug = useSelector((state: T.State) => state.PlanConfig.config?.slug);
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ videoIds }: { videoIds: Array<T.DroneVideo['id']> }) => {
      if (!projectId) {
        throw new Error('Project ID is required');
      }

      return photoService.deleteVideo({
        projectId,
        videoIds,
        auth,
        planConfigSlug,
      });
    },
    onMutate: async ({ videoIds }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: PhotoKeys.getVideoKey(projectId!) });

      // Snapshot the previous videos
      const previousVideos = queryClient.getQueryData(PhotoKeys.getVideoKey(projectId!));

      // Optimistically update videos
      queryClient.setQueryData(PhotoKeys.getVideoKey(projectId!), (old: T.DroneVideo[]) =>
        old.filter(video => !videoIds.includes(video.id))
      );

      return { previousVideos };
    },
    onSuccess: () => {
      dispatch(SetCurrentPhotoId({ photoId: undefined }));
      dispatch(SetSelectedMedia({ selectedMedia: [] }));
      dispatch(CloseContentPagePopup());
    },
    onError: (error, variables, context) => {
      // Rollback on error
      if (context?.previousVideos) {
        queryClient.setQueryData(PhotoKeys.getVideoKey(projectId!), context.previousVideos);
      }
      console.error('Failed to delete videos:', error);
    },
    onSettled: () => {
      // Invalidate and refetch
      void queryClient.invalidateQueries({ queryKey: PhotoKeys.getVideoKey(projectId!) });
    },
  });
};

export const useDeletePhoto = () => {
  const auth = useSelector((state: T.State) => state.Auth);
  const planConfigSlug = useSelector((state: T.State) => state.PlanConfig.config?.slug);
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);
  const photos = useSelector((state: T.State) => state.Photos.photos);
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ photoId }: { photoId: T.Photo['id'] }) =>
      photoService.deletePhoto({
        photoId,
        auth,
        planConfigSlug,
      }),
    onMutate: async ({ photoId }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: PhotoKeys.getNonPhotoAlbumKey(projectId!) });

      // Snapshot the previous photos
      const previousPhotos = queryClient.getQueryData(PhotoKeys.getNonPhotoAlbumKey(projectId!));

      // Find the index of the photo to be deleted
      const photoIdx = photos.findIndex(photo => photo.id === photoId);

      // Optimistically update photos
      queryClient.setQueryData(PhotoKeys.getNonPhotoAlbumKey(projectId!), (old: T.Photo[]) =>
        old.filter(photo => photo.id !== photoId)
      );

      // Update current photo ID if necessary
      if (photos.length === 0) {
        dispatch(SetCurrentPhotoId({ photoId: undefined }));
      } else if (photoIdx !== 0 && photoIdx === photos.length - 1) {
        dispatch(SetCurrentPhotoId({ photoId: photos[photoIdx - 1].id }));
      } else if (photos[photoIdx + 1]) {
        dispatch(SetCurrentPhotoId({ photoId: photos[photoIdx + 1].id }));
      }

      return { previousPhotos, photoIdx };
    },
    onSuccess: () => {
      dispatch(CloseContentPagePopup());
    },
    onError: (error, variables, context) => {
      // Rollback on error
      if (context?.previousPhotos) {
        queryClient.setQueryData(PhotoKeys.getNonPhotoAlbumKey(projectId!), context.previousPhotos);
      }
      console.error('Failed to delete photo:', error);
    },
    onSettled: () => {
      // Invalidate and refetch
      void queryClient.invalidateQueries({ queryKey: PhotoKeys.getNonPhotoAlbumKey(projectId!) });
    },
  });
};

export function usePhotoAlbumQuery() {
  const params = useParams();
  const projectId = Number(params.id);

  // Define the URL for fetching photo albums
  const photoAlbumURL: string = makeV2APIURL('projects', projectId, 'photo_albums');

  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const authHeader = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();
  const [, lang] = useL10n();

  const results = useQueries({
    queries: [
      {
        queryKey: PhotoKeys.photoAlbumQuery(projectId),
        queryFn: async () =>
          fetchPhotoAlbums(photoAlbumURL, authHeader!, versionHeader, 'photo_album'),
        enabled: Boolean(projectId) && Boolean(authHeader),
        ...commonQueryOptions,
      },
    ],
  });

  // Extract data from queries
  const [photoAlbumsQuery] = results;

  const isLoading = photoAlbumsQuery.isLoading;
  const isError = photoAlbumsQuery.isError;

  const photoAlbums = (photoAlbumsQuery.data || []) as T.PhotoAlbum[];
  //   const groupedAlbums = groupPhotoAlbums(photoAlbums, lang);
  //   const filterRequestPhotos = photoAlbums.filter(photo => photo.status !== 'requested');
  const groupedAlbums = groupPhotoAlbums(photoAlbums, lang);
  const viewpointAlbum = photoAlbums.find(
    album => album.type === T.PhotoAlbumFilterTypes.VIEWPOINT
  );

  return { groupedAlbums, isLoading, isError, photoAlbums, viewpointAlbum };
}

export const useRenamePhotoAlbum = () => {
  const auth = useSelector((state: T.State) => state.Auth);
  const planConfigSlug = useSelector((state: T.State) => state.PlanConfig.config?.slug);
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);

  return useMutation({
    mutationFn: async ({
      photoAlbumId,
      newTitle,
    }: {
      photoAlbumId: T.PhotoAlbum['id'];
      newTitle: string;
      updatePhotoAlbumQuery?: boolean;
    }) =>
      photoService.renamePhotoAlbum({
        photoAlbumId,
        newTitle,
        auth,
        planConfigSlug,
      }),
    onMutate: async ({ photoAlbumId, newTitle, updatePhotoAlbumQuery }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: PhotoKeys.getPhotoAlbumKey(projectId!) });
      const previousAlbums = queryClient.getQueryData(PhotoKeys.photoAlbumQuery(projectId!));

      // If the album title is updated when inside the album directory in the ui.
      if (updatePhotoAlbumQuery) {
        const oldPhotoAlbumData = queryClient.getQueryData(
          PhotoKeys.getPhotoAlbumById(photoAlbumId)
        ) as FormattedPhotoAlbumResponse;

        if (oldPhotoAlbumData.albumInfo) {
          queryClient.setQueryData(
            PhotoKeys.getPhotoAlbumById(photoAlbumId),
            (old: FormattedPhotoAlbumResponse) => ({
              ...old,
              albumInfo: { ...oldPhotoAlbumData.albumInfo, title: newTitle },
            })
          );
        }
      }

      queryClient.setQueryData(PhotoKeys.photoAlbumQuery(projectId!), (old: T.PhotoAlbum[]) => {
        const updatedAlbums = [...old];
        const idx = updatedAlbums.findIndex(album => album.id === photoAlbumId);
        if (idx !== -1) {
          updatedAlbums[idx] = { ...updatedAlbums[idx], title: newTitle };
        }
        return updatedAlbums;
      });

      return { previousAlbums };
    },
    onError: (error, _, context) => {
      // Rollback on error
      if (context?.previousAlbums) {
        queryClient.setQueryData(PhotoKeys.photoAlbumQuery(projectId!), context.previousAlbums);
      }
      console.error('Failed to rename photo album:', error);
    },
  });
};

export const useSourcePhotoQuery = (
  projectId: number,
  screenId?: number,
  isSourcePhoto?: boolean
): {
  sourcePhotosAlbum: AlbumMapType;
  photos: T.FinalPhoto[];
  isLoading: boolean;
  isError: boolean;
} => {
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const authHeader = makeAuthHeader(Auth, slug);
  const versionHeader = makeVersionHeader();
  const [, lang] = useL10n();

  // Handle case when screenId is not provided
  const photoAlbumByIdURL: string = screenId
    ? makeV2APIURL('projects', projectId, 'photos', 'screen', screenId)
    : '';

  const result = useQuery({
    queryKey: PhotoKeys.sourcePhotoQuery(projectId, screenId ?? NaN),
    queryFn: async () => fetchPhotoAlbums(photoAlbumByIdURL, authHeader!, versionHeader, 'source'),
    enabled: Boolean(isSourcePhoto) && Boolean(screenId),
    ...commonQueryOptions,
  });

  const photosQueryData = (result.data || []) as T.FinalPhoto[];
  const sourcePhotosAlbum = groupPhotoAlbums(photosQueryData, lang);
  const isError = result.isError;
  const isLoading = result.isLoading;

  return {
    sourcePhotosAlbum,
    photos: photosQueryData,
    isLoading,
    isError,
  };
};

interface CreateAlbumMutationParams {
  albumType: string;
  title?: string;
  contentId?: string | number;
  onSuccessCallback?(albumId?: string): void;
}
export const useCreateAlbumMutation = () => {
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);
  const auth = useSelector((state: T.State) => state.Auth);
  const planConfigSlug = useSelector((state: T.State) => state.PlanConfig.config?.slug);

  return useMutation({
    mutationFn: async ({ albumType, contentId, title }: CreateAlbumMutationParams) => {
      if (!projectId) {
        throw new Error('Project Id is required');
      }
      const response = await photoService.createAlbum({
        albumType,
        projectId,
        auth,
        contentId,
        title,
        planConfigSlug,
      });

      return { albumId: response.id };
    },
    onSuccess: ({ albumId }, { onSuccessCallback }) => {
      onSuccessCallback?.(String(albumId));
      void queryClient.invalidateQueries({
        queryKey: PhotoKeys.getPhotoAlbumById(Number(albumId)),
      });
      void queryClient.invalidateQueries({
        queryKey: PhotoKeys.photoAlbumQuery(Number(projectId)),
      });
    },
  });
};

interface MovePhotoParams {
  albumId: T.PhotoAlbum['id'];
  photoIds: Array<T.Photo['id']>;
  onSuccessCallback?(): void;
}

export const useMovePhotoMutation = () => {
  const auth = useSelector((state: T.State) => state.Auth);
  const planConfigSlug = useSelector((state: T.State) => state.PlanConfig.config?.slug);
  const projectId = useSelector((state: T.State) => state.Pages.Contents.projectId);

  return useMutation({
    mutationFn: async ({ albumId, photoIds }: MovePhotoParams) => {
      if (!projectId) {
        throw new Error('Project ID is required');
      }

      const response = await photoService.movePhoto({
        albumId,
        auth,
        photoIds,
        projectId,
        planConfigSlug,
      });

      return response;
    },
    onSuccess: (...args) => {
      const variables = args[1];
      void queryClient.invalidateQueries({
        queryKey: PhotoKeys.getPhotoAlbumById(Number(variables.albumId)),
      });
      void queryClient.invalidateQueries({
        queryKey: PhotoKeys.photoAlbumQuery(Number(projectId)),
      });
      variables?.onSuccessCallback?.();
    },
  });
};
