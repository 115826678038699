import React, { FC, ReactNode, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import Tippy from '@tippyjs/react';

import Text from './text';
import DeleteSvg from '^/assets/icons/close.svg';
import ProfileSvg from '^/assets/icons/profile.svg';
import Dropdown, { Option as DropdownOption } from '^/components/atoms/Dropdown/1';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import { MediaQuery } from '^/constants/styles';
import * as T from '^/types';
import { l10n } from '^/utilities/l10n';
import { UseL10n, useL10n } from '^/hooks';
import { useAuthUserQuery } from '^/store/react-query/users';
import { usePermissionStore } from '^/store/permissionStore';
interface isCompactSize {
  isCompactSize: boolean;
}

const NAME_MAX_WIDTH = 250;
const TEAM_MAX_WIDTH = 120;

const Root = styled.tr<isCompactSize>(({ isCompactSize }) => ({
  fontWeight: 500,

  [MediaQuery.MOBILE_L]: {
    width: '100%',

    display: 'flex',
    flexDirection: 'column',

    boxSizing: 'border-box',
    backgroundColor: palette.white.toString(),

    padding: '20px',
  },
  borderBottomWidth: isCompactSize ? '1px' : '0px',
  borderBottomStyle: 'solid',
  borderBottomColor: palette.icon.toString(),
}));

const Column = styled.td.attrs<{ 'data-th'?: string }>(props => ({
  'data-th': props['data-th'],
}))<isCompactSize>(({ isCompactSize }) => ({
  paddingTop: isCompactSize ? '8px' : '25px',
  paddingBottom: isCompactSize ? '8px' : '25px',
  paddingRight: '3px',
  paddingLeft: '3px',

  fontSize: isCompactSize ? '13px' : '15px',
  lineHeight: 1,
  fontWeight: 'normal',
  color: palette.textLight.toString(),
  textAlign: 'center',
  verticalAlign: 'middle',

  backgroundColor: palette.white.toString(),

  [MediaQuery.MOBILE_L]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    paddingTop: '10px',
    paddingBottom: '10px',

    '::before': {
      content: 'attr(data-th)',
      fontWeight: 'bold',
    },
  },
}));

const NameColumn = styled(Column)(({ isCompactSize }) => ({
  paddingTop: isCompactSize ? '8px' : '14px',
  paddingBottom: isCompactSize ? '8px' : '14px',
  textAlign: 'left',
  verticalAlign: 'middle',
}));
NameColumn.displayName = 'NameColumn';

const NameContent = styled.div({
  display: 'flex',
  direction: 'ltr',
  alignItems: 'center',

  [MediaQuery.MOBILE_L]: {
    width: '100%',
  },
});

const AvatarWrapper = styled.div({
  flexShrink: 0,

  overflow: 'hidden',
  marginLeft: '15px',
  width: '37px',
  height: '37px',

  borderRadius: '50%',
  backgroundColor: palette.icon.toString(),

  [MediaQuery.MOBILE_L]: {
    marginLeft: 0,
  },
});

const UserAvatarImg = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const UserAvatarSVGWrapper = styled.svg({
  width: '100%',
  height: '100%',
  fill: palette.background.toString(),
});

const NameCell = styled.div({
  marginLeft: '20px',
  width: '100%',
});

const NameTextWrapper = styled.div({
  display: 'flex',
});

const truncateStyle: CSSObject = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const NameText = styled.div({
  fontWeight: 500,
  color: palette.textGray.toString(),
  paddingBottom: '2px',
  lineHeight: 'initial',
  paddingRight: '4px',
  flexShrink: 1,
  maxWidth: NAME_MAX_WIDTH,
});

const NameTextHelper = styled.div({
  display: 'block',

  maxWidth: NAME_MAX_WIDTH,
  width: 'fit-content',

  color: palette.textLight.toString(),
  lineHeight: 'initial',
  ...truncateStyle,
});

const TeamColumn = styled(Column)({
  paddingLeft: '5px',
  paddingRight: '5px',
});

const TeamText = styled.div({
  color: palette.textLight.toString(),
  width: 'max-content',
  display: 'inline-block',

  lineHeight: 'initial',
  flexShrink: 1,
  maxWidth: TEAM_MAX_WIDTH,
  ...truncateStyle,
});

const labelTextStyle: CSSObject = {
  display: 'flex',
  alignItems: 'center',

  boxSizing: 'border-box',
  padding: '1px 3px',

  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '3px',

  fontSize: '10px',
  lineHeight: 'initial',
  fontWeight: 500,
};
const PendingText = styled.span({
  ...labelTextStyle,
  borderColor: palette.subColor.toString(),
  color: palette.subColor.toString(),
});
PendingText.displayName = 'PendingText';

const DeniedText = styled.span({
  ...labelTextStyle,
  borderColor: palette.error.toString(),
  color: palette.error.toString(),
});
DeniedText.displayName = 'DeniedText';

const DeleteIconWrapper = styled.svg<{ disabled: boolean }>(({ disabled }) => ({
  width: '14px',
  height: '14px',

  fill: palette.textLight.toString(),

  cursor: disabled ? 'not-allowed' : 'pointer',
}));

const AuthorityContent = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const Tooltip = styled(Tippy)({
  wordWrap: 'break-word',
});

export interface Props {
  readonly permission: T.Permission;
  readonly zIndex: number;
  readonly featurePermission: T.PermissionFeature;
  readonly isCompactSize: boolean;
  onDelete(): void;
  onPermissionChange(id: T.Permission['id'], role: T.PermissionRole): void;
  onFeaturePermissionChange(id: T.Permission['id'], role: string, isESS: boolean): void;
}

export const usePermissionDropdownOptions = (): DropdownOption[] => {
  const [, language]: UseL10n = useL10n();
  return [
    {
      value: T.PermissionRole.ADMIN,
      text: 'Admin',
      helperText: l10n(Text.adminRole, language),
    },
    {
      value: T.PermissionRole.PILOT,
      text: 'Pilot',
      helperText: l10n(Text.pilotRole, language),
    },
    {
      value: T.PermissionRole.MEMBER,
      text: 'Member',
      helperText: l10n(Text.memberRole, language),
    },
    {
      value: T.PermissionRole.VIEWER,
      text: 'Viewer',
      helperText: l10n(Text.viewerRole, language),
    },
  ];
};

/**
 * Project manage tab permission table item component
 */
const ProjectPermissionItem: FC<Props & L10nProps> = ({
  permission,
  language,
  zIndex,
  onDelete,
  onPermissionChange,
  onFeaturePermissionChange,
  featurePermission,
  isCompactSize,
}) => {
  const { data: authUser } = useAuthUserQuery();
  const [nameRef, setNameRef] = useState<HTMLDivElement | null>(null);
  const [helperTextRef, setHelperTextRef] = useState<HTMLDivElement | null>(null);
  const [teamRef, setTeamRef] = useState<HTMLDivElement | null>(null);
  const searchText = usePermissionStore(s => s.searchText);

  const handlePermissionChange: (option: DropdownOption) => void = option => {
    onPermissionChange(permission.id, option.value as T.PermissionRole);
  };

  const handleSwitchChange = (isESS: boolean): void => {
    onFeaturePermissionChange(permission.id, permission.role, !isESS);
  };

  const permissionOptions: DropdownOption[] = usePermissionDropdownOptions();

  const avatar: ReactNode =
    permission.avatar !== undefined ? (
      <UserAvatarImg alt="user-avatar" src={permission.avatar} />
    ) : (
      <UserAvatarSVGWrapper>
        <ProfileSvg />
      </UserAvatarSVGWrapper>
    );
  const statusText: ReactNode =
    permission.status === T.PermissionStatus.ACCEPTED ? undefined : permission.status ===
      T.PermissionStatus.PENDING ? (
      <PendingText>PENDING</PendingText>
    ) : (
      <DeniedText>DENIED</DeniedText>
    );

  const highlightString = (name: string) =>
    name.split(new RegExp(`(${searchText})`, 'gi')).map((text, index) => {
      if (text.toLowerCase() === searchText.toLowerCase() && Boolean(searchText)) {
        return (
          <span key={index} style={{ backgroundColor: 'rgba(0, 100, 249, 0.26)' }}>
            {text}
          </span>
        );
      } else {
        return text;
      }
    });
  const name =
    language === T.Language.KO_KR
      ? `${permission.lastName} ${permission.firstName}`
      : `${permission.firstName} ${permission.lastName}`;

  return (
    <Root isCompactSize={isCompactSize}>
      <NameColumn isCompactSize={isCompactSize}>
        <NameContent>
          <AvatarWrapper>{avatar}</AvatarWrapper>
          <NameCell>
            <NameTextWrapper>
              <Tooltip
                theme="angelsw"
                offset={T.TIPPY_OFFSET}
                arrow={false}
                content={name}
                placement="bottom"
                disabled={Number(nameRef?.offsetWidth) < NAME_MAX_WIDTH}
              >
                <NameText ref={setNameRef}>{highlightString(name)}</NameText>
              </Tooltip>
              {statusText}
            </NameTextWrapper>
            <Tooltip
              theme="angelsw"
              offset={T.TIPPY_OFFSET}
              arrow={false}
              content={permission.email}
              placement="bottom"
              disabled={Number(helperTextRef?.offsetWidth) < NAME_MAX_WIDTH}
            >
              <NameTextHelper ref={setHelperTextRef}>
                {highlightString(permission.email)}
              </NameTextHelper>
            </Tooltip>
          </NameCell>
        </NameContent>
      </NameColumn>
      <Column isCompactSize={isCompactSize} data-th={l10n(Text.authority, language)}>
        <AuthorityContent>
          <Dropdown
            options={permissionOptions}
            value={permission.role}
            placeHolder=""
            onClick={handlePermissionChange}
            zIndex={zIndex}
            caretStyle={{ color: palette.textLight.toString() }}
            isCompactSize={isCompactSize}
            disabled={authUser?.id === permission.userId}
          />
        </AuthorityContent>
      </Column>
      <TeamColumn isCompactSize={isCompactSize} data-th={l10n(Text.team, language)}>
        <Tooltip
          theme="angelsw"
          offset={T.TIPPY_OFFSET}
          arrow={false}
          content={permission.organization}
          placement="bottom"
          disabled={Number(teamRef?.offsetWidth) < TEAM_MAX_WIDTH}
        >
          <TeamText ref={setTeamRef}>{permission.organization}</TeamText>
        </Tooltip>
      </TeamColumn>
      {featurePermission.ess && (
        <Column isCompactSize={isCompactSize} data-th={l10n(Text.featureToggle.ess, language)}>
          <AuthorityContent>
            <ToggleSlider
              backgroundColor="var(--color-theme-primary)"
              enabled={permission?.features?.ess || false}
              onClick={() => handleSwitchChange(permission?.features?.ess || false)}
            />
          </AuthorityContent>
        </Column>
      )}
      <Column isCompactSize={isCompactSize} data-th={l10n(Text.delete, language)}>
        <DeleteIconWrapper disabled={authUser?.id === permission.userId} onClick={onDelete}>
          <DeleteSvg data-testid="project-permission-item-delete-button" />
        </DeleteIconWrapper>
      </Column>
    </Root>
  );
};
export default withL10n(ProjectPermissionItem);
