import { Language } from '^/types';

export default {
  workRadiusToggleLabel: {
    [Language.KO_KR]: '작업반경 표시',
    [Language.EN_US]: 'Enable Working Radius',
  },
  opacityTitle: {
    [Language.KO_KR]: '불투명도',
    [Language.EN_US]: 'Opacity',
  },
  saveAs: {
    [Language.KO_KR]: '모델 저장',
    [Language.EN_US]: 'Save As',
  },
  update: {
    [Language.KO_KR]: '적용',
    [Language.EN_US]: 'Update',
  },
  color: {
    [Language.KO_KR]: '색상',
    [Language.EN_US]: 'Color',
  },
};
