import theme from '^/theme';
import React, { ButtonHTMLAttributes } from 'react';
import styled, { CSSObject } from 'styled-components';

export type ButtonVariants = 'primary' | 'secondary' | 'primaryBlack' | 'coloredSecondary';
export type ButtonSizes = 'small' | 'medium' | 'large';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  disabled?: boolean;
  selected?: boolean;
  error?: boolean;
  customStyles?: CSSObject;
}

const variantStyles: Record<ButtonVariants, CSSObject> = {
  primary: {
    backgroundColor: theme.button.bg.primary,
    color: theme.colors.white[100],
    border: 'none',
    '&:hover': { backgroundColor: theme.button.bg.primaryHover },
    '&:active': { backgroundColor: theme.button.bg.primaryPressed },
  },
  secondary: {
    backgroundColor: theme.button.bg.secondary,
    color: theme.button.title.secondary,
    border: `1px solid ${theme.button.border.secondary}`,
    '&:hover': { backgroundColor: theme.button.bg.secondaryHover },
    '&:active': { backgroundColor: theme.button.bg.secondaryPressed },
    '&:focus': {
      border: `1px solid ${theme.button.border.success}`,
      color: theme.button.title.secondarySuccess,
    },
  },
  coloredSecondary: {
    backgroundColor: theme.button.bg.secondary,
    color: theme.button.title.secondarySuccess,
    border: `1px solid ${theme.button.title.secondarySuccess}`,
    '&:hover': { backgroundColor: theme.button.bg.secondaryHover },
    '&:active': { backgroundColor: theme.button.bg.secondaryPressed },
    '&:focus': {
      border: `1px solid ${theme.button.border.success}`,
      color: theme.button.title.secondarySuccess,
    },
  },
  primaryBlack: {
    backgroundColor: theme.button.bg.primaryBlack,
    color: theme.button.title.primary,
    '&:hover': { backgroundColor: theme.button.bg.primaryBlackHover },
    '&:active': { backgroundColor: theme.button.bg.primaryBlackPressed },
  },
};

const sizeStyles: Record<ButtonSizes, CSSObject> = {
  small: {
    padding: '4px 8px',
    fontSize: '14px',
  },
  medium: {
    padding: '8px 16px',
    fontSize: '14px',
  },
  large: {
    padding: '8px 24px',
    fontSize: '15px',
    height: '48px',
  },
};

const StyledButton = styled.button<ButtonProps>(
  ({ variant = 'primary', size = 'medium', disabled, selected, error, customStyles }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    fontWeight: 500,
    fontFamily: 'pretendard, sans-serif',
    cursor: disabled ? 'not-allowed' : 'pointer',
    transition: 'background 0.2s ease-in-out',
    ...variantStyles[variant],
    ...sizeStyles[size],
    ...(disabled && {
      backgroundColor:
        variant === 'primary' ? theme.button.bg.primaryDisabled : theme.button.bg.secondaryDisabled,
      color:
        variant === 'primary'
          ? theme.button.title.primaryDisabled
          : theme.button.title.secondaryDisabled,
      pointerEvents: 'none',
    }),
    ...(selected && {
      border: `1px solid ${theme.button.border.success}`,
      color: theme.button.title.secondarySuccess,
    }),
    ...(error && {
      border: `1px solid ${theme.button.border.error}`,
      color: theme.button.title.secondaryError,
    }),
    ...customStyles,
  })
);

export function Button({
  children,
  variant = 'primary',
  size = 'medium',
  disabled,
  selected,
  error,
  ...props
}: ButtonProps) {
  return (
    <StyledButton
      variant={variant}
      size={size}
      disabled={disabled}
      selected={selected}
      error={error}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
