import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import { ContentsTreeList } from '../ContentsTreeList';
import { Fallback } from './fallback';
import { CONTENTSLIST_CTXSORT_KEY } from '^/components/atoms/ContentsListItem';
import { GroupedContentsListHeader } from '^/components/molecules/GroupedContentsListHeader';

import palette from '^/constants/palette';
import { useIsRoleX } from '^/hooks';
import { AddNewGroup, MoveContent } from '^/store/duck/Groups';
import * as T from '^/types';
import {
  CtxSort,
  CtxSortCollisionMode,
  CtxSortContext,
  CtxSortEvent,
  CtxSortEventOptions,
} from '^/utilities/ctxsort';
import { arePropsEqual } from '^/utilities/react-util';
import { isRoleViewer } from '^/utilities/role-permission-check';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import ContentsSearchBox from '^/components/molecules/ContentsSearchBox';
import { defaultOverlayFilters } from '^/store/duck/Contents';
import { ContentFilterContextProvider } from '^/components/molecules/ContentsSearchBox/context';
import { useGroupStore } from '^/store/zustand/groups/groupStore';

const OverlayContentsListWrapper = styled.div.attrs({
  className: 'ctxsort-scroller',
})({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowX: 'hidden',
  touchAction: 'none',
});
OverlayContentsListWrapper.displayName = 'OverlayContentsListWrapper';

export const OverlayContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',
  userSelect: 'none',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});
OverlayContentsListRoot.displayName = 'GroupedContentsListRoot';

const GroupListDivider = styled.div({
  position: 'sticky',
  zIndex: 10,
  top: '43px',
  height: '6px',
  marginTop: '17.5px',
  borderTop: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  borderBottom: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  backgroundColor: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  boxSizing: 'border-box',
});
GroupListDivider.displayName = 'GroupListDivider';

/**
 * @description Component for content list in sidebar of contents page
 */
const Contents: FC = () => {
  const dispatch = useDispatch();
  const { open, personal } = useGroupStore(s => s.tree.rootIdsBySpace[T.ContentCategory.OVERLAY]);

  useEffect(() => {
    if (personal.length === 0) {
      dispatch(AddNewGroup({ isPersonal: true }));
    }
    if (open.length === 0) {
      dispatch(AddNewGroup({ isPersonal: false }));
    }
  }, [open.length, personal.length]);

  return (
    <>
      <ContentFilterContextProvider
        contentTypes={defaultOverlayFilters}
        filterType={T.ContentsListType.PERSONAL_OVERLAY}
        rootGroupIds={personal}
      >
        <GroupedContentsListHeader rootGroupIds={personal} isPersonalGroups={true} />
        <ContentsSearchBox />
        <ContentsTreeList contentIds={personal} />
      </ContentFilterContextProvider>
      <GroupListDivider />
      <ContentFilterContextProvider
        contentTypes={defaultOverlayFilters}
        filterType={T.ContentsListType.OPEN_OVERLAY}
        rootGroupIds={open}
      >
        <GroupedContentsListHeader rootGroupIds={open} isPersonalGroups={false} />

        <ContentsSearchBox />
        <ContentsTreeList contentIds={open} />
      </ContentFilterContextProvider>
    </>
  );
};

const GroupedContentsList: FC = () => {
  const contentListItemCtxSortRef = useRef<CtxSortContext | null>(null);
  const groupCtxSortRef = useRef<CtxSortContext | null>(null);

  const dispatch: Dispatch = useDispatch();
  const isViewer: boolean = useIsRoleX(isRoleViewer);

  const contentFilters = useSelector((s: T.State) => s.Contents.contentFilters);
  const printingContentId: T.ContentsPageState['printingContentId'] = useSelector(
    (s: T.State) => s.Pages.Contents.printingContentId
  );

  const handleContentMoveThroughDnD: CtxSortEventOptions['onSortEnd'] = useCallback(
    (e: CtxSortEvent) => {
      dispatch(MoveContent({ e }));
    },
    []
  );

  useEffect(() => {
    if (isViewer || printingContentId) {
      return;
    }

    const filterText =
      contentFilters[T.ContentsListType.OPEN_OVERLAY].filterText ||
      contentFilters[T.ContentsListType.PERSONAL_OVERLAY].filterText;
    const hasFilteredContents =
      contentFilters[T.ContentsListType.OPEN_OVERLAY].filterContents.length !==
        defaultOverlayFilters.length ||
      contentFilters[T.ContentsListType.PERSONAL_OVERLAY].filterContents.length !==
        defaultOverlayFilters.length;

    if (filterText || hasFilteredContents) {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();
    } else {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();

      contentListItemCtxSortRef.current = CtxSort({
        target: CONTENTSLIST_CTXSORT_KEY,
        owner: 'Group',
        scroller: '.ctxsort-scroller',
        portalId: 'ctxsort-portal-content-list-item',
        delay: 200,
        onSortEnd: handleContentMoveThroughDnD,
        psuedoOwnerClassName: 'ctxsort-contentlistitem-owner-active',
      });
      groupCtxSortRef.current = CtxSort({
        target: 'Group',
        owner: 'GroupedContentsListHeader',
        scroller: '.ctxsort-scroller',
        portalId: 'ctxsort-portal-content-group',
        collisionMode: CtxSortCollisionMode.PARENT,
        delay: 200,
        psuedoOwnerClassName: 'ctxsort-contentlistitem-owner-active',
        onSortEnd: handleContentMoveThroughDnD,
        restrictSubGroup: true,
      });
    }

    return () => {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();
    };
  }, [contentFilters]);

  const scrollBarViewStyle: CSSObject = {
    height: '100%',
    overflowX: 'hidden',
    paddingBottom: '2.5px',
    touchAction: 'none',
  };
  const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
    <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
  );
  const hiddenAfterMilliSec: number = 0;
  const hiddenViaMilliSec: number = 500;

  return (
    <OverlayContentsListRoot>
      <ScrollBars
        renderView={ScrollBarView}
        autoHide={true}
        autoHideTimeout={hiddenAfterMilliSec}
        autoHideDuration={hiddenViaMilliSec}
      >
        <Contents />
      </ScrollBars>
    </OverlayContentsListRoot>
  );
};
// eslint-disable-next-line max-lines
export default memo(withErrorBoundary(GroupedContentsList)(Fallback), arePropsEqual);
