import { PresentationAttachment } from '^/types';
import { create, useStore } from 'zustand';
import * as T from '^/types';

interface PresentationState {
  isInPresentationMode: boolean;
  slideContentApiStatus?: T.APIStatus;
  currentSlideId?: T.SlideContent['id'];
  attachments?: PresentationAttachment[];
  isScreenBeingCaptured: boolean;
  presentationGroupIds: Array<T.SlideContent['id']>;
  currentPresentationGroupId: T.SlideContent['id'] | null;
  presentationContentsById: Record<T.Content['id'], T.SlideContent | T.AnnotationContent>;
  slideIdsByGroupId: Record<T.Content['id'], Array<T.SlideContent['id']>>;
  currentlySelectedSlideId: T.SlideContent['id'] | null;
  deletingPresentationId?: T.Content['id'];
  deletingSlideid?: T.SlideContent['id'];
  selectedGroupId?: T.Content['id'];
  slideContentsIds: Array<T.Content['id']>;
  cameraPosition: T.GeoPoint;
  cameraRotation: number[];
  isSlideActive: boolean;
  selectedThumbnails: Set<T.SlideContent['id']>;
  isChangingSlide: boolean;
}

interface PresentationStateActions {
  //TODO: rename this actions and attributes @govinda
  reset(): void;
  setCurrentSlideId(slideId: T.SlideContent['id']): void;
  setIsInPresentationMode(isInPresentationMode: boolean): void;
  setPresentationAttachments(attachments: PresentationAttachment[]): void;
  setPresentationGroupIds(presentationGroupIds: Array<T.Content['id']>): void;
  setPresentationContentById(
    presentationContentsById: Record<T.Content['id'], T.SlideContent | T.AnnotationContent>
  ): void;
  setSlidesIdsByGroupId(
    slideIdsByGroupId: Record<T.Content['id'], Array<T.SlideContent['id']>>
  ): void;
  setSlideContentApiStatus(slideContentApiStatus: T.APIStatus): void;
  setisScreenBeingCaptured(isScreenBeingCaptured: boolean): void;
  setCurrentlySelectedSlideId(currentlySelectedSlideId: T.SlideContent['id'] | null): void;
  setDeletingPresentationId(deletingPresentationId?: T.Content['id']): void;
  setDeletingSlideid(deletingSlideid?: T.SlideContent['id']): void;
  setSelectedGroupId(selectedGroupId?: T.Content['id']): void;
  setCameraPosition(cameraPosition: T.GeoPoint): void;
  setCameraRotation(cameraRotation: number[]): void;
  setIsActiveSlide(isSlideActive: boolean): void;
  setSelectedThumbnails(selectedThumbnails: Set<T.SlideContent['id']>): void;
  setIsChangingSlide(isChangingSlide: boolean): void;
}

const initialState: PresentationState = {
  attachments: undefined,
  isScreenBeingCaptured: false,
  presentationGroupIds: [],
  currentSlideId: undefined,
  isInPresentationMode: false,
  presentationContentsById: {},
  currentPresentationGroupId: null,
  slideContentApiStatus: T.APIStatus.IDLE,
  slideIdsByGroupId: {},
  currentlySelectedSlideId: null,
  deletingPresentationId: undefined,
  deletingSlideid: undefined,
  selectedGroupId: undefined,
  slideContentsIds: [],
  cameraPosition: [],
  cameraRotation: [],
  isSlideActive: false,
  selectedThumbnails: new Set(),
  isChangingSlide: false,
};

type PresentationStoreInterface = PresentationState & PresentationStateActions;

export const presentationStore = create<PresentationStoreInterface>()(set => ({
  ...initialState,
  setCurrentlySelectedSlideId: currentlySelectedSlideId => set({ currentlySelectedSlideId }),
  setCurrentSlideId: currentSlideId => set({ currentSlideId }),
  setisScreenBeingCaptured: isScreenBeingCaptured => set({ isScreenBeingCaptured }),
  setPresentationAttachments: attachments => set({ attachments }),
  setIsInPresentationMode: isInPresentationMode => set({ isInPresentationMode }),
  setSlideContentApiStatus: slideContentApiStatus => set({ slideContentApiStatus }),
  setPresentationContentById: presentationContentsById => set({ presentationContentsById }),
  setPresentationGroupIds: presentationGroupIds => set({ presentationGroupIds }),
  setSlidesIdsByGroupId: slideIdsByGroupId => set({ slideIdsByGroupId }),
  setDeletingPresentationId: deletingPresentationId => set({ deletingPresentationId }),
  setDeletingSlideid: deletingSlideid => set({ deletingSlideid }),
  setSelectedGroupId: selectedGroupId => set({ selectedGroupId }),
  setCameraPosition: cameraPosition => set({ cameraPosition }),
  setCameraRotation: cameraRotation => set({ cameraRotation }),
  setIsActiveSlide: isSlideActive => set({ isSlideActive }),
  setSelectedThumbnails: selectedThumbnails => set({ selectedThumbnails }),
  setIsChangingSlide: isChangingSlide => set({ isChangingSlide }),
  //  setState: <K extends keyof PresentationState>(
  //   key: K,
  //   value: PresentationState[K]
  // ) => set({ [key]: value } as Pick<PresentationState, K>),
  reset: () => set(initialState),
}));

export function usePresentationStore<T = PresentationStoreInterface>(
  selector?: (state: PresentationStoreInterface) => T
): T {
  return useStore(presentationStore, selector ?? (state => state as T));
}
