import { Language } from '^/types';

export default {
  lock: {
    [Language.KO_KR]: '잠금',
    [Language.EN_US]: 'Lock',
  },
  translate: {
    [Language.KO_KR]: '이동',
    [Language.EN_US]: 'Translate',
  },
  freemove: {
    [Language.KO_KR]: '자유 이동',
    [Language.EN_US]: 'Free Move',
  },
  rotate: {
    [Language.KO_KR]: '회전',
    [Language.EN_US]: 'Rotate',
  },
  scale: {
    [Language.KO_KR]: '크기',
    [Language.EN_US]: 'Scale',
  },
  zoomIn: {
    [Language.KO_KR]: '확대',
    [Language.EN_US]: 'Zoom In',
  },
  zoomOut: {
    [Language.KO_KR]: '축소',
    [Language.EN_US]: 'Zoom Out',
  },
};
