import { Language } from '^/types';

export default {
  upload: {
    [Language.KO_KR]: '업로드',
    [Language.EN_US]: 'Upload',
  },
  uploadTooltip: {
    twoD: {
      [Language.KO_KR]: '전경 사진 업로드',
      [Language.EN_US]: 'Upload Bird Eye View Photos',
    },
    threeD: {
      [Language.KO_KR]: '드론 사진 업로드',
      [Language.EN_US]: 'Upload Inspection Photos',
    },
  },
};
