import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import dsPalette from '^/constants/ds-palette';
import {
  EmptyTimelapseCollectionItem,
  TimelapseCollectionFilter,
  TimelapseCollectionItem,
} from './Components';
import { PhotoContainer, PhotoListWrapper } from '../PhotoList/style';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import useTimelapseContents from '^/hooks/timelapse/useTimelapseContents';
import * as T from '^/types';
import SkeletonLoaderImages from './SkeletonLoaderImages';
import { defaultToastErrorOption, useToast, UseToast } from '^/hooks';
import Text from './text';

const Root = styled(PhotoContainer)({
  maxHeight: 'calc(100vh - 200px)',
  padding: '0 15px 3.188rem 15px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
});

export const FilterWrapper = styled.div({
  position: 'sticky',
  top: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-end',
  gap: '10px',
  flexWrap: 'wrap',
  padding: '20px 0px',
  backgroundColor: dsPalette.white.toString(),
  zIndex: 1,
});

const SkeletonLoaderWrapper = styled(PhotoListWrapper)({});

const TImelapseCollectionList: React.FC = () => {
  const loaderCount = 20;
  const { id: projectId } = useParams();

  const toastify: UseToast = useToast();

  const { getTimelapseCollections } = useTimelapseContents(Number(projectId));

  const {
    timelapseCollections,
    collectionFilter,
    getTimelapseCollectionsStatus,
    setTimelapseCollectionsStatus,
  } = useTimelapseStore(s => ({
    timelapseCollections: s.timelapseCollections,
    collectionFilter: s.collectionFilter,
    getTimelapseCollectionsStatus: s.getTimelapseCollectionsStatus,
    setTimelapseCollectionsStatus: s.setTimelapseCollectionsStatus,
  }));

  const isInProgress = getTimelapseCollectionsStatus === T.APIStatus.PROGRESS;
  const hasError = getTimelapseCollectionsStatus === T.APIStatus.ERROR;

  const sortedlists = useMemo(() => {
    if (!timelapseCollections) {
      return [];
    }
    return timelapseCollections.sort((a, b) => {
      if (!a.photoCount || !b.photoCount) {
        return -1;
      }

      return b.photoCount - a.photoCount;
    });
  }, [timelapseCollections]);

  useEffect(() => {
    if (hasError) {
      toastify({
        type: T.Toast.ERROR,
        content: {
          title: Text.photo.error.title,
          description: Text.photo.error.description,
        },
        option: defaultToastErrorOption,
      });
      setTimelapseCollectionsStatus(T.APIStatus.IDLE);
    }
  }, [hasError]);

  useEffect(() => {
    void getTimelapseCollections(collectionFilter);
  }, [projectId, collectionFilter]);

  return (
    <Root>
      <FilterWrapper>
        <TimelapseCollectionFilter />
      </FilterWrapper>
      {isInProgress ? (
        <SkeletonLoaderWrapper>
          <SkeletonLoaderImages count={loaderCount} />
        </SkeletonLoaderWrapper>
      ) : timelapseCollections.length ? (
        <PhotoListWrapper>
          {sortedlists.map((timelapseCollection, i) => (
            <TimelapseCollectionItem
              key={`timelapse_collection_${i}`}
              timelapseCollection={timelapseCollection}
            />
          ))}
        </PhotoListWrapper>
      ) : (
        <EmptyTimelapseCollectionItem />
      )}
    </Root>
  );
};

export default TImelapseCollectionList;
