import * as T from '^/types';

export default {
  emptyData: {
    [T.Language.KO_KR]: '데이터가 없습니다.',
    [T.Language.EN_US]: 'No datas at the moment',
  },
  title: {
    [T.Language.KO_KR]: '드론 스테이션',
    [T.Language.EN_US]: 'Drone Stations',
  },
  taskStatus: {
    inProgress: {
      [T.Language.KO_KR]: '연결됨',
      [T.Language.EN_US]: 'Connected',
    },
    completed: {
      [T.Language.KO_KR]: '작업 완료',
      [T.Language.EN_US]: 'Task Completed',
    },
    connected: {
      [T.Language.KO_KR]: '연결됨',
      [T.Language.EN_US]: 'Connected',
    },
    disconnected: {
      [T.Language.KO_KR]: '연결이 끊김',
      [T.Language.EN_US]: 'Disconnected',
    },
  },
  droneFields: {
    battery: { [T.Language.KO_KR]: '배터리', [T.Language.EN_US]: 'Battery' },
  },
  dockFields: {
    temperature: { [T.Language.KO_KR]: '온도', [T.Language.EN_US]: 'Temperature' },
    internetSpeed: { [T.Language.KO_KR]: '인터넷 속도', [T.Language.EN_US]: 'Internet Speed' },
  },
  buttons: {
    liveCamera: {
      [T.Language.KO_KR]: '실시간 영상',
      [T.Language.EN_US]: 'Live Camera',
    },
  },
};
