import * as T from '^/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useContentsFilter } from '^/components/molecules/ContentsSearchBox/context';
import { useESSContentsFilter } from '^/components/molecules/ContentsSearchBox/essContext';
import { PatchContent } from '^/store/duck/Contents';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useESSContents } from './useESSContents';

export const checkIfContentIsFilteredOut = (
  content: T.Content,
  filterText: string,
  selectedFilterContents: T.ContentType[]
): boolean => {
  if (!content) {
    return false;
  }
  if (filterText === '') {
    return !selectedFilterContents.includes(content.type);
  }

  const isFilteredIn =
    selectedFilterContents.includes(content.type) &&
    content.title.toLowerCase().includes(filterText.toLowerCase());

  return !isFilteredIn;
};

export const useIsContentHiddenByFilter = (content?: T.Content, isESS?: boolean) => {
  const essContents = useESSContentsStore(s => s.essContents);
  const { updateESSContent } = useESSContents();
  const dispatch = useDispatch();

  const essFilterData = useESSContentsFilter();
  const filterData = useContentsFilter();
  const byId = useContentsStore(s => s.contents.byId);

  const { selectedFilterContents, filterText, contentTypes, filteredContentIds, filterType } = isESS
    ? essFilterData
    : filterData;

  const currentContentId = filteredContentIds.find(id => id === content?.id);
  const currentContent = isESS
    ? essContents[currentContentId ?? NaN]
    : byId[currentContentId ?? NaN];

  useEffect(() => {
    if (filterText || selectedFilterContents.length !== contentTypes?.length) {
      if (currentContent?.type === T.ContentType.GROUP && !currentContent.info.isOpened) {
        if (currentContent.category === T.ContentCategory.ESS) {
          void updateESSContent({
            content: { id: currentContent.id, info: { isOpened: true } },
          });
        } else {
          dispatch(PatchContent({ content: { id: currentContent.id, info: { isOpened: true } } }));
        }
      }
    }
  }, [
    currentContent?.type,
    currentContent?.category,
    currentContent?.id,
    filterText,
    selectedFilterContents.length,
    contentTypes?.length,
  ]);

  if (filterType) {
    return !currentContent;
  }
  return false;
};
