/**
 * TODO: @ebraj-angelswing
 * In WIP mode.
 */

import { useDroneStationStore } from '^/store/droneStationStore';
import { makeAuthHeader } from '^/store/duck/API';
import {
  useGetDroneLiveStreamMutation,
  useUpdateDroneLiveStreamMutation,
} from '^/store/react-query/drone-station/drone-station';
import * as T from '^/types';
import AgoraRTC, { AgoraRTCProvider, ClientConfig, useRTCClient } from 'agora-rtc-react';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const AgoraVideoWrapper = styled.div({ width: '100%', height: '100%', pointerEvents: 'none' });

const config: ClientConfig = {
  mode: 'live',
  codec: 'vp8',
};
const appConfig = {
  appId: import.meta.env.VITE_DJI_LIVESTREAM_APP_ID,
  token: import.meta.env.VITE_DJI_LIVESTREAM_APP_TOKEN,
  channelName: import.meta.env.VITE_DJI_LIVESTREAM_APP_CHANNEL_NAME,
};

export const AgoraChild = () => {
  const projectId: T.Project['id'] | undefined = useSelector(
    (state: T.State) => state.Pages.Contents.projectId
  );
  const authHeader = useSelector((s: T.State) => makeAuthHeader(s.Auth, s.PlanConfig.config?.slug));
  const getLiveStreamMutation = useGetDroneLiveStreamMutation();
  const updateLiveStreamMutation = useUpdateDroneLiveStreamMutation();
  const client = useRTCClient();
  const videoRef = useRef<HTMLDivElement>(null);
  const liveStreamQuality = useDroneStationStore(s => s.liveStreamQuality);

  /**
   * TODO: @ebraj-angelswing
   * Some Hardcoded value for now...
   */
  const requiredPayload = {
    url: `channel=${appConfig.channelName}&sn=7CTDM4K00BB5G3&token=${appConfig.token}&uid=123456`,
    video_id: '7CTDM4K00BB5G3/165-0-7/normal-0',
    url_type: 0,
    video_quality: liveStreamQuality,
  };

  useEffect(() => {
    getLiveStreamMutation.mutate({
      datas: requiredPayload,
      projectId,
      authHeader,
    });
  }, []);

  useEffect(() => {
    if (getLiveStreamMutation.isSuccess) {
      void joinChannel();
    }
  }, [getLiveStreamMutation.isSuccess]);

  useEffect(() => {
    updateLiveStreamMutation.mutate({
      datas: { video_id: requiredPayload.video_id, video_quality: liveStreamQuality },
      projectId,
      authHeader,
    });
  }, [liveStreamQuality]);

  async function joinChannel() {
    const { appId, channelName, token } = appConfig;

    // may be changed to the useJoin hook later...
    await client.join(appId, channelName, token, null);

    client.on('user-published', async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      if (mediaType === 'video') {
        const remoteVideoTrack = user.videoTrack;
        console.log({ remoteVideoTrack });

        if (videoRef.current) {
          remoteVideoTrack?.play(videoRef.current);
        }
      }
    });
  }

  return <AgoraVideoWrapper ref={videoRef} />;
};

export function AgoraLiveStreaming() {
  const client = AgoraRTC.createClient(config);
  return (
    <AgoraRTCProvider client={client}>
      <AgoraChild />
    </AgoraRTCProvider>
  );
}

export default AgoraLiveStreaming;
