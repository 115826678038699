/* eslint-disable max-lines */
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Action, Dispatch } from 'redux';
import styled from 'styled-components';

import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import SingleSlider from '^/components/atoms/SingleSlider';
import { DSMDTMEditPanel } from '^/components/organisms/DSMDTMEditPanel';
import dsPalette from '^/constants/ds-palette';
import { DISABLED_CONTENT_OPACITY, FontFamily } from '^/constants/styles';
import { UseL10n, UseState, useL10n } from '^/hooks';
import { useCalculateDSMDTMParams } from '^/hooks/useCalcuateDSMDTMParams';
import { PatchContent, contentsSelector } from '^/store/duck/Contents';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';
import { determineUnitType } from '^/utilities/imperial-unit';
import { getSingleContentId } from '^/utilities/state-util';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { ContentsListDSMItem } from '../ContentsListDSMItem';
import { ContentsListDTMItem } from '../ContentsListDTMItem';
import { Fallback } from './fallback';
import Text from './text';
import palette from '^/constants/palette';
import { http } from '^/utilities/api';
import { AuthHeader, jsonContentHeader, makeAuthHeader, makeVersionHeader } from '^/store/duck/API';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { UnleashFlagProvider } from '^/components/atoms/UnleashFeatureProvider';
import { useContentsStore } from '^/store/zustand/content/contentStore';

export const CANCELLABLE_CLASS_NAME: HTMLElement['className'] = '_cancellable';
export const contentsListDsmItemClassName: string = 'contents-list-dsm-item';
export const contentsListDtmItemClassName: string = 'contents-list-dsm-item';

const OpactiyControlWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  marginBottom: '15px',
  width: '100%',
});
const Opacity = styled.div({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',

  marginTop: '13px',
});

const ElevationText = styled.div({
  fontSize: '13px',
  fontWeight: 'bold',

  color: dsPalette.title.toString(),
});

const OpacityText = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,

  color: 'var(--color-theme-primary)',
  fontFamily: FontFamily.ROBOTO,
  fontWeight: 500,
}));

const GenerateDTMWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const GenerateDTMButton = styled.button({
  backgroundColor: 'var(--color-theme-primary)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: palette.white.toString(),
  padding: '8px 16px',
  borderRadius: '5px',
  fontSize: '13px',
  fontFamily: FontFamily.ROBOTO,
  fontWeight: 500,
  lineHeight: 1.31,
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
});

const WarningText = styled.p({
  backgroundColor: palette.ContentsList.itemHoverGray.toString(),
  padding: '8px 16px',
  borderRadius: '5px',
  fontSize: '12px',
  fontFamily: FontFamily.ROBOTO,
  fontWeight: 300,
  lineHeight: 1.31,
});
const loadingDivCustomStyle = {
  width: '14px',
  height: '14px',
};
export const HorizontalDivider = styled.div(() => ({
  width: '100%',
  borderTop: `1px solid ${palette.toggleButtonGray.toString()}`,

  marginTop: '13px',
  marginBottom: '13px',
}));
export const HorizontalWideDivider = styled(HorizontalDivider)({
  marginTop: '23px',
  marginBottom: '23px',
});

interface MinMaxNumber {
  min: number;
  max: number;
}

export interface Props {
  readonly content: T.DSMContent | T.DTMContent;
  children: React.ReactNode;
}

interface SelectorState {
  unitType: T.ValidUnitType;
  editingContentId: T.Content['id'] | undefined;
  in3D: boolean;
  in3DPointCloud: boolean;
  isPreventAutoSelect: boolean | undefined;
  opacity: number;
  isProcessingOrFailed: boolean;
  isSelected: boolean;
  isHillshadeMultiplyToggled: boolean;
  hasHillshade: boolean;
  isHillshadeProcessing: boolean;
  dsmContent: T.DSMContent | undefined;
  dtmContent: T.DTMContent | undefined;
  isDSM: boolean;
  isDTM: boolean;
}

export const RawContentsListDSMDTMItem: FC<Props> = ({ content }) => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const [isTempDTMProcessing, setIsTempDTMProcessing]: UseState<boolean> = useState<boolean>(false);

  const [percents]: UseState<MinMaxNumber> = useState<MinMaxNumber>(
    content.config?.type === T.ContentType.DSM && content.config.percents
      ? content.config.percents
      : { min: 0, max: 1 }
  );
  const { getDSMDTMParams } = useCalculateDSMDTMParams();
  const Pages = useSelector((s: T.State) => s.Pages);
  const byId = useContentsStore(s => s.contents.byId);
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const Contents = useSelector((s: T.State) => s.Contents);
  const SharedContents = useSelector((s: T.State) => s.SharedContents);
  const Projects = useSelector((s: T.State) => s.Projects);

  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);

  const { opacity, isProcessingOrFailed, dsmContent, dtmContent, isDTM }: SelectorState =
    useMemo(() => {
      const projectId = Pages.Contents.projectId;
      const projectUnit = SharedContents.projectUnit;
      const project = Projects.projects.byId[projectId ?? NaN];
      const _editingContentId = Pages.Contents.editingContentId;
      if (!projectUnit && !projectId) {
        throw new Error(' No Project Id in Pages.Contents.projectId');
      }

      const dsmId: T.DSMContent['id'] | undefined = getSingleContentId(
        Pages,
        ProjectConfigPerUser,
        T.ContentType.DSM
      );
      const dtmId: T.DTMContent['id'] | undefined = getSingleContentId(
        Pages,
        ProjectConfigPerUser,
        T.ContentType.DTM
      );
      const _isDSM: boolean = content.type === T.ContentType.DSM;
      const isDSMMapSelected: boolean = contentsSelector.isSelected(ProjectConfigPerUser)(dsmId);
      const isDTMMapSelected: boolean = contentsSelector.isSelected(ProjectConfigPerUser)(dtmId);

      const _isSelected: boolean = (() => {
        switch (content.type) {
          case T.ContentType.DSM:
          case T.ContentType.DTM:
            return isDSMMapSelected || isDTMMapSelected;
          default:
            return false;
        }
      })();

      const _hasHillshade: boolean =
        content.type === T.ContentType.DSM && Boolean(content.info.hasHillshade);
      const _isHillshadeProcessing: boolean =
        content.type === T.ContentType.DSM &&
        content.info.hillshadeStatus === T.HillshadeStatus.PROCESSING;
      const _in3D = Pages.Contents.in3D;
      const isHillshadeToggled = Contents.isDSMHillshadeMultiplyToggled;
      return {
        unitType: project ? determineUnitType(project?.unit) : determineUnitType(projectUnit),
        editingContentId: _editingContentId,
        in3D: _in3D,
        in3DPointCloud: Pages.Contents.in3DPointCloud,
        isPreventAutoSelect: Pages.Contents.isPreventAutoSelect,
        opacity: content.config?.opacity !== undefined ? content.config.opacity : 100,
        isProcessingOrFailed: contentsSelector.isProcessingOrFailedByContent(content),
        isSelected: _isSelected,
        isHillshadeMultiplyToggled: isHillshadeToggled ?? false,
        hasHillshade: _hasHillshade,
        isHillshadeProcessing: _isHillshadeProcessing,
        dsmContent: byId[dsmId ?? NaN] as T.DSMContent | undefined,
        dtmContent: byId[dtmId ?? NaN] as T.DTMContent | undefined,
        isDSM: _isDSM,
        isDTM: isDTMMapSelected,
      };
    }, [Contents, Pages, ProjectConfigPerUser, SharedContents, Projects]);
  const [newPercentage, setNewPercentage] = useState(opacity);
  const isColorAndHeightEditorDisabled: boolean =
    dsmContent?.status === T.ContentProcessingStatus.PROCESSING &&
    dtmContent?.status === T.ContentProcessingStatus.PROCESSING;
  const isDTMContentProcessing: boolean =
    dtmContent?.status === T.ContentProcessingStatus.PROCESSING;
  const dsmHeightsAndColors = useSelector((s: T.State) => s.Contents.dsmHeightsAndColors);
  const dsmdtmParams = getDSMDTMParams(dsmContent, dtmContent);

  const updateContent: (opacity: number) => void = newOpacity => {
    setNewPercentage(newOpacity);
    const actions: Action[] = [];
    updateContentConfig(content.id, {
      ...content.config,
      type: T.ContentType.DSM,
      opacity: newOpacity,
    });
    if (dtmContent) {
      updateContentConfig(dtmContent.id, {
        ...dtmContent.config,
        type: T.ContentType.DTM,
        opacity: newOpacity,
      });
    }

    actions.forEach(dispatch);
  };

  const patchContent: (opacity: number, percents: T.DSMConfigPerUser['percents']) => void = (
    newOpacity,
    newPercents
  ) => {
    dispatch(
      PatchContent({
        content: {
          id: content.id,
          config: {
            type: T.ContentType.DSM,
            opacity: newOpacity,
            percents: newPercents,
          },
        },
      })
    );
    if (dtmContent) {
      dispatch(
        PatchContent({
          content: {
            id: dtmContent.id,
            config: {
              ...dtmContent.config,
              type: T.ContentType.DTM,
              opacity: newOpacity,
              percents: newPercents,
            },
          },
        })
      );
    }
  };

  const handleMouseUp: () => void = () => {
    patchContent(newPercentage, percents);
  };

  const handleOpacityChange: (opacityValue: number) => void = opacityValue => {
    updateContent(opacityValue);
  };

  const handleGenerateDTM: () => Promise<void> = async () => {
    /**
     * TODO: @ebraj-angelswing
     * This is temporary, remove it later
     */
    setIsTempDTMProcessing(true);
    return;

    if (!dtmContent) {
      return;
    }
    if (isDTMContentProcessing) {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.CONTENT_PROCESSING,
          errorMessage: l10n(Text.dtmAlreadyBeingProcessed),
        })
      );
      return;
    }
    const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);
    const versionHeader = makeVersionHeader();
    const headers = {
      ...authHeader,
      ...versionHeader,
      ...jsonContentHeader,
    };

    /**
     * TODO: @ebraj-angelswing
     * Need to update here once BE ready
     */
    // @ts-expect-error Remove this later
    const url = makeV2APIURL('contents', dtmContent.id, '_____');
    try {
      const response = await http.post(url, {}, { headers });
      if (response.status === 201) {
        batch(() => {
          dispatch(
            OpenContentPagePopup({
              popup: T.ContentPagePopupType.CONTENT_PROCESSING,
              errorMessage: l10n(Text.dtmBeingProcessed),
            })
          );
          // add content here
        });
      }
    } catch (error) {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.CONTENT_PROCESSING,
          errorMessage: l10n(Text.dtmAlreadyBeingProcessed),
        })
      );
    }
  };

  const colorAndHeightEditor: ReactNode = (
    <>
      <DSMDTMEditPanel
        min={Number(dsmdtmParams.minInput) || 0}
        max={Number(dsmdtmParams.maxInput) || 255}
        defaultHeightsAndColors={dsmdtmParams.defaultHeightsAndColors}
        updatedHeightsAndColors={dsmHeightsAndColors}
        isDisabled={isColorAndHeightEditorDisabled}
      />
    </>
  );

  const opacityControl: ReactNode = useMemo(
    () => (
      <Opacity>
        <OpactiyControlWrapper>
          <ElevationText>Opacity</ElevationText>
          <OpacityText isDisabled={isProcessingOrFailed}>{newPercentage.toFixed(0)}%</OpacityText>
        </OpactiyControlWrapper>
        <SingleSlider
          minValue={0}
          maxValue={100}
          value={newPercentage}
          onChange={handleOpacityChange}
          onMouseUp={handleMouseUp}
          isDisabled={isProcessingOrFailed}
        />
      </Opacity>
    ),
    [
      opacity,
      isProcessingOrFailed,
      content.config,
      dtmContent?.config,
      handleOpacityChange,
      newPercentage,
    ]
  );

  const generateDTM = (
    // dtmContent && dtmContent.status !== T.ContentProcessingStatus.COMPLETED ? (
    <UnleashFlagProvider
      flagName="generate_dtm"
      render={({ isEnabled }) =>
        isEnabled ? (
          <GenerateDTMWrapper>
            <HorizontalWideDivider style={{ margin: '20px 0px' }} />
            <WarningText>
              {isTempDTMProcessing ? l10n(Text.processingDTM) : l10n(Text.noDTMAvailable)}
              {/* {isDTMContentProcessing ? l10n(Text.processingDTM) : l10n(Text.noDTMAvailable)} */}
            </WarningText>
            {/* <GenerateDTMButton disabled={isDTMContentProcessing} onClick={handleGenerateDTM}> */}
            <GenerateDTMButton disabled={isTempDTMProcessing} onClick={handleGenerateDTM}>
              {/* {isDTMContentProcessing ? ( */}
              {isTempDTMProcessing ? (
                <LoadingIcon loadingDivCustomStyle={loadingDivCustomStyle} />
              ) : (
                l10n(Text.generateDTM)
              )}
            </GenerateDTMButton>
          </GenerateDTMWrapper>
        ) : null
      }
    />
  );

  return (
    <ContentsListItem
      className={`${CANCELLABLE_CLASS_NAME} ${
        isDTM ? contentsListDtmItemClassName : contentsListDsmItemClassName
      }`}
      content={content}
      firstBalloonTitle={l10n(Text.terrain)}
    >
      {isDTM ? (
        <ContentsListDTMItem content={dtmContent as T.DTMContent}>
          {opacityControl}
        </ContentsListDTMItem>
      ) : (
        <ContentsListDSMItem content={dsmContent as T.DSMContent}>
          {opacityControl}
        </ContentsListDSMItem>
      )}
      {colorAndHeightEditor}
      {generateDTM}
    </ContentsListItem>
  );
};

export const ContentsListDSMDTMItem: FC<Props> =
  withErrorBoundary(RawContentsListDSMDTMItem)(Fallback);
