/* eslint-disable max-lines */
// import _ from 'lodash-es';
import React, {
  FC,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  MutableRefObject,
  useState,
} from 'react';
import styled from 'styled-components';
import { FileRejection, useDropzone } from 'react-dropzone';
import * as THREE from 'three';
import * as OBC from 'openbim-components';

import Text from './text';
import EquipmentSVG from '^/assets/icons/equipment.svg';
import { ConfirmButton as RawConfirmButton } from '^/components/atoms/Buttons';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { UseL10n, useL10n, usePrevProps, useProjectCoordinateSystem } from '^/hooks';
import DownloadProgressDisplay from '^/components/atoms/DownloadProgressDisplay';
import { ErrorIconSVG } from '^/assets/icons/ifc-icon';
import { useSelector } from 'react-redux';
import * as T from '^/types';
import { useIFCFileStore } from '../IFCFileStore';
import { getEPSGfromProjectionLabel } from '^/utilities/coordinate-util';
import { extractIFCProperties } from '^/utilities/extractIFCProperties';

export const TextLabel = styled.p({
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '10px',

  color: dsPalette.title.toString(),
});

const ViewerWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  height: '400px',
  minWidth: '440px',
  marginTop: '5px',
  border: '1px solid #C7CCD4',
  borderRadius: '4px',
  flexDirection: 'column',
  gap: '13px',
});

const UploadSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const DragAndDropText = styled.div({
  marginTop: '25px',
  marginBottom: '8px',
  textAlign: 'center',
  fontSize: '13px',
  fontWeight: '700',
  whiteSpace: 'nowrap',
});

const OrText = styled.div({
  textAlign: 'center',
  fontSize: '13px',
});

export const BrowseButton = styled(RawConfirmButton)({
  marginTop: '15px',
  padding: '11px 30px',
  // height: '40px',
});

const BrowseAnotherButton = styled('button')<{ isFileuploaded?: boolean; isDisabled?: boolean }>(
  ({ isFileuploaded, isDisabled }) => ({
    minWidth: '90px',
    paddingLeft: '15px',
    paddingRight: '15px',
    height: '48px',
    borderRadius: '6px',

    fontSize: '15px',
    fontWeight: 'bold',
    marginTop: '15px',
    padding: '11px 30px',
    background: isFileuploaded ? palette.white.toString() : 'var(--color-theme-primary)',
    color: isFileuploaded ? '#1F4782' : palette.white.toString(),
    border: isFileuploaded ? '1.5px solid #1F4782' : '',
    cursor: isDisabled ? 'wait' : 'pointer',

    ':disabled': {
      opacity: 0.6,
    },
  })
);

const SupportedFileText = styled.span({
  marginBottom: '10px',
  marginTop: '12px',
  fontSize: '12px',
  color: '#C7CCD4',
});

const LoadingWrapper = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ProgressText = styled.span<{ fontSize?: number; color: string }>(({ color }) => ({
  color: color,
  textAlign: 'center',
  // fontSize: `${fontSize}px`,
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '18px',
}));

export const ThreeJsContainer = styled.div<{ isFileuploaded: boolean }>(({ isFileuploaded }) => ({
  width: '396px',
  height: '268px',
  opacity: 0,
  border: '1px solid #C7C7C7',
  borderRadius: '4px',
  position: !isFileuploaded ? 'absolute' : 'static',
  zIndex: !isFileuploaded ? -1 : 0,
}));

const ErrorContainer = styled.div<{ hasFileError?: boolean }>(({ hasFileError }) => ({
  display: 'flex',
  width: '396px',
  padding: '12px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '8px',
  background: hasFileError ? 'rgba(241, 164, 164, 0.20)' : '#F5F5F5',
  marginLeft: '20px',
  marginRight: '20px',
  marginBottom: '20px',
  boxSizing: 'border-box',
  borderRadius: '4px',
}));

const ErrorHeadlineWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
});

const ErrorHeadline = styled.div({
  color: '#E03A3A',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
});

const ErrorDescription = styled.span<{ hasFileError?: boolean }>(({ hasFileError }) => ({
  color: hasFileError ? '#E03A3A' : '#4D4C4C',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  whiteSpace: 'pre-line',
  textAlign: 'center',
}));

const ITEM_HEIGHT: number = 28;
const LIST_MAX_ITEM: number = 3;
const TIMER_MIILISEC: number = 5000; // 5000 milliseconds = 5 seconds

export interface Props {
  readonly hasError?: boolean;
  readonly hasMultipleFiles?: boolean;
  readonly extensions: string[];
  readonly files: File[];
  readonly coordinateSystem: T.CoordinateSystem | undefined;
  setFiles: Dispatch<SetStateAction<File[]>>;
  setFilesLoaded(loaded: boolean): void;
}

export const BIMFileInput: FC<Props> = ({ files, setFiles, setFilesLoaded, coordinateSystem }) => {
  const [l10n]: UseL10n = useL10n();
  const listRef: MutableRefObject<HTMLUListElement | null> = useRef(null);
  const prevFiles: File[] | undefined = usePrevProps<File[]>(files);

  const [uploadedModel, setUploadedModel] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(null);
  const [fileError, setFileError] = useState<FileRejection | null>(null);
  const [ifcParseError, setIFCParseError] = useState<Error | null>(null);
  const [modelIfc, setModelIfc] = useState<THREE.Object3D | null>(null);
  const [startTimeStamp, setStartTimeStamp] = useState(0);
  const [endTimeStamp, setEndTimeStamp] = useState(0);

  const { isInUploadIFC } = useSelector((state: T.State) => state.Pages.Contents);
  const setIFCModel = useIFCFileStore(s => s.setIFCModel);

  const renderer = useRef<any | null>();
  const scene = useRef(new THREE.Scene());
  const camera = useRef<THREE.PerspectiveCamera | null>(null);
  const viewerRef = useRef<HTMLDivElement>(null);
  const ifcLoader = useRef<any | undefined>();
  const viewer = useRef<any | undefined>();

  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();
  const projectionSystemLabel: string = getEPSGfromProjectionLabel(projectProjection);

  const handleTimer = () => {
    setEndTimeStamp(Math.floor(Date.now() / 1000));
  };

  useEffect(() => {
    initializeComponents();

    return () => {
      resetComponents();
    };
  }, []);

  const initializeComponents = () => {
    if (!viewerRef.current) {
      return;
    }
    viewer.current = new OBC.Components();

    const sceneComponent = new OBC.SimpleScene(viewer.current);
    viewer.current._renderer = new OBC.PostproductionRenderer(viewer.current, viewerRef.current);

    viewer.current.scene = sceneComponent;
    scene.current = sceneComponent.get();

    const ambientLight = new THREE.AmbientLight(0xe6e7e4, 1);
    const directionalLight = new THREE.DirectionalLight(0xf9f9f9, 0.75);
    directionalLight.position.set(10, 50, 10);
    scene.current.add(ambientLight, directionalLight);
    scene.current.background = new THREE.Color('#202932');

    const cameraComponent = new OBC.OrthoPerspectiveCamera(viewer.current);
    viewer.current.camera = cameraComponent;

    camera.current = viewer.current.camera.get();
    renderer.current = viewer.current.renderer.get();

    viewer.current.uiEnabled = false;
    viewer.current.init();

    cameraComponent.updateAspect();
    viewer.current.renderer.postproduction.enabled = true;

    new OBC.SimpleGrid(viewer.current, new THREE.Color(0x666666));
  };

  const initLoader = async () => {
    setIsLoading(true);
    if (viewerRef.current) {
      viewerRef.current.style.opacity = '0';
    }

    const loader = new OBC.FragmentIfcLoader(viewer.current);

    loader.settings.wasm = {
      path: '/web-ifc/',
      absolute: false,
    };
    // await loader.setup();
    ifcLoader.current = loader;
  };

  const resetComponents = () => {
    if (viewer.current) {
      viewer.current.tools.list = {};
    }

    if (ifcLoader.current) {
      ifcLoader.current.dispose();
    }
  };

  useEffect(() => {
    const areFilesOverLimit: boolean = Boolean(
      prevFiles && prevFiles.length < files.length && files.length > LIST_MAX_ITEM
    );

    if (areFilesOverLimit) {
      listRef?.current?.scrollTo(0, files.length * ITEM_HEIGHT);
    }
  }, [files.length]);

  useEffect(() => {
    if (isLoading) {
      const intervalId = setInterval(handleTimer, TIMER_MIILISEC);
      setTimerInterval(intervalId);
      if (viewerRef.current) {
        viewerRef.current.style.opacity = '0';
      }
    } else {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    }

    // Clean up the timer when the component unmounts
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && !uploadedModel && isInUploadIFC && files.length) {
      setUploadedModel(files[0]);
      setIsLoading(true);
      setStartTimeStamp(Math.floor(Date.now() / 1000));
      // setEndTimeStamp(0);

      void initLoader();

      void loadIFCFile(files[0]);
    }
  }, [isInUploadIFC, isLoading, files, uploadedModel]);

  useEffect(() => {
    if (coordinateSystem && modelIfc) {
      extractIFCProperties(modelIfc, projectionSystemLabel, coordinateSystem);
    }
  }, [coordinateSystem]);

  const onDrop = async (acceptedFiles: File[]) => {
    if (modelIfc) {
      scene.current.remove(modelIfc);
    }
    resetComponents();
    setFileError(null);
    setIFCParseError(null);

    await initLoader();
    if (acceptedFiles.length > 0) {
      setUploadedModel(acceptedFiles[0]);
      setFiles([...acceptedFiles]);
      // setThreeDivElement(threeRef);
      setStartTimeStamp(Math.floor(Date.now() / 1000));
      // setEndTimeStamp(0);
      await loadIFCFile(acceptedFiles[0]);
    }
  };

  const loadIFCFile = async (file: File) => {
    if (!ifcLoader.current) {
      return;
    }
    // await viewer.current.ui.dispose();
    setFilesLoaded(false);

    ifcLoader.current.settings.webIfc.COORDINATE_TO_ORIGIN = true;
    ifcLoader.current.settings.webIfc.OPTIMIZE_PROFILES = true;
    ifcLoader.current.settings.includeProperties = false;

    // ifcLoader.current.settings.webIfc.MEMORY_LIMIT = 300;

    const data = await file.arrayBuffer();
    const buffer = new Uint8Array(data);

    try {
      const model = await ifcLoader.current.load(buffer, 'ifc');
      extractIFCProperties(model, projectionSystemLabel, coordinateSystem);

      scene.current.add(model);
      setModelIfc(model);
      setIFCModel(model);

      if (viewerRef.current) {
        viewerRef.current.style.opacity = '1';
      }

      setFilesLoaded(true);
      setIsLoading(false);
    } catch (error) {
      setIFCParseError(error);
      setIsLoading(false);
      setFilesLoaded(false);
      setUploadedModel(null);
      resetComponents();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/ifc': ['.ifc'],
    },
    maxSize: 209715200,
    onDropRejected: file => {
      if (file.length) {
        setFileError(file[0]);
      }
    },
  });

  const ErrorComponent = () => {
    const message = fileError?.errors[0].code;

    switch (message) {
      case 'file-too-large':
        return (
          <ErrorContainer hasFileError={Boolean(message)}>
            <ErrorDescription hasFileError={Boolean(message)}>
              {l10n(Text.largeFileErrorTitle(fileError ? fileError.file.name : ''))}
            </ErrorDescription>
            <ErrorDescription hasFileError={false}>{l10n(Text.filerLargeError)}</ErrorDescription>
          </ErrorContainer>
        );

      case 'file-invalid-type':
        return (
          <ErrorContainer hasFileError={Boolean(message)}>
            <ErrorDescription hasFileError={Boolean(message)}>
              {l10n(Text.notSupportedFormat)}
            </ErrorDescription>
          </ErrorContainer>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <TextLabel>{l10n(Text.attachFile)}</TextLabel>
      <ViewerWrapper>
        {!uploadedModel ? (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <UploadSection>
              <EquipmentSVG />
              {fileError && <ErrorComponent />}
              {ifcParseError && (
                <ErrorContainer hasFileError={true}>
                  <ErrorDescription hasFileError={true}>
                    {l10n(Text.notIFCFileType)}
                  </ErrorDescription>
                </ErrorContainer>
              )}

              <DragAndDropText>{l10n(Text.dragAndDrop)}</DragAndDropText>
              <OrText>{l10n(Text.or)}</OrText>
              <BrowseButton>{l10n(Text.browse)}</BrowseButton>
              <SupportedFileText>{l10n(Text.supportedFiles)}</SupportedFileText>
            </UploadSection>
          </div>
        ) : (
          <>
            {isLoading && (
              <LoadingWrapper>
                <DownloadProgressDisplay />
                {startTimeStamp + 15 <= endTimeStamp ? (
                  <ErrorContainer>
                    <ErrorHeadlineWrapper>
                      <ErrorIconSVG />
                      <ErrorHeadline>{l10n(Text.errorHeadline)}</ErrorHeadline>
                    </ErrorHeadlineWrapper>
                    <ErrorDescription>
                      {l10n(Text.errorDescription(uploadedModel ? uploadedModel.name : ''))}
                    </ErrorDescription>
                  </ErrorContainer>
                ) : (
                  <>
                    <ProgressText fontSize={13} color={palette.black.toString()}>
                      {l10n(Text.processingText)}
                    </ProgressText>
                    <SupportedFileText>{uploadedModel?.name || ''}</SupportedFileText>
                  </>
                )}
              </LoadingWrapper>
            )}
          </>
        )}
        <ThreeJsContainer isFileuploaded={Boolean(uploadedModel)} ref={viewerRef} />
        {!isLoading && startTimeStamp + 15 <= endTimeStamp && (
          <ErrorContainer hasFileError={true}>
            <ErrorDescription hasFileError={true}>{l10n(Text.notOptimizedError)}</ErrorDescription>
          </ErrorContainer>
        )}

        {uploadedModel && (
          <BrowseAnotherButton
            disabled={isLoading}
            isDisabled={isLoading}
            isFileuploaded={Boolean(modelIfc)}
            {...getRootProps()}
          >
            {l10n(Text.browseAnotherFile)}
          </BrowseAnotherButton>
        )}
      </ViewerWrapper>
    </>
  );
};
