import * as T from '^/types';

export default {
  groupListHeader: {
    personal: {
      [T.ContentPageTabType.MAP]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.OVERLAY]: {
        [T.Language.KO_KR]: '비공개',
        [T.Language.EN_US]: 'Personal space',
      },
      [T.ContentPageTabType.MEASUREMENT]: {
        [T.Language.KO_KR]: '비공개',
        [T.Language.EN_US]: 'Personal space',
      },
      [T.ContentPageTabType.PHOTO]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.ESS]: {
        [T.Language.KO_KR]: '비공개',
        [T.Language.EN_US]: 'Personal space',
      },
      [T.ContentPageTabType.DASHBOARD]: {
        [T.Language.KO_KR]: '비공개',
        [T.Language.EN_US]: 'Personal space',
      },
      [T.ContentPageTabType.DRONE_STATION]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_PLAN]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_SCHEDULE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.PRESENTATION]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.VIEWPOINT_CAPTURE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
    },
    open: {
      [T.ContentPageTabType.MAP]: {
        [T.Language.KO_KR]: '지형 편집',
        [T.Language.EN_US]: 'Terrain Editing',
      },
      [T.ContentPageTabType.OVERLAY]: {
        [T.Language.KO_KR]: '공개',
        [T.Language.EN_US]: 'Open space',
      },
      [T.ContentPageTabType.MEASUREMENT]: {
        [T.Language.KO_KR]: '공개',
        [T.Language.EN_US]: 'Open space',
      },
      [T.ContentPageTabType.PHOTO]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.ESS]: {
        [T.Language.KO_KR]: '공개',
        [T.Language.EN_US]: 'Open space',
      },
      [T.ContentPageTabType.DASHBOARD]: {
        [T.Language.KO_KR]: '공개',
        [T.Language.EN_US]: 'Open space',
      },
      [T.ContentPageTabType.DRONE_STATION]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_PLAN]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.FLIGHT_SCHEDULE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
      [T.ContentPageTabType.PRESENTATION]: {
        [T.Language.KO_KR]: '프레젠테이션',
        [T.Language.EN_US]: 'Presentations',
      },
      [T.ContentPageTabType.VIEWPOINT_CAPTURE]: {
        [T.Language.KO_KR]: '',
        [T.Language.EN_US]: '',
      },
    },
  },
  tooltipUnselectAllText: {
    [T.Language.KO_KR]: '모두 해제',
    [T.Language.EN_US]: 'Unselect All',
  },
  tooltipCopyText: {
    [T.Language.KO_KR]: '그룹 복제',
    [T.Language.EN_US]: 'Copy group',
  },
  tooltipDeleteText: {
    [T.Language.KO_KR]: '선택 삭제',
    [T.Language.EN_US]: 'Delete selection',
  },
  tooltipAddFolderText: {
    [T.Language.KO_KR]: '새 그룹 만들기',
    [T.Language.EN_US]: 'Make a new group',
  },
  tooltipExportText: {
    [T.Language.KO_KR]: '내보내기',
    [T.Language.EN_US]: 'Export',
  },
};
