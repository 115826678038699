import { findIndex, forEach } from 'lodash-es';
import {
  BufferAttribute,
  BufferGeometry,
  Matrix3,
  Matrix4,
  Mesh,
  Triangle,
  Vector3,
  Vector4,
} from 'three';
import { TriangleSplitter } from 'three-bvh-csg';
import { generateByBarycoord, IntersectionMap } from './GeometryUtils';

/**
 * Triangle intersected data
 * @class
 */
class TriangleIntersectData {
  public triangle: Triangle;
  public intersects: any = {};
  public constructor(tri: Triangle) {
    this.triangle = new Triangle().copy(tri);
    this.intersects = {};
  }

  public addTriangle(index: number, tri: Triangle) {
    this.intersects[index] = new Triangle().copy(tri);
  }

  public getIntersectArray() {
    const array = [];
    const { intersects } = this;
    // eslint-disable-next-line guard-for-in
    for (const key in intersects) {
      array.push(intersects[key]);
    }

    return array;
  }
}
/**
 * Set of triangle intersected
 * @class
 */
class TriangleIntersectionSets {
  public data: any = {};
  public constructor() {
    this.data = {};
  }

  public addTriangleIntersection(ia: number, triA: Triangle, ib: number, triB: Triangle) {
    const { data } = this;
    if (!data[ia]) {
      data[ia] = new TriangleIntersectData(triA);
    }

    data[ia].addTriangle(ib, triB);
  }

  public getTrianglesAsArray(id = null) {
    const { data } = this;
    const arr = [];

    if (id !== null) {
      if (id in data) {
        arr.push(data[id].triangle);
      }
    } else {
      // eslint-disable-next-line guard-for-in
      for (const key in data) {
        arr.push(data[key].triangle);
      }
    }

    return arr;
  }

  public getTriangleIndices() {
    // eslint-disable-next-line radix
    return Object.keys(this.data).map(i => parseInt(i));
  }

  public getIntersectionIndices(id: number) {
    const { data } = this;
    if (!data[id]) {
      return [];
    } else {
      // eslint-disable-next-line radix
      return Object.keys(data[id].intersects).map(i => parseInt(i));
    }
  }

  public getIntersectionsAsArray(id = null, id2 = null) {
    const { data } = this;
    const triSet = new Set();
    const arr: any = [];

    const addTriangles = (key: any) => {
      if (!data[key]) {
        return;
      }

      if (id2 !== null) {
        if (data[key].intersects[id2]) {
          arr.push(data[key].intersects[id2]);
        }
      } else {
        const intersects = data[key].intersects;
        for (const key2 in intersects) {
          if (!triSet.has(key2)) {
            triSet.add(key2);
            arr.push(intersects[key2]);
          }
        }
      }
    };

    if (id !== null) {
      addTriangles(id);
    } else {
      // eslint-disable-next-line guard-for-in
      for (const key in data) {
        addTriangles(key);
      }
    }

    return arr;
  }

  public reset() {
    this.data = {};
  }
}
/**
 * Debug for find for collectIntersectingTriangles
 * @see GeometryUtils.collectIntersectingTriangles
 * @class
 */
export class OperationDebugData {
  public enabled = false;
  public triangleIntersectsA: any = new TriangleIntersectionSets();
  public triangleIntersectsB: any = new TriangleIntersectionSets();
  public intersectionEdges: any = [];
  public constructor() {
    this.enabled = false;
    this.triangleIntersectsA = new TriangleIntersectionSets();
    this.triangleIntersectsB = new TriangleIntersectionSets();
    this.intersectionEdges = [];
  }

  public addIntersectingTriangles(ia: number, triA: Triangle, ib: number, triB: Triangle) {
    const { triangleIntersectsA, triangleIntersectsB } = this;
    triangleIntersectsA.addTriangleIntersection(ia, triA, ib, triB);
    triangleIntersectsB.addTriangleIntersection(ib, triB, ia, triA);
  }

  public addEdge(edge: any) {
    this.intersectionEdges.push(edge.clone());
  }

  public reset() {
    this.triangleIntersectsA.reset();
    this.triangleIntersectsB.reset();
    this.intersectionEdges = [];
  }
}

const _vec4a = new Vector4();
const _vec4b = new Vector4();
const _vec4c = new Vector4();
const _matrix = new Matrix4();
const _normalMatrix = new Matrix3();
const _triA = new Triangle();
const _triB = new Triangle();
const _barycoordTri = new Triangle();

/**
 * split the triangle
 */

export const performSplitTriangleOperations = (
  a: Mesh,
  b: Mesh,
  intersectionMap: IntersectionMap, //aIntersections
  splitter: TriangleSplitter
): any => {
  const no = true;
  const attributes: any = {};
  const indices = [...(a.geometry.index!.array as number[])];
  const deleteIndices = [];

  if (no) {
    const bufferNoIndex = a.geometry.toNonIndexed();
    forEach(bufferNoIndex.attributes, (v, k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      attributes[k] = [...v.array];
    });
  } else {
    forEach(a.geometry.attributes, (v, k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      attributes[k] = [...v.array];
    });
  }
  const invertedGeometry = a.matrixWorld.determinant() < 0;

  // transforms into the local frame of matrix b
  _matrix.copy(b.matrixWorld).invert().multiply(a.matrixWorld);

  _normalMatrix.getNormalMatrix(a.matrixWorld).multiplyScalar(invertedGeometry ? -1 : 1);

  // const groupIndices = a.geometry.groupIndices;
  const aIndex = a.geometry.index!;
  const aPosition = a.geometry.attributes.position;

  const bIndex = b.geometry.index!;

  const bPosition = b.geometry.attributes.position;
  const splitIds = intersectionMap.ids;
  const intersectionSet = intersectionMap.intersectionSet;

  // iterate over all split triangle indices
  for (let i = 0, l = splitIds.length; i < l; i++) {
    const vertices = [];
    const ia = splitIds[i];
    // get the triangle in the geometry B local frame
    const ia3 = 3 * ia;
    const ia0 = aIndex.getX(ia3 + 0);
    const ia1 = aIndex.getX(ia3 + 1);
    const ia2 = aIndex.getX(ia3 + 2);
    _triA.a.fromBufferAttribute(aPosition, ia0).applyMatrix4(_matrix);
    _triA.b.fromBufferAttribute(aPosition, ia1).applyMatrix4(_matrix);
    _triA.c.fromBufferAttribute(aPosition, ia2).applyMatrix4(_matrix);
    vertices.push({ index: ia0, vertex: _triA.a });
    vertices.push({ index: ia1, vertex: _triA.b });
    vertices.push({ index: ia2, vertex: _triA.c });

    // initialize the splitter with the triangle from geometry A
    splitter.reset();
    splitter.initialize(_triA);

    // split the triangle with the intersecting triangles from B
    const intersectingIndices = intersectionSet[ia];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let ib = 0; ib < intersectingIndices.length; ib++) {
      const ib3 = 3 * intersectingIndices[ib];
      const ib0 = bIndex.getX(ib3 + 0);
      const ib1 = bIndex.getX(ib3 + 1);
      const ib2 = bIndex.getX(ib3 + 2);
      _triB.a.fromBufferAttribute(bPosition, ib0);
      _triB.b.fromBufferAttribute(bPosition, ib1);
      _triB.c.fromBufferAttribute(bPosition, ib2);
      splitter.splitByTriangle(_triB);
    }

    // for all triangles in the split result
    const triangles = splitter.triangles;
    if (triangles.length === 0) {
      continue;
    }

    let counter = a.geometry.attributes.position.count;

    const newPositions = [];

    const map: any = [];

    const distance = 0;

    const uv1 = attributes.uv.slice(ia3 * 2 + 0, ia3 * 2 + 2);
    const uv2 = attributes.uv.slice(ia3 * 2 + 2, ia3 * 2 + 4);
    const uv3 = attributes.uv.slice(ia3 * 2 + 4, ia3 * 2 + 6);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let t = 0; t < triangles.length; t++) {
      const clippedTri = triangles[t];
      // for (const clippedTri of triangles) {
      const localNewPosition = [];
      const localIndices = [];
      //TODO: debug section
      //includes.includes(t)
      // {
      //   const trisHelper3 = new TriangleSetHelper([clippedTri]);
      //   trisHelper3.children[0].material.depthTest = false;
      //   trisHelper3.children[0].renderOrder = 999999;
      //   trisHelper3.children[1].material.depthTest = false;
      //   trisHelper3.children[1].renderOrder = 999999;
      //   this.viewer.scene.add(trisHelper3);
      //   if (colors[t]) {
      //     trisHelper3.color.set(colors[t]);
      //   } else {
      //     trisHelper3.color.set('white');
      //   }
      // }

      {
        const edge1 = new Vector3().subVectors(clippedTri.a, clippedTri.b);
        const edge2 = new Vector3().subVectors(clippedTri.b, clippedTri.c);
        const normal1 = new Vector3().crossVectors(edge1, edge2).normalize();

        const tmpVer = [clippedTri.a, clippedTri.b, clippedTri.c];
        const tmp = [false, false, false];
        for (const item of vertices) {
          const isA = clippedTri.a.equals(item.vertex);
          const isB = clippedTri.b.equals(item.vertex);
          const isC = clippedTri.c.equals(item.vertex);
          if (isA || isB || isC) {
            //TODO: debug section
            // const cubeCLone1 = cube4.clone();
            // cubeCLone1.position.set(
            //   a.geometry.attributes.position.getX(item.index),
            //   a.geometry.attributes.position.getY(item.index),
            //   a.geometry.attributes.position.getZ(item.index)
            // );
            // this.viewer.scene.add(cubeCLone1);
            localIndices.push(item.index);
            // indices.push(item.index);
            if (isA) {
              const clone = clippedTri.a.clone();
              clone.z = clone.z + distance;
              tmp[0] = true;
              localNewPosition.push(clone);
            } else if (isB) {
              const clone = clippedTri.b.clone();
              clone.z = clone.z + distance;
              tmp[1] = true;
              localNewPosition.push(clone);
            } else if (isC) {
              const clone = clippedTri.c.clone();
              clone.z = clone.z + distance;
              tmp[2] = true;
              localNewPosition.push(clone);
            }
          }
        }
        forEach(tmp, (v, k) => {
          if (!v) {
            const vertex = tmpVer[k];
            const clone = vertex.clone();
            clone.z = clone.z + distance;
            localNewPosition.push(clone);
            const index = findIndex(map, (d: any) => d.vertex.equals(vertex));
            if (index >= 0) {
              // indices.push(map[index].index);
              localIndices.push(map[index].index);
            } else {
              // indices.push(counter);
              localIndices.push(counter);
              map.push({ index: counter, vertex });
              counter++;
            }
          }
        });

        const edge3 = new Vector3().subVectors(localNewPosition[0], localNewPosition[1]);
        const edge4 = new Vector3().subVectors(localNewPosition[1], localNewPosition[2]);
        const normal2 = new Vector3().crossVectors(edge3, edge4).normalize();
        if (normal1.dot(normal2) < 0) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          const findA = findIndex(localNewPosition, v => v.equals(clippedTri.a));
          const findB = findIndex(localNewPosition, v => v.equals(clippedTri.b));
          const findC = findIndex(localNewPosition, v => v.equals(clippedTri.c));
          newPositions.push(
            localNewPosition[findA],
            localNewPosition[findB],
            localNewPosition[findC]
          );
          indices.push(localIndices[findA], localIndices[findB], localIndices[findC]);
        } else {
          newPositions.push(localNewPosition[0], localNewPosition[1], localNewPosition[2]);
          indices.push(localIndices[0], localIndices[1], localIndices[2]);
        }
      }

      {
        _triA.getBarycoord(clippedTri.a, _barycoordTri.a);
        _triA.getBarycoord(clippedTri.b, _barycoordTri.b);
        _triA.getBarycoord(clippedTri.c, _barycoordTri.c);

        _vec4a.set(uv1[0], uv1[1], 0, 0);
        _vec4b.set(uv2[0], uv2[1], 0, 0);
        _vec4c.set(uv3[0], uv3[1], 0, 0);
        generateByBarycoord(_vec4a, _vec4b, _vec4c, attributes.uv, 2, false, _barycoordTri);
      }
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    forEach(newPositions, position => {
      const clone = position.clone();
      // clone.z = clone.z + 10;
      attributes.position.push(...clone.toArray());
    });

    {
      //TODO: debug section
      // for (const clippedTri of triangles) {
      //   const cubeCLone1 = cube2.clone();
      //   cubeCLone1.position.copy(clippedTri.a);
      //   const cubeCLone2 = cube2.clone();
      //   cubeCLone2.position.copy(clippedTri.b);
      //   const cubeCLone3 = cube2.clone();
      //   cubeCLone3.position.copy(clippedTri.c);
      //   this.viewer.scene.add(cubeCLone1, cubeCLone2, cubeCLone3);
      // }
      ///////////////
      // const cubeCLone1 = cube3.clone();
      // cubeCLone1.position.fromArray(attributes.position.slice(attributes.position.length - 3));
      // const cubeCLone2 = cube3.clone();
      // cubeCLone2.position.fromArray(attributes.position.slice(attributes.position.length - 6));
      // const cubeCLone3 = cube3.clone();
      // cubeCLone3.position.fromArray(attributes.position.slice(attributes.position.length - 9));
      // this.viewer.scene.add(cubeCLone1, cubeCLone2, cubeCLone3);
      // const tri = new Triangle(cubeCLone1.position, cubeCLone2.position, cubeCLone3.position);
      // const trisHelper3 = new TriangleSetHelper([tri]);
      // trisHelper3.children[0].material.depthTest = false;
      // trisHelper3.children[0].renderOrder = 999999;
      // trisHelper3.children[1].material.depthTest = false;
      // trisHelper3.children[1].renderOrder = 999999;
      // trisHelper3.color.set('red');
      // this.viewer.scene.add(trisHelper3);
    }

    attributes.position.slice(ia3 * 3 + 0, ia3 * 3 + 3);
    attributes.position.slice(ia3 * 3 + 3, ia3 * 3 + 6);
    attributes.position.slice(ia3 * 3 + 6, ia3 * 3 + 9);

    // attributes.position.splice(ia3 * 3 + 0, 3);
    // attributes.position.splice(ia3 * 3 + 0, 3);
    // attributes.position.splice(ia3 * 3 + 0, 3);

    // indices.splice(ia3, 3);
    deleteIndices.push(ia3);
    {
      //TODO: debug section
      // const cubeCLone11 = cube4.clone();
      // cubeCLone11.position.fromArray(d1);
      // const cubeCLone22 = cube4.clone();
      // cubeCLone22.position.fromArray(d2);
      // const cubeCLone33 = cube4.clone();
      // cubeCLone33.position.fromArray(d3);
      // this.viewer.scene.add(cubeCLone11, cubeCLone22, cubeCLone33);
    }

    if (no) {
      a.geometry = new BufferGeometry();
      a.geometry.setAttribute(
        'position',
        new BufferAttribute(new Float32Array(attributes.position), 3, false)
      );
      a.geometry.setAttribute('uv', new BufferAttribute(new Float32Array(attributes.uv), 2, false));
      a.geometry.setAttribute(
        'normal',
        new BufferAttribute(new Float32Array(attributes.normal), 3, false)
      );
      // a.geometry = a.geometry.toIndexed(); // mergeVertices(a.geometry);
    } else {
      a.geometry.setIndex(indices);
      a.geometry.setAttribute(
        'position',
        new BufferAttribute(new Float32Array(attributes.position), 3)
      );
      a.geometry.setAttribute('uv', new BufferAttribute(new Float32Array(attributes.uv), 2, false));
      a.geometry.setAttribute(
        'normal',
        new BufferAttribute(new Float32Array(attributes.normal), 3, false)
      );
    }
  }
  deleteIndices.forEach(idx => {
    indices.splice(idx, 3);
    attributes.position.splice(idx * 3 + 0, 3);
    attributes.position.splice(idx * 3 + 0, 3);
    attributes.position.splice(idx * 3 + 0, 3);
    attributes.uv.splice(idx * 2 + 0, 2);
    attributes.uv.splice(idx * 2 + 0, 2);
    attributes.uv.splice(idx * 2 + 0, 2);
  });

  return a.geometry;
};
