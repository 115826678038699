import React, { FC } from 'react';

import { FlightPlanContentsListRoot } from '.';
import {
  ErrorText,
  NOT_ALLOWED_CLASS_NAME,
  defaultToastErrorOption,
  useInitialToast,
} from '^/hooks';
import * as T from '^/types';

export const Fallback: FC = () => {
  useInitialToast({
    type: T.Toast.ERROR,
    content: {
      title: ErrorText.contentsList.title,
      description: ErrorText.contentsList.description,
    },
    option: defaultToastErrorOption,
  });

  return <FlightPlanContentsListRoot className={NOT_ALLOWED_CLASS_NAME} />;
};
