import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import * as T from '^/types';
import { GeoCoord } from 'three-loader-3dtiles';
import { Content } from '^/types';

interface HoveredCoordinates {
  x: number | string;
  y: number | string;
}

interface ContextMenuPosition {
  top: number;
  left: number;
}

export interface PreservedCameraControlPositions {
  target: GeoCoord;
  offset: GeoCoord;
  position: GeoCoord;
}

interface UtilsStoreState {
  elevation: string;
  isContextOpen: boolean;
  isLatLongTooltipEnabled: boolean;
  contextMenuPosition: ContextMenuPosition | null;
  isSidebarContextMenuOpen: boolean;
  contextMenuContentType: 'content' | 'group' | null;
  contextMenuContentId: Content['id'] | null;
  hoveredCoordinates: HoveredCoordinates | null;
  clickedCoordinates: HoveredCoordinates | null;
  preservedCameraControlPositions: PreservedCameraControlPositions | null;
  twoDDisplayZoom: number;
  twoDDisplayCenter: number[];
  elevApiStatus: T.APIStatus;
  editingTitleContentId: Content['id'] | null;
  isEssModelIconEnabled: boolean;
}

interface UtilsStoreActions {
  setPreservedCameraPositions(
    preservedCameraControlPositions: PreservedCameraControlPositions | null
  ): void;
  setHoveredCoordinates(hoveredCoordinates: HoveredCoordinates | null): void;
  setClickedCoordinates(clickedCoordinates: HoveredCoordinates | null): void;
  setIsLatLongTooltipEnabled(isLatLongTooltipEnabled: boolean): void;
  setIsEssModelIconEnabled(isEssModelIconEnabled: boolean): void;
  setElevation(elevation: string): void;
  setIsContextOpen(isContextOpen: boolean): void;
  setContextMenuPosition(contextMenuPosition: ContextMenuPosition | null): void;
  setTwoDDisplayZoom(twoDDisplayZoom: number): void;
  setTwoDDisplayCenter(twoDDisplayCenter: number[]): void;
  setElevApiStatus(elevApiStatus: T.APIStatus): void;
  reset(): void;
  setIsSidebarContextMenuOpen(isSidebarContextMenuOpen: boolean): void;
  setContextMenuContentType(contextMenuContentType: 'content' | 'group' | null): void;
  setContextMenuContentId(contextMenuContentId: Content['id'] | null): void;
  setEditingTitleContentId(editingTitleContentId: Content['id'] | null): void;
  resetContextMenu(): void;
}

const initialState: UtilsStoreState = {
  elevation: '',
  isContextOpen: false,
  isSidebarContextMenuOpen: false,
  hoveredCoordinates: null,
  clickedCoordinates: null,
  isLatLongTooltipEnabled: false,
  contextMenuPosition: null,
  preservedCameraControlPositions: null,
  twoDDisplayZoom: 16,
  twoDDisplayCenter: [],
  elevApiStatus: T.APIStatus.IDLE,
  contextMenuContentId: null,
  contextMenuContentType: null,
  editingTitleContentId: null,
  isEssModelIconEnabled: false,
};

type UtilsStore = UtilsStoreState & UtilsStoreActions;

export const utilsStore = createStore<UtilsStore>()(
  devtools(set => ({
    ...initialState,
    setPreservedCameraPositions(preservedCameraControlPositions) {
      set({ preservedCameraControlPositions });
    },
    setHoveredCoordinates(hoveredCoordinates) {
      set({ hoveredCoordinates });
    },
    setClickedCoordinates(clickedCoordinates) {
      set({ clickedCoordinates });
    },
    setIsLatLongTooltipEnabled(isLatLongTooltipEnabled) {
      set({ isLatLongTooltipEnabled });
    },
    setElevation(elevation) {
      set({ elevation });
    },
    setIsContextOpen(isContextOpen) {
      set({ isContextOpen });
    },
    setIsSidebarContextMenuOpen(isSidebarContextMenuOpen) {
      set({ isSidebarContextMenuOpen });
    },
    setContextMenuPosition(contextMenuPosition) {
      set({ contextMenuPosition });
    },
    setTwoDDisplayZoom(twoDDisplayZoom) {
      set({ twoDDisplayZoom });
    },
    setTwoDDisplayCenter(twoDDisplayCenter) {
      set({ twoDDisplayCenter });
    },
    setElevApiStatus(elevApiStatus) {
      set({ elevApiStatus });
    },
    setContextMenuContentType(contextMenuContentType) {
      set({ contextMenuContentType });
    },
    setContextMenuContentId(contextMenuContentId) {
      set({ contextMenuContentId });
    },
    setEditingTitleContentId(editingTitleContentId) {
      set({ editingTitleContentId });
    },
    resetContextMenu() {
      set({
        isSidebarContextMenuOpen: false,
        contextMenuContentType: null,
        contextMenuContentId: null,
        contextMenuPosition: null,
      });
    },
    setIsEssModelIconEnabled(isEssModelIconEnabled) {
      set({ isEssModelIconEnabled });
    },
    reset() {
      set(initialState);
    },
  }))
);

export function useUtilsStore(): UtilsStore;
export function useUtilsStore<T>(selector: (state: UtilsStore) => T): T;
export function useUtilsStore<T>(selector?: (state: UtilsStore) => T) {
  return useStore(utilsStore, selector!);
}
