import { useSelector } from 'react-redux';
import { useAuthHeader } from '../useAuthHeader';
import * as T from '^/types';
import {
  CreateEarthworkRequestParam,
  CreateEarthworkResponse,
  Earthwork,
  GetEarthworkAnalysisResponse,
  GetEarthWorklistsResponse,
  SyncStatus,
} from '^/store/earthwork/types';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import { http } from '^/utilities/api';
import { makeV2APIURL } from '^/store/duck/API';

const useEarthWorkContents = () => {
  const authHeader = useAuthHeader();
  const state$ = useSelector((state: T.State) => state);
  const projectId = state$.Pages.Contents.projectId!;

  const {
    earthWOrkList,
    selectedEarthworkId,
    earthworkAnalysisById,

    setSyncStatus,
    setEarthworkLists,
    setEarthworkAnalysis,
    setDeletingEarthworkId,
    setEarthworkFetchStatus,
    setSelectedEarthworkId,
    setEarthworkAnalysisById,
    setCreateEarthworkStatus,
    setEarthworkAnalysisFetchStatus,
  } = useEarthWorkStore(s => ({
    earthWOrkList: s.earthworkLists,
    selectedEarthworkId: s.selectedEarthworkId,
    earthworkAnalysisById: s.earthworkAnalysisById,

    setSyncStatus: s.setSyncStatus,
    setEarthworkLists: s.setEarthworkLists,
    setEarthworkAnalysis: s.setEarthworkAnalysis,
    setDeletingEarthworkId: s.setDeletingEarthworkId,
    setEarthworkFetchStatus: s.setEarthworkFetchStatus,
    setSelectedEarthworkId: s.setSelectedEarthworkId,
    setCreateEarthworkStatus: s.setCreateEarthworkStatus,
    setEarthworkAnalysisById: s.setEarthworkAnalysisById,
    setEarthworkAnalysisFetchStatus: s.setEarthworkAnalysisFetchStatus,
  }));

  async function getEarthWorklists() {
    if (!projectId || !authHeader) {
      return;
    }
    setEarthworkFetchStatus(T.APIStatus.PROGRESS);
    try {
      const response = await http.get<GetEarthWorklistsResponse>(
        makeV2APIURL(`earthwork/earthworks`, `?project_id=${projectId}`),
        {
          headers: authHeader,
        }
      );
      setEarthworkLists(response.data.data);
      setEarthworkFetchStatus(T.APIStatus.SUCCESS);
    } catch (e) {
      setEarthworkFetchStatus(T.APIStatus.ERROR);
    }
  }
  async function getEarthWorkAnalysis(id: Earthwork['id']) {
    if (!projectId || !authHeader) {
      return;
    }
    if (id in earthworkAnalysisById) {
      return;
    }
    setSyncStatus(SyncStatus.SYNCING);
    setEarthworkAnalysisFetchStatus(T.APIStatus.PROGRESS);
    try {
      const response = await http.get<GetEarthworkAnalysisResponse>(
        makeV2APIURL(`earthwork/earthworks`, id),
        {
          headers: authHeader,
        }
      );
      const responseData = response.data.data;
      const byIdEarthworkAnalysis = { [responseData.id]: responseData };

      setEarthworkAnalysisById(byIdEarthworkAnalysis);
      setEarthworkAnalysis(response.data.data.attributes);
      setSyncStatus(SyncStatus.SUCCESS);
      setEarthworkFetchStatus(T.APIStatus.SUCCESS);
      setEarthworkAnalysisFetchStatus(T.APIStatus.SUCCESS);
    } catch (e) {
      setEarthworkAnalysisFetchStatus(T.APIStatus.ERROR);
    }
  }
  async function createEarthWork(
    payload: Partial<CreateEarthworkRequestParam>,
    resetCredentials: Function
  ) {
    if (!projectId || !authHeader) {
      return;
    }
    setEarthworkAnalysisFetchStatus(T.APIStatus.PROGRESS);
    setCreateEarthworkStatus(T.APIStatus.PROGRESS);

    const _payload = {
      ...payload,
      projectId,
    };

    try {
      const response = await http.post<CreateEarthworkResponse>(
        makeV2APIURL(`earthwork`, 'earthworks'),
        _payload,
        {
          headers: authHeader,
        }
      );
      const toAppendOption = {
        attributes: {
          description: payload.description as string,
          name: payload.name as string,
        },
        id: response.data.id,
        type: 'v2EarthworkBaseEarthworks',
      };
      if (response) {
        resetCredentials?.();
      }
      setEarthworkLists([toAppendOption, ...(earthWOrkList ?? [])]);
      setSelectedEarthworkId(response.data.id);
      await getEarthWorkAnalysis(response.data.id);
      setCreateEarthworkStatus(T.APIStatus.SUCCESS);
    } catch (e) {
      setCreateEarthworkStatus(T.APIStatus.ERROR);
    }
  }
  async function deleteEarthwork(earthworkId?: string | number) {
    if (!projectId || !authHeader || !earthworkId) {
      return;
    }
    try {
      const response = await http.delete<CreateEarthworkResponse>(
        makeV2APIURL(`earthwork`, 'earthworks', earthworkId),
        {
          headers: authHeader,
        }
      );
      if (response) {
        const updatedEathworkList = earthWOrkList?.filter(
          earthwork => earthwork.id !== earthworkId
        );
        setDeletingEarthworkId(undefined);
        setEarthworkLists([...(updatedEathworkList ?? [])]);
        if (selectedEarthworkId === earthworkId) {
          setSelectedEarthworkId(undefined);
        }
      }
    } catch (e) {
      setCreateEarthworkStatus(T.APIStatus.ERROR);
    }
  }

  return {
    getEarthWorklists,
    getEarthWorkAnalysis,
    createEarthWork,
    deleteEarthwork,
  };
};

export default useEarthWorkContents;
