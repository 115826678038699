import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import * as T from '^/types';

interface AttachmentsStore {
  isUploading: boolean;
  attachmentUploadStatus: { [hash: string]: T.AttachmentPostStatus };
  setIsUploading(isUploading: boolean): void;
  setAttachmentUploadStatus(hash: string, status: T.AttachmentPostStatus): void;
  resetAttachmentUploadStatus(): void;

  processingAttachment?: File;
  setProcessingAttachment(processingAttachment?: File): void;
}

export const attachmentsStore = createStore<AttachmentsStore>()(
  devtools((set, get) => ({
    isUploading: true,
    attachmentUploadStatus: {},
    processingAttachment: undefined,
    setIsUploading(isUploading) {
      set({ isUploading });
    },
    setAttachmentUploadStatus(hash, status) {
      set({
        attachmentUploadStatus: {
          ...get().attachmentUploadStatus,
          [hash]: status,
        },
      });
    },
    resetAttachmentUploadStatus() {
      set({ isUploading: false, attachmentUploadStatus: {} });
    },

    setProcessingAttachment(processingAttachment?: File) {
      set({ processingAttachment });
    },
  }))
);

export function useAttachmentsStore(): AttachmentsStore;
export function useAttachmentsStore<T>(selector: (state: AttachmentsStore) => T): T;
export function useAttachmentsStore<T>(selector?: (state: AttachmentsStore) => T) {
  return useStore(attachmentsStore, selector!);
}
