import * as T from '^/types';

export const issuePriorityOptions = {
  [T.Language.KO_KR]: [
    {
      text: '낮음',
      value: T.IssuePriority.LOW,
      priorityColor: '#64C26F',
    },
    {
      text: '높음',
      value: T.IssuePriority.HIGH,
      priorityColor: '#FFE603',
    },
    {
      text: '심각',
      value: T.IssuePriority.CRITICAL,
      priorityColor: '#E03A3A',
    },
  ],
  [T.Language.EN_US]: [
    {
      text: 'Low',
      value: T.IssuePriority.LOW,
      priorityColor: '#64C26F',
    },
    {
      text: 'High',
      value: T.IssuePriority.HIGH,
      priorityColor: '#FFE603',
    },
    {
      text: 'Critical',
      value: T.IssuePriority.CRITICAL,
      priorityColor: '#E03A3A',
    },
  ],
};

export const issueGroupByCriteriaOptions = {
  [T.Language.KO_KR]: [
    {
      text: '우선순위',
      value: T.IssueGroupByCriteria.PRIORITY,
    },
    {
      text: '상태',
      value: T.IssueGroupByCriteria.STATUS,
    },
    {
      text: '유형',
      value: T.IssueGroupByCriteria.CATEGORY,
    },
  ],
  [T.Language.EN_US]: [
    {
      text: 'Priority',
      value: T.IssueGroupByCriteria.PRIORITY,
    },
    {
      text: 'Status',
      value: T.IssueGroupByCriteria.STATUS,
    },
    {
      text: 'Type',
      value: T.IssueGroupByCriteria.CATEGORY,
    },
  ],
};

export const issueSortByCriteriaOptions = {
  [T.Language.KO_KR]: [
    {
      text: '우선순위',
      value: T.IssueSortByCriteria.PRIORITY,
    },
    {
      text: '상태',
      value: T.IssueSortByCriteria.STATUS,
    },
    {
      text: '생성자',
      value: T.IssueSortByCriteria.CREATED_BY,
    },
    {
      text: '생성된 날짜',
      value: T.IssueSortByCriteria.CREATED_DATE,
    },
  ],
  [T.Language.EN_US]: [
    {
      text: 'Priority',
      value: T.IssueSortByCriteria.PRIORITY,
    },
    {
      text: 'Status',
      value: T.IssueSortByCriteria.STATUS,
    },
    {
      text: 'Created By',
      value: T.IssueSortByCriteria.CREATED_BY,
    },
    {
      text: 'Created Date',
      value: T.IssueSortByCriteria.CREATED_DATE,
    },
  ],
};

export const ISSUE_DONE_EN_LABEL = 'Done';
export const ISSUE_DONE_OPACITY = 0.4;
export const ISSUE_DEFAULT_OPACITY = 1;
