import React, {
  Context,
  FC,
  createContext,
  useContext,
  memo,
  useMemo,
  PropsWithChildren,
} from 'react';
import { useSelector } from 'react-redux';

import { checkIfContentIsFilteredOut } from '^/hooks/useContentsSearchFilter';
import * as T from '^/types';
import { getAllChildren } from '^/utilities/state-util';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useGroupStore } from '^/store/zustand/groups/groupStore';

interface ContentsFilter {
  filterText: string;
  selectedFilterContents: T.ContentType[];
  filterType?: T.ContentsListType;
  contentTypes?: T.ContentType[];
  rootGroupIds: Array<T.Content['id']>;
  filteredContentIds: Array<T.Content['id']>;
  count: number;
}

export const ContentFilterContext: Context<ContentsFilter> = createContext<ContentsFilter>({
  filterText: '',
  selectedFilterContents: [],
  rootGroupIds: [],
  filteredContentIds: [],
  count: 0,
});

interface ContentFilterContextProviderProps {
  contentTypes: T.ContentType[];
  filterType: T.ContentsListType;
  rootGroupIds: Array<T.Content['id']>;
}

const ContentFilterContextProviderRoot: FC<
  PropsWithChildren<ContentFilterContextProviderProps>
> = ({ filterType, contentTypes, children, rootGroupIds }) => {
  const contentFilters = useSelector((s: T.State) => s.Contents.contentFilters);
  const filterText = contentFilters[filterType].filterText;
  const selectedFilterContents = contentFilters[filterType].filterContents;

  const idsByGroup = useGroupStore(s => s.tree.idsByGroup);
  const contentByIds = useContentsStore(state => state.contents.byId);

  const allChildren = useMemo(
    () => getAllChildren(rootGroupIds, idsByGroup, contentByIds),
    [rootGroupIds, idsByGroup, contentByIds]
  );

  const filteredContent = useMemo(
    () =>
      allChildren.filter(
        _content => !checkIfContentIsFilteredOut(_content, filterText, selectedFilterContents)
      ),
    [filterText, selectedFilterContents, allChildren]
  );

  const filteredContentIds = useMemo(() => {
    const filteredParents = filteredContent
      .map(_content => contentByIds[_content?.groupId ?? NaN])
      .filter(_content => _content);
    const filteredGrandParents = filteredParents
      .map(_content => contentByIds[_content?.groupId ?? NaN])
      .filter(_content => _content);
    const Ids = filteredContent
      .concat(filteredParents)
      .concat(filteredGrandParents)
      .map(_content => _content.id);
    return Ids;
  }, [allChildren, filteredContent]);

  const filterState = useMemo(
    () => ({
      filterText,
      selectedFilterContents,
      filterType,
      contentTypes,
      rootGroupIds,
      filteredContentIds,
      count: filteredContentIds.length,
    }),
    [filterText, selectedFilterContents, filterType, contentTypes, rootGroupIds, filteredContentIds]
  );

  return (
    <ContentFilterContext.Provider value={filterState}>{children}</ContentFilterContext.Provider>
  );
};

export const ContentFilterContextProvider = memo<
  FC<PropsWithChildren<ContentFilterContextProviderProps>>
>(ContentFilterContextProviderRoot);
export const useContentsFilter = () => useContext(ContentFilterContext);
