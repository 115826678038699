import React, { FC, memo, useCallback } from 'react';
import Tippy from '@tippyjs/react';

import * as T from '^/types';
import { useDispatch, useSelector } from 'react-redux';
import { PhotoItem } from '../PhotoAlbum/PhotoItem';
import { usePhotoQuery } from '^/hooks/api/photos/usePhoto';
import {
  Checkbox,
  PhotoContainer,
  PhotoDateSubHeader,
  PhotoDiv,
  PhotoListWrapper,
  PhotoRoot,
  PhotoWrapper,
} from '../PhotoList/style';
import { UseL10n, useL10n } from '^/hooks';
import Text from '../PhotoList/text';
import { SetSelectedMedia } from '^/store/duck/Photos';
import { Photo360Item } from '../Photo/Photo360Item';

export const NonDatePhotoList: FC = memo(() => {
  const [l10n]: UseL10n = useL10n();
  const dispatch = useDispatch();
  const selectedMedia = useSelector((s: T.State) => s.Photos.selectedMedia);
  const { nonDatePhotos } = usePhotoQuery();

  const selectAllDatePhotos = useCallback(
    (checked: boolean, photoLists: Array<T.FinalPhoto | T.PhotoAlbum>) => {
      const updatedSelectedMedia = new Set(selectedMedia.map(media => media.contentId));

      photoLists.forEach(photo => {
        if (checked) {
          if (!updatedSelectedMedia.has(photo.id) && !photo.count) {
            updatedSelectedMedia.add(photo.id);
          }
        } else {
          updatedSelectedMedia.delete(photo.id);
        }
      });

      const newSelectedMedia = Array.from(updatedSelectedMedia).map(id => ({
        type: photoLists.find(photo => photo.id === id)?.photoType ?? null,
        contentId: id,
      }));

      dispatch(SetSelectedMedia({ selectedMedia: newSelectedMedia }));
    },
    [selectedMedia, dispatch]
  );

  return (
    <PhotoWrapper>
      <PhotoContainer isSelected={false}>
        <PhotoRoot>
          <Tippy
            offset={T.TIPPY_OFFSET}
            theme="angelsw"
            placement="top"
            arrow={false}
            content={l10n(Text.photo.selectMonthTooltip)}
          >
            <Checkbox
              checked={nonDatePhotos.every((pht: { id: number }) =>
                selectedMedia.some(media => media.contentId === pht.id)
              )}
              onChange={checked => selectAllDatePhotos(checked, nonDatePhotos)}
              show={nonDatePhotos.some((pht: { id: number }) =>
                selectedMedia.some(media => media.contentId === pht.id)
              )}
              isMonth={true}
            />
          </Tippy>
          <PhotoDateSubHeader
            isSelected={nonDatePhotos.some((pht: { id: number }) =>
              selectedMedia.some(media => media.contentId === pht.id)
            )}
          >
            {l10n(Text.noDateSelectText)}
          </PhotoDateSubHeader>
        </PhotoRoot>
      </PhotoContainer>
      <PhotoListWrapper>
        {nonDatePhotos.map(photo => (
          <PhotoDiv
            key={photo.id}
            isSelected={selectedMedia.some(media => media.contentId === photo.id)}
          >
            {photo.photoType === 'three_sixty_video' ? (
              <Photo360Item
                isSelected={selectedMedia.some(media => media.contentId === photo.id)}
                photoDate={photo}
              />
            ) : (
              <PhotoItem
                isSelected={selectedMedia.some(media => media.contentId === photo.id)}
                photoDate={photo}
              />
            )}
          </PhotoDiv>
        ))}
      </PhotoListWrapper>
    </PhotoWrapper>
  );
});

export default NonDatePhotoList;
