import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import { useSelector, useDispatch } from 'react-redux';
import styled, { CSSObject } from 'styled-components';
import { Dispatch } from 'redux';

import { ContentsTreeList } from '../ContentsTreeList';
import { CONTENTSLIST_CTXSORT_KEY } from '^/components/atoms/ContentsListItem';
import { GroupedContentsListHeader } from '^/components/molecules/GroupedContentsListHeader';

import palette from '^/constants/palette';
import {
  UseLastSelectedScreen,
  useDidMountEffect,
  useIsRoleX,
  useLastSelectedScreen,
} from '^/hooks';
import { AddNewGroup, MoveContent } from '^/store/duck/Groups';
import { ChangeEditingContent } from '^/store/duck/Pages';
import * as T from '^/types';
import {
  CtxSort,
  CtxSortCollisionMode,
  CtxSortContext,
  CtxSortEvent,
  CtxSortEventOptions,
} from '^/utilities/ctxsort';
import { isRoleViewer } from '^/utilities/role-permission-check';
import ContentsSearchBox from '^/components/molecules/ContentsSearchBox';
import { defaultMeasurementFilters, defaultThreeFilters } from '^/store/duck/Contents';
import { ContentFilterContextProvider } from '^/components/molecules/ContentsSearchBox/context';
import { useGroupStore } from '^/store/zustand/groups/groupStore';

const MeasurementContentsListWrapper = styled.div.attrs({
  className: 'ctxsort-scroller',
})({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowX: 'hidden',
  touchAction: 'none',
});
MeasurementContentsListWrapper.displayName = 'MeasurementContentsListWrapper';

export const MeasurementContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',
  userSelect: 'none',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});
MeasurementContentsListRoot.displayName = 'GroupedContentsListRoot';

const GroupListDivider = styled.div({
  position: 'sticky',
  zIndex: 10,
  top: '43px',
  height: '6px',
  marginTop: '17.5px',
  borderTop: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  borderBottom: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  backgroundColor: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  boxSizing: 'border-box',
});
GroupListDivider.displayName = 'GroupListDivider';

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

const Contents: FC = () => {
  const dispatch: Dispatch = useDispatch();
  const in3DPointCloud = useSelector((s: T.State) => s.Pages.Contents.in3DPointCloud);
  const currentPointCloudEngine = useSelector(
    (s: T.State) => s.Pages.Contents.currentPointCloudEngine
  );

  const isInPotree = in3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.POTREE;

  const defaultContents = isInPotree ? defaultThreeFilters : defaultMeasurementFilters;
  const rootIdsBySpace = useGroupStore(s => s.tree.rootIdsBySpace);
  const { open: openTree, personal: personalTree } = rootIdsBySpace[T.ContentCategory.MEASUREMENT];

  useEffect(() => {
    if (personalTree.length === 0) {
      dispatch(AddNewGroup({ isPersonal: true }));
    }
    if (openTree.length === 0) {
      dispatch(AddNewGroup({ isPersonal: false }));
    }
  }, [openTree.length, personalTree.length]);

  return (
    <>
      <ContentFilterContextProvider
        contentTypes={defaultContents}
        filterType={T.ContentsListType.PERSONAL_MEASUREMENT}
        rootGroupIds={personalTree}
      >
        <GroupedContentsListHeader rootGroupIds={personalTree} isPersonalGroups={true} />
        <ContentsSearchBox />
        <ContentsTreeList contentIds={personalTree} />
      </ContentFilterContextProvider>
      <GroupListDivider />
      <ContentFilterContextProvider
        contentTypes={defaultContents}
        filterType={T.ContentsListType.OPEN_MEASUREMENT}
        rootGroupIds={openTree}
      >
        <GroupedContentsListHeader rootGroupIds={openTree} isPersonalGroups={false} />

        <ContentsSearchBox />
        <ContentsTreeList contentIds={openTree} />
      </ContentFilterContextProvider>
    </>
  );
};

const MeasurementContentsList: FC = () => {
  const contentListItemCtxSortRef = useRef<CtxSortContext | null>(null);
  const groupCtxSortRef = useRef<CtxSortContext | null>(null);

  const dispatch: Dispatch = useDispatch();
  const isViewer: boolean = useIsRoleX(isRoleViewer);

  const contentFilters = useSelector((s: T.State) => s.Contents.contentFilters);

  const filterText =
    contentFilters[T.ContentsListType.OPEN_MEASUREMENT].filterText ||
    contentFilters[T.ContentsListType.PERSONAL_MEASUREMENT].filterText;

  const filteredContentsInOpen = contentFilters[T.ContentsListType.OPEN_MEASUREMENT].filterContents;

  const filteredContentsInPersonal =
    contentFilters[T.ContentsListType.PERSONAL_MEASUREMENT].filterContents;

  const hasFilteredContents = Boolean(
    filteredContentsInOpen.length !== defaultMeasurementFilters.length ||
      filteredContentsInPersonal.length !== defaultMeasurementFilters.length
  );

  const isSortDisabled = Boolean(filterText || hasFilteredContents);

  const editingContentId: T.Content['id'] | undefined = useSelector(
    (state: T.State) => state.Pages.Contents.editingContentId
  );
  const currentScreen: UseLastSelectedScreen = useLastSelectedScreen();

  const handleContentMoveThroughDnD: CtxSortEventOptions['onSortEnd'] = useCallback(
    (e: CtxSortEvent) => {
      dispatch(MoveContent({ e }));
    },
    []
  );

  useEffect(() => {
    if (isViewer) {
      return;
    }
    if (isSortDisabled) {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();
    } else {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();

      contentListItemCtxSortRef.current = CtxSort({
        target: CONTENTSLIST_CTXSORT_KEY,
        owner: 'Group',
        scroller: '.ctxsort-scroller',
        portalId: 'ctxsort-portal-content-list-item',
        delay: 200,
        onSortEnd: handleContentMoveThroughDnD,
        psuedoOwnerClassName: 'ctxsort-contentlistitem-owner-active',
      });
      groupCtxSortRef.current?.destroy();
      groupCtxSortRef.current = CtxSort({
        target: 'Group',
        owner: 'GroupedContentsListHeader',
        tenant: CONTENTSLIST_CTXSORT_KEY,
        scroller: '.ctxsort-scroller',
        portalId: 'ctxsort-portal-content-group',
        collisionMode: CtxSortCollisionMode.PARENT,
        delay: 200,
        psuedoOwnerClassName: 'ctxsort-contentlistitem-owner-active',
        onSortEnd: handleContentMoveThroughDnD,
      });
    }

    return () => {
      contentListItemCtxSortRef.current?.destroy();
      groupCtxSortRef.current?.destroy();
    };
  }, [isSortDisabled]);

  useDidMountEffect(() => {
    if (editingContentId !== undefined) {
      dispatch(ChangeEditingContent({}));
    }
  }, [currentScreen?.id]);

  return (
    <MeasurementContentsListRoot>
      <ScrollBars
        renderView={ScrollBarView}
        autoHide={true}
        autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
        autoHideDuration={HIDE_SCROLL_BAR_DURATION}
      >
        <Contents />
      </ScrollBars>
    </MeasurementContentsListRoot>
  );
};

export default memo(MeasurementContentsList);
