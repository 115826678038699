import { ContentsListItem } from '^/components/atoms/ContentsListItem';
import palette from '^/constants/palette';
import { UseL10n, useL10n } from '^/hooks';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
export interface Props {
  content: T.FlightPlanContent;
}
const Header = styled.div({
  width: '100%',

  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),

  position: 'relative',

  top: '-12px',

  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  pointerEvents: 'none',
});

const ContentSummaryWrapper = styled.div({
  width: '100%',
  marginBottom: '-8px',
  paddingTop: '25px',
});
const ContentInfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  pointerEvents: 'visible',
});
const ContentCreatedTitle = styled.div({});

export const getYearDateMonth = (providedDateTime: Date): Record<string, number> => ({
  fullYear: providedDateTime ? providedDateTime.getFullYear() : 0,
  monthIndex: providedDateTime ? providedDateTime.getMonth() : 0,
  weekdayIndex: providedDateTime ? providedDateTime.getDay() : 0,
  date: providedDateTime ? providedDateTime.getDate() : 0,
});

const RawContentsListFlightPlanItem = ({ content }: Props) => {
  const [l10n, language]: UseL10n = useL10n();
  const createdDateInFormat = new Date(content.info.createTime);
  const createdDateInYrMonthDayFormat = getYearDateMonth(createdDateInFormat);

  const createdAtString: string = (() => {
    switch (language) {
      case T.Language.KO_KR:
        return `${createdDateInYrMonthDayFormat.fullYear}${Text.year[T.Language.KO_KR]} ${
          Text.month[T.Language.KO_KR][createdDateInYrMonthDayFormat.monthIndex]
        } ${createdDateInYrMonthDayFormat.date}${Text.date[T.Language.KO_KR]} ${
          Text.weekday[T.Language.KO_KR][createdDateInYrMonthDayFormat.weekdayIndex]
        }`;
      case T.Language.EN_US:
        return `${Text.weekday[T.Language.EN_US][createdDateInYrMonthDayFormat.weekdayIndex]}, ${
          Text.month[T.Language.EN_US][createdDateInYrMonthDayFormat.monthIndex]
        } ${createdDateInYrMonthDayFormat.date}, ${createdDateInYrMonthDayFormat.fullYear}`;
      default:
        return '';
    }
  })();

  return (
    <ContentsListItem className={`${CANCELLABLE_CLASS_NAME}`} content={content} isPinned={true}>
      <Header>
        <ContentSummaryWrapper>
          <ContentInfoWrapper>
            <ContentCreatedTitle>{l10n(Text.createdAt)}</ContentCreatedTitle>
            {createdAtString}
          </ContentInfoWrapper>
        </ContentSummaryWrapper>
      </Header>
    </ContentsListItem>
  );
};

export const ContentsListFlightPlanItem: FC<Props> = withErrorBoundary(
  RawContentsListFlightPlanItem
)(Fallback);
