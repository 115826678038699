import { Language } from '^/types';

export default {
  showHideLayer: {
    [Language.EN_US]: 'Show / Hide layer',
    [Language.KO_KR]: '폴더 표시 / 숨기기',
  },
  showHideFolder: {
    [Language.EN_US]: 'Show / Hide folder',
    [Language.KO_KR]: '폴더 표시 / 숨기기',
  },
  rename: {
    [Language.EN_US]: 'Rename',
    [Language.KO_KR]: '이름 변경',
  },
  delete: {
    [Language.EN_US]: 'Delete',
    [Language.KO_KR]: '삭제',
  },
  centerOnMap: {
    [Language.EN_US]: 'Center on map',
    [Language.KO_KR]: '지도의 중심으로 이동',
  },
  showHideDetails: {
    [Language.EN_US]: 'Show / Hide details',
    [Language.KO_KR]: '세부 정보 표시 / 숨기기',
  },
  showForAllDates: {
    [Language.EN_US]: 'Show on maps for all dates',
    [Language.KO_KR]: '모든 날짜에 표시하기',
  },
  foldUnfoldFolder: {
    [Language.EN_US]: 'Fold / Unfold folder',
    [Language.KO_KR]: '폴더 접기 / 펼치기',
  },
  markAsFavourite: {
    [Language.EN_US]: 'Mark as favourite',
    [Language.KO_KR]: '즐겨찾기로 지정',
  },
};
