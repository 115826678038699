import React from 'react';

import Tippy from '@tippyjs/react';

import Text from './text';
import UploadSVG from '^/assets/icons/photo/upload.svg';
import dsPalette from '^/constants/ds-palette';
import { useL10n } from '^/hooks';
import * as T from '^/types';

import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

const rootStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100px',
  height: '30px',
  paddingRight: '3px',
  backgroundColor: dsPalette.white.toString(),
  borderRadius: '6px',
  cursor: 'pointer',
  gap: '5px',

  '&:hover': {
    backgroundColor: dsPalette.grey20.toString(),

    '> svg > path': {
      fill: dsPalette.black.toString(),
    },
    '> div': {
      color: dsPalette.black.toString(),
    },
  },
});

const Root = styled.div<{ photoSize: Props['photoSize'] }>(({ photoSize }) => {
  switch (photoSize) {
    case 'sm':
      return css`
        ${rootStyles};
        width: 40px;
        height: 40px;
        padding-right: 0;
        border-radius: 20px;
      `;

    case 'md':
    default:
      return css`
        ${rootStyles};
      `;
  }
});

const uploadTextStyles = css({
  height: '20px',
  color: dsPalette.grey130.toString(),
});

const UploadText = styled.div<{ photoSize: Props['photoSize'] }>(({ photoSize }) => {
  switch (photoSize) {
    case 'sm':
      return css`
        ${uploadTextStyles};
        display: none;
      `;

    case 'md':
    default:
      return uploadTextStyles;
  }
});

interface Props {
  readonly photoSize: 'sm' | 'md';
  readonly isIn3D: boolean;
  onClick(): void;
}

function PhotoUploadButton({ photoSize, onClick }: Props) {
  const [l10n] = useL10n();
  const { albumType, contentType } = useParams();
  const paramPhotoType = albumType ?? contentType;
  const tooltipText =
    paramPhotoType === T.PhotoAlbumType.DRONE ? Text.uploadTooltip.threeD : Text.uploadTooltip.twoD;

  return (
    <Tippy
      offset={T.TIPPY_OFFSET}
      theme="angelsw"
      placement="bottom"
      arrow={false}
      content={l10n(tooltipText)}
    >
      <Root photoSize={photoSize} onClick={onClick}>
        <UploadSVG />
        <UploadText photoSize={photoSize}>{l10n(Text.upload)}</UploadText>
      </Root>
    </Tippy>
  );
}

export default PhotoUploadButton;
