import React, { FC, ReactNode, memo, useMemo } from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import { useSelector } from 'react-redux';
import styled, { CSSObject } from 'styled-components';

import { Fallback } from './fallback';
import { ContentToItem } from '^/components/atoms/ContentToItem';
import ContentListEmptyMsg from '^/components/molecules/ContentListEmptyMsg';
import { tabToContent } from '^/store/duck/Pages/Content';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import palette from '^/constants/palette';
import { ChooseEngineButton } from '../ChooseEngineButton';
import { ContentFilterContextProvider } from '^/components/molecules/ContentsSearchBox/context';
import { defaultTerrainFilters } from '^/store/duck/Contents';
import { ContentsTreeList } from '../ContentsTreeList';
import { GroupedContentsListHeader } from '^/components/molecules/GroupedContentsListHeader';
import { UnleashFlagProvider } from '^/components/atoms/UnleashFeatureProvider';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useGroupStore } from '^/store/zustand/groups/groupStore';

export const Root = styled.ol({
  width: '100%',
  height: '100%',
  paddingTop: '10px',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});

export const MapContainer = styled.ol({
  position: 'sticky',
  top: '0',
  backgroundColor: 'white',
  zIndex: '11',
});
const GroupListDivider = styled.div({
  position: 'sticky',
  zIndex: 10,
  top: '43px',
  height: '6px',
  marginTop: '17.5px',
  borderTop: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  borderBottom: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  backgroundColor: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  boxSizing: 'border-box',
});
GroupListDivider.displayName = 'GroupListDivider';

const Contents: FC = memo(() => {
  const defaultContents = defaultTerrainFilters;
  const byId = useContentsStore(s => s.contents.byId);
  const allIds = useContentsStore(s => s.contents.allIds);
  const projectConfig = useSelector((s: T.State) => s.ProjectConfigPerUser.config);

  const contentIds: Array<T.Content['id']> = useMemo(
    () =>
      projectConfig?.lastSelectedScreenId === undefined
        ? []
        : allIds
            .map(id => byId[id])
            .filter(
              ({ type, screenId }) =>
                tabToContent[T.ContentPageTabType.MAP].includes(type) &&
                screenId === projectConfig.lastSelectedScreenId
            )
            .sort((c1, c2) => T.MAP_TAB_CONTENTS_ORDER[c1.type] - T.MAP_TAB_CONTENTS_ORDER[c2.type])
            .map(({ id: contentId }) => contentId),
    [projectConfig?.lastSelectedScreenId, allIds, byId]
  );

  const { open: openTree } = useGroupStore(s => s.tree.rootIdsBySpace[T.ContentCategory.TERRAIN]);
  const contentListEmptyMsg: ReactNode = useMemo(
    () => <ContentListEmptyMsg currentTab={T.ContentPageTabType.MAP} />,
    []
  );

  return (
    <>
      <MapContainer>
        {contentIds.length > 0 ? (
          <>
            {contentIds.map(contentId => (
              <ContentToItem key={contentId} contentId={contentId} />
            ))}
          </>
        ) : (
          <>{contentListEmptyMsg}</>
        )}
        <ChooseEngineButton />
        <GroupListDivider />
      </MapContainer>

      <UnleashFlagProvider
        flagName={T.FEATURE_FLAGS.TERRAIN_EDITING}
        render={({ isEnabled }) =>
          isEnabled && (
            <ContentFilterContextProvider
              contentTypes={defaultContents}
              filterType={T.ContentsListType.OPEN_TERRAIN}
              rootGroupIds={openTree}
            >
              <GroupedContentsListHeader rootGroupIds={openTree} isPersonalGroups={false} />

              <ContentsTreeList contentIds={openTree} />
            </ContentFilterContextProvider>
          )
        }
      />
    </>
  );
});

const MapContentsList: FC = () => {
  const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
    <div {...others} style={{ ...style, height: '100%', overflowX: 'hidden' }} />
  );
  const hiddenAfterMilliSec: number = 0;
  const hiddenViaMilliSec: number = 500;
  return (
    <Root>
      <ScrollBars
        renderView={ScrollBarView}
        autoHide={true}
        autoHideTimeout={hiddenAfterMilliSec}
        autoHideDuration={hiddenViaMilliSec}
      >
        <Contents />
      </ScrollBars>
    </Root>
  );
};

export default memo(withErrorBoundary(MapContentsList)(Fallback));
