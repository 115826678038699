import React, { FC, ComponentType, PropsWithChildren } from 'react';
import { create } from 'zustand';
import * as THREE from 'three';
import { PotreeInteraction } from '../PotreeInteraction';

export interface PotreeStore {
  potree: any;
  camera: THREE.PerspectiveCamera | null;
  scene: any | null;
  viewer: any;
  viewerDiv: null | HTMLElement;
  interaction: null | PotreeInteraction;
  earthControls: any | null;
  orbitControls: any | null;
  isPCOLoading: boolean;
  PCO: any | null;
  setIsPCOLoading(isPCOLoading: boolean): void;
  setViewerDiv(element: HTMLElement): void;
  setInteraction(interaction: PotreeInteraction): void;
  setPCO(PCO: any): void;
  resetPotreeJS(): void;
  zoomInPotree(): void;
  zoomOutPotree(): void;
  centerPotreeMap(): void;
  setViewerSceneAndCamera(viewer: any): void;
}

const Potree = window.Potree;

export const usePotreeStore = create<PotreeStore>()((set, get) => ({
  potree: Potree,
  viewer: null,
  camera: null,
  scene: null,

  interaction: null,
  earthControls: null,
  orbitControls: null,
  isPCOLoading: true,
  PCO: null,
  viewerDiv: null,

  setViewerSceneAndCamera: (viewer: any) => {
    if (!viewer) {
      return;
    }
    const scene = viewer.scene;
    const orbitControls = viewer.orbitControls;

    set({ viewer, scene, camera: scene.getActiveCamera(), orbitControls });
  },
  resetPotreeJS() {
    set(({ interaction }) => {
      interaction?.removeListeners();

      return {
        isPCOLoading: true,
        PCO: null,
        scene: null,
        camera: null,
        viewer: null,
        interaction: null,
        viewerDiv: null,
        orbitControls: null,
        potree: null,
      };
    });
  },
  setIsPCOLoading(isPCOLoading: boolean) {
    set({ isPCOLoading });
  },
  setPCO(PCO: any) {
    set({ PCO });
  },
  setViewerDiv(viewerDiv: HTMLElement) {
    set({ viewerDiv });
  },
  setInteraction(interaction: PotreeInteraction) {
    set({ interaction });
  },

  zoomInPotree() {
    const { orbitControls, scene } = get();
    if (!scene) {
      return;
    }

    // apply zoom
    const fadeFactor = 20;
    const delta = 1;
    const progression = Math.min(1, fadeFactor * delta);
    const view = scene.view;

    orbitControls.radiusDelta -= 5;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const radius = view.radius + progression * orbitControls.radiusDelta;

    const V = view.direction.multiplyScalar(-radius);
    const position = new THREE.Vector3().addVectors(view.getPivot(), V);
    view.radius = radius;

    view.position.copy(position);
  },
  zoomOutPotree() {
    const { orbitControls, scene } = get();

    if (!scene) {
      return;
    }

    // apply zoom
    const fadeFactor = 20;
    const delta = 1;
    const progression = Math.min(1, fadeFactor * delta);
    const view = scene.view;

    orbitControls.radiusDelta += 5;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const radius = view.radius + progression * orbitControls.radiusDelta;

    const V = view.direction.multiplyScalar(-radius);
    const position = new THREE.Vector3().addVectors(view.getPivot(), V);
    view.radius = radius;

    view.position.copy(position);
  },

  centerPotreeMap() {
    const { viewer } = get();

    if (viewer) {
      viewer.fitToScreen();
    }
  },
}));

export function withPotreeStore<T>(TargetComponent: ComponentType<T & PotreeStore>): FC<T> {
  return (subProps: PropsWithChildren<T>) => {
    const potreeStore = usePotreeStore();
    return <TargetComponent {...subProps} {...potreeStore} />;
  };
}
