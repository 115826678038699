import { Tuple } from '^/components/molecules/MyPage/Tuple';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';
import { useAuthUserQuery } from '^/store/react-query/users';
import { useL10n } from '^/hooks';
import Text from './text';
import * as T from '^/types';

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

const languageTypeToLanguageText: Record<T.Language, string> = {
  [T.Language.EN_US]: 'English',
  [T.Language.KO_KR]: '한국어',
};

export default function MyPageSettings() {
  const [l10n] = useL10n();
  const { data: authedUser } = useAuthUserQuery();

  const language = useMemo(() => {
    if (!authedUser?.language) {
      return languageTypeToLanguageText[T.Language.EN_US];
    }
    return languageTypeToLanguageText[authedUser.language];
  }, []);

  return (
    <Root>
      <GroupedSection title={l10n(Text.settings.locale.title)}>
        <Tuple title={l10n(Text.settings.locale.country)} value={authedUser?.country} />
        <Tuple title={l10n(Text.settings.locale.language)} value={language} />
      </GroupedSection>
    </Root>
  );
}
