/* eslint-disable max-len */

import { Language } from '^/types';

export default {
  attachFile: {
    [Language.KO_KR]: 'BIM 첨부',
    [Language.EN_US]: 'Attach BIM',
  },
  attachFileNotification: {
    [Language.KO_KR]: '파일을 첨부해 주세요.',
    [Language.EN_US]: 'Please upload files.',
  },
  fileExtensionNotification: {
    [Language.KO_KR]: '지원되는 파일 형식: ',
    [Language.EN_US]: 'Supported file formats: ',
  },
  tooltipDeleteAll: {
    [Language.KO_KR]: '모두 삭제',
    [Language.EN_US]: 'Delete all',
  },
  dragAndDrop: {
    [Language.KO_KR]: '업로드할 파일 끌어서 놓기',
    [Language.EN_US]: 'Drag and drop files to Upload',
  },
  or: {
    [Language.KO_KR]: '또는',
    [Language.EN_US]: 'or',
  },
  browse: {
    [Language.KO_KR]: '찾아보기',
    [Language.EN_US]: 'Browse',
  },
  browseAnotherFile: {
    [Language.KO_KR]: '다른 파일 찾아보기',
    [Language.EN_US]: 'Browse Another File',
  },
  processingText: {
    [Language.KO_KR]: '업로드 처리 중',
    [Language.EN_US]: 'Processing your upload',
  },
  supportedFiles: {
    [Language.KO_KR]: '지원되는 파일: IFC • 최대 파일 크기: 200MB',
    [Language.EN_US]: 'Supported files: IFC • Maximum file size: 200 MB',
  },
  fileError: {
    [Language.KO_KR]: '지원되는 파일을 선택(또는 업로드)하십시오.',
    [Language.EN_US]: 'Please choose (or upload) a supported file.',
  },
  errorHeadline: {
    [Language.KO_KR]: '평소보다 시간이 오래 걸리네요',
    [Language.EN_US]: "That's taking longer than usual",
  },
  errorDescription: (fileName: string) => ({
    [Language.KO_KR]: `파일 최적화 문제로 인해 ${fileName} 처리 시간이 연장되었습니다. 계속 진행하기 전에 모델을 최적화하십시오.`,
    [Language.EN_US]: `Processing time for ${fileName} is extended, 
    possibly due to file optimization issues. Please optimize the model before continuing.`,
  }),
  largeFileErrorTitle: (fileName: string) => ({
    [Language.KO_KR]: `${fileName}는 200MB보다 큽니다.\nIFC 모델의 파일 크기가 클수록 성능이 저하됩니다. `,
    [Language.EN_US]: `${fileName} is larger than 200 MB. `,
  }),
  filerLargeError: {
    [Language.KO_KR]: `IFC 파일 크기를 줄이는데 안내가 필요한 경우 고객 지원팀에 문의하십시오.`,
    [Language.EN_US]: `Larger file sizes in IFC models lead to a slower performance. Please contact our Customer Support team if you need guidance in reducing the size of your IFC files.`,
  },
  notOptimizedError: {
    [Language.KO_KR]: `IFC 파일이 최적화되어 있지 않은 것 같습니다. 다른 모델을 사용하는 것을 고려해 보세요.`,
    [Language.EN_US]: `The IFC file doesn't seem optimized. Consider using a different model.`,
  },
  notSupportedFormat: {
    [Language.KO_KR]: `지원되지 않는 형식입니다. 계속하려면 IFC 파일을 업로드하십시오.`,
    [Language.EN_US]: `Unsupported format. Upload an IFC file to continue.`,
  },
  notIFCFileType: {
    [Language.KO_KR]: `지원되지 않는 형식입니다. IFC2x3 또는 IFC4 형식의 IFC 파일을 계속 업로드합니다.`,
    [Language.EN_US]: `Unsupported format. Upload an IFC file with type IFC2x3 or IFC4 to continue.`,
  },
};
