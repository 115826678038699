import React, { FC, memo } from 'react';
import ScrollBars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { CSSObject } from 'styled-components';

import { GroupedContentsListHeader } from '^/components/molecules/GroupedContentsListHeader/ForIssueItems';

import palette from '^/constants/palette';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';

import { IssueTreeList } from '../IssueTreeList';
import { Fallback } from './fallback';
import SidebarIssueToggle from '^/components/atoms/SidebarIssueToggle';
import { ChangeEditingContent } from '^/store/duck/Pages';
import { useIssueStore } from '^/store/issue';
import SkeletonElement from '^/components/atoms/SkeletonElement';

const HorizontalDividerWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  paddingTop: '3px',
  paddingBottom: '3px',
  paddingLeft: '10px',
  paddingRight: '10px',

  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});

const HorizontalDivider = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '1px',
  background: palette.toggleButtonGray.toString(),
});

const IssueContentsListWrapper = styled.div.attrs({
  className: 'ctxsort-scroller',
})({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowX: 'hidden',
  touchAction: 'none',
});
IssueContentsListWrapper.displayName = 'IssueContentsListWrapper';

export const IssueContentsListRoot = styled.ol({
  width: '100%',
  height: '100%',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});
IssueContentsListRoot.displayName = 'GroupedIssueContentsListRoot';

const GroupListDivider = styled.div({
  position: 'sticky',
  zIndex: 10,
  top: '43px',
  height: '6px',
  marginTop: '17.5px',
  borderTop: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  borderBottom: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  backgroundColor: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  boxSizing: 'border-box',
});
GroupListDivider.displayName = 'GroupListDivider';

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const SkeletonLoaderWrapper = styled.div({
  width: '92%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
});

const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

const Contents: FC = () => {
  const dispatch: Dispatch = useDispatch();
  const isShowingIssuesRelatedToMe = useIssueStore(s => s.isShowingIssuesRelatedToMe);
  const setIsShowingIssuesRelatedToMe = useIssueStore(s => s.setIsShowingIssuesRelatedToMe);
  const handleOnChangeIssueRelatedView = (): void => {
    setIsShowingIssuesRelatedToMe(!isShowingIssuesRelatedToMe);
    dispatch(ChangeEditingContent({}));
  };

  const selectedGroupByCriteria = useIssueStore(s => s.selectedGroupByCriteria);
  const setSelectedGroupByCriteria = useIssueStore(s => s.setSelectedGroupByCriteria);
  const handleOnChangeGroupCriteria = (newGroupCriteria: T.IssueGroupByCriteria): void => {
    setSelectedGroupByCriteria(newGroupCriteria);
  };

  const getIssuesStatus = useSelector((state: T.State) => state.Contents.getIssuesStatus);
  const isReadyShowContents: boolean = getIssuesStatus === T.APIStatus.SUCCESS;

  const IssueContentsListSkeleton: FC = () => (
    <SkeletonLoaderWrapper>
      {Array.from({ length: 10 }).map((_, i) => (
        <SkeletonElement key={i} />
      ))}
    </SkeletonLoaderWrapper>
  );

  const issueTreeListItem = isReadyShowContents ? (
    <IssueTreeList groupByCriteria={selectedGroupByCriteria} />
  ) : (
    <IssueContentsListSkeleton />
  );

  return (
    <>
      <GroupedContentsListHeader
        isPinnedGroups={true}
        hasPinnedFolder={false}
        currentGroupByCriteria={selectedGroupByCriteria}
        onChangeCurrentGroupByCriteria={handleOnChangeGroupCriteria}
      />
      <HorizontalDividerWrapper>
        <HorizontalDivider />
      </HorizontalDividerWrapper>
      <SidebarIssueToggle
        isEnabled={isShowingIssuesRelatedToMe}
        onClick={handleOnChangeIssueRelatedView}
      />
      {issueTreeListItem}
    </>
  );
};

const IssueContentsList: FC = memo(() => (
  <IssueContentsListRoot>
    <ScrollBars
      autoHide={true}
      renderView={ScrollBarView}
      autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
      autoHideDuration={HIDE_SCROLL_BAR_DURATION}
    >
      <Contents />
    </ScrollBars>
  </IssueContentsListRoot>
));

export default memo(withErrorBoundary(IssueContentsList)(Fallback));
