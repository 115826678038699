import theme from '^/theme';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Root = styled.div<{ topBorder?: boolean }>(({ topBorder }) => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'start',
  paddingTop: '32px',
  borderTop: topBorder ? `1px solid ${theme.colorTokens.lineGray}` : 'none',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '16px',
    paddingTop: '0',
    borderTop: 'none',
  },
}));

const LeftSideWrapper = styled.div({
  width: '168px',
});

const Title = styled.p({
  fontSize: '16px',
  fontWeight: '700',
  marginTop: '12px',
});

const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
});

interface Props {
  title: string;
  topBorder?: boolean;
  componentToShowWithTitle?: React.ReactNode;
}
export function GroupedSection({
  title,
  children,
  topBorder,
  componentToShowWithTitle,
}: PropsWithChildren<Props>) {
  return (
    <Root topBorder={topBorder}>
      <LeftSideWrapper>
        <Title>{title}</Title>
        {componentToShowWithTitle}
      </LeftSideWrapper>
      <ContentContainer>{children}</ContentContainer>
    </Root>
  );
}
