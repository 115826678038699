import React from 'react';
import styled from 'styled-components';
import AuthPageLogoContainer from '^/components/organisms/AuthPageLogoContainer';
import RetroGrid from '../RetroGrid';
import { FlexBox } from '^/components/atoms/FlexBox';

const BackgroundContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  background: '#F2F5F8', // TODO: place it on the colorTokens
  flexDirection: 'column',
});

export const AuthMessageBoxLayout = ({ children }: { children: React.ReactNode }) => (
  <BackgroundContainer>
    <FlexBox customStyles={{ padding: '20px' }} flexDirection="column">
      <AuthPageLogoContainer />
    </FlexBox>
    <FlexBox customStyles={{ zIndex: 1 }} justifyContent="center">
      {children}
    </FlexBox>
    <RetroGrid theme="lightGray" />
  </BackgroundContainer>
);
