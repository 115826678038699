import React, { FC, useEffect } from 'react';
import styled, { CSSObject } from 'styled-components';

import RawDropdown, {
  Option as DropdownOption,
  Props as DropdownProps,
} from '^/components/atoms/Dropdown/1';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import { CoordinateSystem } from '^/types';
import { useEarthWorkStore } from '^/store/earthwork/earthworkStore';
import useEarthWorkContents from '^/hooks/earthwork/useEarthWorkContents';
import { EarthworkContent } from './EarthworkContent';

const Root = styled.div({
  fontSize: '13px',
  fontWeight: 500,
  color: palette.textBlack.toString(),
});
Root.displayName = 'EarthworkSelectDropdown';

interface DropdownStyleProps {
  dropdownStyle?: CSSObject;
}
const Dropdown = styled(RawDropdown)<DropdownProps & DropdownStyleProps>(
  {
    display: 'inline-block',

    width: '100%',
  },
  ({ dropdownStyle }) => (dropdownStyle ? dropdownStyle : {})
);
Dropdown.displayName = 'Dropdown';

export interface Props {
  value?: string;
  isError?: boolean;
  options?: DropdownOption[];
  dropdownButtonStyle?: CSSObject;
  onSelect(coordinateSystem: CoordinateSystem): void;
}

const EarthworkContentList: FC<Props & L10nProps> = () => {
  const { earthworkLists, setSelectedEarthworkId } = useEarthWorkStore();
  const { getEarthWorkAnalysis } = useEarthWorkContents();

  const handleSelectEarthwork = (earthworkId: string | number) => {
    setSelectedEarthworkId(earthworkId);
    void getEarthWorkAnalysis(earthworkId);
  };

  useEffect(() => {
    if (earthworkLists.length) {
      setSelectedEarthworkId(earthworkLists[0].id);
      void getEarthWorkAnalysis(earthworkLists[0].id);
    }
  }, []);

  return (
    <Root>
      {earthworkLists?.map(earthwork => (
        <EarthworkContent
          key={earthwork.id}
          earthwork={earthwork}
          onClick={() => handleSelectEarthwork(earthwork.id)}
        />
      ))}
    </Root>
  );
};

export default withL10n(EarthworkContentList);
