import React, { useRef, useState, TouchEvent, MouseEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Color from 'color';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { isSameDay } from 'date-fns';

import * as T from '^/types';
import { useClickOutside, useL10n } from '^/hooks';
import palette from '^/constants/palette';

import RawIssueDueDateSvg from '^/assets/icons/issue-due-date.svg';
import IssueDueDatePrevSvg from '^/assets/icons/issue-due-date-prev.svg';
import IssueDueDateNextSvg from '^/assets/icons/issue-due-date-next.svg';

import dsPalette from '^/constants/ds-palette';
import { makeConsistentUTCDateViaOffset } from '^/utilities/date-format';
import Text from './text';
import useIssueContents from '^/hooks/useIssueContents';

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  marginBottom: '10px',

  fontSize: '12px',
  color: palette.issue.sidebarItemFont.toString(),
});

const Title = styled.div({
  marginBottom: '6px',
});

const MainArea = styled.div({
  boxSizing: 'border-box',
  width: '100%',
});

const MainButton = styled.button({
  all: 'unset',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '6.5px',

  boxSizing: 'border-box',
  width: '100%',
  height: '30px',
  paddingLeft: '9px',
  paddingRight: '9px',

  backgroundColor: palette.white.toString(),
  border: `1px solid ${palette.border.toString()}`,
  borderRadius: '5px',

  cursor: 'pointer',
});

const CalendarIconWrapper = styled.div({
  color: dsPalette.typePrimary.toString(),
});

const ValueViewer = styled.div({
  color: dsPalette.typePrimary.toString(),
});

const Placeholder = styled.div({
  color: '#D5D5D8',
});

const IssueDueDateSvg = styled(RawIssueDueDateSvg)({
  marginTop: '2px',
  marginLeft: '1px',
});

const IssueDueDateHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '11px',
  paddingLeft: '6.5px',
  paddingRight: '3px',
});

const IssueDueDateCaption = styled.div({
  fontWeight: 'bold',
  fontSize: '13px',
});

const IssueDueDateButtonsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  columnGap: '12px',
});

const IssueDueDateButton = styled.button({
  all: 'unset',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: new Color(
      `rgba(${dsPalette.themePrimary.red()}, ${dsPalette.themePrimary.green()}, ${dsPalette.themePrimary.blue()}, 0.15)`
    ).toString(),
  },
});

const IssueDueDateUIButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  columnGap: '4px',
});

const IssueDueDateUIButton = styled.button({
  all: 'unset',
  padding: '4px 12px',
  color: dsPalette.themePrimary.toString(),
  fontWeight: 'bold',
  fontSize: '12px',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: new Color(
      `rgba(${dsPalette.themePrimary.red()}, ${dsPalette.themePrimary.green()}, ${dsPalette.themePrimary.blue()}, 0.15)`
    ).toString(),
  },
});

const CalendarAreaRoot = styled.div({
  position: 'absolute',
  zIndex: 1,

  boxSizing: 'border-box',
  width: 'calc(100% - 50px)',

  backgroundColor: palette.white.toString(),
  padding: '21px 17px 26px 17px',
  border: `1px solid ${palette.border.toString()}`,
  borderRadius: '5px',

  '&&&': {
    '.DayPicker': {
      width: '100%',
      marginBottom: '17px',
    },
    '.DayPicker-wrapper': {
      paddingBottom: '0px',
    },
    '.DayPicker-Month': {
      width: '100%',
      margin: '0px',
      display: 'flex',
      flexDirection: 'column',
    },
    '.DayPicker-NavButton': {
      top: 0,
      right: 0,
      marginTop: 0,
    },
    '.DayPicker-Caption': {
      display: 'none',
    },
    '.DayPicker-Weekdays, .DayPicker-WeekdaysRow': {
      display: 'flex',
      marginTop: '0px',
      width: '100%',
      justifyContent: 'space-between',
    },
    '.DayPicker-WeekdaysRow': {
      width: '100%',
      fontSize: '13px',
    },
    '.DayPicker-Body': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.DayPicker-Week': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: '11px',
      fontWeight: 400,
    },
    '.DayPicker-Day': {
      padding: '0px',
      width: '26px',
      height: '26px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: dsPalette.title.toString(),
    },
    '.DayPicker-Weekday': {
      padding: '0px',
      width: '26px',
      height: '26px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: palette.Calendar.weekday.toString(),
      fontWeight: 500,
    },
    '.DayPicker-Day--today': {
      color: dsPalette.title.toString(),
      fontWeight: 'normal',
    },
    '.DayPicker-Day--selected': {
      backgroundColor: 'var(--color-theme-primary)',
      color: palette.white.toString(),
    },
    '.DayPicker-Day--disabled': {
      position: 'relative',
      color: palette.Calendar.disabled.toString(),
    },
    '.DayPicker-Day--outside': {
      color: dsPalette.typeDisabled.toString(),
      cursor: 'pointer',
    },
    '.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover': {
      boxSizing: 'border-box',
      border: `1px solid ${dsPalette.themePrimary.toString()}`,
      backgroundColor: new Color(
        `rgba(${dsPalette.themePrimary.red()}, ${dsPalette.themePrimary.green()}, ${dsPalette.themePrimary.blue()}, 0.25)`
      ).toString(),
    },
    abbr: {
      cursor: 'default',
    },
  },
});

interface CalendarAreaProps {
  initSelectedDate: Date | null;
  onCancel(): void;
  onConfirm(newDueDate: Date): void;
}

function CalendarArea({ initSelectedDate, onCancel, onConfirm }: CalendarAreaProps) {
  const [selectedDate, setSelectedDate] = useState<Date>(initSelectedDate ?? new Date());
  const [curYear, setCurYear] = useState<number>(new Date().getFullYear());
  const [curMonthIndex, setCurMonthIndex] = useState<number>(new Date().getMonth());
  const [l10n, language] = useL10n();
  const timezoneOffset: T.CommonPageState['timezoneOffset'] = useSelector(
    (state: T.State) => state.Pages.Common.timezoneOffset
  );

  useEffect(() => {
    setCurYear(selectedDate.getFullYear());
    setCurMonthIndex(selectedDate.getMonth());
  }, [selectedDate]);

  const handleDayClick: (
    day: Date,
    modifiers: DayModifiers,
    e: MouseEvent<HTMLDivElement>
  ) => void = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }
    /**
     * @desc react-day-picker returns Tue Jul 04 2017 **12:00:00** GMT+0300 (MSK)
     * We have to refine the time
     * This will make time 'YYYY/MM/DD 00:00:00 UTC'.
     */
    if (selectedDate && isSameDay(selectedDate, day)) {
      return;
    }
    const refinedDate: Date = makeConsistentUTCDateViaOffset(day, timezoneOffset);
    setSelectedDate(refinedDate);
  };

  const handleDayTouchEnd: (
    day: Date,
    modifiers: DayModifiers,
    e: TouchEvent<HTMLDivElement>
  ) => void = (day, modifiers, e) => {
    handleDayClick(day, modifiers, e as unknown as MouseEvent<HTMLDivElement>);
  };

  function handleOnClickPrev() {
    const rawPrevMonthIndex = curMonthIndex - 1;
    const prevYear = rawPrevMonthIndex < 0 ? curYear - 1 : curYear;
    const prevMonthIndex = (12 + rawPrevMonthIndex) % 12;
    setCurYear(prevYear);
    setCurMonthIndex(prevMonthIndex);
  }

  function handleOnClickNext() {
    const rawNextMonthIndex = curMonthIndex + 1;
    const nextYear = rawNextMonthIndex >= 12 ? curYear + 1 : curYear;
    const nextMonthIndex = rawNextMonthIndex % 12;
    setCurYear(nextYear);
    setCurMonthIndex(nextMonthIndex);
  }

  function handleOnCancel() {
    onCancel();
  }

  function handleOnConfirm() {
    onConfirm(selectedDate);
  }

  const dueDateString = (() => {
    switch (language) {
      case T.Language.KO_KR:
        return `${curYear}${Text.year[T.Language.KO_KR]} ${
          Text.month[T.Language.KO_KR][curMonthIndex]
        }`;
      case T.Language.EN_US:
        return `${Text.month[T.Language.EN_US][curMonthIndex]} ${curYear}`;
      default:
        return '';
    }
  })();

  return (
    <CalendarAreaRoot>
      <IssueDueDateHeader>
        <IssueDueDateCaption>{dueDateString}</IssueDueDateCaption>
        <IssueDueDateButtonsWrapper>
          <IssueDueDateButton onClick={handleOnClickPrev}>
            <IssueDueDatePrevSvg />
          </IssueDueDateButton>
          <IssueDueDateButton onClick={handleOnClickNext}>
            <IssueDueDateNextSvg />
          </IssueDueDateButton>
        </IssueDueDateButtonsWrapper>
      </IssueDueDateHeader>
      <DayPicker
        canChangeMonth={false}
        showOutsideDays={true}
        fixedWeeks={true}
        month={new Date(curYear, curMonthIndex)}
        onDayClick={handleDayClick}
        onDayTouchEnd={handleDayTouchEnd}
        locale={language}
        weekdaysShort={Text.weekdaysShort[language]}
        weekdaysLong={Text.weekdaysLong[language]}
        selectedDays={selectedDate}
      />
      <IssueDueDateUIButtonWrapper>
        <IssueDueDateUIButton onClick={handleOnCancel}>{l10n(Text.cancel)}</IssueDueDateUIButton>
        <IssueDueDateUIButton onClick={handleOnConfirm}>{l10n(Text.setDate)}</IssueDueDateUIButton>
      </IssueDueDateUIButtonWrapper>
    </CalendarAreaRoot>
  );
}

interface Props {
  content: T.IssueContent;
}

function IssueDueDatePicker({ content }: Props) {
  const [l10n, language] = useL10n();
  const [isCalendarShown, setIsCalendarShown] = useState<boolean>(false);
  const mainAreaRef = useRef<HTMLDivElement>(null);

  const { patchIssueContent } = useIssueContents();

  useClickOutside({
    ref: mainAreaRef,
    callback: () => {
      setIsCalendarShown(false);
    },
  });

  function handleOnDueDateCancel() {
    setIsCalendarShown(false);
  }

  function handleOnDueDateConfirm(newDueDate: Date) {
    patchIssueContent({
      content: {
        id: content.id,
        dueDate: newDueDate,
      },
    });
    setIsCalendarShown(false);
  }

  const dueDateString =
    content.dueDate !== null
      ? (() => {
          switch (language) {
            case T.Language.KO_KR:
              return `${content.dueDate.getFullYear()}${Text.year[T.Language.KO_KR]} ${
                Text.month[T.Language.KO_KR][content.dueDate.getMonth()]
              } ${content.dueDate.getDate()}${Text.date[T.Language.KO_KR]} ${
                Text.weekday[T.Language.KO_KR][content.dueDate.getDay()]
              }`;
            case T.Language.EN_US:
              return `${Text.weekday[T.Language.EN_US][content.dueDate.getDay()]}, ${
                Text.month[T.Language.EN_US][content.dueDate.getMonth()]
              } ${content.dueDate.getDate()}, ${content.dueDate.getFullYear()}`;
            default:
              return '';
          }
        })()
      : null;

  const valueViewer =
    content.dueDate !== null ? (
      <ValueViewer>{dueDateString}</ValueViewer>
    ) : (
      <Placeholder>{l10n(Text.placeholder)}</Placeholder>
    );

  const calendarArea = isCalendarShown ? (
    <CalendarArea
      initSelectedDate={content.dueDate}
      onCancel={handleOnDueDateCancel}
      onConfirm={handleOnDueDateConfirm}
    />
  ) : null;

  return (
    <Root>
      <Title>{l10n(Text.dueDate)}</Title>
      <MainArea ref={mainAreaRef}>
        <MainButton onClick={() => setIsCalendarShown(!isCalendarShown)}>
          <CalendarIconWrapper>
            <IssueDueDateSvg />
          </CalendarIconWrapper>
          {valueViewer}
        </MainButton>
        {calendarArea}
      </MainArea>
    </Root>
  );
}

export default IssueDueDatePicker;
