import {
  QueryClient,
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useStore } from 'react-redux';
import { Store } from 'redux';

import { APIToUser as APIUserToUser, setUserInfo } from '../../react-query/users';
import { ERROR } from '../types';
import { PermissionKeys, PermissionURLs } from './constant';
import { AuthHeader, makeAuthHeader } from '^/store/duck/API';
import * as T from '^/types';
import { DEFAULT_USER_FEATURE_PERMISSION } from '^/utilities/withFeatureToggle';
import { usePermissionStore } from '^/store/permissionStore';
import { useL10n } from '^/hooks';
import { useCallback } from 'react';
import { http } from '^/utilities/api';

interface GetPermissionResponse {
  readonly data: T.APIPermission[];
}

type PartialPermission = Omit<T.Permission, 'projectId'>;
const APIToPartialPermission: (response: T.APIPermission) => PartialPermission = ({
  avatar,
  createdAt,
  updatedAt,
  ...response
}) => ({
  ...response,
  avatar: avatar ? avatar : undefined,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
});

const APIToPermission: (projectId: number, response: T.APIPermission) => T.Permission = (
  projectId,
  response
) => ({
  projectId,
  ...APIToPartialPermission(response),
});

const APIPermissionToAPIUser: (response: T.APIPermission) => T.APIUser = ({
  userId,
  createdAt,
  ...response
}) => ({
  id: String(userId),
  type: 'users',
  attributes: {
    ...response,
    ...response.user,
    createdAt: new Date(createdAt),
    featurePermission: DEFAULT_USER_FEATURE_PERMISSION,
    role: response.user.role,
  },
});

const getPermissionQueryfn =
  (store: Store<T.State>) =>
  async ({ queryKey }: QueryFunctionContext<ReturnType<typeof PermissionKeys.getPermission>>) => {
    const state = store.getState();
    const projectId = queryKey[1].projectId;
    if (!projectId) {
      throw new Error('projectId is undefined.');
    }
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );
    if (headers === undefined) {
      throw new Error(ERROR.AuthHeaderUndefinedError);
    }

    const response = await http.get<GetPermissionResponse>(
      PermissionURLs.getPermissionURL(projectId),
      { headers }
    );
    response.data.data.forEach((APIPermission: T.APIPermission) => {
      const apiUser = APIPermissionToAPIUser(APIPermission);
      const user = APIUserToUser(apiUser, state.PlanConfig.config?.slug);
      setUserInfo(user);
    });

    const permission: T.Permission[] = response.data.data.map(APIPermission =>
      APIToPermission(projectId, APIPermission)
    );

    return permission;
  };

export const usePermissionQuery = (projectId?: number) => {
  const store = useStore();
  const searchText = usePermissionStore(s => s.searchText).toLowerCase();
  const [, language] = useL10n();

  return useQuery(PermissionKeys.getPermission(projectId), getPermissionQueryfn(store), {
    enabled: Boolean(projectId),
    refetchOnMount: true,
    select(data) {
      return data.filter(permission => {
        const name =
          language === T.Language.KO_KR
            ? `${permission.lastName} ${permission.firstName}`
            : `${permission.firstName} ${permission.lastName}`;
        return (
          permission.email.toLowerCase().includes(searchText) ||
          name.toLowerCase().includes(searchText)
        );
      });
    },
  });
};

export const invalidatePermissionQuery = async (client: QueryClient, projectId: number) =>
  client.invalidateQueries({ queryKey: PermissionKeys.getPermission(projectId) });

interface PostPermissionBody {
  readonly data: Array<
    Readonly<{
      email: string;
      role: T.PermissionRole;
    }>
  >;
  language?: T.Language;
}
interface PostPermission {
  projectId: T.Project['id'];
  permissions: PostPermissionBody['data'];
  language?: T.Language;
}
const PostPermissionMutationFn =
  (store: Store<T.State>) =>
  async ({ permissions: actionPermissions, projectId, language }: PostPermission) => {
    const state = store.getState();
    const URL = PermissionURLs.postPermissionURL(projectId);
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );
    const body: PostPermissionBody = {
      data: actionPermissions,
      language,
    };
    const response = await http.post<T.APIPermission>(URL, body, { headers });
    return response.data;
  };

export const usePostPermissionMutation = () => {
  const store = useStore();
  const queryClient = useQueryClient();
  const mutationFn = useCallback(PostPermissionMutationFn(store), [store]);

  return useMutation(mutationFn, {
    async onSuccess(_data, { projectId }) {
      await queryClient.invalidateQueries(PermissionKeys.getPermission(projectId));
    },
  });
};

interface PatchPermission {
  role: T.PermissionRole;
  feature_ess?: boolean;
  id: number;
}
type PatchPermissionBody = Omit<PatchPermission, 'id'>;
const patchPermissionMutationFn =
  (store: Store<T.State>) =>
  async ({ id, role, feature_ess }: PatchPermission) => {
    const state = store.getState();
    const URL = PermissionURLs.patchPermissionURL(id);
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );
    const body: PatchPermissionBody = { role, feature_ess };
    const response = await http.patch<T.APIPermission>(URL, body, { headers });
    return response.data;
  };

export const usepatchPermissionMutation = (projectId: number | undefined) => {
  const queryClient = useQueryClient();
  const store = useStore();
  const mutationFn = useCallback(patchPermissionMutationFn(store), [store]);

  return useMutation(mutationFn, {
    async onSuccess() {
      if (projectId) {
        await queryClient.invalidateQueries(PermissionKeys.getPermission(projectId));
      }
    },
  });
};

interface DeletePermission {
  id: number;
}
const deletePermissionMutationFn =
  (store: Store<T.State>) =>
  async ({ id }: DeletePermission) => {
    const state = store.getState();
    const URL = PermissionURLs.deletePermissionURL(id);
    const headers: AuthHeader | undefined = makeAuthHeader(
      state.Auth,
      state.PlanConfig.config?.slug
    );
    const response = await http.delete<T.APIPermission>(URL, { headers });
    return response.data;
  };

export const useDeletePermissionMutation = (projectId: number | undefined) => {
  const queryClient = useQueryClient();
  const store = useStore();
  const mutationFn = useCallback(deletePermissionMutationFn(store), [store]);

  return useMutation(mutationFn, {
    async onSuccess() {
      if (projectId) {
        await queryClient.invalidateQueries(PermissionKeys.getPermission(projectId));
      }
    },
  });
};
