import { GroupedContentsListHeader } from '^/components/molecules/GroupedContentsListHeader/ForDroneStationItems';
import palette from '^/constants/palette';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import React, { FC, memo, useCallback, useRef } from 'react';
import ScrollBars, { ScrollbarProps } from 'react-custom-scrollbars-2';
import styled, { CSSObject } from 'styled-components';
import { Fallback } from './fallback';

import { BaseButton } from '^/components/atoms/Buttons';
import { ContentToItem } from '^/components/atoms/ContentToItem';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { UseL10n, useL10n } from '^/hooks';
import { ChangeIsInFlightScheduleCreation } from '^/store/duck/Pages';
import { useFlightScheduleStore } from '^/store/flightScheduleStore';
import { useGetFlightSchedulesQuery } from '^/store/react-query/drone-station/flight-schedule';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import Text from './text';

const HorizontalDividerWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  paddingTop: '3px',
  paddingLeft: '10px',
  paddingRight: '10px',

  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
});

const HorizontalDivider = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  height: '1px',
  background: palette.toggleButtonGray.toString(),
});

export const FlightScheduleContentsListRoot = styled.div({
  width: '100%',
  height: '100%',
  fontSize: '15px',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),

  display: 'flex',
  flexDirection: 'column',
});
FlightScheduleContentsListRoot.displayName = 'GroupedContentsListRoot';

export const FlightSchedulesWrapper = styled.div({});

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',

  display: 'flex',
  flexDirection: 'column',
};

const GroupListDivider = styled.div({
  position: 'sticky',
  zIndex: 10,
  top: '43px',
  height: '6px',
  marginTop: '17.5px',
  borderTop: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  borderBottom: `1px solid ${palette.ContentsList.groupListDividerBorderGray.toString()}`,
  backgroundColor: palette.ContentsList.groupListDividerBackgroundGray.toString(),
  boxSizing: 'border-box',
});
GroupListDivider.displayName = 'GroupListDivider';

const LoaderIconWrapper = styled.div({
  width: '100%',
  height: '60px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: palette.white.toString(),
});

const LoaderText = styled.p({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '44px',
  fontSize: '14px',
  textAlign: 'center',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
  color: palette.ContentsList.groupListHeaderTextGray.toString(),
});

const FlightSchedulesListWrapper = styled.div({
  flex: '1',
});

const FlightSchedulesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const FlexGrowWrapper = styled.div({
  flexGrow: '1',
});

const ScrollBarsWrapper = styled(ScrollBars)<ScrollbarProps>({});
const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

const ButtonWrapper = styled.div({
  boxSizing: 'border-box',

  width: '100%',

  padding: '10px 20px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '6px',
});

export const CreateButton = styled(BaseButton)(({ isDisabled }) => ({
  backgroundColor: palette.ContentsList.itemBackgroundGray.toString(),
  color: palette.buttonFontColor.toString(),
  ':hover': isDisabled
    ? undefined
    : {
        backgroundColor: palette.UploadPopup.hoverGray.toString(),
      },

  ':disabled': {
    color: palette.disabledFont.toString(),
    backgroundColor: palette.iconDisabled.toString(),
  },
}));
const HIDE_SCROLL_BAR_TIMEOUT: number = 0;
const HIDE_SCROLL_BAR_DURATION: number = 500;

const Contents: FC = () => {
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const {
    data: flightSchedules,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
  } = useGetFlightSchedulesQuery();

  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          void fetchNextPage();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  const handleFlightScheduleCreation = useCallback(() => {
    dispatch(
      ChangeIsInFlightScheduleCreation({
        isInFlightScheduleCreation: true,
      })
    );
  }, []);

  const rawLoadingDiv: React.ReactNode = (
    <LoaderIconWrapper>
      <LoadingIcon />
    </LoaderIconWrapper>
  );

  const rawLoadingText: React.ReactNode = <LoaderText>{l10n(Text.emptyData)}</LoaderText>;

  if (isLoading || isError) {
    return <>{rawLoadingText}</>;
  }

  const allFlightSchedules = flightSchedules.pages.flatMap(single => single.list) ?? [];
  return (
    <FlightSchedulesContainer>
      <ScrollBarsWrapper
        renderView={ScrollBarView}
        autoHide={true}
        autoHideTimeout={HIDE_SCROLL_BAR_TIMEOUT}
        autoHideDuration={HIDE_SCROLL_BAR_DURATION}
      >
        <FlightSchedulesListWrapper>
          {allFlightSchedules.map(content => (
            <FlightSchedulesWrapper key={content.id} ref={lastElementRef}>
              <ContentToItem contentId={content.id} isFlightSchedule={true} />
            </FlightSchedulesWrapper>
          ))}

          {hasNextPage && isFetching ? <>{rawLoadingDiv}</> : null}
        </FlightSchedulesListWrapper>
      </ScrollBarsWrapper>

      <ButtonWrapper>
        <CreateButton onClick={handleFlightScheduleCreation}>{l10n(Text.createText)}</CreateButton>
      </ButtonWrapper>
    </FlightSchedulesContainer>
  );
};

const FlightScheduleContentsList: FC = memo(() => {
  const { selectedFlightScheduleSort, setSelectedFlightScheduleSort } = useFlightScheduleStore(
    s => ({
      selectedFlightScheduleSort: s.selectedFlightScheduleSort,
      setSelectedFlightScheduleSort: s.setSelectedFlightScheduleSort,
    })
  );

  return (
    <FlightScheduleContentsListRoot>
      <GroupedContentsListHeader
        selectedSortValue={selectedFlightScheduleSort}
        onChangeSortValue={setSelectedFlightScheduleSort}
      />
      <HorizontalDividerWrapper>
        <HorizontalDivider />
      </HorizontalDividerWrapper>
      <FlexGrowWrapper>
        <Contents />
      </FlexGrowWrapper>
    </FlightScheduleContentsListRoot>
  );
});

export default memo(withErrorBoundary(FlightScheduleContentsList)(Fallback));
