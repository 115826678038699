import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Option as DropdownOption } from '^/components/atoms/Dropdown/1';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import { l10n } from '^/utilities/l10n';
import Text from './text';
import useEarthWorkFile from '^/hooks/earthwork/useEarthWorkFile';
import { AttachDropdownWrapper } from './FinalEarthWorkSelectAttachment';
import AttachDropdown from '^/components/atoms/AttachDropdown';
import { Option } from '^/components/atoms/Dropdown';
import SelectedPlaceholder from './SelectedPlaceholder';
import { useDispatch } from 'react-redux';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import * as T from '^/types';

const Root = styled.div({
  fontSize: '11px',
  fontWeight: 500,
  color: palette.textBlack.toString(),
});
Root.displayName = 'DXFContentSelectDropdown';

const NotificationWrapper = styled.div({
  marginTop: '10px',
  fontSize: '11px',
  lineHeight: 1,
  fontWeight: 'normal',
  color: palette.error.toString(),
});

export interface Props {
  value?: string;
  isError?: boolean;
  options?: DropdownOption[];
  onSelect(option: Option['value']): void;
  onDeleteClick?(): void;
}

const DxfContentSelectDropdown: FC<Props & L10nProps> = ({
  isError,
  onSelect,
  language,
  value,
  onDeleteClick,
}) => {
  const dispatch = useDispatch();
  const { dxfOptions } = useEarthWorkFile();

  const handleDropdownClick: (v: Option['value']) => void = v => onSelect(v);

  const selectRequiredNotification: ReactNode = isError ? (
    <NotificationWrapper>{l10n(Text.volume.error, language)}</NotificationWrapper>
  ) : undefined;

  const textValue = value ? dxfOptions.find(option => option.value === value)?.text : '';

  const handleUpload: () => void = () => {
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.DESIGN_UPLOAD }));
  };

  return (
    <Root>
      <AttachDropdownWrapper>
        {value ? (
          <SelectedPlaceholder
            textValue={textValue}
            onDeleteClick={onDeleteClick}
            onClick={() => {}}
            placeHolderText={l10n(Text.finalEarthWork.placeHolder, language)}
          />
        ) : (
          <AttachDropdown
            text={l10n(Text.dxfContent.placeholder, language)}
            options={dxfOptions}
            attachFileText={l10n(Text.fileText, language)}
            noOptionText={l10n(Text.noPreviousFile, language)}
            fileExtension=".dxf"
            onClick={handleDropdownClick}
            isStatic={true}
            onAttach={() => {}}
            callback={handleUpload}
            isDxf={true}
          />
        )}

        {selectRequiredNotification}
      </AttachDropdownWrapper>
    </Root>
  );
};

export default withL10n(DxfContentSelectDropdown);
