import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import * as T from '^/types';

interface ContentFilters {
  [filterType: string]: {
    filterText: string;
    filterContents: T.ContentType[];
  };
}

interface ESSContentsState {
  editingESSContentId: T.ESSContent['id'] | undefined;
  deletingESSContentId: T.ESSContent['id'] | undefined;
  essContentsResponse: T.APIESSContent[];
  essContents: Record<T.ESSContent['id'], T.ESSContent>;
  essContentIds: Array<T.ESSContent['id']>;
  rootESSContentIds: Array<T.ESSContent['id']>;
  rootESSContentIdsBySpace: {
    open: Array<T.ESSContent['id']>;
    personal: Array<T.ESSContent['id']>;
  };
  essContentGroupTree: Record<T.ESSContent['id'], Array<T.ESSContent['id']>>;
  fetchESSContentStatus: T.APIStatus;
  contentFilters: ContentFilters;
  selectedESSGroupIdByTab: Record<Partial<T.ContentPageTabType>, T.GroupContent['id'] | undefined>;
  openSpaceRootESSContentIdsLimit: number;
  essNewContentId: T.ESSContent['id'] | undefined;
}
interface ESSContentsActions {
  setOpenSpaceRootESSContentIdsLimit(limit: number): void;
  setEditingESSContentId(essContentId: T.ESSContent['id'] | undefined): void;
  setDeletingESSContentId(essContentId: T.ESSContent['id'] | undefined): void;
  setESSContentsResponse(essContentResponse: T.APIESSContent[]): void;
  setESSContent(
    essContent: T.ESSContent,
    essReponse: T.APIESSContent | null,
    moveOption?: T.MoveOption,
    target?: T.Movable
  ): void;
  setESSContents(essContents: Record<T.ESSContent['id'], T.ESSContent>): void;
  setESSContentIds(essContentIds: Array<T.ESSContent['id']>): void;
  buildESSContentGroupTree(essContents?: Record<T.ESSContent['id'], T.ESSContent>): void;
  removeESSContent(essContentId: T.ESSContent['id']): void;
  removeESSContentGroup(essContentGroupId: T.ESSContent['id']): void;
  setFetchESSContentStatus(status: T.APIStatus): void;
  setContentFilters(contentFilters: ContentFilters): void;
  updateSelectedAtInESSContents(ids: Array<T.ESSContent['id']>, selectedAt: Date | undefined): void;
  setSelectedESSGroupIdByTab(essGroupId: T.GroupContent['id'] | undefined): void;
  updateSingleESSContent(contentId: T.ESSContent['id'], info: T.ESSContent['info']): void;
  setESSNewContentId(essNewContentId: T.ESSContent['id'] | undefined): void;
  reset(): void;
}

export const defaultESSFilters = [T.ContentType.GROUP, ...T.ESSContentTypes];
const initialState: Omit<ESSContentsState, 'essContentsResponse'> = {
  editingESSContentId: undefined,
  deletingESSContentId: undefined,
  essContents: {},
  essContentIds: [],
  rootESSContentIds: [],
  rootESSContentIdsBySpace: {
    open: [],
    personal: [],
  },
  essContentGroupTree: {},
  fetchESSContentStatus: T.APIStatus.IDLE,
  contentFilters: {
    [T.ContentsListESSType.PERSONAL_ESS]: {
      filterText: '',
      filterContents: defaultESSFilters,
    },
    [T.ContentsListESSType.OPEN_ESS]: {
      filterText: '',
      filterContents: defaultESSFilters,
    },
  },
  selectedESSGroupIdByTab: { [T.ContentPageTabType.ESS]: undefined } as any,
  openSpaceRootESSContentIdsLimit: 10,
  essNewContentId: undefined,
};

type ESSContentsStore = ESSContentsState & ESSContentsActions;

export const essContentsStore = createStore<ESSContentsStore>()(
  devtools((set, get) => ({
    ...initialState,
    essContentsResponse: [],
    setOpenSpaceRootESSContentIdsLimit(limit) {
      set({ openSpaceRootESSContentIdsLimit: limit });
    },
    setEditingESSContentId(essContentId) {
      set({ editingESSContentId: essContentId });
    },
    setDeletingESSContentId(essContentId) {
      set({ deletingESSContentId: essContentId });
    },
    setESSContentsResponse(essContentsResponse) {
      set({ essContentsResponse });
    },
    setESSNewContentId(essNewContentId) {
      set({ essNewContentId });
    },
    setESSContent(essContent, essReponse, moveOption, target) {
      // Create ESS content if not exists or update existing
      const { essContents, essContentIds, essContentsResponse } = get();
      const newESSContents = {
        ...essContents,
        [essContent.id]: essContent,
      };
      let newESSContentIds = [...essContentIds];
      let newEssContentsResponse = [...essContentsResponse];

      // updating essresponse content
      if (essReponse) {
        const responseIndex = essContentsResponse.findIndex(ess => ess.id === essReponse.id);
        newEssContentsResponse[responseIndex] = essReponse;
      }

      switch (moveOption) {
        case T.MoveOption.FIRST: {
          newESSContentIds.unshift(essContent.id);
          if (essReponse) {
            newEssContentsResponse.unshift(essReponse);
          }
          break;
        }
        case T.MoveOption.PREVIOUS: {
          if (target === undefined) {
            throw new Error('Target is required when moveOption is PREVIOUS');
          }

          const targetIndex = newESSContentIds.indexOf(target.id);

          newESSContentIds = [
            ...newESSContentIds.filter(id => id !== essContent.id).slice(0, targetIndex),
            essContent.id,
            ...newESSContentIds.filter(id => id !== essContent.id).slice(targetIndex),
          ];

          // modifying ess response
          if (essReponse) {
            const essIndex = essContentsResponse.findIndex(ess => ess.id === target.id);

            newEssContentsResponse = [
              ...essContentsResponse.filter(ess => ess.id !== essContent.id).slice(0, essIndex),
              essReponse,
              ...essContentsResponse.filter(ess => ess.id !== essContent.id).slice(essIndex),
            ];
          }
          break;
        }
        case T.MoveOption.NEXT: {
          if (target === undefined) {
            throw new Error('Target is required when moveOption is NEXT');
          }

          const targetIndex = newESSContentIds.indexOf(target.id);
          newESSContentIds = [
            ...newESSContentIds.filter(id => id !== essContent.id).slice(0, targetIndex),
            essContent.id,
            ...newESSContentIds.filter(id => id !== essContent.id).slice(targetIndex),
          ];
          // modifying ess response
          if (essReponse) {
            const essIndex = essContentsResponse.findIndex(ess => ess.id === target.id);
            newEssContentsResponse = [
              ...essContentsResponse.filter(ess => ess.id !== essContent.id).slice(0, essIndex),
              essReponse,
              ...essContentsResponse.filter(ess => ess.id !== essContent.id).slice(essIndex),
            ];
          }
          break;
        }
        case T.MoveOption.LAST: {
          newESSContentIds.concat(essContent.id);
          if (essReponse) {
            newEssContentsResponse.concat(essReponse);
          }
          break;
        }
        default:
          if (!essContentIds.includes(essContent.id)) {
            newESSContentIds.concat(essContent.id);
          }
          // modifying ess response
          if (essReponse && !essContentsResponse.some(res => res.id === essReponse.id)) {
            newEssContentsResponse.concat(essReponse);
          }
      }

      set({
        essContentsResponse: newEssContentsResponse,
        essContents: newESSContents,
        essContentIds: newESSContentIds,
        rootESSContentIds: newESSContentIds.filter(
          id => newESSContents[id].type === T.ContentType.GROUP
        ),
        rootESSContentIdsBySpace: {
          open: newESSContentIds.filter(
            id =>
              newESSContents[id].isPersonal === false &&
              newESSContents[id].type === T.ContentType.GROUP
          ),
          personal: newESSContentIds.filter(
            id =>
              newESSContents[id].isPersonal === true &&
              newESSContents[id].type === T.ContentType.GROUP
          ),
        },
      });
    },
    setESSContents(essContents) {
      set({ essContents });
    },
    setESSContentIds(essContentIds) {
      const { essContents } = get();
      set({
        essContentIds,
        rootESSContentIds: essContentIds.filter(id => essContents[id].type === T.ContentType.GROUP),
        rootESSContentIdsBySpace: {
          open: essContentIds.filter(
            id =>
              essContents[id].isPersonal === false && essContents[id].type === T.ContentType.GROUP
          ),
          personal: essContentIds.filter(
            id =>
              essContents[id].isPersonal === true && essContents[id].type === T.ContentType.GROUP
          ),
        },
      });
    },
    buildESSContentGroupTree(_essContents) {
      const { essContentIds } = get();
      const essContents = _essContents ?? get().essContents;
      const newESSContentGroupTree: Record<T.GroupContent['id'], Array<T.ESSContent['id']>> = {};

      Object.values(essContents).forEach(essContent => {
        // Determine the key based on whether the type is content_group or if it has a groupId
        const key = essContent.type === T.ContentType.GROUP ? essContent.id : essContent.groupId;

        if (key) {
          // Initialize the key if it doesn't exist
          if (!(key in newESSContentGroupTree)) {
            newESSContentGroupTree[key] = [];
          }
        }
      });

      essContentIds.forEach(id => {
        const essContent = essContents[id];
        // If its not a content_group but has a groupId, add its id to the respective group
        if (essContent.type !== T.ContentType.GROUP && essContent.groupId) {
          newESSContentGroupTree[essContent.groupId].push(essContent.id);
        }
      });

      set({ essContentGroupTree: newESSContentGroupTree });
    },
    updateSelectedAtInESSContents(ids, selectedAt) {
      const { essContents, setESSContents } = get();

      // Update the selectedAt property of the targeted contents
      const targetContents: T.ESSContent[] = Object.values(essContents).filter(content =>
        ids.includes(content.id)
      );
      const newById: { [id: T.ESSContent['id']]: T.ESSContent } = {};

      targetContents.forEach(content => {
        const newContent: any = {
          ...content,
          config: {
            ...content.config,
            selectedAt: selectedAt,
          },
        };
        newById[content.id] = newContent;
      });
      const newContents = {
        ...essContents,
        ...newById,
      };
      setESSContents(newContents);
    },
    removeESSContent(essContentId) {
      const {
        essContents,
        essContentIds,
        rootESSContentIds,
        rootESSContentIdsBySpace,
        essContentGroupTree,
        essContentsResponse,
      } = get();

      const newESSContents = { ...essContents };
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newESSContents[essContentId];

      const newESSContentGroupTree = { ...essContentGroupTree };
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newESSContentGroupTree[essContentId];

      set({
        // removing from ess response
        essContentsResponse: essContentsResponse.filter(res => res.id !== essContentId),
        essContents: newESSContents,
        essContentIds: essContentIds.filter(id => id !== essContentId),
        rootESSContentIds: rootESSContentIds.filter(id => id !== essContentId),
        rootESSContentIdsBySpace: {
          open: rootESSContentIdsBySpace.open.filter(id => id !== essContentId),
          personal: rootESSContentIdsBySpace.personal.filter(id => id !== essContentId),
        },
        essContentGroupTree: newESSContentGroupTree,
      });
    },
    removeESSContentGroup(essContentGroupId) {
      const {
        essContentGroupTree,
        essContents,
        essContentIds,
        rootESSContentIds,
        rootESSContentIdsBySpace,
        essContentsResponse,
      } = get();
      const childrenIds = essContentGroupTree[essContentGroupId] ?? [];

      const newESSContents = { ...essContents };
      const newESSContentGroupTree = { ...essContentGroupTree };

      childrenIds.forEach(id => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newESSContents[id];

        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newESSContentGroupTree[id];
      });

      const newEssContentsIds = essContentIds.filter(
        id => !childrenIds.includes(id) && id !== essContentGroupId
      );

      const newRootESSContentIds = rootESSContentIds.filter(id => id !== essContentGroupId);

      const newRootESSContentIdsBySpaceOpen = rootESSContentIdsBySpace.open.filter(
        id => id !== essContentGroupId
      );
      const newRootESSContentIdsBySpacePersonal = rootESSContentIdsBySpace.personal.filter(
        id => id !== essContentGroupId
      );

      set({
        essContentsResponse: essContentsResponse.filter(res => res.id !== essContentGroupId),
        essContents: newESSContents,
        essContentIds: newEssContentsIds,
        rootESSContentIds: newRootESSContentIds,
        rootESSContentIdsBySpace: {
          open: newRootESSContentIdsBySpaceOpen,
          personal: newRootESSContentIdsBySpacePersonal,
        },
        essContentGroupTree: newESSContentGroupTree,
      });
    },
    setFetchESSContentStatus(status) {
      set({ fetchESSContentStatus: status });
    },
    setContentFilters(contents) {
      const { contentFilters } = get();
      set({ contentFilters: { ...contentFilters, ...contents } });
    },
    setSelectedESSGroupIdByTab(essGroupId) {
      set({ selectedESSGroupIdByTab: { [T.ContentPageTabType.ESS]: essGroupId } as any });
    },
    // Add the function to update a single ESS content
    updateSingleESSContent(contentId, info) {
      const { essContents } = get();

      set({
        essContents: {
          ...essContents,
          [contentId]: {
            ...essContents[contentId],
            info: info,
          } as T.ESSContent,
        },
      });
    },
    reset() {
      set(initialState);
    },
  }))
);

export function useESSContentsStore(): ESSContentsStore;
export function useESSContentsStore<T>(selector: (state: ESSContentsStore) => T): T;
export function useESSContentsStore<T>(selector?: (state: ESSContentsStore) => T) {
  return useStore(essContentsStore, selector!);
}
