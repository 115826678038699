// import _ from 'lodash-es';

import { http } from '^/utilities/api';
import { APIToContent } from '^/store/duck/Contents';
import { AuthHeader, makeV2APIURL, VersionHeader } from '^/store/duck/API';
import { checkCategoryType, checkContentType } from '^/utilities/content-util';
import * as T from '^/types';

interface GetContentsResponse {
  readonly data: T.APIContent[];
}

/**
 * Fetches project details and updates the store.
 */
export async function fetchProject(
  projectId: number,
  authHeader: AuthHeader,
  versionHeader: VersionHeader
) {
  const URL = makeV2APIURL('projects', projectId);

  const response = await http.get(URL, { headers: { ...authHeader, ...versionHeader } });
  const project = response.data.data;

  return project;
}

/**
 * Fetches and processes contents for a project.
 */
export async function fetchContents(
  contentURL: string,
  authHeader: AuthHeader,
  versionHeader: VersionHeader
  // dispatch?: Dispatch
) {
  const response = await http.get(contentURL, { headers: { ...authHeader, ...versionHeader } });

  const contents = (response.data.data as GetContentsResponse['data'])
    .filter(({ category }) => checkCategoryType(category))
    .filter(({ type }) => checkContentType(type))
    .map(APIToContent);

  return contents;
}

/**
 * Fetches issue categories for a project.
 */
export async function fetchIssueCategories(
  projectId: number,
  authHeader: AuthHeader,
  versionHeader: VersionHeader
) {
  const response = await http.get(makeV2APIURL('projects', projectId, 'issue_categories'), {
    headers: { ...authHeader, ...versionHeader },
  });

  return response.data.data;
}

/**
 * Fetches issue statuses for a project.
 */
export async function fetchIssueStatuses(
  projectId: number,
  authHeader: AuthHeader,
  versionHeader: VersionHeader
) {
  const response = await http.get(makeV2APIURL('projects', projectId, 'issue_statuses'), {
    headers: { ...authHeader, ...versionHeader },
  });

  return response.data.data;
}
