/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-param-reassign */
import {
  Vector3,
  Vector2,
  BufferGeometry,
  Mesh,
  Matrix4,
  Line3,
  Triangle,
  Vector4,
  BufferAttribute,
} from 'three';
import { MathUtils } from '.';
import { OperationDebugData } from 'three-bvh-csg';
import { ExtendedTriangle } from 'three-mesh-bvh';

const EPSILON = 1e-14;
const FLOATING_COPLANAR_EPSILON = 1e-14;
const _AB = new Vector3();
const _AC = new Vector3();
const _CB = new Vector3();
const _edge = new Line3();
// eslint-disable-next-line @typescript-eslint/naming-convention
const _vec4_0 = new Vector4();
// eslint-disable-next-line @typescript-eslint/naming-convention
const _vec4_1 = new Vector4();
// eslint-disable-next-line @typescript-eslint/naming-convention
const _vec4_2 = new Vector4();

/**
 * classify the triangle of gemeotry
 * @param {Vector2[]} twoDPolygon
 * @param {number[]} indexAttr
 * @param {BufferGeometry} geometry
 */
export const classifyTriangles = (
  twoDPolygon: Vector2[],
  indexAttr: number[],
  geometry: BufferGeometry
): { fullyInsideIndices: number[]; partialInPolyline: number[]; fullyOutsideIndices: number[] } => {
  const positionAttr = geometry.attributes.position;
  const fullyInsideIndices: number[] = [];
  const partialInPolyline: number[] = [];
  const fullyOutsideIndices: number[] = [];

  for (let i = 0; i < indexAttr.length; i += 3) {
    const index1 = indexAttr[i];
    const index2 = indexAttr[i + 1];
    const index3 = indexAttr[i + 2];

    const v1 = new Vector2(positionAttr.getX(index1), positionAttr.getY(index1));
    const v2 = new Vector2(positionAttr.getX(index2), positionAttr.getY(index2));
    const v3 = new Vector2(positionAttr.getX(index3), positionAttr.getY(index3));

    const triangle = [v1, v2, v3];

    let insideCount = 0;
    for (const pt of triangle) {
      if (MathUtils.isPointInsideTwoDPolygon(pt, twoDPolygon)) {
        insideCount++;
      }
    }

    if (insideCount === 3) {
      fullyInsideIndices.push(index1, index2, index3);
    } else if (insideCount > 0) {
      partialInPolyline.push(index1, index2, index3);
    } else if (insideCount === 0) {
      // triangle 이 polyline 과 하나라도 intersect 하면 추가
      const triangle3d = [
        new Vector3(
          positionAttr.getX(index1),
          positionAttr.getY(index1),
          positionAttr.getZ(index1)
        ),
        new Vector3(
          positionAttr.getX(index2),
          positionAttr.getY(index2),
          positionAttr.getZ(index2)
        ),
        new Vector3(
          positionAttr.getX(index3),
          positionAttr.getY(index3),
          positionAttr.getZ(index3)
        ),
      ];
      const { intersections } = findTriangleIntersections(triangle3d, twoDPolygon);

      if (intersections.length > 0) {
        partialInPolyline.push(index1, index2, index3);
      } else {
        fullyOutsideIndices.push(index1, index2, index3);
      }
    } else {
      throw new Error('classify Error');
    }
  }

  return { fullyInsideIndices, partialInPolyline, fullyOutsideIndices };
};
/**
 * Find intersects of triangle and  2d polygon
 * @param {Vector3[]} triangle
 * @param {Vector2[]} twoDPolygon
 * @returns {Vector3[]} intersections
 * @returns {number[]} edgeIndices
 */
const findTriangleIntersections = (triangle: Vector3[], twoDPolygon: Vector2[]) => {
  const intersections: THREE.Vector3[] = [];
  const edgeIndices: number[] = [];

  for (let i = 0; i < 3; i++) {
    const edge = [
      new Vector2(triangle[i].x, triangle[i].y),
      new Vector2(triangle[(i + 1) % 3].x, triangle[(i + 1) % 3].y),
    ];

    // Find intersect with each segment in polygon
    for (let j = 0; j < twoDPolygon.length - 1; j++) {
      const seg = [twoDPolygon[j], twoDPolygon[j + 1]];

      const t = MathUtils.findIntersectionOfTwoSegments(edge, seg);
      if (t !== null) {
        const intersection = new Vector3().lerpVectors(triangle[i], triangle[(i + 1) % 3], t);
        intersections.push(intersection);
        edgeIndices.push(i);
      }
    }
  }

  return { intersections, edgeIndices };
};

/**
 * Update matrix and clone geometry
 * @param {Mesh} mesh
 * @returns {BufferGeometry} indexAttr
 */
export const cloneGeometry = (mesh: Mesh): BufferGeometry => {
  mesh.geometry.computeVertexNormals();
  mesh.updateMatrix();
  mesh.updateMatrixWorld();

  const geometry = mesh.geometry.clone();
  geometry.applyMatrix4(mesh.matrixWorld);
  geometry.computeBoundingBox();
  return geometry;
};

/**
 * This is class for intersect data
 * @class
 */
export class IntersectionMap {
  public intersectionSet: any = {};
  public ids: number[] = [];
  public constructor() {
    this.intersectionSet = {};
    this.ids = [];
  }

  public add(id: number, intersectionId: number) {
    const { intersectionSet, ids } = this;
    if (!intersectionSet[id]) {
      intersectionSet[id] = [];
      ids.push(id);
    }

    intersectionSet[id].push(intersectionId);
  }
}

/**
 * Find intersect between two mesh via traingles
 * @param {Mesh} a
 * @param {Mesh} b
 * @param {OperationDebugData} debugContext
 * @returns {IntersectionMap} aIntersections
 * @returns {IntersectionMap} bIntersections
 */
export const collectIntersectingTriangles = (
  a: Mesh,
  b: Mesh,
  debugContext?: OperationDebugData
): {
  aIntersections: IntersectionMap;
  bIntersections: IntersectionMap;
} => {
  const aIntersections = new IntersectionMap();
  const bIntersections = new IntersectionMap();

  a.updateMatrixWorld();
  b.updateMatrixWorld();
  const matrix2to1 = new Matrix4().copy(a.matrixWorld).invert().multiply(b.matrixWorld);

  a.geometry.boundsTree!.bvhcast(b.geometry.boundsTree!, matrix2to1, {
    intersectsTriangles(triangleA: ExtendedTriangle, triangleB: ExtendedTriangle, ia, ib) {
      if (!isTriDegenerate(triangleA) && !isTriDegenerate(triangleB)) {
        // due to floating point error it's possible that we can have two overlapping, coplanar triangles
        // that are a _tiny_ fraction of a value away from each other. If we find that case then check the
        // distance between triangles and if it's small enough consider them intersecting.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        let intersected = triangleA.intersectsTriangle(triangleB, _edge, true);
        if (!intersected) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const pa = triangleA.plane;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const pb = triangleB.plane;
          const na = pa.normal;
          const nb = pb.normal;

          if (na.dot(nb) === 1 && Math.abs(pa.constant - pb.constant) < FLOATING_COPLANAR_EPSILON) {
            intersected = true;
          }
        }

        if (intersected) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const va = a.geometry.boundsTree.resolveTriangleIndex(ia);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const vb = b.geometry.boundsTree.resolveTriangleIndex(ib);
          aIntersections.add(va, vb);
          bIntersections.add(vb, va);

          if (debugContext?.enabled) {
            debugContext.addEdge(_edge);
            debugContext.addIntersectingTriangles(ia, triangleA, ib, triangleB);
          }
        }
      }

      return false;
    },
  });

  return { aIntersections, bIntersections };
};
/**
 * Check triangle is degenrate or not
 * @param {Triangle} tri
 * @param {number} eps
 * @returns {boolean}
 */
export const isTriDegenerate = (tri: Triangle, eps = EPSILON): boolean => {
  // compute angles to determine whether they're degenerate
  _AB.subVectors(tri.b, tri.a);
  _AC.subVectors(tri.c, tri.a);
  _CB.subVectors(tri.b, tri.c);

  const angle1 = _AB.angleTo(_AC); // AB v AC
  const angle2 = _AB.angleTo(_CB); // AB v BC
  const angle3 = Math.PI - angle1 - angle2; // 180deg - angle1 - angle2

  return (
    Math.abs(angle1) < eps ||
    Math.abs(angle2) < eps ||
    Math.abs(angle3) < eps ||
    tri.a.distanceToSquared(tri.b) < eps ||
    tri.a.distanceToSquared(tri.c) < eps ||
    tri.b.distanceToSquared(tri.c) < eps
  );
};

/**
 * generate attribule via barycentric coordinate
 * @param {Vector4} v0
 * @param {Vector4} v1
 * @param {Vector4} v2
 * @param {number[]} attrArr
 * @param {number} itemSize
 * @param {boolean} invert
 * @param {Triangle} barycoordTri
 */
export const generateByBarycoord = (
  v0: Vector4,
  v1: Vector4,
  v2: Vector4,
  attrArr: number[],
  itemSize: number,
  invert: boolean = false,
  barycoordTri: Triangle
): void => {
  _vec4_0
    .set(0, 0, 0, 0)
    .addScaledVector(v0, barycoordTri.a.x)
    .addScaledVector(v1, barycoordTri.a.y)
    .addScaledVector(v2, barycoordTri.a.z);

  _vec4_1
    .set(0, 0, 0, 0)
    .addScaledVector(v0, barycoordTri.b.x)
    .addScaledVector(v1, barycoordTri.b.y)
    .addScaledVector(v2, barycoordTri.b.z);

  _vec4_2
    .set(0, 0, 0, 0)
    .addScaledVector(v0, barycoordTri.c.x)
    .addScaledVector(v1, barycoordTri.c.y)
    .addScaledVector(v2, barycoordTri.c.z);

  const addValues = (v: Vector4) => {
    attrArr.push(v.x);
    if (itemSize > 1) {
      attrArr.push(v.y);
    }
    if (itemSize > 2) {
      attrArr.push(v.z);
    }
    if (itemSize > 3) {
      attrArr.push(v.w);
    }
  };
  addValues(_vec4_0);

  if (invert) {
    addValues(_vec4_2);
    addValues(_vec4_1);
  } else {
    addValues(_vec4_1);
    addValues(_vec4_2);
  }
};

export const ensureIndex = (geo: BufferGeometry, options: any = {}) => {
  if (!geo.index) {
    const vertexCount = geo.attributes.position.count;
    const BufferConstructor: any = options.useSharedArrayBuffer ? SharedArrayBuffer : ArrayBuffer;
    const index = getIndexArray(vertexCount, BufferConstructor);
    geo.setIndex(new BufferAttribute(index, 1));

    for (let i = 0; i < vertexCount; i++) {
      index[i] = i;
    }
  }
};

export function getIndexArray(vertexCount: number, BufferConstructor = ArrayBuffer) {
  if (vertexCount > 65535) {
    return new Uint32Array(new BufferConstructor(4 * vertexCount));
  } else {
    return new Uint16Array(new BufferConstructor(2 * vertexCount));
  }
}

export const calcParts = (result: Mesh) => {
  const parts: BufferGeometry[] = [];
  const posMap: any = {};
  const positions = result.geometry.attributes.position;
  if (positions.count === 0) {
    return [];
  }
  const normals = result.geometry.attributes.normal;
  const uvs = result.geometry.attributes.uv;
  const cache: any = {};
  const hashify = (i: any) => {
    if (cache[i]) {
      return cache[i];
    }
    cache[i] =
      positions.getX(i).toFixed(2) + positions.getY(i).toFixed(2) + positions.getZ(i).toFixed(2);
    return cache[i];
  };
  for (let i = 0; i < positions.count; i += 3) {
    const vert1 = hashify(i);
    const vert2 = hashify(i + 1);
    const vert3 = hashify(i + 2);
    if (!posMap[vert1]) {
      posMap[vert1] = [];
    }
    if (!posMap[vert2]) {
      posMap[vert2] = [];
    }
    if (!posMap[vert3]) {
      posMap[vert3] = [];
    }
    posMap[vert1].push(vert2, vert3);
    posMap[vert2].push(vert1, vert3);
    posMap[vert3].push(vert1, vert2);
  }
  const noPoses: any = {};
  while (Object.keys(noPoses).length < positions.count) {
    const availableIs: number[] = []; //Array(positions.length).fill()
    for (let i = 0; i < positions.count; i++) {
      if (!noPoses[hashify(i)]) {
        availableIs.push(i);
      }
    }
    if (availableIs.length === 0) {
      break;
    }
    const randomPos = hashify(availableIs[0]);
    const posesCollected: any = {};
    const posesToGoThrough = [randomPos];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (posesToGoThrough.length === 0) {
        break;
      }
      const pos = posesToGoThrough.pop();
      posesCollected[pos] = true;
      const cs = posMap[pos];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < cs.length; i++) {
        if (!posesCollected[cs[i]]) {
          posesToGoThrough.push(cs[i]);
        }
      }
    }
    const part = new BufferGeometry();
    const newPositions: number[] = [];
    const newNormals: number[] = [];
    const newUvs: number[] = [];
    const madeTries: { [key: string]: boolean } = {};
    for (let i = 0; i < positions.count; i += 3) {
      const vert1 = hashify(i);
      const vert2 = hashify(i + 1);
      const vert3 = hashify(i + 2);
      const combined = vert1 + vert2 + vert3;
      if (
        posesCollected[vert1] &&
        posesCollected[vert2] &&
        posesCollected[vert3] &&
        !madeTries[combined]
      ) {
        madeTries[vert1 + vert2 + vert3] = true;
        madeTries[vert2 + vert3 + vert1] = true;
        madeTries[vert3 + vert1 + vert2] = true;
        noPoses[vert1] = true;
        noPoses[vert2] = true;
        noPoses[vert3] = true;
        newPositions.push(positions.getX(i), positions.getY(i), positions.getZ(i));
        newPositions.push(positions.getX(i + 1), positions.getY(i + 1), positions.getZ(i + 1));
        newPositions.push(positions.getX(i + 2), positions.getY(i + 2), positions.getZ(i + 2));
        newNormals.push(normals.getX(i), normals.getY(i), normals.getZ(i));
        newNormals.push(normals.getX(i + 1), normals.getY(i + 1), normals.getZ(i + 1));
        newNormals.push(normals.getX(i + 2), normals.getY(i + 2), normals.getZ(i + 2));
        newUvs.push(uvs.getX(i), uvs.getY(i));
        newUvs.push(uvs.getX(i + 1), uvs.getY(i + 1));
        newUvs.push(uvs.getX(i + 2), uvs.getY(i + 2));
      }
    }
    part.setAttribute('position', new BufferAttribute(new Float32Array(newPositions), 3));
    part.setAttribute('normal', new BufferAttribute(new Float32Array(newNormals), 3));
    part.setAttribute('uv', new BufferAttribute(new Float32Array(newUvs), 3));
    parts.push(part);
  }
  return parts;
};
