import { Language } from '^/types';

export default {
  firstBalloonTitle: {
    [Language.KO_KR]: '거리',
    [Language.EN_US]: 'Distance',
  },
  elevation: {
    [Language.KO_KR]: '단면도',
    [Language.EN_US]: 'Elevation Profile',
  },
  noDsmElevation: {
    [Language.KO_KR]: '수치표면모델(DSM) 데이터가 없어 단면도와 기울기 정보를 표시할 수 없습니다.',
    [Language.EN_US]: 'No DSM data. Elevation profile and slope information cannot be viewed.',
  },
  horizontalLength: {
    [Language.KO_KR]: '수평 거리',
    [Language.EN_US]: 'Horizontal Distance',
  },
  pointToPointLength: {
    [Language.KO_KR]: '직선 거리',
    [Language.EN_US]: 'Point to Point Distance',
  },
  ThreeHeightLength: {
    [Language.KO_KR]: '수직 높이',
    [Language.EN_US]: 'Height',
  },
  horizontalLengthTooltip: {
    [Language.KO_KR]: '동일 고도선상 거리',
    [Language.EN_US]: 'Distance of the cross-section along the same elevation',
  },
  pointToPointLengthTooltip: {
    [Language.KO_KR]: '두 점 사이의 최단 거리',
    [Language.EN_US]: 'The nearest distance between points',
  },
  ThreeHeightLengthTooltip: {
    [Language.KO_KR]: '최저점에서의 수직 높이',
    [Language.EN_US]: 'Vertical height of the terrain',
  },
  twoDDisabled: {
    [Language.KO_KR]: '2D로 전환 시, 지도에 시각화됩니다.',
    [Language.EN_US]: 'To visualize it on the map, switch to 2D Viewer.',
  },
  threeDDisabled: {
    [Language.KO_KR]: '3D로 전환 시, 지도에 시각화됩니다.',
    [Language.EN_US]: 'To visualize it on the map, switch to 3D Viewer.',
  },
  potreeDisabled: {
    [Language.KO_KR]: '포인트 클라우드로 전환 시, 지도에 시각화됩니다.',
    [Language.EN_US]: 'To visualize it on the map, switch to Point Cloud Viewer.',
  },
};
