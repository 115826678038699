import { BoxGeometry, Mesh, MeshPhongMaterial, Color } from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { ESSObject } from './ESSObject';
import { autobind } from 'core-decorators';
import { MaterialPool } from '../../Lib/Pools';

interface Metadata {
  thumbnailUrl: string;
  groupId: number | string | undefined;
}
const MATERIAL_POOL: MaterialPool = new MaterialPool(
  new MeshPhongMaterial({ color: 'white', transparent: true, opacity: 0.5 })
);

const _color = new Color();
const _color1 = new Color();
const _color2 = new Color(0xffffff);
class ESSCustomObject extends ESSObject {
  protected currentColor: string;
  protected opacity = 0.5;
  public depthTest: boolean = false;
  public constructor(contentId: string | number, viewer: Viewer, metadata: Metadata) {
    super(contentId, '', viewer, metadata);
    this.model = new Mesh(new BoxGeometry(1, 1, 1));
    this.currentColor = '#' + _color.getHexString();
    // _material.color.set(this.currentColor);
    (this.model as Mesh).material = MATERIAL_POOL.getMaterial(this.currentColor);
    this.add(this.model);
    this.workRadius.rotation.x = 0;
  }
  /**
   * @public
   * @description update material
   */
  public updateMaterial(color: string, opacity: number) {
    this.opacity = opacity;
    this.setColor(color);
  }
  /**
   * @public
   * @description set depthTest for material
   */
  public setDepthTest(depthTest: boolean) {
    this.depthTest = depthTest;
    ((this.model as THREE.Mesh)!.material as THREE.Material).depthTest = this.depthTest;
  }
  /**
   * @public
   * @description set color
   */
  @autobind
  public setColor(color: string): void {
    _color.set(color);
    this.currentColor = '#' + _color.getHexString();
    (this.model as Mesh).material = MATERIAL_POOL.getMaterial(this.currentColor, this.opacity);
    ((this.model as Mesh).material as MeshPhongMaterial).depthTest = this.depthTest;
  }
  /**
   * @inheritdoc
   * @override
   */
  @autobind
  public override setHighlight(): void {
    _color.lerpColors(_color1.set(this.currentColor), _color2, 0.1);
    (this.model as Mesh).material = MATERIAL_POOL.getMaterial(
      '#' + _color.getHexString(),
      this.opacity
    );
    ((this.model as Mesh).material as MeshPhongMaterial).depthTest = this.depthTest;
  }
  /**
   * @public
   * @inheritdoc
   * @override
   */
  @autobind
  public override setUnHighlight(): void {
    (this.model as Mesh).material = MATERIAL_POOL.getMaterial(this.currentColor, this.opacity);
    ((this.model as Mesh).material as MeshPhongMaterial).depthTest = this.depthTest;
  }
  /**
   * @inheritdoc
   * @extends
   */
  public dispose(): void {
    super.dispose();
    MATERIAL_POOL.reset();
  }
}

export { ESSCustomObject };
