/* eslint-disable max-lines */
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import * as T from '^/types';
import { L10nFn } from '^/hooks';
import Text2 from './text';
import { forEach } from 'lodash-es';

interface PhotoList extends Omit<T.FinalPhoto, 'takenAt'> {
  takenAt: Date;
}

export interface DayPhotos {
  [dayMonthYear: string]: T.FinalPhoto[];
}

interface MonthPhotos {
  [monthYear: string]: DayPhotos;
}

export interface SortedPhotos {
  title: string;
  timeline: Array<{
    dayTimeline: {
      [dayMonthYear: string]: T.FinalPhoto[];
    };
    droneUploads: T.FinalPhoto[];
  }>;
  count: number;
}

export function formatMonthYear(date: Date | string, lang: T.Language) {
  switch (lang) {
    case T.Language.KO_KR:
      return dayjs(date).format('YYYY년 M월');
    case T.Language.EN_US:
    default:
      return dayjs(date).format('MMMM YYYY');
  }
}

export function formatDayMonthYear(date: Date | string, lang: T.Language) {
  switch (lang) {
    case T.Language.KO_KR:
      return dayjs(date).locale('ko').format('YYYY년 M월 D일 (dd)');
    case T.Language.EN_US:
    default:
      return dayjs(date).format('ddd, D MMMM, YYYY');
  }
}

export function sortPhotosByDate(
  photos: T.FinalPhoto[],
  l10n: L10nFn,
  lang: T.Language
): {
  sortedPhotos: SortedPhotos[];
  unSortedPhotos: SortedPhotos[];
  droneSortedPhotos: SortedPhotos[];
  surveySortedPhotos: SortedPhotos[];
} {
  const datedPhotos: PhotoList[] = [];
  const undatedPhotos: T.FinalPhoto[] = [];

  // Split the photos into two arrays - dated and undated
  photos.forEach(photo => {
    if (photo.takenAt) {
      datedPhotos.push(photo as PhotoList);
    } else {
      undatedPhotos.push(photo);
    }
  });

  // Sort the dated photos by date in descending order
  datedPhotos.sort((a, b) => {
    if (a.takenAt && b.takenAt) {
      return b.takenAt.getTime() - a.takenAt.getTime();
    }
    return 0;
  });

  // Group the dated photos by month and year
  const groups: MonthPhotos = {};
  for (const photo of datedPhotos) {
    const date = new Date(photo.takenAt);

    const monthYear = formatMonthYear(date, lang);
    const dayMonthYear = formatDayMonthYear(date, lang);
    photo.dateString = dayMonthYear;
    if (!groups[monthYear]) {
      groups[monthYear] = {};
    }
    if (!groups[monthYear][dayMonthYear]) {
      groups[monthYear][dayMonthYear] = [];
    }
    groups[monthYear][dayMonthYear].push(photo);
  }

  // Create a new array of sorted photos with the dates displayed in timeline format
  const sortedPhotos: SortedPhotos[] = [];
  const unSortedPhotos: SortedPhotos[] = [];
  const droneSortedPhotos: SortedPhotos[] = [];
  const surveySortedPhotos: SortedPhotos[] = [];

  if (undatedPhotos.length > 0) {
    unSortedPhotos.push({
      title: '',
      count: undatedPhotos.length,
      timeline: [
        {
          dayTimeline: {
            [l10n(Text2.noDateSelectText)]: undatedPhotos.map(photo => ({ ...photo })),
          },
          droneUploads: [],
        },
      ],
    });
  }

  for (const monthYear of Object.keys(groups)) {
    const monthPhotos = groups[monthYear];
    const monthTimeline = [];
    const droneMonthTimeline = [];
    const surveyMonthTimeline = [];

    for (const dayMonthYear of Object.keys(monthPhotos)) {
      const dayPhotos = monthPhotos[dayMonthYear];
      const dayTimeline: any = {};
      const dayDroneTimeline: any = {};
      const daySurveyTimeLine: any = {};
      const inspectionPhotos: any = {};
      const threeSixtyPhotos: any = {};
      const threeSixtySourcePhotos: any = {};
      const threeSixtyStitchedPhotos: any = {};
      const threeSixtyVslamVideos: any = {};
      const threeSixtyVslamPhotos: any = {};
      const sourcePhotos: any = {};
      inspectionPhotos[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.photoType === 'drone'
      );
      threeSixtyPhotos[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.photoType === 'three_sixty'
      );
      threeSixtySourcePhotos[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.photoType === 'three_sixty_source'
      );
      threeSixtyStitchedPhotos[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.photoType === 'three_sixty_stitched'
      );
      threeSixtyVslamPhotos[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.photoType === 'three_sixty_vslam'
      );
      threeSixtyVslamVideos[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.photoType === 'three_sixty_video'
      );
      sourcePhotos[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.photoType === 'source' && photo.screenId
      );
      dayTimeline[dayMonthYear] = dayPhotos.filter(
        (photo: T.FinalPhoto) =>
          (photo.uploadSource === 'photo' &&
            photo.photoType !== 'drone' &&
            photo.photoType !== 'source' &&
            photo.photoType !== 'three_sixty' &&
            photo.photoType !== 'three_sixty_source' &&
            photo.photoType !== 'three_sixty_stitched' &&
            photo.photoType !== 'three_sixty_video' &&
            photo.photoType !== 'three_sixty_vslam') ||
          photo.type === 'overlay'
      );
      /**
       * This part help to create icon for inspection
       */
      generateFolder(inspectionPhotos, dayTimeline, dayMonthYear, T.PhotoAlbumType.INSPECTION);
      /**
       * This part help to create icon for soruce photo
       */
      generateFolder(sourcePhotos, dayTimeline, dayMonthYear, T.PhotoAlbumType.SOURCE);
      generateFolder(threeSixtyPhotos, dayTimeline, dayMonthYear, T.PhotoAlbumType.THREE_SIXTY);
      generateFolder(
        threeSixtySourcePhotos,
        dayTimeline,
        dayMonthYear,
        T.PhotoAlbumType.THREE_SIXTY_SOURCE
      );
      generateFolder(
        threeSixtyStitchedPhotos,
        dayTimeline,
        dayMonthYear,
        T.PhotoAlbumType.THREE_SIXTY_STITCHED
      );
      generateFolder(
        threeSixtyVslamPhotos,
        dayTimeline,
        dayMonthYear,
        T.PhotoAlbumType.THREE_SIXTY_VSLAM
      );
      generateFolder(
        threeSixtyVslamVideos,
        dayTimeline,
        dayMonthYear,
        T.PhotoAlbumType.THREE_SIXTY_VIDEO
      );

      // Check if the day photos contain drone uploads or survey uploads
      const surveyUploads = dayPhotos.filter(
        (photo: T.FinalPhoto) => photo.uploadSource === 'survey'
      );
      const droneUploads = dayPhotos.filter(
        (photo: T.FinalPhoto) =>
          (photo.uploadSource === 'application' || photo.uploadSource === 'drone') &&
          photo.type !== 'overlay'
      );

      if (droneUploads.length) {
        dayDroneTimeline[dayMonthYear] = dayPhotos.filter(
          (photo: T.FinalPhoto) =>
            (photo.uploadSource === 'application' || photo.uploadSource === 'drone') &&
            photo.type !== 'overlay'
        );
        droneMonthTimeline.push({ dayTimeline: dayDroneTimeline, droneUploads: [] });
      }

      if (surveyUploads.length) {
        daySurveyTimeLine[dayMonthYear] = dayPhotos.filter(
          (photo: T.FinalPhoto) => photo?.uploadType === 'surveys'
        );
        surveyMonthTimeline.push({ dayTimeline: daySurveyTimeLine, droneUploads: [] });
      }

      monthTimeline.push({ dayTimeline, droneUploads: droneUploads });
    }

    surveySortedPhotos.push({ title: monthYear, timeline: surveyMonthTimeline, count: 1 });
    droneSortedPhotos.push({ title: monthYear, timeline: droneMonthTimeline, count: 1 });
    sortedPhotos.push({
      title: monthYear,
      timeline: monthTimeline,
      count: datedPhotos.length,
    });
  }

  return { sortedPhotos, unSortedPhotos, droneSortedPhotos, surveySortedPhotos };
}

const generateFolder = (
  groupPhoto: any,
  dayTimeline: any,
  date: string,
  type: T.PhotoAlbumType
) => {
  if (groupPhoto[date].length !== 0) {
    if (dayTimeline[date].length === 0) {
      dayTimeline[date] = [];
    }
    if (type === T.PhotoAlbumType.INSPECTION) {
      dayTimeline[date].splice(0, 0, {
        id: `${type}_${groupPhoto[date][0].id}`,
        // items: groupPhoto[date],
        takenAt: groupPhoto[date][0].takenAt,
        imageUrl: '',
        thumbUrl: '',
        fullUrl: '',
        boxThumbUrl: '',
        photoType: type,
        isFolder: true,
        geotags: { gimbalPitchDegree: 1, gimbalYawDegree: 1, gimbalRollDegree: 1 },
        count: groupPhoto[date].length,
        screenTitle: groupPhoto[date][0].screenTitle,
      });
    } else if (type === T.PhotoAlbumType.THREE_SIXTY) {
      dayTimeline[date].splice(0, 0, {
        id: `${type}_${groupPhoto[date][0].id}`,
        // items: groupPhoto[date],
        takenAt: groupPhoto[date][0].takenAt,
        imageUrl: '',
        thumbUrl: '',
        fullUrl: '',
        boxThumbUrl: '',
        photoType: type,
        isFolder: true,
        geotags: { gimbalPitchDegree: 1, gimbalYawDegree: 1, gimbalRollDegree: 1 },
        count: groupPhoto[date].length,
        screenTitle: groupPhoto[date][0].screenTitle,
      });
    } else if (type === T.PhotoAlbumType.THREE_SIXTY_SOURCE) {
      dayTimeline[date].splice(0, 0, {
        id: `${type}_${groupPhoto[date][0].id}`,
        // items: groupPhoto[date],
        takenAt: groupPhoto[date][0].takenAt,
        imageUrl: '',
        thumbUrl: '',
        fullUrl: '',
        boxThumbUrl: '',
        photoType: type,
        isFolder: true,
        geotags: { gimbalPitchDegree: 1, gimbalYawDegree: 1, gimbalRollDegree: 1 },
        count: groupPhoto[date].length,
        screenTitle: groupPhoto[date][0].screenTitle,
      });
    } else if (type === T.PhotoAlbumType.THREE_SIXTY_STITCHED) {
      dayTimeline[date].splice(0, 0, {
        id: `${type}_${groupPhoto[date][0].id}`,
        // items: groupPhoto[date],
        takenAt: groupPhoto[date][0].takenAt,
        imageUrl: '',
        thumbUrl: '',
        fullUrl: '',
        boxThumbUrl: '',
        photoType: type,
        isFolder: true,
        geotags: { gimbalPitchDegree: 1, gimbalYawDegree: 1, gimbalRollDegree: 1 },
        count: groupPhoto[date].length,
        screenTitle: groupPhoto[date][0].screenTitle,
      });
    } else if (type === T.PhotoAlbumType.THREE_SIXTY_VIDEO) {
      dayTimeline[date].splice(0, 0, {
        id: `${type}_${groupPhoto[date][0].id}`,
        // items: groupPhoto[date],
        takenAt: groupPhoto[date][0].takenAt,
        imageUrl: '',
        thumbUrl: '',
        fullUrl: '',
        boxThumbUrl: '',
        photoType: type,
        isFolder: true,
        geotags: { gimbalPitchDegree: 1, gimbalYawDegree: 1, gimbalRollDegree: 1 },
        count: groupPhoto[date].length,
        screenTitle: groupPhoto[date][0].screenTitle,
      });
    } else if (type === T.PhotoAlbumType.THREE_SIXTY_VSLAM) {
      dayTimeline[date].splice(0, 0, {
        id: `${type}_${groupPhoto[date][0].id}`,
        // items: groupPhoto[date],
        takenAt: groupPhoto[date][0].takenAt,
        imageUrl: '',
        thumbUrl: '',
        fullUrl: '',
        boxThumbUrl: '',
        photoType: type,
        isFolder: true,
        geotags: { gimbalPitchDegree: 1, gimbalYawDegree: 1, gimbalRollDegree: 1 },
        count: groupPhoto[date].length,
        screenTitle: groupPhoto[date][0].screenTitle,
      });
    } else {
      forEach(groupPhoto[date], photo => {
        dayTimeline[date].splice(0, 0, {
          id: `${type}_${photo.id}`,
          screenId: photo.screenId,
          takenAt: photo.takenAt,
          imageUrl: '',
          thumbUrl: '',
          fullUrl: '',
          boxThumbUrl: '',
          photoType: type,
          isFolder: true,
          geotags: { gimbalPitchDegree: 1, gimbalYawDegree: 1, gimbalRollDegree: 1 },
          count: photo.count,
          screenTitle: photo.screenTitle,
        });
      });
    }
  }
};
export const checkExtension = (url: string) => {
  const extension = url.split('.').pop()?.split('?')[0]?.toLowerCase();

  if (extension === 'jpg' || extension === 'png' || extension === 'gif') {
    return true;
  } else {
    return false;
  }
};

export const formatFullTime = (time: number): string => {
  // Formatting duration of video
  if (isNaN(time)) {
    return '00:00:00';
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds}`;
};

export const formatTime = (time: number): string => {
  // Formatting duration of video
  if (isNaN(time)) {
    return '00:00';
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  if (hours) {
    // If video has hours
    return `${hours}:${minutes.toString().padStart(2, '0')} `;
  } else {
    return `${minutes}:${seconds}`;
  }
};

export const formatMsTime = (time: number): string => {
  // Formatting duration of video
  if (isNaN(time)) {
    return '00:00';
  }

  const date = new Date(time);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  if (hours) {
    // If video has hours
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
};

// check if it is video or photos
export const isVideo = (url: string) => {
  if (!url) {
    return false;
  }
  const extension = url.split('.').pop()?.split('?')[0]?.toLowerCase();

  if (extension === 'mp4' || extension === 'webm') {
    return true;
  } else {
    return false;
  }
};

// count video and photos from photolist array and return both video and photos count
export const countVideoAndPhotos = (photos: T.FinalPhoto[]) => {
  let videoCount = 0;
  let photoCount = 0;

  photos.forEach(photo => {
    if (isVideo(photo.fullUrl)) {
      videoCount += 1;
    } else {
      photoCount += 1;
    }
  });

  return { videoCount, photoCount };
};

// Define type for the nested map structure
type PhotoMapType = Map<string, Map<string, Map<string, Array<T.PhotoAlbum | T.FinalPhoto>>>>;
type AlbumMapType = PhotoMapType;

// Helper function to create a PhotoAlbum from a group of photos
function createPhotoAlbum(
  photos: T.FinalPhoto[],
  albumType: T.PhotoAlbumFilterTypes,
  photoType: T.PhotoType
): T.PhotoAlbum {
  // Use the first photo's date or current date if no photos
  const takenAt = photos.length > 0 && photos[0].takenAt ? new Date(photos[0].takenAt) : null;

  // Use a consistent title generation based on the album type
  let title = '';
  switch (albumType) {
    case 'inspection':
      title = 'Drone Photos';
      break;
    case 'three_sixty':
      title = '360° Photos';
      break;
    case 'three_sixty_source':
      title = '360° Source Photos';
      break;
    case 'three_sixty_stitched':
      title = '360° Stitched Photos';
      break;
    case 'three_sixty_video':
      title = '360° VSLAM Videos';
      break;
    case 'viewpoint':
      title = 'Requested Photos';
      break;
    case 'three_sixty_vslam':
      title = '360° VSLAM Photos';
      break;
    default:
      title = 'Photo Album';
  }

  return {
    id: photos.length > 0 ? photos[0].id : Date.now(), // Generate ID from first photo or use timestamp
    title,
    type: albumType,
    takenAt,
    photosCount: photos.length,
    count: photos.length,
    photoType,
    contentId: null,
  };
}

function createSourcePhotoAlbum(photos: T.FinalPhoto[]): T.PhotoAlbum[] {
  return photos.map(photo => ({
    id: photo.id, // Use the photo's ID for the album ID
    title: photo.screenTitle || 'Source Photo', // Use the screen title or a default title
    type: T.PhotoAlbumFilterTypes.SOURCE,
    takenAt: photo.takenAt ? new Date(photo.takenAt) : null, // Use the photo's takenAt date
    photosCount: photo?.count ?? 0, // Each album contains one photo
    count: photo.count, // Count of photos in this album
    photoType: 'source',
    contentId: photo.screenId || null, // Use the screen ID if available
  }));
}

function createViewPointPhotoAlbum(photos: T.FinalPhoto[]): T.PhotoAlbum[] {
  return photos.map(photo => ({
    id: photo.id, // Use the photo's ID for the album ID
    title: photo.albumTitle || 'Request Photo', // Use the screen title or a default title
    type: T.PhotoAlbumFilterTypes.VIEWPOINT,
    takenAt: photo.takenAt ? new Date(photo.takenAt) : null, // Use the photo's takenAt date
    photosCount: photo?.count ?? 0, // Each album contains one photo
    count: photo.count, // Count of photos in this album
    photoType: 'viewpoint',
    contentId: photo.screenId || null, // Use the screen ID if available
  }));
}

export function newsortPhotosByDate(
  photos: T.FinalPhoto[],
  lang: T.Language
): { photoMap: AlbumMapType; photosByType: Map<string, T.FinalPhoto[]> } {
  const datedPhotos: PhotoList[] = [];
  const undatedPhotos: T.FinalPhoto[] = [];

  // Split the photos into two arrays - dated and undated
  photos.forEach(photo => {
    if (photo.takenAt) {
      datedPhotos.push(photo as PhotoList);
    } else {
      undatedPhotos.push(photo);
    }
  });

  // Sort the dated photos by date in descending order
  datedPhotos.sort((a, b) => {
    if (a.takenAt && b.takenAt) {
      return b.takenAt.getTime() - a.takenAt.getTime();
    }
    return 0;
  });

  // Helper function to create map structure for a date
  const createDateMaps = (date: Date, photoMap: PhotoMapType) => {
    const year = date.getFullYear().toString();
    const monthYear = formatMonthYear(date, lang);
    const dayMonthYear = formatDayMonthYear(date, lang);

    if (!photoMap.has(year)) {
      photoMap.set(year, new Map());
    }

    const yearMap = photoMap.get(year)!;
    if (!yearMap.has(monthYear)) {
      yearMap.set(monthYear, new Map());
    }

    const monthMap = yearMap.get(monthYear)!;
    if (!monthMap.has(dayMonthYear)) {
      monthMap.set(dayMonthYear, []);
    }

    return { year, monthYear, dayMonthYear };
  };

  // Create photoMap to organize photos by year, month, and day
  const photoMap: AlbumMapType = new Map();
  // Filter photos by type
  const photosByType = new Map<string, T.FinalPhoto[]>();

  // Process dated photos by grouping them by day
  // Then for each day, group photos by type into albums
  const dateGroupedPhotos = new Map<string, T.FinalPhoto[]>();

  // Group photos by their day-month-year formatted string
  for (const photo of datedPhotos) {
    const date = new Date(photo.takenAt);
    const dayMonthYear = formatDayMonthYear(date, lang);
    photo.dateString = dayMonthYear;

    if (!dateGroupedPhotos.has(dayMonthYear)) {
      dateGroupedPhotos.set(dayMonthYear, []);
    }

    dateGroupedPhotos.get(dayMonthYear)!.push(photo);
  }

  // Process each day's photos
  for (const [dayMonthYear, dayPhotos] of dateGroupedPhotos.entries()) {
    // Make sure we have at least one photo to determine the date
    if (dayPhotos.length === 0) {
      continue;
    }

    // Get the date from the first photo
    const date = dayPhotos[0].takenAt;

    // Create the year/month/day structure
    const { year, monthYear } = createDateMaps(new Date(date!), photoMap); // Ensure date is a Date object

    // Get the relevant maps
    const yearMap = photoMap.get(year)!;
    const monthMap = yearMap.get(monthYear)!;
    const dayList = monthMap.get(dayMonthYear)!;

    dayPhotos.forEach(photo => {
      if (!photo.photoType) {
        return;
      }
      if (!photosByType.has(photo.photoType)) {
        photosByType.set(photo.photoType, []);
      }
      photosByType.get(photo.photoType)!.push(photo);
    });

    const dronePhotos = dayPhotos.filter(photo => photo.photoType === 'drone');
    const threeSixtyPhotos = dayPhotos.filter(photo => photo.photoType === 'three_sixty');
    const threeSixtySourcePhotos = dayPhotos.filter(
      photo => photo.photoType === 'three_sixty_source'
    );
    const threeSixtyStitchedPhotos = dayPhotos.filter(
      photo => photo.photoType === 'three_sixty_stitched'
    );
    const threeSixtyVslamVideos = dayPhotos.filter(
      photo => photo.photoType === 'three_sixty_video'
    );
    const threeSixtyVslamPhotos = dayPhotos.filter(
      photo => photo.photoType === 'three_sixty_vslam'
    );
    const viewPointPhotos = dayPhotos.filter(photo => photo.photoType === 'viewpoint');
    const sourcePhotos = dayPhotos.filter(photo => photo.photoType === 'source' && photo.screenId);
    const standardPhotos = dayPhotos.filter(
      photo =>
        (photo.uploadSource === 'photo' &&
          photo.photoType !== 'drone' &&
          photo.photoType !== 'source' &&
          photo.photoType !== 'three_sixty' &&
          photo.photoType !== 'three_sixty_source' &&
          photo.photoType !== 'three_sixty_vslam' &&
          photo.photoType !== 'three_sixty_video' &&
          photo.photoType !== 'viewpoint' &&
          photo.photoType !== 'three_sixty_stitched') ||
        photo.type === 'overlay'
    );

    if (viewPointPhotos.length > 0) {
      dayList.push(...createViewPointPhotoAlbum(viewPointPhotos));
    }

    // Create albums for each photo type if there are photos of that type
    if (dronePhotos.length > 0) {
      dayList.push(createPhotoAlbum(dronePhotos, T.PhotoAlbumFilterTypes.INSPECTION, 'drone'));
    }

    if (threeSixtyPhotos.length > 0) {
      dayList.push(
        createPhotoAlbum(threeSixtyPhotos, T.PhotoAlbumFilterTypes.THREE_SIXTY, 'three_sixty')
      );
    }

    if (threeSixtySourcePhotos.length > 0) {
      dayList.push(
        createPhotoAlbum(
          threeSixtySourcePhotos,
          T.PhotoAlbumFilterTypes.THREE_SIXTY_SOURCE,
          'three_sixty_source'
        )
      );
    }

    if (threeSixtyStitchedPhotos.length > 0) {
      dayList.push(
        createPhotoAlbum(
          threeSixtyStitchedPhotos,
          T.PhotoAlbumFilterTypes.THREE_SIXTY_STITCHED,
          'three_sixty_stitched'
        )
      );
    }
    if (threeSixtyVslamPhotos.length > 0) {
      dayList.push(
        createPhotoAlbum(
          threeSixtyVslamPhotos,
          T.PhotoAlbumFilterTypes.THREE_SIXTY_VSLAM,
          'three_sixty_vslam'
        )
      );
    }
    if (threeSixtyVslamVideos.length > 0) {
      dayList.push(
        createPhotoAlbum(
          threeSixtyVslamVideos,
          T.PhotoAlbumFilterTypes.THREE_SIXTY_VIDEO,
          'three_sixty_video'
        )
      );
    }
    if (sourcePhotos.length > 0) {
      dayList.push(...createSourcePhotoAlbum(sourcePhotos));
    }

    // Add standard photos individually (not as an album)
    if (standardPhotos.length > 0) {
      dayList.push(...standardPhotos);
    }
  }

  return { photoMap, photosByType };
}
