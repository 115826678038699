/**
 * Custom React hook that tracks whether a specified element is in the viewport.
 *
 * @template T - The type of the element (typically HTMLElement or a subtype like CustomDivElement)
 * @returns {[React.RefObject<T | null>, boolean]} - A tuple containing a React ref object
 *   and a boolean indicating whether the element is in view.
 */

import { useState, useEffect, useRef, RefObject } from 'react';

export interface CustomDivElement extends HTMLDivElement {
  isSelected: boolean;
  isVisible: boolean;
}

export const useIsInView = <T extends HTMLElement>(isHidden?: boolean): [RefObject<T>, boolean] => {
  const [isInView, setIsInView] = useState<boolean>(false);
  const ref = useRef<T | null>(null);

  useEffect(() => {
    let isMounted = true;

    const observer = new IntersectionObserver(entries => {
      if (isMounted) {
        entries.forEach(entry => {
          setIsInView(entry.isIntersecting);
        });
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      isMounted = false;
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref.current, isHidden]);

  return [ref, isInView];
};
