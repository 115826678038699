import React from 'react';
import styled from 'styled-components';
import HeadlessTippy from '@tippyjs/react/headless';

import dsPalette from '^/constants/ds-palette';
import { UnassignButton } from '^/components/atoms/UnassignButton';
import IssuePersonDetail from '^/components/atoms/IssuePersonDetail';

import * as T from '^/types';
import palette from '^/constants/palette';

const Root = styled.div({
  display: 'inline-block',
  boxSizing: 'border-box',
  height: '21px',

  backgroundColor: '#EBEDF0',
  color: dsPalette.typePrimary.toString(),
  fontSize: '10px',

  padding: '4px',
  border: `1px solid ${dsPalette.themePrimary.toString()}`,
  borderRadius: '10px',

  '&:hover': {
    backgroundColor: '#D5DBE4',
  },
});

const AvatarWrapper = styled.div({
  display: 'inline-block',
  overflow: 'hidden',
  width: '13px',
  height: '13px',

  borderRadius: '50%',
  backgroundColor: '#95A7C2',
});

const ImageWrapper = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const NameWrapper = styled.span({
  verticalAlign: 'text-top',

  marginLeft: '3px',
  marginRight: '3px',
});

const IssuePersonDetailWrapper = styled.div({
  backgroundColor: palette.white.toString(),
  borderRadius: '5px',
  boxShadow: '0px 0px 4px 0px #A2A2A2',
});

interface Props {
  id: number;
  name: string;
  email: string;
  avatar?: string;
  onDelete?(): void;
}

function IssuePerson({ id, name, email, avatar, onDelete }: Props) {
  function handleOnDelete() {
    onDelete?.();
  }

  const avatarArea = avatar ? <ImageWrapper data-testid="profile-img" src={avatar} /> : null;

  return (
    <HeadlessTippy
      theme="angelsw"
      offset={T.TIPPY_OFFSET}
      arrow={false}
      render={() => (
        <IssuePersonDetailWrapper>
          <IssuePersonDetail
            key={`assignee-${id}`}
            id={id}
            name={name}
            email={email}
            avatar={avatar}
          />
        </IssuePersonDetailWrapper>
      )}
      placement="bottom-start"
    >
      <Root>
        <AvatarWrapper>{avatarArea}</AvatarWrapper>
        <NameWrapper>{name}</NameWrapper>
        <UnassignButton onCloseClick={handleOnDelete} />
      </Root>
    </HeadlessTippy>
  );
}

export default IssuePerson;
