import { Language } from '^/types';

export default {
  clear: {
    [Language.KO_KR]: '지우기',
    [Language.EN_US]: 'Clear',
  },
  placeholderText: {
    [Language.KO_KR]: '팀원 검색',
    [Language.EN_US]: 'Search team members',
  },
};
