import React from 'react';
import Logo from '^/assets/logo.svg';
import { FlexBox } from '^/components/atoms/FlexBox';
import LanguageDropdown from '^/components/atoms/Dropdown/LanguageDropdown';

const AuthPageLogoContainer = () => (
  <FlexBox gap="32px" alignItems="center" customStyles={{ padding: '12px', zIndex: '10' }}>
    <Logo />
    <LanguageDropdown />
  </FlexBox>
);

export default AuthPageLogoContainer;
