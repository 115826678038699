import { Epic, combineEpics } from 'redux-observable';
import { REHYDRATE } from 'redux-persist';
import { concat } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { action as makeAction, union } from 'tsdux';
import { ofType } from 'tsdux-observable';

import { getUserInfo } from '../react-query/users';
import { getUserInfoQuery } from '../react-query/users/manual';

import { ChangeLanguage } from './Pages/Common';
import { GetProjects } from './Projects';
import { FinishGetUserInfo } from './Users';

import { getVendorUser } from './vendor';
import { insertUserToSentry } from './vendor/sentry';
import { queryClient } from '^/store/react-query';
import * as T from '^/types';

const isDefined: <A>(a: A | null | undefined) => a is A = <A>(
  a: A | null | undefined
  // eslint-disable-next-line eqeqeq
): a is A => a != undefined;

const FinishPersistEmbeddedTools = makeAction('ddm/persist/FINISH_PERSIST_EMBEDDED_TOOLS');

// Redux actions
const Action = union([GetProjects, ChangeLanguage, FinishPersistEmbeddedTools]);
export type Action = { type: typeof REHYDRATE; [k: string]: any } | typeof Action;

const persistLanguageEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.ofType(REHYDRATE).pipe(
    map(() => state$.value.Auth.authedUser),
    filter(isDefined),
    tap(async ({ id }) => {
      await getUserInfoQuery(queryClient, id);
    }),
    mergeMap(({ id }) =>
      concat(
        action$.pipe(
          ofType(FinishGetUserInfo),
          map(() => getUserInfo(id)),
          map(res => res?.language),
          filter(language => language !== undefined),
          map(language => ({ language })),
          map(ChangeLanguage)
        )
      )
    )
  );

const persistEmbeddedToolsEpic: Epic<Action, Action, T.State> = (action$, state$) =>
  action$.ofType(REHYDRATE).pipe(() =>
    action$.pipe(
      ofType(FinishGetUserInfo),
      map(() => getVendorUser(state$.value)),
      map(vendorUser => {
        if (vendorUser) {
          insertUserToSentry(vendorUser);
        }
      }),
      map(FinishPersistEmbeddedTools)
    )
  );

export const epic: Epic<Action, Action> = combineEpics(
  persistLanguageEpic,
  persistEmbeddedToolsEpic
);
