/**
 * @author Reuben Thapa
 * @desc Tue March 19 07:20:25 2023 UTC
 */
import React, { useRef } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import JSZip from 'jszip';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';

import * as T from '^/types';
import Text from '^/components/molecules/PhotoList/text';
import { UseL10n, useL10n } from '^/hooks';
import GroupSvg from '^/assets/icons/photo/folder.svg';
import CheckSVG from '^/assets/icons/photo/check-mark.svg';
import palette from '^/constants/palette';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { SetDownloadPhotoState } from '^/store/duck/Photos';
import { useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import axios from 'axios';
import { DownloadProgressDisplay } from '^/components/atoms/DownloadProgressPercent';
import { useSourcePhotoQuery } from '^/hooks/api/photos/usePhoto';

const TOAST_ZIP_ID = 1;

export const ToastWrapper = styled.div({
  display: 'flex',
  background: palette.white.alpha(0.9).toString(),
  borderRadius: '5px',
  height: '68px',
  alignItems: 'center',
  gap: '20px',
});

export const Container = styled.div<{ size: number }>(({ size }) => ({
  position: 'relative',
  width: `${size}px`,
  height: `${size}px`,
}));

export const Circle = styled.svg({
  transform: 'rotate(-90deg)',
});

export const ProgressCircle = styled.circle<{
  circumference: number;
  strokeWidth: number;
  color: string;
  offset: number;
}>(({ circumference, strokeWidth, color, offset }) => ({
  fill: 'none',
  stroke: color,
  strokeWidth: `${strokeWidth}px`,
  strokeDasharray: `${circumference} ${circumference}`,
  strokeDashoffset: offset,
  transition: 'stroke-dashoffset 0.3s ease',
}));

export const PercentageText = styled.span<{ fontSize: number; color: string }>(
  ({ fontSize, color }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: `${fontSize}px`,
    color: color,
  })
);

export const ToastContent = styled.p({
  color: '#4D4C4C',
  fontSize: '13px',
  fontWeight: 700,
});

export const defaultToasterOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: false,
  draggable: false,
};

export const useDownloadImages = (rawPhotosAndVideos: T.FinalPhoto[]) => {
  const dispatch: Dispatch = useDispatch();
  const { takenAt, contentType, id: projectId } = useParams();
  const toastIdRef = useRef<string | number | null>(null);
  const [l10n]: UseL10n = useL10n();
  const { pathname } = useLocation();
  const isTimelapseMode = pathname.endsWith(`/${T.PhotoPathType.LOCATION}`);
  const isSourcePhoto = contentType === T.PhotoAlbumType.SOURCE;

  const filteredPhotos = rawPhotosAndVideos.filter(
    photo =>
      photo.photoType === contentType && photo.takenAt?.toISOString().split('T')[0] === takenAt
  );

  const { photos: sourcePhotos } = useSourcePhotoQuery(
    Number(projectId!),
    filteredPhotos.length ? filteredPhotos[0]?.screenId : undefined,
    isSourcePhoto
  );
  const photos = isSourcePhoto ? sourcePhotos : rawPhotosAndVideos;

  const { timelapseInspectionImages } = useTimelapseStore(s => ({
    timelapseInspectionImages: s.timelapseInspectionImages,
  }));

  const ZippedDisplay = () => (
    <ToastWrapper>
      <GroupSvg />
      <ToastContent>{l10n(Text.photo.zippedTooltip)}</ToastContent>
    </ToastWrapper>
  );

  const DownloadCompleteDisplay = () => (
    <ToastWrapper>
      <CheckSVG />
      <ToastContent>{l10n(Text.photo.downloadCompleteMsg)}</ToastContent>
    </ToastWrapper>
  );

  const handleDownloadImages = async (selectedImageIds: T.SelectedMedia[]) => {
    dispatch(SetDownloadPhotoState({ isDownloading: true }));
    let selectedPhotos: T.FinalPhoto[] = [];

    if (isTimelapseMode) {
      selectedPhotos = timelapseInspectionImages.filter(pht =>
        selectedImageIds.some(id => id.contentId === pht.id && id.type === pht.photoType)
      );
    } else {
      selectedPhotos = photos.filter(pht =>
        selectedImageIds.some(id => id.contentId === pht.id && id.type === pht.photoType)
      );
    }

    if (selectedPhotos.length === 1) {
      const pht = selectedPhotos[0];
      const response = await axios.get(pht.imageUrl ? pht.imageUrl : pht.videoUrl, {
        withCredentials: true,
        responseType: 'blob',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      link.download = pht.originalFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.dismiss();
      toast(DownloadCompleteDisplay, { ...defaultToasterOptions, autoClose: 5000 });
      dispatch(SetDownloadPhotoState({ isDownloading: false }));
      return;
    }

    toast(ZippedDisplay, { ...defaultToasterOptions, toastId: TOAST_ZIP_ID });

    // create an array of Promises that resolve with the image blobs
    const filePromises = selectedPhotos.map(async (pht: T.FinalPhoto) => {
      const response = await axios.get(pht.imageUrl ? pht.imageUrl : pht.videoUrl, {
        withCredentials: true,
        responseType: 'blob',
      });
      return response.data;
    });

    try {
      // wait for all imagePromises to resolve
      const blobs = await Promise.all(filePromises);

      const zip = new JSZip();

      // add each image blob to the zip archive
      blobs.forEach((blob, index) => {
        const extension = selectedPhotos[index].originalFilename.split('.').pop();
        if (selectedPhotos[index].imageUrl) {
          zip.file(`image-${selectedPhotos[index].id}.${extension}`, blob);
        } else {
          zip.file(`video-${selectedPhotos[index].id}.${extension}`, blob);
        }
      });

      const content = await zip.generateAsync(
        { type: 'blob' },
        (metadata: { percent?: number }) => {
          const percent = Math.round(metadata.percent || 0);
          if (toastIdRef.current === null) {
            toast.dismiss();
            toastIdRef.current = toast(
              <DownloadProgressDisplay progress={percent} />,
              defaultToasterOptions
            );
          } else {
            toast.update(toastIdRef.current, {
              render: <DownloadProgressDisplay progress={percent} />,
              ...defaultToasterOptions,
            });
          }
        }
      );

      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = 'selected-files.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.dismiss();

      toast(DownloadCompleteDisplay, { ...defaultToasterOptions, autoClose: 5000 });
      dispatch(SetDownloadPhotoState({ isDownloading: false }));
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    handleDownloadImages,
  };
};
