import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as T from '^/types';
import {
  DimensionType,
  getCoordinatesIssueRectangle,
} from '^/utilities/getCoordinatesIssueRectangle';
import useIssueContents from '^/hooks/useIssueContents';

import { IssuePhotoMarker } from './IssuePhotoMarker';

const Root = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
});

const Img = styled.img<{ readonly isIssueEnabled?: boolean }>(({ isIssueEnabled }) => ({
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  userSelect: 'none',
  cursor: isIssueEnabled ? 'crosshair' : 'default',
}));

interface IssueBoundaryProps {
  top?: number;
  left?: number;
  width: number;
  height: number;
  isIssueEnabled?: boolean;
}
interface IssueBoundaryWrapperProps {
  width: number;
  height: number;
  isIssueEnabled?: boolean;
}

const IssueBoundaryWrapper = styled.div<IssueBoundaryWrapperProps>`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  cursor: ${props => (props.isIssueEnabled ? 'crosshair' : 'default')};
  pointer-events: ${props => (!props.isIssueEnabled ? 'none' : 'auto')};
`;

const IssueBoundary = styled.div<IssueBoundaryProps>`
  cursor: pointer;
  position: absolute;
  border: 2px solid red;
  box-sizing: border-box;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  cursor: ${props => (props.isIssueEnabled ? 'crosshair' : 'default')};
`;

interface Props {
  src: string;
  isIssueEnabled?: boolean;
  onIssueCreate?(imagePoint: T.Point): void;
  issuePhotoContents?: T.IssuePhotoContent[];
  onWheel?: React.WheelEventHandler<HTMLImageElement>;
}

export function PhotoViewerItemWithIssue({
  src,
  onWheel,
  onIssueCreate,
  isIssueEnabled,
  issuePhotoContents,
}: Readonly<Props>) {
  const imgRef = useRef<HTMLImageElement>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);
  const [drawing, setDrawing] = useState<boolean>(false);
  const [coords, setCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState<DimensionType>({
    width: { value: 0, sign: 0 },
    height: { value: 0, sign: 0 },
    top: 0,
    left: 0,
  });
  const [isImgLoaded, setIsImgLoaded] = useState<boolean>(false);
  const [editingIssue, setEditingIssue] = useState<T.IssuePhotoContent | null>(null);

  const { patchIssueContent } = useIssueContents();

  function updateRect() {
    if (imgRef.current) {
      setRect(imgRef.current.getBoundingClientRect());
    }
  }

  useEffect(() => {
    updateRect();

    window.addEventListener('resize', updateRect);
    return () => window.removeEventListener('resize', updateRect);
  }, []);

  useEffect(() => {
    updateRect();
  }, [src, isImgLoaded]);

  function onMouseDown(event: React.MouseEvent<HTMLImageElement>) {
    if (event.target !== event.currentTarget && !drawing) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    setDrawing(true);
    if (isIssueEnabled && rect) {
      setCoords({
        y: event.clientY - rect.top,
        x: event.clientX - rect.left,
      });
    }

    if (drawing && rect) {
      onIssueCreate?.(getCoordinatesIssueRectangle({ rect, coords, dimensions }));
      setDrawing(false);
      setDimensions({
        width: { value: 0, sign: 0 },
        height: { value: 0, sign: 0 },
        top: 0,
        left: 0,
      });
    }
  }
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (rect && drawing) {
      const diffX = e.clientX - rect.left - coords.x;
      const diffY = e.clientY - rect.top - coords.y;

      // calculations for width, height, left and top
      const left = diffX >= 0 ? coords.x : coords.x + diffX;
      const top = diffY >= 0 ? coords.y : coords.y + diffY;

      setDimensions({
        height: { value: Math.abs(diffY), sign: Math.sign(diffY) },
        width: { value: Math.abs(diffX), sign: Math.sign(diffX) },
        top,
        left,
      });
    }
  };

  function handleIssueClick(contentId: T.IssuePhotoContent['id']) {
    setEditingIssue(issuePhotoContents?.find(content => content.id === contentId) ?? null);
  }

  function handleIssueRelocate(contentId: T.IssuePhotoContent['id'], newPoint: T.Point) {
    patchIssueContent({
      content: {
        id: contentId,
        info: {
          imagePoint: newPoint,
        },
      },
      isUndoable: true,
    });
  }

  function handleBlur() {
    setEditingIssue(null);
  }

  useEffect(() => {
    if (!isIssueEnabled) {
      setDrawing(false);
      setDimensions({
        width: { value: 0, sign: 0 },
        height: { value: 0, sign: 0 },
        top: 0,
        left: 0,
      });
      setCoords({ x: 0, y: 0 });
    }
  }, [isIssueEnabled]);

  return (
    <Root>
      <Img
        src={src}
        ref={imgRef}
        draggable={false}
        onWheel={onWheel}
        onLoad={() => setIsImgLoaded(true)}
      />
      <IssueBoundaryWrapper
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        isIssueEnabled={isIssueEnabled}
        width={rect?.width as number}
        height={rect?.height as number}
      >
        {drawing && isIssueEnabled && (
          <IssueBoundary
            top={dimensions.top}
            left={dimensions.left}
            isIssueEnabled={isIssueEnabled}
            width={dimensions.width.value}
            height={dimensions.height.value}
          />
        )}
        {isImgLoaded &&
          issuePhotoContents?.map(content => (
            <IssuePhotoMarker
              rect={rect}
              key={content.id}
              content={content}
              onBlur={handleBlur}
              isIssueEnabled={isIssueEnabled}
              onIssueClick={handleIssueClick}
              onIssueRelocate={handleIssueRelocate}
              isEditing={editingIssue?.id === content.id}
            />
          ))}
      </IssueBoundaryWrapper>
    </Root>
  );
}
