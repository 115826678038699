import _ from 'lodash-es';
import React, { FC, useState, useEffect } from 'react';
import styled, { CSSObject } from 'styled-components';

import Text from './text';
import Dropdown, { Option as DropdownOption } from '^/components/atoms/Dropdown';
import { TutorialPosition } from '^/components/atoms/TutorialWrapperHoverable';
import withL10n, { L10nProps } from '^/components/atoms/WithL10n';
import palette from '^/constants/palette';
import { usePrevProps } from '^/hooks';
import * as T from '^/types';
import { l10n } from '^/utilities/l10n';

const TUTORIAL_WIDTH: number = 226;
const TUTORIAL_MARGIN: number = 5;

const Root = styled.div({
  display: 'block',
});
Root.displayName = 'TerrainEditingDropdown';

const dropdownMainButtonStyle: CSSObject = {
  color: palette.textGray.toString(),

  borderColor: palette.textGray.toString(),
};

const terrainMethods: T.TerrainEditingMethod[] = [
  T.TerrainEditingMethod.PLANE_FITTING,
  T.TerrainEditingMethod.TRIANGULATION,
];
export const terrainMethodLabel: (
  terrainMethod: T.TerrainEditingMethod,
  language: T.Language
) => string = (terrainMethod, language) => {
  switch (terrainMethod) {
    case T.TerrainEditingMethod.PLANE_FITTING:
      return l10n(Text.itemTitle.planeFitting, language);
    case T.TerrainEditingMethod.TRIANGULATION:
      return l10n(Text.itemTitle.triangulation, language);
    default:
      return 'undefined';
  }
};

const getTerrainEditingTutorialProps: (
  terrainEditingMethod: T.TerrainEditingMethod,
  language: T.Language
) => NonNullable<DropdownOption['tutorial']> = (terrainEditingMethod, language) => ({
  width: TUTORIAL_WIDTH,
  margin: TUTORIAL_MARGIN,
  position: TutorialPosition.RIGHT_BOTTOM,
  title: l10n(Text.tutorial[terrainEditingMethod].title, language),
  description: l10n(Text.tutorial[terrainEditingMethod].description, language),
  image: <></>,
  //  <VolumeCalcTutorialImage type={terrainEditingMethod} />,
  // link: l10n(routes.externalLink.volumeCalculation, language),
  isZendesk: false,
  customStyle: {
    root: { width: '100%' },
    target: { width: '100%' },
  },
});

export interface State {
  readonly terrainEditingMethod: T.TerrainEditingMethod;
}

export interface Props {
  content: T.FlattenContent;
  onSelect(volumeAlgorithm: T.TerrainEditingMethod): void;
}

/**
 * Component for selecting Method for Terrain Editing
 */
const VolumeAlgorithmDropdown: FC<Props & L10nProps> = ({ content, language, onSelect }) => {
  const [dropdown, setDropdown] = useState<State>({
    terrainEditingMethod:
      content.config?.terrianEditingMethod ?? T.TerrainEditingMethod.PLANE_FITTING,
  });

  const prevContent = usePrevProps<T.FlattenContent>(content);

  const handleDropdownClick: (option: DropdownOption) => void = option => {
    const terrainEditingMethod: T.TerrainEditingMethod = option.value as T.TerrainEditingMethod;
    setDropdown({ ...dropdown, terrainEditingMethod });
    onSelect(terrainEditingMethod);
  };

  useEffect(() => {
    if (prevContent) {
      const prevTerrainEditing =
        prevContent.config?.terrianEditingMethod ?? T.TerrainEditingMethod.PLANE_FITTING;
      const currTerrainEditing =
        content.config?.terrianEditingMethod ?? T.TerrainEditingMethod.PLANE_FITTING;
      if (!_.isEqual(prevTerrainEditing, currTerrainEditing)) {
        setDropdown({
          terrainEditingMethod: currTerrainEditing,
        });
      }
    }
  });

  const options: DropdownOption[] = terrainMethods.map(terrainMethod => ({
    leftText: terrainMethodLabel(terrainMethod, language),
    value: terrainMethod,
    tutorial: getTerrainEditingTutorialProps(terrainMethod, language),
  }));

  return (
    <Root>
      <Dropdown
        mainButtonStyle={dropdownMainButtonStyle}
        value={dropdown.terrainEditingMethod}
        placeHolder={l10n(Text.placeholder, language)}
        options={options}
        zIndex={1}
        onClick={handleDropdownClick}
        height={'112px'}
        menuItemHeight={'34px'}
        itemStyle={{ justifyContent: 'left' }}
      />
    </Root>
  );
};

export default withL10n(VolumeAlgorithmDropdown);
