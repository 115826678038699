/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-lines */
import {
  Color,
  ConeGeometry,
  EdgesGeometry,
  Euler,
  Group,
  LineBasicMaterial,
  LineSegments,
  Material,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  PlaneGeometry,
  Quaternion,
  Sprite,
  SpriteMaterial,
  SRGBColorSpace,
  TextureLoader,
  Vector3,
  VideoTexture,
} from 'three';
import { Viewer } from '../../ThreeInteraction/Viewer';
import { Runtime } from 'three-loader-3dtiles';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import { DisposeHelper } from '../../Lib/Helper';
import { LabelObject } from '../Label';
import { autobind } from 'core-decorators';
import { setupPictureFromCamera } from '../../Lib/Utils//TilesetUtils';
import { closeIcon, pauseIcon, playIcon } from '^/assets/icons/imageBase64';
import { ThreeCameraHelper, ThreeCameraHelper2 } from '../Camera';
import * as T from '^/types';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';

export interface GeoData {
  longitude: number;
  latitude: number;
  altitude: number;
  gimbalYawDegree: number;
  gimbalPitchDegree: number;
  gimbalRollDegree: number;
  width: number;
  height: number;
  focalLength: number;
  relativeAltitude: number;
  calibratedFocalLength: number;
  absoluteAltitude: number;
}
interface ProcessData {
  main: Mesh;
  border: Mesh;
  position: Vector3;
  rotation: Euler;
  camera: PerspectiveCamera;
}
export enum TYPE {
  PROGRESS = 'progress',
  ICON = 'icon',
  HIGHTLIGHT_ICON = 'highlight_icon',
}

const CONE_GEOMETRY = new ConeGeometry(1, 2.0, 32);
const CONE_GEOMETRY_LARGE = new ConeGeometry(2, 3, 32);
const MATERIAL_VIDEO_FRAME = new LineBasicMaterial({ color: '#D9D9D9' });
const ARROW_MATERIAL = new MeshBasicMaterial({ color: 'white', transparent: true, opacity: 0.8 });
const ARROW_LARGE_MATERIAL = new MeshBasicMaterial({
  color: 0x4bb7fe,
  transparent: true,
  opacity: 0.8,
});
const ARROW = new Mesh(CONE_GEOMETRY, ARROW_MATERIAL);
const ARROW_LARGE = new Mesh(CONE_GEOMETRY_LARGE, ARROW_LARGE_MATERIAL);

const HIGHLIGHT_COLOR = new Color();
const TEXTURE_LOADER = new TextureLoader();

TEXTURE_LOADER.setCrossOrigin('use-credentials');
const SPRITE_MATERIAL = new SpriteMaterial({
  // sizeAttenuation: false,
  map: TEXTURE_LOADER.setCrossOrigin('anonymous').load(closeIcon),
  depthTest: false,
});

const SPRITE_MATERIAL_PLAY = new SpriteMaterial({
  // sizeAttenuation: false,
  map: TEXTURE_LOADER.setCrossOrigin('anonymous').load(playIcon),
  depthTest: false,
});

const SPRITE_MATERIAL_PAUSE = new SpriteMaterial({
  // sizeAttenuation: false,
  map: TEXTURE_LOADER.setCrossOrigin('anonymous').load(pauseIcon),
  depthTest: false,
});

const DRONE_CAMERA = new PerspectiveCamera(45, 1.5, 5, 100);

const FLIGHT_PATH_MATERIAL = new LineMaterial({
  color: 0x4bb7fe,
  linewidth: 5, // in pixels
  vertexColors: false,
  dashed: false,
  alphaToCoverage: true,
  transparent: true,
  opacity: 0.7,
});
const _quaternion = new Quaternion();
const _direction = new Vector3();
const _up = new Vector3(0, 1, 0);
async function readCSV(url: string): Promise<string[][]> {
  const response = await fetch(url, {
    credentials: 'include',
  });
  const text = await response.text();
  const lines = text.split('\n');

  return lines
    .slice(1)
    .map(line => line.split(','))
    .filter(row => row[2] !== undefined && row[2] !== '');
}

export class VideoAlbumObject extends Object3D {
  public calcDroneCamera: PerspectiveCamera | undefined;
  public tempCamera: OrthographicCamera | null = null;
  public droneHelper: ThreeCameraHelper | ThreeCameraHelper2;

  public photoPlane: Mesh | null = null;
  public group: Group;
  public label: LabelObject | null = null;

  public readonly contentId: string | number;
  private readonly tilesRuntime: Runtime;
  private readonly videoUrl: string;
  private readonly viewer: Viewer;
  private readonly cameraHelperMaterialColor: number;
  public scaleMainPlane: number[] = [1, 1];
  private scaleBorderPlane: number[] = [1, 1];
  public spriteIcon: Sprite | undefined;
  public spriteIconPlay: Sprite | undefined;
  public spriteIconPause: Sprite | undefined;
  public readonly contentType: T.PhotoType | undefined;
  public video: HTMLVideoElement | undefined;
  public csvData: string[][] = [];
  public csvUrl: string;
  public videoTexture: VideoTexture | undefined;
  public focalLength: number = 0;
  public flightPath: Group = new Group();
  public isFirstPersonView: boolean = true;
  public status: T.ContentProcessingStatus = T.ContentProcessingStatus.PROCESSING;
  private readonly resolutionMap: Record<number, string> = {};
  /** 
  This space used for calculate camera
  */
  private readonly localSpace: Object3D | undefined;
  public videoFrame: LineSegments | undefined;
  public baseSize: number = 0.75;
  public aspect = 1;
  public constructor(
    contentId: string | number,
    viewer: Viewer,
    videoUrl: string,
    csvUrl: string,
    geoData: GeoData | null,
    tilesRuntime: Runtime,
    contentType: T.PhotoType,
    resolutionMap: Record<number, string>
  ) {
    super();
    this.name = `video_${contentId.toString()}`;
    this.contentId = contentId;
    this.viewer = viewer;
    this.videoUrl = videoUrl;
    this.csvUrl = csvUrl;
    this.contentType = contentType;
    this.resolutionMap = resolutionMap;
    this.group = new Group();
    this.group.visible = false;
    this.renderOrder = 10;
    this.droneHelper = new ThreeCameraHelper2(DRONE_CAMERA, viewer);
    this.tilesRuntime = tilesRuntime;
    this.add(this.droneHelper);
    if (geoData) {
      this.initPlane(geoData, geoData.altitude);
    }

    this.cameraHelperMaterialColor = 0xffa500;
    //@ts-ignore
    this.droneHelper.material.color.set(0xffa500);
    this.droneHelper.visible = false;
  }
  /**
   * @private
   * @description Funtion init position for photo album
   * @param {GeoData} geoData
   * @returns {void}
   */
  @autobind
  public async initData(): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      const data = await readCSV(this.csvUrl);
      this.csvData = data;
      this.createFlightPath();
      resolve(true);
    });
  }
  public initPlane(geoData: GeoData, altitude: number) {
    this.focalLength = geoData.focalLength;
    const { main, border, position, rotation, camera } = this.processGeoData(geoData, altitude);
    this.position.copy(position);
    this.rotation.copy(rotation);
    if (this.group.children.length === 0) {
      main.renderOrder = 8;
      border.renderOrder = 8;
      (main.material as Material).depthTest = false;
      (border.material as Material).depthTest = false;
      this.photoPlane = main;
      this.scaleMainPlane = [main.scale.x, main.scale.y];
      this.scaleBorderPlane = [border.scale.x, border.scale.y];

      this.calcDroneCamera = camera;
      this.group.add(main, border);
      (main.material as MeshBasicMaterial).map = this.videoTexture!;
      this.droneHelper.add(this.group);
      this.group.visible = false;
      border.visible = false;

      this.spriteIcon = new Sprite(SPRITE_MATERIAL);
      this.spriteIcon.renderOrder = 9;
      this.spriteIcon.visible = false;
      this.spriteIcon.name = 'close';
      this.add(this.spriteIcon);

      this.spriteIconPlay = new Sprite(SPRITE_MATERIAL_PLAY);
      this.spriteIconPlay.renderOrder = 9;
      this.spriteIconPlay.visible = false;
      this.spriteIconPlay.name = 'play';
      this.add(this.spriteIconPlay);

      this.spriteIconPause = new Sprite(SPRITE_MATERIAL_PAUSE);
      this.spriteIconPause.renderOrder = 9;
      this.spriteIconPause.visible = false;
      this.spriteIconPause.name = 'pause';
      this.add(this.spriteIconPause);
    }
    if (this.videoTexture && !(main.material as MeshBasicMaterial).map) {
      (main.material as MeshBasicMaterial).map = this.videoTexture;
    }

    const geometry = new PlaneGeometry(main.scale.x, main.scale.y);
    const edges = new EdgesGeometry(geometry, 1);
    this.videoFrame = new LineSegments(edges, MATERIAL_VIDEO_FRAME);
    this.videoFrame.position.copy(main.position);
    this.videoFrame.raycast = () => false;
    this.videoFrame.renderOrder = 12;
    this.videoFrame.visible = false;
    this.add(this.videoFrame);
    geometry.dispose();
  }
  public updatePlane(geoData: GeoData, altitude: number) {
    const { position, rotation } = this.processGeoData(geoData, altitude);
    this.position.copy(position);
    this.rotation.copy(rotation);
  }
  /**
   * @public
   * @description process geo data
   * @param {GeoData} geoData
   * @returns {void}
   */
  @autobind
  public processGeoData(geoData: GeoData, altitude: number): ProcessData {
    const position = this.tilesRuntime.getPositionFromLatLongHeight({
      long: geoData.longitude,
      lat: geoData.latitude,
      height: altitude,
    });

    const headingRad = degToRad(-geoData.gimbalYawDegree);
    const pitchRad = degToRad(geoData.gimbalPitchDegree);
    const rollRad = degToRad(geoData.gimbalRollDegree);
    const rotation = new Euler();
    rotation.set(pitchRad + degToRad(90), rollRad, headingRad, 'ZYX');
    const focalLength = geoData.height * 1.3;

    const fov = radToDeg(2 * Math.atan(geoData.height / 2 / focalLength));
    const camera =
      this.calcDroneCamera ?? new PerspectiveCamera(fov, geoData.width / geoData.height, 1, 10 * 2);
    camera.up.set(0, 0, 1);
    camera.updateProjectionMatrix();
    camera.rotation.set(pitchRad + degToRad(90), rollRad, headingRad, 'ZYX');
    const { main, border }: any = setupPictureFromCamera(
      camera,
      10,
      this.group.children.length > 0
        ? { main: this.group.children[0] as Mesh, border: this.group.children[1] as Mesh }
        : undefined
    );
    return { main, border, position, rotation, camera };
  }

  /**
   * @public
   * @description set photo texture for the image plane
   * @returns {void}
   */
  @autobind
  public async setTexture(): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.video) {
        this.video = document.createElement('video');

        if (this.resolutionMap[1080]) {
          const source = document.createElement('source');
          source.src = this.resolutionMap[1080];
          this.video.appendChild(source);
        }
        if (this.resolutionMap[720]) {
          const source = document.createElement('source');
          source.src = this.resolutionMap[720];
          this.video.appendChild(source);
          this.changeResolution(720);
        }

        // this.video.src = this.videoUrl;

        this.video.crossOrigin = 'use-credentials';
        this.videoTexture = new VideoTexture(this.video);
        this.videoTexture.colorSpace = SRGBColorSpace;

        this.video.addEventListener('loadedmetadata', () => {
          resolve(true);
        });

        this.video.addEventListener('error', () => {
          resolve(false);
        });

        // Optional: Add a timeout in case the video takes too long to load
        const timeout = setTimeout(() => {
          resolve(false);
        }, 10000); // 10 seconds timeout

        this.video.addEventListener('loadedmetadata', () => clearTimeout(timeout));
      } else {
        resolve(true);
      }
    });
  }

  @autobind
  public changeResolution(targetResolution: number) {
    if (!this.video) {
      return;
    }
    const currentTime = this.video.currentTime;
    const wasPaused = this.video.paused;

    // const sources = this.video.getElementsByTagName('source');
    const newSource = this.resolutionMap[targetResolution];

    if (newSource) {
      this.video.src = newSource;
      this.video.load();
      this.video.currentTime = currentTime;

      if (!wasPaused) {
        void this.video.play();
      }
    }
  }

  /**
   * @public
   * @description creat close icon for photo plane
   * @returns {void}
   */
  @autobind
  public createCloseIcon(): void {
    if (!this.photoPlane || !this.spriteIcon) {
      return;
    }
    const videoAspectRatio = this.photoPlane.scale.x / this.photoPlane.scale.y;
    const iconSize = Math.min(this.photoPlane.scale.x, this.photoPlane.scale.y) * 0.15; // 10% of the smaller dimension

    this.spriteIcon.position.x = this.photoPlane.scale.x / 2;
    this.spriteIcon.position.y = this.photoPlane.scale.y / 2;
    this.spriteIcon.position.z = this.photoPlane.position.z + 0.1;

    if (videoAspectRatio > 1) {
      // Landscape video
      this.spriteIcon.scale.set(iconSize / videoAspectRatio, iconSize / videoAspectRatio, 1);
    } else {
      // Portrait or square video
      this.spriteIcon.scale.set(iconSize, iconSize, 1);
    }

    this.group.add(this.spriteIcon);
    this.spriteIcon.visible = true;
  }
  public createVideoControl() {
    if (!this.photoPlane || !this.spriteIconPlay || !this.spriteIconPause) {
      return;
    }
    const videoAspectRatio = this.photoPlane.scale.x / this.photoPlane.scale.y;
    const iconSize = Math.min(this.photoPlane.scale.x, this.photoPlane.scale.y) * 0.15; // 10% of the smaller dimension

    this.spriteIconPlay.position.z = this.photoPlane.position.z + 0.1;
    this.spriteIconPause.position.z = this.photoPlane.position.z + 0.1;
    if (videoAspectRatio > 1) {
      // Landscape video
      this.spriteIconPlay.scale.set(iconSize / videoAspectRatio, iconSize / videoAspectRatio, 1);
      this.spriteIconPause.scale.set(iconSize / videoAspectRatio, iconSize / videoAspectRatio, 1);
    } else {
      // Portrait or square video
      this.spriteIconPlay.scale.set(iconSize, iconSize, 1);
      this.spriteIconPause.scale.set(iconSize, iconSize, 1);
    }

    this.group.add(this.spriteIconPlay, this.spriteIconPause);
    this.spriteIconPlay.visible = false;
    this.spriteIconPause.visible = false;
  }
  /**
   * @public
   * @description toggle plane image
   * @param {number} color
   * @returns {void}
   */
  @autobind
  public togglePlaneImage(visible: boolean): void {
    //@ts-ignore
    this.droneHelper.isLine =
      this.viewer.sidebarTab !== T.ContentPageTabType.PHOTO ? false : !visible;
    //@ts-ignore
    this.droneHelper.isLineSegments =
      this.viewer.sidebarTab !== T.ContentPageTabType.PHOTO ? false : !visible;
    // this.droneHelper!.visible =
    //   this.viewer.sidebarTab !== T.ContentPageTabType.PHOTO ? false : !visible;
    this.droneHelper.visible = true;
    this.group.visible = visible;
    this.flightPath.visible = visible;
    const photoMain = this.group.children[0] as Mesh;
    const photoBorder = this.group.children[1] as Mesh;
    (photoMain.material as MeshBasicMaterial).opacity = 1;
    (photoBorder.material as MeshBasicMaterial).opacity = 1;
    if (!visible) {
      this.isFirstPersonView = true;
      this.spriteIcon!.visible = false;
      photoMain.scale.set(this.scaleMainPlane[0], this.scaleMainPlane[1], 1);
      photoBorder.scale.set(this.scaleBorderPlane[0], this.scaleBorderPlane[1], 1);
      this.add(this.spriteIcon!);
      const transformData =
        this.viewer.controllers.photoAlbumController?.convertCSVToTransformData(0);
      if (transformData) {
        this.updatePlane(transformData, transformData.altitude);
        if (this.video) {
          this.video.currentTime = 0;
        }
      }
      this.videoFrame!.visible = false;
    }
  }
  /**
   * @public
   * @description set un highlight play icon
   */
  @autobind
  public setUnHighlightPlayIcon(): void {
    //@ts-ignore
    if (this.spriteIconPause!.visible) {
      this.spriteIconPause!.visible = false;
    }
    if (this.spriteIconPlay!.visible) {
      this.spriteIconPlay!.visible = false;
    }
    this.spriteIconPlay!.material.color.set(0xffffff);
    this.spriteIconPause!.material.color.set(0xffffff);
  }
  /**
   * @public
   * @description set highlight play icon
   * @param {number} color
   */
  @autobind
  public setHighlightPlayIcon(color: number = 0xefeff3): void {
    //@ts-ignore
    if (this.video?.paused) {
      this.spriteIconPlay!.visible = true;
      this.spriteIconPause!.visible = false;
    } else {
      this.spriteIconPlay!.visible = false;
      this.spriteIconPause!.visible = true;
    }
    this.spriteIconPlay!.material.color.set(color);
    this.spriteIconPause!.material.color.set(color);
  }
  /**
   * @public
   * @description set highlight close icon
   * @param {number} color
   */
  @autobind
  public setHighlightCloseIcon(color: number = 0xefeff3): void {
    //@ts-ignore
    this.spriteIcon?.material.color.set(color);
  }
  /**
   * @public
   * @description set un highlight camera helper
   */
  @autobind
  public setUnHighlightCloseIcon(): void {
    //@ts-ignore
    this.spriteIcon?.material.color.set(0xffffff);
  }
  /**
   * @public
   * @description set visivibe
   */
  @autobind
  public setVisible(visible: boolean): void {
    this.visible = visible;
    this.droneHelper.visible = visible;
  }
  /**
   * @public
   * @description set highlight camera helper
   * @param {number} color
   * @returns {void}
   */
  @autobind
  public setHighlight(color: number = 0xff0000): void {
    HIGHLIGHT_COLOR.set(color);
    //@ts-ignore
    this.droneHelper.material.color.set(HIGHLIGHT_COLOR);
  }
  /**
   * @public
   * @description set un highlight camera helper
   */
  @autobind
  public setUnHighlight(): void {
    HIGHLIGHT_COLOR.set(this.cameraHelperMaterialColor);
    //@ts-ignore
    this.droneHelper.material.color.set(HIGHLIGHT_COLOR);
  }
  /**
   * @public
   * @description This function will auto-call when the scene render
   * @param {boolean | undefined} force
   */
  @autobind
  public updateMatrixWorld(force?: boolean | undefined) {
    super.updateMatrixWorld(force);
    // if (this.viewer.frustum.containsPoint(this.position)) {
    //   this.visible = true;
    // } else {
    //   this.visible = false;
    // }
  }
  public async play() {
    if (this.video) {
      await this.video.play();
    }
  }
  public pause() {
    if (this.video) {
      this.video.pause();
    }
  }
  /**
   * @public
   * @description dispose all
   * @returns { void}
   */
  @autobind
  public dispose(): void {
    if (this.localSpace) {
      DisposeHelper.disposeHierarchy(this.localSpace);
    }
    DisposeHelper.disposeHierarchy(this.group);
    DisposeHelper.disposeHierarchy(this);
    this.viewer.scene.remove(this.flightPath);
    DisposeHelper.disposeHierarchy(this.flightPath);
    this.video = undefined;
    this.videoTexture = undefined;
    this.csvData = [];
    this.calcDroneCamera = undefined;
  }

  /**
   * @public
   * @description scale plane from aspect
   * @returns { void}
   */
  @autobind
  public scalePlaneFromAspect(aspect: number) {
    if (aspect !== this.aspect) {
      this.aspect = aspect;
    }
    this.scale.set(this.baseSize, this.baseSize, this.baseSize);
    if (aspect > 1) {
      this.scale.setY(this.scale.y * (1 / aspect));
    } else {
      this.scale.setX(this.scale.x * aspect);
    }
  }

  @autobind
  public createFlightPath() {
    if (!this.csvData || this.csvData.length === 0) {
      return;
    }
    this.flightPath.visible = false;
    const points: number[] = [];
    let lastPoint: Vector3 | null = null;
    let firstPoint: Vector3 | null = null;
    let previousPoint: Vector3 | null = null;

    const ICON_INTERVAL = 10; // Create icon every 10 rows
    const MIN_DISTANCE = 20; // Minimum distance between icons
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.csvData.length; i++) {
      const row = this.csvData[i];

      const latitude = parseFloat(row[1]);
      const longitude = parseFloat(row[2]);
      const altitude = parseFloat(row[3]);
      const point = this.viewer.runtime!.getPositionFromLatLongHeight({
        long: longitude,
        lat: latitude,
        height: altitude,
      });
      if (i === 0) {
        firstPoint = point;
        previousPoint = point;
      } else if (this.csvData.length - 1 === i) {
        lastPoint = point;
      }

      points.push(point.x, point.y, point.z);
      // Create icon every 10 rows
      if (i % ICON_INTERVAL === 0) {
        if (previousPoint && point.distanceTo(previousPoint) > MIN_DISTANCE) {
          this.addFlightPathArrow(point, previousPoint);
          previousPoint = point;
        }
      }
    }
    if (firstPoint && lastPoint) {
      if (this.flightPath.children.length > 0) {
        if (firstPoint.distanceTo(lastPoint) > MIN_DISTANCE) {
          this.addFlightPathSprite(
            lastPoint,
            this.flightPath.children[this.flightPath.children.length - 1].position,
            lastPoint
          );
        }
        this.addFlightPathSprite(this.flightPath.children[0].position, firstPoint, firstPoint);
      }
    }
    // Create and add the path
    this.createFlightPathCurve(points);
  }
  @autobind
  private addFlightPathSprite(point1: Vector3, point2: Vector3, position: Vector3) {
    const arrow = ARROW_LARGE.clone();

    arrow.position.copy(position);

    _direction.subVectors(point1, point2).normalize();

    _quaternion.setFromUnitVectors(_up, _direction);
    arrow.setRotationFromQuaternion(_quaternion);

    this.flightPath.add(arrow);
  }
  @autobind
  private createFlightPathCurve(points: number[]) {
    const geometry = new LineGeometry();
    geometry.setPositions(points);

    const line = new Line2(geometry, FLIGHT_PATH_MATERIAL);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);
    line.renderOrder = 12;

    this.flightPath.add(line);
    this.viewer.scene.add(this.flightPath);

    // Update the material's resolution to match the renderer's resolution
    const updateLineMaterial = () => {
      const canvas = this.viewer.renderer.domElement;
      FLIGHT_PATH_MATERIAL.resolution.set(canvas.clientWidth, canvas.clientHeight);
    };

    // Call once to set initial resolution
    updateLineMaterial();

    // Add event listener to update resolution when window is resized
    window.addEventListener('resize', updateLineMaterial);
  }

  @autobind
  private addFlightPathArrow(position: Vector3, previousPosition: Vector3 | null = null) {
    if (previousPosition) {
      this.createArrowHead(position, previousPosition);
    } else {
      // Optionally, add a default orientation arrow if there's no previous position
      const defaultDirection = new Vector3(0, 1, 0); // Adjust as needed
      const arrow = ARROW.clone();
      arrow.position.copy(position);

      _quaternion.setFromUnitVectors(new Vector3(0, 1, 0), defaultDirection);
      arrow.setRotationFromQuaternion(_quaternion);
      this.flightPath.add(arrow);
    }
  }
  @autobind
  public createArrowHead(point1: Vector3, point2: Vector3) {
    const arrow = ARROW.clone();

    arrow.position.copy(point1);

    _direction.subVectors(point1, point2).normalize();

    _quaternion.setFromUnitVectors(_up, _direction);
    arrow.setRotationFromQuaternion(_quaternion);

    this.flightPath.add(arrow);
  }
  @autobind
  public appendVideoToOverlay(): void {
    if (this.video) {
      const overlayHolder = document.getElementById('video-overlay-holder');
      if (overlayHolder) {
        overlayHolder.appendChild(this.video);
        this.video.controls = true;
        this.video.style.width = '100%';
        this.video.style.height = '100%';
        this.video.style.objectFit = 'contain';
        // this.viewer.controllers.photoAlbumController?.toggleMiniView(true);
        setTimeout(() => {
          this.viewer.controllers.photoAlbumController?.resizePhotoController();
        }, 100);
      }
    }
  }
  @autobind
  public removeVideoFromOverlay(): void {
    if (this.video && this.video.parentNode) {
      this.video.parentNode.removeChild(this.video);

      setTimeout(() => {
        this.viewer.controllers.photoAlbumController?.resizePhotoController();
      }, 100);
    }
  }
}
