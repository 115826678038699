/**
 * TODO: @ebraj-angelswing
 * It is built with reference to the day-picker from the IssueDueDatePicker.
 * More to refactor on it and make it reusable...
 * Ref - For range picker - https://react-day-picker-v7.netlify.app/examples/selected-range
 */

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import React, { MouseEvent, TouchEvent, useRef, useState } from 'react';
import styled from 'styled-components';

import * as T from '^/types';
import Text from './text';

import FlightScheduleDateNextSvg from '^/assets/icons/issue-due-date-next.svg';
import FlightScheduleDatePrevSvg from '^/assets/icons/issue-due-date-prev.svg';
import RawFlightScheduleDateSvg from '^/assets/icons/issue-due-date.svg';

import Tippy from '@tippyjs/react';
import CloseSVG from '^/assets/icons/toast-close.svg';
import { useL10n } from '^/hooks';

import { makeConsistentUTCDateViaOffset } from '^/utilities/date-format';
import Color from 'color';
import DayPicker, { DateUtils, DayModifiers, RangeModifier } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { hideAll } from 'tippy.js';

type TselectedExecuteDate = T.RawFlightScheduleFields['selectedExecuteDate'];
interface Props {
  selectedDate: TselectedExecuteDate;
  onResetDate(): void;
  onSelectedDateChange(newSelectedDate: TselectedExecuteDate): void;
  hasError: boolean;
}

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '12px',
  color: palette.FlightSchedule.sidebarItemFont.toString(),
});

const Title = styled.div({
  fontSize: '14px',
  fontWeight: 'bold',

  marginBottom: '16px',
  color: dsPalette.title.toString(),
});

const FlightScheduleDateSvg = styled(RawFlightScheduleDateSvg)({
  marginTop: '2px',
  marginLeft: '1px',
});

const MainArea = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  // position: 'relative',
});

const MainButton = styled.button<{ hasError?: boolean }>(({ hasError }) => ({
  all: 'unset',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '6.5px',

  boxSizing: 'border-box',
  width: '100%',
  height: '37px',
  paddingLeft: '9px',
  paddingRight: '9px',

  backgroundColor: hasError
    ? palette.DDMInput.error.alpha(0.05).toString()
    : palette.white.toString(),
  border: `1px solid ${(hasError ? palette.DDMInput.error : palette.border).toString()}`,
  borderRadius: '5px',

  cursor: 'pointer',
}));

const CalendarIconWrapper = styled.div({
  color: dsPalette.typePrimary.toString(),
});

interface CalendarAreaProps {
  selectedDate: TselectedExecuteDate;
  onCancel(): void;
  onConfirm(newDateRange: TselectedExecuteDate): void;
}

const CalendarAreaRoot = styled.div({
  position: 'absolute',
  zIndex: 1,
  boxSizing: 'border-box',
  width: 'min-content',
  backgroundColor: palette.white.toString(),
  padding: '21px 17px 26px 17px',
  border: `1px solid ${palette.border.toString()}`,
  borderRadius: '5px',
  '&&&': {
    '.DayPicker': {
      width: '100%',
      marginBottom: '17px',
    },
    '.DayPicker-wrapper': {
      paddingBottom: '0px',
    },
    '.DayPicker-Months': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      width: '100%',
      gap: '25px',
    },
    '.DayPicker-Month': {
      width: '100%',
      margin: '0px',
      display: 'flex',
      flexDirection: 'column',
    },
    '.DayPicker-NavButton': {
      top: 0,
      right: 0,
      marginTop: 0,
    },
    '.DayPicker-Caption': {
      textAlign: 'center',
      color: dsPalette.typePrimary.toString(),
      fontSize: '12px',
      fontWeight: 400,
      paddingBottom: '10px',
    },
    '.DayPicker-Weekdays, .DayPicker-WeekdaysRow': {
      display: 'flex',
      marginTop: '0px',
      width: '100%',
      justifyContent: 'space-between',
    },
    '.DayPicker-WeekdaysRow': {
      width: '100%',
      fontSize: '13px',
    },
    '.DayPicker-Body': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.DayPicker-Week': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.DayPicker-Day': {
      padding: '0px',
      width: '26px',
      height: '26px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: dsPalette.title.toString(),
    },
    '.DayPicker-Weekday': {
      padding: '0px',
      width: '26px',
      height: '26px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: palette.Calendar.weekday.toString(),
      fontWeight: 500,
    },
    '.DayPicker-Day--today': {
      fontWeight: 'normal',
    },
    '.DayPicker-Day--from': {
      backgroundColor: dsPalette.themePrimary.toString(),
      color: palette.white.toString(),

      '&:not(.DayPicker-Day--selected):hover': {
        color: dsPalette.themePrimary.toString(),
      },
    },
    '.DayPicker-Day--selected': {
      backgroundColor: dsPalette.themePrimary.toString(),
      color: palette.white.toString(),
    },
    '.DayPicker-Day--disabled': {
      position: 'relative',
      color: palette.Calendar.disabled.toString(),
    },
    '.DayPicker-Day--outside': {
      visibility: 'hidden',
    },

    abbr: {
      cursor: 'default',
    },

    '.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover': {
      boxSizing: 'border-box',
      border: `1px solid ${dsPalette.themePrimary.toString()}`,
      backgroundColor: new Color(
        `rgba(${dsPalette.themePrimary.red()}, ${dsPalette.themePrimary.green()}, ${dsPalette.themePrimary.blue()}, 0.25)`
      ).toString(),
    },

    '.DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside)':
      {
        backgroundColor: '#f0f8ff !important',
        color: dsPalette.themePrimary.toString(),
      },
  },
});

const FlightScheduleDateHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '11px',
  paddingLeft: '6.5px',
  paddingRight: '3px',
});

const FlightScheduleDateButtonsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  columnGap: '12px',
  width: '100%',
});

const FlightScheduleDateButton = styled.button({
  all: 'unset',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: new Color(
      `rgba(${dsPalette.themePrimary.red()}, ${dsPalette.themePrimary.green()}, ${dsPalette.themePrimary.blue()}, 0.15)`
    ).toString(),
  },
});

const FlightScheduleDateUIButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  columnGap: '4px',
});

const FlightScheduleDateUIButton = styled.button({
  all: 'unset',
  padding: '4px 12px',
  color: dsPalette.themePrimary.toString(),
  fontWeight: 'bold',
  fontSize: '12px',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: new Color(
      `rgba(${dsPalette.themePrimary.red()}, ${dsPalette.themePrimary.green()}, ${dsPalette.themePrimary.blue()}, 0.15)`
    ).toString(),
  },
});

const ValueWrapper = styled.div({
  display: 'flex',
  gap: ' 10px',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const ClosedButtonWrapper = styled.div({
  padding: '5px 0 5px 5px',
  color: '#e03a3a',
});

function CalendarArea({ selectedDate, onCancel, onConfirm }: CalendarAreaProps) {
  const [range, selectedRange] = useState<TselectedExecuteDate>(selectedDate);
  const [curYear, setCurYear] = useState<number>(new Date().getFullYear());
  const [curMonthIndex, setCurMonthIndex] = useState<number>(new Date().getMonth());
  const [l10n, language] = useL10n();
  const timezoneOffset: T.CommonPageState['timezoneOffset'] = useSelector(
    (state: T.State) => state.Pages.Common.timezoneOffset
  );

  const { from, to } = range;
  const newModifiers = { from, to };

  const handleDayClick: (
    day: Date,
    modifiers: DayModifiers,
    e: MouseEvent<HTMLDivElement>
  ) => void = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }
    /**
     * @desc react-day-picker returns Tue Jul 04 2017 **12:00:00** GMT+0300 (MSK)
     * We have to refine the time
     * This will make time 'YYYY/MM/DD 00:00:00 UTC'.
     */

    const refinedDate: Date = makeConsistentUTCDateViaOffset(day, timezoneOffset);

    selectedRange(DateUtils.addDayToRange(refinedDate, range as RangeModifier));
  };

  const handleDayTouchEnd: (
    day: Date,
    modifiers: DayModifiers,
    e: TouchEvent<HTMLDivElement>
  ) => void = (day, modifiers, e) => {
    handleDayClick(day, modifiers, e as unknown as MouseEvent<HTMLDivElement>);
  };

  function handleOnClickPrev() {
    const rawPrevMonthIndex = curMonthIndex - 1;
    const prevYear = rawPrevMonthIndex < 0 ? curYear - 1 : curYear;
    const prevMonthIndex = (12 + rawPrevMonthIndex) % 12;
    setCurYear(prevYear);
    setCurMonthIndex(prevMonthIndex);
  }

  function handleOnClickNext() {
    const rawNextMonthIndex = curMonthIndex + 1;
    const nextYear = rawNextMonthIndex >= 12 ? curYear + 1 : curYear;
    const nextMonthIndex = rawNextMonthIndex % 12;
    setCurYear(nextYear);
    setCurMonthIndex(nextMonthIndex);
  }

  function handleOnCancel() {
    onCancel();
  }

  function handleOnConfirm() {
    onConfirm(range);
  }

  return (
    <CalendarAreaRoot>
      <FlightScheduleDateHeader>
        <FlightScheduleDateButtonsWrapper>
          <FlightScheduleDateButton onClick={handleOnClickPrev}>
            <FlightScheduleDatePrevSvg />
          </FlightScheduleDateButton>
          <FlightScheduleDateButton onClick={handleOnClickNext}>
            <FlightScheduleDateNextSvg />
          </FlightScheduleDateButton>
        </FlightScheduleDateButtonsWrapper>
      </FlightScheduleDateHeader>
      <DayPicker
        canChangeMonth={false}
        showOutsideDays={true}
        fixedWeeks={true}
        disabledDays={{ before: new Date() }}
        month={new Date(curYear, curMonthIndex)}
        onDayClick={handleDayClick}
        onDayTouchEnd={handleDayTouchEnd}
        locale={language}
        selectedDays={{ from, to } as RangeModifier}
        numberOfMonths={2}
        modifiers={newModifiers}
      />

      <FlightScheduleDateUIButtonWrapper>
        <FlightScheduleDateUIButton onClick={handleOnCancel}>
          {l10n(Text.cancel)}
        </FlightScheduleDateUIButton>
        <FlightScheduleDateUIButton onClick={handleOnConfirm}>
          {l10n(Text.setDate)}
        </FlightScheduleDateUIButton>
      </FlightScheduleDateUIButtonWrapper>
    </CalendarAreaRoot>
  );
}

const FlightScheduleDatePicker = (props: Props) => {
  const [isCalendarShown, setIsCalendarShown] = useState<boolean>(false);
  const mainAreaRef = useRef<HTMLDivElement>(null);
  const [l10n, language] = useL10n();

  const {
    selectedDate: { from, to },
    onResetDate,
    hasError,
  } = props;

  const getProperDateFormat = (providedDate: Date) => {
    switch (language) {
      case T.Language.KO_KR:
        return `${providedDate.getFullYear()}${Text.year[T.Language.KO_KR]} ${
          Text.month[T.Language.KO_KR][providedDate.getMonth()]
        } ${providedDate.getDate()}${Text.date[T.Language.KO_KR]} ${
          Text.weekday[T.Language.KO_KR][providedDate.getDay()]
        }`;
      case T.Language.EN_US:
        return `${Text.weekday[T.Language.EN_US][providedDate.getDay()]}, ${
          Text.month[T.Language.EN_US][providedDate.getMonth()]
        } ${providedDate.getDate()}, ${providedDate.getFullYear()}`;
      default:
        return '';
    }
  };

  const valueViewer = (() => {
    if (from && to) {
      return `${getProperDateFormat(from)} - ${getProperDateFormat(to)}`;
    } else if (from && !to) {
      return `${getProperDateFormat(from)} - ${l10n(Text.endDate)}`;
    } else {
      return `${l10n(Text.startDate)} - ${l10n(Text.endDate)}`;
    }
  })();

  const rawCloseButton =
    from || to ? (
      <Tippy theme="angelsw" arrow={false} placement="bottom" content="Reset Date">
        <ClosedButtonWrapper onClick={handleOnResetClick}>
          <CloseSVG />
        </ClosedButtonWrapper>
      </Tippy>
    ) : null;

  const calendarArea = isCalendarShown ? (
    <CalendarArea
      selectedDate={props.selectedDate}
      onCancel={handleOnDateCancel}
      onConfirm={handleOnDateConfirm}
    />
  ) : null;

  function handleOnDateCancel() {
    hideAll();
    setIsCalendarShown(false);
  }

  function handleOnDateConfirm(newDate: TselectedExecuteDate) {
    setIsCalendarShown(false);

    hideAll();
    props.onSelectedDateChange(newDate);
  }

  function handleOnResetClick(e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    e.stopPropagation();

    hideAll();
    onResetDate();
  }

  return (
    <Root>
      <Title>{l10n(Text.title)}</Title>

      <MainArea ref={mainAreaRef}>
        <Tippy
          arrow={false}
          placement="bottom-start"
          theme="calendar-theme"
          trigger="click"
          interactive={true}
          appendTo={() => document.body}
          hideOnClick={false}
          // onHide={() => {
          //   setIsCalendarShown(false);
          // }}
          onClickOutside={() => {
            setIsCalendarShown(false);
            hideAll();
          }}
          content={calendarArea}
        >
          <MainButton onClick={() => setIsCalendarShown(true)} hasError={hasError}>
            <CalendarIconWrapper>
              <FlightScheduleDateSvg />
            </CalendarIconWrapper>
            <ValueWrapper>{valueViewer}</ValueWrapper>
            {rawCloseButton}
          </MainButton>
        </Tippy>
      </MainArea>
    </Root>
  );
};

export default FlightScheduleDatePicker;
