import colors from './color-variants';

const colorTokens = {
  accentBlue: colors.neutralBlue[500],
  accentBlueDark: colors.neutralBlue[600],
  accentBlueDarker: colors.neutralBlue[700],
  accentBlackLighter: colors.coolGray[700],

  accentWhiteDark: colors.neutralGray[30],
  accentWhiteDarker: colors.neutralGray[75],

  disabledGrayLighter: colors.neutralGray[75],
  disabledGrayLight: colors.neutralGray[100],
  disabledGray: colors.neutralGray[200],
  disabledGrayDark: colors.neutralGray[300],
  disabledGrayDarker: colors.neutralGray[400],

  errorRed: colors.pointRed[500],
  warnRed: colors.pointRed[400],

  successBlue: colors.neutralBlue[500],
  successGreen: colors.pointGreen[500],
  focusSkyblue: colors.lightBlue[500],
  highlightSkyblue: colors.lightBlue[400],
  focusLightBlue: colors.lightBlue[75],

  lineCoolLighter: colors.coolGray[100],
  lineCoolLight: colors.coolGray[200],
  lineCool: colors.coolGray[300],
  lineCoolDark: colors.coolGray[400],
  lineCoolDarker: colors.coolGray[500],

  lineGrayLighter: colors.coolGray[100],
  lineGrayLight: colors.coolGray[200],
  lineGray: colors.coolGray[300],
  lineGrayDark: colors.coolGray[400],
  lineGrayDarker: colors.coolGray[500],

  textCoolLighter: colors.coolGray[300],
  textCoolLight: colors.coolGray[400],
  textCool: colors.coolGray[600],
  textCoolDark: colors.coolGray[700],
  textCoolDarker: colors.coolGray[900],

  textGrayLighter: colors.neutralGray[300],
  textGrayLight: colors.neutralGray[400],
  textGray: colors.neutralGray[600],
  textGrayDark: colors.neutralGray[700],
  textGrayDarker: colors.neutralGray[900],

  bgWhiteDark: colors.neutralGray[30],
  bgWhiteDarker: colors.neutralGray[75],
  bgLightGray: colors.coolGray[30],
  bgLightCoolGray: colors.coolGray[50],

  inputBorderColor: colors.coolGray[300],
  inputBackgroundColor: colors.neutralGray[50],

  bgBlack: colors.blackVariants.blackDark,
  bgBlackLight: colors.blackVariants.blackLight,
  bgBlackLighter: colors.blackVariants.blackLighter,

  bgBlueLight: colors.lightBlue[75],
};

export default colorTokens;
