import React, { FC } from 'react';
import styled from 'styled-components';
import * as T from '^/types';

import DistanceIcon from '^/assets/icons/filters/16px_Distance.svg';
import AllIcon from '^/assets/icons/filters/16px_All.svg';
import AreaIcon from '^/assets/icons/filters/16px_Area.svg';
import ArrowIcon from '^/assets/icons/filters/16px_Arrow.svg';
import CustomModelIcon from '^/assets/icons/filters/16px_CustomModel.svg';
import DXFIcon from '^/assets/icons/filters/16px_DXF.svg';
import ESSIcon from '^/assets/icons/filters/16px_ESS.svg';
import FoldersIcon from '^/assets/icons/filters/16px_Folders.svg';
import IFCIcon from '^/assets/icons/filters/16px_IFC.svg';
import LocationIcon from '^/assets/icons/filters/16px_Location.svg';
import PDFIcon from '^/assets/icons/filters/16px_PDF.svg';
import PolygonIcon from '^/assets/icons/filters/16px_Polygon.svg';
import PolylineIcon from '^/assets/icons/filters/16px_PolyLine.svg';
import TextIcon from '^/assets/icons/filters/16px_Text.svg';
import VolumeIcon from '^/assets/icons/filters/16px_Volume.svg';
import ThreeDistanceIcon from '^/assets/icons/filters/16px_3pDistance.svg';

const IconWrapper = styled.div({
  width: 16,
  height: 16,
  color: '#b1b1b1',
});
const getContentIcon = (content?: T.ContentType) => {
  switch (content) {
    case T.ContentType.LENGTH:
    case T.ContentType.THREE_LENGTH:
      return <DistanceIcon />;
    case T.ContentType.AREA:
    case T.ContentType.THREE_AREA:
      return <AreaIcon />;
    case T.ContentType.ESS_ARROW:
      return <ArrowIcon />;
    case T.ContentType.ESS_MODEL_CUSTOM:
      return <CustomModelIcon />;
    case T.ContentType.DESIGN_DXF:
      return <DXFIcon />;
    case T.ContentType.ESS_MODEL:
      return <ESSIcon />;
    case T.ContentType.GROUP:
      return <FoldersIcon />;
    case T.ContentType.MARKER:
      return <LocationIcon />;
    case T.ContentType.BLUEPRINT_PDF:
    case T.ContentType.BLUEPRINT_DWG:
    case T.ContentType.BLUEPRINT_DXF:
      return <PDFIcon />;
    case T.ContentType.ESS_POLYGON:
      return <PolygonIcon />;
    case T.ContentType.ESS_POLYLINE:
      return <PolylineIcon />;
    case T.ContentType.ESS_TEXT:
      return <TextIcon />;
    case T.ContentType.VOLUME:
      return <VolumeIcon />;
    case T.ContentType.BIM:
      return <IFCIcon />;
    case T.ContentType.THREE_HEIGHT:
      return <ThreeDistanceIcon />;
    default:
      return <AllIcon />;
  }
};

export const ContentFilterIcon: FC<{ contentType?: T.ContentType }> = ({ contentType }) => (
  <IconWrapper>{getContentIcon(contentType)}</IconWrapper>
);
