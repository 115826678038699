import { create } from 'zustand';
import * as T from '^/types';
import { MarkerAreaState } from 'markerjs2';

interface AnnotationState {
  isAnnotationModeOpen: boolean;
  currentAnnotation: T.AnnotationContent | null;
  annotationsForSelectedSlide: T.AnnotationContent[];
  isAnnotationsPanelOpen: boolean;
  unsavedAnnotations: MarkerAreaState | null;
  annotationIdsBySlideId: Record<T.Content['id'], Array<T.Content['id']>>;
  deletingAnnotationId: T.Content['id'] | null;
  showAnnotationsToolbar: boolean;
}
interface AnnotationStateActions {
  setIsAnnotationModeOpen(isAnnotationModeOpen: boolean): void;
  setCurrentAnnotation(currentAnnotation: T.AnnotationContent | null): void;
  setAnnotationsForSelectedSlide(annotationsForSelectedSlide: T.AnnotationContent[]): void;
  setIsAnnotationsPanelOpen(isAnnotationsPanelOpen: boolean): void;
  setUnsavedAnnotations(unsavedAnnotations: MarkerAreaState): void;
  setAnnotationIdsBySlideId(
    annotationIdsBySlideId: Record<T.Content['id'], Array<T.Content['id']>>
  ): void;
  setDeletingAnnotationId(deletingAnnotationid: T.Content['id'] | null): void;
  setShowAnnotationsToolbar(showAnnotationsToolbar: boolean): void;
}
const initialState: AnnotationState = {
  isAnnotationModeOpen: false,
  currentAnnotation: null,
  annotationsForSelectedSlide: [],
  isAnnotationsPanelOpen: false,
  unsavedAnnotations: null,
  annotationIdsBySlideId: {},
  deletingAnnotationId: null,
  showAnnotationsToolbar: false,
};

type AnnotationStoreInterface = AnnotationState & AnnotationStateActions;
export const useAnnotationStore = create<AnnotationStoreInterface>()(set => ({
  ...initialState,
  setIsAnnotationModeOpen: isAnnotationModeOpen => set({ isAnnotationModeOpen }),
  setCurrentAnnotation: currentAnnotation => set({ currentAnnotation }),
  setAnnotationsForSelectedSlide: annotationsForSelectedSlide =>
    set({ annotationsForSelectedSlide }),
  setIsAnnotationsPanelOpen: isAnnotationsPanelOpen => set({ isAnnotationsPanelOpen }),
  setUnsavedAnnotations: unsavedAnnotations => set({ unsavedAnnotations }),
  setAnnotationIdsBySlideId: annotationIdsBySlideId => set({ annotationIdsBySlideId }),
  setDeletingAnnotationId: deletingAnnotationId => set({ deletingAnnotationId }),
  setShowAnnotationsToolbar: showAnnotationsToolbar => set({ showAnnotationsToolbar }),
}));
