import * as T from '^/types';

interface PartialESSContentGeneric<E extends T.ESSContent | T.APIESSContent> {
  readonly type: E['type'];
  readonly title?: E['title'];
  readonly info?: Partial<E['info']>;
  readonly config?: Partial<E['config']>;
  readonly color?: E['color'];
  readonly groupId?: E['groupId'];
}
type PartialESSModelContent = PartialESSContentGeneric<T.ESSModelContent>;
type PartialESSCustomModelContent = PartialESSContentGeneric<T.ESSCustomModelContent>;
type PartialESSArrowContent = PartialESSContentGeneric<T.ESSArrowContent>;
type PartialESSPolygonContent = PartialESSContentGeneric<T.ESSPolygonContent>;
type PartialESSPolylineContent = PartialESSContentGeneric<T.ESSPolylineContent>;
type PartialESSTextContent = PartialESSContentGeneric<T.ESSTextContent>;
type PartialESSLineTextContent = PartialESSContentGeneric<T.ESSLineTextContent>;
export type PartialESSContent =
  | PartialESSModelContent
  | PartialESSArrowContent
  | PartialESSPolygonContent
  | PartialESSPolylineContent
  | PartialESSTextContent
  | PartialESSCustomModelContent
  | PartialESSLineTextContent
  | PartialESSContentGeneric<T.GroupContent>;

type PartialESSModelContentParams = PartialESSContentGeneric<T.APIESSModelContent> & {
  memo?: T.ESSModelContent['info']['description'];
};
type PartialESSArrowContentParams = PartialESSContentGeneric<T.ESSArrowContent>;
export type PartialAPIESSParams = Overwrite<
  PartialESSModelContentParams | PartialESSArrowContentParams,
  {
    type: T.APIESSContentType;
    config?: {
      selectedAt?: string;
    };
  }
>;

export const shapeESSContentParams: (
  content: PartialESSContent
) => PartialAPIESSParams = content => {
  switch (content.type) {
    case T.ContentType.ESS_MODEL: {
      return {
        type: T.APIESSContentType.MODEL,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        memo: content.info?.description?.toString(),
        info: content.info
          ? {
              locations: content.info?.location,
              heading: content.info?.heading,
              speed: content.info?.speed,
              modelId: content.info?.modelId,
              waypointId: content.info?.waypointId,
              miscMeta: content.info?.miscMeta,
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    case T.ContentType.ESS_MODEL_CUSTOM: {
      return {
        type: T.APIESSContentType.MODEL_CUSTOM,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        memo: content.info?.description?.toString(),
        info: content.info
          ? {
              heading: content.info?.heading,
              locations: content.info?.location,
              miscMeta: content.info?.miscMeta,
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    case T.ContentType.ESS_ARROW: {
      return {
        type: T.APIESSContentType.ARROW,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        info: content.info
          ? {
              locations: content.info?.locations,
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    case T.ContentType.ESS_POLYGON: {
      return {
        type: T.APIESSContentType.POLYGON,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        info: content.info
          ? {
              locations: content.info?.locations,
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    case T.ContentType.ESS_POLYLINE: {
      return {
        type: T.APIESSContentType.POLYLINE,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        info: content.info
          ? {
              locations: content.info?.locations,
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    case T.ContentType.ESS_TEXT: {
      return {
        type: T.APIESSContentType.TEXT,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        info: content.info
          ? {
              locations: content.info?.location,
              description: content.info?.description,
              fontSize: content.info?.fontSize,
              fontColor: content.info?.fontColor?.toString(),
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    case T.ContentType.ESS_LINE_TEXT: {
      return {
        type: T.APIESSContentType.LINE_TEXT,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        info: content.info
          ? {
              locations: content.info?.locations,
              description: content.info?.description,
              fontSize: content.info?.fontSize,
              fontColor: content.info?.fontColor?.toString(),
              borderColor: content.info?.borderColor?.toString(),
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    case T.ContentType.GROUP: {
      return {
        type: T.APIESSContentType.GROUP,
        groupId: content.groupId,
        color: content.color?.toString(),
        title: content.title?.toString(),
        info: content.info
          ? {
              isOpened: content.info?.isOpened,
            }
          : undefined,
        config: content.config
          ? {
              selectedAt: content.config.selectedAt?.toISOString(),
            }
          : undefined,
      };
    }
    default: {
      throw new Error(`Invalid type: ${content}`);
    }
  }
};
