import * as T from '^/types';
import { Map } from 'ol';
import { createStore, useStore } from 'zustand';

interface TPhotoAlbumId {
  photoId: number | null;
  photoType: T.PhotoType | undefined;
}
interface TThreeSixtyMiniMapDetails {
  photoLongLat: T.GeoPoint;
  photoCameraOrientation: { x: number; y: number; z: number };
  photoFov: number;
  miniMap2D: Map | null;
  selectedPhotoId: string | undefined;
}
export interface ThreePhotoAlbumStore {
  isOnPhotoTab: boolean;
  isInspectionMode: boolean;
  photoMode: string | null | undefined;
  inspectionDate: string | null;
  sourceId: number | null | undefined;
  sourcePhotos: T.FinalPhoto[];
  isVideoOverlay: boolean;
  isVideoDigitalView: boolean;
  isFirstPersonView: boolean;
  isPhotoOverlay: boolean;
  is360MiniMapView: boolean;
  selectedPhotoAlbumIdNew: TPhotoAlbumId;
  isShouldResetToDroneView: boolean;
  threeSixtyMiniMapDetails: TThreeSixtyMiniMapDetails;

  setInspectionDate(value: string | null): void;
  setPhotoMode(value: string | null | undefined): void;
  setSelectedPhotoAlbumIdNew(value: TPhotoAlbumId): void;
  setThreeSixtyMiniMapDetails(value: Partial<TThreeSixtyMiniMapDetails>): void;
  setIsOnPhotoTab(value: boolean): void;
  setIsInspectionMode(value: boolean): void;
  setSourceId(value: number | null | undefined): void;
  setSourcePhotos(value: T.FinalPhoto[]): void;
  setIsVideoOverlay(value: boolean): void;
  setIsVideoDigitalView(value: boolean): void;
  setIsFirstPersonView(value: boolean): void;
  setIsPhotoOverlay(value: boolean): void;
  setIs360MiniMapView(value: boolean): void;
  setIsShouldResetToDroneView(value: boolean): void;
  resetPhotoAlbumStore(): void;
}

const initialState = {
  isOnPhotoTab: false,
  isInspectionMode: false,
  selectedPhotoAlbumIdNew: {
    photoId: null,
    photoType: undefined,
  },
  threeSixtyMiniMapDetails: {
    photoLongLat: [],
    photoCameraOrientation: {
      x: 0,
      y: 0,
      z: 0,
    },
    miniMap2D: null,
    photoFov: 50,
    selectedPhotoId: undefined,
  },
  inspectionDate: null,
  photoMode: null,
  sourceId: null,
  is360PhotoOverlay: false,
  is360MiniMapView: false,
  sourcePhotos: [],
  isVideoOverlay: false,
  isVideoDigitalView: false,
  isFirstPersonView: true,
  isPhotoOverlay: false,
  isShouldResetToDroneView: false,
};

export const threeAlbumStore = createStore<ThreePhotoAlbumStore>()(set => ({
  ...initialState,
  setInspectionDate: (value: string | null) => set(() => ({ inspectionDate: value })),
  setPhotoMode: (value: string | null | undefined) => set(() => ({ photoMode: value })),
  setSelectedPhotoAlbumIdNew: (value: TPhotoAlbumId) =>
    set(() => ({
      selectedPhotoAlbumIdNew: value,
    })),
  setThreeSixtyMiniMapDetails: (value: Partial<TThreeSixtyMiniMapDetails>) =>
    set(state => ({
      threeSixtyMiniMapDetails: {
        ...state.threeSixtyMiniMapDetails,
        ...value,
      },
    })),
  setIsOnPhotoTab: (value: boolean) => set(() => ({ isOnPhotoTab: value })),
  setIsInspectionMode: (value: boolean) => set(() => ({ isInspectionMode: value })),
  setSourceId: (value: number | null | undefined) => set(() => ({ sourceId: value })),
  setSourcePhotos: (value: T.FinalPhoto[]) => set(() => ({ sourcePhotos: value })),
  setIsVideoOverlay: (value: boolean) => set(() => ({ isVideoOverlay: value })),
  setIsVideoDigitalView: (value: boolean) => set(() => ({ isVideoDigitalView: value })),
  setIsFirstPersonView: (value: boolean) => set(() => ({ isFirstPersonView: value })),
  setIsPhotoOverlay: (value: boolean) => set(() => ({ isPhotoOverlay: value })),
  setIs360MiniMapView: (value: boolean) => set(() => ({ is360MiniMapView: value })),
  setIsShouldResetToDroneView: (value: boolean) => set(() => ({ isShouldResetToDroneView: value })),
  resetPhotoAlbumStore: () => set(initialState),
}));

export function useThreePhotoAlbumStore(): ThreePhotoAlbumStore;
export function useThreePhotoAlbumStore<T>(selector: (state: ThreePhotoAlbumStore) => T): T;
export function useThreePhotoAlbumStore<T>(selector?: (state: ThreePhotoAlbumStore) => T) {
  return useStore(threeAlbumStore, selector!);
}
