/* eslint-disable max-lines */
import styled, { css, CSSObject, keyframes } from 'styled-components';
import React, { FC, MouseEventHandler, useEffect, useState } from 'react';

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import PreviousSVG from '^/assets/icons/popup/left-arrow.svg';
import CheckedSVG from '^/assets/icons/photo/checked.svg';

import { MediaQuery, responsiveStyle } from '^/constants/styles';
import * as T from '^/types';
import theme from '^/theme';

interface SlidePanelWidth {
  slidePanelWidth?: number;
}
interface SelectedProps {
  isSelected?: boolean;
  isInspection?: boolean;
  isDisabled?: boolean;
}

type SelectedWrapperProps = SlidePanelWidth & SelectedProps;

const SELECTED_FLEX_BOX_MIN_WIDTH = 415;

export const PhotoListRoot = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: palette.white.toString(),
  top: '3.188rem',
  padding: '0px 15px 0px 75px',
  height: 'calc(100vh - 40px)',
});
PhotoListRoot.displayName = 'PhotoListRoot';

export const PhotoOnboardingRoot = styled.div({
  position: 'absolute',
  left: '50%',
  top: 'calc(50% - 25px)',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: dsPalette.typePrimary.toString(),
});
PhotoOnboardingRoot.displayName = 'PhotoOnboardingRoot';

export const PhotoListWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
  gridColumnGap: '9px',
  gridRowGap: '9px',

  [MediaQuery[T.Device.TABLET]]: { gridTemplateColumns: 'repeat(4, 1fr)' },
  [MediaQuery[T.Device.MOBILE_L]]: { gridTemplateColumns: 'repeat(3, 1fr)' },
});
PhotoListWrapper.displayName = 'PhotoListWrapper';

export const PhotoWrapper = styled.div({
  padding: ' 0 1rem 34px 0',
});

export const PhotoMonthHeader = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  lineHeight: '19px',
  fontSize: '16px',
  fontWeight: '600',
  padding: '0 13px 13px 11px',
  transform: 'translateY(-1px)',
  color: palette.textBlack.toString(),
});
PhotoMonthHeader.displayName = 'PhotoMonthHeader';

export const PhotoHeader = styled.div({
  fontSize: '22.5px',
  lineHeight: '35px',
  fontWeight: 600,
  paddingBottom: '26px',
  color: palette.textBlack.toString(),
});
PhotoHeader.displayName = 'PhotoHeader';

export const PhotoDateSubHeader = styled.div<SelectedProps>(({ isSelected }) => ({
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 600,
  color: isSelected ? '#444444' : '#8F8F8F',
}));
PhotoDateSubHeader.displayName = 'PhotoDateSubHeader';

export const OnboardHeaderText = styled.div({
  fontSize: '35px',
  marginBottom: '14px',
  fontWeight: 'bold',
});
OnboardHeaderText.displayName = 'OnboardHeaderText';

export const OnboardHeaderSubText = styled.div({
  fontSize: '15px',
  marginBottom: '46px',
});
OnboardHeaderSubText.displayName = 'OnboardHeaderSubText';

export const OnboardDescription = styled.div({
  fontSize: '11px',
  lineHeight: '16px',
});
OnboardDescription.displayName = 'OnboardDescription';

export const OnboardSvgWrapper = styled.div({ display: 'inline-block' });
OnboardSvgWrapper.displayName = 'OnboardSvgWrapper';

export const PhotoListItem = styled.div<{
  url: string;
  isSelected: boolean;
  isVisible?: boolean;
  status?: T.PhotoProcessStatus;
}>(({ url, isSelected, isVisible, status }) => ({
  cursor: 'pointer',
  borderRadius: '6px',
  backgroundImage:
    status === T.PhotoProcessStatus.PENDING || status === T.PhotoProcessStatus.PROCESSING
      ? ``
      : isVisible
      ? `url('${url}')`
      : '',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  transition: 'height 0.2s',

  [MediaQuery[T.Device.DESKTOP]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoListItem[T.Device.DESKTOP]
      : responsiveStyle.photoListItem[T.Device.DESKTOP]),
  },
  [MediaQuery[T.Device.TABLET]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoListItem[T.Device.TABLET]
      : responsiveStyle.photoListItem[T.Device.TABLET]),
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoListItem[T.Device.MOBILE_L]
      : responsiveStyle.photoListItem[T.Device.MOBILE_L]),
  },

  '&:hover': {
    boxShadow:
      'inset 0px 60px 30px 5px rgba(0, 0, 0, 0.5),inset 0px -60px 30px 5px rgba(0, 0, 0, 0.5)',
  },
}));
PhotoListItem.displayName = 'PhotoListItem';

export const PhotoStaticListItem = styled.div<{
  url: string;
  isSelected: boolean;
  isVisible?: boolean;
  status?: T.PhotoProcessStatus;
}>(({ url, isSelected, isVisible, status }) => ({
  borderRadius: '6px',
  background:
    status === T.PhotoProcessStatus.PENDING || status === T.PhotoProcessStatus.PROCESSING
      ? ``
      : isVisible
      ? `linear-gradient(rgba(211, 211, 211, 0.5), rgba(211, 211, 211, 0.1)), url('${url}')`
      : '',
  backgroundSize: 'cover',
  backgroundColor: 'lightgray',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  transition: 'height 0.2s',

  [MediaQuery[T.Device.DESKTOP]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoListItem[T.Device.DESKTOP]
      : responsiveStyle.photoListItem[T.Device.DESKTOP]),
  },
  [MediaQuery[T.Device.TABLET]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoListItem[T.Device.TABLET]
      : responsiveStyle.photoListItem[T.Device.TABLET]),
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoListItem[T.Device.MOBILE_L]
      : responsiveStyle.photoListItem[T.Device.MOBILE_L]),
  },
}));
PhotoStaticListItem.displayName = 'PhotoStaticListItem';

export const PhotoInspectionListItemWrapper = styled.div<{
  isSelected: boolean;
}>(({ isSelected }) => ({
  [MediaQuery[T.Device.DESKTOP]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoInspectionListItem[T.Device.DESKTOP]
      : responsiveStyle.photoInspectionListItem[T.Device.DESKTOP]),
  },
  [MediaQuery[T.Device.TABLET]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoInspectionListItem[T.Device.TABLET]
      : responsiveStyle.photoInspectionListItem[T.Device.TABLET]),
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoInspectionListItem[T.Device.MOBILE_L]
      : responsiveStyle.photoInspectionListItem[T.Device.MOBILE_L]),
  },
}));
PhotoInspectionListItemWrapper.displayName = 'PhotoInspectionListItemWrapper';

/* Add styles for the individual items to control their width */
export const PhotoItemBase = css`
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
`;

export const StyledPhotoRequestWrapper = styled(PhotoInspectionListItemWrapper)({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  width: '100%',
});

export const StyledPhotoListItem = styled(PhotoListItem)`
  ${PhotoItemBase}
`;

export const StyledPhotoStaticListItem = styled(PhotoStaticListItem)`
  ${PhotoItemBase}
`;

export const PhotoInspectionListItem = styled.div<{
  url?: string;
  isSelected: boolean;
}>(({ url, isSelected }) => ({
  cursor: 'pointer',
  borderRadius: '6px',
  backgroundImage: `url('${url}')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  transition: 'height 0.2s',

  ...(url ? { backgroundImage: `url('${url}')` } : {}),
  [MediaQuery[T.Device.DESKTOP]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoInspectionListItem[T.Device.DESKTOP]
      : responsiveStyle.photoInspectionListItem[T.Device.DESKTOP]),
  },
  [MediaQuery[T.Device.TABLET]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoInspectionListItem[T.Device.TABLET]
      : responsiveStyle.photoInspectionListItem[T.Device.TABLET]),
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    ...(isSelected
      ? responsiveStyle.selectedPhotoInspectionListItem[T.Device.MOBILE_L]
      : responsiveStyle.photoInspectionListItem[T.Device.MOBILE_L]),
  },

  '&:hover': {
    boxShadow:
      'inset 0px 60px 30px 5px rgba(0, 0, 0, 0.5),inset 0px -60px 30px 5px rgba(0, 0, 0, 0.5)',
  },
}));
PhotoInspectionListItem.displayName = 'PhotoInspectionListItem';

export const Wrapper = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  margin: '10px',
  display: disabled ? 'none' : 'block',
  zIndex: 999,
}));

export const PhotoSvgWrapper = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  position: 'absolute',
  bottom: '0px',
  right: '0px',
  margin: '10px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  height: '20px',
  width: '20px',
  borderRadius: '3px',
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',

  '&:hover': {
    background: 'rgba(99, 99, 99, 0.7)',
    'svg path': {
      fillOpacity: 1,
    },
  },
}));

export const SvgWrapper = styled.div({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '6px',
  height: 36,
  width: 36,

  ':hover': {
    background: '#B4D0FB',
  },
});

const CheckboxWrapper = styled.div<{
  show?: boolean;
  isChecked: boolean;
}>(({ show, isChecked }) => ({
  position: 'relative',
  display: show ? 'flex' : 'none',
  width: 13,
  height: 13,
  color: '#cdcdcd',
  marginRight: '7px',
  borderRadius: '3px',
  boxShadow: `0 0 0 2px ${isChecked ? '#ffffff' : '#CDCDCD'}`,
  cursor: 'pointer',
}));

export const ExcludeImageSvgWrapper = styled(PhotoSvgWrapper)({
  position: 'absolute',
  top: '8px',
  right: '10px',
});

export const CenterCameraSvgWrapper = styled(PhotoSvgWrapper)({
  position: 'absolute',
  top: '8px',
  right: '10px',
});

export const DateWrapper = styled.div({
  fontSize: '12px',
  fontWeight: '600',
  textAlign: 'center',
  color: dsPalette.white.toString(),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  padding: '10px',
  zIndex: 3,
  transition: 'all 0.3s ease-in-out',
  userSelect: 'none',
});

export const PhotoDiv = styled.div<SelectedProps>(({ isSelected, isInspection }) => ({
  position: 'relative',
  padding: isSelected ? '8px' : '',
  background: isInspection ? '#1f4782' : '#DAE6F8',
  borderRadius: '6px',
  transition: 'padding 0.2s',

  [`${PhotoSvgWrapper}`]: {
    display: isSelected ? 'flex' : 'none',
    margin: '14px',
  },

  [`${CheckboxWrapper}`]: {
    display: isSelected ? 'flex' : 'none',
    margin: '14px',
  },

  [`${ExcludeImageSvgWrapper}`]: {
    display: 'none',
    margin: '14px',
  },

  [`${CenterCameraSvgWrapper}`]: {
    display: 'none',
  },

  [`${DateWrapper}`]: {
    bottom: isSelected ? '8px' : '0px',
    left: isSelected ? '8px' : '0px',
    right: isSelected ? '8px' : '0px',
  },

  [`&:hover ${PhotoSvgWrapper}`]: {
    display: 'flex',
    margin: '14px',
  },
  [`&:hover ${CheckboxWrapper}`]: {
    display: 'flex',
    margin: '14px',
  },
  [`&:hover ${ExcludeImageSvgWrapper}`]: {
    display: isSelected ? 'none' : 'flex',
    margin: '14px',
  },
  [`&:hover ${CenterCameraSvgWrapper}`]: {
    display: isSelected ? 'flex' : 'none',
    zIndex: 10,
  },

  [`&:hover ${DateWrapper}`]: {
    backgroundColor: 'transparent',
    opacity: 1,
  },
}));

export const StyledPhotoRequestDiv = styled(PhotoDiv)({
  gridColumn: 'span 2',
});

export const InspectionPhotoDiv = styled.div<SelectedProps>(({ isSelected }) => ({
  position: 'relative',
  padding: isSelected ? '8px' : '',
  background: '#DAE6F8',
  borderRadius: '6px',
  transition: 'padding 0.2s',
  display: 'flex',
  alignItems: 'center',

  [`${PhotoSvgWrapper}`]: {
    display: isSelected ? 'flex' : 'none',
    margin: '14px',
  },

  [`${CheckboxWrapper}`]: {
    display: isSelected ? 'flex' : 'none',
    margin: '14px',
  },

  [`&:hover ${PhotoSvgWrapper}`]: {
    display: 'flex',
    margin: '14px',
  },
  [`&:hover ${CheckboxWrapper}`]: {
    display: 'flex',
    margin: '14px',
  },
}));

const CheckedIcon = styled(CheckedSVG)({
  borderRadius: '3px',
});

const CheckboxRoot = styled.div<{
  isMonth?: boolean;
}>(({ isMonth }) => ({
  // padding: '3px',
  [`:hover ${CheckboxWrapper}`]: {
    boxShadow: `0 0 0 2px ${
      isMonth ? dsPalette.themePrimaryLightest.toString() : palette.white.toString()
    }`,
    color: isMonth ? dsPalette.themePrimaryLightest.toString() : palette.white.toString(),
  },
}));

interface CheckboxProps {
  onChange?(isChecked: boolean, event: React.MouseEvent<Element, MouseEvent>): void;
  checked: boolean;
  isMonth?: boolean;
  show?: boolean;
}
export const Checkbox: FC<CheckboxProps> = ({ onChange, checked, isMonth, show }) => {
  const [_checked, setIsChecked] = useState(false);
  const handleClick: MouseEventHandler = e => {
    e.stopPropagation();
    setIsChecked(!_checked);
    onChange?.(!_checked, e);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <CheckboxRoot onClick={handleClick} isMonth={isMonth}>
      <CheckboxWrapper show={show} isChecked={_checked}>
        {_checked && <CheckedIcon />}
      </CheckboxWrapper>
    </CheckboxRoot>
  );
};

export const MenuWrapper = styled.div<{ isDropdownOpen: boolean }>(({ isDropdownOpen }) => ({
  position: 'absolute',
  top: '4px',
  right: '4px',
  cursor: 'pointer',

  display: isDropdownOpen ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',

  height: '20px',
  width: '20px',
  borderRadius: '4px',
  backgroundColor: theme.colors.white[100],
  zIndex: 2,
  ':hover': {
    backgroundColor: theme.colors.coolGray[30],
  },
}));

MenuWrapper.displayName = 'DropdownMenuWrapper';

export const SelectWrapper = styled.div<SelectedWrapperProps>(
  ({ isSelected, slidePanelWidth }) => ({
    background: '#DAE6F8',
    borderRadius: '10px',
    display: isSelected ? 'flex' : 'none',
    alignItems: 'center',
    padding: slidePanelWidth && slidePanelWidth < SELECTED_FLEX_BOX_MIN_WIDTH ? '20px 5px' : '20px',
    marginTop: '20px',
    justifyContent: 'space-between',
    alignContent: 'center',
    '& svg path': {
      fill: dsPalette.themePrimary.toString(),
    },
  })
);

const dissolveAnimation = keyframes`
  0% {
    background-color: #E6EBEF;
  }

  100% {
    background-color: transparent;
  }
`;

export const PhotoContainer = styled.div<SelectedProps>`
  border-radius: 8px;
  padding: 13px;
  animation: ${({ isSelected }) => (isSelected ? dissolveAnimation : 'none')} 1.5s ease-out;
`;

export const PhotoRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '16px',

  [`&:hover ${CheckboxWrapper}`]: {
    display: 'flex',
  },
});

export const SelectedTextElement = styled.p({
  fontSize: '16px',
  color: '#1F4782',
});

export const FlexBoxWrapper = styled.div({
  display: 'flex',
  padding: '0px 13px',
});

export const HeaderWrapper = styled.div({
  width: '100%',
});

export const PhotoHeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const PhotoUploadButtonWrapper = styled.div({
  paddingBottom: '20px',
});

export const FlexBox = styled(FlexBoxWrapper)<SlidePanelWidth>(({ slidePanelWidth }) => ({
  gap: slidePanelWidth && slidePanelWidth < SELECTED_FLEX_BOX_MIN_WIDTH ? '15px' : '35px',
  alignItems: 'center',
  padding: slidePanelWidth && slidePanelWidth < SELECTED_FLEX_BOX_MIN_WIDTH ? 0 : '0px 13px',
}));

export const TopIconsWrapper = styled(FlexBoxWrapper)({
  gap: '4px',
  alignItems: 'center',
});

const IconWrapper = styled.div({
  position: 'relative',

  width: '40px',
  height: '40px',

  cursor: 'pointer',

  ':hover': {
    borderRadius: '50%',
    backgroundColor: dsPalette.iconHover.toString(),
  },
});

export const PreviousIconWrapper = styled(IconWrapper)({
  marginRight: '8px',
});

export const PreviousIcon = styled(PreviousSVG)({
  position: 'relative',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

export const NoDateSubText = styled.p({
  fontSize: '15px',
  fontWeight: 600,
  color: '#8F8F8F',
  padding: '4px',
});

export const LoaderWrapper = styled.div({
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TabContainer = styled(FlexBox)({
  gap: '29px',
  margin: '0 50px 0 10px',
  borderBottom: `2px solid ${palette.MapTopBar.dividerDisabled.toString()}`,
});

export const TabButton = styled.button<{ isSelected: boolean; isSurvey: boolean }>(
  ({ isSelected, isSurvey }) => ({
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    paddingBottom: '12px',
    color: isSelected
      ? dsPalette.themePrimary.toString()
      : palette.ContentsTabBar.uploadIconDisabled.toString(),

    ' ::before': {
      content: "''",
      position: 'absolute',
      width: isSurvey ? '140px' : '75px',
      height: '4px',
      marginTop: '35px',
      zIndex: '300',
      backgroundColor: isSelected ? dsPalette.themePrimary.toString() : '',
    },
  })
);

export const TabText = styled.span({
  fontSize: '20px',
  fontFamily: 'Roboto',
  fontWeight: '600',
});

export const DroneContainer = styled.div<{ url: string }>(({ url }) => ({
  borderRadius: '6px',
  background: `url('${url}')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  zIndex: 0,

  [MediaQuery[T.Device.DESKTOP]]: {
    ...responsiveStyle.photoListItem[T.Device.DESKTOP],
  },
  [MediaQuery[T.Device.TABLET]]: {
    ...responsiveStyle.photoListItem[T.Device.TABLET],
  },
  [MediaQuery[T.Device.MOBILE_L]]: {
    ...responsiveStyle.photoListItem[T.Device.MOBILE_L],
  },

  ' ::before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'block',
    backgroundColor: dsPalette.themePrimary.alpha(0.85).toString(),
    borderRadius: '6px',
  },
  ' svg': {
    zIndex: 1,
  },
}));

export const VideoDurationText = styled.span({
  color: dsPalette.white.toString(),
  textAlign: 'center',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: '600',
  paddingTop: '4px',
  paddingBottom: '4px',
  zIndex: 1,
});

export const DroneText = styled.span({
  color: dsPalette.white.toString(),
  textAlign: 'center',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: '600',
  paddingTop: '16px',
  paddingBottom: '4px',
  zIndex: 1,
});

export const CountText = styled.span({
  color: 'rgba(255, 255, 255, 0.70)',
  textAlign: 'center',
  fontSize: '10px',
  fontFamily: 'Roboto',
  zIndex: 1,
});

export const VideoDurationWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  top: '10px',
  position: 'absolute',
  right: '10px',
});

export const ThumbNailWrapper = styled.div<{ isSelected?: boolean }>(({ isSelected }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: !isSelected ? '100%' : 'calc(100% - 16px)',
  height: !isSelected ? '100%' : 'calc(100% - 16px)',
  flexDirection: 'column',
  borderRadius: '6px',
  // background: palette.black.alpha(0.4).toString(),
  // backdropFilter: 'blur(1.5px)',
}));

export const InfoText = styled.span<{ color: CSSObject['color']; underline?: boolean }>(
  ({ color, underline }) => ({
    color: color,
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    textDecoration: underline ? 'underline' : 'none',
    cursor: underline ? 'pointer' : 'auto',
    wordWrap: 'break-word',
  })
);

export const InfoContainer = styled.div({
  display: 'flex',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  background: dsPalette.themePrimary.alpha(0.5).toString(),
  backdropFilter: 'blur(1.5px)',
  width: '100%',
  boxSizing: 'border-box',
  position: 'absolute',
  bottom: 0,
});

export const VideoWrapper = styled.video<{ isProcessing: boolean }>(({ isProcessing }) => ({
  display: isProcessing ? 'block' : 'block',
  width: '100%',
  borderRadius: '6px',
}));
