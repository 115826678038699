import Tippy from '@tippyjs/react';
import Color from 'color';
import React, { FC, useState } from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars-2';
import styled, { CSSObject } from 'styled-components';

import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { useL10n } from '^/hooks/useL10n';

import CloseSVG from '^/assets/icons/toast-close.svg';
import * as T from '^/types';
import Text from './text';

const Root = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '12px',
  color: palette.FlightSchedule.sidebarItemFont.toString(),
});

const Title = styled.div({
  fontSize: '14px',
  fontWeight: 'bold',

  marginBottom: '16px',
  color: dsPalette.title.toString(),
});

const MainArea = styled.div({
  boxSizing: 'border-box',
  width: '100%',
});

const MainButton = styled.button<{ hasError?: boolean }>(({ hasError }) => ({
  all: 'unset',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '6.5px',

  boxSizing: 'border-box',
  width: '100%',
  height: '37px',
  paddingLeft: '9px',
  paddingRight: '9px',

  backgroundColor: hasError
    ? palette.DDMInput.error.alpha(0.05).toString()
    : palette.white.toString(),
  border: `1px solid ${(hasError ? palette.DDMInput.error : palette.border).toString()}`,
  borderRadius: '5px',

  cursor: 'pointer',
}));

const ClosedButtonWrapper = styled.div({
  padding: '5px 0 5px 5px',
  color: '#e03a3a',
});

const ValueWrapper = styled.div({
  display: 'flex',
  gap: ' 10px',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const FlightScheduleTimeUIButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  columnGap: '4px',
  padding: '5px',
});

const FlightScheduleTimeUIButton = styled.button({
  all: 'unset',
  padding: '4px 12px',
  color: dsPalette.themePrimary.toString(),
  fontWeight: 'bold',
  fontSize: '12px',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: new Color(
      `rgba(${dsPalette.themePrimary.red()}, ${dsPalette.themePrimary.green()}, ${dsPalette.themePrimary.blue()}, 0.15)`
    ).toString(),
  },
});

const SingleTimeFieldItem = styled.p<{ isSelected?: boolean }>(({ isSelected }) => ({
  fontSize: '12px',
  padding: '5px',
  cursor: 'pointer',

  backgroundColor: isSelected ? `${dsPalette.themePrimary.toString()}` : '',
  color: isSelected ? palette.white.toString() : '',
}));

const TimeFieldItemsContainer = styled.div({
  backgroundColor: palette.white.toString(),
  // padding: '21px 17px 26px 17px',
  border: `1px solid ${palette.border.toString()}`,
  borderRadius: '5px',
});

const TimeFieldItemsWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  backgroundColor: palette.white.toString(),
  // padding: '21px 17px 26px 17px',
  borderBottom: `1px solid ${palette.border.toString()}`,
});

const scrollBarViewStyle: CSSObject = {
  height: '100%',
  overflowX: 'hidden',
  paddingBottom: '2.5px',
  touchAction: 'none',
};

const ScrollbarsWrapper = styled(Scrollbars)<ScrollbarProps>({
  minHeight: '160px',
  borderRight: `1px solid ${palette.border.toString()}`,
});
const ScrollBarView: FC<{ style: CSSObject } & any> = ({ style, ...others }) => (
  <div className="ctxsort-scroller" {...others} style={{ ...style, ...scrollBarViewStyle }} />
);

interface TimerProps {
  selectedTime: TTimerObject;
  onCancel(): void;
  onConfirm(finalizedValue: string): void;
}

type TSelectedExecuteTime = T.RawFlightScheduleFields['selectedExecuteTime'];

interface FlightScheduleTimerPickerProps {
  selectedTime: TSelectedExecuteTime;
  onResetTime(): void;
  onSelectedTimeChange(newSelectedTime: TSelectedExecuteTime): void;
  hasError: boolean;
  isStartTime?: boolean;
}

interface TTimerObject {
  hrs: string;
  minutes: string;
  seconds: string;
  unit: string;
}

const hrOptions = [...Array(12).keys()].map(i => String(i + 1).padStart(2, '0'));
const minuteOptions = [...Array(60).keys()].map(i => String(i + 1).padStart(2, '0'));
const secondOptions = [...Array(60).keys()].map(i => String(i + 1).padStart(2, '0'));
const unitOptions = ['AM', 'PM'];

const initialTimerObject: TTimerObject = {
  hrs: '',
  minutes: '',
  seconds: '',
  unit: '',
};

const getProperTimeFormat = (providedTime: string | undefined): TTimerObject => {
  if (!providedTime) {
    return initialTimerObject;
  }
  const [time, period] = providedTime.split(' ');
  const [hours, minutes, seconds] = time.split(':');

  return {
    hrs: hours,
    minutes: minutes,
    seconds: seconds,
    unit: period,
  };
};

const TimerComponent = ({ selectedTime, onCancel, onConfirm }: TimerProps) => {
  const [timerValue, setTimerValue] = useState<TTimerObject>(selectedTime);
  const [l10n] = useL10n();

  const handleOnCancel = () => {
    onCancel();
  };

  const handleOnConfirm = () => {
    if (!timerValue.hrs || !timerValue.minutes || !timerValue.seconds || !timerValue.unit) {
      return;
    }

    const finalizedValue = `${timerValue.hrs}:${timerValue.minutes}:${timerValue.seconds} ${timerValue.unit}`;
    onConfirm(finalizedValue);
  };

  const upTimeTimerValue = (timerKey: keyof TTimerObject, timerNewValue: string) => {
    setTimerValue(prev => ({
      ...prev,
      [timerKey]: timerNewValue,
    }));
  };

  return (
    <TimeFieldItemsContainer>
      <TimeFieldItemsWrapper>
        <ScrollbarsWrapper renderView={ScrollBarView} autoHide={true}>
          {hrOptions.map(single => (
            <SingleTimeFieldItem
              key={single}
              isSelected={single === timerValue.hrs}
              onClick={() => {
                upTimeTimerValue('hrs', single);
              }}
            >
              {single}
            </SingleTimeFieldItem>
          ))}
        </ScrollbarsWrapper>
        <ScrollbarsWrapper renderView={ScrollBarView} autoHide={true}>
          {minuteOptions.map(single => (
            <SingleTimeFieldItem
              key={single}
              isSelected={single === timerValue.minutes}
              onClick={() => {
                upTimeTimerValue('minutes', single);
              }}
            >
              {single}
            </SingleTimeFieldItem>
          ))}
        </ScrollbarsWrapper>
        <ScrollbarsWrapper renderView={ScrollBarView} autoHide={true}>
          {secondOptions.map(single => (
            <SingleTimeFieldItem
              key={single}
              isSelected={single === timerValue.seconds}
              onClick={() => {
                upTimeTimerValue('seconds', single);
              }}
            >
              {single}
            </SingleTimeFieldItem>
          ))}
        </ScrollbarsWrapper>
        <ScrollbarsWrapper renderView={ScrollBarView} autoHide={true}>
          {unitOptions.map(single => (
            <SingleTimeFieldItem
              key={single}
              isSelected={single === timerValue.unit}
              onClick={() => {
                upTimeTimerValue('unit', single);
              }}
            >
              {single}
            </SingleTimeFieldItem>
          ))}
        </ScrollbarsWrapper>
      </TimeFieldItemsWrapper>

      <FlightScheduleTimeUIButtonWrapper>
        <FlightScheduleTimeUIButton onClick={handleOnCancel}>
          {l10n(Text.cancel)}
        </FlightScheduleTimeUIButton>
        <FlightScheduleTimeUIButton onClick={handleOnConfirm}>
          {l10n(Text.confirm)}
        </FlightScheduleTimeUIButton>
      </FlightScheduleTimeUIButtonWrapper>
    </TimeFieldItemsContainer>
  );
};

const FlightScheduleTimePicker = (props: FlightScheduleTimerPickerProps) => {
  const [isTimerShown, setIsTimerShown] = useState<boolean>(false);
  const [l10n] = useL10n();

  const { selectedTime, onResetTime, hasError, onSelectedTimeChange, isStartTime = true } = props;

  const handleOnResetClick = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    onResetTime();
  };

  const handleOnTimerCancel = () => {
    setIsTimerShown(false);
  };

  const handleOnTimerConfirm = (obtainedValue: string) => {
    onSelectedTimeChange(obtainedValue);
    setIsTimerShown(false);
  };

  const valueViewer = selectedTime
    ? selectedTime
    : isStartTime
    ? l10n(Text.startTime)
    : l10n(Text.endTime);

  const updatedTimeFormat: TTimerObject = getProperTimeFormat(selectedTime);

  const timerArea = isTimerShown ? (
    <TimerComponent
      selectedTime={updatedTimeFormat}
      onCancel={handleOnTimerCancel}
      onConfirm={handleOnTimerConfirm}
    />
  ) : null;

  const rawCloseButton =
    selectedTime && !isTimerShown ? (
      <Tippy theme="angelsw" arrow={false} placement="bottom" content="Reset Time">
        <ClosedButtonWrapper onClick={handleOnResetClick}>
          <CloseSVG />
        </ClosedButtonWrapper>
      </Tippy>
    ) : null;

  return (
    <Root>
      <Title>{isStartTime ? l10n(Text.startTime) : l10n(Text.endTime)}</Title>
      <MainArea>
        <MainButton
          onClick={() => {
            setIsTimerShown(!isTimerShown);
          }}
          hasError={hasError}
        >
          <ValueWrapper>{valueViewer}</ValueWrapper>
          {rawCloseButton}
        </MainButton>
        {timerArea}
      </MainArea>
    </Root>
  );
};

export default FlightScheduleTimePicker;
