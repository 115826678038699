import Tippy from '@tippyjs/react';
import React, { FC, ReactNode, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import FileExportSvg from '^/assets/icons/contents-list/dxf-export.svg';
import GroupAddDisabledSvg from '^/assets/icons/contents-list/group-add-disabled.svg';
import GroupAddLockedDisabledSvg from '^/assets/icons/contents-list/group-add-locked-disabled.svg';
import GroupAddLockedSvg from '^/assets/icons/contents-list/group-add-locked.svg';
import GroupAddSvg from '^/assets/icons/contents-list/group-add.svg';
import PartialCheckSvg from '^/assets/icons/contents-list/partial-check.svg';
import CopyFolderIcon from '^/assets/icons/copy-folder-filled.svg';
import DeleteFolderIcon from '^/assets/icons/delete-filled.svg';
import palette from '^/constants/palette';
import { UseL10n, useAuthHeader, useIsRoleX, useL10n, useRole } from '^/hooks';
import { useESSContents } from '^/hooks/useESSContents';
import { AuthHeader } from '^/store/duck/API';
import { AddNewGroup, ChangeSelectedGroupId, CopySelectedGroup } from '^/store/duck/Groups';
import { OpenContentPagePopup } from '^/store/duck/Pages/Content';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useGroupStore } from '^/store/zustand/groups/groupStore';
import * as T from '^/types';
import { isContentPersonal } from '^/utilities/content-util';
import {
  isAllowExportMultipleElevation,
  isRoleAdmin,
  isRoleDemo,
  isRolePilot,
  isRoleViewer,
} from '^/utilities/role-permission-check';
import { getAllChildren } from '^/utilities/state-util';
import { updateContentSelectedAtInServer } from '^/utilities/updateContentSelectedAtInServer';
import Text from './text';

export const enum GroupedContentsListHeaderDataSortKeys {
  PROJECT = '1',
  SCREEN = '0',
}

const Root = styled.section<{ isPersonalGroups: boolean }>(({ isPersonalGroups }) => ({
  position: 'sticky',
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  top: isPersonalGroups ? '0' : '49px',
  paddingTop: '7px',
  paddingBottom: '7px',
  fontSize: '14px',
  paddingLeft: '18.5px',
  paddingRight: '12.5px',
  backgroundColor: palette.SideBar.ContentslistBackground.toString(),
  color: palette.ContentsList.groupListHeaderTextGray.toString(),
}));
Root.displayName = 'GroupedContentsListHeader';

const TitleWrapper = styled.div`
  flex: 1;
  padding: 7px 0;
`;
TitleWrapper.displayName = 'TitleWrapper';

const SvgWrapper = styled.div<{ isDisabled: boolean }>(({ isDisabled }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30px',
  height: '30px',
  cursor: isDisabled ? 'default' : 'pointer',
  borderRadius: '3px',
  ':hover': isDisabled
    ? undefined
    : {
        backgroundColor: palette.GroupedContentsHeader.iconBackgroundDisabled.toString(),
      },

  '& > svg': {
    fill: isDisabled ? palette.iconDisabled.toString() : palette.hoverUploadIcon.toString(),
  },
}));
SvgWrapper.displayName = 'SvgWrapper';

const GroupCheckboxRoot = styled.div({
  marginRight: '9px',
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#CED7E5',
  },
});
GroupCheckboxRoot.displayName = 'GroupCheckboxRoot';

const ExportSvgWrapper: FC<
  Readonly<{
    isDisabled: boolean;
    onClick(): void;
    'data-ddm-track-action': string;
    'data-ddm-track-label': string;
  }>
> = props => {
  const [l10n]: UseL10n = useL10n();

  return (
    <Tippy
      offset={T.TIPPY_OFFSET}
      theme="angelsw"
      placement="top"
      arrow={false}
      content={l10n(Text.tooltipExportText)}
      disabled={props.isDisabled}
    >
      <SvgWrapper {...props}>
        <FileExportSvg />
      </SvgWrapper>
    </Tippy>
  );
};

const GroupAddSvgWrapper: FC<
  Readonly<{
    shouldShowLockIcon: boolean;
    isDisabled: boolean;
    onClick(): void;
    'data-ddm-track-action': string;
    'data-ddm-track-label': string;
  }>
> = props => {
  const [l10n]: UseL10n = useL10n();

  const groupAddSvg: ReactNode = props.shouldShowLockIcon ? (
    props.isDisabled ? (
      <GroupAddLockedDisabledSvg />
    ) : (
      <GroupAddLockedSvg />
    )
  ) : props.isDisabled ? (
    <GroupAddDisabledSvg />
  ) : (
    <GroupAddSvg />
  );

  return (
    <Tippy
      offset={T.TIPPY_OFFSET}
      theme="angelsw"
      placement="top"
      arrow={false}
      content={l10n(Text.tooltipAddFolderText)}
      disabled={props.isDisabled}
    >
      <div style={{ transform: props.shouldShowLockIcon ? 'translateX(2px)' : undefined }}>
        <SvgWrapper {...props}>{groupAddSvg}</SvgWrapper>
      </div>
    </Tippy>
  );
};

type Props = Readonly<{
  isPersonalGroups: boolean;
  rootGroupIds: Array<T.Content['id']>;
}>;

export const GroupedContentsListHeader: FC<Props> = memo(({ isPersonalGroups, rootGroupIds }) => {
  const { createESSGroupContent, duplicateESSGroupContent } = useESSContents();
  const {
    essContents,
    essContentGroupTree,
    selectedESSGroupIdByTab,
    setDeletingESSContentId,
    updateSelectedAtInESSContents,
  } = useESSContentsStore(s => ({
    essContents: s.essContents,
    essContentGroupTree: s.essContentGroupTree,
    selectedESSGroupIdByTab: s.selectedESSGroupIdByTab,
    setDeletingESSContentId: s.setDeletingESSContentId,
    updateSelectedAtInESSContents: s.updateSelectedAtInESSContents,
  }));
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();
  const authHeader: AuthHeader | undefined = useAuthHeader();
  const role = useRole();
  const byId = useContentsStore(s => s.contents.byId);
  const updateContentsSelectedAtInStore = useContentsStore(s => s.updateContentsSelectedAtInStore);
  const mainIdsByGroup = useGroupStore(s => s.tree.idsByGroup);
  const selectedGroupIdByTab = useGroupStore(s => s.selectedGroupIdByTab);

  const sidebarTab: T.ContentPageTabType = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
  const isESS = sidebarTab === T.ContentPageTabType.ESS;
  const contentByIds = isESS ? essContents : byId;
  const idsByGroup = isESS ? essContentGroupTree : mainIdsByGroup;

  const selectedGroupId = isESS
    ? selectedESSGroupIdByTab[T.ContentPageTabType.ESS]
    : selectedGroupIdByTab[sidebarTab];

  const children = selectedGroupId
    ? getAllChildren([selectedGroupId], idsByGroup, contentByIds)
    : [];

  const allChildren = getAllChildren(rootGroupIds, idsByGroup, contentByIds);

  const selectedGroup = contentByIds[selectedGroupId ?? NaN];
  const printingContentId = useSelector((s: T.State) => s.Pages.Contents.printingContentId);
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);

  const isSelectedGroupPersonal = isContentPersonal(selectedGroup);
  const isDisabled: boolean = Boolean(printingContentId);
  const isViewer: boolean = useIsRoleX(isRoleViewer);
  const isPilot: boolean = useIsRoleX(isRolePilot);
  const isAdmin: boolean = useIsRoleX(isRoleAdmin);
  const isDemo: boolean = useIsRoleX(isRoleDemo);

  const isGroupSelected =
    selectedGroupId !== undefined && isSelectedGroupPersonal === isPersonalGroups;
  const isGroupChecked =
    selectedGroupId !== undefined
      ? isESS
        ? essContents[selectedGroupId]?.config?.selectedAt
        : byId[selectedGroupId]?.config?.selectedAt
      : false;
  const isPartiallyChecked = Boolean(children.filter(content => content.config?.selectedAt).length);
  const isSomeContentChecked = Boolean(
    allChildren.filter(content => content.config?.selectedAt).length
  );

  function handleExport() {
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.EXPORT_START }));
  }

  const addNewGroup: () => void = useCallback(() => {
    if (printingContentId) {
      return;
    }
    // the user who has a role as viewer is not allowed to create a new content
    if (isViewer) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));

      return;
    }

    // the user who has a role as demo is not allowed to create a new content in Overlay
    if (isDemo && sidebarTab === T.ContentPageTabType.OVERLAY) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));

      return;
    }

    // Avoid lagging behind the previously selected group by clearing them first.
    if (isESS) {
      void createESSGroupContent({
        parentGroupId:
          selectedGroup?.isPersonal === isPersonalGroups
            ? selectedGroup?.groupId || selectedGroupId
            : undefined,
        isPersonal: isPersonalGroups,
      });
    } else {
      dispatch(
        AddNewGroup({
          isPersonal: isPersonalGroups,
          parentGroupId:
            selectedGroup?.isPersonal === isPersonalGroups
              ? selectedGroup?.groupId || selectedGroupId
              : undefined,
        })
      );
    }
  }, [printingContentId, isViewer, selectedGroupId, selectedGroup]);

  const copySelectedGroup: () => void = useCallback(() => {
    if (printingContentId) {
      return;
    }
    // the user who has a role as viewer is not allowed to copy a content
    if (isViewer) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));

      return;
    }

    // Avoid lagging behind the previously selected group by clearing them first.
    dispatch(ChangeSelectedGroupId({ tab: sidebarTab }));

    if (isESS) {
      void duplicateESSGroupContent({
        isPersonal: isPersonalGroups,
        selectedGroupId,
        isUndoable: true,
      });
    } else {
      dispatch(
        CopySelectedGroup({
          isPinned: isPersonalGroups,
          selectedGroupId,
          isPersonal: isPersonalGroups,
        })
      );
    }
  }, [
    printingContentId,
    isViewer,
    selectedGroupId,
    isPersonalGroups,
    sidebarTab,
    essContentGroupTree,
  ]);

  const deleteGroupConfirm: () => void = useCallback(() => {
    if (printingContentId) {
      return;
    }
    // only the user who has a role as pilot or admin of project can delete the group
    if (isPilot || isAdmin) {
      if (isESS) {
        setDeletingESSContentId(selectedGroupId);
      }
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.DELETE_GROUP }));

      return;
    }
    dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));
  }, [printingContentId, selectedGroupId, isPilot, isAdmin]);

  const deSelectAllRootGroups = async () => {
    const outdatedContents = allChildren.filter(child => child.config?.selectedAt);
    const request = updateContentSelectedAtInServer(outdatedContents, authHeader, projectId);
    updateSelectedAtInESSContents(
      outdatedContents.map(c => c.id),
      undefined
    );
    updateContentsSelectedAtInStore(
      outdatedContents.map(c => c.id),
      undefined
    );
    await request;
  };

  const copyGroupSvg: ReactNode = useMemo(
    () =>
      isGroupSelected && sidebarTab === T.ContentPageTabType.ESS ? (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={l10n(Text.tooltipCopyText)}
        >
          <SvgWrapper onClick={copySelectedGroup} isDisabled={isDisabled}>
            <CopyFolderIcon />
          </SvgWrapper>
        </Tippy>
      ) : null,
    [isGroupSelected, sidebarTab, copySelectedGroup, isDisabled]
  );

  const deleteGroupSvg: ReactNode = useMemo(
    () =>
      isGroupSelected && (isGroupChecked || isPartiallyChecked) ? (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={l10n(Text.tooltipDeleteText)}
        >
          <SvgWrapper onClick={deleteGroupConfirm} isDisabled={isDisabled}>
            <DeleteFolderIcon />
          </SvgWrapper>
        </Tippy>
      ) : null,
    [isGroupSelected, isGroupChecked, isPartiallyChecked, deleteGroupConfirm, isDisabled]
  );

  const titleText: string =
    sidebarTab !== T.ContentPageTabType.ISSUE
      ? isPersonalGroups
        ? l10n(Text.groupListHeader.personal[sidebarTab])
        : l10n(Text.groupListHeader.open[sidebarTab])
      : '';

  const dataCtxSortKey: GroupedContentsListHeaderDataSortKeys = isPersonalGroups
    ? GroupedContentsListHeaderDataSortKeys.PROJECT
    : GroupedContentsListHeaderDataSortKeys.SCREEN;

  return (
    <Root
      isPersonalGroups={isPersonalGroups}
      data-ctxsort="GroupedContentsListHeader"
      data-ctxsort-key={dataCtxSortKey}
      data-testid={isPersonalGroups ? 'category-personal' : 'category-open'}
    >
      {isSomeContentChecked && sidebarTab !== T.ContentPageTabType.MAP && (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="bottom"
          arrow={false}
          content={l10n(Text.tooltipUnselectAllText)}
        >
          <GroupCheckboxRoot onClick={deSelectAllRootGroups}>
            <PartialCheckSvg />
          </GroupCheckboxRoot>
        </Tippy>
      )}
      <TitleWrapper>{titleText}</TitleWrapper>
      {sidebarTab === T.ContentPageTabType.MEASUREMENT &&
      !isPersonalGroups &&
      isAllowExportMultipleElevation(role) ? (
        <ExportSvgWrapper
          isDisabled={false}
          onClick={handleExport}
          data-ddm-track-action={T.TrackActions.MEASUREMENT_EXPORT}
          data-ddm-track-label={T.TrackLabels.BTN_MEASUREMENT_EXPORT}
        />
      ) : null}
      {copyGroupSvg}
      {deleteGroupSvg}

      <GroupAddSvgWrapper
        shouldShowLockIcon={isPersonalGroups}
        onClick={addNewGroup}
        isDisabled={isDisabled}
        data-ddm-track-action={T.TrackActions.CONTENT_GROUP}
        data-ddm-track-label={`${T.TrackLabels.BTN_CREATE_CONTENT_GROUP}-${sidebarTab}`}
      />
    </Root>
  );
});
