import React, { FC, memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import { Fallback } from './fallback';
import Text from './text';
import { ContentsListItem, HorizontalDivider } from '^/components/atoms/ContentsListItem';
import { UseL10n, useL10n } from '^/hooks';
import * as T from '^/types';
import { withErrorBoundary } from '^/utilities/withErrorBoundary';
import { Basic } from './TerrainContent/basic';
import styled from 'styled-components';
import dsPalette from '^/constants/ds-palette';
// import ReloadSVG from '^/assets/icons/contents-list/reload.svg';
import ToggleSlider from '^/components/atoms/ToggleSlider';
import { useThreeStore } from '^/components/three/ThreeStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';

const TerrainEditingMethodWrapper = styled.div({
  width: '-webkit-fill-available',
  padding: '0px 0px',
});
const Balloon2 = styled.div({
  boxSizing: 'border-box',
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const Balloon3 = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});
const ToggledText = styled.p({
  fontSize: '12px',
  lineHeight: '17px',
  color: dsPalette.title.toString(),
});
// const ReloadContainer = styled.div({
//   width: '14px',
//   height: '14px',
//   display: 'flex',
//   alignItems: 'flex-end',
// });
export interface Props {
  readonly content: T.FlattenContent;
  readonly isPinned?: boolean;
}
const ContentsListTerrainFlatItemRaw: FC<Props> = ({ content, isPinned = false }) => {
  const [l10n]: UseL10n = useL10n();
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const projectUnit = useSelector((s: T.State) => s.SharedContents.projectUnit);
  const isVisualizeVolumeToggled: boolean = Boolean(content.config?.isVisualizeVolumeToggled);
  const viewer = useThreeStore(s => s.viewer);
  const updateContentConfig = useContentsStore(s => s.updateContentConfig);

  if (!projectUnit && !projectId) {
    throw new Error(' No Project Id in Pages.Contents.projectId');
  }
  const handleToggleVisulaizeVolume: () => void = () => {
    if (!viewer) {
      return;
    }
    if (viewer.entities.terrainFlatEntity) {
      viewer.entities.terrainFlatEntity.editor.detached();
    }
    updateContentConfig(content.id, {
      ...content.config,
      type: content.type,
      isVisualizeVolumeToggled: !isVisualizeVolumeToggled,
    });
  };
  // const reloadSurface = (e: any) => {
  //   e.preventDefault();
  //   if (!content.config?.isClampedToggled) {
  //     return;
  //   }
  //   if (viewer?.tileset?.children.length === 0) {
  //     return;
  //   }

  //   if (viewer) {
  //     const object = viewer.entities.dimensionEntity?.getObjectByName(`dimension-${content.id}`);
  //     if (object) {
  //       const objectVolume = object as TerrainFlatObject;
  //       const mergeMesh = mergeTileset(viewer.tileset!);
  //       objectVolume.intersectMesh(mergeMesh, objectVolume.boundary.pointerArray);
  //       objectVolume.generateFlatVolume();
  //       objectVolume.visibleFlatVolume(true);
  //     }
  //   }
  // };
  const visualizeToggleButton: ReactNode = viewer ? (
    <>
      <HorizontalDivider />
      <Balloon2>
        <Balloon3>
          <ToggledText>{l10n(Text.visualize)}</ToggledText>
        </Balloon3>

        <ToggleSlider
          onClick={handleToggleVisulaizeVolume}
          enabled={isVisualizeVolumeToggled}
          data-ddm-track-action={T.TrackActions.CONTENT_ITEM}
          data-ddm-track-label={`${T.TrackLabels.BTN_TOGGLE_CLAMPED_VOLUME}-${
            isVisualizeVolumeToggled ? 'off' : 'on'
          }`}
        />
      </Balloon2>
    </>
  ) : null;
  return (
    <ContentsListItem
      isPinned={isPinned}
      className={CANCELLABLE_CLASS_NAME}
      content={content}
      firstBalloonTitle={l10n(Text.firstBalloonTitle)}
    >
      <HorizontalDivider />
      <TerrainEditingMethodWrapper>
        <Basic content={content} />
      </TerrainEditingMethodWrapper>
      {visualizeToggleButton}
    </ContentsListItem>
  );
};
const ContentsListFlattenMapItem = memo<FC<Props>>(ContentsListTerrainFlatItemRaw);
export default withErrorBoundary(ContentsListFlattenMapItem)(Fallback);
