import { contentTexts } from '^/constants/defaultText';
import * as T from '^/types';

export const filterContentTexts = {
  ...contentTexts,
  [T.ContentType.GROUP]: {
    [T.Language.KO_KR]: '그룹',
    [T.Language.EN_US]: 'Group',
  },
  [T.ContentType.ESS_MODEL]: {
    [T.Language.KO_KR]: '기본 장비',
    [T.Language.EN_US]: 'System Library',
  },
  all: {
    [T.Language.KO_KR]: '전체 선택',
    [T.Language.EN_US]: 'Select All',
  },
};

export default {
  resultText: (text: string, count: number) => ({
    [T.Language.KO_KR]: `"${text}" 에 대해 ${count} 건이 검색되었습니다`,
    [T.Language.EN_US]: `${count} results found for "${text}"`,
  }),
};

export const placeholderText: Record<T.ContentsListType, Record<T.Language, string>> = {
  [T.ContentsListType.PERSONAL_MEASUREMENT]: {
    [T.Language.KO_KR]: '비공개 측정 검색',
    [T.Language.EN_US]: 'Search personal measurements',
  },
  [T.ContentsListType.OPEN_MEASUREMENT]: {
    [T.Language.KO_KR]: '공개 측정 검색',
    [T.Language.EN_US]: 'Search open measurements',
  },
  [T.ContentsListType.PERSONAL_OVERLAY]: {
    [T.Language.KO_KR]: '비공개 오버레이 검색',
    [T.Language.EN_US]: 'Search personal overlays',
  },
  [T.ContentsListType.OPEN_OVERLAY]: {
    [T.Language.KO_KR]: '공개 오버레이 검색',
    [T.Language.EN_US]: 'Search open overlays',
  },
  [T.ContentsListType.PERSONAL_ESS]: {
    [T.Language.KO_KR]: '비공개 안전 자산 검색',
    [T.Language.EN_US]: 'Search personal logistics',
  },
  [T.ContentsListType.OPEN_ESS]: {
    [T.Language.KO_KR]: '공개 안전 자산 검색',
    [T.Language.EN_US]: 'Search open logistics',
  },
  [T.ContentsListType.OPEN_PRESENTATION]: {
    [T.Language.KO_KR]: '검색 프레젠테이션 및 슬라이드',
    [T.Language.EN_US]: 'Search Presentations and slides',
  },
  [T.ContentsListType.OPEN_TERRAIN]: {
    [T.Language.KO_KR]: '지형 검색',
    [T.Language.EN_US]: 'Search terrain',
  },
};
