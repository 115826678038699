import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import * as T from '^/types';

interface ServiceTabState {
  selectedServiceTab?: T.ContentPageServiceTabType;
}

interface ServiceTabActions {
  setSelectedServiceTab(selectedServiceTab: T.ContentPageServiceTabType): void;
}

const serviceTabInitialState: ServiceTabState = {
  selectedServiceTab: undefined,
};
type ServiceTabStore = ServiceTabState & ServiceTabActions;

export const useServiceTabStore = create<ServiceTabStore>()(
  devtools(set => ({
    ...serviceTabInitialState,
    setSelectedServiceTab(selectedServiceTab) {
      set({ selectedServiceTab });
    },
  }))
);
