import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';

export interface ExifrParsedImage {
  id: number;
  takenAt: Date;
  latitude: number;
  longitude: number;
  originalFilename: string;
}

interface ExifrParsedState {
  imagesWithLocation: ExifrParsedImage[];
  progressPercent: number;
  renderProgress: boolean;
}

interface ExifrParsedAction {
  setParsedPhotos(imagesWithLocation: ExifrParsedImage[]): void;
  setProgressPercent(progressPercent: number): void;
  setRenderProgress(renderProgress: boolean): void;
}

const initialState: ExifrParsedState = {
  imagesWithLocation: [],
  progressPercent: 0,
  renderProgress: false,
};

type ExifrParsedStore = ExifrParsedState & ExifrParsedAction;

export const exifrParsedStore = createStore<ExifrParsedStore>()(
  devtools(set => ({
    ...initialState,
    setParsedPhotos(imagesWithLocation) {
      set({ imagesWithLocation });
    },
    setProgressPercent(progressPercent) {
      set({ progressPercent });
    },
    setRenderProgress(renderProgress) {
      set({ renderProgress });
    },
    reset() {
      set(initialState);
    },
  }))
);

export function useExifrPhotosStore(): ExifrParsedStore;
export function useExifrPhotosStore<T>(selector: (state: ExifrParsedStore) => T): T;
export function useExifrPhotosStore<T>(selector?: (state: ExifrParsedStore) => T) {
  return useStore(exifrParsedStore, selector!);
}
