import React, { ComponentType, FC } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

export function withErrorBoundary<Props = any>(
  Component: ComponentType<Props>
): (Fallback: ComponentType<any>) => FC<Props> {
  return Fallback =>
    ({ ...props }: any) =>
      (
        <ErrorBoundary fallback={<Fallback {...props} />}>
          <Component {...props} />
        </ErrorBoundary>
      );
}
