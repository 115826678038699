import Tippy from '@tippyjs/react';
import React, { FC, memo, SyntheticEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import PhotoCameraSvg from '^/assets/icons/photo/photo-camera.svg';
import { defaultToastInfoOption, UseL10n, useL10n, useToast, UseToast } from '^/hooks';
import {
  SetCurrentPhotoId,
  SetHoveredPhotoId,
  SetHoveredVideoId,
  SetPhotoOnMapId,
  SetSelectedMedia,
  SetVideoOnMapId,
} from '^/store/duck/Photos';
import * as T from '^/types';
import * as THREE from 'three';
import {
  Checkbox,
  InfoContainer,
  InfoText,
  PhotoInspectionListItemWrapper,
  PhotoListItem,
  PhotoSvgWrapper,
  ThumbNailWrapper,
  VideoDurationText,
  VideoDurationWrapper,
  VideoWrapper,
  Wrapper,
} from '../PhotoList/style';
import Text from '../PhotoList/text';

import PlayIcon from '^/assets/icons/photo/video.svg';
import { useThreePhotoAlbumStore } from '^/components/three/Lib/Store';
import { PhotoAlbumLocationObject, PhotoAlbumObject } from '^/components/three/ThreeObjects/Photo';
import { useThreeStore } from '^/components/three/ThreeStore';
import { useIsInView } from '^/hooks/useIsInView';
import { ChangeTwoDDisplayCenter } from '^/store/duck/Pages';
import { isImageGeoReferenced } from '^/utilities/image';
import { formatFullTime, isVideo } from '../PhotoList/util';
import {
  DefaultFlightVideoElement,
  DefaultImageContainer,
  VideoProcessingText,
} from '../PhotoViewer/style';

interface PhotoItemProps {
  isSelected: boolean;
  photoDate: T.Photo;
}

const RawPhotoItem: FC<PhotoItemProps> = memo(({ isSelected, photoDate }) => {
  const { selectedMedia } = useSelector((s: T.State) => s.Photos);
  const dispatch: Dispatch = useDispatch();
  const [l10n]: UseL10n = useL10n();

  const [ref, isVisible] = useIsInView<HTMLDivElement>();
  const toast: UseToast = useToast();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState('');
  const viewer = useThreeStore(s => s.viewer);
  const cameraControls = useThreeStore(s => s.cameraControls);
  const runtime = useThreeStore(s => s.runtime);
  const isPhotoProcessing =
    photoDate.status === T.PhotoProcessStatus.PENDING ||
    photoDate.status === T.PhotoProcessStatus.PROCESSING;
  const setSelectedPhotoAlbumIdNew = useThreePhotoAlbumStore(s => s.setSelectedPhotoAlbumIdNew);

  const handleVideoLoad = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const videoDuration = formatFullTime(videoElement.duration);
      setDuration(videoDuration);
    }
  };

  const handlePhotoListItemClick: (photoId: T.Photo['id']) => () => void = photoId => () => {
    if (isImageGeoReferenced(photoDate) && viewer) {
      if (selectedMedia.some(media => media.contentId === photoDate.id)) {
        photoAlbumAnimation();
      } else {
        const temp = isSelected
          ? selectedMedia.filter(media => media.contentId !== photoDate.id)
          : [...selectedMedia, { type: photoDate.photoType ?? null, contentId: photoDate.id }];
        dispatch(SetSelectedMedia({ selectedMedia: temp }));
        setTimeout(() => {
          photoAlbumAnimation();
        }, 500);
      }
    } else {
      dispatch(SetCurrentPhotoId({ photoId }));
    }
  };

  const phototooltipContent = () =>
    photoDate.latitude && photoDate.longitude
      ? l10n(Text.photo.viewTooltip)
      : l10n(Text.photo.noLocationTooltip);

  const handleSelectedHoveredItems: (photo: T.Photo, isHovered: boolean) => () => void =
    (photo, isHovered) => () => {
      if (isSelected && isHovered) {
        handleHighlightPhoto();
        if (isVideo(photo.fullUrl)) {
          dispatch(SetHoveredVideoId({ hoveredVideoId: photo.id }));
        } else {
          dispatch(SetHoveredPhotoId({ hoveredId: photo.id }));
        }
      } else if (isSelected && !isHovered) {
        handleUnHighlightPhoto();
        if (isVideo(photo.fullUrl)) {
          dispatch(SetHoveredVideoId({ hoveredVideoId: undefined }));
        } else {
          dispatch(SetHoveredPhotoId({ hoveredId: undefined }));
        }
      }
    };

  const handleInputChange: (
    event?: SyntheticEvent<HTMLInputElement, MouseEvent>
  ) => void = event => {
    event?.stopPropagation();
    const temp = isSelected
      ? selectedMedia.filter(media => media.contentId !== photoDate.id)
      : [...selectedMedia, { type: photoDate.photoType ?? null, contentId: photoDate.id }];
    dispatch(SetSelectedMedia({ selectedMedia: temp }));
    if (!isSelected) {
      setTimeout(() => {
        handleHighlightPhoto();
      }, 200);
    }
  };

  const handleHighlightPhoto = () => {
    if (viewer) {
      const photoAlbumController = viewer.controllers.photoAlbumController;
      if (photoAlbumController) {
        let object;
        if (isVideo(photoDate.fullUrl)) {
          object = photoAlbumController?.getObjectByName(`video_${photoDate.id.toString()}`);
        } else {
          object = photoAlbumController?.getObjectByName(photoDate.id.toString());
        }
        if (object instanceof PhotoAlbumLocationObject) {
          if (photoAlbumController.hoverObject) {
            photoAlbumController.hoverObject.setUnHighlight();
          }
          photoAlbumController.hoverObject = object;
          photoAlbumController.hoverObject?.setHighlight(false);
        }
      }
    }
  };
  const handleUnHighlightPhoto = () => {
    if (viewer) {
      const photoAlbumController = viewer.controllers.photoAlbumController;
      if (photoAlbumController) {
        if (photoAlbumController.hoverObject) {
          photoAlbumController.hoverObject.setUnHighlight();
          photoAlbumController.hoverObject = null;
        }
      }
    }
  };

  const handlePhotoOnMapId: (
    event: SyntheticEvent<HTMLDivElement, MouseEvent>
  ) => void = async event => {
    event.stopPropagation();
    /**
     * @TODO: ebraj-angelswing
     * Need to update the content tooo....
     */
    if (isPhotoProcessing) {
      toast({
        type: T.Toast.INFO,
        content: {
          title: Text.drone.flightVideoTitle,
          description: Text.drone.flightVideoProcessing,
        },
        option: defaultToastInfoOption,
      });
      return;
    }
    if (!photoDate.latitude || !photoDate.longitude || !photoDate.altitude) {
      return;
    }

    if (!isSelected) {
      dispatch(
        SetSelectedMedia({
          selectedMedia: [
            ...selectedMedia,
            { type: photoDate.photoType ?? null, contentId: photoDate.id },
          ],
        })
      );
    }

    if (viewer) {
      /**
       *  This part for move camera on top of icon for threejs
       */
      setSelectedPhotoAlbumIdNew({ photoId: photoDate.id, photoType: photoDate.photoType });
      const center = runtime!.getPositionFromLatLongHeight({
        long: photoDate.longitude,
        lat: photoDate.latitude,
        height: photoDate.altitude,
      });
      const sphere = new THREE.Mesh(
        new THREE.SphereGeometry(15, 32, 16),
        new THREE.MeshBasicMaterial({ color: 0xffff00 })
      );
      sphere.position.set(center.x, center.y, center.z);
      await cameraControls?.rotateTo(0, 0);
      await cameraControls?.fitToSphere(sphere, true);
      sphere.geometry.dispose();
      sphere.material.dispose();
    } else {
      /**
       *  This part for move camera on top of icon for openlayer
       */
      if (isVideo(photoDate.fullUrl)) {
        dispatch(SetVideoOnMapId({ videoId: photoDate.id }));
        dispatch(SetHoveredVideoId({ hoveredVideoId: photoDate.id }));
      } else {
        dispatch(SetPhotoOnMapId({ photoId: photoDate.id }));
        dispatch(SetHoveredPhotoId({ hoveredId: photoDate.id }));
      }

      const { longitude: lon, latitude: lat }: T.Photo = photoDate;
      if (!lat || !lon) {
        return;
      }
      //   view.setCenter(fromLonLat([lon, lat]));
      dispatch(ChangeTwoDDisplayCenter({ twoDDisplayCenter: [lon, lat] }));
    }
  };
  const photoAlbumAnimation = (): boolean => {
    if (isPhotoProcessing) {
      toast({
        type: T.Toast.INFO,
        content: {
          title: Text.drone.flightVideoTitle,
          description: Text.drone.flightVideoProcessing,
        },
        option: defaultToastInfoOption,
      });
      return false;
    }
    let photoObject;
    if (isVideo(photoDate.fullUrl)) {
      photoObject = viewer?.controllers.photoAlbumController?.getObjectByName(
        `video_${photoDate.id.toString()}`
      );
    } else {
      photoObject = viewer?.controllers.photoAlbumController?.getObjectByName(
        photoDate.id.toString()
      );
    }
    if (photoObject) {
      const object = photoObject as PhotoAlbumObject;
      viewer?.controllers.photoAlbumController?.setPhotoObject(object);
      return true;
    }
    return false;
  };

  return (
    <PhotoInspectionListItemWrapper isSelected={isSelected} ref={ref}>
      {isVisible && (
        <PhotoListItem
          isVisible={isVisible}
          isSelected={isSelected}
          onMouseEnter={handleSelectedHoveredItems(photoDate, true)}
          onMouseLeave={handleSelectedHoveredItems(photoDate, false)}
          url={photoDate.thumbUrl}
          status={photoDate.status}
          onClick={handlePhotoListItemClick(photoDate.id)}
          id={`photo_${photoDate.id}`}
        >
          {photoDate.photoType === 'source' && !photoDate.takenAt ? (
            <InfoContainer>
              <InfoText color="#f5f5f5">{l10n(Text.drone.sourcePhotoProcessing)}</InfoText>
            </InfoContainer>
          ) : null}
          {isVideo(photoDate.fullUrl) ? (
            <>
              <ThumbNailWrapper isSelected={isSelected}>
                <VideoDurationWrapper>
                  <VideoDurationText>{duration}</VideoDurationText>
                  <PlayIcon />
                </VideoDurationWrapper>
                <VideoWrapper
                  isProcessing={isPhotoProcessing}
                  ref={videoRef}
                  src={photoDate.fullUrl}
                  onLoadedMetadata={handleVideoLoad}
                />
                <PhotoSvgWrapper onClick={handlePhotoOnMapId} disabled={false}>
                  <PhotoCameraSvg />
                </PhotoSvgWrapper>
              </ThumbNailWrapper>
              {isPhotoProcessing && (
                <DefaultImageContainer>
                  <DefaultFlightVideoElement scale={1} />
                  <VideoProcessingText>
                    {l10n(Text.drone.flightVideoProcessingText)}
                  </VideoProcessingText>
                </DefaultImageContainer>
              )}
            </>
          ) : null}

          <Wrapper>
            <Checkbox checked={isSelected} onChange={() => handleInputChange()} />
          </Wrapper>
          {!isVideo(photoDate.fullUrl) ? (
            <>
              <Tippy
                offset={T.TIPPY_OFFSET}
                theme="angelsw"
                placement="top"
                arrow={false}
                content={phototooltipContent()}
              >
                <PhotoSvgWrapper onClick={handlePhotoOnMapId} disabled={false}>
                  <PhotoCameraSvg />
                </PhotoSvgWrapper>
              </Tippy>
            </>
          ) : null}
        </PhotoListItem>
      )}
    </PhotoInspectionListItemWrapper>
  );
});

export const PhotoItem: FC<PhotoItemProps> = RawPhotoItem;
