/* eslint-disable max-lines */
import Tippy from '@tippyjs/react';
import React, {
  FC,
  KeyboardEvent,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import styled from 'styled-components';

import CenteredViewSvg from '^/assets/icons/centered-view.svg';
import TwoDBadgeSvg from '^/assets/icons/contents-list/2d-badge.svg';
import ThreeDBadgeSvg from '^/assets/icons/contents-list/3d-badge.svg';
import PhotoSvg from '^/assets/icons/contents-list/photo-1.svg';
import ToggleSvg from '^/assets/icons/contents-list/arrow.svg';
import DisabledToggleSvg from '^/assets/icons/contents-list/disabled-arrow.svg';
import DownloadGrayActivatedSvg from '^/assets/icons/download-gray-activated.svg';
import { ContentIcon as RawContentIcon } from '^/components/atoms/ContentIcon';
import { ContentsListItemCheckbox as Checkbox } from '^/components/atoms/ContentsListItemCheckbox';
import { ContentsListItemTitle as Title } from '^/components/atoms/ContentsListItemTitle';
import CopyContentButton from '^/components/atoms/CopyContentButton';
import DeleteContentButton from '^/components/atoms/DeleteContentButton';
import { zoomToCoordinate } from '^/components/cesium/cesium-util';
import { CesiumContext, CesiumContextProps } from '^/components/cesium/CesiumContext';
import { CANCELLABLE_CLASS_NAME } from '^/components/molecules/CreatingVolumeClickEventHandler';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { DISABLED_CONTENT_OPACITY, MediaQuery } from '^/constants/styles';
import {
  UseL10n,
  UseShouldContentDisabled,
  UseState,
  useL10n,
  useProjectCoordinateSystem,
  useShouldContentDisabled,
  useDeleteContent,
  UseDeleteContent,
  typeGuardESSContent,
} from '^/hooks';
import { DownloadOverlay, contentsSelector } from '^/store/duck/Contents';
import { ChangeEditingContent, ChangeTwoDDisplayCenter } from '^/store/duck/Pages';
import { useESSContentsStore } from '^/store/essContentsStore';
import * as T from '^/types';
import { getHasColor } from '^/utilities/annotation-content-util';
import { getCenterPointByContent } from '^/utilities/content-util';
import { isMobile } from '^/utilities/device';
import Text from './text';
import { useThreeStore } from '^/components/three/ThreeStore';
import { Mesh, MeshBasicMaterial, Object3D, SphereGeometry } from 'three';
import { forEach } from 'lodash-es';
import { SetCurrentPhotoId, SetSelectedMedia } from '^/store/duck/Photos';
import { PhotoAlbumObject } from '^/components/three/ThreeObjects';
import { isVideo } from '^/components/molecules/PhotoList/util';
import { useIssueStore } from '^/store/issue';
import { useIssueContents } from '^/hooks/useIssueContents';
import { useContentsStore } from '^/store/zustand/content/contentStore';

/** FIXME: this is temporary fix for the selectedPhotoIds state is nout update*/
let LOCAL_SELECTED_MEDIA: T.SelectedMedia[] = [];

interface ItemProps {
  isEditing?: boolean;
}

export const CONTENTSLIST_CTXSORT_KEY = 'ContentsListItem';

export const Root = styled.li({
  position: 'relative',
  listStyle: 'none',

  width: '100%',
  [MediaQuery[T.Device.MOBILE_L]]: {
    width: 'auto',
  },

  [MediaQuery[T.Device.MOBILE_S]]: {
    width: 'auto',
  },

  zIndex: 'auto',
});

export const SelectionBox = styled.div<ItemProps>(({ isEditing }) => ({
  position: 'relative',

  display: 'flex',
  alignItems: 'center',

  boxSizing: 'border-box',
  height: '40px',
  paddingLeft: '24px',
  paddingRight: '12px',
  backgroundColor: isEditing ? palette.ContentsList.titleActiveLightBlue.toString() : undefined,

  cursor: 'pointer',
  userSelect: 'none',

  ' ::after': {
    content: "''",
    position: 'absolute',
    width: '36px',
    height: '100%',
    left: '-36px',
    top: '0',
    backgroundColor: isEditing ? palette.ContentsList.titleActiveLightBlue.toString() : undefined,
  },
}));
SelectionBox.displayName = 'SelectionBox';

const ToggleIconContainer = styled.div<Pick<ItemProps, 'isEditing'>>(({ isEditing }) => ({
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: isEditing ? 'rotate(-180deg)' : undefined,
  borderRadius: '3px',
  ':hover': {
    backgroundColor: '#CED7E5',
  },
}));

export const TitleWrapper = styled.div({
  position: 'relative',
  flex: 1,

  height: '32px',

  marginLeft: '1.5px',
});

const Balloon = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  padding: '25px',
  margin: 'auto',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  background: palette.white.toString(),
  borderBottom: `1px solid ${palette.toggleButtonGray.toString()}`,
});

const BalloonHeader = styled.div<{ isColorAtEnd: boolean; isMarginOnTitle: boolean }>(
  ({ isColorAtEnd, isMarginOnTitle }) => ({
    boxSizing: 'border-box',

    width: '100%',
    height: '100%',
    padding: isMarginOnTitle ? '0 15px' : undefined,

    display: 'flex',
    justifyContent: isColorAtEnd ? 'flex-end' : 'space-between',

    alignItems: 'center',
  })
);

const BalloonTitle = styled.div<DisabledProps>(({ isDisabled }) => ({
  display: 'flex',
  alignItems: 'center',

  fontSize: '13px',
  fontWeight: 'bold',
  color: dsPalette.title.toString(),

  span: {
    opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  },
}));

const BalloonTools = styled.div<{ width: string }>(({ width }) => ({
  display: 'flex',
  position: 'relative',
  width,
  height: '13px',
  justifyContent: 'flex-end',
  alignItems: 'center',

  marginLeft: '7px',

  'svg, img': {
    cursor: 'pointer',
  },
}));

export const HorizontalDivider = styled.div<DisabledProps>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '100%',
  borderTop: `1px solid ${palette.toggleButtonGray.toString()}`,

  marginTop: '16px',
  marginBottom: '16px',
}));

export const HorizontalWideDivider = styled(HorizontalDivider)({
  marginTop: '23px',
  marginBottom: '23px',
});

export const ContentIcon = styled(RawContentIcon)<{ isProcessingOrFailed?: boolean }>(
  ({ isProcessingOrFailed }) => ({
    marginLeft: isProcessingOrFailed ? '12px' : '18px',
  })
);

export const FeatureWrapper = styled.div({
  marginLeft: 'auto',

  display: 'flex',
  alignItems: 'center',
});

const ContentButtonWrapper = styled.div<{ isProcessingOrFailed: boolean }>(
  ({ isProcessingOrFailed }) => ({
    marginTop: isProcessingOrFailed ? '-2px' : undefined,
  })
);

const BadgeWrapper = styled.div({
  paddingTop: '1px',
  marginRight: '10.5px',
});

const ContentCenterButton = styled.div({
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  marginRight: '8.5px',
  marginLeft: '3px',
  ':hover': {
    backgroundColor: palette.dropdown.dividerColor.toString(),
  },
});

function getDimensionBadge(type: T.ContentType, in3D: boolean): ReactNode {
  const in2D: boolean = !in3D;

  if (in3D && T.MAP_TAB_TWO_D_CONTENTS.includes(type)) {
    return (
      <BadgeWrapper>
        <TwoDBadgeSvg />
      </BadgeWrapper>
    );
  } else if (in2D && T.MAP_TAB_THREE_D_CONTENTS.includes(type)) {
    return (
      <BadgeWrapper>
        <ThreeDBadgeSvg />
      </BadgeWrapper>
    );
  }

  return undefined;
}

export interface Props {
  readonly content: T.IssueContent;
  readonly firstBalloonTitle?: string;
  readonly firstBalloonDescription?: ReactNode | string;
  readonly className?: string;
  readonly isPinned?: boolean;
  onClick?(): void;
}

interface DisabledProps {
  isDisabled?: boolean;
}
/**
 * If you pass children, it will be appended into first balloon
 * at the bottom of content title.
 */
export const ContentsListItem: FC<PropsWithChildren<Props>> = ({
  content: { id: contentId },
  content,
  firstBalloonTitle,
  firstBalloonDescription,
  children,
  className,
  onClick,
  isPinned = false,
}) => {
  const setEditingESSContentId = useESSContentsStore(s => s.setEditingESSContentId);
  const { setEditingIssueContentId } = useIssueStore();
  const dispatch: Dispatch = useDispatch();
  const { viewer }: CesiumContextProps = useContext(CesiumContext);

  const [isHovered, setIsHovered]: UseState<boolean> = useState<boolean>(false);

  const { patchIssueContentSelection, patchIssueContent } = useIssueContents();
  const byId = useContentsStore(s => s.contents.byId);

  const isIn3D: T.ContentsPageState['in3D'] = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const isIn3DPointCloud: T.ContentsPageState['in3DPointCloud'] = useSelector(
    (s: T.State) => s.Pages.Contents.in3DPointCloud
  );
  const currentPointCloudEngine: T.ContentsPageState['currentPointCloudEngine'] = useSelector(
    (s: T.State) => s.Pages.Contents.currentPointCloudEngine
  );

  const editingContentId = useIssueStore().editingIssueContentId;
  const printingContentId: T.ContentsPageState['printingContentId'] = useSelector(
    (s: T.State) => s.Pages.Contents.printingContentId
  );
  const isOverlayContent: boolean = T.OverlayContentTypes.includes(byId[content.id]?.type);

  const sidebarTab: T.ContentsPageState['sidebarTab'] = useSelector(
    (s: T.State) => s.Pages.Contents.sidebarTab
  );

  const [l10n]: UseL10n = useL10n();
  const rootRef: MutableRefObject<HTMLLIElement | null> = useRef(null);
  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();
  const shouldContentDisabled: UseShouldContentDisabled = useShouldContentDisabled(content.type);
  const ESSContent: T.ESSContent | undefined = typeGuardESSContent(content);
  const isEditing: boolean = useMemo(
    () => content.id === editingContentId,
    [content.id, editingContentId]
  );

  const isProcessingOrFailed: boolean = contentsSelector.isProcessingOrFailedByContent(content);
  const isProcessing: boolean = contentsSelector.isProcessing(content);
  const isFailed: boolean = contentsSelector.isFailed(content);
  const isInCesium =
    isIn3D || (isIn3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.CESIUM);
  const viewer3Js = useThreeStore(s => s.viewer);
  const cameraControls = useThreeStore(s => s.cameraControls);
  const selectedMedia = useSelector((s: T.State) => s.Photos.selectedMedia);
  const isColorAtEnd: boolean = false;
  const isMarginOnTitle: boolean = false;

  const handleMouseOver: () => void = useCallback(() => {
    setIsHovered(true);
  }, [isHovered]);

  const handleMouseLeave: () => void = useCallback(() => {
    setIsHovered(false);
  }, [isHovered]);

  const color: string | undefined = getHasColor(content.type)
    ? content.color.toString()
    : undefined;

  const handleScrollIntoView: () => void = async () => {
    if (!rootRef?.current) {
      return;
    }
    scrollIntoViewIfNeeded(rootRef.current as Element, {
      scrollMode: 'if-needed',
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  useEffect(() => {
    if (isMobile()) {
      setIsHovered(isEditing);
    }
    // Use window.setTimeout for smoother animation
    if (isEditing) {
      window.setTimeout(handleScrollIntoView);
    }
  }, [isEditing]);

  const handleDownloadOverlay: () => void = useCallback(
    () => dispatch(DownloadOverlay({ contentId: content.id })),
    [content.id]
  );

  const handleToggle: () => void = useCallback(() => {
    if (shouldContentDisabled) {
      return;
    }
    const isGoingToBeEditing: boolean = !isEditing;

    switch (sidebarTab) {
      case T.ContentPageTabType.ESS: {
        setEditingESSContentId(isGoingToBeEditing ? contentId : undefined);
        break;
      }
      case T.ContentPageTabType.ISSUE: {
        setEditingIssueContentId(isGoingToBeEditing ? contentId : undefined);
        break;
      }
      default: {
        dispatch(
          ChangeEditingContent({
            contentId: !isGoingToBeEditing ? undefined : contentId,
            // When content is failed is processing,
            // the content list needs to be expanded still (see error message, delete, etc).
            // However, do not actually select them since it might trigger
            // rendering something (e.g. switching to 3D).
            skipSelecting: isProcessingOrFailed,
          })
        );
      }
    }
  }, [isProcessingOrFailed, shouldContentDisabled, isEditing, contentId]);

  useEffect(() => {
    LOCAL_SELECTED_MEDIA = selectedMedia;
  }, [selectedMedia]);

  const handleContentCenterClick: () => void = async () => {
    // const actions: Action[] = [];
    patchIssueContentSelection({
      ...content,
      id: contentId,
      config: {
        type: content.type,
        selectedAt: new Date(),
      },
    } as T.IssueContent);
    const centerPoint: T.GeoPoint | undefined = getCenterPointByContent(
      content,
      dispatch,
      projectProjection
    );
    if (centerPoint !== undefined) {
      if (viewer3Js) {
        setTimeout(async () => {
          if (viewer3Js?.controllers?.photoAlbumController?.selectedObject) {
            void viewer3Js?.controllers?.photoAlbumController?.exit();
          }
          let issueObject: Object3D | undefined;
          forEach(viewer3Js.entities.issuePointEntity?.children, (v: any) => {
            if (v.contentId === content.id) {
              issueObject = v;
              return false;
            }
            return true;
          });
          if (issueObject) {
            const sphere = new Mesh(
              new SphereGeometry(15, 32, 16),
              new MeshBasicMaterial({ color: 0xffff00 })
            );
            sphere.position.copy(issueObject.position);
            await cameraControls?.rotateTo(0, 0);
            await cameraControls?.fitToSphere(sphere, true);
            sphere.geometry.dispose();
            sphere.material.dispose();
          }
        }, 500);
      } else {
        if (isInCesium && viewer !== undefined) {
          zoomToCoordinate(viewer, centerPoint);
        } else {
          dispatch(ChangeTwoDDisplayCenter({ twoDDisplayCenter: centerPoint }));
        }
      }
    }

    // batch(() => actions.forEach(dispatch));
  };

  const isMapRelatedContent: boolean = T.MAP_TAB_CONTENTS.includes(content.type);
  const dimensionBadge: ReactNode = isMapRelatedContent
    ? getDimensionBadge(content.type, isIn3D)
    : undefined;

  const hasNoDownloadAndDelete: boolean = false;

  const downloadOverlayButton: ReactNode = useMemo(
    () =>
      !hasNoDownloadAndDelete && isOverlayContent ? (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="bottom"
          arrow={false}
          content={l10n(Text.tooltipDownload)}
        >
          <ContentButtonWrapper
            onClick={handleDownloadOverlay}
            className={CANCELLABLE_CLASS_NAME}
            isProcessingOrFailed={isProcessingOrFailed}
            data-ddm-track-action={T.TrackActions.MAP_DOWNLOAD}
            data-ddm-track-label={`${T.TrackLabels.BTN_CONTENTS_LIST}-download-overlay-${content.type}`}
          >
            <DownloadGrayActivatedSvg />
          </ContentButtonWrapper>
        </Tippy>
      ) : undefined,
    [
      hasNoDownloadAndDelete,
      isOverlayContent,
      handleDownloadOverlay,
      isProcessingOrFailed,
      content.type,
    ]
  );

  const copyButton: ReactNode = useMemo(() => {
    if (hasNoDownloadAndDelete || ESSContent === undefined) {
      return null;
    }

    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom"
        arrow={false}
        content={l10n(Text.tooltipCopy)}
      >
        <ContentButtonWrapper isProcessingOrFailed={isProcessingOrFailed}>
          <CopyContentButton content={content} isDisabled={Boolean(printingContentId)} />
        </ContentButtonWrapper>
      </Tippy>
    );
  }, [content, hasNoDownloadAndDelete, printingContentId, isProcessingOrFailed, ESSContent]);

  const deleteButton: ReactNode = useMemo(() => {
    if (hasNoDownloadAndDelete) {
      return null;
    }

    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom"
        arrow={false}
        content={l10n(Text.tooltipDelete)}
      >
        <ContentButtonWrapper
          className={CANCELLABLE_CLASS_NAME}
          isProcessingOrFailed={isProcessingOrFailed}
        >
          <DeleteContentButton content={content} isDisabled={Boolean(printingContentId)} />
        </ContentButtonWrapper>
      </Tippy>
    );
  }, [content, hasNoDownloadAndDelete, printingContentId, isProcessingOrFailed]);

  const contentCenterButtonTooltipText: string = useMemo(() => {
    const contentTabString: string = l10n(Text.issue);
    return `${contentTabString}${l10n(Text.tooltipCenter)}`;
  }, [content.type, l10n]);

  const contentCenterButton: ReactNode = useMemo(() => {
    if (!isHovered || isMapRelatedContent || isProcessingOrFailed) {
      return null;
    }
    if (isIn3DPointCloud) {
      return null;
    }

    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom"
        arrow={false}
        content={contentCenterButtonTooltipText}
      >
        <ContentCenterButton onClick={handleContentCenterClick}>
          <CenteredViewSvg />
        </ContentCenterButton>
      </Tippy>
    );
  }, [
    isIn3D,
    isIn3DPointCloud,
    isHovered,
    isMapRelatedContent,
    isProcessingOrFailed,
    content.type,
  ]);

  const handlePhotoIssueClick = (): void => {
    if (!content.photo) {
      return;
    }
    patchIssueContent({
      content: {
        id: contentId,
        config: {
          selectedAt: new Date(),
        },
      },
    });
    if (viewer3Js) {
      if (LOCAL_SELECTED_MEDIA.some(media => media.contentId === content.photo?.id)) {
        photoAlbumAnimation(content.photo);
      } else {
        const temp = [
          ...LOCAL_SELECTED_MEDIA,
          {
            type: content.photo?.photoType ?? null,
            contentId: content.photo.id,
          },
        ];
        dispatch(SetSelectedMedia({ selectedMedia: temp }));
        setTimeout(() => {
          photoAlbumAnimation(content.photo!);
        }, 500);
      }
    } else {
      setTimeout(() => {
        // dispatch(ChangeContentsSidebarTab({ sidebarTab: T.ContentPageTabType.PHOTO }));
        if (content.photo) {
          const temp = [
            ...LOCAL_SELECTED_MEDIA,
            { type: content.photo?.photoType ?? null, contentId: content.photo.id },
          ];
          dispatch(SetSelectedMedia({ selectedMedia: temp }));
          dispatch(SetCurrentPhotoId({ photoId: content.photo?.id }));
        }
      }, 500);
    }
  };
  const photoAlbumAnimation = (photoDate: T.Photo): boolean => {
    let photoObject;
    if (isVideo(photoDate.fullUrl)) {
      photoObject = viewer3Js?.controllers.photoAlbumController?.getObjectByName(
        `video_${photoDate.id.toString()}`
      );
    } else {
      photoObject = viewer3Js?.controllers.photoAlbumController?.getObjectByName(
        photoDate.id.toString()
      );
    }
    if (photoObject) {
      const object = photoObject as PhotoAlbumObject;
      viewer3Js?.controllers.photoAlbumController?.setPhotoObject(object);
      return true;
    }
    return false;
  };
  const issuePhotoNavigateButton: ReactNode = useMemo(() => {
    if (isMapRelatedContent || isProcessingOrFailed) {
      return null;
    }
    if (isIn3DPointCloud) {
      return null;
    }

    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom"
        arrow={false}
        content={l10n(Text.tooltipNavigateToPhotoAlbum)}
      >
        <ContentCenterButton onClick={handlePhotoIssueClick}>
          <PhotoSvg />
        </ContentCenterButton>
      </Tippy>
    );
  }, [isMapRelatedContent, isProcessingOrFailed, viewer3Js, content.type]);

  const toggleIcon: ReactNode = useMemo(() => {
    if (shouldContentDisabled) {
      return <DisabledToggleSvg />;
    }
    return (
      <ToggleIconContainer className={CANCELLABLE_CLASS_NAME} isEditing={isEditing}>
        <ToggleSvg />
      </ToggleIconContainer>
    );
  }, [shouldContentDisabled, isEditing]);

  const title: ReactNode = (
    <TitleWrapper className={CANCELLABLE_CLASS_NAME}>
      <Title fromUI={T.EditableTextUI.CONTENT_TITLE} content={content} isEditing={isEditing} />
    </TitleWrapper>
  );

  // This logic is needed because this component is trying to do too much.
  // It wants to have different sizes for different types of content.
  // The design needs to somehow be more consistent than this,
  // or create separate components for each type instead.
  const balloonToolsWidth: string = useMemo(() => {
    if (hasNoDownloadAndDelete) {
      return '13px';
    }

    if (isOverlayContent) {
      return '66px';
    }

    return ESSContent ? '51px' : '39px';
  }, [hasNoDownloadAndDelete, isOverlayContent, ESSContent]);

  const deleteContent: UseDeleteContent = useDeleteContent();

  const handleDeleteContent = () => {
    if (printingContentId) {
      return;
    }
    // Only the user who is admin of project can delete content,
    // so instead of dispatching DeleteESSContent directly,
    // show popup to confirm deleting selected content
    if (content.id === editingContentId) {
      deleteContent(content.id, content.type);
    }
  };

  const handleKeyDown: (e: KeyboardEvent<HTMLElement>) => void = e => {
    if ((e.target as HTMLElement).tagName === T.TagName.INPUT || !isEditing) {
      return;
    }
    e.preventDefault();

    if (!isEditing) {
      return;
    }
    if (e.key === T.ShortCut.DELETE) {
      handleDeleteContent();
    }
  };

  const balloon: ReactNode = isEditing ? (
    <Balloon>
      <BalloonHeader
        data-testid="balloon-header"
        isColorAtEnd={isColorAtEnd}
        isMarginOnTitle={isMarginOnTitle}
      >
        <BalloonTitle isDisabled={isProcessing && isOverlayContent}>
          <span>{firstBalloonTitle}</span>
          {firstBalloonDescription}
        </BalloonTitle>
        <BalloonTools width={balloonToolsWidth}>
          {downloadOverlayButton}
          {copyButton}
          {deleteButton}
        </BalloonTools>
      </BalloonHeader>
      {children}
    </Balloon>
  ) : undefined;

  const trackLabel: string | undefined = useMemo(
    () => `${content.type}-${isIn3D ? '3d' : '2d'}`,
    [content.type, isIn3D]
  );

  // Only tracks content list click to view
  // when it was not selected previously.
  const trackActionContentsList: string | undefined = useMemo(
    () => (!content?.config?.selectedAt && !isEditing ? 'map-view' : undefined),
    [Boolean(content?.config?.selectedAt), isEditing]
  );

  // Should differ between toggling on and off the content.
  const trackActionContentsListCheckbox: string | undefined = useMemo(
    () => (!content?.config?.selectedAt ? 'map-view' : 'map-view-off'),
    [Boolean(content?.config?.selectedAt)]
  );

  // The only exception for color is ESS text where
  // the color that the icon represents is the font color.
  // It's the requirement.
  const iconColor: string | undefined = color;
  return (
    <Root
      id={`contentid-${contentId}`}
      ref={rootRef}
      color={color}
      className={className}
      onClick={onClick}
    >
      <SelectionBox
        isEditing={isEditing}
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        data-testid="content-selection-box"
        data-ctxsort={CONTENTSLIST_CTXSORT_KEY}
        data-ctxsort-key={content.id}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        data-ddm-track-action={trackActionContentsList}
        data-ddm-track-label={`${T.TrackLabels.BTN_CONTENTS_LIST}-${trackLabel}`}
      >
        <Checkbox
          content={content}
          isProcessingOrFailed={isProcessingOrFailed}
          isFailed={isFailed}
          trackAction={trackActionContentsListCheckbox}
          trackLabel={`btn-contents-list-checkbox-${trackLabel}`}
        />
        <ContentIcon
          contentType={content.type}
          color={iconColor}
          isProcessingOrFailed={isProcessingOrFailed}
          isPinned={isPinned}
        />
        {title}
        <FeatureWrapper>
          {dimensionBadge}
          {content.type === T.ContentType.ISSUE_PHOTO
            ? issuePhotoNavigateButton
            : contentCenterButton}
          {toggleIcon}
        </FeatureWrapper>
      </SelectionBox>
      {balloon}
    </Root>
  );
};
