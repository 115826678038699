import { useRenamePhotoAlbum } from '^/hooks/api/photos/usePhoto';
import theme from '^/theme';
import { PhotoAlbum } from '^/types';
import React, { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import PhotoAlbumTitleInput from '../PhotoAlbum/PhotoAlbumTitleInput';

const EditableTitleContainerRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '20px',
});

EditableTitleContainerRoot.displayName = 'PhotoAlbumTitleEditable';

const AlbumTitle = styled.div({
  color: theme.colors.black[100],
  fontSize: '18px',
  fontWeight: 700,
  ':hover': {
    backgroundColor: theme.colors.coolGray[100],
    color: theme.colors.coolGray[800],
  },
  padding: '5px 8px',
  borderRadius: '4px',
  cursor: 'text',
});

export const PhotoAlbumTitleEditable = ({ photoAlbum }: { photoAlbum: PhotoAlbum }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const { mutate: renamePhotoAlbum } = useRenamePhotoAlbum();

  const handleRenameOptionClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setEditedTitle(photoAlbum.title);
    setIsEditing(true);
  };

  const handleCompleteRename = () => {
    renamePhotoAlbum({
      newTitle: editedTitle,
      photoAlbumId: photoAlbum.id,
      updatePhotoAlbumQuery: true,
    });
  };

  return (
    <EditableTitleContainerRoot>
      {isEditing ? (
        <PhotoAlbumTitleInput
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          editedTitle={editedTitle}
          setEditedTitle={setEditedTitle}
          onSave={handleCompleteRename}
          onLightBg={true}
          customStyles={{
            fontSize: '18px',
            fontWeight: 700,
          }}
        />
      ) : (
        <AlbumTitle onClick={handleRenameOptionClick}>{photoAlbum.title}</AlbumTitle>
      )}
    </EditableTitleContainerRoot>
  );
};
