import { Language } from '^/types';

export default {
  attachFile: {
    [Language.KO_KR]: '파일 첨부',
    [Language.EN_US]: 'Attach files',
  },
  dragActive: {
    [Language.KO_KR]: '여기에 끌어서 놓기',
    [Language.EN_US]: 'Drop here',
  },
  dropzoneText: {
    [Language.KO_KR]: '클릭하여 업로드할 파일을 선택하거나 이곳에 끌어서 놓기',
    [Language.EN_US]: 'Click here to upload or simply drag and drop files here',
  },
  selectFromAlbumSelectorText: {
    [Language.KO_KR]: '포토 갤러리에서 원본 사진을 업로드하려면 버튼을 클릭하세요',
    [Language.EN_US]: 'Click the button to upload a source photo from the photo gallery',
  },
  selectPhotos: {
    [Language.KO_KR]: '앨범 선택',
    [Language.EN_US]: 'Select Album',
  },
};
