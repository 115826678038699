import { ComponentType } from 'react';
import { ConnectedComponent, DispatchProp, GetProps, Matching, connect } from 'react-redux';
import { AnyAction } from 'redux';

import * as T from '^/types';

export interface L10nProps {
  readonly language: T.Language;
}

type InjectedProps = DispatchProp<AnyAction> & L10nProps;

/**
 * @author Joon-Mo Yang <jmyang@angelswing.io>
 * @desc HOC for transform context to props
 */
function withL10n<C extends ComponentType<Matching<InjectedProps, GetProps<C>>>>(
  Comp: any
): ConnectedComponent<any, any> {
  /**
   * @author Junyoung Clare Jang <jjc9310@gmail.com>
   * @desc Wrapped component
   */
  return connect(
    (state: Pick<T.State, 'Pages'>) => ({
      language: state.Pages.Common.language,
    }),
    null
  )(Comp);
}
export default withL10n;
