import * as T from '^/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface FlightScheduleState {
  editingFlightScheduleId: T.FlightScheduleContent['id'] | undefined;
  deletingFlightScheduleId: T.FlightScheduleContent['id'] | undefined;
  flightSchedules: Record<T.FlightScheduleContent['id'], T.FlightScheduleContent>;
  selectedFlightScheduleSort: T.FlightScheduleSortByCriteria;
}

interface FlightScheduleActions {
  setEditingFlightScheduleId(flightScheduleId: T.FlightScheduleContent['id'] | undefined): void;
  setDeletingFlightScheduleId(flightScheduleId: T.FlightScheduleContent['id'] | undefined): void;
  setFlightSchedules(
    flightSchedules: Record<T.FlightScheduleContent['id'], T.FlightScheduleContent>
  ): void;
  setSelectedFlightScheduleSort(
    newSelectedFlightScheduleSort: T.FlightScheduleSortByCriteria
  ): void;
  updateFlightSchedules(singleFlightSchedule: T.FlightScheduleContent): void;
}

const flightScheduleInitialState: FlightScheduleState = {
  editingFlightScheduleId: undefined,
  deletingFlightScheduleId: undefined,
  flightSchedules: {},
  selectedFlightScheduleSort: T.FlightScheduleSortByCriteria.NEWEST,
};

type FlightScheduleStore = FlightScheduleState & FlightScheduleActions;

export const useFlightScheduleStore = create<FlightScheduleStore>()(
  devtools((set, get) => ({
    ...flightScheduleInitialState,
    setFlightSchedules(flightSchedules) {
      set(flightScheduleStore => ({ ...flightScheduleStore, flightSchedules }));
    },
    updateFlightSchedules(singleFlightSchedule: T.FlightScheduleContent) {
      const { flightSchedules } = get();
      const tempFlightSchedule = {
        ...flightSchedules,
        [singleFlightSchedule.id]: singleFlightSchedule,
      };
      set({ flightSchedules: tempFlightSchedule });
    },
    setSelectedFlightScheduleSort(newSelectedFlightScheduleSort: T.FlightScheduleSortByCriteria) {
      set(flightScheduleStore => ({
        ...flightScheduleStore,
        selectedFlightScheduleSort: newSelectedFlightScheduleSort,
      }));
    },
    setEditingFlightScheduleId(flightScheduleId: T.FlightScheduleContent['id']) {
      set(flightScheduleStore => ({
        ...flightScheduleStore,
        editingFlightScheduleId: flightScheduleId,
      }));
    },
    setDeletingFlightScheduleId(flightScheduleId: T.FlightScheduleContent['id']) {
      set(flightScheduleStore => ({
        ...flightScheduleStore,
        deletingFlightScheduleId: flightScheduleId,
      }));
    },
  }))
);
