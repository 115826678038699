import styled, { CSSObject, keyframes } from 'styled-components';
import { MapController } from '^/constants/zindex';
import dsPalette from '^/constants/ds-palette';

export const Root = styled.div({
  //   width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '1033px',
  height: '770px',
  paddingTop: '11px',
  paddingLeft: '50px',
  paddingRight: '50px',
  paddingBottom: '50px',
  position: 'relative',
});

export const ControllerRoot = styled.div({
  position: 'absolute',
  right: '64px',
  width: '32px',
  top: '50px',
  zIndex: MapController.DEFAULT,
});

export const ViewerWrapper = styled.div({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

export const IfcTopHeader = styled.div({
  height: '150px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#181818',
  borderRadius: '12px 12px 0px 0px',
});

export const IfcTooltipTextWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '24px',
  gap: '9px',
});

export const IfcTooltipHeaderText = styled.span({
  fontSize: '16px',
  color: 'var(--neutral-2, #32404D)',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '24px',
});

export const IfcTooltipDescriptionText = styled.span({
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  color: 'var(--neutral-base, #576F84)',
  whiteSpace: 'pre-wrap',
});

export const IFCTooltipContainer = styled.div({
  width: '300px',
  flexShrink: '0',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '12px',
  background: '#ffffff',
  border: '2px solid #EEF2F5',
});

export const LoadingWrapper = styled.div({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  borderRadius: '6px',
  border: '2px solid rgba(0, 0, 0, 0.1)',
  padding: '10px',
  background: 'rgba(0, 0, 0, 0.25)',
  flexDirection: 'row',
  gap: '15px',
  height: '50px',
});

export const LoadingText = styled.div({
  color: '#ffffff',
});

export const loadingDivCustomStyle: CSSObject = {
  border: `5px solid ${dsPalette.grey40.toString()}`,
  borderTop: '5px solid #ffffff',
  width: 32,
  height: 32,
};

export const shimmerAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const ShimmerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.1) 50%, transparent 100%);
  transform: translateX(-100%);
  animation: ${shimmerAnimation} 3s infinite;
`;

export const LoadingContainer = styled.div<{ img: string }>(({ img }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  alignItems: 'center',
  background: `url('${img}')`,
}));

export const LoadingBackground = styled.div({
  position: 'absolute', // Position the overlay relative to the parent
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100vw',
  height: '100vh',
  paddingTop: '11px',
  paddingLeft: '50px',
  paddingRight: '50px',
  paddingBottom: '50px',
  backdropFilter: 'blur(5px)',
  background: 'rgba(24, 24, 24, 0.4)',
  zIndex: 300,
});

export const TooltipContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20%',
  cursor: 'pointer',
});

export const TooltipWrapper = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 301,
});

export const TooltipButton = styled.button({
  borderRadius: '4px',
  background: '#1F4782',
  padding: '6px',
  color: '#fff',
  cursor: 'pointer',
});
